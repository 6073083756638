import React, { useState, useEffect, useRef } from "react";
import NotificationAlert from "react-notification-alert";
import Firebase from "firebase";
import LoadingOverlay from "react-loading-overlay";
import CopyToClipboard from "react-copy-to-clipboard";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import Switch from "react-bootstrap-switch";
import IconUpload from "../../../components/CustomUpload/IconUpload1";
import { ColorPicker } from "react-pick-color";
import dateFormat from "dateformat";
import ReactBSAlert from "react-bootstrap-sweetalert";

import {
  Col,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Label,
  Button,
  Input,
  Form,
  FormGroup,
} from "reactstrap";
import config from "../../../config";
const uuidv1 = require("uuid/v1");

function KioskAddEdit(props) {
  // constructor (props) {
  // super(props)
  if (!Firebase.apps.length) {
    Firebase.initializeApp(config);
  }

  const printLogo = useRef(null);
  const background = useRef(null);
  const notificationAlert = useRef(null);

  const [state, setState1] = useState({
    printerIp: "",
    printerPort: "9100",
    add: false,
    loading: false,
    customer_id: "",
    max_height: 0,
    max_width: 0,
    sub_location_id: "",
    current_time: Date.now(),
    dateFormat: "dddd, mmmm dS, yyyy, h:MM:ss TT",

    show_location_icon: true,
    footer_text: "",
    button_layout_format: "full",
    enable_print_logo: true,
    headerImage: "",
    print_type: null,
    print_type_list: [
      {
        value: "normal",
        label: "Normal Thermal Printer",
      },
      {
        value: "v001",
        label: "Standard Dispenser V001",
      },
    ],

    main_location_list: [],
    sub_location_list: [],
    selected_main_location: null,
    selected_sub_location: null,
    pinState: "",
    pin_type: "password",
    location_package_id: "",

    dispenser_id: "",
    dispenser_name: "",
    dispenser_nameState: "",
    height: 1920,
    width: 1080,
    invalid_height: false,
    invalid_width: false,
    pin: "",
    location_image_url: "",
    location_name: "",
    location_address: "",
    service_list: [],
    backgroundUrl: "",
    logoPadding: { top: "0", bottom: "0" },
    servicesMargin: "20",
    servicesRadius: "5",
    servicesSize: "60",
    defaultFont: "Calibri",
    backgroundColor: "#f7f0e0",
    colorPick: false,
    colorPlace: "",
    servicesBackground: "#ea8e3d",

    servicesName: true,
    servicesTextColor: "#ffff",
    servicesFontSize: "",
    servicesFont: "",

    servicesIconSize: "30",
    servicesIcon: false,

    servicesDetails: false,
    servicesDetailsColor: "#ffff",
    servicesDetailsFont: "",
    servicesDetailsFontSize: "",

    secServices: false,
    secServicesColor: "",
    secServicesFont: "",
    secServicesFontSize: "",

    dateDisplay: false,
    dateBackground: "#ffff",
    dateColor: "#000000",
    dateFont: "",
    dateFontSize: "",
    dateSize: "",

    footerDisplay: false,
    footerBackground: "#ffff",
    footerColor: "#000000",
    footerFont: "",
    footerFontSize: "",
    footerSize: "",
    printingAnimation: "",
    sortingOrder: { label: "", value: "" },

    fontList: [
      { label: "Calibri", value: "Calibri" },
      { label: "Montserrat", value: "Montserrat" },
      { label: "A_Waheed", value: "A_Waheed" },
      { label: "Faruma", value: "Faruma" },
      { label: "MV_Faseyha", value: "MV_Faseyha" },
      { label: "Arial Black", value: "Arial Black" },
      { label: "Verdana", value: "Verdana" },
      { label: "Tahoma", value: "Tahoma" },
      { label: "Trebuchet MS", value: "Trebuchet MS" },
      { label: "Impact", value: "Impact" },
      { label: "Times", value: "Times" },
      { label: "Didot", value: "Didot" },
      { label: "Georgia", value: "Georgia" },
      { label: "American Typewriter", value: "American Typewriter" },
      { label: "Andalé Mono", value: "Andalé Mono" },
      { label: "Courier", value: "Courier" },
      { label: "Lucida", value: "Lucida" },
      { label: "Monaco", value: "Monaco" },
      { label: "Bradley", value: "Bradley" },
      { label: "Brush Script MT", value: "Brush Script MT" },
      { label: "Luminari", value: "Luminari" },
      { label: "Comic Sans MS", value: "Comic Sans MS" },
    ],

    ////////////

    mainLocFontFont: "",
    subLocFontFont: "",
    addressFont: "",
    text1Font: "",
    text2Font: "",
    serviceNameFont: "",
    serviceDetailsFont: "",
    tokenFont: "",
    tokensAheadFont: "",
    qrCodeFont: "",
    printDateFont: "",
    printFooterFont: "",

    // dateFormat: "",
    // customer_id: "",
    main_location: "",
    sub_location: "",
    printLogo: false,
    printLogoUrl: "",
    printLogoSize: "",
    mainLocationEnable: false,
    mainLocFontSize: "",
    mainLocBold: false,
    subLocationEnable: false,
    subLocFontSize: "",
    subLocBold: false,
    addressEnable: false,
    addressSize: "",
    addressBold: false,
    text1Enable: false,
    text1: "",
    text1Size: "",
    text1Bold: false,
    text2Enable: false,

    text2: "",
    text2Size: "",
    text2Bold: false,
    printHeaderBottom: "",

    serviceName: false,
    serviceNameSize: "",
    serviceNameBold: false,

    serviceDetails: false,
    serviceDetailsSize: "",
    serviceDetailsBold: false,
    printServicePadding: "",

    tokenSize: "3",
    tokenBold: false,
    tokenAhead: false,
    tokensAheadSize: "",
    tokensAheadBold: false,

    qrCode: false,
    qrCodeSize: "",
    tokenBottomPadding: "",

    printDateDisplay: false,
    printDateSize: "",
    printDateBold: false,

    printFooterDisplay: false,
    printFooterText: "",
    printFooterSize: "",

    printFooterBackground: "",
    printFooterColor: "",
    printFooterTextSize: "",

    macAddress: "",
    kioskModels: [],
    kioskModel: {},
    kioskAddErr: "",
    saveTemp: false,
    selectedServices: [],
    selectedServicesObjects: [],
  });
  const setState = (e) => setState1((c) => ({ ...c, ...e }));
  // }

  // componentDidMount () {
  useEffect(() => {
    getCurrentTime();
    let id = props.match.params.id;
    console.log(props.match.params);
    if (id !== "" && id !== null && id !== undefined) {
      setState({ dispenser_id: id });
      loadData(id);
    } else {
      let dispenser_id = uuidv1();
      setState({ dispenser_id: dispenser_id, add: true });
      loadMainLocations();
    }
  }, []);

  useEffect(() => {
    if (state.kioskModel?.height > 0 && state.kioskModel?.width > 0)
      setState({
        height: state.kioskModel?.height,
        width: state.kioskModel?.width,
      });
  }, [state.kioskModel]);

  // }

  function loadData(id) {
    setState({ loading: true });
    // ---------- Load Max Height and Width ---------- //
    Firebase.firestore()
      .collection("System_Config")
      .doc("Dispensor_Settings")
      .get()
      .then(function (dispenser_setting) {
        if (dispenser_setting.exists) {
          setState({
            max_height: dispenser_setting.data().Dispensor_Max_Height,
            max_width: dispenser_setting.data().Dispensor_Max_Width,
            kioskModels: dispenser_setting.data()?.Kiosk_Models || [],
          });
          if (!state.add)
            // ---------- Load Dispenser Data ---------- //
            Firebase.firestore()
              .collection("Kiosk")
              .doc(id)
              .get()
              .then(function (doc) {
                if (doc.exists) {
                  let one = state.print_type_list.find((item) => item.value === doc.data().Print_Type);
                  setState({
                    printerIp: doc.data().PrinterIp || "",
                    printerPort: doc.data().PrinterPort || "9100",
                    dateFormat: doc.data().Date_Format || state.dateFormat,
                    sub_location_id: doc.data().Sub_Location_ID,
                    show_location_icon: doc.data().Show_Location_Icon,
                    footer_text: doc.data().Footer_Text,
                    button_layout_format: doc.data().Button_Layout_Format,
                    enable_print_logo: doc.data().Enable_Print_Logo,
                    headerImage: doc.data().Header_Image || "",
                    print_type: one,
                    dispenser_name: doc.data().Name,
                    height: doc.data().Layout_Height,
                    width: doc.data().Layout_Width,
                    pin: doc.data().Pin,
                    backgroundUrl: doc.data()?.Background_Url || "",
                    logoPadding: doc.data()?.Logo_Padding || state.logoPadding,
                    servicesMargin: doc.data()?.Services_Margin || "",
                    servicesRadius: doc.data()?.Services_Radius || "",
                    servicesSize: doc.data()?.Services_Size || "",
                    defaultFont: doc.data()?.Default_Font || "",
                    backgroundColor: doc.data()?.Background_Color || "",
                    servicesBackground: doc.data()?.Services_Background || "",
                    servicesName: doc.data()?.Services_Name != undefined ? doc.data()?.Services_Name : true,
                    servicesTextColor: doc.data()?.Services_Text_Color || "",
                    servicesFont: doc.data()?.Services_Font || "",
                    servicesFontSize: doc.data()?.Services_Font_Size || "",
                    servicesIconSize: doc.data()?.Services_Icon_Size || "",
                    servicesIcon: doc.data()?.Services_Icon != undefined ? doc.data()?.Services_Icon : true,

                    servicesDetails:
                      doc.data()?.Services_Details != undefined ? doc.data()?.Services_Details : true,
                    servicesDetailsColor: doc.data()?.Services_Details_Color || "",
                    servicesDetailsFont: doc.data()?.Services_Details_Font || "",
                    servicesDetailsFontSize: doc.data()?.Services_Details_Font_Size || "",

                    secServices:
                      doc.data()?.Sec_Services_Name != undefined ? doc.data()?.Sec_Services_Name : true,
                    secServicesColor: doc.data()?.Sec_Services_Name_Color || "",
                    secServicesFont: doc.data()?.Sec_Services_Name_Font || "",
                    secServicesFontSize: doc.data()?.Sec_Services_Name_Font_Size || "",

                    dateDisplay: doc.data()?.Date_Display != undefined ? doc.data()?.Date_Display : true,
                    dateBackground: doc.data()?.Date_Background || "",
                    dateColor: doc.data()?.Date_Color || "",
                    dateFont: doc.data()?.Date_Font || "",
                    dateFontSize: doc.data()?.Date_Font_Size || "",
                    dateSize: doc.data()?.Date_Size || "",

                    footerDisplay:
                      doc.data()?.Footer_Display != undefined ? doc.data()?.Footer_Display : true,
                    footerBackground: doc.data()?.Footer_Background || "",
                    footerColor: doc.data()?.Footer_Color || "",
                    footerFont: doc.data()?.Footer_Font || "",
                    footerFontSize: doc.data()?.Footer_Font_Size || "",
                    footerSize: doc.data()?.Footer_Size || "",
                    printingAnimation: doc.data()?.Printing_Animation || "",
                    sortingOrder: doc.data()?.Sorting_Order || {
                      label: "",
                      value: "",
                    },
                    kioskModel: doc.data()?.Kiosk_Model || {
                      label: "",
                      value: "",
                    },
                    macAddress: doc.data()?.MAC_Address || "",
                    selectedServices: doc.data()?.Selected_Services_To_Display || [],
                  });

                  printLogo.current.handleSetUrl(doc.data().Header_Image || "");
                  background.current.handleSetUrl(doc.data()?.Background_Url || "");

                  // ---------- Load Sub Location Data ---------- //
                  Firebase.firestore()
                    .collection("Sub_Locations")
                    .doc(doc.data().Sub_Location_ID)
                    .get()
                    .then(function (sub_location) {
                      if (sub_location.exists) {
                        setState({
                          location_image_url: sub_location.data().Icon,
                          location_name: sub_location.data().Name,
                          location_address: sub_location.data().Address,
                        });
                        loadServiceListBySub(doc.data().Sub_Location_ID);
                      } else {
                        setState({ loading: false });
                        notifyMessage("tc", 3, "Network error!");
                      }
                    })
                    .catch(function (err) {
                      setState({ loading: false });
                      notifyMessage("tc", 3, "Network error!");
                    });
                } else {
                  setState({ loading: false });
                  notifyMessage("tc", 3, "Network error!");
                }
              })
              .catch(function (err) {
                setState({ loading: false });
                notifyMessage("tc", 3, "Network error!");
              });
        } else {
          setState({ loading: false });
          notifyMessage("tc", 3, "Network error!");
        }
      })
      .catch(function (err) {
        setState({ loading: false });
        notifyMessage("tc", 3, "Network error!");
      });
  }
  function loadMainLocations() {
    setState({ loading: true });
    let main_locations = [];
    var customer_id = JSON.parse(localStorage.getItem("auth_info")).customer_id;
    setState({ customer_id: customer_id });
    // ---------- Load Max Height and Width ---------- //
    Firebase.firestore()
      .collection("System_Config")
      .doc("Dispensor_Settings")
      .get()
      .then(function (dispenser_setting) {
        if (dispenser_setting.exists) {
          setState({
            max_height: dispenser_setting.data().Dispensor_Max_Height,
            max_width: dispenser_setting.data().Dispensor_Max_Width,
            kioskModels: dispenser_setting.data()?.Kiosk_Models || [],
          });
          Firebase.firestore()
            .collection("Main_Locations")
            .where("Customer_ID", "==", customer_id)
            .get()
            .then(function (response) {
              response.docs.forEach(function (doc) {
                main_locations.push({ label: doc.data().Name, value: doc.id });
              });

              setState({ main_location_list: main_locations });
              if (main_locations.length > 0) {
                setState({ selected_main_location: main_locations[0] });
                loadSubLocationByMain(main_locations[0].value);
              } else {
                setState({ loading: false });
              }
            })
            .catch(function (err) {
              setState({ loading: false });
              notifyMessage("tc", 3, "Network error!");
              console.log("loadMainLocations NetworkError1==>", err);
            });
        } else {
          setState({ loading: false });
          notifyMessage("tc", 3, "Network error!");
          console.log("loadMainLocations NetworkError2");
        }
      })
      .catch(function (err) {
        setState({ loading: false });
        notifyMessage("tc", 3, "Network error!");
        console.log("loadMainLocations NetworkError3==>", err);
      });
  }
  function loadSubLocationByMain(main_id) {
    setState({ loading: true });
    setState({ selected_sub_location: null });
    let sub_locations = [];
    let customer_id = JSON.parse(localStorage.getItem("auth_info")).customer_id;
    Firebase.firestore()
      .collection("Sub_Locations")
      .where("Customer_ID", "==", customer_id)
      .where("Main_Location_ID", "==", main_id)
      .get()
      .then(function (response) {
        response.docs.forEach(function (doc) {
          sub_locations.push({
            label: doc.data().Name,
            value: doc.id,
            package_id: doc.data().Package_ID,
            image_url: doc.data().Icon,
            address: doc.data().Address,
          });
        });

        setState({ sub_location_list: sub_locations });
        if (sub_locations.length > 0) {
          let first_one = sub_locations[0];
          setState({ selected_sub_location: first_one });
          setState({ location_package_id: first_one.package_id });
          setState({ location_image_url: first_one.image_url });
          setState({ location_name: first_one.label });
          setState({ location_address: first_one.address });
          loadServiceListBySub(sub_locations[0].value);
        } else {
          setState({ loading: false });
          notifyMessage("tc", 3, "This main location does not have any sub locations.");
        }
      })
      .catch(function (err) {
        setState({ loading: false });
        notifyMessage("tc", 3, "Network error!");
        console.log("loadSubLocationByMain NetworkError4==>", err);
      });
  }
  function loadServiceListBySub(sub_id) {
    setState({ loading: true });
    let services = [];
    let customer_id = JSON.parse(localStorage.getItem("auth_info")).customer_id;
    Firebase.firestore()
      .collection("Services")
      .where("Customer_ID", "==", customer_id)
      .where("Sub_Location_ID", "==", sub_id)
      .get()
      .then(function (response) {
        response.docs.forEach(function (doc) {
          if (doc.data().Service_Type == "Token")
            services.push({
              service_id: doc.id,
              service_name: doc.data().Name,
              secService_name: doc.data()?.Secondary_Service_Name || "",
              service_details: doc.data().Details,
              icon: doc.data()?.Icon || "",
            });
        });

        setState({ service_list: services });
        if(state.add)setState({
          selectedServices: services.map((e) => e.service_id),
        });
        setState({ loading: false });
      })
      .catch(function (err) {
        setState({ loading: false });
        notifyMessage("tc", 3, "Network error!");
      });
  }
  function getCurrentTime() {
    let now = new Date();
    // setState({ current_time: now });
    // window.setTimeout(getCurrentTime, 1000);
  }
  async function handleSave() {
    setState({ saveTemp: true });
    // if (state?.kioskModel?.selfContained && state?.kioskModel?.wifi)
    //   setState({ printerIp: "127.0.0.1" });
    if (state.dispenser_nameState === "" && state.dispenser_name.length == 0) {
      setState({ dispenser_nameState: "has-danger" });
    }
    // if (state.printerIp === "") {
    //   setState({ loading: false });
    //   return notifyMessage("tc", 3, "Kiosk address is required.");
    // }

    // if (state.pinState === "") {
    //   setState({ pinState: "has-danger" });
    // }

    if (
      (state.printerIp?.length != 0 || state.macAddress?.length != 0) &&
      (state.dispenser_nameState === "has-success" || state.dispenser_name.length > 0) &&
      !state.invalid_height &&
      !state.invalid_width
    ) {
      try {
        setState({ saveTemp: false });
        let res = "",
          backgroundUrl1 = state.backgroundUrl,
          update_dispenser_data = {};

        let now = new Date();
        setState({ loading: true });

        let selectedServices = state.selectedServices.filter((e) => state.service_list.find(l=>l.service_id==e));
        // if (state.enable_print_logo) {
        if (printLogo.current?.state?.file) {
          let file = printLogo.current.state.file;
          if (file !== null) {
            var storageRef = Firebase.storage().ref();
            var image_name = "Media_Gallery/Kiosk_Media/" + state.dispenser_id + "/headerLogo";
            var subLocationRef = storageRef.child(image_name);
            let snapshot = await subLocationRef.put(file);
            // .then(function (snapshot) {
            res = await subLocationRef.getDownloadURL();
            // .then(function (res) {

            // })
            // .catch(function (err) {
            //   setState({ loading: false })
            //   notifyMessage('tc', 3, 'Network error!')
            // })
            // })
            // .catch(function (err) {
            //   setState({ loading: false })
            //   notifyMessage('tc', 3, 'Network error!')
            // })
          } else {
            setState({ loading: false });
            notifyMessage("tc", 3, "You must upload the print logo image.");
          }
        }
        // }
        if (background.current?.state?.file) {
          let file = background.current.state?.file;
          if (file !== null) {
            try {
              var storageRef = Firebase.storage().ref();
              var image_name = "Media_Gallery/Kiosk_Media/" + state.dispenser_id + "/background";
              var subLocationRef = storageRef.child(image_name);
              let snapshot = await subLocationRef.put(file);
              // .then(function (snapshot) {
              backgroundUrl1 = await subLocationRef.getDownloadURL();
              console.log(backgroundUrl1);
            } catch (e) {
              setState({ loading: false });
              notifyMessage("tc", 3, "You must upload the print logo image.");
              console.log(e);
            }
            // .then(function (res) {

            // })
            // .catch(function (err) {
            //   setState({ loading: false })
            //   notifyMessage('tc', 3, 'Network error!')
            // })
            // })
            // .catch(function (err) {
            //   setState({ loading: false })
            //   notifyMessage('tc', 3, 'Network error!')
            // })
          } else {
            setState({ loading: false });
            notifyMessage("tc", 3, "You must upload the print logo image.");
          }
        }

        update_dispenser_data = {
          PrinterIp:
            state?.kioskModel?.selfContained && state?.kioskModel?.wifi ? "127.0.0.1" : state.printerIp,
          PrinterPort: state.printerPort,
          Date_Format: state.dateFormat,
          Show_Location_Icon: state.show_location_icon,
          Footer_Text: state.footer_text,
          Button_Layout_Format: state.button_layout_format,
          Enable_Print_Logo: state.enable_print_logo,
          Header_Image: res || state.headerImage,
          Print_Type: state?.print_type?.value || "",
          Name: state.dispenser_name,
          Layout_Height: state.height,
          Layout_Width: state.width,
          Pin: parseInt(state.pin),
          Updated_Date: now,
          Background_Url: backgroundUrl1,
          Logo_Padding: state?.logoPadding || {},
          Services_Margin: state.servicesMargin || "",
          Services_Radius: state.servicesRadius || "",
          Services_Size: state.servicesSize || "",
          Default_Font: state.defaultFont || "",
          Background_Color: state.backgroundColor || "",
          Services_Background: state.servicesBackground || "",

          Services_Name: state.servicesName,
          Services_Text_Color: state.servicesTextColor || "",
          Services_Font: state.servicesFont || "",
          Services_Font_Size: state.servicesFontSize || "",

          Services_Icon_Size: state.servicesIconSize || "",
          Services_Icon: state.servicesIcon,

          Services_Details: state.servicesDetails,
          Services_Details_Color: state.servicesDetailsColor || "",
          Services_Details_Font: state.servicesDetailsFont || "",
          Services_Details_Font_Size: state.servicesDetailsFontSize || "",

          Sec_Services_Name: state.secServices,
          Sec_Services_Name_Color: state.secServicesColor || "",
          Sec_Services_Name_Font: state.secServicesFont || "",
          Sec_Services_Name_Font_Size: state.secServicesFontSize || "",

          Date_Display: state.dateDisplay,
          Date_Background: state.dateBackground || "",
          Date_Color: state.dateColor || "",
          Date_Font: state.dateFont || "",
          Date_Font_Size: state.dateFontSize || "",
          Date_Size: state.dateSize || "",

          Footer_Display: state.footerDisplay,
          Footer_Background: state.footerBackground || "",
          Footer_Color: state.footerColor || "",
          Footer_Font: state.footerFont || "",
          Footer_Font_Size: state.footerFontSize || "",
          Footer_Size: state.footerSize || "",
          Printing_Animation: state.printingAnimation || "",
          Sorting_Order: state.sortingOrder || "",

          Kiosk_Model: state.kioskModel,

          Connection_Type:
            (state?.kioskModel?.wifi && "Wifi") || (state?.kioskModel?.bluetooth && "Bluetooth") || "Wifi",
          MAC_Address: state.macAddress || "",
          Selected_Services_To_Display: selectedServices || [],
        };
        if (state.add) {
          update_dispenser_data = {
            ...update_dispenser_data,
            Created_Date: now,
            Customer_ID: state.customer_id,
            Main_Location_ID: state.selected_main_location.value,
            Main_Location_Name: state.selected_main_location.label,
            Sub_Location_ID: state.selected_sub_location.value,
            Sub_Location_Name: state.selected_sub_location.label,
            Sub_Location_Address: state.location_address,
            Package_ID: state.location_package_id,
            Name: state.dispenser_name,

            mainLocFont_Font: state.mainLocFontFont,
            subLocFont_Font: state.subLocFontFont,
            address_Font: state.addressFont,
            text1_Font: state.text1Font,
            text2_Font: state.text2Font,
            serviceName_Font: state.serviceNameFont,
            serviceDetails_Font: state.serviceDetailsFont,
            token_Font: state.tokenFont,
            tokensAhead_Font: state.tokensAheadFont,
            qrCode_Font: state.qrCodeFont,
            printDate_Font: state.printDateFont,

            Label_Date_Format: state.dateFormat,

            Print_Logo: state.printLogo,
            Print_Logo_Url: res || state.printLogoUrl,
            Print_Logo_Size: state.printLogoSize,
            MainLocation_Enable: state.mainLocationEnable,
            MainLocation_Font_Size: state.mainLocFontSize,
            MainLocation_Bold: state.mainLocBold,
            SubLocation_Enable: state.subLocationEnable,
            SubLocation_Font_Size: state.subLocFontSize,
            SubLocation_Bold: state.subLocBold,
            Address_Size: state.addressSize,
            Address_Bold: state.addressBold,

            Text1_Enable: state.text1Enable,
            Text1: state.text1,
            Text1_Size: state.text1Size,
            Text1_Bold: state.text1Bold,

            Text2_Enable: state.text2Enable,
            Text2: state.text2,
            Text2_Size: state.text2Size,
            Text2_Bold: state.text2Bold,
            Print_Header_Bottom: state.printHeaderBottom,

            Service_Name: state.serviceName,
            Service_Name_Size: state.serviceNameSize,
            Service_Name_Bold: state.serviceNameBold,

            Service_Details: state.serviceDetails,
            Service_Details_Size: state.serviceDetailsSize,
            Service_Details_Bold: state.serviceDetailsBold,
            Print_Service_Padding: state.printServicePadding,

            Token_Size: state.tokenSize,
            Token_Bold: state.tokenBold,
            Tokens_Ahead: state.tokenAhead,
            Tokens_Ahead_Size: state.tokensAheadSize,
            Tokens_Ahead_Bold: state.tokensAheadBold,

            Qr_Code: state.qrCode,
            Qr_Code_Size: state.qrCodeSize,
            Token_Bottom_Padding: state.tokenBottomPadding,

            Print_Date_Display: state.printDateDisplay,
            Print_Date_Size: state.printDateSize,
            Print_Date_Bold: state.printDateBold,

            Print_Footer_Display: state.printFooterDisplay,
            Print_Footer_Text: state.printFooterText,
            Print_Footer_Size: state.printFooterSize,

            Print_Footer_Background: state.printFooterBackground,
            Print_Footer_Text_Color: state.printFooterColor,
            Print_Footer_Font: state.printFooterFont,
            Print_Footer_Text_Size: state.printFooterTextSize,
          };
          console.log(update_dispenser_data);
          Firebase.firestore()
            .collection("Kiosk")
            .doc(state.dispenser_id)
            .set(update_dispenser_data)
            .then(function () {
              setState({ loading: false });
              notifyMessage("tc", 2, "Kiosk added successfully.");
              window.setTimeout(function () {
                props.history.push("/Kiosks");
              }, 2000);
            })
            .catch(function (error) {
              setState({ loading: false });
              console.log(error);
              notifyMessage("tc", 3, "Network error!");
            });
        } else
          Firebase.firestore()
            .collection("Kiosk")
            .doc(state.dispenser_id)
            .update(update_dispenser_data)
            .then(function () {
              setState({ loading: false });
              notifyMessage("tc", 2, "Kiosk saved successfully.");
              window.setTimeout(function () {
                props.history.push("/Kiosks");
              }, 2000);
            })
            .catch(function (error) {
              setState({ loading: false });
              console.log(error);
              notifyMessage("tc", 3, "Network error!");
            });
      } catch (e) {
        console.log(e);
        notifyMessage("tc", 3, e.message);
        setState({ loading: false });
      }
    } else {
      setState({ loading: false });
      notifyMessage("tc", 3, "Please fill the required fields");
    }
  }
  const change = (event, stateName, type, stateNameEqualTo) => {
    switch (type) {
      case "length":
        if (verifyLength(event.target.value, stateNameEqualTo)) {
          setState({ [stateName + "State"]: "has-success" });
        } else {
          setState({ [stateName + "State"]: "has-danger" });
        }

        setState({ [stateName]: event.target.value });
        break;
      case "pin":
        if (verifyLength(event.target.value, stateNameEqualTo) && verifyNumber(event.target.value)) {
          setState({ [stateName + "State"]: "has-success" });
        } else {
          setState({ [stateName + "State"]: "has-danger" });
        }

        if (verifyNumber(event.target.value) || event.target.value.length === 0) {
          setState({ [stateName]: event.target.value });
        }

        break;
      default:
        break;
    }
  };
  // useEffect(() => {
  //   console.log(state.service_list.filter((e) =>
  //   state.selectedServices.includes(e.service_id)
  // ),state.selectedServices)
  //   setState({
  //     selectedServicesObjects: state.service_list.filter((e) =>
  //       state.selectedServices.includes(e.service_id)
  //     ),
  //   });
  // }, [state.selectedServices]);
  function getServices() {
    let format = state.button_layout_format;
    let services = state.service_list;
    // let services = state.selectedServicesObjects;
    if (state.sortingOrder.value == "a-z")
      services.sort((a, b) => a.service_name.localeCompare(b.service_name));
    if (state.sortingOrder.value == "z-a")
      services.sort((a, b) => b.service_name.localeCompare(a.service_name));

    return services
      .filter((e) => state.selectedServices.includes(e?.service_id))
      .map((prop, key) => (
        <div
          key={key}
          className={
            format === "full" ? "col-11 " : format === "half" ? "col-5 " : "col-3 p-0" + " text-center"
          }
          style={{
            margin: "auto",
            marginBottom: state.servicesMargin > 0 ? state.servicesMargin + "px" : "1rem",
            marginLeft: state.servicesMargin > 0 ? state.servicesMargin + "px" : "auto",
            height: state.servicesSize + "px",
            borderRadius: state.servicesRadius + "px",
            background: state.servicesBackground,
          }}
        >
          <Row
            style={{}}
            // onClick={e => e.preventDefault()}
            className="h-100 mx-0"
          >
            {state.servicesIcon && prop.icon.length > 0 && (
              <Col className="align-self-center text-center">
                <img
                  className="mw-auto"
                  style={{
                    width: state.servicesIconSize + "px",
                    maxWidth: "initial",
                  }}
                  src={prop.icon}
                />
              </Col>
            )}
            {/* {(state.servicesName||state.secServices||state.servicesDetails)&&<Row>} */}
            <Col
              className={
                "align-self-center " +
                (!state.servicesName && !state.secServices && !state.servicesDetails && "d-none")
              }
            >
              {state.servicesName && (
                <Row className="p-1 align-self-center text-center">
                  <Col className="p-0">
                    <span
                      className="overflow-ellipsis font-weight-bold fs-15"
                      style={{
                        color: state.servicesTextColor,
                        fontSize: state.servicesFontSize + "px",
                        fontFamily: state.servicesFont,
                      }}
                    >
                      {prop.service_name}
                    </span>
                  </Col>
                </Row>
              )}
              {state.secServices && prop.secService_name.length > 0 && (
                <Row className="p-1 align-self-center text-center">
                  <Col className="p-0">
                    <span
                      className="overflow-ellipsis font-weight-bold fs-15"
                      style={{
                        color: state.secServicesColor,
                        fontSize: state.secServicesFontSize + "px",
                        fontFamily: state.secServicesFont,
                      }}
                    >
                      {prop.secService_name}
                    </span>
                  </Col>
                </Row>
              )}
              {/* <br /> */}
              {state.servicesDetails && prop.service_details.length > 0 && (
                <Row className="p-1 align-self-center text-center">
                  <Col className="p-0">
                    <span
                      className="overflow-ellipsis"
                      style={{
                        color: state.servicesDetailsColor,
                        fontSize: state.servicesDetailsFontSize + "px",
                        fontFamily: state.servicesDetailsFont,
                      }}
                    >
                      {prop.service_details}
                    </span>
                  </Col>
                </Row>
              )}
              {/* {(state.servicesName||state.secServices||state.servicesDetails)&&</Row>} */}
            </Col>
          </Row>
        </div>
      ));
  }
  const notifyMessage = (place, color, text) => {
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }

    var options = {};
    options = {
      place: place,
      message: (
        <div className="text-md-center">
          <div>
            <b>{text}</b>
          </div>
        </div>
      ),
      type: type,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 3,
    };
    if (options !== null && notificationAlert != null) {
      notificationAlert.current.notificationAlert(options);
    }
  };
  const verifyLength = (value, length) => {
    return value.length >= length;
  };
  const verifyNumber = (value) => {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  };
  function onChangeMain(e) {
    setState({ selected_main_location: e });
    loadSubLocationByMain(e.value);
  }
  function onChangeSub(e) {
    setState({ selected_sub_location: e });
    setState({ location_package_id: e.package_id });
    setState({ location_image_url: e.image_url });
    setState({ location_name: e.label });
    setState({ location_address: e.address });
    loadServiceListBySub(e.value);
  }
  // render () {
  let { dispenser_nameState, pinState } = state;
  return (
    <>
      <LoadingOverlay active={state.loading} spinner text="Loading" className="content dispenser">
        <NotificationAlert ref={notificationAlert} />
        {/* {state.kioskModel.value == "other" && (
          <ReactBSAlert
            title="Add Kiosk Model Info"
            style={{ transform: "scale(1.3)" }}
            className="row"
            onConfirm={() => {
              setState({ kioskAddErr: "" });
              let newModel = { ...state.kioskModel, label: state.kioskModel.name };
              if (state.kioskModel.wifi || state.kioskModel.bluetooth) {
                setState({ kioskModel: newModel, kioskAddErr: "loading" });
                newModel.value=state.kioskModel.name;
                delete newModel['name'];

                Firebase.firestore()
                  .collection("System_Config")
                  .doc("Dispensor_Settings")
                  .update({ Kiosk_Models: [...state.kioskModels, newModel] })
                  .then(() => {
                    setState({ kioskModel: newModel, kioskModels: [newModel, ...state.kioskModels],kioskAddErr:'' });
                  });
              } else setState({ kioskAddErr: "Please select at least one connection type (Wifi/Bluetooth)" });
            }}
            onCancel={() => {
              setState({ kioskModel: { label: "", value: "" } });
            }}
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            confirmBtnText="Add"
            cancelBtnText="Cancel"
            showCancel
            showConfirm={state.kioskAddErr != "loading"}>
            <Col>
              <Row className="text-danger my-3">{state.kioskAddErr!='loading'&&state.kioskAddErr}</Row>
              <Row>
                <Label lg="4" xs="3">
                  Name:
                </Label>
                <Col>
                  <Input
                    type="text"
                    onChange={(e) => {
                      setState({
                        kioskModel: { ...state.kioskModel, name: e.target.value },
                      });
                    }}
                  />
                </Col>
              </Row>
              <hr />
              <Row>
                <Label lg="6" xs="9">
                  Is Self Contained (Printer&Device):
                </Label>
                <Col className='align-self-center'>
                    <Input
                      type="checkbox"
                      onChange={(e) => {
                        setState({
                          kioskModel: { ...state.kioskModel, selfContained: e.target.checked },
                        });
                      }}
                    />
                </Col>
              </Row>
              <hr />
              <Row>
                <Label lg="4" xs="3">
                  Supports:
                </Label>
                <Col>
                  <Input
                    type="checkbox"
                    onChange={(e) => {
                      setState({
                        kioskModel: { ...state.kioskModel, wifi: e.target.checked },
                      });
                    }}
                  />
                  <Label>Wifi</Label>
                </Col>
                <Col>
                  <Input
                    type="checkbox"
                    onChange={(e) => {
                      setState({
                        kioskModel: { ...state.kioskModel, bluetooth: e.target.checked },
                      });
                    }}
                  />
                  <Label>Bluetooth</Label>
                </Col>
              </Row>
            </Col>
          </ReactBSAlert>
        )} */}
        {state.colorPick ? (
          <div
            style={{
              position: "absolute",
              top: "20%",
              left: "35%",
              zIndex: "2000",
            }}
          >
            <ColorPicker
              onChange={(e) => {
                let opacity =
                  e.alpha > 0
                    ? Number(e.alpha * 255)
                        .toString(16)
                        .split(".")[0]
                    : "00";
                setState({ [state.colorPlace]: e.hex + opacity });
                console.log(e, opacity);
              }}
            />
          </div>
        ) : (
          ""
        )}
        <Row
          onClick={() => {
            if (state.colorPick)
              setState({
                colorPick: false,
              });
          }}
        >
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Kiosk {state.add ? "Add" : "Edit"}</CardTitle>
              </CardHeader>
              <CardBody>
                <Row className="w-75">
                  <Col lg="1" xs="1"></Col>
                  <Col lg="4" xs="5">
                    <Button color="success" onClick={(e) => handleSave()} block>
                      {state.add ? "Add" : "Save"}
                    </Button>
                  </Col>
                  <Col lg="4" xs="5">
                    <Button color="youtube" onClick={(e) => props.history.push("/Kiosks")} block>
                      Close
                    </Button>
                  </Col>
                </Row>
                <Row style={{ overflow: "hidden" }}>
                  <Col
                    md="7"
                    xm="12"
                    className="border-right"
                    style={{
                      height: "100vh",
                      minHeight: "600px",
                      overflow: "hidden",
                      overflowY: "auto",
                    }}
                  >
                    {/* <Card className='border'>
                      <CardBody>
                        <Row>
                          <Col md='8' xs='8'>
                            <h6>General Screen Settings</h6>
                          </Col>
                          <Col md='4' xs='4' className='text-right'>
                            <Switch
                              className='left-margin-30'
                              defaultValue={state.show_location_icon}
                              value={state.show_location_icon}
                              offColor='success'
                              offText='OFF'
                              onColor='success'
                              onText='ON'
                              onChange={event =>
                                setState({
                                  show_location_icon: event.state.value
                                })
                              }
                            />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card> */}
                    <Card className="border">
                      <CardHeader className="h6 fw-bold">General</CardHeader>
                      <CardBody>
                        {/* <Row className='top-margin-10'>
                          <Label lg='4' xs='3'>
                            Dispenser ID
                          </Label>
                          <Col lg='8' xs='9'>
                            <FormGroup>
                              <Row>
                                <Col lg='8' xs='8'>
                                  <Input value={state.dispenser_id} disabled />
                                </Col>
                                <Col lg='4' xs='4'>
                                  <CopyToClipboard
                                    text={state.dispenser_id}
                                    onCopy={e =>
                                      notifyMessage(
                                        'tc',
                                        2,
                                        'Copied to clipboard'
                                      )
                                    }
                                  >
                                    <Button
                                      className='margin-0 btn btn-success text-capitalize'
                                      block
                                    >
                                      Copy
                                    </Button>
                                  </CopyToClipboard>
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                        </Row> */}
                        {state.add && (
                          <>
                            <Row>
                              <Label lg="4" xs="3">
                                Main Location
                              </Label>
                              <Col lg="8" xs="9">
                                <FormGroup>
                                  <Select
                                    className="react-select info select-location"
                                    classNamePrefix="react-select"
                                    placeholder="Select Main Location"
                                    name="selectMainLocation"
                                    value={state.selected_main_location}
                                    onChange={(e) => onChangeMain(e)}
                                    options={state.main_location_list}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Label lg="4" xs="3">
                                Sub Location
                              </Label>
                              <Col lg="8" xs="9">
                                <FormGroup>
                                  <Select
                                    className="react-select info select-location"
                                    classNamePrefix="react-select"
                                    placeholder="Select Sub Location"
                                    name="selectSubLocation"
                                    value={state.selected_sub_location}
                                    onChange={(e) => onChangeSub(e)}
                                    options={state.sub_location_list}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </>
                        )}
                        <Row>
                          <Label lg="4" xs="3">
                            Kiosk Name
                          </Label>
                          <Col lg="8" xs="9">
                            <FormGroup className={`has-label ${dispenser_nameState}`}>
                              <Row>
                                <Col lg="12" xs="12">
                                  <Input
                                    value={state.dispenser_name}
                                    type="text"
                                    onChange={(e) => change(e, "dispenser_name", "length", 1)}
                                  />
                                  {state.dispenser_nameState === "has-danger" ? (
                                    <label className="error">This field is required.</label>
                                  ) : null}
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Label lg="4" xs="3">
                            Screen Size(pixels)
                          </Label>
                          <Label lg="2" xs="3" sm="4">
                            Height
                          </Label>
                          <Col lg="2" xs="3" sm="4">
                            <Input
                              value={state.height}
                              type="number"
                              min={0}
                              max={state.max_height}
                              invalid={state.invalid_height}
                              onChange={(e) => {
                                if (e.target.value > state.max_height) setState({ invalid_height: true });
                                else setState({ invalid_height: false });

                                setState({
                                  height: e.target.value === "" ? 0 : parseInt(e.target.value),
                                });
                              }}
                            />
                          </Col>
                          <Label lg="2" xs="3" sm="4">
                            Width
                          </Label>
                          <Col lg="2" xs="3" sm="4">
                            <Input
                              value={state.width}
                              type="number"
                              min={0}
                              max={state.max_width}
                              invalid={state.invalid_width}
                              onChange={(e) => {
                                if (e.target.value > state.max_width) setState({ invalid_width: true });
                                else setState({ invalid_width: false });

                                setState({
                                  width: e.target.value === "" ? 0 : parseInt(e.target.value),
                                });
                              }}
                            />
                          </Col>
                        </Row>
                        {/* <Row>
                          <Label lg="4" xs="3">
                            Unlock Pin
                          </Label>
                          <Col lg="8" xs="9">
                            <FormGroup className={`has-label ${pinState}`}>
                              <Row>
                                <Col lg="5" xs="6">
                                  <Input value={state.pin} type={state.pin_type} maxLength={4} onChange={(e) => change(e, "pin", "pin", 4)} />
                                </Col>
                                <Col lg="5" xs="6">
                                  <Button
                                    className="margin-0 btn btn-info text-capitalize"
                                    onClick={(e) =>
                                      state.pin_type === "password"
                                        ? setState({
                                            pin_type: "text",
                                          })
                                        : setState({
                                            pin_type: "password",
                                          })
                                    }
                                    block>
                                    Show
                                  </Button>
                                </Col>
                                <Col lg="7" xs="5" />
                                {state.pinState === "has-danger" ? <label className="error">field is required to 4 digit number.</label> : null}
                              </Row>
                            </FormGroup>
                          </Col>
                        </Row> */}
                        <Row>
                          <Col>
                            <FormGroup>
                              <Row>
                                <Label lg="4" xs="3">
                                  Background Image
                                </Label>
                                <Col>
                                  <IconUpload
                                    ref={background}
                                    change={(e) => setState({ backgroundUrl: e })}
                                  />
                                  {/* <Input
                                    type="file"
                                    style={{
                                      position: "unset",
                                      opacity: "1",
                                    }}
                                    ref={background}
                                    onChange={(e) =>
                                      setState({
                                        backgroundUrl: e?.target?.files[0] ? URL.createObjectURL(e.target.files[0]) : "",
                                      })
                                    }
                                  /> */}
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Row>
                                <Label xs="4">Default Font</Label>
                                <Col>
                                  <Select
                                    // type='text'
                                    value={{
                                      label: state.defaultFont,
                                      value: state.defaultFont,
                                    }}
                                    // ref={background}
                                    onChange={(e) =>
                                      setState({
                                        defaultFont: e?.value,
                                      })
                                    }
                                    options={state.fontList}
                                  />
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                        </Row>
                        {/* background color */}
                        <Row>
                          <Col>
                            <FormGroup>
                              <Row>
                                <Label xs="4">Background Color</Label>
                                <Col xs="3">
                                  <div
                                    style={{
                                      background: state.backgroundColor || "white",
                                      width: "4vh",
                                      height: "4vh",
                                      verticalAlign: "middle",
                                      borderRadius: "3px",
                                      display: "inline-block",
                                      boxShadow: "0 0 3px 0px grey",
                                      margin: "auto 5% auto 0",
                                    }}
                                    className="point1 btn"
                                    onClick={() => {
                                      setState({
                                        colorPick: !state.colorPick,
                                        colorPlace: "backgroundColor",
                                      });
                                    }}
                                  ></div>
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row className="my-3">
                          <Label xs="4">Printing Animation Delay</Label>
                          <Col xs="5">
                            <Input
                              type="number"
                              value={Number(state.printingAnimation)}
                              min="0"
                              max="500"
                              onChange={(e) => setState({ printingAnimation: e.target.value })}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Label lg="4" xs="3">
                            Kiosk Details
                          </Label>
                          {/* </Row> */}
                          {/* <Row> */}
                          {/* <Label lg="2" xs="3" sm="4">
                                IP Address
                            </Label>
                            <Col>
                            <Label className={state.printerIp.length==0&&'has-danger error'}>
                                <Input
                                
                                value={state.printerIp}
                                type="text"
                                onChange={(e) => {
                                    setState({
                                    printerIp: e.target.value,
                                    });
                                }}
                                />
                                </Label>
                            </Col>
                            <Label lg="2" xs="3" sm="4">
                                Port Number
                            </Label>
                            <Col>
                            <Label>
                                <Input
                                value={state.printerPort}
                                type="text"
                                onChange={(e) => {
                                    setState({
                                    printerPort: e.target.value,
                                    });
                                }}
                                />
                                </Label>
                            </Col> */}

                          <Col lg="5" xs="6" sm="5">
                            <Select
                              placeholder="Select Kiosk Model"
                              value={state.kioskModel}
                              onChange={(e) => {
                                setState({ kioskModel: e });
                                if (e.selfContained && e.wifi) setState({ printerIp: "127.0.0.1" });
                              }}
                              options={[...state.kioskModels]}
                            />
                            {((state.printerIp.length == 0 && state?.kioskModel?.wifi) ||
                              (state.macAddress.length == 0 && state?.kioskModel?.bluetooth) ||
                              Object.keys(state?.kioskModel).length == 0) &&
                            state.saveTemp ? (
                              <label className="error text-danger">This field is required.</label>
                            ) : null}
                          </Col>
                        </Row>

                        {state.kioskModel?.label?.length > 0 && state.kioskModel.value != "other" && (
                          <Row>
                            {/* <Row className="align-items-center pt-3 ps-4"> */}
                            <Col lg="4" xs="3"></Col>
                            <Col>
                              <Row className="my-1">
                                <Label lg="6" xs="9">
                                  Is Self Contained (Printer&Device):
                                </Label>
                                <Col>
                                  <Label>
                                    <Input
                                      type="checkbox"
                                      disabled
                                      checked={state?.kioskModel?.selfContained || false}
                                    />
                                  </Label>
                                </Col>
                              </Row>
                              <Row className="my-1">
                                <Label lg="4" xs="3">
                                  Supports:
                                </Label>
                                <Col>
                                  <Input
                                    type="checkbox"
                                    disabled
                                    checked={state?.kioskModel?.wifi || false}
                                  />
                                  <Label>Wifi</Label>
                                </Col>
                                <Col>
                                  <Input
                                    type="checkbox"
                                    disabled
                                    checked={state?.kioskModel?.bluetooth || false}
                                  />
                                  <Label>Bluetooth</Label>
                                </Col>
                              </Row>
                              {state.kioskModel?.wifi && !state.kioskModel?.selfContained ? (
                                <>
                                  <Row className="my-1">
                                    <Label lg="4" xs="3">
                                      IP Address
                                    </Label>
                                    <Col>
                                      <Label
                                        className={(state.printerIp.length == 0 && "has-danger error") + ""}
                                      >
                                        <Input
                                          value={state.printerIp}
                                          type="text"
                                          onChange={(e) => {
                                            setState({
                                              printerIp: e.target.value,
                                            });
                                          }}
                                        />
                                      </Label>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Label lg="4" xs="3">
                                      Port Number
                                    </Label>
                                    <Col>
                                      <Input
                                        value={state.printerPort}
                                        type="text"
                                        onChange={(e) => {
                                          setState({
                                            printerPort: e.target.value,
                                          });
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                </>
                              ) : (
                                state.kioskModel?.bluetooth &&
                                !state.kioskModel?.wifi && (
                                  <Row className="my-1">
                                    <Label lg="4" xs="3">
                                      MAC Address
                                    </Label>
                                    <Col>
                                      <Label className={state.macAddress.length == 0 && "has-danger error"}>
                                        <Input
                                          value={state.macAddress}
                                          type="text"
                                          onChange={(e) => {
                                            setState({
                                              macAddress: e.target.value,
                                            });
                                          }}
                                        />
                                      </Label>
                                    </Col>
                                  </Row>
                                )
                              )}
                            </Col>
                          </Row>
                        )}
                      </CardBody>
                    </Card>
                    <Card>
                      <CardHeader className="h6 fw-bold">Header</CardHeader>
                      <CardBody>
                        <Row className="align-items-center">
                          <Label xs="3" sm="4">
                            Header Image
                          </Label>
                          <Col>
                            <IconUpload ref={printLogo} change={(e) => setState({ headerImage: e })} />
                          </Col>
                        </Row>
                        <Row className="my-2">
                          <Label xs="4">Padding</Label>
                          <Col>
                            <Row className="align-items-center">
                              <label g="4" xs="3">
                                Top
                              </label>
                              <Col>
                                <Input
                                  type="number"
                                  value={Number(state.logoPadding.top)}
                                  min="0"
                                  max="500"
                                  onChange={(e) =>
                                    setState({
                                      logoPadding: {
                                        ...state.logoPadding,
                                        top: e.target.value,
                                      },
                                    })
                                  }
                                />
                              </Col>
                              <label g="4" xs="3">
                                Bottom
                              </label>
                              <Col>
                                <Input
                                  type="number"
                                  value={Number(state.logoPadding.bottom)}
                                  min="0"
                                  max="500"
                                  onChange={(e) =>
                                    setState({
                                      logoPadding: {
                                        ...state.logoPadding,
                                        bottom: e.target.value,
                                      },
                                    })
                                  }
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <Card className="border">
                      <CardHeader className="h6 fw-bold">Services</CardHeader>
                      <CardBody>
                        <Row>
                          <Label xs="4">Layout</Label>
                        </Row>
                        <Row className="my-3">
                          {/* <Label lg="2" xs="2"/> */}
                          <Col className="col-4 text-center">
                            <div className="">
                              <Label className="">
                                <input
                                  checked={state.button_layout_format === "full"}
                                  style={{ transform: "scale(1.5)" }}
                                  name="layout"
                                  type="radio"
                                  onChange={(e) => {
                                    setState({
                                      button_layout_format: "full",
                                    });
                                  }}
                                />
                                {/* <span className='form-check-sign' /> */}
                              </Label>
                            </div>
                          </Col>
                          {/* <Label lg="1" xs="1"/> */}
                          <Col className="col-4 text-center">
                            <div className="">
                              <Label className="">
                                <input
                                  checked={state.button_layout_format === "half"}
                                  style={{ transform: "scale(1.5)" }}
                                  name="layout"
                                  type="radio"
                                  onChange={(e) => {
                                    setState({
                                      button_layout_format: "half",
                                    });
                                  }}
                                />
                                {/* <span className='form-check-sign' /> */}
                              </Label>
                            </div>
                          </Col>
                          {/* <Label lg="1" xs="1"/> */}
                          <Col className="col-4 text-center">
                            <div className="">
                              <Label className="">
                                <input
                                  checked={state.button_layout_format === "third"}
                                  style={{ transform: "scale(1.5)" }}
                                  name="layout"
                                  type="radio"
                                  onChange={(e) => {
                                    setState({
                                      button_layout_format: "third",
                                    });
                                  }}
                                />
                                {/* <span className='form-check-sign' /> */}
                              </Label>
                            </div>
                          </Col>
                        </Row>
                        <Row className="my-3">
                          <Col xs="4" className="px-4">
                            <Row className="justify-content-center">
                              <Col xs="10" className=" px-1">
                                <Button className="my-1" color="secondary" block />
                              </Col>
                            </Row>
                            <Row className="justify-content-center">
                              <Col xs="10" className=" px-1">
                                <Button className="my-1" color="secondary" block />
                              </Col>
                            </Row>
                            <Row className="justify-content-center">
                              <Col xs="10" className=" px-1">
                                <Button className="my-1" color="secondary" block />
                              </Col>
                            </Row>
                          </Col>
                          <Col xs="4" className="px-4">
                            <Row className="justify-content-center">
                              <Col xs="5" className=" px-1">
                                <Button className="my-1" color="secondary" block />
                              </Col>
                              <Col xs="5" className=" px-1">
                                <Button className="my-1" color="secondary" block />
                              </Col>
                            </Row>
                            <Row className="justify-content-center">
                              <Col xs="5" className=" px-1">
                                <Button className="my-1" color="secondary" block />
                              </Col>
                              <Col xs="5" className=" px-1">
                                <Button className="my-1" color="secondary" block />
                              </Col>
                            </Row>
                            <Row className="justify-content-center">
                              <Col xs="5" className=" px-1">
                                <Button className="my-1" color="secondary" block />
                              </Col>
                              <Col xs="5" className=" px-1">
                                <Button className="my-1" color="secondary" block />
                              </Col>
                            </Row>
                          </Col>
                          <Col xs="4" className="px-4">
                            <Row className="justify-content-center w-100 m-auto">
                              <Col className="overflow-hidden m-0 px-1">
                                <Button className="m-1 w-100 px-0" color="secondary" />
                              </Col>
                              <Col className="overflow-hidden m-0 px-1">
                                <Button className="m-1 w-100 px-0" color="secondary" />
                              </Col>
                              <Col className="overflow-hidden m-0 px-1">
                                <Button className="m-1 w-100 px-0" color="secondary" />
                              </Col>
                            </Row>
                            <Row className="justify-content-center w-100 m-auto">
                              <Col className="overflow-hidden m-0 px-1">
                                <Button className="m-1 w-100 px-0" color="secondary" />
                              </Col>
                              <Col className="overflow-hidden m-0 px-1">
                                <Button className="m-1 w-100 px-0" color="secondary" />
                              </Col>
                              <Col className="overflow-hidden m-0 px-1">
                                <Button className="m-1 w-100 px-0" color="secondary" />
                              </Col>
                            </Row>
                            <Row className="justify-content-center w-100 m-auto">
                              <Col className="overflow-hidden m-0 px-1">
                                <Button className="m-1 w-100 px-0" color="secondary" />
                              </Col>
                              <Col className="overflow-hidden m-0 px-1">
                                <Button className="m-1 w-100 px-0" color="secondary" />
                              </Col>
                              <Col className="overflow-hidden m-0 px-1">
                                <Button className="m-1 w-100 px-0" color="secondary" />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row className="my-3">
                          <Label xs="4">Margin</Label>
                          <Col>
                            <input
                              type="range"
                              value={Number(state.servicesMargin)}
                              min="0"
                              max="500"
                              onChange={(e) => setState({ servicesMargin: e.target.value })}
                            />
                          </Col>
                        </Row>
                        <Row className="my-3">
                          <Label xs="4">Color</Label>
                          <Col xs="4">
                            <div
                              style={{
                                background: state.servicesBackground,
                                width: "4vh",
                                height: "4vh",
                                verticalAlign: "middle",
                                borderRadius: "3px",
                                display: "inline-block",
                                boxShadow: "0 0 3px 0px grey",
                                margin: "auto 5% auto 0",
                              }}
                              className="point1 btn"
                              onClick={() => {
                                setState({
                                  colorPick: !state.colorPick,
                                  colorPlace: "servicesBackground",
                                });
                              }}
                            ></div>
                          </Col>
                        </Row>
                        <Row className="my-3">
                          <Label xs="4">Corner Radius (px)</Label>
                          <Col>
                            <Input
                              type="number"
                              value={Number(state.servicesRadius)}
                              min="0"
                              max="500"
                              onChange={(e) => setState({ servicesRadius: e.target.value })}
                            />
                          </Col>
                        </Row>
                        <Row className="my-3">
                          <Label xs="4">Size</Label>
                          <Col>
                            <input
                              type="range"
                              value={Number(state.servicesSize)}
                              min="0"
                              max="500"
                              onChange={(e) => setState({ servicesSize: e.target.value })}
                            />
                          </Col>
                        </Row>
                        <Row className="my-3">
                          <Label xs="4">Services Sorting Order</Label>
                          <Col>
                            <Select
                              className="react-select info select-location"
                              classNamePrefix="react-select"
                              placeholder="Select Main Location"
                              name="selectMainLocation"
                              value={state.sortingOrder}
                              onChange={(e) => setState({ sortingOrder: e })}
                              options={[
                                { label: "List order", value: "" },
                                { label: "Alphabet (A-Z)", value: "a-z" },
                                { label: "Alphabet (Z-A)", value: "z-a" },
                              ]}
                            />
                          </Col>
                        </Row>
                        {/* service name */}
                        <Row className="my-3">
                          <Label xs="4">Service Name</Label>
                          <Col>
                            <Switch
                              value={state.servicesName}
                              // offColor="success"
                              // offText="OFF"
                              // onColor="success"
                              // onText="ON"
                              onChange={(e) => setState({ servicesName: e.state.value })}
                            />
                          </Col>
                        </Row>
                        <Row className="my-3">
                          <Col xs="4"></Col>
                          <Col>
                            <Row>
                              <Label xs="4">Text Color</Label>
                              <Col>
                                <div
                                  style={{
                                    background: state.servicesTextColor || "white",
                                    width: "4vh",
                                    height: "4vh",
                                    verticalAlign: "middle",
                                    borderRadius: "3px",
                                    display: "inline-block",
                                    boxShadow: "0 0 3px 0px grey",
                                    margin: "auto 5% auto 0",
                                  }}
                                  className="point1 btn"
                                  onClick={() => {
                                    setState({
                                      colorPick: !state.colorPick,
                                      colorPlace: "servicesTextColor",
                                    });
                                  }}
                                ></div>
                              </Col>
                            </Row>
                            <Row className="my-3">
                              <Label xs="4">Font</Label>
                              <Col>
                                {/* <Input
                                  type='text'
                                  value={state.servicesFont}
                                  onChange={e =>
                                    setState({
                                      servicesFont: e.target.value
                                    })
                                  }
                                /> */}
                                <Select
                                  // type='text'
                                  value={{
                                    label: state.servicesFont,
                                    value: state.servicesFont,
                                  }}
                                  // ref={background}
                                  onChange={(e) =>
                                    setState({
                                      servicesFont: e?.value,
                                    })
                                  }
                                  options={state.fontList}
                                />
                              </Col>
                            </Row>
                            <Row className="my-3">
                              <Label xs="4">Font Size</Label>
                              <Col>
                                <Input
                                  type="range"
                                  value={Number(state.servicesFontSize)}
                                  min="0"
                                  max="500"
                                  onChange={(e) =>
                                    setState({
                                      servicesFontSize: e.target.value,
                                    })
                                  }
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row className="my-3">
                          <Label xs="4">Service Icon</Label>
                          <Col>
                            <Switch
                              value={state.servicesIcon}
                              // offColor="success"
                              // offText="OFF"
                              // onColor="success"
                              // onText="ON"
                              onChange={(e) => setState({ servicesIcon: e.state.value })}
                            />
                          </Col>
                        </Row>
                        <Row className="my-3">
                          <Col xs="4"></Col>
                          <Col>
                            <Row className="my-3">
                              <Label xs="4">Size</Label>
                              <Col>
                                <Input
                                  type="range"
                                  value={Number(state.servicesIconSize)}
                                  min="0"
                                  max="500"
                                  onChange={(e) =>
                                    setState({
                                      servicesIconSize: e.target.value,
                                    })
                                  }
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row className="my-3">
                          <Label xs="4">Secondary Service Name</Label>
                          <Col>
                            <Switch
                              value={state.secServices}
                              // offColor="success"
                              // offText="OFF"
                              // onColor="success"
                              // onText="ON"
                              onChange={(e) => setState({ secServices: e.state.value })}
                            />
                          </Col>
                        </Row>
                        <Row className="my-3">
                          <Col xs="4"></Col>
                          <Col>
                            <Row>
                              <Label xs="4">Text Color</Label>
                              <Col>
                                <div
                                  style={{
                                    background: state.secServicesColor || "white",
                                    width: "4vh",
                                    height: "4vh",
                                    verticalAlign: "middle",
                                    borderRadius: "3px",
                                    display: "inline-block",
                                    boxShadow: "0 0 3px 0px grey",
                                    margin: "auto 5% auto 0",
                                  }}
                                  className="point1 btn"
                                  onClick={() => {
                                    setState({
                                      colorPick: !state.colorPick,
                                      colorPlace: "secServicesColor",
                                    });
                                  }}
                                ></div>
                              </Col>
                            </Row>
                            <Row className="my-3">
                              <Label xs="4">Font</Label>
                              <Col>
                                {/* <Input
                                  type='text'
                                  value={state.secServicesFont}
                                  onChange={e =>
                                    setState({
                                      secServicesFont: e.target.value
                                    })
                                  }
                                /> */}
                                <Select
                                  // type='text'
                                  value={{
                                    label: state.secServicesFont,
                                    value: state.secServicesFont,
                                  }}
                                  // ref={background}
                                  onChange={(e) =>
                                    setState({
                                      secServicesFont: e?.value,
                                    })
                                  }
                                  options={state.fontList}
                                />
                              </Col>
                            </Row>
                            <Row className="my-3">
                              <Label xs="4">Font Size</Label>
                              <Col>
                                <Input
                                  type="range"
                                  value={Number(state.secServicesFontSize)}
                                  min="0"
                                  max="500"
                                  onChange={(e) =>
                                    setState({
                                      secServicesFontSize: e.target.value,
                                    })
                                  }
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row className="my-3">
                          <Label xs="4">Service Details</Label>
                          <Col>
                            <Switch
                              value={state.servicesDetails}
                              // offColor="success"
                              // offText="OFF"
                              // onColor="success"
                              // onText="ON"
                              onChange={(e) => setState({ servicesDetails: e.state.value })}
                            />
                          </Col>
                        </Row>
                        <Row className="my-3">
                          <Col xs="4"></Col>
                          <Col>
                            <Row>
                              <Label xs="4">Text Color</Label>
                              <Col>
                                <div
                                  style={{
                                    background: state.servicesDetailsColor || "white",
                                    width: "4vh",
                                    height: "4vh",
                                    verticalAlign: "middle",
                                    borderRadius: "3px",
                                    display: "inline-block",
                                    boxShadow: "0 0 3px 0px grey",
                                    margin: "auto 5% auto 0",
                                  }}
                                  className="point1 btn"
                                  onClick={() => {
                                    setState({
                                      colorPick: !state.colorPick,
                                      colorPlace: "servicesDetailsColor",
                                    });
                                  }}
                                ></div>
                              </Col>
                            </Row>
                            <Row className="my-3">
                              <Label xs="4">Font</Label>
                              <Col>
                                {/* <Input
                                  type='text'
                                  value={state.servicesDetailsFont}
                                  onChange={e =>
                                    setState({
                                      servicesDetailsFont: e.target.value
                                    })
                                  }
                                /> */}
                                <Select
                                  // type='text'
                                  value={{
                                    label: state.servicesDetailsFont,
                                    value: state.servicesDetailsFont,
                                  }}
                                  // ref={background}
                                  onChange={(e) =>
                                    setState({
                                      servicesDetailsFont: e?.value,
                                    })
                                  }
                                  options={state.fontList}
                                />
                              </Col>
                            </Row>
                            <Row className="my-3">
                              <Label xs="4">Font Size</Label>
                              <Col>
                                <Input
                                  type="range"
                                  value={Number(state.servicesDetailsFontSize)}
                                  min="0"
                                  max="500"
                                  onChange={(e) =>
                                    setState({
                                      servicesDetailsFontSize: e.target.value,
                                    })
                                  }
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row className="my-3">
                          <Label xs="4">Service Selection</Label>
                          <Col>
                            {state.service_list.map((e) => (
                              <Row>
                                <Col>{e.service_name}</Col>
                                <Col>
                                  <Switch
                                    value={state.selectedServices.includes(e?.service_id)}
                                    // offColor="success"
                                    // offText="OFF"
                                    // ref={
                                    //   state.add
                                    //     ? state.selectedServices.length!=state.service_list.length
                                    //       ? setState({
                                    //           selectedServices: state.selectedServices.concat(e.service_id),
                                    //         })
                                    //       : null
                                    //     : null
                                    // }
                                    // onColor="success"
                                    // onText="ON"
                                    onChange={(i) => {
                                      // setState({
                                      //   selectedServices: [],
                                      // });
                                      i.state.value
                                        ? setState({
                                            selectedServices: state.selectedServices.concat(e.service_id),
                                          })
                                        : setState({
                                            selectedServices: state.selectedServices.filter(
                                              (s) => s != e.service_id
                                            ),
                                          });
                                    }}
                                  />
                                </Col>
                              </Row>
                            ))}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <Card className="border">
                      <CardHeader className="h6 fw-bold">
                        <h6>Footer</h6>
                      </CardHeader>
                      <CardBody>
                        <Row className="my-3">
                          <Label xs="4">Date Display</Label>
                          <Col>
                            <Switch
                              value={state.dateDisplay}
                              // offColor="success"
                              // offText="OFF"
                              // onColor="success"
                              // onText="ON"
                              onChange={(e) => setState({ dateDisplay: e.state.value })}
                            />
                          </Col>
                        </Row>
                        <Row className="my-3">
                          <Col xs="4"></Col>
                          <Col>
                            <Row className="my-3">
                              <Label xs="4">
                                Format <small>(Createable)</small>
                              </Label>
                              <Col>
                                <CreatableSelect
                                  className="react-select info select-location"
                                  classNamePrefix="react-select"
                                  placeholder="Select Main Location"
                                  name="selectMainLocation"
                                  value={{
                                    label: state.dateFormat,
                                    value: state.dateFormat,
                                  }}
                                  onChange={(e) => {
                                    console.log(e);
                                    setState({ dateFormat: e.value });
                                  }}
                                  options={[
                                    // { label: "Select Format", value: "" },
                                    { label: "d-m-yyyy", value: "d-m-yyyy" },
                                    {
                                      label: "dddd-mmmm-yyyy",
                                      value: "dddd-mmmm-yyyy",
                                    },
                                    {
                                      label: "d-m-yyyy-h:MM:ss TT",
                                      value: "d-m-yyyy-h:MM:ss TT",
                                    },
                                    { label: "m-d-yyyy", value: "m-d-yyyy" },
                                  ]}
                                />
                              </Col>
                            </Row>
                            <Row className="my-3">
                              <Label xs="4">Size</Label>
                              <Col>
                                <Input
                                  type="range"
                                  value={Number(state.dateSize)}
                                  min="0"
                                  max="500"
                                  onChange={(e) =>
                                    setState({
                                      dateSize: e.target.value,
                                    })
                                  }
                                />
                              </Col>
                            </Row>
                            <Row className="pb-2">
                              <Label xs="4">Background Color</Label>
                              <Col>
                                <div
                                  style={{
                                    background: state.dateBackground || "white",
                                    width: "4vh",
                                    height: "4vh",
                                    verticalAlign: "middle",
                                    borderRadius: "3px",
                                    display: "inline-block",
                                    boxShadow: "0 0 3px 0px grey",
                                    margin: "auto 5% auto 0",
                                  }}
                                  className="point1 btn"
                                  onClick={() => {
                                    setState({
                                      colorPick: !state.colorPick,
                                      colorPlace: "dateBackground",
                                    });
                                  }}
                                ></div>
                              </Col>
                            </Row>
                            <Row>
                              <Label xs="4">Text Color</Label>
                              <Col>
                                <div
                                  style={{
                                    background: state.dateColor || "white",
                                    width: "4vh",
                                    height: "4vh",
                                    verticalAlign: "middle",
                                    borderRadius: "3px",
                                    display: "inline-block",
                                    boxShadow: "0 0 3px 0px grey",
                                    margin: "auto 5% auto 0",
                                  }}
                                  className="point1 btn"
                                  onClick={() => {
                                    setState({
                                      colorPick: !state.colorPick,
                                      colorPlace: "dateColor",
                                    });
                                  }}
                                ></div>
                              </Col>
                            </Row>
                            <Row className="my-3">
                              <Label xs="4">Font</Label>
                              <Col>
                                {/* <Input
                                  type='text'
                                  value={state.dateFont}
                                  onChange={e =>
                                    setState({
                                      dateFont: e.target.value
                                    })
                                  }
                                /> */}
                                <Select
                                  // type='text'
                                  value={{
                                    label: state.dateFont,
                                    value: state.dateFont,
                                  }}
                                  // ref={background}
                                  onChange={(e) =>
                                    setState({
                                      dateFont: e?.value,
                                    })
                                  }
                                  options={state.fontList}
                                />
                              </Col>
                            </Row>
                            <Row className="my-3">
                              <Label xs="4">Font Size</Label>
                              <Col>
                                <Input
                                  type="range"
                                  value={Number(state.dateFontSize)}
                                  min="0"
                                  max="500"
                                  onChange={(e) =>
                                    setState({
                                      dateFontSize: e.target.value,
                                    })
                                  }
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row className="my-3">
                          <Label xs="4">Footer Text</Label>
                          <Col>
                            <Switch
                              value={state.footerDisplay}
                              // offColor="success"
                              // offText="OFF"
                              // onColor="success"
                              // onText="ON"
                              onChange={(e) => setState({ footerDisplay: e.state.value })}
                            />
                          </Col>
                        </Row>
                        <Row className="my-3">
                          <Col xs="4"></Col>
                          <Col>
                            <Row>
                              <Label xs="4">Text</Label>
                              <Col>
                                <Input
                                  value={state.footer_text}
                                  type="text"
                                  onChange={(e) => {
                                    setState({
                                      footer_text: e.target.value,
                                    });
                                  }}
                                />
                              </Col>
                            </Row>
                            <Row className="my-3">
                              <Label xs="4">Size</Label>
                              <Col>
                                <Input
                                  type="range"
                                  value={Number(state.footerSize)}
                                  min="0"
                                  max="500"
                                  onChange={(e) =>
                                    setState({
                                      footerSize: e.target.value,
                                    })
                                  }
                                />
                              </Col>
                            </Row>
                            <Row className="pb-2">
                              <Label xs="4">Background Color</Label>
                              <Col>
                                <div
                                  style={{
                                    background: state.footerBackground || "white",
                                    width: "4vh",
                                    height: "4vh",
                                    verticalAlign: "middle",
                                    borderRadius: "3px",
                                    display: "inline-block",
                                    boxShadow: "0 0 3px 0px grey",
                                    margin: "auto 5% auto 0",
                                  }}
                                  className="point1 btn"
                                  onClick={() => {
                                    setState({
                                      colorPick: !state.colorPick,
                                      colorPlace: "footerBackground",
                                    });
                                  }}
                                ></div>
                              </Col>
                            </Row>
                            <Row>
                              <Label xs="4">Text Color</Label>
                              <Col>
                                <div
                                  style={{
                                    background: state.footerColor || "white",
                                    width: "4vh",
                                    height: "4vh",
                                    verticalAlign: "middle",
                                    borderRadius: "3px",
                                    display: "inline-block",
                                    boxShadow: "0 0 3px 0px grey",
                                    margin: "auto 5% auto 0",
                                  }}
                                  className="point1 btn"
                                  onClick={() => {
                                    setState({
                                      colorPick: !state.colorPick,
                                      colorPlace: "footerColor",
                                    });
                                  }}
                                ></div>
                              </Col>
                            </Row>
                            <Row className="my-3">
                              <Label xs="4">Font</Label>
                              <Col>
                                {/* <Input
                                  type='text'
                                  value={state.footerFont}
                                  onChange={e =>
                                    setState({
                                      footerFont: e.target.value
                                    })
                                  }
                                /> */}
                                <Select
                                  // type='text'
                                  value={{
                                    label: state.footerFont,
                                    value: state.footerFont,
                                  }}
                                  // ref={background}
                                  onChange={(e) =>
                                    setState({
                                      footerFont: e?.value,
                                    })
                                  }
                                  options={state.fontList}
                                />
                              </Col>
                            </Row>
                            <Row className="my-3">
                              <Label xs="4">Font Size</Label>
                              <Col>
                                <Input
                                  type="range"
                                  value={Number(state.footerFontSize)}
                                  min="0"
                                  max="500"
                                  onChange={(e) =>
                                    setState({
                                      footerFontSize: e.target.value,
                                    })
                                  }
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    {/* <Card className='border'>
                        <CardBody>
                          <Row>
                            <Col lg='12'>
                              <h6>Logo and Printer</h6>
                            </Col>
                            <Col
                              md='8'
                              xs='8'
                              hidden={!state.enable_print_logo}
                            >
                            </Col>
                            <Col
                              md='8'
                              xs='8'
                              hidden={state.enable_print_logo}
                            />
                            <Col md='4' xs='4' className='text-right'>
                              <Switch
                                className='left-margin-30'
                                defaultValue={state.enable_print_logo}
                                value={state.enable_print_logo}
                                offColor='success'
                                offText='OFF'
                                onColor='success'
                                onText='ON'
                                onChange={event =>
                                  setState({
                                    enable_print_logo: event.state.value
                                  })
                                }
                              />
                            </Col>
                            <Col lg='12' className='top-margin-10' />
                            <Label lg='3' xs='3'>
                              Printer Type
                            </Label>
                            <Col lg='9' xs='9'>
                              <FormGroup>
                                <Select
                                  className='react-select info'
                                  classNamePrefix='react-select'
                                  placeholder='Select Print Type'
                                  name='selectPrintType'
                                  value={state.print_type}
                                  onChange={e =>
                                    setState({ print_type: e })
                                  }
                                  options={state.print_type_list}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card> */}
                  </Col>
                  <Col md="5" xm="12" className="ps-0" style={{ height: "100vh", overflow: "scroll" }}>
                    <Card className="border d-inline">
                      <CardBody>
                        <Form className="form-horizontal" style={{ height: "100vh" }}>
                          <Row
                            className="justify-content-center"
                            style={{
                              width: state.width + "px",
                              transform: "scale(0.7)",
                              transformOrigin: "left top",
                            }}
                          >
                            {/* <Col lg='1' xs='1' /> */}
                            <Col lg="12" xs="12" className="p-0">
                              <div
                                className="border card"
                                style={{
                                  background: `url(${state.backgroundUrl}) center center / cover no-repeat`,
                                  //   backgroundPosition:' center center',
                                  // backgroundSize: "cover",
                                  fontFamily: state.defaultFont,
                                  backgroundColor: state.backgroundColor,
                                }}
                              >
                                <div style={{}}>
                                  <div className="">
                                    <div
                                      className="row w-100 m-0"
                                      style={{
                                        // height: "1180px",
                                        height: state.height + "px",
                                      }}
                                    >
                                      <div
                                        className="col-lg-12 col-xs-12 text-center"
                                        style={{
                                          paddingTop: state.logoPadding.top + "px",
                                          paddingBottom: state.logoPadding.bottom + "px",
                                        }}
                                      >
                                        {(state.headerImage.length > 0 || printLogo.current?.state?.file) && (
                                          <img src={state.headerImage} alt="..." className="width-20" />
                                        )}
                                      </div>
                                      {/* <div className='col-lg-12 col-xs-12 text-center top-margin-12'>
                                        <h5>{state.location_name}</h5>
                                        <label>{state.location_address}</label>
                                      </div> */}
                                      {/* <div className='col-lg-12 col-xs-12'>
                                        <hr />
                                      </div> */}
                                      <Col className="align-self-center">
                                        <Row className="justify-content-center w-100 m-auto">
                                          {getServices()}
                                        </Row>
                                      </Col>
                                      <div className="col-lg-12 col-xs-12 px-0 align-self-end">
                                        {state.dateDisplay && (
                                          <button
                                            className="btn btn-block btn-lg text-center m-0 rounded-0"
                                            disabled
                                            style={{
                                              color: state.dateColor,
                                              fontSize: state.dateFontSize + "px",
                                              fontFamily: state.dateFont,
                                              background: state.dateBackground,
                                              opacity: 1,
                                              height: state.dateSize + "px",
                                            }}
                                          >
                                            <span className="text-capitalize">
                                              {dateFormat(state.current_time, state.dateFormat)}
                                            </span>
                                          </button>
                                        )}
                                        {state.footerDisplay && (
                                          <button
                                            className="btn btn-block btn-lg text-center m-0  rounded-0"
                                            disabled
                                            style={{
                                              color: state.footerColor || "white",
                                              fontSize: state.footerFontSize + "px",
                                              fontFamily: state.footerFont,
                                              background: state.footerBackground || "grey",
                                              opacity: 1,
                                              height: state.footerSize + "px" || "",
                                            }}
                                          >
                                            <span className="text-capitalize">
                                              {state.footer_text === "" ? "\u00A0" : state.footer_text}
                                            </span>
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </LoadingOverlay>
    </>
  );
  // }
}

export default KioskAddEdit;
