import React from "react";
import { DateRangePicker } from 'react-dates';
import LoadingOverlay from "react-loading-overlay";
import ReactTable from "react-table-6";
import Select from "react-select";
import ReactBSAlert from "react-bootstrap-sweetalert";
import NotificationAlert from "react-notification-alert";
import {
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    FormGroup,
    Label,
    Modal,
    Input,
    Button
} from "reactstrap";

import Firebase from "firebase";
import { ExportReactCSV } from '../../../components/ExportReactCSV'
import PaymentForm from "../../../components/PaymentForm";

import DateRangePickerWrapper from "../../../components/DateRangePickerWrapper";

class BillingAlerts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            alert: null,
            loading: false,
            data: [],

            subscriptions: [],
            invoices: [],

            startDate: null,
            endDate: null,
            focusedInput: null,

            sub_location: null,
            sub_location_list: [],

            failed_payments: [],
        };
        this.auth_info = {};
        // this.functions = null;
        // this.fbStore = null;
    }

    componentDidMount() {
        this.auth_info = JSON.parse(localStorage.getItem('auth_info')) || {};
        // this.initializeFirebase();
        this.fetchCustomerInfo();
        this.fetchSubLocationsList();

        // Firebase.functions().httpsCallable('stripe_listCustomerInfo')({ customer_id, sub_location: sub_location?.value, startDate: startDate ? startDate.format("X") : null, endDate: endDate ? endDate.add(1, 'days').format("X") : null })
        //     .then((response) => {
        //         console.log('fetchCustomerInfo', {customer_id, response});
        //         const res = response.data;
        //         if (res.status === 'ok') {
        //             const { subscriptions, invoices } = res.data;
        //             const failed_payments = this.filterFailedPaymentSubscriptions(subscriptions, invoices);
        //             console.log({failed_payments});
        //             this.setState({ failed_payments, subscriptions, invoices })
        //         }
        //         this.setState({loading: false});
        //     })
        //     .catch((error) => {
        //         this.setState({loading: false});
        //         console.log('fetchCustomerInfo', {error});
        //     });
    }

    componentWillUnmount() {

    }

    componentDidUpdate(e) {

    }

    // initializeFirebase = () => {
    //   if (!this.functions) {
    //     this.functions = Firebase.functions();
    //     // this.functions.useFunctionsEmulator('http://localhost:5001');
    //     this.fbStore = Firebase.firestore();
    //   }
    // }

    toggleModalClassic = () => {
        this.setState({
            modalClassic: !this.state.modalClassic
        });
    };

    fetchSubLocationsList = async () => {
        const { customer_id, email } = this.auth_info || {};

        let accessible_locations = [];
        let sub_locations = [{ label: 'Select All', value: -1, image_url: '', address: '' }];
        try {
            const app_info = await Firebase.firestore().collection('Web_App_Users').doc(email).get();
            if (app_info.exists) {
                accessible_locations = app_info.data().Accessible_Locations;
                //console.log("accessible locations:",accessible_locations);
                // console.log('user-json', JSON.stringify(app_info.data()));
            }
        } catch (ex1) {
            console.log("loadSubLocation NetworkError2==>", ex1);
        }

        try {
            const response = await Firebase.firestore().collection('Sub_Locations').where('Customer_ID', '==', customer_id).get();
            response.docs.forEach(function (doc) {
                if (accessible_locations.includes(doc.id)) {
                    const { Name, Icon, Address } = doc.data();
                    sub_locations.push({ label: Name, value: doc.id, image_url: Icon, address: Address });
                }
            });
            this.setState({
                sub_location_list: sub_locations
            });
        } catch (ex2) {
            console.log("loadSubLocationByMain NetworkError2==>", ex2);
        }
    }

    fetchCustomerInfo = () => {
        const { customer_id, email } = this.auth_info || {};
        const { sub_location, startDate, endDate } = this.state;

        let sub_location_id = sub_location?.value;
        if (sub_location_id == -1) {
            sub_location_id = null;
        }
        this.setState({ loading: true });
        Firebase.functions().httpsCallable('stripe_listCustomerInfo')({ customer_id, sub_location: sub_location_id, startDate: startDate ? startDate.format("X") : null, endDate: endDate ? endDate.add(1, 'days').format("X") : null })
            .then((response) => {
                console.log('fetchCustomerInfo>>>1 customer_id, response', { customer_id, response });
                const res = response.data;
                if (res.status === 'ok') {
                    const { subscriptions, invoices } = res.data;
                    const failed_payments = this.filterFailedPaymentSubscriptions(subscriptions, invoices);
                    console.log('subscriptions', subscriptions);
                    console.log('fetchCustomerInfo>>>2 failed_payments:', failed_payments);
                    this.setState({ failed_payments, subscriptions, invoices });
                }
                this.setState({ loading: false });
            })
            .catch((error) => {
                this.setState({ loading: false });
                console.log('fetchCustomerInfo>>>3 error', { error });
            });
    }

    filterFailedPaymentSubscriptions = (subscriptions, invoices) => {
        //possible status values => incomplete, incomplete_expired, trialing, active, past_due, canceled, unpaid
        const failed_payments = subscriptions.filter(subscription => {
            return subscription.status != 'active' && subscription.status != 'trialing' && subscription.status != 'canceled';
        });
        console.log("filterFailedPaymentSubscriptions>>>1 failed_payments", failed_payments)
        return failed_payments.slice(Math.max(failed_payments.length - 3, 0));
    };

    // fetchBillingSubscriptions = () => {
    //     const { email } = this.auth_info || {};
    //     const { sub_location } = this.state;

    //     this.setState({loading: true});
    //     Firebase.functions().httpsCallable('stripe_listBillingSubscriptions')({ email, sublocation: sub_location?.value })
    //         .then((response) => {
    //             console.log('fetchBillingSubscriptions', {email, response});
    //             const res = response.data;
    //             if (res.status === 'ok') {
    //                 const { subscriptions, invoices } = res.data;
    //                 this.setState({ subscriptions, invoices })
    //             }
    //             this.setState({loading: false});
    //         })
    //         .catch((error) => {
    //             this.setState({loading: false});
    //             console.log('fetchBillingSubscriptions', {error});
    //         });
    // }

    fetchBillingInvoiceList = () => {
        const { sub_location, startDate, endDate, selected } = this.state;
        const subscription = selected?.original.id;

        let sub_location_id = sub_location?.value;
        if (sub_location_id == -1) {
            sub_location_id = null;
        }
        this.setState({ loading: true });
        const payload = { sublocation: sub_location_id, subscription, startDate: startDate?.format("X"), endDate: endDate?.clone().add(1, 'days').format("X") };
        console.log('stripe_listBillingInvoices', payload);
        Firebase.functions().httpsCallable('stripe_listBillingInvoices')(payload)
            .then((response) => {
                console.log('fetchBillingInvoiceList', { response });
                const res = response.data;
                if (res.status === 'ok') {
                    const { invoices } = res.data;
                    this.setState({ invoices })
                }
                this.setState({ loading: false });
            })
            .catch((error) => {
                this.setState({ loading: false });
                console.log('fetchBillingInvoiceList', { error });
            });
    }

    handlePayNow = (subscription_id) => {
        let _this = this;
        const { failed_payments, loading } = this.state;
        const subscriptions = failed_payments.filter(e => e.id === subscription_id);
        const subscription = subscriptions ? subscriptions[0] : {};
        const { metadata, latest_invoice } = subscription;
        const { email, interval, packageId, sublocationId } = metadata;

        // if (loading) return;
        // _this.setState({loading: true});
        // window.setTimeout(function() { _this.props.history.push("/sub_location/manage_package/"+sublocationId) }, 2000);
        Firebase.firestore().collection("Packages").doc(packageId).get().then(package_snapdoc => {
            const { Name, Monthly_Price, Annual_Price } = package_snapdoc.data();
            const price = interval === 'Monthly' ? Monthly_Price : Annual_Price;
            this.setState({
                productSelected: { id: packageId, name: Name, price: '$ ' + price, }, email, interval,
                sub_location_id: sublocationId, invoice: latest_invoice, loading: false, modalClassic: true,
            });
        });
    };

    gotoBillingOnlineSubscriptions() {
        this.props.history.push("/billing/online_subscriptions");
    }

    handleSubscriptionComplete = () => {
        //
    };

    prepareSubscriptionList = (response) => {
        // let no = 0;
        // response.data.map(subscription => {
        //     no ++;
        //     return {
        //         'sub-locatin-name': subscription.object,
        //         'package': subscription.object,
        //         'unit': subscription.object,
        //         'expire': subscription.days_until_due ? subscription.days_until_due + ' days left' : '-',
        //         'status': subscription.object,
        //     }
        // })
    }

    prepareInvoiceList = (invoices) => {
        //
    }

    onChangeSub(e, type) {
        console.log('onChangeSub', e);
        this.setState({ sub_location: e }, () => {
            // if (type === 'payment') {
            // } else {
            // }
            // this.fetchBillingSubscriptions();
            // this.fetchBillingInvoiceList();
            this.fetchCustomerInfo();
        });
    }

    render() {
        const {
            failed_payments,
            subscriptions,
            invoice,
            invoices,
            startDate,
            endDate,
            focusedInput,
            sub_location,
            sub_location_list,
            modalClassic,
            productSelected,
            email,
            interval,
            sub_location_id
        } = this.state;

        const { role } = this.auth_info;
        const fileName = 'Subscriptions.csv';
        console.log({ auth_info: this.auth_info, role });

        return (
            <>
                <LoadingOverlay
                    active={this.state.loading}
                    spinner
                    text='Loading'
                    className='content'
                >
                    <Row>
                        <Col md="12">
                            <Card className="">
                                <CardHeader>
                                    <CardTitle tag="h4">Billing Alerts</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <div className="padding_Card card-body">
                                        <div className="blockquote blockquote-primary padding">
                                            <Row>
                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <h6>Failed payments</h6>
                                                </div>
                                            </Row>
                                            {failed_payments.length >= 1 ?
                                                (<Row>
                                                    <Col>
                                                        {failed_payments.map(payment =>
                                                        (<div key={payment.id}>
                                                            {/* <div className="payment-alert-notification">Payment for {payment.sublocation} for the month of {payment.month}, {payment.year} has been rejected due to: {payment.status}.</div> 
                                                        <div><button className="btn btn-warning btn-block" onClick={() => this.handlePayNow(payment.id)}>Pay Now</button></div> */}
                                                            <br />
                                                            <div className="alert alert-info alert-with-icon payment-alert-notification" data-notify="container">
                                                                <span data-notify="icon" className="nc-icon nc-bell-55 col-md-2 col-lg-2"></span>
                                                                <div data-notify="message" className="col-md-8 col-lg-8">Payment for {payment.sublocation} for {payment.month}, {payment.year} was unsuccessful due to reason:&nbsp;&nbsp;
                                                                    <span className="badge badge-pill badge-warning">{payment.status}.</span>
                                                                </div>
                                                                <span className="col-md-2 col-lg-2">
                                                                    <Button className="btn btn-default" style={{ textAlign: 'right' }} onClick={e => { e.preventDefault(); this.handlePayNow(payment.id); }}>Pay Now</Button>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        )
                                                        )}
                                                        <Modal
                                                            isOpen={modalClassic}
                                                            toggle={this.toggleModalClassic}
                                                        >
                                                            {modalClassic &&
                                                                <PaymentForm
                                                                    productSelected={productSelected}
                                                                    email={email}
                                                                    invoice_no={invoice}
                                                                    interval={interval}
                                                                    sublocationId={sub_location_id}
                                                                    handleSubscriptionComplete={this.handleSubscriptionComplete}
                                                                />
                                                            }
                                                        </Modal>
                                                    </Col>
                                                </Row>)

                                                :

                                                (<Row>
                                                    <Col>
                                                        <br />
                                                        <div className="alert alert-success alert-with-icon" data-notify="container">
                                                            <span data-notify="icon" className="nc-icon nc-bell-55 col-md-2 col-lg-2"></span>
                                                            <div data-notify="message" className="col-md-8 col-lg-8">There are no failed payment alerts to display at the moment!</div>
                                                            <span className="col-md-2 col-lg-2">
                                                                <Button className="btn btn-default" style={{ textAlign: 'right' }} onClick={e => { e.preventDefault(); this.gotoBillingOnlineSubscriptions(); }}>View Online Subscriptions</Button>
                                                            </span>
                                                        </div>
                                                    </Col>
                                                </Row>)
                                            }
                                        </div>
                                    </div>
                                    {/* <div className="padding_Card card-body">
                                        <div className="blockquote blockquote-primary padding">
                                            <Row>
                                                <Col xl="6" lg="6">
                                                    <h6>Subscriptions</h6>
                                                </Col>
                                                <Col xl="6" lg="6">
                                                    {role=='Site_Admin' || role=='System_Admin' || role=='Billing_Admin'? 
                                                    <FormGroup className="flex-horizontal">
                                                        <Label>Sub Location</Label>
                                                        <Select
                                                            className="react-select info flex-item"
                                                            classNamePrefix="react-select"
                                                            placeholder="Select Sub Location"
                                                            name="selectSubLocation"
                                                            value={sub_location}
                                                            onChange={e =>
                                                                _this.onChangeSub(e, 'payment')
                                                            }
                                                            options={sub_location_list}
                                                        />
                                                        <ExportReactCSV csvData={subscriptions} fileName={fileName} />
                                                    </FormGroup>
                                                    :''}
                                                </Col>
                                                <Col md="12" lg="12">
                                                    <ReactTable
                                                        data={subscriptions}
                                                        columns={[
                                                            {
                                                                Header: "Sub location Name",
                                                                accessor: "sublocation"
                                                            },
                                                            {
                                                                Header: "Package",
                                                                accessor: "package"
                                                            },
                                                            {
                                                                Header: "Unit",
                                                                accessor: "interval"
                                                            },
                                                            {
                                                                Header: "Expiry Date",
                                                                accessor: "expire"
                                                            },
                                                            {
                                                                Header: "Payment Status",
                                                                accessor: "status"
                                                            }
                                                        ]}
                                                        defaultPageSize={5}
                                                        showPaginationTop={false}
                                                        showPaginationBottom={true}
                                                        showPageSizeOptions={false}
                                                        getTrProps={(state, rowInfo) => {
                                                            if (rowInfo && rowInfo.row) {
                                                              return {
                                                                onClick: (e) => {
                                                                    console.log({rowInfo});
                                                                  _this.setState({
                                                                    selected: rowInfo
                                                                  }, ()=> {
                                                                      _this.fetchBillingInvoiceList();
                                                                  })
                                                                },
                                                                style: rowInfo.index === _this.state.selected?.index ? {
                                                                  background: '#00afec',
                                                                  color: 'white'
                                                                } : {}
                                                              }
                                                            }else{
                                                              return {}
                                                            }
                                                          }
                                                        }
                                                        // You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                                                        className="-striped -highlight primary-pagination"
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    <div className="padding_Card card-body">
                                        <div className="blockquote blockquote-primary padding">
                                            <Row>
                                                <Col xl="2" lg="2">
                                                    <h6>Payment History</h6>
                                                </Col>
                                                <Col xl="4" lg="4">
                                                    {role=='Site_Admin' || role=='System_Admin' || role=='Billing_Admin'? 
                                                    <DateRangePicker
                                                        startDate={startDate} // momentPropTypes.momentObj or null,
                                                        endDate={endDate} // momentPropTypes.momentObj or null,
                                                        onDatesChange={({ startDate, endDate }) => {
                                                            console.log([ startDate?.format("YYYY-MM-DD"), endDate?.format("YYYY-MM-DD") ]);
                                                            _this.setState({ startDate, endDate });
                                                        }} // PropTypes.func.isRequired,
                                                        onClose={({ startDate, endDate }) => {
                                                            console.log([ startDate?.format("YYYY-MM-DD"), endDate?.format("YYYY-MM-DD") ]);
                                                            _this.fetchBillingInvoiceList();
                                                        }}
                                                        isOutsideRange={day => false}
                                                        startDateId="startDateId"
                                                        endDateId="endDateId"
                                                        focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                                        onFocusChange={focusedInput => _this.setState({ focusedInput })} // PropTypes.func.isRequired,
                                                    ></DateRangePicker>
                                                    :''}
                                                </Col>
                                                <Col xl="6" lg="6">
                                                    {role=='Site_Admin' || role=='System_Admin' || role=='Billing_Admin'? 
                                                    <FormGroup className="flex-horizontal">
                                                        <Label>Sub Location</Label>
                                                        <Select
                                                            className="react-select info flex-item Margin-right--12"
                                                            classNamePrefix="react-select"
                                                            placeholder="Select Sub Location"
                                                            name="selectSubLocation"
                                                            value={sub_location}
                                                            onChange={e =>
                                                                _this.onChangeSub(e, 'invoice')
                                                            }
                                                            options={sub_location_list}
                                                        />
                                                        <ExportReactCSV csvData={invoices} fileName="invoices.csv" />
                                                    </FormGroup>
                                                    :''}
                                                </Col>
                                                <Col md="12" lg="12">
                                                    <ReactTable
                                                        data={invoices}
                                                        columns={[
                                                            {
                                                                Header: "Invoice No",
                                                                accessor: "invoice_no",
                                                                Cell: ({ row })=> <a href={row._original.pdf_link}>{row.invoice_no}</a>
                                                            },
                                                            {
                                                                Header: "Invoice Date",
                                                                accessor: "invoice_date",
                                                                Cell: ({ row })=> <a href={row._original.pdf_link}>{row.invoice_date}</a>
                                                            },
                                                            {
                                                                Header: "Amount",
                                                                accessor: "amount",
                                                                Cell: ({ row })=> <a href={row._original.pdf_link}>{row.amount}</a>
                                                            },
                                                            {
                                                                Header: "Due Date",
                                                                accessor: "due_date",
                                                                Cell: ({ row })=> <a href={row._original.pdf_link}>{row.due_date}</a>
                                                            },
                                                            {
                                                                Header: "Payment Status",
                                                                accessor: "status",
                                                                Cell: ({ row })=> <a href={row._original.pdf_link}>{row.status}</a>
                                                            }
                                                        ]}
                                                        defaultPageSize={5}
                                                        showPaginationTop={false}
                                                        showPaginationBottom={true}
                                                        showPageSizeOptions={false}
                                                        // You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                                                        className="-striped -highlight primary-pagination"
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    </div> */}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </LoadingOverlay>
            </>
        );
    }
}

export default BillingAlerts;
