import React from "react";
import { DateRangePicker } from 'react-dates';
import LoadingOverlay from "react-loading-overlay";
import ReactTable from "react-table-6";
import Select from "react-select";
import ReactBSAlert from "react-bootstrap-sweetalert";
import NotificationAlert from "react-notification-alert";
import {
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    FormGroup,
    Label,
    Modal,
    Input,
    Button
} from "reactstrap";

import Firebase from "firebase";
import { ExportReactCSV } from '../../../components/ExportReactCSV'
import config from "../../../config";

//import DateRangePickerWrapper from "../../../components/DateRangePickerWrapper";

class BillingManualPaymentHistory extends React.Component {
    constructor(props) {
        super(props);
        if (!Firebase.apps.length) {
            Firebase.initializeApp(config);
        }
        this.state = {
            alert: null,
            loading: false,
            data: [],

            customer_id: '',
            subscriptions: [],
            invoices: [],

            startDate: null,
            endDate: null,
            focusedInput: null,

            sub_location: null,
            sub_location_list: [],

            payments: [],
            checkedItem: [],
            selectedSubscriptionID: null,
            selectedSublocationID: null
        };
        this.auth_info = {};
        this.getInvoiceData = this.getInvoiceData.bind(this);
        this.onAddInvoice = this.onAddInvoice.bind(this);

        // this.getSubscriptionData = this.getSubscriptionData.bind(this);
        //this.onChangeStatus = this.onChangeStatus.bind(this);

        //this.functions = null;                                          //functions emulator
        //this.fbStore = null;                                            //functions emulator
    }

    componentDidMount() {
        //this.initializeFirebase();                                      //functions emulator

        let _this = this;
        _this.auth_info = JSON.parse(localStorage.getItem('auth_info')) || {};
        let customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;
        let email = JSON.parse(localStorage.getItem('auth_info')).email;
        _this.setState({ loading: true });
        _this.setState({ own_email: email });
        _this.setState({ customer_id: customer_id });
        // this.fetchCustomerInfo();
        _this.fetchSubLocationsList();
        _this.fetchBillingInvoiceList();
    }

    // componentWillUnmount() {
    // }

    // componentDidUpdate(e) {
    // }

    //initializeFirebase = () => {                                        //functions emulator
    //if (!this.functions) {                                            //functions emulator
    //this.functions = Firebase.functions();                          //functions emulator
    //this.functions.useFunctionsEmulator('http://localhost:5001');   //functions emulator
    //this.fbStore = Firebase.firestore();                            //functions emulator
    //}                                                                 //functions emulator
    //}                                                                   //functions emulator

    formatDate(date) {
        var d = new Date(date), month = '' + (d.getMonth() + 1), day = '' + d.getDate(), year = d.getFullYear();
        if (month.length < 2) { month = '0' + month } if (day.length < 2) { day = '0' + day }
        return [year, month, day].join('-');
    }

    // toggleModalClassic = () => {
    //     this.setState({
    //         modalClassic: !this.state.modalClassic
    //     });
    // };

    fetchSubLocationsList = async () => {
        const { customer_id, email } = this.auth_info || {};

        let accessible_locations = [];
        let sub_locations = [{ label: 'Select All', value: -1, image_url: '', address: '' }];
        try {
            const app_info = await Firebase.firestore().collection('Web_App_Users').doc(email).get();
            if (app_info.exists) {
                accessible_locations = app_info.data().Accessible_Locations;
                //console.log("accessible locations:",accessible_locations);
                // console.log('user-json', JSON.stringify(app_info.data()));
            }
        } catch (ex1) {
            console.log("loadSubLocation NetworkError2==>", ex1);
        }

        try {
            const response = await Firebase.firestore().collection('Sub_Locations').where('Customer_ID', '==', customer_id).get();
            response.docs.forEach(function (doc) {
                if (accessible_locations.includes(doc.id) && doc.data().Subscription_Details.Payment_Source == "Manual_Payment") {
                    const { Name, Icon, Address } = doc.data();
                    sub_locations.push({ label: Name, value: doc.id, image_url: Icon, address: Address });
                }
            });
            this.setState({
                sub_location_list: sub_locations
            });
        } catch (ex2) {
            console.log("loadSubLocationByMain NetworkError2==>", ex2);
        }
    }
    handleDateChange(startDate, endDate) {
        this.setState({ startDate, endDate });
        //this.fetchCustomerInfo(); //this.fetchSubLocationsList();
        console.log([startDate?.format("YYYY-MM-DD"), endDate?.format("YYYY-MM-DD")]);
    }
    handleDateClose(startDate, endDate) {
        this.fetchBillingInvoiceList();
        console.log([startDate?.format("YYYY-MM-DD"), endDate?.format("YYYY-MM-DD")]);
    }
    // onChangeStatus(e, value, type) {
    //     switch (type) {
    //         case "check":
    //             var checked = e.target.checked;
    //             var checkedItem = [];
    //             if (checked) {
    //                 checkedItem.push(value);
    //             } 
    //             this.setState({checkedItem: checkedItem});
    //             break;
    //         default:
    //             break;
    //     }
    //     console.log('checkedItem', checkedItem);
    // }
    // getSubscriptionData() {
    //     var data = [];
    //     this.state.subscriptions.map((prop, key) => {
    //         console.log("prop", prop);
    //         data.push({
    //             num: key+1,
    //             // check: (
    //             //     <FormGroup check style={{marginTop: "-10px"}}>
    //             //         <div className="col-sm-10 checkbox-radios">
    //             //             <div className="form-check-radio">
    //             //                 <label className="form-check-label">
    //             //                     <input className="form-check-input" type="radio" name="subscription" id={prop.id} value={prop.id} checked={this.state.selectedSubscriptionID === prop.id} onChange={e => this.onChangeStatus(e, prop, "check")} />
    //             //                     <span className="form-check-sign"></span>
    //             //                 </label>
    //             //             </div>
    //             //         </div>
    //             //     </FormGroup>
    //             // ),
    //             expire: prop.expire,
    //             id: prop.id,
    //             interval: prop.interval,
    //             latest_invoice: prop.latest_invoice,
    //             metadata: prop.metadata,
    //             month: prop.month,
    //             package: prop.package,
    //             reason: prop.reason,
    //             status: (
    //                 prop.invoice_status === 'active' ? 
    //                     <span className="badge badge-pill badge-success">{prop.invoice_status}</span>
    //                     : <span className="badge badge-pill badge-warning">{prop.invoice_status}</span>
    //             ),
    //             sublocation: prop.sublocation,
    //             year: prop.year,
    //             action: (
    //                 prop.invoice_status === 'active' ? 
    //                     <Button onClick={e => this.warningWithConfirmMessageSubscription(prop)} className="btn btn-warning" style={{marginTop: '-7px', marginBottom: '-7px'}}>Cancel</Button>
    //                     : <Button disabled="true" onClick={e => this.warningWithConfirmMessageSubscription(prop)} className="btn btn-warning" style={{marginTop: '-7px', marginBottom: '-7px'}}>Cancel</Button>
    //             )
    //         });
    //     });
    //     console.log('data', data);
    //     return data;
    // }
    getInvoiceData() {
        var data = [];
        this.state.invoices.map((prop, key) => {
            console.log("prop", prop);
            data.push({
                num: key + 1,
                // check: (
                //     <FormGroup check style={{marginTop: "-10px"}}>
                //         <div className="col-sm-10 checkbox-radios">
                //             <div className="form-check-radio">
                //                 <label className="form-check-label">
                //                     <input className="form-check-input" type="radio" name="subscription" id={prop.id} value={prop.id} checked={this.state.selectedSubscriptionID === prop.id} onChange={e => this.onChangeStatus(e, prop, "check")} />
                //                     <span className="form-check-sign"></span>
                //                 </label>
                //             </div>
                //         </div>
                //     </FormGroup>
                // ),
                invoice_id: prop.invoice_id,
                invoice_created_date: prop.invoice_created_date,
                invoice_amount: prop.invoice_amount,
                invoice_due_date: prop.invoice_due_date,
                invoice_status: (   // Draft, Open, Paid, Void, Uncollectible
                    (prop.invoice_status === 'Open' || prop.invoice_status === 'Paid') ?
                        <span className="badge badge-pill badge-success">{prop.invoice_status}</span>
                        : <span className="badge badge-pill badge-warning">{prop.invoice_status}</span>
                ),
                invoice_pdf: (
                    (prop.invoice_pdf_attachment_url !== '' ?
                        <div>
                            <Button className="btn btn-default btn-sm" style={{ marginTop: '-7px', marginBottom: '-7px' }}>
                                <a href={prop.invoice_pdf_attachment_url} target='_blank' rel="noopener noreferrer">Download</a>
                            </Button>
                        </div>
                        : null)
                ),
                payment_receipt_pdf: (
                    (prop.invoice_payment_receipt_pdf_attachment_url !== '' ?
                        <div>
                            <Button className="btn btn-default btn-sm" style={{ marginTop: '-7px', marginBottom: '-7px' }}>
                                <a href={prop.invoice_payment_receipt_pdf_attachment_url} target='_blank' rel="noopener noreferrer">Download</a>
                            </Button>
                        </div>
                        : null)
                )
            });
        });
        console.log('data', data);
        return data;
    }
    // fetchCustomerInfo = () => {
    //     const { customer_id, email } = this.auth_info || {};
    //     const { sub_location, startDate, endDate } = this.state;

    //     let sub_location_id = sub_location?.value;
    //     if (sub_location_id == -1) {
    //         sub_location_id = null;
    //     }
    //     this.setState({loading: true});
    //     Firebase.functions().httpsCallable('manual_listCustomerInfo')({ customer_id, sub_location: sub_location_id, startDate: startDate ? startDate.format("X") : null, endDate: endDate ? endDate.add(1, 'days').format("X") : null })
    //         .then((response) => {
    //             console.log('fetchCustomerInfo', {customer_id, response});
    //             const res = response.data;
    //             var data = [];
    //             if (res.status === 'ok') {
    //                 const { subscriptions, invoices } = res.data;
    //                 const payments = this.filterPaymentSubscriptions(subscriptions, invoices);
    //                 console.log('subscriptions', subscriptions);
    //                 console.log({payments});
    //                 this.setState({ payments, subscriptions, invoices });
    //             }
    //             this.setState({loading: false});
    //         })
    //         .catch((error) => {
    //             this.setState({loading: false});
    //             console.log('fetchCustomerInfo', {error});
    //         });
    // }

    // filterPaymentSubscriptions = (subscriptions, invoices) => {
    //     const payments = subscriptions.filter(subscription => {
    //         return subscription.status != 'active' && subscription.status != 'trialing';
    //     });
    //     return payments.slice(Math.max(payments.length - 3, 0));
    // };

    // fetchBillingSubscriptions = () => {
    //     const { email } = this.auth_info || {};
    //     const { sub_location } = this.state;

    //     this.setState({loading: true});
    //     Firebase.functions().httpsCallable('manual_listBillingSubscriptions')({ email, sublocation: sub_location?.value })
    //         .then((response) => {
    //             console.log('fetchBillingSubscriptions', {email, response});
    //             const res = response.data;
    //             if (res.status === 'ok') {
    //                 const { subscriptions, invoices } = res.data;
    //                 this.setState({ subscriptions, invoices })
    //             }
    //             this.setState({loading: false});
    //         })
    //         .catch((error) => {
    //             this.setState({loading: false});
    //             console.log('fetchBillingSubscriptions', {error});
    //         });
    // }

    fetchBillingInvoiceList = () => {
        const { customer_id, sub_location, startDate, endDate, selected } = this.state;
        const subscription = -1; //selected?.original.id;

        let sub_location_id = sub_location?.value;
        if (sub_location_id === -1 || sub_location_id === '' || sub_location_id === null || typeof sub_location_id === 'undefined') {
            sub_location_id = -1;
        }
        this.setState({ loading: true });
        const payload = {
            customer_id: customer_id,
            sublocation: sub_location_id,
            subscription: subscription,
            startDate: this.formatDate(startDate), //(startDate?.format("X")),
            endDate: this.formatDate(endDate) //(endDate?.clone().add(1, 'days').format("X"))
        };
        console.log('fetchBillingInvoiceList>>1 payload', payload);
        console.log("fetchBillingInvoiceList>>2 sub_location_id", sub_location_id);

        Firebase.functions().httpsCallable('manual_listBillingInvoices')(payload)
            .then((response) => {
                console.log('fetchBillingInvoiceList>>3 response', { response });
                const res = response.data;
                if (res.status === 'ok') {
                    const { invoices } = res.data;
                    this.setState({ invoices })
                }
                this.setState({ loading: false });
            })
            .catch((error) => {
                this.setState({ loading: false });
                console.log('fetchBillingInvoiceList', { error });
            });
    }

    // handlePayNow = (subscription_id) => {
    //     const { payments, loading } = this.state;
    //     const subscriptions = payments.filter(e => e.id === subscription_id);
    //     const subscription = subscriptions ? subscriptions[0] : {};
    //     const { metadata, latest_invoice } = subscription;
    //     const { email, interval, packageId, sublocationId } = metadata;

    //     if (loading) return;

    //     this.setState({loading: true});
    //     Firebase.firestore().collection("Packages").doc(packageId).get().then(package_snapdoc => {
    //         const { Name, Monthly_Price, Annual_Price } = package_snapdoc.data();
    //         const price = interval === 'Monthly' ? Monthly_Price : Annual_Price;

    //         this.setState({
    //             productSelected: {
    //                 id: packageId,
    //                 name: Name,
    //                 price: '$ ' + price,
    //             },
    //             email,
    //             interval,
    //             sub_location_id: sublocationId,
    //             invoice: latest_invoice,

    //             loading: false,
    //             modalClassic: true,
    //         });

    //         // this.toggleModalClassic();
    //     });
    // };

    // handleSubscriptionComplete = () => {
    //     //
    // };

    // prepareSubscriptionList = (response) => {
    //     // let no = 0;
    //     // response.data.map(subscription => {
    //     //     no ++;
    //     //     return {
    //     //         'sub-locatin-name': subscription.object,
    //     //         'package': subscription.object,
    //     //         'unit': subscription.object,
    //     //         'expire': subscription.days_until_due ? subscription.days_until_due + ' days left' : '-',
    //     //         'status': subscription.object,
    //     //     }
    //     // })
    // }

    // prepareInvoiceList = (invoices) => {
    //     //
    // }

    onAddInvoice() {
        this.props.history.push("/billing/manual_payment_invoice/add");
    }

    onChangeSub(e, type) {
        console.log('onChangeSub', e);
        this.setState({ sub_location: e }, () => {
            // if (type === 'payment') {
            // } else {
            // }
            // this.fetchBillingSubscriptions();
            this.fetchBillingInvoiceList();
            // this.fetchCustomerInfo();
        });
    }

    // disableSubscription = (subscriptionID, sublocationID) => {
    //     let _this = this;
    //     const { customer_id, email } = _this.auth_info || {};
    //     const { selectedSubscriptionID, selectedSublocationID } = _this.state;
    //     console.log('selectedSubscriptionID, selectedSublocationID', selectedSubscriptionID, selectedSublocationID);
    //     console.log('subscriptionID, sublocationID', subscriptionID, sublocationID);

    //     _this.setState({loading: true});
    //     Firebase.functions().httpsCallable('manual_cancelSubscription')({ subscriptionId: subscriptionID , sublocationId: sublocationID })
    //         .then((response) => {
    //             const res = response.data;
    //             if (res.status === 'ok') {
    //                 console.log('>>> FUNC result disableSubscription', {response});
    //                 _this.setState({loading: false});
    //             }
    //         })
    //         .catch((error) => {
    //             _this.setState({loading: false});
    //             console.log('manual_cancelSubscription', {error});
    //         });
    // }

    // reloadCurrentWindow = () => {
    //     let _this = this; 
    //     _this.hideAlert();
    //     window.location.reload(false);
    // }

    // warningWithConfirmMessageSubscription = (object) => {
    //     let _this = this;
    //     let title = "Are you sure you want to cancel this subscription?";
    //     _this.setState({
    //         alert: (
    //             <ReactBSAlert
    //                 warning
    //                 style={{display: "block"}}
    //                 title={title}
    //                 onConfirm={() => _this.showSuccessAlertCancelSubscription(object.id, object.metadata.sublocationId)}
    //                 onCancel={() => _this.hideAlert()}
    //                 confirmBtnBsStyle="info"
    //                 cancelBtnBsStyle="danger"
    //                 confirmBtnText="Yes"
    //                 cancelBtnText="Cancel"
    //                 showCancel
    //             >
    //             <div className="text-left" style={{paddingLeft: "2em"}}>
    //                 <b className="text-uppercase">ID:</b> {object.id},<br/>
    //                 <b className="text-uppercase">Sublocation:</b> {object.sublocation},<br/>
    //                 <b className="text-uppercase">Package:</b> {object.package},<br/>
    //                 <b className="text-uppercase">Expires:</b> {object.expire},<br/>
    //                 <b className="text-uppercase">Interval:</b> {object.interval}<br/>
    //                 <b className="text-uppercase">Latest Invoice:</b> {object.latest_invoice},<br/>
    //                 <b className="text-uppercase">Status:</b> {object.status},<br/>
    //             </div>
    //             </ReactBSAlert>
    //         )
    //     });
    // };
    // showSuccessAlertCancelSubscription = (subscriptionID, sublocationID) => {
    //     let _this = this;
    //     _this.disableSubscription(subscriptionID, sublocationID);
    //     _this.setState({
    //         alert: (
    //             <ReactBSAlert
    //                 success
    //                 style={{ display: "block" }}
    //                 title="Subscription was cancelled."
    //                 onConfirm={() => _this.reloadCurrentWindow()}
    //                 onCancel={() => _this.reloadCurrentWindow()}
    //                 confirmBtnBsStyle="info"
    //             >

    //             </ReactBSAlert>
    //         )
    //     });
    // };
    hideAlert = () => {
        this.setState({
            alert: null
        });
    };
    notifyMessage = (place, color, text) => {
        var type;
        switch (color) {
            case 1: type = "primary"; break;
            case 2: type = "success"; break;
            case 3: type = "danger"; break;
            case 4: type = "warning"; break;
            case 5: type = "info"; break;
            default: break;
        }
        var options = {};
        options = {
            place: place,
            message: (
                <div className="text-md-center">
                    <div>
                        <b>{text}</b>
                    </div>
                </div>
            ),
            type: type,
            icon: "now-ui-icons ui-1_bell-53",
            autoDismiss: 3
        };
        if (options !== null) { this.notificationAlert.notificationAlert(options) }
    };

    render() {
        const { role } = this.auth_info;
        var ok = false;
        console.log("render>>1 role", role);
        if (role === "Site_Admin" || role === "Support_Admin")
            ok = true;
        const invoices_data = this.getInvoiceData();
        const {
            payments,
            subscriptions,
            invoice,
            invoices,
            startDate,
            endDate,
            focusedInput,
            sub_location,
            sub_location_list,
            modalClassic,
            productSelected,
            email,
            interval,
            sub_location_id
        } = this.state;


        const fileName = 'Manual_Payment_Invoices.csv';
        console.log({ auth_info: this.auth_info, role });

        return (
            <>
                <LoadingOverlay
                    active={this.state.loading}
                    spinner
                    text='Loading'
                    className='content'
                >
                    <NotificationAlert ref={(ref) => this.notificationAlert = ref} />
                    {this.state.alert}

                    <Row>
                        <Col md="12">
                            <Card className="">
                                <CardHeader>
                                    <CardTitle tag="h4">Billing - Manual Payment History</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <div className="padding_Card card-body">
                                        <div>
                                            {ok ? <><Row className="bottom-margin-20">
                                                <Col lg="6">
                                                    <Col xl="6" lg="6">
                                                        <Button
                                                            onClick={e => { e.preventDefault(); this.onAddInvoice(); }}
                                                            className="btn btn-info"
                                                            id="btnAddInvoice"
                                                            block
                                                        >
                                                            Add Invoice
                                                        </Button>
                                                    </Col>
                                                </Col>
                                            </Row></> : ''}
                                        </div>
                                        <div className="blockquote blockquote-primary padding">
                                            <Row>
                                                <Col xl="2" lg="2">
                                                    <h6>Manual Payment History</h6>
                                                </Col>
                                                <Col xl="4" lg="4">
                                                    {(role == 'Site_Admin' || role == 'System_Admin' || role == 'Billing_Admin') ?
                                                        <DateRangePicker
                                                            startDate={startDate} // momentPropTypes.momentObj or null,
                                                            endDate={endDate} // momentPropTypes.momentObj or null,
                                                            onDatesChange={({ startDate, endDate }) => { this.handleDateChange(startDate, endDate); }} // PropTypes.func.isRequired,
                                                            onClose={({ startDate, endDate }) => { this.handleDateClose(startDate, endDate); }}
                                                            isOutsideRange={day => false}
                                                            startDateId="startDateId"
                                                            endDateId="endDateId"
                                                            focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                                            onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                                                        ></DateRangePicker>
                                                        : ''}
                                                </Col>
                                                <Col xl="6" lg="6">
                                                    {role == 'Site_Admin' || role == 'System_Admin' || role == 'Billing_Admin' ?
                                                        <FormGroup className="flex-horizontal">
                                                            <Label>Sub Location</Label>
                                                            <Select
                                                                className="react-select info flex-item Margin-right--12"
                                                                classNamePrefix="react-select"
                                                                placeholder="Select Sub Location"
                                                                name="selectSubLocation"
                                                                value={sub_location}
                                                                onChange={e =>
                                                                    this.onChangeSub(e, 'invoice')
                                                                }
                                                                options={sub_location_list}
                                                            />
                                                            <ExportReactCSV csvData={invoices} fileName={fileName} />
                                                        </FormGroup>
                                                        : ''}
                                                </Col>
                                                <Col md="12" lg="12">
                                                    <ReactTable
                                                        data={invoices_data}
                                                        columns={[
                                                            {
                                                                Header: "#",
                                                                accessor: "num",
                                                            },
                                                            {
                                                                Header: "Invoice ID",
                                                                accessor: "invoice_id",
                                                            },
                                                            {
                                                                Header: "Invoice Created Date",
                                                                accessor: "invoice_created_date",
                                                            },
                                                            {
                                                                Header: "Amount",
                                                                accessor: "invoice_amount",
                                                            },
                                                            {
                                                                Header: "Due Date",
                                                                accessor: "invoice_due_date",
                                                            },
                                                            {
                                                                Header: "Invoice Status",
                                                                accessor: "invoice_status",
                                                            },
                                                            {
                                                                Header: "Invoice",
                                                                accessor: "invoice_pdf",
                                                            },
                                                            {
                                                                Header: "Payment Receipt",
                                                                accessor: "payment_receipt_pdf",
                                                            }
                                                        ]}
                                                        defaultPageSize={5}
                                                        showPaginationTop={false}
                                                        showPaginationBottom={true}
                                                        showPageSizeOptions={false}
                                                        getTrProps={(state, rowInfo) => {
                                                            if (rowInfo && rowInfo.row) {
                                                                return {
                                                                    onClick: (e) => {
                                                                        console.log('rowInfo', { rowInfo });
                                                                        this.setState({
                                                                            selected: rowInfo,
                                                                            selectedSubscriptionID: rowInfo.original.id
                                                                        }, () => {
                                                                            //this.fetchBillingInvoiceList();
                                                                        })
                                                                    },
                                                                    style: rowInfo.index === this.state.selected?.index ? {
                                                                        background: '#266dd3', //'#00afec',
                                                                        color: 'white'
                                                                    } : {}
                                                                }
                                                            } else {
                                                                return {}
                                                            }
                                                        }
                                                        }
                                                        // You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                                                        className="-striped -highlight primary-pagination"
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </LoadingOverlay>
            </>
        );
    }
}

export default BillingManualPaymentHistory;
