import React from 'react';
import LoadingOverlay from "react-loading-overlay";
import NotificationAlert from "react-notification-alert";
import Firebase from "firebase";
import Select from 'react-select';
import 'react-tagsinput/react-tagsinput.css' // If using WebPack and style-loader.
// import _ from 'lodash'
import * as am4core from "@amcharts/amcharts4/core";
// import { Dropdown, DropdownItem, DropdownMenu } from 'semantic-ui-react'
import 'bootstrap/dist/css/bootstrap.css';
import {
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Button
} from "reactstrap";
import { DateRangePicker } from '@progress/kendo-react-dateinputs';
// import { CustomStartDateInput } from './customStartDateInput.jsx';
// import { CustomEndDateInput } from './customEndDateInput.jsx';
import { CSVLink } from "react-csv";
import moment from "moment-timezone";
import config from "../../../config";

// import { getPriority } from "os";

// const servicesInfo = [];
// let nMainCnt = 0, nSerCnt = 0, nTSubLoc = 0;
// const uuidv1 = require('uuid/v1');

const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
}

class Report6DetailDownloadCSVFormat extends React.Component {

    constructor(props) {

        super(props);
        if (!Firebase.apps.length) {
            Firebase.initializeApp(config);
            // const perf = Firebase.performance();
        }

        this.state = {
            matches: window.matchMedia("(min-width: 370px)").matches,
            dateRangeLimitSeconds: 5184000, // number of seconds in 1 day = 86400s
                                            // number of seconds in 30 days = (30 X 86400) = 2592000s
                                            // number of seconds in 60 days = (60 x 86400) = 5184000s
            dateRangeLimitDays: 60,
            loading: false,

            defaultDate: {},
            bVisualize: false,
            start_date_formatted: '',
            end_date_formatted: '',
            defaultTimeZone: '',
            defaultTimeZoneSet: false,

            // bLoaded: false,
            // bdelete: false,
            // startDate: '',
            // endData: '',
            // main_count: 1,
            download_data: [],

            main_location_list: [],
            addressDefinitions: [],
            sub_location_list: [],
            subTemp: [],
            sub_location_ID: [],

            services: [],
            servTemp: [],
            stateOptions: [],

            detailTokenData: [],

            MLCnt: 0,
            SLCnt: 0,
            SVLCnt: 0,
            bdate: false,
            bmain: false,
            bsub: false,
            bservice: false,
            bUpdate: false,
            customer_id:'',
            user_name:'',
            downloads:[],
            email:''
        };

        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangesub = this.handleChangesub.bind(this);
        this.handleChangeService = this.handleChangeService.bind(this);

    }

    componentDidMount() {
        if (!this.state.bVisualize) {
            this.setState({ loading: true });
            // this.loadDefaultDate();
            this.loadAccessLoc();
            this.loadDownloads(JSON.parse(localStorage.getItem('auth_info')).customer_id)
        }
        this.setState({email:JSON.parse(localStorage.getItem('auth_info')).email });
        
        const handler = e => this.setState({ matches: e.matches });
        window.matchMedia("(min-width: 370px)").addListener(handler);
    }

    loadAccessLoc() {
        let _this = this;
        let access = [];
        let temp = [];
        // let i = 0;
        let email = JSON.parse(localStorage.getItem('auth_info')).email;
        Firebase.firestore().collection('Web_App_Users').doc(email).get().then(function (response) {
            if (response.exists) {
                access.push(response.data().Accessible_Locations);
            }
            // alert(access[8] + "//" + access[9] + "//" + access[10])
            for (let i = 0; i < access.length; i++) {
                let id = access[i];
                // temp.push({label:id});

                let len = id.length;
                for (let j = 0; j < len; j++) {
                    let subid = id[j];
                    // let b = _this.boolExist(temp, subid);
                    // if( b === 0)
                    temp.push({ label: subid });
                }
            }
            if (access.length > 0) {
                _this.loadSubLocationByMain(temp);
            }
        }).catch(e=>console.log(e))
    }
    
    loadDownloads(customer_id){
        let _this = this;
        Firebase.firestore().collection('Temporary_Downloads').where('Customer_ID', '==',customer_id).onSnapshot(e=>{
            let arr=[]
            e.docs.forEach(doc=>{
                arr.push({
                    id: doc.id,
                    ...doc.data()
                })
            })
            arr.sort((a,b)=>b?.Requested_Firestore_Timestamp?.seconds-a?.Requested_Firestore_Timestamp?.seconds)
            _this.setState({downloads:arr})
        },e=>console.log(e))
    }


    componentWillUnmount() {
        // this.setState({loading: false});
    }

    // componentDidUpdate(e) {
    // }

    handleChangeDate(event) {
        let _this = this;
        let dateObject = event.target.value;
        _this.setState({ defaultDate: dateObject });

        let defaultTimeZone = _this.state.defaultTimeZone;
        let defaultTimeZoneSet = _this.state.defaultTimeZoneSet;
        let startDateFmt = "", endDateFmt = "";

        if (defaultTimeZoneSet && _this.isValidJSDate(dateObject.start)) {
            startDateFmt = parseInt(moment(dateObject.start).tz(defaultTimeZone).unix());
            // startDateFmt = (moment(new Date(startDateFmt * 1000)).tz(defaultTimeZone).format("YYYY-MM-DD")).toString();
            startDateFmt = startDateFmt.toString();
            _this.setState({ start_date_formatted: startDateFmt });
        }

        if (defaultTimeZoneSet && _this.isValidJSDate(dateObject.end)) {
            endDateFmt = parseInt((moment(dateObject.end).tz(defaultTimeZone).endOf('day')).unix()) + 1;
            // endDateFmt = (moment(new Date(endDateFmt * 1000)).tz(defaultTimeZone).format("YYYY-MM-DD")).toString();
            endDateFmt = endDateFmt.toString();
            _this.setState({ end_date_formatted: endDateFmt });
        }

        console.log("handleChangeDate>>", "dateObject", dateObject, "defaultTimeZone", defaultTimeZone, "startDateFmt", startDateFmt, "endDateFmt", endDateFmt);
    }

    isValidJSDate(jsDateObj) {
        var timestamp = Date.parse(jsDateObj);
        if (!(isNaN(timestamp))) { return true; }
        return false;
    }
    loadMainLocations(temp) {
        let _this = this;
        // let access = temp;
        let main_locations = [];
        let mainTemp = [];
        let i = 0;
        let customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id,
        user_name=JSON.parse(localStorage.getItem('auth_info')).username
        this.setState({customer_id,user_name});
        Firebase.firestore().collection('Main_Locations').where('Customer_ID', '==', customer_id).get().then(function (response) {
            response.docs.forEach(function (doc) {
                main_locations.push({ value: doc.id, label: doc.data().Name });
            });

            for (i = 0; i < main_locations.length; i++) {
                let nn = main_locations[i].value;
                let b = _this.boolExist(temp, nn);
                if (b === 1)
                    mainTemp.push({ value: main_locations[i].value, label: main_locations[i].label })
            }

            if (mainTemp.length > 0) {
                _this.setState({ main_location_list: mainTemp, addressDefinitions: mainTemp });
                // _this.loadSubLocationByMain(temp, mainTemp);
            }
        }, (error) => {
            console.log("loadMainLocations Error===>", error);
        }).catch(e=>console.log(e))
    }

    loadSubLocationByMain(temp) {
        let _this = this;
        let defaultTimeZone = _this.state.defaultTimeZone;
        let TT = [], main = [], main2 = [];
        let sub_locations = [];
        let i = 0;
        let customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;
        Firebase.firestore().collection('Sub_Locations').where('Customer_ID', '==', customer_id).get().then(function (response) {
            response.docs.forEach(function (doc) {
                sub_locations.push({ value: doc.id, label: doc.data().Name, mID: doc.data().Main_Location_ID, timezone: doc.data().TimeZone });
                if (defaultTimeZone == "" || defaultTimeZone == undefined || defaultTimeZone == null) {
                    let timeZoneParts = doc.data().TimeZone.split(")");
                    defaultTimeZone = timeZoneParts[1];
                    _this.setState({ defaultTimeZone: defaultTimeZone });
                    _this.setState({ defaultTimeZoneSet: true });
                    console.log("defaultTimeZone", defaultTimeZone, "defaultTimeZoneSet", true);
                    _this.loadDefaultDate();
                }
                main.push({ label: doc.data().Main_Location_ID });
            });
            for (i = 0; i < sub_locations.length; i++) {
                // let mm = sub_locations[i].mID;
                // let bb = _this.boolExistID(mainTemp, mm);
                // if( bb === 0 ) continue;

                let nn = sub_locations[i].value;
                let b = _this.boolExist(temp, nn);
                if (b === 1) {
                    TT.push({ value: sub_locations[i].value, label: sub_locations[i].label, mID: sub_locations[i].mID, timezone: sub_locations[i].timezone })
                    let mainId = sub_locations[i].mID;
                    let bb = _this.boolExist(main, mainId);
                    if (bb === 1)
                        main2.push(main[i])
                }
            }
            if (TT.length > 0) {
                _this.setState({ sub_location_list: TT, sub_location_ID: TT });
                _this.setState({ subTemp: TT });
                // // console.log("loadSubLocationByMain >>TT", TT);
                _this.loadMainLocations(main2);
                _this.loadServicesByMainAndSub(main2, TT);
            }
        }, (error) => {
            console.log("loadSubLocationByMain Error==>", error);
        }).catch(e=>console.log(e))
    }

    loadServicesByMainAndSub(main, sub) {
        let _this = this;
        let servicesT = [];
        let Ser = [];
        let subValue = [];
        let customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;
        sub.map((subItem, index) => {
            subValue.push(subItem.value);
        });
        Firebase.firestore().collection('Services').where('Customer_ID', '==', customer_id).get().then(function (response) {
            response.docs.forEach(function (doc) {
                if (subValue.includes(doc.data().Sub_Location_ID)) {
                    // console.log("services ==> ", doc)
                    servicesT.push({ value: doc.id, label: doc.data().Name, mID: doc.data().Main_Location_ID, sID: doc.data().Sub_Location_ID });
                    // servicesInfo.push({value:doc.data().Sub_Location_ID, label: doc.data().Name,  date: Sec});
                }
            });

            for (let i = 0; i < servicesT.length; i++) {
                let mm = servicesT[i].mID;
                let bb = _this.boolExist(main, mm);
                if (bb === 0) continue;

                let nn = servicesT[i].sID;

                let b = _this.boolExistID(sub, nn);
                if (b === 1)
                    Ser.push(servicesT[i]);
            }
            if (Ser.length > 0) {
                // console.log("service===>", Ser);
                _this.setState({ services: Ser, servTemp: Ser, stateOptions: Ser });
                _this.setState({ loading: false });
                // _this.loadTokenData();
            } else {
                console.log("No Services..");
                _this.setState({ loading: false });
            }

        }, (error) => {
            console.log("loadServicesByMainAndSub Error ===> ", error);
            _this.setState({ loading: false });
        }).catch(e=>console.log(e))
        // })
    }

    loadDefaultDate() {
        let _this = this;
        let today = new Date();
        let y = today.getFullYear();
        let m = today.getMonth();
        let d = today.getDate();

        var dateObject = {
            start: new Date(y, m, 1),
            end: new Date(y, m, d)
        };

        _this.setState({ defaultDate: dateObject });

        let defaultTimeZone = _this.state.defaultTimeZone;
        let defaultTimeZoneSet = _this.state.defaultTimeZoneSet;
        let startDateFmt = "", endDateFmt = "";  

        if (defaultTimeZoneSet && _this.isValidJSDate(dateObject.start)) {
            startDateFmt = parseInt(moment(dateObject.start).tz(defaultTimeZone).unix());
            // startDateFmt = (moment(new Date(startDateFmt * 1000)).tz(defaultTimeZone).format("YYYY-MM-DD")).toString();
            startDateFmt = startDateFmt.toString();
            _this.setState({ start_date_formatted: startDateFmt });
        }

        if (defaultTimeZoneSet && _this.isValidJSDate(dateObject.end)) {
            endDateFmt = parseInt((moment(dateObject.end).tz(defaultTimeZone).endOf('day')).unix()) + 1;
            // endDateFmt = (moment(new Date(endDateFmt * 1000)).tz(defaultTimeZone).format("YYYY-MM-DD")).toString();
            endDateFmt = endDateFmt.toString();
            _this.setState({ end_date_formatted: endDateFmt });
        }

        console.log("loadDefaultDate>>", "dateObject", dateObject, "defaultTimeZone", defaultTimeZone, "startDateFmt", startDateFmt, "endDateFmt", endDateFmt);
    }

    getDeleteData(src, filter, color, name) {
        let len = src.length;
        let ret = [];
        for (let i = 0; i < len; i++) {
            for (let j = 0; j < filter.length; j++)
                if (src[i].label === filter[j].label) {
                    color.push(src[i].color);
                    name.push(src[i].label)
                    ret.push(src[i])
                }
        }
        return ret;

    }

    handleChange(selectedOption) {
        this.state.main_location_list = selectedOption;
        this.state.sub_location_list = [];
        this.setState({ main_location_list: selectedOption });
        this.setState({ sub_location_list: [] });
        this.LoadSubLocation();
        // if (this.state.bVisualize && selectedOption.length !== 0)
        // this.subfiltering(selectedOption, 1);
    }

    LoadSubLocation() {
        let _this = this;
        let SelOpt = _this.state.main_location_list;
        let nL = SelOpt?.length;
        let sub = _this.state.subTemp;
        let subtt = [];
        _this.setState({ sub_location_list: [] });

        for (let j = 0; j < nL; j++) {
            for (let i = 0; i < sub.length; i++) {
                if (SelOpt[j].value === sub[i].mID)
                    subtt.push({ value: sub[i].value, label: sub[i].label, mID: sub[i].mID, timezone: sub[i].timezone });
            }
        }
        _this.state.sub_location_list = subtt;
        _this.setState({ sub_location_list: subtt, sub_location_ID: subtt });

        _this.LoadServNamesBySL();

    }

    handleChangesub(selectItem) {
        this.state.sub_location_list = selectItem;
        this.setState({ sub_location_list: selectItem });
        this.LoadServNamesBySL();

        // if (this.state.bVisualize && selectItem.length !== 0)
        //     this.subfiltering(selectItem, 2);
    }

    LoadServNamesBySL() {
        let _this = this;
        let SelOpt = _this.state.sub_location_list;
        let nL = SelOpt?.length;

        let sub = _this.state.servTemp;
        let subtt = [];

        _this.setState({ services: [] });

        for (let j = 0; j < nL; j++)
            for (let i = 0; i < sub.length; i++)
                if (SelOpt[j].value === sub[i].sID)
                    subtt.push({ value: sub[i].value, label: sub[i].label, mID: sub[i].mID, sID: sub[i].sID });
        _this.state.services = subtt;
        _this.state.stateOptions = subtt;
        _this.setState({ services: subtt, stateOptions: subtt });

        // if( this.state.bVisualize )
        //     _this.handleChangeService(subtt);
    }

    handleChangeService(selectItemService) {
        this.state.services = selectItemService;
        this.setState({ services: selectItemService });
        // if (this.state.bVisualize && selectItemService.length !== 0)
        //     this.subfiltering(selectItemService, 3);
    }

    getfilterName(n1, sv) {
        let temp = [];
        for (let i = 0; i < n1.length; i++) {
            if (this.boolExist(sv, n1[i].serID) === 0) continue;
            temp.push(n1[i]);
        }
        return temp;
    }

    handleChangeMainFiltering(Src, tar, nCase) {
        let temp = [];
        for (let i = 0; i < tar.length; i++) {
            let id = tar[i].value;
            if (nCase === 3) id = tar[i].sID;
            let b = this.boolExistName(Src, id, nCase);
            if (b === 0) continue;

            temp.push(tar[i]);
        }
        return temp;
    }

    boolExistName(Src, id, nCase) {
        for (let i = 0; i < Src.length; i++) {
            let tt = null;
            if (nCase === 1) tt = Src[i].mID;
            else if (nCase === 2) tt = Src[i].sID;
            else if (nCase === 3) tt = Src[i].svID;
            if (id === tt) return 1;
        }
        return 0;
    }

    boolMainLoc(id) {
        let IDArr = this.state.main_location_list;

        let len = IDArr.length;

        for (let i = 0; i < len; i++) {
            let tt = IDArr[i].value;
            if (tt === id)
                return 1;
        }
        return 0;
    }

    boolSubLoc(id) {
        let IDArr = this.state.sub_location_list;

        let len = IDArr.length;
        for (let i = 0; i < len; i++) {
            let tt = IDArr[i].value;
            if (tt === id)
                return 1;
        }
        return 0;
    }

    boolService(id) {
        let IDArr = this.state.services;
        let nCnt = 0;
        let lenid = id.length;
        let len = IDArr.length;
        for (let j = 0; j < lenid; j++)
            for (let i = 0; i < len; i++) {
                let tt = IDArr[i].value;
                if (tt === id[j])
                    nCnt++;
                if (nCnt === lenid)
                    return 1;
            }

        return 0;
    }

    getTimezone(id) {
        let _this = this;
        let sub = _this.state.sub_location_list;
        for (let i = 0; i < sub.length; i++) {
            let tt = sub[i].timezone;
            let t = tt.split(")");

            if (id === sub[i].value)
                // console.log("t[1]", t[1]);
            return t[1];
        }
    }

    formatDate(date) {
        var d = new Date(date), month = '' + (d.getMonth() + 1), day = '' + d.getDate(), year = d.getFullYear();
        if (month.length < 2) { month = '0' + month } if (day.length < 2) { day = '0' + day }
        return [year, month, day].join('-');
    }

    notifyMessage = (place, color, text,dissDur=3) => {
        var type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }

        var options = {};
        options = {
            place: place,
            message: (
                <div className="text-md-center">
                    <div>
                        <b>{text}</b>
                    </div>
                </div>
            ),
            type: type,
            icon: "now-ui-icons ui-1_bell-53",
            autoDismiss: dissDur
        };
        if (options !== null) { this.notificationAlert.notificationAlert(options) }
    };

    async loadTokenData() {
        let _this = this,
            startDateFmt = _this.state.start_date_formatted, endDateFmt = _this.state.end_date_formatted;
        let subLocIDArrVals = _this.exportArrayValues(_this.state.sub_location_ID),
            servIDArrVals = _this.exportArrayValues(_this.state.services);
        let downloaddata = [],
        mainLocationNames=_this.state.main_location_list?.map(e=>e?.label||'')+'',
        mainLocationIds=_this.state.main_location_list?.map(e=>e?.value||'')||'',
        subLocationNames=_this.state.sub_location_list?.map(e=>e?.label||'')+''
        _this.setState({ download_data: [] });
        let i = 0;
        console.log({
            "Currently running function:": "loadTokenData",
            start_date: startDateFmt,
            end_date: endDateFmt,
            sub_loc_arr: subLocIDArrVals,
            servs_arr: servIDArrVals,
            query_type: "vw_1"
        });
        let newFile = await Firebase.firestore().collection("Temporary_Downloads").add({
            Customer_ID:_this.state.customer_id,
            Download_File_URL:'',
            Download_Type:'',
            File_Extension:'',
            From_Date:startDateFmt,
            Main_Location_Names:mainLocationNames,
            Main_Location_IDs:mainLocationIds,
            Requested_Firestore_Timestamp:Firebase.firestore.FieldValue.serverTimestamp(),
            Requested_User:_this.state.user_name,
            Status:'Submited ',
            Sub_Location_Names:subLocationNames,
            Sub_Location_IDs:subLocIDArrVals,
            To_Date:endDateFmt,
          }).catch(e=>{console.log(e); _this.notifyMessage(
            "tc",
            3,
            `An error happened while sending report request, please make sure you have the required permission or try again later.`,
            5
          )})
          console.log(newFile)
 
        // Firebase.functions().useEmulator("localhost", 5001) 
        if(newFile?.id?.length>0)
        Firebase.functions()
            .httpsCallable("generateAnalyticsDocument", { timeout: 540000 })({
            //HttpsCallableOptions timeout in ms
            start_date: startDateFmt,
            end_date: endDateFmt,
            sub_loc_arr: subLocIDArrVals,
            servs_arr: servIDArrVals,
            query_type: "vw_1",
            doc_id: newFile.id,
            })
            .catch((e) => {
            console.log(e);
            Firebase.firestore()
                .collection("Temporary_Downloads")
                .doc(newFile.id)
                .update({ Status: "Failed", Fail_Reason: e + "" });
            });

        console.log("reached here #5 done");
    }



    calcTimeSegmentFromSeconds(tSegSec) {
        var sec = tSegSec;
        var timeSegment = "";
        if (sec <= 300) timeSegment = "5 minutes and less";
        else if (sec <= 600) timeSegment = "5 to 10 minutes";
        else if (sec <= 900) timeSegment = "10 to 15 minutes";
        else if (sec <= 1200) timeSegment = "15 to 20 minutes";
        else if (sec <= 1500) timeSegment = "20 to 25 minutes";
        else if (sec <= 1800) timeSegment = "25 to 30 minutes";
        else if (sec > 1800) timeSegment = "Above 30 minutes";
        return timeSegment;
    }

    timeStampToDateTimeConvert(UNIX_timestamp) {
        var a = new Date(UNIX_timestamp * 1000);
        var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var hour = a.getHours();
        var min = a.getMinutes();
        var sec = a.getSeconds();
        var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
        return time;
    }

    onVisualize() {
        let _this = this;
        if (!_this.state.bVisualize) {
            let start = _this.state.defaultDate.start, end = _this.state.defaultDate.end;
            // let startSec = Date.parse(start) / 1000, endSec = Date.parse(end) / 1000;
            let startSec = Date.parse(start) / 1000, endSec = (Date.parse(end) + 86400) / 1000; // number of seconds in 1 day = 86400s
            // let startDateFmt = _this.formatDate(start), endDateFmt = _this.formatDate(end);
            if ((endSec - startSec) > (_this.state.dateRangeLimitSeconds)) {
                _this.setState({ loading: false });
                _this.notifyMessage("tc", 3, `Please select a date range less than ${_this.state.dateRangeLimitDays} days!`);
            } else {
                // _this.setState({ loading: true, bVisualize: true });
                // // console.log("selected Segmentation Type:", _this.state.segmentationType);
                _this.loadTokenData();
                // _this.subfiltering([], 0);
            }
        }
    }

    mainFiltering() {
        let total = this.state.detailTokenData;
        let len = total.length;
        let main = [], sub = [], service = [];
        for (let i = 0; i < len; i++) {
            let b1 = this.boolExist(main, total[i].mainID);
            let b2 = this.boolExist(sub, total[i].subID);
            let b3 = this.boolExist(service, total[i].serID);

            if (b1 === 1 && b2 === 1 && b3 === 1) continue;

            if (b1 === 0) {
                main.push({ value: total[i].id, label: total[i].mainID, mID: total[i].mID, sID: total[i].sID, vID: total[i].vID });
                // addressDefinitions.push({value : total[i].id, label: total[i].mainID, mID: total[i].mID, sID: total[i].sID, vID : total[i].vID });
            }
            if (b2 === 0)
                sub.push({ value: total[i].id, label: total[i].subID, mID: total[i].mID, sID: total[i].sID, vID: total[i].vID });
            if (b3 === 0)
                service.push({ value: total[i].id, label: total[i].serID, mID: total[i].mID, sID: total[i].sID, vID: total[i].vID });
        }

        this.setState({ main_location_list: main, addressDefinitions: main, sub_location_list: sub, subTemp: sub, services: service, servTemp: service, sub_location_ID: sub, stateOptions: service });
    }

    boolExist(temp, name) {
        let bN = 0;
        for (let j = 0; j < temp.length; j++) {
            if (name === temp[j].label) {
                bN = 1;
                break;
            }
        }
        return bN;
    }

    boolExistID(temp, name) {
        let bN = 0;
        for (let j = 0; j < temp.length; j++) {
            if (name === temp[j].value) {
                bN = 1;
                break;
            }
        }
        return bN;
    }

    exportArrayValues(temp) {
        let result = [];
        for (let j = 0; j < temp.length; j++) {
            result.push(temp[j].value);
        }
        return result;
    }

    pad(num, size) {
        return ('000' + num).slice(size * -1);
    }

    sec2time(time) {
        // var pad = function(num, size) { return ('000' + n?um).slice(size * -1); },
        // time = parseFloat(timeInSeconds).toFixed(3),
        let hours = Math.floor(time / 60 / 60);
        let minutes = Math.floor(time / 60) % 60;
        let seconds = Math.floor(time - minutes * 60);

        return this.pad(hours, 2) + ':' + this.pad(minutes, 2) + ':' + this.pad(seconds, 2);
    }

    displayDownloadRow(row,k) {
        let _this = this;
        let emailHtml='Thank you for using Ant Queue, your Analytics Report is ready to be downloaded. Please click the link below to download your file.\
        <br><br>\
        <a href="'+row.Download_File_URL+'">Download</a>\
        <br><br>\
        <b>Note:</b> Please save the file to your computer and open it in Microsoft Excel or Google Sheets.\
        <br><br>\
        Best Regards, <br>\
        Ant Queue Team';
        return (
          <tr key={k}>
            <td className="ka-cell">{row.Requested_Firestore_Timestamp?.toDate().toDateString()}</td>
            <td className="ka-cell">{row.Requested_User}</td>
            <td className="ka-cell">{new Date(row.From_Date * 1000).toDateString()}</td>
            <td className="ka-cell">{new Date(row.To_Date * 1000).toDateString()}</td>
            <td className="ka-cell overflow-ellipsis" title={row.Main_Location_Names}>
              {row.Main_Location_Names}
            </td>
            <td className="ka-cell overflow-ellipsis" title={row.Sub_Location_Names}>
              {row.Sub_Location_Names}
            </td>
            <td className="ka-cell">
              <span
                className={
                  "badge badge-pill badge-" + (row.Status == "Failed"
                    ? "danger"
                    : row.Status == "Ready"
                    ? "success"
                    : row.Status == "Processing"
                    ? "warning"
                    : "secondary")
                }
              >
                {row.Status}
              </span>
            </td>
            {row?.Download_File_URL?.length > 0 && (
              <td className="ka-cell">
                <a href={row.Download_File_URL} download>
                  <Button
                    size="xs"
                    className="btn btn-primary btn-sm"
                    color="white"
                    title="Download file"
                    // onClick={() => this.handleRequest(prop, "reject_tok", 0)}
                  >
                    <i className="nc-icon nc-cloud-download-93" />
                  </Button>
                </a>
                <Button
                  size="xs"
                  className="btn btn-warning btn-sm"
                  color="white"
                  title="Send to my Email"
                  onClick={() =>
                    Firebase.functions()
                      .httpsCallable("sendMail")({
                        email: _this.state.email,
                        subject: "Analytics Report",
                        text: emailHtml,
                      })
                      .then(function (result) {
                          // console.log(result);
                          _this.notifyMessage("tc", 2, `File Download Link sent to ${_this.state.email}`);
                      })
                      .catch((e) => {
                          console.log(e);
                          _this.notifyMessage(
                           "tc",
                           3,
                           `Sending File URL to Email failed, please try again in a minute.`
                           );
                      })
                  }
                  // onClick={() => this.handleRequest(prop, "reject_tok", 0)}
                >
                  <i className="nc-icon nc-email-85" />
                </Button>
              </td>
            )}
          </tr>
        );
    }

    //V^6@}J*s
    render() {
        let _this=this
        const { main_location_list, sub_location_list, services, defaultDate } = this.state;
        //const { feedback_data } = { ...this.state }; // dataLineChartToken, data3DChartToken, TokenColor, TokenLabel, 
        //       this.setState({dataLineChartToken:this.state.dataLineChartToken});
        // const feedback_data = this.getfeedbackData();
        return (
          <>
            <LoadingOverlay active={this.state.loading} spinner text="Loading" className="content">
            <NotificationAlert ref={(ref) => this.notificationAlert = ref} />

              <Row>
                <Col md="12">
                  <Card className="content">
                    <CardHeader>
                      <h4 className="padding_Dashboard card-title">Report - Download Detail Data (CSV Format)</h4>
                      {/* <CardTitle tag="h4">Report - Detail Download</CardTitle> */}
                    </CardHeader>
                    <CardBody>
                      <div className="padding_Card card-body">
                        <div className="blockquote blockquote-primary padding">
                          <Row>
                            <div className="col-12 col-md-6 col-lg-3">
                              <h5>Select Date Range</h5>

                              {/*<div id="daterangepicker" title="daterangepicker"></div> */}
                              <DateRangePicker
                                value={this.state.defaultDate}
                                onChange={this.handleChangeDate}
                              />
                            </div>
                            <div className="col-12 col-md-6 col-lg-3">
                              <h5>Select Main Location</h5>
                              <Select
                                placeholder="Select Main Location"
                                closeMenuOnSelect={true}
                                value={this.state.main_location_list}
                                isMulti
                                options={this.state.addressDefinitions}
                                onChange={this.handleChange}
                              />
                            </div>
                            <div className="col-12 col-md-6 col-lg-3">
                              <h5>Select Sub Location</h5>
                              <Select
                                placeholder="Select Sub Location"
                                closeMenuOnSelect={true}
                                value={this.state.sub_location_list}
                                clearValue
                                isMulti
                                options={this.state.sub_location_ID}
                                onChange={this.handleChangesub}
                              />
                            </div>
                            <div className="col-12 col-md-6 col-lg-3">
                              <h5>Select Services</h5>
                              <Select
                                placeholder="Select Services"
                                closeMenuOnSelect={true}
                                value={this.state.services}
                                isClearable
                                clearValue
                                isMulti
                                options={this.state.stateOptions}
                                onChange={(selectItemService) => this.handleChangeService(selectItemService)}
                              />
                            </div>
                            {/* <div className="col-12 col-md-6 col-lg-3">
                                                    <h5>Select Segmentation Type</h5>
                                                    <Select
                                                        placeholder='Select Segmentation Type'
                                                        closeMenuOnSelect={true}
                                                        value={this.state.segmentationType}
                                                        isClearable
                                                        clearValue
                                                        isMulti
                                                        options={this.state.segmentationOptions}
                                                        onChange={(selectItemSegmentation) => this.handleChangeSegmentation(selectItemSegmentation)}
                                                    />
                                                </div> */}
                          </Row>

                          <div className="text-center padding_button">
                            <button
                              disabled={
                                !(
                                  main_location_list?.length > 0 &&
                                  sub_location_list?.length > 0 &&
                                  services?.length > 0 &&
                                  defaultDate.start &&
                                  defaultDate.end
                                )
                              }
                              className="btn-round btn btn-outline-success h6"
                              type="button"
                              onClick={() => this.onVisualize()}
                            >
                              Prepare Data
                            </button>
                          </div>
                          <Row>
                            <div className="text-center">
                              <h5>Data maybe delayed up to 20 hours.</h5>
                            </div>
                          </Row>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <table
                className="ka-table"
                style={{
                  width: "100%",
                  tableLayout: "fixed",
                  fontWeight: "normal",
                  marginTop: "3rem",
                  textAlign: "left",
                }}
              >
                <thead className="ka-thead">
                  <tr className="ka-tr ka-thead-row" style={{ background: "#F1F5F7" }}>
                    <th className="ka-thead-cell">Requested Date</th>
                    <th className="ka-thead-cell">Requested User</th>
                    <th className="ka-thead-cell">From Date</th>
                    <th className="ka-thead-cell">To Date</th>
                    <th className="ka-thead-cell">Main Location</th>
                    <th className="ka-thead-cell">Sub Location</th>
                    <th className="ka-thead-cell">Status</th>
                    <th className="ka-thead-cell">Action</th>
                  </tr>
                </thead>
                <tbody>{this.state.downloads.map((e, k) => this.displayDownloadRow(e, k))}</tbody>
              </table>
            </LoadingOverlay>
          </>
        );
    }
}

export default Report6DetailDownloadCSVFormat;


// // excel file path
// const excel_file_path = 'excel_templates/products_excel_file.xlsx'
// // firebase 'Products' table reference
// const firebaseDatabaseRef = firebase.database().ref();
//       const productsRef = firebaseDatabaseRef.child("Products");
// // get Products table from FirebaseS
// productsRef.once("value",function(snapData){
//         var count = snapData.numChildren();
//         console.log('count : '+count);
//         readWriteToExcelfile(snapData);
//     },function(errorObj){
//         console.log('function(errorObj)')
//     });
// function readWriteToExcelfile(snapData) {
//   // create a workbook variable
//   var workbook = new Excel.Workbook();
//   // read excel file from the path
//   workbook.xlsx.readFile(excel_file_path)
//     .then(function() {
//      // access the excel sheet
//         var worksheet = workbook.getWorksheet('Sheet1');
//         addRowsToExcelSheet(snapData,workbook,worksheet);
//    });
// }
// // code to write data into excel sheet
// function addRowsToExcelSheet(snapData,workbook,worksheet){
//   // for loop to read each record from Products table
//   snapData.forEach(function(data){
//     // get value for the record
//     const val = data.val()
//     // Add a row by sparse Array (assign to columns)
//               var rowValues = [];
//               rowValues[1]=val.itemId       // itemId
//               rowValues[2]=val.itemName          // itemName
//               rowValues[3]=val.price               // price
//               rowValues[4]=val.stockIndicator         // GroupCode
//     // add row to worksheet
//     worksheet.addRow(rowValues);
//   })
//   //write file function to save all the data to the excel template file.
//         workbook.xlsx.writeFile(ocrd_file_path)
//             .then(function () {
//                 console.log("saved to excel file successfully");
//             });
// }