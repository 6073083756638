import React from 'react';
import _ from "underscore";
import NotificationAlert from "react-notification-alert";
import Firebase from "firebase";
import LoadingOverlay from "react-loading-overlay";
import Select from 'react-select';
import 'react-tagsinput/react-tagsinput.css' // If using WebPack and style-loader.
// import _ from 'lodash'
import * as am4core from "@amcharts/amcharts4/core";
// import { Dropdown, DropdownItem, DropdownMenu } from 'semantic-ui-react'
import 'bootstrap/dist/css/bootstrap.css';
import {
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row
} from "reactstrap";
import { DateRangePicker } from '@progress/kendo-react-dateinputs';
// import { CustomStartDateInput } from './customStartDateInput.jsx';
// import { CustomEndDateInput } from './customEndDateInput.jsx';
// import { CSVLink } from "react-csv";
import moment from "moment-timezone";
import config from "../../../config";

import MainLocLineChart from '../../../components/amCharts/MainLocLine';
import MainLoc3DChart from '../../../components/amCharts/MainLoc3D';
import SubLoc3DChart from '../../../components/amCharts/SubLoc3DChart';
import SubLocLineChart from '../../../components/amCharts/SubLocLineChart';
import ServLocLineChart from '../../../components/amCharts/ServLocLineChart';
import ServLoc3DChart from '../../../components/amCharts/ServLoc3DChart';
import InsrcLocLineChart from '../../../components/amCharts/InsrcLocLineChart';
import InsrcLoc3DChart from '../../../components/amCharts/InsrcLoc3DChart';
// import ServctrLocLineChart from '../../../components/amCharts/ServctrLocLineChart';
// import ServctrLoc3DChart from '../../../components/amCharts/ServctrLoc3DChart';
// import ServusrLocLineChart from '../../../components/amCharts/ServusrLocLineChart';
// import ServusrLoc3DChart from '../../../components/amCharts/ServusrLoc3DChart';
// import CustomerLocLineChart from '../../../components/amCharts/CustomerLocLineChart';
// import CustomerLoc3DChart from '../../../components/amCharts/CustomerLoc3DChart';
// import TokenLocLineChart from '../../../components/amCharts/TokenLocLineChart';
// import TokenLoc3DChart from '../../../components/amCharts/TokenLoc3DChart';
// import TokenTable from '../../../components/Tabletoken2';
// import CommentTable from '../../../components/TableComment';
// import { getPriority } from "os";

// const servicesInfo = [];
// let nMainCnt = 0, nSerCnt = 0, nTSubLoc = 0;
// const uuidv1 = require('uuid/v1');

const mainLocColor = ["#3F52E4", "#BA20C6", "#277397", "#6D2387", "#3E2A7A"];
const subLocColor = ["#A71B2D", "#CE5ACC", "#327B3E", "#2E3C67", "#9C5B71"];
const serviceColor = ["#3F52E4", "#A71B2D", "#BA20C6", "#CE5ACC", "#277397", "#327B3E", "#6D2387", "#2E3C67", "#3E2A7A", "#CE5ACC", "#BA20C6", "#327B3E", "#277397", "#9C5B71", "#3E2A7A", "#A71B2D"];
const insrcColor = ["#1C0845", "#EB0F52", "#620BF1", "#0BF13C", "#F1980B"];
// const servctrColor = ["#A71B2D", "#3E2A7A", "#9C5B71", "#277397", "#327B3E", "#7DDC67", "#CE5ACC", "#CE5ACC"];
// const servusrColor = ["#806DFF", "#A599FA", "#327B3E", "#BA20C6", "#ADBCD1", "#BEA5F0", "#A71B2D", "#3E2A7A", "#A66DE6", "#71479B", "#2A12CD", "#6358B0", "#04D5F7", "#1468DA", "#4B6FA0", "#684BA1"]
// const CustomerColor = ["#14F609", "#8CF886", "#031AFC", "#9C5B71", "#FA2E38"];
//const TokenColor = ["#052DF7", "#4764FA", "#8296FC", "#B2BDF6", "#6A6AA8"];

const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
}

class Dashboard1GeneralOverview extends React.Component {

    constructor(props) {

        super(props);
        if (!Firebase.apps.length) {
            Firebase.initializeApp(config);
            // const perf = Firebase.performance();
        }

        this.state = {
            matches: window.matchMedia("(min-width: 370px)").matches,
            dateRangeLimitSeconds: 5184000, // number of seconds in 1 day = 86400s
                                            // number of seconds in 30 days = (30 X 86400) = 2592000s
                                            // number of seconds in 60 days = (60 x 86400) = 5184000s
            dateRangeLimitDays:60,
            loading: false,

            defaultDate: {},
            bVisualize: false,
            start_date_formatted: '',
            end_date_formatted: '',
            defaultTimeZone: '',
            defaultTimeZoneSet: false,

            // bLoaded: false,
            // bdelete: false,
            // startDate: '',
            // endData: '',
            // main_count: 1,
            download_data: [],
            time_segment: [],
            wtime_segment: [],
            //customer_feedback: [],
            feedback_data: [],

            main_location_list: [],
            addressDefinitions: [],
            sub_location_list: [],
            subTemp: [],
            sub_location_ID: [],

            services: [],
            servTemp: [],
            stateOptions: [],

            counter_user: [],
            counter_user1: [],

            counteruser: [],
            custom_rating: [],
            custom_rating1: [],

            customrating: [],
            token_status: [],
            token_status1: [],

            tokenstatus: [],
            serv_Username: [],
            serv_Username1: [],

            servUsername: [],

            detailTokenData: [],
            dataLineChartML: [],

            data3DChartML: [],
            mainLocLabel: [],
            mainLocColor: [],

            dataLineChartSL: [],
            data3DChartSL: [],

            subLocLabel: [],
            subLocColor: [],

            dataLineChartServ: [],
            data3DChartServ: [],

            servLabel: [],
            servColor: [],

            input_srcName: [],
            inputsrcName: [],
            dataLineChartInSrc: [],
            data3DChartInSrc: [],
            InSrcLabel: [],
            InSrcColor: [],

            //dataLineChartServctr: [],
            //data3DChartServctr: [],
            //servctrLabel: [],
            //servctrColor: [],

            // dataLineChartServusr: [],
            // data3DChartServusr: [],
            // ServusrLabel: [],
            // ServusrColor: [],

            // dataLineChartCustomer: [],
            // data3DChartCustomer: [],
            // CustomerLabel: [],
            // CustomerColor: [],

            // dataLineChartToken: [],
            // data3DChartToken: [],
            // TokenLabel: [],
            // TokenColor: [],

            MLCnt: 0,
            SLCnt: 0,
            SVLCnt: 0,
            ILCnt: 0,
            SCLCnt: 0,
            SULCnt: 0,
            bdate: false,
            bmain: false,
            bsub: false,
            bservice: false,
            bUpdate: false,
            bUpdate1: false,
            bUpdate2: false,
            bUpdate3: false,
            bUpdate4: false,
            STime: null,
            longSTime: null,
            WTime: null,
            longWTime: null,

        };

        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangesub = this.handleChangesub.bind(this);
        this.handleChangeService = this.handleChangeService.bind(this);
        this.handleChangeCounterUser = this.handleChangeCounterUser.bind(this);
        this.handleChangecustomrating = this.handleChangecustomrating.bind(this);
        this.handleChangetokenstatus = this.handleChangetokenstatus.bind(this);
        this.handleChangeservUsername = this.handleChangeservUsername.bind(this);
    }

    componentDidMount() {
        if (!this.state.bVisualize) {
            this.setState({ loading: true });
            // this.loadDefaultDate();
            this.loadAccessLoc();
        }
        const handler = e => this.setState({ matches: e.matches });
        window.matchMedia("(min-width: 370px)").addListener(handler);
    }

    loadAccessLoc() {
        let _this = this;
        let access = [];
        let temp = [];
        // let i = 0;
        let email = JSON.parse(localStorage.getItem('auth_info')).email;
        Firebase.firestore().collection('Web_App_Users').doc(email).get().then(function (response) {
            if (response.exists) {
                access.push(response.data().Accessible_Locations);
            }
            // alert(access[8] + "//" + access[9] + "//" + access[10])
            for (let i = 0; i < access.length; i++) {
                let id = access[i];
                // temp.push({label:id});

                let len = id.length;
                for (let j = 0; j < len; j++) {
                    let subid = id[j];
                    // let b = _this.boolExist(temp, subid);
                    // if( b === 0)
                    temp.push({ label: subid });
                }
            }
            if (access.length > 0) {
                _this.loadSubLocationByMain(temp);
            }
        }, (error) => {
            console.log("loadAccessLoc Error ==> ", error);
        });
    }

    // componentDidUpdate(e) {
    // }

    handleChangeDate(event) {
        let _this = this;
        let dateObject = event.target.value;
        _this.setState({ defaultDate: dateObject });

        let defaultTimeZone = _this.state.defaultTimeZone;
        let defaultTimeZoneSet = _this.state.defaultTimeZoneSet;
        let startDateFmt = "", endDateFmt = "";

        if (defaultTimeZoneSet && _this.isValidJSDate(dateObject.start)) {
            startDateFmt = parseInt(moment(dateObject.start).tz(defaultTimeZone).unix());
            startDateFmt = (moment(new Date(startDateFmt * 1000)).tz(defaultTimeZone).format("YYYY-MM-DD")).toString(); 
            _this.setState({ start_date_formatted: startDateFmt });
        }

        if (defaultTimeZoneSet && _this.isValidJSDate(dateObject.end)) {
            endDateFmt = parseInt((moment(dateObject.end).tz(defaultTimeZone).endOf('day')).unix()) + 1;
            endDateFmt = (moment(new Date(endDateFmt * 1000)).tz(defaultTimeZone).format("YYYY-MM-DD")).toString(); 
            _this.setState({ end_date_formatted: endDateFmt });
        }

        console.log("handleChangeDate>>", "dateObject", dateObject, "defaultTimeZone", defaultTimeZone, "startDateFmt", startDateFmt, "endDateFmt", endDateFmt);
    }

    isValidJSDate(jsDateObj) {
        var timestamp = Date.parse(jsDateObj);
        if (!(isNaN(timestamp))) { return true; }
        return false;
    }

    loadMainLocations(temp) {
        let _this = this;
        // let access = temp;
        let main_locations = [];
        let mainTemp = [];
        let i = 0;
        let customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;

        Firebase.firestore().collection('Main_Locations').where('Customer_ID', '==', customer_id).get().then(function (response) {
            response.docs.forEach(function (doc) {
                main_locations.push({ value: doc.id, label: doc.data().Name });
            });

            for (i = 0; i < main_locations.length; i++) {
                let nn = main_locations[i].value;
                let b = _this.boolExist(temp, nn);
                if (b === 1)
                    mainTemp.push({ value: main_locations[i].value, label: main_locations[i].label })
            }

            if (mainTemp.length > 0) {
                _this.setState({ main_location_list: mainTemp, addressDefinitions: mainTemp });
                // _this.loadSubLocationByMain(temp, mainTemp);
            }
        }, (error) => {
            console.log("loadMainLocations Error===>", error);
        });
    }

    loadSubLocationByMain(temp) {
        let _this = this;
        let defaultTimeZone = _this.state.defaultTimeZone;
        let TT = [], main = [], main2 = [];
        let sub_locations = [];
        let i = 0;
        let customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;
        Firebase.firestore().collection('Sub_Locations').where('Customer_ID', '==', customer_id).get().then(function (response) {
            response.docs.forEach(function (doc) {
                sub_locations.push({ value: doc.id, label: doc.data().Name, mID: doc.data().Main_Location_ID, timezone: doc.data().TimeZone });
                if (defaultTimeZone == "" || defaultTimeZone == undefined || defaultTimeZone == null) {
                    let timeZoneParts = doc.data().TimeZone.split(")");
                    defaultTimeZone = timeZoneParts[1];
                    _this.setState({ defaultTimeZone: defaultTimeZone });
                    _this.setState({ defaultTimeZoneSet: true });
                    console.log("defaultTimeZone", defaultTimeZone, "defaultTimeZoneSet", true);
                    _this.loadDefaultDate();
                }
                main.push({ label: doc.data().Main_Location_ID });
            });
            for (i = 0; i < sub_locations.length; i++) {
                // let mm = sub_locations[i].mID;
                // let bb = _this.boolExistID(mainTemp, mm);
                // if( bb === 0 ) continue;

                let nn = sub_locations[i].value;
                let b = _this.boolExist(temp, nn);
                if (b === 1) {
                    TT.push({ value: sub_locations[i].value, label: sub_locations[i].label, mID: sub_locations[i].mID, timezone: sub_locations[i].timezone })
                    let mainId = sub_locations[i].mID;
                    let bb = _this.boolExist(main, mainId);
                    if (bb === 1)
                        main2.push(main[i])
                }
            }
            if (TT.length > 0) {
                _this.setState({ sub_location_list: TT, sub_location_ID: TT });
                _this.setState({ subTemp: TT });
                // // console.log("loadSubLocationByMain >>TT", TT);
                _this.loadMainLocations(main2);
                _this.loadServicesByMainAndSub(main2, TT);
            }
        }, (error) => {
            console.log("loadSubLocationByMain Error==>", error);
        });
    }

    loadServicesByMainAndSub(main, sub) {
        let _this = this;
        let servicesT = [];
        let Ser = [];
        let subValue = [];
        let customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;
        sub.map((subItem, index) => {
            subValue.push(subItem.value);
        });
        Firebase.firestore().collection('Services').where('Customer_ID', '==', customer_id).get().then(function (response) {
            response.docs.forEach(function (doc) {
                if (subValue.includes(doc.data().Sub_Location_ID)) {
                    // console.log("services ==> ", doc)
                    servicesT.push({ value: doc.id, label: doc.data().Name, mID: doc.data().Main_Location_ID, sID: doc.data().Sub_Location_ID });
                    // servicesInfo.push({value:doc.data().Sub_Location_ID, label: doc.data().Name,  date: Sec});
                }
            });

            for (let i = 0; i < servicesT.length; i++) {
                let mm = servicesT[i].mID;
                let bb = _this.boolExist(main, mm);
                if (bb === 0) continue;

                let nn = servicesT[i].sID;

                let b = _this.boolExistID(sub, nn);
                if (b === 1)
                    Ser.push(servicesT[i]);
            }
            if (Ser.length > 0) {
                // console.log("service===>", Ser);
                _this.setState({ services: Ser, servTemp: Ser, stateOptions: Ser });
                _this.setState({ loading: false });
                // _this.loadTokenData();
            } else {
                console.log("No Services..");
                _this.setState({loading: false});
            }

        }, (error) => {
            console.log("loadServicesByMainAndSub Error ===> ", error);
            _this.setState({ loading: false });
        });
        // })
    }

    loadDefaultDate() {
        let _this = this;
        let today = new Date();
        let y = today.getFullYear();
        let m = today.getMonth();
        let d = today.getDate();

        var dateObject = {
            start: new Date(y, m, 1),
            end: new Date(y, m, d)
        };

        _this.setState({ defaultDate: dateObject });

        let defaultTimeZone = _this.state.defaultTimeZone;
        let defaultTimeZoneSet = _this.state.defaultTimeZoneSet;
        let startDateFmt = "", endDateFmt = "";  

        if (defaultTimeZoneSet && _this.isValidJSDate(dateObject.start)) {
            startDateFmt = parseInt(moment(dateObject.start).tz(defaultTimeZone).unix());
            startDateFmt = (moment(new Date(startDateFmt * 1000)).tz(defaultTimeZone).format("YYYY-MM-DD")).toString(); 
            _this.setState({ start_date_formatted: startDateFmt });
        }

        if (defaultTimeZoneSet && _this.isValidJSDate(dateObject.end)) {
            endDateFmt = parseInt((moment(dateObject.end).tz(defaultTimeZone).endOf('day')).unix()) + 1;
            endDateFmt = (moment(new Date(endDateFmt * 1000)).tz(defaultTimeZone).format("YYYY-MM-DD")).toString(); 
            _this.setState({ end_date_formatted: endDateFmt });
        }

        console.log("loadDefaultDate>>", "dateObject", dateObject, "defaultTimeZone", defaultTimeZone, "startDateFmt", startDateFmt, "endDateFmt", endDateFmt);
    }

    getDeleteData(src, filter, color, name) {
        let len = src.length;
        let ret = [];
        for (let i = 0; i < len; i++) {
            for (let j = 0; j < filter.length; j++)
                if (src[i].label === filter[j].label) {
                    color.push(src[i].color);
                    name.push(src[i].label)
                    ret.push(src[i])
                }
        }
        return ret;

    }

    handleChange(selectedOption) {
        this.state.main_location_list = selectedOption;
        this.state.sub_location_list = [];
        this.setState({ main_location_list: selectedOption });
        this.setState({ sub_location_list: [] });
        this.LoadSubLocation();
        // if (this.state.bVisualize && selectedOption.length !== 0)
        // this.subfiltering(selectedOption, 1);
    }

    LoadSubLocation() {
        let _this = this;
        let SelOpt = _this.state.main_location_list;
        let nL = SelOpt.length;
        let sub = _this.state.subTemp;
        let subtt = [];
        _this.setState({ sub_location_list: [] });

        for (let j = 0; j < nL; j++) {
            for (let i = 0; i < sub.length; i++) {
                if (SelOpt[j].value === sub[i].mID)
                    subtt.push({ value: sub[i].value, label: sub[i].label, mID: sub[i].mID, timezone: sub[i].timezone });
            }
        }
        _this.state.sub_location_list = subtt;
        _this.setState({ sub_location_list: subtt, sub_location_ID: subtt });

        _this.LoadServNamesBySL();

    }

    handleChangesub(selectItem) {
        this.state.sub_location_list = selectItem;
        this.setState({ sub_location_list: selectItem });
        this.LoadServNamesBySL();

        // if (this.state.bVisualize && selectItem.length !== 0)
        //     this.subfiltering(selectItem, 2);
    }

    LoadServNamesBySL() {
        let _this = this;
        let SelOpt = _this.state.sub_location_list;
        let nL = SelOpt.length;

        let sub = _this.state.servTemp;
        let subtt = [];

        _this.setState({ services: [] });

        for (let j = 0; j < nL; j++)
            for (let i = 0; i < sub.length; i++)
                if (SelOpt[j].value === sub[i].sID)
                    subtt.push({ value: sub[i].value, label: sub[i].label, mID: sub[i].mID, sID: sub[i].sID });
        _this.state.services = subtt;
        _this.state.stateOptions = subtt;
        _this.setState({ services: subtt, stateOptions: subtt });

        // if( this.state.bVisualize )
        //     _this.handleChangeService(subtt);
    }

    handleChangeService(selectItemService) {
        this.state.services = selectItemService;
        this.setState({ services: selectItemService });
        // if (this.state.bVisualize && selectItemService.length !== 0)
        //     this.subfiltering(selectItemService, 3);
    }

    ResubFiltering(selectItemService) {
        let counter = this.state.counter_user1;
        let token = this.state.token_status1;
        let served = this.state.serv_Username1;
        let input = this.state.inputsrcName;

        let temp = this.getfilterName(counter, selectItemService);
        this.setState({ counter_user: temp });

        this.handleChangeCounterUser(temp);
        temp = this.getfilterName(token, selectItemService);
        this.setState({ token_status: temp });
        this.handleChangetokenstatus(temp);
        temp = this.getfilterName(served, selectItemService);
        this.setState({ serv_Username: temp });
        this.handleChangeservUsername(temp);
        temp = this.getfilterName(input, selectItemService);
        this.setState({ input_srcName: temp });
        this.handleChangeInputSrc(temp);


    }

    // handleChangeInputSrc(temp) {
    //     // if( this.state.bVisualize )
    //     // {
    //     //     let Ddata = this.state.data3DChartInSrc;
    //     //     let color= [];
    //     //     let name = [];
    //     //     let data = this.getDeleteData(Ddata, temp, color, name);
    //     //     this.setState({bUpdate4: true});
    //     //     sleep(0).then(() => {
    //     //         this.setState({bUpdate4: false, InSrcLabel: name, data3DChartInSrc1: data, InSrcColor : color});
    //     //     });
    //     // }
    // }

    getfilterName(n1, sv) {
        let temp = [];
        for (let i = 0; i < n1.length; i++) {
            if (this.boolExist(sv, n1[i].serID) === 0) continue;
            temp.push(n1[i]);
        }
        return temp;
    }

    handleChangeSubFiltering(Src, tar) {
        let temp = [];
        for (let i = 0; i < tar.length; i++) {
            let id = tar[i].value;
            let b = this.boolExistID(Src, id);
            if (b === 0) continue;

            temp.push(tar[i]);
        }

        return temp;
    }

    handleChangeMainFiltering(Src, tar, nCase) {
        let temp = [];
        for (let i = 0; i < tar.length; i++) {
            let id = tar[i].value;
            if (nCase === 3) id = tar[i].sID;
            let b = this.boolExistName(Src, id, nCase);
            if (b === 0) continue;

            temp.push(tar[i]);
        }
        return temp;
    }

    boolExistName(Src, id, nCase) {
        for (let i = 0; i < Src.length; i++) {
            let tt = null;
            if (nCase === 1) tt = Src[i].mID;
            else if (nCase === 2) tt = Src[i].sID;
            else if (nCase === 3) tt = Src[i].svID;
            if (id === tt) return 1;
        }
        return 0;
    }

    getSubChartData(select, ncase) {
        alert(JSON.stringify(select))

        let main = this.handleChangeMainFiltering(select, this.state.addressDefinitions, 1);
        let sub = this.handleChangeMainFiltering(select, this.state.sub_location_ID, 2);
        let service = this.handleChangeMainFiltering(select, this.state.stateOptions, 3);
        alert(JSON.stringify(service))

        let counter = this.handleChangeSubFiltering(select, this.state.counter_user1);
        let token = this.handleChangeSubFiltering(select, this.state.token_status1);
        let customer = this.handleChangeSubFiltering(select, this.state.custom_rating1);
        let input = this.handleChangeSubFiltering(select, this.state.inputsrcName);
        let served = this.handleChangeSubFiltering(select, this.state.serv_Username1);
        // sleep(1).then(() => {
        this.GetTokenDataByDay(main, sub, service, input, counter, served, customer, token, ncase);
        // });
    }

    getSubChartDataAll(select, id) {
        let Ddata = this.state.data3DChartServctr;
        let color = [], name = [], temp = [], data = [];
        if (id === 1) temp = this.state.counter_user1;
        else temp = this.state.counter_user;
        data = this.getDeleteData(Ddata, temp, color, name);
        this.setState({ counter_user: temp });

        this.setState({ bUpdate1: true });
        this.setState({ servctrLabel: name, data3DChartServctr1: data, servctrColor: color });

        color = []; name = []; temp = []; data = []; Ddata = [];
        id === 3 ? temp = this.state.token_status1 : temp = this.state.token_status;
        Ddata = this.state.data3DChartToken;
        data = this.getDeleteData(Ddata, temp, color, name);

        this.setState({ token_status: temp });
        this.setState({ bUpdate2: true });
        this.setState({ TokenLabel: name, data3DChartToken1: data, TokenColor: color });

        color = []; name = []; temp = []; data = []; Ddata = [];
        id === 2 ? temp = this.state.custom_rating1 : temp = this.state.custom_rating;
        Ddata = this.state.data3DChartCustomer;
        data = this.getDeleteData(Ddata, temp, color, name);
        this.setState({ custom_rating: temp });
        this.setState({ bUpdate: true });
        this.setState({ CustomerLabel: name, data3DChartCustomer1: data, CustomerColor: color });
        color = []; name = []; temp = []; data = []; Ddata = [];

        // temp = this.handleChangeSubFiltering(select, this.state.inputsrcName);
        // Ddata = this.state.data3DChartInSrc;
        // data = this.getDeleteData(Ddata, temp, color, name);
        // this.setState({input_srcName : temp});
        // this.setState({bUpdate4: true});
        // this.state.InSrcLabel = name;
        // this.state.data3DChartInSrc1 = data;
        // this.state.InSrcColor = color;
        // color= []; name = []; temp = []; data = []; Ddata = [];

        id === 4 ? temp = this.state.serv_Username1 : temp = this.state.serv_Username;
        Ddata = this.state.data3DChartServusr;
        data = this.getDeleteData(Ddata, temp, color, name);
        this.setState({ serv_Username: temp });
        this.setState({ bUpdate3: true });
        this.setState({ ServusrLabel: name, data3DChartServusr1: data, ServusrColor: color });

        if (name.length > 7)
            this.setState({ SULCnt: 600 + (name.length - 2) * 40 });
        else this.setState({ SULCnt: 500 });
        sleep(10).then(() => {
            this.setState({ bUpdate1: false });
            this.setState({ bUpdate2: false });
            this.setState({ bUpdate: false });

            this.setState({ bUpdate3: false, bUpdate4: false });
        });

    }

    handleChangeCounterUser(selected) {
        if (this.state.bVisualize && selected.length !== 0)
            this.subfiltering(selected, 4);
        this.setState({ counter_user: selected });

    }

    boolMainLoc(id) {
        let IDArr = this.state.main_location_list;

        let len = IDArr.length;

        for (let i = 0; i < len; i++) {
            let tt = IDArr[i].value;
            if (tt === id)
                return 1;
        }
        return 0;
    }

    boolSubLoc(id) {
        let IDArr = this.state.sub_location_list;

        let len = IDArr.length;
        for (let i = 0; i < len; i++) {
            let tt = IDArr[i].value;
            if (tt === id)
                return 1;
        }
        return 0;
    }

    boolService(id) {
        let IDArr = this.state.services;
        let nCnt = 0;
        let lenid = id.length;
        let len = IDArr.length;
        for (let j = 0; j < lenid; j++)
            for (let i = 0; i < len; i++) {
                let tt = IDArr[i].value;
                if (tt === id[j])
                    nCnt++;
                if (nCnt === lenid)
                    return 1;
            }

        return 0;
    }

    boolCounter(id) {
        let IDArr = this.state.counteruser;
        // let nCnt = 0;
        let len = IDArr.length;
        for (let i = 0; i < len; i++) {
            let tt = IDArr[i].value;
            if (tt === id)
                return 1;
        }

        return 0;
    }

    getTimezone(id) {
        let _this = this;
        let sub = _this.state.sub_location_list;
        for (let i = 0; i < sub.length; i++) {
            let tt = sub[i].timezone;
            let t = tt.split(")");

            if (id === sub[i].value)
                // console.log("t[1]", t[1]);
                return t[1];
        }
    }

    formatDate(date) {
        var d = new Date(date), month = '' + (d.getMonth() + 1), day = '' + d.getDate(), year = d.getFullYear();
        if (month.length < 2) { month = '0' + month } if (day.length < 2) { day = '0' + day }
        return [year, month, day].join('-');
    }

    notifyMessage = (place, color, text) => {
        var type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }

        var options = {};
        options = {
            place: place,
            message: (
                <div className="text-md-center">
                    <div>
                        <b>{text}</b>
                    </div>
                </div>
            ),
            type: type,
            icon: "now-ui-icons ui-1_bell-53",
            autoDismiss: 3
        };
        if (options !== null) { this.notificationAlert.notificationAlert(options) }
    };

    loadTokenData() {
        let _this = this,
            startDateFmt = _this.state.start_date_formatted, endDateFmt = _this.state.end_date_formatted;
        let subLocIDArrVals = _this.exportArrayValues(_this.state.sub_location_ID),
            servIDArrVals = _this.exportArrayValues(_this.state.services);
        let totalData = [];
        _this.setState({ detailTokenData: [] });
        let i = 0;
        console.log({
            "Currently running function:": "loadTokenData",
            start_date: startDateFmt,
            end_date: endDateFmt,
            sub_loc_arr: subLocIDArrVals,
            servs_arr: servIDArrVals,
            query_type: "aggr_1"
        });

        Firebase.functions().httpsCallable('getBigQueryAnalyticsData', { timeout: 540000 }) ({ //HttpsCallableOptions timeout in ms
            start_date: startDateFmt,
            end_date: endDateFmt,
            sub_loc_arr: subLocIDArrVals,
            servs_arr: servIDArrVals,
            query_type: "aggr_1"
        }).then(function (response) {

            console.log('response.data.length:' + response.data.length);
            // console.log("response.data", response.data);

                JSON.parse(response.data).forEach(function (doc) {
                    // console.logs(i, "doc",doc);
                    //if (_this.boolExistID(_this.state.services, doc.Services_ID) === 1) {
                    //let dateTimeString = (doc.Created_Date_Formated).toString();

                    totalData.push({
                        id: i, // doc.id, //ok?
                        date: doc.Created_Date_Formated.value, //ok?
                        day: doc.Created_Date_Formated.value, //ok?
                        mID: doc.Main_Location_ID, //ok
                        sID: doc.Sub_Location_ID, //ok
                        vID: doc.Services_ID, //ok
                        mainID: doc.Main_Location_Name, //ok
                        subID: doc.Sub_Location_Name, //ok
                        serID: doc.Service_Name, //ok
                        inputSrc: doc.Input_Source, //ok
                        ST: doc.Serving_Time, //ok
                        WT: doc.Waiting_Time, //ok
                        count: doc.Count //ok?
                    });

                    i++;
                    //}
                });

                if (totalData.length > 0) {
                    _this.setState({ loading: false });
                    //_this.setState({ detailTokenData: totalData });
                }
                //// console.log("totalData.length", totalData.length);
            // }
        }).then(function () {
            if (totalData.length > 0) {
                // _this.setState({ loading: false });
                console.log("reached here #3 success");
                _this.setState({ detailTokenData: totalData });
                //// console.log("totalData.length", totalData.length, "totalData", totalData)
                _this.subfiltering([], 0);
            }
            else {
                _this.setState({loading: false});
                _this.notifyMessage("tc", 3, "No data found for these selected dates, locations and services!");
            }
        }).catch(function (error) {
            _this.setState({ loading: false });
            console.log("reached here #4 error", error);
        });
        console.log("reached here #5 done");
    }

    // loadtokenDataO1() {
    //     let _this = this, totalData = [],
    //         start = _this.state.defaultDate.start, end = _this.state.defaultDate.end;
    //     let startSec = Date.parse(start) / 1000,
    //         endSec = Date.parse(end) / 1000;
    //     let startDateFmt = this.formatDate(start),
    //         endDateFmt = this.formatDate(end);
    //     let subLocIDArrVals = this.exportArrayValues(_this.state.sub_location_ID),
    //         servIDArrVals = this.exportArrayValues(_this.state.services);
    //     // console.log("reached here #1", "start", start, "end", end,
    //         "startSec", startSec, "endSec", endSec,
    //         "startDateFmt", startDateFmt, "endDateFmt", endDateFmt,
    //         '_this.state.stateOptions', _this.state.stateOptions, '_this.state.services', _this.state.services,
    //         'subLocIDArrVals', subLocIDArrVals, 'servIDArrVals', servIDArrVals,
    //     );
    //     _this.setState({ detailTokenData: [] });
    //     // let customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;

    //     Firebase.functions().httpsCallable('getDataBigquery')({
    //         start_date: startDateFmt, end_date: endDateFmt, sub_loc_arr: subLocIDArrVals, servs_arr: servIDArrVals, query_type: "aggr_1"
    //         // start_date:'2019-12-12', end_date:'2020-01-01', sub_loc_arr:['00e9dbb0-e1e4-11e9-b12f-a5173bbf44b8','9e0de6f0-e772-11e9-acb0-c97c1427b883'], servs_arr:['6fc71a80-e7bf-11e9-aaee-5d5c2544f5fa','3fa47500-012d-11ea-97d7-e39efc493bed']
    //     }).then(function (response) {

    //         // // console.log('data count:' + response.docs.length);
    //         // console.log("response1", response.data);
    //         JSON.parse(response.data).forEach(function (doc) {
    //             // console.logs(i, "doc",doc);
    //             if (_this.boolExistID(_this.state.services, doc.Services_ID) === 1) {
    //                 //let dateTimeString = (doc.Created_Date_Formated).toString();
    //                 let DayStr = doc.Created_Date_Formated;
    //                 let DayStr1 = DayStr.value;
    //                 let Day = (Date.parse(DayStr1)) / 1000; //new Date((dateTimeString.substring(0, 4),(dateTimeString.substring(5, 7),(dateTimeString.substring(8, 11))))); //Created_Datetime Created_Datetime_Unix_Timestamp
    //                 let ID2 = doc.Sub_Location_ID;
    //                 // console.log("doc.Created_Date_Formated", doc.Created_Date_Formated);
    //                 //// console.log("dateTimeString",Day);
    //                 // console.log("Day", Day);
    //                 let Sec = new Date(Day * 1000);
    //                 let ID1 = doc.Main_Location_ID;
    //                 let ID3 = doc.Services_ID;

    //                 let main = doc.Main_Location_Name;
    //                 let sub = doc.Sub_Location_Name;
    //                 let serv = doc.Service_Name;
    //                 // let sc = doc.Cancelled;
    //                 // let scd = doc.Service_Cancelled_Datetime;
    //                 // let mauser = doc.Mobile_App_User_Contact;
    //                 // let mauid = doc.Mobile_App_User_ID;
    //                 // let cc = doc.Customer_Comments;
    //                 // let cusID = doc.Customer_ID;
    //                 // let cusRDtime = doc.Customer_Rated_Datetime;
    //                 let name = doc.Customer_Rating;
    //                 // let cusScr = doc.Customer_Source;
    //                 // let counterN = doc.Served_Counter;
    //                 // let pt = doc.Priority;
    //                 // let ptStr = parseInt(pt) == 0 ? "Normal" : "High";
    //                 // let tcun = doc.Token_Created_User_Name;
    //                 //let tn = doc.Token_Number;
    //                 // let token = doc.Token_Status;
    //                 // let suname = doc.Served_User;
    //                 let inputsource = doc.Input_Source;

    //                 let st = doc.Serving_Time;
    //                 let wt = doc.Waiting_Time;
    //                 var a = [], seconds = 0;

    //                 if (st.length > 4) {
    //                     a = st.split(':');
    //                     seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
    //                 }
    //                 var b = parseInt(wt, 10);

    //                 totalData.push({
    //                     id: doc.id, date: Sec, Day: Day, mainID: main, mID: ID1, subID: sub, sID: ID2, serID: serv, vID: ID3, inputSrc: inputsource, ST: seconds, WT: b, label: name,
    //                     //token: token, sc: sc,
    //                     //SCDT: scd, MAUC: mauser, MAUI: mauid, CC: cc, CUSID: cusID, CUSRDT: cusRDtime, CUSSRC: cusScr, counteruser: counterN, servusr: suname, TCUN: tcun, TN: tn, Prio: ptStr, 
    //                 });
    //             }
    //         });

    //         if (totalData.length > 0) {
    //             _this.setState({ loading: false });
    //             _this.setState({ detailTokenData: totalData });
    //         }

    //     }).then(function () {
    //         // _this.setState({ loading: false });
    //         console.log("reached here #3 success");
    //         _this.subfiltering([], 0);

    //     }).catch(function (error) {
    //         _this.setState({ loading: false });
    //         // console.log("reached here #4 error", error);
    //     });
    //     // console.log("reached here #5 done");
    // }

    // loadtokenDataO2() {
    //     let _this = this;
    //     let totalData = [];
    //     let start = _this.state.defaultDate.start;
    //     let end = _this.state.defaultDate.end;
    //     let startSec = Date.parse(start) / 1000;
    //     let endSec = Date.parse(end) / 1000;
    //     // console.log("reached here #1", "start", start, "end", end, "startSec", startSec, "endSec", endSec);
    //     _this.state.detailTokenData = [];

    //     let customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;
    //     Firebase.firestore().collection('Token_Details_Archive')
    //         .where('Customer_ID', '==', customer_id)
    //         .where('Created_Datetime_Unix_Timestamp', '>=', startSec)
    //         .where('Created_Datetime_Unix_Timestamp', '<=', endSec)
    //         .get().then(function (response) {
    //             let i = 0, timez = '';
    //             response.docs.forEach(function (doc) {
    //                 // console.logs(i, "doc",doc);
    //                 var Day = doc.data().Created_Datetime_Unix_Timestamp; //Created_Datetime
    //                 // var str = Day.substring(0, 3); let m = 0; switch (str) { case "Jan": m = 0; break; case "Feb": m = 1; break; case "Mar": m = 2; break; case "Apr": m = 3; break; case "May": m = 4; break; case "Jun": m = 5; break; case "Jul": m = 6; break; case "Aug": m = 7; break; case "Sep": m = 8; break; case "Oct": m = 9; break; case "Nov": m = 10; break; case "Dec": m = 11; break; }
    //                 // str = Day.substring(4, 7); let d = parseInt(str, 10); str = Day.substring(7, 12); let y = parseInt(str, 10); str = Day.substring(16, 19); let hh = parseInt(str, 10); str = Day.substring(18, 20); let mm = parseInt(str, 10); str = Day.substring(21, 24); let ss = parseInt(str, 10); 
    //                 let ID2 = doc.data().Sub_Location_ID;
    //                 // if (i == 0) { 
    //                 //     timez = _this.getTimezone(ID2); 
    //                 // }
    //                 // console.log("reached here #2");
    //                 // Day = new Date(y, m, d, hh, mm, ss).toLocaleString("en-US", { timeZone: timez }); let Sec = new Date(Day); let sec = Date.parse(Sec) / 1000;
    //                 let Sec = new Date(Day * 1000);
    //                 // let sec = new Date(Day).getTime() / 1000;
    //                 // if (sec > startSec && sec < endSec)
    //                 {
    //                     let ID1 = doc.data().Main_Location_ID;
    //                     let ID3 = doc.data().Services_ID;

    //                     let main = doc.data().Main_Location_Name;
    //                     let sub = doc.data().Sub_Location_Name;
    //                     let serv = doc.data().Service_Name;
    //                     let scd = doc.data().Service_Cancelled_Datetime;
    //                     let mauser = doc.data().Mobile_App_User_Contact;
    //                     let mauid = doc.data().Mobile_App_User_ID;
    //                     let cc = doc.data().Customer_Comments;
    //                     let cusID = doc.data().Customer_ID;
    //                     let cusRDtime = doc.data().Customer_Rated_Datetime;
    //                     let name = doc.data().Customer_Rating;
    //                     let cusScr = doc.data().Customer_Source;
    //                     let counterN = doc.data().Served_Counter;
    //                     let pt = doc.data().Priority;
    //                     let ptStr = parseInt(pt) == 0 ? "Normal" : "High";
    //                     let tcun = doc.data().Token_Created_User_Name;
    //                     let tn = doc.data().Token_Number;
    //                     let token = doc.data().Token_Status;
    //                     let suname = doc.data().Served_User_Name;
    //                     let inputsource = doc.data().Input_Source;

    //                     let st = doc.data().Serving_Time;
    //                     let wt = doc.data().Waiting_Time;
    //                     var a = [], seconds = 0;

    //                     if (st.length > 4) {
    //                         a = st.split(':'); // split it at the colons
    //                         seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
    //                     }
    //                     var b = parseInt(wt, 10);
    //                     if (_this.boolExistID(_this.state.stateOptions, ID3) === 1)
    //                         // totalData.push({
    //                         //     id: doc.id, date: Sec, Day: Day, mainID: main, mID: ID1, subID: sub, sID: ID2, serID: serv, vID: ID3, SCDT: scd,
    //                         //     MAUC: mauser, MAUI: mauid, CC: cc, CUSID: cusID, CUSRDT: cusRDtime, label: name, CUSSRC: cusScr, inputSrc: inputsource,
    //                         //     counteruser: counterN, servusr: suname, TCUN: tcun, TN: tn, token: token,
    //                         //     ST: seconds, WT: b, Prio: ptStr
    //                         // });
    //                         totalData.push({
    //                             id: doc.id, date: Sec, Day: Day, mainID: main, mID: ID1, subID: sub, sID: ID2, serID: serv, vID: ID3, inputSrc: inputsource, ST: seconds, WT: b, token: token,
    //                             SCDT: scd, MAUC: mauser, MAUI: mauid, CC: cc, CUSID: cusID, CUSRDT: cusRDtime, CUSSRC: cusScr, counteruser: counterN, servusr: suname, TCUN: tcun, TN: tn, Prio: ptStr, label: name,
    //                         });
    //                 }
    //                 i++;
    //             });


    //             // 
    //             if (totalData.length > 0) {
    //                 _this.setState({ loading: false });
    //                 _this.setState({ detailTokenData: totalData });
    //                 // for test
    //                 // _this.subfiltering(totalData, 0);                
    //             }

    //         }).then(function () {
    //             // _this.setState({ loading: false });
    //             console.log("reached here #3 success");
    //             _this.subfiltering([], 0);

    //         }).catch(function (error) {
    //             _this.setState({ loading: false });
    //             // console.log("reached here #4 error", error);
    //         });
    //     // console.log("reached here #5 done");
    // }

    handleChangetokenstatus(select) {
        if (this.state.bVisualize && select.length !== 0)
            this.subfiltering(select, 6);

        this.setState({ token_status: select });
    }

    handleChangecustomrating(select) {
        if (this.state.bVisualize && select.length !== 0)
            this.subfiltering(select, 5);

        this.setState({ custom_rating: select });
        // this.setState({custom_rating : select});
    }

    handleChangeservUsername(select) {
        // if( select.length  === 0 )
        if (this.state.bVisualize && select.length !== 0)
            this.subfiltering(select, 7);
        this.setState({ serv_Username: select });

    }

    onVisualize() {
        let _this = this;
        if (!_this.state.bVisualize) {
            let start = _this.state.defaultDate.start, end = _this.state.defaultDate.end;
            // let startSec = Date.parse(start) / 1000, endSec = Date.parse(end) / 1000;
            let startSec = Date.parse(start) / 1000, endSec = (Date.parse(end) + 86400) / 1000; // number of seconds in 1 day = 86400s
            // let startDateFmt = _this.formatDate(start), endDateFmt = _this.formatDate(end);
            if ((endSec - startSec) > (_this.state.dateRangeLimitSeconds)) {
                _this.setState({ loading: false });
                _this.notifyMessage("tc", 3, `Please select a date range less than ${_this.state.dateRangeLimitDays} days!`);
            } else {
                _this.setState({ loading: true });
                // // console.log("selected Segmentation Type:", _this.state.segmentationType);
                _this.loadTokenData();
                // _this.subfiltering([], 0);
            }
        }
    }

    mainFiltering() {
        let total = this.state.detailTokenData;
        let len = total.length;
        let main = [], sub = [], service = [];
        for (let i = 0; i < len; i++) {
            let b1 = this.boolExist(main, total[i].mainID);
            let b2 = this.boolExist(sub, total[i].subID);
            let b3 = this.boolExist(service, total[i].serID);

            if (b1 === 1 && b2 === 1 && b3 === 1) continue;

            if (b1 === 0) {
                main.push({ value: total[i].id, label: total[i].mainID, mID: total[i].mID, sID: total[i].sID, vID: total[i].vID });
                // addressDefinitions.push({value : total[i].id, label: total[i].mainID, mID: total[i].mID, sID: total[i].sID, vID : total[i].vID });
            }
            if (b2 === 0)
                sub.push({ value: total[i].id, label: total[i].subID, mID: total[i].mID, sID: total[i].sID, vID: total[i].vID });
            if (b3 === 0)
                service.push({ value: total[i].id, label: total[i].serID, mID: total[i].mID, sID: total[i].sID, vID: total[i].vID });
        }

        this.setState({ main_location_list: main, addressDefinitions: main, sub_location_list: sub, subTemp: sub, services: service, servTemp: service, sub_location_ID: sub, stateOptions: service });
    }

    boolExist(temp, name) {
        let bN = 0;
        for (let j = 0; j < temp.length; j++) {
            if (name === temp[j].label) {
                bN = 1;
                break;
            }
        }
        return bN;
    }

    boolExistID(temp, name) {
        let bN = 0;
        for (let j = 0; j < temp.length; j++) {
            if (name === temp[j].value) {
                bN = 1;
                break;
            }
        }
        return bN;
    }

    exportArrayValues(temp) {
        let result = [];
        for (let j = 0; j < temp.length; j++) {
            result.push(temp[j].value);
        }
        return result;
    }

    subfiltering(select, ncase) {

        let _this = this;

        var originData =  _this.state.detailTokenData;
        // console.log(originData, 'originData');
        var groups = _.groupBy(originData, 'date');
        var dateGroupByAgg = _.map(groups, function(value, key) {
            return {
                date: key,
                count: _.reduce(value, function(total, o) {
                    return total + o.count;
                }, 0)
            };
        });

        let Src = _this.state.detailTokenData;
        //console.log('dateGroupByAggL: ', dateGroupByAgg); >V_IMP
        //console.log('Src: ', Src); >V_IMP


        let identy = null;
        let len = Src.length;
        // alert(len)
        // if( len === 0 )
        // {
        //     _this.setState({loading: false})
        //     return;
        // }
        let main = [], sub = [], service = [];
        let downloaddata = [], returndata = [], commentsData =[];
        let Counter = [], Token = [], Served = [], Input = [], custom = [];
        for (let i = 0; i < len; i++) {
            if (ncase !== 0) {
                switch (ncase) {
                    case 1: identy = Src[i].mainID; break;
                    case 2: identy = Src[i].subID; break;
                    case 3: identy = Src[i].serID; break;
                    case 4: identy = Src[i].counteruser; break;
                    case 5: identy = Src[i].label; break;
                    case 6: identy = Src[i].token; break;
                    case 7: identy = Src[i].servusr; break;
                }
                if (_this.boolExist(select, identy) === 0) continue;
            }

            let ID1 = Src[i].mID;
            let ID2 = Src[i].sID;
            let ID3 = Src[i].vID;
            let main1 = [], sub1 = [], service1 = [];
            main1 = Src[i].mainID;
            sub1 = Src[i].subID;
            service1 = Src[i].serID;

            let token = Src[i].token;

            let srcName = Src[i].inputSrc;
            let cus = Src[i].label;
            let st = _this.sec2time(Src[i].ST);
            let wt = _this.sec2time(Src[i].WT);

            // let servStartDate = Src[i].servstdate; //new
            // let servedUserID = Src[i].servuid; //new
            let servedName = Src[i].servusr; //new
            let counter = Src[i].counteruser; //new?
            let count = Src[i].count //new new


            main1 = Src[i].mainID;
            sub1 = Src[i].subID;
            service1 = Src[i].serID;
            downloaddata.push({
                Created_DateTime: Src[i].day, //ok //Created_DateTime: this.timeStampToDateTimeConvert(Src[i].Day), 
                Main_Location_Name:ID1, //ok
                sub: ID2, //ok
                serv: ID3, //ok
                Input_Source: srcName, //ok
                Serving_Time: st, //ok
                Waiting_Time: wt,//ok
                count: count //ok?
            });

            //// console.log("mauc", mauc, "mauid", mauid, "maue", maue, "maun", maun,"rdtime", rdtime,"rcom", rcom,"rating", rating, "Rating", Src[i].label);
            let b1 = _this.boolExist(Counter, counter);
            let b2 = _this.boolExist(Token, token);
            let b3 = _this.boolExist(Served, servedName);
            let b4 = _this.boolExist(Input, srcName);
            let b5 = _this.boolExist(custom, cus);
            let bm = _this.boolExist(main, main1);
            let bs = _this.boolExist(sub, sub1);
            let bv = _this.boolExist(service, service1);
            if (bm === 0)
                main.push({ value: Src[i].id, label: main1, mID: ID1, sID: ID2, svID: ID3, count: count });
            if (bs === 0)
                sub.push({ value: Src[i].id, label: sub1, mID: ID1, sID: ID2, svID: ID3, count: count });
            if (bv === 0)
                service.push({ value: Src[i].id, label: service1, mID: ID1, sID: ID2, svID: ID3, count: count });
            if (b1 === 0)
                Counter.push({ value: Src[i].id, label: counter, mID: ID1, sID: ID2, svID: ID3, count: count });
            if (b2 === 0)
                Token.push({ value: Src[i].id, label: token, mID: ID1, sID: ID2, svID: ID3, count: count });
            if (b3 === 0)
                Served.push({ value: Src[i].id, label: servedName, mID: ID1, sID: ID2, serID: ID3, count: count });
            if (b4 === 0)
                Input.push({ value: Src[i].id, label: srcName, mID: ID1, sID: ID2, svID: ID3, count: count });
            if (b5 === 0)
                custom.push({ value: Src[i].id, label: cus, mID: ID1, sID: ID2, svID: ID3, count: count });

            returndata.push(Src[i]);
            // console.log("Src[i]", Src[i])
        }

        _this.setState({ download_data: downloaddata });
        //_this.setState({ feedback_data: commentsData });
        if (!_this.state.bVisualize) {
            _this.setState({ custom_rating: custom });

            _this.setState({ counter_user: Counter });
            _this.setState({ token_status: Token });
            _this.setState({ serv_Username: Served });
            _this.setState({ input_srcName: Input });
            _this.setState({ custom_rating: custom });

            _this.setState({ counter_user1: Counter });
            _this.setState({ token_status1: Token });
            _this.setState({ serv_Username1: Served });
            _this.setState({ input_srcName1: Input });
            _this.setState({ custom_rating1: custom });
        }

        //console.log("main loc: ", main)
        if (main.length > 0) {
            _this.GetTokenDataByDay(main, sub, service, Input, Counter, Served, custom, Token, returndata, dateGroupByAgg);
        }
        // _this.setState({ loading: false });
    }

    timeStampToDateTimeConvert(UNIX_timestamp){
        var a = new Date(UNIX_timestamp * 1000);
        var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var hour = a.getHours();
        var min = a.getMinutes();
        var sec = a.getSeconds();
        var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
        return time;
    }

    getLineChartDataByDaily(mainCnt, lenMain, label, sM, eM, sD, eD, nDaysOfMonth, strMonth) {

        //// console.log("getLineChartDataByDaily","mainCnt",mainCnt, "lenMain",lenMain, "label",label, "sM", sM, "eM", eM, "sD", sD, "eD", eD,"nDaysOfMonth", nDaysOfMonth, "strMonth", strMonth )
        let OriginLineMLoc = [];
        if (sM === eM) {
            for (let i = sD; i <= eD; i++) {
                let date = strMonth[sM - 1] + i;
                let bZero = 0;
                let value = [];
                let result_temp = {};
                result_temp['date'] = date;
                for (let ii = 0; ii < lenMain; ii++) {
                    value[ii] = mainCnt[i * lenMain + ii];
                    if (value[ii] !== 0)
                        bZero = 1;
                }
                if (bZero === 0) continue;

                for (let ii = 0; ii < lenMain; ii++) {
                    let name = label[ii];
                    value[ii] = mainCnt[i * lenMain + ii];
                    if (value[ii] !== 0)
                        result_temp[name] = value[ii];

                }

                OriginLineMLoc.push(result_temp);
            }
        }
        else {
            for (let i = sD; i <= nDaysOfMonth; i++) {
                let date = strMonth[sM - 1] + i;
                let bZero = 0;
                let value = [];
                let result_temp = {};
                result_temp['date'] = date;
                for (let ii = 0; ii < lenMain; ii++) {
                    value[ii] = mainCnt[i * lenMain + ii];
                    if (value[ii] !== 0)
                        bZero = 1;
                }
                if (bZero === 0) continue;

                for (let ii = 0; ii < lenMain; ii++) {
                    let name = label[ii];
                    value[ii] = mainCnt[i * lenMain + ii];
                    if (value[ii] !== 0)
                        result_temp[name] = value[ii];

                }

                OriginLineMLoc.push(result_temp);
            }

            for (let i = 1; i < eD; i++) {
                let date = strMonth[eM - 1] + i;
                let bZero = 0;
                let value = [];
                let result_temp = {};
                result_temp['date'] = date;
                for (let ii = 0; ii < lenMain; ii++) {
                    value[ii] = mainCnt[i * lenMain + ii];
                    if (value[ii] !== 0)
                        bZero = 1;
                }
                if (bZero === 0) continue;

                for (let ii = 0; ii < lenMain; ii++) {
                    let name = label[ii];
                    value[ii] = mainCnt[i * lenMain + ii];
                    if (value[ii] !== 0)
                        result_temp[name] = value[ii];

                }

                OriginLineMLoc.push(result_temp);
            }
        }


        //console.log('OriginLineMLoc data: ', OriginLineMLoc); >V_IMP
        return OriginLineMLoc;
    }

    getLineChartDataByMonthly(mainCnt, lenMain, label, sY, eY, sM, eM, strMonth) {
        let OriginLineMLoc = [];
        if (sY === eY) {
            for (let i = sM; i < eM + 1; i++) {
                let date = strMonth[i - 1];
                let value = [];
                let result_temp = {};
                result_temp['date'] = date;

                for (let ii = 0; ii < lenMain; ii++) {
                    let name = label[ii];
                    value[ii] = mainCnt[i * lenMain + ii];
                    if (value[ii] !== 0)
                        result_temp[name] = value[ii];

                }

                OriginLineMLoc.push(result_temp);
            }
        }
        else {
            for (let i = sM; i < 13; i++) {
                let date = strMonth[sM - 1];
                let value = [];
                let result_temp = {};
                result_temp['date'] = date;

                for (let ii = 0; ii < lenMain; ii++) {
                    let name = label[ii];
                    value[ii] = mainCnt[i * lenMain + ii];
                    if (value[ii] !== 0)
                        result_temp[name] = value[ii];
                }

                OriginLineMLoc.push(result_temp);
            }
            // this.setState({loading : true});

            for (let i = 1; i < eM; i++) {
                let date = strMonth[eM - 1];
                let value = [];
                let result_temp = {};
                result_temp['date'] = date;

                for (let ii = 0; ii < lenMain; ii++) {
                    let name = label[ii];
                    value[ii] = mainCnt[i * lenMain + ii];
                    if (value[ii] !== 0)
                        result_temp[name] = value[ii];
                }

                OriginLineMLoc.push(result_temp);
            }
        }
        return OriginLineMLoc;
    }

    pad(num, size) {
        return ('000' + num).slice(size * -1);
    }

    sec2time(time) {
        // var pad = function(num, size) { return ('000' + n?um).slice(size * -1); },
        // time = parseFloat(timeInSeconds).toFixed(3),
        let hours = Math.floor(time / 60 / 60);
        let minutes = Math.floor(time / 60) % 60;
        let seconds = Math.floor(time - minutes * 60);

        return this.pad(hours, 2) + ':' + this.pad(minutes, 2) + ':' + this.pad(seconds, 2);
    }

    GetTokenDataByDay(mainLoc, subLoc, servLoc, insrcLoc, servctrLoc, ServusrLoc, CustomerLoc, TokenLoc, originData, dateGroupByAgg) {
        if (originData.length === 0)
            return;
        let mainCnt = [0,], subCnt = [0,], servCnt = [0,], insrcCnt = [], servctrCnt = [], ServusrCnt = [], CustomerCnt = [], TokenCnt = [];
        let subTcnt = [0,], mainTCnt = [0,], servTcnt = [0,], insrcTcnt = [], servctrTcnt = [], ServusrTcnt = [], CustomerTcnt = [], TokenTcnt = [];

        let start = this.state.defaultDate.start;
        let end = this.state.defaultDate.end;

        let sY = start.getFullYear();
        let sM = start.getMonth() + 1;
        let sD = start.getDate();

        let eY = end.getFullYear();
        let eM = end.getMonth() + 1;
        let eD = end.getDate();

        let d = new Date(sY, sM, 0);
        let nDaysOfMonth = d.getDate();

        var one_day = 1000 * 60 * 60 * 24
        if (sM == 11 && sD > 25)
            end.setFullYear(eY + 1)

        // To Calculate the result in milliseconds and then converting into days 
        var Result = Math.round(end.getTime() - start.getTime()) / (one_day);

        // To remove the decimals from the (Result) resulting days value 
        var Final_Result = Result.toFixed(0);

        let strMonth = ["Jan ", "Feb ", "Mar ", "Apr ", "May ", "Jun ", "Jul ", "Aug ", "Sep ", "Oct ", "Nov ", "Dec "];

        let lenMain = mainLoc.length;
        let lenSub = subLoc.length;
        let lenServ = servLoc.length;
        let lenInsrc = insrcLoc.length;
        // let lenServctr = servctrLoc.length;
        // let lenServusr = ServusrLoc.length;
        // let lenCustomer = CustomerLoc.length;
        // let lenToken = TokenLoc.length;
        // initializing counts with 0.....//////////////////////        
        for (let ii = 0; ii < 31 * lenMain; ii++) {
            mainCnt[ii] = 0;
            mainTCnt[ii] = 0;
        }

        for (let ii = 0; ii < 31 * lenSub; ii++) {
            subCnt[ii] = 0;
            subTcnt[ii] = 0;
        }
        for (let ii = 0; ii < 31 * lenServ; ii++) {
            servCnt[ii] = 0;
            servTcnt[ii] = 0;
        }
        for (let ii = 0; ii < 31 * lenInsrc; ii++) {
            insrcCnt[ii] = 0;
            insrcTcnt[ii] = 0;
        }
        // for (let ii = 0; ii < 31 * lenServctr; ii++) {
        //     servctrCnt[ii] = 0;
        //     servctrTcnt[ii] = 0;
        // }
        // for (let ii = 0; ii < 31 * lenServusr; ii++) {
        //     ServusrCnt[ii] = 0;
        //     ServusrTcnt[ii] = 0;
        // }
        // for (let ii = 0; ii < 31 * lenCustomer; ii++) {
        //     CustomerCnt[ii] = 0;
        //     CustomerTcnt[ii] = 0;
        // }
        // for (let ii = 0; ii < 31 * lenToken; ii++) {
        //     TokenCnt[ii] = 0;
        //     TokenTcnt[ii] = 0;
        // }
        // variables declare for labels and colors ////////////////////
        let label = [], labelsub = [], labelserv = [], labelinsrc = [], labelservctr = [], labelServusr = [], labelCustomer = [], labelToken = [];
        //let count = [], countsub = [], countserv = [], countinsrc = [], countservctr = [], countServusr = [], countCustomer = [], countToken = [];
        let color = [], colorsub = [], colorserv = [], colorinsrc = [], colorservctr = [], colorServusr = [], colorCustomer = [], colorToken = [];
        /////// get mainloclabel and color ///////////////////////
        let labelChcnt = 0;
        for (let k = 0; k < lenMain; k++) {
            label[k] = mainLoc[k].label;
            color[k] = am4core.color(mainLocColor[k % 5]);
            //count[k] = mainLoc[k].count;
            labelChcnt += mainLoc[k].count;
        }


        this.setState({ mainLocLabel: label, mainLocColor: color })

        ///////// get subloclabel and color /////////////////////
        labelChcnt = 0;
        for (let k = 0; k < lenSub; k++) {
            labelsub[k] = subLoc[k].label;
            colorsub[k] = am4core.color(subLocColor[k % 5]);
            labelChcnt += labelsub[k].length;
        }
        this.setState({ SLCnt: 500 + (lenSub - 2) * 40, subLocLabel: labelsub, subLocColor: colorsub });
        /////// get services label and color ////////////////////
        labelChcnt = 0;
        for (let k = 0; k < lenServ; k++) {
            labelserv[k] = servLoc[k].label;
            colorserv[k] = am4core.color(serviceColor[k % 16]);
            labelChcnt += labelserv[k].length;
        }
        if (lenServ > 7)
            this.setState({ SVLCnt: 500 + (lenServ - 2) * 40 });
        else this.setState({ SVLCnt: 500 });
        this.setState({ servLabel: labelserv, servColor: colorserv });
        /////// get Input source label and color ////////////////
        for (let k = 0; k < lenInsrc; k++) {
            labelinsrc[k] = insrcLoc[k].label;
            colorinsrc[k] = am4core.color(insrcColor[k % 5]);
        }
        this.setState({ InSrcLabel: labelinsrc, InSrcColor: colorinsrc });
        //         ///// get Input source label and color ////////////////
        //         for (let k = 0; k < lenServctr; k++) {
        //             labelservctr[k] = servctrLoc[k].label;
        //             colorservctr[k] = am4core.color(servctrColor[k % 8]);
        //         }
        //         this.setState({ servctrLabel: labelservctr, servctrColor: colorservctr })
        //         /////// get Served User label and color ////////////////
        //         labelChcnt = 0;
        //         for (let k = 0; k < lenServusr; k++) {
        //             labelServusr[k] = ServusrLoc[k].label;
        //             colorServusr[k] = am4core.color(servusrColor[k % 16]);
        //             labelChcnt += labelServusr[k].length;

        //         }
        //         if (lenServusr > 7)
        //             this.setState({ SULCnt: 600 + (lenServusr - 2) * 40 });
        //         else this.setState({ SULCnt: 500 });
        //         this.setState({ ServusrLabel: labelServusr, ServusrColor: colorServusr });
        //         /////// get Customer rating label and color ////////////////
        //         for (let k = 0; k < lenCustomer; k++) {
        //             labelCustomer[k] = CustomerLoc[k].label;
        // //// console.log("labelCustomer[k]",labelCustomer[k]);
        //             colorCustomer[k] = am4core.color(CustomerColor[k % 5]);
        //         }

        //         this.setState({ CustomerLabel: labelCustomer, CustomerColor: colorCustomer });
        //         /////// get Token Status label and color ////////////////
        //         for (let k = 0; k < lenToken; k++) {
        //             labelToken[k] = TokenLoc[k].label;
        //             colorToken[k] = am4core.color(TokenColor[k % 5]);
        //         }
        //         this.setState({ TokenLabel: labelToken, TokenColor: colorToken });

        // /////// variables for charts /////////////////////
        let OriginMLoc = [], OriginSLoc = [], OriginServLoc = [], OriginInsrcLoc = [], OriginservctrLoc = [], OriginServusrLoc = [], OriginCustomerLoc = [], OriginTokenLoc = [];
        let OriginLineMLoc = [], OriginLineSLoc = [], OriginLineServLoc = [], OriginLineInsrcLoc = [], OriginLineservctrLoc = [], OriginLineServusrLoc = [], OriginLineCustomerLoc = [], OriginLineTokenLoc = [];

        // let AveStime = 0, AveWtime = 0, lWtime = 0, lStime = 0, ss = [], pp = [], No = [], cc = [];
        // let ws = [], wp = [], wn = [], wc = [];
        // let sSeg1 = [], sSeg2 = [], sSeg3 = [], sSeg4 = [], sSeg5 = [], sSeg6 = [], sSeg7 = [];
        // for (let kk = 0; kk < 7; kk++) {
        //     ss[kk] = 0; ws[kk] = 0;
        //     pp[kk] = 0; wp[kk] = 0;
        //     No[kk] = 0; wn[kk] = 0;
        //     cc[kk] = 0; wc[kk] = 0;
        // }

        // console.log('originData[i].date', dateGroupByAgg[0].date)
        // var xx= dateGroupByAgg.filter(x=> x.date === dateGroupByAgg[0].date);
        // console.log('xx', xx.length);
        // console.log('xx', xx.first());

        // logic (calculate)
        // var dz = '2020-01-22';
        // var subID = 'Dhiraagu Head Office – Male’';
        // console.log('dz = ' + dz); //returns 403
        // console.log('subID = ' + subID); //returns 403
        // console.log(originData.filter(x=> x.date === dz && x.subID === subID).length);
        // if(originData.filter(x=> x.date === dz && x.subID === subID).length > 0){
        //     let totalScores = originData.filter(x=> x.date === dz && x.subID === subID).reduce((prev, curr)=> {
        //         // console.log('prev ', prev.count);
        //         // console.log('curr ', curr.count);
        //         return prev +  parseInt(curr.count || 0);
        //     }, 0);
        //     console.log('totalScores = ' + totalScores); //returns 403  
        // }

        /// Get data for chart drawing /////////////////////////////        
        if (Final_Result <= 31) {
            for (let i = 0; i < originData.length; i++) {
                let Day = originData[i].date;

                let DD = Day.substring(8, 10); //Day.getDate(); 
                //console.log('DD', DD); >V_IMP
                let mainID = originData[i].mainID;
                let subID = originData[i].subID;
                let servID = originData[i].serID;
                let insrcName = originData[i].inputSrc;
                // let servctrName = originData[i].counteruser;
                // let ServusrName = originData[i].servusr;
                // let CustomerName = originData[i].label;
                // let TokenName = originData[i].token;

                for (let j = 0; j < lenMain; j++) {
                    // console.log('mainLoc[j].label', mainLoc[j].label);
                    if (mainID === mainLoc[j].label) {
                        let nn = DD * lenMain + j;

                        if(originData.filter(x=> x.date === Day && x.mainID === mainID).length > 0){
                            let totalCntA = originData.filter(x=> x.date === Day && x.mainID === mainID).reduce(
                                (prev, curr)=> prev +  parseInt(curr.count || 0),
                                0);
                            mainCnt[nn] = totalCntA;
                        }

                        if(originData.filter(x=> x.mainID === mainID).length > 0){
                            let totalCntB = originData.filter(x=> x.mainID === mainID).reduce(
                                (prev, curr)=> prev +  parseInt(curr.count || 0),
                                0);
                            mainTCnt[j] = totalCntB;
                        }
                        // mainCnt[nn]++; mainTCnt[j]++;
                    }
                }
                for (let j = 0; j < lenSub; j++) {
                    if (subID === subLoc[j].label) {
                        let nn = DD * lenSub + j;

                        if(originData.filter(x=> x.date === Day && x.subID === subID).length > 0){
                            let totalCntA = originData.filter(x=> x.date === Day && x.subID === subID).reduce(
                                (prev, curr)=> prev +  parseInt(curr.count || 0),
                                0);
                            subCnt[nn] = totalCntA;
                        }

                        if(originData.filter(x=> x.subID === subID).length > 0){
                            let totalCntB = originData.filter(x=> x.subID === subID).reduce(
                                (prev, curr)=> prev +  parseInt(curr.count || 0),
                                0);
                            subTcnt[j] = totalCntB;
                        }
                        // subCnt[nn]++; subTcnt[j]++;
                    }
                }
                for (let j = 0; j < lenServ; j++) {
                    if (servID === servLoc[j].label) {
                        let nn = DD * lenServ + j;

                        if(originData.filter(x=> x.date === Day && x.serID === servID).length > 0){
                            let totalCntA = originData.filter(x=> x.date === Day && x.serID === servID).reduce(
                                (prev, curr)=> prev +  parseInt(curr.count || 0),
                                0);
                            servCnt[nn] = totalCntA;
                        }

                        if(originData.filter(x=> x.serID === servID).length > 0){
                            let totalCntB = originData.filter(x=> x.serID === servID).reduce(
                                (prev, curr)=> prev +  parseInt(curr.count || 0),
                                0);
                            servTcnt[j] = totalCntB;
                        }
                        // servCnt[nn]++; servTcnt[j]++;
                    }
                }
                for (let j = 0; j < lenInsrc; j++) {
                    if (insrcName === insrcLoc[j].label) {
                        let nn = DD * lenInsrc + j;

                        if(originData.filter(x=> x.date === Day && x.inputSrc === insrcName).length > 0){
                            let totalCntA = originData.filter(x=> x.date === Day && x.inputSrc === insrcName).reduce(
                                (prev, curr)=> prev +  parseInt(curr.count || 0),
                                0);
                            insrcCnt[nn] = totalCntA;
                        }

                        if(originData.filter(x=> x.inputSrc === insrcName).length > 0){
                            let totalCntB = originData.filter(x=> x.inputSrc === insrcName).reduce(
                                (prev, curr)=> prev +  parseInt(curr.count || 0),
                                0);
                            insrcTcnt[j] = totalCntB;
                        }
                        // insrcCnt[nn]++; insrcTcnt[j]++;
                    }
                }
                // for (let j = 0; j < lenServctr; j++) {
                //     if (servctrName === servctrLoc[j].label) {
                //         let nn = DD * lenServctr + j;
                //         servctrCnt[nn]++;
                //         servctrTcnt[j]++;
                //     }
                // }
                // for (let j = 0; j < lenServusr; j++) {
                //     if (ServusrName === ServusrLoc[j].label) {
                //         let nn = DD * lenServusr + j;
                //         ServusrCnt[nn]++;
                //         ServusrTcnt[j]++;
                //     }
                // }
                // for (let j = 0; j < lenCustomer; j++) {
                //     if (CustomerName === CustomerLoc[j].label) {
                //         let nn = DD * lenCustomer + j;

                //         CustomerCnt[nn]++;
                //         CustomerTcnt[j]++;
                //     }
                // }

                // for (let j = 0; j < lenToken; j++) {
                //     if (TokenName === TokenLoc[j].label) {
                //         let nn = DD * lenToken + j;
                //         TokenCnt[nn]++;
                //         TokenTcnt[j]++;

                //         if (TokenName.toLowerCase() === "served") {
                //             if (ost <= 300) ss[0]++;
                //             else if (ost <= 600) ss[1]++;
                //             else if (ost <= 900) ss[2]++;
                //             else if (ost <= 1200) ss[3]++;
                //             else if (ost <= 1500) ss[4]++;
                //             else if (ost <= 1800) ss[5]++;
                //             else ss[6]++;

                //             if (wst <= 300) ws[0]++;
                //             else if (wst <= 600) ws[1]++;
                //             else if (wst <= 900) ws[2]++;
                //             else if (wst <= 1200) ws[3]++;
                //             else if (wst <= 1500) ws[4]++;
                //             else if (wst <= 1800) ws[5]++;
                //             else ws[6]++;
                //         } else if (TokenName.toLowerCase() === "pending") {
                //             if (ost <= 300) pp[0]++;
                //             else if (ost <= 600) pp[1]++;
                //             else if (ost <= 900) pp[2]++;
                //             else if (ost <= 1200) pp[3]++;
                //             else if (ost <= 1500) pp[4]++;
                //             else if (ost <= 1800) pp[5]++;
                //             else pp[6]++;

                //             if (wst <= 300) wp[0]++;
                //             else if (wst <= 600) wp[1]++;
                //             else if (wst <= 900) wp[2]++;
                //             else if (wst <= 1200) wp[3]++;
                //             else if (wst <= 1500) wp[4]++;
                //             else if (wst <= 1800) wp[5]++;
                //             else wp[6]++;
                //         } else if (TokenName.toLowerCase() === "no show") {
                //             if (ost <= 300) No[0]++;
                //             else if (ost <= 600) No[1]++;
                //             else if (ost <= 900) No[2]++;
                //             else if (ost <= 1200) No[3]++;
                //             else if (ost <= 1500) No[4]++;
                //             else if (ost <= 1800) No[5]++;
                //             else No[6]++;

                //             if (wst <= 300) wn[0]++;
                //             else if (wst <= 600) wn[1]++;
                //             else if (wst <= 900) wn[2]++;
                //             else if (wst <= 1200) wn[3]++;
                //             else if (wst <= 1500) wn[4]++;
                //             else if (wst <= 1800) wn[5]++;
                //             else wn[6]++;
                //         } else if (TokenName.toLowerCase() === "closed") {
                //             if (ost <= 300) cc[0]++;
                //             else if (ost <= 600) cc[1]++;
                //             else if (ost <= 900) cc[2]++;
                //             else if (ost <= 1200) cc[3]++;
                //             else if (ost <= 1500) cc[4]++;
                //             else if (ost <= 1800) cc[5]++;
                //             else cc[6]++;

                //             if (wst <= 300) wc[0]++;
                //             else if (wst <= 600) wc[1]++;
                //             else if (wst <= 900) wc[2]++;
                //             else if (wst <= 1200) wc[3]++;
                //             else if (wst <= 1500) wc[4]++;
                //             else if (wst <= 1800) wc[5]++;
                //             else wc[6]++;
                //         }
                //     }

                // }
            }

            // /// Serving time , long serving time, wating time, long waiting time ////
            // let ttemp = Math.floor(AveStime / originData.length);
            // let ti = this.sec2time(ttemp);
            // ttemp = Math.floor(AveWtime / originData.length);
            // let wi = this.sec2time(ttemp);
            // ttemp = Math.floor(lStime);
            // let ls = this.sec2time(ttemp);
            // ttemp = Math.floor(lWtime);
            // let lw = this.sec2time(ttemp);
            // this.setState({ longSTime: ls, STime: ti, longWTime: lw, WTime: wi });

            // sSeg1 = { "id": 1, "Interval": '5 minutes and less', "Served": ss[0], "Pending": pp[0], "NoShow": No[0], "Closed": cc[0] };
            // sSeg2 = { "id": 2, "Interval": "5 to 10 minutes", "Served": ss[1], "Pending": pp[1], "NoShow": No[1], "Closed": cc[1] };
            // sSeg3 = { "id": 3, "Interval": "10 to 15 minutes", "Served": ss[2], "Pending": pp[2], "NoShow": No[2], "Closed": cc[2] };
            // sSeg4 = { "id": 4, "Interval": "15 to 20 minutes", "Served": ss[3], "Pending": pp[3], "NoShow": No[3], "Closed": cc[3] };
            // sSeg5 = { "id": 5, "Interval": "20 to 25 minutes", "Served": ss[4], "Pending": pp[4], "NoShow": No[4], "Closed": cc[4] };
            // sSeg6 = { "id": 6, "Interval": "25 to 30 minutes", "Served": ss[5], "Pending": pp[5], "NoShow": No[5], "Closed": cc[5] };
            // sSeg7 = { "id": 7, "Interval": "Above 30 minutes", "Served": ss[6], "Pending": pp[6], "NoShow": No[6], "Closed": cc[6] };

            // let segment = [sSeg1, sSeg2, sSeg3, sSeg4, sSeg5, sSeg6, sSeg7];
            // this.setState({ time_segment: segment });
            // segment = [];
            // sSeg1 = { "id": 1, "Interval": '5 minutes and less', "Served": ws[0], "Pending": wp[0], "NoShow": wn[0], "Closed": wc[0] };
            // sSeg2 = { "id": 2, "Interval": "5 to 10 minutes", "Served": ws[1], "Pending": wp[1], "NoShow": wn[1], "Closed": wc[1] };
            // sSeg3 = { "id": 3, "Interval": "10 to 15 minutes", "Served": ws[2], "Pending": wp[2], "NoShow": wn[2], "Closed": wc[2] };
            // sSeg4 = { "id": 4, "Interval": "15 to 20 minutes", "Served": ws[3], "Pending": wp[3], "NoShow": wn[3], "Closed": wc[3] };
            // sSeg5 = { "id": 5, "Interval": "20 to 25 minutes", "Served": ws[4], "Pending": wp[4], "NoShow": wn[4], "Closed": wc[4] };
            // sSeg6 = { "id": 6, "Interval": "25 to 30 minutes", "Served": ws[5], "Pending": wp[5], "NoShow": wn[5], "Closed": wc[5] };
            // sSeg7 = { "id": 7, "Interval": "Above 30 minutes", "Served": ws[6], "Pending": wp[6], "NoShow": wn[6], "Closed": wc[6] };

            // segment = [sSeg1, sSeg2, sSeg3, sSeg4, sSeg5, sSeg6, sSeg7];
            // this.setState({ wtime_segment: segment });
            // segment = [];

            // setting up som
            //console.log('subLoc',subLoc); >V_IMP


            // this.setState({ feedback_data: cFeedback1 });
            /////////////////////// MainLoc3DChartData by Day /////////////////////////////////
            for (let i = 0; i < lenMain; i++)
                OriginMLoc.push({ label: label[i], value: mainTCnt[i], color: color[i] });
            this.setState({ data3DChartML: OriginMLoc });
            // MainLocLineChartData By Day //
            OriginLineMLoc = this.getLineChartDataByDaily(mainCnt, lenMain, label, sM, eM, sD, eD, nDaysOfMonth, strMonth);
            // console.log("getLineChartDataByDaily","mainCnt",mainCnt, "lenMain",lenMain, "label",label, "sM", sM, "eM", eM, "sD", sD, "eD", eD,"nDaysOfMonth", nDaysOfMonth, "strMonth", strMonth )
            this.setState({ dataLineChartML: OriginLineMLoc });

            ////////////////////// SubLoc3DChartData By Day////////////////////////////////////
            for (let i = 0; i < lenSub; i++)
                OriginSLoc.push({ label: labelsub[i], value: subTcnt[i], color: colorsub[i] });
            this.setState({ data3DChartSL: OriginSLoc });
            // SubLocLineChartData By Day //
            OriginLineSLoc = this.getLineChartDataByDaily(subCnt, lenSub, labelsub, sM, eM, sD, eD, nDaysOfMonth, strMonth);
            this.setState({ dataLineChartSL: OriginLineSLoc });

            /////////////////////// serv3DChartData by Day /////////////////////////////////
            for (let i = 0; i < lenServ; i++)
                OriginServLoc.push({ label: labelserv[i], value: servTcnt[i], color: colorserv[i] });
            this.setState({ data3DChartServ: OriginServLoc });
            // ServiceLineChartData By Day //
            OriginLineServLoc = this.getLineChartDataByDaily(servCnt, lenServ, labelserv, sM, eM, sD, eD, nDaysOfMonth, strMonth);
            this.setState({ dataLineChartServ: OriginLineServLoc });

            /////////// Input Source By day  //////////////////////////////////////////////
            for (let i = 0; i < lenInsrc; i++)
                OriginInsrcLoc.push({ label: labelinsrc[i], value: insrcTcnt[i], color: colorinsrc[i] });
            this.setState({ data3DChartInSrc: OriginInsrcLoc });
            // InputSourceLineChartData By Day //
            OriginLineInsrcLoc = this.getLineChartDataByDaily(insrcCnt, lenInsrc, labelinsrc, sM, eM, sD, eD, nDaysOfMonth, strMonth);
            this.setState({ dataLineChartInSrc: OriginLineInsrcLoc });

            /////////// Counter User By day  //////////////////////////////////////////////
            // for (let i = 0; i < lenServctr; i++)
            //     OriginservctrLoc.push({ label: labelservctr[i], value: servctrTcnt[i], color: colorservctr[i] });
            // this.setState({ data3DChartServctr: OriginservctrLoc });
            // OriginLineservctrLoc = this.getLineChartDataByDaily(servctrCnt, lenServctr, labelservctr, sM, eM, sD, eD, nDaysOfMonth, strMonth);
            // this.setState({ dataLineChartServctr: OriginLineservctrLoc });

            /////////// Counter User By day  //////////////////////////////////////////////
            // for (let i = 0; i < lenServusr; i++)
            //     OriginServusrLoc.push({ label: labelServusr[i], value: ServusrTcnt[i], color: colorServusr[i] });
            // this.setState({ data3DChartServusr: OriginServusrLoc });
            // // CounterUserLineChartData By Day //
            // OriginLineServusrLoc = this.getLineChartDataByDaily(ServusrCnt, lenServusr, labelServusr, sM, eM, sD, eD, nDaysOfMonth, strMonth);
            // this.setState({ dataLineChartServusr: OriginLineServusrLoc });
            //// console.log("la")
            /////////// Custom Rating By day  //////////////////////////////////////////////
            // for (let i = 0; i < lenCustomer; i++)
            //     OriginCustomerLoc.push({ label: labelCustomer[i], value: CustomerTcnt[i], color: colorCustomer[i] });
            // this.setState({ data3DChartCustomer: OriginCustomerLoc });
            // // CustomerLineChartData By Day //
            // OriginLineCustomerLoc = this.getLineChartDataByDaily(CustomerCnt, lenCustomer, labelCustomer, sM, eM, sD, eD, nDaysOfMonth, strMonth);
            // this.setState({ dataLineChartCustomer: OriginLineCustomerLoc });

            /////////// Token Status By day  //////////////////////////////////////////////
            // for (let i = 0; i < lenToken; i++)
            //     OriginTokenLoc.push({ label: labelToken[i], value: TokenTcnt[i], color: colorToken[i] });
            // this.setState({ data3DChartToken: OriginTokenLoc });
            // // TokenStatusLineChartData By Day //
            // OriginLineTokenLoc = this.getLineChartDataByDaily(TokenCnt, lenToken, labelToken, sM, eM, sD, eD, nDaysOfMonth, strMonth);
            // this.setState({ dataLineChartToken: OriginLineTokenLoc });

        }
        else {
            for (let i = 0; i < originData.length; i++) {
                // if( identy.length !== 0 )
                // {
                //     let nameID = null;
                //     switch(ncase)
                //     {
                //         case 1 : nameID = originData[i].counteruser; break;
                //         case 2 : nameID = originData[i].label; break;
                //         case 3 : nameID = originData[i].token; break;
                //         case 4 : nameID = originData[i].servusr; break;
                //     }
                //     if( this.boolExist(identy, nameID) === 0 ) continue;
                // }
                let Day = originData[i].date;

                // let ost = parseInt(originData[i].ST, 10);
                // let wst = parseInt(originData[i].WT, 10);
                // AveStime += ost;
                // if (lStime < ost)
                //     lStime = ost;

                // AveWtime += wst;
                // if (lWtime < wst) lWtime = wst;

                // let YY = Day.getFullYear();
                let DD = Day.getMonth() + 1;

                let mainID = originData[i].mainID;
                let subID = originData[i].subID;
                let servID = originData[i].serID;
                let insrcName = originData[i].inputSrc;
                // let servctrName = originData[i].counteruser;
                // let ServusrName = originData[i].servusr;
                // let CustomerName = originData[i].label;
                // let TokenName = originData[i].token;

                for (let j = 0; j < lenMain; j++) {
                    if (mainID === mainLoc[j].label) {
                        let nn = DD * lenMain + j;
                        mainCnt[nn]++;
                        mainTCnt[j]++;
                    }
                }
                for (let j = 0; j < lenSub; j++) {
                    if (subID === subLoc[j].label) {
                        let nn = DD * lenSub + j;
                        subCnt[nn]++;
                        subTcnt[j]++;
                    }
                }
                for (let j = 0; j < lenServ; j++) {
                    if (servID === servLoc[j].label) {
                        let nn = DD * lenServ + j;
                        servCnt[nn]++;
                        servTcnt[j]++;
                    }
                }
                for (let j = 0; j < lenInsrc; j++) {
                    if (insrcName === insrcLoc[j].label) {
                        let nn = DD * lenInsrc + j;
                        insrcCnt[nn]++;
                        insrcTcnt[j]++;
                    }
                }
                // for (let j = 0; j < lenServctr; j++) {
                //     if (servctrName === servctrLoc[j].label) {
                //         let nn = DD * lenServctr + j;
                //         servctrCnt[nn]++;
                //         servctrTcnt[j]++;
                //     }
                // }
                // for (let j = 0; j < lenServusr; j++) {
                //     if (ServusrName === ServusrLoc[j].label) {
                //         let nn = DD * lenServusr + j;
                //         ServusrCnt[nn]++;
                //         ServusrTcnt[j]++;
                //     }
                // }
                // for (let j = 0; j < lenCustomer; j++) {
                //     if (CustomerName === CustomerLoc[j].label) {
                //         let nn = DD * lenCustomer + j;
                //         CustomerCnt[nn]++;
                //         CustomerTcnt[j]++;
                //     }
                // }
                // for (let j = 0; j < lenToken; j++) {
                //     if (TokenName === TokenLoc[j].label) {
                //         let nn = DD * lenToken + j;
                //         TokenCnt[nn]++;
                //         TokenTcnt[j]++;

                //         if (TokenName.toLowerCase() === "served") {
                //             if (ost <= 300) ss[0]++;
                //             else if (ost <= 600) ss[1]++;
                //             else if (ost <= 900) ss[2]++;
                //             else if (ost <= 1200) ss[3]++;
                //             else if (ost <= 1500) ss[4]++;
                //             else if (ost <= 1800) ss[5]++;
                //             else ss[6]++;

                //             if (wst <= 300) ws[0]++;
                //             else if (wst <= 600) ws[1]++;
                //             else if (wst <= 900) ws[2]++;
                //             else if (wst <= 1200) ws[3]++;
                //             else if (wst <= 1500) ws[4]++;
                //             else if (wst <= 1800) ws[5]++;
                //             else ws[6]++;
                //         } else if (TokenName.toLowerCase() === "pending") {
                //             if (ost <= 300) pp[0]++;
                //             else if (ost <= 600) pp[1]++;
                //             else if (ost <= 900) pp[2]++;
                //             else if (ost <= 1200) pp[3]++;
                //             else if (ost <= 1500) pp[4]++;
                //             else if (ost <= 1800) pp[5]++;
                //             else pp[6]++;

                //             if (wst <= 300) wp[0]++;
                //             else if (wst <= 600) wp[1]++;
                //             else if (wst <= 900) wp[2]++;
                //             else if (wst <= 1200) wp[3]++;
                //             else if (wst <= 1500) wp[4]++;
                //             else if (wst <= 1800) wp[5]++;
                //             else wp[6]++;
                //         } else if (TokenName.toLowerCase() === "no show") {
                //             if (ost <= 300) No[0]++;
                //             else if (ost <= 600) No[1]++;
                //             else if (ost <= 900) No[2]++;
                //             else if (ost <= 1200) No[3]++;
                //             else if (ost <= 1500) No[4]++;
                //             else if (ost <= 1800) No[5]++;
                //             else No[6]++;

                //             if (wst <= 300) wn[0]++;
                //             else if (wst <= 600) wn[1]++;
                //             else if (wst <= 900) wn[2]++;
                //             else if (wst <= 1200) wn[3]++;
                //             else if (wst <= 1500) wn[4]++;
                //             else if (wst <= 1800) wn[5]++;
                //             else wn[6]++;
                //         } else if (TokenName.toLowerCase() === "closed") {
                //             if (ost <= 300) cc[0]++;
                //             else if (ost <= 600) cc[1]++;
                //             else if (ost <= 900) cc[2]++;
                //             else if (ost <= 1200) cc[3]++;
                //             else if (ost <= 1500) cc[4]++;
                //             else if (ost <= 1800) cc[5]++;
                //             else cc[6]++;

                //             if (wst <= 300) wc[0]++;
                //             else if (wst <= 600) wc[1]++;
                //             else if (wst <= 900) wc[2]++;
                //             else if (wst <= 1200) wc[3]++;
                //             else if (wst <= 1500) wc[4]++;
                //             else if (wst <= 1800) wc[5]++;
                //             else wc[6]++;
                //         }
                //     }

                // }
            }
            // // get serving time, long serving time, waiting time, long waiting time //////
            // let ttemp = Math.floor(AveStime / originData.length);
            // let ti = this.sec2time(ttemp);
            // ttemp = Math.floor(AveWtime / originData.length);
            // let wi = this.sec2time(ttemp);
            // ttemp = Math.floor(lStime);
            // let ls = this.sec2time(ttemp);
            // ttemp = Math.floor(lWtime);
            // let lw = this.sec2time(ttemp);
            // this.setState({ longSTime: ls, STime: ti, longWTime: lw, WTime: wi });

            // sSeg1 = { "Interval": "5 minutes and less", "Served": ss[0], "Pending": pp[0], "No Show": No[0], "Closed": cc[0] };
            // sSeg2 = { "Interval": "5 minutes and less", "Served": ss[1], "Pending": pp[1], "No Show": No[1], "Closed": cc[1] };
            // sSeg3 = { "Interval": "5 minutes and less", "Served": ss[2], "Pending": pp[2], "No Show": No[2], "Closed": cc[2] };
            // sSeg4 = { "Interval": "5 minutes and less", "Served": ss[3], "Pending": pp[3], "No Show": No[3], "Closed": cc[3] };
            // sSeg5 = { "Interval": "5 minutes and less", "Served": ss[4], "Pending": pp[4], "No Show": No[4], "Closed": cc[4] };
            // sSeg6 = { "Interval": "5 minutes and less", "Served": ss[5], "Pending": pp[5], "No Show": No[5], "Closed": cc[5] };
            // sSeg7 = { "Interval": "5 minutes and less", "Served": ss[6], "Pending": pp[6], "No Show": No[6], "Closed": cc[6] };

            // let segment = [sSeg1, sSeg2, sSeg3, sSeg4, sSeg5, sSeg6, sSeg7];
            // this.setState({ time_segment: segment });
            // segment = [];

            // sSeg1 = { "id": 1, "Interval": '5 minutes and less', "Served": ws[0], "Pending": wp[0], "NoShow": wn[0], "Closed": wc[0] };
            // sSeg2 = { "id": 2, "Interval": "5 to 10 minutes", "Served": ws[1], "Pending": wp[1], "NoShow": wn[1], "Closed": wc[1] };
            // sSeg3 = { "id": 3, "Interval": "10 to 15 minutes", "Served": ws[2], "Pending": wp[2], "NoShow": wn[2], "Closed": wc[2] };
            // sSeg4 = { "id": 4, "Interval": "15 to 20 minutes", "Served": ws[3], "Pending": wp[3], "NoShow": wn[3], "Closed": wc[3] };
            // sSeg5 = { "id": 5, "Interval": "20 to 25 minutes", "Served": ws[4], "Pending": wp[4], "NoShow": wn[4], "Closed": wc[4] };
            // sSeg6 = { "id": 6, "Interval": "25 to 30 minutes", "Served": ws[5], "Pending": wp[5], "NoShow": wn[5], "Closed": wc[5] };
            // sSeg7 = { "id": 7, "Interval": "Above 30 minutes", "Served": ws[6], "Pending": wp[6], "NoShow": wn[6], "Closed": wc[6] };

            // segment = [sSeg1, sSeg2, sSeg3, sSeg4, sSeg5, sSeg6, sSeg7];
            // this.setState({ wtime_segment: segment });
            // segment = [];
            /////////////////////// MainLoc3DChartData by Month /////////////////////////////////
            for (let i = 0; i < lenMain; i++)
                OriginMLoc.push({ label: label[i], value: mainTCnt[i], color: color[i] });
            this.setState({ data3DChartML: OriginMLoc });
            // MainLocLineChartData by Month //
            OriginLineMLoc = this.getLineChartDataByMonthly(mainCnt, lenMain, label, sY, eY, sM, eM, strMonth);
            this.setState({ dataLineChartML: OriginLineMLoc });

            ////////////////////// SubLoc3DChartData by Month////////////////////////////////////
            for (let i = 0; i < lenSub; i++)
                OriginSLoc.push({ label: labelsub[i], value: subTcnt[i], color: colorsub[i] });
            this.setState({ data3DChartSL: OriginSLoc });
            // SubLocLineChartData by Month //
            OriginLineSLoc = this.getLineChartDataByMonthly(subCnt, lenSub, labelsub, sY, eY, sM, eM, strMonth);
            this.setState({ dataLineChartSL: OriginLineSLoc });

            /////////////////////// ServLoc3DChartData by Month /////////////////////////////////
            for (let i = 0; i < lenServ; i++)
                OriginServLoc.push({ label: labelserv[i], value: servTcnt[i], color: colorserv[i] });
            this.setState({ data3DChartServ: OriginServLoc });
            // ServLocLineChartData by Month //
            OriginLineServLoc = this.getLineChartDataByMonthly(servCnt, lenServ, labelserv, sY, eY, sM, eM, strMonth);
            this.setState({ dataLineChartServ: OriginLineServLoc });

            /////////// Input Source By day  //////////////////
            /////////////////////// InsrcLoc3DChartData by Month /////////////////////////////////
            for (let i = 0; i < lenInsrc; i++)
                OriginInsrcLoc.push({ label: labelinsrc[i], value: insrcTcnt[i], color: colorinsrc[i] });
            this.setState({ data3DChartInSrc: OriginInsrcLoc });
            // ServLocLineChartData by Month //
            OriginLineInsrcLoc = this.getLineChartDataByMonthly(insrcCnt, lenInsrc, labelinsrc, sY, eY, sM, eM, strMonth);
            this.setState({ dataLineChartInSrc: OriginLineInsrcLoc });

            /////////////////////// ServctrLoc3DChartData by Month /////////////////////////////////
            // for (let i = 0; i < lenServctr; i++)
            //     OriginservctrLoc.push({ label: labelservctr[i], value: servctrTcnt[i], color: colorservctr[i] });
            // this.setState({ data3DChartServctr: OriginservctrLoc });
            // // ServctrLocLineChartData by Month //
            // OriginLineservctrLoc = this.getLineChartDataByMonthly(servctrCnt, lenServctr, labelservctr, sY, eY, sM, eM, strMonth);
            // this.setState({ dataLineChartServctr: OriginLineservctrLoc });

            // /////////////////////// ServusrLoc3DChartData by Month /////////////////////////////////
            // for (let i = 0; i < lenServusr; i++)
            //     OriginServusrLoc.push({ label: labelServusr[i], value: ServusrTcnt[i], color: colorServusr[i] });
            // this.setState({ data3DChartServusr: OriginServusrLoc });
            // // ServusrLocLineChartData by Month //
            // OriginLineServusrLoc = this.getLineChartDataByMonthly(ServusrCnt, lenServusr, labelServusr, sY, eY, sM, eM, strMonth);
            // this.setState({ dataLineChartServusr: OriginLineServusrLoc });

            // /////////////////////// CustomerLoc3DChartData by Month /////////////////////////////////
            // for (let i = 0; i < lenCustomer; i++)
            //     OriginCustomerLoc.push({ label: labelCustomer[i], value: CustomerTcnt[i], color: colorCustomer[i] });
            // this.setState({ data3DChartCustomer: OriginCustomerLoc });
            // // ServusrLocLineChartData by Month //
            // OriginLineCustomerLoc = this.getLineChartDataByMonthly(CustomerCnt, lenCustomer, labelCustomer, sY, eY, sM, eM, strMonth);
            // this.setState({ dataLineChartCustomer: OriginLineCustomerLoc });
            // /////////////////////// TokenStatusLoc3DChartData by Month /////////////////////////////////
            // for (let i = 0; i < lenToken; i++)
            //     OriginTokenLoc.push({ label: labelToken[i], value: TokenTcnt[i], color: colorToken[i] });
            // this.setState({ data3DChartToken: OriginTokenLoc });
            // // TokenLocLineChartData by Month //
            // OriginLineTokenLoc = this.getLineChartDataByMonthly(TokenCnt, lenToken, labelToken, sY, eY, sM, eM, strMonth);
            // this.setState({ dataLineChartToken: OriginLineTokenLoc });

        }

        sleep(2000).then(() => {
            this.setState({ bmain: true, bsub: true, bservice: true, bUpdate1: true, bUpdate2: true, bUpdate: true, bUpdate4: true, bUpdate3: true });
        });

        if (OriginMLoc.length > 0)
            sleep(2001).then(() => {
                this.setState({ loading: false });
                this.setState({ bVisualize: true, bUpdate1: false, bUpdate2: false, bUpdate3: false, bUpdate4: false, bUpdate: false, bmain: false, bsub: false, bservice: false, bdate: false });
            });


    }

    onShowCounter() {
        // this.state.counter_user = this.state.counter_user1;
        let tt = this.state.counter_user1;
        this.setState({ counter_user: tt })
        if (this.state.bVisualize)
            this.subfiltering(tt, 4);
    }

    onShowToken() {
        let tt = this.state.token_status1;
        this.setState({ token_status: tt });
        if (this.state.bVisualize)
            this.subfiltering(tt, 6);
    }

    onShowUserName() {
        let tt = this.state.serv_Username1;
        this.setState({ serv_Username: tt })
        if (this.state.bVisualize)
            this.subfiltering(tt, 7);

    }

    onShowCustomer() {
        let tt = this.state.custom_rating1;
        this.setState({ custom_rating: tt })
        if (this.state.bVisualize)
            this.subfiltering(tt, 5);
    }

    //V^6@}J*s
    render() {
        const { dataLineChartToken, data3DChartToken, TokenColor, TokenLabel, feedback_data, main_location_list, sub_location_list, services, defaultDate } = { ...this.state };
        //       this.setState({dataLineChartToken:this.state.dataLineChartToken});
        // const feedback_data = this.getfeedbackData();
        // const {
        // allowedMaxDays,
        // allowedDays,
        // allowedRange,
        // beforeToday,
        // afterToday,
        // combine
        // } = DateRangePicker;

        return (
            <>
                <LoadingOverlay
                    active={this.state.loading}
                    spinner
                    text='Loading'
                    className='content'

                >
                    <NotificationAlert ref={(ref) => this.notificationAlert = ref} />
                    <Row>
                        <Col md="12">
                            <Card className="content">
                                <CardHeader>
                                    <h4 className="padding_Dashboard card-title">Dashboard - General Overview</h4>
                                    {/* <CardTitle tag="h4">Dashboard - General Overview</CardTitle> */}
                                </CardHeader>
                                <CardBody >
                                    <div className="padding_Card card-body">
                                        <div className="blockquote blockquote-primary padding">
                                            <Row>
                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <h5>Select Date Range</h5>

                                                    {/*<div id="daterangepicker" title="daterangepicker"></div>*/}
                                                    <DateRangePicker
                                                        value={this.state.defaultDate}
                                                        onChange={this.handleChangeDate}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <h5>Select Main Location</h5>
                                                    <Select
                                                        placeholder='Select Main Location'
                                                        closeMenuOnSelect={true}
                                                        value={this.state.main_location_list}
                                                        isMulti
                                                        options={this.state.addressDefinitions}
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <h5>Select Sub Location</h5>
                                                    <Select
                                                        placeholder='Select Sub Location'
                                                        closeMenuOnSelect={true}
                                                        value={this.state.sub_location_list}
                                                        clearValue
                                                        isMulti
                                                        options={this.state.sub_location_ID}
                                                        onChange={this.handleChangesub}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <h5>Select Services</h5>
                                                    <Select
                                                        placeholder='Select Services'
                                                        closeMenuOnSelect={true}
                                                        value={this.state.services}
                                                        isClearable
                                                        clearValue
                                                        isMulti
                                                        options={this.state.stateOptions}
                                                        onChange={(selectItemService) => this.handleChangeService(selectItemService)}
                                                    />
                                                </div>
                                                {/* <div className="col-12 col-md-6 col-lg-3">
                                                    <h5>Select Segmentation Type</h5>
                                                    <Select
                                                        placeholder='Select Segmentation Type'
                                                        closeMenuOnSelect={true}
                                                        value={this.state.segmentationType}
                                                        isClearable
                                                        clearValue
                                                        isMulti
                                                        options={this.state.segmentationOptions}
                                                        onChange={(selectItemSegmentation) => this.handleChangeSegmentation(selectItemSegmentation)}
                                                    />
                                                </div> */}
                                            </Row>

                                            <div className="text-center padding_button">
                                                <button disabled={!(main_location_list.length > 0 && sub_location_list.length > 0 && services.length > 0 && defaultDate.start && defaultDate.end)} className="btn-round btn btn-outline-primary h6" type="button" onClick={() => this.onVisualize()}>Visualize</button>
                                            </div>
                                            <Row>
                                                <div className="text-center">
                                                    <h5>Data maybe delayed up to 20 hours.</h5>
                                                </div>
                                            </Row>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    {this.state.bVisualize ?
                        <Row >
                            <Col md="12">
                                <Row>
                                    <Col md="12">
                                        <Card className="ml-auto mr-auto ">
                                            <CardHeader>
                                                <CardTitle tag="h4" style={{ marginLeft: 30 }}>Main Location</CardTitle>
                                            </CardHeader>
                                            {!this.state.bmain ?
                                                <Row>
                                                    <Col md="6"><MainLocLineChart data={this.state.dataLineChartML} color={this.state.mainLocColor} label={this.state.mainLocLabel} /></Col>
                                                    <Col md="6"><MainLoc3DChart data={this.state.data3DChartML} /></Col>
                                                </Row> : <div style={{ width: '100%', height: 500 }}></div>}
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <Card className="ml-auto mr-auto ">
                                            <CardHeader>
                                                <CardTitle tag="h4" style={{ marginLeft: 30 }}>Sub Location</CardTitle>
                                            </CardHeader>
                                            <CardBody>
                                                {!this.state.bsub ?
                                                    <Row>
                                                        <Col md="6"><SubLocLineChart height={this.state.SLCnt} data={this.state.dataLineChartSL} color={this.state.subLocColor} label={this.state.subLocLabel} /></Col>
                                                        <Col md="6"><SubLoc3DChart height={this.state.SLCnt} data={this.state.data3DChartSL} /></Col>
                                                    </Row> : <div style={{ width: '100%', height: 500 }}></div>}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <Card className="ml-auto mr-auto " style={{ maxHeight: 'none !important' }} >
                                            <CardHeader>
                                                <CardTitle tag="h4" style={{ marginLeft: 30 }}>Service</CardTitle>
                                            </CardHeader>
                                            <CardBody style={{ height: this.state.SVLCnt + 10, paddingBottom: 10 }} >
                                                {!this.state.bservice ?
                                                    <Row  >
                                                        <Col md="6"><ServLocLineChart height={this.state.SVLCnt} data={this.state.dataLineChartServ} color={this.state.servColor} label={this.state.servLabel} /></Col>
                                                        <Col md="6"><ServLoc3DChart data={this.state.data3DChartServ} Height={this.state.SVLCnt} /></Col>
                                                    </Row> : <div style={{ width: '100%', height: 500 }}></div>}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <Card className="ml-auto mr-auto ">
                                            <CardHeader>
                                                <CardTitle tag="h4" style={{ marginLeft: 30 }}>Input Source</CardTitle>
                                            </CardHeader>
                                            <CardBody>
                                                {!this.state.bUpdate4 ?
                                                    <Row >
                                                        <Col md="6"><InsrcLocLineChart data={this.state.dataLineChartInSrc} color={this.state.InSrcColor} label={this.state.InSrcLabel} /></Col>
                                                        <Col md="6"><InsrcLoc3DChart data={this.state.data3DChartInSrc} /></Col>
                                                    </Row> :
                                                    <div style={{ width: '100%', height: 500 }}></div>}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row> :
                        <div ></div>
                    }
                </LoadingOverlay>
            </>
        );
    }
}

export default Dashboard1GeneralOverview;