import React from "react";
import LoadingOverlay from "react-loading-overlay";
import Select from "react-select";
import Switch from "react-bootstrap-switch";
import request from "request";
import Firebase from "firebase";
import NotificationAlert from "react-notification-alert";
import CreatableSelect from 'react-select/creatable';
import config from "../../../config";
import IconUpload from "../../../components/CustomUpload/IconUpload";
import CustomMap from "../../../components/CustomMap";
import info from "../../../info";
import { DatePicker } from '@progress/kendo-react-dateinputs';

import {
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Collapse,
    Row,
    Button,
    Form,
    FormGroup,
    Label,
    Input
} from "reactstrap";

import publicIp from "public-ip";
import geoTz from "geo-tz";
import moment from "moment-timezone";

/*
Notes:
//devcheck ==> marks lines that need to be reverted before saving for production 
//             fix issue with stripe function stripe_fetchSubLocationData (must return main loc name)
*/

import trash from '../../trash-alt-solid.svg'

class SubLocationEdit extends React.Component {
    constructor(props) {
        super(props);
        if (!Firebase.apps.length) {
            Firebase.initializeApp(config);
        }

        this.state = {
            loading: false,
            package_id: '',
            sub_location_id: '',
            address_info: null,
            customer_id: '',
            icon_max_limit: 0,
            map_zoom: 5,
            package_enable_mobile: true,
            mobile_ad_file_max_limit: 1,
            
            name: '',
            address: '',
            enable_mobile: false,
            active: true,
            allow_only_one_token_per_person_per_sublocation: false,
            validate_serv_for_tok_num_range_overlap: true,
            
            package_name: '',
            time_zone: null,
            time_zone_list: [],
            current_position: null,
            current_time_zone: null,
            center: null,
            position: null,
            remarks: '',
            nameState: '',
            billing_emailState: '',
            billing_emailStateMessage: 'This field is required.',
            billing_nameState: '',
            billing_addressState: '',
            package_idState: '',
            last_payment_invoice_idState: 'has-success',
            last_payment_statusState: '',
            last_payment_statusStateMessage: 'This field is required.',
            intervalState: '',
            intervalStateMessage: 'This field is required.',
            current_period_startState: 'has-success',
            current_period_endState: 'has-success',
            
            selected_main_location: null,
            main_location_list: [],
            //subs_count:1,
            openedCollapses: [],

            selected_stripe_customer: null,
            selected_manual_billing_profile: null,
            billing_email: '',
            billing_name: '',
            billing_address: '',
            billing_email_list: null,
            billing_profile_id: null,
            default_billing_email: null,
            billing_email_disable_selection: false,

            // pending_count: '',
            // last_paid_date: '',
            // notification_date: '',
            // final_notification_date: '',
            // next_payment_date: '',
            // payment_due_date: '',

            created_date_time: '',
            current_period_end: '',
            current_period_end_formatted_1: '',
            current_period_end_formatted_2: '',
            current_period_start: '',
            current_period_start_formatted_1: '',
            current_period_start_formatted_2: '',
            interval: {},
            //interval_disable_selection: false,
            interval_options_list: null,
            //package_id_disable_selection: true,
            last_payment_invoice_id: 'in1_99999999999999999999', //null,
            last_payment_status: {},
            last_payment_status_options_list: null,
            payment_source: '',
            payment_source_options_list: '',
            //payment_source_disable_selection: true,
            service_stop_date_time: '',
            subscription_id: '',
            subscription_status: '',
            sms_balance_default: 0,
            inputKey1: Math.random().toString(36),
            mobile_app_title: '',
            mobile_app_description: '',
            mobile_app_enable_title_description: false,
            mobile_app_enable_title_descriptionState: '',
            mobile_app_ad_1: null,
            mobile_app_ad_1_enable: false,
            mobile_app_ad_1_file_ext_ok: false,
            mobile_app_ad_1_file_size_ok: false,
            mobile_app_ad_1_name: '',
            mobile_app_ad_1_url: '',
            mobile_app_ad_1_attachmentState: '',
        };
        this.inputFileRef1 = React.createRef();
        // this.functions = null;                                              //functions emulator
        // this.fbStore = null;                                                //functions emulator
    }

    // initializeFirebase = () => {                                            //functions emulator
        // if (!this.functions) {                                              //functions emulator
            // this.functions = Firebase.functions();                          //functions emulator
            // this.functions.useFunctionsEmulator('http://localhost:5001');   //functions emulator
            // this.fbStore = Firebase.firestore();                            //functions emulator
        // }                                                                   //functions emulator
    // }                                                                       //functions emulator

    componentDidMount() {
        // this.initializeFirebase();                                          //functions emulator
        let _this=this;
        let id = _this.props.match.params.id;
        _this.auth_info = JSON.parse(localStorage.getItem('auth_info'));
        var role = _this.auth_info.role;

        if (id !== "" && id !== null && id !== undefined) {
            _this.setState({loading: true, sub_location_id: id, role:role});
            
            // if (role === 'Site_Admin') {
            //     _this.loadPaymentIntervalInfo();
            //     _this.loadSubLocationData(id);
            //     _this.loadPaymentStatusInfo();
            // } else {
            //     _this.loadSubLocationData(id);
            // }
            _this.loadPaymentIntervalInfo();
            _this.loadSubLocationData(id);
            _this.loadPaymentStatusInfo();
            //_this.loadAddressInfo();
        }


        // /// temp Check Sublocation ///   //devcheck
        // Firebase.firestore().collection('Sub_Locations').get().then(function (sub_location_info) {
        //     sub_location_info.docs.forEach(result => {
        //         if (typeof result.data().Package_ID === 'undefined'){
        //             console.log("componentDidMount>>>1:", result.id, result.data().Name);
        //         } else {
        //             console.log("componentDidMount>>>2:", result.id, result.data().Name);
        //         }
        //     })
        // });
            
        /// temp PopulateSublocationData ///   //devcheck
        // Firebase.firestore().collection('Sub_Locations').get().then(function (sub_location_info) {
        //     var all=0, a=0, b=0, c=0, d=0, e=0, f=0, g=0, h=0, i=0, j=0, k=0;
        //     all = sub_location_info.docs.length;
        //     sub_location_info.docs.forEach(result => {
        //         var subdets = result.data().Subscription_Details;
        //         var createddateO = result.data().Created_Date;
        //         var createddate = subdets.Created_Date;
        //         var paySrc = subdets.Payment_Source;
        //         var currPeriodStart = subdets.Current_Period_Start;
        //         var lastPaymentInvNum1 = 'in1_00000000000000000000';
        //         var lastPaymentInvNum2 = 'in1_99999999999999999999';

        //         if ((result.id === '00e9dbb0-e1e4-11e9-b12f-a5173bbf44b8') &&
        //         // if (
        //             (typeof subdets !== 'undefined') && (typeof createddate !== 'undefined')) {
        //                 a++;
        //                 //console.log("a id", result.id);
        //                 // Firebase.firestore().collection('Sub_Locations').doc(result.id).set({
        //                 //     "Subscription_Details": {
        //                 //       "Payment_Source": "Online_Payment"
        //                 //     }
        //                 //   },{merge:true})
        //         }
        //         if ((typeof subdets !== 'undefined') && (typeof createddate === 'undefined') && paySrc == "Manual_Payment") {
        //                 b++;
        //                 console.log("b id", result.id);
        //                 Firebase.firestore().collection('Sub_Locations').doc(result.id).set({
        //                     "Subscription_Details": {
        //                       "Created_Date": currPeriodStart
        //                     }
        //                   },{merge:true})
        //         } 
        //         if ((typeof subdets !== 'undefined') && (paySrc == "Manual_Payment")) {
        //                 c++;
        //                 // console.log("paySrc1",paySrc)
        //                 //console.log("[Subscription_Details sc3']", result.id);
        //                 // Firebase.firestore().collection('Sub_Locations').doc(result.id).set({
        //                 //     "Subscription_Details": {
        //                 //       "Payment_Source": "Manual_Payment"
        //                 //     }
        //                 //   },{merge:true})

        //         } else if ((typeof subdets !== 'undefined') && (paySrc == "Online_Payment")) {
        //                 d++;
        //                 // console.log("d id", result.id);
        //                 // console.log("paySrc1",paySrc)
        //                 //console.log("[Subscription_Details sc3']", result.id);
        //                 // Firebase.firestore().collection('Sub_Locations').doc(result.id).set({
        //                 //     "Subscription_Details": {
        //                 //       "Payment_Source": "Manual_Payment"
        //                 //     }
        //                 //   },{merge:true})
        //         }
        //         else {
        //                 e++;
        //                 console.log("e id", result.id);
        //         }
        //         if ((typeof subdets === 'undefined')) {
        //                 f++;
        //                 //console.log("[Subscription_Details sc3']", result.id);
        //                 // Firebase.firestore().collection('Sub_Locations').doc(result.id).set({
        //                 //     "Subscription_Details": {
        //                 //       "Payment_Source": "Manual_Payment"
        //                 //     }
        //                 //   },{merge:true})
        //         }
        //         if ((typeof subdets !== 'undefined') && (typeof createddate === 'undefined') && (paySrc == "Manual_Payment")) {
        //             g++;

        //         }
        //         if (//result.id == "38150390-e09d-11ea-a513-13353d562272" &&
        //             (typeof subdets !== 'undefined') && (typeof createddate === 'undefined') && (paySrc == "Manual_Payment")) {
        //             h++;
        //             // var created_date = (createddateO).toDate().toDateString();
        //             // console.log("Subscription_Details::9: created_date",created_date);
        //             // console.log("Subscription_Details::10: created_date_unix", Math.floor((new Date(created_date)).getTime() / 1000));
        //             // console.log("Subscription_Details::11: max_date_unix", Math.floor(9999999999));
        //             // console.log("Subscription_Details::12: max_date_unix_minus_7_days", Math.floor(9999395199));
        //             // Firebase.firestore().collection('Sub_Locations').doc(result.id).set({
        //             // "Subscription_Details": {
        //             //         Created_Date: Math.floor((new Date(created_date)).getTime() / 1000),
        //             //         Current_Period_End: Math.floor(9999999999),
        //             //         Current_Period_Start: Math.floor((new Date(created_date)).getTime() / 1000),
        //             //         Interval: "Yearly",
        //             //         Payment_Source: "Manual_Payment",
        //             //         Service_Stop_Date: Math.floor(9999999999),
        //             //         Subscription_ID: "",
        //             //         Subscription_Status: "active",
        //             //         Last_Payment_Invoice_Number: "",
        //             //         Last_Payment_Status: "Pending",
        //             // }
        //             // },{merge:true})
        //             // console.log(
        //             //     "result.id",result.id,
        //             //     "Created_Date:", Math.floor((new Date(created_date)).getTime() / 1000),
        //             //     "Current_Period_End", Math.floor(9999999999),
        //             //     "Current_Period_Start", Math.floor((new Date(created_date)).getTime() / 1000),
        //             //     "Interval", "Yearly",
        //             //     "Payment_Source", "Manual_Payment",
        //             //     "Service_Stop_Date", Math.floor(9999999999),
        //             //     "Subscription_ID", "-",
        //             //     "Subscription_Status", "active",
        //             //     "Last_Payment_Invoice_Number", "",
        //             //     "Last_Payment_Status", "Pending"
        //             // );

        //         }
        //     })
        //     console.log("PopulateSublocationData>>> ALL ");
        //     console.log("all", all);
        //     console.log("Subscription_Details::1: Subscription_Details !== 'undefined && Created_Date !== undefined", a);
        //     console.log("Subscription_Details::2: Subscription_Details !== 'undefined && Created_Date === undefined", b);
        //     console.log("Subscription_Details::3: Subscription_Details !== 'undefined && Payment_Source === Manual_Payment", c);
        //     console.log("Subscription_Details::4: Subscription_Details !== 'undefined && Payment_Source === Online_Payment", d);
        //     console.log("Subscription_Details::5: Subscription_Details !== 'undefined && Payment_Source === Other", e);
        //     console.log("Subscription_Details::6: Subscription_Details === 'undefined", f);
        //     console.log("Subscription_Details::7: Subscription_Details !== 'undefined && Created_Date === undefined && Payment_Source === Manual_Payment ", g);
        //     console.log("Subscription_Details::8: Subscription_Details !== 'undefined && Created_Date !== undefined && Payment_Source === Manual_Payment ", h);
        // }).then(() => {
        //     _this.setState({loading: false});
        // });
    }

    // loadPaymentSources = () => {
    //     let _this=this
    //     var role = this.auth_info.role;
    //     Firebase.firestore().collection('System_Config').doc('Payment_Sources').get().then(function (doc) {
    //         var payment_source_options = doc.data().Payment_Source_Options;
    //         var payment_source_options_list = [];
    //         payment_source_options.forEach(function (payment) {
    //             var sources = { value: payment, label: payment };
    //             payment_source_options_list.push(sources);
    //         });
    //         _this.setState({payment_source_options_list: payment_source_options_list});
    //         console.log('payment_source_options_list', payment_source_options_list);
    //         if (role==="Site_Admin") {
    //             _this.setState({payment_source_disable_selection: false});
    //         }
    //     }).catch(function (err) {
    //         _this.setState({loading: false});
    //         _this.notifyMessage("tc", 3, "Network error!");
    //         console.log('loadPaymentSources error', err);
    //     });
    // }

    loadPaymentStatusInfo = () => {
        let _this=this
        var role = this.auth_info.role;
        Firebase.firestore().collection('System_Config').doc('Manual_Payments').get().then(function (doc) {
            var payment_status_options = doc.data().Payment_Status_Options;
            var last_payment_status_options_list = [];
            payment_status_options.forEach(function (payment) {
                var option = { value: payment, label: payment };
                last_payment_status_options_list.push(option);
            });
            _this.setState({last_payment_status_options_list: last_payment_status_options_list});
            console.log('last_payment_status_options_list', last_payment_status_options_list);
        }).catch(function (err) {
            _this.setState({loading: false});
            _this.notifyMessage("tc", 3, "Network error!");
            console.log('loadPaymentStatusInfo error', err);
        });
    }

    loadPaymentIntervalInfo = () => {
        let _this=this
        var role = this.auth_info.role;
        Firebase.firestore().collection('System_Config').doc('Payment_Intervals').get().then(function (doc) {
            var interval_types = doc.data().Interval_Types;
            var interval_options_list = [];
            interval_types.forEach(function (payment) {
                var option = { value: payment, label: payment };
                interval_options_list.push(option);
            });
            _this.setState({interval_options_list: interval_options_list});
            console.log('interval_options_list', interval_options_list);
        }).catch(function (err) {
            _this.setState({loading: false});
            _this.notifyMessage("tc", 3, "Network error!");
            console.log('loadPaymentIntervalInfo error', err);
        });
    }

    loadSubLocationData = async (id) => {
        var _this = this;
        let role = _this.auth_info.role;

        // if (role === "Site_Admin") { expected_payment_source = "Manual_Payment"; }
        // if (role !== "Site_Admin") { expected_payment_source = "Online_Payment"; }
        
        // ---------- Start Load TimeZone List ---------- //
        var timeZones = moment.tz.names();
        var offsetTmz=[];

        for(var i in timeZones)
        {
            offsetTmz.push("(UTC"+moment.tz(timeZones[i]).format('Z')+")" + timeZones[i]);
        }

        let sortTimezones = offsetTmz.sort().map(item => { return {'value': item, 'label': item}});
        //console.log("sortTimezones", sortTimezones);

        //console.log("expected_payment_source>>> ", expected_payment_source);
        const [response0] = await Promise.all([
            Firebase.functions().httpsCallable('general_fetchSubLocationData')({sub_location_id: id})
        ]);
        const res0 = response0.data;
        
        console.log("res0.data.sub_location.Subscription_Details", res0.data.sub_location.Subscription_Details);
        console.log("res0", res0);
        console.log("res0.status", res0.status);

        if (res0.status === 'ok' && res0.data.sub_location.Subscription_Details.Payment_Source === 'Online_Payment') { //onlineSource
            _this.setState({payment_source: { value: "Online_Payment", label: "Online_Payment"} });
            console.log("loadSubLocationData>>> payment_source", _this.state.payment_source);
            try {
                const customer_id = res0.data.sub_location.Customer_ID;
                const [response, address_info, billing_email_list] = await Promise.all([
                    Firebase.functions().httpsCallable('stripe_fetchSubLocationData')({sub_location_id: id}),
                    new Promise((resolve, reject) => {
                        publicIp.v4().then(ip => {
                            let url = 'https://api.ipgeolocation.io/ipgeo?apiKey=' + info.ip_api_key + '&ip='+ip+'&lang=en';
                            request.get(url, function (err, res, body) {
                                if (err) {
                                    reject(err);
                                } else if (res.statusCode !== 200) {
                                    reject({statusCode: res.statusCode});
                                } else {
                                    resolve(JSON.parse(body));
                                }
                            });
                        })
                    }),
                    _this.loadBillingInfo('Online_Payment', customer_id),
                ]);
                
                const res = response.data;
                console.log("loadSubLocationData>>>1: response", response,"address_info", address_info, "billing_email_list", billing_email_list);

                if (res.status === 'ok') {
                    const {main_location, sub_location, icon_max_limit, mobile_ad_file_max_limit, package_name, package_enable_mobile} = res.data;
                    //const customer_id = sub_location.Customer_ID; //_this.auth_info;
                    const paymentSourceObject = {value: sub_location.Subscription_Details.Payment_Source, label: sub_location.Subscription_Details.Payment_Source};

                    const same_one1 = sortTimezones.find(item => item.value.includes(address_info.time_zone.name));
                    _this.refs.icon.handleSetUrl(sub_location.Icon);
                    const same_one2 = sortTimezones.find(item => item.value.includes(sub_location.TimeZone));
                    console.log('loadSubLocationData>>>2: icon_max_limit', icon_max_limit, 'mobile_ad_file_max_limit', mobile_ad_file_max_limit, 'time_zone_list', sortTimezones, 'customer_id', customer_id,
                                'address_info', address_info, 'current_position', parseFloat(address_info.latitude), parseFloat(address_info.longitude),
                                'current_time_zone', same_one1, 'main_location.Name', main_location.Name, 'address',  sub_location.Address, 'created_date_time',  sub_location.Created_Date,
                                'sub_location.Customer_ID',  sub_location.Customer_ID, 'position',  sub_location.Geolocation, 'center',  sub_location.Geolocation,
                                'icon',  sub_location.Icon, 'name',  sub_location.Name, 'address',  sub_location.Address,
                                'pending_count', sub_location.Pending_Queue_Count,
                                'remarks', sub_location.Remarks, 'enable_mobile', sub_location.Show_On_Mobile_App, 'active', sub_location.Status,
                                'allow_only_one_token_per_person_per_sublocation', sub_location.Allow_Only_One_Token_Per_Person_Per_Sublocation,
                                'sms_balance', sub_location.SMS_Balance, 'sms_sender_id', sub_location.SMS_Sender_ID, 'sms_sender_name', sub_location.SMS_Sender_Name,
                                'payment_source', paymentSourceObject, 'validate_serv_for_tok_num_range_overlap', sub_location.Validate_Service_For_Token_Number_Range_Overlap);
                    console.log('loadSubLocationData>>>3: sub_location.Billing_Profile_Id', sub_location.Billing_Profile_Id, 'package_name', package_name);
                    
                    // Online Payment Source
                    if (package_name != null) {
                        const billing_profile_id = sub_location.Billing_Profile_Id;
                        const default_billing_email = billing_profile_id ? billing_email_list?.filter(option=>option.id===billing_profile_id)[0] : {billing_name: '', billing_email: ''};
                        const defaultBillingName = default_billing_email.billing_name;
                        const defaultBillingAddress = default_billing_email.billing_address;
                        const currentPeriodEnd = new Date(sub_location.Subscription_Details.Current_Period_End * 1000);
                        const currentPeriodEndFormatted1 = sub_location.Subscription_Details.Current_Period_End;
                        const currentPeriodEndFormatted2 = _this.getFormattedDate((new Date(sub_location.Subscription_Details.Current_Period_End * 1000)), 'format1');
                        const currentPeriodStart = new Date(sub_location.Subscription_Details.Current_Period_Start * 1000);
                        const currentPeriodStartFormatted1 = sub_location.Subscription_Details.Current_Period_Start;
                        const currentPeriodStartFormatted2 = _this.getFormattedDate((new Date(sub_location.Subscription_Details.Current_Period_Start * 1000)), 'format1');
                        
                        console.log('loadSubLocationData>>>10: sub_location.Billing_Profile_Id', sub_location.Billing_Profile_Id, 'default_billing_email', default_billing_email, 'defaultBillingName', defaultBillingName, 'defaultBillingAddress', defaultBillingAddress);
                        console.log('loadSubLocationData>>>11: ' , sub_location.Next_Payment_Date, 'last_paid_date', sub_location.Last_Paid_Date, 'package_id', sub_location.Package_ID,
                                    sub_location.Payment_Due_Date, 'service_stop_date_time', sub_location.Subscription_Details.Service_Stop_Date, 'package_name', package_name, 'package_enable_mobile', package_enable_mobile);
                        console.log('loadSubLocationData>>>12: sub_location.Subscription_Details.Last_Payment_Invoice_Number', sub_location.Subscription_Details.Last_Payment_Invoice_Number,
                                    'sub_location.Subscription_Details.Interval',sub_location.Subscription_Details.Interval,'package_id', sub_location.Package_ID,
                                    'current_period_end', sub_location.Subscription_Details.Current_Period_End,
                                    'current_period_start', sub_location.Subscription_Details.Current_Period_Start,
                                    'last_payment_status', sub_location.Subscription_Details.Last_Payment_Status);
                        console.log('loadSubLocationData>>>13:');
                        console.log('created_date_time:', sub_location.Created_Date);
                        console.log('current_period_endState:', _this.state.current_period_endState);
                        console.log('current_period_startState:', _this.state.current_period_startState);
                        console.log('current_period_end:', currentPeriodEnd);
                        console.log('current_period_end_formatted_1:', currentPeriodEndFormatted1);
                        console.log('current_period_end_formatted_2:', currentPeriodEndFormatted2); 
                        console.log('current_period_start:', currentPeriodStart); 
                        console.log('current_period_start_formatted_1:', currentPeriodStartFormatted1); 
                        console.log('current_period_start_formatted_2:', currentPeriodStartFormatted2); 

                        _this.setState({
                            loading: false,
                            selected_main_location: { value: main_location.Name, label: main_location.Name},
                            time_zone_list: sortTimezones,
                            current_time_zone: same_one1,
                            time_zone: same_one2,
            
                            address_info,
                            current_position: { lat: parseFloat(address_info.latitude), lng: parseFloat(address_info.longitude) },
                            icon_max_limit,
                            mobile_ad_file_max_limit,
                            
                            billing_profile_id,
                            billing_email_list,
                            default_billing_email,
                            billing_name: defaultBillingName,
                            billing_address: defaultBillingAddress,
            
                            address: sub_location.Address,
                            customer_id: sub_location.Customer_ID,
                            position: sub_location.Geolocation,
                            center: sub_location.Geolocation,
                            icon: sub_location.Icon,
                            name: sub_location.Name,
                            //next_payment_date: sub_location.Next_Payment_Date,
                            //last_paid_date: sub_location.Last_Paid_Date,
                            //payment_due_date: sub_location.Payment_Due_Date,
                            //pending_count: sub_location.Pending_Queue_Count,
                            remarks: sub_location.Remarks,
                            enable_mobile: sub_location.Show_On_Mobile_App,
                            active: sub_location.Status,
                            allow_only_one_token_per_person_per_sublocation: sub_location.Allow_Only_One_Token_Per_Person_Per_Sublocation,
                            validate_serv_for_tok_num_range_overlap: sub_location.Validate_Service_For_Token_Number_Range_Overlap,
                            
                            created_date_time: sub_location.Created_Date,
                            current_period_end: currentPeriodEnd,
                            current_period_end_formatted_1: currentPeriodEndFormatted1,
                            current_period_end_formatted_2: currentPeriodEndFormatted2,
                            current_period_start: currentPeriodStart,
                            current_period_start_formatted_1: currentPeriodStartFormatted1,
                            current_period_start_formatted_2: currentPeriodStartFormatted2,
                            
                            interval: { label: sub_location.Subscription_Details.Interval, value: sub_location.Subscription_Details.Interval },
                            last_payment_invoice_id: sub_location.Subscription_Details.Last_Payment_Invoice_Number,
                            last_payment_status: { label: sub_location.Subscription_Details.Last_Payment_Status, value: sub_location.Subscription_Details.Last_Payment_Status},
                            payment_source: paymentSourceObject,
                            service_stop_date_time: new Date(sub_location.Subscription_Details.Service_Stop_Date * 1000),
                            subscription_id: sub_location.Subscription_Details.Subscription_ID,
                            subscription_status: sub_location.Subscription_Details.Subscription_Status,
                            
                            package_id: sub_location.Package_ID,
                            
                            package_name,
                            package_enable_mobile,
            
                            //Temp fix for SMS SID (until Add-ons module has been developed) --17th Jan 2022
                            sms_balance: sub_location.SMS_Balance,
                            sms_sender_id: sub_location.SMS_Sender_ID,
                            sms_sender_name: sub_location.SMS_Sender_Name,

                            mobile_app_title: sub_location.Mobile_App_Title,
                            mobile_app_description: sub_location.Mobile_App_Description,
                            mobile_app_enable_title_description: sub_location.Mobile_App_Enable_Title_Description,
                            mobile_app_ad_1_url: sub_location.Mobile_App_Ad_1,
                            mobile_app_ad_1_enable: sub_location.Mobile_App_Enable_Ad_1,

                            //expire: sub_location.expire,
                            //status: sub_location.status,
                            nameState: 'has-success',
                            billing_emailState: 'has-success',
                            billing_nameState: 'has-success',
                            billing_addressState: 'has-success',
                            package_idState: 'has-success',
                            last_payment_invoice_idState: 'has-success',
                            last_payment_statusState: 'has-success',
                            intervalState: 'has-success',
                            current_period_startState: 'has-success',
                            current_period_endState: 'has-success',
                        })
                    } else {
                        const billing_profile_id = sub_location.Billing_Profile_Id;
                        const default_billing_email = billing_profile_id ? billing_email_list?.filter(option=>option.id===billing_profile_id)[0] : {billing_name: '', billing_email: ''};
                        const defaultBillingName = default_billing_email.billing_name;
                        const defaultBillingAddress = default_billing_email.billing_address;
                        const currentPeriodEnd = new Date(sub_location.Subscription_Details.Current_Period_End * 1000);
                        const currentPeriodEndFormatted1 = sub_location.Subscription_Details.Current_Period_End;
                        const currentPeriodEndFormatted2 = _this.getFormattedDate((new Date(sub_location.Subscription_Details.Current_Period_End * 1000)), 'format1');
                        const currentPeriodStart = new Date(sub_location.Subscription_Details.Current_Period_Start * 1000);
                        const currentPeriodStartFormatted1 = sub_location.Subscription_Details.Current_Period_Start;
                        const currentPeriodStartFormatted2 = _this.getFormattedDate((new Date(sub_location.Subscription_Details.Current_Period_Start * 1000)), 'format1');
                        
                        console.log('loadSubLocationData>>>10: sub_location.Billing_Profile_Id', sub_location.Billing_Profile_Id, 'default_billing_email', default_billing_email, 'defaultBillingName', defaultBillingName, 'defaultBillingAddress', defaultBillingAddress);
                        console.log('loadSubLocationData>>>11: ' , sub_location.Next_Payment_Date, 'last_paid_date', sub_location.Last_Paid_Date, /*'package_id', sub_location.Package_ID,*/
                                    sub_location.Payment_Due_Date, 'service_stop_date_time', sub_location.Subscription_Details.Service_Stop_Date, /*'package_name', package_name, 'package_enable_mobile', package_enable_mobile*/);
                        console.log('loadSubLocationData>>>12: sub_location.Subscription_Details.Last_Payment_Invoice_Number', sub_location.Subscription_Details.Last_Payment_Invoice_Number,
                                    'sub_location.Subscription_Details.Interval',sub_location.Subscription_Details.Interval,/*'package_id', sub_location.Package_ID,*/
                                    'current_period_end', sub_location.Subscription_Details.Current_Period_End,
                                    'current_period_start', sub_location.Subscription_Details.Current_Period_Start,
                                    'last_payment_status', sub_location.Subscription_Details.Last_Payment_Status);
                        console.log('loadSubLocationData>>>13:');
                        console.log('created_date_time:', sub_location.Created_Date);
                        console.log('current_period_endState:', _this.state.current_period_endState);
                        console.log('current_period_startState:', _this.state.current_period_startState);
                        console.log('current_period_end:', currentPeriodEnd);
                        console.log('current_period_end_formatted_1:', currentPeriodEndFormatted1);
                        console.log('current_period_end_formatted_2:', currentPeriodEndFormatted2); 
                        console.log('current_period_start:', currentPeriodStart); 
                        console.log('current_period_start_formatted_1:', currentPeriodStartFormatted1); 
                        console.log('current_period_start_formatted_2:', currentPeriodStartFormatted2); 

                        _this.setState({
                            loading: false,
                            selected_main_location: { value: main_location.Name, label: main_location.Name},
                            time_zone_list: sortTimezones,
                            current_time_zone: same_one1,
                            time_zone: same_one2,
            
                            address_info,
                            current_position: { lat: parseFloat(address_info.latitude), lng: parseFloat(address_info.longitude) },
                            icon_max_limit,
                            mobile_ad_file_max_limit,
                            
                            billing_profile_id,
                            billing_email_list,
                            default_billing_email,
                            billing_name: defaultBillingName,
                            billing_address: defaultBillingAddress,
            
                            address: sub_location.Address,
                            customer_id: sub_location.Customer_ID,
                            position: sub_location.Geolocation,
                            center: sub_location.Geolocation,
                            icon: sub_location.Icon,
                            name: sub_location.Name,
                            //next_payment_date: sub_location.Next_Payment_Date,
                            //last_paid_date: sub_location.Last_Paid_Date,
                            //payment_due_date: sub_location.Payment_Due_Date,
                            //pending_count: sub_location.Pending_Queue_Count,
                            remarks: sub_location.Remarks,
                            enable_mobile: sub_location.Show_On_Mobile_App,
                            active: sub_location.Status,
                            allow_only_one_token_per_person_per_sublocation: sub_location.Allow_Only_One_Token_Per_Person_Per_Sublocation,
                            validate_serv_for_tok_num_range_overlap: sub_location.Validate_Service_For_Token_Number_Range_Overlap,
                            
                            created_date_time: sub_location.Created_Date,
                            current_period_end: currentPeriodEnd,
                            current_period_end_formatted_1: currentPeriodEndFormatted1,
                            current_period_end_formatted_2: currentPeriodEndFormatted2,
                            current_period_start: currentPeriodStart,
                            current_period_start_formatted_1: currentPeriodStartFormatted1,
                            current_period_start_formatted_2: currentPeriodStartFormatted2,
                            
                            interval: { label: sub_location.Subscription_Details.Interval, value: sub_location.Subscription_Details.Interval },
                            last_payment_invoice_id: sub_location.Subscription_Details.Last_Payment_Invoice_Number,
                            last_payment_status: { label: sub_location.Subscription_Details.Last_Payment_Status, value: sub_location.Subscription_Details.Last_Payment_Status},
                            payment_source: paymentSourceObject,
                            service_stop_date_time: new Date(sub_location.Subscription_Details.Service_Stop_Date * 1000),
                            subscription_id: sub_location.Subscription_Details.Subscription_ID,
                            subscription_status: sub_location.Subscription_Details.Subscription_Status,
                            
                            // package_id: sub_location.Package_ID,
                            
                            // package_name,
                            // package_enable_mobile,
            
                            //Temp fix for SMS SID (until Add-ons module has been developed) --17th Jan 2022
                            sms_balance: sub_location.SMS_Balance,
                            sms_sender_id: sub_location.SMS_Sender_ID,
                            sms_sender_name: sub_location.SMS_Sender_Name,

                            mobile_app_title: sub_location.Mobile_App_Title,
                            mobile_app_description: sub_location.Mobile_App_Description,
                            mobile_app_enable_title_description: sub_location.Mobile_App_Enable_Title_Description,
                            mobile_app_ad_1_url: sub_location.Mobile_App_Ad_1,
                            mobile_app_ad_1_enable: sub_location.Mobile_App_Enable_Ad_1,

                            //expire: sub_location.expire,
                            //status: sub_location.status,
                            nameState: 'has-success',
                            billing_emailState: 'has-success',
                            billing_nameState: 'has-success',
                            billing_addressState: 'has-success',
                            package_idState: 'has-success',
                            last_payment_invoice_idState: 'has-success',
                            last_payment_statusState: 'has-success',
                            intervalState: 'has-success',
                            current_period_startState: 'has-success',
                            current_period_endState: 'has-success',
                        })
                    }
                }
            } catch (err) {
                _this.setState({
                    loading: false,
                    address_info: null
                })
                _this.notifyMessage("tc", 3, "Network error!");
                console.log("loadSubLocationData NetworkError1==>", err);
            }
        }
        else if (res0.status === 'ok' && res0.data.sub_location.Subscription_Details.Payment_Source === 'Manual_Payment') { //manualSource
            _this.setState({payment_source: { value: "Manual_Payment", label: "Manual_Payment"} });
            console.log("loadSubLocationData0>>> payment_source", _this.state.payment_source);
            try {
                const customer_id = res0.data.sub_location.Customer_ID;
                const [response, address_info, billing_email_list] = await Promise.all([
                    Firebase.functions().httpsCallable('manual_fetchSubLocationData')({sub_location_id: id}),
                    new Promise((resolve, reject) => {
                        publicIp.v4().then(ip => {
                            let url = 'https://api.ipgeolocation.io/ipgeo?apiKey=' + info.ip_api_key + '&ip='+ip+'&lang=en';
                            request.get(url, function (err, res, body) {
                                if (err) {
                                    reject(err);
                                } else if (res.statusCode !== 200) {
                                    reject({statusCode: res.statusCode});
                                } else {
                                    resolve(JSON.parse(body));
                                }
                            });
                        })
                    }),
                    _this.loadBillingInfo('Manual_Payment', customer_id)
                ]);

                const res = response.data;
                console.log("loadSubLocationData>>>1: response", response,"address_info", address_info, "billing_email_list", billing_email_list);

                if (res.status === 'ok') {
                    const {main_location, sub_location, icon_max_limit, mobile_ad_file_max_limit, package_name, package_enable_mobile} = res.data;
                    //const customer_id = sub_location.Customer_ID; //_this.auth_info;
                    const paymentSourceObject = {value: sub_location.Subscription_Details.Payment_Source, label: sub_location.Subscription_Details.Payment_Source};

                    const same_one1 = sortTimezones.find(item => item.value.includes(address_info.time_zone.name));
                    _this.refs.icon.handleSetUrl(sub_location.Icon);
                    const same_one2 = sortTimezones.find(item => item.value.includes(sub_location.TimeZone));
                    console.log('loadSubLocationData>>>2: icon_max_limit', icon_max_limit, 'mobile_ad_file_max_limit', mobile_ad_file_max_limit, 'time_zone_list', sortTimezones, 'customer_id', customer_id,
                                'address_info', address_info, 'current_position', parseFloat(address_info.latitude), parseFloat(address_info.longitude),
                                'current_time_zone', same_one1, 'main_location.Name', main_location.Name, 'address',  sub_location.Address, 'created_date_time',  sub_location.Created_Date,
                                'sub_location.Customer_ID',  sub_location.Customer_ID, 'position',  sub_location.Geolocation, 'center',  sub_location.Geolocation,
                                'icon',  sub_location.Icon, 'name',  sub_location.Name, 'address',  sub_location.Address,
                                'pending_count', sub_location.Pending_Queue_Count,
                                'remarks', sub_location.Remarks, 'enable_mobile', sub_location.Show_On_Mobile_App, 'active', sub_location.Status,
                                'allow_only_one_token_per_person_per_sublocation', sub_location.Allow_Only_One_Token_Per_Person_Per_Sublocation,
                                'sms_balance', sub_location.SMS_Balance, 'sms_sender_id', sub_location.SMS_Sender_ID, 'sms_sender_name', sub_location.SMS_Sender_Name,
                                'payment_source', paymentSourceObject, 'validate_serv_for_tok_num_range_overlap', sub_location.Validate_Service_For_Token_Number_Range_Overlap);
                    console.log('loadSubLocationData>>>3: sub_location.Billing_Profile_Id', sub_location.Billing_Profile_Id, 'package_name', package_name);
                    
                    // Manual Payment Source
                    if (package_name != null) {
                        const billing_profile_id = sub_location.Billing_Profile_Id;
                        const default_billing_email = billing_profile_id ? billing_email_list?.filter(option=>option.id===billing_profile_id)[0] : {billing_name: '', billing_email: ''};
                        const defaultBillingName = default_billing_email.billing_name;
                        const defaultBillingAddress = default_billing_email.billing_address;
                        const currentPeriodEnd = new Date(sub_location.Subscription_Details.Current_Period_End * 1000);
                        const currentPeriodEndFormatted1 = sub_location.Subscription_Details.Current_Period_End;
                        const currentPeriodEndFormatted2 = _this.getFormattedDate((new Date(sub_location.Subscription_Details.Current_Period_End * 1000)), 'format1');
                        const currentPeriodStart = new Date(sub_location.Subscription_Details.Current_Period_Start * 1000);
                        const currentPeriodStartFormatted1 = sub_location.Subscription_Details.Current_Period_Start;
                        const currentPeriodStartFormatted2 = _this.getFormattedDate((new Date(sub_location.Subscription_Details.Current_Period_Start * 1000)), 'format1');
                    
                        console.log('loadSubLocationData>>>10: sub_location.Billing_Profile_Id', sub_location.Billing_Profile_Id, 'default_billing_email', default_billing_email, 'defaultBillingName', defaultBillingName, 'defaultBillingAddress', defaultBillingAddress);
                        console.log('loadSubLocationData>>>11: ' , sub_location.Next_Payment_Date, 'last_paid_date', sub_location.Last_Paid_Date, 'package_id', sub_location.Package_ID,
                                    sub_location.Payment_Due_Date, 'service_stop_date_time', sub_location.Subscription_Details.Service_Stop_Date, 'package_name', package_name, 'package_enable_mobile', package_enable_mobile);
                        console.log('loadSubLocationData>>>12: sub_location.Subscription_Details.Last_Payment_Invoice_Number', sub_location.Subscription_Details.Last_Payment_Invoice_Number,
                                    'sub_location.Subscription_Details.Interval',sub_location.Subscription_Details.Interval,'package_id', sub_location.Package_ID,
                                    'current_period_end', sub_location.Subscription_Details.Current_Period_End,
                                    'current_period_start', sub_location.Subscription_Details.Current_Period_Start,
                                    'last_payment_status', sub_location.Subscription_Details.Last_Payment_Status);
                        console.log('loadSubLocationData>>>13:');
                        console.log('created_date_time:', sub_location.Created_Date);
                        console.log('current_period_endState:', _this.state.current_period_endState);
                        console.log('current_period_startState:', _this.state.current_period_startState);
                        console.log('current_period_end:', currentPeriodEnd);
                        console.log('current_period_end_formatted_1:', currentPeriodEndFormatted1);
                        console.log('current_period_end_formatted_2:', currentPeriodEndFormatted2); 
                        console.log('current_period_start:', currentPeriodStart); 
                        console.log('current_period_start_formatted_1:', currentPeriodStartFormatted1); 
                        console.log('current_period_start_formatted_2:', currentPeriodStartFormatted2);  

                        _this.setState({
                            loading: false,
                            selected_main_location: { value: main_location.Name, label: main_location.Name},
                            time_zone_list: sortTimezones,
                            current_time_zone: same_one1,
                            time_zone: same_one2,
            
                            address_info,
                            current_position: { lat: parseFloat(address_info.latitude), lng: parseFloat(address_info.longitude) },
                            icon_max_limit,
                            mobile_ad_file_max_limit,
                            
                            billing_profile_id,
                            billing_email_list,
                            default_billing_email,
                            billing_name: defaultBillingName,
                            billing_address: defaultBillingAddress,
            
                            address: sub_location.Address,
                            customer_id: sub_location.Customer_ID,
                            position: sub_location.Geolocation,
                            center: sub_location.Geolocation,
                            icon: sub_location.Icon,
                            name: sub_location.Name,
                            //next_payment_date: sub_location.Next_Payment_Date,
                            //last_paid_date: sub_location.Last_Paid_Date,
                            //payment_due_date: sub_location.Payment_Due_Date,
                            //pending_count: sub_location.Pending_Queue_Count,
                            remarks: sub_location.Remarks,
                            enable_mobile: sub_location.Show_On_Mobile_App,
                            active: sub_location.Status,
                            allow_only_one_token_per_person_per_sublocation: sub_location.Allow_Only_One_Token_Per_Person_Per_Sublocation,
                            validate_serv_for_tok_num_range_overlap: sub_location.Validate_Service_For_Token_Number_Range_Overlap,
                            
                            created_date_time: sub_location.Created_Date,
                            current_period_end: currentPeriodEnd,
                            current_period_end_formatted_1: currentPeriodEndFormatted1,
                            current_period_end_formatted_2: currentPeriodEndFormatted2,
                            current_period_start: currentPeriodStart,
                            current_period_start_formatted_1: currentPeriodStartFormatted1,
                            current_period_start_formatted_2: currentPeriodStartFormatted2,
                            
                            interval: { label: sub_location.Subscription_Details.Interval, value: sub_location.Subscription_Details.Interval },
                            last_payment_invoice_id: sub_location.Subscription_Details.Last_Payment_Invoice_Number,
                            last_payment_status: { label: sub_location.Subscription_Details.Last_Payment_Status, value: sub_location.Subscription_Details.Last_Payment_Status},
                            payment_source: paymentSourceObject,
                            service_stop_date_time: new Date(sub_location.Subscription_Details.Service_Stop_Date * 1000),
                            subscription_id: sub_location.Subscription_Details.Subscription_ID,
                            subscription_status: sub_location.Subscription_Details.Subscription_Status,
                            
                            package_id: sub_location.Package_ID,
                            
                            package_name,
                            package_enable_mobile,

                            //Temp fix for SMS SID (until Add-ons module has been developed) --17th Jan 2022
                            sms_balance: sub_location.SMS_Balance,
                            sms_sender_id: sub_location.SMS_Sender_ID,
                            sms_sender_name: sub_location.SMS_Sender_Name,

                            mobile_app_title: sub_location.Mobile_App_Title,
                            mobile_app_description: sub_location.Mobile_App_Description,
                            mobile_app_enable_title_description: sub_location.Mobile_App_Enable_Title_Description,
                            mobile_app_ad_1_url: sub_location.Mobile_App_Ad_1,
                            mobile_app_ad_1_enable: sub_location.Mobile_App_Enable_Ad_1,
            
                            //expire: sub_location.expire,
                            //status: sub_location.status,
                            nameState: 'has-success',
                            billing_emailState: 'has-success',
                            billing_nameState: 'has-success',
                            billing_addressState: 'has-success',
                            package_idState: 'has-success',
                            last_payment_invoice_idState: 'has-success',
                            last_payment_statusState: 'has-success',
                            intervalState: 'has-success',
                            current_period_startState: 'has-success',
                            current_period_endState: 'has-success',
                        })
                    } else {
                        const billing_profile_id = sub_location.Billing_Profile_Id;
                        const default_billing_email = billing_profile_id ? billing_email_list?.filter(option=>option.id===billing_profile_id)[0] : {billing_name: '', billing_email: ''};
                        const defaultBillingName = default_billing_email.billing_name;
                        const defaultBillingAddress = default_billing_email.billing_address;
                        const currentPeriodEnd = new Date(sub_location.Subscription_Details.Current_Period_End * 1000);
                        const currentPeriodEndFormatted1 = sub_location.Subscription_Details.Current_Period_End;
                        const currentPeriodEndFormatted2 = _this.getFormattedDate((new Date(sub_location.Subscription_Details.Current_Period_End * 1000)), 'format1');
                        const currentPeriodStart = new Date(sub_location.Subscription_Details.Current_Period_Start * 1000);
                        const currentPeriodStartFormatted1 = sub_location.Subscription_Details.Current_Period_Start;
                        const currentPeriodStartFormatted2 = _this.getFormattedDate((new Date(sub_location.Subscription_Details.Current_Period_Start * 1000)), 'format1');
                    
                        console.log('loadSubLocationData>>>10: sub_location.Billing_Profile_Id', sub_location.Billing_Profile_Id, 'default_billing_email', default_billing_email, 'defaultBillingName', defaultBillingName, 'defaultBillingAddress', defaultBillingAddress);
                        console.log('loadSubLocationData>>>11: ' , sub_location.Next_Payment_Date, 'last_paid_date', sub_location.Last_Paid_Date, /*'package_id', sub_location.Package_ID,*/
                                    sub_location.Payment_Due_Date, 'service_stop_date_time', sub_location.Subscription_Details.Service_Stop_Date, /*'package_name', package_name, 'package_enable_mobile', package_enable_mobile*/);
                        console.log('loadSubLocationData>>>12: sub_location.Subscription_Details.Last_Payment_Invoice_Number', sub_location.Subscription_Details.Last_Payment_Invoice_Number,
                                    'sub_location.Subscription_Details.Interval',sub_location.Subscription_Details.Interval,/*'package_id', sub_location.Package_ID,*/
                                    'current_period_end', sub_location.Subscription_Details.Current_Period_End,
                                    'current_period_start', sub_location.Subscription_Details.Current_Period_Start,
                                    'last_payment_status', sub_location.Subscription_Details.Last_Payment_Status);
                        console.log('loadSubLocationData>>>13:');
                        console.log('created_date_time:', sub_location.Created_Date);
                        console.log('current_period_endState:', _this.state.current_period_endState);
                        console.log('current_period_startState:', _this.state.current_period_startState);
                        console.log('current_period_end:', currentPeriodEnd);
                        console.log('current_period_end_formatted_1:', currentPeriodEndFormatted1);
                        console.log('current_period_end_formatted_2:', currentPeriodEndFormatted2); 
                        console.log('current_period_start:', currentPeriodStart); 
                        console.log('current_period_start_formatted_1:', currentPeriodStartFormatted1); 
                        console.log('current_period_start_formatted_2:', currentPeriodStartFormatted2);  

                        _this.setState({
                            loading: false,
                            selected_main_location: { value: main_location.Name, label: main_location.Name},
                            time_zone_list: sortTimezones,
                            current_time_zone: same_one1,
                            time_zone: same_one2,
            
                            address_info,
                            current_position: { lat: parseFloat(address_info.latitude), lng: parseFloat(address_info.longitude) },
                            icon_max_limit,
                            mobile_ad_file_max_limit,
                            
                            billing_profile_id,
                            billing_email_list,
                            default_billing_email,
                            billing_name: defaultBillingName,
                            billing_address: defaultBillingAddress,
            
                            address: sub_location.Address,
                            customer_id: sub_location.Customer_ID,
                            position: sub_location.Geolocation,
                            center: sub_location.Geolocation,
                            icon: sub_location.Icon,
                            name: sub_location.Name,
                            //next_payment_date: sub_location.Next_Payment_Date,
                            //last_paid_date: sub_location.Last_Paid_Date,
                            //payment_due_date: sub_location.Payment_Due_Date,
                            //pending_count: sub_location.Pending_Queue_Count,
                            remarks: sub_location.Remarks,
                            enable_mobile: sub_location.Show_On_Mobile_App,
                            active: sub_location.Status,
                            allow_only_one_token_per_person_per_sublocation: sub_location.Allow_Only_One_Token_Per_Person_Per_Sublocation,
                            validate_serv_for_tok_num_range_overlap: sub_location.Validate_Service_For_Token_Number_Range_Overlap,
                            
                            created_date_time: sub_location.Created_Date,
                            current_period_end: currentPeriodEnd,
                            current_period_end_formatted_1: currentPeriodEndFormatted1,
                            current_period_end_formatted_2: currentPeriodEndFormatted2,
                            current_period_start: currentPeriodStart,
                            current_period_start_formatted_1: currentPeriodStartFormatted1,
                            current_period_start_formatted_2: currentPeriodStartFormatted2,
                            
                            interval: { label: sub_location.Subscription_Details.Interval, value: sub_location.Subscription_Details.Interval },
                            last_payment_invoice_id: sub_location.Subscription_Details.Last_Payment_Invoice_Number,
                            last_payment_status: { label: sub_location.Subscription_Details.Last_Payment_Status, value: sub_location.Subscription_Details.Last_Payment_Status},
                            payment_source: paymentSourceObject,
                            service_stop_date_time: new Date(sub_location.Subscription_Details.Service_Stop_Date * 1000),
                            subscription_id: sub_location.Subscription_Details.Subscription_ID,
                            subscription_status: sub_location.Subscription_Details.Subscription_Status,
                            
                            // package_id: sub_location.Package_ID,
                            
                            // package_name,
                            // package_enable_mobile,

                            //Temp fix for SMS SID (until Add-ons module has been developed) --17th Jan 2022
                            sms_balance: sub_location.SMS_Balance,
                            sms_sender_id: sub_location.SMS_Sender_ID,
                            sms_sender_name: sub_location.SMS_Sender_Name,

                            mobile_app_title: sub_location.Mobile_App_Title,
                            mobile_app_description: sub_location.Mobile_App_Description,
                            mobile_app_enable_title_description: sub_location.Mobile_App_Enable_Title_Description,
                            mobile_app_ad_1_url: sub_location.Mobile_App_Ad_1,
                            mobile_app_ad_1_enable: sub_location.Mobile_App_Enable_Ad_1,
            
                            //expire: sub_location.expire,
                            //status: sub_location.status,
                            nameState: 'has-success',
                            billing_emailState: 'has-success',
                            billing_nameState: 'has-success',
                            billing_addressState: 'has-success',
                            package_idState: 'has-success',
                            last_payment_invoice_idState: 'has-success',
                            last_payment_statusState: 'has-success',
                            intervalState: 'has-success',
                            current_period_startState: 'has-success',
                            current_period_endState: 'has-success',
                        })
                    }
                }
            } catch (err) {
                _this.setState({
                    loading: false,
                    address_info: null
                })
                _this.notifyMessage("tc", 3, "Network error!");
                console.log("loadSubLocationData NetworkError2==>", err);
            }
        } else {
            console.log("loadSubLocationData>>>N/ASource");
        }
    }
    
    loadBillingInfo = (payment_source, customer_id) => {
        console.log("loadBillingInfo>>>1 payment_source, customer_id", payment_source, customer_id);
        //const { customer_id } = this.auth_info;
        //const payment_source = this.state.payment_source.value;
        if ( payment_source=='Online_Payment' ) {
            console.log("loadBillingInfo>>>2 Online_Payment", payment_source);
            return new Promise((resolve, reject) => {
                Firebase.firestore().collection('Stripe_Customers').where('Antqueue_Customer_ID', '==', customer_id).get()
                    .then(result=> {
                        const billing_email_list = [];
                        result.docs.forEach(stripe_customer =>{
                            const { Stripe_Billing_User_Email, Stripe_Billing_User_Name, Stripe_Billing_User_Address } = stripe_customer.data();
                            const option = {
                                id: stripe_customer.id,
                                email: Stripe_Billing_User_Email,
                                label: Stripe_Billing_User_Email,
                                value: stripe_customer.id,
                                billing_name: Stripe_Billing_User_Name,
                                billing_address: Stripe_Billing_User_Address
                            };
                            billing_email_list.push(option);
                        });
                        console.log("loadBillingInfo>>>3 billing_email_list", billing_email_list);
                        resolve(billing_email_list);
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            });
        } else if ( payment_source=='Manual_Payment' ) {
            console.log("loadBillingInfo>>>3 Manual_Payment", payment_source);
            // Handle Manual Payments
            return new Promise((resolve, reject) => {
                Firebase.firestore().collection('Manual_Billing_Profiles').where('Antqueue_Customer_ID', '==', customer_id).get()
                    .then(result=> {
                        const billing_email_list = [];
                        result.docs.forEach(profile =>{
                            const { Manual_Billing_User_Email, Manual_Billing_User_Name, Manual_Billing_User_Address } = profile.data();
                            const option = {
                                id: profile.id,
                                email: Manual_Billing_User_Email,
                                label: Manual_Billing_User_Email,
                                value: profile.id,
                                billing_name: Manual_Billing_User_Name,
                                billing_address: Manual_Billing_User_Address,
                            };
                            billing_email_list.push(option);
                        });
                        console.log("loadBillingInfo>>>4 billing_email_list", billing_email_list);
                        resolve(billing_email_list);
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            });
        } else {
            console.log("loadBillingInfo>>>5 Other");
        } 
    };

    collapsesToggle = collapse => {
        let openedCollapses = this.state.openedCollapses;
        if (openedCollapses.includes(collapse)) {
            this.setState({
                openedCollapses: openedCollapses.filter(item => item !== collapse)
            });
        } else {
            openedCollapses.push(collapse);
            this.setState({
                openedCollapses: openedCollapses
            });
        }
    };

    async handleSave() {
        let _this = this;
        const { nameState, billing_emailState, billing_nameState, billing_addressState, package_idState, last_payment_invoice_idState,
                last_payment_statusState, current_period_endState, current_period_startState, mobile_app_ad_1_attachmentState, mobile_app_enable_title_descriptionState } = this.state;
        let payment_source = _this.state.payment_source.value;
        let role = this.auth_info.role;
        let manualSource = false; let onlineSource = false;
        _this.setState({loading: true});

        if (payment_source === "Manual_Payment") { manualSource = true; }
        if (payment_source === "Online_Payment") { onlineSource = true; }
        if (nameState === "") { _this.setState({ nameState: "has-danger" }); }
        if (billing_emailState === "") { _this.setState({ billing_emailState: "has-danger" }); }
        if (billing_nameState === "") { _this.setState({ billing_nameState: "has-danger" }); }
        if (billing_addressState === "") { _this.setState({ billing_addressState: "has-danger" }); }
        // if (mobile_app_enable_title_descriptionState === "") { _this.setState({ mobile_app_enable_title_descriptionState: "has-danger" }); }
        // if (mobile_app_ad_1_attachmentState === "") { _this.setState({ mobile_app_ad_1_attachmentState: "has-danger" }); }
        
        if (manualSource && _this.isValidPackageID(_this.state.package_id) === true) { _this.setState({ package_idState: "has-success"}); }
            else if (manualSource && _this.isValidPackageID(_this.state.package_id)=== false) { _this.setState({ package_idState: "has-danger"}); }
                else if (onlineSource) { _this.setState({ package_idState: "has-success"}); }

        // if (manualSource && _this.isValidInvoiceID(_this.state.last_payment_invoice_id) === true) { _this.setState({ last_payment_invoice_idState: "has-success"}); }
        //     else if (manualSource && last_payment_invoice_idState === "") { _this.setState({ last_payment_invoice_idState: "has-danger"}); }
        //         else if (onlineSource) { _this.setState({ last_payment_invoice_idState: "has-success"}); }
        _this.setState({ last_payment_invoice_idState: "has-success"});
        
        if (manualSource && _this.state.last_payment_status.value !== "") { _this.setState({ last_payment_statusState: "has-success"}); }
            else if (manualSource && _this.state.last_payment_status.value === "") { _this.setState({ last_payment_statusState: "has-danger"}); }
                else if (onlineSource) { _this.setState({ last_payment_statusState: "has-success"}); }

        // if (manualSource && _this.isValidFormattedDate(_this.state.current_period_end_formatted_2, 'current_period_endState') === true) { _this.setState({ current_period_endState: "has-success"}); }
        //     else if (manualSource && _this.isValidFormattedDate(_this.state.current_period_end_formatted_2, 'current_period_endState') === false) { _this.setState({ current_period_endState: "has-danger"}); }
        //         else if (onlineSource) { _this.setState({ current_period_endState: "has-success"}); }
    
        // if (manualSource && _this.isValidFormattedDate(_this.state.current_period_start_formatted_2, 'current_period_startState') === true) { _this.setState({ current_period_startState: "has-success"}); }
        //     else if (manualSource && _this.isValidFormattedDate(_this.state.current_period_start_formatted_2, 'current_period_startState') === false) { _this.setState({ current_period_startState: "has-danger"}); }
        //         else if (onlineSource) { _this.setState({ current_period_startState: "has-success"}); }
        _this.setState({ current_period_startState: "has-success"});
        _this.setState({ current_period_endState: "has-success"});

        console.log("handleSave>>> nameState", nameState, "billing_emailState", billing_emailState, "billing_nameState", billing_nameState, "billing_addressState", billing_addressState,
            "package_idState", package_idState, "last_payment_invoice_idState", last_payment_invoice_idState, "last_payment_statusState", last_payment_statusState, 
            "current_period_endState", current_period_endState, "current_period_startState", current_period_startState,
            "mobile_app_enable_title_descriptionState", mobile_app_enable_title_descriptionState, "mobile_app_ad_1_attachmentState", mobile_app_ad_1_attachmentState);

        if (nameState === "has-success" && billing_emailState === "has-success" && billing_nameState === "has-success" && billing_addressState === "has-success"
        // && package_idState === "has-success" && last_payment_invoice_idState === "has-success" && last_payment_statusState === "has-success"
        // && current_period_endState === "has-success"  && current_period_startState === "has-success"
        // && _this.state.mobile_app_enable_title_descriptionState === "has-success"
        // && _this.state.mobile_app_ad_1_attachmentState !== "has-danger"
        ) {

            const customer_id = _this.state.customer_id;
            //const { customer_id } = _this.auth_info;
            const {
                default_billing_email,
                billing_email,
                billing_name,
                billing_address,
            } = _this.state; 
            
            console.log("handleSave>>>1 customer_id", customer_id, "default_billing_email", default_billing_email, "billing_email", billing_email, "billing_name",
                        billing_name, "billing_address",billing_address, "customer_id", customer_id, "payment_source",payment_source,
                        );
            if (onlineSource) {
                var stripe_customer_id = null;
                console.log("handleSave>>>2 Online_Payment path", "default_billing_email.email", default_billing_email.email, "billing_name", billing_name, "billing_address", billing_address, "customer_id", customer_id);
                try {                
                    const response = await Firebase.functions().httpsCallable('stripe_saveUpdateStripeCustomerInfo')({ 
                        email: default_billing_email.email, //billing_email,
                        name: billing_name,
                        address: billing_address,
                        antqueue_customer_id: customer_id
                    });
                    const res = response.data;
                    if (res.status === 'error') {
                        const err = res.error;
                        _this.setState({loading: false});
                        _this.notifyMessage("tc", 3, err.message);
                        console.log("handleSave NetworkError1==>", err);
                        return;
                    }
                    
                    stripe_customer_id = res.data.stripe_customer_doc_id;
                    console.log("handleSave>>>3 stripe_customer_id", stripe_customer_id);
                } catch(err) {
                    _this.setState({loading: false});
                    _this.notifyMessage("tc", 3, "Network error!");
                    console.log("handleSave NetworkError2==>", err);
                }

                var now = new Date();
                let file = this.refs.icon.state.file;
                console.log("file", file)
                if (file !== null) {
                    // --------- Check Max Icon Size Limit --------- //
                    let max_bytes = _this.state.icon_max_limit * 1024 * 1024;
                    if (file.size > max_bytes) {
                        _this.setState({loading: false});
                        _this.notifyMessage("tc", 3, "Icon file size exceeds maximum size of " + _this.state.icon_max_limit +  " MB. Please change icon!");
                    } else {
                        Firebase.firestore().collection('Sub_Locations').doc(_this.state.sub_location_id).get().then(res => {
                            
                            let metadata = {
                                customMetadata: {
                                    customer_id: customer_id,
                                    main_location_id: res.data().Main_Location_ID,
                                    sub_location_id: this.state.sub_location_id,
                                }
                            }
                            console.log(metadata);
                        var storageRef = Firebase.storage().ref();
                        var image_name = "sub_location_icon_" + this.state.sub_location_id;
                        var subLocationRef = storageRef.child(image_name);
                            subLocationRef.put(file, metadata).then(function (snapshot) {
                            subLocationRef.getDownloadURL().then(function (res) {
                                let update_sub_location_data = {
                                    Icon: res,
                                    Address: _this.state.address,
                                    Name: _this.state.name,
                                    Remarks: _this.state.remarks,
                                    Show_On_Mobile_App: _this.state.enable_mobile,
                                    Status: _this.state.active,
                                    Allow_Only_One_Token_Per_Person_Per_Sublocation: _this.state.allow_only_one_token_per_person_per_sublocation,
                                    Validate_Service_For_Token_Number_Range_Overlap: _this.state.validate_serv_for_tok_num_range_overlap,
                                    TimeZone: _this.state.time_zone.value,
                                    Geolocation: _this.state.position,
                                    //Temp fix for SMS SID (until Add-ons module has been developed) --17th Jan 2022
                                    SMS_Balance: _this.state.sms_balance_default,
                                    SMS_Sender_ID: _this.state.customer_id !== '000000000006' ? 'MG8d4735c253ffa9ed9eb0c3af956e35d1' : 'MG9218bcc3128a2ccf282ca4af3f5e01e2',
                                    SMS_Sender_Name: _this.state.customer_id !== '000000000006' ? 'Ant Queue' : 'DhiraaguCSC',
                                    Mobile_App_Title: _this.state.mobile_app_title,
                                    Mobile_App_Description: _this.state.mobile_app_description,
                                    Mobile_App_Enable_Title_Description: _this.state.mobile_app_enable_title_description,
                                    Mobile_App_Ad_1: _this.state.mobile_app_ad_1_url,
                                    Mobile_App_Enable_Ad_1: _this.state.mobile_app_ad_1_enable,
                                    Updated_Date: now,                                
                                    Billing_Profile_Id: _this.state.billing_profile_id, //stripe_customer_id,

                                    // Package_ID: (_this.state.package_id).trim(),
                                    // Subscription_Details: {
                                    //     //Created_Date: Math.floor(now.getTime() / 1000),
                                    //     Current_Period_End: parseInt(_this.state.current_period_end_formatted_1),
                                    //     Current_Period_Start:  parseInt(_this.state.current_period_start_formatted_1),
                                    //     Interval: _this.state.interval.value,
                                    //     Payment_Source: _this.state.payment_source===null?"":_this.state.payment_source.value,
                                    //     Service_Stop_Date: parseInt(((_this.state.current_period_end_formatted_1) + (7 * 86400))), //86400 -> num of seconds in a day // Add 7 days to this
                                    //     Subscription_ID: _this.state.payment_source===null?'-':_this.state.payment_source.value,
                                    //     Subscription_Status: subscriptionStatus, //'no_subscription_yet',
                                    //     Last_Payment_Invoice_Number: _this.state.last_payment_invoice_id,
                                    //     Last_Payment_Status: _this.state.last_payment_status.value,
                                    // },
                                };
                                Firebase.firestore().collection('Sub_Locations').doc(_this.state.sub_location_id).update(update_sub_location_data)
                                    .then(function() {
                                        let batch = Firebase.firestore().batch();
                                        Firebase.firestore().collection('Services').where('Customer_ID', '==', customer_id).where('Sub_Location_ID', '==',_this.state.sub_location_id).get().then(query=> {
                                            query.docs.forEach(t =>{
                                                let updateData = { TimeZone: _this.state.time_zone.value };
                                                let serv = Firebase.firestore().collection('Services').doc(t.id);
                                                batch.update(serv, updateData);
                                            })
                                            batch.commit().then(() => {
                                                console.log('Updated all Services documents under the Sublocation with the same Timezone.');
                                                _this.setState({loading: false});
                                                _this.notifyMessage("tc", 2, "Sub Location saved successfully.");
                                                window.setTimeout(function() { _this.props.history.push("/locations") }, 2000); })
                                        }).catch(function (error) {
                                            _this.setState({loading: false});
                                            _this.notifyMessage("tc", 3, "Network error!");
                                            console.log("handleSave NetworkError5==>", error);
                                        });

                                    }).catch(function (error) {
                                    _this.setState({loading: false});
                                    _this.notifyMessage("tc", 3, "Network error!");
                                    console.log("handleSave NetworkError6==>", error);
                                });
                            }).catch(function (err) {
                                _this.setState({loading: false});
                                _this.notifyMessage("tc", 3, "Network error!");
                                console.log("handleSave NetworkError7==>", err);
                            });
                        }).catch(function (err) {
                            _this.setState({loading: false});
                            _this.notifyMessage("tc", 3, "Network error!");
                            console.log("handleSave NetworkError8==>", err);
                        });
                        }, (err) => {
                            console.log("Sub_Locations load error==> ", err);
                            _this.setState({ loading: false });
                        })
                    }
                } else {
                    let update_sub_location_data = {
                        Address: _this.state.address,
                        Name: _this.state.name,
                        Remarks: _this.state.remarks,
                        Show_On_Mobile_App: _this.state.enable_mobile,
                        Status: _this.state.active,
                        Allow_Only_One_Token_Per_Person_Per_Sublocation: _this.state.allow_only_one_token_per_person_per_sublocation,
                        Validate_Service_For_Token_Number_Range_Overlap: _this.state.validate_serv_for_tok_num_range_overlap,
                        TimeZone: _this.state.time_zone.value,
                        Geolocation: _this.state.position,
                        //Temp fix for SMS SID (until Add-ons module has been developed) --17th Jan 2022
                        SMS_Balance: _this.state.sms_balance_default,
                        SMS_Sender_ID: _this.state.customer_id !== '000000000006' ? 'MG8d4735c253ffa9ed9eb0c3af956e35d1' : 'MG9218bcc3128a2ccf282ca4af3f5e01e2',
                        SMS_Sender_Name: _this.state.customer_id !== '000000000006' ? 'Ant Queue' : 'DhiraaguCSC',
                        Mobile_App_Title: _this.state.mobile_app_title,
                        Mobile_App_Description: _this.state.mobile_app_description,
                        Mobile_App_Enable_Title_Description: _this.state.mobile_app_enable_title_description,
                        Mobile_App_Ad_1: _this.state.mobile_app_ad_1_url,
                        Mobile_App_Enable_Ad_1: _this.state.mobile_app_ad_1_enable,
                        Updated_Date: now,                                
                        Billing_Profile_Id: _this.state.billing_profile_id, //stripe_customer_id,

                        // Package_ID: (_this.state.package_id).trim(),
                        // Subscription_Details: {
                        //     //Created_Date: Math.floor(now.getTime() / 1000),
                        //     Current_Period_End: parseInt(_this.state.current_period_end_formatted_1),
                        //     Current_Period_Start:  parseInt(_this.state.current_period_start_formatted_1),
                        //     Interval: _this.state.interval.value,
                        //     Payment_Source: _this.state.payment_source===null?"":_this.state.payment_source.value,
                        //     Service_Stop_Date: parseInt(((_this.state.current_period_end_formatted_1) + (7 * 86400))), //86400 -> num of seconds in a day // Add 7 days to this
                        //     Subscription_ID: _this.state.payment_source===null?'-':_this.state.payment_source.value,
                        //     Subscription_Status: subscriptionStatus, //'no_subscription_yet',
                        //     Last_Payment_Invoice_Number: _this.state.last_payment_invoice_id,
                        //     Last_Payment_Status: _this.state.last_payment_status.value,
                        // },
                    };
                    console.log("handleSave>>>6 id:", _this.state.sub_location_id, "update:", update_sub_location_data);

                    Firebase.firestore().collection('Sub_Locations').doc(_this.state.sub_location_id).update(update_sub_location_data)
                        .then(function() {
                            let batch = Firebase.firestore().batch();
                            Firebase.firestore().collection('Services').where('Customer_ID', '==', customer_id).where('Sub_Location_ID', '==',_this.state.sub_location_id).get().then(query=> {
                                query.docs.forEach(t =>{
                                    let updateData = { TimeZone: _this.state.time_zone.value };
                                    let serv = Firebase.firestore().collection('Services').doc(t.id);
                                    batch.update(serv, updateData);
                                })
                                batch.commit().then(() => {
                                    console.log('Updated all Services documents under the Sublocation with the same Timezone.');
                                    _this.setState({loading: false});
                                    _this.notifyMessage("tc", 2, "Sub Location saved successfully.");
                                    window.setTimeout(function() { _this.props.history.push("/locations") }, 2000); })
                            }).catch(function (error) {
                                _this.setState({loading: false});
                                _this.notifyMessage("tc", 3, "Network error!");
                                console.log("handleSave NetworkError9==>", error);
                            });
                        }).catch(function (error) {
                        _this.setState({loading: false});
                        _this.notifyMessage("tc", 3, "Network error!");
                        console.log("handleSave NetworkError10==>", error);
                    });
                }

            } else if (manualSource) {
                console.log("handleSave>>>4 Manual_Payment path", "default_billing_email.email", default_billing_email.email, "billing_name", billing_name, "billing_address", billing_address, "customer_id", customer_id);
                var subscriptionStatus = 'active';
                if (_this.state.last_payment_status.value === 'Paid') { subscriptionStatus = 'active' }
                    else if (_this.state.last_payment_status.value === 'Pending') { subscriptionStatus = 'incomplete' }
                        else if (_this.state.last_payment_status.value === 'Cancelled') { subscriptionStatus = 'cancelled' }
                // try {
                //     const response = await Firebase.functions().httpsCallable('manual_saveUpdateManualBillingProfileInfo')({ 
                //         email: default_billing_email.email, //billing_email,
                //         name: billing_name,
                //         address: billing_address,
                //         antqueue_customer_id: customer_id
                //     });
                //     const res = response.data;
                //     if (res.status === 'error') {
                //         const err = res.error;
                //         _this.setState({loading: false});
                //         _this.notifyMessage("tc", 3, err.message);
                //         console.log("handleSave NetworkError3==>", err);
                //         return;
                //     }
                    
                //     stripe_customer_id = res.data.stripe_customer_doc_id;
                //     console.log("handleSave>>>3 stripe_customer_id", stripe_customer_id);
                // } catch(err) {
                //     _this.setState({loading: false});
                //     _this.notifyMessage("tc", 3, "Network error!");
                //     console.log("handleSave NetworkError4==>", err);
                // }

                var now = new Date();
                let file = this.refs.icon.state.file;
                console.log("file", file)
                if (file !== null) {
                    // --------- Check Max Icon Size Limit --------- //
                    let max_bytes = _this.state.icon_max_limit * 1024 * 1024;
                    if (file.size > max_bytes) {
                        _this.setState({loading: false});
                        _this.notifyMessage("tc", 3, "Icon file size exceeds maximum size of " + _this.state.icon_max_limit +  " MB. Please change icon!");
                    } else {
                        Firebase.firestore().collection('Sub_Locations').doc(_this.state.sub_location_id).get().then(res => {
                            
                            let metadata = {
                                customMetadata: {
                                    customer_id: customer_id,
                                    main_location_id: res.data().Main_Location_ID,
                                    sub_location_id: this.state.sub_location_id,
                                }
                            }
                            console.log(metadata);
                        var storageRef = Firebase.storage().ref();
                        var image_name = "sub_location_icon_" + this.state.sub_location_id;
                        var subLocationRef = storageRef.child(image_name);
                            subLocationRef.put(file, metadata).then(function (snapshot) {
                            subLocationRef.getDownloadURL().then(function (res) {
                                let update_sub_location_data = {
                                    Icon: res,
                                    Address: _this.state.address,
                                    Name: _this.state.name,
                                    Remarks: _this.state.remarks,
                                    Show_On_Mobile_App: _this.state.enable_mobile,
                                    Status: _this.state.active,
                                    Allow_Only_One_Token_Per_Person_Per_Sublocation: _this.state.allow_only_one_token_per_person_per_sublocation,
                                    Validate_Service_For_Token_Number_Range_Overlap: _this.state.validate_serv_for_tok_num_range_overlap,
                                    TimeZone: _this.state.time_zone.value,
                                    Geolocation: _this.state.position,
                                    Updated_Date: now,                                
                                    Billing_Profile_Id: _this.state.billing_profile_id, //stripe_customer_id,

                                    Package_ID: (_this.state.package_id).trim(),
                                    Subscription_Details: {
                                        //Created_Date: Math.floor(now.getTime() / 1000),
                                        Current_Period_End: parseInt(_this.state.current_period_end_formatted_1),
                                        Current_Period_Start:  parseInt(_this.state.current_period_start_formatted_1),
                                        Interval: _this.state.interval.value,
                                        Payment_Source: _this.state.payment_source===null?"":_this.state.payment_source.value,
                                        Service_Stop_Date: parseInt(((_this.state.current_period_end_formatted_1) + (7 * 86400))), //86400 -> num of seconds in a day // Add 7 days to this
                                        Subscription_ID: _this.state.payment_source===null?'-':_this.state.payment_source.value,
                                        Subscription_Status: subscriptionStatus, //'no_subscription_yet',
                                        Last_Payment_Invoice_Number: _this.state.last_payment_invoice_id,
                                        Last_Payment_Status: _this.state.last_payment_status.value,
                                    },
                                    //Temp fix for SMS SID (until Add-ons module has been developed) --17th Jan 2022
                                    SMS_Balance: _this.state.sms_balance_default,
                                    SMS_Sender_ID: _this.state.customer_id !== '000000000006' ? 'MG8d4735c253ffa9ed9eb0c3af956e35d1' : 'MG9218bcc3128a2ccf282ca4af3f5e01e2',
                                    SMS_Sender_Name: _this.state.customer_id !== '000000000006' ? 'Ant Queue' : 'DhiraaguCSC',
                                    Mobile_App_Title: _this.state.mobile_app_title,
                                    Mobile_App_Description: _this.state.mobile_app_description,
                                    Mobile_App_Enable_Title_Description: _this.state.mobile_app_enable_title_description,
                                    Mobile_App_Ad_1: _this.state.mobile_app_ad_1_url,
                                    Mobile_App_Enable_Ad_1: _this.state.mobile_app_ad_1_enable,
                                };
                                Firebase.firestore().collection('Sub_Locations').doc(_this.state.sub_location_id).update(update_sub_location_data)
                                    .then(function() {
                                        let batch = Firebase.firestore().batch();
                                        Firebase.firestore().collection('Services').where('Customer_ID', '==', customer_id).where('Sub_Location_ID', '==',_this.state.sub_location_id).get().then(query=> {
                                            query.docs.forEach(t =>{
                                                let updateData = { TimeZone: _this.state.time_zone.value };
                                                let serv = Firebase.firestore().collection('Services').doc(t.id);
                                                batch.update(serv, updateData);
                                            })
                                            batch.commit().then(() => {
                                                console.log('Updated all Services documents under the Sublocation with the same Timezone.');
                                                _this.setState({loading: false});
                                                _this.notifyMessage("tc", 2, "Sub Location saved successfully.");
                                                window.setTimeout(function() { _this.props.history.push("/locations") }, 2000); })
                                        }).catch(function (error) {
                                            _this.setState({loading: false});
                                            _this.notifyMessage("tc", 3, "Network error!");
                                            console.log("handleSave NetworkError5==>", error);
                                        });
                                    }).catch(function (error) {
                                    _this.setState({loading: false});
                                    _this.notifyMessage("tc", 3, "Network error!");
                                    console.log("handleSave NetworkError6==>", error);
                                });
                            }).catch(function (err) {
                                _this.setState({loading: false});
                                _this.notifyMessage("tc", 3, "Network error!");
                                console.log("handleSave NetworkError7==>", err);
                            });
                        }).catch(function (err) {
                            _this.setState({loading: false});
                            _this.notifyMessage("tc", 3, "Network error!");
                            console.log("handleSave NetworkError8==>", err);
                        });
                        }, (err) => {
                            console.log("Sub_Locations load error==> ", err);
                            _this.setState({ loading: false });
                        })
                    }
                } else {
                    let update_sub_location_data = {
                        Address: _this.state.address,
                        Name: _this.state.name,
                        Remarks: _this.state.remarks,
                        Show_On_Mobile_App: _this.state.enable_mobile,
                        Status: _this.state.active,
                        Allow_Only_One_Token_Per_Person_Per_Sublocation: _this.state.allow_only_one_token_per_person_per_sublocation,
                        Validate_Service_For_Token_Number_Range_Overlap: _this.state.validate_serv_for_tok_num_range_overlap,
                        TimeZone: _this.state.time_zone.value,
                        Geolocation: _this.state.position,
                        Updated_Date: now,                                
                        Billing_Profile_Id: _this.state.billing_profile_id, //stripe_customer_id,

                        Package_ID: (_this.state.package_id).trim(),
                        Subscription_Details: {
                            //Created_Date: Math.floor(now.getTime() / 1000),
                            Current_Period_End: parseInt(_this.state.current_period_end_formatted_1),
                            Current_Period_Start:  parseInt(_this.state.current_period_start_formatted_1),
                            Interval: _this.state.interval.value,
                            Payment_Source: _this.state.payment_source===null?"":_this.state.payment_source.value,
                            Service_Stop_Date: parseInt(((_this.state.current_period_end_formatted_1) + (7 * 86400))), //86400 -> num of seconds in a day // Add 7 days to this
                            Subscription_ID: _this.state.payment_source===null?'-':_this.state.payment_source.value,
                            Subscription_Status: subscriptionStatus, //'no_subscription_yet',
                            Last_Payment_Invoice_Number: _this.state.last_payment_invoice_id,
                            Last_Payment_Status: _this.state.last_payment_status.value,
                        },
                        //Temp fix for SMS SID (until Add-ons module has been developed) --17th Jan 2022
                        SMS_Balance: _this.state.sms_balance_default,
                        SMS_Sender_ID: _this.state.customer_id !== '000000000006' ? 'MG8d4735c253ffa9ed9eb0c3af956e35d1' : 'MG9218bcc3128a2ccf282ca4af3f5e01e2',
                        SMS_Sender_Name: _this.state.customer_id !== '000000000006' ? 'Ant Queue' : 'DhiraaguCSC',
                        Mobile_App_Title: _this.state.mobile_app_title,
                        Mobile_App_Description: _this.state.mobile_app_description,
                        Mobile_App_Enable_Title_Description: _this.state.mobile_app_enable_title_description,
                        Mobile_App_Ad_1: _this.state.mobile_app_ad_1_url,
                        Mobile_App_Enable_Ad_1: _this.state.mobile_app_ad_1_enable,
                    };
                    console.log("handleSave>>>6 id:", _this.state.sub_location_id, "update:", update_sub_location_data);

                    Firebase.firestore().collection('Sub_Locations').doc(_this.state.sub_location_id).update(update_sub_location_data)
                        .then(function() {
                            let batch = Firebase.firestore().batch();
                            Firebase.firestore().collection('Services').where('Customer_ID', '==', customer_id).where('Sub_Location_ID', '==',_this.state.sub_location_id).get().then(query=> {
                                query.docs.forEach(t =>{
                                    let updateData = { TimeZone: _this.state.time_zone.value };
                                    let serv = Firebase.firestore().collection('Services').doc(t.id);
                                    batch.update(serv, updateData);
                                })
                                batch.commit().then(() => {
                                    console.log('Updated all Services documents under the Sublocation with the same Timezone.');
                                    _this.setState({loading: false});
                                    _this.notifyMessage("tc", 2, "Sub Location saved successfully.");
                                    window.setTimeout(function() { _this.props.history.push("/locations") }, 2000); })
                            }).catch(function (error) {
                                _this.setState({loading: false});
                                _this.notifyMessage("tc", 3, "Network error!");
                                console.log("handleSave NetworkError9==>", error);
                            });
                        }).catch(function (error) {
                        _this.setState({loading: false});
                        _this.notifyMessage("tc", 3, "Network error!");
                        console.log("handleSave NetworkError10==>", error);
                    });
                }
            }
        } else {
            this.setState({loading: false});
            this.notifyMessage("tc", 3, "Please make sure all required fields are filled.");
        }
    }

    notifyMessage = (place, color, text) => {
        var type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }

        var options = {};
        options = {
            place: place,
            message: (
                <div className="text-md-center">
                    <div>
                        <b>{text}</b>
                    </div>
                </div>
            ),
            type: type,
            icon: "now-ui-icons ui-1_bell-53",
            autoDismiss: 3
        };
        if (options !== null) { this.notificationAlert.notificationAlert(options) }
    }
    isValidInvoiceID = value => {
        let _this = this;
        var docInvRefID = /^in1_[A-Za-z0-9]{20,36}$/; //in1_ followed by any alphabet char or numeric char between 20 (firestore default) to 36 chars length
        if (docInvRefID.test(value)) {
            console.log('validInvoiceID = true'); this.setState({ last_payment_invoice_idState: "has-success" }); return true;
        }
        else{
            console.log('validInvoiceID = false'); this.setState({ last_payment_invoice_idState: "has-danger" }); return false;
        }
    }
    isValidFormattedDate = (value, stateName) => {
        let _this = this;
        var dateRef = /^\d{2}[-]\d{2}[-]\d{4}$/; //dd-MM-yyyy
        if (dateRef.test(value) && value.substring(6, 10) !== '1970') {
            this.setState({ [stateName]: "has-success" });
            console.log({"value": value, [stateName]: "has-success"});
        }
        else{
            this.setState({ [stateName]: "has-danger" });
            console.log({"value": value, [stateName]: "has-danger"});
        }
    }
    isValidPackageID = value => {
        let _this = this;
        if (value !== null && value !== "") {
            Firebase.firestore().collection('Packages').doc(value.trim()).get().then(function (response) {
                if (response.exists) {
                    console.log(' validPackageID = true'); _this.setState({ package_idState: "has-success"}); return true;
                }
                else {
                    console.log(' validPackageID = false'); _this.setState({ package_idState: "has-danger"}); return false;
                }
            })
            .catch(function (error) {
                _this.setState({loading: false});
                _this.notifyMessage("tc", 3, "Network error!");
                console.log("isValidPackageID NetworkError==>", error);
            });
        }
       else {
        _this.setState({ package_idState: "has-danger"}); 
       }
    }
    // isValidBillingEmail = value => {
    //     let _this = this;
    //     let payment_source = this.state.payment_source.value;
    //     var role = this.auth_info.role;
    //     let customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;
    //     if (role === 'Site_Admin' && _this.props.match.params.id != 1) {
    //         customer_id = _this.props.match.params.id;
    //     } else {
    //         customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;
    //     }

    //     if ( payment_source =='Online_Payment' ) {
    //         Firebase.firestore().collection('Stripe_Customers').where('Stripe_Billing_User_Email', '==', value).get().then(function (response) {
    //             if (response.docs.length >= 1 && response.docs[0].data().Antqueue_Customer_ID !== customer_id) {
    //                console.log(' validEmail = false'); return false;
    //             }
    //             else {
    //                 console.log(' validEmail = true'); return true;
    //             }
    //         })
    //         .catch(function (error) {
    //             _this.setState({loading: false});
    //             _this.notifyMessage("tc", 3, "Network error!");
    //             console.log("isValidBillingEmail NetworkError==>", error);
    //         });
    
    //         return false;
    //     } else if ( payment_source =='Manual_Payment' ) {
    //         Firebase.firestore().collection('Manual_Billing_Profiles').where('Manual_Billing_User_Email', '==', value).get().then(function (response) {
    //             if (response.docs.length >= 1 && response.docs[0].data().Antqueue_Customer_ID !== customer_id) {
    //                console.log(' validEmail = false'); return false;
    //             }
    //             else {
    //                 console.log(' validEmail = true'); return true;
    //             }
    //         })
    //         .catch(function (error) {
    //             _this.setState({loading: false});
    //             _this.notifyMessage("tc", 3, "Network error!");
    //             console.log("isValidBillingEmail NetworkError==>", error);
    //         });
    
    //         return false;
    //     }
    // };
    
    verifyEmail = value => {
        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return emailRex.test(value);
    }

    handleBillingEmailChange = (newValue, actionMeta) => {
        let _this = this;
        console.group('Value Changed');
        console.log(newValue);
        console.log(`action: ${actionMeta.action}`);
        console.groupEnd();

        let payment_source = this.state.payment_source.value;
        var role = this.auth_info.role;
        let customer_id = _this.state.customer_id;
        // let customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;
        // if (role === 'Site_Admin' && _this.props.match.params.id != 1) {
        //     customer_id = _this.props.match.params.id;
        // } else {
        //     customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;
        // }
        if ( payment_source =='Online_Payment' ) {
            console.log("payment_source == 'Online_Payment'");
            if (newValue) {
                const { __isNew__, billing_name, billing_address, email, value } = newValue;

                const _email_ = __isNew__ ? value : email;
                const _name_ = __isNew__ ? '' : billing_name;
                const _address_ = __isNew__ ? '' : billing_address;

                //let customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;
                
                if (_this.verifyEmail(newValue.value) === false && actionMeta.action == 'create-option') {
                    console.log(' validEmail = false');
                    _this.setState({ billing_emailState: "has-danger" });
                    _this.setState({
                        billing_emailStateMessage: "Please provide a valid email address!",
                    });

                } else {
                    Firebase.firestore().collection('Stripe_Customers').where('Stripe_Billing_User_Email', '==', _email_).get().then(function (response) {
                        if (//1+1==0 && // TESTING_ONLY 1+1==2 || firdous//
                            response.docs.length >= 1 && response.docs[0].data().Antqueue_Customer_ID !== customer_id) {
                            console.log(' validEmail = false');
                            _this.setState({ billing_emailState: "has-danger" });
                            _this.setState({
                                billing_emailStateMessage: "This email address is already being used by another Customer. Please provide a different email address!",
                            });
                        }
                        else {
                            console.log(' validEmail = true');
                            _this.setState({
                                billing_emailStateMessage: "This field is required.",
                                billing_emailState: "has-success",
                                selected_stripe_customer: newValue,
                                billing_email: _email_,
                                billing_name: _name_,
                                billing_address: _address_,
                            });
                            console.log({
                                selected_stripe_customer: newValue,
                                billing_email: email,
                                billing_name: _name_,
                                billing_address: _address_,
                            });
                            _this.handleChange({target: {value:_name_ || ''}}, "billing_name", "length", 1);
                            _this.handleChange({target: {value:_address_ || ''}}, "billing_address", "length", 1);
                        }
                    })
                    .catch(function (error) {
                        _this.setState({loading: false});
                        _this.notifyMessage("tc", 3, "Network error!");
                        console.log("isValidBillingEmail NetworkError==>", error);
                    });
                }
                // if (!_this.isValidBillingEmail(_email_)) {
                //     _this.setState({ billing_emailState: "has-danger" });
                // }
                // else {
                //     _this.setState({
                //         billing_emailState: "has-success",
                //         selected_stripe_customer: newValue,
                //         billing_email: _email_,
                //         billing_name: _name_,
                //         billing_address: _address_,
                //     });
                //     console.log({
                //         selected_stripe_customer: newValue,
                //         billing_email: email,
                //         billing_name: _name_,
                //         billing_address: _address_,
                //     });
                //     _this.handleChange({target: {value:_name_ || ''}}, "billing_name", "length", 1);
                //     _this.handleChange({target: {value:_address_ || ''}}, "billing_address", "length", 1);
                // }

            } else {
                _this.setState({
                    selected_stripe_customer: null,
                    billing_email: '',
                    billing_name: '',
                    billing_address: '',
                    billing_nameState: '',
                    billing_addressState: '',
                });
            }
        } else if ( payment_source =='Manual_Payment' ) {
            console.log("payment_source == 'Manual_Payment'");
            if (newValue) {
                const { __isNew__, billing_name, billing_address, email, value } = newValue;

                const _email_ = __isNew__ ? value : email;
                const _name_ = __isNew__ ? '' : billing_name;
                const _address_ = __isNew__ ? '' : billing_address;

                //let customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;

                if (_this.verifyEmail(newValue.value) === false && actionMeta.action == 'create-option') {
                    console.log(' validEmail = false');
                    _this.setState({ billing_emailState: "has-danger" });
                    _this.setState({
                        billing_emailStateMessage: "Please provide a valid email address!",
                    });

                } else {
                    Firebase.firestore().collection('Manual_Billing_Profiles').where('Manual_Billing_User_Email', '==', value).get().then(function (response) {
                        if (response.docs.length >= 1 && response.docs[0].data().Antqueue_Customer_ID !== customer_id) {
                        console.log(' validEmail = false');
                        _this.setState({ billing_emailState: "has-danger" });
                        _this.setState({
                            billing_emailStateMessage: "This email address is already being used by another Customer. Please provide a different email address!",
                            });
                        }
                        else {
                            console.log(' validEmail = true');
                            _this.setState({
                                billing_emailStateMessage: "This field is required.",
                                billing_emailState: "has-success",
                                selected_manual_billing_profile: newValue,
                                billing_email: _email_,
                                billing_name: _name_,
                                billing_address: _address_,
                            });
                            console.log({
                                selected_manual_billing_profile: newValue,
                                billing_email: email,
                                billing_name: _name_,
                                billing_address: _address_,
                            });
                            _this.handleChange({target: {value:_name_ || ''}}, "billing_name", "length", 1);
                            _this.handleChange({target: {value:_address_ || ''}}, "billing_address", "length", 1);
                        }
                    })
                    .catch(function (error) {
                        _this.setState({loading: false});
                        _this.notifyMessage("tc", 3, "Network error!");
                        console.log("isValidBillingEmail NetworkError==>", error);
                    });

                    // if (!_this.isValidBillingEmail(_email_)) {
                    //     _this.setState({ billing_emailState: "has-danger" });
                    // }
                    // else {
                    //     _this.setState({
                    //         billing_emailState: "has-success",
                    //         selected_manual_billing_profile: newValue,
                    //         billing_email: _email_,
                    //         billing_name: _name_,
                    //         billing_address: _address_,
                    //     });
                    //     console.log({
                    //         selected_manual_billing_profile: newValue,
                    //         billing_email: email,
                    //         billing_name: _name_,
                    //         billing_address: _address_,
                    //     });
                    //     _this.handleChange({target: {value:_name_ || ''}}, "billing_name", "length", 1);
                    //     _this.handleChange({target: {value:_address_ || ''}}, "billing_address", "length", 1);
                    // }
                }

            } else {
                _this.setState({
                    selected_manual_billing_profile: null,
                    billing_email: '',
                    billing_name: '',
                    billing_address: '',
                    billing_nameState: '',
                    billing_addressState: '',
                });
            }
        }
    }

    verifyLength = (value, length) => {
        return value.length >= length;
    };

    onChangePosition(e) {
        this.setState({position: { lat: e.latLng.lat(), lng: e.latLng.lng() }});
        let time_zone = geoTz(e.latLng.lat(), e.latLng.lng());console.log(time_zone)
        let same_one = this.state.time_zone_list.find(item => item.value.includes(time_zone));
        this.setState({time_zone: same_one});
    }
    onWatchCurrent() {
        this.loadAddressInfo()
        let current_position = this.state.current_position;
        this.setState({position: current_position});
        this.setState({center: current_position});
        let current_time_zone = this.state.current_time_zone;
        this.setState({time_zone: current_time_zone});
    }
    getFormattedDate(date, format){
        if (format === 'format1') {
            var year = date.getFullYear();
            var month = (1 + date.getMonth()).toString();
                month = month.length > 1 ? month : '0' + month;
            var day = date.getDate().toString();
                day = day.length > 1 ? day : '0' + day;
            return day + '-' + month + '-' + year;
        }
        console.log("getFormattedDate>> date", date, "day", day, "month", month, "year", year);
    }
    static getTimeString(time) {
        if (time === null || time === undefined)
            return "";

        if (time.seconds === null || time.seconds === undefined)
            return "";

        let date = new Date(time.seconds*1000);
        return date.toLocaleString();
    }

    handleChange = (event, stateName, type, stateNameEqualTo) => {
        switch (type) {
            case "length":
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + "State"]: "has-success" });
                } else {
                    this.setState({ [stateName + "State"]: "has-danger" });
                }
                break;
            case "current_period_end":
                console.log("current_period_end event.target.value", event.target.value);
                var dateString1 = new Date(event.target.value); var year1 = dateString1.getFullYear().toString(); var month1 = dateString1.getMonth() + 1; var day1 = dateString1.getDate();
                var timestamp1 = Date.parse(dateString1); var d1 =  Math.floor((new Date(timestamp1)).getTime() / 1000);
                var d11 = this.getFormattedDate(dateString1, 'format1');
                if (event.target.value !== null && isNaN(timestamp1) == false && parseInt(year1) >= 2019) {
                    this.setState({ [stateName + "State"]: "has-success", current_period_end_formatted_1: d1, current_period_end_formatted_2: d11 });
                    console.log("(isNaN(timestamp1) == false)");
                } else {
                    this.setState({ [stateName + "State"]: "has-danger" });
                    console.log("(isNaN(timestamp1) == true)");
                }
                console.log("current_period_end d1", d1); console.log("d11", d11); console.log("event.target.value", event.target.value); console.log("dateString1", dateString1); console.log("day1-month1-year1", day1 + "-" + month1 + "-" + year1);      
                break;
            case "current_period_start":
                    console.log("current_period_start event.target.value", event.target.value);
                    var dateString2 = new Date(event.target.value); var year2 = dateString2.getFullYear().toString(); var month2 = dateString2.getMonth() + 1; var day2 = dateString2.getDate();
                    var timestamp2 = Date.parse(dateString2); var d2 =  Math.floor((new Date(timestamp2)).getTime() / 1000);
                    var d22 = this.getFormattedDate(dateString2, 'format1');
                    if (event.target.value !== null && isNaN(timestamp2) == false && parseInt(year2) >= 2019) {
                        this.setState({ [stateName + "State"]: "has-success", current_period_start_formatted_1: d2, current_period_start_formatted_2: d22 });
                        console.log("(isNaN(timestamp2) == false)");
                    } else {
                        this.setState({ [stateName + "State"]: "has-danger" });
                        console.log("(isNaN(timestamp2) == true)");
                    }
                    console.log("current_period_start d2", d2); console.log("d22", d22);  console.log("event.target.value", event.target.value); console.log("dateString2", dateString2); console.log("day2-month2-year2", day2 + "-" + month2 + "-" + year2);      
                    break;
            case "last_payment_invoice_id":
                var docInvRefID = /^in1_[A-Za-z0-9]{20,36}$/; //in1_ followed by any alphabet char or numeric char between 20 (firestore default) to 36 chars length
                if (docInvRefID.test(event.target.value)) {
                    this.setState({ [stateName + "State"]: "has-success" });
                }
                else{
                    this.setState({ [stateName + "State"]: "has-danger" });
                }
                break;
            case "mobile_app_enable_title_description":
                if (event === true) {
                    if (this.verifyLength(this.state.mobile_app_title, stateNameEqualTo)) {
                        this.setState({ [stateName + "State"]: "has-success" });
                    } else {
                        this.setState({ [stateName + "State"]: "has-danger" });
                    }
                } else if (event === false) {
                    this.setState({ [stateName + "State"]: "has-success" });
                }

                break;
            case "mobile_app_title":
                if ((this.state.mobile_app_title).length >= 1) {
                    this.setState({ "mobile_app_enable_title_descriptionState": "has-success" });
                } else {
                    if (this.state.mobile_app_enable_title_description) {
                        this.setState({ "mobile_app_enable_title_descriptionState": "has-danger" });
                    }
                }
                break;
            default:
                break;
        }
        if (stateName == "mobile_app_enable_title_description") {
            this.setState({ [stateName]: event });
        }
        else {
            this.setState({ [stateName]: event.target.value });
        }
    };

    handleAttachment(e, field) {
        var _this = this; 
        var ok = false; var customerID = JSON.parse(localStorage.getItem('auth_info')).customer_id;  //_this.state.customer_id;  
        var elementID = ""; var inputFileRef = []; var fileName = ""; var fileNameWithExt = ""; var attachment_file = ""; var attachment_file_name = "";
        var task = ""; let metadata = {};

        if (field == "mobile_app_ad_1") {
            elementID = "uploader1";
            inputFileRef = this.inputFileRef1.current.files;
            fileName = "main_location_ad_1_" + _this.state.main_location_key;
            attachment_file = inputFileRef[0];

            if (/*e.target.files[0]*/ attachment_file != undefined) {
       
                attachment_file_name = attachment_file.name; /*e.target.files[0]*/
                console.log("attachment_file_name", attachment_file_name, "fileName", fileName, "attachment_file_name.split('.')[1]", attachment_file_name.split('.')[1]);
                fileNameWithExt = fileName+'.'+attachment_file_name.split('.')[1]; 
                _this.setState({mobile_app_ad_1_name: fileNameWithExt});
                switch (attachment_file_name.slice((attachment_file_name.lastIndexOf(".") - 1 >>> 0) + 2)) { //attachment_file_name.split('.')[1] 
                    case 'mp4': ok = true; break;
                    case 'png': ok = true; break;
                    case 'jpg': ok = true; break;
                    case 'jpeg': ok = true; break;
                    case 'webp': ok = true; break;
                    default: ok = false; break;
                }
            }
            if (/*e.target.files[0]*/ attachment_file != undefined && ok) {
                console.log("this.state.main_location_key, customerID", _this.state.main_location_key, customerID);
                metadata = {
                    customMetadata: {
                        customer_id: _this.state.customer_id,
                        main_location_id: _this.state.main_location_key,
                        sub_location_id: _this.state.sub_location_id
                    }
                };

                if (/*e.target.files[0]*/ attachment_file.size <= _this.state.mobile_ad_file_max_limit * 1024 * 1024) {
                    var storageRef1 = Firebase.storage().ref('Media_Gallery/Mobile_Ads/'+customerID+'/'); console.log("handleAttachment storageRef1", storageRef1);
                    var fileName1 = fileNameWithExt; console.log("handleAttachment fileName1", fileName1);
                    var fileRef1 = storageRef1.child(fileName1); console.log("handleAttachment fileRef1", fileRef1);
                    task = fileRef1.put(attachment_file, metadata)
                    /*.then(res=>console.log('uploadsuccess'),err=>console.log('uploadfailed...',err))*/
                    document.getElementById(elementID).style.display = 'block';

                    task.on('state_changed', (snapshot) => {
                        var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        document.getElementById(elementID).value = percentage;

                    }, () => { }, () => {
                        //let _this = this, j = 0, ad = [], o = 0
                        _this.setState({ mobile_app_ad_1_attachmentState: "has-success" });
                         // .then(function (snapshot) {
                            fileRef1.getDownloadURL().then(function (res1) {
                                _this.setState({mobile_app_ad_1_url: res1});
                                console.log("handleAttachment mobile_app_ad_1_url", res1);
                            })
                        // })
                        document.getElementById(elementID).style.display = 'none';
                    })
                    task.catch(error => {
                        _this.setState({ mobile_app_ad_1_attachmentState: "has-danger" });
                        console.log('image/video upload error==> ', error);
                    })
                }
                else {
                    _this.notifyMessage("tc", 3, "Image/video AD size must be less than " + _this.state.mobile_ad_file_max_limit + " MB");
                    _this.setState({ mobile_app_ad_1_attachmentState: "has-danger" });
                }
            } else {
                console.log("fileName", fileName); console.log("attachment_file", attachment_file);
                _this.notifyMessage("tc", 3, "Please provide a valid image/video file with any one of these extensions: mp4, png, jpg, jpeg, webp!");
                _this.setState({ mobile_app_ad_1_attachmentState: "has-danger" });
            }
        }
    }

    render() {
        var ok = false;  var disabledManualPaymentRelatedFields = true;
        // console.log(this.state.role)
        if(this.state.role == "Site_Admin" || this.state.role == "System_Admin"  || this.state.role == "Billing_Admin")
            ok=true
       
        if (this.state.role == 'Site_Admin' && this.state.payment_source.value == 'Manual_Payment') {
            disabledManualPaymentRelatedFields = false;
        }
        let {
            nameState,
            billing_emailState,
            billing_emailStateMessage,
            billing_nameState,
            billing_addressState,
            package_idState,
            last_payment_invoice_idState,
            last_payment_statusState,
            last_payment_statusStateMessage,
            intervalState,
            intervalStateMessage,
            current_period_endState,
            current_period_startState,
            mobile_app_enable_title_descriptionState,
            mobile_app_ad_1_attachmentState,
        } = this.state;
        const { billing_email_list, billing_name, billing_address, billing_profile_id, default_billing_email } = this.state;
        
        return (
            <>
                <LoadingOverlay
                    active={this.state.loading}
                    spinner
                    text='Loading'
                    className='content'
                >
                    <NotificationAlert ref={(ref) => this.notificationAlert = ref} />
                    <Row>
                        <Col md="12">
                            {ok?<Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Sub Location Edit</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Col className="ml-auto mr-auto" lg="8">
                                        <Row>
                                            <Col md="3">
                                                <Button
                                                    color="success"
                                                    onClick={e => this.handleSave()}
                                                    block
                                                >
                                                    Save
                                                </Button>
                                            </Col>
                                            <Col md="3">
                                                <Button
                                                    color="youtube"
                                                    onClick={e => this.props.history.push("/locations")}
                                                    block
                                                >
                                                    Close
                                                </Button>
                                            </Col>
                                            <Col md="3">
                                                <Button
                                                    color="primary"
                                                    onClick={e => this.props.history.push("/sub_location/manage_package/" + this.state.sub_location_id)}
                                                    block
                                                >
                                                    Upgrade
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row className="top-margin-10">
                                        </Row>
                                        <Row>
                                            <Col md="8">
                                                <Form className="form-horizontal">
                                                    <hr/>
                                                    <Row>
                                                        <Col md="12">
                                                            <FormGroup>
                                                            <h5>General</h5>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Main Location</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Select
                                                                    className="react-select primary"
                                                                    classNamePrefix="react-select"
                                                                    name="mainLocationSelect"
                                                                    value={this.state.selected_main_location}
                                                                    isDisabled={true}
                                                                    //options={this.state.main_location_list}
                                                                    placeholder="Select Main Location"
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Sub Location Key</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Input
                                                                    type="text"
                                                                    defaultValue={this.state.sub_location_id}
                                                                    disabled
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Sub Location Name</Label>
                                                        <Col md="8">
                                                            <FormGroup className={`has-label ${nameState}`}>
                                                                <Input
                                                                    placeholder="Enter Sub Location Name"
                                                                    type="text"
                                                                    defaultValue={this.state.name}
                                                                    onChange={e =>
                                                                        this.handleChange(e, "name", "length", 1)
                                                                    }
                                                                />
                                                                {this.state.nameState === "has-danger" ? (
                                                                    <label className="error">This field is required.</label>
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Icon</Label>
                                                        <Col md="8">
                                                            <IconUpload ref="icon" />
                                                        </Col>
                                                    </Row>
                                                    <Row className="top-margin-10"/>
                                                    <Row>
                                                        <Label md="4">Address</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Input
                                                                    type="text"
                                                                    defaultValue={this.state.address}
                                                                    onChange={e => {this.setState({address: e.target.value})}}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Enable on mobile app</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Row className="top-margin-7"/>
                                                                <Switch
                                                                    offColor="success"
                                                                    offText={<i className="nc-icon nc-simple-remove" />}
                                                                    onColor="success"
                                                                    onText={<i className="nc-icon nc-check-2" />}
                                                                    disabled={!this.state.package_enable_mobile}
                                                                    defaultValue={this.state.enable_mobile}
                                                                    value={this.state.enable_mobile}
                                                                    onChange={e => {this.setState({enable_mobile: e.state.value})}}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Active/Inactive</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Row className="top-margin-7"/>
                                                                <Switch
                                                                    offColor="success"
                                                                    offText={<i className="nc-icon nc-simple-remove" />}
                                                                    onColor="success"
                                                                    onText={<i className="nc-icon nc-check-2" />}
                                                                    defaultValue={this.state.active}
                                                                    value={this.state.active}
                                                                    onChange={e => {this.setState({active: e.state.value})}}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Allow only One Token per Person per Sublocation</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Row className="top-margin-7"/>
                                                                <Switch
                                                                    offColor="success"
                                                                    offText={<i className="nc-icon nc-simple-remove" />}
                                                                    onColor="success"
                                                                    onText={<i className="nc-icon nc-check-2" />}
                                                                    defaultValue={this.state.allow_only_one_token_per_person_per_sublocation}
                                                                    value={this.state.allow_only_one_token_per_person_per_sublocation}
                                                                    onChange={e => {this.setState({allow_only_one_token_per_person_per_sublocation: e.state.value})}}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>  
                                                    : ,
                                                    <Row>
                                                        <Label md="4">Validate Service for Token Number Range overlap</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Row className="top-margin-7"/>
                                                                <Switch
                                                                    offColor="success"
                                                                    offText={<i className="nc-icon nc-simple-remove" />}
                                                                    onColor="success"
                                                                    onText={<i className="nc-icon nc-check-2" />}
                                                                    defaultValue={this.state.validate_serv_for_tok_num_range_overlap}
                                                                    value={this.state.validate_serv_for_tok_num_range_overlap}
                                                                    onChange={e => {this.setState({validate_serv_for_tok_num_range_overlap: e.state.value})}}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    {/* <Row>
                                                        <Label md="4">Package</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Input
                                                                    defaultValue={this.state.package_name}
                                                                    type="text"
                                                                    disabled
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row> */}
                                                    <Row>
                                                        <Label md="4">Time Zone</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Select
                                                                    className="react-select primary"
                                                                    classNamePrefix="react-select"
                                                                    name="timezoneSelect"
                                                                    value={this.state.time_zone}
                                                                    onChange={value =>
                                                                        this.setState({ time_zone: value })
                                                                    }
                                                                    options={this.state.time_zone_list}
                                                                    placeholder="Select time zone"
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Google Map</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Button
                                                                    className="btn-round"
                                                                    color="primary"
                                                                    outline
                                                                    onClick={e => this.onWatchCurrent()}
                                                                >
                                                                    <i className="fa fa-eye" />
                                                                    Where I am
                                                                </Button>
                                                                <CustomMap
                                                                    ref="custom_map"
                                                                    api_key={info.google_map_api_key}
                                                                    center={this.state.center}
                                                                    position={this.state.position}
                                                                    zoom={this.state.map_zoom}
                                                                    onMarkerDragEnd={e => this.onChangePosition(e)}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row className="top-margin-10">
                                                        <Label md="4">Remarks</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Input
                                                                    value={this.state.remarks}
                                                                    type="textarea"
                                                                    onChange={e => {this.setState({remarks: e.target.value})}}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <hr/>
                                                    <Row>
                                                        <Col md="12">
                                                            <FormGroup>
                                                            <h5>Billing</h5>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row className="top-margin-10">
                                                        <Label md="4">Payment Source</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Select
                                                                    className="react-select info"
                                                                    classNamePrefix="react-select"
                                                                    value={this.state.payment_source}
                                                                    isDisabled={(this.state.role !== 'Site_Admin') ? true: false} //this.state.payment_source_disable_selection
                                                                    onChange={value =>
                                                                        this.setState({ payment_source: value })
                                                                    }
                                                                    options={this.state.payment_source_options_list}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    {this.state.payment_source.value =='Manual_Payment' ?
                                                    <div>
                                                    <Row className="top-margin-10">
                                                        <Label md="4">Payment Interval</Label>
                                                        <Col md="8">
                                                            <FormGroup className={`has-label ${intervalState}`}>
                                                                <Select
                                                                    className="react-select info"
                                                                    classNamePrefix="react-select"
                                                                    value={this.state.interval}
                                                                    isDisabled={disabledManualPaymentRelatedFields} //this.state.interval_disable_selection 
                                                                    onChange={value =>
                                                                        this.setState({ interval: value })
                                                                    }
                                                                    options={this.state.interval_options_list}
                                                                />
                                                                {intervalState === "has-danger" ? (
                                                                    <label className="error">{`${intervalStateMessage}`}</label>
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Package ID</Label>
                                                        <Col md="8">
                                                            <FormGroup className={`has-label ${package_idState}`}>
                                                                <Input
                                                                    placeholder="Package ID"
                                                                    type="text"
                                                                    value={this.state.package_id}
                                                                    disabled={disabledManualPaymentRelatedFields} //this.state.package_id_disable_selection 
                                                                    onChange={e =>
                                                                        this.handleChange(e, "package_id", "length", 1)
                                                                    }
                                                                />
                                                                {this.state.package_idState === "has-danger" ? (
                                                                    <label className="error">A valid Package ID is required for this field.</label>
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Last Payment Invoice ID</Label>
                                                        <Col md="8">
                                                            <FormGroup className={`has-label ${last_payment_invoice_idState}`}>
                                                                <Input
                                                                    placeholder="in1_XXXXXXXXXXXXXXXXXXXX"
                                                                    type="text"
                                                                    value={this.state.last_payment_invoice_id}
                                                                    disabled={disabledManualPaymentRelatedFields}
                                                                    onChange={e =>
                                                                        this.handleChange(e, "last_payment_invoice_id", "last_payment_invoice_id", 1)
                                                                    }
                                                                />
                                                                {this.state.last_payment_invoice_idState === "has-danger" ? (
                                                                    <label className="error">A valid Invoice ID is required for this field.</label>
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row className="top-margin-10">
                                                        <Label md="4">Payment Status</Label>
                                                        <Col md="8">
                                                            <FormGroup className={`has-label ${last_payment_statusState}`}>
                                                                <Select
                                                                    className="react-select info"
                                                                    classNamePrefix="react-select"
                                                                    value={this.state.last_payment_status}
                                                                    isDisabled={disabledManualPaymentRelatedFields}
                                                                    onChange={value =>
                                                                        this.setState({ last_payment_status: value })
                                                                    }
                                                                    options={this.state.last_payment_status_options_list}
                                                                />
                                                                {this.state.last_payment_statusState === "has-danger" ? (
                                                                    <label className="error">{`${last_payment_statusStateMessage}`}</label>
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Current Period Start Date</Label>
                                                        <Col md="8">
                                                            <FormGroup /*className={`has-label ${current_period_startState}`}*/ >
                                                                <DatePicker
                                                                    format="dd-MM-yyyy"
                                                                    value={this.state.current_period_start}
                                                                    disabled={disabledManualPaymentRelatedFields}
                                                                    onChange={e =>
                                                                        this.handleChange(e, "current_period_start", "current_period_start")
                                                                    }
                                                                />
                                                                {/* {this.state.current_period_startState === "has-danger" ? (
                                                                    <label className="error">This field is required.</label>
                                                                ) : null} */}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Current Period End Date</Label>
                                                        <Col md="8">
                                                            <FormGroup /*className={`has-label ${current_period_endState}`}*/ >
                                                                <DatePicker
                                                                    format="dd-MM-yyyy"
                                                                    value={this.state.current_period_end}
                                                                    disabled={disabledManualPaymentRelatedFields}
                                                                    onChange={e =>
                                                                        this.handleChange(e, "current_period_end", "current_period_end")
                                                                    }
                                                                />
                                                                {/* {this.state.current_period_endState === "has-danger" ? (
                                                                    <label className="error">This field is required.</label>
                                                                ) : null} */}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    </div>
                                                    : null}
                                                    {/* {this.state.payment_source.value =='Online_Payment' ?
                                                    <Row>
                                                        <Label md="4">Package ID</Label>
                                                        <Col md="8">
                                                            <FormGroup className={`has-label ${package_idState}`}>
                                                                <Input
                                                                    placeholder="Package ID"
                                                                    type="text"
                                                                    disabled={true}
                                                                    value={this.state.package_id}
                                                                    //onChange={e =>
                                                                    //    this.handleChange(e, "package_id", "length", 1)
                                                                    //}
                                                                />
                                                                {this.state.package_idState === "has-danger" ? (
                                                                    <label className="error">A valid Package ID is required for this field.</label>
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    : null} */}
                                                    {billing_email_list && /*billing_email_list && billing_profile_id !== null &&*/
                                                    <div>
                                                        <Row>
                                                            <Label md="4">Billing Email</Label>
                                                            <Col md="8">
                                                                <FormGroup className={`has-label ${billing_emailState}`}>
                                                                    <CreatableSelect
                                                                        isClearable
                                                                        defaultValue={default_billing_email}
                                                                        isDisabled={this.state.billing_email_disable_selection}
                                                                        // isValidNewOption={this.isValidBillingEmail}
                                                                        //value={this.state.billing_email}
                                                                        onChange={this.handleBillingEmailChange}
                                                                        options={billing_email_list}
                                                                    />
                                                                    {billing_emailState === "has-danger" ? (
                                                                        <label className="error">{`${billing_emailStateMessage}`}</label>
                                                                    ) : null}
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Label md="4"></Label>
                                                            <Col md="8">
                                                                <FormGroup>
                                                                <label className="error">If you select an existing billing email address and change related billing information (name and/or address fields below), corresponding billing profile will also be updated. </label>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    }
                                                    <Row>
                                                        <Label md="4">Billing Name</Label>
                                                        <Col md="8">
                                                            <FormGroup className={`has-label ${billing_nameState}`}>
                                                                <Input
                                                                    placeholder="Your Billing Name"
                                                                    type="text"
                                                                    value={billing_name}
                                                                    // disabled={!selected_stripe_customer}
                                                                    onChange={e =>
                                                                        this.handleChange(e, "billing_name", "length", 1)
                                                                    }
                                                                />
                                                                {billing_nameState === "has-danger" ? (
                                                                    <label className="error">This field is required.</label>
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Billing Address</Label>
                                                        <Col md="8">
                                                            <FormGroup className={`has-label ${billing_addressState}`}>
                                                                <Input
                                                                    placeholder="Your Billing Address"
                                                                    type="text"
                                                                    value={ billing_address }
                                                                    // disabled={!selected_stripe_customer}
                                                                    onChange={e =>
                                                                        this.handleChange(e, "billing_address", "length", 1)
                                                                    }
                                                                />
                                                                {billing_addressState === "has-danger" ? (
                                                                    <label className="error">This field is required.</label>
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <hr/>
                                                    <Row>
                                                        <Col md="12">
                                                            <FormGroup>
                                                            <h5>Mobile App Advertising</h5>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row className="top-margin-10">
                                                    <Label md="4">Title</Label>
                                                        <Col md="8">
                                                            <FormGroup className={`has-label ${mobile_app_enable_title_descriptionState}`}>
                                                                <Input
                                                                    defaultValue={this.state.mobile_app_title}
                                                                    type="text"
                                                                    onChange={e =>
                                                                        this.handleChange(e, "mobile_app_title", "mobile_app_title", 1)
                                                                    }
                                                                />
                                                                {this.state.mobile_app_enable_title_descriptionState === "has-danger" ? (
                                                                    <label className="error">This field is required if "Enable Title & Description" field is enabled.</label>
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row className="top-margin-10">
                                                        <Label md="4">Description</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Input
                                                                    defaultValue={this.state.mobile_app_description}
                                                                    type="text"
                                                                    onChange={e => {this.setState({mobile_app_description: (e.target.value).trim()})}}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Enable Title & Description</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Row className="top-margin-7"/>
                                                                <Switch
                                                                    offColor="success"
                                                                    offText={<i className="nc-icon nc-simple-remove" />}
                                                                    onColor="success"
                                                                    onText={<i className="nc-icon nc-check-2" />}
                                                                    defaultValue={this.state.mobile_app_enable_title_description}
                                                                    value={this.state.mobile_app_enable_title_description}
                                                                    onChange={e => {this.handleChange(e.state.value, "mobile_app_enable_title_description", "mobile_app_enable_title_description", 1)}}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row className="top-margin-10" />
                                                    <Row md='12' className='px-0 mx-0'>
                                                        <Label md="4">Advertisement 1 Image / Video File</Label>

                                                        <Col md='8' style={{ paddingLeft: '0px', paddingBottom: '15px' }} >
                                                            <progress id="uploader1" value="0" max="100" style={{ display: 'none' }}>0%</progress>
                                                            <input type="file" id='file1' ref={this.inputFileRef1} key={this.state.inputKey1}
                                                                onChange={e => this.handleAttachment(e, "mobile_app_ad_1")}
                                                            />
                                                            {this.state.mobile_app_ad_1_name != undefined ?
                                                                (this.state.mobile_app_ad_1_name.length > 0 ?
                                                                    <Row md='12' className='px-0 mx-0'>
                                                                    <img src={trash} style={{ width: '15px', marginTop: '3px' }} alt=""
                                                                        onClick={() => {
                                                                            console.log("this.state.mobile_app_ad_1_name", this.state.mobile_app_ad_1_name);
                                                                            var task1 = Firebase.storage().ref('Media_Gallery/Mobile_Ads/'+this.state.customer_id+'/').child(this.state.mobile_app_ad_1_name).delete();
                                                                            task1.catch(error => { 
                                                                                console.log("task1 error", error);
                                                                            })
                                                                            
                                                                            this.setState({
                                                                                inputKey1: Math.random().toString(36),
                                                                                mobile_app_ad_1: '',
                                                                                mobile_app_ad_1_name: '',
                                                                                mobile_app_ad_1_url: '',
                                                                                mobile_app_ad_1_attachmentState: '',
                                                                            })
                                                                        }}
                                                                    /></Row> : null)
                                                                : null}
                                                            {this.state.mobile_app_ad_1_attachmentState === "has-danger" ? (
                                                                <div className="has-label has-danger position-relative form-group">
                                                                    <label className="error">Please provide a valid image/video file of type png, jpg, jpeg, webp OR mp4!!</label>
                                                                </div>
                                                            ) : null}
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Enable Advertisement 1</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Row className="top-margin-7"/>
                                                                <Switch
                                                                    offColor="success"
                                                                    offText={<i className="nc-icon nc-simple-remove" />}
                                                                    onColor="success"
                                                                    onText={<i className="nc-icon nc-check-2" />}
                                                                    defaultValue={this.state.mobile_app_ad_1_enable}
                                                                    value={this.state.mobile_app_ad_1_enable}
                                                                    onChange={e => {this.setState({mobile_app_ad_1_enable: e.state.value})}}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4"></Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <label className="error">Mobile App Ad can only be activated after purchasing the add-on &quot;Add-On: Sub Locations Ad 1&quot;.</label>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Card className="card-plain width-100">
                                                            <CardHeader role="tab">
                                                                <a
                                                                    aria-expanded={this.state.openedCollapses.includes(
                                                                        "show-more"
                                                                    )}
                                                                    href="#"
                                                                    data-parent="#accordion"
                                                                    data-toggle="collapse"
                                                                    onClick={e => {e.preventDefault(); this.collapsesToggle("show-more")}}
                                                                >
                                                                    {this.state.openedCollapses.includes("show-more")?'Show Less...':'Show More...'}{" "}
                                                                </a>
                                                            </CardHeader>
                                                            <Collapse
                                                                role="tabpanel"
                                                                isOpen={this.state.openedCollapses.includes(
                                                                    "show-more"
                                                                )}
                                                            >
                                                                <CardBody>
                                                                    <Row>
                                                                        <Label md="4">Expiry Date</Label>
                                                                        <Col md="8">
                                                                            <FormGroup>
                                                                                <Input
                                                                                    type="text"
                                                                                    value={this.state.expire}
                                                                                    disabled
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Label md="4">Status</Label>
                                                                        <Col md="8">
                                                                            <FormGroup>
                                                                                <Input
                                                                                    type="text"
                                                                                    value={this.state.status}
                                                                                    disabled
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Label md="4">Pending Queue Count</Label>
                                                                        <Col md="8">
                                                                            <FormGroup>
                                                                                <Input
                                                                                    defaultValue={this.state.pending_count}
                                                                                    type="text"
                                                                                    disabled
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Label md="4">Created Datetime</Label>
                                                                        <Col md="8">
                                                                            <FormGroup>
                                                                                <Input
                                                                                    value={SubLocationEdit.getTimeString(this.state.created_date_time)}
                                                                                    type="text"
                                                                                    disabled
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Label md="4">Stop Datetime</Label>
                                                                        <Col md="8">
                                                                            <FormGroup>
                                                                                <Input
                                                                                    value={SubLocationEdit.getTimeString(this.state.service_stop_date_time)}
                                                                                    type="text"
                                                                                    disabled
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Label md="4">Last Paid Date</Label>
                                                                        <Col md="8">
                                                                            <FormGroup>
                                                                                <Input
                                                                                    value={SubLocationEdit.getTimeString(this.state.last_paid_date)}
                                                                                    type="text"
                                                                                    disabled
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Label md="4">Notification Date</Label>
                                                                        <Col md="8">
                                                                            <FormGroup>
                                                                                <Input
                                                                                    value={SubLocationEdit.getTimeString(this.state.notification_date)}
                                                                                    type="text"
                                                                                    disabled
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Label md="4">Final Notification Date</Label>
                                                                        <Col md="8">
                                                                            <FormGroup>
                                                                                <Input
                                                                                    value={SubLocationEdit.getTimeString(this.state.final_notification_date)}
                                                                                    type="text"
                                                                                    disabled
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Label md="4">Next Payment Date</Label>
                                                                        <Col md="8">
                                                                            <FormGroup>
                                                                                <Input
                                                                                    value={SubLocationEdit.getTimeString(this.state.next_payment_date)}
                                                                                    type="text"
                                                                                    disabled
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            </Collapse>
                                                        </Card>
                                                    </Row>
                                                </Form>
                                            </Col>
                                        </Row>
                                    </Col>
                                </CardBody>
                            </Card>: "Sorry, you don't have permission to use this function, Please contact System Administrator if this is a mistake."}
                        </Col>
                    </Row>
                </LoadingOverlay>
            </>
        );
    }
}

export default SubLocationEdit;
