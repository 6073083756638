import React from "react";
import NotificationAlert from "react-notification-alert";
import Firebase from "firebase";
import LoadingOverlay from "react-loading-overlay";
import CopyToClipboard from "react-copy-to-clipboard";
import Select from "react-select";
import Switch from "react-bootstrap-switch";
import IconUpload from "../../../components/CustomUpload/IconUpload";

import {
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Label,
    Button,
    Input,
    Form,
    FormGroup
} from "reactstrap";
import { callApiHandleCounterRun } from "./ServiceAddEditFuncs";
import config from "../../../config";
//var t0 = performance.now(); //(1+1!=2) && // TESTING_ONLY 1+1==2 || firdous//

const uuidv1 = require('uuid/v1');
class CounterAdd extends React.Component {
    constructor(props) {
        super(props);
        if (!Firebase.apps.length) {
            Firebase.initializeApp(config);
        }

        this.state = {
            loading: false,
            customer_id: '',
            current_time: '',
            lock_to: false,
            footer_text: '',
            button_layout_format: 'full',
            enable_print_logo: true,
            print_type: {
                value: 'normal',
                label: 'Normal thermal printer'
            },
            time_zone: '',
            counter_id: '',
            counter_name: '',
            counter_nameState: '',
            mac_addState: '',
            mac_add: '',
            main_location_list: [],
            sub_location_list: [],
            selected_main_location: null,
            selected_sub_location: null,
            height: 0,
            width: 0,
            call_based_on_service_date: true,
            call_specific_service: true,
            // print_specific_service: false,
            generate_token_for_specific_service: false,
            call_specific_token: false,
            modify_service_details_for_display: false,
            reset_counter_at_day_close: true,
            invalid_width: false,
            pin: '',
            pinState: '',
            pin_type: 'password',
            location_package_id: '',
            location_image_url: '',
            location_name: '',
            location_address: '',
            service_list: [],
            filtered_services: [],
            serving_services: []
        };

        // this.functions = null;                                              //functions emulator
        // this.fbStore = null;                                                //functions emulator
    }

    // initializeFirebase = () => {                                            //functions emulator
    // if (!this.functions) {                                              //functions emulator
    // this.functions = Firebase.functions();                          //functions emulator
    // this.functions.useFunctionsEmulator('http://localhost:5001');   //functions emulator
    // this.fbStore = Firebase.firestore();                            //functions emulator
    // }                                                                   //functions emulator
    // }                                                                       //functions emulator

    componentDidMount() {
        // this.initializeFirebase();                                          //functions emulator
        this.getCurrentTime();
        let _this = this;
        var customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;
        _this.setState({ customer_id: customer_id });

        let counter_id = uuidv1();
        var role = JSON.parse(localStorage.getItem('auth_info')).role;
        this.setState({ counter_id: counter_id, role: role });
        this.loadMainLocations();
    }

    loadMainLocations() {
        let _this = this;
        _this.setState({ loading: true });
        let main_locations = [];
        var customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;
        _this.setState({ customer_id: customer_id });

        Firebase.firestore().collection('Main_Locations').where('Customer_ID', '==', customer_id).get().then(function (response) {
            response.docs.forEach(function (doc) {
                main_locations.push({ label: doc.data().Name, value: doc.id });
            });

            _this.setState({ main_location_list: main_locations });
            if (main_locations.length > 0) {
                _this.setState({ selected_main_location: main_locations[0] });
                _this.loadSubLocationByMain(main_locations[0].value);
            } else {
                _this.setState({ loading: false });
            }
        }).catch(function (err) {
            _this.setState({ loading: false });
            _this.notifyMessage("tc", 3, "Network error!");
            console.log("loadMainLocations NetworkError1==>", err);
        });
    }
    loadSubLocationByMain(main_id) {
        let _this = this;
        _this.setState({ loading: true });
        _this.setState({ selected_sub_location: null });
        let sub_locations = [];
        let customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;
        Firebase.firestore().collection('Sub_Locations').where('Customer_ID', '==', customer_id).where('Main_Location_ID', '==', main_id).get().then(function (response) {
            response.docs.forEach(function (doc) {
                sub_locations.push({ label: doc.data().Name, value: doc.id, package_id: doc.data().Package_ID, image_url: doc.data().Icon, address: doc.data().Address });
            });

            _this.setState({ sub_location_list: sub_locations });
            if (sub_locations.length > 0) {
                let first_one = sub_locations[0];
                _this.setState({ selected_sub_location: first_one });
                _this.setState({ location_package_id: first_one.package_id });
                _this.setState({ location_image_url: first_one.image_url });
                _this.setState({ location_name: first_one.label });
                _this.setState({ location_address: first_one.address });
                _this.loadServiceListBySub(sub_locations[0].value);
            } else {
                _this.setState({ loading: false });
                _this.notifyMessage("tc", 3, "This main location does not have any sub locations.");
            }
        }).catch(function (err) {
            _this.setState({ loading: false });
            _this.notifyMessage("tc", 3, "Network error!");
            console.log("loadSubLocationByMain NetworkError2==>", err);
        });
    }
    loadServiceListBySub(sub_id) {
        let _this = this;
        _this.setState({ loading: true });
        let services = [];
        let customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;
        Firebase.firestore().collection('Services').where('Customer_ID', '==', customer_id).where('Sub_Location_ID', '==', sub_id).get().then(function (response) {
            response.docs.forEach(function (doc) {
                services.push({ thiss: _this, service_id: doc.id, service_name: doc.data().Name, service_icon: doc.data().Icon, startNum: doc.data().Start_Number, endNum: doc.data().End_Number, service_details: doc.data().Details });
            });
            let sorted = services.sort((a, b) => a.service_name.localeCompare(b.service_name));

            _this.setState({ service_list: sorted });
            _this.setState({ loading: false });
        }).catch(function (err) {
            _this.setState({ loading: false });
            _this.notifyMessage("tc", 3, "Network error!");
            console.log("loadServiceListBySub NetworkError3==>", err);
        });

        Firebase.firestore().collection('Sub_Locations').doc(sub_id).get().then((e) => {
            if (!e.empty) {
                //this.setState({ sublocation_id: e.id, sublocation_name: e.data().Name, sub_offset: e.data().TimeZone.split('UTC')[1].split(':')[0] });
                _this.setState({ time_zone: e.data().TimeZone }); //console.log("TimeZone", e.data().TimeZone);
            }
        })
    }

    getCurrentTime() {
        let _this = this;
        let now = new Date();
        _this.setState({ current_time: now.toLocaleString() });
        window.setTimeout(function () { _this.getCurrentTime() }, 500);
    }
    async serviceDetUpdate(type = 'counter_add') {
        let params = {
            version: '1',
            cmd_: 'C8',
            customerId: this.state.customer_id || '',
            mainLocId: this.state.main_location || '',
            subLocId: this.state.sub_location_id || '',
            // counterId: counter_id || '',
            emailId: JSON.parse(localStorage.getItem("auth_info")).email || '',
            userId: JSON.parse(localStorage.getItem("auth_info")).email || '',
            userName: this.state.logged_userName || '',
            timeZone: this.state.time_zone || '',
            refAction: type
        }, c8Result
        c8Result = await callApiHandleCounterRun({ params });
        console.log('c8Result', c8Result)
    }


    handleAdd() {
        let _this = this;
        _this.setState({ loading: true });
        Firebase.functions().httpsCallable('getPaymentRestrictionsBasedOnSelectedPackage')({
            sub_location_id: _this.state.selected_sub_location.value,
            type: 'COUNTERS',
            antqueue_customer_id: _this.state.customer_id,
        }).then(function (result) {
            const res = result.data;
            console.log('validateItemCount', { result });
            if (res?.status === 'ok') {
                try {
                    var limitExceeded = res.data.countExceedsLimit;
                    console.log('validateItemCount>>> countExceedsLimit', limitExceeded);
                    _this.setState({ loading: false });
                    // return limitExceeded;
                    if (limitExceeded === true) {
                        _this.notifyMessage("tc", 3, "Sorry, the selected package for current sub location does not allow any more Counters to be added.");
                    } else {
                        if (_this.state.counter_nameState === "") {
                            _this.setState({ counter_nameState: "has-danger" });
                        }
                        // if (_this.state.mac_addState === "") {
                        //     _this.setState({ mac_addState: "has-danger" });
                        // }

                        if (_this.state.counter_nameState === "has-success") {
                            let customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;
                            let now = new Date();
                            _this.setState({ loading: true });

                            // console.log("_this.state.serving_services", _this.state.serving_services);

                            // if (_this.state.serving_services != undefined && _this.state.serving_services.length > 0) {

                            //     if (_this.checkSameRangeTokenFunction() == true) {
                            //         _this.notifyMessage("tc", 3, "One or more Service(s) configured for this Counter has the same token range as another Service in this Counter. Please contact your System Administrator as this is not currently allowed!");
                            //         _this.setState({loading: false});
                            //     } else {
                            //         // continue as normal

                            let currTimestamp = Math.floor(now.getTime());
                            let currFirestoreTS = Firebase.firestore.FieldValue.serverTimestamp(); //new Date();
                            let currToken = {
                                "date_stamp": currTimestamp,
                                "curr_firestore_timestamp": currFirestoreTS,
                                "number": "0000"
                            };
                            let new_counter_data = {
                                Counter_Name: _this.state.counter_name,
                                Lock_To_Specific_Computer: _this.state.lock_to ? _this.state.mac_add : '',
                                // Logged_In_User_ID:_this.state.customer_id,
                                // Logged_In_User_Name:'',
                                Customer_ID: customer_id,
                                Main_Location_ID: _this.state.selected_main_location.value,
                                Print_Token: false,
                                Current_Token: currToken,
                                Serving_Services: _this.state.serving_services,
                                Filtered_Services: _this.state.serving_services,
                                Counter_State: 'Closed',
                                Sub_Location_ID: _this.state.selected_sub_location.value,
                                Call_Based_On_Service_Date: _this.state.call_based_on_service_date,
                                User_Can_Call_Specific_Service: _this.state.call_specific_service,
                                // User_Can_Print_Specific_Service: _this.state.print_specific_service,
                                User_Can_Generate_Token_For_Specific_Service: _this.state.generate_token_for_specific_service,
                                User_Can_Call_Specific_Token: _this.state.call_specific_token,
                                User_Can_Modify_Service_Details_For_Display: _this.state.modify_service_details_for_display,
                                Reset_Counter_At_Day_Close: _this.state.reset_counter_at_day_close,
                                Last_Called_Token: '',
                                Blink: false,
                                TimeZone: _this.state.time_zone,
                                Created_Date: now,
                                Updated_Date: now
                            };

                            Firebase.firestore().collection('Counters').doc(_this.state.counter_id).set(new_counter_data).then(function () {
                                _this.setState({ loading: false });
                                _this.notifyMessage("tc", 2, "New Counter added successfully.");
                                _this.serviceDetUpdate()
                                window.setTimeout(function () { _this.props.history.push("/counters") }, 2000);
                            }).catch(function (error) {
                                _this.setState({ loading: false });
                                _this.notifyMessage("tc", 3, "Network error!");
                                console.log("handleAdd NetworkError4==>", error);
                            });

                            // console.log("new_counter_data", new_counter_data);
                            // }

                            // } else {
                            //     _this.notifyMessage("tc", 3, `There are no services attached to this counter. You must attach at least one service to continue!`);
                            // }
                        }
                    }
                } catch (err) {
                    _this.setState({
                        loading: false,
                    })
                    _this.notifyMessage("tc", 3, "Network error!");
                    console.log("handleAdd NetworkError1==>", err);
                }
            }
        })
            .catch((error) => {
                _this.setState({ loading: false });
                console.log('validateItemCount', { error });
                return error;
            });
    }

    // checkSameRangeTokenFunction() {
    //     let _this = this;
    //     let servicesList = [];
    //     let sameRangeServiceInCounter = false; //true;
    //     let sameRangeServiceInCounterArr = [];
    //     let servingServiceIds = [];
    //     servingServiceIds = _this.state.serving_services;
    //     console.log("_this.state.counter_id", _this.state.counter_id);

    //     console.log("result 1 timestamp_ms", performance.now()-t0); //(1+1!=2) && // TESTING_ONLY 1+1==2 || firdous//

    //     // Firebase.firestore().collection('Counters').doc(_this.state.counter_id).get().then(counterDoc => {
    //         for (let h = 0; h< servingServiceIds.length; h++) { //counterDoc.data().Serving_Services.forEach(e => {
    //             let e = servingServiceIds[h];
    //             console.log("e", e);
    //             if (sameRangeServiceInCounter == false) {
    //                 Firebase.firestore().collection('Services').doc(e).get().then(d => {
    //                     console.log("servid", e);

    //                     var startNum = parseInt(d.data().Start_Number);
    //                     var endNum = parseInt(d.data().End_Number);

    //                     console.log("servicesList", servicesList);

    //                     for (var i = 0; i < servicesList.length; i++) { //for (var i = -servicesList.length; i++ ;) {
    //                         console.log("reached here");
    //                         var servListStartNum = servicesList[i].start_num;
    //                         var servListEndNum = servicesList[i].end_num;

    //                         if (i == 0) { console.log("servicesList i=0 || length ||", servicesList, servicesList.length); } //(1+1!=2) && // TESTING_ONLY 1+1==2 || firdous//
    //                         if (i == 1) { console.log("servicesList i=1 || length ||", servicesList, servicesList.length); } //(1+1!=2) && // TESTING_ONLY 1+1==2 || firdous//
    //                         if (i == 2) { console.log("servicesList i=2 || length ||", servicesList, servicesList.length); } //(1+1!=2) && // TESTING_ONLY 1+1==2 || firdous//
    //                         if (i == 3) { console.log("servicesList i=3 || length ||", servicesList, servicesList.length); } //(1+1!=2) && // TESTING_ONLY 1+1==2 || firdous//
    //                         if (i == 4) { console.log("servicesList i=4 || length ||", servicesList, servicesList.length); } //(1+1!=2) && // TESTING_ONLY 1+1==2 || firdous//
    //                         if (i == 5) { console.log("servicesList i=5 || length ||", servicesList, servicesList.length); } //(1+1!=2) && // TESTING_ONLY 1+1==2 || firdous//
    //                         if (i == 6) { console.log("servicesList i=6 || length ||", servicesList, servicesList.length); } //(1+1!=2) && // TESTING_ONLY 1+1==2 || firdous//
    //                         if (i == 7) { console.log("servicesList i=7 || length ||", servicesList, servicesList.length); } //(1+1!=2) && // TESTING_ONLY 1+1==2 || firdous//

    //                         if  (
    //                                 // (i==2 && 1+1!=2) && // TESTING_ONLY 1+1==2 || firdous//
    //                                 (startNum > servListStartNum && startNum > servListEndNum && endNum > servListStartNum && endNum > servListEndNum) ||
    //                                 (startNum < servListStartNum && startNum < servListEndNum && endNum < servListStartNum && endNum < servListEndNum)
    //                             ) {
    //                             console.log({ COMPARE_sameRangeServiceinCounter: false, Comparing_startNum: startNum, Comparing_endNum: endNum, Against_servListStartNum: servListStartNum, Against_servListEndNum: servListEndNum, servid: e });
    //                         } else {
    //                             console.log({ COMPARE_sameRangeServiceinCounter: true, Comparing_startNum: startNum, Comparing_endNum: endNum, Against_servListStartNum: servListStartNum, Against_servListEndNum: servListEndNum, servid: e });
    //                             //console.log("sameRangeServiceInCounter 3 timestamp_ms", true, performance.now()-t0); //(1+1!=2) && // TESTING_ONLY 1+1==2 || firdous//
    //                             sameRangeServiceInCounter = true;
    //                             break;
    //                         }
    //                     }

    //                     if (sameRangeServiceInCounter == false) {
    //                         console.log("sameRangeServiceInCounter 1 timestamp_ms", false, performance.now()-t0); //(1+1!=2) && // TESTING_ONLY 1+1==2 || firdous//
    //                         sameRangeServiceInCounterArr.push(false);

    //                         servicesList.push({
    //                             name: d.data().Name,
    //                             // main_loc_id: _this.state.selected_main_loc_id !== undefined ? _this.state.selected_main_loc_id || '' : '', 
    //                             // sub_loc_id: _this.state.selected_sub_loc_id !== undefined ? _this.state.selected_sub_loc_id || '' : '',
    //                             id: e,
    //                             // pending: pending,
    //                             // serv_last_called_tok: d.data().Last_Called_Number,
    //                             // service_days: d.data().Service_Days,
    //                             // time_zone: d.data().TimeZone,
    //                             start_num: parseInt(d.data().Start_Number),
    //                             end_num: parseInt(d.data().End_Number),
    //                         });

    //                     } else {
    //                         console.log("sameRangeServiceInCounter 1 timestamp_ms", true, performance.now()-t0); //(1+1!=2) && // TESTING_ONLY 1+1==2 || firdous//
    //                         sameRangeServiceInCounterArr.push(true);
    //                     }
    //                     console.log("result 2 timestamp_ms", performance.now()-t0); //(1+1!=2) && // TESTING_ONLY 1+1==2 || firdous//
    //                 },
    //                 (error) => {
    //                     console.log('error4==>', error); // _this.notifyMessage("tc", 3, "Permission Error!");
    //                 })
    //             }
    //         }

    //         console.log("result 3 timestamp_ms", performance.now()-t0); //(1+1!=2) && // TESTING_ONLY 1+1==2 || firdous//

    //         var indexOf_TRUE_sameRangeServiceinCounterArr = sameRangeServiceInCounterArr.indexOf(true);
    //         console.log("sameRangeServiceInCounterArr", sameRangeServiceInCounterArr);
    //         if (indexOf_TRUE_sameRangeServiceinCounterArr !== -1) {
    //             return false;
    //         }
    //         else {          // -1 means here there is at least one TRUE value; one (or more) service(s) added to counter has the same token range as another...
    //             return true;
    //         }

    //     // }).then(() => {
    //     //     console.log("sameRangeServiceInCounterArr", sameRangeServiceInCounterArr);
    //     //     if (indexOf_TRUE_sameRangeServiceinCounterArr !== -1) {
    //     //         return false;
    //     //     }
    //     //     else {          // -1 means here there is at least one TRUE value; one (or more) service(s) added to counter has the same token range as another...
    //     //         return true;
    //     //     }
    //     // })
    //     // .catch(err=> {
    //     //     console.log("error=>", err);
    //     //     return -1;
    //     // });
    // }

    onChangeMain(e) {
        this.setState({ selected_main_location: e });
        this.loadSubLocationByMain(e.value);
    }
    onChangeSub(e) {
        this.setState({ selected_sub_location: e });
        this.setState({ location_package_id: e.package_id });
        this.setState({ location_image_url: e.image_url });
        this.setState({ location_name: e.label });
        this.setState({ location_address: e.address });
        this.loadServiceListBySub(e.value);
    }
    change = (event, stateName, type, stateNameEqualTo) => {
        switch (type) {
            case "length":
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + "State"]: "has-success" });
                } else {
                    this.setState({ [stateName + "State"]: "has-danger" });
                }

                this.setState({ [stateName]: event.target.value });
                break;
            case 'pin':
                if (this.verifyLength(event.target.value, stateNameEqualTo) && this.verifyNumber(event.target.value)) {
                    this.setState({ [stateName + "State"]: "has-success" });
                } else {
                    this.setState({ [stateName + "State"]: "has-danger" });
                }

                if (this.verifyNumber(event.target.value) || event.target.value.length === 0) {
                    this.setState({ [stateName]: event.target.value });
                }

                break;
            default:
                break;
        }
    };
    getServices() {
        let _this = this;
        return this.state.service_list.map((prop, key) => {
            return (
                <Row key={key} className={"col-lg-12 col-sm-12 col-xs-12 mt-3 text-center"}>
                    <div className="col-3 text-center">
                        <img src={prop.service_icon} alt="..." className="width-20" />
                    </div>
                    <div className="col-3 text-center" onClick={e => e.preventDefault()}>
                        <span className="overflow-ellipsis font-weight-bold fs-15">{prop.service_name}</span>
                        <br />
                        <span className="overflow-ellipsis">{prop.service_details}</span>
                    </div>
                    <div className="col-3  text-center">
                        <Row className='border'>
                            <h6 style={{ margin: 'auto' }} className='col-5 text-center'>From</h6>
                            <h6 style={{ margin: 'auto' }} className='col-7 text-center'>{prop.startNum}</h6>
                        </Row>
                        <Row className='border'>
                            <h6 style={{ margin: 'auto' }} className='col-5 text-center'>To</h6>
                            <h6 style={{ margin: 'auto' }} className='col-7 text-center'>{prop.endNum}</h6>
                        </Row>
                    </div>
                    <Col md="2" xs="2" className="text-center">
                        <Switch
                            // className="left-margin-30"
                            defaultValue={false}
                            value={_this.state.serving_services.includes(prop.service_id) ? true : false}
                            offColor="success"
                            offText="OFF"
                            onColor="success"
                            onText="ON"
                            onChange={event => {
                                {/* console.log(prop); */ }
                                event.state.value ? _this.state.serving_services.push(prop.service_id) : _this.state.serving_services = _this.state.serving_services.filter(item => item != prop.service_id);
                            }}
                        />
                    </Col>
                </Row>
            );
        });
    }
    notifyMessage = (place, color, text) => {
        var type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }

        var options = {};
        options = {
            place: place,
            message: (
                <div className="text-md-center">
                    <div>
                        <b>{text}</b>
                    </div>
                </div>
            ),
            type: type,
            icon: "now-ui-icons ui-1_bell-53",
            autoDismiss: 3
        };
        if (options !== null) { this.notificationAlert.notificationAlert(options) }
    };
    verifyLength = (value, length) => {
        return value.length >= length;
    };
    verifyNumber = value => {
        var numberRex = new RegExp("^[0-9]+$");
        if (numberRex.test(value)) {
            return true;
        }
        return false;
    };
    render() {
        var ok = false
        if (this.state.role == "Site_Admin" || this.state.role == "System_Admin" || this.state.role == "Location_Admin")
            ok = true
        let {
            counter_nameState,
            // mac_addState,
            pinState
        } = this.state;
        return (
            <>
                <LoadingOverlay
                    active={this.state.loading}
                    spinner
                    text='Loading'
                    className='content'
                >
                    <NotificationAlert ref={(ref) => this.notificationAlert = ref} />
                    <Row>
                        <Col md="12">
                            {ok ? <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Counter Add</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Row>

                                        <Col md='12'>
                                            <Card className="border">
                                                <CardBody>
                                                    <Row>
                                                        <Col lg="1" xs="1">
                                                        </Col>
                                                        <Col lg="2" xs="4">
                                                            <Button
                                                                color="success"
                                                                onClick={e => this.handleAdd()}
                                                                block
                                                            >
                                                                Add
                                                            </Button>
                                                        </Col>
                                                        <Col lg="2" xs="4">
                                                            <Button
                                                                color="youtube"
                                                                onClick={e => this.props.history.push("/counters")}
                                                                block
                                                            >
                                                                Close
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                    <Form className="form-horizontal">

                                                        <Row>
                                                            <Label lg="4" xs="3">Counter Name</Label>
                                                            <Col lg="8" xs="9">
                                                                <FormGroup className={`has-label ${counter_nameState}`}>
                                                                    <Row>
                                                                        <Col lg="12" xs="12">
                                                                            <Input
                                                                                value={this.state.counter_name}
                                                                                type="text" name='name'
                                                                                onChange={e => this.change(e, "counter_name", "length", 1)}
                                                                            />
                                                                        </Col>
                                                                        {this.state.counter_nameState === "has-danger" ? (
                                                                            <label className="error">This field is required.</label>
                                                                        ) : null}
                                                                    </Row>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Label lg="4" xs="3">Main Location</Label>
                                                            <Col lg="8" xs="9">
                                                                <FormGroup>
                                                                    <Select
                                                                        className="react-select info select-location"
                                                                        classNamePrefix="react-select"
                                                                        placeholder="Select Main Location"
                                                                        name="selectMainLocation"
                                                                        value={this.state.selected_main_location}
                                                                        onChange={e =>
                                                                            this.onChangeMain(e)
                                                                        }
                                                                        options={this.state.main_location_list}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Label lg="4" xs="3">Sub Location</Label>
                                                            <Col lg="8" xs="9">
                                                                <FormGroup>
                                                                    <Select
                                                                        className="react-select info select-location"
                                                                        classNamePrefix="react-select"
                                                                        placeholder="Select Sub Location"
                                                                        name="selectSubLocation"
                                                                        value={this.state.selected_sub_location}
                                                                        onChange={e =>
                                                                            this.onChangeSub(e)
                                                                        }
                                                                        options={this.state.sub_location_list}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="12" xs="12">
                                                                <div className="border card">
                                                                    <div style={{ height: '50%', overflowX: 'auto' }}>
                                                                        <div className="padding-10 ">
                                                                            <div className="row">
                                                                                <div className="col-lg-12 col-xs-12 text-center">
                                                                                    <img src={this.state.location_image_url} alt="..." className="width-10" />
                                                                                </div>
                                                                                <div className="col-lg-12 col-xs-12 text-center top-margin-12">
                                                                                    <h5>{this.state.location_name}</h5>
                                                                                    <label>{this.state.location_address}</label>
                                                                                </div>
                                                                                <div className="col-lg-12 col-xs-12">
                                                                                    <hr />
                                                                                </div>
                                                                                {this.getServices()}
                                                                            </div>
                                                                        </div>
                                                                        {/* <div className="col-lg-12 col-xs-12 custom-footer-2">
                                                                            <button className="btn btn-block btn-lg text-capitalize text-center" disabled>
                                                                                <span className="text-capitalize">{this.state.current_time}</span>
                                                                            </button>
                                                                        </div> */}
                                                                        {/* <div className="col-lg-12 col-xs-12 custom-footer-1">
                                                                            <button className="btn btn-block btn-lg text-capitalize text-center" disabled>
                                                                                <span className="text-capitalize">{this.state.footer_text===""?'\u00A0':this.state.footer_text}</span>
                                                                            </button>
                                                                        </div> */}
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                    <Card>
                                                        <CardBody>
                                                            <Row>
                                                                <Col md="8" xs="8">
                                                                    <h6>Call Tokens based on Service Date/time (If &quot;OFF&quot; use token number sequence instead)</h6>
                                                                </Col>
                                                                <Col md="4" xs="4" className="text-right">
                                                                    <Switch
                                                                        className="left-margin-30"
                                                                        defaultValue={false}
                                                                        value={this.state.call_based_on_service_date}
                                                                        offColor="success"
                                                                        offText="OFF"
                                                                        onColor="success"
                                                                        onText="ON"
                                                                        onChange={event => this.setState({ call_based_on_service_date: event.state.value })}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md="8" xs="8">
                                                                    <h6>User can call specific service</h6>
                                                                </Col>
                                                                <Col md="4" xs="4" className="text-right">
                                                                    <Switch
                                                                        className="left-margin-30"
                                                                        defaultValue={false}
                                                                        value={this.state.call_specific_service}
                                                                        offColor="success"
                                                                        offText="OFF"
                                                                        onColor="success"
                                                                        onText="ON"
                                                                        onChange={event => this.setState({ call_specific_service: event.state.value })}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            {/* <Row>
                                                                <Col md="8" xs="8">
                                                                    <h6>User can print specific service</h6>
                                                                </Col>
                                                                <Col md="4" xs="4" className="text-right">
                                                                    <Switch
                                                                        className="left-margin-30"
                                                                        defaultValue={false}
                                                                        value={this.state.print_specific_service}
                                                                        offColor="success"
                                                                        offText="OFF"
                                                                        onColor="success"
                                                                        onText="ON"
                                                                        onChange={event => this.setState({print_specific_service: event.state.value})}
                                                                    />
                                                                </Col>
                                                            </Row> */}
                                                            <Row>
                                                                <Col md="8" xs="8">
                                                                    <h6>User can generate token for specific service</h6>
                                                                </Col>
                                                                <Col md="4" xs="4" className="text-right">
                                                                    <Switch
                                                                        className="left-margin-30"
                                                                        defaultValue={false}
                                                                        value={this.state.generate_token_for_specific_service}
                                                                        offColor="success"
                                                                        offText="OFF"
                                                                        onColor="success"
                                                                        onText="ON"
                                                                        onChange={event => this.setState({ generate_token_for_specific_service: event.state.value })}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md="8" xs="8">
                                                                    <h6>User can call a specific token</h6>
                                                                </Col>
                                                                <Col md="4" xs="4" className="text-right">
                                                                    <Switch
                                                                        className="left-margin-30"
                                                                        defaultValue={false}
                                                                        value={this.state.call_specific_token}
                                                                        offColor="success"
                                                                        offText="OFF"
                                                                        onColor="success"
                                                                        onText="ON"
                                                                        onChange={event => this.setState({ call_specific_token: event.state.value })}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md="8" xs="8">
                                                                    <h6>User can modify Service details for Display</h6>
                                                                </Col>
                                                                <Col md="4" xs="4" className="text-right">
                                                                    <Switch
                                                                        className="left-margin-30"
                                                                        defaultValue={false}
                                                                        value={this.state.modify_service_details_for_display}
                                                                        offColor="success"
                                                                        offText="OFF"
                                                                        onColor="success"
                                                                        onText="ON"
                                                                        onChange={event => this.setState({ modify_service_details_for_display: event.state.value })}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md="8" xs="8">
                                                                    <h6>Reset Counter (and related Tokens) At Day Close</h6>
                                                                </Col>
                                                                <Col md="4" xs="4" className="text-right">
                                                                    <Switch
                                                                        className="left-margin-30"
                                                                        defaultValue={false}
                                                                        value={this.state.reset_counter_at_day_close}
                                                                        offColor="success"
                                                                        offText="OFF"
                                                                        onColor="success"
                                                                        onText="ON"
                                                                        onChange={event => this.setState({ reset_counter_at_day_close: event.state.value })}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md="8" xs="8">
                                                                    <h6>Lock to this computer</h6>
                                                                </Col>
                                                                <Col md="4" xs="4" className="text-right">
                                                                    <Switch
                                                                        className="left-margin-30"
                                                                        defaultValue={false}
                                                                        // value={this.state.show_location_icon}
                                                                        offColor="success"
                                                                        offText="OFF"
                                                                        onColor="success"
                                                                        onText="ON"
                                                                        onChange={event => this.setState({ lock_to: event.state.value })}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Label lg="4" xs="3">FingerPrint key of computer</Label>
                                                                <Col lg="8" xs="9">
                                                                    <FormGroup className={`has-label`}>
                                                                        <Row>
                                                                            <Col lg="12" xs="12">
                                                                                <Input
                                                                                    value={this.state.mac_add}
                                                                                    type="text" name='mac address'
                                                                                    disabled={this.state.lock_to ? false : true}
                                                                                    onChange={e => this.change(e, "mac_add", "length", 1)}
                                                                                />
                                                                            </Col>
                                                                            {/* {this.state.mac_addState === "has-danger" ? (
                                                                                <label className="error">This field is required.</label>
                                                                            ) : null} */}
                                                                        </Row>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card> : null}

                        </Col>
                    </Row>
                </LoadingOverlay>
            </>
        );
    }
}

export default CounterAdd;
