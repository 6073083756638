import React from "react";
import LoadingOverlay from 'react-loading-overlay';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Label,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row
} from "reactstrap";

//import Spinner from 'react-spinkit';
//import { authRef } from '../reference';
import Firebase from "firebase";
import config from "../../config";

class RecoverEmail extends React.Component {
  constructor(props) {
    super(props);

    if (!Firebase.apps.length) {
      Firebase.initializeApp(config);
    }

    this.state = {
      error: '',
      restoredEmail: '',
      resetSent: false,
      validCode: null,
      verifiedCode: false,
    };
  }

  componentDidMount() {
    // Confirm the action code is valid.
    const authRef = Firebase.auth();
    authRef
      .checkActionCode(this.props.actionCode)
      .then(info => {
        // Get the restored email address.
        const restoredEmail = info['data']['email'];
        // Revert to the old email.
        authRef
          .applyActionCode(this.props.actionCode)
          .then(() => {
            // Account email reverted to restoredEmail
            this.setState({ restoredEmail, validCode: true, verifiedCode: true });
          });
      }, error => {
        // Invalid code.
        this.setState({ error: error.message, validCode: false, verifiedCode: true });
        console.log({ action: "recoverEmail", function: "componentDidMount", error: error.message, validCode: false, verifiedCode: true });
      });
  }

  sendReset = () => {
    // You might also want to give the user the option to reset their password
    // in case the account was compromised:
    const authRef = Firebase.auth();
    authRef
      .sendPasswordResetEmail(this.state.restoredEmail)
      .then(() => {
        // Password reset confirmation sent. Ask user to check their email.
        this.setState({ resetSent: true });
      });
  }

  render() {

    let appVersion = process.env.REACT_APP_VERSION;
    const {
      error,
      restoredEmail,
      resetSent,
      validCode,
      verifiedCode,
    } = this.state;

    let wrapperFullPageContent, cardBodyContent;

    if (resetSent) {
      cardBodyContent = (
        <div className="row justify-content-center">
          <h3 style={{ color: '#fff' }} className="header text-center Margin-top--20">Check your email</h3>
          <h5 style={{ color: '#fff' }} className="header text-center Margin-top--20">Follow the instructions sent to your email <span>{restoredEmail}</span> to recover your password.</h5>
        </div>
      );
    } else if (verifiedCode && validCode) {
      cardBodyContent = (
        <div className="row justify-content-center">
          <h3 style={{ color: '#fff' }} className="header text-center Margin-top--20">Updated email address</h3>
          <h5 style={{ color: '#fff' }} className="header text-center Margin-top--20">
            <p>Your sign-in email address has been changed back to <span>{restoredEmail}</span></p>
            <p>If you did not change your sign-in email,
              it is possible someone is trying to access your account and you should
              <div style={{ color: '#ef476f' }}>
                <button onClick={this.sendReset}>change your password right away</button>
              </div>
            </p>
          </h5>
        </div>
      );
    } else if (verifiedCode && !validCode) {
      cardBodyContent = (
        <div className="row justify-content-center">
          <h3 style={{ color: '#fff' }} className="header text-center Margin-top--20">Unable to update your email address</h3>
          <h5 style={{ color: '#ef476f' }} className="header text-center Margin-top--20">There was a problem changing your sign-in email back.</h5>
        </div>
      );
    }

    wrapperFullPageContent = (
      <div className="wrapper wrapper-full-page" ref={(ref) => this.fullPages = ref}>
        <LoadingOverlay
          active={!verifiedCode}
          spinner
          text='Loading'
        >
          <div className="full-page section-image">
            <div className="login-page">
              <Container>
                <Row>
                  <Col className="ml-auto mr-auto" lg="7" md="6">
                    <Card style={{ backgroundColor: '#ffffff00', boxShadow: '0px 0px 0px 0px rgb(0 0 0 / 1%)' }} className="card-login">
                      <CardHeader>
                        <CardHeader>
                          <div className="header text-center">
                            {/* <img src={'/antqueue-animated-logo.gif'} alt={"animated-antqueue-logo"} />  */}
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                              <lottie-player src="/antqueue-animated-logo-lottie-data.json" background="transparent" speed="1" style={{ width: "200px", height: "200px" }} loop autoplay></lottie-player>
                            </div>
                          </div>
                          <h3 style={{ color: '#fff' }} className="header text-center Margin-top--20">AntQueue Recover Email</h3>
                        </CardHeader>
                      </CardHeader>
                      <CardBody>
                        {cardBodyContent}
                      </CardBody>
                      <CardFooter>
                        <div style={{ color: '#fff', fontSize: '8px', marginTop: '25px' }} className="header text-center font-weight-normal text-capitalize">{"AntQueue v" + appVersion}</div>
                      </CardFooter>
                    </Card>
                  </Col>
                </Row>
              </Container>
              <div
                className="full-page-background"
                style={{
                  backgroundImage: `url(${require("../../assets/img/bg/queue_4000x2500_3.jpg")})`,
                  backgroundColor: "lightGrey"
                }}
              />
            </div>
          </div>
        </LoadingOverlay>
      </div>
    )

    return wrapperFullPageContent;
  }
}

export default RecoverEmail;