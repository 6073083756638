import React, { useState, useEffect, useRef } from "react";
import Firebase from "firebase";
import config from "../../../config";

if (!Firebase.apps.length) {
  Firebase.initializeApp(config);
}
var db = Firebase.firestore();

const tables = {
  CUSTOMERS: "Customers",
  COUNTERS: "Counters",
  DISPENSERS: "Dispensers",
  DISPLAYS: "Displays",
  MAIN_LOCATIONS: "Main_Locations",
  SUB_LOCATIONS: "Sub_Locations",
  SERVICES: "Services",
  SERVICE_GROUPS: "Service_Groups",
  REQUEST_APPROVAL_GROUPS: "Request_Approval_Groups",
  PACKAGES: "Packages",
  SYSTEM_CONFIG: "System_Config",
  WEB_APP_USERS: "Web_App_Users",
  MANUAL_BILLING_PROFILES: "Manual_Billing_Profiles",
  MANUAL_DOCUMENT_ATTACHMENTS: "Manual_Document_Attachments",
  MANUAL_INVOICES: "Manual_Invoices",
  MANUAL_SUBSCRIPTIONS: "Manual_Subscriptions",
  MANUAL_LOGS: "Manual_Logs",
  MANUAL_EVENTS: "Manual_Events",
  CALENDARS:"Calendars"
};
let response;
export default async function apiHandleServicesAddEdit (data, _) {
  let cmd = data.cmd;

  if (cmd == "serviceAdd_LoadData") {
    return await general_fetchDataForServicesAdd(data, _);
  }
  if (cmd == "serviceEdit_LoadData") {
    return await general_fetchDataForServicesEdit(data, _);
  }
  if (cmd == "service_SaveServiceGroupData") {
    return await general_saveDataForServicesAddEdit_ServiceGroupInfo(data, _);
  }
  if (cmd == "service_SaveRequestApprovalGroupData") {
    return await general_saveDataForServicesAddEdit_RequestApprovalGroupInfo(
      data,
      _
    );
  }

  return {
    status: "error",
    error: {
      message: "No CMD sent",
      method: "No CMD sent",
    },
    current_date: new Date(),
  };
}

 export const callApiHandleCounterRun = (e) => {
    let currentUser = Firebase.auth().currentUser;
    return currentUser.getIdToken().then(function (token) {
      // Firebase.functions().httpsCallable('apiHandleCounterRun')({email: email, subject: 'Welcome to Antqueue Web App', text: "text"}).then(function(result) {
      //     console.log(result);
      // });
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      let fullBaseURL = config.baseURL + "apiHandleCounterRun";
     
      let url = new URL(fullBaseURL),
        params = e.params;
      params.userId = currentUser.uid;

      //TESTING_ONLY firdous urgent
      Object.keys(params).forEach(function (key) {
        url.searchParams.append(key, params[key]);
        console.log(key, params[key]);
      });

      // Object.keys(params).forEach((key) =>
      //   url.searchParams.append(key, params[key])
      //   console.log(key, params[key])
      // );

      return fetch(url, requestOptions)
        .then((response) => response.json())
        .then((res) => {
         
          if (res.status == "success") {
            let notify_message_methods = ["noPendingToks"];
            // only show success messages for these operations
            if (notify_message_methods.includes(res.method)) {
              // notifyMessage("tc", 2, res.message);
            }
            console.log("counter api call res", res);
          } else if (res.status == "failed") {
            console.error("counter api call res", res);
          }
          return res;
        })
        .catch(function (error) {
          console.log("callApiHandleCounterRun error", error);
        });
    });
  };

async function general_fetchDataForServicesAdd (data, _) {
  try {
    const { customer_id, main_location_id, sub_location_id, service_id } = data;
    console.log({
      customer_id: customer_id,
      main_location_id: main_location_id,
      sub_location_id: sub_location_id,
      service_id: service_id,
    });

    const [
      main_location_snap,
      sub_location_snap,
      mobile_app_snap,
      upload_limit_snap,
      service_groups_snap,
      request_approval_groups_snap,
      calendars_snap
    ] = await Promise.all([
      db
        .collection(tables.MAIN_LOCATIONS)
        .doc(main_location_id)
        .get(),
      db
        .collection(tables.SUB_LOCATIONS)
        .doc(sub_location_id)
        .get(),
      db
        .collection(tables.SYSTEM_CONFIG)
        .doc("Mobile_App")
        .get(),
      db
        .collection(tables.SYSTEM_CONFIG)
        .doc("Upload_Limits")
        .get(),
      db
        .collection(tables.SERVICE_GROUPS)
        .where("Customer_ID", "==", customer_id)
        .get(),
      db
        .collection(tables.REQUEST_APPROVAL_GROUPS)
        .where("Customer_ID", "==", customer_id)
        .get(),
      db
        .collection(tables.CALENDARS)
        // .where("Main_Location_ID", "==", main_location_id)
        // .where("Sub_Location_ID", "==", sub_location_id)
        .where("Customer_ID", "==", customer_id)
        .get(),
    ]);

    if (!main_location_snap.exists) {
      return {
        error: {
          message: "Invalid Main Location Id",
          method: "query main location",
        },
      };
    }
    if (!sub_location_snap.exists) {
      return {
        error: {
          message: "Invalid Sub Location Id",
          method: "query sub location",
        },
      };
    }
    if (!mobile_app_snap.exists) {
      return {
        error: {
          message: "Invalid Mobile App Config",
          method: "query sys config",
        },
      };
    }
    if (!upload_limit_snap.exists) {
      return {
        error: {
          message: "Invalid Upload Limit Config",
          method: "query sys config",
        },
      };
    }
    // if (!calendars?.docs?.length>0) {
    //   return {
    //     error: {
    //       message: "Invalid Upload Limit Config",
    //       method: "query sys config",
    //     },
    //   };
    // }

    const main_location = main_location_snap.data();
    Object.assign(main_location, { id: main_location_snap.id });

    const sub_location = sub_location_snap.data();
    Object.assign(sub_location, { id: sub_location_snap.id });

    const mobile_app = mobile_app_snap.data();
    const icon_max_limit = upload_limit_snap.data().Max_Icon_size_in_MB;
    const appointment_snap = mobile_app_snap.data().Appointment_Scheduling;
    const appointment_scheduling = {};
    Object.assign(appointment_scheduling, {
      default_min_num_of_days_appointment_can_be_scheduled_ahead:
        appointment_snap.Min_Number_Of_Days_Appointment_Can_Be_Scheduled_Ahead
          .Default_Value,
      min_min_num_of_days_appointment_can_be_scheduled_ahead:
        appointment_snap.Min_Number_Of_Days_Appointment_Can_Be_Scheduled_Ahead
          .From_Value,
      min_max_num_of_days_appointment_can_be_scheduled_ahead:
        appointment_snap.Min_Number_Of_Days_Appointment_Can_Be_Scheduled_Ahead
          .To_Value,
      default_max_num_of_days_appointment_can_be_scheduled_ahead:
        appointment_snap.Max_Number_Of_Days_Appointment_Can_Be_Scheduled_Ahead
          .Default_Value,
      max_min_num_of_days_appointment_can_be_scheduled_ahead:
        appointment_snap.Max_Number_Of_Days_Appointment_Can_Be_Scheduled_Ahead
          .From_Value,
      max_max_num_of_days_appointment_can_be_scheduled_ahead:
        appointment_snap.Max_Number_Of_Days_Appointment_Can_Be_Scheduled_Ahead
          .To_Value,
    });

    var service_group_name_list = [];
    service_groups_snap.forEach((service_group) => {
      const {
        Customer_ID,
        Show_On_Mobile_App,
        Name,
        Description,
        Icon,
        Main_Location_ID,
        Sub_Location_ID,
        List_Order,
      } = service_group.data();
      const option = {
        service_group_id: service_group.id,
        //id: service_group.id,
        value: service_group.id,
        label: Name,
        service_group_name: Name,
        service_group_description: Description,
        service_group_icon_url: Icon,
        //service_group_members_count: Members_Count,
        service_group_list_order_number: List_Order,
        customer_id: Customer_ID,
        main_location_id: Main_Location_ID,
        sub_location_id: Sub_Location_ID,
      };
      service_group_name_list.push(option);
    });

    var request_approval_group_name_list = [];
    request_approval_groups_snap.forEach((request_approval_group) => {
      const {
        Customer_ID,
        Name,
        Description,
        Main_Location_ID,
        Sub_Location_ID,
      } = request_approval_group.data();
      const option = {
        request_approval_group_id: request_approval_group.id,
        //id: request_approval_group.id,
        value: request_approval_group.id,
        label: Name,
        request_approval_group_name: Name,
        request_approval_group_description: Description,
        customer_id: Customer_ID,
        main_location_id: Main_Location_ID,
        sub_location_id: Sub_Location_ID,
      };
      request_approval_group_name_list.push(option);
    });

    let calendars = calendars_snap?.docs?.map(e=>({...e?.data(),id:e?.id}))||[]

    response = {
      status: "ok",
      data: {
        main_location,
        sub_location,
        mobile_app,
        icon_max_limit,
        appointment_scheduling,
        service_group_name_list,
        request_approval_group_name_list,
        calendars
      },
    };
    console.log("response", response);
    return response;
  } catch (error) {
    response = {
      status: "error",
      error: {
        message: error.message,
        method: "fetch-Location-Service-ServiceGroup-Data",
        detail: error,
      },
      current_date: new Date(),
    };
    // await manual_logErrorResponse(response)
    return response;
  }
}
async function general_fetchDataForServicesEdit (data, _) {
  let response;
  try {
    const { customer_id, service_id } = data;
    console.log({ customer_id: customer_id, service_id: service_id });

    const [
      service_snap,
      mobile_app_snap,
      upload_limit_snap,
      service_groups_snap,
      request_approval_groups_snap,
    ] = await Promise.all([
      db
        .collection(tables.SERVICES)
        .doc(service_id)
        .get(),
      db
        .collection(tables.SYSTEM_CONFIG)
        .doc("Mobile_App")
        .get(),
      db
        .collection(tables.SYSTEM_CONFIG)
        .doc("Upload_Limits")
        .get(),
      db
        .collection(tables.SERVICE_GROUPS)
        .where("Customer_ID", "==", customer_id)
        .get(),
      db
        .collection(tables.REQUEST_APPROVAL_GROUPS)
        .where("Customer_ID", "==", customer_id)
        .get(),
    ]);

    const service = service_snap.data();
    Object.assign(service, { id: service_snap.id });

    //const customer_id = service_snap.data().Customer_ID;
    const main_location_id = service_snap.data().Main_Location_ID;
    const sub_location_id = service_snap.data().Sub_Location_ID;

    const [main_location_snap, sub_location_snap,calendars_snap] = await Promise.all([
      db
        .collection(tables.MAIN_LOCATIONS)
        .doc(main_location_id)
        .get(),
      db
        .collection(tables.SUB_LOCATIONS)
        .doc(sub_location_id)
        .get(),
        db
        .collection(tables.CALENDARS)
        // .where("Main_Location_ID", "==", main_location_id)
        // .where("Sub_Location_ID", "==", sub_location_id)
        .where("Customer_ID", "==", customer_id)
        .get(),
    ]);

    if (!main_location_snap.exists) {
      return {
        error: {
          message: "Invalid Main Location Id",
          method: "query main location",
        },
      };
    }
    if (!sub_location_snap.exists) {
      return {
        error: {
          message: "Invalid Sub Location Id",
          method: "query sub location",
        },
      };
    }
    // if (!calendars?.docs?.length>0) {
    //   return {
    //     error: {
    //       message: "calendars length is zero",
    //       method: "query sys config",
    //     },
    //   };
    // }

    const main_location = main_location_snap.data();
    Object.assign(main_location, { id: main_location_snap.id });

    const sub_location = sub_location_snap.data();
    Object.assign(sub_location, { id: sub_location_snap.id });

    var serviceProfileID = service_snap.data().Service_Group_Profile_Id;
    var service_group_snap = [];
    var service_group = [];

    if (
      typeof serviceProfileID !== "undefined" &&
      serviceProfileID !== null &&
      serviceProfileID !== "" &&
      serviceProfileID !== "-1"
    ) {
      service_group_snap = await db
        .collection(tables.SERVICE_GROUPS)
        .doc(serviceProfileID)
        .get();
      service_group = service_group_snap.data();
    }

    var requestApprovalProfileID = service_snap.data().Request_Approval_Group_Profile_Id;
    var request_approval_group_snap = [];
    var request_approval_group = [];

    if (
      typeof requestApprovalProfileID !== "undefined" &&
      requestApprovalProfileID !== null &&
      requestApprovalProfileID !== "" &&
      requestApprovalProfileID !== "-1"
    ) {
      request_approval_group_snap = await db
        .collection(tables.REQUEST_APPROVAL_GROUPS)
        .doc(requestApprovalProfileID)
        .get();
      request_approval_group = request_approval_group_snap.data();
    }

    const mobile_app = mobile_app_snap.data();
    const icon_max_limit = upload_limit_snap.data().Max_Icon_size_in_MB;
    const appointment_snap = mobile_app_snap.data().Appointment_Scheduling;
    const appointment_scheduling = {};
    Object.assign(appointment_scheduling, {
      default_min_num_of_days_appointment_can_be_scheduled_ahead:
        appointment_snap.Min_Number_Of_Days_Appointment_Can_Be_Scheduled_Ahead
          .Default_Value,
      min_min_num_of_days_appointment_can_be_scheduled_ahead:
        appointment_snap.Min_Number_Of_Days_Appointment_Can_Be_Scheduled_Ahead
          .From_Value,
      min_max_num_of_days_appointment_can_be_scheduled_ahead:
        appointment_snap.Min_Number_Of_Days_Appointment_Can_Be_Scheduled_Ahead
          .To_Value,
      default_max_num_of_days_appointment_can_be_scheduled_ahead:
        appointment_snap.Max_Number_Of_Days_Appointment_Can_Be_Scheduled_Ahead
          .Default_Value,
      max_min_num_of_days_appointment_can_be_scheduled_ahead:
        appointment_snap.Max_Number_Of_Days_Appointment_Can_Be_Scheduled_Ahead
          .From_Value,
      max_max_num_of_days_appointment_can_be_scheduled_ahead:
        appointment_snap.Max_Number_Of_Days_Appointment_Can_Be_Scheduled_Ahead
          .To_Value,
    });

    var service_group_name_list = [];
    service_groups_snap.forEach((service_group) => {
      const {
        Customer_ID,
        Show_On_Mobile_App,
        Name,
        Description,
        Icon,
        Main_Location_ID,
        Sub_Location_ID,
        List_Order,
      } = service_group.data();
      const option = {
        service_group_id: service_group.id,
        //id: service_group.id,
        value: service_group.id,
        label: Name,
        service_group_name: Name,
        service_group_description: Description,
        service_group_icon_url: Icon,
        //service_group_members_count: Members_Count,
        service_group_list_order_number: List_Order,
        customer_id: Customer_ID,
        main_location_id: Main_Location_ID,
        sub_location_id: Sub_Location_ID,
      };
      service_group_name_list.push(option);
    });

    var request_approval_group_name_list = [];
    request_approval_groups_snap.forEach((request_approval_group) => {
      const {
        Customer_ID,
        Name,
        Description,
        Main_Location_ID,
        Sub_Location_ID,
      } = request_approval_group.data();
      const option = {
        request_approval_group_id: request_approval_group.id,
        //id: request_approval_group.id,
        value: request_approval_group.id,
        label: Name,
        request_approval_group_name: Name,
        request_approval_group_description: Description,
        customer_id: Customer_ID,
        main_location_id: Main_Location_ID,
        sub_location_id: Sub_Location_ID,
      };
      request_approval_group_name_list.push(option);
    });
    let calendars = calendars_snap?.docs?.map(e=>({...e?.data(),id:e?.id}))||[]


    response = {
      status: "ok",
      data: {
        main_location,
        sub_location,
        service,
        service_group,
        request_approval_group,
        mobile_app,
        icon_max_limit,
        appointment_scheduling,
        service_group_name_list,
        request_approval_group_name_list,
        calendars

      },
    };
    console.log("response", response);
    return response;
  } catch (error) {
    response = {
      status: "error",
      error: {
        message: error.message,
        method: "fetch-Location-Service-ServiceGroup-Data",
        detail: error,
      },
      current_date: new Date(),
    };
    // await manual_logErrorResponse(response)
    return response;
  }
}

async function general_saveDataForServicesAddEdit_ServiceGroupInfo (data, _) {
  try {
    var docId;
    var extra = {};
    const {
      name,
      description,
      icon_url,
      list_order_number,
      customer_id,
      main_location_id,
      sub_location_id,
      service_group_profile_id,
      service_group_show_on_mobile_app,
    } = data; //,existing_service_group
    const now = new Date();
    const dbRef = db.collection(tables.SERVICE_GROUPS);
    console.log(
      "general_saveDataForServicesAddEdit_ServiceGroupInfo",
      "params",
      data
    );

    var service_group_snap = [];
    if (
      typeof service_group_profile_id !== "undefined" &&
      service_group_profile_id !== null &&
      service_group_profile_id !== "" &&
      service_group_profile_id !== "-1"
    ) {
      service_group_snap = await dbRef.doc(service_group_profile_id).get();

      if (service_group_snap.exists) {
        const doc = service_group_snap.data();
        console.log("a service_group", service_group_snap.id, doc);

        const {
          Name,
          Description,
          Icon,
          List_Order,
          Customer_ID,
          Main_Location_ID,
          Sub_Location_ID,
          Show_On_Mobile_App,
        } = service_group_snap.data();
        // check this email is already used in other antqueue customer

        if (
          Show_On_Mobile_App != service_group_show_on_mobile_app ||
          Name != name ||
          Description != description ||
          Icon != icon_url ||
          List_Order != list_order_number
        ) {
          const promises = [];
          if (icon_url === "no_change") {
            promises.push(
              dbRef.doc(service_group_profile_id).update({
                Show_On_Mobile_App: service_group_show_on_mobile_app,
                Name: name,
                Description: description,
                List_Order: list_order_number,
                Updated_Date: now,
              })
            );
          } else {
            promises.push(
              dbRef.doc(service_group_profile_id).update({
                Show_On_Mobile_App: service_group_show_on_mobile_app,
                Name: name,
                Description: description,
                Icon: icon_url,
                List_Order: list_order_number,
                Updated_Date: now,
              })
            );
          }
          await Promise.all(promises);
        }
        docId = service_group_profile_id; //service_group_snap.id; //doc.id;
        console.log("b service_group", service_group_snap.id, doc);
      } else {
        // create new service group document
        const doc = await dbRef.doc(service_group_profile_id).set({
          Created_Date: now,
          Show_On_Mobile_App: service_group_show_on_mobile_app,
          Name: name,
          Description: description,
          Icon: icon_url,
          List_Order: list_order_number,
          Customer_ID: customer_id,
          Main_Location_ID: main_location_id,
          Sub_Location_ID: sub_location_id,
          Updated_Date: now,
        });
        docId = service_group_profile_id; //doc.id;
      }

      if (docId) {
        response = { status: "ok", data: { service_group_profile_id: docId } };
      }
    } else {
      response = {
        status: "error",
        error: {
          message: "invalid service_group_profile_id supplied: ",
          service_group_profile_id,
          method: "general_saveDataForServicesAddEdit_ServiceGroupInfo-1",
        },
        current_date: new Date(),
      };
    }
  } catch (error) {
    response = {
      status: "error",
      error: {
        message: error.message,
        method: "general_saveDataForServicesAddEdit_ServiceGroupInfo-2",
        detail: error,
      },
      current_date: new Date(),
    };
    console.log("error response", response);
    // await manual_logErrorResponse(response)
  }
  console.log(response);
  return response;
}

async function general_saveDataForServicesAddEdit_RequestApprovalGroupInfo (
  data,
  context
) {
  let response;
  try {
    var docId;
    var extra = {};
    const {
      name,
      description,
      customer_id,
      main_location_id,
      sub_location_id,
      request_approval_group_profile_id,
    } = data; //,existing_request_approval_group

    const now = new Date();
    const dbRef = db.collection(tables.REQUEST_APPROVAL_GROUPS);
    console.log(
      "general_saveDataForServicesAddEdit_RequestApprovalGroupInfo",
      "params",
      data
    );

    var request_approval_group_snap = [];
    if (
      typeof request_approval_group_profile_id !== "undefined" &&
      request_approval_group_profile_id !== null &&
      request_approval_group_profile_id !== "" &&
      request_approval_group_profile_id !== "-1"
    ) {
      request_approval_group_snap = await dbRef
        .doc(request_approval_group_profile_id)
        .get();

      if (request_approval_group_snap.exists) {
        const doc = request_approval_group_snap.data();
        console.log("a request_approval", request_approval_group_snap.id, doc);

        const {
          Name,
          Description,
          Customer_ID,
          Main_Location_ID,
          Sub_Location_ID,
        } = request_approval_group_snap.data();
        // check this email is already used in other antqueue customer

        if (Name != name || Description != description) {
          const promises = [];
          promises.push(
            dbRef.doc(request_approval_group_profile_id).update({
              Name: name,
              Description: description,
              Updated_Date: now,
            })
          );
          await Promise.all(promises);
        }
        docId = request_approval_group_snap.id; //doc.id;
        console.log("b request_approval", request_approval_group_snap.id, doc);
      } else {
        // create new request approval group document
        const doc = await dbRef.add({
          Created_Date: now,
          Name: name,
          Description: description,
          Customer_ID: customer_id,
          Main_Location_ID: main_location_id,
          Sub_Location_ID: sub_location_id,
          Updated_Date: now,
        });
        docId = doc.id;
      }

      if (docId) {
        response = {
          status: "ok",
          data: { request_approval_group_profile_id: docId },
        };
      }
    } else {
      response = {
        status: "error",
        error: {
          message: "invalid request_approval_group_profile_id supplied: ",
          request_approval_group_profile_id,
          method:
            "general_saveDataForServicesAddEdit_RequestApprovalGroupInfo-1",
        },
        current_date: new Date(),
      };
    }
  } catch (error) {
    response = {
      status: "error",
      error: {
        message: error.message,
        method: "general_saveDataForServicesAddEdit_RequestApprovalGroupInfo-2",
        detail: error,
      },
      current_date: new Date(),
    };
    console.log("error response", response);
    // await manual_logErrorResponse(response)
  }
  console.log(response);
  return response;
}
