import React from 'react';
import RecoverEmail from "../views/pages/RecoverEmail";
import ResetPassword from "../views/pages/ResetPassword";
import VerifyEmail from "../views/pages/VerifyEmail";
import DeleteMobileUserAccount from "../views/pages/DeleteMobileUserAccount";

// Custom action URL paths:
// Default: "https://antqueuelive.firebaseapp.com/__/auth/action"
// New: "https://app.antqueue.com/__/auth/action"
// Test: "http://localhost:3000/__/auth/action?mode=resetPassword&oobCode=ABC123&apiKey=AIzaSy"

// mode - The user management action to be completed
// oobCode - A one-time code, used to identify and verify a request
// apiKey - Your Firebase project's API key, provided for convenience

const Action = (props) => {
  console.log("props", props);
  const queryParams = new URLSearchParams(props.location.search);
  //console.log("queryParams", queryParams);

  // Get the action to complete.
  //const mode = props.location.search.mode;
  const mode = queryParams.get("mode");
  console.log("mode", mode);

  // Get the one-time code from the search parameter.
  //const actionCode = props.location.search.oobCode;
  const actionCode = queryParams.get("oobCode");
  console.log("actionCode", actionCode);

  // (Optional) Get the API key from the search parameter.
  //const apiKey = props.location.search.apiKey;
  //const apiKey = queryParams.get("apiKey");
  //console.log("apiKey", apiKey);

  // Handle the user management action.
  switch (mode) {
    case 'recoverEmail':
      // Display reset password handler and UI.
      return <RecoverEmail actionCode={actionCode} />;
    case 'resetPassword':
      // Display email recovery handler and UI.
      return <ResetPassword actionCode={actionCode} />;
    case 'verifyEmail':
      // Display email verification handler and UI.
      return <VerifyEmail actionCode={actionCode} />;
    case 'deleteMobileUserAccount':
      // Display delete mobile user account handler and UI.
      return <DeleteMobileUserAccount actionCode={actionCode} />;
    default:
      // Error: invalid mode.
      return (
        <div className="Action">
          <h1>Error encountered</h1>
          <p>The selected page mode is invalid.</p>
        </div>
      );
  }
}

export default Action;
