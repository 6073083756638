
import * as React from 'react';

import { DateInput } from '@progress/kendo-react-dateinputs';

export class CustomStartDateInput extends React.Component {
    render() {
        const style = { color: this.props.value !== null ? 'green' : 'red' };

        return (
            <label style={{ textAlign: 'center' }}>
                <span style={style}>Start</span> <br />
                <DateInput {...this.props} label={undefined} />
            </label>
        );
    }
}
