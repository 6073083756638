import React from "react";
import LoadingOverlay from "react-loading-overlay";
import Select from "react-select";
import Switch from "react-bootstrap-switch";
import request from "request";
import Firebase from "firebase";
import NotificationAlert from "react-notification-alert";
import CreatableSelect from 'react-select/creatable';
import config from "../../../config";
import IconUpload from "../../../components/CustomUpload/IconUpload";
import CustomMap from "../../../components/CustomMap";
import info from "../../../info";
import { DatePicker } from '@progress/kendo-react-dateinputs';

import {
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Collapse,
    Row,
    Button,
    Form,
    FormGroup,
    Label,
    Input
} from "reactstrap";

import uuidv1 from "uuid/v1";
import publicIp from "public-ip";
import geoTz from "geo-tz";
import moment from "moment-timezone";

import trash from '../../trash-alt-solid.svg'

/*
Notes:
//devcheck ==> marks lines that need to be reverted before saving for production 
*/

class SubLocationAdd extends React.Component {
    constructor(props) {
        super(props);
        if (!Firebase.apps.length) {
            Firebase.initializeApp(config);
        }

        this.state = {
            loading: false,
            package_id: '',
            sub_location_id: '',
            address_info: null,
            customer_id: '',
            icon_max_limit: 0,
            map_zoom: 5,
            package_enable_mobile: true,
            mobile_ad_file_max_limit: 1,

            name: '',
            address: '',
            enable_mobile: false,
            active: true,
            allow_only_one_token_per_person_per_sublocation: false,
            validate_serv_for_tok_num_range_overlap: true,
            
            package_name: '',
            time_zone: null,
            time_zone_list: [],
            current_position: null,
            current_time_zone: null,
            center: null,
            position: null,
            remarks: '',
            nameState: '',
            billing_emailState: '',
            billing_emailStateMessage: 'This field is required.',
            billing_nameState: '',
            billing_addressState: '',
            package_idState: '',
            last_payment_invoice_idState: '',
            last_payment_statusState: '',
            last_payment_statusStateMessage: 'This field is required.',
            intervalState: '',
            intervalStateMessage: 'This field is required.',
            current_period_startState: '',
            current_period_endState: '',

            selected_main_location: null,
            main_location_list: [],
            subs_count:1,
            openedCollapses: [],

            selected_stripe_customer: null,
            selected_manual_billing_profile: null,
            billing_email: '',
            billing_name: '',
            billing_address: '',
            billing_email_list: null,
            billing_profile_id: null,
            default_billing_email: null,
            billing_email_disable_selection: false,
            created_date_time: '',
            current_period_end: '',
            current_period_end_formatted_1: '',
            current_period_end_formatted_2: '',
            current_period_start: '',
            current_period_start_formatted_1: '',
            current_period_start_formatted_2: '',
            interval: { value: 'Yearly', label: 'Yearly'},
            interval_disable_selection: false,
            interval_options_list: null,
            package_id_disable_selection: true,
            last_payment_invoice_id: "in1_99999999999999999999",
            last_payment_status:{ value: 'Pending', label: 'Pending'},
            last_payment_status_options_list: null,
            payment_source: '',
            payment_source_options_list: '',
            payment_source_disable_selection: true,
            service_stop_date_time: '',
            subscription_id: '',
            subscription_status: '',
            sms_balance: 0,
            sms_sender_id: '',
            sms_sender_name: '',
            inputKey1: Math.random().toString(36),
            mobile_app_title: '',
            mobile_app_description: '',
            mobile_app_enable_title_description: false,
            mobile_app_enable_title_descriptionState: '',
            mobile_app_ad_1: null,
            mobile_app_ad_1_enable: false,
            mobile_app_ad_1_file_ext_ok: false,
            mobile_app_ad_1_file_size_ok: false,
            mobile_app_ad_1_name: '',
            mobile_app_ad_1_url: '',
            mobile_app_ad_1_attachmentState: '',
        };
        // this.functions = null;                                              //functions emulator
        // this.fbStore = null;                                                //functions emulator
        this.inputFileRef1 = React.createRef();
    }

    // initializeFirebase = () => {                                            //functions emulator
        // if (!this.functions) {                                              //functions emulator
            // this.functions = Firebase.functions();                          //functions emulator
            // this.functions.useFunctionsEmulator('http://localhost:5001');   //functions emulator
            // this.fbStore = Firebase.firestore();                            //functions emulator
        // }                                                                   //functions emulator
    // }                                                                       //functions emulator

    componentDidMount() {
        // this.initializeFirebase();                                          //functions emulator
        let _this=this;
        _this.auth_info = JSON.parse(localStorage.getItem('auth_info'));
        var role = this.auth_info.role;
        let customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;
        console.log("_this.props.match.params.id", _this.props.match.params.id);
        if (role === 'Site_Admin' && _this.props.match.params.id != 1) {
            customer_id = _this.props.match.params.id;
        } else {
            customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;
        }

        _this.setState({
            sms_sender_id: customer_id !== '000000000006' ? 'MG8d4735c253ffa9ed9eb0c3af956e35d1' : 'MG9218bcc3128a2ccf282ca4af3f5e01e2',
            sms_sender_name: customer_id !== '000000000006' ? 'Ant Queue' : 'DhiraaguCSC',
        })

        Firebase.firestore().collection('Sub_Locations').where('Customer_ID', '==', customer_id).get().then(q=>{
            var i=1
            q.forEach(e=>{
                if(e.data().NID > i)
                    i=e.data().NID 
            })
            _this.setState({subs_count:i+1})
            console.log('subs_count: ', i+1);
        }, (error) => console.log("Sub_Location error ==> ", error))

        _this.setState({role:role});
        let sub_location_id = uuidv1();
        _this.setState({sub_location_id: sub_location_id, loading: true});
        console.log("sub_location_id", sub_location_id);
        if (role === 'Site_Admin') {
            _this.setState({
                payment_source: { value: 'Manual_Payment', label: 'Manual_Payment' },
                //payment_source_disable_selection: false,
            });
            _this.loadPaymentIntervalInfo();
            _this.loadBillingInfo('Manual_Payment');
            _this.loadPaymentStatusInfo();
        } else {
            // _this.loadPaymentSources();
            _this.setState({ 
                payment_source: { value: 'Online_Payment', label: 'Online_Payment' },
            });
            _this.loadBillingInfo('Online_Payment');
        }
        _this.loadAddressInfo();
    }

    // loadPaymentSources = () => {
    //     let _this=this
    //     var role = this.auth_info.role;
    //     Firebase.firestore().collection('System_Config').doc('Payment_Sources').get().then(function (doc) {
    //         var payment_source_options = doc.data().Payment_Source_Options;
    //         var payment_source_options_list = [];
    //         payment_source_options.forEach(function (payment) {
    //             var sources = { value: payment, label: payment };
    //             payment_source_options_list.push(sources);
    //         });
    //         _this.setState({payment_source_options_list: payment_source_options_list});
    //         console.log('payment_source_options_list', payment_source_options_list);
    //         if (role==="Site_Admin") {
    //             _this.setState({payment_source_disable_selection: false});
    //         }
    //     }).catch(function (err) {
    //         _this.setState({loading: false});
    //         _this.notifyMessage("tc", 3, "Network error!");
    //         console.log('loadPaymentSources error', err);
    //     });
    // }
    
    loadPaymentStatusInfo = () => {
        let _this=this
        var role = this.auth_info.role;
        Firebase.firestore().collection('System_Config').doc('Manual_Payments').get().then(function (doc) {
            var payment_status_options = doc.data().Payment_Status_Options;
            var last_payment_status_options_list = [];
            payment_status_options.forEach(function (payment) {
                var option = { value: payment, label: payment };
                last_payment_status_options_list.push(option);
            });
            _this.setState({last_payment_status_options_list: last_payment_status_options_list});
            console.log('last_payment_status_options_list', last_payment_status_options_list);
        }).catch(function (err) {
            _this.setState({loading: false});
            _this.notifyMessage("tc", 3, "Network error!");
            console.log('loadPaymentStatusInfo error', err);
        });
    }

    loadPaymentIntervalInfo = () => {
        let _this=this
        var role = this.auth_info.role;
        Firebase.firestore().collection('System_Config').doc('Payment_Intervals').get().then(function (doc) {
            var interval_types = doc.data().Interval_Types;
            var interval_options_list = [];
            interval_types.forEach(function (payment) {
                var option = { value: payment, label: payment };
                interval_options_list.push(option);
            });
            _this.setState({interval_options_list: interval_options_list});
            console.log('interval_options_list', interval_options_list);
        }).catch(function (err) {
            _this.setState({loading: false});
            _this.notifyMessage("tc", 3, "Network error!");
            console.log('loadPaymentIntervalInfo error', err);
        });
    }

    loadBillingInfo = (payment_source) => {
        var _this = this;
        var { customer_id } = _this.auth_info;
        //var payment_source = _this.state.payment_source.value;
        console.log("loadBillingInfo>>> customer_id", customer_id);
        //console.log("payment_source.value>>> ", _this.state.payment_source.value);
        console.log("payment_source>>> ", payment_source);

        if ( payment_source=='Online_Payment' ) {
            console.log("payment_source>>> Online");
            Firebase.firestore().collection('Stripe_Customers').where('Antqueue_Customer_ID', '==', customer_id).get().then(result=> {
                const billing_email_list = [];
                result.docs.forEach(stripe_customer =>{
                    const { Stripe_Billing_User_Email, Stripe_Billing_User_Name, Stripe_Billing_User_Address } = stripe_customer.data();
                    const option = {
                        id: stripe_customer.id,
                        email: Stripe_Billing_User_Email,
                        label: Stripe_Billing_User_Email,
                        value: stripe_customer.id,
                        billing_name: Stripe_Billing_User_Name,
                        billing_address: Stripe_Billing_User_Address
                    };
                    billing_email_list.push(option);
                });
                _this.setState({billing_email_list}, () => {
                    console.log("_this.state.billing_email_list: ",_this.state.billing_email_list);
                });
            })
            .catch(function (error) {
                _this.setState({loading: false});
                _this.notifyMessage("tc", 3, "Network error!");
                console.log("loadBillingInfo NetworkError9==>", error);
            });
        } else if ( payment_source=='Manual_Payment' ) {
            console.log("payment_source>>> Manual", payment_source);
            // Handle Manual Payments
            Firebase.firestore().collection('Manual_Billing_Profiles').where('Antqueue_Customer_ID', '==', customer_id).get().then(result=> {
                const billing_email_list = [];
                result.docs.forEach(profile =>{
                    const { Manual_Billing_User_Email, Manual_Billing_User_Name, Manual_Billing_User_Address } = profile.data();
                    const option = {
                        id: profile.id,
                        email: Manual_Billing_User_Email,
                        label: Manual_Billing_User_Email,
                        value: profile.id,
                        billing_name: Manual_Billing_User_Name,
                        billing_address: Manual_Billing_User_Address,
                    };
                    billing_email_list.push(option);
                });
                _this.setState({billing_email_list}, () => {
                    console.log("_this.state.billing_email_list: ",_this.state.billing_email_list);
                });
            })
            .catch(function (error) {
                _this.setState({loading: false});
                _this.notifyMessage("tc", 3, "Network error!");
                console.log("loadBillingInfo NetworkError9==>", error);
            });
        }
    };

    loadAddressInfo() {
        var _this = this;

        // ---------- Start Load TimeZone List ---------- //
        var timeZones = moment.tz.names();
        var offsetTmz=[];

        for(var i in timeZones)
        {
            offsetTmz.push("(UTC"+moment.tz(timeZones[i]).format('Z')+")" + timeZones[i]);
        }

        let sortTimezones = offsetTmz.sort().map(item => { return {'value': item, 'label': item}});
        console.log("sortTimezones", sortTimezones);
        _this.setState({time_zone_list: sortTimezones});
        // ---------- End Load TimeZone List ---------- //
        // ---------- Load Icon Max Size ---------- //
        Firebase.firestore().collection('System_Config').doc('Upload_Limits').get().then(function (upload_limit_info) {
            if (upload_limit_info.exists) {
                _this.setState({icon_max_limit: upload_limit_info.data().Max_Icon_size_in_MB});
                _this.setState({mobile_ad_file_max_limit: upload_limit_info.data().Max_Mobile_Ad_File_size_in_MB});
                // ---------- Load Address Info ---------- //
                let customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;
                _this.setState({customer_id: customer_id});
                var main_location_list = [];
                publicIp.v4().then(ip => {
                    // console.log("info.ip_api_key",  info.ip_api_key, "ip", ip);
                    let url = 'https://api.ipgeolocation.io/ipgeo?apiKey=' + info.ip_api_key + '&ip='+ip+'&lang=en';

                    request.get(url, function(err,res,body){
                        if(err){
                            _this.setState({address_info: null});
                            _this.setState({loading: false});
                            _this.notifyMessage("tc", 3, "Network error!");
                            console.log("loadAddressInfo NetworkError1==>", err);
                        }
                        if(res.statusCode !== 200 ) {
                            _this.setState({address_info: null});
                            _this.setState({loading: false});
                            _this.notifyMessage("tc", 3, "Load geolocation error!");
                        }

                        let result = JSON.parse(body);
                        _this.setState({address_info: result});
                        _this.setState({current_position: { lat: parseFloat(result.latitude), lng: parseFloat(result.longitude) }});
                        _this.setState({position: { lat: parseFloat(result.latitude), lng: parseFloat(result.longitude) }});
                        _this.setState({center: { lat: parseFloat(result.latitude), lng: parseFloat(result.longitude) }});
                        let time_zone = result.time_zone.name;
                        let same_one = _this.state.time_zone_list.find(item => item.value.includes(time_zone));
                        _this.setState({time_zone: same_one});
                        _this.setState({current_time_zone: same_one});
                        // Firebase.firestore().collection('Packages').doc(_this.state.package_id).get().then(function (package_info) {
                        //     if (package_info.exists) {
                        //         // _this.setState({package_name: package_info.data().Name});
                        //         _this.setState({package_enable_mobile: package_info.data().Is_Mobile});
                                // -------- Load Main Location List --------- //
                                Firebase.firestore().collection('Main_Locations').where('Customer_ID', '==', customer_id).get().then(function (locations) {
                                    locations.docs.forEach(function (location) {
                                        let one = {
                                            label: location.data().Name,
                                            value: location.id
                                        };
                                        main_location_list.push(one);
                                    });

                                    _this.setState({main_location_list: main_location_list});
                                    _this.setState({selected_main_location: main_location_list[0]});
                                    _this.setState({loading: false});
                                }).catch(function (err) {
                                    _this.setState({loading: false});
                                    _this.notifyMessage("tc", 3, "Network error!");
                                    console.log("loadAddressInfo NetworkError2==>", err);
                                });
                        //     } else {
                        //         _this.setState({loading: false});
                        //         _this.notifyMessage("tc", 3, "Network error!");
                        //         console.log("loadAddressInfo NetworkError3");
                        //     }
                        // }).catch(function (err) {
                        //     _this.setState({loading: false});
                        //     _this.notifyMessage("tc", 3, "Network error!");
                        //     console.log("loadAddressInfo NetworkError4==>", err);
                        // });
                    });
                });
            } else {
                _this.setState({loading: false});
                _this.notifyMessage("tc", 3, "Network error!");
                console.log("loadAddressInfo NetworkError5");
            }
        }).catch(function (err) {
            _this.setState({loading: false});
            _this.notifyMessage("tc", 3, "Network error!");
            console.log("loadAddressInfo NetworkError6==>", err);
        });
    }
    collapsesToggle = collapse => {
        let openedCollapses = this.state.openedCollapses;
        if (openedCollapses.includes(collapse)) {
            this.setState({
                openedCollapses: openedCollapses.filter(item => item !== collapse)
            });
        } else {
            openedCollapses.push(collapse);
            this.setState({
                openedCollapses: openedCollapses
            });
        }
    };

    async handleAdd() {
        
        let _this = this; 
        _this.setState({loading: true});
        let payment_source = _this.state.payment_source.value;
        console.log("handleAdd payment_source.value>>>0 ", _this.state.payment_source.value);
        
        const { nameState, billing_emailState, billing_nameState, billing_addressState, package_idState, last_payment_invoice_idState, last_payment_statusState, current_period_endState, current_period_startState, mobile_app_ad_1_attachmentState, mobile_app_enable_title_descriptionState } = this.state;
        
        if (nameState === "") { _this.setState({ nameState: "has-danger" }); }
        if (billing_emailState === "") { _this.setState({ billing_emailState: "has-danger" }); }
        if (billing_nameState === "") { _this.setState({ billing_nameState: "has-danger" }); }
        if (billing_addressState === "") { _this.setState({ billing_addressState: "has-danger" }); }
        // if (mobile_app_enable_title_descriptionState === "") { _this.setState({ mobile_app_enable_title_descriptionState: "has-danger" }); }
        // if (mobile_app_ad_1_attachmentState === "") { _this.setState({ mobile_app_ad_1_attachmentState: "has-danger" }); }

        console.log("package_idState1", package_idState, "last_payment_invoice_idState1", last_payment_invoice_idState, "last_payment_statusState1", last_payment_statusState, "current_period_startState1", current_period_startState, "current_period_endState1", current_period_endState);
        console.log("_this.state.package_id", _this.state.package_id);
        console.log("_this.state.current_period_end", _this.state.current_period_end, "_this.state.current_period_end_formatted_1", _this.state.current_period_end_formatted_1, "_this.state.current_period_end_formatted_2", _this.state.current_period_end_formatted_2);
        console.log("_this.state.current_period_start_formatted_1", _this.state.current_period_start_formatted_1, "_this.state.current_period_start_formatted_2", _this.state.current_period_start_formatted_2, "_this.state.current_period_start", _this.state.current_period_start);
        if (payment_source == "Manual_Payment" && _this.isValidPackageID(_this.state.package_id) === true) { _this.setState({ package_idState: "has-success"}); }
            else if (payment_source == "Manual_Payment" && _this.isValidPackageID(_this.state.package_id)=== false) { _this.setState({ package_idState: "has-danger"}); }
                else if (payment_source == "Online_Payment") { _this.setState({ package_idState: "has-success"}); }
        
        if (payment_source == "Manual_Payment" && _this.isValidInvoiceID(_this.state.last_payment_invoice_id) === true) { _this.setState({ last_payment_invoice_idState: "has-success"}); }
            else if (payment_source == "Manual_Payment" && last_payment_invoice_idState === "") { _this.setState({ last_payment_invoice_idState: "has-danger"}); }
                else if (payment_source == "Online_Payment") { _this.setState({ last_payment_invoice_idState: "has-success"}); }

        if (payment_source == "Manual_Payment" && _this.state.last_payment_status.value !== "") { _this.setState({ last_payment_statusState: "has-success"}); }
            else if (payment_source == "Manual_Payment" && _this.state.last_payment_status.value === "") { _this.setState({ last_payment_statusState: "has-danger"}); }
                else if (payment_source == "Online_Payment") { _this.setState({ last_payment_statusState: "has-success"}); }

        if (payment_source == "Manual_Payment" && _this.isValidFormattedDate(_this.state.current_period_end_formatted_2, 'current_period_endState') === true) { _this.setState({ current_period_endState: "has-success"}); }
            else if (payment_source == "Manual_Payment" && _this.isValidFormattedDate(_this.state.current_period_end_formatted_2, 'current_period_endState') === false) { _this.setState({ current_period_endState: "has-danger"}); }
                else if (payment_source == "Online_Payment") { _this.setState({ current_period_endState: "has-success"}); }

        if (payment_source == "Manual_Payment" && _this.isValidFormattedDate(_this.state.current_period_start_formatted_2, 'current_period_startState') === true) { _this.setState({ current_period_startState: "has-success"}); }
            else if (payment_source == "Manual_Payment" && _this.isValidFormattedDate(_this.state.current_period_start_formatted_2, 'current_period_startState') === false) { _this.setState({ current_period_startState: "has-danger"}); }
                else if (payment_source == "Online_Payment") { _this.setState({ current_period_startState: "has-success"}); }
    
        //if (mobile_app_ad_1_attachmentState === "") { _this.setState({ mobile_app_ad_1_attachmentState: "has-danger" }); }

        console.log("nameState2", nameState, "billing_emailState2", billing_emailState, "billing_nameState2", billing_nameState, "billing_addressState2", billing_addressState,
                    "package_idState2", package_idState, "last_payment_invoice_idState2", last_payment_invoice_idState, "last_payment_statusState2", last_payment_statusState,
                    "current_period_endState2", current_period_endState, "current_period_startState2", current_period_startState,
                    "mobile_app_enable_title_descriptionState2", mobile_app_enable_title_descriptionState, "mobile_app_ad_1_attachmentState", mobile_app_ad_1_attachmentState);

        if (nameState === "has-success" && billing_emailState === "has-success" && billing_nameState === "has-success" && billing_addressState === "has-success"
            && package_idState === "has-success" && last_payment_invoice_idState === "has-success" && last_payment_statusState === "has-success"
            && current_period_endState === "has-success"  && current_period_startState === "has-success"
            && _this.state.mobile_app_enable_title_descriptionState !== "has-danger"
            && _this.state.mobile_app_ad_1_attachmentState !== "has-danger" ) {
           
            const { customer_id } = _this.auth_info;
            const {
                billing_email,
                billing_name,
                billing_address,
            } = _this.state;

            if (payment_source === 'Online_Payment') {
                console.log("handleAdd payment_source.value>>>1 ", _this.state.payment_source.value);

                var stripe_customer_id = null;
                try {                
                    const response = await Firebase.functions().httpsCallable('stripe_saveUpdateStripeCustomerInfo')({ 
                        email: billing_email, 
                        name: billing_name, 
                        address: billing_address, 
                        antqueue_customer_id: customer_id 
                    });
                    const res = response.data;
                    if (res.status === 'error') {
                        const err = res.error;
                        _this.setState({loading: false});
                        _this.notifyMessage("tc", 3, err.message);
                        console.log("handleAdd NetworkError0==>", err);
                        return;
                    }
                    
                    stripe_customer_id = res.data.stripe_customer_doc_id;
                } catch(err) {
                    _this.setState({loading: false});
                    _this.notifyMessage("tc", 3, "Network error!");
                    console.log("handleAdd NetworkError0==>", err);
                }
                
                var now = new Date();
                let newSublocationID = this.state.sub_location_id;
                let file = this.refs.icon.state.file;
                if (file !== null) {
                    // --------- Check Max Icon Size Limit --------- //
                    let max_bytes = _this.state.icon_max_limit * 1024 * 1024;
                    if (file.size > max_bytes) {
                        _this.setState({loading: false});
                        _this.notifyMessage("tc", 3, "Icon file size exceeds maximum size of " + _this.state.icon_max_limit +  " MB. Please change icon!");
                    } else {
                        let metadata = {
                            customMetadata: {
                                customer_id: customer_id,
                                main_location_id: _this.state.selected_main_location.value,
                                sub_location_id: newSublocationID
                            }
                        }
                        // console.log(metadata)
                        var storageRef = Firebase.storage().ref();
                        var image_name = "sub_location_icon_" + newSublocationID;
                        var subLocationRef = storageRef.child(image_name);
                        subLocationRef.put(file, metadata).then(function (snapshot) {
                            subLocationRef.getDownloadURL().then(function (res) {
                                Firebase.firestore().collection('Main_Locations').doc(_this.state.selected_main_location.value).get().then(function (main_location) {
                                    if (main_location.exists) {
                                        let sub_location_count = main_location.data().Sub_Locations_Count;
                                        Firebase.firestore().collection('Customers').doc(_this.state.customer_id).get().then(function (own_customer_info) {
                                            if (own_customer_info.exists) {
                                                Firebase.firestore().collection('Main_Locations').doc(_this.state.selected_main_location.value).update({Sub_Locations_Count: sub_location_count+1}).then(function (response) {
                                                    let new_sub_location_data = {
                                                        Address: _this.state.address,
                                                        Created_Date: now,
                                                        Customer_ID: _this.state.customer_id,
                                                        Geolocation: _this.state.position,
                                                        Icon: res,
                                                        Last_Paid_Date: '',
                                                        Main_Location_ID: _this.state.selected_main_location.value,
                                                        Name: _this.state.name,
                                                        Next_Payment_Date: '',
                                                        Package_ID: (_this.state.package_id).trim(),
                                                        Payment_Due_Date: '',
                                                        Pending_Queue_Count: '',
                                                        Remarks: _this.state.remarks,
                                                        Show_On_Mobile_App: _this.state.enable_mobile,
                                                        Status: _this.state.active,
                                                        Allow_Only_One_Token_Per_Person_Per_Sublocation: _this.state.allow_only_one_token_per_person_per_sublocation,
                                                        Validate_Service_For_Token_Number_Range_Overlap: _this.state.validate_serv_for_tok_num_range_overlap,
                                                        Stop_Date: '',
                                                        TimeZone: _this.state.time_zone.value,
                                                        Notification_Date: '',
                                                        Final_Notification_Date: '',
                                                        NID:_this.state.subs_count,
                                                        Updated_Date: now,
                                                        Service_Count: 0,
                                                        Billing_Profile_Id: stripe_customer_id,
                                                        Subscription_Details: {
                                                            Subscription_Status: 'no_subscription_yet',
                                                            Payment_Source: _this.state.payment_source===null?"":_this.state.payment_source.value,
                                                        },
                                                        //Temp fix for SMS SID (until Add-ons module has been developed) --17th Jan 2022
                                                        SMS_Balance: _this.state.sms_balance,
                                                        SMS_Sender_ID: _this.state.sms_sender_id,
                                                        SMS_Sender_Name: _this.state.sms_sender_name,
                                                        Mobile_App_Title: _this.state.mobile_app_title,
                                                        Mobile_App_Description: _this.state.mobile_app_description,
                                                        Mobile_App_Enable_Title_Description: _this.state.mobile_app_enable_title_description,
                                                        Mobile_App_Ad_1: _this.state.mobile_app_ad_1_url,
                                                        Mobile_App_Enable_Ad_1: _this.state.mobile_app_ad_1_enable,
                                                    };

                                                    Firebase.firestore().collection('Sub_Locations').doc(newSublocationID).set(new_sub_location_data)
                                                        .then(function() {
                                                            _this.setState({loading: false});
                                                            _this.notifyMessage("tc", 2, "New Sub Location added successfully.");
                                                            window.setTimeout(function() { _this.props.history.push("/sub_location/manage_package/"+newSublocationID) }, 2000);
                                                        }).catch(function (error) {
                                                        _this.setState({loading: false});
                                                        _this.notifyMessage("tc", 3, "Network error!");
                                                        console.log("handleAdd NetworkError7==>", error);
                                                    });

                                                    let batch = Firebase.firestore().batch();
                                                    var AccLocsRoles = [];
                                                    AccLocsRoles = ["System_Admin", "Location_Admin"];  //devcheck
                                                    Firebase.firestore().collection('Web_App_Users').where('Customer_ID', '==', customer_id).where('Role', 'in', AccLocsRoles ).get()
                                                        .then(query=> {
                                                            query.docs.forEach(t =>{
                                                                let arrAccessibleLocations = t.data().Accessible_Locations;
                                                                arrAccessibleLocations.push(newSublocationID);
                                            
                                                                let updateData = {
                                                                    Accessible_Locations: arrAccessibleLocations
                                                                };
                                                                // console.log("arrAccessibleLocations ",arrAccessibleLocations)
                                                                let webAppUsers = Firebase.firestore().collection('Web_App_Users').doc(t.id);
                                                                batch.update(webAppUsers, updateData);
                                                            })
                                            
                                                            batch.commit().then(() => {
                                                                console.log('Updated all Accessible_Locations in Admin Roles under same Customer_ID');
                                                            })
                                            
                                                        })
                                                        .catch(function (error) {
                                                            _this.setState({loading: false});
                                                            _this.notifyMessage("tc", 3, "Network error!");
                                                            console.log("handleAdd NetworkError17b==>", error);
                                                        });

                                                }).catch(function (err) {
                                                    _this.setState({loading: false});
                                                    _this.notifyMessage("tc", 3, "Network error!");
                                                    console.log("handleAdd NetworkError8==>", err);
                                                });
                                            } else {
                                                _this.setState({loading: false});
                                                _this.notifyMessage("tc", 3, "Network error!");
                                                console.log("handleAdd NetworkError11");
                                            }
                                        }).catch(function (err) {
                                            _this.setState({loading: false});
                                            _this.notifyMessage("tc", 3, "Network error!");
                                            console.log("handleAdd NetworkError12==>", err);
                                        });
                                    } else {
                                        _this.setState({loading: false});
                                        _this.notifyMessage("tc", 3, "Network error!");
                                        console.log("handleAdd NetworkError13");
                                    }
                                }).catch(function (err) {
                                    _this.setState({loading: false});
                                    _this.notifyMessage("tc", 3, "Network error!");
                                    console.log("handleAdd NetworkError14==>", err);
                                });
                            }).catch(function (err) {
                                _this.setState({loading: false});
                                _this.notifyMessage("tc", 3, "Network error!");
                                console.log("handleAdd NetworkError15==>", err);
                            });
                        }).catch(function (err) {
                            _this.setState({loading: false});
                            _this.notifyMessage("tc", 3, "Network error!");
                            console.log("handleAdd NetworkError16==>", err);
                        });
                    }
                } else {
                    Firebase.firestore().collection('Main_Locations').doc(_this.state.selected_main_location.value).get().then(function (main_location) {
                        if (main_location.exists) {
                            let sub_location_count = main_location.data().Sub_Locations_Count;
                            Firebase.firestore().collection('Customers').doc(_this.state.customer_id).get().then(function (own_customer_info) {
                                if (own_customer_info.exists) {
                                    Firebase.firestore().collection('Main_Locations').doc(_this.state.selected_main_location.value).update({Sub_Locations_Count: sub_location_count+1}).then(function (response) {
                                        let new_sub_location_data = {
                                            Address: _this.state.address,
                                            Created_Date: now,
                                            Customer_ID: _this.state.customer_id,
                                            Geolocation: _this.state.position,
                                            Icon: '',
                                            Last_Paid_Date: '',
                                            Main_Location_ID: _this.state.selected_main_location.value,
                                            Name: _this.state.name,
                                            Next_Payment_Date: '',
                                            Package_ID: (_this.state.package_id).trim(),
                                            Payment_Due_Date: '',
                                            Pending_Queue_Count: '',
                                            Remarks: _this.state.remarks,
                                            Show_On_Mobile_App: _this.state.enable_mobile,
                                            Status: _this.state.active,
                                            Allow_Only_One_Token_Per_Person_Per_Sublocation: _this.state.allow_only_one_token_per_person_per_sublocation,
                                            Validate_Service_For_Token_Number_Range_Overlap: _this.state.validate_serv_for_tok_num_range_overlap,
                                            Stop_Date: '',
                                            TimeZone: _this.state.time_zone.value,
                                            Notification_Date: '',
                                            Final_Notification_Date: '',
                                            NID:_this.state.subs_count,
                                            Updated_Date: now,
                                            Service_Count: 0,
                                            Billing_Profile_Id: stripe_customer_id,
                                            Subscription_Details: {
                                                Subscription_Status: 'no_subscription_yet',
                                                Payment_Source: _this.state.payment_source===null?"":_this.state.payment_source.value,
                                            },
                                            //Temp fix for SMS SID (until Add-ons module has been developed) --17th Jan 2022
                                            SMS_Balance: _this.state.sms_balance,
                                            SMS_Sender_ID: _this.state.sms_sender_id,
                                            SMS_Sender_Name: _this.state.sms_sender_name,
                                            Mobile_App_Title: _this.state.mobile_app_title,
                                            Mobile_App_Description: _this.state.mobile_app_description,
                                            Mobile_App_Enable_Title_Description: _this.state.mobile_app_enable_title_description,
                                            Mobile_App_Ad_1: _this.state.mobile_app_ad_1_url,
                                            Mobile_App_Enable_Ad_1: _this.state.mobile_app_ad_1_enable,
                                        };

                                        Firebase.firestore().collection('Sub_Locations').doc(newSublocationID).set(new_sub_location_data)
                                            .then(function() {
                                                _this.setState({loading: false});
                                                _this.notifyMessage("tc", 2, "New Sub Location added successfully.");
                                                window.setTimeout(function() { _this.props.history.push("/sub_location/manage_package/"+newSublocationID) }, 2000);
                                            }).catch(function (error) {
                                                _this.setState({loading: false});
                                                _this.notifyMessage("tc", 3, "Network error!");
                                                console.log("handleAdd NetworkError17==>", error);
                                            });

                                            let batch = Firebase.firestore().batch();
                                            var AccLocsRoles = [];
                                            AccLocsRoles = ["System_Admin", "Location_Admin"];  //devcheck
                                            Firebase.firestore().collection('Web_App_Users').where('Customer_ID', '==', customer_id).where('Role', 'in', AccLocsRoles ).get()
                                                .then(query=> {
                                                    query.docs.forEach(t =>{
                                                        let arrAccessibleLocations = t.data().Accessible_Locations;
                                                        arrAccessibleLocations.push(newSublocationID);
                                    
                                                        let updateData = {
                                                            Accessible_Locations: arrAccessibleLocations
                                                        };
                                                        // console.log("arrAccessibleLocations ",arrAccessibleLocations)
                                                        let webAppUsers = Firebase.firestore().collection('Web_App_Users').doc(t.id);
                                                        batch.update(webAppUsers, updateData);
                                                    })
                                    
                                                    batch.commit().then(() => {
                                                        console.log('Updated all Accessible_Locations in Admin Roles under same Customer_ID');
                                                    })
                                    
                                                })
                                                .catch(function (error) {
                                                    _this.setState({loading: false});
                                                    _this.notifyMessage("tc", 3, "Network error!");
                                                    console.log("handleAdd NetworkError17b==>", error);
                                                });

                                    }).catch(function (err) {
                                        _this.setState({loading: false});
                                        _this.notifyMessage("tc", 3, "Network error!");
                                        console.log("handleAdd NetworkError18==>", err);
                                    });
                                } else {
                                    _this.setState({loading: false});
                                    _this.notifyMessage("tc", 3, "Network error!");
                                    console.log("handleAdd NetworkError21");
                                }
                            }).catch(function (err) {
                                _this.setState({loading: false});
                                _this.notifyMessage("tc", 3, "Network error!");
                                console.log("handleAdd NetworkError22==>", err);
                            });
                        } else {
                            _this.setState({loading: false});
                            _this.notifyMessage("tc", 3, "Network error!");
                            console.log("handleAdd NetworkError23");
                        }
                    }).catch(function (err) {
                        _this.setState({loading: false});
                        _this.notifyMessage("tc", 3, "Network error!");
                        console.log("handleAdd NetworkError24==>", err);
                    });
                }
            } else if (payment_source === 'Manual_Payment') {
                console.log("handleAdd payment_source.value>>>2 ", _this.state.payment_source.value);

                var subscriptionStatus = 'active';
                if (_this.state.last_payment_status.value === 'Paid') { subscriptionStatus = 'active' }
                    else if (_this.state.last_payment_status.value === 'Pending') { subscriptionStatus = 'incomplete' }
                        else if (_this.state.last_payment_status.value === 'Cancelled') { subscriptionStatus = 'cancelled' }
                        
                var manual_billing_profile_id = null;
                try {                
                    const response = await Firebase.functions().httpsCallable('manual_saveUpdateManualBillingProfileInfo')({ 
                        email: billing_email, 
                        name: billing_name, 
                        address: billing_address, 
                        antqueue_customer_id: customer_id 
                    });
                    const res = response.data;
                    if (res.status === 'error') {
                        const err = res.error;
                        _this.setState({loading: false});
                        _this.notifyMessage("tc", 3, err.message);
                        console.log("handleAdd NetworkError0==>", err);
                        return;
                    }
                    
                    manual_billing_profile_id = res.data.manual_billing_profile_doc_id;
                } catch(err) {
                    _this.setState({loading: false});
                    _this.notifyMessage("tc", 3, "Network error!");
                    console.log("handleAdd NetworkError0==>", err);
                }
                
                var now = new Date();
                let newSublocationID = this.state.sub_location_id;
                let file = this.refs.icon.state.file;
                if (file !== null) {
                    // --------- Check Max Icon Size Limit --------- //
                    let max_bytes = _this.state.icon_max_limit * 1024 * 1024;
                    if (file.size > max_bytes) {
                        _this.setState({loading: false});
                        _this.notifyMessage("tc", 3, "Icon file size exceeds maximum size of " + _this.state.icon_max_limit +  " MB. Please change icon!");
                    } else {
                        let metadata = {
                            customMetadata: {
                                customer_id: customer_id,
                                main_location_id: _this.state.selected_main_location.value,
                                sub_location_id: newSublocationID
                            }
                        }
                        // console.log(metadata)
                        var storageRef = Firebase.storage().ref();
                        var image_name = "sub_location_icon_" + newSublocationID;
                        var subLocationRef = storageRef.child(image_name);
                        subLocationRef.put(file, metadata).then(function (snapshot) {
                            subLocationRef.getDownloadURL().then(function (res) {
                                Firebase.firestore().collection('Main_Locations').doc(_this.state.selected_main_location.value).get().then(function (main_location) {
                                    if (main_location.exists) {
                                        let sub_location_count = main_location.data().Sub_Locations_Count;
                                        Firebase.firestore().collection('Customers').doc(_this.state.customer_id).get().then(function (own_customer_info) {
                                            if (own_customer_info.exists) {
                                                Firebase.firestore().collection('Main_Locations').doc(_this.state.selected_main_location.value).update({Sub_Locations_Count: sub_location_count+1}).then(function (response) {
                                                    let new_sub_location_data = {
                                                        Address: _this.state.address,
                                                        Created_Date: now,
                                                        Customer_ID: _this.state.customer_id,
                                                        Geolocation: _this.state.position,
                                                        Icon: res,
                                                        Last_Paid_Date: '',
                                                        Main_Location_ID: _this.state.selected_main_location.value,
                                                        Name: _this.state.name,
                                                        Next_Payment_Date: '',
                                                        Package_ID: (_this.state.package_id).trim(),
                                                        Payment_Due_Date: '',
                                                        Pending_Queue_Count: '',
                                                        Remarks: _this.state.remarks,
                                                        Show_On_Mobile_App: _this.state.enable_mobile,
                                                        Status: _this.state.active,
                                                        Allow_Only_One_Token_Per_Person_Per_Sublocation: _this.state.allow_only_one_token_per_person_per_sublocation,
                                                        Validate_Service_For_Token_Number_Range_Overlap: _this.state.validate_serv_for_tok_num_range_overlap,
                                                        Stop_Date: '',
                                                        TimeZone: _this.state.time_zone.value,
                                                        Notification_Date: '',
                                                        Final_Notification_Date: '',
                                                        NID:_this.state.subs_count,
                                                        Updated_Date: now,
                                                        Service_Count: 0,
                                                        Billing_Profile_Id: manual_billing_profile_id,
                                                        Subscription_Details: {
                                                            Created_Date: Math.floor(now.getTime() / 1000),
                                                            Current_Period_End: parseInt(_this.state.current_period_end_formatted_1),
                                                            Current_Period_Start: parseInt(_this.state.current_period_start_formatted_1),
                                                            Interval: _this.state.interval.value,
                                                            Payment_Source: _this.state.payment_source===null?"":_this.state.payment_source.value,
                                                            Service_Stop_Date: parseInt(((_this.state.current_period_end_formatted_1) + (7 * 86400))), //86400 -> num of seconds in a day // Add 7 days to this
                                                            Subscription_ID: '-',
                                                            Subscription_Status: subscriptionStatus, //'no_subscription_yet',
                                                            Last_Payment_Invoice_Number: _this.state.last_payment_invoice_id,
                                                            Last_Payment_Status: _this.state.last_payment_status.value,
                                                        },
                                                        //Temp fix for SMS SID (until Add-ons module has been developed) --17th Jan 2022
                                                        SMS_Balance: _this.state.sms_balance,
                                                        SMS_Sender_ID: _this.state.sms_sender_id,
                                                        SMS_Sender_Name: _this.state.sms_sender_name,
                                                        Mobile_App_Title: _this.state.mobile_app_title,
                                                        Mobile_App_Description: _this.state.mobile_app_description,
                                                        Mobile_App_Enable_Title_Description: _this.state.mobile_app_enable_title_description,
                                                        Mobile_App_Ad_1: _this.state.mobile_app_ad_1_url,
                                                        Mobile_App_Enable_Ad_1: _this.state.mobile_app_ad_1_enable,
                                                    };

                                                    Firebase.firestore().collection('Sub_Locations').doc(newSublocationID).set(new_sub_location_data)
                                                        .then(function() {
                                                            _this.setState({loading: false});
                                                            _this.notifyMessage("tc", 2, "New Sub Location added successfully.");
                                                            window.setTimeout(function() { _this.props.history.push("/locations") }, 2000);  //devcheck
                                                        }).catch(function (error) {
                                                        _this.setState({loading: false});
                                                        _this.notifyMessage("tc", 3, "Network error!");
                                                        console.log("handleAdd NetworkError7==>", error);
                                                    });

                                                    let batch = Firebase.firestore().batch();
                                                    var AccLocsRoles = [];
                                                    AccLocsRoles = ["System_Admin", "Location_Admin"];  //devcheck
                                                    Firebase.firestore().collection('Web_App_Users').where('Customer_ID', '==', customer_id).where('Role', 'in', AccLocsRoles ).get()
                                                        .then(query=> {
                                                            query.docs.forEach(t =>{
                                                                let arrAccessibleLocations = t.data().Accessible_Locations;
                                                                arrAccessibleLocations.push(newSublocationID);
                                            
                                                                let updateData = {
                                                                    Accessible_Locations: arrAccessibleLocations
                                                                };
                                                                // console.log("arrAccessibleLocations ",arrAccessibleLocations)
                                                                let webAppUsers = Firebase.firestore().collection('Web_App_Users').doc(t.id);
                                                                batch.update(webAppUsers, updateData);
                                                            })
                                            
                                                            batch.commit().then(() => {
                                                                console.log('Updated all Accessible_Locations in Admin Roles under same Customer_ID');
                                                            })
                                            
                                                        })
                                                        .catch(function (error) {
                                                            _this.setState({loading: false});
                                                            _this.notifyMessage("tc", 3, "Network error!");
                                                            console.log("handleAdd NetworkError17b==>", error);
                                                        });

                                                }).catch(function (err) {
                                                    _this.setState({loading: false});
                                                    _this.notifyMessage("tc", 3, "Network error!");
                                                    console.log("handleAdd NetworkError8==>", err);
                                                });
                                            } else {
                                                _this.setState({loading: false});
                                                _this.notifyMessage("tc", 3, "Network error!");
                                                console.log("handleAdd NetworkError11");
                                            }
                                        }).catch(function (err) {
                                            _this.setState({loading: false});
                                            _this.notifyMessage("tc", 3, "Network error!");
                                            console.log("handleAdd NetworkError12==>", err);
                                        });
                                    } else {
                                        _this.setState({loading: false});
                                        _this.notifyMessage("tc", 3, "Network error!");
                                        console.log("handleAdd NetworkError13");
                                    }
                                }).catch(function (err) {
                                    _this.setState({loading: false});
                                    _this.notifyMessage("tc", 3, "Network error!");
                                    console.log("handleAdd NetworkError14==>", err);
                                });
                            }).catch(function (err) {
                                _this.setState({loading: false});
                                _this.notifyMessage("tc", 3, "Network error!");
                                console.log("handleAdd NetworkError15==>", err);
                            });
                        }).catch(function (err) {
                            _this.setState({loading: false});
                            _this.notifyMessage("tc", 3, "Network error!");
                            console.log("handleAdd NetworkError16==>", err);
                        });
                    }
                } else {
                    Firebase.firestore().collection('Main_Locations').doc(_this.state.selected_main_location.value).get().then(function (main_location) {
                        if (main_location.exists) {
                            let sub_location_count = main_location.data().Sub_Locations_Count;
                            Firebase.firestore().collection('Customers').doc(_this.state.customer_id).get().then(function (own_customer_info) {
                                if (own_customer_info.exists) {
                                    Firebase.firestore().collection('Main_Locations').doc(_this.state.selected_main_location.value).update({Sub_Locations_Count: sub_location_count+1}).then(function (response) {
                                        let new_sub_location_data = {
                                            Address: _this.state.address,
                                            Created_Date: now,
                                            Customer_ID: _this.state.customer_id,
                                            Geolocation: _this.state.position,
                                            Icon: '',
                                            Last_Paid_Date: '',
                                            Main_Location_ID: _this.state.selected_main_location.value,
                                            Name: _this.state.name,
                                            Next_Payment_Date: '',
                                            Package_ID: (_this.state.package_id).trim(),
                                            Payment_Due_Date: '',
                                            Pending_Queue_Count: '',
                                            Remarks: _this.state.remarks,
                                            Show_On_Mobile_App: _this.state.enable_mobile,
                                            Status: _this.state.active,
                                            Allow_Only_One_Token_Per_Person_Per_Sublocation: _this.state.allow_only_one_token_per_person_per_sublocation,
                                            Validate_Service_For_Token_Number_Range_Overlap: _this.state.validate_serv_for_tok_num_range_overlap,
                                            Stop_Date: '',
                                            TimeZone: _this.state.time_zone.value,
                                            Notification_Date: '',
                                            Final_Notification_Date: '',
                                            NID:_this.state.subs_count,
                                            Updated_Date: now,
                                            Service_Count: 0,
                                            Billing_Profile_Id: manual_billing_profile_id,
                                            Subscription_Details: {
                                                Created_Date: Math.floor(now.getTime() / 1000),
                                                Current_Period_End: parseInt(_this.state.current_period_end_formatted_1),
                                                Current_Period_Start: parseInt(_this.state.current_period_start_formatted_1),
                                                Interval: _this.state.interval.value,
                                                Payment_Source: _this.state.payment_source===null?"":_this.state.payment_source.value,
                                                Service_Stop_Date: parseInt(((_this.state.current_period_end_formatted_1) + (7 * 86400))), //86400 -> num of seconds in a day // Add 7 days to this
                                                Subscription_ID: '-',
                                                Subscription_Status: subscriptionStatus, //'no_subscription_yet',
                                                Last_Payment_Invoice_Number: _this.state.last_payment_invoice_id,
                                                Last_Payment_Status: _this.state.last_payment_status.value,
                                            },
                                            //Temp fix for SMS SID (until Add-ons module has been developed) --17th Jan 2022
                                            SMS_Balance: _this.state.sms_balance,
                                            SMS_Sender_ID: _this.state.sms_sender_id,
                                            SMS_Sender_Name: _this.state.sms_sender_name,
                                            Mobile_App_Title: _this.state.mobile_app_title,
                                            Mobile_App_Description: _this.state.mobile_app_description,
                                            Mobile_App_Enable_Title_Description: _this.state.mobile_app_enable_title_description,
                                            Mobile_App_Ad_1: _this.state.mobile_app_ad_1_url,
                                            Mobile_App_Enable_Ad_1: _this.state.mobile_app_ad_1_enable,
                                        };

                                        Firebase.firestore().collection('Sub_Locations').doc(newSublocationID).set(new_sub_location_data)
                                            .then(function() {
                                                _this.setState({loading: false});
                                                _this.notifyMessage("tc", 2, "New Sub Location added successfully.");
                                               window.setTimeout(function() { _this.props.history.push("/locations") }, 2000);  //devcheck
                                            }).catch(function (error) {
                                                _this.setState({loading: false});
                                                _this.notifyMessage("tc", 3, "Network error!");
                                                console.log("handleAdd NetworkError17==>", error);
                                            });

                                            let batch = Firebase.firestore().batch();
                                            var AccLocsRoles = [];
                                            AccLocsRoles = ["System_Admin", "Location_Admin"];  //devcheck
                                            Firebase.firestore().collection('Web_App_Users').where('Customer_ID', '==', customer_id).where('Role', 'in', AccLocsRoles ).get()
                                                .then(query=> {
                                                    query.docs.forEach(t =>{
                                                        let arrAccessibleLocations = t.data().Accessible_Locations;
                                                        arrAccessibleLocations.push(newSublocationID);
                                    
                                                        let updateData = {
                                                            Accessible_Locations: arrAccessibleLocations
                                                        };
                                                        // console.log("arrAccessibleLocations ",arrAccessibleLocations)
                                                        let webAppUsers = Firebase.firestore().collection('Web_App_Users').doc(t.id);
                                                        batch.update(webAppUsers, updateData);
                                                    })
                                    
                                                    batch.commit().then(() => {
                                                        console.log('Updated all Accessible_Locations in Admin Roles under same Customer_ID');
                                                    })
                                    
                                                })
                                                .catch(function (error) {
                                                    _this.setState({loading: false});
                                                    _this.notifyMessage("tc", 3, "Network error!");
                                                    console.log("handleAdd NetworkError17b==>", error);
                                                });

                                    }).catch(function (err) {
                                        _this.setState({loading: false});
                                        _this.notifyMessage("tc", 3, "Network error!");
                                        console.log("handleAdd NetworkError18==>", err);
                                    });
                                } else {
                                    _this.setState({loading: false});
                                    _this.notifyMessage("tc", 3, "Network error!");
                                    console.log("handleAdd NetworkError21");
                                }
                            }).catch(function (err) {
                                _this.setState({loading: false});
                                _this.notifyMessage("tc", 3, "Network error!");
                                console.log("handleAdd NetworkError22==>", err);
                            });
                        } else {
                            _this.setState({loading: false});
                            _this.notifyMessage("tc", 3, "Network error!");
                            console.log("handleAdd NetworkError23");
                        }
                    }).catch(function (err) {
                        _this.setState({loading: false});
                        _this.notifyMessage("tc", 3, "Network error!");
                        console.log("handleAdd NetworkError24==>", err);
                    });
                }
            }
                
        } else {
            _this.setState({loading: false});
            _this.notifyMessage("tc", 3, "Please make sure all required fields are filled.");
        }
    }
    notifyMessage = (place, color, text) => {
        var type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }

        var options = {};
        options = {
            place: place,
            message: (
                <div className="text-md-center">
                    <div>
                        <b>{text}</b>
                    </div>
                </div>
            ),
            type: type,
            icon: "now-ui-icons ui-1_bell-53",
            autoDismiss: 3
        };
        if (options !== null) { this.notificationAlert.notificationAlert(options) }
    }
    isValidInvoiceID = value => {
        let _this = this;
        var docInvRefID = /^in1_[A-Za-z0-9]{20,36}$/; //in1_ followed by any alphabet char or numeric char between 20 (firestore default) to 36 chars length
        if (docInvRefID.test(value)) {
            console.log('validInvoiceID = true'); this.setState({ last_payment_invoice_idState: "has-success" }); return true;
        }
        else{
            console.log('validInvoiceID = false'); this.setState({ last_payment_invoice_idState: "has-danger" }); return false;
        }
    }
    isValidFormattedDate = (value, stateName) => {
        let _this = this;
        var dateRef = /^\d{2}[-]\d{2}[-]\d{4}$/; //dd-MM-yyyy
        if (dateRef.test(value) && value.substring(6, 10) !== '1970') {
            this.setState({ [stateName]: "has-success" });
            console.log({"value": value, [stateName]: "has-success"});
        }
        else{
            this.setState({ [stateName]: "has-danger" });
            console.log({"value": value, [stateName]: "has-danger"});
        }
    }
    isValidPackageID = value => {
        let _this = this;
        if (value !== null && value !== "") {
            Firebase.firestore().collection('Packages').doc(value.trim()).get().then(function (response) {
                if (response.exists) {
                    console.log(' validPackageID = true'); _this.setState({ package_idState: "has-success"}); return true;
                }
                else {
                    console.log(' validPackageID = false'); _this.setState({ package_idState: "has-danger"}); return false;
                }
            })
            .catch(function (error) {
                _this.setState({loading: false});
                _this.notifyMessage("tc", 3, "Network error!");
                console.log("isValidPackageID NetworkError==>", error);
            });
        }
       else {
        _this.setState({ package_idState: "has-danger"}); 
       }
    }
    isValidBillingEmail = value => {
        let _this = this;
        let payment_source = this.state.payment_source.value;
        var role = this.auth_info.role;
        let customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;
        if (role === 'Site_Admin' && _this.props.match.params.id != 1) {
            customer_id = _this.props.match.params.id;
        } else {
            customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;
        }

        if ( payment_source =='Online_Payment' ) {
            Firebase.firestore().collection('Stripe_Customers').where('Stripe_Billing_User_Email', '==', value).get().then(function (response) {
                if (response.docs.length >= 1 && response.docs[0].data().Antqueue_Customer_ID !== customer_id) {
                   console.log(' validEmail = false'); return false;
                }
                else {
                    console.log(' validEmail = true'); return true;
                }
            })
            .catch(function (error) {
                _this.setState({loading: false});
                _this.notifyMessage("tc", 3, "Network error!");
                console.log("isValidBillingEmail NetworkError==>", error);
            });
    
            return false;
        } else if ( payment_source =='Manual_Payment' ) {
            Firebase.firestore().collection('Manual_Billing_Profiles').where('Manual_Billing_User_Email', '==', value).get().then(function (response) {
                if (response.docs.length >= 1 && response.docs[0].data().Antqueue_Customer_ID !== customer_id) {
                   console.log(' validEmail = false'); return false;
                }
                else {
                    console.log(' validEmail = true'); return true;
                }
            })
            .catch(function (error) {
                _this.setState({loading: false});
                _this.notifyMessage("tc", 3, "Network error!");
                console.log("isValidBillingEmail NetworkError==>", error);
            });
    
            return false;
        }
    };
 
    verifyEmail = value => {
        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return emailRex.test(value);
    }

    handleBillingEmailChange = (newValue, actionMeta) => {
        let _this = this;
        console.group('Value Changed');
        console.log(newValue);
        console.log(`action: ${actionMeta.action}`);
        console.groupEnd();

        let payment_source = this.state.payment_source.value;
        var role = this.auth_info.role;
        let customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;
        if (role === 'Site_Admin' && _this.props.match.params.id != 1) {
            customer_id = _this.props.match.params.id;
        } else {
            customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;
        }
        if ( payment_source =='Online_Payment' ) {
            console.log("payment_source == 'Online_Payment'");
            if (newValue) {
                const { __isNew__, billing_name, billing_address, email, value } = newValue;

                const _email_ = __isNew__ ? value : email;
                const _name_ = __isNew__ ? '' : billing_name;
                const _address_ = __isNew__ ? '' : billing_address;

                let customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;
                
                if (_this.verifyEmail(newValue.value) === false && actionMeta.action == 'create-option') {
                    console.log(' validEmail = false');
                    _this.setState({ billing_emailState: "has-danger" });
                    _this.setState({
                        billing_emailStateMessage: "Please provide a valid email address!",
                    });

                } else {
                    Firebase.firestore().collection('Stripe_Customers').where('Stripe_Billing_User_Email', '==', _email_).get().then(function (response) {
                        if (response.docs.length >= 1 && response.docs[0].data().Antqueue_Customer_ID !== customer_id) {
                            console.log(' validEmail = false');
                            _this.setState({ billing_emailState: "has-danger" });
                            _this.setState({
                                billing_emailStateMessage: "This email address is already being used by another Customer. Please provide a different email address!",
                            });
                        }
                        else {
                            console.log(' validEmail = true');
                            _this.setState({
                                billing_emailStateMessage: "This field is required.",
                                billing_emailState: "has-success",
                                selected_stripe_customer: newValue,
                                billing_email: _email_,
                                billing_name: _name_,
                                billing_address: _address_,
                            });
                            console.log({
                                selected_stripe_customer: newValue,
                                billing_email: email,
                                billing_name: _name_,
                                billing_address: _address_,
                            });
                            _this.handleChange({target: {value:_name_ || ''}}, "billing_name", "length", 1);
                            _this.handleChange({target: {value:_address_ || ''}}, "billing_address", "length", 1);
                        }
                    })
                    .catch(function (error) {
                        _this.setState({loading: false});
                        _this.notifyMessage("tc", 3, "Network error!");
                        console.log("isValidBillingEmail NetworkError==>", error);
                    });
                }
                // if (!_this.isValidBillingEmail(_email_)) {
                //     _this.setState({ billing_emailState: "has-danger" });
                // }
                // else {
                //     _this.setState({
                //         billing_emailState: "has-success",
                //         selected_stripe_customer: newValue,
                //         billing_email: _email_,
                //         billing_name: _name_,
                //         billing_address: _address_,
                //     });
                //     console.log({
                //         selected_stripe_customer: newValue,
                //         billing_email: email,
                //         billing_name: _name_,
                //         billing_address: _address_,
                //     });
                //     _this.handleChange({target: {value:_name_ || ''}}, "billing_name", "length", 1);
                //     _this.handleChange({target: {value:_address_ || ''}}, "billing_address", "length", 1);
                // }

            } else {
                _this.setState({
                    selected_stripe_customer: null,
                    billing_email: '',
                    billing_name: '',
                    billing_address: '',
                    billing_nameState: '',
                    billing_addressState: '',
                });
            }
        } else if ( payment_source =='Manual_Payment' ) {
            console.log("payment_source == 'Manual_Payment'");
            if (newValue) {
                const { __isNew__, billing_name, billing_address, email, value } = newValue;

                const _email_ = __isNew__ ? value : email;
                const _name_ = __isNew__ ? '' : billing_name;
                const _address_ = __isNew__ ? '' : billing_address;

                let customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;

                if (_this.verifyEmail(newValue.value) === false && actionMeta.action == 'create-option') {
                    console.log(' validEmail = false');
                    _this.setState({ billing_emailState: "has-danger" });
                    _this.setState({
                        billing_emailStateMessage: "Please provide a valid email address!",
                    });

                } else {
                    Firebase.firestore().collection('Manual_Billing_Profiles').where('Manual_Billing_User_Email', '==', value).get().then(function (response) {
                        if (response.docs.length >= 1 && response.docs[0].data().Antqueue_Customer_ID !== customer_id) {
                        console.log(' validEmail = false');
                        _this.setState({ billing_emailState: "has-danger" });
                        _this.setState({
                            billing_emailStateMessage: "This email address is already being used by another Customer. Please provide a different email address!",
                            });
                        }
                        else {
                            console.log(' validEmail = true');
                            _this.setState({
                                billing_emailStateMessage: "This field is required.",
                                billing_emailState: "has-success",
                                selected_manual_billing_profile: newValue,
                                billing_email: _email_,
                                billing_name: _name_,
                                billing_address: _address_,
                            });
                            console.log({
                                selected_manual_billing_profile: newValue,
                                billing_email: email,
                                billing_name: _name_,
                                billing_address: _address_,
                            });
                            _this.handleChange({target: {value:_name_ || ''}}, "billing_name", "length", 1);
                            _this.handleChange({target: {value:_address_ || ''}}, "billing_address", "length", 1);
                        }
                    })
                    .catch(function (error) {
                        _this.setState({loading: false});
                        _this.notifyMessage("tc", 3, "Network error!");
                        console.log("isValidBillingEmail NetworkError==>", error);
                    });

                    // if (!_this.isValidBillingEmail(_email_)) {
                    //     _this.setState({ billing_emailState: "has-danger" });
                    // }
                    // else {
                    //     _this.setState({
                    //         billing_emailState: "has-success",
                    //         selected_manual_billing_profile: newValue,
                    //         billing_email: _email_,
                    //         billing_name: _name_,
                    //         billing_address: _address_,
                    //     });
                    //     console.log({
                    //         selected_manual_billing_profile: newValue,
                    //         billing_email: email,
                    //         billing_name: _name_,
                    //         billing_address: _address_,
                    //     });
                    //     _this.handleChange({target: {value:_name_ || ''}}, "billing_name", "length", 1);
                    //     _this.handleChange({target: {value:_address_ || ''}}, "billing_address", "length", 1);
                    // }
                }

            } else {
                _this.setState({
                    selected_manual_billing_profile: null,
                    billing_email: '',
                    billing_name: '',
                    billing_address: '',
                    billing_nameState: '',
                    billing_addressState: '',
                });
            }
        }
    }

    verifyLength = (value, length) => {
        return value.length >= length;
    };
    
    onChangePosition(e) {
        this.setState({position: { lat: e.latLng.lat(), lng: e.latLng.lng() }});
        let time_zone = geoTz(e.latLng.lat(), e.latLng.lng());console.log(time_zone)
        let same_one = this.state.time_zone_list.find(item => item.value.includes(time_zone));
        this.setState({time_zone: same_one});
    }
    onWatchCurrent() {
        this.loadAddressInfo()
        let current_position = this.state.current_position;
        this.setState({position: current_position});
        this.setState({center: current_position});
        let current_time_zone = this.state.current_time_zone;
        this.setState({time_zone: current_time_zone});
    }
    getFormattedDate(date, format){
        if (format === 'format1') {
            var year = date.getFullYear();
            var month = (1 + date.getMonth()).toString();
                month = month.length > 1 ? month : '0' + month;
            var day = date.getDate().toString();
                day = day.length > 1 ? day : '0' + day;
            return day + '-' + month + '-' + year;
        }
    }
    static getTimeString(time) {
        if (time === null || time === undefined)
            return "";

        if (time.seconds === null || time.seconds === undefined)
            return "";

        let date = new Date(time.seconds*1000);
        return date.toLocaleString();
    }

    handleChange = (event, stateName, type, stateNameEqualTo) => {
        switch (type) {
            case "length":
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + "State"]: "has-success" });
                } else {
                    this.setState({ [stateName + "State"]: "has-danger" });
                }
                break;
            case "current_period_end":
                console.log("current_period_end event.target.value", event.target.value);
                var dateString1 = new Date(event.target.value); var year1 = dateString1.getFullYear().toString(); var month1 = dateString1.getMonth() + 1; var day1 = dateString1.getDate();
                var timestamp1 = Date.parse(dateString1); var d1 =  Math.floor((new Date(timestamp1)).getTime() / 1000);
                var d11 = this.getFormattedDate(dateString1, 'format1');
                if (event.target.value !== null && isNaN(timestamp1) == false && parseInt(year1) >= 2019) {
                    this.setState({ [stateName + "State"]: "has-success", current_period_end_formatted_1: d1, current_period_end_formatted_2: d11 });
                    console.log("(isNaN(timestamp1) == false)");
                } else {
                    this.setState({ [stateName + "State"]: "has-danger" });
                    console.log("(isNaN(timestamp1) == true)");
                }
                console.log("current_period_end d1", d1); console.log("d11", d11); console.log("event.target.value", event.target.value); console.log("dateString1", dateString1); console.log("day1-month1-year1", day1 + "-" + month1 + "-" + year1);      
                break;
            case "current_period_start":
                    console.log("current_period_start event.target.value", event.target.value);
                    var dateString2 = new Date(event.target.value); var year2 = dateString2.getFullYear().toString(); var month2 = dateString2.getMonth() + 1; var day2 = dateString2.getDate();
                    var timestamp2 = Date.parse(dateString2); var d2 =  Math.floor((new Date(timestamp2)).getTime() / 1000);
                    var d22 = this.getFormattedDate(dateString2, 'format1');
                    if (event.target.value !== null && isNaN(timestamp2) == false && parseInt(year2) >= 2019) {
                        this.setState({ [stateName + "State"]: "has-success", current_period_start_formatted_1: d2, current_period_start_formatted_2: d22 });
                        console.log("(isNaN(timestamp2) == false)");
                    } else {
                        this.setState({ [stateName + "State"]: "has-danger" });
                        console.log("(isNaN(timestamp2) == true)");
                    }
                    console.log("current_period_start d2", d2); console.log("d22", d22);  console.log("event.target.value", event.target.value); console.log("dateString2", dateString2); console.log("day2-month2-year2", day2 + "-" + month2 + "-" + year2);      
                    break;
            case "last_payment_invoice_id":
                var docInvRefID = /^in1_[A-Za-z0-9]{20,36}$/; //in1_ followed by any alphabet char or numeric char between 20 (firestore default) to 36 chars length
                if (docInvRefID.test(event.target.value)) {
                    this.setState({ [stateName + "State"]: "has-success" });
                }
                else{
                    this.setState({ [stateName + "State"]: "has-danger" });
                }
                break;
            case "mobile_app_enable_title_description":
                if (event === true) {
                    if (this.verifyLength(this.state.mobile_app_title, stateNameEqualTo)) {
                        this.setState({ [stateName + "State"]: "has-success" });
                    } else {
                        this.setState({ [stateName + "State"]: "has-danger" });
                    }
                } else if (event === false) {
                    this.setState({ [stateName + "State"]: "has-success" });
                }

                break;
            case "mobile_app_title":
                if ((this.state.mobile_app_title).length >= 1) {
                    this.setState({ "mobile_app_enable_title_descriptionState": "has-success" });
                } else {
                    if (this.state.mobile_app_enable_title_description) {
                        this.setState({ "mobile_app_enable_title_descriptionState": "has-danger" });
                    }
                }
                break;
            default:
                break;
        }
        if (stateName == "mobile_app_enable_title_description") {
            this.setState({ [stateName]: event });
        }
        else {
            this.setState({ [stateName]: event.target.value });
        }
    };

    handleAttachment(e, field) {
        var _this = this; 
        var ok = false; var customerID = JSON.parse(localStorage.getItem('auth_info')).customer_id;  //_this.state.customer_id;  
        var elementID = ""; var inputFileRef = []; var fileName = ""; var fileNameWithExt = ""; var attachment_file = ""; var attachment_file_name = "";
        var task = ""; let metadata = {};

        if (field == "mobile_app_ad_1") {
            elementID = "uploader1";
            inputFileRef = this.inputFileRef1.current.files;
            fileName = "main_location_ad_1_" + _this.state.main_location_key;
            attachment_file = inputFileRef[0];

            if (/*e.target.files[0]*/ attachment_file != undefined) {
       
                attachment_file_name = attachment_file.name; /*e.target.files[0]*/
                console.log("attachment_file_name", attachment_file_name, "fileName", fileName, "attachment_file_name.split('.')[1]", attachment_file_name.split('.')[1]);
                fileNameWithExt = fileName+'.'+attachment_file_name.split('.')[1]; 
                _this.setState({mobile_app_ad_1_name: fileNameWithExt});
                switch (attachment_file_name.slice((attachment_file_name.lastIndexOf(".") - 1 >>> 0) + 2)) { //attachment_file_name.split('.')[1] 
                    case 'mp4': ok = true; break;
                    case 'png': ok = true; break;
                    case 'jpg': ok = true; break;
                    case 'jpeg': ok = true; break;
                    case 'webp': ok = true; break;
                    default: ok = false; break;
                }
            }
            if (/*e.target.files[0]*/ attachment_file != undefined && ok) {
                console.log("this.state.main_location_key, customerID", _this.state.main_location_key, customerID);
                metadata = {
                    customMetadata: {
                        customer_id: _this.state.customer_id,
                        main_location_id: _this.state.main_location_key,
                        sub_location_id: _this.state.sub_location_id
                    }
                };

                if (/*e.target.files[0]*/ attachment_file.size <= _this.state.mobile_ad_file_max_limit * 1024 * 1024) {
                    var storageRef1 = Firebase.storage().ref('Media_Gallery/Mobile_Ads/'+customerID+'/'); console.log("handleAttachment storageRef1", storageRef1);
                    var fileName1 = fileNameWithExt; console.log("handleAttachment fileName1", fileName1);
                    var fileRef1 = storageRef1.child(fileName1); console.log("handleAttachment fileRef1", fileRef1);
                    task = fileRef1.put(attachment_file, metadata)
                    /*.then(res=>console.log('uploadsuccess'),err=>console.log('uploadfailed...',err))*/
                    document.getElementById(elementID).style.display = 'block';

                    task.on('state_changed', (snapshot) => {
                        var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        document.getElementById(elementID).value = percentage;

                    }, () => { }, () => {
                        //let _this = this, j = 0, ad = [], o = 0
                        _this.setState({ mobile_app_ad_1_attachmentState: "has-success" });
                         // .then(function (snapshot) {
                            fileRef1.getDownloadURL().then(function (res1) {
                                _this.setState({mobile_app_ad_1_url: res1});
                                console.log("handleAttachment mobile_app_ad_1_url", res1);
                            })
                        // })
                        document.getElementById(elementID).style.display = 'none';
                    })
                    task.catch(error => {
                        _this.setState({ mobile_app_ad_1_attachmentState: "has-danger" });
                        console.log('image/video upload error==> ', error);
                    })
                }
                else {
                    _this.notifyMessage("tc", 3, "Image/video AD size must be less than " + _this.state.mobile_ad_file_max_limit + " MB");
                    _this.setState({ mobile_app_ad_1_attachmentState: "has-danger" });
                }
            } else {
                console.log("fileName", fileName); console.log("attachment_file", attachment_file);
                _this.notifyMessage("tc", 3, "Please provide a valid image/video file with any one of these extensions: mp4, png, jpg, jpeg, webp!");
                _this.setState({ mobile_app_ad_1_attachmentState: "has-danger" });
            }
        }
    }

    render() {
        var ok = false
        // console.log(this.state.role)
        if(this.state.role == "Site_Admin" || this.state.role == "System_Admin" || this.state.role == "Billing_Admin")
            ok=true
        let {
            nameState,
            billing_emailState,
            billing_emailStateMessage,
            billing_nameState,
            billing_addressState,
            package_idState,
            last_payment_invoice_idState,
            last_payment_statusState,
            last_payment_statusStateMessage,
            intervalState,
            intervalStateMessage,
            current_period_endState,
            current_period_startState,
            mobile_app_enable_title_descriptionState,
            mobile_app_ad_1_attachmentState
        } = this.state;
        const { last_payment_invoice_id, billing_email_list, billing_name, billing_address } = this.state;
        
        return (
            <>
                <LoadingOverlay
                    active={this.state.loading}
                    spinner
                    text='Loading'
                    className='content'
                >
                    <NotificationAlert ref={(ref) => this.notificationAlert = ref} />
                    <Row>
                        <Col md="12">
                            {ok? <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Sub Location Add</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Col className="ml-auto mr-auto" xl="8" lg="10" md="12">
                                        <Row>
                                            <Col md="3">
                                                <Button
                                                    color="success"
                                                    onClick={e => this.handleAdd()}
                                                    block
                                                >
                                                    Add
                                                </Button>
                                            </Col>
                                            <Col md="3">
                                                <Button
                                                    color="youtube"
                                                    onClick={e => this.props.history.push("/locations")}
                                                    block
                                                >
                                                    Close
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row className="top-margin-10">
                                        </Row>
                                        <Row>
                                            <Col xl="8" lg="12" md="12">
                                                <Form className="form-horizontal">
                                                    <hr/>
                                                    <Row>
                                                        <Col md="12">
                                                            <FormGroup>
                                                            <h5>General</h5>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Main Location</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Select
                                                                    className="react-select primary"
                                                                    classNamePrefix="react-select"
                                                                    name="mainLocationSelect"
                                                                    value={this.state.selected_main_location}
                                                                    onChange={value =>
                                                                        this.setState({ selected_main_location: value })
                                                                    }
                                                                    options={this.state.main_location_list}
                                                                    placeholder="Select Main Location"
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Sub Location Key</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Input
                                                                    type="text"
                                                                    defaultValue={this.state.sub_location_id}
                                                                    disabled
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Sub Location Name</Label>
                                                        <Col md="8">
                                                            <FormGroup className={`has-label ${nameState}`}>
                                                                <Input
                                                                    placeholder="New Sub Location"
                                                                    type="text"
                                                                    onChange={e =>
                                                                        this.handleChange(e, "name", "length", 1)
                                                                    }
                                                                />
                                                                {this.state.nameState === "has-danger" ? (
                                                                    <label className="error">This field is required.</label>
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Icon</Label>
                                                        <Col md="8">
                                                            <IconUpload ref="icon" />
                                                        </Col>
                                                    </Row>
                                                    <Row className="top-margin-10"/>
                                                    <Row>
                                                        <Label md="4">Address</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Input
                                                                    placeholder=""
                                                                    type="text"
                                                                    onChange={e => {this.setState({address: e.target.value})}}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Enable on mobile app</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Row className="top-margin-7"/>
                                                                <Switch
                                                                    offColor="success"
                                                                    offText={<i className="nc-icon nc-simple-remove" />}
                                                                    onColor="success"
                                                                    onText={<i className="nc-icon nc-check-2" />}
                                                                    disabled={!this.state.package_enable_mobile}
                                                                    defaultValue={this.state.enable_mobile}
                                                                    value={this.state.enable_mobile}
                                                                    onChange={e => {this.setState({enable_mobile: e.state.value})}}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Active/Inactive</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Row className="top-margin-7"/>
                                                                <Switch
                                                                    offColor="success"
                                                                    offText={<i className="nc-icon nc-simple-remove" />}
                                                                    onColor="success"
                                                                    onText={<i className="nc-icon nc-check-2" />}
                                                                    defaultValue={this.state.active}
                                                                    value={this.state.active}
                                                                    onChange={e => {this.setState({active: e.state.value})}}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Allow only One Token per Person per Sublocation</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Row className="top-margin-7"/>
                                                                <Switch
                                                                    offColor="success"
                                                                    offText={<i className="nc-icon nc-simple-remove" />}
                                                                    onColor="success"
                                                                    onText={<i className="nc-icon nc-check-2" />}
                                                                    defaultValue={this.state.allow_only_one_token_per_person_per_sublocation}
                                                                    value={this.state.allow_only_one_token_per_person_per_sublocation}
                                                                    onChange={e => {this.setState({allow_only_one_token_per_person_per_sublocation: e.state.value})}}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Validate Service for Token Number Range overlap</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Row className="top-margin-7"/>
                                                                <Switch
                                                                    offColor="success"
                                                                    offText={<i className="nc-icon nc-simple-remove" />}
                                                                    onColor="success"
                                                                    onText={<i className="nc-icon nc-check-2" />}
                                                                    defaultValue={this.state.validate_serv_for_tok_num_range_overlap}
                                                                    value={this.state.validate_serv_for_tok_num_range_overlap}
                                                                    onChange={e => {this.setState({validate_serv_for_tok_num_range_overlap: e.state.value})}}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    {/* <Row>
                                                        <Label md="4">Package</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Input
                                                                    defaultValue={this.state.package_name}
                                                                    type="text"
                                                                    disabled
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row> */}
                                                    <Row>
                                                        <Label md="4">Time Zone</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Select
                                                                    className="react-select primary"
                                                                    classNamePrefix="react-select"
                                                                    name="timezoneSelect"
                                                                    value={this.state.time_zone}
                                                                    onChange={value =>
                                                                        this.setState({ time_zone: value })
                                                                    }
                                                                    options={this.state.time_zone_list}
                                                                    placeholder="Select time zone"
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Google Map</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Button
                                                                    className="btn-round"
                                                                    color="primary"
                                                                    outline
                                                                    onClick={e => {e.preventDefault();this.onWatchCurrent()}}
                                                                >
                                                                    <i className="fa fa-eye" />
                                                                    Where I am
                                                                </Button>
                                                                <CustomMap
                                                                    ref="custom_map"
                                                                    api_key={info.google_map_api_key}
                                                                    center={this.state.center}
                                                                    position={this.state.position}
                                                                    zoom={this.state.map_zoom}
                                                                    onMarkerDragEnd={e => this.onChangePosition(e)}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row className="top-margin-10">
                                                        <Label md="4">Remarks</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Input
                                                                    defaultValue={this.state.remarks}
                                                                    type="textarea"
                                                                    onChange={e => {this.setState({remarks: e.target.value})}}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <hr/>
                                                    <Row>
                                                        <Col md="12">
                                                            <FormGroup>
                                                            <h5>Billing</h5>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row className="top-margin-10">
                                                        <Label md="4">Payment Source</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Select
                                                                    className="react-select info"
                                                                    classNamePrefix="react-select"
                                                                    value={this.state.payment_source}
                                                                    isDisabled={this.state.payment_source_disable_selection}
                                                                    onChange={value =>
                                                                        this.setState({ payment_source: value })
                                                                    }
                                                                    options={this.state.payment_source_options_list}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    {this.state.payment_source.value =='Manual_Payment' ?
                                                    <div>
                                                    <Row className="top-margin-10">
                                                        <Label md="4">Payment Interval</Label>
                                                        <Col md="8">
                                                            <FormGroup className={`has-label ${intervalState}`}>
                                                                <Select
                                                                    className="react-select info"
                                                                    classNamePrefix="react-select"
                                                                    value={this.state.interval}
                                                                    isDisabled={this.state.interval_disable_selection}
                                                                    onChange={value =>
                                                                        this.setState({ interval: value })
                                                                    }
                                                                    options={this.state.interval_options_list}
                                                                />
                                                                {intervalState === "has-danger" ? (
                                                                    <label className="error">{`${intervalStateMessage}`}</label>
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Package ID</Label>
                                                        <Col md="8">
                                                            <FormGroup className={`has-label ${package_idState}`}>
                                                                <Input
                                                                    placeholder="Package ID"
                                                                    type="text"
                                                                    onChange={e =>
                                                                        this.handleChange(e, "package_id", "length", 1)
                                                                    }
                                                                />
                                                                {this.state.package_idState === "has-danger" ? (
                                                                    <label className="error">A valid Package ID is required for this field.</label>
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Last Payment Invoice ID</Label>
                                                        <Col md="8">
                                                            <FormGroup className={`has-label ${last_payment_invoice_idState}`}>
                                                                <Input
                                                                    placeholder="in1_XXXXXXXXXXXXXXXXXXXX"
                                                                    value={last_payment_invoice_id}
                                                                    type="text"
                                                                    onChange={e =>
                                                                        this.handleChange(e, "last_payment_invoice_id", "last_payment_invoice_id", 1)
                                                                    }
                                                                />
                                                                {this.state.last_payment_invoice_idState === "has-danger" ? (
                                                                    <label className="error">A valid Invoice ID is required for this field.</label>
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row className="top-margin-10">
                                                        <Label md="4">Payment Status</Label>
                                                        <Col md="8">
                                                            <FormGroup className={`has-label ${last_payment_statusState}`}>
                                                                <Select
                                                                    className="react-select info"
                                                                    classNamePrefix="react-select"
                                                                    value={this.state.last_payment_status}
                                                                    onChange={value =>
                                                                        this.setState({ last_payment_status: value })
                                                                    }
                                                                    options={this.state.last_payment_status_options_list}
                                                                />
                                                                {this.state.last_payment_statusState === "has-danger" ? (
                                                                    <label className="error">{`${last_payment_statusStateMessage}`}</label>
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Current Period Start Date</Label>
                                                        <Col md="8">
                                                            <FormGroup className={`has-label ${current_period_startState}`}>
                                                                <DatePicker
                                                                    format="dd-MM-yyyy"
                                                                    value={this.state.current_period_start}
                                                                    onChange={e =>
                                                                        this.handleChange(e, "current_period_start", "current_period_start")
                                                                    }
                                                                />
                                                                {this.state.current_period_startState === "has-danger" ? (
                                                                    <label className="error">This field is required.</label>
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Current Period End Date</Label>
                                                        <Col md="8">
                                                            <FormGroup className={`has-label ${current_period_endState}`}>
                                                                <DatePicker
                                                                    format="dd-MM-yyyy"
                                                                    value={this.state.current_period_end}
                                                                    onChange={e =>
                                                                        this.handleChange(e, "current_period_end", "current_period_end")
                                                                    }
                                                                />
                                                                {this.state.current_period_endState === "has-danger" ? (
                                                                    <label className="error">This field is required.</label>
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    </div>
                                                    : null}

                                                    {billing_email_list &&
                                                    <Row>
                                                        <Label md="4">Billing Email</Label>
                                                        <Col md="8">
                                                            <FormGroup className={`has-label ${billing_emailState}`}>
                                                                <CreatableSelect
                                                                    isClearable
                                                                    // isValidNewOption={this.isValidBillingEmail}
                                                                    onChange={this.handleBillingEmailChange}
                                                                    options={billing_email_list}
                                                                />
                                                                {billing_emailState === "has-danger" ? (
                                                                    <label className="error">{`${billing_emailStateMessage}`}</label>
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>}
                                                    {billing_email_list &&
                                                    <Row>
                                                        <Label md="4"></Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <label className="error">If you select an existing billing email address and change related billing information (name and/or address fields below), corresponding billing profile will also be updated. </label>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    }
                                                    <Row>
                                                        <Label md="4">Billing Name</Label>
                                                        <Col md="8">
                                                            <FormGroup className={`has-label ${billing_nameState}`}>
                                                                <Input
                                                                    placeholder="Your Billing Name"
                                                                    type="text"
                                                                    defaultValue={billing_name}
                                                                    onChange={e =>
                                                                        this.handleChange(e, "billing_name", "length", 1)
                                                                    }
                                                                />
                                                                {billing_nameState === "has-danger" ? (
                                                                    <label className="error">This field is required.</label>
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Billing Address</Label>
                                                        <Col md="8">
                                                            <FormGroup className={`has-label ${billing_addressState}`}>
                                                                <Input
                                                                    placeholder="Your Billing Address"
                                                                    type="text"
                                                                    defaultValue={billing_address}
                                                                    onChange={e =>
                                                                        this.handleChange(e, "billing_address", "length", 1)
                                                                    }
                                                                />
                                                                {billing_addressState === "has-danger" ? (
                                                                    <label className="error">This field is required.</label>
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <hr/>
                                                    <Row>
                                                        <Col md="12">
                                                            <FormGroup>
                                                            <h5>Mobile App Advertising</h5>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row className="top-margin-10">
                                                    <Label md="4">Title</Label>
                                                        <Col md="8">
                                                            <FormGroup className={`has-label ${mobile_app_enable_title_descriptionState}`}>
                                                                <Input
                                                                    type="text"
                                                                    defaultValue={this.state.mobile_app_title}
                                                                    onChange={e =>
                                                                        this.handleChange(e, "mobile_app_title", "mobile_app_title", 1)
                                                                    }
                                                                />
                                                                {this.state.mobile_app_enable_title_descriptionState === "has-danger" ? (
                                                                    <label className="error">This field is required if "Enable Title & Description" field is enabled.</label>
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row className="top-margin-10">
                                                        <Label md="4">Description</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Input
                                                                    defaultValue={this.state.mobile_app_description}
                                                                    type="text"
                                                                    onChange={e => {this.setState({mobile_app_description: (e.target.value).trim()})}}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Enable Title & Description</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Row className="top-margin-7"/>
                                                                <Switch
                                                                    offColor="success"
                                                                    offText={<i className="nc-icon nc-simple-remove" />}
                                                                    onColor="success"
                                                                    onText={<i className="nc-icon nc-check-2" />}
                                                                    defaultValue={this.state.mobile_app_enable_title_description}
                                                                    value={this.state.mobile_app_enable_title_description}
                                                                    onChange={e => {this.handleChange(e.state.value, "mobile_app_enable_title_description", "mobile_app_enable_title_description", 1)}}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row className="top-margin-10" />
                                                    <Row md='12' className='px-0 mx-0'>
                                                        <Label md="4">Advertisement 1 Image / Video File</Label>

                                                        <Col md='8' style={{ paddingLeft: '0px', paddingBottom: '15px' }} >
                                                            <progress id="uploader1" value="0" max="100" style={{ display: 'none' }}>0%</progress>
                                                            <input type="file" id='file1' ref={this.inputFileRef1} key={this.state.inputKey1}
                                                                onChange={e => this.handleAttachment(e, "mobile_app_ad_1")}
                                                            />
                                                            {this.state.mobile_app_ad_1_name != undefined ?
                                                                (this.state.mobile_app_ad_1_name.length > 0 ?
                                                                    <Row md='12' className='px-0 mx-0'>
                                                                    <img src={trash} style={{ width: '15px', marginTop: '3px' }} alt=""
                                                                        onClick={() => {
                                                                            console.log("this.state.mobile_app_ad_1_name", this.state.mobile_app_ad_1_name);
                                                                            var task1 = Firebase.storage().ref('Media_Gallery/Mobile_Ads/'+this.state.customer_id+'/').child(this.state.mobile_app_ad_1_name).delete();
                                                                            task1.catch(error => { 
                                                                                console.log("task1 error", error);
                                                                            })
                                                                            
                                                                            this.setState({
                                                                                inputKey1: Math.random().toString(36),
                                                                                mobile_app_ad_1: '',
                                                                                mobile_app_ad_1_name: '',
                                                                                mobile_app_ad_1_url: '',
                                                                                mobile_app_ad_1_attachmentState: '',
                                                                            })
                                                                        }}
                                                                    /></Row> : null)
                                                                : null}
                                                            {this.state.mobile_app_ad_1_attachmentState === "has-danger" ? (
                                                                <div className="has-label has-danger position-relative form-group">
                                                                    <label className="error">Please provide a valid image/video file of type png, jpg, jpeg, webp OR mp4!!</label>
                                                                </div>
                                                            ) : null}
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Enable Advertisement 1</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Row className="top-margin-7"/>
                                                                <Switch
                                                                    offColor="success"
                                                                    offText={<i className="nc-icon nc-simple-remove" />}
                                                                    onColor="success"
                                                                    onText={<i className="nc-icon nc-check-2" />}
                                                                    defaultValue={this.state.mobile_app_ad_1_enable}
                                                                    value={this.state.mobile_app_ad_1_enable}
                                                                    onChange={e => {this.setState({mobile_app_ad_1_enable: e.state.value})}}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4"></Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <label className="error">Mobile App Ad 1 can only be activated after purchasing the add-on &quot;Add-On: Services Ad 1&quot;.</label>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </Col>
                                        </Row>
                                    </Col>
                                </CardBody>
                            </Card>: "Sorry, you don't have permission to use this function, Please contact System Administrator if this is a mistake."}
                        </Col>
                    </Row>
                </LoadingOverlay>
            </>
        );
    }
}

export default SubLocationAdd;
