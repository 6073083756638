import React, { useEffect, useState } from 'react'
import LoadingOverlay from 'react-loading-overlay'
import Switch from 'react-bootstrap-switch'
import Select from 'react-select'
import ReactDragListView from 'react-drag-listview/lib/index.js'
import NotificationAlert from 'react-notification-alert'
import Firebase from 'firebase'
// import ReactBSAlert from "react-bootstrap-sweetalert";
import ReactTable from 'react-table-6';
import {
  DateRangePicker,
  DatePicker,
  DateInput
} from '@progress/kendo-react-dateinputs'
import moment from 'moment-timezone'
import config from '../../../config'
import { ExportReactCSV } from '../../../components/ExportReactCSV'
// const uuidv1 = require('uuid/v1')
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Collapse,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Button,
  Label,
  Modal,
  FormGroup,
  InputGroup,
  Row,
  Col,
  Input,
  InputGroupAddon,
  InputGroupText,
  Spinner
} from 'reactstrap'
import TokenAttachFile from './TokenAttachFile'

function TokenModal(props) {
  const [loading, set_loading] = useState(false)
  const [TokenData, set_TokenData] = useState({ file_attachments: [] })
  const [inputFields, set_inputFields] = useState({})
  const [fileAttachments, set_fileAttachments] = useState({})
  const [horizontalTabs, set_horizontalTabs] = useState('')
  const [ID, set_ID] = useState(props.id)
  const [update, setUpdate] = useState('')
  const [modalClassic, set_modalClassic] = useState(false)
  console.log(ID)
  let set_loading_counter = props.set_loading
  let notifyMessage = props.notifyMessage
  let userId = Firebase.auth().currentUser.uid
  useEffect(() => {
    console.log(ID)
    Firebase.firestore()
      .collection('Token_Details')
      .doc(ID)
      .get()
      .then(function (tok) {
        const option = {
          token_detail_id: tok.id,
          id: tok.id,
          value: tok.id,
          customer_id: tok.data().Customer_ID,
          created_datetime: tok.data().Created_Datetime,
          created_datetime_ts: tok.data().Created_Datetime_Unix_Timestamp,
          requested_datetime: tok.data().Requested_Datetime,
          requested_datetime_ts: tok.data().Requested_Datetime_Unix_Timestamp,
          service_datetime: tok.data().Service_Datetime,
          service_datetime_formatted: moment(
            new Date(
              parseInt(tok.data().Service_Datetime_Unix_Timestamp) * 1000
            )
          )
            .tz(tok.data().TimeZone)
            .format('YYYY-MM-DD'),
          service_datetime_ts: tok.data().Service_Datetime_Unix_Timestamp,
          internal_response: tok.data().Internal_Response,
          service_type: tok.data().Service_Type,
          start_char: tok.data().Start_Character,
          input_source: tok.data().Input_Source,
          main_loc_id: tok.data().Main_Location_ID,
          sub_loc_id: tok.data().Sub_Location_ID,
          serv_id: tok.data().Services_ID,
          main_loc_nam: tok.data().Main_Location_Name,
          sub_loc_nam: tok.data().Sub_Location_Name,
          serv_name: tok.data().Service_Name,
          serv_start_date: tok.data().Service_Start_Datetime,
          token_num: tok.data().Token_Number_String,
          token_status: tok.data().Token_Status,
          waiting_time: tok.data().Waiting_Time,
          mau_id: tok.data().Mobile_App_User_ID,
          mau_contact: tok.data().Mobile_App_User_Contact,
          mau_email: tok.data().Mobile_App_User_Email,
          mau_name: tok.data().Mobile_App_User_Name,
          time_zone: tok.data().TimeZone,
          new_tok_ref_id: tok.data().New_Token_Reference_ID,
          new_tok_ref_num: tok.data().New_Token_Reference_Num,
          cancelled_tok_ref_id: tok.data().Cancelled_Token_Reference_ID,
          cancelled_tok_ref_num: tok.data().Cancelled_Token_Reference_Num,
          new_tok_ref_prev_tok_cancelled_reason: tok.data().Cancelled_Reason,
          input_fields: tok.data().Mobile_App_Input_Fields,
          file_attachments: tok.data().Mobile_App_File_Attachments

          // label: Name,
        }

        let tokenData = option
        console.log('tokenData++++++++++++', option)

        var inputFields = []
        var fileAttachments = []
        if (tokenData !== null && typeof tokenData !== 'undefined') {
          if (tokenData.input_fields != undefined)
            tokenData.input_fields.map((prop, key) => {
              if (
                typeof prop.id !== 'undefined' &&
                prop.id !== null &&
                prop.id !== ''
              ) {
                inputFields.push({
                  id: prop.id,
                  key: key + 1,
                  field_name: prop.Input_Field_Name,
                  field_value: prop.Input_Field_Value,
                  field_data_type: prop.Input_Field_Data_Type,
                  field_mandatory:
                    prop.Input_Field_Mandatory == true ? (
                      <span className='badge badge-pill badge-success'>
                        Yes
                      </span>
                    ) : (
                      <span className='badge badge-pill badge-default'>No</span>
                    )
                })
              }
            })
          console.log('file_attachments+++++++++++', tokenData.file_attachments)
          if (
            tokenData.file_attachments != undefined &&
            typeof tokenData.file_attachments == 'object'
          )
            tokenData.file_attachments.map((prop, key) => {
              if (
                // typeof prop.id !== 'undefined' &&
                // prop.id !== null &&
                // prop.id !== ''
                true
              ) {
                fileAttachments.push({
                  id: prop.id || '',
                  key: key + 1,
                  file_attachment_name: prop.File_Attachment_Name,
                  // file_attachment_file_name: prop.File_Attachment_File_Name,
                  file_size: prop.File_Attachment_File_Size_In_KB || '',
                  file_type: prop.File_Attachment_File_Type || '',
                  file_mandatory:
                    prop.File_Attachment_Mandatory == true ? (
                      <span className='badge badge-pill badge-success'>
                        Yes
                      </span>
                    ) : (
                      (
                        <span className='badge badge-pill badge-default'>
                          No
                        </span>
                      ) || ''
                    ),
                  action:
                    prop.File_Attachment_URL !== '' ? (
                      <div>
                        <Button
                          className='btn btn-default btn-sm'
                          style={{ marginTop: '-7px', marginBottom: '-7px' }}
                        >
                          <a
                            href={prop.File_Attachment_URL}
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            Preview
                          </a>
                        </Button>
                      </div>
                    ) : (
                      null || ''
                    )
                })
              }
            })
          let horizontalTabsVal = 'modifyRequestTabPane'
          if (
            tokenData.input_fields !== null &&
            typeof tokenData.input_fields !== 'undefined' &&
            tokenData.input_fields.length >= 1
          ) {
            horizontalTabsVal = 'inputFieldsTabPane'
            console.log('showTokenDetails1')
          } else if (
            tokenData.file_attachments !== null &&
            typeof tokenData.file_attachments !== 'undefined' &&
            tokenData.file_attachments.length >= 1
          ) {
            horizontalTabsVal = 'attachmentFieldsTabPane'
            console.log('showTokenDetails2')
          }
          set_TokenData(tokenData)

          set_inputFields(inputFields)
          set_fileAttachments(fileAttachments)
          set_horizontalTabs(horizontalTabsVal)
          set_modalClassic(true)
        }
      })
  }, [update])
  const toggleModalClassic = () => {
    set_loading(false)
    set_loading_counter(false)
    set_modalClassic(!modalClassic)
  }

  return (
    <>
      {modalClassic && (
        <>
          <div className='modal-header justify-content-center font-weight-bold'>
            {TokenData.new_tok_ref_id !== null &&
              typeof TokenData.new_tok_ref_id !== 'undefined' &&
              TokenData.new_tok_ref_id !== '' ? (
              <>
                <span>
                  <p>Current Token Number (Cancelled): {TokenData.token_num}</p>
                </span>
                <span>
                  <p>New Token Number: {TokenData.new_tok_ref_num}</p>
                </span>
              </>
            ) : TokenData.cancelled_tok_ref_id !== null &&
              typeof TokenData.cancelled_tok_ref_id !== 'undefined' &&
              TokenData.cancelled_tok_ref_id !== '' ? (
              <>
                <span>
                  <p>
                    Previous Token Number (Cancelled):{' '}
                    {TokenData.cancelled_tok_ref_num}
                  </p>
                </span>
                <span>
                  <p>Current Token Number: {TokenData.token_num}</p>
                </span>
              </>
            ) : (
              <>
                <span>
                  <p>Current Token Number: {TokenData.token_num}</p>
                </span>
              </>
            )}
          </div>
          <div className='modal-body'>
            <>
              <div className='content'>
                <div className='nav-tabs-navigation'>
                  <div className='nav-tabs-wrapper'>
                    <Nav id='tabs' role='tablist' tabs>
                      <NavItem>
                        <NavLink
                          aria-expanded={
                            horizontalTabs === 'inputFieldsTabPane'
                          }
                          data-toggle='tab'
                          href='#panel'
                          role='tab'
                          className={
                            horizontalTabs === 'inputFieldsTabPane'
                              ? 'active'
                              : ''
                          }
                          onClick={() =>
                            set_horizontalTabs('inputFieldsTabPane')
                          }
                        >
                          Input Fields
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          aria-expanded={
                            horizontalTabs === 'attachmentFieldsTabPane'
                          }
                          data-toggle='tab'
                          href='#panel'
                          role='tab'
                          className={
                            horizontalTabs === 'attachmentFieldsTabPane'
                              ? 'active'
                              : ''
                          }
                          onClick={() =>
                            set_horizontalTabs('attachmentFieldsTabPane')
                          }
                        >
                          File Attachments
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </div>
                <TabContent
                  className='text-center'
                  id='my-tab-content'
                  activeTab={horizontalTabs}
                >
                  {/* <TabPane tabId='modifyRequestTabPane' role='tabpanel'>
                            <Row className='top-margin-10' />
                            <Row>
                              <Label md='4'>Service Date</Label>
                              <Col md='8' style={{ textAlign: 'left' }}>
                                <FormGroup
                                  className={`has-label ${selected_token_serv_dateState}`}
                                >
                                  <DatePicker
                                    format='dd-MM-yyyy'
                                    value={this.state.selected_token_serv_date}
                                    onChange={e =>
                                      this.handleChangeModifyTokenFields(
                                        e,
                                        'MOD_servDateUnixTS'
                                      )
                                    }
                                  />
                                  {selected_token_serv_dateState ===
                                  'has-danger' ? (
                                    <label className='error'>
                                      A valid Service Date is required.
                                    </label>
                                  ) : null}
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Label md='4'>Main Location</Label>
                              <Col md='8'>
                                <FormGroup
                                  className={`has-label ${selected_token_main_locState}`}
                                >
                                  <Select
                                    placeholder='Select'
                                    closeMenuOnSelect={true}
                                    value={this.state.selected_token_main_loc}
                                    options={
                                      this.state.selected_token_main_loc_list
                                    }
                                    onChange={e =>
                                      this.handleChangeModifyTokenFields(
                                        e,
                                        'MOD_mainLocId'
                                      )
                                    }
                                  />
                                  {selected_token_main_locState ===
                                  'has-danger' ? (
                                    <label className='error'>
                                      This field is required.
                                    </label>
                                  ) : null}
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Label md='4'>Sub Location</Label>
                              <Col md='8'>
                                <FormGroup
                                  className={`has-label ${selected_token_sub_locState}`}
                                >
                                  <Select
                                    placeholder='Select'
                                    closeMenuOnSelect={true}
                                    value={this.state.selected_token_sub_loc}
                                    options={
                                      this.state.selected_token_sub_loc_list
                                    }
                                    onChange={e =>
                                      this.handleChangeModifyTokenFields(
                                        e,
                                        'MOD_subLocId'
                                      )
                                    }
                                  />
                                  {selected_token_sub_locState ===
                                  'has-danger' ? (
                                    <label className='error'>
                                      This field is required.
                                    </label>
                                  ) : null}
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Label md='4'>Service</Label>
                              <Col md='8'>
                                <FormGroup
                                  className={`has-label ${selected_token_servState}`}
                                >
                                  <Select
                                    placeholder='Select'
                                    closeMenuOnSelect={true}
                                    value={this.state.selected_token_serv}
                                    options={
                                      this.state.selected_token_serv_list
                                    }
                                    onChange={e =>
                                      this.handleChangeModifyTokenFields(
                                        e,
                                        'MOD_servId'
                                      )
                                    }
                                  />
                                  {selected_token_servState === 'has-danger' ? (
                                    <label className='error'>
                                      This field is required.
                                    </label>
                                  ) : null}
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Label md='4'>Internal Response</Label>
                              <Col md='8'>
                                <FormGroup>
                                  <Input
                                    value={
                                      this.state.selected_token_internal_resp
                                    }
                                    type='text'
                                    onChange={e =>
                                      this.handleChangeModifyTokenFields(
                                        e,
                                        'MOD_internalResp'
                                      )
                                    }
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Label md='4'>Token Status</Label>
                              <Col md='8'>
                                <FormGroup
                                  className={`has-label ${selected_token_token_statusState}`}
                                >
                                  <Select
                                    placeholder='Select'
                                    closeMenuOnSelect={true}
                                    value={
                                      this.state.selected_token_token_status
                                    }
                                    options={
                                      this.state
                                        .selected_token_token_status_list
                                    }
                                    onChange={e =>
                                      this.handleChangeModifyTokenFields(
                                        e,
                                        'MOD_tokenStatus'
                                      )
                                    }
                                  />
                                  {selected_token_token_statusState ===
                                  'has-danger' ? (
                                    <label className='error'>
                                      This field is required.
                                    </label>
                                  ) : null}
                                </FormGroup>
                              </Col>
                            </Row>
                            {tokenData.new_tok_ref_id !== null &&
                            typeof tokenData.new_tok_ref_id !== 'undefined' &&
                            tokenData.new_tok_ref_id !== '' ? (
                              <>
                                <Row>
                                  <Label md='4'>Cancelled Reason</Label>
                                  <Col md='8'>
                                    <FormGroup>
                                      <Input
                                        disabled
                                        defaultValue={
                                          this.state
                                            .selected_token_ref_cancelled_reason
                                        }
                                        type='textarea'
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </>
                            ) : null}
                            <Row>
                              <Label md='4'></Label>
                              <Col md='8'>
                                <FormGroup>
                                  <div>
                                    <button
                                      className='btn btn-info btn-block'
                                      variant='primary'
                                      disabled={
                                        this.state.btn_disabled_save_tok
                                      }
                                      onClick={() =>
                                        this.handleRequest(
                                          tokenData,
                                          'modify_tok',
                                          1
                                        )
                                      }
                                    >
                                      
                                      {this.state.btn_disabled_save_tok == true
                                        ? 'Saving. Please wait.'
                                        : 'Save'}
                                    </button>
                                  </div>
                                </FormGroup>
                              </Col>
                            </Row>
                          </TabPane> */}
                  <TabPane tabId='inputFieldsTabPane' role='tabpanel'>
                    <Row className='top-margin-10' />
                    {inputFields !== null &&
                      typeof inputFields !== 'undefined' &&
                      inputFields.length !== 0 ? (
                      <>
                        <div>
                          <ReactTable
                            data={inputFields}
                            columns={[
                              {
                                Header: '#',
                                accessor: 'key',
                                width: 40
                              },
                              {
                                Header: 'Field Name',
                                accessor: 'field_name'
                              },
                              {
                                Header: 'Field Value',
                                accessor: 'field_value'
                              },
                              {
                                Header: 'Data Type',
                                accessor: 'field_data_type'
                              },
                              {
                                Header: 'Mandatory',
                                accessor: 'field_mandatory'
                              }
                            ]}
                            defaultPageSize={5}
                            showPaginationTop={false}
                            showPaginationBottom={true}
                            showPageSizeOptions={false}
                            className='-striped -highlight primary-pagination'
                          />
                        </div>
                      </>
                    ) : (
                      'There are no input fields attached to this token!'
                    )}
                  </TabPane>
                  <TabPane tabId='attachmentFieldsTabPane' role='tabpanel'>
                    <Row className='top-margin-10' />
                    {fileAttachments !== null &&
                      typeof fileAttachments !== 'undefined' &&
                      fileAttachments.length !== 0 ? (
                      <>
                        <div>
                          <ReactTable
                            data={fileAttachments}
                            columns={[
                              {
                                Header: '#',
                                accessor: 'key',
                                width: 40
                              },
                              {
                                Header: 'Attachment Name',
                                accessor: 'file_attachment_name'
                              },
                              {
                                Header: 'Attachment File Name',
                                accessor: 'file_attachment_file_name'
                              },
                              {
                                Header: 'File Size (Kb)',
                                accessor: 'file_size'
                              },
                              {
                                Header: 'File Type',
                                accessor: 'file_type'
                              },
                              {
                                Header: 'Mandatory',
                                accessor: 'file_mandatory'
                              },
                              {
                                Header: 'Action',
                                accessor: 'action',
                                sortable: false
                              }
                            ]}
                            defaultPageSize={5}
                            showPaginationTop={false}
                            showPaginationBottom={true}
                            showPageSizeOptions={false}
                            className='-striped -highlight primary-pagination'
                          />
                        </div>
                      </>
                    ) : (
                      <>There are no file attachments attached to this token!</>
                    )}
                    <br />
                    {/*<progress
                      id='uploader'
                      value='0'
                      max='100'
                      style={{ display: 'none' }}
                    >
                      0%
                    </progress>
                    <div
                      className='btn'
                      onClick={() => {
                        document.getElementById('filee').click()
                      }}
                    >
                      +
                    </div>
                    <input
                      type='file'
                      style={{ display: 'none' }}
                      id='filee'
                      onChange={e => {
                        var ok = false
                        var ADType = ''
                        e.persist()
                        if (e.target.files[0] != undefined) {
                          if (
                            e.target.files[0].name.split('.')[1] === 'png' ||
                            e.target.files[0].name.split('.')[1] === 'jpg' ||
                            e.target.files[0].name.split('.')[1] === 'jpeg' ||
                            e.target.files[0].name.split('.')[1] === 'webp'
                          ) {
                            ADType = 'IMAGE_ADS'
                          } else if (
                            e.target.files[0].name.split('.')[1] === 'mp4'
                          ) {
                            ADType = 'VIDEO_ADS'
                          }
                        }
                        if (
                          e.target.files[0].name.split('.')[1] === 'pdf' ||
                          e.target.files[0].name.split('.')[1] === 'doc' ||
                          e.target.files[0].name.split('.')[1] === 'docx'
                        ) {
                          ADType = 'PDF_File'
                        }

                        if (
                          ADType == 'IMAGE_ADS' ||
                          ADType == 'VIDEO_ADS' ||
                          ADType == 'PDF_File'
                        ) {
                          // setState({ loading: true })
                          let customer_id = TokenData.customer_id
                          // let sub_location_id =
                          //   state.selected_sub_location.value

                          // Firebase.functions()
                          //   .httpsCallable(
                          //     'getPaymentRestrictionsBasedOnSelectedPackage'
                          //   )({
                          //     sub_location_id: sub_location_id,
                          //     type: ADType,
                          //     antqueue_customer_id: customer_id
                          //   })
                          //   .then(function (result) {
                          // const res = result.data
                          const res = {
                            status: 'ok',
                            data: { countExceedsLimit: false }
                          }
                          // console.log('validateItemCount', {
                          //   result
                          // })
                          if (res.status === 'ok') {
                            var limitExceeded = res.data.countExceedsLimit
                            console.log(
                              'validateItemCount>>> countExceedsLimit',
                              limitExceeded
                            )
                            //   setState({
                            //     loading: false
                            //   })
                            // return limitExceeded;
                            if (limitExceeded === true) {
                              if (ADType == 'IMAGE_ADS') {
                                notifyMessage(
                                  'tc',
                                  3,
                                  'Sorry, the selected package for current sub location does not allow any more Image ADs to be added.'
                                )
                              } else if (ADType == 'VIDEO_ADS') {
                                notifyMessage(
                                  'tc',
                                  3,
                                  'Sorry, the selected package for current sub location does not allow any more Video ADs to be added.'
                                )
                              }
                            } else {
                              // var customer_id = JSON.parse(
                              //   localStorage.getItem('auth_info')
                              // ).customer_id
                              // console.log(
                              //   state.display_id,
                              //   customer_id
                              // )
                              let metadata = {
                                customMetadata: {
                                  customer_id: customer_id,
                                  main_location_id: TokenData.main_loc_id,
                                  sub_location_id: TokenData.sub_loc_id
                                }
                              }
                              console.log(metadata)
                              if (
                                e.target.files[0].size <= 5 * 1024 * 1024 ||
                                e.target.files[0].size <= 5 * 1024 * 1024
                              ) {
                                var task = Firebase.storage().ref(
                                    'Mobile_App_File_Attachments/' +
                                      TokenData.customer_id +
                                      '/' +
                                      userId +
                                      '/' +
                                      e.target.files[0].name
                                  ),
                                  task1 = task.put(e.target.files[0], metadata)

                                // .then(res=>console.log('uploadsuccess'),err=>console.log('uploadfailed...',err))

                                document.getElementById(
                                  'uploader'
                                ).style.display = 'block'

                                task1.on(
                                  'state_changed',
                                  snapshot => {
                                    var percentage =
                                      (snapshot.bytesTransferred /
                                        snapshot.totalBytes) *
                                      100
                                    document.getElementById(
                                      'uploader'
                                    ).value = percentage
                                  },
                                  () => {},
                                  () => {
                                    let j = 0,
                                      ad = [],
                                      o = 0
                                    document.getElementById(
                                      'uploader'
                                    ).style.display = 'none'
                                    //   adsLoad(state.display_id)
                                    set_loading(false)
                                    set_loading_counter(false)
                                    task
                                      .getDownloadURL()
                                      .then(function (downloadURL) {
                                        var idd = uuidv1()
                                        if (
                                          typeof TokenData.file_attachments ==
                                          'object'
                                        )
                                          TokenData.file_attachments.push({
                                            File_Attachment_Name: e.target.files[0].name.split(
                                              '.'
                                            )[0],
                                            File_Attachment_URL: downloadURL,
                                            File_Attachment_File_Name:
                                              'file_' + idd,
                                            id: idd,
                                            File_Attachment_File_Size_In_KB:
                                              e.target.files[0].size / 1024,
                                            File_Attachment_File_Type: e.target.files[0].name.split(
                                              '.'
                                            )[1]
                                          })
                                        else
                                          TokenData.file_attachments = [
                                            {
                                              File_Attachment_Name:
                                                e.target.files[0].name,
                                              File_Attachment_URL: downloadURL
                                            }
                                          ]
                                        Firebase.firestore()
                                          .collection('Token_Details')
                                          .doc(TokenData.id)
                                          .update({
                                            Mobile_App_File_Attachments:
                                              TokenData.file_attachments
                                          })
                                          .then(() => setUpdate(new Date()))
                                      })
                                  }
                                )
                                task1.catch(error => {
                                  console.log(
                                    'token attachment upload error==> ',
                                    error
                                  )
                                })
                              } else {
                                notifyMessage(
                                  'tc',
                                  3,
                                  'attachment size must be less than or equal to ' +
                                    5 +
                                    ' MB for image AD ' +
                                    'and ' +
                                    5 +
                                    ' MB for video AD.'
                                )
                              }
                            }
                          }
                          //   })
                          //   .catch(error => {
                          //     setState({ loading: false })
                          //     console.log('validateItemCount', {
                          //       error
                          //     })
                          //     return error
                          //   })
                        }
                      }}
                    />*/}
                    <TokenAttachFile setUpdate={e => setUpdate(Date.now())} TokenData={TokenData} notifyMessage={notifyMessage} />
                  </TabPane>
                </TabContent>
              </div>
            </>
          </div>
          {/* <div className='modal-footer'>
                    <div className='left-side'>
                      <Button
                        className='btn btn-primary'
                        color='success'
                        disabled={this.state.btn_disabled_approve_tok}
                        onClick={() =>
                          this.handleRequest(tokenData, 'approve_tok', 1)
                        }
                      >
                        Approve <i className='nc-icon nc-check-2' />
                      </Button>
                      <Button
                        className='btn btn-primary'
                        color='danger'
                        disabled={this.state.btn_disabled_reject_tok}
                        onClick={() =>
                          this.handleRequest(tokenData, 'reject_tok', 1)
                        }
                      >
                        Reject <i className='nc-icon nc-simple-remove' />
                      </Button>
                      <Button
                        className='btn btn-default'
                        color='default'
                        disabled={this.state.btn_disabled_mod_cancel}
                        onClick={this.toggleModalClassic}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div> */}
        </>
      )}
      {/* // </Modal> */}
    </>
  )
}

export default TokenModal
