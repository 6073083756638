import React from "react";
import LoadingOverlay from "react-loading-overlay";
import Firebase from "firebase";
import NotificationAlert from "react-notification-alert";
import config from '../../../config';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import {
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Button,
    Form,
    FormGroup,
    Input
} from "reactstrap";

class SubLocationRequestQuotation extends React.Component {
    constructor(props) {
        super(props);
        if (!Firebase.apps.length) {
            Firebase.initializeApp(config);
        }

        this.state = {
            loading: false,
            name: '',
            email: '',
            // contact: '',
            phoneNum: '',
            detail: '',
            receiver_email: '',
            thank_message: '',

            nameState: '',
            emailState: '',
            contactState: '',
            detailState: '',
            numbers_countersState: '',
            numbers_servicesState: '',
            numbers_tokensState: '',
            numbers_displaysState: '',
        };
    }
    componentDidMount() {
        let _this = this;
        _this.setState({loading: true});
        Firebase.firestore().collection('System_Config').doc('Custom_Requirement_Request').get().then(function (response) {
            if (response.exists) {
                _this.setState({receiver_email: response.data().Email});
                _this.setState({thank_message: response.data().Customer_Message});
                _this.setState({loading: false});
            } else {
                _this.setState({loading: false});
                _this.notifyMessage("tc", 3, "Network Error.");
            }
        }).catch(function (err) {
            _this.setState({loading: false});
            _this.notifyMessage("tc", 3, "Network Error.");
        });
    }
    handleSend() {
        if (this.state.nameState === "") {
            this.setState({ nameState: "has-danger" });
        }
        if (this.state.emailState === "") {
            this.setState({ emailState: "has-danger" });
        }
        if (this.state.contactState === "") {
            this.setState({ contactState: "has-danger" });
        }
        if (this.state.numbers_countersState === "") {
            this.setState({ numbers_countersState: "has-danger" });
        }
        if (this.state.numbers_servicesState === "") {
            this.setState({ numbers_servicesState: "has-danger" });
        }
        if (this.state.numbers_tokensState === "") {
            this.setState({ numbers_tokensState: "has-danger" });
        }
        if (this.state.numbers_displaysState === "") {
            this.setState({ numbers_displaysState: "has-danger" });
        }
        if (this.state.detailState === "") {
            this.setState({ detailState: "has-danger" });
        }
        if (this.state.nameState === "has-success" && this.state.emailState === "has-success"
            && this.state.contactState === "has-success" && this.state.numbers_countersState === "has-success" && this.state.numbers_servicesState === "has-success"
            && this.state.numbers_tokensState === "has-success" && this.state.numbers_displaysState === "has-success" && this.state.detailState === "has-success"
            ) {
            let _this = this;
            _this.setState({loading: true});
            let text =  "Name : " + this.state.name + "<br/>" + 
                        "Email : " + this.state.email + "<br/>" +
                        "Contact : " + this.state.phoneNum + "<br/>" + 
                        "Num of Counters : " + this.state.numbers_counters + "<br/>" + 
                        "Num of Services : " + this.state.numbers_services + "<br/>" +
                        "Num of Tokens  : " + this.state.numbers_tokens + "<br/>" + 
                        "Num of Displays  : " + this.state.numbers_displays + "<br/>" + 
                        "Other Details : " + this.state.detail;

            Firebase.functions().httpsCallable('sendMail')({email: this.state.receiver_email, subject: 'Request for Antqueue package quotation.', text: text}).then(function(result) {
                _this.setState({loading: false});
                _this.notifyMessage("tc", 2, _this.state.thank_message);
                window.setTimeout(function() { _this.props.history.goBack() }, 4000);
            }).catch(function (err) {
                _this.setState({loading: false});
                _this.notifyMessage("tc", 3, "Network Error.");
            });
        }
    }
    setPhoneNumber(num) {
        let _this = this;
        let phoneNumFormatted = "+" + num.toString();
        console.log("phoneNumNonFormatted", num, "phoneNumFormatted", phoneNumFormatted);
        if (_this.verifyLength(phoneNumFormatted, 1) && _this.validE164(phoneNumFormatted)) {
            this.setState({ contactState: "has-success" });
            _this.setState({phoneNum: phoneNumFormatted});
        } else {
            _this.setState({ contactState: "has-danger" });
        }
    }
    /// Validate E164 format
    // validE164(num) { /* return /^\+?[1-9]\d{1,14}$/.test(num) */ return /^\+(?:[0-9] ?){6,14}[0-9]$/.test(num); }
    validE164 = value => {
        var phoneRex = /^\+(?:[0-9] ?){6,14}[0-9]$/; // /^\+?[1-9]\d{1,14}$/
        if (phoneRex.test(value)) { return true; }
        return false;
    }
    verifyEmail = value => {
        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return emailRex.test(value);
    };
    verifyLength = (value, length) => {
        return value.length >= length;
    };
    change = (event, stateName, type, stateNameEqualTo) => {
        switch (type) {
            case "email":
                if (this.verifyEmail(event.target.value)) {
                    this.setState({ [stateName + "State"]: "has-success" });
                } else {
                    this.setState({ [stateName + "State"]: "has-danger" });
                }
                break;
            case "length":
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + "State"]: "has-success" });
                } else {
                    this.setState({ [stateName + "State"]: "has-danger" });
                }
                break;
            case "numbers_counters":
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + "State"]: "has-success" });
                } else {
                    this.setState({ [stateName + "State"]: "has-danger" });
                }
                break;
            case "numbers_services":
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + "State"]: "has-success" });
                } else {
                    this.setState({ [stateName + "State"]: "has-danger" });
                }
                break;
            case "numbers_tokens":
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + "State"]: "has-success" });
                } else {
                    this.setState({ [stateName + "State"]: "has-danger" });
                }
                break;
            case "numbers_displays":
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + "State"]: "has-success" });
                } else {
                    this.setState({ [stateName + "State"]: "has-danger" });
                }
                break;
            default:
                break;
        }
        this.setState({ [stateName]: event.target.value });
    };
    notifyMessage = (place, color, text) => {
        var type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }

        var options = {};
        options = {
            place: place,
            message: (
                <div className="text-md-center">
                    <div>
                        <b>{text}</b>
                    </div>
                </div>
            ),
            type: type,
            icon: "now-ui-icons ui-1_bell-53",
            autoDismiss: 3
        };
        if (options !== null) { this.notificationAlert.notificationAlert(options) }
    };
    render() {
        let {
            nameState,
            emailState,
            contactState,
            detailState,
            numbers_countersState,
            numbers_servicesState,
            numbers_tokensState,
            numbers_displaysState,
        } = this.state;
        return (
            <>
                <LoadingOverlay
                  active={this.state.loading}
                  spinner
                  text='Loading'
                  className='content'
                >
                    <NotificationAlert ref={(ref) => this.notificationAlert = ref} />
                    <Row>
                        <Col md="12">
                            <Card className="full-size">
                                <CardHeader>
                                    <CardTitle tag="h4">Sub Location: Request for Quotation</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Col className="ml-auto mr-auto" lg="8">
                                        <Row>
                                            <Col md="3">
                                                <Button
                                                    color="primary"
                                                    onClick={e => this.handleSend()}
                                                    block
                                                >
                                                    Send
                                                </Button>
                                            </Col>
                                            <Col md="3">
                                                <Button
                                                    color="youtube"
                                                    onClick={e => this.props.history.goBack()}
                                                    block
                                                >
                                                    Close
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row className="top-margin-10"/>
                                        <Row>
                                            <Col md="8">
                                                <Form>
                                                    <FormGroup className={`has-label ${nameState}`}>
                                                        <label>Name</label>
                                                        <Input
                                                            type="text"
                                                            onChange={e =>
                                                                this.change(e, "name", "length", 1)
                                                            }
                                                        />
                                                        {this.state.nameState === "has-danger" ? (
                                                            <label className="error">This field is required.</label>
                                                        ) : null}
                                                    </FormGroup>
                                                    <FormGroup className={`has-label ${emailState}`}>
                                                        <label>Email Address *</label>
                                                        <Input
                                                            name="email"
                                                            type="email"
                                                            onChange={e => this.change(e, "email", "email")}
                                                        />
                                                        {this.state.emailState === "has-danger" ? (
                                                            <label className="error">
                                                                Please enter a valid email address.
                                                            </label>
                                                        ) : null}
                                                    </FormGroup>
                                                    <div className={`has-label ${contactState}`} style={{marginBottom: '10px', position: 'relative'}}>
                                                        <label>Enter SMS number (+[Country_Code][Area_Code][Phone_Number]):</label>
                                                        <div style={{height: '10px'}}>
                                                        </div>
                                                        <div style={{textAlign: 'left'}}>
                                                            <PhoneInput
                                                                country={'us'}
                                                                value={this.state.phoneNum}
                                                                onChange={(phoneNum) => this.setPhoneNumber(phoneNum)}
                                                            />
                                                        </div>
                                                        {this.state.contactState === "has-danger" ? (
                                                            <label className="error" style={{color:"#ef8157", paddingTop: "2px"}}>This field is required and must be of correct E164 format (+[Country_Code][Area_Code][Phone_Number]).</label>
                                                        ) : null}
                                                        <div style={{height: '10px'}}>
                                                        </div>
                                                    </div>
                                                    <FormGroup className={`has-label ${numbers_countersState}`}>
                                                        <label>Number of Counters In Sub Location</label>
                                                        <Input
                                                            value={this.state.numbers_counters}
                                                            type="number"
                                                            onChange={e =>
                                                                this.change(e, "numbers_counters", "length", 1)
                                                            }
                                                        />
                                                        {this.state.numbers_countersState === "has-danger" ? (
                                                            <label className="error">This field is required.</label>
                                                        ) : null}
                                                    </FormGroup>
                                                    <FormGroup className={`has-label ${numbers_servicesState}`}>
                                                        <label>Number of Services In Sub Location</label>
                                                        <Input
                                                            type="number"
                                                            onChange={e =>
                                                                this.change(e, "numbers_services", "length", 1)
                                                            }
                                                        />
                                                        {this.state.numbers_servicesState === "has-danger" ? (
                                                            <label className="error">This field is required.</label>
                                                        ) : null}
                                                    </FormGroup>
                                                    <FormGroup className={`has-label ${numbers_tokensState}`}>
                                                        <label>Number of Tokens per Service per Day</label>
                                                        <Input
                                                            type="number"
                                                            onChange={e =>
                                                                this.change(e, "numbers_tokens", "length", 1)
                                                            }
                                                        />
                                                        {this.state.numbers_tokensState === "has-danger" ? (
                                                            <label className="error">This field is required.</label>
                                                        ) : null}
                                                    </FormGroup>
                                                    <FormGroup className={`has-label ${numbers_displaysState}`}>
                                                        <label>Number of Displays per Sub Location</label>
                                                        <Input
                                                            type="number"
                                                            onChange={e =>
                                                                this.change(e, "numbers_displays", "length", 1)
                                                            }
                                                        />
                                                        {this.state.numbers_displaysState === "has-danger" ? (
                                                            <label className="error">This field is required.</label>
                                                        ) : null}
                                                    </FormGroup>
                                                </Form>
                                            </Col>
                                            <Col md="12">
                                                <Form>
                                                    <FormGroup className={`has-label ${detailState}`}>
                                                        <label>Details</label>
                                                        <Input
                                                            type="textarea"
                                                            rows="5"
                                                            onChange={e =>
                                                                this.change(e, "detail", "length", 1)
                                                            }
                                                        />
                                                        {this.state.detailState === "has-danger" ? (
                                                            <label className="error">This field is required.</label>
                                                        ) : null}
                                                    </FormGroup>
                                                </Form>
                                            </Col>
                                        </Row>
                                    </Col>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </LoadingOverlay>
            </>
        );
    }
}

export default SubLocationRequestQuotation;
