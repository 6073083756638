import React, { useCallback, useEffect, useRef } from "react";
import { useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import Firebase from "firebase";
import ReactBSAlert from "react-bootstrap-sweetalert";
import NotificationAlert from "react-notification-alert";
import {
  Col,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Table,
  Button,
} from "reactstrap";
import config from "../../../../config";

export default function Downloads(props) {
  const notificationAlert = useRef(null);
  const [state, setState1] = useState({
    loading: false,
    alert: null,
    role: JSON.parse(localStorage.getItem("auth_info")).role,
    downloadsData: [],
  });
  const setState = (e) => {
    setState1((c) => ({ ...c, ...e }));
  };

  useEffect(() => {
    if (!Firebase.apps.length) {
      Firebase.initializeApp(config);
    }
    getInitData();
  }, []);

  const getInitData = useCallback(async () => {
    setState({
      loading: true,
    });
    let downloadsInfo = await Firebase.firestore()
      .collection("System_Config")
      .doc("Downloads")
      .get();

    downloadsInfo.exists
      ? (downloadsInfo = downloadsInfo.data())
      : (downloadsInfo = {});

    let downloadsData = await Firebase.firestore()
      .collection("Downloads")
      .get();

    downloadsData.exists || downloadsData.docs.length > 0
      ? (downloadsData = downloadsData.docs
          .filter((e) => e.data().Applications?.includes("web_interface"))
          .map((e) => {
            return {
              ...e.data(),
              id: e.id,
            };
          }))
      : (downloadsData = []);

    setState({
      loading: false,
      downloadsData,
      // categories: Object.keys(downloadsInfo.Categories).map(e=>({[downloadsInfo.Categories[e]]:e})),
      // applications: Object.keys(downloadsInfo.Applications).map(e=>({[downloadsInfo.Applications[e]]:e})),

      categories: Object.assign(
        {},
        ...Object.keys(downloadsInfo.Categories).map((e) => ({
          [downloadsInfo.Categories[e]]: e,
        }))
      ),

      // applications: Object.assign(
      //   {},
      //   ...Object.keys(downloadsInfo.Applications).map((e) => ({
      //     [downloadsInfo.Applications[e]]: e,
      //   }))
      // ),

      // categories: Object.keys(downloadsInfo.Categories).map(e => ({
      //   [downloadsInfo.Categories[e]]: e
      // })),
      // applications: Object.keys(downloadsInfo.Applications).map(e => ({
      //   [downloadsInfo.Applications[e]]: e
      // }))
    });
  }, []);

  const warningWithConfirmMessage = (obj) => {
    setState({
      alert: (
        <ReactBSAlert
          warning
          title={`Delete this file: ${obj.Name} ?`}
          onConfirm={() => deleteItem(obj.id)}
          onCancel={() => hideAlert()}
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="info"
          confirmBtnText="Delete"
          cancelBtnText="Cancel"
          showCancel
        ></ReactBSAlert>
      ),
    });
  };
  const deleteItem = async (id) => {
    setState({ loading: true });
    await Firebase.firestore()
      .collection("Downloads")
      .doc(id)
      .delete()
      .catch((e) => {
        throw notifyMessage(
          "tc",
          2,
          "Download File Deletion Failed," + (e.message || e + "")
        );
      });
      hideAlert()
    setState({ loading: false });
    notifyMessage("tc", 2, "File Deleted Successfully");
    setTimeout(() => document.location.reload(), 1000);
  };
  const hideAlert = () => {
    setState({
      alert: null,
    });
  };
  const notifyMessage = (place, color, text) => {
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }

    var options = {};
    options = {
      place: place,
      message: (
        <div className="text-md-center">
          <div>
            <b>{text}</b>
          </div>
        </div>
      ),
      type: type,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 3,
    };
    if (options !== null) {
      notificationAlert.current.notificationAlert(options);
    }
  };

  return (
    <>
      <LoadingOverlay
        active={state.loading}
        spinner
        text="Loading"
        className="content"
      >
        <NotificationAlert ref={notificationAlert} />
        {state.alert}
        <Row>
          <Col md="12">
            <Card className="full-height-page">
              <CardHeader>
                <CardTitle tag="h4">Downloads</CardTitle>
              </CardHeader>
              <CardBody>
                {/* <Row>
                  <Col lg='6'></Col>
                </Row>
                <Row>
                  <Col lg='12'>
                    <Col className='text-left ml-auto mr-auto col-md-12 col-lg-12 col-xl-10'>
                      <h4>Links:</h4>
                    </Col>
                  </Col>
                </Row> */}
                <Row>
                  <Col md="11" className="m-auto">
                    <Row>
                      {state.role == "Site_Admin" ? (
                        <Col md="4" lg="3" className="padding-custom">
                          <Button
                            onClick={(e) => props.history.push("/download/add")}
                            className="btn btn-sm btn-info"
                            block
                          >
                            Add
                          </Button>
                        </Col>
                      ) : (
                        ""
                      )}
                    </Row>
                    {/* <Col className='text-left ml-auto mr-auto col-md-12 col-lg-12 col-xl-10'>
                      Download{' '}
                      <a
                        href='https://drive.google.com/drive/folders/1dIc0_r7UvC29wZ_pVnk4O0bqV3dMj_S9?usp=sharing'
                        rel='noopener noreferrer'
                        target='_blank'
                      >
                        Dispenser APK (Latest Version)
                      </a>
                    </Col> */}
                    <Table>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Details</th>
                          <th className="text-center">Download</th>
                          <th className="text-center">Categories</th>
                          {/* <th className="text-center">Application</th> */}
                          {state.role == "Site_Admin" && (
                            <>
                              <th>Edit</th>
                              <th>Delete</th>
                            </>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {state.downloadsData?.map((d) => (
                          <tr>
                            <td>{d.Name}</td>
                            <td>{d.Details}</td>
                            <td className="text-center">
                              <a
                                className="fa fa-cloud-download"
                                download={d.Name}
                                href={d.Download_Link}
                              ></a>
                            </td>
                            <td className="text-center">
                              {d.Categories.map((e) => (
                                <Bullet txt={state.categories[e]} />
                              ))}
                            </td>
                            {/* <td className="text-center">
                              {d.Applications.map((e) => (
                                <Bullet txt={state.applications[e]} />
                              ))}
                            </td> */}
                            {state.role == "Site_Admin" && (
                              <>
                                <td>
                                  <Button
                                    className="btn-sm fa fa-pen"
                                    color="info"
                                    onClick={() =>
                                      props.history.push(
                                        `/download/edit/${d.id}`
                                      )
                                    }
                                  >
                                    {/* Edit */}
                                  </Button>
                                </td>
                                <td>
                                  <Button
                                    className="btn-sm fa fa-trash"
                                    color="danger"
                                    onClick={() => warningWithConfirmMessage(d)}
                                  ></Button>
                                </td>
                              </>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    {/* (Right click and “Save link as...”) */}
                  </Col>
                </Row>
                {/* <Row> */}
                {/* <Col lg="6"> */}
                {/* <Col className='text-center ml-auto mr-auto col-md-12 col-lg-12 col-xl-10'> */}
                {/* <iframe name='printf' src="https://app.antqueue.com/dynamic_content/DownloadList.htm" frameborder="0" style={{width:'100%',height:'76vh',overflow:'visible'}}></iframe> */}
                {/* </Col> */}
                {/* </Col> */}
                {/* </Row> */}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </LoadingOverlay>
    </>
  );
}

const Bullet = ({ txt }) => (
  <span className="badge badge-pill badge-info">{txt || ""}</span>
);
