import React from "react";
import NotificationAlert from "react-notification-alert";
import Firebase from "firebase";
import LoadingOverlay from "react-loading-overlay";
import Select from "react-select";
import ReactBSAlert from "react-bootstrap-sweetalert";
import disImg from "../../../assets/img/display.png";
import config from "../../../config";
import {
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Button,
    Label,
    Form,
    FormGroup,
    Input
} from "reactstrap";
import displayYellow from "../../../assets/img/display-yellow.png";
import displayRed from "../../../assets/img/display-red.png";

class MainDisplay extends React.Component {
    constructor(props) {
        super(props);
        if (!Firebase.apps.length) {
            Firebase.initializeApp(config);
        }

        this.state = {
            loading: false,
            customer_id: '',
            role: '',

            display_list: [],
            main_location_list: [],
            sub_location_list: [],
            selected_main_location: null,
            selected_sub_location: null,
            alert: null
        };

        // this.functions = null;                                              //functions emulator
        // this.fbStore = null;                                                //functions emulator
    }

    // initializeFirebase = () => {                                            //functions emulator
        // if (!this.functions) {                                              //functions emulator
            // this.functions = Firebase.functions();                          //functions emulator
            // this.functions.useFunctionsEmulator('http://localhost:5001');   //functions emulator
            // this.fbStore = Firebase.firestore();                            //functions emulator
        // }                                                                   //functions emulator
    // }                                                                       //functions emulator

    componentDidMount() {
        // this.initializeFirebase();                                          //functions emulator
        let _this = this
        var  customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;
        _this.setState({customer_id: customer_id});

        this.loadMainLocations();
    }

    loadMainLocations() {
        let _this = this;
        let main_locations = [];
        let customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;
        let email = JSON.parse(localStorage.getItem('auth_info')).email;
        _this.setState({customer_id: customer_id});
        let role = JSON.parse(localStorage.getItem('auth_info')).role;
        _this.setState({role: role});

        Firebase.firestore().collection('Main_Locations').where('Customer_ID', '==', customer_id).get().then(function (response) {
            response.docs.forEach(function (doc) {
                main_locations.push({label: doc.data().Name, value: doc.id});
            });

            _this.setState({main_location_list: main_locations});
            if (main_locations.length > 0) {
                _this.setState({selected_main_location: main_locations[0]});
                _this.loadSubLocationByMain(main_locations[0].value);
            } else {
                _this.setState({loading: false});
            }
        }).catch(function (err) {
            _this.setState({loading: false});
            _this.notifyMessage("tc", 3, "Network error!");
            console.log("loadMainLocations NetworkError1==>", err);
        });
    }
    loadSubLocationByMain(main_id) {
        let _this = this;
        _this.setState({loading: true});
        _this.setState({selected_sub_location: null});
        let sub_locations = [];
        let accessible_locations = [];
        let email = JSON.parse(localStorage.getItem('auth_info')).email;

        Firebase.firestore().collection('Web_App_Users').doc(email).get().then(function (app_info) {
            if (app_info.exists) {
                accessible_locations = app_info.data().Accessible_Locations;
                //console.log("accessible locations:",accessible_locations);
            }
        }).catch(function (err) {
            _this.setState({loading: false});
            _this.notifyMessage("tc", 3, "Network error!");
            console.log("loadSubLocationByMain NetworkError2==>", err);
        });
        let customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;
        Firebase.firestore().collection('Sub_Locations').where('Customer_ID', '==', customer_id).where('Main_Location_ID', '==', main_id).get().then(function (response) {
            response.docs.forEach(function (doc) {
                if (accessible_locations.includes(doc.id)) {
                    sub_locations.push({label: doc.data().Name, value: doc.id, image_url: doc.data().Icon, address: doc.data().Address});
                }
            });

            _this.setState({sub_location_list: sub_locations});
            if (sub_locations.length > 0) {
                let first_one = sub_locations[0];
                _this.setState({selected_sub_location: first_one});
                _this.setState({location_image_url : first_one.image_url});
                _this.setState({location_name : first_one.label });
                _this.setState({location_address : first_one.address});
                _this.loadDisplayListBySub(sub_locations[0].value);
            } else {
                _this.setState({loading: false});
                _this.notifyMessage("tc", 3, "This main location does not have any sub locations.");
            }
        }).catch(function (err) {
            _this.setState({loading: false});
            _this.notifyMessage("tc", 3, "Network error!");
            console.log("loadSubLocationByMain NetworkError3==>", err);
        });
    }
    loadDisplayListBySub(sub_id) {
        let _this = this;
        let customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;
        Firebase.firestore().collection('Displays').where('Customer_ID', '==', customer_id).where('Sub_Location_ID', '==', sub_id).onSnapshot(function (response) {
            let displays = [],i=0;
            console.log(response.docs.length)
            if(response.docs.length>0)
            response.docs.sort().forEach(function (display) {
                // console.log(display)
                var img1 = disImg
                let customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;
                Firebase.firestore().collection('Sub_Locations').doc(display.data().Sub_Location_ID).get().then(e=>{
                    Firebase.firestore().collection('Displays_Status').doc(display.id).get().then(b=>{

                    var  offset = e.data().TimeZone.split('UTC')[1].split(':')[0]
                    var d = new Date();
                    var utc = d.getTime() + (d.getTimezoneOffset() * 60000);
                    var nd = new Date(utc + (3600000*offset)).getTime();

                    if(b.data().Last_Active!=undefined)
                        if((nd-parseInt(b.data().Last_Active))/1000 > 60)
                            img1=displayRed
                        else img1=displayYellow

                    let one = {
                        id: display.id,
                        img:img1,
                        created_date:display.data().Created_Date,
                        name: display.data().Display_Name,
                        files:display.data().ADs_Files,
                    };

                    displays.push(one);
                    console.log(i)
                    console.log(response.docs.length)
                    if(i+1 == response.docs.length){
                        // let sorted = displays.sort(function (a, b) {
                        //     return a.name < b.name ? -1 : 1;
                        // });
                        let sorted = displays.sort((a,b)=>a.name.localeCompare(b.name));
            
                        _this.setState({display_list: sorted});
                        _this.setState({loading: false});
                    }

                    i++
                }).catch(()=>{
                    var  offset = e.data().TimeZone.split('UTC')[1].split(':')[0]
                    var d = new Date();
                    var utc = d.getTime() + (d.getTimezoneOffset() * 60000);
                    var nd = new Date(utc + (3600000*offset)).getTime();

                    // if(b.data().Last_Active!=undefined)
                    //     if((nd-parseInt(b.data().Last_Active))/1000 > 60)
                    //         img1=displayRed
                    //     else img1=displayYellow

                    let one = {
                        id: display.id,
                        img:img1,
                        created_date:display.data().Created_Date,
                        name: display.data().Display_Name,
                        files:display.data().ADs_Files,
                    };

                    displays.push(one);

                    if(i+1 == response.docs.length){
                        let sorted = displays.sort(function (a, b) {
                            return a.created_date < b.created_date ? -1 : 1;
                        });
            
                        _this.setState({display_list: sorted});
                    }
                    _this.setState({loading: false});

                    i++
                })
            })
            });
            else 
                _this.setState({loading: false});
        })
    }

    handleAdd() {
        let _this = this;
        _this.setState({loading: true});
        Firebase.functions().httpsCallable('getPaymentRestrictionsBasedOnSelectedPackage')({
            sub_location_id: _this.state.selected_sub_location.value, 
            type: 'DISPLAYS',
            antqueue_customer_id: _this.state.customer_id,
        }).then(function(result) {
            const res = result.data;
             //console.log('validateItemCount', {result});
            if (res.status === 'ok') {
                var limitExceeded = res.data.countExceedsLimit;
                console.log('validateItemCount>>> countExceedsLimit', limitExceeded);
                _this.setState({loading: false});
                // return limitExceeded;
                if (limitExceeded === true) {
                    _this.notifyMessage("tc", 3, "Sorry, the selected package for current sub location does not allow any more Displays to be added.");
                } else {
                    _this.props.history.push('/display/add');
                }
            }
        })
        .catch((error) => {
            _this.setState({loading: false});
            console.log('validateItemCount', {error});
            return error;
        });
    }

    handleRun(id) {
        this.props.history.push('/display/run/' + id);
    }
    handleEdit(id) {
        this.props.history.push('/display/edit/' + id);
    }
    getDisplays() {
        
        let _this = this,nd=new Date();
        return _this.state.display_list.map(function (prop, key) {
            
            return (
               <> <Row key={key} className='col-12'>
                        <hr/>
                        <Col md='7' className='text-center'>
                            <Row>
                                <Col md="8" sm="8">
                                    <button className="btn btn-block btn-info" onClick={e => _this.handleRun(prop.id)}>
                                        Run 
                                    </button>
                                </Col>
                            </Row>
                            
                            
                            <Row>
                                <Col md="8" sm="8" hidden={!(_this.state.role==="Site_Admin" || _this.state.role==="System_Admin" || _this.state.role==="Location_Admin")}>
                                    <Button
                                        color="warning"
                                        onClick={e => _this.handleEdit(prop.id)}
                                        block
                                    >
                                        Edit
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="8" sm="8" hidden={!(_this.state.role==="Site_Admin" || _this.state.role==="System_Admin" || _this.state.role==="Location_Admin")}>
                                    <Button
                                        color="youtube"
                                        onClick={e => _this.warningWithConfirmMessage(prop)}
                                        block
                                    >
                                        Delete
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col md='4' className='d-flex flex-column justify-content-center'>
                            <Row  className=" text-center">
                                <Col md="8" sm="8">
                                    <img className='width-5 mx-auto'
                                        src={prop.img}
                                        alt="..."
                                    />
                                </Col>
                            </Row>
                            <Row className="text-center font-weight-bold">
                                <Col md="8" sm="8">
                                    <h6 className="col-12 text-center font-weight-bold">{prop.name}</h6>
                                </Col>
                            </Row>
                        </Col>
                </Row>
                <hr/></>
            );
        });
    }
    onChangeMain(e) {
        this.setState({ selected_main_location : e });
        this.loadSubLocationByMain(e.value);
    }
    onChangeSub(e) {
        this.setState({ selected_sub_location : e });
        this.setState({ location_image_url : e.image_url });
        this.setState({ location_name : e.label });
        this.setState({ location_address : e.address });
        this.loadDisplayListBySub(e.value);
    }
    deleteItem(object) {
        var _this = this;
        _this.setState({loading: true});
        console.log('/Media_Gallery/Main_Display_Ads/' + object.id);
        let storageRef = Firebase.storage().ref();
        
        storageRef.child('/Media_Gallery/Main_Display_Ads').child(object.id).listAll().then((r)=>{
            r.items.forEach(i=>{
                console.log(i.name);
                Firebase.storage().ref('Media_Gallery/Main_Display_Ads/').child(object.id+'/'+i.name).delete().catch(error => {
                    console.log('file delete error', error);
                })
                _this.setState({loading: false})
            })
        }, err => {
            console.log('list disp file error==> ', err);
            _this.setState({loading: false})
        })
        Firebase.firestore().collection('Displays').doc(object.id).delete().then(function (res) {
            _this.setState({loading: false});
            _this.successDelete();
        }).catch(function (err) {
            _this.setState({loading: false});
            _this.notifyMessage("tc", 3, "Network Error.");
            console.log("deleteItem NetworkError4==>", err);
        });
    }
    warningWithConfirmMessage = (object) => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block" }}
                    title="Are you sure?"
                    onConfirm={() => this.deleteItem(object)}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="info"
                    cancelBtnBsStyle="danger"
                    confirmBtnText="Yes, delete it!"
                    cancelBtnText="Cancel"
                    showCancel
                >

                </ReactBSAlert>
            )
        });
    };
    successDelete = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block" }}
                    title="Deleted!"
                    onConfirm={() => this.confirmDelete()}
                    onCancel={() => this.confirmDelete()}
                    confirmBtnBsStyle="info"
                >

                </ReactBSAlert>
            )
        });
    };
    confirmDelete = () => {
        this.setState({
            alert: null
        });

        this.loadDisplayListBySub(this.state.selected_sub_location.value);
    };
    hideAlert = () => {
        this.setState({
            alert: null
        });
    };
    notifyMessage = (place, color, text) => {
        var type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }

        var options = {};
        options = {
            place: place,
            message: (
                <div className="text-md-center">
                    <div>
                        <b>{text}</b>
                    </div>
                </div>
            ),
            type: type,
            icon: "now-ui-icons ui-1_bell-53",
            autoDismiss: 3
        };
        if (options !== null) { this.notificationAlert.notificationAlert(options) }
    };
    render() {
        var ok = true,_this=this
        if (this.state.role == "Counter_User" || this.state.role == "Counter_Super" || this.state.role == "Counter_Display_User" || this.state.role == "Dispenser_User"|| this.state.role == "Kiosk_User")
        ok=false
        return (
            <>
                <LoadingOverlay
                    active={this.state.loading}
                    spinner
                    text='Loading'
                    className='content'
                >
                    <NotificationAlert ref={(ref) => this.notificationAlert = ref} />
                    {this.state.alert}
                    <Row>
                        <Col md="12">
                            {ok?<Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Main Display</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Col className="ml-auto mr-auto" xl="8" lg="12" md="12">
                                        <Row>
                                            <Col xl="4" lg="6" md="6" sm="6" hidden={!(_this.state.role==="Site_Admin" || _this.state.role==="System_Admin" || _this.state.role==="Location_Admin")}>
                                                <Button
                                                    color="success"
                                                    onClick={e => this.handleAdd()}
                                                    block
                                                >
                                                    Add Display
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Form>
                                            <Row>
                                                <Col lg="8" xs="12">
                                                    <FormGroup>
                                                        <Label>Main Location</Label>
                                                        <Select
                                                            className="react-select info select-location"
                                                            classNamePrefix="react-select"
                                                            placeholder="Select Main Location"
                                                            name="selectMainLocation"
                                                            value={this.state.selected_main_location}
                                                            onChange={e =>
                                                                this.onChangeMain(e)
                                                            }
                                                            options={this.state.main_location_list}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="8" xs="12">
                                                    <FormGroup>
                                                        <Label>Sub Location</Label>
                                                        <Select
                                                            className="react-select info select-location"
                                                            classNamePrefix="react-select"
                                                            placeholder="Select Sub Location"
                                                            name="selectSubLocation"
                                                            value={this.state.selected_sub_location}
                                                            onChange={e =>
                                                                this.onChangeSub(e)
                                                            }
                                                            options={this.state.sub_location_list}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <hr/>
                                        </Form>
                                        <Row>
                                            <Col xl="12" lg="12" md="12">
                                                {this.getDisplays()}
                                            </Col>
                                        </Row>
                                    </Col>
                                </CardBody>
                            </Card>:null}
                        </Col>
                    </Row>
                </LoadingOverlay>
            </>
        );
    }
}

export default MainDisplay;
