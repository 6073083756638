import React from "react";
import { DateRangePicker } from 'react-dates';
import LoadingOverlay from "react-loading-overlay";
import ReactTable from "react-table-6";
import Select from "react-select";
import ReactBSAlert from "react-bootstrap-sweetalert";
import NotificationAlert from "react-notification-alert";
import {
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    FormGroup,
    Label,
    Modal,
    Input,
    Button
} from "reactstrap";

import Firebase from "firebase";
import { ExportReactCSV } from '../../../components/ExportReactCSV'
import PaymentForm from "../../../components/PaymentForm";

import DateRangePickerWrapper from "../../../components/DateRangePickerWrapper";

class BillingOnlineSubscriptions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            alert: null,
            loading: false,
            data: [],

            subscriptions: [],
            invoices: [],

            startDate: null,
            endDate: null,
            focusedInput: null,

            sub_location: null,
            sub_location_list: [],

            payments: [],
            checkedItem: [],
            selectedSubscriptionID: null,
            selectedSublocationID: null
        };
        this.auth_info = {};
        // this.functions = null;
        // this.fbStore = null;
        this.getSubscriptionData = this.getSubscriptionData.bind(this);
        // this.getInvoiceData = this.getInvoiceData.bind(this);
        this.onChangeStatus = this.onChangeStatus.bind(this);
    }

    componentDidMount() {
        this.auth_info = JSON.parse(localStorage.getItem('auth_info')) || {};
        // this.initializeFirebase();
        this.fetchCustomerInfo();
        this.fetchSubLocationsList();

        // Firebase.functions().httpsCallable('stripe_listCustomerInfo')({ customer_id, sub_location: sub_location?.value, startDate: startDate ? startDate.format("X") : null, endDate: endDate ? endDate.add(1, 'days').format("X") : null })
        //     .then((response) => {
        //         console.log('fetchCustomerInfo', {customer_id, response});
        //         const res = response.data;
        //         if (res.status === 'ok') {
        //             const { subscriptions, invoices } = res.data;
        //             const payments = this.filterByPaymentSubscriptionStatus(subscriptions, invoices);
        //             console.log({payments});
        //             this.setState({ payments, subscriptions, invoices })
        //         }
        //         this.setState({loading: false});
        //     })
        //     .catch((error) => {
        //         this.setState({loading: false});
        //         console.log('fetchCustomerInfo', {error});
        //     });
    }

    componentWillUnmount() {

    }

    componentDidUpdate(e) {

    }

    // initializeFirebase = () => {
    //   if (!this.functions) {
    //     this.functions = Firebase.functions();
    //     // this.functions.useFunctionsEmulator('http://localhost:5001');
    //     this.fbStore = Firebase.firestore();
    //   }
    // }

    toggleModalClassic = () => {
        this.setState({
            modalClassic: !this.state.modalClassic
        });
    };

    fetchSubLocationsList = async () => {
        const { customer_id, email } = this.auth_info || {};

        let accessible_locations = [];
        let sub_locations = [{ label: 'Select All', value: -1, image_url: '', address: '' }];
        try {
            const app_info = await Firebase.firestore().collection('Web_App_Users').doc(email).get();
            if (app_info.exists) {
                accessible_locations = app_info.data().Accessible_Locations;
                //console.log("accessible locations:",accessible_locations);
                // console.log('user-json', JSON.stringify(app_info.data()));
            }
        } catch (ex1) {
            console.log("loadSubLocation NetworkError2==>", ex1);
        }

        try {
            const response = await Firebase.firestore().collection('Sub_Locations').where('Customer_ID', '==', customer_id).get();
            response.docs.forEach(function (doc) {
                if (accessible_locations.includes(doc.id)) {
                    const { Name, Icon, Address } = doc.data();
                    sub_locations.push({ label: Name, value: doc.id, image_url: Icon, address: Address });
                }
            });
            this.setState({
                sub_location_list: sub_locations
            });
        } catch (ex2) {
            console.log("loadSubLocationByMain NetworkError2==>", ex2);
        }
    }
    // handleDateChange(startDate, endDate) {
    //     this.setState({ startDate, endDate }); 
    //     console.log([ startDate?.format("YYYY-MM-DD"), endDate?.format("YYYY-MM-DD") ]); //this.fetchCustomerInfo(); //this.fetchSubLocationsList();
    // }
    // handleDateClose(startDate, endDate) {
    //     this.fetchBillingInvoiceList();
    //     console.log([ startDate?.format("YYYY-MM-DD"), endDate?.format("YYYY-MM-DD") ]);
    // }
    onChangeStatus(e, value, type) {
        switch (type) {
            case "check":
                var checked = e.target.checked;
                var checkedItem = [];
                if (checked) {
                    checkedItem.push(value);
                }
                this.setState({ checkedItem: checkedItem });
                break;
            default:
                break;
        }
        console.log('checkedItem', checkedItem);
    }
    getSubscriptionData() {
        var data = [];
        this.state.subscriptions.map((prop, key) => {
            console.log("prop", prop);
            data.push({
                num: key + 1,
                // check: (
                //     <FormGroup check style={{marginTop: "-10px"}}>
                //         <div className="col-sm-10 checkbox-radios">
                //             <div className="form-check-radio">
                //                 <label className="form-check-label">
                //                     <input className="form-check-input" type="radio" name="subscription" id={prop.id} value={prop.id} checked={this.state.selectedSubscriptionID === prop.id} onChange={e => this.onChangeStatus(e, prop, "check")} />
                //                     <span className="form-check-sign"></span>
                //                 </label>
                //             </div>
                //         </div>
                //     </FormGroup>
                // ),
                expire: prop.expire,
                id: prop.id,
                interval: prop.interval,
                latest_invoice: prop.latest_invoice,
                metadata: prop.metadata,
                month: prop.month,
                package: prop.package,
                reason: prop.reason,
                status: (
                    prop.status === 'active' ?
                        <span className="badge badge-pill badge-success">{prop.status}</span>
                        : <span className="badge badge-pill badge-warning">{prop.status}</span>
                ),
                sublocation: prop.sublocation,
                year: prop.year,
                action: (
                    prop.status === 'active' ?
                        <Button onClick={e => this.warningWithConfirmMessageSubscription(prop)} className="btn btn-warning" style={{ marginTop: '-7px', marginBottom: '-7px' }}>Cancel</Button>
                        : <Button disabled={true} onClick={e => this.warningWithConfirmMessageSubscription(prop)} className="btn btn-warning" style={{ marginTop: '-7px', marginBottom: '-7px' }}>Cancel</Button>
                )
            });
        });
        console.log('data', data);
        return data;
    }
    getInvoiceData() {
        var data = [];
        this.state.invoices.map((prop, key) => {
            console.log("prop", prop);
            data.push({
                num: key + 1,
                // check: (
                //     <FormGroup check style={{marginTop: "-10px"}}>
                //         <div className="col-sm-10 checkbox-radios">
                //             <div className="form-check-radio">
                //                 <label className="form-check-label">
                //                     <input className="form-check-input" type="radio" name="subscription" id={prop.id} value={prop.id} checked={this.state.selectedSubscriptionID === prop.id} onChange={e => this.onChangeStatus(e, prop, "check")} />
                //                     <span className="form-check-sign"></span>
                //                 </label>
                //             </div>
                //         </div>
                //     </FormGroup>
                // ),
                invoice_no: prop.invoice_no,
                invoice_date: prop.invoice_date,
                amount: prop.amount,
                due_date: prop.due_date,
                status: (
                    prop.status === 'paid' ?
                        <span className="badge badge-pill badge-success">{prop.status}</span>
                        : <span className="badge badge-pill badge-warning">{prop.status}</span>
                ),
                action: (
                    <Button className="btn btn-default" style={{ marginTop: '-7px', marginBottom: '-7px' }}><a href={prop.pdf_link}>Download</a></Button>
                )
            });
        });
        console.log('data', data);
        return data;
    }
    fetchCustomerInfo = () => {
        const { customer_id, email } = this.auth_info || {};
        const { sub_location, startDate, endDate } = this.state;

        let sub_location_id = sub_location?.value;
        if (sub_location_id == -1) {
            sub_location_id = null;
        }
        this.setState({ loading: true });
        Firebase.functions().httpsCallable('stripe_listCustomerInfo')({ customer_id, sub_location: sub_location_id, startDate: startDate ? startDate.format("X") : null, endDate: endDate ? endDate.add(1, 'days').format("X") : null })
            .then((response) => {
                console.log('fetchCustomerInfo', { customer_id, response });
                const res = response.data;
                if (res.status === 'ok') {
                    const { subscriptions, invoices } = res.data;
                    const payments = this.filterByPaymentSubscriptionStatus(subscriptions, invoices);
                    console.log('subscriptions', subscriptions);
                    console.log({ payments });
                    this.setState({ payments, subscriptions, invoices });
                }
                this.setState({ loading: false });
            })
            .catch((error) => {
                this.setState({ loading: false });
                console.log('fetchCustomerInfo', { error });
            });
    }

    filterByPaymentSubscriptionStatus = (subscriptions, invoices) => {

        const payments = subscriptions.filter(subscription => {
            return subscription.status != 'active' && subscription.status != 'trialing';
        });
        return payments.slice(Math.max(payments.length - 3, 0));
    };

    // fetchBillingSubscriptions = () => {
    //     const { email } = this.auth_info || {};
    //     const { sub_location } = this.state;

    //     this.setState({loading: true});
    //     Firebase.functions().httpsCallable('stripe_listBillingSubscriptions')({ email, sublocation: sub_location?.value })
    //         .then((response) => {
    //             console.log('fetchBillingSubscriptions', {email, response});
    //             const res = response.data;
    //             if (res.status === 'ok') {
    //                 const { subscriptions, invoices } = res.data;
    //                 this.setState({ subscriptions, invoices })
    //             }
    //             this.setState({loading: false});
    //         })
    //         .catch((error) => {
    //             this.setState({loading: false});
    //             console.log('fetchBillingSubscriptions', {error});
    //         });
    // }

    fetchBillingInvoiceList = () => {
        const { sub_location, startDate, endDate, selected } = this.state;
        const subscription = selected?.original.id;

        let sub_location_id = sub_location?.value;
        if (sub_location_id == -1) {
            sub_location_id = null;
        }
        this.setState({ loading: true });
        const payload = { sublocation: sub_location_id, subscription, startDate: startDate?.format("X"), endDate: endDate?.clone().add(1, 'days').format("X") };
        console.log('stripe_listBillingInvoices', payload);
        Firebase.functions().httpsCallable('stripe_listBillingInvoices')(payload)
            .then((response) => {
                console.log('fetchBillingInvoiceList', { response });
                const res = response.data;
                if (res.status === 'ok') {
                    const { invoices } = res.data;
                    this.setState({ invoices })
                }
                this.setState({ loading: false });
            })
            .catch((error) => {
                this.setState({ loading: false });
                console.log('fetchBillingInvoiceList', { error });
            });
    }

    handlePayNow = (subscription_id) => {
        const { payments, loading } = this.state;
        const subscriptions = payments.filter(e => e.id === subscription_id);
        const subscription = subscriptions ? subscriptions[0] : {};
        const { metadata, latest_invoice } = subscription;
        const { email, interval, packageId, sublocationId } = metadata;

        if (loading) return;

        this.setState({ loading: true });
        Firebase.firestore().collection("Packages").doc(packageId).get().then(package_snapdoc => {
            const { Name, Monthly_Price, Annual_Price } = package_snapdoc.data();
            const price = interval === 'Monthly' ? Monthly_Price : Annual_Price;

            this.setState({
                productSelected: {
                    id: packageId,
                    name: Name,
                    price: '$ ' + price,
                },
                email,
                interval,
                sub_location_id: sublocationId,
                invoice: latest_invoice,

                loading: false,
                modalClassic: true,
            });

            // this.toggleModalClassic();
        });
    };

    handleSubscriptionComplete = () => {
        //
    };

    prepareSubscriptionList = (response) => {
        // let no = 0;
        // response.data.map(subscription => {
        //     no ++;
        //     return {
        //         'sub-locatin-name': subscription.object,
        //         'package': subscription.object,
        //         'unit': subscription.object,
        //         'expire': subscription.days_until_due ? subscription.days_until_due + ' days left' : '-',
        //         'status': subscription.object,
        //     }
        // })
    }

    prepareInvoiceList = (invoices) => {
        //
    }

    onChangeSub(e, type) {
        console.log('onChangeSub', e);
        this.setState({ sub_location: e }, () => {
            // if (type === 'payment') {
            // } else {
            // }
            // this.fetchBillingSubscriptions();
            // this.fetchBillingInvoiceList();
            this.fetchCustomerInfo();
        });
    }

    disableSubscription = (subscriptionID, sublocationID) => {
        let _this = this;
        const { customer_id, email } = _this.auth_info || {};
        const { selectedSubscriptionID, selectedSublocationID } = _this.state;
        console.log('selectedSubscriptionID, selectedSublocationID', selectedSubscriptionID, selectedSublocationID);
        console.log('subscriptionID, sublocationID', subscriptionID, sublocationID);

        _this.setState({ loading: true });
        Firebase.functions().httpsCallable('stripe_cancelSubscription')({ subscriptionId: subscriptionID, sublocationId: sublocationID })
            .then((response) => {
                const res = response.data;
                if (res.status === 'ok') {
                    console.log('>>> FUNC result disableSubscription', { response });
                    _this.setState({ loading: false });
                }
                _this.reloadCurrentWindow();
            })
            .catch((error) => {
                _this.setState({ loading: false });
                console.log('stripe_cancelSubscription', { error });
            });
    }

    reloadCurrentWindow = () => {
        let _this = this;
        _this.hideAlert();
        window.location.reload(false);
    }

    warningWithConfirmMessageSubscription = (object) => {
        let _this = this;
        let title = "Are you sure you want to cancel this subscription?";
        console.log("object.id, object.metadata.sublocationId, object", object.id, object.metadata.sublocationId, object);
        _this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block" }}
                    title={title}
                    onConfirm={() => _this.showSuccessAlertCancelSubscription(object.id, object.metadata.sublocationId)}
                    onCancel={() => _this.hideAlert()}
                    confirmBtnBsStyle="info"
                    cancelBtnBsStyle="danger"
                    confirmBtnText="Yes"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    <div className="text-left" style={{ paddingLeft: "2em" }}>
                        <b className="text-uppercase">ID:</b> {object.id},<br />
                        <b className="text-uppercase">Sublocation:</b> {object.sublocation},<br />
                        <b className="text-uppercase">Package:</b> {object.package},<br />
                        <b className="text-uppercase">Expires:</b> {object.expire},<br />
                        <b className="text-uppercase">Interval:</b> {object.interval}<br />
                        <b className="text-uppercase">Latest Invoice:</b> {object.latest_invoice},<br />
                        <b className="text-uppercase">Status:</b> {object.status},<br />
                    </div>
                </ReactBSAlert>
            )
        });
    };
    showSuccessAlertCancelSubscription = (subscriptionID, sublocationID) => {
        console.log("showSuccessAlertCancelSubscription>>> subscriptionID, sublocationID", subscriptionID, sublocationID);
        let _this = this;
        _this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block" }}
                    title="Subscription was cancelled."
                    onConfirm={() => _this.disableSubscription(subscriptionID, sublocationID)}
                    onCancel={() => _this.disableSubscription(subscriptionID, sublocationID)}
                    confirmBtnBsStyle="info"
                >

                </ReactBSAlert>
            )
        });
    };
    hideAlert = () => {
        this.setState({
            alert: null
        });
    };
    notifyMessage = (place, color, text) => {
        var type;
        switch (color) {
            case 1: type = "primary"; break;
            case 2: type = "success"; break;
            case 3: type = "danger"; break;
            case 4: type = "warning"; break;
            case 5: type = "info"; break;
            default: break;
        }
        var options = {};
        options = {
            place: place,
            message: (
                <div className="text-md-center">
                    <div>
                        <b>{text}</b>
                    </div>
                </div>
            ),
            type: type,
            icon: "now-ui-icons ui-1_bell-53",
            autoDismiss: 3
        };
        if (options !== null) { this.notificationAlert.notificationAlert(options) }
    };

    render() {
        const subscriptions_data = this.getSubscriptionData();
        const {
            payments,
            subscriptions,
            invoice,
            invoices,
            startDate,
            endDate,
            focusedInput,
            sub_location,
            sub_location_list,
            modalClassic,
            productSelected,
            email,
            interval,
            sub_location_id
        } = this.state;

        const { role } = this.auth_info;
        const fileName = 'Subscriptions.csv';
        console.log({ auth_info: this.auth_info, role });

        return (
            <>
                <LoadingOverlay
                    active={this.state.loading}
                    spinner
                    text='Loading'
                    className='content'
                >
                    <NotificationAlert ref={(ref) => this.notificationAlert = ref} />
                    {this.state.alert}

                    <Row>
                        <Col md="12">
                            <Card className="">
                                <CardHeader>
                                    <CardTitle tag="h4">Billing Online Subscriptions</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    {/* <div className="padding_Card card-body">
                                        <div className="blockquote blockquote-primary padding">
                                              <Row>
                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <h6>Billing Profile</h6>
                                                </div>
                                            </Row>
                                            {payments && 
                                            (<Row>
                                                <Col>
                                                {payments.map(payment => 
                                                    (<div key={payment.id} className="payment-alert-notification-wrapper">
                                                        <div className="payment-alert-notification">Payment for {payment.sublocation} for the month of {payment.month}, {payment.year} has been rejected due to: {payment.status}.</div> 
                                                        <div><button className="btn btn-warning btn-block" onClick={() => this.handlePayNow(payment.id)}>Pay Now</button></div>
                                                    </div>)
                                                )}
                                                <Modal
                                                    isOpen={modalClassic}
                                                    toggle={this.toggleModalClassic}
                                                >
                                                    {modalClassic &&
                                                    <PaymentForm
                                                        productSelected={productSelected}
                                                        email={email}
                                                        invoice_no={invoice}
                                                        interval={interval}
                                                        sublocationId={sub_location_id}
                                                        handleSubscriptionComplete={this.handleSubscriptionComplete}
                                                    />
                                                    }
                                                </Modal>
                                                </Col>
                                            </Row>)
                                            }
                                        </div>
                                    </div> */}
                                    <div className="padding_Card card-body">
                                        <div className="blockquote blockquote-primary padding">
                                            <Row>
                                                <Col xl="6" lg="6">
                                                    <h6>Online Subscriptions</h6>
                                                </Col>
                                                <Col xl="6" lg="6">
                                                    {role == 'Site_Admin' || role == 'System_Admin' || role == 'Billing_Admin' ?
                                                        <FormGroup className="flex-horizontal">
                                                            <Label>Sub Location</Label>
                                                            <Select
                                                                className="react-select info flex-item Margin-right--12"
                                                                classNamePrefix="react-select"
                                                                placeholder="Select Sub Location"
                                                                name="selectSubLocation"
                                                                value={sub_location}
                                                                onChange={e =>
                                                                    this.onChangeSub(e, 'payment')
                                                                }
                                                                options={sub_location_list}
                                                            />
                                                            <ExportReactCSV csvData={subscriptions} fileName={fileName} />
                                                        </FormGroup>
                                                        : ''}
                                                </Col>
                                                <Col md="12" lg="12">
                                                    <ReactTable
                                                        data={subscriptions_data}
                                                        columns={[
                                                            //{
                                                            //    Header: "#",
                                                            //    accessor: "check",
                                                            //    sortable: false,
                                                            //},
                                                            {
                                                                Header: "#",
                                                                accessor: "num",
                                                            },
                                                            {
                                                                Header: "Sub location Name",
                                                                accessor: "sublocation"
                                                            },
                                                            {
                                                                Header: "Package",
                                                                accessor: "package"
                                                            },
                                                            {
                                                                Header: "Unit",
                                                                accessor: "interval"
                                                            },
                                                            {
                                                                Header: "Expiry Date",
                                                                accessor: "expire"
                                                            },
                                                            {
                                                                Header: "Subscription Status",
                                                                accessor: "status"
                                                            },
                                                            {
                                                                Header: "Action",
                                                                accessor: "action"
                                                            },
                                                        ]}
                                                        defaultPageSize={5}
                                                        showPaginationTop={false}
                                                        showPaginationBottom={true}
                                                        showPageSizeOptions={false}
                                                        getTrProps={(state, rowInfo) => {
                                                            if (rowInfo && rowInfo.row) {
                                                                return {
                                                                    onClick: (e) => {
                                                                        console.log('rowInfo', { rowInfo });
                                                                        console.log('rowInfo.original.id', rowInfo.original.id);
                                                                        this.setState({
                                                                            selected: rowInfo,
                                                                            selectedSubscriptionID: rowInfo.original.id,
                                                                            selectedSublocationID: rowInfo.original.metadata.sublocationId
                                                                        }, () => {
                                                                            this.fetchBillingInvoiceList();
                                                                        });
                                                                    },
                                                                    style: rowInfo.index === this.state.selected?.index ? {
                                                                        background: '#266dd3', //'#00afec',
                                                                        color: 'white'
                                                                    } : {}
                                                                }
                                                            } else {
                                                                return {}
                                                            }
                                                        }
                                                        }
                                                        // You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                                                        className="-striped -highlight primary-pagination"
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    {/* <div className="padding_Card card-body">
                                        <div className="blockquote blockquote-primary padding">
                                            <Row>
                                                <Col xl="2" lg="2">
                                                    <h6>Payment History</h6>
                                                </Col>
                                                <Col xl="4" lg="4">
                                                    {role=='Site_Admin' || role=='System_Admin' || role=='Billing_Admin'? 
                                                    <DateRangePicker
                                                        <DateRangePicker
                                                        startDate={startDate} // momentPropTypes.momentObj or null,
                                                        endDate={endDate} // momentPropTypes.momentObj or null,
                                                        onDatesChange={({ startDate, endDate }) => { this.handleDateChange(startDate, endDate); }} // PropTypes.func.isRequired,
                                                        onClose={({ startDate, endDate }) => { this.handleDateClose(startDate, endDate); }}
                                                        isOutsideRange={day => false}
                                                        startDateId="startDateId"
                                                        endDateId="endDateId"
                                                        focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                                        onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                                                    ></DateRangePicker>
                                                    :''}
                                                </Col>
                                                <Col xl="6" lg="6">
                                                    {role=='Site_Admin' || role=='System_Admin' || role=='Billing_Admin'? 
                                                    <FormGroup className="flex-horizontal">
                                                        <Label>Sub Location</Label>
                                                        <Select
                                                            className="react-select info flex-item"
                                                            classNamePrefix="react-select"
                                                            placeholder="Select Sub Location"
                                                            name="selectSubLocation"
                                                            value={sub_location}
                                                            onChange={e =>
                                                                this.onChangeSub(e, 'invoice')
                                                            }
                                                            options={sub_location_list}
                                                        />
                                                        <ExportReactCSV csvData={invoices} fileName="invoices.csv" />
                                                    </FormGroup>
                                                    :''}
                                                </Col>
                                                <Col md="12" lg="12">
                                                    <ReactTable
                                                        data={invoices_data}
                                                        columns={[
                                                           {
                                                                Header: "#",
                                                                accessor: "num",
                                                            },
                                                            {
                                                                Header: "Invoice No",
                                                                accessor: "invoice_no",
                                                            },
                                                            {
                                                                Header: "Invoice Date",
                                                                accessor: "invoice_date",
                                                            },
                                                            {
                                                                Header: "Amount",
                                                                accessor: "amount",
                                                            },
                                                            {
                                                                Header: "Due Date",
                                                                accessor: "due_date",
                                                            },
                                                            {
                                                                Header: "Payment Status",
                                                                accessor: "status",
                                                            },
                                                            {
                                                                Header: "Action",
                                                                accessor: "action",
                                                            }
                                                        ]}
                                                        defaultPageSize={5}
                                                        showPaginationTop={false}
                                                        showPaginationBottom={true}
                                                        showPageSizeOptions={false}
                                                        getTrProps={(state, rowInfo) => {
                                                            if (rowInfo && rowInfo.row) {
                                                              return {
                                                                onClick: (e) => {
                                                                  console.log('rowInfo',{rowInfo});
                                                                  this.setState({
                                                                    selected: rowInfo,
                                                                    selectedSubscriptionID: rowInfo.original.id
                                                                  }, ()=> {
                                                                      //this.fetchBillingInvoiceList();
                                                                  })
                                                                },
                                                                style: rowInfo.index === this.state.selected?.index ? {
                                                                  background: '#266dd3', //'#00afec',
                                                                  color: 'white'
                                                                } : {}
                                                              }
                                                            }else{
                                                              return {}
                                                            }
                                                          }
                                                        }
                                                        // You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                                                       className="-striped -highlight primary-pagination"
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    </div> */}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </LoadingOverlay>
            </>
        );
    }
}

export default BillingOnlineSubscriptions;
