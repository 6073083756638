import React from "react";
import LoadingOverlay from "react-loading-overlay";
import Select from "react-select";
import Switch from "react-bootstrap-switch";
import Firebase from "firebase";
import NotificationAlert from "react-notification-alert";
import CreatableSelect from 'react-select/creatable';
import config from "../../../config";
import IconUpload from "../../../components/CustomUpload/IconUpload";
// import { SingleDatePicker } from 'react-dates';
import { DateInput, DatePicker } from '@progress/kendo-react-dateinputs';
import trash from '../../trash-alt-solid.svg'

import {
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Collapse,
    Row,
    Button,
    Form,
    FormGroup,
    Label,
    Input
} from "reactstrap";

import uuidv1 from "uuid/v1";
// import publicIp from "public-ip";
// import geoTz from "geo-tz";
// import moment from "moment-timezone";
// import { d } from '@progress/kendo-date-math';

class BillingManualDocumentAttachmentEdit extends React.Component {
    constructor(props) {
        super(props);
        if (!Firebase.apps.length) {
            Firebase.initializeApp(config);
        }

        this.state = {
            loading: false,
            package_id: '',
            customer_id: '',
            document_id: '',
            document_invoice_reference_id: '',
            prefix1: 'in1_',
            prefix2: 'dm1_',
            document_payment_source: { value: 'Manual_Payment', label: 'Manual_Payment' },
            document_payment_source_options_list: '',
            document_payment_source_disable_selection: true,

            document_created_date_formatted: '',
            general_document_attachment_max_size_in_mb: 1,
            inputKey1: Math.random().toString(36),
            document_pdf_attachment: '',
            document_pdf_attachment_full_name: '',
            document_pdf_attachment_url: '',
            document_remarks: '',
            
            document_type_list: '',
            document_type: { value: 'Payment_Receipt', label: 'Payment_Receipt' },
            customer_idState: '',
            document_invoice_reference_idState: '',
            document_created_dateState: '',
           
            document_pdf_attachmentState: '',
            document_typeState: 'has-success',
            // billing_emailState: '',
            // billing_emailStateMessage: 'This field is required.',
            // billing_nameState: '',
            // billing_addressState: '',
            // billing_email: '',
            // billing_name: '',
            // billing_address: '',
            // billing_email_list: null,
        };
        this.inputFileRef1 = React.createRef();
        // this.onFormSubmit = this.onFormSubmit.bind(this);
    }

    componentDidMount() {
        let _this = this;
        _this.auth_info = JSON.parse(localStorage.getItem('auth_info'));
        let role = _this.auth_info.role;
        let customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;
        let timestamp = Math.round((new Date()). getTime() / 1000);
        let document_id_unformatted = Firebase.firestore().collection('Manual_Document_Attachments').doc().id;
        let document_id = _this.state.prefix2 + document_id_unformatted;
        _this.setState({ customer_id: customer_id, role: role, document_id: document_id, loading: true });
        this.loadData();

        console.log("firebase_document_id", document_id);
        console.log("timestamp", timestamp);
        console.log("document_id", document_id);
        console.log("uuidv1()", uuidv1());
        console.log("generateUuid", this.generateUuid());
        console.log("randomString(24)", this.randomString(24));
    }

    loadData() {
        let _this = this;
        var role = this.auth_info.role;
        _this.setState({ loading: true });

        Firebase.firestore().collection('System_Config').doc('Upload_Limits').get().then(e => {
            _this.setState({
                general_document_attachment_max_size_in_mb: e.data().General_Document_Attachment_Max_Size_In_MB
            })
            console.log("general_document_attachment_max_size_in_mb", e.data().General_Document_Attachment_Max_Size_In_MB);
            _this.setState({ loading: false });
        }, (error) => {
            console.log("System_config error ==> ", error);
        })

        Firebase.firestore().collection('System_Config').doc('Manual_Payments').get().then(function (doc) {
            var document_attachment_types = doc.data().Document_Attachment_Types;

            var document_type_list = [];
            document_attachment_types.forEach(function (type) { var type_one = { value: type, label: type }; document_type_list.push(type_one); });
            _this.setState({document_type_list: document_type_list});
            console.log('document_type_list', document_type_list);
        }).catch(function (err) {
            _this.setState({ loading: false });
            _this.notifyMessage("tc", 3, "Network error!");
            console.log('loadDocumentTypes error', err);
        });
    }

    randomString(length) {
        var result = '';
        var chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
        return result;
    }

    generateUuid() {
        return 'xxxxxxxxxxxxxxxxxxxxxxxx'.replace(/[x]/g, function (c) {
            // return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    // loadBillingInfo = () => {
    //     const _this = this;
    //     const { customer_id } = _this.auth_info;
    //     const document_payment_source = _this.state.document_payment_source.value;
    //     console.log("loadBillingInfo>>> customer_id", customer_id);
    //     console.log("document_payment_source.value>>> ", _this.state.document_payment_source.value);

    //     if (document_payment_source == 'Online_Payment') {
    //         console.log("document_payment_source>>> Online");
    //         Firebase.firestore().collection('Stripe_Customers').where('Antqueue_Customer_ID', '==', customer_id).get().then(result => {
    //             const billing_email_list = [];
    //             result.docs.forEach(stripe_customer => {
    //                 const { Stripe_Billing_User_Email, Stripe_Billing_User_Name, Stripe_Billing_User_Address } = stripe_customer.data();
    //                 const option = {
    //                     id: stripe_customer.id,
    //                     email: Stripe_Billing_User_Email,
    //                     label: Stripe_Billing_User_Email,
    //                     value: stripe_customer.id,
    //                     billing_name: Stripe_Billing_User_Name,
    //                     billing_address: Stripe_Billing_User_Address
    //                 };
    //                 billing_email_list.push(option);
    //             });
    //             _this.setState({ billing_email_list }, () => {
    //                 console.log("_this.state.billing_email_list: ", _this.state.billing_email_list);
    //             });
    //         })
    //             .catch(function (error) {
    //                 _this.setState({ loading: false });
    //                 _this.notifyMessage("tc", 3, "Network error!");
    //                 console.log("handleSave NetworkError9==>", error);
    //             });
    //     } else if (document_payment_source == 'Manual_Payment') {
    //         console.log("document_payment_source>>> Manual");
    //         // Handle Manual Payments
    //     } else {
    //         console.log("document_payment_source>>> ", document_payment_source);
    //     }
    // };

    collapsesToggle = collapse => {
        let openedCollapses = this.state.openedCollapses;
        if (openedCollapses.includes(collapse)) {
            this.setState({
                openedCollapses: openedCollapses.filter(item => item !== collapse)
            });
        } else {
            openedCollapses.push(collapse);
            this.setState({
                openedCollapses: openedCollapses
            });
        }
    };
    async handleAdd() {

        let _this = this;
        _this.setState({ loading: true });

        console.log("handleAdd>>1  customer_idState, document_invoice_reference_idState, document_created_dateState, document_pdf_attachmentState, document_typeState", customer_idState, document_invoice_reference_idState, document_created_dateState, document_pdf_attachmentState, document_typeState);
        const { customer_idState, document_invoice_reference_idState, document_created_dateState, document_pdf_attachmentState, document_typeState } = this.state;
        // const { nameState, billing_emailState, billing_nameState, billing_addressState } = _this.state;
        
        //if (customer_idState === "") { _this.setState({ customer_idState: "has-danger" }); }
        if (document_invoice_reference_idState === "") { _this.setState({ document_invoice_reference_idState: "has-danger" }); }
        if (document_created_dateState === "") { _this.setState({ document_created_dateState: "has-danger" }); }
        if (document_pdf_attachmentState === "") { _this.setState({ document_pdf_attachmentState: "has-danger" }); }
        if (document_typeState === "") { _this.setState({ document_typeState: "has-danger" }); }

        if (//customer_idState === "has-success" && 
            document_invoice_reference_idState === "has-success" &&
            document_created_dateState === "has-success" &&
            document_pdf_attachmentState === "has-success" && document_typeState === "has-success") {
            // if (nameState === "has-success" && billing_emailState === "has-success" && billing_nameState === "has-success" && billing_addressState === "has-success") {
            //const { customer_id } = _this.auth_info;
            // const { billing_email, billing_name, billing_address } = _this.state;

            var now = new Date();

        
            Firebase.firestore().collection('Customers').doc(_this.state.customer_id).get().then(function (own_customer_info) {
                if (own_customer_info.exists) {
                    let new_document_data = {
                        Document_Customer_ID: (_this.state.customer_id).trim(),
                        Document_ID: _this.state.document_id,
                        Document_Invoice_Reference_ID: _this.state.document_invoice_reference_id,
                        Document_Created_Date: _this.state.document_created_date_formatted,
                        Document_Payment_Source: _this.state.document_payment_source.value,
                        Document_PDF_Attachment_URL: _this.state.document_pdf_attachment_url, 
                        Document_Type: _this.state.document_type.value,
                    }
                    Firebase.firestore().collection('Manual_Document_Attachments').doc(_this.state.document_id).set(new_document_data).then(function (response) {
                        _this.setState({ loading: false });
                        console.log("handleAdd fileRef2 success");
                        _this.notifyMessage("tc", 2, "New Document added successfully.");
                        window.setTimeout(function () { _this.props.history.push("/billing/manual_subscriptions") }, 2000);
                    }).catch(function (err) {
                        _this.setState({ loading: false });
                        _this.notifyMessage("tc", 3, "Network error!");
                        console.log("handleAdd NetworkError8==>", err);
                    });
                } else {
                    _this.setState({ loading: false });
                    _this.notifyMessage("tc", 3, "Network error!");
                    console.log("handleAdd NetworkError11");
                }
            }).catch(function (err) {
                _this.setState({ loading: false });
                _this.notifyMessage("tc", 3, "Network error!");
                console.log("handleAdd NetworkError12==>", err);
            });

        } else {
            _this.setState({ loading: false });
            _this.notifyMessage("tc", 3, "Please make sure all required fields are filled.");
        }
    }

    notifyMessage = (place, color, text) => {
        var type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }

        var options = {};
        options = {
            place: place,
            message: (
                <div className="text-md-center">
                    <div>
                        <b>{text}</b>
                    </div>
                </div>
            ),
            type: type,
            icon: "now-ui-icons ui-1_bell-53",
            autoDismiss: 3
        };
        if (options !== null) { this.notificationAlert.notificationAlert(options) }
    };
    // isValidBillingEmail = value => {
    //     let _this = this;
    //     let customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;
    //     Firebase.firestore().collection('Stripe_Customers').where('Stripe_Billing_User_Email', '==', value).get().then(function (response) {
    //         if (response.docs.length >= 1 && response.docs[0].data().Antqueue_Customer_ID !== customer_id) {
    //             console.log(' validEmail = false'); return false;
    //         }
    //         else {
    //             console.log(' validEmail = true'); return true;
    //         }
    //     })
    //         .catch(function (error) {
    //             _this.setState({ loading: false });
    //             _this.notifyMessage("tc", 3, "Network error!");
    //             console.log("isValidBillingEmail NetworkError==>", error);
    //         });

    //     return false;
    // };
    verifyLength = (value, length) => {
        return value.length >= length;
    };
    changeDropDownSelect(value, option) {
        if (option == 'changeDocumentType') { this.setState({ document_typeState: "has-success" }); this.setState({ document_type: value }); }
        //else if (option == 'changeDocumenType') { this.setState({ document_document_typeState: "has-success" }); this.setState({ document_document_type: value }); }
    }
    change = (event, stateName, type, stateNameEqualTo) => {
        switch (type) {
            case "length":
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + "State"]: "has-success" });
                } else {
                    this.setState({ [stateName + "State"]: "has-danger" });
                }
                break;
            case "customer_id":
                var custIDRex = /^0[\d]{11}$/; //0 followed by 11 digits, total 12 digits
                if (custIDRex.test(event.target.value)) {
                    this.setState({ [stateName + "State"]: "has-success" });
                }
                else{
                    this.setState({ [stateName + "State"]: "has-danger" });
                }
                break;
            case "document_created_date":
                var dateString1 = new Date(event.target.value); var year1 = dateString1.getFullYear().toString(); var month1 = dateString1.getMonth() + 1; var day1 = dateString1.getDate();
                var timestamp1 = Date.parse(dateString1); var d1 = new Date(timestamp1);
                if (isNaN(timestamp1) == false && parseInt(year1) >= 2019) {
                    this.setState({ [stateName + "State"]: "has-success", document_created_date_formatted: d1 });
                    console.log("(isNaN(timestamp1) == false)");
                } else {
                    this.setState({ [stateName + "State"]: "has-danger" });
                    console.log("(isNaN(timestamp1) == true)");
                }
                console.log("d1", d1); console.log("event.target.value", event.target.value); console.log("dateString1", dateString1); console.log("day1-month1-year1", day1 + "-" + month1 + "-" + year1);      
                break;
            default:
                break;
        }
        this.setState({ [stateName]: event.target.value });
    };

    // handleBillingEmailChange = (newValue, actionMeta) => {
    //     let _this = this;
    //     if (newValue) {
    //         const { __isNew__, billing_name, billing_address, email, value } = newValue;

    //         const _email_ = __isNew__ ? value : email;
    //         const _name_ = __isNew__ ? '' : billing_name;
    //         const _address_ = __isNew__ ? '' : billing_address;

    //         let customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;
    //         Firebase.firestore().collection('Stripe_Customers').where('Stripe_Billing_User_Email', '==', _email_).get().then(function (response) {
    //             if (response.docs.length >= 1 && response.docs[0].data().Antqueue_Customer_ID !== customer_id) {
    //                 console.log(' validEmail = false');
    //                 _this.setState({ billing_emailState: "has-danger" });
    //                 _this.setState({
    //                     billing_emailStateMessage: "This email address is already being used by another Customer. Please provide a different email address!",
    //                 });
    //             }
    //             else {
    //                 console.log(' validEmail = true');
    //                 _this.setState({
    //                     billing_emailStateMessage: "This field is required.",
    //                     billing_emailState: "has-success",
    //                     selected_stripe_customer: newValue,
    //                     billing_email: _email_,
    //                     billing_name: _name_,
    //                     billing_address: _address_,
    //                 });
    //                 console.log({
    //                     selected_stripe_customer: newValue,
    //                     billing_email: email,
    //                     billing_name: _name_,
    //                     billing_address: _address_,
    //                 });
    //                 _this.change({ target: { value: _name_ || '' } }, "billing_name", "length", 1);
    //                 _this.change({ target: { value: _address_ || '' } }, "billing_address", "length", 1);
    //             }
    //         })
    //             .catch(function (error) {
    //                 _this.setState({ loading: false });
    //                 _this.notifyMessage("tc", 3, "Network error!");
    //                 console.log("isValidBillingEmail NetworkError==>", error);
    //             });

    //         // if (!_this.isValidBillingEmail(_email_)) {
    //         //     _this.setState({ billing_emailState: "has-danger" });
    //         // }
    //         // else {
    //         //     _this.setState({
    //         //         billing_emailState: "has-success",
    //         //         selected_stripe_customer: newValue,
    //         //         billing_email: _email_,
    //         //         billing_name: _name_,
    //         //         billing_address: _address_,
    //         //     });
    //         //     console.log({
    //         //         selected_stripe_customer: newValue,
    //         //         billing_email: email,
    //         //         billing_name: _name_,
    //         //         billing_address: _address_,
    //         //     });
    //         //     _this.change({target: {value:_name_ || ''}}, "billing_name", "length", 1);
    //         //     _this.change({target: {value:_address_ || ''}}, "billing_address", "length", 1);
    //         // }

    //     } else {
    //         _this.setState({
    //             selected_stripe_customer: null,
    //             billing_email: '',
    //             billing_name: '',
    //             billing_address: '',
    //             billing_nameState: '',
    //             billing_addressState: '',
    //         });
    //     }
    // };

    handleAttachment(e, field) {
      
        if (field == "document_pdf_attachment") {
            var _this = this; var ok = false; var customerID = _this.state.customer_id;
            var elementID = ""; var inputFileRef = ""; var fileName = ""; var fileNameWithExt = ""; var attachment_file = ""; var attachment_file_name = "";
            elementID = "uploader1";
            inputFileRef = this.inputFileRef1.current.files;
            fileName = _this.state.document_id;
            attachment_file = inputFileRef[0];

            if (/*e.target.files[0]*/ attachment_file != undefined) {
       
                attachment_file_name = attachment_file.name; /*e.target.files[0]*/
                console.log("attachment_file_name", attachment_file_name);
                console.log("fileName", fileName);
                console.log("attachment_file_name.split('.')[1]", attachment_file_name.split('.')[1]);
                fileNameWithExt = fileName+'.'+attachment_file_name.split('.')[1]; 
                _this.setState({document_pdf_attachment_full_name: fileNameWithExt});
                switch (attachment_file_name.slice((attachment_file_name.lastIndexOf(".") - 1 >>> 0) + 2)) { //attachment_file_name.split('.')[1] 
                    case 'pdf': ok = true; break;
                    case 'png': ok = true; break;
                    case 'jpg': ok = true; break;
                    case 'jpeg': ok = true; break;
                    case 'webp': ok = true; break;
                    default: ok = false; break;
                }
            }
            if (/*e.target.files[0]*/ attachment_file != undefined && ok) {
                var task = "";
                var customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id; let metadata = {};
                console.log("this.state.document_id, customer_id", _this.state.document_id, customer_id);
                metadata = {
                    customMetadata: {
                        invoice_reference_id: _this.state.document_invoice_reference_id,
                        document_id: _this.state.document_id,
                        customer_id: customer_id,
                        document_type: _this.state.document_type.value,
                    }
                };
            
                if (/*e.target.files[0]*/ attachment_file.size <= _this.state.general_document_attachment_max_size_in_mb * 1024 * 1024) {
                    var storageRef1 = Firebase.storage().ref('Payments/Other_Documents/'); console.log("handleAttachment storageRef1", storageRef1);
                    var fileName1 = fileNameWithExt; console.log("handleAttachment fileName1", fileName1);
                    var fileRef1 = storageRef1.child(customerID + '/' + fileName1); console.log("handleAttachment fileRef1", fileRef1);
                    task = fileRef1.put(attachment_file, metadata)
                    /*.then(res=>console.log('uploadsuccess'),err=>console.log('uploadfailed...',err))*/
                    document.getElementById(elementID).style.display = 'block';

                    task.on('state_changed', (snapshot) => {
                        var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        document.getElementById(elementID).value = percentage;

                    }, () => { }, () => {
                        let _this = this, j = 0, ad = [], o = 0
                        _this.setState({ document_pdf_attachmentState: "has-success" });
                         // .then(function (snapshot) {
                            fileRef1.getDownloadURL().then(function (res1) {
                                _this.setState({document_pdf_attachment_url: res1});
                                console.log("handleAttachment document_pdf_attachment_url", res1);
                            })
                        // })
                        document.getElementById(elementID).style.display = 'none';
                    })
                    task.catch(error => {
                        _this.setState({ document_pdf_attachmentState: "has-danger" });
                        console.log('pdf upload error==> ', error);
                    })
                }
                else {
                    _this.notifyMessage("tc", 3, "Pdf attachment size must be less than " + _this.state.general_document_attachment_max_size_in_mb + " MB");
                    _this.setState({ document_pdf_attachmentState: "has-danger" });
                }
            } else {
                console.log("fileName", fileName); console.log("attachment_file", attachment_file);
                _this.setState({ document_pdf_attachmentState: "has-danger" });
            }
        }
    }

    render() {
        var ok = false
        // console.log(this.state.role)
        if (this.state.role == "Site_Admin" || this.state.role == "System_Admin" || this.state.role == "Location_Admin" || this.state.role == "Billing_Admin")
            ok = true
        // let { nameState, billing_emailState, billing_emailStateMessage, billing_nameState, billing_addressState, } = this.state;
        let { customer_idState, document_invoice_reference_idState, document_created_dateState, document_pdf_attachmentState, document_typeState } = this.state; //document_document_typeState

        // const { billing_email_list, billing_name, billing_address } = this.state;

        return (
            <>
                <LoadingOverlay
                    active={this.state.loading}
                    spinner
                    text='Loading'
                    className='content'
                >
                    <NotificationAlert ref={(ref) => this.notificationAlert = ref} />
                    <Row>
                        <Col md="12">
                            {ok ? <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Billing -- Manual Document Attachment Edit</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Col className="ml-auto mr-auto" xl="8" lg="10" md="12">
                                        <Row>
                                            <Col md="3">
                                                <Button
                                                    color="success"
                                                    onClick={e => this.handleAdd()}
                                                    block
                                                >
                                                    Add
                                                </Button>
                                            </Col>
                                            <Col md="3">
                                                <Button
                                                    color="youtube"
                                                    onClick={e => this.props.history.push("/locations")}
                                                    block
                                                >
                                                    Close
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row className="top-margin-10">
                                        </Row>
                                        <Row>
                                            <Col xl="8" lg="12" md="12">
                                                <Form className="form-horizontal">
                                                    {/* <Row>
                                                        <Label md="4">Main Location</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Select
                                                                    className="react-select primary"
                                                                    classNamePrefix="react-select"
                                                                    name="mainLocationSelect"
                                                                    value={this.state.selected_main_location}
                                                                    onChange={value =>
                                                                        this.setState({ selected_main_location: value })
                                                                    }
                                                                    options={this.state.main_location_list}
                                                                    placeholder="Select Main Location"
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row> */}
                                                    <Row>
                                                        <Label md="4">Document Key</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Input
                                                                    type="text"
                                                                    defaultValue={this.state.document_id}
                                                                    disabled
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Customer ID</Label>
                                                        <Col md="8">
                                                            <FormGroup className={`has-label ${customer_idState}`}>
                                                                <Input
                                                                    disabled
                                                                    value={this.state.customer_id}
                                                                    type="text"
                                                                    placeholder="0XXXXXXXXXXX"
                                                                    onChange={e =>
                                                                        this.change(e, "customer_id", "customer_id", 1)
                                                                    }
                                                                />
                                                                {this.state.customer_idState === "has-danger" ? (
                                                                    <label className="error">This field is required.</label>
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Invoice Reference ID</Label>
                                                        <Col md="8">
                                                            <FormGroup className={`has-label ${document_invoice_reference_idState}`}>
                                                                <Input
                                                                    value={this.state.document_invoice_reference_id}
                                                                    type="text"
                                                                    placeholder="Invoice Reference ID"
                                                                    onChange={e =>
                                                                        this.change(e, "document_invoice_reference_id", "length", 1)
                                                                    }
                                                                />
                                                                {this.state.document_invoice_reference_idState === "has-danger" ? (
                                                                    <label className="error">This field is required.</label>
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Created Date</Label>
                                                        <Col md="8">
                                                            <FormGroup className={`has-label ${document_created_dateState}`}>
                                                                <DatePicker
                                                                    format="dd-MM-yyyy"
                                                                    value={this.state.document_created_date}
                                                                    onChange={e =>
                                                                        this.change(e, "document_created_date", "document_created_date")
                                                                    }
                                                                />
                                                                {this.state.document_created_dateState === "has-danger" ? (
                                                                    <label className="error">This field is required.</label>
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row className="top-margin-10" />
                                                    <Row md='12' className='px-0 mx-0'>
                                                        <Label md="4">Document PDF</Label>
                                                        <Col md='8' style={{ paddingLeft: '0px', paddingBottom: '15px' }} >
                                                            <progress id="uploader1" value="0" max="100" style={{ display: 'none' }}>0%</progress>
                                                            <input type="file" id='file1' ref={this.inputFileRef1} key={this.state.inputKey1}
                                                                onChange={e => this.handleAttachment(e, "document_pdf_attachment")}
                                                            />
                                                            {this.state.document_pdf_attachment_full_name != undefined ?
                                                                (this.state.document_pdf_attachment_full_name.length > 0 ?
                                                                    <Row md='12' className='px-0 mx-0'>
                                                                    <img src={trash} style={{ width: '15px', marginTop: '3px' }} alt=""
                                                                        onClick={() => {
                                                                            console.log("this.state.document_pdf_attachment_full_name", this.state.document_pdf_attachment_full_name);
                                                                            var customerID = this.state.customer_id;
                                                                            var task1 = Firebase.storage().ref('Payments/Other_Documents/' + customerID + '/').child(this.state.document_pdf_attachment_full_name).delete();
                                                                            task1.catch(error => { 
                                                                                console.log("task1 error", error);
                                                                            })
                                                                            
                                                                            this.setState({
                                                                                inputKey1: Math.random().toString(36),
                                                                                document_pdf_attachment: '',
                                                                                document_pdf_attachment_full_name: '',
                                                                                document_pdf_attachment_url: '',
                                                                                document_pdf_attachmentState: 'has-danger',
                                                                            })
                                                                        }}
                                                                    /></Row> : null)
                                                                : null}
                                                            {this.state.document_pdf_attachmentState === "has-danger" ? (
                                                                <div className="has-label has-danger position-relative form-group">
                                                                    <label className="error">This field is required.</label>
                                                                </div>
                                                            ) : null}
                                                        </Col>
                                                    </Row>
                                                    <Row className="top-margin-10">
                                                        <Label md="4">Remarks</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Input
                                                                    defaultValue={this.state.document_remarks}
                                                                    type="textarea"
                                                                    onChange={e =>
                                                                        this.change(e, "document_remarks", "length", 1)
                                                                    }
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Payment Source</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Select
                                                                    className="react-select primary"
                                                                    classNamePrefix="react-select"
                                                                    //name="document_payment_sourceSelect"
                                                                    value={this.state.document_payment_source}
                                                                    isDisabled={this.state.document_payment_source_disable_selection}
                                                                    onChange={value => this.changeDropDownSelect(value, "changePaymentSource")}
                                                                    options={this.state.document_payment_source_options_list}
                                                                    placeholder="Select Payment Source"
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Document Type</Label>
                                                        <Col md="8">
                                                            <FormGroup className={`has-label ${document_typeState}`}>
                                                                <Select
                                                                    className="react-select primary"
                                                                    classNamePrefix="react-select"
                                                                    name="document_statusSelect"
                                                                    value={this.state.document_type}
                                                                    onChange={value => this.changeDropDownSelect(value, "changeDocumentType")}
                                                                    options={this.state.document_type_list}
                                                                    placeholder="Select Document Type"
                                                                />
                                                                {this.state.document_typeState === "has-danger" ? (
                                                                    <label className="error">This field is required.</label>
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    {/* <Row>
                                                        <Label md="4">Icon</Label>
                                                        <Col md="8">
                                                            <IconUpload ref="icon" />
                                                        </Col>
                                                    </Row> */}
                                                    {/* <Row>
                                                        <Label md="4">Inactive/Active</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Row className="top-margin-7" />
                                                                <Switch
                                                                    offColor="success"
                                                                    offText={<i className="nc-icon nc-simple-remove" />}
                                                                    onColor="success"
                                                                    onText={<i className="nc-icon nc-check-2" />}
                                                                    defaultValue={this.state.active}
                                                                    value={this.state.active}
                                                                    onChange={e => { this.setState({ active: e.state.value }) }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row> */}
                                                    {/* <Row>
                                                        <Label md="4">Time Zone</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Select
                                                                    className="react-select primary"
                                                                    classNamePrefix="react-select"
                                                                    name="timezoneSelect"
                                                                    value={this.state.time_zone}
                                                                    onChange={value =>
                                                                        this.setState({ time_zone: value })
                                                                    }
                                                                    options={this.state.time_zone_list}
                                                                    placeholder="Select time zone"
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row> */}
                                                    {/* {billing_email_list &&
                                                        <Row>
                                                            <Label md="4">Billing Email</Label>
                                                            <Col md="8">
                                                                <FormGroup className={`has-label ${billing_emailState}`}>
                                                                    <CreatableSelect
                                                                        isClearable
                                                                        // isValidNewOption={this.isValidBillingEmail}
                                                                        onChange={this.handleBillingEmailChange}
                                                                        options={billing_email_list}
                                                                    />
                                                                    {billing_emailState === "has-danger" ? (
                                                                        <label className="error">{`${billing_emailStateMessage}`}</label>
                                                                    ) : null}
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>}
                                                    {billing_email_list &&
                                                        <Row>
                                                            <Label md="4"></Label>
                                                            <Col md="8">
                                                                <FormGroup>
                                                                    <label className="error">If you select an existing billing email address and change related billing information (name and/or address fields below), corresponding billing profile will also be updated. </label>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    } */}
                                                </Form>
                                            </Col>
                                        </Row>
                                    </Col>
                                </CardBody>
                            </Card> : null}
                        </Col>
                    </Row>
                </LoadingOverlay>
            </>
        );
    }
}

export default BillingManualDocumentAttachmentEdit;