import React from "react";
import LoadingOverlay from "react-loading-overlay";
import Switch from "react-bootstrap-switch";
import Select from "react-select";
import ReactDragListView from "react-drag-listview/lib/index.js";
import NotificationAlert from "react-notification-alert";
import Firebase from "firebase";
// import ReactBSAlert from "react-bootstrap-sweetalert";
import ReactTable from "react-table-6";
import {
  DateRangePicker,
  DatePicker,
  DateInput,
} from "@progress/kendo-react-dateinputs";
import moment from "moment-timezone";
import config from "../../../config";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Collapse,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Button,
  Label,
  Modal,
  FormGroup,
  InputGroup,
  Row,
  Col,
  Input,
  InputGroupAddon,
  InputGroupText,
  Spinner,
  ModalHeader,
} from "reactstrap";
import { CSVLink } from "react-csv";
import TokenAttachFile from './TokenAttachFile'

class ServiceRequests extends React.Component {
  constructor(props) {
    super(props);
    if (!Firebase.apps.length) {
      Firebase.initializeApp(config);
    }

    this.state = {
      horizontalTabs: "inputFieldsTabPane",
      dateRangeLimitSeconds: 5184000, // number of seconds in 1 day = 86400s
      // number of seconds in 30 days = (30 X 86400) = 2592000s
      // number of seconds in 60 days = (60 x 86400) = 5184000s
      dateRangeLimitDays: 60,
      loading: false,
      customer_id: "",
      main_location_list: [],
      selected_main_locations: null,
      sub_location_list: [],
      selected_sub_locations: null,
      service_list: [],
      selected_services: null,

      default_serv_icon: null,
      selected_request_approval_groups: null,
      request_approval_group_list: [],
      selected_token_statuses: null,
      token_status_list: [],
      token_details_list: [],

      selected_token: null,
      selected_token_id: "", //internal field
      selected_token_user_id: "", //internal field
      selected_token_time_zone: "", //internal field
      selected_token_input_fields_arr: [], //internal field
      selected_token_file_attachments_arr: [], //internal field

      selected_token_serv_date: "",
      selected_token_serv_date_unix_timestamp: "",
      selected_token_main_loc_list_ALL: [],
      selected_token_main_loc_list: [],
      selected_token_main_loc: [],
      selected_token_sub_loc_list_ALL: [],
      selected_token_sub_loc_list: [],
      selected_token_sub_loc: [],
      selected_token_serv_list_ALL: [],
      selected_token_serv_list: [],
      selected_token_serv: [],
      selected_token_internal_resp: "",
      selected_token_token_status_list_ALL: [],
      selected_token_token_status_list: [],
      selected_token_token_status: [],

      // cancelled tokens
      selected_token_ref_new_tok_id: "",
      selected_token_ref_new_tok_num: "",
      selected_token_ref_cancelled_reason: "",

      selected_token_serv_dateState: "has-success",
      selected_token_main_locState: "",
      selected_token_sub_locState: "",
      selected_token_servState: "",
      selected_token_internal_respState: "has-success",
      selected_token_token_status_respState: "has-success",

      modalClassic: false,
      approving_user_id: "",
      approving_user_name: "",
      approving_user_request_approval_groups: [],

      defaultDate: {},
      bVisualize: false,
      start_date_formatted: "",
      end_date_formatted: "",

      // data: [],
      alert: null,
      hideCustom: true,
      functionsBaseURL: "",
      phoneNum: "",

      btn_disabled_save_tok: false,
      btn_disabled_approve_tok: false,
      btn_disabled_reject_tok: false,
      btn_disabled_mod_cancel: false,
    };

    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.handleChangeMainLoc = this.handleChangeMainLoc.bind(this);
    this.handleChangeSubLoc = this.handleChangeSubLoc.bind(this);
    this.handleChangeService = this.handleChangeService.bind(this);
    this.handleChangeServiceRequestsGroup =
      this.handleChangeServiceRequestsGroup.bind(this);
    this.handleChangeTokenStatus = this.handleChangeTokenStatus.bind(this);
    this.handleChangeModifyTokenFields =
      this.handleChangeModifyTokenFields.bind(this);

    // this.functions = null;                                              //functions emulator
    // this.fbStore = null;                                                //functions emulator
  }

  // initializeFirebase = () => {                                            //functions emulator
  // if (!this.functions) {                                              //functions emulator
  // this.functions = Firebase.functions();                          //functions emulator
  // this.functions.useFunctionsEmulator('http://localhost:5000');//5001   //functions emulator
  // this.fbStore = Firebase.firestore();                            //functions emulator
  // }                                                                   //functions emulator
  // }                                                                       //functions emulator

  componentDidMount() {
    // this.initializeFirebase();                                          //functions emulator
    let _this = this;
    let customer_id = JSON.parse(localStorage.getItem("auth_info")).customer_id;
    _this.setState({ customer_id: customer_id });

    let role = JSON.parse(localStorage.getItem("auth_info")).role;
    if (!_this.state.bVisualize) {
      _this.setState({ loading: true });
      _this.loadApprovingUserDetails();
      _this.loadTokStatuses();
      _this.loadMainLocations(true);
      _this.loadDefaultDate();
    }
    _this.setState({ functionsBaseURL: config.baseURL });
  }

  componentDidUpdate() { }

  toggleModalClassic = () => {
    this.setState({
      loading: false,
      modalClassic: !this.state.modalClassic,
    });
  };

  loadApprovingUserDetails() {
    let _this = this;
    let approving_user_id = JSON.parse(localStorage.getItem("auth_info")).email;
    Firebase.firestore()
      .collection("Web_App_Users")
      .doc(approving_user_id)
      .get()
      .then((e) => {
        if (!e.empty) {
          _this.setState({ approving_user_id: approving_user_id });
          _this.setState({ approving_user_name: e.data().Name });
          _this.setState({
            approving_user_request_approval_groups:
              e.data().Request_Approval_Groups_Assigned,
          });
          // console.log("approving_user_id", approving_user_id);
          // console.log("approving_user_name", e.data().Name);
        } else {
          console.log("couldn't load current user details!");
        }
      });
  }

  loadDefaultDate() {
    let _this = this;
    let today = new Date();
    let y = today.getFullYear();
    let m = today.getMonth();
    let d = today.getDate();

    var dateObject = {
      start: new Date(y, m, 1),
      end: new Date(y, m, d),
    };

    let startDateFmt = _this.formatDate(dateObject.start);
    let endDateFmt = _this.formatDate(dateObject.end);
    startDateFmt = parseInt(moment(startDateFmt, "YYYY-MM-DD").unix());
    //// endDateFmt = parseInt(moment(endDateFmt, 'YYYY-MM-DD').unix());
    endDateFmt = parseInt(moment(endDateFmt, "YYYY-MM-DD").unix()) + 86400; //number of seconds in a day
    console.log(
      "dateObject",
      dateObject,
      "startDateFmt",
      startDateFmt,
      "endDateFmt",
      endDateFmt
    );

    _this.setState({ defaultDate: dateObject });
    _this.setState({ start_date_formatted: startDateFmt });
    _this.setState({ end_date_formatted: endDateFmt });
  }

  loadTokStatuses() {
    let _this = this;
    Firebase.firestore()
      .collection("System_Config")
      .doc("Token_Statuses")
      .get()
      .then(function (resp) {
        var tokStatuses = resp.data().Token_Status_Options;
        var tok_statuses = [];
        tokStatuses.forEach(function (status) {
          tok_statuses.push({
            label: _this.formatTokenStatus(status),
            value: status,
          });
        });
        _this.setState({ token_status_list: tok_statuses });
        _this.setState({ selected_token_statuses: tok_statuses });
        _this.setState({ selected_token_token_status_list_ALL: tok_statuses });
        console.log("token_status_list", tokStatuses);
        console.log("selected_token_statuses", tok_statuses);
      });
  }

  loadMainLocations(firstRun) {
    let _this = this;
    // _this.setState({loading: true});
    let main_locations = [],
      selectedMainLocIds = [];
    var customer_id = JSON.parse(localStorage.getItem("auth_info")).customer_id;
    Firebase.firestore()
      .collection("Main_Locations")
      .where("Customer_ID", "==", customer_id)
      .get()
      .then(function (response) {
        response.docs.forEach(function (doc) {
          main_locations.push({ label: doc.data().Name, value: doc.id });
        });
        if (main_locations.length >= 1) {
          _this.setState({ main_location_list: [] });
          _this.setState({ main_location_list: main_locations });
          if (firstRun == true) {
            _this.setState({
              selected_token_main_loc_list_ALL: main_locations,
            });
            console.log({ selected_token_main_loc_list_ALL: main_locations });
          }
          _this.setState({ selected_main_locations: [] });
          _this.setState({ selected_main_locations: main_locations }); //main_locations[0]
          selectedMainLocIds = main_locations.map((prop, key) => {
            return prop.value;
          });
          if (firstRun == true) {
            _this.loadSubLocationsByMain(selectedMainLocIds, true);
          } else {
            _this.loadSubLocationsByMain(selectedMainLocIds, false);
          }
          // console.log("main_locations", main_locations);
          // console.log("selectedMainLocIds", selectedMainLocIds);
        } else {
          _this.setState({ loading: false });
          _this.notifyMessage("tc", 3, "No main locations found!");
        }
        //var servIconPathDefault = "";
        Firebase.firestore()
          .collection("System_Config")
          .doc("Mobile_App")
          .get()
          .then(function (resp) {
            var servIconPathDefault = resp.data().Default_Icons.Services;
            _this.setState({ default_serv_icon: servIconPathDefault });
            // console.log("here",servIconPathDefault);
          });
      })
      .catch(function (err) {
        _this.setState({ loading: false });
        _this.notifyMessage("tc", 3, "Network error!");
        console.log("loadMainLocations NetworkError0==>", err);
      });
  }

  loadSubLocationsByMain(selectedMainLocIds, firstRun) {
    let _this = this;
    // _this.setState({loading: true});
    // console.log("selectedMainLocIds", selectedMainLocIds);
    let sub_locations = [],
      selectedSubLocIds = [];
    var customer_id = JSON.parse(localStorage.getItem("auth_info")).customer_id;
    if (selectedMainLocIds.length >= 1) {
      Firebase.firestore()
        .collection("Sub_Locations")
        .where("Customer_ID", "==", customer_id)
        .get()
        .then(function (response) {
          response.docs.forEach(function (doc) {
            if (selectedMainLocIds.includes(doc.data().Main_Location_ID)) {
              sub_locations.push({
                label: doc.data().Name,
                value: doc.id,
                main_loc_id: doc.data().Main_Location_ID,
              });
            }
          });
          if (sub_locations.length >= 1) {
            _this.setState({ sub_location_list: [] });
            _this.setState({ sub_location_list: sub_locations });
            if (firstRun == true) {
              _this.setState({
                selected_token_sub_loc_list_ALL: sub_locations,
              });
              console.log({ selected_token_sub_loc_list_ALL: sub_locations });
            }
            _this.setState({ selected_sub_locations: [] });
            _this.setState({ selected_sub_locations: sub_locations }); //sub_locations[0]
            selectedSubLocIds = sub_locations.map((prop, key) => {
              return prop.value;
            });
            if (firstRun == true) {
              _this.loadServicesBySub(selectedSubLocIds, true);
            } else {
              _this.loadServicesBySub(selectedSubLocIds, false);
            }
            _this.loadServiceRequestsGroupsBySub(selectedSubLocIds);
            // console.log("sub_locations", sub_locations);
            // console.log("selectedSubLocIds", selectedSubLocIds);
          } else {
            _this.setState({ loading: false });
            _this.notifyMessage("tc", 3, "No sub locations found!");
          }
        })
        .catch(function (err) {
          _this.setState({ loading: false });
          _this.notifyMessage("tc", 3, "Network error!");
          console.log("loadSubLocationsByMain NetworkError0==>", err);
        });
    }
    // else { _this.setState({loading: false}); _this.notifyMessage("tc", 3, "No sub locations found for selected main location(s)!"); console.log("No sub locations found"); }
  }

  loadServicesBySub(selectedSubLocIds, firstRun) {
    let _this = this;
    // _this.setState({loading: true});
    // console.log("selectedSubLocIds", selectedSubLocIds);
    let services = [],
      selectedServIds = [];
    var customer_id = JSON.parse(localStorage.getItem("auth_info")).customer_id;
    if (selectedSubLocIds.length >= 1) {
      Firebase.firestore()
        .collection("Services")
        .where("Customer_ID", "==", customer_id)
        .get()
        .then(function (response) {
          response.docs.forEach(function (doc) {
            if (selectedSubLocIds.includes(doc.data().Sub_Location_ID)) {
              services.push({
                label: doc.data().Name,
                value: doc.id,
                main_loc_id: doc.data().Main_Location_ID,
                sub_loc_id: doc.data().Sub_Location_ID,
              });
            }
          });
          if (services.length >= 1) {
            _this.setState({ service_list: [] });
            _this.setState({ service_list: services });
            if (firstRun == true) {
              _this.setState({ selected_token_serv_list_ALL: services });
              console.log({ selected_token_serv_list_ALL: services });
            }
            _this.setState({ selected_services: [] });
            _this.setState({ selected_services: services }); //services[0]
            selectedServIds = services.map((prop, key) => {
              return prop.value;
            });
            // console.log("services", services);
            // console.log("selectedServIds", selectedServIds);
          } else {
            _this.setState({ loading: false });
            _this.notifyMessage("tc", 3, "No sub locations found!");
          }
        })
        .catch(function (err) {
          _this.setState({ loading: false });
          _this.notifyMessage("tc", 3, "Network error!");
          console.log("loadServicesBySub NetworkError0==>", err);
        });
    }
    // else { _this.setState({loading: false}); _this.notifyMessage("tc", 3, "No sub locations found for selected main location(s)!"); console.log("No sub locations found"); }
  }

  loadServiceRequestsGroupsBySub(selectedSubLocIds) {
    let _this = this;
    // _this.setState({loading: true});
    // console.log("selectedSubLocIds", selectedSubLocIds);
    let request_approval_groups = [],
      selectedReqAppGrpIds = [];
    var customer_id = JSON.parse(localStorage.getItem("auth_info")).customer_id;
    if (selectedSubLocIds.length >= 1) {
      Firebase.firestore()
        .collection("Request_Approval_Groups")
        .where("Customer_ID", "==", customer_id)
        .get()
        .then(function (response) {
          response.docs.forEach(function (doc) {
            if (selectedSubLocIds.includes(doc.data().Sub_Location_ID)) {
              request_approval_groups.push({
                label: doc.data().Name,
                value: doc.id,
                request_approval_group_id: doc.id,
                counts: doc.data().Service_Count,
                name: doc.data().Name,
                request_approval_group_name: doc.data().Name,
                request_approval_group_description: doc.data().Description,
                customer_id: doc.data().Customer_ID,
                main_location_id: doc.data().Main_Location_ID,
                sub_location_id: doc.data().Sub_Location_ID,
              });
            }
          });
          if (request_approval_groups.length >= 1) {
            // _this.multiSort(request_approval_groups,{ name: 'asc' });
            _this.setState({ request_approval_group_list: [] });
            _this.setState({
              request_approval_group_list: request_approval_groups,
            });
            _this.setState({ selected_request_approval_groups: [] });
            _this.setState({
              selected_request_approval_groups: request_approval_groups,
            }); //request_approval_groups[0]
            selectedReqAppGrpIds = request_approval_groups.map((prop, key) => {
              return prop.value;
            });
            // console.log("request_approval_groups", request_approval_groups);
            // console.log("selectedReqAppGrpIds", selectedReqAppGrpIds);
          } //else { _this.setState({loading: false}); _this.notifyMessage("tc", 3, "No request approval groups found!"); }
          _this.setState({ loading: false });
        })
        .catch(function (err) {
          _this.setState({ loading: false });
          _this.notifyMessage("tc", 3, "Network error!");
          console.log("loadServiceRequestsGroupsBySub NetworkError1==>", err);
        });
    }
    //else { _this.setState({loading: false}); _this.notifyMessage("tc", 3, "No sub locations found for selected main location(s)!"); console.log("No sub locations found"); }
  }

  onVisualize() {
    let _this = this;
    if (!_this.state.bVisualize) {
      let start = _this.state.defaultDate.start,
        end = _this.state.defaultDate.end;
      // let startSec = Date.parse(start) / 1000, endSec = Date.parse(end) / 1000;
      let startSec = Date.parse(start) / 1000,
        endSec = (Date.parse(end) + 86400) / 1000; // number of seconds in 1 day = 86400s
      // let startDateFmt = _this.formatDate(start), endDateFmt = _this.formatDate(end);
      if (endSec - startSec > _this.state.dateRangeLimitSeconds) {
        _this.setState({ loading: false });
        _this.notifyMessage(
          "tc",
          3,
          `Please select a date range less than ${_this.state.dateRangeLimitDays} days!`
        );
      } else {
        _this.setState({ loading: true });
        // // console.log("selected Segmentation Type:", _this.state.segmentationType);
        _this.loadTokenData();
        // _this.subfiltering([], 0);
      }
    }
  }

  async loadTokenData() {
    let _this = this;
    // let token_details_list = [];
    let i = 0;

    let customer_id = JSON.parse(localStorage.getItem("auth_info")).customer_id;
    let startDateFmt = _this.state.start_date_formatted;
    let endDateFmt = _this.state.end_date_formatted;
    let reqApprovalGrpArr = _this.state.approving_user_request_approval_groups;
    console.log(
      "startDateFmt",
      startDateFmt,
      "endDateFmt",
      endDateFmt,
      "reqApprovalGrpArr",
      reqApprovalGrpArr
    );
    let selected_main_locations = _this.state.selected_main_locations;
    let selected_sub_locations = _this.state.selected_sub_locations;
    let selected_services = _this.state.selected_services;
    let selected_request_approval_groups =
      _this.state.selected_request_approval_groups;
    let selected_token_statuses = _this.state.selected_token_statuses;

    let selectedMainLocIds = selected_main_locations.map((prop, key) => {
      return prop.value;
    });
    let selectedSubLocIds = selected_sub_locations.map((prop, key) => {
      return prop.value;
    });
    let selectedServIds = selected_services.map((prop, key) => {
      return prop.value;
    });
    let selectedReqAppGrpIds = [];
    if (
      selected_request_approval_groups !== null &&
      selected_request_approval_groups.length >= 1
    ) {
      selectedReqAppGrpIds = selected_request_approval_groups.map(
        (prop, key) => {
          return prop.value;
        }
      );
    }
    let selectedTokStatuses = selected_token_statuses.map((prop, key) => {
      return prop.value;
    });

    if (
      reqApprovalGrpArr === null ||
      typeof reqApprovalGrpArr == "undefined" ||
      reqApprovalGrpArr.length <= 0
    ) {
      _this.notifyMessage(
        "tc",
        3,
        "Sorry you don't have access to approve any service requests. Please contact your System Administrator"
      );
      _this.setState({ loading: false });
      return;
    }

    let response;
    // const [token_details_snap /*, request_approval_group_snap*/] = await Promise.all([
    //     Firebase.firestore().collection('Token_Details')
    //         .where('Customer_ID', '==', customer_id)
    //         .where('Created_Datetime_Unix_Timestamp', '>=', startDateFmt)
    //         .where('Created_Datetime_Unix_Timestamp', '<=', endDateFmt)
    //         .where('Requires_Approval', '==', true)
    //         .where('Request_Approval_Group_Profile_Id', 'in', reqApprovalGrpArr)
    //         .get(),
    //     // Firebase.firestore().collection('Request_Approval_Groups').where('Customer_ID', '==', customer_id).get(),
    // ]);

    Firebase.firestore()
      .collection("Token_Details")
      .where("Customer_ID", "==", customer_id)
      .where("Created_Datetime_Unix_Timestamp", ">=", startDateFmt)
      .where("Created_Datetime_Unix_Timestamp", "<=", endDateFmt)
      //   .where("Token_Status", "!=", "Pending_Approval")
      // .where('Requires_Approval', '==', true)
      //   .where("Request_Approval_Group_Profile_Id", "in", [
      //     ...reqApprovalGrpArr,
      //     [""],
      //   ])
      .onSnapshot(
        function (response) {
          let token_details_list = [];
          response.docs.forEach(function (tok) {
            console.log({
              tokid: tok.id,
              selectedMainLocIds: selectedMainLocIds,
              selectedSubLocIds: selectedSubLocIds,
              selectedServIds: selectedServIds,
              selectedTokStatuses: selectedTokStatuses,
              selectedReqAppGrpIds: selectedReqAppGrpIds,
              "tok.data().Main_Location_ID": tok.data().Main_Location_ID,
              "tok.data().Sub_Location_ID": tok.data().Sub_Location_ID,
              "tok.data().Services_ID": tok.data().Services_ID,
              "tok.data().Token_Status": tok.data().Token_Status,
              "tok.data().Request_Approval_Group_Profile_Id":
                tok.data().Request_Approval_Group_Profile_Id,
            });
            console.log(
              "here2 selectedReqAppGrpIds.length",
              selectedReqAppGrpIds.length,
              "selectedReqAppGrpIds",
              selectedReqAppGrpIds,
              "tok.data().Request_Approval_Group_Profile_Id",
              tok.data().Request_Approval_Group_Profile_Id
            );
            if (selectedReqAppGrpIds.length >= 1) {
              if (
                selectedMainLocIds.includes(tok.data().Main_Location_ID) &&
                selectedSubLocIds.includes(tok.data().Sub_Location_ID) &&
                selectedServIds.includes(tok.data().Services_ID) &&
                selectedTokStatuses.includes(tok.data().Token_Status) &&
                selectedReqAppGrpIds.includes(
                  tok.data().Request_Approval_Group_Profile_Id
                )
              ) {
                console.log(
                  "path A1 selectedReqAppGrpIds.length",
                  selectedReqAppGrpIds.length
                );
                const option = {
                  token_detail_id: tok.id,
                  id: tok.id,
                  value: tok.id,
                  customer_id: tok.data().Customer_ID,
                  created_datetime: tok.data().Created_Datetime,
                  created_datetime_ts:
                    tok.data().Created_Datetime_Unix_Timestamp,
                  requested_datetime: tok.data().Requested_Datetime,
                  requested_datetime_ts:
                    tok.data().Requested_Datetime_Unix_Timestamp,
                  service_datetime: tok.data().Service_Datetime,
                  service_datetime_formatted: moment(
                    new Date(
                      parseInt(tok.data().Service_Datetime_Unix_Timestamp) *
                      1000
                    )
                  )
                    .tz(tok.data().TimeZone)
                    .format("YYYY-MM-DD"),
                  service_datetime_ts:
                    tok.data().Service_Datetime_Unix_Timestamp,
                  service_date_firestore_timestamp:
                    tok.data().Service_Date_Firestore_Timestamp,
                  internal_response: tok.data().Internal_Response,
                  service_type: tok.data().Service_Type,
                  start_char: tok.data().Start_Character,
                  input_source: tok.data().Input_Source,
                  main_loc_id: tok.data().Main_Location_ID,
                  sub_loc_id: tok.data().Sub_Location_ID,
                  serv_id: tok.data().Services_ID,
                  main_loc_nam: tok.data().Main_Location_Name,
                  sub_loc_nam: tok.data().Sub_Location_Name,
                  serv_name: tok.data().Service_Name,
                  serv_start_date: tok.data().Service_Start_Datetime,
                  token_num: tok.data().Token_Number_String,
                  token_status: tok.data().Token_Status,
                  waiting_time: tok.data().Waiting_Time,
                  mau_id: tok.data().Mobile_App_User_ID,
                  mau_contact: tok.data().Mobile_App_User_Contact,
                  mau_email: tok.data().Mobile_App_User_Email,
                  mau_name: tok.data().Mobile_App_User_Name,
                  time_zone: tok.data().TimeZone,
                  new_tok_ref_id: tok.data().New_Token_Reference_ID,
                  new_tok_ref_num: tok.data().New_Token_Reference_Num,
                  cancelled_tok_ref_id: tok.data().Cancelled_Token_Reference_ID,
                  cancelled_tok_ref_num:
                    tok.data().Cancelled_Token_Reference_Num,
                  new_tok_ref_prev_tok_cancelled_reason:
                    tok.data().Cancelled_Reason,
                  input_fields: tok.data().Mobile_App_Input_Fields,
                  file_attachments: tok.data().Mobile_App_File_Attachments,

                  // label: Name,
                };
                token_details_list.push(option);
              } else {
                console.log(
                  "path A2 selectedReqAppGrpIds.length",
                  selectedReqAppGrpIds.length
                );
              }
            } else {
              if (
                selectedMainLocIds.includes(tok.data().Main_Location_ID) &&
                selectedSubLocIds.includes(tok.data().Sub_Location_ID) &&
                selectedServIds.includes(tok.data().Services_ID) &&
                selectedTokStatuses.includes(tok.data().Token_Status)
              ) {
                console.log(
                  "path B1 selectedReqAppGrpIds.length",
                  selectedReqAppGrpIds.length
                );
                const option = {
                  token_detail_id: tok.id,
                  id: tok.id,
                  value: tok.id,
                  customer_id: tok.data().Customer_ID,
                  created_datetime: tok.data().Created_Datetime,
                  created_datetime_ts:
                    tok.data().Created_Datetime_Unix_Timestamp,
                  requested_datetime: tok.data().Requested_Datetime,
                  requested_datetime_ts:
                    tok.data().Requested_Datetime_Unix_Timestamp,
                  service_datetime: tok.data().Service_Datetime,
                  service_datetime_formatted: moment(
                    new Date(
                      parseInt(tok.data().Service_Datetime_Unix_Timestamp) *
                      1000
                    )
                  )
                    .tz(tok.data().TimeZone)
                    .format("YYYY-MM-DD"),
                  service_datetime_ts:
                    tok.data().Service_Datetime_Unix_Timestamp,
                  service_date_firestore_timestamp:
                    tok.data().Service_Date_Firestore_Timestamp,
                  internal_response: tok.data().Internal_Response,
                  service_type: tok.data().Service_Type,
                  start_char: tok.data().Start_Character,
                  input_source: tok.data().Input_Source,
                  main_loc_id: tok.data().Main_Location_ID,
                  sub_loc_id: tok.data().Sub_Location_ID,
                  serv_id: tok.data().Services_ID,
                  main_loc_nam: tok.data().Main_Location_Name,
                  sub_loc_nam: tok.data().Sub_Location_Name,
                  serv_name: tok.data().Service_Name,
                  serv_start_date: tok.data().Service_Start_Datetime,
                  token_num: tok.data().Token_Number_String,
                  token_status: tok.data().Token_Status,
                  waiting_time: tok.data().Waiting_Time,
                  mau_id: tok.data().Mobile_App_User_ID,
                  mau_contact: tok.data().Mobile_App_User_Contact,
                  mau_email: tok.data().Mobile_App_User_Email,
                  mau_name: tok.data().Mobile_App_User_Name,
                  time_zone: tok.data().TimeZone,
                  new_tok_ref_id: tok.data().New_Token_Reference_ID,
                  new_tok_ref_num: tok.data().New_Token_Reference_Num,
                  cancelled_tok_ref_id: tok.data().Cancelled_Token_Reference_ID,
                  cancelled_tok_ref_num:
                    tok.data().Cancelled_Token_Reference_Num,
                  new_tok_ref_prev_tok_cancelled_reason:
                    tok.data().Cancelled_Reason,
                  input_fields: tok.data().Mobile_App_Input_Fields,
                  file_attachments: tok.data().Mobile_App_File_Attachments,
                  // label: Name,
                };
                token_details_list.push(option);
              } else {
                console.log(
                  "path B2 selectedReqAppGrpIds.length",
                  selectedReqAppGrpIds.length
                );
              }
            }
          });

          // _this.multiSort(token_details_list,{ name: 'asc' });
          _this.setState({ token_details_list: [] });
          _this.setState({ token_details_list: token_details_list });
          _this.setState({ loading: false });
          console.log("token_details_list", token_details_list);
          console.log("reached here #5 done");
        },
        (error) => {
          _this.setState({ loading: false });
          console.log(`Encountered error: ${error}`);
        }
      );
  }

  loadSelectedTokenSubLocsMainLocsServs() { }

  loadSelectedTokenSubLocsByMainLoc(e) {
    try {
      console.log("e", e);
      let _this = this;
      let main_loc_id = e.value;

      // let selectedTokMainLocListALL =  /* no_change */     //_this.state.selected_token_main_loc_list_ALL;
      let selectedTokSubLocListALL =
        _this.state.selected_token_sub_loc_list_ALL;
      // let selectedTokServListALL =     /* no_change */     //_this.state.selected_token_serv_list_ALL;

      // let selectedTokMainLocList =     /* no_change */     //selectedTokMainLocListALL.filter((item, i) => { if (i>= 0) { return item; } }); //ALl main_loc items
      let selectedTokSubLocList = selectedTokSubLocListALL.filter((item, i) => {
        if (item.main_loc_id == main_loc_id) {
          return item;
        }
      });
      // let selectedTokServList =        /* no_change */     //selectedTokServListALL.filter((item, i) => { if (item.sub_loc_id == selectedToken.sub_loc_id) { return item; } });

      if (
        selectedTokSubLocList.length <= 0 ||
        selectedTokSubLocList === null ||
        typeof selectedTokSubLocList == "undefined"
      ) {
        _this.setState({ selected_token_sub_loc_list: [] });
        _this.setState({ selected_token_sub_loc: [] });
        _this.setState({ selected_token_serv_list: [] });
        _this.setState({ selected_token_serv: [] });
        _this.setState({ selected_token_sub_locState: "has-danger" });
        _this.setState({ selected_token_servState: "has-danger" });
        _this.notifyMessage(
          "tc",
          3,
          "There are no sub locations configured under this main location!"
        );
      } else {
        let selectedTokMainLocID = e; //selectedTokMainLocList.filter((item, i) => { return item.value == selectedToken.main_loc_id; });
        let selectedTokSubLocID = selectedTokSubLocList[0]; //selectedTokSubLocList.filter((item, i) => { return item.value == selectedToken.sub_loc_id; });
        // let selectedTokServID =      /* no_change */     //selectedTokServList.filter((item, i) => { return item.value == selectedToken.serv_id; });

        // _this.setState({selected_token_main_loc_list: selectedTokMainLocList});      /* no_change */
        _this.setState({ selected_token_main_loc: selectedTokMainLocID });
        _this.setState({ selected_token_sub_loc_list: selectedTokSubLocList });
        _this.setState({ selected_token_sub_loc: selectedTokSubLocID });
        // _this.setState({selected_token_serv_list: selectedTokServList});             /* no_change */
        // _this.setState({selected_token_serv: selectedTokServID});                    /* no_change */
        _this.setState({ selected_token_main_locState: "has-success" });
        _this.setState({ selected_token_sub_locState: "has-success" });

        // console.log({selected_token_main_loc_list: selectedTokMainLocList});         /* no_change */
        console.log({ selected_token_main_loc: selectedTokMainLocID });
        console.log({ selected_token_sub_loc_list: selectedTokSubLocList });
        console.log({ selected_token_sub_loc: selectedTokSubLocID });
        // console.log({selected_token_serv_list: selectedTokServList});                /* no_change */
        // console.log({selected_token_serv: selectedTokServID});                       /* no_change */
        console.log({ selected_token_main_locState: "has-success" });
        console.log({ selected_token_sub_locState: "has-success" });

        _this.loadSelectedTokenServsBySubLoc(selectedTokSubLocID);
      }
    } catch (err) {
      console.log("an error occured: ", err);
    }
  }

  loadSelectedTokenServsBySubLoc(e) {
    try {
      console.log("e", e);
      let _this = this;
      let sub_loc_id = e.value;

      // let selectedTokMainLocListALL =  /* no_change */     //_this.state.selected_token_main_loc_list_ALL;
      // let selectedTokSubLocListALL =   /* no_change */     //_this.state.selected_token_sub_loc_list_ALL;
      let selectedTokServListALL = _this.state.selected_token_serv_list_ALL;

      // let selectedTokMainLocList =     /* no_change */     //selectedTokMainLocListALL.filter((item, i) => { if (i>= 0) { return item; } }); //ALl main_loc items
      // let selectedTokSubLocList =      /* no_change */     //selectedTokSubLocListALL.filter((item, i) => { if (item.main_loc_id == main_loc_id) { return item; } });
      let selectedTokServList = selectedTokServListALL.filter((item, i) => {
        if (item.sub_loc_id == sub_loc_id) {
          return item;
        }
      });

      if (
        selectedTokServList.length <= 0 ||
        selectedTokServList === null ||
        typeof selectedTokServList == "undefined"
      ) {
        _this.setState({ selected_token_serv_list: [] });
        _this.setState({ selected_token_serv: [] });
        _this.setState({ selected_token_servState: "has-danger" });
        _this.notifyMessage(
          "tc",
          3,
          "There are no services configured under this sub location!"
        );
      } else {
        // let selectedTokMainLocID =   /* no_change */     //selectedTokMainLocList.filter((item, i) => { return item.value == selectedToken.main_loc_id; });
        let selectedTokSubLocID = e; //selectedTokSubLocList[0]; //selectedTokSubLocList.filter((item, i) => { return item.value == selectedToken.sub_loc_id; });
        let selectedTokServID = selectedTokServList[0]; // selectedTokServList.filter((item, i) => { return item.value == selectedToken.serv_id; });

        // _this.setState({selected_token_main_loc_list: selectedTokMainLocList});      /* no_change */
        // _this.setState({selected_token_main_loc: selectedTokMainLocID});             /* no_change */
        // _this.setState({selected_token_sub_loc_list: selectedTokSubLocList});        /* no_change *
        _this.setState({ selected_token_sub_loc: selectedTokSubLocID });
        _this.setState({ selected_token_serv_list: selectedTokServList });
        _this.setState({ selected_token_serv: selectedTokServID });
        _this.setState({ selected_token_sub_locState: "has-success" });
        _this.setState({ selected_token_servState: "has-success" });
        // console.log({selected_token_main_loc_list: selectedTokMainLocList});         /* no_change */
        // console.log({selected_token_main_loc: selectedTokMainLocID});                /* no_change */
        // console.log({selected_token_sub_loc_list: selectedTokSubLocList});           /* no_change *
        console.log({ selected_token_sub_loc: selectedTokSubLocID });
        console.log({ selected_token_serv_list: selectedTokServList });
        console.log({ selected_token_serv: selectedTokServID });
        console.log({ selected_token_sub_locState: "has-success" });
        console.log({ selected_token_servState: "has-success" });
      }
    } catch (err) {
      console.log("an error occured: ", err);
    }
  }

  formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }
    return [year, month, day].join("-");
  }

  // /**
  //  * Sorts an array of objects by column/property.
  //  * @param {Array} array - The array of objects.
  //  * @param {object} sortObject - The object that contains the sort order keys with directions (asc/desc). e.g. { age: 'desc', name: 'asc' }
  //  * @returns {Array} The sorted array.
  //  */
  multiSort(array, sortObject = {}) {
    // 18JUN2020 1547
    const sortKeys = Object.keys(sortObject);
    // Return array if no sort object is supplied.
    if (!sortKeys.length) {
      return array;
    }
    // Change the values of the sortObject keys to -1, 0, or 1.
    for (let key in sortObject) {
      sortObject[key] =
        sortObject[key] === "desc" || sortObject[key] === -1
          ? -1
          : sortObject[key] === "skip" || sortObject[key] === 0
            ? 0
            : 1;
    }
    const keySort = (a, b, direction) => {
      direction = direction !== null ? direction : 1;
      if (a === b) {
        // If the values are the same, do not switch positions.
        return 0;
      }
      // If b > a, multiply by -1 to get the reverse direction.
      return a > b ? direction : -1 * direction;
    };
    return array.sort((a, b) => {
      let sorted = 0;
      let index = 0;
      // Loop until sorted (-1 or 1) or until the sort keys have been processed.
      while (sorted === 0 && index < sortKeys.length) {
        const key = sortKeys[index];
        if (key) {
          const direction = sortObject[key];

          sorted = keySort(a[key], b[key], direction);
          index++;
        }
      }
      return sorted;
    });
  }

  handleChangeDate(event) {
    let _this = this;
    let dateObject = event.target.value;
    console.log("dateObject", dateObject);
    let startDateFmt = _this.formatDate(dateObject.start);
    let endDateFmt = _this.formatDate(dateObject.end);
    startDateFmt = parseInt(moment(startDateFmt, "YYYY-MM-DD").unix());
    // endDateFmt = parseInt(moment(endDateFmt, 'YYYY-MM-DD').unix());
    endDateFmt = parseInt(moment(endDateFmt, "YYYY-MM-DD").unix()) + 86400; //number of seconds in a day
    console.log(
      "handleChangeDate dateObject",
      dateObject,
      "startDateFmt",
      startDateFmt,
      "endDateFmt",
      endDateFmt
    );

    _this.setState({ defaultDate: dateObject }); //this.setState({defaultDate: date});
    _this.setState({ start_date_formatted: startDateFmt });
    _this.setState({ end_date_formatted: endDateFmt });
  }

  handleChangeMainLoc(selectedOptions) {
    let _this = this;
    if (selectedOptions !== null && selectedOptions.length >= 1) {
      let selectedMainLocIds = selectedOptions.map((prop, key) => {
        return prop.value;
      });
      console.log("selectedMainLocIds", selectedMainLocIds);
      _this.setState({ selected_main_locations: [] });
      _this.setState({ selected_main_locations: selectedOptions });
      _this.setState({ selected_sub_locations: [] });
      _this.setState({ selected_services: [] });
      _this.setState({ selected_request_approval_groups: [] });
      _this.loadSubLocationsByMain(selectedMainLocIds, false);
    } else {
      _this.setState({ selected_main_locations: [] });
      _this.setState({ selected_sub_locations: [] });
      _this.setState({ selected_services: [] });
      _this.setState({ selected_request_approval_groups: [] });
    }
  }

  handleChangeSubLoc(selectedOptions) {
    let _this = this;
    if (selectedOptions !== null && selectedOptions.length >= 1) {
      let selectedSubLocIds = selectedOptions.map((prop, key) => {
        return prop.value;
      });
      console.log("selectedSubLocIds", selectedSubLocIds);
      _this.setState({ selected_sub_locations: [] });
      _this.setState({ selected_sub_locations: selectedOptions });
      _this.setState({ selected_services: [] });
      _this.setState({ selected_request_approval_groups: [] });
      _this.loadServicesBySub(selectedSubLocIds, false);
      _this.loadServiceRequestsGroupsBySub(selectedSubLocIds);
    } else {
      _this.setState({ selected_sub_locations: [] });
      _this.setState({ selected_services: [] });
      _this.setState({ selected_request_approval_groups: [] });
    }
  }

  handleChangeService(selectedOptions) {
    let _this = this;
    if (selectedOptions !== null && selectedOptions.length >= 1) {
      let selectedServIds = selectedOptions.map((prop, key) => {
        return prop.value;
      });
      console.log("selectedServIds", selectedServIds);
      _this.setState({ selected_services: [] });
      _this.setState({ selected_services: selectedOptions });
    } else {
      _this.setState({ selected_services: [] });
    }
  }

  handleChangeServiceRequestsGroup(selectedOptions) {
    let _this = this;
    if (selectedOptions !== null && selectedOptions.length >= 1) {
      let selectedReqAppGrpIds = selectedOptions.map((prop, key) => {
        return prop.value;
      });
      console.log("selectedReqAppGrpIds", selectedReqAppGrpIds);
      _this.setState({ selected_request_approval_groups: [] });
      _this.setState({ selected_request_approval_groups: selectedOptions });
      // _this.loadTokenData(selectedReqAppGrpIds);
    } else {
      _this.setState({ selected_request_approval_groups: [] });
    }
  }

  handleChangeTokenStatus(selectedOptions) {
    let _this = this;
    if (selectedOptions !== null && selectedOptions.length >= 1) {
      let selectedTokStatuses = selectedOptions.map((prop, key) => {
        return prop.value;
      });
      console.log("selectedTokStatuses", selectedTokStatuses);
      _this.setState({ selected_token_statuses: [] });
      _this.setState({ selected_token_statuses: selectedOptions });
      // _this.loadTokenData(selectedTokStatuses);
    } else {
      _this.setState({ selected_token_statuses: [] });
    }
  }

  // onClickClose() {
  //     this.setState({modalClassic: true});
  // }

  handleClose() {
    if (this.state.close_format === "pin") {
      if (parseInt(this.state.pin) === this.state.own_pin) {
        localStorage.removeItem("running_dispenser");
        this.props.history.goBack();
      } else {
        this.notifyMessage("tc", 3, "Pin is incorrect");
      }
    } else {
    }
  }

  formatTokenStatus(str) {
    var splitStr = str.toLowerCase().split("_");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }

  getTokenDetails() {
    let _this = this;
    var data = [];
    _this.state.token_details_list.map((prop, key) => {
      // var inputSource = '';
      // if (prop.input_source === 'Android_Dispenser_API') { inputSource = 'Dispenser'}
      // else if (prop.input_source === 'Mobile_App') { inputSource = 'Mobile App'}
      // else if (prop.input_source === 'Web_Interface') { inputSource = 'Web Interface'}

      var tokStatus = "";
      tokStatus = _this.formatTokenStatus(prop.token_status);

      // if (prop.token_status === 'Closed') { tokStatus = 'Closed'}
      // else if (prop.token_status === 'Served') { tokStatus = 'Served'}
      // else if (prop.token_status === 'Now_Serving') { tokStatus = 'Now Serving'}
      // else if (prop.token_status === 'No_Show') { tokStatus = 'No Show'}
      // else { tokStatus = prop.token_status}

      var mauID = "";
      var mauContact = "";
      var mauEmail = "";
      var mauName = "";
      if (
        typeof prop.mau_id !== "undefined" &&
        prop.mau_id !== null &&
        prop.mau_id !== ""
      ) {
        mauID = prop.mau_id;
        mauContact = prop.mau_contact;
        mauEmail = prop.mau_email;
        mauName = prop.mau_name;
      }

      data.push({
        key: key + 1,
        name: prop.name,
        // token_detail_id: prop.token_detail_id,
        // main_loc_nam: prop.main_loc_nam,
        sub_loc_nam: prop.sub_loc_nam,
        requested_datetime:
          prop.requested_datetime?.split("UTC")[0] || prop.requested_datetime,
        service_datetime:
          prop.service_datetime?.split("UTC")[0] || prop.service_datetime,
        serv_name: prop.serv_name,
        // input_source:
        //             (
        //                 (prop.input_source === 'Android_Dispenser_API') ||  (prop.input_source === 'Web_Interface') ?
        //                     <span className="badge badge-pill badge-info">{inputSource}</span>
        //                     : <span className="badge badge-pill badge-success">{inputSource}</span>
        //             ),
        mobile_app_user:
          typeof prop.mau_id !== "undefined" &&
            prop.mau_id !== null &&
            prop.mau_id !== ""
            ? mauName + " / " + mauEmail + " / " + mauContact
            : "",
        result: "",
        message: "",
        start_char: prop.start_char,
        token_num: prop.token_num,
        new_tok_ref_num:
          prop.new_tok_ref_id !== null &&
            typeof prop.new_tok_ref_id !== "undefined" &&
            prop.new_tok_ref_id !== ""
            ? prop.new_tok_ref_num
            : "N/A",
        token_string_status: prop.token_status || '',
        token_status:
          prop.token_status === "Closed" ||
            prop.token_status === "Cancelled" ||
            prop.token_status === "No_show" ||
            prop.token_status === "No_Show" ||
            prop.token_status === "Rejected" ? (
            <span className="badge badge-pill badge-danger">{tokStatus}</span>
          ) : prop.token_status === "Pending" ||
            prop.token_status === "Pending_Approval" ? (
            <span className="badge badge-pill badge-warning">{tokStatus}</span>
          ) : prop.token_status === "Now_Serving" ||
            prop.token_status === "Approved_Pending_Service" ? (
            <span className="badge badge-pill badge-info">{tokStatus}</span>
          ) : prop.token_status === "Served" ? (
            <span className="badge badge-pill badge-success">{tokStatus}</span>
          ) : (
            <span className="badge badge-pill badge-default">{tokStatus}</span>
          ),
        action: (
          <>
            <Button
              size="xs"
              className="btn btn-primary btn-sm"
              color="info"
              onClick={() => this.showTokenDetails(prop)}
            >
              <i className="nc-icon nc-alert-circle-i" />
            </Button>
            {/* <Button
              size="xs"
              className="btn btn-primary btn-sm"
              color="success"
              onClick={() => this.handleRequest(prop, "approve_tok", 0)}
            >
              <i className="nc-icon nc-check-2" />
            </Button>
            <Button
              size="xs"
              className="btn btn-primary btn-sm"
              color="danger"
              onClick={() => this.handleRequest(prop, "reject_tok", 0)}
            >
              <i className="nc-icon nc-simple-remove" />
            </Button> */}
          </>
        ),
      });
    });

    return data;
  }

  showTokenDetails(selectedToken) {
    let _this = this;
    let tokID = selectedToken.token_detail_id;

    let selectedTokId = tokID;
    let selectedTokTokenStatus = {
      label: _this.formatTokenStatus(selectedToken.token_status),
      value: selectedToken.token_status,
    };
    let selectedTokUserId = selectedToken.mau_id;
    let selectedTokTimeZone = selectedToken.time_zone;
    let selectedTokInputFieldsArr = selectedToken.input_fields;
    let selectedTokFileAttachmentsArr = selectedToken.file_attachments;

    let selectedTokServDate = new Date(
      selectedToken.service_datetime_ts * 1000
    );
    let selectedTokServDateFormatted = selectedToken.service_datetime_formatted;
    let selectedTokServDateUnixTS = parseInt(selectedToken.service_datetime_ts);

    console.log(
      "selectedTokServDate",
      selectedTokServDate,
      "selectedTokServDateFormatted",
      selectedTokServDateFormatted,
      "selectedTokServDateUnixTS",
      selectedTokServDateUnixTS
    );

    let selectedTokMainLocListALL =
      _this.state.selected_token_main_loc_list_ALL;
    let selectedTokSubLocListALL = _this.state.selected_token_sub_loc_list_ALL;
    let selectedTokServListALL = _this.state.selected_token_serv_list_ALL;
    let selectedTokTokenStatusALL =
      _this.state.selected_token_token_status_list_ALL;

    let selectedTokMainLocList = selectedTokMainLocListALL.filter((item, i) => {
      if (i >= 0) {
        return item;
      }
    }); //ALl main_loc items
    let selectedTokSubLocList = selectedTokSubLocListALL.filter((item, i) => {
      if (item.main_loc_id == selectedToken.main_loc_id) {
        return item;
      }
    });
    let selectedTokServList = selectedTokServListALL.filter((item, i) => {
      if (item.sub_loc_id == selectedToken.sub_loc_id) {
        return item;
      }
    });
    let selectedTokTokenStatusList = selectedTokTokenStatusALL;

    if (
      selectedTokMainLocList.length <= 0 ||
      selectedTokMainLocList === null ||
      typeof selectedTokMainLocList == "undefined"
    ) {
      _this.setState({ selected_token_main_loc_list: [] });
      _this.setState({ selected_token_main_loc: [] });
      _this.setState({ selected_token_sub_loc_list: [] });
      _this.setState({ selected_token_sub_loc: [] });
      _this.setState({ selected_token_serv_list: [] });
      _this.setState({ selected_token_serv: [] });
      _this.setState({ selected_token_main_locState: "has-danger" });
      _this.setState({ selected_token_sub_locState: "has-danger" });
      _this.setState({ selected_token_servState: "has-danger" });
      _this.notifyMessage("tc", 3, "There are no main locations configured!");
      return;
    }

    if (
      selectedTokSubLocList.length <= 0 ||
      selectedTokSubLocList === null ||
      typeof selectedTokSubLocList == "undefined"
    ) {
      _this.setState({ selected_token_sub_loc_list: [] });
      _this.setState({ selected_token_sub_loc: [] });
      _this.setState({ selected_token_serv_list: [] });
      _this.setState({ selected_token_serv: [] });
      _this.setState({ selected_token_sub_locState: "has-danger" });
      _this.setState({ selected_token_servState: "has-danger" });
      _this.notifyMessage(
        "tc",
        3,
        "There are no sub locations configured under this main location!"
      );
      return;
    }

    if (
      selectedTokServList.length <= 0 ||
      selectedTokServList === null ||
      typeof selectedTokServList == "undefined"
    ) {
      _this.setState({ selected_token_serv_list: [] });
      _this.setState({ selected_token_serv: [] });
      _this.setState({ selected_token_servState: "has-danger" });
      _this.notifyMessage(
        "tc",
        3,
        "There are no services configured under this sub location!"
      );
      return;
    }

    let selectedTokMainLocID = selectedTokMainLocList.filter((item, i) => {
      return item.value == selectedToken.main_loc_id;
    });
    let selectedTokSubLocID = selectedTokSubLocList.filter((item, i) => {
      return item.value == selectedToken.sub_loc_id;
    });
    let selectedTokServID = selectedTokServList.filter((item, i) => {
      return item.value == selectedToken.serv_id;
    });

    let selectedTokInternalResp = selectedToken.internal_response;
    let selectedTokRefNewTokID = selectedToken.new_tok_ref_id;
    let selectedTokRefNewTokNum = selectedToken.new_tok_ref_num;
    let selectedTokRefCancelledReason =
      selectedToken.new_tok_ref_prev_tok_cancelled_reason;

    _this.setState({ selected_token_id: selectedTokId }); //internal field
    _this.setState({ selected_token_user_id: selectedTokUserId }); //internal field
    _this.setState({ selected_token_time_zone: selectedTokTimeZone }); //internal field
    _this.setState({
      selected_token_input_fields_arr: selectedTokInputFieldsArr,
    }); //internal field
    _this.setState({
      selected_token_file_attachments_arr: selectedTokFileAttachmentsArr,
    }); //internal field

    _this.setState({ selected_token_serv_date: selectedTokServDate });
    _this.setState({
      selected_token_serv_date_formatted: selectedTokServDateFormatted,
    });
    _this.setState({
      selected_token_serv_date_unix_timestamp: selectedTokServDateUnixTS,
    });

    _this.setState({ selected_token_main_loc_list: selectedTokMainLocList });
    _this.setState({ selected_token_main_loc: selectedTokMainLocID });
    _this.setState({ selected_token_sub_loc_list: selectedTokSubLocList });
    _this.setState({ selected_token_sub_loc: selectedTokSubLocID });
    _this.setState({ selected_token_serv_list: selectedTokServList });
    _this.setState({ selected_token_serv: selectedTokServID });
    _this.setState({
      selected_token_token_status_list: selectedTokTokenStatusList,
    });
    _this.setState({ selected_token_token_status: selectedTokTokenStatus });

    _this.setState({ selected_token_main_locState: "has-success" });
    _this.setState({ selected_token_sub_locState: "has-success" });
    _this.setState({ selected_token_servState: "has-success" });
    _this.setState({ selected_token_servState: "has-success" });
    _this.setState({ selected_token_token_statusState: "has-success" });

    _this.setState({ selected_token_internal_resp: selectedTokInternalResp });

    _this.setState({ selected_token_ref_new_tok_id: selectedTokRefNewTokID });
    _this.setState({ selected_token_ref_new_tok_num: selectedTokRefNewTokNum });
    _this.setState({
      selected_token_ref_cancelled_reason: selectedTokRefCancelledReason,
    });

    console.log({ selected_token_id: selectedTokId }); //internal field
    console.log({ selected_token_user_id: selectedTokUserId }); //internal field
    console.log({ selected_token_time_zone: selectedTokTimeZone }); //internal field
    console.log({ selected_token_input_fields_arr: selectedTokInputFieldsArr }); //internal field
    console.log({
      selected_token_file_attachments_arr: selectedTokFileAttachmentsArr,
    }); //internal field

    console.log({ selected_token_serv_date: selectedTokServDate });
    console.log({
      selected_token_serv_date_formatted: selectedTokServDateFormatted,
    });
    console.log({
      selected_token_serv_date_unix_timestamp: selectedTokServDateUnixTS,
    });

    console.log({ selected_token_main_loc_list: selectedTokMainLocList });
    console.log({ selected_token_main_loc: selectedTokMainLocID });
    console.log({ selected_token_sub_loc_list: selectedTokSubLocList });
    console.log({ selected_token_sub_loc: selectedTokSubLocID });
    console.log({ selected_token_serv_list: selectedTokServList });
    console.log({ selected_token_serv: selectedTokServID });
    console.log({
      selected_token_token_status_list: selectedTokTokenStatusList,
    });
    console.log({ selected_token_token_status: selectedTokTokenStatus });

    console.log({ selected_token_main_locState: "has-success" });
    console.log({ selected_token_sub_locState: "has-success" });
    console.log({ selected_token_servState: "has-success" });
    console.log({ selected_token_servState: "has-success" });
    console.log({ selected_token_token_statusState: "has-success" });

    console.log({ selected_token_internal_resp: selectedTokInternalResp });

    console.log({ selected_token_ref_new_tok_id: selectedTokRefNewTokID });
    console.log({ selected_token_ref_new_tok_num: selectedTokRefNewTokNum });
    console.log({
      selected_token_ref_cancelled_reason: selectedTokRefCancelledReason,
    });

    let horizontalTabsVal = "modifyRequestTabPane";
    if (
      selectedToken.input_fields !== null &&
      typeof selectedToken.input_fields !== "undefined" &&
      selectedToken.input_fields.length >= 1
    ) {
      horizontalTabsVal = "inputFieldsTabPane";
      console.log("showTokenDetails1");
    } else if (
      selectedToken.file_attachments !== null &&
      typeof selectedToken.file_attachments !== "undefined" &&
      selectedToken.file_attachments.length >= 1
    ) {
      horizontalTabsVal = "attachmentFieldsTabPane";
      console.log("showTokenDetails2");
    }
    _this.setState({ horizontalTabs: horizontalTabsVal });

    _this.resetModalValues(1);
    _this.setState({ modalClassic: true });
    _this.setState({ selected_token: selectedToken });

    console.log("tokID", tokID);
    console.log("selectedToken", selectedToken);
  }

  handleChangeModifyTokenFields(e, fieldType) {
    let _this = this;
    switch (fieldType) {
      case "MOD_servDateUnixTS": {
        let selectedTokTimeZone = _this.state.selected_token_time_zone;
        let dateString = new Date(e.target.value);
        let year = dateString.getFullYear().toString();
        let month = dateString.getMonth() + 1;
        let day = dateString.getDate();
        let timestamp = Date.parse(dateString);
        let d1 = new Date(timestamp);
        if (isNaN(timestamp) == false && parseInt(year) >= 2019) {
          _this.setState({
            selected_token_serv_dateState: "has-success",
            selected_token_serv_date: dateString,
            selected_token_serv_date_formatted: moment(
              new Date(parseInt(timestamp))
            )
              .tz(selectedTokTimeZone)
              .format("YYYY-MM-DD"),
            selected_token_serv_date_unix_timestamp: parseInt(timestamp) / 1000,
          });
        } else {
          _this.setState({
            selected_token_serv_dateState: "has-danger",
          });
          _this.notifyMessage(
            "tc",
            3,
            "There was a problem with the new date value set!"
          );
        }
        console.log({
          selected_token_serv_dateState: "has-success",
          selected_token_serv_date: dateString,
          selected_token_serv_date_formatted: moment(
            new Date(parseInt(timestamp))
          )
            .tz(selectedTokTimeZone)
            .format("YYYY-MM-DD"),
          selected_token_serv_date_unix_timestamp: parseInt(timestamp) / 1000,
          d1: d1,
        });
        break;
      }
      case "MOD_mainLocId":
        _this.setState({
          selected_token_main_locState: "has-success",
          selected_token_main_loc: e,
        });
        console.log({
          selected_token_main_locState: "has-success",
          selected_token_main_loc: e,
        });
        _this.loadSelectedTokenSubLocsByMainLoc(e);
        break;
      case "MOD_subLocId":
        _this.setState({
          selected_token_sub_locState: "has-success",
          selected_token_sub_loc: e,
        });
        console.log({
          selected_token_sub_locState: "has-success",
          selected_token_sub_loc: e,
        });
        _this.loadSelectedTokenServsBySubLoc(e);
        break;
      case "MOD_servId":
        _this.setState({
          selected_token_servState: "has-success",
          selected_token_serv: e,
        });
        console.log({
          selected_token_servState: "has-success",
          selected_token_serv: e,
        });
        break;
      case "MOD_internalResp":
        _this.setState({
          selected_token_internal_respState: "has-success",
          selected_token_internal_resp: e.target.value,
        });
        console.log({
          selected_token_internal_respState: "has-success",
          selected_token_internal_resp: e.target.value,
        });
        break;
      case "MOD_tokenStatus":
        _this.setState({
          selected_token_token_statusState: "has-success",
          selected_token_token_status: e,
        });
        console.log({
          selected_token_token_statusState: "has-success",
          selected_token_token_status: e,
        });
        break;
      // case "MOD_inputFieldsArr":
      //     _this.setState({
      //         selected_token_input_fieldsState: "has-success",
      //         selected_token_input_fields:  e.target.value,
      //     });
      //     console.log({
      //         selected_token_input_fieldsState: "has-success",
      //         selected_token_input_fields:  e.target.value,
      //     });
      //     break;
      // case "MOD_fileAttachmentsArr":
      //     _this.setState({
      //         selected_token_file_attachmentsState: "has-success",
      //         selected_token_file_attachments:  e.target.value,
      //     });
      //     console.log({
      //         selected_token_file_attachmentsState: "has-success",
      //         selected_token_file_attachments:  e.target.value,
      //     });
      //     break;
      default:
        break;
    }
  }

  async handleRequest(selectedToken, action, modalToggle) {
    let _this = this;
    let approving_user_id = _this.state.approving_user_id;
    let approving_user_name = _this.state.approving_user_name;
    // let approving_user_request_approval_groups = _this.state.approving_user_request_approval_groups;

    let tokID = selectedToken.token_detail_id;
    let timeZone = selectedToken.time_zone;
    let tokTokenStatus = selectedToken.token_status;
    // _this.setState({selected_token: selectedToken})

    console.log("part1_handleRequest");
    let updateData = {};
    let tokStatus = "";
    let resp;
    let status;
    let message;
    let message_code;
    let method;
    let result;

    if (tokTokenStatus == "Served" || tokTokenStatus == "Now_Serving") {
      if (tokTokenStatus == "Served") {
        resp = {
          status: "success",
          message:
            "This token has already been served. You can't modify this token.",
        };
      } else if (tokTokenStatus == "Now_Serving") {
        resp = {
          status: "success",
          message: "This token is being served. You can't modify this token.",
        };
      }
      _this.notifyMessage("tc", 2, resp.message);
      if (modalToggle == 1) {
        setTimeout(function () {
          _this.toggleModalClassic();
          _this.resetModalValues();
        }, 1500);
      }
      return;
    }

    _this.setState({ loading: true });
    let tokenDetailsList = _this.state.token_details_list;
    // console.log("token_details_list0", _this.state.token_details_list);
    let approvedTS = moment(new Date())
      .tz(timeZone)
      .format("MMM DD,YYYY [at] hh:mm:ss a [UTC] z");
    let approvedUnixTS = Math.floor(new Date().getTime() / 1000);

    _this.setState({
      // loading: true,
      btn_disabled_save_tok: true,
      btn_disabled_approve_tok: true,
      btn_disabled_reject_tok: true,
      btn_disabled_mod_cancel: true,
    });

    if (action === "approve_tok") {
      tokStatus = "Approved_Pending_Service";
      updateData["Token_Status"] = tokStatus;
    } else if (action === "reject_tok") {
      tokStatus = "Rejected";
      updateData["Token_Status"] = tokStatus;
    } else if (action === "modify_tok") {
      let selected_token_serv_dateState =
        _this.state.selected_token_serv_dateState;
      let selected_token_main_locState =
        _this.state.selected_token_main_locState;
      let selected_token_sub_locState = _this.state.selected_token_sub_locState;
      let selected_token_servState = _this.state.selected_token_servState;
      let selected_token_internal_respState =
        _this.state.selected_token_internal_respState;
      let selected_token_token_statusState =
        _this.state.selected_token_token_statusState;

      if (
        selected_token_serv_dateState == "has-success" &&
        selected_token_main_locState == "has-success" &&
        selected_token_sub_locState == "has-success" &&
        selected_token_servState == "has-success" &&
        selected_token_internal_respState == "has-success" &&
        selected_token_token_statusState == "has-success"
      ) {
        let tokenData = _this.state.selected_token;
        let tokID = tokenData.token_detail_id;

        let tokUserId = tokenData.mau_id;
        let tokTimeZone = tokenData.time_zone;
        let tokInputFieldsArr = tokenData.input_fields;
        let tokFileAttachmentsArr = tokenData.file_attachments;

        let tokServDate = tokenData.service_datetime;
        let tokServDateFormatted = tokenData.service_datetime_formatted;
        let tokServDateUnixTS = tokenData.service_datetime_ts;
        let tokServDateFSTS = tokenData.service_datetime_fs_ts;
        let tokMainLocID = tokenData.main_loc_id;
        let tokSubLocID = tokenData.sub_loc_id;
        let tokServID = tokenData.serv_id;
        let tokInternalResp = tokenData.internal_response;
        let tokTokenStatus = tokenData.token_status;

        let selectedTokId = tokID; //internal field
        let selectedTokUserId = _this.state.selected_token_user_id; //internal field
        let selectedTokTimeZone = _this.state.selected_token_time_zone; //internal field
        let selectedTokInputFieldsArr =
          _this.state.selected_token_input_fields_arr; //internal field
        let selectedTokFileAttachmentsArr =
          _this.state.selected_token_file_attachments_arr; //internal field

        let selectedTokServDate = _this.state.selected_token_serv_date;
        let selectedTokServDateFormatted =
          _this.state.selected_token_serv_date_formatted;
        let selectedTokServDateUnixTS =
          _this.state.selected_token_serv_date_unix_timestamp;
        let selectedTokMainLocID =
          _this.state.selected_token_main_loc.value === null ||
            typeof _this.state.selected_token_main_loc.value == "undefined" ||
            _this.state.selected_token_main_loc.value == ""
            ? tokMainLocID
            : _this.state.selected_token_main_loc.value;
        let selectedTokSubLocID =
          _this.state.selected_token_sub_loc.value === null ||
            typeof _this.state.selected_token_sub_loc.value == "undefined" ||
            _this.state.selected_token_sub_loc.value == ""
            ? tokSubLocID
            : _this.state.selected_token_sub_loc.value;
        let selectedTokServID =
          _this.state.selected_token_serv.value === null ||
            typeof _this.state.selected_token_serv.value == "undefined" ||
            _this.state.selected_token_serv.value == ""
            ? tokServID
            : _this.state.selected_token_serv.value;
        let selectedTokInternalResp = _this.state.selected_token_internal_resp;
        let selectedTokTokenStatus =
          _this.state.selected_token_token_status.value === null ||
            typeof _this.state.selected_token_token_status.value == "undefined" ||
            _this.state.selected_token_token_status.value == ""
            ? tokTokenStatus
            : _this.state.selected_token_token_status.value;

        // check if no changes before calling API
        console.log({ tokID: tokID });
        console.log({
          tokServDate: tokServDate,
          tokServDateFormatted: tokServDateFormatted,
          tokServDateUnixTS: tokServDateUnixTS,
          tokMainLocID: tokMainLocID,
          tokSubLocID: tokSubLocID,
          tokServID: tokServID,
          tokInternalResp: tokInternalResp,
          tokTokenStatus: tokTokenStatus,
        });
        console.log({
          selectedTokServDate: selectedTokServDate,
          selectedTokServDateFormatted: selectedTokServDateFormatted,
          selectedTokServDateUnixTS: selectedTokServDateUnixTS,
          selectedTokMainLocID: selectedTokMainLocID,
          selectedTokSubLocID: selectedTokSubLocID,
          selectedTokServID: selectedTokServID,
          selectedTokInternalResp: selectedTokInternalResp,
          selectedTokTokenStatus: selectedTokTokenStatus,
        });
        console.log({
          selectedTokUserId: selectedTokUserId,
          selectedTokTimeZone: selectedTokTimeZone,
          selectedTokInputFieldsArr: selectedTokInputFieldsArr,
          selectedTokFileAttachmentsArr: selectedTokFileAttachmentsArr,
        });

        if (
          //1+1==2 === false && // TESTING_ONLY
          // tokServDateUnixTS == selectedTokServDateUnixTS &&
          tokServDateFormatted == selectedTokServDateFormatted &&
          (tokMainLocID == selectedTokMainLocID ||
            typeof selectedTokMainLocID == "undefined" ||
            selectedTokMainLocID === null ||
            selectedTokMainLocID == "") &&
          (tokSubLocID == selectedTokSubLocID ||
            typeof selectedTokSubLocID == "undefined" ||
            selectedTokSubLocID === null ||
            selectedTokSubLocID == "") &&
          (tokServID == selectedTokServID ||
            typeof selectedTokServID == "undefined" ||
            selectedTokServID === null ||
            selectedTokServID == "") &&
          (tokInternalResp === selectedTokInternalResp ||
            typeof selectedTokInternalResp == "undefined" ||
            selectedTokInternalResp === null ||
            selectedTokInternalResp == "") &&
          (tokTokenStatus === selectedTokTokenStatus ||
            typeof selectedTokTokenStatus == "undefined" ||
            selectedTokTokenStatus === null ||
            selectedTokTokenStatus == "")
        ) {
          resp = {
            status: "success",
            message: "No fields were changed. Token was not modified.",
          };
          _this.notifyMessage("tc", 2, resp.message);
          if (modalToggle == 1) {
            setTimeout(function () {
              _this.toggleModalClassic();
              _this.resetModalValues(1);
            }, 1500);
          }
          return;
        } else {
          console.log("api called");
          console.log({
            Version: 4,
            TokenId: selectedTokId,
            UserId: selectedTokUserId,
            TimeZone: selectedTokTimeZone,
            InputFieldsArr: selectedTokInputFieldsArr,
            FileAttachmentsArr: selectedTokFileAttachmentsArr,

            MainLocId: selectedTokMainLocID,
            SubLocId: selectedTokSubLocID,
            ServiceId: selectedTokServID,
            ServDateFormatted: selectedTokServDateFormatted,
            InternalResponse: selectedTokInternalResp,
            TokenStatus: selectedTokTokenStatus,
          });

          let currentUser = Firebase.auth().currentUser;
          currentUser.getIdToken().then(function (token) {
            // console.log("token", token);

            const requestOptions = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify({
                Version: 4,
                TokenId: selectedTokId,
                UserId: selectedTokUserId,
                TimeZone: selectedTokTimeZone,
                InputFieldsArr: selectedTokInputFieldsArr,
                FileAttachmentsArr: selectedTokFileAttachmentsArr,
                MainLocId: selectedTokMainLocID,
                SubLocId: selectedTokSubLocID,
                ServiceId: selectedTokServID,
                ServiceDateFormatted: selectedTokServDateFormatted,
                InternalResponse: selectedTokInternalResp,
                TokenStatus: selectedTokTokenStatus,
              }),
            };

            let functionsBaseURL =
              _this.state.functionsBaseURL +
              "apiModifyCurrentToken_GenerateNewORUpdateExisting";
            let url = new URL(functionsBaseURL);
            fetch(url, requestOptions)
              .then((response) => response.json())
              .then((responseData) => {
                console.log("responseData", responseData);

                if (responseData.status == "success") {
                  // Update modal window with details of new token if required!
                  if (
                    responseData.message_code == "no_change" ||
                    responseData.message_code == "update_existing_token"
                  ) {
                    _this.notifyMessage("tc", 2, responseData.message);
                    if (modalToggle == 1) {
                      setTimeout(function () {
                        _this.toggleModalClassic();
                        _this.resetModalValues(1);
                      }, 1500);
                    }
                    return;
                  }

                  if (
                    responseData.message_code ==
                    "cancel_existing_token_and_create_new_token"
                  ) {
                    let selectedTokenPosition = 0,
                      i = 0;
                    for (i; i < tokenDetailsList.length; i++) {
                      if (tokenDetailsList[i].token_detail_id === tokID) break;
                    }
                    selectedTokenPosition = i;

                    // 1. Make a shallow copy of the items
                    let tokDetailsList = [..._this.state.token_details_list];
                    // 2. Make a shallow copy of the item you want to mutate
                    let tokDet = { ...tokDetailsList[selectedTokenPosition] }; //{...tokDetailsList[i]};
                    // 3. Replace the property you're intested in
                    tokDet.new_tok_ref_id =
                      responseData.result.NewTokenReferenceID;
                    tokDet.new_tok_ref_num = responseData.result.NewToken;
                    tokDet.new_tok_ref_prev_tok_cancelled_reason =
                      responseData.result.PrevTokCancelledReason;
                    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
                    tokDetailsList[i] = tokDet;
                    // 5. Set the state to our new copy
                    _this.setState({ token_details_list: tokDetailsList });

                    _this.setState({
                      selected_token_ref_new_tok_id:
                        responseData.result.NewTokenReferenceID,
                    });
                    _this.setState({
                      selected_token_ref_new_tok_num:
                        responseData.result.NewToken,
                    });
                    _this.setState({
                      selected_token_ref_cancelled_reason:
                        responseData.result.PrevTokCancelledReason,
                    });

                    console.log("token_details_list1", tokDetailsList);
                    // console.log("token_details_list2", _this.state.token_details_list);
                    _this.notifyMessage("tc", 2, responseData.message);
                    if (modalToggle == 1) {
                      setTimeout(function () {
                        _this.toggleModalClassic();
                        // _this.resetModalValues(); //TESTING_ONLY
                      }, 1500);
                    }
                    return;
                  }
                } else if (responseData.status == "failed") {
                  _this.resetModalValues(1);
                  _this.notifyMessage("tc", 3, responseData.message);
                  console.log(
                    "Some error occured -- handleRequest -- 1 (api/technical)"
                  );
                  return;
                } else {
                  _this.resetModalValues(1);
                  _this.notifyMessage("tc", 3, "Some error occured!");
                  console.log(
                    "Some error occured -- handleRequest -- 2 (api/technical)"
                  );
                  return;
                }
              })
              .catch((error) => {
                _this.resetModalValues(1);
                _this.notifyMessage("tc", 3, "Some error occured!");
                console.log(
                  "Some error occured -- handleRequest -- 3 (api/technical)",
                  error
                );
                return;
              });
          });
        }
      } else {
        _this.notifyMessage(
          "tc",
          3,
          "Some error occured! Token was not modified."
        );
        console.log(
          "Some error occured -- handleRequest -- 4 (field validations)"
        );
        console.log({
          selected_token_serv_dateState: selected_token_serv_dateState,
          selected_token_main_locState: selected_token_main_locState,
          selected_token_sub_locState: selected_token_sub_locState,
          selected_token_servState: selected_token_servState,
          selected_token_internal_respState: selected_token_internal_respState,
          selected_token_token_statusState: selected_token_token_statusState,
        });
        // if (modalToggle == 1) {
        //     setTimeout(function(){
        //         _this.toggleModalClassic();
        _this.resetModalValues(2);
        //     }, 1500);
        // }
        return;
      }
    }

    if (action === "approve_tok" || action === "reject_tok") {
      console.log("part2_handleRequest");

      updateData["Token_Status"] = tokStatus;
      updateData["Approving_User_Id"] = approving_user_id;
      updateData["Approving_User_Name"] = approving_user_name;
      updateData["Approved_Timestamp"] = approvedTS;
      updateData["Approved_Unix_Timestamp"] = approvedUnixTS;
      updateData["Last_Update_Timestamp"] = approvedTS;
      updateData["Last_Update_Unix_Timestamp"] = approvedUnixTS;

      Firebase.firestore()
        .collection("Token_Details")
        .doc(tokID)
        .update(updateData)
        .then(function () {
          let selectedTokenPosition = 0,
            i = 0;
          for (i; i < tokenDetailsList.length; i++) {
            if (tokenDetailsList[i].token_detail_id === tokID) break;
          }
          selectedTokenPosition = i;

          // 1. Make a shallow copy of the items
          let tokDetailsList = [..._this.state.token_details_list];
          // 2. Make a shallow copy of the item you want to mutate
          let tokDet = { ...tokDetailsList[selectedTokenPosition] }; //{...tokDetailsList[i]};
          // 3. Replace the property you're intested in
          tokDet.token_status = tokStatus;
          // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
          tokDetailsList[i] = tokDet;
          // 5. Set the state to our new copy
          _this.setState({ token_details_list: tokDetailsList });

          // console.log("token_details_list1", tokDetailsList);
          // console.log("token_details_list2", _this.state.token_details_list);
          // _this.notifyMessage("tc", 2, action === "approve_tok" ? "Token was approved" : "Token was rejected");
          if (modalToggle == 1) {
            setTimeout(function () {
              _this.toggleModalClassic();
              _this.resetModalValues();
            }, 1500);
          }
        })
        .catch(function (err) {
          _this.setState({ loading: false });
          _this.notifyMessage("tc", 3, "Network error!");
          console.log("handleRequest NetworkError1==>", err);
        });

      console.log(
        "tokID",
        tokID,
        "action",
        action,
        "selectedToken",
        selectedToken
      );
    }
  }

  resetModalValues = (mode) => {
    let _this = this;
    // enable modal buttons
    if (mode === 1) {
      _this.setState({
        loading: false,

        btn_disabled_save_tok: false,
        btn_disabled_approve_tok: false,
        btn_disabled_reject_tok: false,
        btn_disabled_mod_cancel: false,
      });
    }
    // reset selected token field values
    else if (mode === 2) {
      _this.setState({
        loading: false,

        selected_token_serv_date: "",
        selected_token_serv_date_unix_timestamp: "",
        selected_token_main_loc: "",
        selected_token_sub_loc: "",
        selected_token_serv: "",
        selected_token_internal_resp: "",
      });
    }

    // enable modal buttons and reset selected token field values
    else {
      _this.setState({
        loading: false,

        btn_disabled_save_tok: false,
        btn_disabled_approve_tok: false,
        btn_disabled_reject_tok: false,
        btn_disabled_mod_cancel: false,

        selected_token_serv_date: "",
        selected_token_serv_date_unix_timestamp: "",
        selected_token_main_loc: "",
        selected_token_sub_loc: "",
        selected_token_serv: "",
        selected_token_internal_resp: "",

        selected_token_ref_new_tok_id: "",
        selected_token_ref_new_tok_num: "",
        selected_token_ref_cancelled_reason: "",
      });
    }
  };

  notifyMessage = (place, color, text) => {
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }

    var options = {};
    options = {
      place: place,
      message: (
        <div className="text-md-center">
          <div>
            <b>{text}</b>
          </div>
        </div>
      ),
      type: type,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 3,
    };
    if (options !== null) {
      this.notificationAlert.notificationAlert(options);
    }
  };
  warningWithConfirmMessage = (object) => {
    // this.setState({
    //     alert: (
    //         <ReactBSAlert
    //             warning
    //             style={{ display: "block", marginTop: "0px" }} //marginTop: "-100px" }}
    //             title="Are you sure?"
    //             onConfirm={() => this.deleteItem(object)}
    //             onCancel={() => this.hideAlert()}
    //             confirmBtnBsStyle="info"
    //             cancelBtnBsStyle="danger"
    //             confirmBtnText="Yes, delete it!"
    //             cancelBtnText="Cancel"
    //             showCancel
    //         >
    //         </ReactBSAlert>
    //     )
    // });
  };
  successDelete = () => {
    // this.setState({
    //     alert: (
    //         <ReactBSAlert
    //             success
    //             style={{ display: "block", marginTop: "-100px" }}
    //             title="Deleted!"
    //             onConfirm={() => this.confirmDelete()}
    //             onCancel={() => this.confirmDelete()}
    //             confirmBtnBsStyle="info"
    //         >
    //         </ReactBSAlert>
    //     )
    // });
  };
  confirmDelete = () => {
    this.setState({
      alert: null,
    });

    // this.loadServicesBySub(this.state.selected_sub_locations.value, false);
  };
  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };
  render() {
    const { modalClassic } = this.state;
    const _this = this;
    const now = new Date();
    const token_details_data = this.getTokenDetails(),
      tableColumns = [
        // { Header: "#", accessor: "key", width: 40 },
        { Header: "Sub Location", accessor: "sub_loc_nam" },
        {
          Header: "Service Date",
          accessor: "service_datetime",
        },
        {
          Header: "Requested Date",
          accessor: "requested_datetime",
        },
        { Header: "Service Name", accessor: "serv_name" },
        { Header: "Token Number", accessor: "token_num" },
        {
          Header: "New Token Number",
          accessor: "new_tok_ref_num",
        },
        {
          Header: "Mobile App User",
          accessor: "mobile_app_user",
          width: 350,
        },
        {
          Header: "Token Status",
          accessor: "token_string_status",
          width: 200,
        },
        {
          Header: "Token Status",
          accessor: "token_status",
          width: 200,
        },
        {
          Header: "Action",
          accessor: "action",
          sortable: false,
          width: 200,
        },
      ];

    const {
      LocationName,
      LocationAddress,
      ServiceName,
      StartCharacter,
      Token,
      CurrentDatetime,
      TokensAhead,
      defaultDate,
      main_location_list,
      sub_location_list,
      service_list,
      token_status_list,
      token_details_list,
      selected_token,

      selected_token_serv_dateState,
      selected_token_main_locState,
      selected_token_sub_locState,
      selected_token_servState,
      selected_token_internal_respState,
      selected_token_token_statusState,
    } = this.state;

    let tokenDetailsList = this.state.token_details_list;
    // let tokenData = tokenDetailsList.filter((id, key) => {
    //     return id = selected_token_id;
    // })
    // console.log("selected_token_id", selected_token_id);
    // console.log("tokenDetailsList", tokenDetailsList);
    // console.log("tokenData", tokenData);
    let tokenData = selected_token;
    console.log("tokenData", tokenData);

    var inputFields = [];
    var fileAttachments = [];
    if (tokenData !== null && typeof tokenData !== "undefined") {
      tokenData.input_fields.map((prop, key) => {
        if (
          typeof prop.id !== "undefined" &&
          prop.id !== null &&
          prop.id !== ""
        ) {
          inputFields.push({
            id: prop.id,
            key: key + 1,
            field_name: prop.Input_Field_Name,
            field_value: prop.Input_Field_Value,
            field_data_type: prop.Input_Field_Data_Type,
            field_mandatory:
              prop.Input_Field_Mandatory == true ? (
                <span className="badge badge-pill badge-success">Yes</span>
              ) : (
                <span className="badge badge-pill badge-default">No</span>
              ),
          });
        }
      });
      // console.log("inputFields", inputFields);

      tokenData.file_attachments.map((prop, key) => {
        if (
          typeof prop.id !== "undefined" &&
          prop.id !== null &&
          prop.id !== ""
        ) {
          fileAttachments.push({
            id: prop.id,
            key: key + 1,
            file_attachment_name: prop.File_Attachment_Name,
            // file_attachment_file_name: prop.File_Attachment_File_Name,
            file_size: prop.File_Attachment_File_Size_In_KB,
            file_type: prop.File_Attachment_File_Type,
            file_mandatory:
              prop.File_Attachment_Mandatory == true ? (
                <span className="badge badge-pill badge-success">Yes</span>
              ) : (
                <span className="badge badge-pill badge-default">No</span>
              ),
            action:
              prop.File_Attachment_URL !== "" ? (
                <div>
                  <Button
                    className="btn btn-default btn-sm"
                    style={{ marginTop: "-7px", marginBottom: "-7px" }}
                  >
                    <a
                      href={prop.File_Attachment_URL}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Preview
                    </a>
                  </Button>
                </div>
              ) : null,
          });
        }
      });

      // console.log("fileAttachments", fileAttachments);
    }

    return (
      <>
        <LoadingOverlay
          active={this.state.loading}
          spinner
          text="Loading"
          className="content"
        >
          <div className="rna-container">
            {" "}
            {/* z-Index css property set in index.html file */}
            <NotificationAlert ref={(ref) => (this.notificationAlert = ref)} />
            {this.state.alert}
          </div>

          {tokenData !== null && typeof tokenData !== "undefined" && (
            <Modal
              size="lg"
              backdrop={"static"}
              //dialogClassName="modal-90w"
              aria-labelledby="example-custom-modal-styling-title"
              isOpen={this.state.modalClassic}
              toggle={this.toggleModalClassic}
            >
              {modalClassic && (
                <>
                  <ModalHeader className="justify-content-center" toggle={this.toggleModalClassic}>

                  {/* <div className="modal-header justify-content-center"> */}
                    <button
                      aria-label="Close"
                      className="close"
                      data-dismiss="modal"
                      type="button"
                      onClick={this.toggleModalClassic}
                    >
                      <i className="nc-icon nc-simple-remove" />
                    </button>

                    {tokenData.new_tok_ref_id !== null &&
                      typeof tokenData.new_tok_ref_id !== "undefined" &&
                      tokenData.new_tok_ref_id !== "" ? (
                      <>
                        <span>
                          <h5>
                            Current Token Number (Cancelled):{" "}
                            {tokenData.token_num}
                          </h5>
                        </span>
                        <span>
                          <h5>New Token Number: {tokenData.new_tok_ref_num}</h5>
                        </span>
                      </>
                    ) : tokenData.cancelled_tok_ref_id !== null &&
                      typeof tokenData.cancelled_tok_ref_id !== "undefined" &&
                      tokenData.cancelled_tok_ref_id !== "" ? (
                      <>
                        <span>
                          <h5>
                            Previous Token Number (Cancelled):{" "}
                            {tokenData.cancelled_tok_ref_num}
                          </h5>
                        </span>
                        <span>
                          <h5>Current Token Number: {tokenData.token_num}</h5>
                        </span>
                      </>
                    ) : (
                      <>
                        <span>
                          <h5>Current Token Number: {tokenData.token_num}</h5>
                        </span>
                      </>
                    )}
                  {/* </div> */}
                  </ModalHeader>
                  <div className="modal-body">
                    <>
                      <div className="content">
                        <div className="nav-tabs-navigation">
                          <div className="nav-tabs-wrapper">
                            <Nav id="tabs" role="tablist" tabs>
                              <NavItem>
                                <NavLink
                                  aria-expanded={
                                    this.state.horizontalTabs ===
                                    "modifyRequestTabPane"
                                  }
                                  data-toggle="tab"
                                  href="#panel"
                                  role="tab"
                                  className={
                                    this.state.horizontalTabs ===
                                      "modifyRequestTabPane"
                                      ? "active"
                                      : ""
                                  }
                                  onClick={() =>
                                    this.setState({
                                      horizontalTabs: "modifyRequestTabPane",
                                    })
                                  }
                                >
                                  Modify Service Request
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  aria-expanded={
                                    this.state.horizontalTabs ===
                                    "inputFieldsTabPane"
                                  }
                                  data-toggle="tab"
                                  href="#panel"
                                  role="tab"
                                  className={
                                    this.state.horizontalTabs ===
                                      "inputFieldsTabPane"
                                      ? "active"
                                      : ""
                                  }
                                  onClick={() =>
                                    this.setState({
                                      horizontalTabs: "inputFieldsTabPane",
                                    })
                                  }
                                >
                                  Input Fields
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  aria-expanded={
                                    this.state.horizontalTabs ===
                                    "attachmentFieldsTabPane"
                                  }
                                  data-toggle="tab"
                                  href="#panel"
                                  role="tab"
                                  className={
                                    this.state.horizontalTabs ===
                                      "attachmentFieldsTabPane"
                                      ? "active"
                                      : ""
                                  }
                                  onClick={() =>
                                    this.setState({
                                      horizontalTabs: "attachmentFieldsTabPane",
                                    })
                                  }
                                >
                                  File Attachments
                                </NavLink>
                              </NavItem>
                            </Nav>
                          </div>
                        </div>
                        <TabContent
                          className="text-center"
                          id="my-tab-content"
                          activeTab={this.state.horizontalTabs}
                        >
                          <TabPane tabId="modifyRequestTabPane" role="tabpanel">
                            <Row className="top-margin-10" />
                            <Row>
                              <Label md="4">Service Date</Label>
                              <Col md="8" style={{ textAlign: "left" }}>
                                <FormGroup
                                  className={`has-label ${selected_token_serv_dateState}`}
                                >
                                  {/* z-Index css property set in index.html file */}
                                  <DatePicker
                                    format="dd-MM-yyyy"
                                    value={this.state.selected_token_serv_date}
                                    onChange={(e) =>
                                      this.handleChangeModifyTokenFields(
                                        e,
                                        "MOD_servDateUnixTS"
                                      )
                                    }
                                  />
                                  {selected_token_serv_dateState ===
                                    "has-danger" ? (
                                    <label className="error">
                                      A valid Service Date is required.
                                    </label>
                                  ) : null}
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Label md="4">Main Location</Label>
                              <Col md="8">
                                <FormGroup
                                  className={`has-label ${selected_token_main_locState}`}
                                >
                                  <Select
                                    placeholder="Select"
                                    closeMenuOnSelect={true}
                                    value={this.state.selected_token_main_loc}
                                    options={
                                      this.state.selected_token_main_loc_list
                                    }
                                    onChange={(e) =>
                                      this.handleChangeModifyTokenFields(
                                        e,
                                        "MOD_mainLocId"
                                      )
                                    }
                                  />
                                  {selected_token_main_locState ===
                                    "has-danger" ? (
                                    <label className="error">
                                      This field is required.
                                    </label>
                                  ) : null}
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Label md="4">Sub Location</Label>
                              <Col md="8">
                                <FormGroup
                                  className={`has-label ${selected_token_sub_locState}`}
                                >
                                  <Select
                                    placeholder="Select"
                                    closeMenuOnSelect={true}
                                    value={this.state.selected_token_sub_loc}
                                    options={
                                      this.state.selected_token_sub_loc_list
                                    }
                                    onChange={(e) =>
                                      this.handleChangeModifyTokenFields(
                                        e,
                                        "MOD_subLocId"
                                      )
                                    }
                                  />
                                  {selected_token_sub_locState ===
                                    "has-danger" ? (
                                    <label className="error">
                                      This field is required.
                                    </label>
                                  ) : null}
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Label md="4">Service</Label>
                              <Col md="8">
                                <FormGroup
                                  className={`has-label ${selected_token_servState}`}
                                >
                                  <Select
                                    placeholder="Select"
                                    closeMenuOnSelect={true}
                                    value={this.state.selected_token_serv}
                                    options={
                                      this.state.selected_token_serv_list
                                    }
                                    onChange={(e) =>
                                      this.handleChangeModifyTokenFields(
                                        e,
                                        "MOD_servId"
                                      )
                                    }
                                  />
                                  {selected_token_servState === "has-danger" ? (
                                    <label className="error">
                                      This field is required.
                                    </label>
                                  ) : null}
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Label md="4">Internal Response</Label>
                              <Col md="8">
                                <FormGroup>
                                  <Input
                                    value={
                                      this.state.selected_token_internal_resp
                                    }
                                    type="text"
                                    onChange={(e) =>
                                      this.handleChangeModifyTokenFields(
                                        e,
                                        "MOD_internalResp"
                                      )
                                    }
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Label md="4">Token Status</Label>
                              <Col md="8">
                                <FormGroup
                                  className={`has-label ${selected_token_token_statusState}`}
                                >
                                  <Select
                                    placeholder="Select"
                                    closeMenuOnSelect={true}
                                    value={
                                      this.state.selected_token_token_status
                                    }
                                    options={
                                      this.state
                                        .selected_token_token_status_list
                                    }
                                    onChange={(e) =>
                                      this.handleChangeModifyTokenFields(
                                        e,
                                        "MOD_tokenStatus"
                                      )
                                    }
                                  />
                                  {selected_token_token_statusState ===
                                    "has-danger" ? (
                                    <label className="error">
                                      This field is required.
                                    </label>
                                  ) : null}
                                </FormGroup>
                              </Col>
                            </Row>
                            {tokenData.new_tok_ref_id !== null &&
                              typeof tokenData.new_tok_ref_id !== "undefined" &&
                              tokenData.new_tok_ref_id !== "" ? (
                              <>
                                <Row>
                                  <Label md="4">Cancelled Reason</Label>
                                  <Col md="8">
                                    <FormGroup>
                                      <Input
                                        disabled
                                        defaultValue={
                                          this.state
                                            .selected_token_ref_cancelled_reason
                                        }
                                        type="textarea"
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </>
                            ) : null}
                            <Row>
                              <Label md="4"></Label>
                              <Col md="8">
                                <FormGroup>
                                  <div>
                                    <button
                                      className="btn btn-info btn-block"
                                      variant="primary"
                                      disabled={
                                        this.state.btn_disabled_save_tok
                                      }
                                      onClick={() =>
                                        this.handleRequest(
                                          tokenData,
                                          "modify_tok",
                                          1
                                        )
                                      }
                                    >
                                      {/* <Spinner
                                                                            as="span"
                                                                            animation="border"
                                                                            size="sm"
                                                                            role="status"
                                                                            aria-hidden="true"
                                                                        /> */}
                                      {this.state.btn_disabled_save_tok == true
                                        ? "Saving. Please wait."
                                        : "Save"}
                                    </button>
                                  </div>
                                </FormGroup>
                              </Col>
                            </Row>
                          </TabPane>
                          <TabPane tabId="inputFieldsTabPane" role="tabpanel">
                            <Row className="top-margin-10" />
                            {inputFields !== null &&
                              typeof inputFields !== "undefined" &&
                              inputFields.length !== 0 ? (
                              <>
                                <div>
                                  <ReactTable
                                    data={inputFields}
                                    columns={[
                                      {
                                        Header: "#",
                                        accessor: "key",
                                        width: 40,
                                      },
                                      {
                                        Header: "Field Name",
                                        accessor: "field_name",
                                      },
                                      {
                                        Header: "Field Value",
                                        accessor: "field_value",
                                      },
                                      {
                                        Header: "Data Type",
                                        accessor: "field_data_type",
                                      },
                                      {
                                        Header: "Mandatory",
                                        accessor: "field_mandatory",
                                      },
                                    ]}
                                    defaultPageSize={5}
                                    showPaginationTop={false}
                                    showPaginationBottom={true}
                                    showPageSizeOptions={false}
                                    /*
                                                                            You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                                                                            */
                                    className="-striped -highlight primary-pagination"
                                  />
                                </div>
                              </>
                            ) : (
                              "There are no input fields attached to this token!"
                            )}
                          </TabPane>
                          <TabPane
                            tabId="attachmentFieldsTabPane"
                            role="tabpanel"
                          >
                            <Row className="top-margin-10" />
                            {fileAttachments !== null &&
                              typeof fileAttachments !== "undefined" &&
                              fileAttachments.length !== 0 ? (
                              <>
                                <div>
                                  <ReactTable
                                    data={fileAttachments}
                                    columns={[
                                      {
                                        Header: "#",
                                        accessor: "key",
                                        width: 40,
                                      },
                                      {
                                        Header: "Attachment Name",
                                        accessor: "file_attachment_name",
                                      },
                                      {
                                        Header: "Attachment File Name",
                                        accessor: "file_attachment_file_name",
                                      },
                                      {
                                        Header: "File Size (Kb)",
                                        accessor: "file_size",
                                      },
                                      {
                                        Header: "File Type",
                                        accessor: "file_type",
                                      },
                                      {
                                        Header: "Mandatory",
                                        accessor: "file_mandatory",
                                      },
                                      {
                                        Header: "Action",
                                        accessor: "action",
                                        sortable: false,
                                      },
                                    ]}
                                    defaultPageSize={5}
                                    showPaginationTop={false}
                                    showPaginationBottom={true}
                                    showPageSizeOptions={false}
                                    /*
                                                                            You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                                                                            */
                                    className="-striped -highlight primary-pagination"
                                  />
                                </div>
                              </>
                            ) : (
                              "There are no file attachments attached to this token!"
                            )}
                            <TokenAttachFile setUpdate={e => {
                              // _this.setState({updateState:Date.now()});
                              fileAttachments = e
                            }} TokenData={tokenData} notifyMessage={_this.notifyMessage} />
                          </TabPane>
                        </TabContent>
                      </div>
                    </>
                  </div>
                  <div className="modal-footer">
                    <div className="left-side">
                      {/* <Button
                        className="btn btn-primary"
                        color="success"
                        disabled={this.state.btn_disabled_approve_tok}
                        onClick={() =>
                          this.handleRequest(tokenData, "approve_tok", 1)
                        }
                      >
                        Approve <i className="nc-icon nc-check-2" />
                      </Button>
                      <Button
                        className="btn btn-primary"
                        color="danger"
                        disabled={this.state.btn_disabled_reject_tok}
                        onClick={() =>
                          this.handleRequest(tokenData, "reject_tok", 1)
                        }
                      >
                        Reject <i className="nc-icon nc-simple-remove" />
                      </Button> */}
                      <Button
                        className="btn btn-default"
                        color="default"
                        disabled={this.state.btn_disabled_mod_cancel}
                        onClick={this.toggleModalClassic}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </Modal>
          )}
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h4 className="padding_Dashboard card-title">
                    Other Requests
                  </h4>
                </CardHeader>
                <CardBody>
                  <div className="padding_Card card-body">
                    <div className="blockquote blockquote-primary padding">
                      <Row>
                        <Col lg="2">
                          <h5>
                            Select Date
                            Range&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </h5>

                          {/* <div id="daterangepicker" title="daterangepicker"></div> */}
                          <DateRangePicker
                            value={this.state.defaultDate}
                            onChange={this.handleChangeDate}
                          />
                        </Col>
                        <Col lg="2">
                          <h5>
                            Select Main
                            Location&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </h5>
                          <Select
                            placeholder="Select"
                            closeMenuOnSelect={true}
                            value={this.state.selected_main_locations}
                            isMulti
                            options={this.state.main_location_list}
                            onChange={this.handleChangeMainLoc}
                          />
                        </Col>
                        <Col lg="2">
                          <h5>
                            Select Sub
                            Location&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </h5>
                          <Select
                            placeholder="Select"
                            closeMenuOnSelect={true}
                            value={this.state.selected_sub_locations}
                            isMulti
                            options={this.state.sub_location_list}
                            onChange={this.handleChangeSubLoc}
                          />
                        </Col>
                        <Col lg="2">
                          <h5>
                            Select
                            Service&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </h5>
                          <Select
                            placeholder="Select"
                            closeMenuOnSelect={true}
                            value={this.state.selected_services}
                            isMulti
                            options={this.state.service_list}
                            onChange={this.handleChangeService}
                          />
                        </Col>
                        <Col lg="2">
                          <h5>Select Approval Group(s)</h5>
                          <Select
                            placeholder="Select"
                            closeMenuOnSelect={true}
                            value={this.state.selected_request_approval_groups}
                            isMulti
                            options={[
                              ...this.state.request_approval_group_list,
                              { label: "No group", value: "" },
                            ]}
                            onChange={this.handleChangeServiceRequestsGroup}
                          />
                        </Col>
                        <Col lg="2">
                          <h5>
                            Select Token
                            Status&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </h5>
                          <Select
                            placeholder="Select"
                            closeMenuOnSelect={true}
                            value={this.state.selected_token_statuses}
                            isMulti
                            options={this.state.token_status_list}
                            onChange={this.handleChangeTokenStatus}
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div className="text-center padding_button">
                    <button
                      disabled={
                        !(
                          main_location_list.length >= 1 &&
                          sub_location_list.length >= 1 &&
                          service_list.length >= 1 &&
                          token_status_list.length >= 1 &&
                          defaultDate.start &&
                          defaultDate.end
                        )
                      }
                      className="btn-round btn btn-outline-success h6"
                      type="button"
                      onClick={() => this.onVisualize()}
                    >
                      View Service Requests
                    </button>
                  </div>
                  <Row className="top-margin-10" />
                  {token_details_data?.length > 0 && (
                    <div
                      style={{
                        marginBottom: 20,
                        marginLeft: 20,
                      }}
                    >
                      <CSVLink
                        data={token_details_data}
                        headers={tableColumns
                          .map((c) => ({
                            label: c.Header,
                            key: c.accessor,
                          }))
                          .filter((e) => e.key != "action" && e.key != "token_status")}
                        // data={_this.state.tableprops?.data}
                        // data={[]}
                        // headers={[]}
                        // data={this.state.tableprops.data}
                        filename={"Other Requests.csv"}
                        className="btn-round btn btn-outline-primary"
                        target="_blank"
                      >
                        Download .csv
                      </CSVLink>
                    </div>
                  )}
                  <div>
                    <ReactTable
                      data={token_details_data}
                      columns={tableColumns.filter(e => e.accessor != 'token_string_status')}
                      defaultPageSize={5}
                      showPaginationTop={false}
                      showPaginationBottom={true}
                      showPageSizeOptions={false}
                      /*
                                            You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                                            */
                      className="-striped -highlight primary-pagination"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </LoadingOverlay>
      </>
    );
  }
}

export default ServiceRequests;
