import React from "react";
import LoadingOverlay from "react-loading-overlay";
import Select from "react-select";
import Firebase from "firebase";
import NotificationAlert from "react-notification-alert";
import Switch from "react-bootstrap-switch";
import config from "../../../config";
import IconUpload from "../../../components/CustomUpload/IconUpload";

import {
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Collapse,
    Row,
    Button,
    Form,
    FormGroup,
    Label,
    Input
} from "reactstrap";

import trash from '../../trash-alt-solid.svg'

class MainLocationEdit extends React.Component {
    constructor(props) {
        super(props);
        if (!Firebase.apps.length) {
            Firebase.initializeApp(config);
        }
        //this.mobile_app_ad_1 = React.createRef();

        this.state = {
            loading: false,
            id: '',
            icon_max_limit: 0,
            mobile_ad_file_max_limit: 1,

            main_location_key: '',
            name: '',
            icon: '',
            remark: '',
            enable_mobile: false,
            active: false,
            nameState: 'has-success',
            country_codeState: 'has-success',

            openedCollapses: [],
            created_date_time: '',
            stop_date_time: '',
            sub_location_cnt: '',
            customer_id: '',
            customer_name: '',

            inputKey1: Math.random().toString(36),
            mobile_app_title: '',
            mobile_app_description: '',
            mobile_app_enable_title_description: false,
            mobile_app_enable_title_descriptionState: '',
            mobile_app_ad_1: null,
            mobile_app_ad_1_enable: false,
            mobile_app_ad_1_file_ext_ok: false,
            mobile_app_ad_1_file_size_ok: false,
            mobile_app_ad_1_name: '',
            mobile_app_ad_1_url: '',
            mobile_app_ad_1_attachmentState: '',

            country_list:[],
            selected_country_code: null,
        };
        this.inputFileRef1 = React.createRef();
    }

    componentDidMount() {
        let id = this.props.match.params.id;
        var role = JSON.parse(localStorage.getItem('auth_info')).role;

        if (id !== "" && id !== null && id !== undefined) {
            this.setState({id: id,role:role});
            this.loadData(id);
        }
    }

    loadData(id) {
        let _this = this;
        _this.setState({loading: true});
        var customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;
        _this.setState({customer_id: customer_id});
         // ---------- Load Country List---------- //
        Firebase.firestore().collection('System_Config').doc('Country_List').collection('Countries').get().then(e=>{
            var three=[]
            e.docs.forEach(b=>{
                three.push({label:b.data().Country_Name,value:b.data().Three_Letter_Country_Code})
            })
            _this.setState({country_list:three})
        })

        // ---------- Load Icon Max Size ---------- //
        Firebase.firestore().collection('System_Config').doc('Upload_Limits').get().then(function (upload_limit_info) {
            if (upload_limit_info.exists) {
                _this.setState({icon_max_limit: upload_limit_info.data().Max_Icon_size_in_MB});
                _this.setState({mobile_ad_file_max_limit: upload_limit_info.data().Max_Mobile_Ad_File_size_in_MB});

                // ---------- load Own Customer Data ----------
                Firebase.firestore().collection('Customers').doc(customer_id).get().then(function (own_customer_info) {
                    if (own_customer_info.exists) {
                        _this.setState({customer_name: own_customer_info.data().Name});
                        _this.setState({loading: false});
                    } else {
                        _this.setState({loading: false});
                        _this.notifyMessage("tc", 3, "Network Error.");
                        console.log("loadData NetworkError1");
                    }
                }).catch(function (err) {
                    _this.setState({loading: false});
                    _this.notifyMessage("tc", 3, "Network Error.");
                    console.log("loadData NetworkError2==>", err);
                });
                
                // ---------- Load Main Location Info ---------- //
                Firebase.firestore().collection('Main_Locations').doc(id).get().then(function (main_location_data) {
                    if (main_location_data.exists) {
                        _this.setState({main_location_key: main_location_data.id});
                        _this.setState({name: main_location_data.data().Name});
                        _this.setState({icon: main_location_data.data().Icon});
                        _this.refs.icon.handleSetUrl(_this.state.icon);
                        _this.setState({remark: main_location_data.data().Remarks});
                        _this.setState({enable_mobile: main_location_data.data().Show_On_Mobile_App});
                        _this.setState({active: main_location_data.data().Status});
                        _this.setState({created_date_time: main_location_data.data().Created_Date});
                        _this.setState({stop_date_time: main_location_data.data().Stop_Date});
                        var country = _this.getCountryItem(main_location_data.data().Country_Code);
                        _this.setState({selected_country_code: {label: country[0].label, value: country[0].value}});
                        _this.setState({sub_location_cnt: main_location_data.data().Sub_Locations_Count});
                        _this.setState({customer_id: main_location_data.data().Customer_ID});
                        _this.setState({mobile_app_title: main_location_data.data().Mobile_App_Title});
                        _this.setState({mobile_app_description: main_location_data.data().Mobile_App_Description});
                        _this.setState({mobile_app_enable_title_description: main_location_data.data().Mobile_App_Enable_Title_Description});
                        _this.setState({mobile_app_ad_1_url: main_location_data.data().Mobile_App_Ad_1});
                        _this.setState({mobile_app_ad_1_enable: main_location_data.data().Mobile_App_Enable_Ad_1});
                        // _this.setState({customer_name: main_location_data.data.data().Customer_Name});
                        _this.setState({loading: false});
                    } else {
                        _this.setState({loading: false});
                        _this.notifyMessage("tc", 3, "Network error!");
                        console.log("loadData NetworkError3");
                    }
                }).catch(function (err) {
                    _this.setState({loading: false});
                    _this.notifyMessage("tc", 3, "Network error!");
                    console.log("loadData NetworkError4==>", err);
                });
            } else {
                _this.setState({loading: false});
                _this.notifyMessage("tc", 3, "Network error!");
                console.log("loadData NetworkError5");
            }
        }).catch(function (err) {
            _this.setState({loading: false});
            _this.notifyMessage("tc", 3, "Network error!");
            console.log("loadData NetworkError6>", err);
        });
    }
    
    getCountryItem = threeLetterCountryCode => {
        var countryList = this.state.country_list;
        var result = countryList.filter(function(item) {
            return item.value === threeLetterCountryCode;
        });
        //console.log("result", result);
        return result;   
    }

    collapsesToggle = collapse => {
        let openedCollapses = this.state.openedCollapses;
        if (openedCollapses.includes(collapse)) {
            this.setState({
                openedCollapses: openedCollapses.filter(item => item !== collapse)
            });
        } else {
            openedCollapses.push(collapse);
            this.setState({
                openedCollapses: openedCollapses
            });
        }
    };
    
    handleSave() {
        let _this = this;
        const { nameState, country_codeState, mobile_app_ad_1_attachmentState, mobile_app_enable_title_descriptionState} = this.state;
        if (nameState === "") { _this.setState({ nameState: "has-danger" }); }
        if (country_codeState === "") { _this.setState({ country_codeState: "has-danger" }); }
        //if (mobile_app_enable_title_descriptionState === "") { _this.setState({ mobile_app_enable_title_descriptionState: "has-danger" }); }
        //if (mobile_app_ad_1_attachmentState === "") { _this.setState({ mobile_app_ad_1_attachmentState: "has-danger" }); }

        if (_this.state.nameState === "has-success" && _this.state.country_codeState === "has-success"
            && _this.state.mobile_app_enable_title_descriptionState !== "has-danger"
            && _this.state.mobile_app_ad_1_attachmentState !== "has-danger") {
            let cc='';
            if((_this.state.selected_country_code.value !== undefined && _this.state.selected_country_code.value.length > 0)) {
                cc=_this.state.selected_country_code.value
            }
            // else if(_this.state.selected_country_code.label!=undefined && _this.state.selected_country_code.label.length>0)
            //     cc=_this.state.selected_country_code.label
            // else cc=_this.state.selected_country_code
            _this.setState({loading: true});

            var now = new Date();
            let file = _this.refs.icon.state.file;
            if (file !== null) {
                // --------- Check Max Icon Size Limit --------- //
                let max_bytes = _this.state.icon_max_limit * 1024 * 1024;
                if (file.size > max_bytes) {
                    _this.setState({loading: false});
                    _this.notifyMessage("tc", 3, "Icon file size exceeds maximum size of " + _this.state.icon_max_limit +  " MB. Please change icon!");
                } else {
                    let metadata = {
                        customMetadata: {
                            customer_id: _this.state.customer_id,
                            main_location_id: _this.state.main_location_key
                        }
                    };
                    // console.log(metadata);
                    var storageRef = Firebase.storage().ref();
                    var image_name = "main_location_icon_" + _this.state.main_location_key;
                    var mainLocationRef = storageRef.child(image_name);
                    mainLocationRef.put(file, metadata).then(function (snapshot) {
                        mainLocationRef.getDownloadURL().then(function (res) {
                            let update_main_location_data = {
                                Icon: res,
                                Name: _this.state.name,
                                Remarks: _this.state.remark,
                                Show_On_Mobile_App: _this.state.enable_mobile,
                                Status: _this.state.active,
                                Country_Code:cc,
                                Mobile_App_Title: _this.state.mobile_app_title,
                                Mobile_App_Description: _this.state.mobile_app_description,
                                Mobile_App_Enable_Title_Description: _this.state.mobile_app_enable_title_description,
                                Mobile_App_Ad_1: _this.state.mobile_app_ad_1_url,
                                Mobile_App_Enable_Ad_1: _this.state.mobile_app_ad_1_enable,
                                Updated_Date: now
                            };

                            Firebase.firestore().collection('Main_Locations').doc(_this.state.main_location_key).update(update_main_location_data)
                                .then(function() {
                                    _this.setState({loading: false});
                                    window.setTimeout(function() { _this.props.history.push("/locations") }, 2000);
                                    _this.notifyMessage("tc", 2, "Main Location saved successfully.");
                                }).catch(function (error) {
                                _this.setState({loading: false});
                                _this.notifyMessage("tc", 3, "Network error!");
                                console.log("handleSave NetworkError5==>", error);
                            });
                        }).catch(function (err) {
                            _this.setState({loading: false});
                            _this.notifyMessage("tc", 3, "Network error!");
                            console.log("handleSave NetworkError6==>", err);
                        });
                    }).catch(function (err) {
                        _this.setState({loading: false});
                        _this.notifyMessage("tc", 3, "Network error!");
                        console.log("handleSave NetworkError7==>", err);
                    });
                }
            } else {
                console.log(cc)
                let update_main_location_data = {
                    Name: _this.state.name,
                    Remarks: _this.state.remark,
                    Show_On_Mobile_App: _this.state.enable_mobile,
                    Status: _this.state.active,
                    Country_Code:cc,
                    Mobile_App_Title: _this.state.mobile_app_title,
                    Mobile_App_Description: _this.state.mobile_app_description,
                    Mobile_App_Enable_Title_Description: _this.state.mobile_app_enable_title_description,
                    Mobile_App_Ad_1: _this.state.mobile_app_ad_1_url,
                    Mobile_App_Enable_Ad_1: _this.state.mobile_app_ad_1_enable,
                    Updated_Date: now
                };

                Firebase.firestore().collection('Main_Locations').doc(_this.state.main_location_key).update(update_main_location_data)
                    .then(function() {
                        _this.setState({loading: false});
                        window.setTimeout(function() { _this.props.history.push("/locations") }, 2000);
                        _this.notifyMessage("tc", 2, "Main Location saved successfully.");
                    }).catch(function (error) {
                    _this.setState({loading: false});
                    _this.notifyMessage("tc", 3, "Network error!");
                    console.log("handleSave NetworkError8==>", error);
                });
            }
        } else {
            _this.setState({loading: false});
            _this.notifyMessage("tc", 3, "Please make sure all required fields are filled.");
        }
    }

    notifyMessage = (place, color, text) => {
        var type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }

        var options = {};
        options = {
            place: place,
            message: (
                <div className="text-md-center">
                    <div>
                        <b>{text}</b>
                    </div>
                </div>
            ),
            type: type,
            icon: "now-ui-icons ui-1_bell-53",
            autoDismiss: 3
        };
        if (options !== null) { this.notificationAlert.notificationAlert(options) }
    };

    verifyLength = (value, length) => {
        return value.length >= length;
    };

    changeCountry(e){
        let _this = this;
        let selectedCountry = e.value; //.toLowerCase();
        
        _this.setState({ country_codeState: "has-success" });
        _this.setState({ selected_country_code: e });

        console.log("country_codeState", "has-success");
        console.log("selected_country_code", selectedCountry);

        _this.setState({loading: false});
    }

    static getTimeString(time) {
        if (time === null || time === undefined)
            return "";

        if (time.seconds === null || time.seconds === undefined)
            return "";

        let date = new Date(time.seconds*1000);
        let time_string = date.toLocaleString();
        return time_string;
    };

    handleChange = (event, stateName, type, stateNameEqualTo) => {
        switch (type) {
            case "length":
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + "State"]: "has-success" });
                } else {
                    this.setState({ [stateName + "State"]: "has-danger" });
                }
                break;
            case "mobile_app_enable_title_description":
                if (event === true) {
                    if (this.verifyLength(this.state.mobile_app_title, stateNameEqualTo)) {
                        this.setState({ [stateName + "State"]: "has-success" });
                    } else {
                        this.setState({ [stateName + "State"]: "has-danger" });
                    }
                } else if (event === false) {
                    this.setState({ [stateName + "State"]: "has-success" });
                }

                break;
            case "mobile_app_title":
                if ((this.state.mobile_app_title).length >= 1) {
                    this.setState({ "mobile_app_enable_title_descriptionState": "has-success" });
                } else {
                    if (this.state.mobile_app_enable_title_description) {
                        this.setState({ "mobile_app_enable_title_descriptionState": "has-danger" });
                    }
                }
                break;
            default:
                break;
        }
        
        if (stateName == "mobile_app_enable_title_description") {
            this.setState({ [stateName]: event });
        }
        else {
            this.setState({ [stateName]: event.target.value });
        }
    };

    handleAttachment(e, field) {
      
        if (field == "mobile_app_ad_1") {
            var _this = this; var ok = false; var customerID = _this.state.customer_id;
            var elementID = ""; var inputFileRef = ""; var fileName = ""; var fileNameWithExt = ""; var attachment_file = ""; var attachment_file_name = "";
            elementID = "uploader1";
            inputFileRef = this.inputFileRef1.current.files;
            fileName = "main_location_ad_1_" + _this.state.main_location_key;
            attachment_file = inputFileRef[0];

            if (/*e.target.files[0]*/ attachment_file != undefined) {
       
                attachment_file_name = attachment_file.name; /*e.target.files[0]*/
                console.log("attachment_file_name", attachment_file_name);
                console.log("fileName", fileName);
                console.log("attachment_file_name.split('.')[1]", attachment_file_name.split('.')[1]);
                fileNameWithExt = fileName+'.'+attachment_file_name.split('.')[1]; 
                _this.setState({mobile_app_ad_1_name: fileNameWithExt});
                switch (attachment_file_name.slice((attachment_file_name.lastIndexOf(".") - 1 >>> 0) + 2)) { //attachment_file_name.split('.')[1] 
                    case 'mp4': ok = true; break;
                    case 'png': ok = true; break;
                    case 'jpg': ok = true; break;
                    case 'jpeg': ok = true; break;
                    case 'webp': ok = true; break;
                    default: ok = false; break;
                }
            }
            if (/*e.target.files[0]*/ attachment_file != undefined && ok) {
                var task = "";
                var customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id; let metadata = {};
                console.log("this.state.main_location_key, customer_id", _this.state.main_location_key, customer_id);
                metadata = {
                    customMetadata: {
                        customer_id: _this.state.customer_id,
                        main_location_id: _this.state.main_location_key
                    }
                };

                if (/*e.target.files[0]*/ attachment_file.size <= _this.state.mobile_ad_file_max_limit * 1024 * 1024) {
                    var storageRef1 = Firebase.storage().ref('Media_Gallery/Mobile_Ads/'+customerID+'/'); console.log("handleAttachment storageRef1", storageRef1);
                    var fileName1 = fileNameWithExt; console.log("handleAttachment fileName1", fileName1);
                    var fileRef1 = storageRef1.child(fileName1); console.log("handleAttachment fileRef1", fileRef1);
                    task = fileRef1.put(attachment_file, metadata)
                    /*.then(res=>console.log('uploadsuccess'),err=>console.log('uploadfailed...',err))*/
                    document.getElementById(elementID).style.display = 'block';

                    task.on('state_changed', (snapshot) => {
                        var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        document.getElementById(elementID).value = percentage;

                    }, () => { }, () => {
                        let _this = this, j = 0, ad = [], o = 0
                        _this.setState({ mobile_app_ad_1_attachmentState: "has-success" });
                         // .then(function (snapshot) {
                            fileRef1.getDownloadURL().then(function (res1) {
                                _this.setState({mobile_app_ad_1_url: res1});
                                console.log("handleAttachment mobile_app_ad_1_url", res1);
                            })
                        // })
                        document.getElementById(elementID).style.display = 'none';
                    })
                    task.catch(error => {
                        _this.setState({ mobile_app_ad_1_attachmentState: "has-danger" });
                        console.log('image/video upload error==> ', error);
                    })
                }
                else {
                    _this.notifyMessage("tc", 3, "Image/video AD size must be less than " + _this.state.mobile_ad_file_max_limit + " MB");
                    _this.setState({ mobile_app_ad_1_attachmentState: "has-danger" });
                }
            } else {
                console.log("fileName", fileName); console.log("attachment_file", attachment_file);
                _this.notifyMessage("tc", 3, "Please provide a valid file with any one of these extensions: mp4, png, jpg, jpeg, webp!");
                _this.setState({ mobile_app_ad_1_attachmentState: "has-danger" });
            }
        }
    }

    render() {
        var ok=false
        if(this.state.role == "Site_Admin" || this.state.role == "System_Admin" || this.state.role == "Billing_Admin")
            ok=true
        let {
            nameState,
            country_codeState,
            mobile_app_enable_title_descriptionState,
            mobile_app_ad_1_attachmentState,
        } = this.state;

        return (
            <>
                <LoadingOverlay
                    active={this.state.loading}
                    spinner
                    text='Loading'
                    className='content'
                >
                    <NotificationAlert ref={(ref) => this.notificationAlert = ref} />
                    <Row>
                        <Col md="12">
                        {ok? <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Main Location Edit</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Col className="ml-auto mr-auto" lg="8">
                                        <Row>
                                            <Col md="3">
                                                <Button
                                                    color="success"
                                                    onClick={e => this.handleSave()}
                                                    block
                                                >
                                                    Save Changes
                                                </Button>
                                            </Col>
                                            <Col md="3">
                                                <Button
                                                    color="youtube"
                                                    onClick={e => this.props.history.push("/locations")}
                                                    block
                                                >
                                                    Close
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row className="top-margin-10">
                                        </Row>
                                        <Row>
                                            <Col md="8">
                                                <Form className="form-horizontal">
                                                    <hr/>
                                                    <Row>
                                                        <Col md="12">
                                                            <FormGroup>
                                                            <h5>General</h5>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Main Location Key</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Input
                                                                    type="text"
                                                                    defaultValue={this.state.main_location_key}
                                                                    disabled
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Main Location Name</Label>
                                                        <Col md="8">
                                                            <FormGroup className={`has-label ${nameState}`}>
                                                                <Input
                                                                    placeholder="New Location"
                                                                    defaultValue={this.state.name}
                                                                    type="text"
                                                                    onChange={e =>
                                                                        this.handleChange(e, "name", "length", 1)
                                                                    }
                                                                />
                                                                {this.state.nameState === "has-danger" ? (
                                                                    <label className="error">This field is required.</label>
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Icon</Label>
                                                        <Col md="8">
                                                            <IconUpload ref="icon"/>
                                                        </Col>
                                                    </Row>
                                                    <Row className="top-margin-10">
                                                        <Label md="4">Remarks</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Input
                                                                    defaultValue={this.state.remark}
                                                                    type="textarea"
                                                                    onChange={e => {this.setState({remark: e.target.value})}}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Enable on Mobile App</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Row className="top-margin-7"/>
                                                                <Switch
                                                                    offColor="success"
                                                                    offText={<i className="nc-icon nc-simple-remove" />}
                                                                    onColor="success"
                                                                    onText={<i className="nc-icon nc-check-2" />}
                                                                    defaultValue={this.state.enable_mobile}
                                                                    value={this.state.enable_mobile}
                                                                    onChange={e => {this.setState({enable_mobile: e.state.value})}}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Active/Inactive</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Row className="top-margin-7"/>
                                                                <Switch
                                                                    offColor="success"
                                                                    offText={<i className="nc-icon nc-simple-remove" />}
                                                                    onColor="success"
                                                                    onText={<i className="nc-icon nc-check-2" />}
                                                                    defaultValue={this.state.active}
                                                                    value={this.state.active}
                                                                    onChange={e => {this.setState({active: e.state.value})}}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Country</Label>
                                                        <Col md="8">
                                                            <FormGroup className={`has-label ${country_codeState}`}>
                                                                <Select
                                                                    className="react-select primary"
                                                                    classNamePrefix="react-select"
                                                                    name="countryselect"
                                                                    //defaultValue={this.state.selected_country_code}
                                                                    value={this.state.selected_country_code}
                                                                    onChange={e =>
                                                                        this.changeCountry(e)
                                                                    }
                                                                    options={this.state.country_list}
                                                                    //placeholder="Select Country Code"
                                                                />
                                                                {this.state.country_codeState === "has-danger" ? (
                                                                    <label className="error">This field is required.</label>
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <hr/>
                                                    <Row>
                                                        <Col md="12">
                                                            <FormGroup>
                                                            <h5>Mobile App Advertising</h5>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row className="top-margin-10">
                                                    <Label md="4">Title</Label>
                                                        <Col md="8">
                                                            <FormGroup className={`has-label ${mobile_app_enable_title_descriptionState}`}>
                                                                <Input
                                                                    defaultValue={this.state.mobile_app_title}
                                                                    type="text"
                                                                    onChange={e =>
                                                                        this.handleChange(e, "mobile_app_title", "mobile_app_title", 1)
                                                                    }
                                                                />
                                                                {this.state.mobile_app_enable_title_descriptionState === "has-danger" ? (
                                                                    <label className="error">This field is required if "Enable Title & Description" field is enabled.</label>
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row className="top-margin-10">
                                                        <Label md="4">Description</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Input
                                                                    defaultValue={this.state.mobile_app_description}
                                                                    type="text"
                                                                    onChange={e => {this.setState({mobile_app_description: (e.target.value).trim()})}}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Enable Title & Description</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Row className="top-margin-7"/>
                                                                <Switch
                                                                    offColor="success"
                                                                    offText={<i className="nc-icon nc-simple-remove" />}
                                                                    onColor="success"
                                                                    onText={<i className="nc-icon nc-check-2" />}
                                                                    defaultValue={this.state.mobile_app_enable_title_description}
                                                                    value={this.state.mobile_app_enable_title_description}
                                                                    onChange={e => {this.handleChange(e.state.value, "mobile_app_enable_title_description", "mobile_app_enable_title_description", 1)}}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row className="top-margin-10" />
                                                    <Row md='12' className='px-0 mx-0'>
                                                        <Label md="4">Advertisement 1 Image / Video File</Label>

                                                        <Col md='8' style={{ paddingLeft: '0px', paddingBottom: '15px' }} >
                                                            <progress id="uploader1" value="0" max="100" style={{ display: 'none' }}>0%</progress>
                                                            <input type="file" id='file1' ref={this.inputFileRef1} key={this.state.inputKey1}
                                                                onChange={e => this.handleAttachment(e, "mobile_app_ad_1")}
                                                            />
                                                            {this.state.mobile_app_ad_1_name != undefined ?
                                                                (this.state.mobile_app_ad_1_name.length > 0 ?
                                                                    <Row md='12' className='px-0 mx-0'>
                                                                    <img src={trash} style={{ width: '15px', marginTop: '3px' }} alt=""
                                                                        onClick={() => {
                                                                            console.log("this.state.mobile_app_ad_1_name", this.state.mobile_app_ad_1_name);
                                                                            var task1 = Firebase.storage().ref('Media_Gallery/Mobile_Ads/'+this.state.customer_id+'/').child(this.state.mobile_app_ad_1_name).delete();
                                                                            task1.catch(error => { 
                                                                                console.log("task1 error", error);
                                                                            })
                                                                            
                                                                            this.setState({
                                                                                inputKey1: Math.random().toString(36),
                                                                                mobile_app_ad_1: '',
                                                                                mobile_app_ad_1_name: '',
                                                                                mobile_app_ad_1_url: '',
                                                                                mobile_app_ad_1_attachmentState: '',
                                                                            })
                                                                        }}
                                                                    /></Row> : null)
                                                                : null}
                                                            {this.state.mobile_app_ad_1_attachmentState === "has-danger" ? (
                                                                <div className="has-label has-danger position-relative form-group">
                                                                    <label className="error">Please provide a valid image/video file of type png, jpg, jpeg, webp OR mp4!!</label>
                                                                </div>
                                                            ) : null}
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Enable Advertisement 1</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Row className="top-margin-7"/>
                                                                <Switch
                                                                    offColor="success"
                                                                    offText={<i className="nc-icon nc-simple-remove" />}
                                                                    onColor="success"
                                                                    onText={<i className="nc-icon nc-check-2" />}
                                                                    defaultValue={this.state.mobile_app_ad_1_enable}
                                                                    value={this.state.mobile_app_ad_1_enable}
                                                                    onChange={e => {this.setState({mobile_app_ad_1_enable: e.state.value})}}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4"></Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <label className="error">Mobile App Ad can only be activated after purchasing the add-on &quot;Add-On: Sub Locations Ad 1&quot;.</label>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Card className="card-plain width-100">
                                                            <CardHeader role="tab">
                                                                <a
                                                                    aria-expanded={this.state.openedCollapses.includes(
                                                                        "show-more"
                                                                    )}
                                                                    href="#"
                                                                    data-parent="#accordion"
                                                                    data-toggle="collapse"
                                                                    onClick={e => {e.preventDefault(); this.collapsesToggle("show-more")}}
                                                                >
                                                                    Show More...{" "}
                                                                </a>
                                                            </CardHeader>
                                                            <Collapse
                                                                role="tabpanel"
                                                                isOpen={this.state.openedCollapses.includes(
                                                                    "show-more"
                                                                )}
                                                            >
                                                                <CardBody>
                                                                    <Row>
                                                                        <Label md="4">Customer ID</Label>
                                                                        <Col md="8">
                                                                            <FormGroup>
                                                                                <Input
                                                                                    defaultValue={this.state.customer_id}
                                                                                    type="text"
                                                                                    disabled
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Label md="4">Customer Name</Label>
                                                                        <Col md="8">
                                                                            <FormGroup>
                                                                                <Input
                                                                                    defaultValue={this.state.customer_name}
                                                                                    type="text"
                                                                                    disabled
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            </Collapse>
                                                        </Card>
                                                    </Row>
                                                </Form>
                                            </Col>
                                        </Row>
                                    </Col>
                                </CardBody>
                            </Card>: "Sorry, you don't have permission to use this function, Please contact System Administrator if this is a mistake."}
                        </Col>
                    </Row>
                </LoadingOverlay>
            </>
        );
    }
}

export default MainLocationEdit;
