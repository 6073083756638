import React from "react";
import Firebase from 'firebase';
import LoadingOverlay from "react-loading-overlay";
import NotificationAlert from "react-notification-alert";
import Select from "react-select";
import config from '../../../config';
import PictureUpload from "../../../components/CustomUpload/PictureUpload";

import {
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Form,
    FormGroup,
    Label,
    Input,
    Button
} from "reactstrap";

class PackageEdit extends React.Component {
    constructor(props) {
        super(props);
        if (!Firebase.apps.length) {
            Firebase.initializeApp(config);
        }

        this.state = {
            loading: false,
            code: '',
            name: '',
            // unit: '',
            icon_max_limit: 0,
            numbers_counters_per_sublocation: 0,
            numbers_services_per_sublocation: 0,
            numbers_tokens_per_service_per_day: 0,
            numbers_displays_per_sublocation: 0,
            numbers_image_ads_per_display: 0,
            numbers_video_ads_per_display: 0,
            numbers_users_per_sublocation: 0,
            reporting: null,
            reporting_list: [],
            archived_for: null,
            archive_list: [],
            has_support: null,
            support_list: [],
            monthly_price: 0,
            annual_price: 0,
            trial_days: 0,
            order_sequence: 0,
            category: null,
            category_list: [],
            detail: '',
            image_url: '',
            is_hosted: false,
            enable_mobile: false,
            is_static_IP: false,
            is_customizable_service: false,
            has_customer_feedback: false,
            is_api: false,
            has_free_updates: false,
            is_trial: false,
            has_user_guides: false,
            is_designated_account_manager: false
        };

        this.handleSave = this.handleSave.bind(this);
        this.loadPackageData = this.loadPackageData.bind(this);
    }
    componentDidMount() {
        var code = "";
        code = this.props.match.params.id;
        this.setState({code: code});
        this.loadPackageData(code);
    }

    loadPackageData(code) {
        var _this = this;
        _this.setState({loading: true});
        Firebase.firestore().collection('Packages').doc(code).get().then(function (doc) {
            if (doc.exists) {
                _this.setState({name: doc.data().Name});
                // _this.setState({unit: doc.data().Unit});
                _this.setState({numbers_counters_per_sublocation: parseInt(doc.data().Numbers_Counters_Per_Sublocation)});
                _this.setState({numbers_services_per_sublocation: parseInt(doc.data().Numbers_Services_Per_Sublocation)});
                _this.setState({numbers_tokens_per_service_per_day: parseInt(doc.data().Numbers_Tokens_Per_Service_Per_Day)});
                _this.setState({numbers_displays_per_sublocation: parseInt(doc.data().Numbers_Displays_Per_Sublocation)});
                _this.setState({numbers_image_ads_per_display: parseInt(doc.data().Numbers_Image_Ads_Per_Display)});
                _this.setState({numbers_video_ads_per_display: parseInt(doc.data().Numbers_Video_Ads_Per_Display)});
                _this.setState({numbers_users_per_sublocation: parseInt(doc.data().Numbers_Users_Per_Sublocation)});
                _this.setState({reporting: {name:doc.data().Reporting, label:doc.data().Reporting}});
                _this.setState({archived_for: {name:doc.data().Archived_For, label:doc.data().Archived_For}});
                _this.setState({has_support: {name:doc.data().Has_Support, label:doc.data().Has_Support}});
                _this.setState({monthly_price: parseInt(doc.data().Monthly_Price)});
                _this.setState({annual_price: parseInt(doc.data().Annual_Price)});
                _this.setState({trial_days: parseInt(doc.data().Trial_Days)});
                _this.setState({order_sequence: parseInt(doc.data().Order_Sequence)});
                _this.setState({category: {name:doc.data().Category, label:doc.data().Category}});
                _this.setState({detail: doc.data().Detail});
                _this.setState({image_url: doc.data().Image_Url});
                _this.setState({is_hosted: doc.data().Is_Hosted});
                _this.setState({enable_mobile: doc.data().Enable_Mobile});
                _this.setState({is_static_IP: doc.data().Is_Static_IP});
                _this.setState({is_customizable_service: doc.data().Is_Customizable_Service});
                _this.setState({has_customer_feedback: doc.data().Has_Customer_Feedback});
                _this.setState({is_api: doc.data().Is_Api});
                _this.setState({has_free_updates: doc.data().Has_Free_Updates});
                _this.setState({is_trial: doc.data().Is_Trial});
                _this.setState({has_user_guides: doc.data().Has_User_Guides});
                _this.setState({is_designated_account_manager: doc.data().Is_Designated_Account_Manager});
                _this.refs.package_image.handleSetUrl(doc.data().Image_Url);
                _this.loadData();
            } else {
                _this.setState({loading: false});
                _this.notifyMessage("tc", 3, "Network error!");
                console.log('loadPackageData', 'error', 'no-doc-exist', code, {debug: {id: 1}});
            }
        }).catch(function (err) {
            _this.setState({loading: false});
            _this.notifyMessage("tc", 3, "Network error!");
            console.log('loadPackageData', 'error', err.message, code, {debug: {id: 2}});
        });
    }
    loadData() {
        var _this = this;
        _this.setState({loading: true});
        Firebase.firestore().collection('System_Config').doc('Statistics_and_Reporting').get().then(function (doc) {
            var reports = doc.data().Reporting_Access;
            var report_list = [];
            reports.forEach(function (report) {
                var report_one = {
                    value: report,
                    label: report
                };

                report_list.push(report_one);
            });

            _this.setState({reporting_list: report_list});

            // ---------- Load Icon Max Size ---------- //
            Firebase.firestore().collection('System_Config').doc('Upload_Limits').get().then(function (upload_limit_info) {
                _this.setState({icon_max_limit: upload_limit_info.data().Max_Icon_size_in_MB});

                Firebase.firestore().collection('System_Config').doc('Archive_Limits').get().then(function (doc) {
                    var archives = doc.data().Archive_Limits;
                    var archive_list = [];
                    archives.forEach(function (archive) {
                        var archive_one = {
                            value: archive,
                            label: archive
                        };

                        archive_list.push(archive_one);
                    });

                    _this.setState({archive_list: archive_list});
                    Firebase.firestore().collection('System_Config').doc('Package_Support').get().then(function (doc) {
                        var supports = doc.data().Support_Access;
                        var support_list = [];
                        supports.forEach(function (support) {
                            var support_one = {
                                value: support,
                                label: support
                            };

                            support_list.push(support_one);
                        });

                        _this.setState({support_list: support_list});
                        Firebase.firestore().collection('System_Config').doc('Package_Categories').get().then(function (doc) {
                            console.log(doc.id, doc.data());
                            var categories = doc.data().Customer_Category;
                            var category_list = [];
                            categories.forEach(function (category) {
                                var category_one = {
                                    value: category,
                                    label: category
                                };

                                category_list.push(category_one);
                            });

                            _this.setState({category_list: category_list});
                            _this.setState({loading: false});
                        }).catch(function (err) {
                            _this.setState({loading: false});
                            _this.notifyMessage("tc", 3, "Network error!");
                            console.log('loadData', 'error', 'System_Config.Package_Categories', {debug: {id: 1, message: err.message}});
                        });
                    }).catch(function (err) {
                        _this.setState({loading: false});
                        _this.notifyMessage("tc", 3, "Network error!");
                        console.log('loadData', 'error', 'System_Config.Package_Support', {debug: {id: 2, message: err.message}});
                    });
                }).catch(function (err) {
                    _this.setState({loading: false});
                    _this.notifyMessage("tc", 3, "Network error!");
                    console.log('loadData', 'error', 'System_Config.Archive_Limits', {debug: {id: 3, message: err.message}});
                });
            }).catch(function (err) {
                _this.setState({loading: false});
                _this.notifyMessage("tc", 3, "Network error!");
                console.log('loadData', 'error', 'System_Config.Upload_Limits', {debug: {id: 4, message: err.message}});
            });
        }).catch(function (err) {
            _this.setState({loading: false});
            _this.notifyMessage("tc", 3, "Network error!");
            console.log('loadData', 'error', 'System_Config.Statistics_and_Reporting', {debug: {id: 5, message: err.message}});
        });
    }
    handleSave() {
        var _this = this;
        if (_this.state.code === "") {
            _this.notifyMessage("tc", 3, "Package code is empty!");
        } else {
            _this.setState({loading: true});
            var now = new Date();
            var customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;
            var email_id = JSON.parse(localStorage.getItem('auth_info')).email;
            let file = this.refs.package_image.state.file;
            var image_upload_ok = false;

            if (file !== null) {
                 // --------- Check Max Icon Size Limit --------- //
                let max_bytes = _this.state.icon_max_limit * 1024 * 1024;
                if (file.size > max_bytes) {
                    _this.setState({loading: false});
                    _this.notifyMessage("tc", 3, "Icon file size exceeds maximum size of " + _this.state.icon_max_limit +  " MB. Please change icon!");
                } else {
                    image_upload_ok = true;
                }

                var storageRef = Firebase.storage().ref();
                var image_name = "package_image_" + _this.state.code;
                var packageRef = storageRef.child(image_name);
                packageRef.put(file).then(function (snapshot) {
                    packageRef.getDownloadURL().then(function (res) {
                        var add_data = {
                            Name: _this.state.name,
                            Last_Modified_Date: now,
                            Last_Modified_User: email_id, //customer_id,
                            // Unit: _this.state.unit,
                            Numbers_Counters_Per_Sublocation: _this.state.numbers_counters_per_sublocation,
                            Numbers_Services_Per_Sublocation: _this.state.numbers_services_per_sublocation,
                            Numbers_Tokens_Per_Service_Per_Day: _this.state.numbers_tokens_per_service_per_day,
                            Numbers_Displays_Per_Sublocation: _this.state.numbers_displays_per_sublocation,
                            Numbers_Image_Ads_Per_Display: _this.state.numbers_image_ads_per_display,
                            Numbers_Video_Ads_Per_Display: _this.state.numbers_video_ads_per_display,
                            Numbers_Users_Per_Sublocation: _this.state.numbers_users_per_sublocation,
                            Reporting: _this.state.reporting===null?"":_this.state.reporting.label,
                            Archived_For: _this.state.archived_for===null?"":_this.state.archived_for.label,
                            Has_Support: _this.state.has_support===null?"":_this.state.has_support.label,
                            Monthly_Price: _this.state.monthly_price,
                            Annual_Price: _this.state.annual_price,
                            Trial_Days: parseInt(_this.state.trial_days),
                            Order_Sequence: _this.state.order_sequence,
                            Category: _this.state.category===null?"":_this.state.category.label,
                            Detail: _this.state.detail,
                            Image_Url: res,
                            Is_Hosted: _this.state.is_hosted,
                            Enable_Mobile: _this.state.enable_mobile,
                            Is_Static_IP: _this.state.is_static_IP,
                            Is_Customizable_Service: _this.state.is_customizable_service,
                            Has_Customer_Feedback: _this.state.has_customer_feedback,
                            Is_Api: _this.state.is_api,
                            Has_Free_Updates: _this.state.has_free_updates,
                            Is_Trial: _this.state.is_trial,
                            Has_User_Guides: _this.state.has_user_guides,
                            Is_Designated_Account_Manager: _this.state.is_designated_account_manager,
                        };

                        if ( image_upload_ok == true ) {
                            Firebase.firestore().collection('Packages').doc(_this.state.code).update(add_data).then(function () {
                                _this.notifyMessage("tc", 2, "Save package success!");
                                _this.setState({loading: false});
                                window.setTimeout(function() { _this.props.history.push("/packages") }, 2000);
                            }).catch(function (err) {
                                _this.setState({loading: false});
                                _this.notifyMessage("tc", 3, "Network error!");
                                console.log('edit-package', 'error', 'package-update', err.message);
                            });
                        }

                    }, (error) => {
                        console.log("getDownloadURL error==>", error);
                        _this.setState({loading: false});
                    });
                }).catch(function (err) {
                    _this.setState({loading: false});
                    _this.notifyMessage("tc", 3, "Network error!");
                    console.log('edit-package', 'error', 'upload-file', err.message);
                });
            } else {
                 
                image_upload_ok = true;

                var add_data = {
                    Name: _this.state.name,
                    Last_Modified_Date: now,
                    Last_Modified_User: email_id, //customer_id,
                    // Unit: _this.state.unit,
                    Numbers_Counters_Per_Sublocation: _this.state.numbers_counters_per_sublocation,
                    Numbers_Services_Per_Sublocation: _this.state.numbers_services_per_sublocation,
                    Numbers_Tokens_Per_Service_Per_Day: _this.state.numbers_tokens_per_service_per_day,
                    Numbers_Displays_Per_Sublocation: _this.state.numbers_displays_per_sublocation,
                    Numbers_Image_Ads_Per_Display: _this.state.numbers_image_ads_per_display,
                    Numbers_Video_Ads_Per_Display: _this.state.numbers_video_ads_per_display,
                    Numbers_Users_Per_Sublocation: _this.state.numbers_users_per_sublocation,
                    Reporting: _this.state.reporting===null?"":_this.state.reporting.label,
                    Archived_For: _this.state.archived_for===null?"":_this.state.archived_for.label,
                    Has_Support: _this.state.has_support===null?"":_this.state.has_support.label,
                    Monthly_Price: _this.state.monthly_price,
                    Annual_Price: _this.state.annual_price,
                    Trial_Days: parseInt(_this.state.trial_days),
                    Order_Sequence: _this.state.order_sequence,
                    Category: _this.state.category===null?"":_this.state.category.label,
                    Detail: _this.state.detail,
                    Is_Hosted: _this.state.is_hosted,
                    Enable_Mobile: _this.state.enable_mobile,
                    Is_Static_IP: _this.state.is_static_IP,
                    Is_Customizable_Service: _this.state.is_customizable_service,
                    Has_Customer_Feedback: _this.state.has_customer_feedback,
                    Is_Api: _this.state.is_api,
                    Has_Free_Updates: _this.state.has_free_updates,
                    Is_Trial: _this.state.is_trial,
                    Has_User_Guides: _this.state.has_user_guides,
                    Is_Designated_Account_Manager: _this.state.is_designated_account_manager,
                };

                if ( image_upload_ok == true ) {
                    Firebase.firestore().collection('Packages').doc(_this.state.code).update(add_data).then(function () {
                        _this.notifyMessage("tc", 2, "Save package success!");
                        _this.setState({loading: false});
                        window.setTimeout(function() { _this.props.history.push("/packages") }, 2000);
                    }).catch(function (err) {
                        _this.setState({loading: false});
                        _this.notifyMessage("tc", 3, "Network error!");
                        console.log('edit-package', 'error', 'package-update', err.message);
                    });
                }
            }
            
        }
    }
    notifyMessage = (place, color, text) => {
        var type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }

        var options = {};
        options = {
            place: place,
            message: (
                <div className="text-md-center">
                    <div>
                        <b>{text}</b>
                    </div>
                </div>
            ),
            type: type,
            icon: "now-ui-icons ui-1_bell-53",
            autoDismiss: 3
        };
        if (options !== null) { this.notificationAlert.notificationAlert(options) }
    };
    render() {
        return (
            <>
                <LoadingOverlay
                    active={this.state.loading}
                    spinner
                    text='Loading'
                    className='content'
                >
                    <NotificationAlert ref={(ref) => this.notificationAlert = ref} />
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Package Edit</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <div className="row">
                                        <div className="col-md-2"/>
                                        <div className="col-md-8">
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <Button
                                                        color="success"
                                                        onClick={this.handleSave}
                                                        block
                                                    >
                                                        Save
                                                    </Button>
                                                </div>
                                                <div className="col-md-3">
                                                    <Button
                                                        color="youtube"
                                                        onClick={e => this.props.history.push("/packages")}
                                                        block
                                                    >
                                                        Close
                                                    </Button>
                                                </div>
                                                <div className="col-md-6" />
                                                <div className="col-md-12 account-tab" />
                                                <div className="col-md-10">
                                                    <Form className="form-horizontal">
                                                        <Row>
                                                            <Label md="4">Package Code</Label>
                                                            <Col md="8">
                                                                <FormGroup>
                                                                    <Input
                                                                        type="text"
                                                                        defaultValue={this.state.code}
                                                                        disabled
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Label md="4">Package Name</Label>
                                                            <Col md="8">
                                                                <FormGroup>
                                                                    <Input
                                                                        placeholder="PackageName"
                                                                        type="text"
                                                                        defaultValue={this.state.name}
                                                                        onChange={e => {this.setState({name: e.target.value})}}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        {/* <Row>
                                                            <Label md="4">Unit</Label>
                                                            <Col md="8">
                                                                <FormGroup>
                                                                    <Input
                                                                        placeholder="Unit"
                                                                        type="text"
                                                                        defaultValue={this.state.unit}
                                                                        onChange={e => {this.setState({unit: e.target.value})}}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row> */}
                                                        <Row>
                                                            <Label sm="4">Cloud hosted</Label>
                                                            <Col className="checkbox-radios" sm="8">
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input
                                                                            type="checkbox"
                                                                            checked={this.state.is_hosted?true:false}
                                                                            onChange={e =>
                                                                            {this.setState({is_hosted: !this.state.is_hosted})}
                                                                            }
                                                                        />
                                                                        <span className="form-check-sign" />
                                                                        Enabled
                                                                    </Label>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </div>
                                                <div className="col-md-2">
                                                    <PictureUpload ref="package_image" />
                                                </div>
                                                <div className="col-md-12">
                                                    <Form className="form-horizontal">
                                                        <Row>
                                                            <Label md="4">Number of Counters per Sub Location</Label>
                                                            <Col md="3">
                                                                <FormGroup>
                                                                    <Input
                                                                        value={this.state.numbers_counters_per_sublocation}
                                                                        type="number"
                                                                        min={0}
                                                                        onChange={e => {this.setState({numbers_counters_per_sublocation: e.target.value})}}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </div>
                                                <div className="col-md-12">
                                                    <Form className="form-horizontal">
                                                        <Row>
                                                            <Label md="4">Number of Services per Sub Location</Label>
                                                            <Col md="3">
                                                                <FormGroup>
                                                                    <Input
                                                                        value={this.state.numbers_services_per_sublocation}
                                                                        type="number"
                                                                        min={0}
                                                                        onChange={e => {this.setState({numbers_services_per_sublocation: e.target.value})}}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </div>
                                                <div className="col-md-12">
                                                    <Form className="form-horizontal">
                                                        <Row>
                                                            <Label md="4">Number of Tokens per Service per Day</Label>
                                                            <Col md="3">
                                                                <FormGroup>
                                                                    <Input
                                                                        value={this.state.numbers_tokens_per_service_per_day}
                                                                        type="number"
                                                                        min={0}
                                                                        onChange={e => {this.setState({numbers_tokens_per_service_per_day: e.target.value})}}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </div>
                                                <div className="col-md-12">
                                                    <Form className="form-horizontal">
                                                        <Row>
                                                            <Label md="4">Number of Displays per Sub Location</Label>
                                                            <Col md="3">
                                                                <FormGroup>
                                                                    <Input
                                                                        value={this.state.numbers_displays_per_sublocation}
                                                                        type="number"
                                                                        min={0}
                                                                        onChange={e => {this.setState({numbers_displays_per_sublocation: e.target.value})}}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </div>
                                                <div className="col-md-12">
                                                    <Form className="form-horizontal">
                                                        <Row>
                                                            <Label md="4">Number of Image Ads per Display</Label>
                                                            <Col md="3">
                                                                <FormGroup>
                                                                    <Input
                                                                        value={this.state.numbers_image_ads_per_display}
                                                                        type="number"
                                                                        min={0}
                                                                        onChange={e => {this.setState({numbers_image_ads_per_display: e.target.value})}}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </div>
                                                <div className="col-md-12">
                                                    <Form className="form-horizontal">
                                                        <Row>
                                                            <Label md="4">Number of Video Ads per Display</Label>
                                                            <Col md="3">
                                                                <FormGroup>
                                                                    <Input
                                                                        value={this.state.numbers_video_ads_per_display}
                                                                        type="number"
                                                                        min={0}
                                                                        onChange={e => {this.setState({numbers_video_ads_per_display: e.target.value})}}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </div>
                                                <div className="col-md-12">
                                                    <Form className="form-horizontal">
                                                        <Row>
                                                            <Label md="4">Number of Users Per Sublocation</Label>
                                                            <Col md="3">
                                                                <FormGroup>
                                                                    <Input
                                                                        value={this.state.numbers_users_per_sublocation}
                                                                        type="number"
                                                                        min={0}
                                                                        onChange={e => {this.setState({numbers_users_per_sublocation: e.target.value})}}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </div>
                                                <div className="col-md-12">
                                                    <Form className="form-horizontal">
                                                        <Row>
                                                            <Label sm="4">Mobile Application</Label>
                                                            <Col className="checkbox-radios" sm="8">
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input
                                                                            type="checkbox"
                                                                            checked={this.state.enable_mobile?true:false}
                                                                            onChange={e =>
                                                                            {this.setState({enable_mobile: !this.state.enable_mobile})}
                                                                            }
                                                                        />
                                                                        <span className="form-check-sign" />
                                                                        Enabled
                                                                    </Label>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </div>
                                                <div className="col-md-12">
                                                    <Form className="form-horizontal">
                                                        <Row>
                                                            <Label sm="4">Static IP</Label>
                                                            <Col className="checkbox-radios" sm="8">
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input
                                                                            type="checkbox"
                                                                            checked={this.state.is_static_IP?true:false}
                                                                            onChange={e =>
                                                                            {this.setState({is_static_IP: !this.state.is_static_IP})}
                                                                            }
                                                                        />
                                                                        <span className="form-check-sign" />
                                                                        Enabled
                                                                    </Label>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </div>
                                                <div className="col-md-12">
                                                    <Form className="form-horizontal">
                                                        <Row>
                                                            <Label sm="4">Self Service Branding and customization</Label>
                                                            <Col className="checkbox-radios" sm="8">
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input
                                                                            type="checkbox"
                                                                            checked={this.state.is_customizable_service?true:false}
                                                                            onChange={e =>
                                                                            {this.setState({is_customizable_service: !this.state.is_customizable_service})}
                                                                            }
                                                                        />
                                                                        <span className="form-check-sign" />
                                                                        Enabled
                                                                    </Label>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </div>
                                                <div className="col-md-12">
                                                    <Form className="form-horizontal">
                                                        <Row>
                                                            <Label md="4">Statistics and Standard Reporting</Label>
                                                            <Col md="8">
                                                                <FormGroup>
                                                                    <Select
                                                                        className="react-select info"
                                                                        classNamePrefix="react-select"
                                                                        value={this.state.reporting}
                                                                        onChange={value =>
                                                                            this.setState({ reporting: value })
                                                                        }
                                                                        options={this.state.reporting_list}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </div>
                                                <div className="col-md-12">
                                                    <Form className="form-horizontal">
                                                        <Row>
                                                            <Label sm="4">Customer Feedback Module</Label>
                                                            <Col className="checkbox-radios" sm="8">
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input
                                                                            type="checkbox"
                                                                            checked={this.state.has_customer_feedback?true:false}
                                                                            onChange={e =>
                                                                            {this.setState({has_customer_feedback: !this.state.has_customer_feedback})}
                                                                            }
                                                                        />
                                                                        <span className="form-check-sign" />
                                                                        Enabled
                                                                    </Label>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </div>
                                                <div className="col-md-12">
                                                    <Form className="form-horizontal">
                                                        <Row>
                                                            <Label sm="4">APIs</Label>
                                                            <Col className="checkbox-radios" sm="8">
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input
                                                                            type="checkbox"
                                                                            checked={this.state.is_api?true:false}
                                                                            onChange={e =>
                                                                            {this.setState({is_api: !this.state.is_api})}
                                                                            }
                                                                        />
                                                                        <span className="form-check-sign" />
                                                                        Enabled
                                                                    </Label>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </div>
                                                <div className="col-md-12">
                                                    <Form className="form-horizontal">
                                                        <Row>
                                                            <Label md="4">Archived for</Label>
                                                            <Col md="8">
                                                                <FormGroup>
                                                                    <Select
                                                                        className="react-select info"
                                                                        classNamePrefix="react-select"
                                                                        value={this.state.archived_for}
                                                                        onChange={value =>
                                                                            this.setState({ archived_for: value })
                                                                        }
                                                                        options={this.state.archive_list}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </div>
                                                <div className="col-md-12">
                                                    <Form className="form-horizontal">
                                                        <Row>
                                                            <Label sm="4">Free Updates</Label>
                                                            <Col className="checkbox-radios" sm="8">
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input
                                                                            type="checkbox"
                                                                            checked={this.state.has_free_updates?true:false}
                                                                            onChange={e =>
                                                                            {this.setState({has_free_updates: !this.state.has_free_updates})}
                                                                            }
                                                                        />
                                                                        <span className="form-check-sign" />
                                                                        Enabled
                                                                    </Label>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </div>
                                                <div className="col-md-12">
                                                    <Form className="form-horizontal">
                                                        <Row>
                                                            <Label md="4">Support</Label>
                                                            <Col md="8">
                                                                <FormGroup>
                                                                    <Select
                                                                        className="react-select info"
                                                                        classNamePrefix="react-select"
                                                                        value={this.state.has_support}
                                                                        onChange={value =>
                                                                            this.setState({ has_support: value })
                                                                        }
                                                                        options={this.state.support_list}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </div>
                                                <div className="col-md-12">
                                                    <Form className="form-horizontal">
                                                        <Row>
                                                            <Label md="4">Monthly Price (USD)</Label>
                                                            <Col md="3">
                                                                <FormGroup>
                                                                    <Input
                                                                        value={this.state.monthly_price}
                                                                        type="number"
                                                                        min={0}
                                                                        readOnly
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </div>
                                                <div className="col-md-12">
                                                    <Form className="form-horizontal">
                                                        <Row>
                                                            <Label md="4">Annual Price (USD)</Label>
                                                            <Col md="3">
                                                                <FormGroup>
                                                                    <Input
                                                                        value={this.state.annual_price}
                                                                        type="number"
                                                                        min={0}
                                                                        readOnly
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </div>
                                                <div className="col-md-12">
                                                    <Form className="form-horizontal">
                                                        <Row>
                                                            <Label sm="4">Trail Package</Label>
                                                            <Col className="checkbox-radios" sm="2">
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input
                                                                            type="checkbox"
                                                                            checked={this.state.is_trial?true:false}
                                                                            onChange={e =>
                                                                            {this.setState({is_trial: !this.state.is_trial})}
                                                                            }
                                                                        />
                                                                        <span className="form-check-sign" />
                                                                        Enabled
                                                                    </Label>
                                                                </FormGroup>
                                                            </Col>
                                                            <Label md="2">Trial Days</Label>
                                                            <Col md="4">
                                                                <FormGroup>
                                                                    <Input
                                                                        disabled={!this.state.is_trial}
                                                                        value={this.state.trial_days}
                                                                        type="number"
                                                                        min={0}
                                                                        onChange={e => {this.setState({trial_days: e.target.value})}}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </div>
                                                <div className="col-md-12">
                                                    <Form className="form-horizontal">
                                                        <Row>
                                                            <Label sm="4">User Guides, Training Documents and Videos</Label>
                                                            <Col className="checkbox-radios" sm="8">
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input
                                                                            type="checkbox"
                                                                            checked={this.state.has_user_guides?true:false}
                                                                            onChange={e =>
                                                                            {this.setState({has_user_guides: !this.state.has_user_guides})}
                                                                            }
                                                                        />
                                                                        <span className="form-check-sign" />
                                                                        Enabled
                                                                    </Label>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </div>
                                                <div className="col-md-12">
                                                    <Form className="form-horizontal">
                                                        <Row>
                                                            <Label sm="4">Designated Account Manager</Label>
                                                            <Col className="checkbox-radios" sm="8">
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input
                                                                            type="checkbox"
                                                                            checked={this.state.is_designated_account_manager?true:false}
                                                                            onChange={e =>
                                                                            {this.setState({is_designated_account_manager: !this.state.is_designated_account_manager})}
                                                                            }
                                                                        />
                                                                        <span className="form-check-sign" />
                                                                        Enabled
                                                                    </Label>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </div>
                                                <div className="col-md-12">
                                                    <Form className="form-horizontal">
                                                        <Row>
                                                            <Label md="4">Order Sequence</Label>
                                                            <Col md="3">
                                                                <FormGroup>
                                                                    <Input
                                                                        value={this.state.order_sequence}
                                                                        type="number"
                                                                        min={0}
                                                                        onChange={e => {this.setState({order_sequence: e.target.value})}}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </div>
                                                <div className="col-md-12">
                                                    <Form className="form-horizontal">
                                                        <Row>
                                                            <Label md="4">Package Category</Label>
                                                            <Col md="8">
                                                                <FormGroup>
                                                                    <Select
                                                                        className="react-select info"
                                                                        classNamePrefix="react-select"
                                                                        value={this.state.category}
                                                                        onChange={value =>
                                                                            this.setState({ category: value })
                                                                        }
                                                                        options={this.state.category_list}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </div>
                                                <div className="col-md-12">
                                                    <Form className="form-horizontal">
                                                        <Row>
                                                            <Label md="4">More Details</Label>
                                                            <Col md="8">
                                                                <FormGroup>
                                                                    <Input
                                                                        value={this.state.detail}
                                                                        type="textarea"
                                                                        onChange={e => {this.setState({detail: e.target.value})}}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-md-2"/>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </LoadingOverlay>
            </>
        );
    }
}

export default PackageEdit;
