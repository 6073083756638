        
import React, { useState } from "react";
import LoadingOverlay from "react-loading-overlay";
// import ChartistGraph from "react-chartist";

import Firebase from "firebase";

import Select from 'react-select';

import 'react-tagsinput/react-tagsinput.css' // If using WebPack and style-loader.
import _ from 'lodash'

import * as am4core from "@amcharts/amcharts4/core";

import { Dropdown, DropdownItem, DropdownMenu } from 'semantic-ui-react'
import 'bootstrap/dist/css/bootstrap.css';

import {
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row
} from "reactstrap";

import { DateRangePicker } from '@progress/kendo-react-dateinputs';
import { CustomStartDateInput } from './customStartDateInput.jsx';
import { CustomEndDateInput } from './customEndDateInput.jsx';
import { CSVLink } from "react-csv";
import moment from "moment-timezone";
import config from "../../../config";


import ServingLocLineChart from '../../../components/amCharts/ServingLocLineChart';
import WaitingLocLineChart from '../../../components/amCharts/WaitingLocLineChart';

const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
}

class Report3Mean extends React.Component {

    constructor(props) {

        super(props);
        if (!Firebase.apps.length) {
            Firebase.initializeApp(config);
        }

        this.state = {
            dateRangeLimitSeconds: 5184000, // number of seconds in 1 day = 86400s
                                            // number of seconds in 30 days = (30 X 86400) = 2592000s
                                            // number of seconds in 60 days = (60 x 86400) = 5184000s
            dateRangeLimitDays:60,
            loading: false,
            
            defaultDate: {},
            bVisualize: false,
            start_date_formatted: '',
            end_date_formatted: '',
            defaultTimeZone: '',
            defaultTimeZoneSet: false,

            bLoaded : false,
            bdelete : false,
            startDate: '',
            endData: '',
            main_count:1,
            download_data : [],
            access_loc : [],
            time_segment :[],
            wtime_segment : [],
            top1 : 0,
            top2 : 0,
            main_location_list: [],
            addressDefinitions : [],
            sub_location_list: [],
            subTemp : [],
            sub_location_ID: [],
           
            services:[],
            servTemp : [],
            stateOptions: [],
           
            counter_user:[],
            counter_user1:[],

            counteruser:[],
            custom_rating:[],
            custom_rating1:[],

            customrating:[],
            token_status : [],
            token_status1 : [],

            tokenstatus :[],
            serv_Username : [],
            serv_Username1 : [],

            servUsername : [],

            detailTokenData:[],
           
            dataLineChartServing : [],

            dataLineChartWaiting : [],

            bdate : false,
            bmain : false,


        };

        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangesub = this.handleChangesub.bind(this);
        this.handleChangeService = this.handleChangeService.bind(this);
        this.handleChangeCounterUser = this.handleChangeCounterUser.bind(this);
        this.handleChangecustomrating = this.handleChangecustomrating.bind(this);
        this.handleChangetokenstatus = this.handleChangetokenstatus.bind(this);
        this.handleChangeservUsername = this.handleChangeservUsername.bind(this);
    }
    
    componentDidMount() {
        this.setState({bLoaded : true});
        this.setState({loading : true});
        // this.loadDefaultDate();
        this.loadAccessLoc();
    }

    loadAccessLoc(){
        let _this = this;
        let access = [];
        let temp = [];
        let i = 0;
        let email = JSON.parse(localStorage.getItem('auth_info')).email;
        Firebase.firestore().collection('Web_App_Users').doc(email).get().then(function (response) {
            if (response.exists) {
                access.push(response.data().Accessible_Locations);
            };
            // alert(access[8] + "//" + access[9] + "//" + access[10])
            for( let i = 0 ; i < access.length ; i++ )
            {
                let id = access[i];
                // temp.push({label:id});

                let len = id.length;
                for( let j = 0 ; j < len ; j++ )
                {
                    let subid = id[j];
                    // let b = _this.boolExist(temp, subid);
                    // if( b === 0)
                        temp.push({label:subid});
                }
            }
            if (access.length > 0) {
                _this.state.access_loc = temp;
                _this.loadSubLocationByMain(temp);
                // _this.setState({access_loc: temp});
            }
        }) ;
    }
    componentWillUnmount(){
        // this.setState({loading: false});
    }

    // componentDidUpdate(e) {
    // }

    handleChangeDate(event) {
        let _this = this;
        let dateObject = event.target.value;
        _this.setState({ defaultDate: dateObject });

        let defaultTimeZone = _this.state.defaultTimeZone;
        let defaultTimeZoneSet = _this.state.defaultTimeZoneSet;
        let startDateFmt = "", endDateFmt = "";

        if (defaultTimeZoneSet && _this.isValidJSDate(dateObject.start)) {
            startDateFmt = parseInt(moment(dateObject.start).tz(defaultTimeZone).unix());
            startDateFmt = (moment(new Date(startDateFmt * 1000)).tz(defaultTimeZone).format("YYYY-MM-DD")).toString(); 
            _this.setState({ start_date_formatted: startDateFmt });
        }

        if (defaultTimeZoneSet && _this.isValidJSDate(dateObject.end)) {
            endDateFmt = parseInt((moment(dateObject.end).tz(defaultTimeZone).endOf('day')).unix()) + 1;
            endDateFmt = (moment(new Date(endDateFmt * 1000)).tz(defaultTimeZone).format("YYYY-MM-DD")).toString(); 
            _this.setState({ end_date_formatted: endDateFmt });
        }

        console.log("handleChangeDate>>", "dateObject", dateObject, "defaultTimeZone", defaultTimeZone, "startDateFmt", startDateFmt, "endDateFmt", endDateFmt);
    }

    isValidJSDate(jsDateObj) {
        var timestamp = Date.parse(jsDateObj);
        if (!(isNaN(timestamp))) { return true; }
        return false;
    }

    loadMainLocations(temp) {
        let _this = this;
        // let access = temp;
        let main_locations = [];
        let mainTemp = [];
        let i = 0;
        let customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;

        Firebase.firestore().collection('Main_Locations').where('Customer_ID', '==', customer_id).get().then(function (response) {
            response.docs.forEach(function (doc) {
                main_locations.push({ value: doc.id, label: doc.data().Name });
            });

            for (i = 0; i < main_locations.length; i++) {
                let nn = main_locations[i].value;
                let b = _this.boolExist(temp, nn);
                if (b === 1)
                    mainTemp.push({ value: main_locations[i].value, label: main_locations[i].label })
            }

            if (mainTemp.length > 0) {
                _this.setState({ main_location_list: mainTemp, addressDefinitions: mainTemp });
                // _this.loadSubLocationByMain(temp, mainTemp);
            }
        }, (error) => {
            console.log("loadMainLocations Error===>", error);
        });
    }

    loadSubLocationByMain(temp) {
        let _this = this;
        let defaultTimeZone = _this.state.defaultTimeZone;
        let TT = [], main = [], main2 = [];
        let sub_locations = [];
        let i = 0;
        let customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;
        Firebase.firestore().collection('Sub_Locations').where('Customer_ID', '==', customer_id).get().then(function (response) {
            response.docs.forEach(function (doc) {
                sub_locations.push({ value: doc.id, label: doc.data().Name, mID: doc.data().Main_Location_ID, timezone: doc.data().TimeZone });
                if (defaultTimeZone == "" || defaultTimeZone == undefined || defaultTimeZone == null) {
                    let timeZoneParts = doc.data().TimeZone.split(")");
                    defaultTimeZone = timeZoneParts[1];
                    _this.setState({ defaultTimeZone: defaultTimeZone });
                    _this.setState({ defaultTimeZoneSet: true });
                    console.log("defaultTimeZone", defaultTimeZone, "defaultTimeZoneSet", true);
                    _this.loadDefaultDate();
                }
                main.push({ label: doc.data().Main_Location_ID });
            });
            for (i = 0; i < sub_locations.length; i++) {
                // let mm = sub_locations[i].mID;
                // let bb = _this.boolExistID(mainTemp, mm);
                // if( bb === 0 ) continue;

                let nn = sub_locations[i].value;
                let b = _this.boolExist(temp, nn);
                if (b === 1) {
                    TT.push({ value: sub_locations[i].value, label: sub_locations[i].label, mID: sub_locations[i].mID, timezone: sub_locations[i].timezone })
                    let mainId = sub_locations[i].mID;
                    let bb = _this.boolExist(main, mainId);
                    if (bb === 1)
                        main2.push(main[i])
                }
            }
            if (TT.length > 0) {
                _this.setState({ sub_location_list: TT, sub_location_ID: TT });
                _this.setState({ subTemp: TT });
                // // console.log("loadSubLocationByMain >>TT", TT);
                _this.loadMainLocations(main2);
                _this.loadServicesByMainAndSub(main2, TT);
            }
        }, (error) => {
            console.log("loadSubLocationByMain Error==>", error);
        });
    }

    loadServicesByMainAndSub(main, sub) {
        let _this = this;
        let servicesT = [];
        let Ser = [];
        let subValue = [];
        let customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;
        sub.map((subItem, index) => {
            subValue.push(subItem.value);
        });
        Firebase.firestore().collection('Services').where('Customer_ID', '==', customer_id).get().then(function (response) {
            response.docs.forEach(function (doc) {
                if (subValue.includes(doc.data().Sub_Location_ID)) {
                    // console.log("services ==> ", doc)
                    servicesT.push({ value: doc.id, label: doc.data().Name, mID: doc.data().Main_Location_ID, sID: doc.data().Sub_Location_ID });
                    // servicesInfo.push({value:doc.data().Sub_Location_ID, label: doc.data().Name,  date: Sec});
                }
            });

            for (let i = 0; i < servicesT.length; i++) {
                let mm = servicesT[i].mID;
                let bb = _this.boolExist(main, mm);
                if (bb === 0) continue;

                let nn = servicesT[i].sID;

                let b = _this.boolExistID(sub, nn);
                if (b === 1)
                    Ser.push(servicesT[i]);
            }
            if (Ser.length > 0) {
                // console.log("service===>", Ser);
                _this.setState({ services: Ser, servTemp: Ser, stateOptions: Ser });
                _this.setState({ loading: false });
                // _this.loadTokenData();
            } else {
                console.log("No Services..");
                _this.setState({loading: false});
            }

        }, (error) => {
            console.log("loadServicesByMainAndSub Error ===> ", error);
            _this.setState({ loading: false });
        });
        // })
    }

    loadDefaultDate() {
        let _this = this;
        let today = new Date();
        let y = today.getFullYear();
        let m = today.getMonth();
        let d = today.getDate();

        var dateObject = {
            start: new Date(y, m, 1),
            end: new Date(y, m, d)
        };

        _this.setState({ defaultDate: dateObject });

        let defaultTimeZone = _this.state.defaultTimeZone;
        let defaultTimeZoneSet = _this.state.defaultTimeZoneSet;
        let startDateFmt = "", endDateFmt = "";  

        if (defaultTimeZoneSet && _this.isValidJSDate(dateObject.start)) {
            startDateFmt = parseInt(moment(dateObject.start).tz(defaultTimeZone).unix());
            startDateFmt = (moment(new Date(startDateFmt * 1000)).tz(defaultTimeZone).format("YYYY-MM-DD")).toString(); 
            _this.setState({ start_date_formatted: startDateFmt });
        }

        if (defaultTimeZoneSet && _this.isValidJSDate(dateObject.end)) {
            endDateFmt = parseInt((moment(dateObject.end).tz(defaultTimeZone).endOf('day')).unix()) + 1;
            endDateFmt = (moment(new Date(endDateFmt * 1000)).tz(defaultTimeZone).format("YYYY-MM-DD")).toString(); 
            _this.setState({ end_date_formatted: endDateFmt });
        }

        console.log("loadDefaultDate>>", "dateObject", dateObject, "defaultTimeZone", defaultTimeZone, "startDateFmt", startDateFmt, "endDateFmt", endDateFmt);
    }

    handleChange(selectedOption) {
        this.state.main_location_list = selectedOption;
        this.state.sub_location_list = [];
        this.LoadSubLocation();
       
        if( this.state.bVisualize )
        {
            this.setState({bmain : true});
            sleep(40).then(() => {
                this.dataFiltering();
            });
        }
    }
   
    LoadSubLocation(){
        let _this = this;
        let SelOpt = _this.state.main_location_list;
        let nL = SelOpt.length;
        let sub = _this.state.subTemp;
        let subtt = [];
        _this.setState({sub_location_list: []});

        for( let j = 0 ; j < nL ; j++ )
         {   for( let i = 0 ; i < sub.length ; i++)
            {
                if( SelOpt[j].value === sub[i].mID )
                    subtt.push({value : sub[i].value, label : sub[i].label, mID:sub[i].mID, timezone:sub[i].timezone});
            }
        }
        _this.state.sub_location_list = subtt;
        _this.setState({sub_location_list : subtt, sub_location_ID : subtt });

        _this.LoadServNamesBySL();

    };

    handleChangesub(selectItem){
        this.state.sub_location_list = selectItem;
        this.setState({sub_location_list : selectItem});
        this.LoadServNamesBySL();
        if( this.state.bVisualize )
        {
            this.setState({bmain : true});
            sleep(40).then(() => {
                this.dataFiltering();
            });
        }
    }

    LoadServNamesBySL(){
        let _this = this;
        let SelOpt = _this.state.sub_location_list;
        let nL = SelOpt.length;

        let sub = _this.state.servTemp;
        let subtt = [];

        _this.setState({services: []});

        for( let j = 0 ; j < nL ; j++ )
           for( let i = 0 ; i < sub.length ; i++)
                if( SelOpt[j].value === sub[i].sID )
                    subtt.push({value : sub[i].value, label : sub[i].label, mID:sub[i].mID, sID:sub[i].sID});
        _this.state.services = subtt;
        _this.state.stateOptions = subtt;
        _this.setState({services : subtt, stateOptions : subtt });
    };

    handleChangeService(selectItemService){
        this.state.services = selectItemService;
        this.setState({ services : selectItemService});

        if( this.state.bVisualize )
        {
            this.setState({bmain : true});
            sleep(40).then(() => {
                this.dataFiltering();
            });
        }
        
    }

    handleChangeCounterUser(selected){
        let src = this.state.detailTokenData;

        this.state.counter_user= [];
        this.state.counter_user = selected;
        this.setState({counter_user : selected});

        if( this.state.bVisualize )
        {
            this.setState({bmain : true});
            sleep(40).then(() => {
                this.dataFiltering();
            });
        }
    }

    boolMainLoc(id){
        let IDArr = this.state.main_location_list;

        let len = IDArr.length;

        for( let i = 0 ; i < len ; i++ )
        {
            let tt = IDArr[i].value;
            if( tt === id )
                return 1;
        }
        return 0;
    }
    boolSubLoc(id){
        let IDArr = this.state.sub_location_list;

        let len = IDArr.length;
        for( let i = 0 ; i < len ; i++ )
        {
            let tt = IDArr[i].value;
            if( tt === id )
                return 1;
        }
        return 0;
    }
    boolService(id){
        let IDArr = this.state.services;
        let nCnt = 0;
        let lenid = id.length;
        let len = IDArr.length;
        for( let j = 0 ; j < lenid ; j++ )
            for( let i = 0 ; i < len ; i++ )
            {
                let tt = IDArr[i].value;
                if( tt === id[j] )
                    nCnt++;
                if( nCnt === lenid )
                    return 1;
            }

        return 0;
    }
    boolCounter(id){
        let IDArr = this.state.counteruser;
        let nCnt = 0;
        let len = IDArr.length;
        for( let i = 0 ; i < len ; i++ )
        {
            let tt = IDArr[i].value;
            if( tt === id )
                return 1;
        }

        return 0;
    }
    getTimezone(id){
        let _this = this;
        let sub = _this.state.sub_location_list;
        for( let i = 0 ; i < sub.length ; i++ )
        {
            let tt = sub[i].timezone;
            let t = tt.split(")");

            if( id === sub[i].value)
                return t[1];
        }
    }
    formatDate(date) {
        var d = new Date(date), month = '' + (d.getMonth() + 1), day = '' + d.getDate(), year = d.getFullYear();
        if (month.length < 2) { month = '0' + month } if (day.length < 2) { day = '0' + day }
        return [year, month, day].join('-');
    }

    // loadtokenData2(){
    //     let _this = this;
    //     let totalData = [];
    //     let start = _this.state.defaultDate.start;
    //     let end = _this.state.defaultDate.end;
    //     let startSec = Date.parse(start)/1000;
    //     let endSec = Date.parse(end)/1000;

    //     _this.state.detailTokenData = [];
    //     Firebase.firestore().collection('Token_Details').get().then(function (response) {

    //         response.docs.forEach(function (doc) {
    //             var Day = doc.data().Created_Datetime;
    //             var str = Day.substring(0, 3);
    //             let m = 0;

    //             switch(str)
    //             {
    //                 case "Jan": m = 0; break;
    //                 case "Feb": m = 1; break;
    //                 case "Mar": m = 2; break;
    //                 case "Apr": m = 3; break;
    //                 case "May": m = 4; break;
    //                 case "Jun": m = 5; break;
    //                 case "Jul": m = 6; break;
    //                 case "Aug": m = 7; break;
    //                 case "Sep": m = 8; break;
    //                 case "Oct": m = 9; break;
    //                 case "Nov": m = 10; break;
    //                 case "Dec": m = 11; break;
    //             }
    //             str = Day.substring(4, 7);
    //             let d = parseInt(str, 10);
    //             str = Day.substring(7, 12);
    //             let y = parseInt(str, 10);
    //             str = Day.substring(16, 19);
    //             let hh = parseInt(str, 10);
    //             str = Day.substring(18, 20);
    //             let mm = parseInt(str, 10);
    //             str = Day.substring(21, 24);
    //             let ss = parseInt(str, 10);

    //             let ID2 = doc.data().Sub_Location_ID;
    //             let timez = _this.getTimezone(ID2);
                 
    //             Day = new Date(y, m, d, hh, mm, ss).toLocaleString("en-US", {timeZone: timez});
    //             let Sec = new Date(Day);
    //             let sec = Date.parse(Sec)/1000;
    //             if( sec > startSec && sec < endSec)
    //             {
    //                 let ID1 = doc.data().Main_Location_ID;
    //                 let ID3 = doc.data().Services_ID;
    
    //                 let main = doc.data().Main_Location_Name;
    //                 let sub = doc.data().Sub_Location_Name;
    //                 let serv= doc.data().Service_Name;
    //                 let scd = doc.data().Service_Cancelled_Datetime;
    //                 let mauser = doc.data().Mobile_App_User_Contact;
    //                 let mauid = doc.data().Mobile_App_User_ID;
    //                 let cc = doc.data().Customer_Comments;
    //                 let cusID=doc.data().Customer_ID;
    //                 let cusRDtime = doc.data().Customer_Rated_Datetime;
    //                 let name = doc.data().Customer_Rating;
    //                 let cusScr = doc.data().Customer_Source;
    //                 let counterN = doc.data().Served_Counter;
    //                 let pt = doc.data().Priority;
    //                 let ptStr = parseInt(pt) == 0 ? "Normal" : "High";
    //                 let tcun = doc.data().Token_Created_User_Name;
    //                 let tn = doc.data().Token_Number;
    //                 let token = doc.data().Token_Status;
    //                 let suname = doc.data().Served_User_Name;
    //                 let inputsource = doc.data().Input_Source;

    //                 let st = doc.data().Serving_Time;
    //                 let wt = doc.data().Waiting_Time;
    //                 var a = [], seconds = 0;

    //                 if( st.length > 4 )
    //                 {
    //                     a = st.split(':'); // split it at the colons
    //                     seconds= (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]); 
    //                 }
    //                 var b = parseInt(wt, 10);
    //                 totalData.push({id: doc.id, date: Sec, Day: Day, mainID:main, mID: ID1, subID:sub, sID: ID2, serID:serv, vID: ID3, SCDT: scd,
    //                     MAUC: mauser, MAUI: mauid, CC:cc, CUSID: cusID, CUSRDT: cusRDtime, label: name, CUSSRC:cusScr, inputSrc: inputsource,
    //                     counteruser: counterN, servusr: suname, TCUN: tcun, TN: tn, token: token,
    //                     ST: seconds, WT: b, Prio: ptStr });
    //             }

    //         });
            
    //         if( totalData.length > 0 )
    //         {
    //             _this.state.detailTokenData = totalData;
    //             _this.setState({detailTokenData : totalData});
    //             _this.subfiltering();
    //         }

    //     })
    // }
    loadTokenData() {
        let _this = this,
            startDateFmt = _this.state.start_date_formatted, endDateFmt = _this.state.end_date_formatted;
        let subLocIDArrVals = _this.exportArrayValues(_this.state.sub_location_ID),
            servIDArrVals = _this.exportArrayValues(_this.state.services);
        let totalData = [];
        _this.setState({ detailTokenData: [] });
        // let customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;

        console.log({
            "Currently running function:": "loadTokenData",
            start_date: startDateFmt,
            end_date: endDateFmt,
            sub_loc_arr: subLocIDArrVals,
            servs_arr: servIDArrVals,
            query_type: "aggr_1"
        });

        Firebase.functions().httpsCallable('getBigQueryAnalyticsData', { timeout: 540000 }) ({ //HttpsCallableOptions timeout in ms
            start_date: startDateFmt,
            end_date: endDateFmt,
            sub_loc_arr: subLocIDArrVals,
            servs_arr: servIDArrVals,
            query_type: "aggr_1"
        }).then(function (response) {

            console.log('response.data.length:' + response.data.length);

            if (response.data.length == 0) {

                _this.notifyMessage("tc", 3, "No data found for these selected dates, locations and services!");
                window.setTimeout(function () { _this.props.history.push("/dashboards") }, 2000);

            } else {
                JSON.parse(response.data).forEach(function (doc) {
                    // console.logs(i, "doc",doc);
                    if (_this.boolExistID(_this.state.services, doc.Services_ID) === 1) {
                        //let dateTimeString = (doc.Created_Date_Formated).toString();
                        let DayStr = doc.Created_Date_Formated;
                        let DayStr1 = DayStr.value;
                        let Day = (Date.parse(DayStr1)) / 1000; //new Date((dateTimeString.substring(0, 4),(dateTimeString.substring(5, 7),(dateTimeString.substring(8, 11))))); //Created_Datetime Created_Datetime_Unix_Timestamp
                        let ID2 = doc.Sub_Location_ID;
                        console.log("doc.Created_Date_Formated", doc.Created_Date_Formated);
                        //console.log("dateTimeString",Day);
                        console.log("Day", Day);
                        let Sec = new Date(Day * 1000);
                        let ID1 = doc.Main_Location_ID;
                        let ID3 = doc.Services_ID;
    
                        let main = doc.Main_Location_Name;
                        let sub = doc.Sub_Location_Name;
                        let serv = doc.Service_Name;
                        let scd = doc.Service_Cancelled_Datetime;
                        let mauser = doc.Mobile_App_User_Contact;
                        let mauid = doc.Mobile_App_User_ID;
                        let cc = doc.Customer_Comments;
                        let cusID = doc.Customer_ID;
                        let cusRDtime = doc.Customer_Rated_Datetime;
                        let name = doc.Customer_Rating;
                        let cusScr = doc.Customer_Source;
                        let counterN = doc.Served_Counter;
                        let pt = doc.Priority;
                        let ptStr = parseInt(pt) == 0 ? "Normal" : "High";
                        let tcun = doc.Token_Created_User_Name;
                        let tn = doc.Token_Number;
                        let token = doc.Token_Status;
                        let suname = doc.Served_User_Name;
                        let inputsource = doc.Input_Source;

                        let st = doc.Serving_Time;
                        let wt = doc.Waiting_Time;
                        var a = [], seconds = 0;

                        if (st.length > 4) {
                            a = st.split(':'); // split it at the colons
                            seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
                        }
                        var b = parseInt(wt, 10);
                        totalData.push({
                            id: doc.id, date: Sec, Day: Day, mainID: main, mID: ID1, subID: sub, sID: ID2, serID: serv, vID: ID3, SCDT: scd,
                            MAUC: mauser, MAUI: mauid, CC: cc, CUSID: cusID, CUSRDT: cusRDtime, label: name, CUSSRC: cusScr, inputSrc: inputsource,
                            counteruser: counterN, servusr: suname, TCUN: tcun, TN: tn, token: token,
                            ST: seconds, WT: b, Prio: ptStr
                        });
                    }
                });

                // if (totalData.length > 0) {
                    // _this.setState({ loading: false });
                    //_this.setState({ detailTokenData: totalData });
                // }
            }

        }).then(function () {
            if (totalData.length > 0) {
                _this.setState({ loading: false });
                _this.setState({ detailTokenData: totalData });
                // _this.setState({ loading: false });
                console.log("reached here #3 success");
                _this.subfiltering([], 0);
            }
        }).catch(function (error) {
            _this.setState({ loading: false });
            console.log("reached here #4 error", error);
        });
        console.log("reached here #5 done");
    }
    
    handleChangetokenstatus(select){
        let src = this.state.detailTokenData;

        this.state.token_status= [];
        this.state.token_status = select;
    
        this.setState({token_status : select});
        if( this.state.bVisualize )
        {
            this.setState({bmain : true});
            sleep(40).then(() => {
                this.dataFiltering();
            });
        }

    };
    handleChangecustomrating(select){
        let src = this.state.detailTokenData;
    //    this.state.custom_rating= [];
        this.state.custom_rating = select;
        this.setState({custom_rating : select});

        if( this.state.bVisualize )
        {
            this.setState({bmain : true});
            sleep(40).then(() => {
                this.dataFiltering();
            });
        }
    }

    handleChangeservUsername(select){
        let src = this.state.detailTokenData;

        this.state.serv_Username= [];
        this.state.serv_Username = select;
        this.setState({serv_Username : select});
        if( this.state.bVisualize )
        {
            this.setState({bmain : true});
            sleep(40).then(() => {
                this.dataFiltering();
            });
        }

    };

    onVisualize() {
        let _this = this;
        if (!_this.state.bVisualize) {
            let start = _this.state.defaultDate.start, end = _this.state.defaultDate.end;
            // let startSec = Date.parse(start) / 1000, endSec = Date.parse(end) / 1000;
            let startSec = Date.parse(start) / 1000, endSec = (Date.parse(end) + 86400) / 1000; // number of seconds in 1 day = 86400s
            // let startDateFmt = _this.formatDate(start), endDateFmt = _this.formatDate(end);
            if ((endSec - startSec) > (_this.state.dateRangeLimitSeconds)) {
                _this.setState({ loading: false });
                _this.notifyMessage("tc", 3, `Please select a date range less than ${_this.state.dateRangeLimitDays} days!`);
            } else {
                _this.setState({ loading: true });
                // // console.log("selected Segmentation Type:", _this.state.segmentationType);
                _this.loadTokenData();
                // _this.subfiltering([], 0);
            }
        }
    }

    mainFiltering(){
        let total = this.state.detailTokenData;
        let len = total.length;
        let main = [], sub = [], service = [];
        for (let i = 0 ; i < len ; i++ )
        {
            let b1 = this.boolExist(main, total[i].mainID );
            let b2 = this.boolExist(sub, total[i].subID );
            let b3 = this.boolExist(service, total[i].serID );

            if( b1 === 1 && b2 === 1 && b3 ===1 ) continue;

            if( b1 === 0 ) 
            {
                main.push({value : total[i].id, label: total[i].mainID, mID: total[i].mID, sID: total[i].sID, vID : total[i].vID });
                // addressDefinitions.push({value : total[i].id, label: total[i].mainID, mID: total[i].mID, sID: total[i].sID, vID : total[i].vID });
            }
            if( b2 === 0 ) 
                sub.push({value: total[i].id, label: total[i].subID, mID: total[i].mID, sID: total[i].sID, vID : total[i].vID })
            if( b3 === 0 ) 
                service.push({value: total[i].id, label: total[i].serID,  mID: total[i].mID, sID: total[i].sID, vID : total[i].vID })
        }

        this.setState({main_location_list : main, addressDefinitions : main, sub_location_list : sub, subTemp: sub, services : service, servTemp: service, sub_location_ID : sub, stateOptions: service});
    }

    boolExist(temp, name){
        let bN = 0;
        for( let j = 0 ; j < temp.length ; j++ )
        {
            if( name === temp[j].label )
            {
                bN = 1;
                break;
            }
        }
        return bN;
    }

    boolExistID(temp, name){
        let bN = 0;
        for( let j = 0 ; j < temp.length ; j++ )
        {
            if( name === temp[j].value )
            {
                bN = 1;
                break;
            }
        }
        return bN;
    }

    dataFiltering(){
        let _this = this;
        let Src = _this.state.download_data;
        let len = Src.length;
        let main = _this.state.main_location_list;
        let sub = _this.state.sub_location_ID;
        let service = _this.state.services;
        let downloaddata = [];
        let Counter = _this.state.counter_user;
        let Token = _this.state.token_status;
        let Served = _this.state.serv_Username;
        let custom = _this.state.custom_rating;
        for (let i = 0 ; i < len ; i++ )
        {
            let mainN = Src[i].Main_Location_Name;
            if( _this.boolExist(main, mainN) === 0 ) continue;
            let subN = Src[i].Sub_Location_Name;
            if( _this.boolExist(sub, subN) === 0 ) continue;
            let serv = Src[i].Service_Name;
            if( _this.boolExist(service, serv) === 0 ) continue;

            let counter = Src[i].Served_Counter;
            if( _this.boolExist(Counter, counter) === 0 ) continue;

            let token = Src[i].Token_Status;
            if( _this.boolExist(Token, token) === 0 ) continue;

            let servedName = Src[i].Served_User_Name;
            if( _this.boolExist(Served, servedName) === 0 ) continue;

            downloaddata.push(Src[i]);
        }

        if( downloaddata.length > 0)
            _this.GetTokenDataByDay(downloaddata);

    }
    exportArrayValues(temp) {
        let result = [];
        for (let j = 0; j < temp.length; j++) {
            result.push(temp[j].value);
        }
        return result;
    }
    subfiltering(){
        let _this = this;
        let Src = _this.state.detailTokenData;
        let len = Src.length;
        // {id: doc.id,  mainID:main, mID: ID1, subID:sub, sID: ID2, serID:serv, vID: ID3,
        //     inputSrc: inputsource, counteruser: counterN, servusr: suname, token: token}
        let main = _this.state.main_location_list;
        let sub = _this.state.sub_location_ID;
        let service = _this.state.services;
        let downloaddata = [], waitdata = [];
        let Counter = [], Token = [], Served = [], Input = [];
        for (let i = 0 ; i < len ; i++ )
        {
            let ID1 = Src[i].mID;
            if(_this.boolExistID(main, ID1) === 0) continue;
            let ID2 = Src[i].sID;
            if( _this.boolExistID(sub, ID2) === 0) continue;

            let ID3 = Src[i].vID;
            if( _this.boolExistID(service, ID3) === 0 ) continue;

            let counter = Src[i].counteruser;
            let token = Src[i].token;
            let servedName = Src[i].servusr;
            let srcName = Src[i].inputSrc;
            let st = Src[i].ST;
            let wt = Src[i].WT;
            let dt = Src[i].Day;
            let Sec = Date.parse(dt)/1000;
            let ssdt = new Date((Sec+Src[i].WT)*1000).toLocaleString();
            let sedt = new Date((Sec+Src[i].WT + Src[i].ST)*1000).toLocaleString();

            if( token.toLowerCase() === "pending" || token.toLowerCase() === "closed" )
            {
                ssdt = "";
                st = 0;
            }
            if( st > 0 || wt >0 )
            downloaddata.push({Created_DateTime:Src[i].date, Main_Location_Name: Src[i].mainID, Sub_Location_Name: Src[i].subID, Service_Name: Src[i].serID, Service_Cancelled_Datetime: Src[i].SCDT.trim(),
                Service_Start_Datetime: ssdt, Service_End_Datetime: sedt,
                Priority: Src[i].Prio, Mobile_App_User_Contact:Src[i].MAUC, Mobile_App_User_ID:Src[i].MAUI, Served_Counter: counter, Customer_Comments: Src[i].CC, Customer_ID: Src[i].CUSID, Customer_Rated_Datetime: Src[i].CUSRDT,
                Customer_Rating: Src[i].label, Customer_Source: Src[i].CUSSRC, Token_Created_User_Name:Src[i].TCUN, Token_Number: Src[i].TN, Token_Status: token,
                Served_User_Name: servedName, Input_Source:srcName, Serving_Time: st, Waiting_Time: wt });
            
            let b1 = _this.boolExist(Counter, counter);
            let b2 = _this.boolExist(Token, token);
            let b3 = _this.boolExist(Served, servedName);
            let b4 = _this.boolExist(Input, srcName);

            if( b1 === 1 && b2 === 1 && b3 === 1 && b4 === 1) continue;

            if( b1 === 0 )
                Counter.push({value: Src[i].id, label: counter});
            if( b2 === 0 )
                Token.push({value: Src[i].id, label: token});
            if( b3  === 0 )
                Served.push({value: Src[i].id, label: servedName});
            if( b4 === 0 )
                Input.push({value: Src[i].id, label: srcName});
        }
        _this.state.download_data = downloaddata;

        let custom = [];
        custom.push({value : "aa", label: "VERY_GOOD"});
        custom.push({value : "bb", label: "SATISFIED"});
        custom.push({value : "cc", label: "BAD"});
        custom.push({value : "dd", label: "VERY_BAD"});
        // custom.push({value : "ee", label: ""});

        _this.state.custom_rating = custom;

        _this.state.counter_user = Counter;
        _this.state.token_status = Token;
        _this.state.serv_Username = Served;
        _this.state.input_srcName = Input;
        _this.state.custom_rating = custom;

       

        _this.state.counter_user1 = Counter;
        _this.state.token_status1 = Token;
        _this.state.serv_Username1 = Served;
        _this.state.input_srcName1 = Input;
        _this.state.custom_rating1 = custom;
        if( downloaddata.length > 0)
        {
           _this.GetTokenDataByDay(downloaddata);
        }

    };
    getLineChartDataByDaily(mainCnt, label, sM, eM, sD, eD, nDaysOfMonth, strMonth){
        let OriginLineMLoc = [];
        if( sM === eM )
        {
            for( let i = sD ; i < eD+1 ; i++ )
            {
                let date = strMonth[sM-1] + i;
                let value = 0;
                let result_temp = {};
                result_temp['date'] = date;
                
                let name = "value";
                if(mainCnt[i] === 0 ) value = 0;
                else
                    value = Math.floor(label[i]/mainCnt[i]/60);
                result_temp[name] = value;

                OriginLineMLoc.push(result_temp);
            }
        }
        else
        {
            for( let i = sD ; i < nDaysOfMonth ; i++ )
            {
                let date = strMonth[sM-1] + i;
                let value = 0;
                let result_temp = {};
                result_temp['date'] = date;
                
                let name = "value";
                value = Math.floor(label[i]/mainCnt[i]/60);
                result_temp[name] = value;

                OriginLineMLoc.push(result_temp);
            }
            
            for( let i = 1 ; i < eD+1 ; i++ )
            {
                let date = strMonth[sM-1] + i;
                let value = 0;
                let result_temp = {};
                result_temp['date'] = date;
                
                let name = "value";
                value = Math.floor(label[i]/mainCnt[i]/60);
                result_temp[name] = value;

                OriginLineMLoc.push(result_temp);
            }
        }
        return OriginLineMLoc;
    };

    getLineChartDataByMonthly(mainCnt, label, sY, eY, sM, eM, strMonth){
        let OriginLineMLoc = [];
        if( sY === eY )
        {
            for( let i = sM ; i < eM + 1 ; i++ )
            {
                let date = strMonth[i-1];
                let value = 0;
                let result_temp = {};
                result_temp['date'] = date;
                
                let name = "value";
                if(mainCnt[i] === 0 ) value = 0;
                else
                    value = Math.floor(label[i]/mainCnt[i]/60);
                result_temp[name] = value;

                OriginLineMLoc.push(result_temp);
            }
        }
        else
        {
            for( let i = sM ; i < 13 ; i++ )
            {
                let date = strMonth[i-1];
                let value = 0;
                let result_temp = {};
                result_temp['date'] = date;
                
                let name = "value";
                if(mainCnt[i] === 0 ) value = 0;
                else
                    value = Math.floor(label[i]/mainCnt[i]/60);
                result_temp[name] = value;

                OriginLineMLoc.push(result_temp);
            }
            // this.setState({loading : true});
            
            for( let i = 1 ; i < eM + 1 ; i++ )
            {
                let date = strMonth[i-1];
                let value = 0;
                let result_temp = {};
                result_temp['date'] = date;
                
                let name = "value";
                if(mainCnt[i] === 0 ) value = 0;
                else
                    value = Math.floor(label[i]/mainCnt[i]/60);
                result_temp[name] = value;

                OriginLineMLoc.push(result_temp);
            }
        }
        return OriginLineMLoc;
    };

    pad(num, size){
        return ('000' + num ).slice(size * -1);
    }

    sec2time(time) {
        // var pad = function(num, size) { return ('000' + n?um).slice(size * -1); },
        // time = parseFloat(timeInSeconds).toFixed(3),
        let hours = Math.floor(time / 60 / 60);
        let minutes = Math.floor(time / 60) % 60;
        let seconds = Math.floor(time - minutes * 60);
    
        return this.pad(hours, 2) + ':' + this.pad(minutes, 2) + ':' + this.pad(seconds, 2);
    }
    GetTokenDataByDay(src){
        let originData = src;
   
        let lenTotal = originData.length;
        if(  lenTotal === 0)
            return;
        let ServingCnt = [], ServingTCnt = [], WaitingCnt = [], WaitingTCnt = [];
    
        let start = this.state.defaultDate.start;
        let end = this.state.defaultDate.end;
               
        let sY = start.getFullYear();
        let sM = start.getMonth()+1;
        let sD = start.getDate();

        let eY = end.getFullYear();
        let eM = end.getMonth()+1;
        let eD = end.getDate();

        let d = new Date(sY, sM, 0);
        let nDaysOfMonth = d.getDate();
        
        var one_day = 1000 * 60 * 60 * 24 
        if (sM == 11 && sD > 25) 
            end.setFullYear(eY + 1) 
          
        // To Calculate the result in milliseconds and then converting into days 
        var Result = Math.round(end.getTime() - start.getTime()) / (one_day); 
          
        // To remove the decimals from the (Result) resulting days value 
        var Final_Result = Result.toFixed(0); 

        let strMonth = ["Jan ", "Feb ", "Mar ", "Apr ", "May ", "Jun ", "Jul ", "Aug ", "Sep ", "Oct ", "Nov ", "Dec "];

// initializing counts with 0.....//////////////////////       
let SLoc = [], WLoc = [];

        for(let ii = 0 ; ii < 31 ; ii++)
        {
            ServingCnt[ii] = 0;
            ServingTCnt[ii] = 0;
            WaitingCnt[ii] = 0;
            WaitingTCnt[ii] = 0;
            SLoc[ii] = 0;
            WLoc[ii] = 0;
        }

    let OriginLineMLoc = [];
/// Get data for chart drawing /////////////////////////////        
        if( Final_Result <= 31 )
        {
            for( let i = 0 ; i < originData.length ; i++ )
            {
                let Day = originData[i].Created_DateTime;
                let DD = Day.getDate();

                let ost = originData[i].Serving_Time;
                let wst = originData[i].Waiting_Time;
                if( ost > 0 ){
                    SLoc[DD] += ost;
                    ServingCnt[DD]++;
                }
                if( wst > 0 )
                {
                    WLoc[DD] += wst;
                    WaitingCnt[DD]++;
                }
            }
    /// Serving time , long serving time, wating time, long waiting time ////
/////////////////////// MainLoc3DChartData by Day /////////////////////////////////
            OriginLineMLoc = this.getLineChartDataByDaily(ServingCnt, SLoc, sM, eM, sD, eD, nDaysOfMonth, strMonth);
            this.setState({dataLineChartServing: OriginLineMLoc});
            OriginLineMLoc = [];
            OriginLineMLoc = this.getLineChartDataByDaily(WaitingCnt, WLoc, sM, eM, sD, eD, nDaysOfMonth, strMonth);
            this.setState({dataLineChartWaiting: OriginLineMLoc});

            let tt = 0, ww = 0, maxt = 0, maxw=0;
            let val = 0;
            let valw = 0;
            for( let i = 1 ; i <= 31 ; i ++)
            {
                if( ServingCnt[i] > 0)
                {
                    tt += ServingCnt[i];
                    val += SLoc[i]/ServingCnt[i]/60;

                    if( maxt < SLoc[i]/ServingCnt[i]/60 ) maxt = SLoc[i]/ServingCnt[i]/60;
                }
                if(WaitingCnt[i] > 0 )
                {
                    valw += WLoc[i]/WaitingCnt[i]/60;
                    if( maxw < WLoc[i]/WaitingCnt[i]/60 ) maxw = WLoc[i]/WaitingCnt[i]/60;
                }
            }

           if( val > 0)
            {
                var ttt = Math.floor(val / Final_Result);
                let maxtemp = 0, div = 0;
                maxtemp = maxt.toFixed(0);
                if( maxtemp <= 100 )
                    div = 10*Math.round(maxtemp /10)
                else if( maxtemp <= 1000 )
                    div = 10*Math.round(maxtemp/10);
                let xx = Math.floor(435*ttt/div);
                this.state.top1 = 435 - xx.toFixed(0);
             }
            if( valw > 0)
            {
                let www = Math.floor( valw /Final_Result);
                let maxtemp = 0, div = 0;
                
                maxtemp = maxw.toFixed(0);
                if( maxtemp <= 100 )
                    div = 10*Math.round(maxtemp /10)
                else if( maxtemp <= 1000 )
                    div = 10*Math.round(maxtemp/10);
                let xx = Math.floor(435*www/div);
                this.state.top2 = 435 - xx.toFixed(0);
            }
        }
        else
        {
            for( let i = 0 ; i < originData.length ; i++ )
            {
                let Day = originData[i].Created_DateTime;
                let DD = Day.getMonth()+1;

                let ost =originData[i].Serving_Time;
                let wst = originData[i].Waiting_Time;
                if( ost > 0 ){
                    SLoc[DD] += ost;
                    ServingCnt[DD]++;
                }
                if( wst > 0 )
                {
                    WLoc[DD] += wst;
                    WaitingCnt[DD]++;
                }
            }
            OriginLineMLoc = this.getLineChartDataByMonthly(ServingCnt, SLoc, sY, eY, sM, eM, strMonth);
            this.setState({dataLineChartServing: OriginLineMLoc});
            OriginLineMLoc = [];
            OriginLineMLoc = this.getLineChartDataByMonthly(WaitingCnt, WLoc, sY, eY, sM, eM, strMonth);
            this.setState({dataLineChartWaiting: OriginLineMLoc});

            let nMonths = (eY-sY) * 12 + (eM - sM) + 1;

            let maxt = 0, maxw=0;
            let val = 0;
            let valw = 0;
            for( let i = 1 ; i <= 31 ; i ++)
            {
                if( ServingCnt[i] > 0)
                {
                    val += SLoc[i]/ServingCnt[i]/60;

                    if( maxt < SLoc[i]/ServingCnt[i]/60 ) maxt = SLoc[i]/ServingCnt[i]/60;
                }
                if(WaitingCnt[i] > 0 )
                {
                    valw += WLoc[i]/WaitingCnt[i]/60;
                    if( maxw < WLoc[i]/WaitingCnt[i]/60 ) maxw = WLoc[i]/WaitingCnt[i]/60;
                }
            }

           if( val > 0)
            {
                var ttt = Math.floor(val / nMonths);
                let maxtemp = 0, div = 0;
                maxtemp = maxt.toFixed(0);
                if( maxtemp <= 1000 )
                    div = 10*Math.round(maxtemp /10)
                let xx = Math.floor(435*ttt/div);
                this.state.top1 = 435 - xx.toFixed(0);
             }
            if( valw > 0)
            {
                let www = Math.floor( valw /nMonths);
                let maxtemp = 0, div = 0;
                
                maxtemp = maxw.toFixed(0);
                if( maxtemp <= 1000 )
                    div = 10*Math.round(maxtemp /10)
                let xx = Math.floor(435*www/div);
                this.state.top2 = 435 - xx.toFixed(0);
            }
        }
        if( OriginLineMLoc.length > 0 )
            this.setState({ bmain : false,  bdate:false});
        this.setState({loading: false, bVisualize : true});

    }

    onShowCounter(){
        // this.state.counter_user = this.state.counter_user1;
        let tt = this.state.counter_user1;
        this.setState({counter_user : tt});
        let src = this.state.detailTokenData;
        sleep(40).then(() => {
            this.GetTokenDataByDay(src);
        })
    }
    onShowToken(){
        let src = this.state.detailTokenData;
        let tt = this.state.token_status1;
        this.setState({token_status : tt});
        sleep(40).then(() => {
            this.GetTokenDataByDay(src);
        })
    }
    onShowUserName(){
        let src = this.state.detailTokenData;
        let tt = this.state.serv_Username1;
        this.setState({serv_Username : tt});
        sleep(40).then(() => {
            this.GetTokenDataByDay(src);
        })
    }
    onShowCustomer(){
        let src = this.state.detailTokenData;

        let tt = this.state.custom_rating1;
        this.setState({custom_rating : tt});
        sleep(40).then(() => {
            this.GetTokenDataByDay(src);
        })
    }

// creating data for sub_location charts

    //V^6@}J*s
    render() {
        const { dataLineChartToken, data3DChartToken, TokenColor, TokenLabel, main_location_list, sub_location_list, services, defaultDate } = { ...this.state };
        return (
            <>
                <LoadingOverlay
                    active={this.state.loading}
                    spinner
                    text='Loading'
                    className='content'
                
                >
                <Row>
                    <Col md="12">
                        <Card className="content">
                            <CardHeader>
                                <h4 className="padding_Dashboard card-title">Report - Mean Value</h4>
                                {/* <CardTitle tag="h4">>Report - Mean Value</CardTitle> */}
                            </CardHeader>
                            <CardBody >
                            <div className = "padding_Card card-body">
                                <div className = "blockquote blockquote-primary padding">
                                    <Row>
                                        <div className="col-12 col-md-6 col-lg-3">
                                            <h5>Select Date Range</h5>

                                            {/* <div id="daterangepicker" title="daterangepicker"></div> */}
                                            <DateRangePicker 
                                                value={this.state.defaultDate}
                                                onChange={this.handleChangeDate}
                                            />
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-3">
                                            <h5>Select Main Location</h5>
                                            <Select
                                                placeholder='Select Main Location'
                                                closeMenuOnSelect={true}
                                                value={this.state.main_location_list}
                                                isMulti
                                                options={this.state.addressDefinitions}
                                                onChange = {this.handleChange}
                                            />
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-3">
                                            <h5>Select Sub Location</h5>
                                            <Select
                                                placeholder='Select Sub Location'
                                                closeMenuOnSelect={true}
                                                value={this.state.sub_location_list}
                                                clearValue
                                                isMulti
                                                options={this.state.sub_location_ID}
                                                onChange = {this.handleChangesub}
                                            />
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-3">
                                            <h5>Select Services</h5>
                                            <Select
                                                placeholder='Select Services'
                                                closeMenuOnSelect={true}
                                                value={this.state.services}
                                                isClearable
                                                clearValue
                                                isMulti
                                                options={this.state.stateOptions}
                                                onChange = {(selectItemService)=>this.handleChangeService(selectItemService)}
                                            />
                                        </div>
                                    </Row>

                                    <div className="text-center padding_button">
                                                <button disabled={!(main_location_list.length>0 && sub_location_list.length>0 && services.length>0 && defaultDate.start && defaultDate.end)} className="btn-round btn btn-outline-success h6" type="button" onClick={() => this.onVisualize()}>Visualize</button>
                                    </div>
                                    <Row>
                                        <div className="text-center">
                                            <h5>Data maybe delayed up to 20 hours.</h5>
                                        </div>
                                    </Row>
                                </div>
                            </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {this.state.bVisualize ?
                <Row >
                    <Col md="8">
                        <Row>
                            <Col md="12">
                                <Card className="ml-auto mr-auto ">
                                    <CardHeader>
                                        <CardTitle tag="h4" style={{marginLeft: 30}}>Mean Value based on Serving time</CardTitle>
                                    </CardHeader>
                                    {!this.state.bmain ?
                                    <CardBody>
                                        <Col md="12"><ServingLocLineChart data={this.state.dataLineChartServing}/>
                                            <div style = {{position:'absolute', width:'85%', height: 5, top: this.state.top1 , backgroundColor:am4core.color("#ED7D31") 
                                                ,marginLeft: 75, marginRight: 50}}></div>
                                        </Col>
                                        <Row style={{padding: 20, justifyContent:'center'}}>
                                            <div style={{float:"center", width:50, height: 20, backgroundColor : am4core.color("#4472C4")}}></div>
                                            <div style = {{ marginLeft: 10}}>Actual</div>
                                            <div style={{width:50, height: 10, backgroundColor : am4core.color("#ED7D31"), marginTop:5, marginLeft: 50}}></div>
                                            <div style={{marginLeft: 10}}>Average</div>
                                        </Row>
                                    </CardBody>: <div style={{width : '100%', height : 500}}></div>}
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <Card className="ml-auto mr-auto ">
                                    <CardHeader>
                                        <CardTitle tag="h4"  style={{marginLeft: 30}}>Mean Value based on Waiting time</CardTitle>
                                    </CardHeader>
                                    {!this.state.bmain ?
                                    <CardBody>
                                        <Col md="12"><WaitingLocLineChart data={this.state.dataLineChartWaiting}/>
                                            <div style = {{position:'absolute', width:'85%', height: 5, top: this.state.top2 , backgroundColor:am4core.color("#3EED31") 
                                                ,marginLeft: 75, marginRight: 50}}></div>
                                        </Col>
                                        <Row style={{padding: 20, justifyContent:'center'}}>
                                            <div style={{float:"center", width:50, height: 20, backgroundColor : am4core.color("#EE075E")}}></div>
                                            <div style = {{ marginLeft: 10}}>Actual</div>
                                            <div style={{width:50, height: 10, backgroundColor : am4core.color("#3EED31"), marginTop:5, marginLeft: 50}}></div>
                                            <div style={{marginLeft: 10}}>Average</div>
                                        </Row>
                                    </CardBody>: <div style={{width : '100%', height : 500}}></div>}
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col md="4">
                        <div className="sticky_Card padding_Card">
                        
                                            
                        <Card className="ml-auto mr-auto " >
                            <CardHeader>
                                <CardTitle tag="h4">Sub Filters</CardTitle>
                            </CardHeader>
                            <div style={{height:5}} ></div>
                            <CardBody>
                            <Row style={{width: "100%",justifyContent:'space-between', alignItems : "center"}}>
                                    <div style={{float:"left", marginLeft: 20 }} >
                                        <h5>Counters:</h5>
                                        </div>
                                    <div style={{marginRight: 0}}>
                                            <button  style={{height: 25, paddingTop: 3}}
                                             className="btn-round btn btn-outline-success " type="button"
                                            onClick={()=>this.onShowCounter()}>ALL</button>
                                    </div>
                                </Row>
                                <Select
                                    placeholder="Counter User"
                                    closeMenuOnSelect={true}
                                    value={this.state.counter_user}
                                    clearValue
                                    isMulti
                                    options={this.state.counter_user1}
                                    onChange = {this.handleChangeCounterUser}
                                />
                                <Row style={{marginTop:20, width: "100%",justifyContent:'space-between', alignItems : "center"}}>
                                    <div style={{float:"left", marginLeft: 20 }} >
                                        <h5 style={{margin:0, padding:0}}>Customer Feedback:</h5>
                                    </div>
                                    <div style={{marginRight: 0}}>
                                            <button  style={{height: 25, paddingTop: 3}}
                                             className="btn-round btn btn-outline-success " type="button"
                                            onClick={()=>this.onShowCustomer()}>ALL</button>
                                    </div>
                                </Row>
                                <Select
                                    placeholder="Customer feedback"
                                    closeMenuOnSelect={true}
                                    value={this.state.custom_rating}
                                    clearValue
                                    isMulti
                                    options={this.state.custom_rating1}
                                    onChange = {this.handleChangecustomrating}
                                />
                                <Row style={{marginTop:20, width: "100%",justifyContent:'space-between', alignItems : "center"}}>
                                    <div style={{float:"left", marginLeft: 20 }} >

                                        <h5>Token Status:</h5>
                                    </div>
                                    <div style={{marginRight: 0}}>
                                            <button  style={{height: 25, paddingTop: 3}}
                                             className="btn-round btn btn-outline-success " type="button"
                                            onClick={()=>this.onShowToken()}>ALL</button>
                                    </div>
                                </Row>
                                <Select
                                    placeholder="Token Status"
                                    closeMenuOnSelect={true}
                                    value={this.state.token_status}
                                    clearValue
                                    isMulti
                                    options={this.state.token_status1}
                                    onChange = {this.handleChangetokenstatus}
                                />
                                <Row style={{marginTop:20, width: "100%",justifyContent:'space-between', alignItems : "center"}}>
                                    <div style={{float:"left", marginLeft: 20 }} >
                                        <h5 style={{margin:0, padding:0}}>Served User Name:</h5>
                                        </div>
                                    <div style={{marginRight: 0}}>
                                            <button  style={{height: 25, paddingTop: 3}}
                                             className="btn-round btn btn-outline-success " type="button"
                                            onClick={()=>this.onShowUserName()}>ALL</button>
                                    </div>
                                </Row>
                                <Select
                                    placeholder="Served User Name"
                                    closeMenuOnSelect={true}
                                    value={this.state.serv_Username}
                                    clearValue
                                    isMulti
                                    options={this.state.serv_Username1}
                                    onChange = {this.handleChangeservUsername}
                                />
                            </CardBody>

                        </Card>
                        </div>
                    </Col>
                </Row>:
                <div ></div>
                }
                </LoadingOverlay>
            </>
        );
    }
}


export default Report3Mean;
