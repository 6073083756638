import React, { PureComponent, useState, useEffect } from 'react'

export default function Ads (props) {
  const [ads, setAds] = useState([])
  const [duration, setDuration] = useState(2)
  const [interva, setInterva] = useState('')
  const [sliding, setSliding] = useState('')

  useEffect(() => {
    if (props.ads != ads || props.dur != duration || props.sliding != sliding) {
      var arrr = props.ads.sort((a, b) => {
        return parseInt(a.order) > parseInt(b.order)
      })
      setAds(arrr)
      props.dur > 0 ? setDuration(props.dur) : setDuration(2)
      setSliding(props.sliding)
      clearInterval(interva)
      slide(props)
    }
  }, [props])

  function ADS () {
    if (ads != undefined)
      if (ads.length > 0) {
        var arrr = ads.sort((a, b) => {
          return parseInt(a.order) > parseInt(b.order)
        })
        console.log(arrr[0].type,'---------------------')
        if (arrr[0].type == 'img') {
          return arrr.map((e, k) => {
            var c = k == 0 ? 'imgOn' : ''
            return <img src={e.url} alt='' className={'slidImg ' + c} key={k} />
          })
          // slide()
        } else {
          setTimeout(() => {
            play()
          }, 300)
          return arrr.map((e, k) => {
            return (
              <video src={e.url} alt='' className='slidVid' key={k}></video>
            )
          })
        }
      }
  }
  function slide (propss) {
    var on = 'imgOn',
      off = 'imgOff'
    if (document.getElementsByClassName('slidImg') != undefined)
      if (document.getElementsByClassName('slidImg')[0] != undefined) {
        var imgs = document.getElementsByClassName('slidImg')
        document.getElementsByClassName('slidImg')[0].classList.add(on)

        // [].forEach.call(imgs,e=>{
        //     e.setAttribute('class','slidImg')
        // })
        document.getElementsByClassName('slidImg')[0].classList.add(on)

        switch (propss.sliding) {
          case 'appear':
            on = on
            off = 'imgOff'
            break
          case 'slide-right':
            on = 'imgRight-on'
            off = 'imgRight-off'
            break
          case 'slide-left':
            on = 'imgLeft-on'
            off = 'imgLeft-off'
            break
          case 'slide-cross':
            on = 'imgRight-on'
            off = 'imgLeft-off'
            break
          case 'slide-cross-rev':
            on = 'imgLeft-on'
            off = 'imgRight-off'
            break
          default:
            break
        }

        var i = 0,
          interv = setInterval(() => {
            if (imgs[i] != undefined) {
              ;[].forEach.call(imgs, e => {
                if (e.classList.contains(on)) e.classList.remove(on)

                if (!e.classList.contains(off)) e.classList.add(off)
              })
              imgs[i].classList.remove(off)
              imgs[i].classList.add(on)

              if (i + 1 >= imgs.length) i = 0
              else i++
              // if(propss.dur != duration || propss.ads != duration){
              // clearInterval(interva)
              // }
            } else clearInterval(interv)
          }, propss.dur * 1000)

        if (imgs[0]) imgs[0].classList.add(on)

        setInterva(interv)
      }
  }
  function play () {
    var vids = document.getElementsByClassName('slidVid')
    if (vids != undefined)
      if (vids[0] != undefined) {
        vids[0].play()
        vids[0].style.display = 'block'
        for (var i = 0; i < vids.length; i++) {
          ;(function () {
            var j = i,
              vid = vids[i]
            vid.addEventListener('ended', () => {
              ;[].forEach.call(vids, vid1 => {
                vid1.style.display = 'none'
                // if(vid1!=vid)
                //     j++
              })

              if (vid.parentElement.children[j + 1] != undefined) {
                vid.parentElement.children[j + 1].style.display = 'block'
                vid.parentElement.children[j + 1].play()
              } else if (vids[0] != undefined) {
                vids[0].style.display = 'block'
                vids[0].play()
              }
            })
          })()
        }
      }
  }
  return <>{ADS()}</>
}
