import React from "react";
// import classnames from "classnames";

import { 
  Collapse,
  Button,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container
} from "reactstrap";

import Notify from "./notify";

class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      color: "navbar-transparent",
      role: "",
    };
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateColor);
    let auth = JSON.parse(localStorage.getItem("auth_info"))
    if(auth!=null)
      this.setState({ role: JSON.parse(localStorage.getItem("auth_info")).role });
  }
  componentDidUpdate(e) {
    // if (
    //   window.outerWidth < 993 &&
    //   e.history.location.pathname !== e.location.pathname &&
    //   document.documentElement.className.indexOf("nav-open") !== -1
    // ) {
    //   document.documentElement.classList.toggle("nav-open");
    // }
  }
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    if (window.innerWidth < 993 && this.state.collapseOpen) {
      this.setState({
        color: "bg-white",
      });
    } else {
      this.setState({
        color: "navbar-transparent",
      });
    }
  };
  // this function opens and closes the sidebar on small devices
  toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
  };
  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  toggleCollapse = () => {
    let newState = {
      collapseOpen: !this.state.collapseOpen,
    };
    if (!this.state.collapseOpen) {
      newState["color"] = "bg-white";
    } else {
      newState["color"] = "navbar-transparent";
    }
    this.setState(newState);
  };
  render() {
    let _this = this,
      role = this.state.role;
    console.log(role);
    return (
      <>
        <Navbar
          className={`navbar-absolute fixed-top ${this.state.color}`}
          expand="lg"
          >
          <Container fluid>
            <div className="navbar-wrapper">
              <div className="navbar-minimize">
                <Button
                  className="btn-icon btn-round"
                  color="default"
                  id="minimizeSidebar"
                  onClick={this.props.handleMiniClick}
                >
                  <i className="nc-icon nc-minimal-right text-center visible-on-sidebar-mini" />
                  <i className="nc-icon nc-minimal-left text-center visible-on-sidebar-regular" />
                </Button>
              </div>
              <div className={`navbar-toggle ${this.state.sidebarOpen ? "toggled" : ""}`}>
                <button className="navbar-toggler" type="button" onClick={this.toggleSidebar}>
                  <span className="navbar-toggler-bar bar1" />
                  <span className="navbar-toggler-bar bar2" />
                  <span className="navbar-toggler-bar bar3" />
                </button>
              </div>
            </div>
            <button
              aria-controls="navigation-index"
              aria-expanded={this.state.collapseOpen}
              aria-label="Toggle navigation"
              className="navbar-toggler"
              // data-target="#navigation"
              data-toggle="collapse"
              type="button"
              onClick={this.toggleCollapse}
            >
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
            </button>
            <Collapse className="justify-content-end" navbar isOpen={this.state.collapseOpen}>
              <Nav navbar>
                <NavItem>
                  <a
                    href="https://antqueue.tawk.help/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-magnify nav-link"
                  >
                    <i className="nc-icon nc-alert-circle-i"></i>
                    <p>
                      <span className="d-lg-none d-md-block">Information</span>
                    </p>
                  </a>
                </NavItem>
                {/* <NavItem>
                  <NavLink
                    className="btn-magnify"
                    href="#"
                    onClick={e => e.preventDefault()}
                  >
                    <i className="nc-icon nc-bell-55" />
                    <p>
                        <span className="d-lg-none d-md-block">Notification</span>
                    </p>
                  </NavLink>
                </NavItem> */}
                {(this.state.role == "System_Admin" ||
                  this.state.role == "Location_Admin" ||
                  this.state.role == "Location_Super" ||
                  this.state.role == "Counter_Super" ||
                  this.state.role == "Counter_User") && (
                  <NavItem>
                    <Notify />
                  </NavItem>
                )}
                <NavItem>
                  <NavLink
                    className="btn-rotate"
                    href="#"
                    onClick={(e) => this.props.history.push("/account_settings")}
                  >
                    <i className="nc-icon nc-circle-10" />
                    <p>
                      <span className="d-lg-none d-md-block">Account</span>
                    </p>
                  </NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default AdminNavbar;
