import React, { useCallback, useState, useEffect, useRef } from 'react'
import LoadingOverlay from 'react-loading-overlay'
import Select from 'react-select'
import Switch from 'react-bootstrap-switch'
import request from 'request'
import Firebase from 'firebase'
import NotificationAlert from 'react-notification-alert'
import CreatableSelect from 'react-select/creatable'
import config from '../../../../config'
import { DatePicker } from '@progress/kendo-react-dateinputs'

import {
  Col,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Collapse,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input
} from 'reactstrap'

export default function DownloadsAdd (props) {
  const notificationAlert = useRef(null)
  // const file = useRef(null)

  const [state, setState1] = useState({
    editId: props.match?.params?.id || false,
    loading: true,
    customer_id: JSON.parse(localStorage.getItem('auth_info')).customer_id,
    role: JSON.parse(localStorage.getItem('auth_info')).role,
    email: JSON.parse(localStorage.getItem('auth_info')).email,
    categories: {},
    applications: {},
    fileName: '',
    fileDetails: '',
    fileCategories: [],
    fileApplications: [],
    fileDownloadLink: ''
  })
  const setState = e => {
    setState1(c => ({ ...c, ...e }))
  }
  useEffect(() => {
    if (!Firebase.apps.length) {
      Firebase.initializeApp(config)
    }
    getInitData()

    if (state.editId) getFileData(state.editId)
  }, [])

  const getInitData = useCallback(async () => {
    let downloadsData = await Firebase.firestore()
      .collection('System_Config')
      .doc('Downloads')
      .get()

    downloadsData.exists
      ? (downloadsData = downloadsData.data())
      : (downloadsData = {})

    setState({
      loading: false,
      categories: downloadsData.Categories,
      applications: downloadsData.Applications
    })
  }, [])

  const getFileData = async id => {
    let file = await Firebase.firestore()
      .collection('Downloads')
      .doc(id)
      .get()

    if (!file.exists) return

    file = file.data()

    console.log(file)

    setState({
      fileName: file.Name,
      fileDetails: file.Details,
      fileDownloadLink: file.Download_Link,
      fileCategories: file.Categories,
      fileApplications: file.Applications
    })
  }

  const handleAdd = async form => {
    form.preventDefault()
    setState({ loading: true })

    try {
      let url = form.target.file.files[0]
        ? await uploadFile(form.target.file.files[0])
        : state.fileDownloadLink

      let downloadProps = {
        Created_Date: new Date(),
        Created_User: state.email,
        Name: state.fileName,
        Details: state.fileDetails,
        Categories: state.fileCategories,
        Applications: state.fileApplications,
        Download_Link: url
      }

      await AddUpdateFile(downloadProps)

      setState({ loading: false })
      notifyMessage('tc', 2, `File ${state.editId?'Saved':'Added'} successfully.`)
      goBack(props)
    } catch (e) {
      setState({ loading: false })
      console.log(e, 'File Addition failed, ' + e.message, e.error)
      notifyMessage('tc', 3, e.message)
    }
  }

  const AddUpdateFile = async obj => {
    if (state.editId) {
      delete obj.Created_Date
      delete obj.Created_User
      return await Firebase.firestore()
        .collection('Downloads')
        .doc(state.editId)
        .update({
          ...obj,
          Updated_Date: new Date(),
          Updated_User: state.email
        })
    }
    return await Firebase.firestore()
      .collection('Downloads')
      .add(obj)
  }

  const uploadFile = async file => {
    let storageRef = Firebase.storage().ref('/Default/Downloads'),
      fileName = file.name,
      fileRef = storageRef.child(fileName),
      result = await fileRef.put(file)

    console.log('upload file snap', result)
    return await fileRef.getDownloadURL()
  }

  const changeFileArr = (e, name) =>
    state[name].includes(e)
      ? setState({ [name]: state[name].filter(c => c != e) })
      : setState({ [name]: [...state[name], e] })

  const notifyMessage = (place, color, text) => {
    var type
    switch (color) {
      case 1:
        type = 'primary'
        break
      case 2:
        type = 'success'
        break
      case 3:
        type = 'danger'
        break
      case 4:
        type = 'warning'
        break
      case 5:
        type = 'info'
        break
      default:
        break
    }

    var options = {}
    options = {
      place: place,
      message: (
        <div className='text-md-center'>
          <div>
            <b>{text}</b>
          </div>
        </div>
      ),
      type: type,
      icon: 'now-ui-icons ui-1_bell-53',
      autoDismiss: 3
    }
    if (options !== null) {
      notificationAlert.current.notificationAlert(options)
    }
  }

  return (
    <LoadingOverlay
      active={state.loading}
      spinner
      text='Loading'
      className='content'
    >
      <NotificationAlert ref={notificationAlert} />
      <Row>
        <Col md='12'>
          <Card>
            <CardHeader>
              <CardTitle tag='h4'>
                Download {state.editId ? 'Edit' : 'Add'}
              </CardTitle>
            </CardHeader>
            <CardBody>
              {/* <table>
                <thead>
                  <th>Name</th>
                  <th>Details</th>
                  <th>Download</th>
                  <th>Category</th>
                </thead>
                <tbody></tbody>
              </table> */}
              <Col className='ml-auto mr-auto' lg='8'>
                <Row className='top-margin-10'></Row>
                <Row>
                  <Col md='8'>
                    <form onSubmit={handleAdd}>
                      <Row>
                        <Col lg='4'>
                          <Button color='success' block>
                            {state.editId ? 'Save' : 'Add'}
                          </Button>
                        </Col>
                        <Col lg='4'>
                          <Button
                            color='youtube'
                            onClick={e => props.history.push('/downloads')}
                            block
                          >
                            Close
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <InputElem
                            type='text'
                            label='Name'
                            func={e => setState({ fileName: e.target.value })}
                            value={state.fileName}
                          />
                          <InputElem
                            type='text'
                            label='Details'
                            func={e =>
                              setState({ fileDetails: e.target.value })
                            }
                            value={state.fileDetails}
                          />

                          <InputElem label='Select Categories'>
                            <DisplayCheckMap
                              arr={state.categories}
                              changeFileArr={e =>
                                changeFileArr(e, 'fileCategories')
                              }
                              values={state.fileCategories}
                            />
                          </InputElem>
                          <InputElem label='Select Applications'>
                            <DisplayCheckMap
                              arr={state.applications}
                              changeFileArr={e =>
                                changeFileArr(e, 'fileApplications')
                              }
                              values={state.fileApplications}
                            />
                          </InputElem>

                          <Label>
                            Upload File
                            <FormGroup>
                              <Input
                                name='file'
                                className='position-relative my-1'
                                style={{ opacity: '1 ' }}
                                type='file'
                                required={
                                  state.fileDownloadLink?.length > 0
                                    ? false
                                    : true
                                }
                              />
                            </FormGroup>
                          </Label>
                        </Col>
                      </Row>
                    </form>
                  </Col>
                </Row>
              </Col>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </LoadingOverlay>
  )
}

const InputElem = ({ label, type, func, children = null, ...additionals }) => (
  <>
    <Label>
      {label}
      <FormGroup>
        {children == null ? (
          <Input
            className='position-relative my-1'
            style={{ opacity: '1 ' }}
            type={type}
            onChange={func}
            {...additionals}
            required
          />
        ) : (
          children
        )}
      </FormGroup>
    </Label>
    <br />
  </>
)

const DisplayCheckMap = ({ arr, changeFileArr, values = [] }) => (
  <>
    {Object.keys(arr).length > 0 &&
      Object.keys(arr)?.map((c, k) => (
        <div key={k + c}>
          <Label className='mb-0 my-1'>
            <Input
              className='mt-0 ml-0 position-relative m-1'
              type='checkbox'
              onChange={e => changeFileArr(arr[c])}
              checked={values.includes(arr[c])}
            />
            {c}
          </Label>
          <br />
        </div>
      ))}
  </>
)

const goBack = props => {
  setTimeout(() => {
    props.history.push('/downloads')
  }, 1000)
}
