import React, { PureComponent, useState, useEffect, useRef } from "react";
import Firebase from "firebase";
import ReactBSAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import Switch from "react-bootstrap-switch";
import NotificationAlert from "react-notification-alert";
import LoadingOverlay from "react-loading-overlay";

import GridLayout from "react-grid-layout";

import { Icon, InlineIcon } from "@iconify/react";
import {
  sizeActual,
  sizeFullscreen,
} from "@iconify/icons-simple-line-icons/size-actual";

import expand from "../../../assets/img/expand.png";
import minimize from "../../../assets/img/minimize.png";

import "../../../assets/scss/css.css";

import "../../react-grid-layout.css";
import "../../react-resizable.css";

import "../../all.css";
// import '../../fontawesome.min.css'
import {
  Col,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Label,
  Button,
  Input,
  Form,
  FormGroup,
  Table,
} from "reactstrap";
import config from "../../../config";
import exp from "../../expand-solid.svg";
import comp from "../../compress-solid.svg";
import ply from "../../play-solid.svg";
import imgIcon from "../../image-solid.svg";
import vidIcon from "../../video-solid.svg";
import plus from "../../plus-solid.svg";
import trash from "../../trash-alt-solid.svg";
import right from "../../arrow-right-solid.svg";
import left from "../../arrow-left-solid.svg";
import just from "../../justify.png";
import al_left from "../../align-left.png";
import al_right from "../../align-right.png";
import { Rnd } from "react-rnd";
import { ColorPicker } from "react-pick-color";
import Ads from "./DisplayAds";
import Layout from "./DisplayLayoutComponent";

class TimeComp extends React.Component {
  state = { timeis: "" };
  componentDidMount() {
    setInterval(
      () =>
        this.setState({
          timeis: Date().toLocaleString().split("GMT")[0],
        }),
      1000
    );
  }
  render() {
    return <Col className="">{this.state.timeis}</Col>;
  }
}

const DisplayEdit = React.memo((props) => {
  //   constructor (props) {
  // super(props)
  if (!Firebase.apps.length) {
    Firebase.initializeApp(config);
  }
  var notificationAlert = useRef(null);
  const [state, setState1] = useState({
    list1: [],
    run: false,
    general_font_size: "12",
    counter_font_size: "12",
    date_display: true,
    token_display: true,
    counter_display: true,
    detail_display: false,
    logo_display: true,

    detail_back_color: "",
    detail_font_color: "",
    detail_font_size: "",
    detail_bold_font: "",
    colorPick: false,
    colorPlace: "",
    loading: false,
    display_id: props.match.params.id ? props.match.params.id : "",
    customer_id: JSON.parse(localStorage.getItem("auth_info")).customer_id,
    email: JSON.parse(localStorage.getItem("auth_info")).email,
    role: JSON.parse(localStorage.getItem("auth_info")).role,
    customer_name: "",
    selected_main_location: { label: '', value: '' },
    main_location: "",
    selected_sub_location: { label: '', value: '' },
    sub_location: "",
    location_name: "",
    tokens: [],
    display_name: "",
    counter_list: [],
    selected_counter_list: [],
    selected_counter_list_right: [],
    layout_theme: "",
    width: "",
    height: "",
    // header_footer:false,
    // date_time:false,
    header_back_color: "#000",
    header_font_color: "#fff",
    header_font_size: "12",
    header_bold_font: false,
    date_back_color: "#000",
    date_font_color: "#fff",
    date_font_size: "12",
    date_bold_font: false,
    footer_message_back_color: "#000",
    footer_message_font_color: "#fff",
    footer_message_font_size: "12",
    footer_message_bold_font: false,
    tokens_back_color: "#000",
    tokens_font_color: "#fff",
    tokens_line_color: "#fff",
    tokens_font_size: "12",

    tokens_bold_font: false,
    color_list: [
      { label: "#000", value: "#000" },
      { label: "#fff", value: "#fff" },
    ],
    font_size_list: [
      { label: "12", value: "12" },
      { label: "10", value: "10" },
    ],
    counters: [],
    timeis: Date().toLocaleString().split("GMT")[0],
    footer_message: "",
    icon: expand,
    beeps: [],
    ads: [],
    selected_beep: "",
    selected_ad: [],
    saved_ads: [],
    adVid: false,
    imgDur: 2,
    col1: "0",
    col2: "0",
    col3: "0",
    col_num: [1],
    width1: "100",
    width2: "100",
    width3: "100",
    height1: "100",
    height2: "100",
    height3: "100",
    tokens_align: "center",
    header_align: "center",
    detail_align: "center",
    logo_align: "center",
    date_align: "center",
    footer_message_align: "center",
    res_sign: "%",
    logo: "",
    logo_size_type:'%',
    logo_width:'50',
    logo_column:1,
    // logo_height:'',
    logo_padding_top:'100',
    logo_padding_bottom:'0',
    max_ad_img: 1,
    max_ad_vid: 1,
    max_icon: 1,
    sliding: "",
    change: false,
    detail_name: "",
    counter_name: "",
    token_name: "",
  });

  // this.functions = null;                                              //functions emulator
  // this.fbStore = null;                                                //functions emulator
  //   }

  // initializeFirebase = () => {                                            //functions emulator
  // if (!this.functions) {                                              //functions emulator
  // this.functions = Firebase.functions();                          //functions emulator
  // this.functions.useFunctionsEmulator('http://localhost:5000');//5001   //functions emulator
  // this.fbStore = Firebase.firestore();                            //functions emulator
  // }                                                                   //functions emulator
  // }                                                                       //functions emulator

  //   componentDidMount () {
  const setState = (e) => {
    //   Object.keys(e).forEach(b => {
    setState1((c) => ({ ...c, ...e }));
    //   })
  };
  useEffect(() => {
    //functions emulator
    // this.initializeFirebase();                                          //functions emulator
    // letthis = this
    // var customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id
    //this.setState({ customer_id: JSON.parse(localStorage.getItem('auth_info')).customer_id })
    // var role1 = JSON.parse(localStorage.getItem("auth_info")).role;
    let id = state.display_id, //props.match.params.id,
      beep = [],
      j = 0,
      ad = [],
      o = 0;
    if (id !== "" && id !== null && id !== undefined) {
      // setState({ display_id: id, role: role1 });
      loadData(id);
    } else {
      let display_id = Firebase.firestore().collection("Display").doc().id;
      setState({ display_id: display_id ,isNew:true});
    }
    loadMainLocations();

    Firebase.storage()
      .ref("Media_Gallery/Main_Display_Beep_Sounds")
      .listAll()
      .then((r) => {
        r.items.forEach((i) => {
          j++;
          i.getDownloadURL().then(function (url) {
            beep.push({ label: i.name, value: url });
            if (j >= r.items.length) {
              //   console.log('arr ' + beep)

              setState({ beeps: beep });
              //  forceUpdate()
            }
          });
        });
      })
      .catch((e) => console.log(e.message));

    Firebase.storage()
      .ref("Media_Gallery/Main_Display_Logo")
      .child(id + "/")
      .listAll()
      .then((r) => {
        r.items.forEach((i) => {
          j++;
          i.getDownloadURL().then(function (url) {
            setState({ logo: { url: url, name: i.name } });
          });
        });
      })
      .catch((e) => console.log(e.message));

    adsLoad(id);
  }, [state.main_location, state.location_name]);

  function adsLoad(id) {
    // console.log('loading ads...');
    let j = 0,
      ad = [],
      o = 0;
    Firebase.storage()
      .ref("Media_Gallery/Main_Display_Ads/")
      .child(id + "/")
      .listAll()
      .then((r) => {
        r.items.forEach((i) => {
          o++;
          i.getDownloadURL().then(function (url) {
            var type;
            switch (i.name.split(".")[1]) {
              case "png":
                type = "img";
                break;
              case "jpg":
                type = "img";
                break;
              case "jpeg":
                type = "img";
                break;
              case "webp":
                type = "img";
                break;
              case "mp4":
                type = "vid";
                break;
              case "mkv":
                type = "vid";
                break;
              case "flv":
                type = "vid";
                break;
              case "gif":
                type = "vid";
                break;
              case "avi":
                type = "vid";
                break;
              case "m4v":
                type = "vid";
                break;
              case "3gp":
                type = "vid";
                break;
              case "webm":
                type = "vid";
                break;
              default:
                type = "n";
                break;
            }
            var ord = 0;
            state.saved_ads.map((p, k) => {
              if (p.name == i.name) ord = p.order;
            });
            ad.push({ name: i.name, url: url, type: type, order: ord });
            if (o == r.items.length) {
              var disAd = [];
              ad.map((e, k) => {
                if (state.adVid) {
                  if (e.type == "vid") disAd.push(e);
                } else if (e.type == "img") disAd.push(e);

                if (k + 1 == ad.length) {
                  setState({ selected_ad: disAd, ads: ad });
                  //  forceUpdate()
                }
              });
            }
          });
        });
      })
      .catch((e) => {
        console.log(e.message);
      });
  }
  const notifyMessage = (place, color, text) => {
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }

    var options = {};
    options = {
      place: place,
      message: (
        <div className="text-md-center">
          <div>
            <b>{text}</b>
          </div>
        </div>
      ),
      type: type,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 3,
    };
    if (options !== null) {
      notificationAlert.current.notificationAlert(options);
    }
  };
  function loadData(id) {
    setState({ loading: true });
    Firebase.firestore()
      .collection("Displays")
      .doc(id)
      .get()
      .then(function (doc) {
        if (doc.exists) {
          setState({
            display_name: doc.data().Display_Name,
            sub_location: doc.data().Sub_Location_ID,
            main_location: doc.data().Main_Location_ID,
            layout_theme: doc.data().Layout_Theme,

            width: doc.data().Width,
            height: doc.data().Height,

            ['logo.url']:doc.data()?.Logo_URL||'',
            logo_size_type:doc.data()?.Logo_Size_Percentage_Or_Px||'px',
            logo_width:doc.data()?.Logo_Size_Width||'50',
            // logo_height:doc.data()?.Logo_Size_Height||'50',
            logo_column:doc.data()?.Logo_Column_Placement||1,
            logo_padding_top:doc.data()?.Logo_Padding_Top||'0',
            logo_padding_bottom:doc.data()?.Logo_Padding_Bottom||'0',

            // header_footer: doc.data().Header_Footer_custom,
            // date_time: doc.data().Datetime_custom,
            footer_message: doc.data().Footer_Message || '',
            header_back_color: doc.data().Header_Back_Color || '',
            detail_back_color: doc.data().Detail_Back_Color || '',

            detail_display: doc.data().Detail_Display != undefined ? doc.data().Detail_Display : true,
            counter_display: doc.data().Counter_Display != undefined ? doc.data().Counter_Display : true,
            token_display: doc.data().Token_Display != undefined ? doc.data().Token_Display : true,
            logo_display: doc.data().Logo_Display != undefined ? doc.data().Logo_Display : true,
            date_display: doc.data().Date_Display != undefined ? doc.data().Date_Display : true,

            date_back_color: doc.data().Date_Back_Color || '#000',
            tokens_back_color: doc.data().Tokens_Back_Color || '#000',
            footer_message_back_color: doc.data().Footer_Message_Back_Color || '#000',

            header_font_color: doc.data().Header_Font_Color || '#fff',
            date_font_color: doc.data().Date_Font_Color || '#fff',
            tokens_font_color: doc.data().Tokens_Font_Color || '#fff',
            tokens_line_color: doc.data().Tokens_Line_Color || '#fff',
            detail_font_color: doc.data().Detail_Font_Color || '#fff',
            detail_font_size: doc.data().Detail_Font_Size || '',
            counter_font_size: doc.data().Counter_Font_Size || '',
            detail_bold_font: doc.data().Detail_Bold_Font || '',
            detail_name: doc.data().Detail_Name || '',
            counter_name: doc.data().Counter_Name || '',
            token_name: doc.data().Token_Name || '',
            footer_message_font_color: doc.data().Footer_Message_Font_Color || '',

            header_font_size: doc.data().Header_Font_Size || '',
            date_font_size: doc.data().Date_Font_Size || '',
            tokens_font_size: doc.data().Tokens_Font_Size || '',
            general_font_size: doc.data().General_Font_Size || '',
            footer_message_font_size: doc.data().Footer_Message_Font_Size || '',

            header_bold_font: doc.data().Header_Font_Bold || '',
            date_bold_font: doc.data().Date_Font_Bold || '',
            tokens_bold_font: doc.data().Tokens_Font_Bold || '',
            footer_message_bold_font: doc.data().Footer_Message_Font_Bold || '',

            selected_counter_list: doc.data().Counter_List || [],
            selected_counter_list_right: doc.data().Counter_List_Right || [],
            logged_userId: doc.data().Logged_In_User_ID,
            logged_userName: doc.data().Logged_In_User_Name,
            // loading: false,
            selected_beep: doc.data().Beep_Sound,
            // selected_ad:doc.data().ADs_Files,
            saved_ads: doc.data().ADs_Files,
            adVid: doc.data().ADs_Video_Enable,
            imgDur: doc.data().ADs_Slider_Duration,
            col1: doc.data().Col1,
            col2: doc.data().Col2,
            col3: doc.data().Col3,
            width1: doc.data().Width1,
            width2: doc.data().Width2,
            width3: doc.data().Width3,
            height1: doc.data().Height1,
            height2: doc.data().Height2,
            height3: doc.data().Height3,

            tokens_align: doc.data().Tokens_Align || '',
            header_align: doc.data().Header_Align || '',
            detail_align: doc.data().Detail_Align || '',
            logo_align: doc.data().Logo_Align || 'center',
            date_align: doc.data().Date_Align || 'center',

            footer_message_align: doc.data().Footer_Message_Align || state.footer_message_align,
            res_sign: doc.data().Resolution_Sign,
            sliding: doc.data().Sliding_Animation,
          });
          //  forceUpdate()
          //  loadCounterListBySub(doc.data().Sub_Location_ID)

          getCountersRows(doc.data().Counter_List);
          adsLoad(state.display_id);
        } else {
          setState({ loading: false });
          notifyMessage("tc", 3, "Network error!");
          console.log("loadData NetworkError1");
        }
      })
      .catch(function (err) {
        setState({ loading: false });
        notifyMessage("tc", 3, "Network error!");
        console.log("loadData NetworkError2==>", err);
      });
    document.addEventListener("fullscreenchange", function (event) {
      if (!document.fullscreenElement)
        if (document.getElementById("image").classList.contains("hov")) {
          document.getElementById("image").classList.remove("hov");
          document.getElementById("image").src = exp;
        }
    });

    Firebase.firestore()
      .collection("System_Config")
      .doc("Display_Settings")
      .get()
      .then((e) => {
        var sts = [];
        e.data().Font_Size_Values.forEach((b) => {
          sts.push({ label: b, value: b });
        });
        setState({ font_size_list: sts });
        sts = [];
        // e.data().Color_Values.forEach(b=>{
        // sts.push({label:b,value:b})
        // })
        for (var k in e.data().Color_Values)
          sts.push({ label: k, value: e.data().Color_Values[k] });
        setState({ color_list: sts ,default_beep:e.data().Default_Beep_Sound_Selection});
        //    forceUpdate()
      });
    Firebase.firestore()
      .collection("System_Config")
      .doc("Upload_Limits")
      .get()
      .then((e) => {
        setState({
          max_ad_img: e.data().Max_Ad_Image_size_in_MB,
          max_ad_vid: e.data().Max_Ad_Video_size_in_MB,
          max_icon: e.data().Max_Icon_size_in_MB,
        });
      });
  }
  function loadMainLocations() {
    let main_locations = [];
    let customer_id = JSON.parse(localStorage.getItem("auth_info")).customer_id;
    // let email = JSON.parse(localStorage.getItem("auth_info")).email;
    // let role = JSON.parse(localStorage.getItem("auth_info")).role;
    //setState({role: role});
    // ---------- Load Location List ---------- //
    Firebase.firestore()
      .collection("Main_Locations")
      .where("Customer_ID", "==", customer_id)
      .get()
      .then(function (response) {
        response.docs.forEach(function (doc) {
          main_locations.push({ label: doc.data().Name, value: doc.id });
        });
        if (main_locations.length > 0) {
          var i = 0;
          main_locations.forEach((e) => {
            if (String(e.value) == state.main_location) {
              setState({ selected_main_location: main_locations[i] });
              //_setState({customer_id: customer_Xid,main_location_list: main_locations,selected_main_location: main_locations[i]});
              loadSubLocationByMain(main_locations[i].value);
            } else i++;
          });
          if(!state.selected_main_location?.value?.length>0){
            setState({ selected_main_location: main_locations[0] });
            loadSubLocationByMain(main_locations[0].value);
          }

          setState({
            customer_id: customer_id,
            main_location_list: main_locations,
          });
        } else {
          setState({ loading: false });
        }
      })
      .catch(function (err) {
        setState({ loading: false });
        notifyMessage("tc", 3, "Network error!");
        console.log("loadMainLocations NetworkError3==>", err);
      });
  }
  function loadSubLocationByMain(main_id) {
    let sub_locations = [];
    let accessible_locations = [];
    // let email = JSON.parse(localStorage.getItem("auth_info")).email;
    Firebase.firestore()
      .collection("Web_App_Users")
      .doc(state.email)
      .get()
      .then(function (app_info) {
        if (app_info.exists) {
          accessible_locations = app_info.data().Accessible_Locations;
          //console.log("accessible locations:",accessible_locations);
        }
      })
      .catch(function (err) {
        setState({ loading: false });
        notifyMessage("tc", 3, "Network error!");
        console.log("loadSubLocationByMain NetworkError4==>", err);
      });
    let customer_id = JSON.parse(localStorage.getItem("auth_info")).customer_id;
    Firebase.firestore()
      .collection("Sub_Locations")
      .where("Customer_ID", "==", customer_id)
      .where("Main_Location_ID", "==", main_id)
      .get()
      .then(function (response) {
        response.docs.forEach(function (doc) {
          if (accessible_locations.includes(doc.id)) {
            sub_locations.push({
              label: doc.data().Name,
              value: doc.id,
              image_url: doc.data().Icon,
              address: doc.data().Address,
            });
          }
        });

        setState({ sub_location_list: sub_locations });

        if (sub_locations.length > 0) {
          var i = 0;
          sub_locations.forEach((e) => {
            if (String(e.value) == state.sub_location) {
              let first_one = sub_locations[i];
              setState({
                loading: true,
                selected_sub_location: null,
                selected_sub_location: first_one,
                location_name: first_one.label,
                loading: false,
              });
              //setState({ location_package_id : first_one.package_id });
              //setState({ location_image_url : first_one.image_url });
              //setState({ location_address : first_one.address });
              loadCounterListBySub(first_one.value);
            } else i++;
          });
          if(!state.sub_location?.length>0){
            setState({ selected_sub_location: sub_locations[0] });
          loadCounterListBySub(sub_locations[0].value);

          }
        } else {
          setState({ loading: false });
          notifyMessage(
            "tc",
            3,
            "This main location does not have any sub locations."
          );
        }
      })
      .catch(function (err) {
        setState({ loading: false });
        notifyMessage("tc", 3, "Network error!");
        console.log("loadSubLocationByMain NetworkError5==>", err);
      });
  }
  function loadCounterListBySub(sub_id) {
    let customer_id = JSON.parse(localStorage.getItem("auth_info")).customer_id;
    Firebase.firestore()
      .collection("Counters")
      .where("Customer_ID", "==", customer_id)
      .where("Sub_Location_ID", "==", sub_id)
      .get()
      .then(function (response) {
        let counters = [];
        response.docs.forEach(function (res) {
          let one = {
            id: res.id,
            created_date: res.data().Created_Date,
            name: res.data().Counter_Name,
            state: res.data().Counter_State,
          };
          // if(one.state=='Open')
          counters.push(one);
        });

        let sorted = counters.sort(function (a, b) {
          return a.created_date < b.created_date ? -1 : 1;
        });

        setState({ counter_list: sorted, loading: false });
      });
  }
  function onChangeMain(e) {
    setState({ selected_main_location: e });
    loadSubLocationByMain(e.value);
  }
  function onChangeSub(e) {
    setState({ selected_sub_location: e });
    loadCounterListBySub(e.value);
  }
  const warningWithConfirmMessage = (object) => {
    setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block" }}
          title="Are you sure?"
          onConfirm={() => deleteFile(object)}
          onCancel={() => hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, delete it!"
          cancelBtnText="Cancel"
          showCancel
        ></ReactBSAlert>
      ),
    });
  };
  function deleteFile(e) {
    Firebase.storage()
      .ref("Media_Gallery/Main_Display_Ads/")
      .child(state.display_id + "/" + e.name)
      .getDownloadURL()
      .then(
        (url) => {
          Firebase.storage()
            .ref("Media_Gallery/Main_Display_Ads/")
            .child(state.display_id + "/" + e.name)
            .delete()
            .then(() => {
              //setState({loading: true});
              let tmp = state.selected_ad.filter((item) => {
                return item.url !== url;
              });
              setState({ selected_ad: tmp });
              //adsLoad(state.display_id)
            })
            .catch(() => adsLoad(state.display_id));
          setState({
            alert: null,
          });
        },
        (error) => {
          console.log("Can't delete !", error);
        }
      );
  }
  const hideAlert = () => {
    setState({
      alert: null,
    });
  };
  function getAds() {
    var disAd = [];

    let icon = "";
    if (state.selected_ad != undefined)
      return state.selected_ad.map(function (prop, key) {
        if (state.adVid) {
          icon = vidIcon;
        } else {
          icon = imgIcon;
        }
        return (
          <>
            <hr />
            <Row key={key} >
              <Col col="5">
                {prop.name}
              </Col>
              <Col col="3">
                <input
                  className="cus-sel"
                  type="number"
                  style={{ width: "4rem" }}
                  placeholder={prop.order}
                  onChange={(e) => {
                    prop.order = e.target.value;
                    // console.log(state.selected_ad)
                  }}
                />
              </Col>
              <Col col="2">
                <img
                  src={icon}
                  style={{ width: "15px", margin: "3px" }}
                  alt=""
                />
              </Col>
              <Col col="2">
                <img
                  src={trash}
                  className="pointer-hover"
                  style={{ width: "15px", margin: "3px" }}
                  alt=""
                  onClick={() => warningWithConfirmMessage(prop)}
                />
              </Col>
            </Row>
          </>
        );
      });
  }
  function getCounters() {
    return state.counter_list.map(function (prop, key) {
      return (
        <Row md="12" key={key}>
          <Col className="" col="6">
            {prop.name}
          </Col>
          <Col className="px-0 mx-0" col="">
            <Switch
              value={state.selected_counter_list.includes(String(prop.id))}
              //   value={state.selected_counter_list.includes(
              //     String(prop.id)?true:false
              //   )}
              offColor="success"
              offText="OFF"
              onColor="success"
              onText="ON"
              onChange={(event) => {
                var arr = state.selected_counter_list;
                if (event.state.value)
                  if (arr.indexOf(prop.id) > -1)
                    arr.splice(arr.indexOf(prop.id), 1);
                  else arr.push(String(prop.id));
                else arr.splice(arr.indexOf(prop.id), 1);
                setState({ selected_counter_list: arr });
                getCountersRows(state.selected_counter_list);
              }}
            />
          </Col>
          {state.selected_counter_list.includes(String(prop.id)) ? (
            state.layout_theme.includes("double list") ||
              state.layout_theme.includes("double list with ad") ? (
              <Col className="px-0 mx-0" col="">
                <Switch
                  //   defaultValue={state.selected_counter_list_right.includes(
                  //      prop.id
                  //   )}
                  value={state.selected_counter_list_right.includes(prop.id)}
                  offColor="success"
                  offText="Auto"
                  onColor="success"
                  onText="Right"
                  onChange={(event) => {
                    var arr = state.selected_counter_list_right;
                    if (event.state.value)
                      if (arr.indexOf(prop.id) > -1)
                        arr.splice(arr.indexOf(prop.id), 1);
                      else arr.push(String(prop.id));
                    else arr.splice(arr.indexOf(prop.id), 1);
                    setState({ selected_counter_list_right: arr });
                    getCountersRows(state.selected_counter_list);
                  }}
                />
              </Col>
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </Row>
      );
    });
    // if(arr!=arr)
  }
  const change = (event, stateName, type, stateNameEqualTo) => {
    switch (type) {
      case "length":
        setState({ [stateName]: event.target.value });
        getCountersRows(state.selected_counter_list);
        break;
      default:
        break;
    }
  };
  function handleEdit() {
    let now = new Date();
    setState({ loading: true });
    let customer_id = JSON.parse(localStorage.getItem("auth_info")).customer_id;
    let new_display_data = {
      Customer_ID: customer_id,
      Counter_List: state.selected_counter_list || [],
      Counter_List_Right: state.selected_counter_list_right || [],
      Display_Name: state.display_name,
      Logged_In_User_ID: state.customer_id,
      Logged_In_User_Name: "",
      Main_Location_ID: state.selected_main_location.value,
      Sub_Location_ID: state.selected_sub_location.value,
      Updated_Date: now,
      Width: state.width,
      Height: state.height,
      Logo_URL: state.logo?.url || "",
      Logo_Size_Percentage_Or_Px: state.logo_size_type || "px",
      Logo_Size_Width: state.logo_width || "50",
      // Logo_Size_Height:state.logo_height||'50',
      Logo_Column_Placement: state.logo_column || 1,
      Logo_Padding_Top: state.logo_padding_top || "100",
      Logo_Padding_Bottom: state.logo_padding_bottom || "0",

      // Header_Footer_custom:_this).state.header_footer,
      // Datetime_custom:state.date_time,
      Footer_Message: state.footer_message,
      Header_Back_Color: state.header_back_color,
      Date_Back_Color: state.date_back_color,
      Detail_Back_Color: state.detail_back_color || "",
      Tokens_Back_Color: state.tokens_back_color,
      Footer_Message_Back_Color: state.footer_message_back_color,
      Header_Font_Color: state.header_font_color,
      Date_Font_Color: state.date_font_color,
      Tokens_Font_Color: state.tokens_font_color,
      Tokens_Line_Color: state.tokens_line_color,

      Detail_Display: state.detail_display,
      Counter_Display: state.counter_display,
      Token_Display: state.token_display,
      Logo_Display: state.logo_display,
      Date_Display: state.date_display,

      Detail_Font_Color: state.detail_font_color,
      Detail_Font_Size: state.detail_font_size,
      Counter_Font_Size: state.counter_font_size,
      Detail_Bold_Font: state.detail_bold_font,
      Detail_Name: state.detail_name,
      Counter_Name: state.counter_name,
      Token_Name: state.token_name,
      Footer_Message_Font_Color: state.footer_message_font_color,
      Header_Font_Size: state.header_font_size,
      Date_Font_Size: state.date_font_size,
      Tokens_Font_Size: state.tokens_font_size,
      General_Font_Size: state.general_font_size,
      Footer_Message_Font_Size: state.footer_message_font_size,
      Header_Font_Bold: state.header_bold_font,
      Date_Font_Bold: state.date_bold_font,
      Tokens_Font_Bold: state.tokens_bold_font,
      Footer_Message_Font_Bold: state.footer_message_bold_font,
      Layout_Theme: state.layout_theme,
      Beep_Sound: state.selected_beep.toString(),
      ADs_Files: state.selected_ad,
      ADs_Video_Enable: state.adVid,
      ADs_Slider_Duration: state.imgDur != undefined ? state.imgDur : 2,
      Col1: state.col1 != undefined ? state.col1 : " ",
      Col2: state.col2 != undefined ? state.col2 : " ",
      Col3: state.col3 != undefined ? state.col3 : " ",
      Height1: state.height1 != undefined ? state.height1 : " ",
      Height2: state.height2 != undefined ? state.height2 : " ",
      Height3: state.height3 != undefined ? state.height3 : " ",
      Width1: state.width1 != undefined ? state.width1 : " ",
      Width2: state.width2 != undefined ? state.width2 : " ",
      Width3: state.width3 != undefined ? state.width3 : " ",

      Tokens_Align: state.tokens_align,
      Header_Align: state.header_align,
      Detail_Align: state.detail_align,
      Footer_Message_Align: state.footer_message_align,
      Logo_Align: state.logo_align,
      Date_Align: state.date_align,

      Resolution_Sign: state.res_sign,
      Sliding_Animation: state.sliding,
      ...(state.isNew
        ? {
            Created_Date_Firestore_Timestamp:
              Firebase.firestore.FieldValue.serverTimestamp(),
          }
        : {}),
    };

    Firebase.firestore()
      .collection("Displays")
      .doc(state.display_id)
      .set(new_display_data)
      .then(function () {
        setState({ loading: false });
        notifyMessage("tc", 2, "Display saved successfully.");
        window.setTimeout(function () {
          props.history.push("/main_display");
        }, 2000);
      })
      .catch(function (error) {
        setState({ loading: false });
        notifyMessage("tc", 3, "Network error!");
        console.log("handleEdit NetworkError6==>", error);
      });
  }

  // /**
  //  * Sorts an array of objects by column/property.
  //  * @param {Array} array - The array of objects.
  //  * @param {object} sortObject - The object that contains the sort order keys with directions (asc/desc). e.g. { age: 'desc', name: 'asc' }
  //  * @returns {Array} The sorted array.
  //  */
  function multiSort(array, sortObject = {}) {
    const sortKeys = Object.keys(sortObject);
    // Return array if no sort object is supplied.
    if (!sortKeys.length) {
      return array;
    }
    // Change the values of the sortObject keys to -1, 0, or 1.
    for (let key in sortObject) {
      sortObject[key] =
        sortObject[key] === "desc" || sortObject[key] === -1
          ? -1
          : sortObject[key] === "skip" || sortObject[key] === 0
            ? 0
            : 1;
    }
    const keySort = (a, b, direction) => {
      direction = direction !== null ? direction : 1;
      if (a === b) {
        // If the values are the same, do not switch positions.
        return 0;
      }
      // If b > a, multiply by -1 to get the reverse direction.
      return a > b ? direction : -1 * direction;
    };
    return array.sort((a, b) => {
      let sorted = 0;
      let index = 0;
      // Loop until sorted (-1 or 1) or until the sort keys have been processed.
      while (sorted === 0 && index < sortKeys.length) {
        const key = sortKeys[index];
        if (key) {
          const direction = sortObject[key];

          sorted = keySort(a[key], b[key], direction);
          index++;
        }
      }
      return sorted;
    });
  }

  function getCountersRows(list) {
    var arr = [],
      end = false,
      tem = [];

    list.map((e, k) => {
      if (e.length > 1) {
        Firebase.firestore()
          .collection("Counters")
          .doc(e)
          .onSnapshot((d) => {
            if (d.exists) {
              var inc = false;
              arr.map((b, k) => {
                if (Object.values(b).includes(d.data().Counter_Name)) {
                  arr[k].id = d.id;
                  arr[k].num = d.data().Current_Token.number;
                  arr[k].detail =
                    d.data().Filtered_Service_Details_For_Display != undefined
                      ? d.data().Filtered_Service_Details_For_Display
                      : "";
                  inc = true;
                }
              });
              if (!inc)
                arr.push({
                  id: d.id,
                  name: d.data().Counter_Name,
                  num:
                    d.data().Current_Token != undefined
                      ? d.data().Current_Token.number?.length > 0
                        ? d.data().Current_Token.number
                        : "0"
                      : "0",
                  detail:
                    d.data().Filtered_Service_Details_For_Display != undefined
                      ? d.data().Filtered_Service_Details_For_Display
                      : "",
                });
              //   multiSort(arr, { name: 'asc' })
              // arr = arr.sort(function (a, b) {
              //     return parseInt(a.name.match(/\d+/)[0],'10') - parseInt(b.name.match(/\d+/)[0],'10');
              // });
              if (end) {
                if (state.selected_counter_list_right?.length > 0) {
                  tem = [];
                  list.forEach((e) => {
                    if (!state.selected_counter_list_right.includes(e))
                      tem.push(e);
                  });
                } else tem = list;
                setState({
                  counters: arr,
                  selected_counter_list: arr.map((e) => e.id),
                  list1: tem,
                });
                //    forceUpdate()
              }
            }
          });
        if (k + 1 == state.selected_counter_list?.length) {
          if (state.selected_counter_list_right?.length > 0) {
            tem = [];
            list.forEach((e) => {
              if (!state.selected_counter_list_right.includes(e)) tem.push(e);
            });
          } else tem = list;
          setState({
            counters: arr,
            selected_counter_list: arr.map((e) => e.id),
            list1: tem,
          });

          //  forceUpdate()
          end = true;
        }
      }
    });
    if (list.length == 0) {
      if (state.selected_counter_list_right?.length > 0) {
        tem = [];
        list.forEach((e) => {
          if (!state.selected_counter_list_right.includes(e)) tem.push(e);
        });
      } else tem = list;

      setState({
        counters: arr,
        selected_counter_list: arr.map((e) => e.id),
        list1: tem,
      });
    }
  }
  const handleUploadLogo = (e) => {
    var ok = false;
    if (e.target.files[0] != undefined)
      if (
        e.target.files[0].size <=
        state.max_icon * 1024 * 1024
      ) {
        // switch (
        //   e.target.files[0].name.split(".")[1]
        // ) {
        //   case "png":
        //   case "jpg":
        //   case "jpeg":
        //   case "webp":
        //     ok = true;
        //     break;
        //   default:
        //     ok = false;
        //     break;
        // }
        if (
          e.target.files[0] != undefined 
          // &&
          // ok
        ) {
          var customer_id = JSON.parse(
            localStorage.getItem("auth_info")
          ).customer_id;
          {
            /* Firebase.firestore().collection('Displays').doc(state.display_id).get().then(disp => { */
          }
          let metadata = {
            customMetadata: {
              customer_id: customer_id,
              main_location_id:
                state.selected_main_location
                  .value,
              sub_location_id:
                state.selected_sub_location
                  .value,
            },
          };
          {
            /* console.log(metadata); */
          }
          var task = Firebase.storage()
            .ref(
              "Media_Gallery/Main_Display_Logo/" +
                state.display_id +
                "/" +
                e.target.files[0].name
            )
            .put(e.target.files[0], metadata);
          document.getElementById(
            "uploader1"
          ).style.display = "block";
          task.on(
            "state_changed",
            (snapshot) => {
              var percentage =
                (snapshot.bytesTransferred /
                  snapshot.totalBytes) *
                100;
              document.getElementById(
                "uploader1"
              ).value = percentage;
            },
            () => {},
            () => {
              let j = 0,
                ad = [],
                o = 0;
              document.getElementById(
                "uploader1"
              ).style.display = "none";
              Firebase.storage()
                .ref(
                  "Media_Gallery/Main_Display_Logo"
                )
                .child(state.display_id + "/")
                .listAll()
                .then((r) => {
                  r.items.forEach((i) => {
                    j++;
                    i.getDownloadURL().then(
                      function (url) {
                        setState({
                          logo: {
                            url: url,
                            name: i.name,
                          },
                        });
                      }
                    );
                  });
                })
                .catch((e) =>
                  console.log(e.message)
                );
            }
          );
        }
      } else
        notifyMessage(
          "tc",
          3,
          "Logo size must be less or equal: " +
            state.max_icon +
            " MB"
        );
  }
  //   render () {
  var ok = false;
  if (
    state.role == "Site_Admin" ||
    state.role == "System_Admin" ||
    state.role == "Location_Admin"
  )
    ok = true;
  let layout = [
    { i: "a", x: 0, y: 0, w: 1, h: 2 },
    { i: "b", x: 1, y: 0, w: 3, h: 2 },
    { i: "c", x: 2, y: 0, w: 2, h: 2 },
  ];
  var icon1 = expand,
    f = "f";
  return (
    <>
      <LoadingOverlay
        active={state.loading}
        spinner
        text="Loading"
        className="content"
      >
        <NotificationAlert ref={notificationAlert} />
        {state.alert}
        {state.colorPick ? (
          <div
            style={{
              position: "absolute",
              top: "20%",
              left: "35%",
              zIndex: "2000",
            }}
          >
            <ColorPicker
              onChange={(e) => {
                setState({ [state.colorPlace]: e.hex });
              }}
            />
          </div>
        ) : (
          ""
        )}

        <Row
          className="display"
          onClick={() => {
            if (state.colorPick)
              setState({
                colorPick: false,
              });
          }}
        >
          <Col md="12">
            {ok ? (
              <Card className="">
                <CardHeader>
                  <CardTitle tag="h4">{!state.isNew ?'Edit':'Add'} display</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form className="col-12">
                    <Row>
                      <Col className="ml-auto " xl="12" lg="12" md="12">
                        <Row className="col-md-8 col-sm-12">
                          <Col className="text-center">
                            <Button
                              color="primary"
                              onClick={(e) => {
                                var elem = document.getElementById("display");
                                if (
                                  !document
                                    .getElementById("image")
                                    .classList.contains("hov")
                                ) {
                                  document.getElementById("image").src = comp;
                                  document
                                    .getElementById("image")
                                    .classList.add("hov");

                                  if (elem.requestFullscreen) {
                                    elem.requestFullscreen();
                                  } else if (elem.mozRequestFullScreen) {
                                    /* Firefox */
                                    elem.mozRequestFullScreen();
                                  } else if (elem.webkitRequestFullscreen) {
                                    /* Chrome, Safari & Opera */
                                    elem.webkitRequestFullscreen();
                                  } else if (elem.msRequestFullscreen) {
                                    /* IE/Edge */
                                    elem.msRequestFullscreen();
                                  }
                                } else {
                                  document
                                    .getElementById("image")
                                    .classList.remove("hov");
                                  document.getElementById("image").src = exp;

                                  if (document.exitFullscreen) {
                                    document.exitFullscreen();
                                  } else if (document.mozCancelFullScreen) {
                                    /* Firefox */
                                    document.mozCancelFullScreen();
                                  } else if (document.webkitExitFullscreen) {
                                    /* Chrome, Safari and Opera */
                                    document.webkitExitFullscreen();
                                  } else if (document.msExitFullscreen) {
                                    /* IE/Edge */
                                    document.msExitFullscreen();
                                  }
                                  //forceUpdate()
                                }
                              }}
                              block
                            >
                              Preview
                            </Button>
                          </Col>
                          <Col className="text-center">
                            <Button
                              color="success"
                              onClick={(e) => handleEdit()}
                              block
                            >
                              Save
                            </Button>
                          </Col>
                          <Col className="text-center">
                            <Button
                              color="youtube"
                              onClick={(e) =>
                                props.history.push("/main_display")
                              }
                              block
                            >
                              Close
                            </Button>
                          </Col>
                        </Row>
                        <Row className="col-md-8 col-sm-12">
                          <Col className="text-center d-flex flex-column justify-content-center">
                            {/* <Col > */}
                            <FormGroup className="my">
                              <Label>Display Name</Label>
                              <Input
                                className="css-10nd86i react-select info select-location"
                                value={state.display_name}
                                type="text"
                                name="name"
                                onChange={(e) =>
                                  change(e, "display_name", "length", 1)
                                }
                              />
                            </FormGroup>
                            {/* </Col> */}
                          </Col>
                          <Col className="text-center d-flex flex-column justify-content-center">
                            <FormGroup className="my">
                              <Label>Main Location</Label>
                              <Select
                                className="react-select info select-location"
                                classNamePrefix="react-select"
                                placeholder="Select Main Location"
                                isDisabled={!state.isNew}
                                name="selectMainLocation"
                                value={state.selected_main_location}
                                onChange={(e) => onChangeMain(e)}
                                options={state.main_location_list}
                              />
                            </FormGroup>
                          </Col>
                          <Col className="text-center d-flex flex-column justify-content-center">
                            <FormGroup className="my">
                              <Label>Sub Location</Label>
                              <Select
                                className="react-select info select-location"
                                classNamePrefix="react-select"
                                placeholder="Select Sub Location"
                                isDisabled={!state.isNew}
                                name="selectSubLocation"
                                value={state.selected_sub_location}
                                onChange={(e) => onChangeSub(e)}
                                options={state.sub_location_list}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        className="col-sm-4 col-md-3"
                        style={{ minWidth: "10rem" }}
                      >
                        <Card
                        //   style={{ maxHeight: '25rem' }}
                        >
                          <CardHeader>
                            <CardTitle style={{ fontSize: "0.85rem" }}>
                              Template
                            </CardTitle>
                          </CardHeader>
                          <CardBody style={{ overflow: "auto" }}>
                            General
                            <Row>
                              <Col className="text-center">
                                <FormGroup className="">
                                  {/* <Label>Layout theme</Label> */}
                                  <Select
                                    className="react-select info select-location"
                                    classNamePrefix="react-select"
                                    placeholder={state.layout_theme}
                                    name="selectSubLocation"
                                    value={state.layout_theme}
                                    onChange={(e) => {
                                      setState({
                                        layout_theme: e.value,
                                        selected_ad: state.selected_ad,
                                        change: true,
                                      });
                                    }}
                                    options={[
                                      {
                                        label: "Single list",
                                        value: "single list",
                                      },
                                      {
                                        label: "Single list with AD",
                                        value: "single list with ad",
                                      },
                                      {
                                        label: "Double list",
                                        value: "double list",
                                      },
                                      {
                                        label: "Double list with AD",
                                        value: "double list with ad",
                                      },
                                      {
                                        label: "Mini Display",
                                        value: "mini display",
                                      },
                                    ]}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Label xs="6">Percent/Pixel</Label>
                              <Col>
                                <FormGroup className='text-center has-label'>
                                  <Switch
                                    value={state.res_sign == "%"}
                                    defaultValue={state.res_sign == "%"}
                                    // value={state.header_footer_op}
                                    offColor="success"
                                    offText="PX"
                                    onColor="success"
                                    onText="%"
                                    onChange={(event) => {
                                      setState({
                                        res_sign: event.state.value
                                          ? "%"
                                          : "px",
                                      });
                                      if (event.state.value) {
                                        state.col_num.map((p, k) => {
                                          setState({
                                            [`width${p}`]:
                                              (parseFloat(state["width" + p]) /
                                                window.screen.width) *
                                                100 +
                                              "%",
                                            [`height${p}`]:
                                              (parseFloat(state["height" + p]) /
                                                window.screen.height) *
                                                100 +
                                              "%",
                                          });
                                        });
                                        //  forceUpdate()
                                      } else {
                                        state.col_num.map((p, k) => {
                                          setState({
                                            [`width${p}`]:
                                              (parseFloat(state["width" + p]) /
                                                100) *
                                                window.screen.width +
                                              "px",
                                            [`height${p}`]:
                                              (parseFloat(state["height" + p]) /
                                                100) *
                                                window.screen.height +
                                              "px",
                                          });
                                        });
                                        //  forceUpdate()
                                      }
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            {state.col_num.map((p, k) => {
                              return (
                                <>
                                  <Row key={k + "l"}>
                                    <Label md="6">Container {p} Width</Label>
                                    <Col md="6">
                                      <FormGroup className={`has-label`}>
                                        <Row>
                                          <Col lg="12" xs="12">
                                            <div
                                              className={
                                                state.res_sign == "%"
                                                  ? "percIn"
                                                  : "pxIn"
                                              }
                                            >
                                              <Input
                                                placeholder={
                                                  parseFloat(
                                                    state[`width${p}`]
                                                  ) != undefined
                                                    ? parseFloat(
                                                        state[`width${p}`]
                                                      )
                                                    : ""
                                                }
                                                value={
                                                  parseFloat(
                                                    state[`width${p}`]
                                                  ) != undefined
                                                    ? parseFloat(
                                                        state[`width${p}`]
                                                      )
                                                    : ""
                                                }
                                                type="number"
                                                min="1"
                                                name="name"
                                                onChange={(e) => {
                                                  change(
                                                    e,
                                                    `width${p}`,
                                                    "length",
                                                    1
                                                  );
                                                }}
                                                required
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row key={k}>
                                    <Label md="6">Container {p} Height</Label>
                                    <Col md="6">
                                      <FormGroup className={`has-label`}>
                                        <Row>
                                          <Col lg="12" xs="12">
                                            <div
                                              className={
                                                state.res_sign == "%"
                                                  ? "percIn"
                                                  : "pxIn"
                                              }
                                            >
                                              <Input
                                                placeholder={
                                                  parseFloat(
                                                    state[`height${p}`]
                                                  ) != undefined
                                                    ? parseFloat(
                                                        state[`height${p}`]
                                                      )
                                                    : ""
                                                }
                                                value={
                                                  parseFloat(
                                                    state[`height${p}`]
                                                  ) != undefined
                                                    ? parseFloat(
                                                        state[`height${p}`]
                                                      )
                                                    : ""
                                                }
                                                type="number"
                                                min="1"
                                                name="name"
                                                onChange={(e) => {
                                                  change(
                                                    e,
                                                    `height${p}`,
                                                    "length",
                                                    1
                                                  );
                                                }}
                                                required
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </>
                              );
                            })}
                            <Row>
                              <Label md="5">Beep sound</Label>
                              <Col md="7">
                                <FormGroup className={`has-label`}>
                                  <Row>
                                    <img
                                    className="cursor-pointer"
                                      onClick={(e) => {
                                        document
                                          .getElementById("beep-audio")
                                          .play();
                                      }}
                                      style={{ width: "15px", margin: "2px" }}
                                      src={ply}
                                    />
                                    <select
                                      className="cus-sel"
                                      name=""
                                      id=""
                                      style={{ width: "80%" }}
                                      value={
                                        state.selected_beep ||
                                        (state.beeps?.find(
                                          (e) => e.label == state.default_beep
                                        ) &&
                                          setState({
                                            selected_beep: state.beeps?.find(
                                              (e) =>
                                                e.label == state.default_beep
                                            )?.value,
                                          }))
                                      }
                                      onChange={(e) =>
                                        change(e, "selected_beep", "length", 1)
                                      }
                                    >
                                      {/* <option>Select sound</option> */}
                                      {state.beeps != undefined
                                        ? state.beeps.map((p, k) => {
                                            return (
                                              <option key={k} value={p.value}>
                                                {p.label}
                                              </option>
                                            );
                                          })
                                        : 1}
                                    </select>
                                    <audio
                                      src={
                                        state.selected_beep != undefined
                                          ? state.selected_beep
                                          : 1
                                      }
                                      style={{ display: "none" }}
                                      id="beep-audio"
                                    ></audio>
                                  </Row>
                                </FormGroup>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col
                        className="col-sm-4 col-md-3"
                        style={{ minWidth: "10rem" }}
                      >
                        <Card>
                          <CardHeader className="pb-0 mb-0">
                            <CardTitle style={{ fontSize: "0.85rem" }}>
                              <Row md="12" className="px-0 mx-0">
                                <Col md="12" className="px-0 mx-0">
                                  Header
                                </Col>
                              </Row>
                            </CardTitle>
                          </CardHeader>
                          <CardBody className="pb-0 mb-0 overflow-auto">
                            <CardBody className="pb-0 mb-0 bg-light">
                              <CardTitle style={{ fontSize: "1rem" }}>
                                Logo
                              </CardTitle>
                              <Row className="col-12 px-0 mx-0 justify-content-center">
                                <Col col="6" className="px-0 mx-0">
                                  <Label
                                    md="12"
                                    style={{ fontSize: "0.8rem" }}
                                    className="px-0 mx-0 justify-content-center"
                                  >
                                    Show on display
                                  </Label>
                                </Col>
                                <Col
                                  col="6"
                                  style={{ fontSize: "0.8rem" }}
                                  className="d-flex flex-column px-0 mx-0 justify-content-center text-center"
                                >
                                  <FormGroup className="my">
                                    {}
                                    <Switch
                                      value={state.logo_display}
                                      offColor="success"
                                      offText="OFF"
                                      onColor="success"
                                      onText="ON"
                                      width="25"
                                      height="11"
                                      onChange={(event) => {
                                        setState({
                                          logo_display: event.state.value,
                                        });
                                      }}
                                    />
                                    {/* </Col> */}
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row md="12" className="px-0 mx-0">
                                <Label
                                  xs="4"
                                  className="px-0 mx-0"
                                  style={{ fontSize: "0.8rem" }}
                                >
                                  File
                                </Label>
                                <Col className="d-flex flex-column justify-content-center mt-0 align-items-center">
                                  <progress
                                    id="uploader1"
                                    value="0"
                                    max="100"
                                    style={{ display: "none" }}
                                  >
                                    0%
                                  </progress>
                                  <img
                                    className="cursor-pointer"
                                    src={state.logo?.url || plus}
                                    alt=""
                                    style={{
                                      width:
                                        state.logo?.url?.length > 0
                                          ? "120px"
                                          : "23px",
                                      margin: "3px",
                                      marginTop: "-3px",
                                    }}
                                    onClick={() => {
                                      document.getElementById("filee1").click();
                                    }}
                                  />
                                  <input
                                    type="file"
                                    style={{ display: "none" }}
                                    id="filee1"
                                    accept="image/*"
                                    onChange={handleUploadLogo}
                                  />
                                </Col>
                                {state.logo?.url?.length > 0 && (
                                  <Col md="3" className="mt-0">
                                    <img
                                      className="cursor-pointer"
                                      src={trash}
                                      style={{
                                        width: "15px",
                                        margin: "3px",
                                      }}
                                      alt=""
                                      onClick={() => {
                                        Firebase.storage()
                                          .ref(
                                            "Media_Gallery/Main_Display_Logo/"
                                          )
                                          .child(
                                            state.display_id +
                                              "/" +
                                              state.logo.name
                                          )
                                          .delete();
                                        setState({ logo: "" });
                                      }}
                                    />
                                  </Col>
                                )}
                              </Row>
                              <Row className="my-3">
                                <Label md="6">Percent/Pixel</Label>
                                <Col>
                                  <FormGroup className={`has-label`}>
                                    <Switch
                                      value={state.logo_size_type == "%"}
                                      defaultValue={state.logo_size_type == "%"}
                                      // value={state.header_footer_op}
                                      offColor="success"
                                      offText="PX"
                                      onColor="success"
                                      onText="%"
                                      onChange={(event) => {
                                        setState({
                                          logo_size_type: event.state.value
                                            ? "%"
                                            : "px",
                                        });
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Label xs="6">Width</Label>
                                <Col>
                                  <div
                                    className={
                                      state.logo_size_type == "%"
                                        ? "percIn"
                                        : "pxIn"
                                    }
                                  >
                                    <Input
                                      value={Number(state.logo_width)}
                                      type="number"
                                      min="0"
                                      max={
                                        state.logo_size_type == "px" &&
                                        state.res_sign == "px"
                                          ? state[`width${state.logo_column}`]
                                          : "10000"
                                      }
                                      // pattern='([0-9]|&#8734;)+'
                                      name="name"
                                      onChange={(e) =>
                                        state.logo_size_type == "px"
                                          ? setState({
                                              logo_width: e.target.value,
                                            })
                                          : setState({
                                              logo_width:
                                                e.target.value
                                                // *
                                                // state[
                                                //   `width${state.logo_column}`
                                                // ],
                                            })
                                      }
                                      required
                                    />
                                    {console.log(` state[
                                                  width${state.logo_column}
                                                ]`, state[
                                                  `width${state.logo_column}`
                                                ])}
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Label xs="6">Top padding</Label>
                                <Col>
                                  <div
                                    className={
                                      state.logo_size_type == "%"
                                        ? "percIn"
                                        : "pxIn"
                                    }
                                  >
                                    <Input
                                      value={Number(state.logo_padding_top)}
                                      type="number"
                                      min="0"
                                      name="padding"
                                      onChange={(e) =>
                                        setState({
                                          logo_padding_top: e.target.value,
                                        })
                                      }
                                      required
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Label xs="6">Bottom padding</Label>
                                <Col>
                                  <div
                                    className={
                                      state.logo_size_type == "%"
                                        ? "percIn"
                                        : "pxIn"
                                    }
                                  >
                                    <Input
                                      value={Number(state.logo_padding_bottom)}
                                      type="number"
                                      min="0"
                                      name="padding"
                                      onChange={(e) =>
                                        setState({
                                          logo_padding_bottom: e.target.value,
                                        })
                                      }
                                      required
                                    />
                                  </div>
                                </Col>
                              </Row>
                              {state.col_num?.length > 0 && (
                                <Row>
                                  <Label xs="6">Display above Column</Label>
                                  <Col>
                                    <div className="text-center">
                                      <select
                                        className="cus-sel bg-white"
                                        name=""
                                        id=""
                                        style={{ width: "5rem" }}
                                        value={state?.logo_column}
                                        onChange={({ target: { value } }) =>
                                          setState({ logo_column: value })
                                        }
                                      >
                                        {state.col_num?.map((p, k) => {
                                          return (
                                            <option key={k} value={k + 1}>
                                              {k + 1}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                  </Col>
                                </Row>
                              )}
                              <Row className="text-center">
                                <Col>
                                  <label htmlFor="left2">
                                    <input
                                      className="radio-align"
                                      style={{
                                        display: "none",
                                        verticalAlign: "middle",
                                        marginRight: "0.5rem",
                                        marginLeft: "0.25rem",
                                      }}
                                      checked={
                                        state.logo_align == "left"
                                          ? true
                                          : false
                                      }
                                      type="radio"
                                      name="logo_align"
                                      id="left2"
                                      onChange={(e) => {
                                        if (e.target.checked)
                                          setState({
                                            logo_align: "left",
                                          });
                                      }}
                                    />
                                    <img
                                      src={al_left}
                                      style={{ margin: "0.5rem" }}
                                      alt=""
                                    />
                                  </label>
                                  <label htmlFor="center2">
                                    <input
                                      className="radio-align"
                                      style={{
                                        display: "none",
                                        verticalAlign: "middle",
                                        marginRight: "0.5rem",
                                        marginLeft: "0.25rem",
                                      }}
                                      checked={
                                        state.logo_align == "center"
                                          ? true
                                          : false
                                      }
                                      type="radio"
                                      name="logo_align"
                                      id="center2"
                                      onChange={(e) => {
                                        if (e.target.checked)
                                          setState({
                                            logo_align: "center",
                                          });
                                      }}
                                    />
                                    <img
                                      src={just}
                                      style={{ margin: "0.5rem" }}
                                    />
                                  </label>
                                  <label htmlFor="right2">
                                    <input
                                      className="radio-align"
                                      style={{
                                        display: "none",
                                        verticalAlign: "middle",
                                        marginRight: "0.5rem",
                                        marginLeft: "0.25rem",
                                      }}
                                      checked={
                                        state.logo_align == "right"
                                          ? true
                                          : false
                                      }
                                      type="radio"
                                      name="logo_align"
                                      id="right2"
                                      onChange={(e) => {
                                        if (e.target.checked)
                                          setState({
                                            logo_align: "right",
                                          });
                                      }}
                                    />
                                    <img
                                      src={al_right}
                                      style={{ margin: "0.5rem" }}
                                      alt=""
                                    />
                                  </label>
                                </Col>
                              </Row>
                            </CardBody>
                            <hr />
                            <CardBody className="pb-0 mb-0 bg-light">
                              <CardTitle style={{ fontSize: "1rem" }}>
                                General
                              </CardTitle>
                              <Row className="col-12 px-0 mx-0 justify-content-center">
                                <Col col="6" className="px-0 mx-0">
                                  <Label
                                    md="12"
                                    style={{ fontSize: "0.8rem" }}
                                    className="px-0 mx-0 justify-content-center"
                                  >
                                    Counter Name
                                  </Label>
                                </Col>
                                <Col
                                  col="6"
                                  style={{ fontSize: "0.8rem" }}
                                  className="d-flex flex-column px-0 mx-0 justify-content-center text-center"
                                >
                                  <FormGroup className="my">
                                    <Input
                                      defaultValue={state.counter_name}
                                      onChange={(e) =>
                                        change(e, "counter_name", "length", 1)
                                      }
                                      placeholder="Counter name"
                                      type="text"
                                    />
                                    {/* </Col> */}
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row className="col-12 px-0 mx-0 justify-content-center">
                                <Col col="6" className="px-0 mx-0">
                                  <Label
                                    md="12"
                                    style={{ fontSize: "0.8rem" }}
                                    className="px-0 mx-0 justify-content-center"
                                  >
                                    Back color
                                  </Label>
                                </Col>
                                <Col
                                  col="6"
                                  style={{ fontSize: "0.8rem" }}
                                  className="d-flex flex-column px-0 mx-0 justify-content-center text-center"
                                >
                                  <FormGroup className="">
                                    <select
                                      className="cus-sel bg-white"
                                      name=""
                                      id=""
                                      value={state.header_back_color}
                                      style={{ width: "65%" }}
                                      placeholder={state.header_back_color}
                                      onChange={(e) =>
                                        change(
                                          e,
                                          "header_back_color",
                                          "length",
                                          1
                                        )
                                      }
                                    >
                                      {state.color_list.map((p, k) => {
                                        return (
                                          <option value={p.value}>
                                            {p.label}
                                          </option>
                                        );
                                      })}
                                    </select>
                                    <div
                                      style={{
                                        background: state.header_back_color,
                                        width: "3vh",
                                        height: "3vh",
                                        verticalAlign: "middle",
                                        borderRadius: "3px",
                                        display: "inline-block",
                                        boxShadow: "0 0 3px 0px grey",
                                        margin: "auto 5%",
                                      }}
                                      className="point1"
                                      onClick={() => {
                                        setState({
                                          colorPick: !state.colorPick,
                                          colorPlace: "header_back_color",
                                        });
                                      }}
                                    >
                                      {/* <Button
                                        style={{ background: 'transparent' }}
                                        
                                      ></Button> */}
                                    </div>
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row className="col-12 px-0 mx-0 justify-content-center">
                                <Col col="6" className="px-0 mx-0">
                                  <Label
                                    md="12"
                                    style={{ fontSize: "0.8rem" }}
                                    className="px-0 mx-0 justify-content-center"
                                  >
                                    Font color
                                  </Label>
                                </Col>
                                <Col
                                  col="6"
                                  style={{ fontSize: "0.8rem" }}
                                  className="d-flex flex-column px-0 mx-0 justify-content-center text-center"
                                >
                                  <FormGroup className="my">
                                    <select
                                      className="cus-sel bg-white"
                                      name=""
                                      id=""
                                      value={state.header_font_color}
                                      style={{ width: "65%" }}
                                      placeholder={state.header_font_color}
                                      onChange={(e) =>
                                        change(
                                          e,
                                          "header_font_color",
                                          "length",
                                          1
                                        )
                                      }
                                    >
                                      {state.color_list.map((p, k) => {
                                        return (
                                          <option value={p.value}>
                                            {p.label}
                                          </option>
                                        );
                                      })}
                                    </select>
                                    <div
                                      style={{
                                        background: state.header_font_color,
                                        width: "3vh",
                                        height: "3vh",
                                        verticalAlign: "middle",
                                        borderRadius: "3px",
                                        display: "inline-block",
                                        boxShadow: "0 0 3px 0px grey",
                                        margin: "auto 5%",
                                      }}
                                      className="point1"
                                      onClick={() => {
                                        setState({
                                          colorPick: !state.colorPick,
                                          colorPlace: "header_font_color",
                                        });
                                      }}
                                    ></div>
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row className="col-12 px-0 mx-0 justify-content-center">
                                <Col col="6" className="px-0 mx-0">
                                  <Label
                                    md="12"
                                    style={{ fontSize: "0.8rem" }}
                                    className="px-0 mx-0 justify-content-center"
                                  >
                                    Font size
                                  </Label>
                                </Col>
                                <Col
                                  col="6"
                                  style={{ fontSize: "0.8rem" }}
                                  className="d-flex flex-column px-0 mx-0 justify-content-center text-center"
                                >
                                  <FormGroup className="my">
                                    <select
                                      className="cus-sel bg-white"
                                      name=""
                                      id=""
                                      value={state.header_font_size}
                                      placeholder={state.header_font_size}
                                      onChange={(e) =>
                                        change(
                                          e,
                                          "header_font_size",
                                          "length",
                                          1
                                        )
                                      }
                                    >
                                      {state.font_size_list.map((p, k) => {
                                        return (
                                          <option value={p.value}>
                                            {p.label}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row className="col-12 px-0 mx-0 justify-content-center">
                                <Col col="6" className="px-0 mx-0">
                                  <Label
                                    md="12"
                                    style={{ fontSize: "0.8rem" }}
                                    className="px-0 mx-0 justify-content-center"
                                  >
                                    Bold font
                                  </Label>
                                </Col>
                                <Col
                                  col="6"
                                  style={{ fontSize: "0.8rem" }}
                                  className="d-flex flex-column px-0 mx-0 justify-content-center text-center"
                                >
                                  <FormGroup className="my">
                                    {}
                                    <Switch
                                      value={state.header_bold_font}
                                      offColor="success"
                                      offText="OFF"
                                      onColor="success"
                                      onText="ON"
                                      width="25"
                                      height="11"
                                      onChange={(event) => {
                                        setState({
                                          header_bold_font: event.state.value,
                                        });
                                      }}
                                    />
                                    {/* </Col> */}
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row className="">
                                <Col>
                                  <label htmlFor="">Align text</label>
                                </Col>
                              </Row>
                              <Row className="text-center">
                                <Col>
                                  <label htmlFor="left1">
                                    <input
                                      className="radio-align"
                                      style={{
                                        display: "none",
                                        verticalAlign: "middle",
                                        marginRight: "0.5rem",
                                        marginLeft: "0.25rem",
                                      }}
                                      checked={state.header_align == "left"}
                                      type="radio"
                                      name="align1"
                                      id="left1"
                                      onChange={(e) => {
                                        if (e.target.checked)
                                          setState({
                                            header_align: "left",
                                          });
                                      }}
                                    />
                                    <img
                                      src={al_left}
                                      style={{ margin: "0.5rem" }}
                                      alt=""
                                    />
                                  </label>
                                  <label htmlFor="center1">
                                    <input
                                      className="radio-align"
                                      style={{
                                        display: "none",
                                        verticalAlign: "middle",
                                        marginRight: "0.5rem",
                                        marginLeft: "0.25rem",
                                      }}
                                      checked={state.header_align == "center"}
                                      type="radio"
                                      name="align1"
                                      id="center1"
                                      onChange={(e) => {
                                        if (e.target.checked)
                                          setState({
                                            header_align: "center",
                                          });
                                      }}
                                    />
                                    <img
                                      src={just}
                                      style={{ margin: "0.5rem" }}
                                    />
                                  </label>
                                  <label htmlFor="right1">
                                    <input
                                      className="radio-align"
                                      style={{
                                        display: "none",
                                        verticalAlign: "middle",
                                        marginRight: "0.5rem",
                                        marginLeft: "0.25rem",
                                      }}
                                      checked={state.header_align == "right"}
                                      type="radio"
                                      name="align1"
                                      id="right1"
                                      onChange={(e) => {
                                        if (e.target.checked)
                                          setState({
                                            header_align: "right",
                                          });
                                      }}
                                    />
                                    <img
                                      src={al_right}
                                      style={{ margin: "0.5rem" }}
                                      alt=""
                                    />
                                  </label>
                                </Col>
                              </Row>
                            </CardBody>
                          </CardBody>
                        </Card>
                      </Col>
                      {/* Body */}
                      <Col
                        className="col-sm-4 col-md-3"
                        style={{ minWidth: "10rem" }}
                      >
                        <Card>
                          <CardHeader>
                            <CardTitle style={{ fontSize: "0.85rem" }}>
                              <Row md="12" className="px-0 mx-0">
                                <Col md="12" className="px-0 mx-0">
                                  Body
                                </Col>
                              </Row>
                            </CardTitle>
                          </CardHeader>
                          <div style={{ maxHeight: "85vh", overflow: "auto" }}>
                            <CardBody>
                              General
                              <Row className="col-12 px-0 mx-0 justify-content-center">
                                <Col col="6" className="px-0 mx-0">
                                  <Label
                                    md="12"
                                    style={{ fontSize: "0.8rem" }}
                                    className="px-0 mx-0 justify-content-center"
                                  >
                                    Back color
                                  </Label>
                                </Col>
                                <Col
                                  col="6"
                                  style={{ fontSize: "0.8rem" }}
                                  className="d-flex flex-column px-0 mx-0 justify-content-center text-center"
                                >
                                  <FormGroup className="my">
                                    <select
                                      className="cus-sel bg-white"
                                      name=""
                                      id=""
                                      value={state.tokens_back_color}
                                      style={{ width: "65%" }}
                                      placeholder={state.tokens_back_color}
                                      onChange={(e) =>
                                        change(
                                          e,
                                          "tokens_back_color",
                                          "length",
                                          1
                                        )
                                      }
                                    >
                                      {state.color_list.map((p, k) => {
                                        return (
                                          <option value={p.value}>
                                            {p.label}
                                          </option>
                                        );
                                      })}
                                    </select>
                                    <div
                                      style={{
                                        background: state.tokens_back_color,
                                        width: "3vh",
                                        height: "3vh",
                                        verticalAlign: "middle",
                                        borderRadius: "3px",
                                        display: "inline-block",
                                        boxShadow: "0 0 3px 0px grey",
                                        margin: "auto 5%",
                                      }}
                                      className="point1"
                                      onClick={() => {
                                        setState({
                                          colorPick: !state.colorPick,
                                          colorPlace: "tokens_back_color",
                                        });
                                      }}
                                    ></div>
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row className="col-12 px-0 mx-0 justify-content-center">
                                <Col col="6" className="px-0 mx-0">
                                  <Label
                                    md="12"
                                    style={{ fontSize: "0.8rem" }}
                                    className="px-0 mx-0 justify-content-center"
                                  >
                                    Font color
                                  </Label>
                                </Col>
                                <Col
                                  col="6"
                                  style={{ fontSize: "0.8rem" }}
                                  className="d-flex flex-column px-0 mx-0 justify-content-center text-center"
                                >
                                  <FormGroup className="my">
                                    <select
                                      className="cus-sel bg-white"
                                      name=""
                                      id=""
                                      value={state.tokens_font_color}
                                      style={{ width: "65%" }}
                                      placeholder={state.tokens_font_color}
                                      onChange={(e) =>
                                        change(
                                          e,
                                          "tokens_font_color",
                                          "length",
                                          1
                                        )
                                      }
                                    >
                                      {state.color_list.map((p, k) => {
                                        return (
                                          <option value={p.value}>
                                            {p.label}
                                          </option>
                                        );
                                      })}
                                    </select>
                                    <div
                                      style={{
                                        background: state.tokens_font_color,
                                        width: "3vh",
                                        height: "3vh",
                                        verticalAlign: "middle",
                                        borderRadius: "3px",
                                        display: "inline-block",
                                        boxShadow: "0 0 3px 0px grey",
                                        margin: "auto 5%",
                                      }}
                                      className="point1"
                                      onClick={() => {
                                        setState({
                                          colorPick: !state.colorPick,
                                          colorPlace: "tokens_font_color",
                                        });
                                      }}
                                    ></div>
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row className="col-12 px-0 mx-0 justify-content-center">
                                <Col col="6" className="px-0 mx-0">
                                  <Label
                                    md="12"
                                    style={{ fontSize: "0.8rem" }}
                                    className="px-0 mx-0 justify-content-center"
                                  >
                                    Line color
                                  </Label>
                                </Col>
                                <Col
                                  col="6"
                                  style={{ fontSize: "0.8rem" }}
                                  className="d-flex flex-column px-0 mx-0 justify-content-center text-center"
                                >
                                  <FormGroup className="my">
                                    <select
                                      className="cus-sel bg-white"
                                      name=""
                                      id=""
                                      value={state.tokens_line_color}
                                      style={{ width: "65%" }}
                                      placeholder={state.tokens_line_color}
                                      onChange={(e) =>
                                        change(
                                          e,
                                          "tokens_line_color",
                                          "length",
                                          1
                                        )
                                      }
                                    >
                                      {state.color_list.map((p, k) => {
                                        return (
                                          <option value={p.value} key={k}>
                                            {p.label}
                                          </option>
                                        );
                                      })}
                                    </select>
                                    <div
                                      style={{
                                        background: state.tokens_line_color,
                                        width: "3vh",
                                        height: "3vh",
                                        verticalAlign: "middle",
                                        borderRadius: "3px",
                                        display: "inline-block",
                                        boxShadow: "0 0 3px 0px grey",
                                        margin: "auto 5%",
                                      }}
                                      className="point1"
                                      onClick={() => {
                                        setState({
                                          colorPick: !state.colorPick,
                                          colorPlace: "tokens_line_color",
                                        });
                                      }}
                                    ></div>
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row className="col-12 px-0 mx-0 justify-content-center">
                                <Col col="6" className="px-0 mx-0">
                                  <Label
                                    md="12"
                                    style={{ fontSize: "0.8rem" }}
                                    className="px-0 mx-0 justify-content-center"
                                  >
                                    Font size
                                  </Label>
                                </Col>
                                <Col
                                  col="6"
                                  style={{ fontSize: "0.8rem" }}
                                  className="d-flex flex-column px-0 mx-0 justify-content-center text-center"
                                >
                                  <FormGroup className="my">
                                    <select
                                      className="cus-sel bg-white"
                                      name=""
                                      id=""
                                      value={state.general_font_size}
                                      placeholder={state.general_font_size}
                                      onChange={(e) => {
                                        change(
                                          e,
                                          "general_font_size",
                                          "length",
                                          1
                                        );
                                      }}
                                    >
                                      {state.font_size_list.map((p, k) => {
                                        return (
                                          <option value={p.value}>
                                            {p.label}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row className="col-12 px-0 mx-0 justify-content-center">
                                <Col col="6" className="px-0 mx-0">
                                  <Label
                                    md="12"
                                    style={{ fontSize: "0.8rem" }}
                                    className="px-0 mx-0 justify-content-center"
                                  >
                                    Bold font
                                  </Label>
                                </Col>
                                <Col
                                  col="6"
                                  style={{ fontSize: "0.8rem" }}
                                  className="d-flex flex-column px-0 mx-0 justify-content-center text-center"
                                >
                                  <FormGroup className="my">
                                    {/* <Col md="2" xs="2" data-id={prop.service_id} className="service text-center"> */}

                                    <Switch
                                      // className="left-margin-30"
                                      value={state.tokens_bold_font}
                                      offColor="success"
                                      offText="OFF"
                                      onColor="success"
                                      onText="ON"
                                      onChange={(event) => {
                                        setState({
                                          tokens_bold_font: event.state.value,
                                        });
                                      }}
                                    />
                                    {/* </Col> */}
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row className="">
                                <Col>
                                  <label htmlFor="">Align text</label>
                                </Col>
                              </Row>
                              <Row className="text-center">
                                <Col>
                                  <label htmlFor="left">
                                    <input
                                      className="radio-align"
                                      style={{
                                        display: "none",
                                        verticalAlign: "middle",
                                        marginRight: "0.5rem",
                                        marginLeft: "0.25rem",
                                      }}
                                      checked={
                                        state.tokens_align == "left"
                                          ? true
                                          : false
                                      }
                                      type="radio"
                                      name="align"
                                      id="left"
                                      onChange={(e) => {
                                        if (e.target.checked)
                                          setState({
                                            tokens_align: "left",
                                          });
                                      }}
                                    />
                                    <img
                                      src={al_left}
                                      style={{ margin: "0.5rem" }}
                                      alt=""
                                    />
                                  </label>
                                  <label htmlFor="center">
                                    <input
                                      className="radio-align"
                                      style={{
                                        display: "none",
                                        verticalAlign: "middle",
                                        marginRight: "0.5rem",
                                        marginLeft: "0.25rem",
                                      }}
                                      checked={
                                        state.tokens_align == "center"
                                          ? true
                                          : false
                                      }
                                      type="radio"
                                      name="align"
                                      id="center"
                                      onChange={(e) => {
                                        if (e.target.checked)
                                          setState({
                                            tokens_align: "center",
                                          });
                                      }}
                                    />
                                    <img
                                      src={just}
                                      style={{ margin: "0.5rem" }}
                                    />
                                  </label>
                                  <label htmlFor="right">
                                    <input
                                      className="radio-align"
                                      style={{
                                        display: "none",
                                        verticalAlign: "middle",
                                        marginRight: "0.5rem",
                                        marginLeft: "0.25rem",
                                      }}
                                      checked={
                                        state.tokens_align == "right"
                                          ? true
                                          : false
                                      }
                                      type="radio"
                                      name="align"
                                      id="right"
                                      onChange={(e) => {
                                        if (e.target.checked)
                                          setState({
                                            tokens_align: "right",
                                          });
                                      }}
                                    />
                                    <img
                                      src={al_right}
                                      style={{ margin: "0.5rem" }}
                                      alt=""
                                    />
                                  </label>
                                </Col>
                              </Row>
                            </CardBody>
                            {state.col_num.length > 0 ? (
                              <>
                                <CardHeader className="pb-0 mb-0">
                                  <CardTitle style={{ fontSize: "0.85rem" }}>
                                    <Row md="12" className="px-0 mx-0">
                                      <Col md="12" className="px-0 mx-0">
                                        Columns
                                      </Col>
                                    </Row>
                                  </CardTitle>
                                </CardHeader>
                                <CardBody className="pb-0 mb-0">
                                  Column 1
                                  <Row className="col-12 px-0 mx-0 justify-content-center">
                                    <Col col="6" className="px-0 mx-0">
                                      <Label
                                        md="12"
                                        style={{ fontSize: "0.8rem" }}
                                        className="px-0 mx-0 justify-content-center"
                                      >
                                        Name
                                      </Label>
                                    </Col>
                                    <Col
                                      col="6"
                                      style={{ fontSize: "0.8rem" }}
                                      className="d-flex flex-column px-0 mx-0 justify-content-center text-center"
                                    >
                                      <FormGroup className="my">
                                        <Input
                                          defaultValue={state.counter_name}
                                          onChange={(e) =>
                                            change(
                                              e,
                                              "counter_name",
                                              "length",
                                              1
                                            )
                                          }
                                          placeholder="Counters name"
                                          type="text"
                                        />
                                        {/* </Col> */}
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row className="col-12 px-0 mx-0 justify-content-center">
                                    <Col col="6" className="px-0 mx-0">
                                      <Label
                                        md="12"
                                        style={{ fontSize: "0.8rem" }}
                                        className="px-0 mx-0 justify-content-center"
                                      >
                                        Show on display
                                      </Label>
                                    </Col>
                                    <Col
                                      style={{ fontSize: "0.8rem" }}
                                      className="d-flex flex-column px-0 mx-0 justify-content-center text-center"
                                    >
                                      <FormGroup className="my">
                                        {}
                                        <Switch
                                          value={state.counter_display}
                                          offColor="success"
                                          offText="OFF"
                                          onColor="success"
                                          onText="ON"
                                          width="25"
                                          height="11"
                                          onChange={(event) => {
                                            setState({
                                              counter_display:
                                                event.state.value,
                                            });
                                          }}
                                        />
                                        {/* </Col> */}
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row className="col-12 px-0 mx-0 justify-content-center">
                                    <Col col="6" className="px-0 mx-0">
                                      <Label
                                        md="12"
                                        style={{ fontSize: "0.8rem" }}
                                        className="px-0 mx-0 justify-content-center"
                                      >
                                        Font size
                                      </Label>
                                    </Col>
                                    <Col
                                      col="6"
                                      style={{ fontSize: "0.8rem" }}
                                      className="d-flex flex-column px-0 mx-0 justify-content-center text-center"
                                    >
                                      <FormGroup className="my">
                                        <select
                                          className="cus-sel bg-white"
                                          name=""
                                          id=""
                                          value={state.counter_font_size}
                                          placeholder={state.counter_font_size}
                                          onChange={(e) =>
                                            change(
                                              e,
                                              "counter_font_size",
                                              "length",
                                              1
                                            )
                                          }
                                        >
                                          {state.font_size_list.map((p, k) => {
                                            return (
                                              <option value={p.value}>
                                                {p.label}
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </CardBody>

                                <CardBody className="pb-0 mb-0">
                                  Column 2
                                  <Row className="col-12 px-0 mx-0 justify-content-center">
                                    <Col col="6" className="px-0 mx-0">
                                      <Label
                                        md="12"
                                        style={{ fontSize: "0.8rem" }}
                                        className="px-0 mx-0 justify-content-center"
                                      >
                                        Name
                                      </Label>
                                    </Col>
                                    <Col
                                      col="6"
                                      style={{ fontSize: "0.8rem" }}
                                      className="d-flex flex-column px-0 mx-0 justify-content-center text-center"
                                    >
                                      <FormGroup className="my">
                                        <Input
                                          defaultValue={state.detail_name}
                                          onChange={(e) =>
                                            change(
                                              e,
                                              "detail_name",
                                              "length",
                                              1
                                            )
                                          }
                                          placeholder="Details name"
                                          type="text"
                                        />
                                        {/* </Col> */}
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row className="col-12 px-0 mx-0 justify-content-center">
                                    <Col col="6" className="px-0 mx-0">
                                      <Label
                                        md="12"
                                        style={{ fontSize: "0.8rem" }}
                                        className="px-0 mx-0 justify-content-center"
                                      >
                                        Show on display
                                      </Label>
                                    </Col>
                                    <Col
                                      col="6"
                                      style={{ fontSize: "0.8rem" }}
                                      className="d-flex flex-column px-0 mx-0 justify-content-center text-center"
                                    >
                                      <FormGroup className="my">
                                        {}
                                        <Switch
                                          value={state.detail_display}
                                          offColor="success"
                                          offText="OFF"
                                          onColor="success"
                                          onText="ON"
                                          width="25"
                                          height="11"
                                          onChange={(event) => {
                                            setState({
                                              detail_display: event.state.value,
                                            });
                                          }}
                                        />
                                        {/* </Col> */}
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row className="col-12 px-0 mx-0 justify-content-center">
                                    <Col col="6" className="px-0 mx-0">
                                      <Label
                                        md="12"
                                        style={{ fontSize: "0.8rem" }}
                                        className="px-0 mx-0 justify-content-center"
                                      >
                                        Font size
                                      </Label>
                                    </Col>
                                    <Col
                                      col="6"
                                      style={{ fontSize: "0.8rem" }}
                                      className="d-flex flex-column px-0 mx-0 justify-content-center text-center"
                                    >
                                      <FormGroup className="my">
                                        <select
                                          className="cus-sel bg-white"
                                          name=""
                                          id=""
                                          value={state.detail_font_size}
                                          placeholder={state.detail_font_size}
                                          onChange={(e) =>
                                            change(
                                              e,
                                              "detail_font_size",
                                              "length",
                                              1
                                            )
                                          }
                                        >
                                          {state.font_size_list.map((p, k) => {
                                            return (
                                              <option value={p.value}>
                                                {p.label}
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </CardBody>

                                <CardBody className="pb-0 mb-0">
                                  Column 3
                                  <Row className="col-12 px-0 mx-0 justify-content-center">
                                    <Col col="6" className="px-0 mx-0">
                                      <Label
                                        md="12"
                                        style={{ fontSize: "0.8rem" }}
                                        className="px-0 mx-0 justify-content-center"
                                      >
                                        Name
                                      </Label>
                                    </Col>
                                    <Col
                                      col="6"
                                      style={{ fontSize: "0.8rem" }}
                                      className="d-flex flex-column px-0 mx-0 justify-content-center text-center"
                                    >
                                      <FormGroup className="my">
                                        <Input
                                          value={state.token_name}
                                          onChange={(e) =>
                                            change(e, "token_name", "length", 1)
                                          }
                                          placeholder="Tokens name"
                                          type="text"
                                        />
                                        {/* </Col> */}
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row className="col-12 px-0 mx-0 justify-content-center">
                                    <Col col="6" className="px-0 mx-0">
                                      <Label
                                        md="12"
                                        style={{ fontSize: "0.8rem" }}
                                        className="px-0 mx-0 justify-content-center"
                                      >
                                        Show on display
                                      </Label>
                                    </Col>
                                    <Col
                                      col="6"
                                      style={{ fontSize: "0.8rem" }}
                                      className="d-flex flex-column px-0 mx-0 justify-content-center text-center"
                                    >
                                      <FormGroup className="my">
                                        {}
                                        <Switch
                                          value={state.token_display}
                                          offColor="success"
                                          offText="OFF"
                                          onColor="success"
                                          onText="ON"
                                          width="25"
                                          height="11"
                                          onChange={(event) => {
                                            setState({
                                              token_display: event.state.value,
                                            });
                                          }}
                                        />
                                        {/* </Col> */}
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row className="col-12 px-0 mx-0 justify-content-center">
                                    <Col col="6" className="px-0 mx-0">
                                      <Label
                                        md="12"
                                        style={{ fontSize: "0.8rem" }}
                                        className="px-0 mx-0 justify-content-center"
                                      >
                                        Font size
                                      </Label>
                                    </Col>
                                    <Col
                                      col="6"
                                      style={{ fontSize: "0.8rem" }}
                                      className="d-flex flex-column px-0 mx-0 justify-content-center text-center"
                                    >
                                      <FormGroup className="my">
                                        <select
                                          className="cus-sel bg-white"
                                          name=""
                                          id=""
                                          value={state.tokens_font_size}
                                          placeholder={state.tokens_font_size}
                                          onChange={(e) =>
                                            change(
                                              e,
                                              "tokens_font_size",
                                              "length",
                                              1
                                            )
                                          }
                                        >
                                          {state.font_size_list.map((p, k) => {
                                            return (
                                              <option value={p.value}>
                                                {p.label}
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </Card>
                      </Col>
                      {/* footer */}

                      <Col
                        className="col-sm-4 col-md-3"
                        style={{ minWidth: "10rem" }}
                      >
                        <Card>
                          <CardHeader className="pb-0 mb-0">
                            <CardTitle style={{ fontSize: "0.85rem" }}>
                              <Row md="12" className="px-0 mx-0">
                                <Col md="7" className="px-0 mx-0">
                                  Footer
                                </Col>
                                <Col md="5" className="px-0 mx-0"></Col>
                              </Row>
                            </CardTitle>
                          </CardHeader>
                          <div style={{ maxHeight: "85vh", overflow: "auto" }}>
                            <CardBody className="pb-0 mb-0">
                              <CardBody className="pb-0 mb-0 bg-light">
                                <CardTitle style={{ fontSize: "1rem" }}>
                                  Date
                                </CardTitle>
                                <Row className="col-12 px-0 mx-0 justify-content-center">
                                  <Col col="6" className="px-0 mx-0">
                                    <Label
                                      md="12"
                                      style={{ fontSize: "0.8rem" }}
                                      className="px-0 mx-0 justify-content-center"
                                    >
                                      Show on display
                                    </Label>
                                  </Col>
                                  <Col
                                    col="6"
                                    style={{ fontSize: "0.8rem" }}
                                    className="d-flex flex-column px-0 mx-0 justify-content-center text-center"
                                  >
                                    <FormGroup className="my">
                                      {}
                                      <Switch
                                        value={state.date_display}
                                        offColor="success"
                                        offText="OFF"
                                        onColor="success"
                                        onText="ON"
                                        width="25"
                                        height="11"
                                        onChange={(event) => {
                                          setState({
                                            date_display: event.state.value,
                                          });
                                        }}
                                      />
                                      {/* </Col> */}
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row className="col-12 px-0 mx-0 justify-content-center">
                                  <Col col="6" className="px-0 mx-0">
                                    <Label
                                      md="12"
                                      style={{ fontSize: "0.8rem" }}
                                      className="px-0 mx-0 justify-content-center"
                                    >
                                      Back color
                                    </Label>
                                  </Col>
                                  <Col
                                    col="6"
                                    style={{ fontSize: "0.8rem" }}
                                    className="d-flex flex-column px-0 mx-0 justify-content-center text-center"
                                  >
                                    <FormGroup className="my">
                                      <select
                                        className="cus-sel bg-white"
                                        name=""
                                        id=""
                                        style={{ width: "65%" }}
                                        onChange={(e) =>
                                          change(
                                            e,
                                            "date_back_color",
                                            "length",
                                            1
                                          )
                                        }
                                      >
                                        {state.color_list.map((p, k) => {
                                          return (
                                            <option value={p.value}>
                                              {p.label}
                                            </option>
                                          );
                                        })}
                                      </select>
                                      <div
                                        style={{
                                          background: state.date_back_color,
                                          width: "3vh",
                                          height: "3vh",
                                          verticalAlign: "middle",
                                          borderRadius: "3px",
                                          display: "inline-block",
                                          boxShadow: "0 0 3px 0px grey",
                                          margin: "auto 5%",
                                        }}
                                        className="point1"
                                        onClick={() => {
                                          setState({
                                            colorPick: !state.colorPick,
                                            colorPlace: "date_back_color",
                                          });
                                        }}
                                      ></div>
                                    </FormGroup>
                                  </Col>
                                </Row>

                                <Row className="col-12 px-0 mx-0 justify-content-center">
                                  <Col col="6" className="px-0 mx-0">
                                    <Label
                                      md="12"
                                      style={{ fontSize: "0.8rem" }}
                                      className="px-0 mx-0 justify-content-center"
                                    >
                                      Font color
                                    </Label>
                                  </Col>
                                  <Col
                                    col="6"
                                    style={{ fontSize: "0.8rem" }}
                                    className="d-flex flex-column px-0 mx-0 justify-content-center text-center"
                                  >
                                    <FormGroup className="my">
                                      <select
                                        className="cus-sel bg-white"
                                        name=""
                                        id=""
                                        value={state.date_font_color}
                                        style={{ width: "65%" }}
                                        placeholder={state.date_font_color}
                                        onChange={(e) =>
                                          change(
                                            e,
                                            "date_font_color",
                                            "length",
                                            1
                                          )
                                        }
                                      >
                                        {state.color_list.map((p, k) => {
                                          return (
                                            <option value={p.value}>
                                              {p.label}
                                            </option>
                                          );
                                        })}
                                      </select>
                                      <div
                                        style={{
                                          background: state.date_font_color,
                                          width: "3vh",
                                          height: "3vh",
                                          verticalAlign: "middle",
                                          borderRadius: "3px",
                                          display: "inline-block",
                                          boxShadow: "0 0 3px 0px grey",
                                          margin: "auto 5%",
                                        }}
                                        className="point1"
                                        onClick={() => {
                                          setState({
                                            colorPick: !state.colorPick,
                                            colorPlace: "date_font_color",
                                          });
                                        }}
                                      ></div>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row className="col-12 px-0 mx-0 justify-content-center">
                                  <Col col="6" className="px-0 mx-0">
                                    <Label
                                      md="12"
                                      style={{ fontSize: "0.8rem" }}
                                      className="px-0 mx-0 justify-content-center"
                                    >
                                      Font size
                                    </Label>
                                  </Col>
                                  <Col
                                    col="6"
                                    style={{ fontSize: "0.8rem" }}
                                    className="d-flex flex-column px-0 mx-0 justify-content-center text-center"
                                  >
                                    <FormGroup className="my">
                                      <select
                                        className="cus-sel bg-white"
                                        name=""
                                        id=""
                                        value={state.date_font_size}
                                        placeholder={state.date_font_size}
                                        onChange={(e) =>
                                          change(
                                            e,
                                            "date_font_size",
                                            "length",
                                            1
                                          )
                                        }
                                      >
                                        {state.font_size_list.map((p, k) => {
                                          return (
                                            <option value={p.value}>
                                              {p.label}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row className="col-12 px-0 mx-0 justify-content-center">
                                  <Col col="6" className="px-0 mx-0">
                                    <Label
                                      md="12"
                                      style={{ fontSize: "0.8rem" }}
                                      className="px-0 mx-0 justify-content-center"
                                    >
                                      Bold font
                                    </Label>
                                  </Col>
                                  <Col
                                    col="6"
                                    style={{ fontSize: "0.8rem" }}
                                    className="d-flex flex-column px-0 mx-0 justify-content-center text-center"
                                  >
                                    <FormGroup className="my">
                                      {/* <Col md="2" xs="2" data-id={prop.service_id} className="service text-center"> */}
                                      <Switch
                                        value={state.date_bold_font}
                                        offColor="success"
                                        offText="OFF"
                                        onColor="success"
                                        onText="ON"
                                        onChange={(event) => {
                                          setState({
                                            date_bold_font: event.state.value,
                                          });
                                        }}
                                      />
                                      {/* </Col> */}
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row className="">
                                  <Col>
                                    <label htmlFor="">Align text</label>
                                  </Col>
                                </Row>
                                <Row className="text-center">
                                  <Col>
                                    <label>
                                      <input
                                        className="radio-align"
                                        style={{
                                          display: "none",
                                          verticalAlign: "middle",
                                          marginRight: "0.5rem",
                                          marginLeft: "0.25rem",
                                        }}
                                        checked={
                                          state.date_align == "left"
                                            ? true
                                            : false
                                        }
                                        type="radio"
                                        name="date_align"
                                        id="left2"
                                        onChange={(e) => {
                                          if (e.target.checked)
                                            setState({
                                              date_align: "left",
                                            });
                                        }}
                                      />
                                      <img
                                        src={al_left}
                                        style={{ margin: "0.5rem" }}
                                        alt=""
                                      />
                                    </label>
                                    <label>
                                      <input
                                        className="radio-align"
                                        style={{
                                          display: "none",
                                          verticalAlign: "middle",
                                          marginRight: "0.5rem",
                                          marginLeft: "0.25rem",
                                        }}
                                        checked={
                                          state.date_align == "center"
                                            ? true
                                            : false
                                        }
                                        type="radio"
                                        name="date_align"
                                        id="center2"
                                        onChange={(e) => {
                                          if (e.target.checked)
                                            setState({
                                              date_align: "center",
                                            });
                                        }}
                                      />
                                      <img
                                        src={just}
                                        style={{ margin: "0.5rem" }}
                                      />
                                    </label>
                                    <label>
                                      <input
                                        className="radio-align"
                                        style={{
                                          display: "none",
                                          verticalAlign: "middle",
                                          marginRight: "0.5rem",
                                          marginLeft: "0.25rem",
                                        }}
                                        checked={
                                          state.date_align == "right"
                                            ? true
                                            : false
                                        }
                                        type="radio"
                                        name="date_align"
                                        id="right2"
                                        onChange={(e) => {
                                          if (e.target.checked)
                                            setState({
                                              date_align: "right",
                                            });
                                        }}
                                      />
                                      <img
                                        src={al_right}
                                        style={{ margin: "0.5rem" }}
                                        alt=""
                                      />
                                    </label>
                                  </Col>
                                </Row>
                              </CardBody>
                              {/* </Col> */}

                              <hr />

                              <CardBody className="pb-0 mb-0 bg-light">
                                <CardTitle style={{ fontSize: "1rem" }}>
                                  Message
                                </CardTitle>
                                <Row className="col-12 px-0 mx-0 justify-content-center">
                                  <Col col="6" className="px-0 mx-0">
                                    <Label
                                      md="12"
                                      style={{ fontSize: "0.8rem" }}
                                      className="px-0 mx-0 justify-content-center"
                                    >
                                      Text
                                    </Label>
                                  </Col>
                                  <Col
                                    col="6"
                                    style={{ fontSize: "0.8rem" }}
                                    className="d-flex flex-column px-0 mx-0 justify-content-center text-center"
                                  >
                                    <FormGroup className="my">
                                      <Input
                                        defaultValue={state.footer_message}
                                        placeholder="Footer message text"
                                        type="text"
                                        onChange={(e) =>
                                          change(
                                            e,
                                            "footer_message",
                                            "length",
                                            1
                                          )
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row className="col-12 px-0 mx-0 justify-content-center">
                                  <Col col="6" className="px-0 mx-0">
                                    <Label
                                      md="12"
                                      style={{ fontSize: "0.8rem" }}
                                      className="px-0 mx-0 justify-content-center"
                                    >
                                      Back color
                                    </Label>
                                  </Col>
                                  <Col
                                    col="6"
                                    style={{ fontSize: "0.8rem" }}
                                    className="d-flex flex-column px-0 mx-0 justify-content-center text-center"
                                  >
                                    <FormGroup className="my">
                                      <select
                                        className="cus-sel bg-white"
                                        name=""
                                        id=""
                                        style={{ width: "65%" }}
                                        onChange={(e) =>
                                          change(
                                            e,
                                            "footer_message_back_color",
                                            "length",
                                            1
                                          )
                                        }
                                      >
                                        {state.color_list.map((p, k) => {
                                          return (
                                            <option value={p.value}>
                                              {p.label}
                                            </option>
                                          );
                                        })}
                                      </select>
                                      <div
                                        style={{
                                          background:
                                            state.footer_message_back_color,
                                          width: "3vh",
                                          height: "3vh",
                                          verticalAlign: "middle",
                                          borderRadius: "3px",
                                          display: "inline-block",
                                          boxShadow: "0 0 3px 0px grey",
                                          margin: "auto 5%",
                                        }}
                                        className="point1"
                                        onClick={() => {
                                          setState({
                                            colorPick: !state.colorPick,
                                            colorPlace:
                                              "footer_message_back_color",
                                          });
                                        }}
                                      ></div>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row className="col-12 px-0 mx-0 justify-content-center">
                                  <Col col="6" className="px-0 mx-0">
                                    <Label
                                      md="12"
                                      style={{ fontSize: "0.8rem" }}
                                      className="px-0 mx-0 justify-content-center"
                                    >
                                      Font color
                                    </Label>
                                  </Col>
                                  <Col
                                    col="6"
                                    style={{ fontSize: "0.8rem" }}
                                    className="d-flex flex-column px-0 mx-0 justify-content-center text-center"
                                  >
                                    <FormGroup className="my">
                                      <select
                                        className="cus-sel bg-white"
                                        name=""
                                        id=""
                                        value={state.footer_message_font_color}
                                        style={{ width: "65%" }}
                                        placeholder={
                                          state.footer_message_font_color
                                        }
                                        onChange={(e) =>
                                          change(
                                            e,
                                            "footer_message_font_color",
                                            "length",
                                            1
                                          )
                                        }
                                      >
                                        {state.color_list.map((p, k) => {
                                          return (
                                            <option value={p.value}>
                                              {p.label}
                                            </option>
                                          );
                                        })}
                                      </select>
                                      <div
                                        style={{
                                          background:
                                            state.footer_message_font_color,
                                          width: "3vh",
                                          height: "3vh",
                                          verticalAlign: "middle",
                                          borderRadius: "3px",
                                          display: "inline-block",
                                          boxShadow: "0 0 3px 0px grey",
                                          margin: "auto 5%",
                                        }}
                                        className="point1"
                                        onClick={() => {
                                          setState({
                                            colorPick: !state.colorPick,
                                            colorPlace:
                                              "footer_message_font_color",
                                          });
                                        }}
                                      ></div>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row className="col-12 px-0 mx-0 justify-content-center">
                                  <Col col="6" className="px-0 mx-0">
                                    <Label
                                      md="12"
                                      style={{ fontSize: "0.8rem" }}
                                      className="px-0 mx-0 justify-content-center"
                                    >
                                      Font size
                                    </Label>
                                  </Col>
                                  <Col
                                    col="6"
                                    style={{ fontSize: "0.8rem" }}
                                    className="d-flex flex-column px-0 mx-0 justify-content-center text-center"
                                  >
                                    <FormGroup className="my">
                                      <select
                                        className="cus-sel bg-white"
                                        name=""
                                        id=""
                                        value={state.footer_message_font_size}
                                        placeholder={
                                          state.footer_message_font_size
                                        }
                                        onChange={(e) =>
                                          change(
                                            e,
                                            "footer_message_font_size",
                                            "length",
                                            1
                                          )
                                        }
                                      >
                                        {state.font_size_list.map((p, k) => {
                                          return (
                                            <option value={p.value}>
                                              {p.label}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row className="col-12 px-0 mx-0 justify-content-center">
                                  <Col col="6" className="px-0 mx-0">
                                    <Label
                                      md="12"
                                      style={{ fontSize: "0.8rem" }}
                                      className="px-0 mx-0 justify-content-center"
                                    >
                                      Bold font
                                    </Label>
                                  </Col>
                                  <Col
                                    col="6"
                                    style={{ fontSize: "0.8rem" }}
                                    className="d-flex flex-column px-0 mx-0 justify-content-center text-center"
                                  >
                                    <FormGroup className="my">
                                      <Switch
                                        value={state.footer_message_bold_font}
                                        offColor="success"
                                        offText="OFF"
                                        onColor="success"
                                        onText="ON"
                                        onChange={(event) => {
                                          setState({
                                            footer_message_bold_font:
                                              event.state.value,
                                          });
                                        }}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row className="">
                                  <Col>
                                    <label htmlFor="">Align text</label>
                                  </Col>
                                </Row>
                                <Row className="text-center">
                                  <Col>
                                    <label htmlFor="left22">
                                      <input
                                        className="radio-align"
                                        style={{
                                          display: "none",
                                          verticalAlign: "middle",
                                          marginRight: "0.5rem",
                                          marginLeft: "0.25rem",
                                        }}
                                        checked={
                                          state.footer_message_align == "left"
                                            ? true
                                            : false
                                        }
                                        type="radio"
                                        name="align22"
                                        id="left22"
                                        onChange={(e) => {
                                          if (e.target.checked)
                                            setState({
                                              footer_message_align: "left",
                                            });
                                        }}
                                      />
                                      <img
                                        src={al_left}
                                        style={{ margin: "0.5rem" }}
                                        alt=""
                                      />
                                    </label>
                                    <label htmlFor="center22">
                                      <input
                                        className="radio-align"
                                        style={{
                                          display: "none",
                                          verticalAlign: "middle",
                                          marginRight: "0.5rem",
                                          marginLeft: "0.25rem",
                                        }}
                                        checked={
                                          state.footer_message_align == "center"
                                            ? true
                                            : false
                                        }
                                        type="radio"
                                        name="align22"
                                        id="center22"
                                        onChange={(e) => {
                                          if (e.target.checked)
                                            setState({
                                              footer_message_align: "center",
                                            });
                                        }}
                                      />
                                      <img
                                        src={just}
                                        style={{ margin: "0.5rem" }}
                                      />
                                    </label>
                                    <label htmlFor="right22">
                                      <input
                                        className="radio-align"
                                        style={{
                                          display: "none",
                                          verticalAlign: "middle",
                                          marginRight: "0.5rem",
                                          marginLeft: "0.25rem",
                                        }}
                                        checked={
                                          state.footer_message_align == "right"
                                            ? true
                                            : false
                                        }
                                        type="radio"
                                        name="align22"
                                        id="right22"
                                        onChange={(e) => {
                                          if (e.target.checked)
                                            setState({
                                              footer_message_align: "right",
                                            });
                                        }}
                                      />
                                      <img
                                        src={al_right}
                                        style={{ margin: "0.5rem" }}
                                        alt=""
                                      />
                                    </label>
                                  </Col>
                                </Row>
                              </CardBody>
                            </CardBody>
                          </div>
                        </Card>
                      </Col>

                      <Col
                        className="col-sm-4 col-md-3"
                        style={{ minWidth: "10rem" }}
                      >
                        <Card style={{ height: "16rem" }}>
                          <CardHeader>
                            <CardTitle style={{ fontSize: "0.85rem" }}>
                              Select counter
                            </CardTitle>
                          </CardHeader>
                          <CardBody style={{ overflowY: "auto" }}>
                            {getCounters()}
                          </CardBody>
                        </Card>
                      </Col>
                      <Col className="text-center">
                        <Card>
                          <CardHeader>
                            <CardTitle
                              style={{ fontSize: "0.85rem", display: "flex" }}
                            >
                              <Col className="px-0 mx-0 my-auto">
                                Advertisements
                              </Col>
                              <Col className="px-0 mx-0">
                                <select
                                  className="cus-sel bg-white"
                                  onChange={(e) => {
                                    change(e, "sliding", "length", 1);
                                  }}
                                >
                                  {/* <option value=''>Select slider</option> */}

                                  <option value="appear">Appear</option>
                                  <option value="slide-right">
                                    Slide right
                                  </option>
                                  <option value="slide-left">Slide left</option>
                                  <option value="slide-cross">
                                    Slide Cross
                                  </option>
                                  <option value="slide-cross-rev">
                                    Slide Cross rev
                                  </option>
                                </select>
                              </Col>
                              <Col>
                                {!state.adVid ? (
                                  <>
                                    Persist image for{" "}
                                    <div id="secIn">
                                      <input
                                        className="cus-sel"
                                        type="number"
                                        min="1"
                                        style={{ width: "5.5rem" }}
                                        defaultValue={
                                          state.imgDur != undefined
                                            ? state.imgDur
                                            : "(sec)"
                                        }
                                        onChange={(e) => {
                                          setState({
                                            imgDur: e.target.value,
                                          });
                                          // forceUpdate()
                                        }}
                                      />
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}
                              </Col>
                              <Col className="px-0 mx-0 my-auto">
                                <Switch
                                  // defaultValue={state.adVid}
                                  value={state.adVid}
                                  offColor="success"
                                  offText="Img"
                                  onColor="success"
                                  onText="Vid"
                                  onChange={(event) => {
                                    var disAd = [];
                                    if (
                                      state.ads != undefined &&
                                      state.ads?.length > 0
                                    )
                                      state.ads.map((e, k) => {
                                        if (event.state.value) {
                                          if (e.type == "vid") disAd.push(e);
                                        } else if (e.type == "img")
                                          disAd.push(e);
                                        if (k + 1 == state.ads.length) {
                                          setState({
                                            selected_ad: disAd,
                                            adVid: event.state.value,
                                          });
                                        }
                                      });
                                    else
                                      setState({
                                        adVid: event.state.value,
                                      });
                                  }}
                                />
                              </Col>
                              <Col
                                md="0.5"
                                className="d-flex flex-column justify-content-center"
                              >
                                <progress
                                  id="uploader"
                                  value="0"
                                  max="100"
                                  style={{ display: "none" }}
                                >
                                  0%
                                </progress>
                                <img
                                  src={plus}
                                  alt=""
                                  className="pointer-hover"
                                  style={{ width: "25px", margin: "3px" }}
                                  onClick={() => {
                                    document.getElementById("filee").click();
                                  }}
                                />
                                <input
                                  type="file"
                                  style={{ display: "none" }}
                                  id="filee"
                                  onChange={(e) => {
                                    var ok = false;
                                    var ADType = "";
                                    e.persist();
                                    if (e.target.files[0] != undefined) {
                                      if (
                                        e.target.files[0].name.split(".")[1] ===
                                          "png" ||
                                        e.target.files[0].name.split(".")[1] ===
                                          "jpg" ||
                                        e.target.files[0].name.split(".")[1] ===
                                          "jpeg" ||
                                        e.target.files[0].name.split(".")[1] ===
                                          "webp"
                                      ) {
                                        ADType = "IMAGE_ADS";
                                      } else if (
                                        e.target.files[0].name.split(".")[1] ===
                                        "mp4"
                                      ) {
                                        ADType = "VIDEO_ADS";
                                      } else
                                        notifyMessage(
                                          "tc",
                                          3,
                                          "Selected file is not supported, please upload only .mp4, .jpg, .jpeg, .png"
                                        );

                                      if (
                                        ADType == "IMAGE_ADS" ||
                                        ADType == "VIDEO_ADS"
                                      ) {
                                        setState({ loading: true });
                                        let customer_id = state.customer_id;
                                        let sub_location_id =
                                          state.selected_sub_location.value;

                                        Firebase.functions()
                                          .httpsCallable(
                                            "getPaymentRestrictionsBasedOnSelectedPackage"
                                          )({
                                            sub_location_id: sub_location_id,
                                            type: ADType,
                                            antqueue_customer_id: customer_id,
                                          })
                                          .then(function (result) {
                                            const res = result.data;
                                            console.log("validateItemCount", {
                                              result,
                                            });
                                            if (res.status === "ok") {
                                              var limitExceeded =
                                                res.data.countExceedsLimit;
                                              console.log(
                                                "validateItemCount>>> countExceedsLimit",
                                                limitExceeded
                                              );
                                              setState({
                                                loading: false,
                                              });
                                              // return limitExceeded;
                                              if (limitExceeded === true) {
                                                if (ADType == "IMAGE_ADS") {
                                                  notifyMessage(
                                                    "tc",
                                                    3,
                                                    "Sorry, the selected package for current sub location does not allow any more Image ADs to be added."
                                                  );
                                                } else if (
                                                  ADType == "VIDEO_ADS"
                                                ) {
                                                  notifyMessage(
                                                    "tc",
                                                    3,
                                                    "Sorry, the selected package for current sub location does not allow any more Video ADs to be added."
                                                  );
                                                }
                                              } else {
                                                var customer_id = JSON.parse(
                                                  localStorage.getItem(
                                                    "auth_info"
                                                  )
                                                ).customer_id;
                                                console.log(
                                                  state.display_id,
                                                  customer_id
                                                );
                                                let metadata = {
                                                  customMetadata: {
                                                    customer_id: customer_id,
                                                    main_location_id:
                                                      state
                                                        .selected_main_location
                                                        .value,
                                                    sub_location_id:
                                                      state
                                                        .selected_sub_location
                                                        .value,
                                                  },
                                                };
                                                console.log(metadata);
                                                if (
                                                  e.target.files[0].size <=
                                                    state.max_ad_img *
                                                      1024 *
                                                      1024 ||
                                                  e.target.files[0].size <=
                                                    state.max_ad_vid *
                                                      1024 *
                                                      1024
                                                ) {
                                                  var task = Firebase.storage()
                                                    .ref(
                                                      "Media_Gallery/Main_Display_Ads/" +
                                                        state.display_id +
                                                        "/" +
                                                        e.target.files[0].name
                                                    )
                                                    .put(
                                                      e.target.files[0],
                                                      metadata
                                                    );
                                                  {
                                                    /*.then(res=>console.log('uploadsuccess'),err=>console.log('uploadfailed...',err))*/
                                                  }
                                                  document.getElementById(
                                                    "uploader"
                                                  ).style.display = "block";

                                                  task.on(
                                                    "state_changed",
                                                    (snapshot) => {
                                                      var percentage =
                                                        (snapshot.bytesTransferred /
                                                          snapshot.totalBytes) *
                                                        100;
                                                      document.getElementById(
                                                        "uploader"
                                                      ).value = percentage;
                                                    },
                                                    () => {},
                                                    () => {
                                                      let j = 0,
                                                        ad = [],
                                                        o = 0;
                                                      document.getElementById(
                                                        "uploader"
                                                      ).style.display = "none";
                                                      adsLoad(state.display_id);
                                                    }
                                                  );
                                                  task.catch((error) => {
                                                    console.log(
                                                      "image upload error==> ",
                                                      error
                                                    );
                                                  });
                                                } else {
                                                  notifyMessage(
                                                    "tc",
                                                    3,
                                                    "AD size must be less than or equal to " +
                                                      state.max_ad_img +
                                                      " MB for image AD " +
                                                      "and " +
                                                      state.max_ad_vid +
                                                      " MB for video AD."
                                                  );
                                                }
                                              }
                                            }
                                          })
                                          .catch((error) => {
                                            setState({ loading: false });
                                            console.log("validateItemCount", {
                                              error,
                                            });
                                            return error;
                                          });

                                        {
                                          /* switch(e.target.files[0].name.split('.')[1]) {
                                                                                case 'png':
                                                                                    if(limitExceededONImageAD()==false){ console.log('aaaaa'); ok = true; } else { ok = false; } break;
                                                                                case 'jpg':
                                                                                    if(limitExceededONImageAD()==false){ console.log('aaaaa'); ok = true; } else { ok = false; } break;
                                                                                case 'jpeg':
                                                                                    if(limitExceededONImageAD()==false){ console.log('aaaaa'); ok = true; } else { ok = false; } break;
                                                                                case 'webp':
                                                                                    if(limitExceededONImageAD()==false){ console.log('aaaaa'); ok = true; } else { ok = false; } break;
                                                                                case 'mp4':
                                                                                    if(limitExceededONVideoAD()==false){ console.log('bbbbb'); ok = true; } else { ok = false; } break;
                                                                                default:
                                                                             ok=false; break;
                                                                            } */
                                        }
                                        {
                                          /* else if (limitExceededONImageAD==true) {
                                                                               notifyMessage("tc", 3, "Sorry, the selected package for current sub location does not allow any more Image ADs to be added.");
                                                                            } else if (limitExceededONVideoAD==true) {
                                                                               notifyMessage("tc", 3, "Sorry, the selected package for current sub location does not allow any more Video ADs to be added.");
                                                                            } */
                                        }
                                      }
                                    }
                                  }}
                                />
                              </Col>
                            </CardTitle>
                          </CardHeader>
                          <CardBody
                            style={{
                              maxHeight: "11rem",
                              minWidth: "22rem",
                              overflowY: "auto",
                            }}
                          >
                            <Row>
                              <Col col="5">Name</Col>
                              <Col col="3">Order</Col>
                              <Col col="2">Type</Col>
                              <Col col="2">Delete</Col>
                            </Row>
                            {getAds()}
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Form>
                  <Row
                    id="display"
                    className="height-100 justify-content-center layout"
                    style={{
                      background: state.tokens_back_color,
                      // fontSize: state.tokens_font_size + 'pt',

                      fontWeight: state.tokens_bold_font ? "bold" : "unset",
                      color: state.tokens_font_color,
                      resize: "both",
                      height: "50vh",
                      // overflowY:'auto',
                      // height:'35rem'
                    }}
                  >
                    <label
                      htmlFor="check"
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        zIndex: "1100",
                      }}
                    >
                      <input
                        name="check"
                        type="checkbox"
                        style={{ display: "none" }}
                        id="check"
                        onChange={(e) => {
                          var elem = document.getElementById("display");

                          if (
                            !document
                              .getElementById("image")
                              .classList.contains("hov")
                          ) {
                            document.getElementById("image").src = comp;
                            document
                              .getElementById("image")
                              .classList.add("hov");

                            if (elem.requestFullscreen) {
                              elem.requestFullscreen();
                            } else if (elem.mozRequestFullScreen) {
                              /* Firefox */
                              elem.mozRequestFullScreen();
                            } else if (elem.webkitRequestFullscreen) {
                              /* Chrome, Safari & Opera */
                              elem.webkitRequestFullscreen();
                            } else if (elem.msRequestFullscreen) {
                              /* IE/Edge */
                              elem.msRequestFullscreen();
                            }
                          } else {
                            document
                              .getElementById("image")
                              .classList.remove("hov");
                            document.getElementById("image").src = exp;

                            if (document.exitFullscreen) {
                              document.exitFullscreen();
                            } else if (document.mozCancelFullScreen) {
                              /* Firefox */
                              document.mozCancelFullScreen();
                            } else if (document.webkitExitFullscreen) {
                              /* Chrome, Safari and Opera */
                              document.webkitExitFullscreen();
                            } else if (document.msExitFullscreen) {
                              /* IE/Edge */
                              document.msExitFullscreen();
                            }
                            //   forceUpdate()
                          }
                          //    forceUpdate()
                        }}
                      />
                      <img
                        src={exp}
                        className=""
                        style={{ width: "70px", padding: "20px" }}
                        id="image"
                        alt=""
                      />
                    </label>
                    {/* {listType()} */}

                    <Layout
                      _this={{ state: state }}
                      setState={(e) => setState(e)}
                      notify={(e) => notifyMessage(...e)}
                    />
                  </Row>
                </CardBody>
              </Card>
            ) : null}
          </Col>
        </Row>
      </LoadingOverlay>
    </>
  );
  //   }
});
export default DisplayEdit;