import React, { PureComponent, useState, useEffect, useRef } from "react";
import Firebase from "firebase";
import Select from "react-select";
import Switch from "react-bootstrap-switch";
import NotificationAlert from "react-notification-alert";
import LoadingOverlay from "react-loading-overlay";
import expand from "../../../assets/img/expand.png";
import minimize from "../../../assets/img/minimize.png";
import "../../../assets/scss/css.css";
import {
  Col,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Label,
  Button,
  Input,
  Form,
  FormGroup,
  Table,
} from "reactstrap";
import config from "../../../config";
import exp from "../../expand-solid.svg";
import comp from "../../compress-solid.svg";
import "../../../assets/scss/offline.css";
import Layout from "./DisplayLayoutComponent";

class TimeComp extends PureComponent {
  state = { timeis: "" };
  componentDidMount() {
    setInterval(
      () =>
        this.setState({
          timeis: Date().toLocaleString().split("GMT")[0],
        }),
      1000
    );
  }
  render() {
    return <Col className="px-0 my-auto">{this.timeis}</Col>;
  }
}

// class DisplayRun extends React.Component {
function DisplayRun(props) {
  //   constructor (props) {
  // super(props)
  if (!Firebase.apps.length) {
    Firebase.initializeApp(config);
  }
  // var customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id

  // this.state = {
  // const [sliding, setSliding] = useState("appear");
  const [list1, setList1] = useState([]);
  // const [token_name, setToken_name] = useState("");
  const [run, setRun] = useState(true);


  const [state, setState1] = useState({
    list1: [],
    run: false,
    general_font_size: "12",
    counter_font_size: "12",
    token_display: true,
    counter_display: true,
    detail_display: false,
    logo_display: true,
    date_display: true,
    detail_back_color: "",
    detail_font_color: "",
    detail_font_size: "",
    detail_bold_font: "",
    colorPick: false,
    colorPlace: "",
    loading: false,
    display_id: "",
    customer_id: JSON.parse(localStorage.getItem("auth_info"))?.customer_id,
    customer_name: "",
    selected_main_location: { label: '', value: '' },
    main_location: "",
    selected_sub_location: { label: '', value: '' },
    sub_location: "",
    location_name: "",
    tokens: [],
    display_name: "",
    counter_list: [],
    selected_counter_list: [],
    selected_counter_list_right: [],
    layout_theme: "",
    width: "",
    height: "",
    // header_footer:false,
    // date_time:false,
    header_back_color: "#000",
    header_font_color: "#fff",
    header_font_size: "12",
    header_bold_font: false,
    date_back_color: "#000",
    date_font_color: "#fff",
    date_font_size: "12",
    date_bold_font: false,
    footer_message_back_color: "#000",
    footer_message_font_color: "#fff",
    footer_message_font_size: "12",
    footer_message_bold_font: false,
    tokens_back_color: "#000",
    tokens_font_color: "#fff",
    tokens_line_color: "#fff",
    tokens_font_size: "12",

    tokens_bold_font: false,
    color_list: [
      { label: "#000", value: "#000" },
      { label: "#fff", value: "#fff" },
    ],
    font_size_list: [
      { label: "12", value: "12" },
      { label: "10", value: "10" },
    ],
    counters: [],
    timeis: Date().toLocaleString().split("GMT")[0],
    footer_message: "",
    icon: expand,
    beeps: [],
    ads: [],
    selected_beep: "",
    selected_ad: [],
    saved_ads: [],
    adVid: false,
    imgDur: 2,
    col1: "0",
    col2: "0",
    col3: "0",
    col_num: [1],
    width1: "",
    width2: "",
    width3: "",
    height1: "",
    height2: "",
    height3: "",
    tokens_align: "center",
    header_align: "center",
    detail_align: "center",
    logo_align: "center",
    date_align: "center",
    footer_message_align: "center",
    res_sign: "px",
    logo: "",
    logo_size_type: '%',
    logo_width: '50',
    logo_column: 1,
    // logo_height:'',
    logo_padding_top: '100',
    logo_padding_bottom: '0',
    max_ad_img: 1,
    max_ad_vid: 1,
    max_icon: 1,
    sliding: "",
    change: false,
    detail_name: "",
    counter_name: "",
    token_name: "",
  });
  const [role, setRole] = useState(
    JSON.parse(localStorage.getItem("auth_info"))?.role
  );
  const [notificationAlert, setNotificationAlert] = useState(null);

  // }
  //   }

  //   componentDidMount () {

  const setState = (e) => {
    //   Object.keys(e).forEach(b => {
    setState1((c) => ({ ...c, ...e }));
    //   })
  };
  useEffect(() => {

    // setState = (e) => {
    //   Object.keys(e).forEach((b) => {
    //     var func = new Function(
    //       `set${b.charAt(0).toUpperCase() + b.slice(1)}(${e[b]})`
    //     )();
    //     console.log(`set${b.charAt(0).toUpperCase() + b.slice(1)}(${e[b]})`)
    //     func();
    //     // window[`set${b.charAt(0).toUpperCase() + b.slice(1)}`](e[b])
    //   });
    // };
    let id = props.match.params.id;
    var role1 = JSON.parse(localStorage.getItem("auth_info"))?.role;

    if (id !== "" && id !== null && id !== undefined) {
      //   setState({
      // setDisplay_id(id);
      // setRole(role1);

      // loadData(id);
      setState({ display_id: id, role: role1 });

      loadData(id);

      Firebase.firestore()
        .collection("Displays")
        .doc(id)
        .get()
        .then(function (doc) {
          Firebase.firestore()
            .collection("Sub_Locations")
            .doc(doc.data().Sub_Location_ID)
            .get()
            .then((e) => {
              setInterval(() => {
                var offset = e.data().TimeZone.split("UTC")[1].split(":")[0];
                var d = new Date();
                var utc = d.getTime() + d.getTimezoneOffset() * 60000;
                var nd = new Date(utc + 3600000 * offset).getTime();
                Firebase.firestore()
                  .collection("Displays_Status")
                  .doc(id)
                  .set({ Last_Active: nd });
              }, 1800000); //every half hour instead of every 6 seconds //6000)
              // return nd.getHours()+':'+nd.getMinutes();
            });
        });
      Firebase.storage()
        .ref("Media_Gallery/Main_Display_Logo")
        .child(id + "/")
        .listAll()
        .then((r) => {
          r.items.forEach((i) => {
            i.getDownloadURL().then(function (url) {
              //   setState({
              // setLogo({ url: url, name: i.name });
              setState({ logo: { url: url, name: i.name } });

            });
          });
        })
        .catch((e) => console.log(e.message));
    }
    // setInterval(()=>setState({timeis:Date().toLocaleString().split('GMT')[0]}),1000)

    window.addEventListener("offline", function (e) {
      if (document.getElementById("offline-ui") != undefined)
        document.getElementById("offline-ui").style.display = "block";
    });

    // Add event listener online to detect network recovery.
    window.addEventListener("online", function (e) {
      document.getElementById("offline-ui").style.display = "none";
    });
    //   }
  }, []);
  // useEffect(()=>loadData(display_id),[selected_counter_list])
  function loadData(id) {
    // setLoading(true);
    setState({ loading: true });

    Firebase.firestore()
      .collection("Displays")
      .doc(id)
      .onSnapshot(function (doc) {
        if (doc.exists) {
          //   setState({
          setState({
            display_name: doc.data().Display_Name,
            sub_location: doc.data().Sub_Location_ID,
            main_location: doc.data().Main_Location_ID,
            layout_theme: doc.data().Layout_Theme,

            width: doc.data().Width,
            height: doc.data().Height,

            ['logo.url']: doc.data()?.Logo_URL || '',
            logo_size_type: doc.data()?.Logo_Size_Percentage_Or_Px || 'px',
            logo_width: doc.data()?.Logo_Size_Width || '50',
            // logo_height:doc.data()?.Logo_Size_Height||'50',
            logo_column: doc.data()?.Logo_Column_Placement || 1,
            logo_padding_top: doc.data()?.Logo_Padding_Top || '0',
            logo_padding_bottom: doc.data()?.Logo_Padding_Bottom || '0',
            // header_footer: doc.data().Header_Footer_custom,
            // date_time: doc.data().Datetime_custom,
            footer_message: doc.data().Footer_Message || '',
            header_back_color: doc.data().Header_Back_Color || '',
            detail_back_color: doc.data().Detail_Back_Color || '',
            detail_display: doc.data().Detail_Display != undefined ? doc.data().Detail_Display : true,
            counter_display: doc.data().Counter_Display != undefined ? doc.data().Counter_Display : true,
            token_display: doc.data().Token_Display != undefined ? doc.data().Token_Display : true,
            logo_display: doc.data().Logo_Display != undefined ? doc.data().Logo_Display : true,
            date_display: doc.data().Date_Display != undefined ? doc.data().Date_Display : true,

            date_back_color: doc.data().Date_Back_Color || '#000',
            tokens_back_color: doc.data().Tokens_Back_Color || '#000',
            footer_message_back_color: doc.data().Footer_Message_Back_Color || '#000',

            header_font_color: doc.data().Header_Font_Color || '#fff',
            date_font_color: doc.data().Date_Font_Color || '#fff',
            tokens_font_color: doc.data().Tokens_Font_Color || '#fff',
            tokens_line_color: doc.data().Tokens_Line_Color || '#fff',
            detail_font_color: doc.data().Detail_Font_Color || '#fff',
            detail_font_size: doc.data().Detail_Font_Size || '',
            counter_font_size: doc.data().Counter_Font_Size || '',
            detail_bold_font: doc.data().Detail_Bold_Font || '',
            detail_name: doc.data().Detail_Name || '',
            counter_name: doc.data().Counter_Name || '',
            token_name: doc.data().Token_Name || '',
            footer_message_font_color: doc.data().Footer_Message_Font_Color || '',

            header_font_size: doc.data().Header_Font_Size || '',
            date_font_size: doc.data().Date_Font_Size || '',
            tokens_font_size: doc.data().Tokens_Font_Size || '',
            general_font_size: doc.data().General_Font_Size || '',
            footer_message_font_size: doc.data().Footer_Message_Font_Size || '',

            header_bold_font: doc.data().Header_Font_Bold || '',
            date_bold_font: doc.data().Date_Font_Bold || '',
            tokens_bold_font: doc.data().Tokens_Font_Bold || '',
            footer_message_bold_font: doc.data().Footer_Message_Font_Bold || '',

            selected_counter_list: doc.data().Counter_List || [],
            selected_counter_list_right: doc.data().Counter_List_Right || [],
            logged_userId: doc.data().Logged_In_User_ID,
            logged_userName: doc.data().Logged_In_User_Name,
            // loading: false,
            selected_beep: doc.data().Beep_Sound,
            // selected_ad:doc.data().ADs_Files,
            saved_ads: doc.data().ADs_Files,
            adVid: doc.data().ADs_Video_Enable,
            imgDur: doc.data().ADs_Slider_Duration,
            col1: doc.data().Col1,
            col2: doc.data().Col2,
            col3: doc.data().Col3,
            width1: doc.data().Width1,
            width2: doc.data().Width2,
            width3: doc.data().Width3,
            height1: doc.data().Height1,
            height2: doc.data().Height2,
            height3: doc.data().Height3,
            tokens_align: doc.data().Tokens_Align || '',
            header_align: doc.data().Header_Align || '',
            detail_align: doc.data().Detail_Align || '',
            logo_align: doc.data().Logo_Align || 'center',
            date_align: doc.data().Date_Align || 'center',
            footer_message_align: doc.data().Footer_Message_Align || state.footer_message_align,
            res_sign: doc.data().Resolution_Sign,
            sliding: doc.data().Sliding_Animation,
          });

          adsLoad(id);
          //   listType()
          getCounters(doc.data().Counter_List);
        }
      });
    setState({ loading: false });
    // setLoading(false);
    document.addEventListener("fullscreenchange", function (event) {
      //   console.log(event.key)
      if (!document.fullscreenElement)
        if (document.getElementById("image").classList.contains("hov")) {
          document.getElementById("image").classList.remove("hov");
          document.getElementById("image").src = exp;
        }
    });
    // document.onkeydown=function(e){console.log(e.key)}
  }
  function adsLoad(id) {
    let j = 0,
      ad = [],
      o = 0;
    Firebase.storage()
      .ref("Media_Gallery/Main_Display_Ads/")
      .child(id + "/")
      .listAll()
      .then((r) => {
        r.items.forEach((i) => {
          o++;
          i.getDownloadURL().then(function (url) {
            var type;
            switch (i.name.split(".")[1]) {
              case "png":
                type = "img";
                break;
              case "jpg":
                type = "img";
                break;
              case "jpeg":
                type = "img";
                break;
              case "webp":
                type = "img";
                break;
              case "mp4":
                type = "vid";
                break;
              case "mkv":
                type = "vid";
                break;
              case "flv":
                type = "vid";
                break;
              case "gif":
                type = "vid";
                break;
              case "avi":
                type = "vid";
                break;
              case "m4v":
                type = "vid";
                break;
              case "3gp":
                type = "vid";
                break;
              case "webm":
                type = "vid";
                break;
              default:
                type = "n";
                break;
            }
            var ord = 0;
            state.saved_ads.map((p, k) => {
              if (p.name == i.name) ord = p.order;
            });
            ad.push({ name: i.name, url: url, type: type, order: ord });
            if (o == r.items.length) {
              var disAd = [];
              ad.map((e, k) => {
                if (state.adVid) {
                  if (e.type == "vid") disAd.push(e);
                } else if (e.type == "img") disAd.push(e);

                if (k + 1 == ad.length) {
                  //   setState({
                  // setSelected_ad(disAd);
                  // setAds(ad);
                  setState({ selected_ad: disAd, ads: ad, col_num: [1] });

                  //   forceUpdate()
                }
              });

              // setTimeout(()=>setState({ads:ad}),200)
            }
          });
        });
      })
      .catch((e) => console.log(e.message));
  }

  // /**
  //  * Sorts an array of objects by column/property.
  //  * @param {Array} array - The array of objects.
  //  * @param {object} sortObject - The object that contains the sort order keys with directions (asc/desc). e.g. { age: 'desc', name: 'asc' }
  //  * @returns {Array} The sorted array.
  //  */
  function multiSort(array, sortObject = {}) {
    const sortKeys = Object.keys(sortObject);
    // Return array if no sort object is supplied.
    if (!sortKeys.length) {
      return array;
    }
    // Change the values of the sortObject keys to -1, 0, or 1.
    for (let key in sortObject) {
      sortObject[key] =
        sortObject[key] === "desc" || sortObject[key] === -1
          ? -1
          : sortObject[key] === "skip" || sortObject[key] === 0
            ? 0
            : 1;
    }
    const keySort = (a, b, direction) => {
      direction = direction !== null ? direction : 1;
      if (a === b) {
        // If the values are the same, do not switch positions.
        return 0;
      }
      // If b > a, multiply by -1 to get the reverse direction.
      return a > b ? direction : -1 * direction;
    };
    return array.sort((a, b) => {
      let sorted = 0;
      let index = 0;
      // Loop until sorted (-1 or 1) or until the sort keys have been processed.
      while (sorted === 0 && index < sortKeys.length) {
        const key = sortKeys[index];
        if (key) {
          const direction = sortObject[key];

          sorted = keySort(a[key], b[key], direction);
          index++;
        }
      }
      return sorted;
    });
  }

  function blink(id) {
    let counterRow = document.getElementById(id)
    if (!counterRow) return;
    counterRow.classList.add("blink");
    setTimeout(() => {
      if (counterRow != undefined)
        counterRow.classList.remove("blink");
    }, 5000);
  }

  function beep() {
    let beepAudio = document.getElementById("beep")
    if (!beepAudio) return;
    beepAudio.currentTime = 0;
    beepAudio.play();
  }

  function getCounters(list) {
    var arr = [],
      end = false,
      tem = [];
    list.map((e, k) => {
      if (e.length > 1) {
        Firebase.firestore()
          .collection("Counters")
          .doc(e)
          .onSnapshot((d) => {
            console.log(d.data().Counter_Name, "Counter updated ----------");
            if (d.exists) {
              var inc = false,
                tok = d.data().Current_Token;
              arr.map((b, k) => {
                // if(Object.values(b).includes(d.data().Counter_Name)){
                if (Object.values(b).includes(d.id)) {
                  var num = d.data().Counter_State;
                  var counter_state = d.data().Counter_State;
                  arr[k].name = d.data().Counter_Name;
                  arr[k].detail =
                    d.data().Filtered_Service_Details_For_Display != undefined
                      ? d.data().Filtered_Service_Details_For_Display
                      : "";
                  //console.log(e,k,d.data().id);
                  console.log(
                    "showTokNumNotCounterState",
                    showTokNumNotCounterState(counter_state)
                  );

                  //if the counter state is not a serving type-------------------------
                  if (
                    showTokNumNotCounterState(counter_state) === false &&
                    d.data().Current_Token != undefined &&
                    d.data().Current_Token.number != undefined
                  ) {
                    console.log(
                      "counter changed and it's token isn't underfined",
                      d.data().Current_Token.number
                    );
                    if (arr[k].num == d.data().Current_Token.number) {
                      beep()
                      blink(d.id)
                    }
                    arr[k].num = tok.number;
                    arr[k].counter_state = counter_state;
                  }

                  if (
                    showTokNumNotCounterState(counter_state) === false &&
                    (d.data().Current_Token == undefined ||
                      d.data().Current_Token.number == undefined)
                  ) {
                    console.log("counter token undefined!!----------");
                    beep()
                    blink(d.id)
                    arr[k].num = num;
                    arr[k].counter_state = counter_state;

                    console.log("2 arr[k]", arr[k]);
                  }

                  //-------------------------------------------------------

                  if (
                    showTokNumNotCounterState(counter_state) &&
                    d.data().Current_Token != undefined &&
                    d.data().Current_Token.number != undefined
                  ) {
                    // console.log("arr[k].num1", arr[k].num);
                    // console.log("tok.number1", tok.number);
                    // console.log(document.getElementById("beep"));
                    // console.log(d.data().Blink);
                    if (
                      arr[k].num != tok.number &&
                      tok.number.length > 0 &&
                      d.data().Blink
                    ) {
                      beep()
                      blink(d.id)
                      if (tok != undefined)
                        if (tok.number != undefined)
                          if (tok.number.length > 0) {
                            var dd = parseInt(tok.date_stamp),
                              day = new Date(dd).getUTCDate(),
                              curr = new Date().getUTCDate();
                            console.log(day, curr, "********************");
                            if (day == curr) {
                              num = tok.number;
                              //counter_state = counter_state
                              // console.log("num", num);
                              // console.log("counter_state1", counter_state);
                            }
                          }
                      arr[k].num = num;
                      arr[k].counter_state = counter_state;

                      // console.log("3 arr[k]", arr[k]);
                    }
                  } else {
                    console.log("reaching_this");
                  }
                  if (counter_state == "Occupied") arr[k].num = tok.number;
                  else arr[k].num = counter_state;
                  if (d.data().Repeat) {
                    arr[k].num = d.data().Current_Token.number;
                    beep()

                    blink(d.id)
                    // console.log("4 arr[k]", arr[k]);
                  }

                  inc = true;
                }
              });
              if (!inc) {
                var num = d.data().Counter_State;
                var counter_state = d.data().Counter_State;
                if (tok != undefined)
                  if (tok.number != undefined)
                    if (tok.number.length > 0) {
                      var dd = tok.date_stamp,
                        day = new Date(dd).getDate(),
                        curr = new Date().getDate();
                      if (d.data().Counter_State == "Occupied") {
                        num = tok.number;
                        //counter_state = counter_state
                      } else num = counter_state;
                    }

                arr.push({
                  id: d.id,
                  name: d.data().Counter_Name,
                  num: num,
                  detail:
                    d.data().Filtered_Service_Details_For_Display != undefined
                      ? d.data().Filtered_Service_Details_For_Display
                      : "",
                  counter_state: counter_state,
                });
              }
              arr = multiSort(arr, { name: "asc" });
              // arr.sort((a, b) => a.name < b.name ? -1 : +1);
              // arr.sort(function (a, b) {
              //     return parseInt(a.name.match(/\d+/)[0],'10') - parseInt(b.name.match(/\d+/)[0],'10');
              // });
              if (end) {
                console.log("end", end, arr);
                if (state.selected_counter_list_right?.length > 0) {
                  tem = [];
                  arr.forEach((e) => {
                    if (!state.selected_counter_list_right?.includes(e)) {
                      tem.push(e.id);
                    }
                  });
                } else {
                  tem = arr.map((e) => e.id);
                  // console.log(arr)
                }
                // setState({
                // setCounters(arr);
                // setSelected_counter_list(arr.map((e) => e.id));
                // setList1(tem);
                setState({
                  counters: arr,
                  selected_counter_list: arr.map((e) => e.id),
                  list1: tem,
                });
                // })
                // forceUpdate()
              }
            }
          });
        if (k + 1 == list.length) {
          console.log("k", k, arr);

          if (state.selected_counter_list_right?.length > 0) {
            tem = [];
            arr.forEach((e) => {
              if (!state.selected_counter_list_right?.includes(e)) {
                tem.push(e.id);
              }
            });
          } else {
            tem = arr.map((e) => e.id);
            //   console.log(arr)
          }
          // setCounters(arr);
          // setSelected_counter_list(arr.map((e) => e.id));
          // setList1(tem);
          setState({
            counters: arr,
            selected_counter_list: arr.map((e) => e.id),
            list1: tem,
          });
          end = true;
        }
      }
    });
  }

  const showTokNumNotCounterState = (counter_state) => {
    console.log("counter_state", counter_state);
    // if ( counter_state === "Occupied" || counter_state === "Serving" || counter_state === "Open" ) {
    //   return true;
    // }
    // else if ( counter_state === "On Break" || counter_state === "Temporarily Closed" || counter_state === "Closed" ) {
    //   return false;
    // } else {
    //   return true;
    // }

    return true;
  };

  function formatCounterStatus(str) {
    var splitStr = str.toLowerCase().split("_");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }

  var ok = true;
  if (
    state.role == "Dispenser_User" ||
    state.role == "Counter_Display_User" ||
    state.role == "Counter_User"
  )
    ok = false;
  //   let state = state
  return (
    <>
      <LoadingOverlay
        active={state.loading}
        spinner
        text="Loading"
        className="content"
      >
        {" "}
        <div id="offline-ui" className="offline-ui">
          Connection is lost!
        </div>
        <NotificationAlert ref={(ref) => setNotificationAlert(ref)} />
        {ok ? (
          <Row
            id="display"
            className=" justify-content-center mt-5"
            style={{
              background: state.tokens_back_color,
              // fontSize: state.tokens_font_size + 'pt',

              fontWeight: state.tokens_bold_font ? "bold" : "unset",
              color: state.tokens_font_color,
              resize: "both",
              height: "50vh",
            }}
          >
            <label
              htmlFor="check"
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                zIndex: "1000",
              }}
            >
              <input
                name="check"
                type="checkbox"
                style={{ display: "none" }}
                id="check"
                onChange={(e) => {
                  var elem = document.getElementById("display");

                  if (
                    !document.getElementById("image").classList.contains("hov")
                  ) {
                    document.getElementById("image").src = comp;
                    document.getElementById("image").classList.add("hov");

                    if (elem.requestFullscreen) {
                      elem.requestFullscreen();
                    } else if (elem.mozRequestFullScreen) {
                      /* Firefox */
                      elem.mozRequestFullScreen();
                    } else if (elem.webkitRequestFullscreen) {
                      /* Chrome, Safari & Opera */
                      elem.webkitRequestFullscreen();
                    } else if (elem.msRequestFullscreen) {
                      /* IE/Edge */
                      elem.msRequestFullscreen();
                    }
                  } else {
                    document.getElementById("image").classList.remove("hov");
                    document.getElementById("image").src = exp;

                    if (document.exitFullscreen) {
                      document.exitFullscreen();
                    } else if (document.mozCancelFullScreen) {
                      /* Firefox */
                      document.mozCancelFullScreen();
                    } else if (document.webkitExitFullscreen) {
                      /* Chrome, Safari and Opera */
                      document.webkitExitFullscreen();
                    } else if (document.msExitFullscreen) {
                      /* IE/Edge */
                      document.msExitFullscreen();
                    }
                    // forceUpdate()
                  }
                }}
              />
              <img
                src={exp}
                className=""
                style={{ width: "70px", padding: "20px" }}
                id="image"
                alt=""
              />
            </label>
            <audio
              src={state.selected_beep}
              id="beep"
              style={{ display: "none" }}
            ></audio>
            {/* {listType()} */}
            <Layout

              _this={{ state: state }}
              setState={(e) => setState(e)}
            // setSelected_ad={(e)=>setSelected_ad(e)}
            // setCol_num={(e)=>setCol_num(e)}
            />
          </Row>
        ) : null}
      </LoadingOverlay>
    </>
  );
}
export default DisplayRun;
