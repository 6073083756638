import React, { Component } from 'react';
import PerfectScrollbar from "perfect-scrollbar";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { ScrollDirection } from '@progress/kendo-react-dateinputs/dist/npm/virtualization/Virtualization';

am4core.useTheme(am4themes_animated);
var ps;

class ServLoc3D extends Component {
  constructor(props) {

    super(props);
    this.state = {
      height : 500,
    }
  };

  componentDidMount() {
    this.state.height = this.props.Height;
    this.setState({height : this.props.Height });
    const chart = am4core.create("Serv_3D_Chart", am4charts.PieChart3D);

    this.createChart(chart);

    this.chart = chart;
    
    // if (navigator.platform.indexOf("Win") > -1) {
    //   ps = new PerfectScrollbar(this.main_panel.current);
    //   document.body.classList.toggle("perfect-scrollbar-on");
    // }
  }

  handleZoom = (e) => {
    console.log(e)
  }

  componentWillUnmount() {
    // if (navigator.platform.indexOf("Win") > -1) {
    //   ps.destroy();
    //   document.body.classList.toggle("perfect-scrollbar-on");
    // }
    if (this.chart) {
      this.chart.dispose();
    }
  }

  createChart = (chart) => {
      chart.data = this.props.data;
      chart.legend = new am4charts.Legend();

      // Add and configure Series

      var pieSeries = chart.series.push(new am4charts.PieSeries3D());
      pieSeries.dataFields.value = "value";
      // if( chart.data.length < 9 )
         pieSeries.dataFields.category = "label";
      
      // pieSeries.slices.template.alignLabels = true;
      // pieSeries.slices.template.propertyFields.isActive = "pulled";
      // pieSeries.slices.template.configField = "config";
      // pieSeries.slices.template.tooltipText = "{label}";


      pieSeries.slices.template.propertyFields.fill = "color"; 
      pieSeries.slices.template.stroke = am4core.color("#fff");
      pieSeries.slices.template.strokeWidth = 2;
      pieSeries.slices.template.strokeOpacity = 1;

      // Disabling labels and ticks on inner circle
      pieSeries.labels.template.disabled = true;
      pieSeries.ticks.template.disabled = true;

      // Disable sliding out of slices
      // pieSeries.slices.template.states.getKey("hover").properties.shiftRadius = 0;
      // pieSeries.slices.template.states.getKey("hover").properties.scale = 1;
      chart.exporting.menu = new am4core.ExportMenu();
  }

  render() {
    return (      
      <div  id="Serv_3D_Chart" style={{ width: "100%", height: this.state.height-10, marginBottom: 10 }}>
      </div>

    );
  }
}

export default ServLoc3D;
