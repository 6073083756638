import React from 'react';
import LoadingOverlay from "react-loading-overlay";
import NotificationAlert from "react-notification-alert";
import Firebase from "firebase";
import Select from 'react-select';
import 'react-tagsinput/react-tagsinput.css' // If using WebPack and style-loader.
// import _ from 'lodash'
import * as am4core from "@amcharts/amcharts4/core";
// import { Dropdown, DropdownItem, DropdownMenu } from 'semantic-ui-react'
import 'bootstrap/dist/css/bootstrap.css';
import {
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row
} from "reactstrap";
import { DateRangePicker } from '@progress/kendo-react-dateinputs';
// import { CustomStartDateInput } from './customStartDateInput.jsx';
// import { CustomEndDateInput } from './customEndDateInput.jsx';
import { CSVLink } from "react-csv";
import moment from "moment-timezone";
import config from "../../../config";

// import { getPriority } from "os";

// const servicesInfo = [];
// let nMainCnt = 0, nSerCnt = 0, nTSubLoc = 0;
// const uuidv1 = require('uuid/v1');

const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
}

class Report5DetailDownload extends React.Component {

    constructor(props) {

        super(props);
        if (!Firebase.apps.length) {
            Firebase.initializeApp(config);
            // const perf = Firebase.performance();
        }

        this.state = {
            matches: window.matchMedia("(min-width: 370px)").matches,
            dateRangeLimitSeconds: 5184000, // number of seconds in 1 day = 86400s
            // number of seconds in 30 days = (30 X 86400) = 2592000s
            // number of seconds in 60 days = (60 x 86400) = 5184000s
            dateRangeLimitDays: 60,
            loading: false,

            defaultDate: {},
            bVisualize: false,
            start_date_formatted: '',
            end_date_formatted: '',
            defaultTimeZone: '',
            defaultTimeZoneSet: false,

            // bLoaded: false,
            // bdelete: false,
            // startDate: '',
            // endData: '',
            // main_count: 1,
            download_data: [],

            main_location_list: [],
            addressDefinitions: [],
            sub_location_list: [],
            subTemp: [],
            sub_location_ID: [],

            services: [],
            servTemp: [],
            stateOptions: [],

            detailTokenData: [],

            MLCnt: 0,
            SLCnt: 0,
            SVLCnt: 0,
            bdate: false,
            bmain: false,
            bsub: false,
            bservice: false,
            bUpdate: false,

        };

        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangesub = this.handleChangesub.bind(this);
        this.handleChangeService = this.handleChangeService.bind(this);

    }

    componentDidMount() {
        if (!this.state.bVisualize) {
            this.setState({ loading: true });
            // this.loadDefaultDate();
            this.loadAccessLoc();
        }
        const handler = e => this.setState({ matches: e.matches });
        window.matchMedia("(min-width: 370px)").addListener(handler);
    }

    loadAccessLoc() {
        let _this = this;
        let access = [];
        let temp = [];
        // let i = 0;
        let email = JSON.parse(localStorage.getItem('auth_info')).email;
        Firebase.firestore().collection('Web_App_Users').doc(email).get().then(function (response) {
            if (response.exists) {
                access.push(response.data().Accessible_Locations);
            }
            // alert(access[8] + "//" + access[9] + "//" + access[10])
            for (let i = 0; i < access.length; i++) {
                let id = access[i];
                // temp.push({label:id});

                let len = id.length;
                for (let j = 0; j < len; j++) {
                    let subid = id[j];
                    // let b = _this.boolExist(temp, subid);
                    // if( b === 0)
                    temp.push({ label: subid });
                }
            }
            if (access.length > 0) {
                _this.loadSubLocationByMain(temp);
            }
        });
    }

    componentWillUnmount() {
        // this.setState({loading: false});
    }

    // componentDidUpdate(e) {
    // }

    handleChangeDate(event) {
        let _this = this;
        let dateObject = event.target.value;
        _this.setState({ defaultDate: dateObject });

        let defaultTimeZone = _this.state.defaultTimeZone;
        let defaultTimeZoneSet = _this.state.defaultTimeZoneSet;
        let startDateFmt = "", endDateFmt = "";

        if (defaultTimeZoneSet && _this.isValidJSDate(dateObject.start)) {
            startDateFmt = parseInt(moment(dateObject.start).tz(defaultTimeZone).unix());
            // startDateFmt = (moment(new Date(startDateFmt * 1000)).tz(defaultTimeZone).format("YYYY-MM-DD")).toString();
            startDateFmt = startDateFmt.toString();
            _this.setState({ start_date_formatted: startDateFmt });
        }

        if (defaultTimeZoneSet && _this.isValidJSDate(dateObject.end)) {
            endDateFmt = parseInt((moment(dateObject.end).tz(defaultTimeZone).endOf('day')).unix()) + 1;
            // endDateFmt = (moment(new Date(endDateFmt * 1000)).tz(defaultTimeZone).format("YYYY-MM-DD")).toString();
            endDateFmt = endDateFmt.toString();
            _this.setState({ end_date_formatted: endDateFmt });
        }

        console.log("handleChangeDate>>", "dateObject", dateObject, "defaultTimeZone", defaultTimeZone, "startDateFmt", startDateFmt, "endDateFmt", endDateFmt);
    }

    isValidJSDate(jsDateObj) {
        var timestamp = Date.parse(jsDateObj);
        if (!(isNaN(timestamp))) { return true; }
        return false;
    }
    loadMainLocations(temp) {
        let _this = this;
        // let access = temp;
        let main_locations = [];
        let mainTemp = [];
        let i = 0;
        let customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;

        Firebase.firestore().collection('Main_Locations').where('Customer_ID', '==', customer_id).get().then(function (response) {
            response.docs.forEach(function (doc) {
                main_locations.push({ value: doc.id, label: doc.data().Name });
            });

            for (i = 0; i < main_locations.length; i++) {
                let nn = main_locations[i].value;
                let b = _this.boolExist(temp, nn);
                if (b === 1)
                    mainTemp.push({ value: main_locations[i].value, label: main_locations[i].label })
            }

            if (mainTemp.length > 0) {
                _this.setState({ main_location_list: mainTemp, addressDefinitions: mainTemp });
                // _this.loadSubLocationByMain(temp, mainTemp);
            }
        }, (error) => {
            console.log("loadMainLocations Error===>", error);
        });
    }

    loadSubLocationByMain(temp) {
        let _this = this;
        let defaultTimeZone = _this.state.defaultTimeZone;
        let TT = [], main = [], main2 = [];
        let sub_locations = [];
        let i = 0;
        let customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;
        Firebase.firestore().collection('Sub_Locations').where('Customer_ID', '==', customer_id).get().then(function (response) {
            response.docs.forEach(function (doc) {
                sub_locations.push({ value: doc.id, label: doc.data().Name, mID: doc.data().Main_Location_ID, timezone: doc.data().TimeZone });
                if (defaultTimeZone == "" || defaultTimeZone == undefined || defaultTimeZone == null) {
                    let timeZoneParts = doc.data().TimeZone.split(")");
                    defaultTimeZone = timeZoneParts[1];
                    _this.setState({ defaultTimeZone: defaultTimeZone });
                    _this.setState({ defaultTimeZoneSet: true });
                    console.log("defaultTimeZone", defaultTimeZone, "defaultTimeZoneSet", true);
                    _this.loadDefaultDate();
                }
                main.push({ label: doc.data().Main_Location_ID });
            });
            for (i = 0; i < sub_locations.length; i++) {
                // let mm = sub_locations[i].mID;
                // let bb = _this.boolExistID(mainTemp, mm);
                // if( bb === 0 ) continue;

                let nn = sub_locations[i].value;
                let b = _this.boolExist(temp, nn);
                if (b === 1) {
                    TT.push({ value: sub_locations[i].value, label: sub_locations[i].label, mID: sub_locations[i].mID, timezone: sub_locations[i].timezone })
                    let mainId = sub_locations[i].mID;
                    let bb = _this.boolExist(main, mainId);
                    if (bb === 1)
                        main2.push(main[i])
                }
            }
            if (TT.length > 0) {
                _this.setState({ sub_location_list: TT, sub_location_ID: TT });
                _this.setState({ subTemp: TT });
                // // console.log("loadSubLocationByMain >>TT", TT);
                _this.loadMainLocations(main2);
                _this.loadServicesByMainAndSub(main2, TT);
            }
        }, (error) => {
            console.log("loadSubLocationByMain Error==>", error);
        });
    }

    loadServicesByMainAndSub(main, sub) {
        let _this = this;
        let servicesT = [];
        let Ser = [];
        let subValue = [];
        let customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;
        sub.map((subItem, index) => {
            subValue.push(subItem.value);
        });
        Firebase.firestore().collection('Services').where('Customer_ID', '==', customer_id).get().then(function (response) {
            response.docs.forEach(function (doc) {
                if (subValue.includes(doc.data().Sub_Location_ID)) {
                    // console.log("services ==> ", doc)
                    servicesT.push({ value: doc.id, label: doc.data().Name, mID: doc.data().Main_Location_ID, sID: doc.data().Sub_Location_ID });
                    // servicesInfo.push({value:doc.data().Sub_Location_ID, label: doc.data().Name,  date: Sec});
                }
            });

            for (let i = 0; i < servicesT.length; i++) {
                let mm = servicesT[i].mID;
                let bb = _this.boolExist(main, mm);
                if (bb === 0) continue;

                let nn = servicesT[i].sID;

                let b = _this.boolExistID(sub, nn);
                if (b === 1)
                    Ser.push(servicesT[i]);
            }
            if (Ser.length > 0) {
                // console.log("service===>", Ser);
                _this.setState({ services: Ser, servTemp: Ser, stateOptions: Ser });
                _this.setState({ loading: false });
                // _this.loadTokenData();
            } else {
                console.log("No Services..");
                _this.setState({ loading: false });
            }

        }, (error) => {
            console.log("loadServicesByMainAndSub Error ===> ", error);
            _this.setState({ loading: false });
        });
        // })
    }

    loadDefaultDate() {
        let _this = this;
        let today = new Date();
        let y = today.getFullYear();
        let m = today.getMonth();
        let d = today.getDate();

        var dateObject = {
            start: new Date(y, m, 1),
            end: new Date(y, m, d)
        };

        _this.setState({ defaultDate: dateObject });

        let defaultTimeZone = _this.state.defaultTimeZone;
        let defaultTimeZoneSet = _this.state.defaultTimeZoneSet;
        let startDateFmt = "", endDateFmt = "";

        if (defaultTimeZoneSet && _this.isValidJSDate(dateObject.start)) {
            startDateFmt = parseInt(moment(dateObject.start).tz(defaultTimeZone).unix());
            // startDateFmt = (moment(new Date(startDateFmt * 1000)).tz(defaultTimeZone).format("YYYY-MM-DD")).toString();
            startDateFmt = startDateFmt.toString();
            _this.setState({ start_date_formatted: startDateFmt });
        }

        if (defaultTimeZoneSet && _this.isValidJSDate(dateObject.end)) {
            endDateFmt = parseInt((moment(dateObject.end).tz(defaultTimeZone).endOf('day')).unix()) + 1;
            // endDateFmt = (moment(new Date(endDateFmt * 1000)).tz(defaultTimeZone).format("YYYY-MM-DD")).toString();
            endDateFmt = endDateFmt.toString();
            _this.setState({ end_date_formatted: endDateFmt });
        }

        console.log("loadDefaultDate>>", "dateObject", dateObject, "defaultTimeZone", defaultTimeZone, "startDateFmt", startDateFmt, "endDateFmt", endDateFmt);
    }

    getDeleteData(src, filter, color, name) {
        let len = src.length;
        let ret = [];
        for (let i = 0; i < len; i++) {
            for (let j = 0; j < filter.length; j++)
                if (src[i].label === filter[j].label) {
                    color.push(src[i].color);
                    name.push(src[i].label)
                    ret.push(src[i])
                }
        }
        return ret;

    }

    handleChange(selectedOption) {
        this.state.main_location_list = selectedOption;
        this.state.sub_location_list = [];
        this.setState({ main_location_list: selectedOption });
        this.setState({ sub_location_list: [] });
        this.LoadSubLocation();
        // if (this.state.bVisualize && selectedOption.length !== 0)
        // this.subfiltering(selectedOption, 1);
    }

    LoadSubLocation() {
        let _this = this;
        let SelOpt = _this.state.main_location_list;
        let nL = SelOpt.length;
        let sub = _this.state.subTemp;
        let subtt = [];
        _this.setState({ sub_location_list: [] });

        for (let j = 0; j < nL; j++) {
            for (let i = 0; i < sub.length; i++) {
                if (SelOpt[j].value === sub[i].mID)
                    subtt.push({ value: sub[i].value, label: sub[i].label, mID: sub[i].mID, timezone: sub[i].timezone });
            }
        }
        _this.state.sub_location_list = subtt;
        _this.setState({ sub_location_list: subtt, sub_location_ID: subtt });

        _this.LoadServNamesBySL();

    }

    handleChangesub(selectItem) {
        this.state.sub_location_list = selectItem;
        this.setState({ sub_location_list: selectItem });
        this.LoadServNamesBySL();

        // if (this.state.bVisualize && selectItem.length !== 0)
        //     this.subfiltering(selectItem, 2);
    }

    LoadServNamesBySL() {
        let _this = this;
        let SelOpt = _this.state.sub_location_list;
        let nL = SelOpt.length;

        let sub = _this.state.servTemp;
        let subtt = [];

        _this.setState({ services: [] });

        for (let j = 0; j < nL; j++)
            for (let i = 0; i < sub.length; i++)
                if (SelOpt[j].value === sub[i].sID)
                    subtt.push({ value: sub[i].value, label: sub[i].label, mID: sub[i].mID, sID: sub[i].sID });
        _this.state.services = subtt;
        _this.state.stateOptions = subtt;
        _this.setState({ services: subtt, stateOptions: subtt });

        // if( this.state.bVisualize )
        //     _this.handleChangeService(subtt);
    }

    handleChangeService(selectItemService) {
        this.state.services = selectItemService;
        this.setState({ services: selectItemService });
        // if (this.state.bVisualize && selectItemService.length !== 0)
        //     this.subfiltering(selectItemService, 3);
    }

    getfilterName(n1, sv) {
        let temp = [];
        for (let i = 0; i < n1.length; i++) {
            if (this.boolExist(sv, n1[i].serID) === 0) continue;
            temp.push(n1[i]);
        }
        return temp;
    }

    handleChangeMainFiltering(Src, tar, nCase) {
        let temp = [];
        for (let i = 0; i < tar.length; i++) {
            let id = tar[i].value;
            if (nCase === 3) id = tar[i].sID;
            let b = this.boolExistName(Src, id, nCase);
            if (b === 0) continue;

            temp.push(tar[i]);
        }
        return temp;
    }

    boolExistName(Src, id, nCase) {
        for (let i = 0; i < Src.length; i++) {
            let tt = null;
            if (nCase === 1) tt = Src[i].mID;
            else if (nCase === 2) tt = Src[i].sID;
            else if (nCase === 3) tt = Src[i].svID;
            if (id === tt) return 1;
        }
        return 0;
    }

    boolMainLoc(id) {
        let IDArr = this.state.main_location_list;

        let len = IDArr.length;

        for (let i = 0; i < len; i++) {
            let tt = IDArr[i].value;
            if (tt === id)
                return 1;
        }
        return 0;
    }

    boolSubLoc(id) {
        let IDArr = this.state.sub_location_list;

        let len = IDArr.length;
        for (let i = 0; i < len; i++) {
            let tt = IDArr[i].value;
            if (tt === id)
                return 1;
        }
        return 0;
    }

    boolService(id) {
        let IDArr = this.state.services;
        let nCnt = 0;
        let lenid = id.length;
        let len = IDArr.length;
        for (let j = 0; j < lenid; j++)
            for (let i = 0; i < len; i++) {
                let tt = IDArr[i].value;
                if (tt === id[j])
                    nCnt++;
                if (nCnt === lenid)
                    return 1;
            }

        return 0;
    }

    getTimezone(id) {
        let _this = this;
        let sub = _this.state.sub_location_list;
        for (let i = 0; i < sub.length; i++) {
            let tt = sub[i].timezone;
            let t = tt.split(")");

            if (id === sub[i].value)
                // console.log("t[1]", t[1]);
                return t[1];
        }
    }

    formatDate(date) {
        var d = new Date(date), month = '' + (d.getMonth() + 1), day = '' + d.getDate(), year = d.getFullYear();
        if (month.length < 2) { month = '0' + month } if (day.length < 2) { day = '0' + day }
        return [year, month, day].join('-');
    }

    notifyMessage = (place, color, text) => {
        var type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }

        var options = {};
        options = {
            place: place,
            message: (
                <div className="text-md-center">
                    <div>
                        <b>{text}</b>
                    </div>
                </div>
            ),
            type: type,
            icon: "now-ui-icons ui-1_bell-53",
            autoDismiss: 3
        };
        if (options !== null) { this.notificationAlert.notificationAlert(options) }
    };

    loadTokenData() {
        let _this = this,
            startDateFmt = _this.state.start_date_formatted, endDateFmt = _this.state.end_date_formatted;
        let subLocIDArrVals = _this.exportArrayValues(_this.state.sub_location_ID),
            servIDArrVals = _this.exportArrayValues(_this.state.services);
        let downloaddata = [];
        _this.setState({ download_data: [] });
        let i = 0;
        console.log({
            "Currently running function:": "loadTokenData",
            start_date: startDateFmt,
            end_date: endDateFmt,
            sub_loc_arr: subLocIDArrVals,
            servs_arr: servIDArrVals,
            query_type: "vw_1"
        });

        Firebase.functions().httpsCallable('getBigQueryAnalyticsData', { timeout: 540000 })({ //HttpsCallableOptions timeout in ms
            start_date: startDateFmt,
            end_date: endDateFmt,
            sub_loc_arr: subLocIDArrVals,
            servs_arr: servIDArrVals,
            query_type: "vw_1"
        }).then(function (response) {

            console.log('response.data.length:' + response.data.length);
            console.log("response.data", response.data)

            // if (response.data.length == 0){

            //     _this.notifyMessage("tc", 3, "No data found!");
            //     window.setTimeout(function() { _this.props.history.push("/dashboards") }, 2000);

            // } else {
            JSON.parse(response.data).forEach(function (doc) {
                // console.logs(i, "doc",doc);
                //if (_this.boolExistID(_this.state.services, doc.Services_ID) === 1) {
                //let dateTimeString = (doc.Created_Date_Formated).toString();

                // new firestore timestamps
                let cft = doc.Created_Firestore_Timestamp !== undefined ? moment(new Date((doc.Created_Firestore_Timestamp) * 1000)).tz(doc.TimeZone).format("YYYY-MM-DD HH:mm:ss") : "";
                let cft_unixts = doc.Created_Firestore_Timestamp !== undefined ? parseInt(doc.Created_Firestore_Timestamp, 10) : "";

                let sdft = doc.Service_Date_Firestore_Timestamp !== undefined ? moment(new Date((doc.Service_Date_Firestore_Timestamp) * 1000)).tz(doc.TimeZone).format("YYYY-MM-DD HH:mm:ss") : "";
                let sdft_unixts = doc.Service_Date_Firestore_Timestamp !== undefined ? parseInt(doc.Service_Date_Firestore_Timestamp, 10) : "";

                let luft = doc.Last_Updated_Firestore_Timestamp !== undefined ? moment(new Date((doc.Last_Updated_Firestore_Timestamp) * 1000)).tz(doc.TimeZone).format("YYYY-MM-DD HH:mm:ss") : "";
                let luft_unixts = doc.Last_Updated_Firestore_Timestamp !== undefined ? parseInt(doc.Last_Updated_Firestore_Timestamp, 10) : "";

                let ssft = doc.Service_Started_Firestore_Timestamp !== undefined ? moment(new Date((doc.Service_Started_Firestore_Timestamp) * 1000)).tz(doc.TimeZone).format("YYYY-MM-DD HH:mm:ss") : "";
                let ssft_unixts = doc.Service_Started_Firestore_Timestamp !== undefined ? parseInt(doc.Service_Started_Firestore_Timestamp, 10) : "";

                let seft = doc.Service_Ended_Firestore_Timestamp !== undefined ? moment(new Date((doc.Service_Ended_Firestore_Timestamp) * 1000)).tz(doc.TimeZone).format("YYYY-MM-DD HH:mm:ss") : "";
                let seft_unixts = doc.Service_Ended_Firestore_Timestamp !== undefined ? parseInt(doc.Service_Ended_Firestore_Timestamp, 10) : "";

                let aft = doc.Approved_Firestore_Timestamp !== undefined ? moment(new Date((doc.Approved_Firestore_Timestamp) * 1000)).tz(doc.TimeZone).format("YYYY-MM-DD HH:mm:ss") : "";
                let aft_unixts = doc.Approved_Firestore_Timestamp !== undefined ? parseInt(doc.Approved_Firestore_Timestamp, 10) : "";

                let c1ft = doc.Cancelled_Firestore_Timestamp !== undefined ? moment(new Date((doc.Cancelled_Firestore_Timestamp) * 1000)).tz(doc.TimeZone).format("YYYY-MM-DD HH:mm:ss") : "";
                let c1ft_unixts = doc.Cancelled_Firestore_Timestamp !== undefined ? parseInt(doc.Cancelled_Firestore_Timestamp, 10) : "";



                // let st_sec = doc.Serving_Time_S; //parseInt(doc.Serving_Time_S, 10); ////WHERE Created_Datetime_Unix_Timestamp  >= '1629821671'  (FIXED TIMESTAMP FOR CURRENT BIG QUERY)
                // let wt_sec = doc.Waiting_Time_S; //parseInt(doc.Waiting_Time_S, 10);

                let sts = doc.Serving_Time_S; //sedt_unixts - ssdt_unixts;   //--ADDED 28/AUG/2021 Tuesday, August 24, 2021 9:14:31 PM GMT+05:00
                let wts = doc.Waiting_Time_S; //ssdt_unixts - cft;

                let timezone = doc.TimeZone;
                //let wts_botct = doc.Waiting_Time_Based_On_Token_Creation_Time;
                let wts_bossst = doc.Waiting_Time_Based_On_Scheduled_Service_Start_Time_Seconds;
                let scst = doc.Scheduled_Call_Start_Time;

                //   console.log({
                //     Tokid: doc.id,
                //     cft: cft,
                //     cft_unixts: cft_unixts,
                //     sdft: sdft,
                //     sdft_unixts: sdft_unixts,
                //     luft: luft,
                //     luft_unixts: luft_unixts,
                //     ssft: ssft,
                //     ssft_unixts: ssft_unixts,
                //     seft: seft,
                //     seft_unixts: seft_unixts,
                //     aft: aft,
                //     aft_unixts: aft_unixts, 
                //     c1ft: c1ft,
                //     c1ft_unixts: c1ft_unixts,
                //     // st_sec: cft,
                //     // wt_sec: cft,
                //     sts: sts,
                //     wts: wts,
                //   });

                downloaddata.push({
                    Created_Date_Formated: doc.Created_Date_Formated.value + " " + doc.Created_DateTime.substring(15, 23),
                    // "Created_Time_Formated": (doc.Created_DateTime).substring(15, 23),
                    Hour: doc.Hour,
                    Created_Datetime_Unix_Timestamp: cft_unixts,
                    // "Main_Location_ID": doc.Main_Location_ID,
                    Main_Location_Name: doc.Main_Location_Name,
                    // "Sub_Location_ID": doc.Sub_Location_ID,
                    Sub_Location_Name: doc.Sub_Location_Name,
                    // "Services_ID": doc.Services_ID,
                    Service_Name: doc.Service_Name,
                    Service_Name_Details: doc.Service_Name_Details,
                    Service_Cancelled_Datetime: doc.Service_Cancelled_Datetime,
                    Mobile_App_User_Email: doc.Mobile_App_User_Email,
                    Mobile_App_User_Contact: doc.Mobile_App_User_Contact,
                    // "Mobile_App_User_ID": doc.Mobile_App_User_ID,
                    Customer_Comments: doc.Customer_Comments,
                    // "Customer_ID": doc.Customer_ID,
                    // "Customer_Rated_Datetime": doc.Customer_Rated_Datetime,
                    Customer_Rating: doc.Customer_Rating,
                    Customer_Source: doc.Customer_Source,
                    Priority: doc.Priority,
                    Token_Created_User_Name: doc.Token_Created_User_Name,
                    Token_Number: doc.Token_Number,
                    Token_Status: doc.Token_Status,
                    Served_Counter: doc.Served_Counter,
                    Served_User_Id: doc.Served_User_Id,
                    Served_User_Name: doc.Served_User_Name,
                    Input_Source: doc.Input_Source,
                    //Serving_Time: doc.Serving_Time,
                    Rated_Comments: doc.Rated_Comments,
                    // "Rated_Datetime": doc.Rated_Datetime,
                    TimeZone: _this.getTimezone(doc.Sub_Location_ID),
                    Rating: doc.Rating,
                    Serving_Time_S: sts,
                    Waiting_Time_S: wts,
                    //Waiting_Time_Based_On_Token_Creation_Time: wts_botct,
                    Waiting_Time_Based_On_Scheduled_Service_Start_Time_S: wts_bossst,
                    Scheduled_Call_Start_Time: scst,
                    Serving_Time_M: parseFloat(sts / 60).toFixed(2),
                    Waiting_Time_M: parseFloat(wts / 60).toFixed(2),
                    Serving_Time_Segment: _this.calcTimeSegmentFromSeconds(sts),
                    Waiting_Time_Segment: _this.calcTimeSegmentFromSeconds(wts),
                    // Created_DateTime: Created_Firestore_Timestamp,
                    // Serving_Time_S: sts, //((seft.toMillis() / 1000) - (ssft.toMillis() / 1000)),
                    // Waiting_Time_S: wts, //((ssft.toMillis() / 1000) - (cft.toMillis() / 1000)),
                    // Waiting_Time_S_Before: (t.data().Waiting_Time_S_Before == undefined) ? t.data().Waiting_Time : t.data().Waiting_Time_S_Before,
                    // Serving_Time: (t.data().Serving_Time !== undefined && t.data().Serving_Time !== "" && t.data().Serving_Time !== "0" && t.data().Serving_Time !== 0)? t.data().Serving_Time : "0:0:0",
                    Created_Firestore_Timestamp: (cft !== undefined && cft !== "" & cft !== "Invalid date") ? cft : 'N/A',
                    Requested_Firestore_Timestamp: (cft !== undefined && cft !== "" & cft !== "Invalid date") ? cft : 'N/A',
                    Service_Date_Firestore_Timestamp: (sdft !== undefined && sdft !== "" & sdft !== "Invalid date") ? sdft : 'N/A',
                    Approved_Firestore_Timestamp: (aft !== undefined && aft !== "" & aft !== "Invalid date") ? aft : 'N/A',
                    Service_Started_Firestore_Timestamp: (ssft !== undefined && ssft !== "" & ssft !== "Invalid date") ? ssft : 'N/A',
                    Service_Ended_Firestore_Timestamp: (seft !== undefined && seft !== "" & seft !== "Invalid date") ? seft : 'N/A',
                    Cancelled_Firestore_Timestamp: (c1ft !== undefined && c1ft !== "" & c1ft !== "Invalid date") ? c1ft : 'N/A',
                    Last_Updated_Firestore_Timestamp: (luft !== undefined && luft !== "" & luft !== "Invalid date") ? luft : 'N/A',

                    // Service_Start_Date_Time: ssft, //moment.tz((((parseInt(cdunix_ts,10) + parseInt(doc.Waiting_Time,10)) - parseInt(doc.Serving_Time_S,10))*1000), _this.getTimezone(doc.Sub_Location_ID)).format('ddd MMM D YYYY HH:mm:ss [GMT]Z'),
                    // Service_End_Date_Time: sedt,
                    // Service_Start_Date_Time_Unix_TS: ssdt_unixts,
                    // Service_End_Date_Time_Unix_TS: sedt_unixts,
                    // "Service_Start_Date_Time_BEFORE": moment.tz(((parseInt(cdunix_ts,10) + parseInt(doc.Waiting_Time,10))*1000), _this.getTimezone(doc.Sub_Location_ID)).format('ddd MMM D YYYY HH:mm:ss [GMT]Z'),
                    // "Service_Start_End_Time_BEFORE": moment.tz(((parseInt(cdunix_ts,10) + parseInt(doc.Waiting_Time,10) + parseInt(doc.Serving_Time_S,10))*1000), _this.getTimezone(doc.Sub_Location_ID)).format('ddd MMM D YYYY HH:mm:ss [GMT]Z'),
                    Count: doc.COUNT,
                });
                //}
                i++;
            });

            // if (downloaddata.length > 0) {
            // _this.setState({ loading: false });
            // _this.setState({ download_data: downloaddata });
            // }
            //console.log("downloaddata.length", downloaddata.length);
            // }

        }).then(function () {
            if (downloaddata.length > 0) {
                // _this.setState({ loading: false });
                console.log("reached here #3 success", "downloaddata.length", downloaddata.length, "i", i);
                _this.setState({ download_data: downloaddata });
                // console.log("downloaddata.length", downloaddata.length, "downloaddata", downloaddata)
                // _this.subfiltering([], 0);
                _this.setState({ loading: false });
            }
            else {
                _this.setState({ loading: false });
                _this.notifyMessage("tc", 3, "No data found for these selected dates, locations and services!");
            }
        }).catch(function (error) {
            _this.setState({ loading: false });
            console.log("reached here #4 error", error);
        });
        console.log("reached here #5 done");
    }



    //     loadtokenDataO() {
    //         let _this = this;
    //         let totalData = [];
    //         let start = _this.state.defaultDate.start;
    //         let end = _this.state.defaultDate.end;
    //         let startSec = Date.parse(start) / 1000;
    //         let endSec = Date.parse(end) / 1000;
    //         let downloaddata = [];
    //         //console.log("reached here #1", "start", start, "end", end, "startSec", startSec, "endSec", endSec);
    //         _this.state.detailTokenData = [];

    //         let customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;
    //         Firebase.firestore().collection('Token_Details_Archive')
    //             .where('Customer_ID', '==', customer_id)
    //             // .where('Created_Datetime_Unix_Timestamp', '>=', startSec)
    //             // .where('Created_Datetime_Unix_Timestamp', '<=', endSec)
    //             .get().then(function (response) {
    //                 console.log('data count:' + response.docs.length);

    //                 let i = 0, timez = '';
    //                 response.docs.forEach(function (doc) {
    // //console.log(i, doc.data().Created_Datetime_Unix_Timestamp, "doc",doc);
    //                     var Day = doc.data().Created_Datetime_Unix_Timestamp; //Created_Datetime
    //                     // var str = Day.substring(0, 3); let m = 0; switch (str) { case "Jan": m = 0; break; case "Feb": m = 1; break; case "Mar": m = 2; break; case "Apr": m = 3; break; case "May": m = 4; break; case "Jun": m = 5; break; case "Jul": m = 6; break; case "Aug": m = 7; break; case "Sep": m = 8; break; case "Oct": m = 9; break; case "Nov": m = 10; break; case "Dec": m = 11; break; }
    //                     // str = Day.substring(4, 7); let d = parseInt(str, 10); str = Day.substring(7, 12); let y = parseInt(str, 10); str = Day.substring(16, 19); let hh = parseInt(str, 10); str = Day.substring(18, 20); let mm = parseInt(str, 10); str = Day.substring(21, 24); let ss = parseInt(str, 10); 
    //                     let ID2 = doc.data().Sub_Location_ID;
    //                     // if (i == 0) { 
    //                     //     timez = _this.getTimezone(ID2); 
    //                     // }
    //                     // console.log("reached here #2");
    //                     // Day = new Date(y, m, d, hh, mm, ss).toLocaleString("en-US", { timeZone: timez }); let Sec = new Date(Day); let sec = Date.parse(Sec) / 1000;
    //                     let Sec = new Date(Day * 1000);
    //                     // let sec = new Date(Day).getTime() / 1000;
    //                     // if (sec > startSec && sec < endSec)
    //                     {
    //                         let ID1 = doc.data().Main_Location_ID;
    //                         let ID3 = doc.data().Services_ID;

    //                         let main = doc.data().Main_Location_Name;
    //                         let sub = doc.data().Sub_Location_Name;
    //                         let serv = doc.data().Service_Name;
    //                         let scd = doc.data().Service_Cancelled_Datetime;
    //                         let cusID = doc.data().Customer_ID;

    //                         let cc = doc.data().Customer_Comments;
    //                         let mauc = doc.data().Mobile_App_User_Contact; //new
    //                         let mauid = doc.data().Mobile_App_User_ID; //new
    //                         let maue = doc.data().Mobile_App_User_Email; //new
    //                         let maun = doc.data().Mobile_App_User_Name; //new
    //                         let rdtime = doc.data().Rated_Datetime; //new
    //                         let rcom = doc.data().Rated_Comments; //new

    //                         let rating, name = "";
    //                         if (doc.data().Rating != undefined){
    //                             rating = doc.data().Rating; //new
    //                             name = doc.data().Rating;
    //                         } 

    //                         let cusScr = doc.data().Customer_Source;
    //                         let counterN = doc.data().Served_Counter;
    //                         let pt = doc.data().Priority;
    //                         let ptStr = parseInt(pt) == 0 ? "Normal" : "High";
    //                         let tcun = doc.data().Token_Created_User_Name;
    //                         let tn = doc.data().Token_Number;
    //                         let token = doc.data().Token_Status;
    //                         let suname = doc.data().Served_User_Name;
    //                         let inputsource = doc.data().Input_Source;

    //                         let st = doc.data().Serving_Time;
    //                         let wt = doc.data().Waiting_Time;
    //                         var a = [], seconds = 0;

    //                         if (st.length > 4) {
    //                             a = st.split(':'); // split it at the colons
    //                             seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
    //                         }
    //                         var b = parseInt(wt, 10);
    //                         if (_this.boolExistID(_this.state.stateOptions, ID3) === 1)
    //                             // totalData.push({
    //                             //     id: doc.id, date: Sec, Day: Day, mainID: main, mID: ID1, subID: sub, sID: ID2, serID: serv, vID: ID3, SCDT: scd,
    //                             //     MAUC: mauc, MAUID: mauid, CC: cc, CUSID: cusID, CUSRDT: rdtime, label: name, CUSSRC: cusScr, inputSrc: inputsource,
    //                             //     counteruser: counterN, servusr: suname, TCUN: tcun, TN: tn, token: token,
    //                             //     ST: seconds, WT: b, Prio: ptStr
    //                             // });
    //                             totalData.push({
    //                                 id: doc.id, date: Sec, Day: Day, mainID: main, mID: ID1, subID: sub, sID: ID2, serID: serv, vID: ID3, inputSrc: inputsource, ST: seconds, WT: b, token: token,
    //                                 SCDT: scd, CC: cc, CUSID: cusID, CUSRDT: rdtime, CUSSRC: cusScr, counteruser: counterN, servusr: suname, TCUN: tcun, TN: tn, Prio: ptStr, label: name,
    //                                 cusCom: cc, MAUC: mauc, MAUID: mauid, MAUE: maue, MAUN: maun, RDTIME: rdtime, RCOM: rcom, RATING: rating
    //                             });

    //                             downloaddata.push({
    //                                 Created_DateTime: Day, Main_Location_Name: main, Sub_Location_Name: sub, Service_Name: serv,
    //                                 Input_Source: inputsource, Serving_Time: seconds, Waiting_Time: wt, Token_Status: token, Served_User_Name: suname, Customer_Comments: cc,
    //                                 Mobile_App_User_Contact: mauc, Mobile_App_User_ID: mauid, Mobile_App_User_Email: maue, Mobile_App_User_Name: maun, Rated_Datetime: rdtime, Rated_Comments: rcom, RATING: rating
    //                             });

    //                     }
    //                     i++;
    //                 });


    //                 // 
    //                 if (totalData.length > 0) {
    //                     _this.setState({ loading: false });
    //                     _this.setState({ detailTokenData: totalData });
    //                     // for test
    //                     // _this.subfiltering(totalData, 0);                
    //                 }

    //             }).then(function () {
    //                 // _this.setState({ loading: false });
    //                 console.log("reached here #3 success");
    //                 _this.setState({ download_data: downloaddata });
    //                 console.log("downloaddata", downloaddata)
    //                 // _this.subfiltering([], 0);

    //             }).catch(function (error) {
    //                 _this.setState({ loading: false });
    //                 console.log("reached here #4 error", error);
    //             });
    //         console.log("reached here #5 done");
    //     }

    calcTimeSegmentFromSeconds(tSegSec) {
        var sec = tSegSec;
        var timeSegment = "";
        if (sec <= 300) timeSegment = "5 minutes and less";
        else if (sec <= 600) timeSegment = "5 to 10 minutes";
        else if (sec <= 900) timeSegment = "10 to 15 minutes";
        else if (sec <= 1200) timeSegment = "15 to 20 minutes";
        else if (sec <= 1500) timeSegment = "20 to 25 minutes";
        else if (sec <= 1800) timeSegment = "25 to 30 minutes";
        else if (sec > 1800) timeSegment = "Above 30 minutes";
        return timeSegment;
    }

    timeStampToDateTimeConvert(UNIX_timestamp) {
        var a = new Date(UNIX_timestamp * 1000);
        var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var hour = a.getHours();
        var min = a.getMinutes();
        var sec = a.getSeconds();
        var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
        return time;
    }

    onVisualize() {
        let _this = this;
        if (!_this.state.bVisualize) {
            let start = _this.state.defaultDate.start, end = _this.state.defaultDate.end;
            // let startSec = Date.parse(start) / 1000, endSec = Date.parse(end) / 1000;
            let startSec = Date.parse(start) / 1000, endSec = (Date.parse(end) + 86400) / 1000; // number of seconds in 1 day = 86400s
            // let startDateFmt = _this.formatDate(start), endDateFmt = _this.formatDate(end);
            if ((endSec - startSec) > (_this.state.dateRangeLimitSeconds)) {
                _this.setState({ loading: false });
                _this.notifyMessage("tc", 3, `Please select a date range less than ${_this.state.dateRangeLimitDays} days!`);
            } else {
                _this.setState({ loading: true, bVisualize: true });
                // // console.log("selected Segmentation Type:", _this.state.segmentationType);
                _this.loadTokenData();
                // _this.subfiltering([], 0);
            }
        }
    }

    mainFiltering() {
        let total = this.state.detailTokenData;
        let len = total.length;
        let main = [], sub = [], service = [];
        for (let i = 0; i < len; i++) {
            let b1 = this.boolExist(main, total[i].mainID);
            let b2 = this.boolExist(sub, total[i].subID);
            let b3 = this.boolExist(service, total[i].serID);

            if (b1 === 1 && b2 === 1 && b3 === 1) continue;

            if (b1 === 0) {
                main.push({ value: total[i].id, label: total[i].mainID, mID: total[i].mID, sID: total[i].sID, vID: total[i].vID });
                // addressDefinitions.push({value : total[i].id, label: total[i].mainID, mID: total[i].mID, sID: total[i].sID, vID : total[i].vID });
            }
            if (b2 === 0)
                sub.push({ value: total[i].id, label: total[i].subID, mID: total[i].mID, sID: total[i].sID, vID: total[i].vID });
            if (b3 === 0)
                service.push({ value: total[i].id, label: total[i].serID, mID: total[i].mID, sID: total[i].sID, vID: total[i].vID });
        }

        this.setState({ main_location_list: main, addressDefinitions: main, sub_location_list: sub, subTemp: sub, services: service, servTemp: service, sub_location_ID: sub, stateOptions: service });
    }

    boolExist(temp, name) {
        let bN = 0;
        for (let j = 0; j < temp.length; j++) {
            if (name === temp[j].label) {
                bN = 1;
                break;
            }
        }
        return bN;
    }

    boolExistID(temp, name) {
        let bN = 0;
        for (let j = 0; j < temp.length; j++) {
            if (name === temp[j].value) {
                bN = 1;
                break;
            }
        }
        return bN;
    }

    exportArrayValues(temp) {
        let result = [];
        for (let j = 0; j < temp.length; j++) {
            result.push(temp[j].value);
        }
        return result;
    }

    pad(num, size) {
        return ('000' + num).slice(size * -1);
    }

    sec2time(time) {
        // var pad = function(num, size) { return ('000' + n?um).slice(size * -1); },
        // time = parseFloat(timeInSeconds).toFixed(3),
        let hours = Math.floor(time / 60 / 60);
        let minutes = Math.floor(time / 60) % 60;
        let seconds = Math.floor(time - minutes * 60);

        return this.pad(hours, 2) + ':' + this.pad(minutes, 2) + ':' + this.pad(seconds, 2);
    }

    //V^6@}J*s
    render() {
        const { main_location_list, sub_location_list, services, defaultDate } = this.state;
        //const { feedback_data } = { ...this.state }; // dataLineChartToken, data3DChartToken, TokenColor, TokenLabel, 
        //       this.setState({dataLineChartToken:this.state.dataLineChartToken});
        // const feedback_data = this.getfeedbackData();
        return (
            <>
                <LoadingOverlay
                    active={this.state.loading}
                    spinner
                    text='Loading'
                    className='content'

                >
                    <Row>
                        <Col md="12">
                            <Card className="content">
                                <CardHeader>
                                    <h4 className="padding_Dashboard card-title">Report - Detail Download</h4>
                                    {/* <CardTitle tag="h4">Report - Detail Download</CardTitle> */}
                                </CardHeader>
                                <CardBody >
                                    <div className="padding_Card card-body">
                                        <div className="blockquote blockquote-primary padding">
                                            <Row>
                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <h5>Select Date Range</h5>

                                                    {/*<div id="daterangepicker" title="daterangepicker"></div> */}
                                                    <DateRangePicker
                                                        value={this.state.defaultDate}
                                                        onChange={this.handleChangeDate}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <h5>Select Main Location</h5>
                                                    <Select
                                                        placeholder='Select Main Location'
                                                        closeMenuOnSelect={true}
                                                        value={this.state.main_location_list}
                                                        isMulti
                                                        options={this.state.addressDefinitions}
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <h5>Select Sub Location</h5>
                                                    <Select
                                                        placeholder='Select Sub Location'
                                                        closeMenuOnSelect={true}
                                                        value={this.state.sub_location_list}
                                                        clearValue
                                                        isMulti
                                                        options={this.state.sub_location_ID}
                                                        onChange={this.handleChangesub}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <h5>Select Services</h5>
                                                    <Select
                                                        placeholder='Select Services'
                                                        closeMenuOnSelect={true}
                                                        value={this.state.services}
                                                        isClearable
                                                        clearValue
                                                        isMulti
                                                        options={this.state.stateOptions}
                                                        onChange={(selectItemService) => this.handleChangeService(selectItemService)}
                                                    />
                                                </div>
                                                {/* <div className="col-12 col-md-6 col-lg-3">
                                                    <h5>Select Segmentation Type</h5>
                                                    <Select
                                                        placeholder='Select Segmentation Type'
                                                        closeMenuOnSelect={true}
                                                        value={this.state.segmentationType}
                                                        isClearable
                                                        clearValue
                                                        isMulti
                                                        options={this.state.segmentationOptions}
                                                        onChange={(selectItemSegmentation) => this.handleChangeSegmentation(selectItemSegmentation)}
                                                    />
                                                </div> */}
                                            </Row>

                                            <div className="text-center padding_button">
                                                <button disabled={!(main_location_list.length > 0 && sub_location_list.length > 0 && services.length > 0 && defaultDate.start && defaultDate.end)} className="btn-round btn btn-outline-success h6" type="button" onClick={() => this.onVisualize()}>Prepare Data</button>
                                            </div>
                                            <Row>
                                                <div className="text-center">
                                                    <h5>Data maybe delayed up to 20 hours.</h5>
                                                </div>
                                            </Row>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    {this.state.bVisualize ?
                        <Row >
                            <Col md="8">
                                <Row>
                                    <Col md="12">
                                        <Card className="ml-auto mr-auto ">
                                            <CardHeader>
                                                <CardTitle tag="h4" style={{ marginLeft: 30 }}>Download Data</CardTitle>
                                            </CardHeader>
                                            <CardBody >
                                                <div className="padding_Card card-body">
                                                    {/* <div className="blockquote blockquote-primary padding"> */}
                                                    <Row>
                                                        <div className="col-12 col-md-6 col-lg-3"></div>
                                                        <Row>
                                                            <CSVLink
                                                                data={this.state.download_data}
                                                                filename={"detail-data.csv"}
                                                                className="btn-round btn btn-outline-success"
                                                                target="_blank"
                                                            >
                                                                Download Detail Data to CSV
                                                            </CSVLink>
                                                        </Row> : <div style={{ width: '100%', height: 200 }}></div>
                                                    </Row>
                                                    {/* </div> */}
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row> :
                        <div ></div>
                    }
                </LoadingOverlay>
            </>
        );
    }
}

export default Report5DetailDownload;