//import ManagementDashboard from "./views/pages/admin/ManagementDashboard.jsx";
// import Customers from "./views/pages/admin/Customers.jsx";
import CustomerAdd from "./views/pages/admin/CustomerAdd.jsx";
import Packages from "./views/pages/admin/Packages.jsx";
import PackageAdd from "./views/pages/admin/PackageAdd.jsx";
import PackageEdit from "./views/pages/admin/PackageEdit.jsx";
import Configurations from "./views/pages/admin/Configurations.jsx";
import Gallery from "./views/pages/admin/Gallery.jsx";
import SupportCenter from "./views/pages/admin/SupportCenter.jsx";
import SupportCenterLoginAsSpecificUser from "./views/pages/admin/SupportCenterLoginAsSpecificUser.jsx";
import SupportCenterLoginByRole from "./views/pages/admin/SupportCenterLoginByRole.jsx";
import Welcome from "./views/pages/admin/Welcome.jsx";
import Dashboard1GeneralOverview from "./views/pages/admin/Dashboard1GeneralOverview.jsx";
import Dashboard2ServiceLevel from "./views/pages/admin/Dashboard2ServiceLevel.jsx";
// import Dashboard3PeakTimes from "./views/pages/admin/Dashboard3PeakTimes.jsx";
import Dashboard4Live from "./views/pages/admin/Dashboard4Live.jsx";
import Report1Segmentation from "./views/pages/admin/Report1Segmentation.jsx";
import Report2Percentile from "./views/pages/admin/Report2Percentile.jsx";
import Report3Mean from "./views/pages/admin/Report3Mean.jsx";
import Report4CustomerFeedback from "./views/pages/admin/Report4CustomerFeedback.jsx";
import Report5DetailDownload from "./views/pages/admin/Report5DetailDownload.jsx";
import Report6DetailDownloadCSVFormat from "./views/pages/admin/Report6DetailDownloadCSVFormat.jsx";
import Locations from "./views/pages/admin/Locations.jsx";
// import Test778 from "./views/pages/admin/Test778.jsx";
import MainLocationAdd from "./views/pages/admin/MainLocationAdd.jsx";
import MainLocationEdit from "./views/pages/admin/MainLocationEdit.jsx";
import SubLocationAdd from "./views/pages/admin/SubLocationAdd.jsx";
// import SubLocationAddRequestQuotation from "./views/pages/admin/SubLocationAddRequestQuotation.jsx";
import SubLocationAddSelectPackage from "./views/pages/admin/SubLocationAddSelectPackage.jsx";
import SubLocationEdit from "./views/pages/admin/SubLocationEdit.jsx";
import SubLocationRequestQuotation from "./views/pages/admin/SubLocationRequestQuotation.jsx";
import SubLocationManagePackage from "./views/pages/admin/SubLocationManagePackage.jsx";
import Services from "./views/pages/admin/Services.jsx";
import ServiceAdd from "./views/pages/admin/ServiceAdd.jsx";
import ServiceEdit from "./views/pages/admin/ServiceEdit.jsx";
import ServiceRequests from "./views/pages/admin/ServiceRequests.jsx";
import ApprovalRequests from "./views/pages/admin/ApprovalRequests.jsx";
import AvailableAppointments from "./views/pages/admin/AvailableAppointments.jsx";
import RequestNotifications from "./views/pages/admin/RequestNotifications.jsx";
// import OtherRequests from "./views/pages/admin/OtherRequests.jsx";
// import TokenDetails from "./views/pages/admin/TokenDetails.jsx";
import Counters from "./views/pages/admin/Counters.jsx";
import CounterAdd from "./views/pages/admin/CounterAdd.jsx";
import CounterEdit from "./views/pages/admin/CounterEdit";
import CounterRun from "./views/pages/admin/CounterRun";
// import CounterRunN from "./views/pages/admin/CounterRunN";
// import CounterDisplays from "./views/pages/admin/CounterDisplays.jsx";
import MainDisplay from "./views/pages/admin/MainDisplay.jsx";
//import DisplayAdd from "./views/pages/admin/DisplayAdd.jsx";
import DisplayEdit from "./views/pages/admin/DisplayEdit.jsx";
import DisplayRun from "./views/pages/admin/DisplayRun.jsx";
import Kiosks from "./views/pages/admin/Kiosks.jsx";
import KioskAddEdit from "./views/pages/admin/KioskAddEdit.jsx";
import KioskPrintEdit from "./views/pages/admin/KioskPrintEdit.jsx";
// import DispenserAdd from "./views/pages/admin/DispenserAdd.jsx";
// import DispenserBasicEdit from "./views/pages/admin/DispenserBasicEdit.jsx";
// import DispenserAdvanceEdit from "./views/pages/admin/DispenserAdvanceEdit.jsx";
import Users from "./views/pages/admin/Users.jsx";
import UserAdd from "./views/pages/admin/UserAdd.jsx";
import UserEdit from "./views/pages/admin/UserEdit.jsx";
// import Downloads from "./views/pages/admin/Downloads.jsx";
import Downloads from './views/pages/admin/downloads/'
import addEdit from './views/pages/admin/downloads/addEdit'
import Statistics from "./views/pages/admin/Statistics.jsx";
import AccountSettings from "./views/pages/admin/AccountSettings.jsx";
import Billing from "./views/pages/admin/Billing.jsx";
import BillingAlerts from "./views/pages/admin/BillingAlerts.jsx";
import BillingOnlineSubscriptions from "./views/pages/admin/BillingOnlineSubscriptions.jsx";
import BillingOnlinePaymentHistory from "./views/pages/admin/BillingOnlinePaymentHistory.jsx";
import BillingManualSubscriptions from "./views/pages/admin/BillingManualSubscriptions.jsx";
import BillingManualPaymentHistory from "./views/pages/admin/BillingManualPaymentHistory.jsx";
import BillingManualPaymentInvoiceAdd from "./views/pages/admin/BillingManualPaymentInvoiceAdd.jsx";
import BillingManualPaymentInvoiceEdit from "./views/pages/admin/BillingManualPaymentInvoiceEdit.jsx";
import BillingManualDocumentAttachments from "./views/pages/admin/BillingManualDocumentAttachments.jsx";
import BillingManualDocumentAttachmentAdd from "./views/pages/admin/BillingManualDocumentAttachmentAdd.jsx";
import BillingManualDocumentAttachmentEdit from "./views/pages/admin/BillingManualDocumentAttachmentEdit.jsx";
import Calendars from "./views/pages/admin/Calendars";

const routes = [
  // {
  //     path: "/management_dashboard",
  //     name: "ManagementDashboard",
  //     icon: "nc-icon nc-palette",
  //     component: ManagementDashboard,
  //     layout: "/admin",
  //     site_admin_hide: false,
  //     support_admin_hide: false,
  //     system_admin_hide: true,
  //     billing_admin_hide: true,
  //     location_admin_hide: true,
  //     location_super_hide: true,
  //     counter_super_hide: true,
  //     counter_user_hide: true,
  //     main_display_user_hide: true,
  //     counter_display_user_hide: true,
  //     dispenser_user_hide: true,
  // },
  {
    path: "/support_center",
    name: "Support Center",
    icon: "nc-icon nc-support-17",
    component: SupportCenter,
    layout: "/admin",
    non_support_hide: true,
    site_admin_hide: false,
    support_admin_hide: false,
    system_admin_hide: true,
    billing_admin_hide: true,
    location_admin_hide: true,
    location_super_hide: true,
    counter_super_hide: true,
    counter_user_hide: true,
    main_display_user_hide: true,
    counter_display_user_hide: true,
    dispenser_user_hide: true,
  },
  {
    path: "/support_central/login_as_specific_user",
    name: "supportCenterLoginAsSpecificUser",
    component: SupportCenterLoginAsSpecificUser,
    layout: "/admin",
    hidden: true,
  },
  {
    path: "/support_central/login_by_role",
    name: "supportCenterLoginByRole",
    component: SupportCenterLoginByRole,
    layout: "/admin",
    hidden: true,
  },
  // {
  //     path: "/customers",
  //     name: "Customers",
  //     icon: "nc-icon nc-badge",
  //     component: Customers,
  //     layout: "/admin",
  //     site_admin_hide: false,
  //     support_admin_hide: false,
  //     system_admin_hide: true,
  //     billing_admin_hide: true,
  //     location_admin_hide: true,
  //     location_super_hide: true,
  //     counter_super_hide: true,
  //     counter_user_hide: true,
  //     main_display_user_hide: true,
  //     counter_display_user_hide: true,
  //     dispenser_user_hide: true,
  // },
  // {
  //     path: "/customer/add",
  //     name: "customerAdd",
  //     component: CustomerAdd,
  //     layout: "/admin",
  //     hidden: true
  // },
  {
    path: "/packages",
    name: "Packages",
    icon: "nc-icon nc-pin-3",
    component: Packages,
    layout: "/admin",
    site_admin_hide: false,
    support_admin_hide: true,
    system_admin_hide: true,
    billing_admin_hide: true,
    location_admin_hide: true,
    location_super_hide: true,
    counter_super_hide: true,
    counter_user_hide: true,
    main_display_user_hide: true,
    counter_display_user_hide: true,
    dispenser_user_hide: true,
  },
  {
    path: "/package/add",
    name: "packageAdd",
    component: PackageAdd,
    layout: "/admin",
    hidden: true,
  },
  {
    path: "/package/edit/:id",
    name: "packageEdit",
    component: PackageEdit,
    layout: "/admin",
    hidden: true,
  },
  {
    path: "/configurations",
    name: "Configurations",
    icon: "nc-icon nc-settings",
    component: Configurations,
    layout: "/admin",
    site_admin_hide: false,
    support_admin_hide: true,
    system_admin_hide: true,
    billing_admin_hide: true,
    location_admin_hide: true,
    location_super_hide: true,
    counter_super_hide: true,
    counter_user_hide: true,
    main_display_user_hide: true,
    counter_display_user_hide: true,
    dispenser_user_hide: true,
  },
  {
    path: "/gallery",
    name: "Gallery",
    icon: "nc-icon nc-album-2",
    component: Gallery,
    layout: "/admin",
    site_admin_hide: false,
    support_admin_hide: false,
    system_admin_hide: true,
    billing_admin_hide: true,
    location_admin_hide: true,
    location_super_hide: true,
    counter_super_hide: true,
    counter_user_hide: true,
    main_display_user_hide: true,
    counter_display_user_hide: true,
    dispenser_user_hide: true,
  },
  {
    path: "/welcome",
    name: "Welcome",
    icon: "nc-icon nc-satisfied",
    component: Welcome,
    layout: "/admin",
    site_admin_hide: false,
    support_admin_hide: false,
    system_admin_hide: false,
    billing_admin_hide: false,
    location_admin_hide: false,
    location_super_hide: false,
    counter_super_hide: false,
    counter_user_hide: false,
    main_display_user_hide: false,
    counter_display_user_hide: false,
    dispenser_user_hide: false,
  },
  // {
  //     path: "/dashboards",
  //     name: "Dashboards",
  //     icon: "nc-icon nc-palette",
  //     component: "",
  //     layout: "/admin",
  //     site_admin_hide: false,
  //     support_admin_hide: false,
  //     system_admin_hide: true,
  //     billing_admin_hide: true,
  //     location_admin_hide: true,
  //     location_super_hide: true,
  //     counter_super_hide: true,
  //     counter_user_hide: true,
  //     main_display_user_hide: true,
  //     counter_display_user_hide: true,
  //     dispenser_user_hide: true,
  // },
  {
    collapse: true,
    name: "Analytics",
    icon: "nc-icon nc-chart-bar-32",
    state: "analyticsCollapse",
    layout: "/admin",
    site_admin_hide: false,
    support_admin_hide: false,
    system_admin_hide: false,
    billing_admin_hide: false,
    location_admin_hide: false,
    location_super_hide: false,
    counter_super_hide: false,
    counter_user_hide: true,
    main_display_user_hide: true,
    counter_display_user_hide: true,
    dispenser_user_hide: true,
    views: [
      {
        path: "/Dashboard1GeneralOverview",
        name: "General Overview",
        icon: "nc-icon nc-chart-bar-32",
        component: Dashboard1GeneralOverview,
        righticon: "nc-icon rotate-180",
        /*hidden : true,*/
        layout: "/admin",
        site_admin_hide: false,
        support_admin_hide: false,
        system_admin_hide: false,
        billing_admin_hide: false,
        location_admin_hide: false,
        location_super_hide: false,
        counter_super_hide: false,
        counter_user_hide: true,
        main_display_user_hide: true,
        counter_display_user_hide: true,
        dispenser_user_hide: true,
      },
      {
        path: "/Dashboard2ServiceLevel",
        name: "Service Level",
        icon: "nc-icon nc-chart-bar-32",
        component: Dashboard2ServiceLevel,
        righticon: "nc-icon rotate-180",
        /*hidden : true,*/
        layout: "/admin",
        site_admin_hide: false,
        support_admin_hide: false,
        system_admin_hide: false,
        billing_admin_hide: false,
        location_admin_hide: false,
        location_super_hide: false,
        counter_super_hide: false,
        counter_user_hide: true,
        main_display_user_hide: true,
        counter_display_user_hide: true,
        dispenser_user_hide: true,
      },
      // {
      //     path: "/Dashboard3PeakTimes ",
      //     name: "Peak Times",
      //     icon: "nc-icon nc-chart-bar-32",
      //     component: Dashboard3PeakTimes,
      //     righticon: "nc-icon rotate-180",
      //     /*hidden : true,*/
      //     layout: "/admin",
      //     site_admin_hide: false,
      //     support_admin_hide: false,
      //     system_admin_hide: false,
      //     billing_admin_hide: false,
      //     location_admin_hide: false,
      //     location_super_hide: true,
      //     counter_super_hide: false,
      //     counter_user_hide: true,
      //     main_display_user_hide: true,
      //     counter_display_user_hide: true,
      //     dispenser_user_hide: true,
      // },
      {
        path: "/Dashboard4Live ",
        name: "Live",
        icon: "nc-icon nc-chart-bar-32",
        component: Dashboard4Live,
        righticon: "nc-icon rotate-180",
        /*hidden : true,*/
        layout: "/admin",
        site_admin_hide: false,
        support_admin_hide: false,
        system_admin_hide: false,
        billing_admin_hide: false,
        location_admin_hide: false,
        location_super_hide: false,
        counter_super_hide: false,
        counter_user_hide: true,
        main_display_user_hide: true,
        counter_display_user_hide: true,
        dispenser_user_hide: true,
      },
      // {
      //     path: "/reports",
      //     name: "Reports",
      //     icon: "nc-icon nc-book-bookmark",
      //     component: "",
      //     layout: "/admin",
      //     site_admin_hide: false,
      //     support_admin_hide: false,
      //     system_admin_hide: false,
      //     billing_admin_hide: false,
      //     location_admin_hide: false,
      //     location_super_hide: true,
      //     counter_super_hide: false,
      //     counter_user_hide: true,
      //     main_display_user_hide: true,
      //     counter_display_user_hide: true,
      //     dispenser_user_hide: true,
      // },
      {
        path: "/Report1Segmentation",
        name: "Segmentation",
        icon: "nc-icon nc-chart-bar-32",
        component: Report1Segmentation,
        righticon: "nc-icon rotate-180",
        /*hidden : true,*/
        layout: "/admin",
        site_admin_hide: false,
        support_admin_hide: false,
        system_admin_hide: false,
        billing_admin_hide: false,
        location_admin_hide: false,
        location_super_hide: false,
        counter_super_hide: false,
        counter_user_hide: true,
        main_display_user_hide: true,
        counter_display_user_hide: true,
        dispenser_user_hide: true,
      },
      {
        path: "/Report2Percentile",
        name: "Percentile",
        icon: "nc-icon nc-chart-bar-32",
        component: Report2Percentile,
        righticon: "nc-icon rotate-180",
        /*hidden : true,*/
        layout: "/admin",
        site_admin_hide: false,
        support_admin_hide: false,
        system_admin_hide: false,
        billing_admin_hide: false,
        location_admin_hide: false,
        location_super_hide: false,
        counter_super_hide: false,
        counter_user_hide: true,
        main_display_user_hide: true,
        counter_display_user_hide: true,
        dispenser_user_hide: true,
      },
      {
        path: "/Report3Mean",
        name: "Mean",
        icon: "nc-icon nc-chart-bar-32",
        component: Report3Mean,
        righticon: "nc-icon rotate-180",
        /*hidden : true,*/
        layout: "/admin",
        site_admin_hide: false,
        support_admin_hide: false,
        system_admin_hide: false,
        billing_admin_hide: false,
        location_admin_hide: false,
        location_super_hide: true,
        counter_super_hide: false,
        counter_user_hide: true,
        main_display_user_hide: true,
        counter_display_user_hide: true,
        dispenser_user_hide: true,
      },
      {
        path: "/Report4CustomerFeedback",
        name: "Customer Feedback",
        icon: "nc-icon nc-chart-bar-32",
        component: Report4CustomerFeedback,
        righticon: "nc-icon rotate-180",
        /*hidden : true,*/
        layout: "/admin",
        site_admin_hide: false,
        support_admin_hide: false,
        system_admin_hide: false,
        billing_admin_hide: false,
        location_admin_hide: false,
        location_super_hide: false,
        counter_super_hide: false,
        counter_user_hide: true,
        main_display_user_hide: true,
        counter_display_user_hide: true,
        dispenser_user_hide: true,
      },
      {
        path: "/Report5DetailDownload",
        name: "Detail Download",
        icon: "nc-icon nc-chart-bar-32",
        component: Report5DetailDownload,
        righticon: "nc-icon rotate-180",
        /*hidden : true,*/
        layout: "/admin",
        site_admin_hide: false,
        support_admin_hide: false,
        system_admin_hide: false,
        billing_admin_hide: false,
        location_admin_hide: false,
        location_super_hide: false,
        counter_super_hide: false,
        counter_user_hide: true,
        main_display_user_hide: true,
        counter_display_user_hide: true,
        dispenser_user_hide: true,
      },
      {
        path: "/Report6DetailDownloadCSVFormat",
        name: "Detail Download (CSV Format)",
        icon: "nc-icon nc-chart-bar-32",
        component: Report6DetailDownloadCSVFormat,
        righticon: "nc-icon rotate-180",
        /*hidden : true,*/
        layout: "/admin",
        site_admin_hide: false,
        support_admin_hide: false,
        system_admin_hide: false,
        billing_admin_hide: false,
        location_admin_hide: false,
        location_super_hide: false,
        counter_super_hide: false,
        counter_user_hide: true,
        main_display_user_hide: true,
        counter_display_user_hide: true,
        dispenser_user_hide: true,
      },
    ],
  },
  // {
  //     path: "/analytics",
  //     name: "Analytics",
  //     icon: "nc-icon nc-chart-bar-32",
  //     component: "",
  //     layout: "/admin",
  //     site_admin_hide: false,
  //     support_admin_hide: false,
  //     system_admin_hide: false,
  //     billing_admin_hide: false,
  //     location_admin_hide: false,
  //     location_super_hide: false,
  //     counter_super_hide: false,
  //     counter_user_hide: true,
  //     main_display_user_hide: true,
  //     counter_display_user_hide: true,
  //     dispenser_user_hide: true,
  // },
  {
    path: "/locations",
    name: "Locations",
    icon: "nc-icon nc-pin-3",
    component: Locations,
    layout: "/admin",
    site_admin_hide: false,
    support_admin_hide: false,
    system_admin_hide: false,
    billing_admin_hide: true,
    location_admin_hide: false,
    location_super_hide: true,
    counter_super_hide: true,
    counter_user_hide: true,
    main_display_user_hide: true,
    counter_display_user_hide: true,
    dispenser_user_hide: true,
  },
  // {
  //   path: "/Test778",
  //   name: "Test778",
  //   icon: "nc-icon nc-atom",
  //   component: Test778,
  //   layout: "/admin",
  //   site_admin_hide: false,
  //   support_admin_hide: false,
  //   system_admin_hide: true,
  //   billing_admin_hide: true,
  //   location_admin_hide: true,
  //   location_super_hide: true,
  //   counter_super_hide: true,
  //   counter_user_hide: true,
  //   main_display_user_hide: true,
  //   counter_display_user_hide: true,
  //   dispenser_user_hide: true,
  // },
  {
    path: "/main_location/add/:id",
    name: "mainLocationAdd",
    component: MainLocationAdd,
    layout: "/admin",
    hidden: true,
  },
  {
    path: "/main_location/edit/:id",
    name: "mainLocationEdit",
    component: MainLocationEdit,
    layout: "/admin",
    hidden: true,
  },
  {
    path: "/sub_location/add/:id",
    name: "subLocationAdd",
    component: SubLocationAdd,
    layout: "/admin",
    hidden: true,
  },
  {
    path: "/sub_location/add_select_package",
    name: "subLocationAdd",
    component: SubLocationAddSelectPackage,
    layout: "/admin",
    hidden: true,
  },
  // {
  //     path: "/sub_location/add_request_quotation",
  //     name: "subLocationAdd",
  //     component: SubLocationAddRequestQuotation,
  //     layout: "/admin",
  //     hidden: true
  // },
  {
    path: "/sub_location/edit/:id",
    name: "subLocationEdit",
    component: SubLocationEdit,
    layout: "/admin",
    hidden: true,
  },
  {
    path: "/sub_location/request_quotation/:id",
    name: "subLocationRequestQuotation",
    component: SubLocationRequestQuotation,
    layout: "/admin",
    hidden: true,
  },
  {
    path: "/sub_location/manage_package/:id",
    name: "subLocationManagePackage",
    component: SubLocationManagePackage,
    layout: "/admin",
    hidden: true,
  },
  {
    path: "/services",
    name: "Services",
    icon: "nc-icon nc-settings-gear-65",
    component: Services,
    layout: "/admin",
    site_admin_hide: false,
    support_admin_hide: false,
    system_admin_hide: false,
    billing_admin_hide: true,
    location_admin_hide: false,
    location_super_hide: false,
    counter_super_hide: true,
    counter_user_hide: true,
    main_display_user_hide: true,
    counter_display_user_hide: true,
    dispenser_user_hide: true,
  },
  {
    path: "/service/add",
    name: "serviceAdd",
    component: ServiceAdd,
    layout: "/admin",
    hidden: true,
  },
  {
    path: "/service/edit/:id",
    name: "serviceEdit",
    component: ServiceEdit,
    layout: "/admin",
    hidden: true,
  },
  {
    collapse: true,
    name: "Service Requests",
    icon: "nc-icon nc-paper",
    state: "serviceRequestsCollapse",
    layout: "/admin",
    site_admin_hide: false,
    support_admin_hide: false,
    system_admin_hide: false,
    billing_admin_hide: true,
    location_admin_hide: false,
    location_super_hide: false,
    counter_super_hide: false,
    counter_user_hide: true,
    main_display_user_hide: true,
    counter_display_user_hide: true,
    dispenser_user_hide: true,
    views: [
      {
        path: "/approval_requests",
        name: "Approval Requests",
        icon: "nc-icon nc-paper",
        component: ApprovalRequests,
        righticon: "nc-icon rotate-180",
        /*hidden : true,*/
        layout: "/admin",
        site_admin_hide: false,
        support_admin_hide: false,
        system_admin_hide: false,
        billing_admin_hide: true,
        location_admin_hide: false,
        location_super_hide: false,
        counter_super_hide: false,
        counter_user_hide: true,
        main_display_user_hide: true,
        counter_display_user_hide: true,
        dispenser_user_hide: true,
      },
      {
        path: "/other_requests",
        name: "Other Requests",
        icon: "nc-icon nc-paper",
        component: ServiceRequests,
        righticon: "nc-icon rotate-180",
        /*hidden : true,*/
        layout: "/admin",
        site_admin_hide: false,
        support_admin_hide: false,
        system_admin_hide: false,
        billing_admin_hide: true,
        location_admin_hide: false,
        location_super_hide: false,
        counter_super_hide: false,
        counter_user_hide: true,
        main_display_user_hide: true,
        counter_display_user_hide: true,
        dispenser_user_hide: true,
      },
      {
        path: "/request_notifications",
        name: "Request Notifications",
        icon: "nc-icon nc-paper",
        component: RequestNotifications,
        righticon: "nc-icon rotate-180",
        /*hidden : true,*/
        layout: "/admin",
        site_admin_hide: false,
        support_admin_hide: false,
        system_admin_hide: false,
        billing_admin_hide: true,
        location_admin_hide: false,
        location_super_hide: false,
        counter_super_hide: false,
        counter_user_hide: true,
        main_display_user_hide: true,
        counter_display_user_hide: true,
        dispenser_user_hide: true,
      },
      {
        path: "/available_appointments",
        name: "Available Appointments",
        icon: "nc-icon nc-paper",
        component: AvailableAppointments,
        righticon: "nc-icon rotate-180",
        /*hidden : true,*/
        layout: "/admin",
        site_admin_hide: false,
        support_admin_hide: false,
        system_admin_hide: false,
        billing_admin_hide: true,
        location_admin_hide: false,
        location_super_hide: false,
        counter_super_hide: false,
        counter_user_hide: true,
        main_display_user_hide: true,
        counter_display_user_hide: true,
        dispenser_user_hide: true,
      },

    ]
  },
  // {
  //     path: "/token_details",
  //     name: "Token Details",
  //     icon: "nc-icon nc-paper",
  //     component: TokenDetails,
  //     layout: "/admin",
  //     site_admin_hide: false,
  //     support_admin_hide: false,
  //     system_admin_hide: false,
  //     billing_admin_hide: true,
  //     location_admin_hide: false,
  //     location_super_hide: true,
  //     counter_super_hide: true,
  //     counter_user_hide: true,
  //     main_display_user_hide: true,
  //     counter_display_user_hide: true,
  //     dispenser_user_hide: true,
  // },
  {
    path: "/counters",
    name: "Counters",
    icon: "nc-icon nc-bank",
    component: Counters,
    layout: "/admin",
    site_admin_hide: false,
    support_admin_hide: false,
    system_admin_hide: false,
    billing_admin_hide: true,
    location_admin_hide: false,
    location_super_hide: false,
    counter_super_hide: false,
    counter_user_hide: false,
    main_display_user_hide: true,
    counter_display_user_hide: true,
    dispenser_user_hide: true,
  },
  {
    path: "/counter/add",
    name: "counterAdd",
    component: CounterAdd,
    layout: "/admin",
    hidden: true,
  },
  {
    path: "/counter/run/:id",
    name: "counterRun",
    component: CounterRun,
    layout: "/admin",
    hidden: true, //firdous test
    /* site_admin_hide: false,
    support_admin_hide: false,
    system_admin_hide: false,
    billing_admin_hide: true,
    location_admin_hide: false,
    location_super_hide: false,
    counter_super_hide: false,
    counter_user_hide: false,
    main_display_user_hide: true,
    counter_display_user_hide: true,
    dispenser_user_hide: true, */
  },
  // {
  //     path: "/counter/runn/:id",
  //     name: "counterRunN",
  //     component: CounterRunN,
  //     layout: "/admin",
  //     hidden: true
  // },
  {
    path: "/counter/edit/:id",
    name: "counterEdit",
    component: CounterEdit,
    layout: "/admin",
    hidden: true,
  },
  // {
  //     path: "/counter_displays",
  //     name: "Counter Displays",
  //     icon: "nc-icon nc-box-2",
  //     component: CounterDisplays,
  //     layout: "/admin",
  //     counter_user_hide: true,
  //     counter_super_hide: true,
  //     main_display_user_hide: true,
  //     dispenser_user_hide: true,
  //     billing_admin_hide: true
  // },
  {
    path: "/calendars",
    name: "Calendars",
    icon: "nc-icon nc-calendar-60",
    component: Calendars,
    layout: "/admin",
    site_admin_hide: false,
    system_admin_hide: false,
    location_admin_hide: false,
    location_super_hide: false,
    support_admin_hide: true,
    billing_admin_hide: true,
    counter_super_hide: true,
    counter_user_hide: true,
    main_display_user_hide: true,
    counter_display_user_hide: true,
    dispenser_user_hide: true,
  },
  {
    path: "/main_display",
    name: "Main Display",
    icon: "nc-icon nc-tablet-2",
    component: MainDisplay,
    layout: "/admin",
    site_admin_hide: false,
    support_admin_hide: false,
    system_admin_hide: false,
    billing_admin_hide: true,
    location_admin_hide: false,
    location_super_hide: true,
    counter_super_hide: true,
    counter_user_hide: true,
    main_display_user_hide: false,
    counter_display_user_hide: true,
    dispenser_user_hide: true,
  },
  {
    path: "/display/add",
    name: "displayAdd",
    // component: DisplayAdd,
    component: DisplayEdit,
    layout: "/admin",
    hidden: true,
  },
  {
    path: "/display/edit/:id",
    name: "displayAdd",
    component: DisplayEdit,
    layout: "/admin",
    hidden: true,
  },
  {
    path: "/display/run/:id",
    name: "displayRun",
    component: DisplayRun,
    layout: "/admin",
    hidden: true,
  },
  {
    path: "/Kiosks",
    name: "Kiosk",
    icon: "nc-icon nc-laptop",
    component: Kiosks,
    layout: "/admin",
    site_admin_hide: false,
    support_admin_hide: false,
    system_admin_hide: false,
    billing_admin_hide: false,
    location_admin_hide: false,
    location_super_hide: true,
    counter_super_hide: true,
    counter_user_hide: true,
    main_display_user_hide: true,
    counter_display_user_hide: true,
    dispenser_user_hide: true,
  },
  {
    path: "/kiosk/add",
    name: "dispenserAdd",
    component: KioskAddEdit,
    layout: "/admin",
    hidden: true,
  },
  //   {
  //     path: "/dispenser/basicEdit/:id",
  //     name: "dispenserBasicEdit",
  //     component: DispenserBasicEdit,
  //     layout: "/admin",
  //     hidden: true,
  //   },
  //   {
  //     path: "/dispenser/advanceEdit/:id",
  //     name: "dispenserAdvanceEdit",
  //     component: DispenserAdvanceEdit,
  //     layout: "/admin",
  //     hidden: true,
  //   },
  {
    path: "/kiosk/edit/:id",
    name: "dispenserEdit",
    component: KioskAddEdit,
    layout: "/admin",
    hidden: true,
  },
  {
    path: "/kioskPrint/edit/:id",
    name: "dispenserPrintEdit",
    component: KioskPrintEdit,
    layout: "/admin",
    hidden: true,
  },
  {
    path: "/users",
    name: "Users",
    icon: "nc-icon nc-single-02",
    component: Users,
    layout: "/admin",
    site_admin_hide: false,
    support_admin_hide: false,
    system_admin_hide: false,
    billing_admin_hide: true,
    location_admin_hide: false,
    location_super_hide: true,
    counter_super_hide: true,
    counter_user_hide: true,
    main_display_user_hide: true,
    counter_display_user_hide: true,
    dispenser_user_hide: true,
  },
  {
    path: "/user/edit/:id",
    name: "userEdit",
    component: UserEdit,
    layout: "/admin",
    hidden: true,
  },
  {
    path: "/user/add",
    name: "userAdd",
    component: UserAdd,
    layout: "/admin",
    hidden: true,
  },
  {
    path: "/downloads",
    name: "Downloads",
    icon: "nc-icon nc-cloud-download-93",
    component: Downloads,
    layout: "/admin",
  },
  // {
  //   collapse: true,
  //   name: "Downloads",
  //   icon: "nc-icon nc-cloud-download-93",
  //   layout: "/admin",
  //   site_admin_hide: false,
  //   billing_admin_hide: true,
  //   location_admin_hide: true,
  //   location_super_hide: true,
  //   counter_super_hide: true,
  //   counter_user_hide: true,
  //   main_display_user_hide: true,
  //   counter_display_user_hide: true,
  //   dispenser_user_hide: true,
  //   views:[
  // {
  //   sub_menu:true,
  //   path: "/downloads",
  //   name: "Downloads",
  //   icon: "nc-icon nc-cloud-download-93",
  //   component: Downloads,
  //   layout: "/admin",
  // },
  {
    path: "/download/add",
    name: "Downloads Add",
    component: addEdit,
    layout: "/admin",
    hidden: true
  },
  //   ]
  // },
  {
    path: "/download/edit/:id",
    name: "Downloads Edit",
    component: addEdit,
    layout: "/admin",
    hidden: true
  },
  // {
  //     path: "/statistics",
  //     name: "Statistics",
  //     icon: "nc-icon nc-chart-bar-32",
  //     component: Statistics,
  //     layout: "/admin",
  //     counter_user_hide: true,
  //     counter_super_hide: true,
  //     main_display_user_hide: true,
  //     counter_display_user_hide: true,
  //     dispenser_user_hide: true
  // },
  {
    path: "/account_settings",
    name: "Account Settings",
    icon: "nc-icon nc-circle-10",
    component: AccountSettings,
    layout: "/admin",
  },
  {
    collapse: true,
    name: "Billing",
    icon: "nc-icon nc-money-coins",
    state: "billingCollapse",
    site_admin_hide: false,
    support_admin_hide: false,
    system_admin_hide: false,
    billing_admin_hide: false,
    location_admin_hide: false,
    location_super_hide: true,
    counter_super_hide: true,
    counter_user_hide: true,
    main_display_user_hide: true,
    counter_display_user_hide: true,
    dispenser_user_hide: true,
    views: [
      {
        sub_menu: true,
        path: "/billing/alerts",
        name: "Billing Alerts",
        icon: "nc-icon nc-money-coins",
        component: BillingAlerts,
        righticon: "nc-icon rotate-180",
        /*hidden : true,*/
        layout: "/admin",
        site_admin_hide: false,
        support_admin_hide: false,
        system_admin_hide: false,
        billing_admin_hide: false,
        location_admin_hide: false,
        location_super_hide: true,
        counter_super_hide: true,
        counter_user_hide: true,
        main_display_user_hide: true,
        counter_display_user_hide: true,
        dispenser_user_hide: true,
      },
      {
        sub_menu: true,
        path: "/billing/online_subscriptions",
        name: "Biling Online Subscriptions",
        icon: "nc-icon nc-money-coins",
        component: BillingOnlineSubscriptions,
        righticon: "nc-icon rotate-180",
        /*hidden : true,*/
        layout: "/admin",
        site_admin_hide: false,
        support_admin_hide: false,
        system_admin_hide: false,
        billing_admin_hide: false,
        location_admin_hide: false,
        location_super_hide: true,
        counter_super_hide: true,
        counter_user_hide: true,
        main_display_user_hide: true,
        counter_display_user_hide: true,
        dispenser_user_hide: true,
      },
      {
        sub_menu: true,
        path: "/billing/online_payment_history",
        name: "Billing Online Payment History",
        icon: "nc-icon nc-money-coins",
        component: BillingOnlinePaymentHistory,
        righticon: "nc-icon rotate-180",
        /*hidden : true,*/
        layout: "/admin",
        site_admin_hide: false,
        support_admin_hide: false,
        system_admin_hide: false,
        billing_admin_hide: false,
        location_admin_hide: false,
        location_super_hide: true,
        counter_super_hide: true,
        counter_user_hide: true,
        main_display_user_hide: true,
        counter_display_user_hide: true,
        dispenser_user_hide: true,
      },
      {
        sub_menu: true,
        path: "/billing/manual_subscriptions",
        name: "Billing Manual Subscriptions",
        icon: "nc-icon nc-money-coins",
        component: BillingManualSubscriptions,
        righticon: "nc-icon rotate-180",
        /*hidden : true,*/
        layout: "/admin",
        site_admin_hide: false,
        support_admin_hide: false,
        system_admin_hide: false,
        billing_admin_hide: false,
        location_admin_hide: false,
        location_super_hide: true,
        counter_super_hide: true,
        counter_user_hide: true,
        main_display_user_hide: true,
        counter_display_user_hide: true,
        dispenser_user_hide: true,
      },
      {
        sub_menu: true,
        path: "/billing/manual_payment_history",
        name: "Billing Manual Payment History",
        icon: "nc-icon nc-money-coins",
        component: BillingManualPaymentHistory,
        righticon: "nc-icon rotate-180",
        /*hidden : true,*/
        layout: "/admin",
        site_admin_hide: false,
        support_admin_hide: false,
        system_admin_hide: false,
        billing_admin_hide: false,
        location_admin_hide: false,
        location_super_hide: true,
        counter_super_hide: true,
        counter_user_hide: true,
        main_display_user_hide: true,
        counter_display_user_hide: true,
        dispenser_user_hide: true,
      },
      {
        sub_menu: true,
        path: "billing/manual_payment_invoice/add",
        name: "billingManualPaymentInvoiceAdd",
        component: BillingManualPaymentInvoiceAdd,
        layout: "/admin",
        hidden: true,
      },
      {
        sub_menu: true,
        path: "billing/manual_payment_invoice/edit/:id",
        name: "billingManualPaymentInvoiceEdit",
        component: BillingManualPaymentInvoiceEdit,
        layout: "/admin",
        hidden: true,
      },
      {
        sub_menu: true,
        path: "/billing/manual_document_attachments",
        name: "Billing Manual Document Attachments",
        icon: "nc-icon nc-money-coins",
        component: BillingManualDocumentAttachments,
        righticon: "nc-icon rotate-180",
        /*hidden : true,*/
        layout: "/admin",
        site_admin_hide: false,
        support_admin_hide: false,
        system_admin_hide: false,
        billing_admin_hide: false,
        location_admin_hide: false,
        location_super_hide: true,
        counter_super_hide: true,
        counter_user_hide: true,
        main_display_user_hide: true,
        counter_display_user_hide: true,
        dispenser_user_hide: true,
      },
      {
        sub_menu: true,
        path: "/billing/manual_document_attachment/add",
        name: "billingDocumentAdd",
        component: BillingManualDocumentAttachmentAdd,
        layout: "/admin",
        hidden: true,
      },
      {
        sub: true,
        path: "/billing/manual_document_attachment/edit/:id",
        name: "billingDocumentEdit",
        component: BillingManualDocumentAttachmentEdit,
        layout: "/admin",
        hidden: true,
      },
      // {
      //     path: "/manual_payment/document/add",
      //     name: "billingManualPaymentDocumentAdd",
      //     component: BillingDocumentAdd,
      //     layout: "/admin",
      //     hidden: true
      // },
    ],
  },
  {
    path: "/logout",
    name: "Logout",
    component: null,
  },
];

export default routes;