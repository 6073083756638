import React from "react";
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
// javascript plugin used to create scrollbars on windows
// import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch } from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import AdminNavbar from "./AdminNavbar.jsx";
import Sidebar from "./Sidebar.jsx";
// import FixedPlugin from "../../../components/FixedPlugin/FixedPlugin.jsx";
import Firebase from "firebase";
import config from "../../../config";
import routes from "../../../routes.js";
import ReactBSAlert from "react-bootstrap-sweetalert";
// var ps;

class Admin extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };
  constructor(props) {
    super(props);
    const { cookies } = props;
    if (!Firebase.apps.length) {
      Firebase.initializeApp(config);
    }
    let loginRedirect = () => {
      // if (!this.props.location.pathname.includes("display/run"))
        this.props.history.push("/login");
    };

    if (localStorage.getItem("auth_info")) {
      let running_dispenser = localStorage.getItem("running_dispenser");
      if (running_dispenser) {
        this.props.history.push("/kiosk/run/" + running_dispenser);
      } else if (
        this.props.history.action === "PUSH" ||
        this.props.location.pathname === "/"
      ) {
        this.props.history.push("/welcome");
      }
    } else {
      if (cookies.get("auth_info")) {
        Firebase.firestore()
          .collection("Customers")
          .doc(cookies.get("auth_info"))
          .get()
          .then(function (customer) {
            if (customer.exists) {
              localStorage.setItem(
                "auth_info",
                JSON.stringify({
                  customer_id: customer.id,
                  role: customer.data().Role,
                })
              );
            } else {
              loginRedirect();
            }
          })
          .catch(function (error) {
            loginRedirect();
          });
      } else {
        loginRedirect();
      }
    }

    this.state = {
      backgroundColor: "brown",
      activeColor: "success",
      sidebarMini: false,
      alert: null,
    };
  }
  // reset = (email)=>
  // Firebase.auth().sendPasswordResetEmail(email)
  // .then(function (user) {
  //   _this.setState({ loading: false });
  //   _this.notifyMessage("tc", 2, "Password reset success, please check the mail (including spam folder)");

  // }).catch(function (e) {
  //   _this.setState({ loading: false });
  //   if(e=='not_exitence'||e?.code=='auth/user-not-found')
  //       _this.notifyMessage("tc", 3, "This email does not exist!");
  //   else _this.notifyMessage("tc", 3, "Network error!");
  // })

  componentDidMount() {
    // if (navigator.platform.indexOf("Win") > -1) {
    //   document.documentElement.className += " perfect-scrollbar-on";
    //   document.documentElement.classList.remove("perfect-scrollbar-off");
    //   ps = new PerfectScrollbar(this.refs.mainPanel);
    // }
    const auth_info = JSON.parse(localStorage.getItem("auth_info") || "{}"),
      _this = this;

      if(auth_info?.message?.length>0){
        if(auth_info?.messageShowOnce == true){
          this.notifyMessage("tc", 2, auth_info.message);
          delete auth_info.message
          delete auth_info.messageShowOnce
          localStorage.setItem('auth_info',JSON.stringify(auth_info))

        }
      }


    const reset = () => {
      return Firebase.auth()
        .sendPasswordResetEmail(auth_info?.email)
        .then(function (user) {
          _this.setState({ alert: null });
          return Firebase.firestore()
            .collection("Web_App_Users")
            .doc(auth_info?.email)
            .update({ First_Time_Login: false })
            .then(function () {
              localStorage.setItem(
                "auth_info",
                JSON.stringify({ ...auth_info, first_time_login: false })
              );
              _this.setState({
                alert: (
                  <ReactBSAlert
                    warning
                    title="Thank you for your patience. Please check your email (check Spam folder if you haven't received the message in your Inbox) for the password reset link."
                    onConfirm={() => _this.setState({ alert: null })}
                    // onCancel={() => this.setState({ alert: null })}
                    confirmBtnBsStyle="info"
                    // cancelBtnBsStyle="danger"
                    confirmBtnText="Close"
                    // cancelBtnText="Later"
                    // showCancel
                  ></ReactBSAlert>
                ),
              });
            })
            .catch(function (err) {
              // _this.setState({loading: false});
              // _this.notifyMessage("tc", 3, "Network error!");
              console.log("handleSubmit NetworkError2==>", err);
              throw err;
            });
        })
        .catch(function (e) {
          console.log("handleSubmit NetworkError1==>", e);
          _this.setState({
            alert: (
              <ReactBSAlert
                warning
                title="An error happened, please try again."
                onConfirm={() => _this.setState({ alert: null })}
                // onCancel={() => this.setState({ alert: null })}
                confirmBtnBsStyle="info"
                // cancelBtnBsStyle="danger"
                confirmBtnText="Reset Pass"
                // cancelBtnText="Later"
                // showCancel
              ></ReactBSAlert>
            ),
          });
        });
    };

    if (
      JSON.parse(localStorage.getItem("auth_info") || '{}')?.first_time_login ==
      true
    ) {
      this.setState({
        alert: (
          <ReactBSAlert
            warning
            title="Welcome to AntQueue web application. You must set a new password to proceed. Please check your email (check Spam folder if you haven't received the message in your Inbox)."
            onConfirm={reset}
            // onCancel={() => this.setState({ alert: null })}
            confirmBtnBsStyle="info"
            // cancelBtnBsStyle="danger"
            confirmBtnText="Reset Pass"
            // cancelBtnText="Later"
            // showCancel
          ></ReactBSAlert>
        ),
      });
    }
  }
  componentWillUnmount() {
    // if (navigator.platform.indexOf("Win") > -1) {
    //   // ps.destroy();
    //   document.documentElement.className += " perfect-scrollbar-off";
    //   document.documentElement.classList.remove("perfect-scrollbar-on");
    // }
  }
  componentDidUpdate(e) {
    // if (e.history.action === "PUSH") {
    //   document.documentElement.scrollTop = 0;
    //   document.scrollingElement.scrollTop = 0;
    //   this.refs.mainPanel.scrollTop = 0;
    // }
  }
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.path}
            component={prop.component} //render={props => <prop.component {...props}/>}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  handleActiveClick = (color) => {
    this.setState({ activeColor: color });
  };
  handleBgClick = (color) => {
    this.setState({ backgroundColor: color });
  };
  handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      this.setState({ sidebarMini: false });
    } else {
      this.setState({ sidebarMini: true });
    }
    document.body.classList.toggle("sidebar-mini");
  };
  notifyMessage = (place, color, text) => {
    var type;
    switch (color) {
        case 1:
            type = "primary";
            break;
        case 2:
            type = "success";
            break;
        case 3:
            type = "danger";
            break;
        case 4:
            type = "warning";
            break;
        case 5:
            type = "info";
            break;
        default:
            break;
    }

    var options = {};
    options = {
        place: place,
        message: (
            <div className="text-md-center">
                <div>
                    <b>{text}</b>
                </div>
            </div>
        ),
        type: type,
        icon: "now-ui-icons ui-1_bell-53",
        autoDismiss: 3
    };
    if (options !== null) { this.notificationAlert.notificationAlert(options) }
};
  render() {
    return (
      <div className="wrapper">
        <NotificationAlert ref={(ref) => this.notificationAlert = ref} />

        <Sidebar
          {...this.props}
          routes={routes}
          bgColor={this.state.backgroundColor}
          activeColor={this.state.activeColor}
        />
        <div className="main-panel" ref="mainPanel">
          {this.state.alert}
          <AdminNavbar {...this.props} handleMiniClick={this.handleMiniClick} />
          <Switch>{this.getRoutes(routes)}</Switch>
        </div>
        {/*<FixedPlugin*/}
        {/*bgColor={this.state.backgroundColor}*/}
        {/*activeColor={this.state.activeColor}*/}
        {/*sidebarMini={this.state.sidebarMini}*/}
        {/*handleActiveClick={this.handleActiveClick}*/}
        {/*handleBgClick={this.handleBgClick}*/}
        {/*handleMiniClick={this.handleMiniClick}*/}
        {/*/>*/}
      </div>
    );
  }
}

export default withCookies(Admin);
