import React from "react";
import LoadingOverlay from "react-loading-overlay";
import Firebase from "firebase";
import NotificationAlert from "react-notification-alert";
import config from "../../../config";
import {
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Collapse, Button
} from "reactstrap";

class Welcome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            open: false,
            default_graphics_welcome_screen_questions: '',
            subscribed_to_a_package_before: false,
        };
    }
    componentDidMount() {
        let _this = this;
        let customer_id = JSON.parse(localStorage.getItem('auth_info'))?.customer_id||'';
        _this.setState({customer_id: customer_id});
        // ---------- Load Default Icon URL ---------- //
        Firebase.firestore().collection('System_Config').doc('Default_Values').get().then(function (doc) {
            if (doc.exists) {
                // console.log(" doc.data().Packages_Icon_Path", doc.data().Packages_Icon_Path);
                _this.setState({ default_graphics_welcome_screen_questions: doc.data().Welcome_Screen_Questions_Graphic });
            }
        }).catch(function (err) {
            console.log("err ==>", err);
        });
        if(customer_id.length>0)
        Firebase.firestore().collection('Customers').doc(customer_id).get().then(function (doc) {
            if (doc.exists) {
                if (doc.data().Subscribed_To_A_Package_Before == true) {
                    _this.setState({subscribed_to_a_package_before: doc.data().Subscribed_To_A_Package_Before});
                }
            }
        }).catch(function (err) {
            console.log("err ==>", err);
        });

    }
    componentWillUnmount() {

    }
    componentDidUpdate(e) {

    }
    render() {
        let { default_graphics_welcome_screen_questions } = this.state;
        return (
            <>
                <LoadingOverlay
                    active={this.state.loading}
                    spinner
                    text='Loading'
                    className='content'
                >
                    <Row>
                        <Col md="12">
                            <Card className="" id='cardde'>
                                {/* <CardHeader style={{height:'10vh'}}>
                                    <CardTitle tag="h4">Welcome</CardTitle>
                                    </CardHeader> */}
                                <CardBody >
                                    <Row>
                                        <div className="row">
                                            <div className="col-md-12"><div className="">
                                                <div className="card-header" />
                                                <div className="card-body">
                                                    <div className="row"><div className="ml-auto mr-auto col-md-12 col-lg-12 col-xl-10">
                                                        <div className="row"><div className="col-md-12">
                                                            <div className="blockquote blockquote-primary">
                                                                <h1><a name="_Toc21530617">{/*[if !supportLists]*/}{/*[endif]*/}<span dir="LTR" /><span style={{ fontVariant: 'normal !important', textTransform: 'uppercase' }}>Welcome to Ant Queue</span></a><span style={{ fontVariant: 'normal !important', textTransform: 'uppercase' }}></span></h1>
                                                                <div style={{ color: '#1C1E29' }}>
                                                                    <p><em><b><span>Skip the wait!</span></b></em><b><span></span></b></p>
                                                                    <p><span></span></p>
                                                                    <p><strong><span>Ant Queue</span></strong><span> is an
                                                                    intelligent and cost-effective cloud-based queue management solution to
revolutionize the way your business operates</span></p>
                                                                    <p><span></span></p>
                                                                    <p><span>Ant Queue
                                                                    allows businesses to handle customer queues smartly and intuitively without 
needing any technical skills or advanced knowledge</span></p>
                                                                    <p><span></span></p>
                                                                    <p><strong><span><b>Key features of Ant Queue Platform:</b></span></strong><span></span></p>
                                                                    <p><span></span></p>
                                                                    <p><strong><span>1. Quick registration and setup</span></strong><span></span></p>
                                                                    <p><strong><span>2. Simple service vendor lookup</span></strong><span></span></p>
                                                                    <p><strong><span>3. Easy token creation</span></strong><span></span></p>
                                                                    <p><strong><span>4. Real-time queue updates and visually stunning statistics</span></strong><span></span></p>
                                                                    <p><strong><span>5. Intuitive interfaces for Web and Mobile applications</span></strong><span></span></p>
                                                                    <p><strong><span>6. Highly customizable</span></strong><span></span></p>
                                                                    <p><strong><span>7. Instant customer feedback </span></strong><span></span></p>
                                                                    <p><span></span></p>
                                                                    <p><span>Joining a queue
                                                                    is often one of the first customer's interactions with a business, and Ant
Queue is designed from the ground up with this in mind.</span></p>
                                                                    <p><span></span></p>
                                                                    <p><span>Ant Queue not
                                                                    only brings order to your waiting area by streamlining service flow and
                                                                    optimizing resources but also delivers consistently exceptional customer
experience.</span></p>
                                                                    <p><span></span></p>
                                                                    <p><span>Ant Queue also
                                                                    monitors data in real-time and can collect customer feedback. Our cloud-based
                                                                    software can then assess this data to enhance the performance of your agents
and services.</span></p>
                                                                </div>
                                                                <p><span></span></p>
                                                                <p className="MsoNormal"><span style={{ fontSize: '12.0pt', lineHeight: '107%', msoFareastFontFamily: '"Times New Roman"', msoBidiFontFamily: '"Century Gothic"', msoBidiThemeFont: 'minor-latin', color: '#1C1E29', msoFareastLanguage: 'EN-GB' }}>More Information: <br />
                                                                    <a href='https://www.antqueue.com' target='_blank' rel="noopener noreferrer">https://www.antqueue.com</a></span></p>
                                                                <p className="MsoNormal"></p>
                                                                <p className="MsoNormal"></p>
                                                                <div className="header text-center">
                                                                    {/* <div style={{ display: "flex", flexDirection: "column", alignItems: "left" }}>
<lottie-player src="/website-image-lottie-data.json"  background="transparent"  speed="1"  style={{width: "200px", height: "200px"}} loop autoplay></lottie-player>
</div> */}
                                                                    <div className="row">
                                                                        <div style={{ fontSize: '30px', color: '#3c424f', paddingTop: '150px', paddingLeft: '16px' }}>
                                                                            Any questions?
<div style={{ fontSize: '12px', color: '#3c424f', paddingLeft: '16px' }}>
                                                                                For more information, please visit our <button type="button" className="btn btn-sm btn-success btn btn-secondary" onClick={() => { window.open('https://antqueue.tawk.help/', '_blank') }}>Support Center</button>
                                                                            </div>
                                                                        </div>
                                                                        {default_graphics_welcome_screen_questions === '' ?
                                                                            (null) :
                                                                            (<div className="text-center" style={{ paddingTop: '3em' }}>
                                                                                <img alt="..." height="300px" src={default_graphics_welcome_screen_questions} />
                                                                            </div>)}
                                                                    </div>
                                                                </div>

                                                                { this.state.subscribed_to_a_package_before ?
                                                                <div>
                                                                    <div aria-multiselectable="true" className="card-collapse col-md-12" id="accordion" role="tablist">
                                                                        <div>
                                                                            <div className="row">
                                                                                <div className="top-margin-custom overflow-ellipsis col-lg-6 col-xl-4"><a href="#" className="custom-nav"><i className={this.state.open ? "nc-icon nc-minimal-up" : "nc-icon nc-minimal-down"} /></a>
                                                                                    <a
                                                                                        aria-expanded={this.state.open}
                                                                                        href="#"
                                                                                        data-parent="#accordion"
                                                                                        data-toggle="collapse"
                                                                                        onClick={e => { e.preventDefault(); this.setState({ open: this.state.open ? false : true }) }}
                                                                                    >
                                                                                        User Guide
    </a>
                                                                                </div>
                                                                                <div className="padding-custom col-lg-2 col-xl-2">
                                                                                    <button type="button" className="btn btn-sm btn-success btn btn-secondary btn-block" onClick={() => {
                                                                                        window.frames["printf"].focus();
                                                                                        window.frames["printf"].print();
                                                                                    }}>Print</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div> 

                                                                    <Collapse isOpen={this.state.open} style={{ height: '76vh' }}>
                                                                        <Row>
                                                                            <iframe name='printf' src="https://app.antqueue.com/dynamic_content/AQ101.htm" frameBorder="0" style={{ width: '100%', height: '76vh', overflow: 'visible' }}></iframe>
                                                                        </Row>
                                                                    </Collapse>
                                                                </div>
                                                                : null}

                                                            </div></div>
                                                        </div></div>
                                                    </div>
                                                </div>
                                            </div></div>
                                        </div>
                                    </Row>

                                    {/*<button style="position:absolute;bottom:10%;right:10%;transform: rotate(-90deg);background:black;color:white;border-radius: 18rem;padding: 0.5rem;width: 4rem;height: 4rem;font-size: 1.8rem;text-align: center;border: transparent;font-family: fantasy;" onclick="window.scrollTo(0,0)">&gt;</button>*/}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </LoadingOverlay>
            </>
        );
    }
}

export default Welcome;
