import React from "react";
import { NavLink } from "react-router-dom";
import { Nav, Collapse } from "reactstrap";
//import Switch from "react-bootstrap-switch";
// import logo from "../../../assets/img/favicon.png";
// import logo from "../../../assets/img/antqueue-animated-logo.gif";
import firebase from 'firebase/app';
import 'firebase/auth';
// javascript plugin used to create scrollbars on windows
// import PerfectScrollbar from "perfect-scrollbar";

// var ps;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getCollapseStates(props.routes);
    this.state = {
      collapse_item: false,
      // analyticsCollapse: false,
      // billingCollapse: false,
      // bDashboard: false,
      // bDash: false,
      // bClick: false,
    }
  }
  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = routes => {
    let initialState = {};
    routes.map((prop, key) => {
      // alert(JSON.stringify(prop))
      // alert(key)
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes) {
    // for (let i = 0; i < routes.length; i++) {
    //   if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
    //     return true;
    //   } else if (window.location.pathname.indexOf(routes[i].path) !== -1) {
    //     return true;
    //   }
    // }
    // return false;
  }
  // this function creates the links and collapses that appear in the sidebar (left menu)
  handleClick(s) {
    // if (s === "Analytics") {
    //   this.setState({ analyticsCollapse: !this.state.analyticsCollapse });
    // }

    // if (s === "Billing") {
    //   this.setState({ billingCollapse: !this.state.billingCollapse });
    // }

    // if( s === "Reports"){
    //   this.setState({analyticsCollapse : !this.state.analyticsCollapse});
    // }
    // if( s === "Dashboards"){
    //   this.setState({bDash : !this.state.bDash});
    //   console.log("dashboards yep");
    // }
  }

  logout = () => {
    // console.log("logout....");
    firebase.auth().signOut();
  }

  createLinks = routes => {
    let _this = this;
    return routes.map((prop, key) => {
      // console.log("prop.name", prop.name, "_this.hideByRole(prop)", _this.hideByRole(prop), "prop", prop);
      if (prop.redirect) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !_this.state[prop.state];

        return (
          <li
            hidden={_this.hideByRole(prop)}
            className={_this.getCollapseInitialState(prop.views) ? "active" : ""}
            key={key}
          >
            <a
              href="#pablo"
              data-toggle="collapse"
              aria-expanded={_this.state[prop.state]}
              onClick={e => {
                e.preventDefault();
                _this.setState(st);
              }}
            >
              {prop.icon !== undefined ? (
                <>
                  <i className={prop.icon} />
                  <p>
                    {prop.name}
                    <b className="caret" />
                  </p>
                </>
              ) : (
                  <>
                    <span className="sidebar-mini-icon">{prop.mini}</span>
                    <span className="sidebar-normal">
                      {prop.name}
                      <b className="caret" />
                    </span>
                  </>
                )}
            </a>
            <Collapse isOpen={_this.state[prop.state]}>
              <ul className="nav">{_this.createLinks(prop.views)}</ul>
            </Collapse>
          </li>
        );
      }
      if (prop.component == null) {
        return (
          <li className={_this.activeRoute(prop.path)} key={key} onClick={() => _this.logout()}><NavLink to={prop.path} activeClassName=""><i className="nc-icon nc-button-power"></i><p>Logout</p></NavLink></li>
        );
      }
      // if( prop.name === " Reports")
      //   _this.state.analyticsCollapse = true;
      // else _this.state.analyticsCollapse = false;

      // if (_this.state.analyticsCollapse) {
      if (prop?.sub_menu||prop.name === 'Detail Download (CSV Format)' || prop.name === "Request Notifications" || prop.name === "Available Appointments" || prop.name === "Other Requests" || prop.name === "Approval Requests" || prop.name === "Segmentation" || prop.name === "Percentile" || prop.name === "Mean" || prop.name === "Customer Feedback" || prop.name === "Detail Download"
        || prop.name === "General Overview" || prop.name === "Service Level" || prop.name === "Peak Times" || prop.name === "Live"
        || prop.name === "Billing Alerts" || prop.name === "Billing Online Subscriptions" || prop.name === "Billing Online Payment History"
        || prop.name === "Billing Manual Subscriptions" || prop.name === "Billing Manual Payment History" || prop.name === "Billing Manual Document Attachments"
      ) {
        _this.state.collapse_item = true;
        // _this.setState({collapse_item: true});
        // prop.hidden = true;
        // console.log("here");
      }
      else {
        _this.state.collapse_item = false;
        // _this.setState({collapse_item: false});
        // prop.hidden = false;
      }
      // }

      // if (_this.state.billingCollapse) {
        // if (prop.name === "Alerts" || prop.name === "Online Subscriptions" || prop.name === "Payment History") {
        //   _this.state.collapse_item = true;
        //   // prop.hidden = true;
        // } else {
        //   _this.state.collapse_item = false;
        //   // console.log("here");
        // }
      // }

      // if( _this.state.bDash ){
      //   if( prop.name === "General Overview" || prop.name === "Service Level" || prop.name === "Peak Times" || prop.name === "Live" )
      //   {
      //     _this.state.bDashboard = true;
      //     prop.hidden = true;
      //   }
      //   else 
      //   {
      //     _this.state.bDashboard = false;
      //     // prop.hidden = false;
      //   }
      // }
      return (

        // <li className= { (_this.state.analyticsCollapse && !_this.state.collapse_item) || (_this.state.bDash && !_this.state.bDashboard) ? "" : _this.activeRoute(prop.path) } 
        //   key={key} style= { (_this.state.collapse_item || _this.state.bDash) ? {marginLeft: 20}:{} }
        // hidden={(_this.state.collapse_item || _this.state.bDash) ? "":_this.hideByRole(prop)} 
        //onClick ={()=> _this.handleClick(prop.name) }>
        //<NavLink to={prop.path} activeClassName="">



        // <li className= {_this.state.bDash && !_this.state.bDashboard ? "" : _this.activeRoute(prop.path) } 
        //         key={key} style= { _this.state.bDashboard ? {marginLeft: 20}:{} }
        //      hidden={_this.state.bDashboard ? "":_this.hideByRole(prop)} 
        //     onClick ={()=> _this.handleClick(prop.name) }>
        //<NavLink to={prop.path} activeClassName="">
        <li className={_this.activeRoute(prop.path)}
          key={key} style={_this.state.collapse_item ? { marginLeft: 20 } : {}}
          hidden={_this.hideByRole(prop)}
          onClick={() => _this.handleClick(prop.name)}>
          <NavLink to={prop.path} activeClassName="">

            {/* <li className={_this.activeRoute(prop.path)} key={key}
            hidden={_this.hideByRole(prop)}>
          <NavLink to={prop.path} activeClassName=""> */}
            {prop.icon !== undefined ? (
              <>
                <i className={prop.icon} />
                <p  >{prop.name}</p>
              </>
            ) : (
                <>
                  <span className="sidebar-mini-icon">{prop.mini}</span>
                  <span className="sidebar-normal">{prop.name}</span>
                </>
              )}
            {/* {prop.icon !== undefined && _this.state.collapse_item ? (
              <>
                <i className={prop.icon} />
                <p>{prop.name}</p>
              </>
            ) : (
              <>
                <span className="sidebar-mini-icon">{prop.mini}</span>
                <span className="sidebar-normal">{prop.name}</span>
              </>
            )} */}
          </NavLink>
        </li>
      );
    });
  };
  // verifies if routeName is the one active (in browser input)
  activeRoute = routeName => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  hideByRole(prop) {
    // console.log("----"); console.log("prop.name", prop.name);
    var auth = JSON.parse(localStorage.getItem('auth_info'));
    if (auth == null)
      return true;

    var role = auth.role;
    var supportAdminRole = auth.support_admin_role;

    if (prop.non_support_hide === true) {
      if (supportAdminRole === true) {
        // console.log("path1");
        return false;
      }
        else {
          // console.log("path2");
          return true;
      }
    }

    // console.log("path3");

    if (prop.hidden /* || prop.state === "analyticsCollapse" || prop.state === "billingCollapse" */) {
      // console.log("path4");
      return true;
    } else {
      // console.log("path5");
      if (role === "Site_Admin")                { if (prop.site_admin_hide === true)          { return true; } else { return false; } }
      else if (role === "Support_Admin")        { if (prop.support_admin_hide === true)       { return true; } else { return false; } }
      else if (role === "System_Admin")         { if (prop.system_admin_hide === true)        { return true; } else { return false; } }
      else if (role === "Billing_Admin")        { if (prop.billing_admin_hide === true)       { return true; } else { return false; } }
      else if (role === "Location_Admin")       { if (prop.location_admin_hide === true)      { return true; } else { return false; } }
      else if (role === "Location_Super")       { if (prop.location_super_hide === true)      { return true; } else { return false; } }
      else if (role === "Counter_User")         { if (prop.counter_user_hide === true)        { return true; } else { return false; } }
      else if (role === "Counter_Super")        { if (prop.counter_super_hide === true)       { return true; } else { return false; } }
      else if (role === "Main_Display_User")    { if (prop.main_display_user_hide === true)   { return true; } else { return false; } }
      else if (role === "Counter_Display_User") { if (prop.counter_display_user_hide === true){ return true; } else { return false; } }
      else if (role === "Dispenser_User"||role === "Kiosk_User")       { if (prop.dispenser_user_hide === true)      { return true; } else { return false; } }

      return true;
    }
  }
  componentDidMount() {
    // if you are using a Windows Machine, the scrollbars will have a Mac look
    // if (navigator.platform.indexOf("Win") > -1) {
    //   ps = new PerfectScrollbar(this.refs.sidebar, {
    //     suppressScrollX: true,
    //     suppressScrollY: false
    //   });
    // }
  }
  componentWillUnmount() {
    // we need to destroy the false scrollbar when we navigate
    // to a page that doesn't have this component rendered
    // if (navigator.platform.indexOf("Win") > -1) {
    //   ps.destroy();
    // }
  }
  render() {
    let appVersion = process.env.REACT_APP_VERSION;
    return (
      <div
        className="sidebar"
        data-color={this.props.bgColor}
        data-active-color={this.props.activeColor}
        style={{ position: 'fixed' }}
      >
        <div className="logo" >
          {/* <div className="logo d-flex flex-row " ></div> */}
          <a
            href="#"
            onClick={e => e.preventDefault()}
            className="simple-text logo-mini" style={{ opacity: 1 }}
          >

            <div className="logo-image-small">
              {/* <img src={logo} style={{ width: '3rem' }} alt="react-logo" /> */}
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <lottie-player src="/antqueue-animated-logo-lottie-data.json"  background="transparent"  speed="1"  style={{width: "40px", height: "40px"}} loop autoplay></lottie-player>
              </div>
            </div>
            {/* <p>CT</p> */}
          </a>
          <a
            href="#"
            onClick={e => e.preventDefault()}
            className="simple-text logo-normal mx-1" style={{ fontSize: '0.85rem' }}
          >
            {"AntQueue v"+appVersion}
          {/* <div class="logo-image-big">
            <img src="../assets/img/logo-big.png">
          </div> */}
          </a>
        </div>

        <div className="sidebar-wrapper" ref="sidebar">
          {/* <div className="activate-status">
            <Switch
              offColor="success"
              offText=""
              onColor="success"
              onText=""
            />
          </div> */}
          <Nav>{this.createLinks(this.props.routes)}</Nav>
        </div>
      </div>
    );
  }
}

export default Sidebar;
