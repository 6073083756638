import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import AdminLayout from "./views/layouts/Admin/Admin.jsx";

import Action from './components/Action.jsx';
import Login from "./views/pages/Login.jsx";
import Register from "./views/pages/Register.jsx";
import Forgot from "./views/pages/Forgot.jsx";
import DeleteMobileUserAccountRequest from "./views/pages/DeleteMobileUserAccountRequest.jsx";
import DispenserRun from "./views/pages/DispenserRun";
// import ServiceRequests from "./views/pages/admin/ServiceRequests";

import "bootstrap/dist/css/bootstrap.css";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import "./assets/scss/paper-dashboard.scss";
import "./assets/demo/demo.css";
// import "./assets/scss/style.min.css";
import './assets/scss/custom.css'
import CacheBuster from './CacheBuster';
import './App.css';
import info from "./info"
const hist = createBrowserHistory();

document.title = info.app_name;

console.log(`REACT_APP_NAME: ${process.env.REACT_APP_NAME} REACT_APP_VERSION: ${process.env.REACT_APP_VERSION}`);

ReactDOM.render(<>
  <Router history={hist}>
    <Switch>
      <Route path="/__/auth/action" component={Action} />
      <Route path="/login" render={props => <Login {...props} />} />
      <Route path="/register" render={props => <Register {...props} />} />
      <Route path="/forgot_password" render={props => <Forgot {...props} />} />
      <Route path="/delete_mobile_user" render={props => <DeleteMobileUserAccountRequest {...props} />} />
      {/* <Route path="/dispenser/run/:id" render={props => <DispenserRun {...props} />} /> */}
      <Redirect from="/logout" to="/login" />
      <Route path="/" render={props => <AdminLayout {...props} />} />
      {/* <Route exact path='/service_requests' component={ServiceRequests} /> */}
    </Switch>
  </Router>
  <CacheBuster>
    {({ loading, isLatestVersion, refreshCacheAndReload }) => {
      if (loading) return null;
      if (!loading && !isLatestVersion) {
        refreshCacheAndReload();
      }

      return (
        ''
      );
    }}
  </CacheBuster>
</>,
  document.getElementById("root")
);
