import React from "react";
import Firebase from 'firebase';
import PasswordHash from 'password-hash';
import config from '../../config';
import ReactBSAlert from "react-bootstrap-sweetalert";
import NotificationAlert from "react-notification-alert";
import {withCookies, Cookies} from 'react-cookie';
import {instanceOf} from "prop-types";
// import GoogleLogin from 'react-google-login';
// import FacebookLogin from 'react-facebook-login';
import info from '../../info'
import request from 'request';
import LoadingOverlay from 'react-loading-overlay';
// reactstrap components

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Label,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

require("firebase/functions");

var Recaptcha = require('react-recaptcha');

const publicIp = require('public-ip');
class Register extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  constructor(props) {
    super(props);
      if (!Firebase.apps.length) {
          Firebase.initializeApp(config);
      }

      const { cookies } = props;
      this.state = {
          loading: false,
          cookies: cookies,
          email: '',
          company_name: '',
          customer_name: '',
          phone_number: '',
          website: '',
          agree: '0',
          interest: '0',

          country_code: '',

          emailState: "",
          company_nameState: "",
          customer_nameState: "",
          phone_numberState: "",
          websiteState: "",
          agreeState: "",
          alert: null,
          enableRegister: true, //false, {/* // Must be uncommented */}
          address_info: null
      };

      this.handleChange = this.handleChange.bind(this);
      this.handleRegister = this.handleRegister.bind(this);
      this.SuccessAlert = this.SuccessAlert.bind(this);
      this.loadAddressInfo = this.loadAddressInfo.bind(this);
      this.loadCallback = this.loadCallback.bind(this);
      this.verifyCallback = this.verifyCallback.bind(this);
  }
  componentDidMount() {
    document.body.classList.toggle("register-page");
    this.loadAddressInfo();

    // var search_term = "ukraine";
    // var countryCode = "";
    // var countriesArr = [];
    // var resultsArr = [];

    // Firebase.firestore()
    //     .collection('System_Config').doc("Country_List")
    //     .collection('Countries').get().then(function (querySnapshot) {
    //         querySnapshot.forEach(function(doc){
    //             countriesArr.push({
    //                 "simpleCountryName": doc.data().Country_Name.toLowerCase(),
    //                 "threeLetterCode": doc.data().Three_Letter_Country_Code}
    //             );
    //         });
    // })
    // .then( function(result) {

    //     for (let i= 0; i < countriesArr.length; i++){
    //         if ((countriesArr[i].simpleCountryName).includes(search_term)){
    //             resultsArr.push({
    //                 "code": countriesArr[i].threeLetterCode
    //             })
    //         }
    //     }

    //     if (resultsArr.length == 1) {
    //         countryCode = resultsArr[0].code;
    //     } else {
    //         countryCode = "INVALID_ERROR_1";
    //     }
      
    //     console.log("countriesArr", countriesArr);
    //     console.log("resultsArr", resultsArr);
    //     console.log("countryCode", countryCode);
    //     return countryCode;
    // })
    // .catch( function(error){
    //     console.log("error",error);
    //     return "INVALID_ERROR_2";
    // })
  }
  componentWillUnmount() {
    document.body.classList.toggle("register-page");
  }
  loadAddressInfo() {
    var _this = this;
    _this.setState({loading: true});
    publicIp.v4().then(ip => {
        console.log("your public ip address: ", ip);
        let url = 'https://api.ipgeolocation.io/ipgeo?apiKey=' + info.ip_api_key + '&ip='+ip+'&lang=en';

        request.get(url, function(err,res,body){
            if(err){
                _this.setState({address_info: null});
                _this.setState({loading: false});
                _this.notifyMessage("tc", 3, "Network error!");
                console.log("Network Error 1: ",err);
            }
            if(res.statusCode !== 200 ) {
                _this.setState({address_info: null});
                _this.setState({loading: false});
                _this.notifyMessage("tc", 3, "Load geolocation error!");
            }

            let result = JSON.parse(body);
            _this.setState({address_info: result});
            let countryCodeVal = _this.getThreeDigitCountryCode(result.country_name.toLowerCase());
            _this.setState({country_code: countryCodeVal});
            // console.log("countryCodeVal",countryCodeVal);
            _this.setState({loading: false});
        });
    });
  }
  loadCallback () {
    this.setState({enableRegister: false});
  }
  verifyCallback () {
      this.setState({enableRegister: true});
  }
  SuccessAlert(text1) {
    this.setState({
        alert: (
            <ReactBSAlert
                style={{ display: "block", marginTop: "-300px" }}
                title="Congratulations"
                onConfirm={() => this.hideAlert()}
                confirmBtnBsStyle="success"
                confirmBtnText="Get Started"
            >
                <div style={{ color:"black" }}><div className="text-md-left">{text1}<br/></div></div>
            </ReactBSAlert>
        )
    });
  };
  hideAlert = () => {
    this.setState({
        alert: null
    });

    this.props.history.push("/login");
  };
  // function that verifies if a string has a given length or not
  verifyLength = (value, length) => {
        if (value.length >= length) {
            return true;
        }
        return false;
  };
  notifyMessage = (place, color, text) => {
        var type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }

        var options = {};
        options = {
            place: place,
            message: (
                <div className="text-md-center">
                    <div>
                        <b>{text}</b>
                    </div>
                </div>
            ),
            type: type,
            icon: "now-ui-icons ui-1_bell-53",
            autoDismiss: 3
        };
        if (options !== null) { this.notificationAlert.notificationAlert(options) }
    };
  handleChange = (event, stateName, type) => {
    switch (type) {
        case "email":
            if (this.verifyEmail(event.target.value)) {
                this.setState({ [stateName + "State"]: "has-success" });
                const {name, value} = event.target;
                this.setState({[name]: value});
            } else {
                this.setState({ [stateName + "State"]: "has-danger" });
            }
            break;
        case "company_name":
            if (this.verifyLength(event.target.value, 1)) {
                this.setState({ [stateName + "State"]: "has-success" });
                const {name, value} = event.target;
                this.setState({[name]: value});
            } else {
                this.setState({ [stateName + "State"]: "has-danger" });
            }
            break;
        case "customer_name":
            if (this.verifyLength(event.target.value, 1)) {
                this.setState({ [stateName + "State"]: "has-success" });
                const {name, value} = event.target;
                this.setState({[name]: value});
            } else {
                this.setState({ [stateName + "State"]: "has-danger" });
            }
            break;
        case "phone_number":
            if (this.verifyLength(event.target.value, 1) && this.validE164(event.target.value)) {
                this.setState({ [stateName + "State"]: "has-success" });
                const {name, value} = event.target;
                this.setState({[name]: value});
            } else {
                this.setState({ [stateName + "State"]: "has-danger" });
            }
            break;
        case "website":
            if (this.verifyLength(event.target.value, 1) && this.verifyWebsite(event.target.value)) {
                this.setState({ [stateName + "State"]: "has-success" });
                const {name, value} = event.target;
                this.setState({[name]: value});
            } else {
                this.setState({ [stateName + "State"]: "has-danger" });
            }
            break;
        case "interest" :
            if (this.state.interest === "0") {
                this.setState({"interest": "1"});
            } else {
                this.setState({"interest": "0"});
            }
            break;
        case "agree" :
            if (this.state.agree === "0") {
                this.setState({ [stateName + "State"]: "has-success" });
                this.setState({"agree": "1"});
            } else {
                this.setState({"agree": "0"});
                this.setState({ [stateName + "State"]: "has-danger" });
            }
            break;
        default:
            break;
    }
  };
  verifyWebsite = value => {
    var websiteRex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
    if (websiteRex.test(value)) { return true; }
    return false;
  }
  /// Validate E164 format
  // validE164(num) { /* return /^\+?[1-9]\d{1,14}$/.test(num) */ return /^\+(?:[0-9] ?){6,14}[0-9]$/.test(num); }
  validE164 = value => {
    var phoneRex = /^\+(?:[0-9] ?){6,14}[0-9]$/; // /^\+?[1-9]\d{1,14}$/
    if (phoneRex.test(value)) { return true; }
    return false;
  }
  verifyEmail = value => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) { return true; }
    return false;
  };
  handleRegister777  = () => {
    //in the works
  }
  handleRegister = () => { //handleRegister 28JUN2020 1953
    if (this.state.emailState === "") { this.setState({ emailState: "has-danger" }) }
    if (this.state.agreeState === "") { this.setState({ agreeState: "has-danger" }) }
    if (this.state.company_nameState === "") { this.setState({ company_nameState: "has-danger" }) }
    if (this.state.customer_nameState === "") { this.setState({ customer_nameState: "has-danger" }) }
    if (this.state.phone_numberState === "") { this.setState({ phone_numberState: "has-danger" }) }
    if (this.state.websiteState === "") { this.setState({ websiteState: "has-danger" }) }

    if (    this.state.emailState === "has-success" && this.state.agreeState === "has-success" &&
            this.state.company_nameState === "has-success" && this.state.customer_nameState === "has-success" &&
            this.state.phone_numberState === "has-success" && this.state.websiteState === "has-success"    ) {
        var email = this.state.email.toLowerCase();
        let ref = Firebase.firestore().collection('Web_App_Users').doc(email);
        var _this = this;
        _this.setState({loading: true});
        ref.get().then(function(doc) {
            if (doc.exists) {
                _this.setState({loading: false});
                _this.notifyMessage("tc", 3, "Sorry, an account for the specified email address already exists in AntQueue!");
            } else {
                Firebase.firestore().collection('Customers').get().then(function (res) {
                    var last_id = "000000000000";
                    var next_id = "000000000001";
                    if (res.docs.length > 0) {
                        last_id = res.docs[res.docs.length - 1].id;
                        var str = (parseInt(last_id) + 1).toString();
                        var pad = "000000000000";
                        next_id = pad.substring(0, pad.length - str.length) + str;
                    }

                    var random_pass = _this.generatePass(8);
                    var customer_id = next_id;
                    var now = new Date();
                    var reg_data1 = {
                        API_Key: "",
                        Company_Name: _this.state.company_name?_this.state.company_name:'',
                        Country: _this.state.address_info===null?"":_this.state.address_info.country_name.toLowerCase(),
                        Country_Code: _this.state.country_code?_this.state.country_code:'',
                        Created_Date: now,
                        Customer_Category: "Cat002", //'to_be_assigned'
                        Email: email,
                        Image_Url: "",
                        Name: _this.state.customer_name?_this.state.customer_name:'',
                        Partner_Interest: _this.state.interest===0?false:true,
                        Phone_Number: _this.state.phone_number?_this.state.phone_number:'',
                        Web_Site: _this.state.website?_this.state.website:'',
                        
                    };
                    Firebase.firestore().collection('Customers').doc(customer_id.toString()).set(reg_data1)
                        .then(function() {
                            var reg_data2 = {
                                API_Key: "",
                                Accessible_Locations: [],
                                Activated: true,
                                Created_Date: now,
                                Customer_ID: customer_id,
                                Designation: "",
                                Image_Url: "",
                                Last_Activity_Date: "",
                                Last_Updated_Date: now,
                                Last_Updated_User_ID: "",
                                Name: "",
                                Notification: true, //false,
                                OTP_Code: "",
                                OTP_Enabled: false,
                                Password: PasswordHash.generate(random_pass),
                                Role: "System_Admin",
                                Google_Token: "",
                                Facebook_Token: "",
                                token: "",
                                What_Sup: "",
                                Viber: "",
                                Telegram: "",
                                Line: "",
                                
                            };
                            Firebase.firestore().collection('Web_App_Users').doc(email).set(reg_data2)
                                .then(function() {
                                    /////// Register success code ////////
                                    // _this.notifyMessage("tc", 2, "Register success!");
                                    var message_text =
                                        "Dear Valued Customer,<br /><br>" +
                                        "Your account has been successfully registered with <b>Ant Queue</b> web app.<br /><br />" +
                                        "Your login details to <a href='https://app.antqueue.com/' target='_blank' style='color:#FF5733;'><b>app.antqueue.com</b></a> are as follows:<br /><br />" +
                                        "Username: " + email.toLowerCase() + "<br/>" +
                                        "Password: " + random_pass + "<br /><br />" +
                                        "Best Regards,<br/>" +
                                        "<b>Ant Queue Team</b><br/>";
                                    
                                    Firebase.functions().httpsCallable('sendMail')({email: email, subject: 'Welcome to Antqueue Web App', text: message_text}).then(function(result) {
                                        console.log("result", result, "random_pass", random_pass);
                                        Firebase.functions().httpsCallable('createNewUser')({email: email, password: random_pass}).then((result) => {
                                            console.log('new user created result ===> ', result);
                                            var message_text1 = "You have successfully registered to AntQueue Web App. Please check your email for login details.";
                                            // _this.SuccessAlert(message_text1);
                                            _this.notifyMessage("tc", 2, message_text1);
                                            _this.setState({loading: false});
                                            window.setTimeout(function() { _this.props.history.push("/login") }, 4000);
                                        }, (err) => console.log("new user created error ==> ", err));
                                    });
                             
                                }).catch(function(error) {
                                    _this.setState({loading: false});
                                    _this.notifyMessage("tc", 3, "Register error!");
                                    console.log("Registration failed: ",error);
                                });
                        }).catch(function(error) {
                            _this.setState({loading: false});
                            _this.notifyMessage("tc", 3, "Register Failed!");
                            console.log("Registration failed: ",error);
                        });
                }).catch(function (err) {
                    _this.setState({loading: false});
                    _this.notifyMessage("tc", 3, "Network error!");
                    console.log("Network Error 2: ",err);
                });
            }
        }).catch(function(error) {
            _this.setState({loading: false});
            _this.notifyMessage("tc", 3, "Network error!");
            console.log("Network Error 3: ",error);
        });
    }
  };
  sendEmail(email, text) {
      Firebase.functions().httpsCallable('sendMail')({email: email, subject: 'Welcome to Antqueue Web App', text: text}).then(function(result) {
          console.log(result);
      });
  }
  getThreeDigitCountryCode(search_term) {
    // console.log("test", search_term);  
    // return "TEST";
    var _this = this;
    var countryCode = "INVALID_ERROR_0";
    var countriesArr = [];
    var resultsArr = [];

    Firebase.firestore()
        .collection('System_Config').doc("Country_List")
        .collection('Countries').get().then(function (querySnapshot) {
            querySnapshot.forEach(function(doc){
                countriesArr.push({
                    "simpleCountryName": doc.data().Country_Name.toLowerCase(),
                    "threeLetterCode": doc.data().Three_Letter_Country_Code}
                );
            });
    })
    .then( function(result) {

        for (let i= 0; i < countriesArr.length; i++){
            if ((countriesArr[i].simpleCountryName).includes(search_term)){
                resultsArr.push({
                    "code": countriesArr[i].threeLetterCode
                })
            }
        }

        if (resultsArr.length == 1) {
            countryCode = resultsArr[0].code;
        } else {
            countryCode = "INVALID_ERROR_1";
        }

        // console.log("countriesArr", countriesArr);
        // console.log("resultsArr", resultsArr);
        console.log("countryCode", countryCode);
        _this.setState({country_code: countryCode});
        return countryCode;
    })
    .catch( function(error){
        console.log("error",error);
        //this.setState({country_code: "INVALID_ERROR_2"});
        return "INVALID_ERROR_2";
    })
  }
//   responseGoogle = (response) => {
//     var _this = this;
//     var now = new Date();
//     if (response.error === "popup_closed_by_user") {
//         _this.setState({loading: false});
//         _this.notifyMessage("tc", 3, "Popup closed by user.");
//         return;
//     } else if (response.error === "idpiframe_initialization_failed") {
//         _this.setState({loading: false});
//         return
//     }

//     var profile = response.profileObj;
//     if (profile == null) {
//         _this.setState({loading: false});
//         _this.notifyMessage("tc", 3, "Network error!");
//         console.log("Network Error 4");
//     } else {
//         Firebase.firestore().collection('Web_App_Users').doc(profile.email.toLowerCase()).get().then(function (doc) {
//             if (doc.exists) {
//                 var auth_info = {
//                     customer_id: doc.data().Customer_ID,
//                     email: doc.id,
//                     role: doc.data().Role
//                 };
//                 var update_data = {
//                     Last_Activity_Date: now,
//                     Google_Token: response.accessToken
//                 }
//                 Firebase.firestore().collection('Web_App_Users').doc(profile.email.toLowerCase()).update(update_data).then(function () {
//                     _this.state.cookies.set('auth_info', doc.id);
//                     localStorage.setItem('auth_info', JSON.stringify(auth_info));
//                     _this.setState({loading: false});
//                     _this.notifyMessage("tc", 2, "Login success!");
//                     window.setTimeout(function() { _this.props.history.push("/") }, 2000);
//                 }).catch(function (err) {
//                     _this.setState({loading: false});
//                     _this.notifyMessage("tc", 3, "Network error!");
//                     console.log("Network Error 5",err);
//                 });
//             } else {
//                 Firebase.firestore().collection('Customers').get().then(function (res) {
//                     var last_id = "000000000000";
//                     var next_id = "000000000001";
//                     if (res.docs.length > 0) {
//                         last_id = res.docs[res.docs.length - 1].id;
//                         var str = (parseInt(last_id) + 1).toString();
//                         var pad = "000000000000";
//                         next_id = pad.substring(0, pad.length - str.length) + str;
//                     }
//                     var customer_id = next_id;

//                     var register_customer_data = {
//                         API_Key: "",
//                         Company_Name: "",
//                         Country: _this.state.address_info===null?"":_this.state.address_info.country_name.toLowerCase(),
//                         Email: profile.email,
//                         Name: profile.givenName + profile.familyName,
//                         Partner_Interest: false,
//                         Image_Url: "",
//                         Phone_Number: "",
//                         Web_Site: "",
//                         Customer_Category: 'Cat002'
//                     };

//                     Firebase.firestore().collection('Customers').doc(customer_id).set(register_customer_data).then(function () {
//                         var random_pass = _this.generatePass(8);
//                         var register_app_data = {
//                             Activated: true,
//                             Accessible_Locations: [],
//                             Created_Date: now,
//                             Customer_ID: customer_id,
//                             Designation: "",
//                             Last_Activity_Date: "",
//                             Last_Updated_Date: now,
//                             Last_Updated_User_ID: "",
//                             Name: register_customer_data.Name,
//                             OTP_Code: "",
//                             OTP_Enabled: false,
//                             Password: PasswordHash.generate(random_pass),
//                             Role: "System_Admin",
//                             Google_Token: response.accessToken,
//                             Facebook_Token: "",
//                             token: "",
//                             What_Sup: "",
//                             Viber: "",
//                             Telegram: "",
//                             Line: "",
//                             Image_Url: "",
//                             Notification: false
//                         };

//                         Firebase.firestore().collection('Web_App_Users').doc(register_customer_data.Email.toLowerCase()).set(register_app_data).then(function () {
//                                 _this.state.cookies.set('auth_info', doc.id);
//                                 localStorage.setItem('auth_info', JSON.stringify({customer_id: register_app_data.Customer_ID, email: doc.id, role: register_app_data.Role}));
//                                 _this.setState({loading: false});
//                                 _this.notifyMessage("tc", 2, "Login success!");
//                                 window.setTimeout(function () {
//                                     _this.props.history.push("/")
//                                 }, 2000);
//                             }).catch(function (err) {
//                                 _this.setState({loading: false});
//                                 _this.notifyMessage("tc", 3, "Network error!");
//                                 console.log("Network Error 6",err);
//                             });

//                         }).catch(function (err) {
//                             _this.setState({loading: false});
//                             _this.notifyMessage("tc", 3, "Network error!");
//                             console.log("Network Error 7",err);
//                         });
//                     });
//                 }
//             }).catch(function (err) {
//                 _this.setState({loading: false});
//                 _this.notifyMessage("tc", 3, "Network error!");
//                 console.log("Network Error 8",err);
//             });
//     }
//   };
//   responseFacebook = (response) => {
//     var now_date = new Date();
//     var _this = this;
//     if (response.accessToken != null) {
//         var profile = response;
//         Firebase.firestore().collection('Web_App_Users').doc(profile.email.toLowerCase()).get().then(function (doc) {
//             if (doc.exists) {
//                 var auth_info = {
//                     customer_id: doc.data().Customer_ID,
//                     email: doc.id,
//                     role: doc.data().Role
//                 };
//                 var update_data = {
//                     Last_Activity_Date: now_date,
//                     Facebook_Token: profile.accessToken
//                 };
//                 Firebase.firestore().collection('Web_App_Users').doc(profile.email.toLowerCase()).update(update_data).then(function () {
//                     _this.notifyMessage("tc", 2, "Login success!");
//                     _this.state.cookies.set('auth_info', doc.id);
//                     localStorage.setItem('auth_info', JSON.stringify(auth_info));
//                     _this.setState({loading: false});
//                     window.setTimeout(function() { _this.props.history.push("/") }, 2000);
//                 }).catch(function (err) {
//                     _this.setState({loading: false});
//                 });
//             } else {
//                 Firebase.firestore().collection('Customers').get().then(function (res) {
//                     var last_id = "000000000000";
//                     var next_id = "000000000001";
//                     if (res.docs.length > 0) {
//                         last_id = res.docs[res.docs.length - 1].id;
//                         var str = (parseInt(last_id) + 1).toString();
//                         var pad = "000000000000";
//                         next_id = pad.substring(0, pad.length - str.length) + str;
//                     }
//                     var customer_id = next_id;
//                     var register_customer_data = {
//                         API_Key: "",
//                         Activated: true,
//                         Company_Name: "",
//                         Country: _this.state.address_info===null?"":_this.state.address_info.country_name.toLowerCase(),
//                         Email: profile.email,
//                         Name: profile.name,
//                         Partner_Interest: false,
//                         Registered_Date: now_date,
//                         Phone_Number: "",
//                         Web_Site: "",
//                         Customer_Category: 'Cat002'
//                     };

//                     Firebase.firestore().collection('Customers').doc(customer_id).set(register_customer_data).then(function () {
//                         var random_pass = _this.generatePass(8);
//                         var register_app_data = {
//                             Activated: true,
//                             Accessible_Locations: [],
//                             Created_Date: now_date,
//                             Customer_ID: customer_id,
//                             Designation: "",
//                             Last_Activity_Date: "",
//                             Last_Updated_Date: now_date,
//                             Last_Updated_User_ID: "",
//                             Name: register_customer_data.Name,
//                             OTP_Code: "",
//                             OTP_Enabled: false,
//                             Password: PasswordHash.generate(random_pass),
//                             Role: "System_Admin",
//                             Facebook_Token: profile.accessToken,
//                             Google_Token: "",
//                             token: "",
//                             What_Sup: "",
//                             Viber: "",
//                             Telegram: "",
//                             Line: "",
//                             Image_Url: "",
//                             Notification: false
//                         };

//                         Firebase.firestore().collection('Web_App_Users').doc(register_customer_data.Email.toLowerCase()).set(register_app_data).then(function () {
//                             _this.state.cookies.set('auth_info', doc.id);
//                             localStorage.setItem('auth_info', JSON.stringify({customer_id: register_app_data.Customer_ID, email: doc.id, role: register_app_data.Role}));
//                             _this.notifyMessage("tc", 2, "Login success!");
//                             _this.setState({loading: false});
//                             window.setTimeout(function() { _this.props.history.push("/") }, 2000);
//                         }).catch(function (err) {
//                             _this.setState({loading: false});
//                             _this.notifyMessage("tc", 3, "Network error!");
//                             console.log("Network Error 9",err);
//                         });

//                     }).catch(function (err) {
//                         _this.setState({loading: false});
//                         _this.notifyMessage("tc", 3, "Network error!");
//                         console.log("Network Error 10",err);
//                     });
//                 }).catch(function (err) {
//                     _this.setState({loading: false});
//                 });
//             }
//         }).catch(function (err) {
//             _this.setState({loading: false});
//             _this.notifyMessage("tc", 3, "Network error!");
//             console.log("Network Error 11",err);
//         });
//     } else {
//         _this.setState({loading: false});
//         _this.notifyMessage("tc", 3, "Network error!");
//         console.log("Network Error 12");
//     }
//   };
//   onClickSocial = (e) => {
//     this.setState({loading: true});
//   };
  generatePass(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789,./<>?:{}[]-=_+)(*&^%$#@!~`';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  render() {
    let appVersion = process.env.REACT_APP_VERSION;
    let {
      emailState,
      company_nameState,
      customer_nameState,
      phone_numberState,
      websiteState,
      agreeState
    } = this.state;

    return (
        <div className="wrapper wrapper-full-page" ref="fullPages">
            <LoadingOverlay
                active={this.state.loading}
                spinner
                text='Loading'
            >
            <div className="full-page section-image">
                <NotificationAlert ref={(ref) => this.notificationAlert = ref} />
                <div className="register-page">
                {this.state.alert}
                  <Container>
                      <Row>
                          <Col className="ml-auto mr-auto" lg="7" md="6">
                              <Card style={{backgroundColor:'#ffffff00', boxShadow:'0px 0px 0px 0px rgb(0 0 0 / 1%)'}}>
                                  <CardHeader>
                                      <CardHeader>
                                          <div className="header text-center">
                                              {/* <img src={'/antqueue-animated-logo.gif'} alt={"animated-antqueue-logo"} />  */}
                                              <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                  <lottie-player src="/antqueue-animated-logo-lottie-data.json"  background="transparent"  speed="1"  style={{width: "200px", height: "200px"}} loop autoplay></lottie-player>
                                              </div>
                                          </div>
                                          <h3 style={{color:'#fff'}} className="header text-center Margin-top--20">AntQueue Registration</h3>
                                      </CardHeader>
                                      <div className="row justify-content-center">
                                        <div style={{color:'#fff'}} className="col-md-9">
                                            Already a member? <span><a href="#" onClick={e => {e.preventDefault(); this.props.history.push("/login");}}>{'Login here'}</a></span> instead.
                                        </div>
                                      </div>
                                  </CardHeader>
                                  <CardBody>
                                      <div className="row justify-content-center">
                                           <div className="col-md-9">
                                              <label style={{color:'#fff'}}>Email Address</label>
                                              <InputGroup className={`has-label ${emailState}`}>
                                                  <InputGroupAddon addonType="prepend">
                                                      <InputGroupText style={{padding:'10px 10px 10px 10px', backgroundColor:'#fff'}}>
                                                          <i className="nc-icon nc-email-85" />
                                                      </InputGroupText>
                                                  </InputGroupAddon>
                                                  <Input
                                                      placeholder="Email Address"
                                                      name="email"
                                                      type="email"
                                                      onChange={e => this.handleChange(e, "email", "email")}
                                                  />
                                                  {this.state.emailState === "has-danger" ? (
                                                      <label className="error">
                                                          Please enter a valid email address.
                                                      </label>
                                                  ) : null}
                                              </InputGroup>
                                              <br/>
                                              <label style={{color:'#fff'}}>Company Name</label>
                                              <InputGroup className={`has-label ${company_nameState}`}>
                                                  <InputGroupAddon addonType="prepend">
                                                      <InputGroupText style={{padding:'10px 10px 10px 10px', backgroundColor:'#fff'}}>
                                                          <i className="nc-icon nc-bank" />
                                                      </InputGroupText>
                                                  </InputGroupAddon>
                                                  <Input
                                                      placeholder="Company Name"
                                                      name="company_name"
                                                      type="company_name"
                                                      onChange={e => this.handleChange(e, "company_name", "company_name")}
                                                  />
                                                  {this.state.company_nameState === "has-danger" ? (
                                                      <label className="error">
                                                          Please enter a valid company name.
                                                      </label>
                                                  ) : null}
                                              </InputGroup>
                                              <br/>
                                              <label style={{color:'#fff'}}>Customer Name</label>
                                              <InputGroup className={`has-label ${customer_nameState}`}>
                                                  <InputGroupAddon addonType="prepend">
                                                      <InputGroupText style={{padding:'10px 10px 10px 10px', backgroundColor:'#fff'}}>
                                                          <i className="nc-icon nc-single-02" />
                                                      </InputGroupText>
                                                  </InputGroupAddon>
                                                  <Input
                                                      placeholder="Customer Name"
                                                      name="customer_name"
                                                      type="customer_name"
                                                      onChange={e => this.handleChange(e, "customer_name", "customer_name")}
                                                  />
                                                  {this.state.customer_nameState === "has-danger" ? (
                                                      <label className="error">
                                                          Please enter a valid customer name.
                                                      </label>
                                                  ) : null}
                                              </InputGroup>
                                              <br/>
                                              <label style={{color:'#fff'}}>Phone Number</label>
                                              <InputGroup className={`has-label ${phone_numberState}`}>
                                                  <InputGroupAddon addonType="prepend">
                                                      <InputGroupText style={{padding:'10px 10px 10px 10px', backgroundColor:'#fff'}}>
                                                          <i className="nc-icon nc-mobile" />
                                                      </InputGroupText>
                                                  </InputGroupAddon>
                                                  <Input
                                                      placeholder="Phone Number"
                                                      name="phone_number"
                                                      type="phone_number"
                                                      onChange={e => this.handleChange(e, "phone_number", "phone_number")}
                                                  />
                                                  {this.state.phone_numberState === "has-danger" ? (
                                                      <label className="error">
                                                          Please enter a valid phone number of correct E164 format! Eg: +[Country_Code][Area_Code][Phone_Number]
                                                      </label>
                                                  ) : null}
                                              </InputGroup>
                                              <br/>
                                              <label style={{color:'#fff'}}>Website</label>
                                              <InputGroup className={`has-label ${websiteState}`}>
                                                  <InputGroupAddon addonType="prepend">
                                                      <InputGroupText style={{padding:'10px 10px 10px 10px', backgroundColor:'#fff'}}>
                                                          <i className="nc-icon nc-globe" />
                                                      </InputGroupText>
                                                  </InputGroupAddon>
                                                  <Input
                                                      placeholder="Website"
                                                      name="website"
                                                      type="website"
                                                      onChange={e => this.handleChange(e, "website", "website")}
                                                      onKeyDown={e => {
                                                          if (e.keyCode === 13){
                                                              if (!this.refs['btn_register'].props.disabled) {
                                                                  this.refs['btn_register'].onClick();
                                                              }
                                                          }
                                                      }}
                                                  />
                                                  {this.state.websiteState === "has-danger" ? (
                                                      <label className="error">
                                                          Please enter a valid website URL.
                                                      </label>
                                                  ) : null}
                                              </InputGroup>
                                              <br/>
                                              <FormGroup>
                                                  <FormGroup check>
                                                      <Label check>
                                                          <Input
                                                              defaultValue=""
                                                              type="checkbox"
                                                              name="interest"
                                                              onChange={e =>
                                                                  this.handleChange(e, "interest", "interest")
                                                              }
                                                          />
                                                          <span style={{color:'#fff'}} className="form-check-sign">
                                                              Interested in becoming a partner
                                                          </span>
                                                      </Label>
                                                  </FormGroup>
                                              </FormGroup>
                                              <FormGroup className={`has-label ${agreeState}`}>
                                                  <FormGroup check>
                                                      <Label check>
                                                          <Input
                                                              defaultValue=""
                                                              type="checkbox"
                                                              name="agree"
                                                              onChange={e =>
                                                                  this.handleChange(e, "agree", "agree")
                                                              }
                                                          />
                                                          <span style={{color:'#fff'}} className="form-check-sign">
                                                              Yes, I agree to the <a href="#" onClick={e => {e.preventDefault();}}>terms and conditions</a>
                                                          </span>
                                                      </Label>
                                                      {this.state.agreeState === "has-danger" ? (
                                                          <label className="error">
                                                              You must agree to the terms and conditions of the AntQueue agreement to proceed with registration.
                                                          </label>
                                                      ) : null}
                                                  </FormGroup>
                                              </FormGroup>
                                              <Recaptcha
                                                  sitekey={info.site_key}
                                                  render="explicit"
                                                  onloadCallback={this.loadCallback}
                                                  verifyCallback={this.verifyCallback}
                                              />
                                              <br></br>
                                              <div className="row">
                                                  <div className="col-md-7">

                                                  </div>
                                                  <div className="col-md-5">
                                                      <Button
                                                          disabled={!this.state.enableRegister}
                                                          ref="btn_register"
                                                          color="success"
                                                          onClick={this.handleRegister}
                                                          block
                                                      >
                                                          Sign up
                                                      </Button>
                                                  </div>
                                              </div>
                                          </div>
                                          {/* <div className="col-md-5">
                                              <div className="site-link-button">
                                                  <GoogleLogin
                                                      clientId={info.google_client_id}
                                                      buttonText="Login"
                                                      onSuccess={this.responseGoogle}
                                                      onFailure={this.responseGoogle}
                                                      onRequest={this.onClickSocial}
                                                      cookiePolicy={'single_host_origin'}
                                                      render={renderProps => (
                                                          <Button color="google" onClick={renderProps.onClick} disabled={renderProps.disabled} block>
                                                              <i className="fa fa-google-plus" />
                                                              Sign in with Google+
                                                          </Button>
                                                      )}
                                                  />
                                              </div> */}
                                              {/* <div className="site-link-button">
                                                  <FacebookLogin
                                                      appId={info.facebook_app_id}
                                                      fields="name,email,picture"
                                                      onClick={this.onClickSocial}
                                                      callback={this.responseFacebook}
                                                      cssClass="btn btn-facebook btn-block"
                                                      icon="fa fa-facebook-square"
                                                  />
                                              </div>
                                          </div> */}
                                      </div>
                                  </CardBody>
                                  <CardFooter>
                                      <div style={{color:'#fff', fontSize: '8px', marginTop: '25px'}} className="header text-center font-weight-normal text-capitalize">{"AntQueue v"+appVersion}</div>
                                  </CardFooter>
                              </Card>
                          </Col>
                      </Row>
                  </Container>
                  <div
                      className="full-page-background"
                      style={{
                          backgroundImage: `url(${require("../../assets/img/bg/queue_4000x2500_3.jpg")})`,
                          backgroundColor: "lightGrey"
                      }}
                  />
                </div>
            </div>
            </LoadingOverlay>
        </div>
    );
  }
}

export default withCookies(Register);
