import React from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
// import am4themes_animated from "@amcharts/amcharts4/themes/animated";
// import am4themes_kelly from "@amcharts/amcharts4/themes/kelly";

import { AmchartsReact } from 'amchart4-react'

// am4core.useTheme(am4themes_animated);

// am4core.useTheme(am4themes_kelly);


class MainLocLine extends React.Component {
  state = {
    chart: null
  }

  componentDidMount() {
    let chart = am4core.create("main_lineChart", am4charts.XYChart);

    this.createChart(chart);

    this.chart = chart;
  }

  handleZoom = (e) => {
    console.log(e)
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  createChart = (chart) => {
    chart.data = this.props.data; 
    chart.colors.list = this.props.color;
    let label = this.props.label;

   // Create axes
    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());

    categoryAxis.dataFields.category = "date";

    var  valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

    var i = 0;
    var series = [];
    for( i = 0 ; i < label.length ; i++ )
    {
      series[i] = chart.series.push(new am4charts.ColumnSeries());
      series[i].dataFields.valueY = label[i];

      series[i].dataFields.categoryX = "date";
      if( label.length < 9 )
        series[i].name = label[i];

      series[i].tooltipText = label[i]+": {categoryX}:{valueY}";
      series[i].stacked = true;
      series[i].stroke = am4core.color("#0835F9");
      series[i].strokeWidth = 1;
      series[i].strokeOpacity = 0.6;
    }
   
    // Add cursor
    chart.cursor = new am4charts.XYCursor();

    // Add legend
    chart.legend = new am4charts.Legend();

    chart.exporting.menu = new am4core.ExportMenu();

    categoryAxis.renderer.grid.template.strokeOpacity = 0.07;
    valueAxis.renderer.grid.template.strokeOpacity = 0.07;
  }

  render() {
    return (
      <div>
      <div id="main_lineChart" style={{ width: "100%", height: "500px" }} />
      {this.state.chart ? <AmchartsReact chart={this.state.chart} /> : null}
      </div>
    );
  }
}

export default MainLocLine;
