var appVersion = process.env.REACT_APP_VERSION;

const info = {
    app_name: "Antqueue Web App v"+appVersion,
    user: "some_user",
    pass: "open_sesame_just_kidding",
    customActionUrl: "https://app.antqueue.com/__/auth/action",
    loginUrl: "https://app.antqueue.com/login",
    changePassUrl: "https://app.antqueue.com/forgot_password",
    deleteAccountUrl: "https://app.antqueue.com/delete_mobile_user",
    // site_key: "6LfY66oUAAAAAKF9pnd5pODp5DcaBhvFDdeMro3B",
    site_key: "6Le8P9EZAAAAAO0CRIMLvhsFdMgbiuzAHVe-2rRK",
    google_client_id: "769503883758-i7jo3sns7senil55l4ug7tk2vk6afd94.apps.googleusercontent.com",
    facebook_app_id: "675772302928636",
    ip_api_key: "f8f9803926444dfb955e7e6d31c61b7c",
    google_map_api_key: "AIzaSyDLC0kopf1rYN8OaVXitspRC_xp-mzM3hQ"
};

export default info;