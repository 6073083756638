import ReactTable from 'react-table-6';
import React, { Component } from 'react';



const columns = [
  { Header: 'Interval', accessor: 'Interval', headerStyle: { whiteSpace: 'unset' }, style: { whiteSpace: 'unset' } },
  { Header: 'Served', accessor: 'Served', headerStyle: { whiteSpace: 'unset' }, style: { whiteSpace: 'unset' } }];
export default class ReactTableComponent extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    //     data : [],
    //     header:[],
    // };
  }
  render() {
    return (
      <div style={{ padding: '5px', paddingTop: '0px' }}>
        <ReactTable
          //manual
          minRows={0}
          defaultPageSize={10} //pageSize={1}
          className="-striped -highlight" //
          data={this.props.data}
          columns={columns}
          //pages={0}
          showPagination={false}
        />
      </div>
    );
  }
}
