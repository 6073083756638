import React from "react";
import LoadingOverlay from "react-loading-overlay";
import Select from "react-select";
import Switch from "react-bootstrap-switch";
import Firebase from "firebase";
import NotificationAlert from "react-notification-alert";
import CreatableSelect from 'react-select/creatable';
import config from "../../../config";
import IconUpload from "../../../components/CustomUpload/IconUpload";
// import { SingleDatePicker } from 'react-dates';
import { DateInput, DatePicker } from '@progress/kendo-react-dateinputs';
import trash from '../../trash-alt-solid.svg'

import {
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Collapse,
    Row,
    Button,
    Form,
    FormGroup,
    Label,
    Input
} from "reactstrap";

import uuidv1 from "uuid/v1";
// import publicIp from "public-ip";
// import geoTz from "geo-tz";
// import moment from "moment-timezone";
// import { d } from '@progress/kendo-date-math';

class BillingManualPaymentInvoiceAdd extends React.Component {
    constructor(props) {
        super(props);
        if (!Firebase.apps.length) {
            Firebase.initializeApp(config);
        }

        this.state = {
            loading: false,
            package_id: '',
            customer_id: '',
            selected_customer_id: '',
            invoice_id: '',
            invoice_id_payment_receipt: '',
            prefix1: 'in1_',
            prefix2: 'dm1_',
            document_payment_source: { value: 'Manual_Payment', label: 'Manual_Payment' },
            document_payment_source_options_list: '',
            document_payment_source_disable_selection: true,

            //invoice_created_date: '',
            //invoice_due_date: '',
            invoice_created_date_formatted: '',
            invoice_due_date_formatted: '',
            general_document_attachment_max_size_in_mb: 1,
            invoice_amount_due: 0,
            // invoice_amount_paid: 0,
            // invoice_amount_remaining: 0,
            // invalid_amount_paid: false,
            // invalid_amount_remaining: false,
            inputKey1: Math.random().toString(36),
            invoice_pdf_attachment: '',
            invoice_pdf_attachment_full_name: '',
            invoice_pdf_attachment_url: '',
            inputKey2: Math.random().toString(36),
            invoice_payment_receipt_pdf_attachment: '',
            invoice_payment_receipt_pdf_attachment_full_name: '',
            invoice_payment_receipt_pdf_attachment_url: '',
            invoice_remarks: '',
            invoice_status_list: '',
            invoice_status: { value: 'Open', label: 'Open' },
            // invoice_document_attachment_type: { value: 'Payment_Receipt', label: 'Payment_Receipt' },
            // invoice_document_attachment_type_list: '',

            selected_customer_idState: '',
            invoice_created_dateState: '',
            invoice_due_dateState: '',
            invoice_amount_dueState: '',
            invoice_pdf_attachmentState: '',
            invoice_payment_receipt_pdf_attachmentState: '',
            // invoice_amount_paidState: '',
            // invoice_amount_remainingState: '',
            invoice_statusState: 'has-success',
            // invoice_document_attachment_typeState: '',
            // billing_emailState: '',
            // billing_emailStateMessage: 'This field is required.',
            // billing_nameState: '',
            // billing_addressState: '',
            // billing_email: '',
            // billing_name: '',
            // billing_address: '',
            // billing_email_list: null,
        };
        this.inputFileRef1 = React.createRef();
        this.inputFileRef2 = React.createRef()
        // this.onFormSubmit = this.onFormSubmit.bind(this);

        // this.functions = null;                                              //functions emulator
        // this.fbStore = null;                                                //functions emulator
    }

    // initializeFirebase = () => {                                            //functions emulator
        // if (!this.functions) {                                              //functions emulator
            // this.functions = Firebase.functions();                          //functions emulator
            // this.functions.useFunctionsEmulator('http://localhost:5001');   //functions emulator
            // this.fbStore = Firebase.firestore();                            //functions emulator
        // }                                                                   //functions emulator
    // }                                                                       //functions emulator

    componentDidMount() {
    // this.initializeFirebase();                                          //functions emulator
        let _this = this;
        _this.auth_info = JSON.parse(localStorage.getItem('auth_info'));
        let role = _this.auth_info.role;
        //let customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;
        let timestamp = Math.round((new Date()). getTime() / 1000);
        let invoice_id_unformatted = Firebase.firestore().collection('Manual_Invoices').doc().id; //'in1_Roa223ZAMVC9cnoiEwqQJCzo'; //this.randomString(24);
        let invoice_id = _this.state.prefix1 + invoice_id_unformatted;
        let invoice_id_payment_receipt = _this.state.prefix2 + invoice_id_unformatted;
        _this.setState({ /*customer_id: customer_id,*/ role: role, invoice_id: invoice_id, invoice_id_payment_receipt: invoice_id_payment_receipt, loading: true });
        this.loadData();

        console.log("firebase_invoice_id", invoice_id);
        console.log("timestamp", timestamp);
        console.log("invoice_id", invoice_id);
        console.log("uuidv1()", uuidv1());
        console.log("generateUuid", this.generateUuid());
        console.log("randomString(24)", this.randomString(24));
    }

    loadData() {
        let _this = this;
        var role = this.auth_info.role;
        _this.setState({ loading: true });

        Firebase.firestore().collection('System_Config').doc('Upload_Limits').get().then(e => {
            _this.setState({
                general_document_attachment_max_size_in_mb: e.data().General_Document_Attachment_Max_Size_In_MB
            })
            console.log("general_document_attachment_max_size_in_mb", e.data().General_Document_Attachment_Max_Size_In_MB);
            _this.setState({ loading: false });
        }, (error) => {
            console.log("System_config error ==> ", error);
        })

        Firebase.firestore().collection('System_Config').doc('Manual_Payments').get().then(function (doc) {
            var invoice_statuses = doc.data().Invoice_Statuses;
            //var document_attachment_types = doc.data().Document_Attachment_Types;

            var invoice_status_list = []; //var invoice_document_attachment_type_list = [];
            invoice_statuses.forEach(function (status) { var status_one = { value: status, label: status }; invoice_status_list.push(status_one); });
            //document_attachment_types.forEach(function (type) { var type_one = { value: type, label: type }; invoice_document_attachment_type_list.push(type_one); });
            _this.setState({
                invoice_status_list: invoice_status_list,
                //invoice_document_attachment_type_list: invoice_document_attachment_type_list
            });
            console.log(
                'invoice_status_list', invoice_status_list,
                //'invoice_document_attachment_type_list', invoice_document_attachment_type_list
            );
        }).catch(function (err) {
            _this.setState({ loading: false });
            _this.notifyMessage("tc", 3, "Network error!");
            console.log('loadInvoiceStatuses error', err);
        });
    }

    randomString(length) {
        var result = '';
        var chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
        return result;
    }

    generateUuid() {
        return 'xxxxxxxxxxxxxxxxxxxxxxxx'.replace(/[x]/g, function (c) {
            // return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    // loadBillingInfo = () => {
    //     const _this = this;
    //     const { customer_id } = _this.auth_info;
    //     const document_payment_source = _this.state.document_payment_source.value;
    //     console.log("loadBillingInfo>>> customer_id", customer_id);
    //     console.log("document_payment_source.value>>> ", _this.state.document_payment_source.value);

    //     if (document_payment_source == 'Online_Payment') {
    //         console.log("document_payment_source>>> Online");
    //         Firebase.firestore().collection('Stripe_Customers').where('Antqueue_Customer_ID', '==', customer_id).get().then(result => {
    //             const billing_email_list = [];
    //             result.docs.forEach(stripe_customer => {
    //                 const { Stripe_Billing_User_Email, Stripe_Billing_User_Name, Stripe_Billing_User_Address } = stripe_customer.data();
    //                 const option = {
    //                     id: stripe_customer.id,
    //                     email: Stripe_Billing_User_Email,
    //                     label: Stripe_Billing_User_Email,
    //                     value: stripe_customer.id,
    //                     billing_name: Stripe_Billing_User_Name,
    //                     billing_address: Stripe_Billing_User_Address
    //                 };
    //                 billing_email_list.push(option);
    //             });
    //             _this.setState({ billing_email_list }, () => {
    //                 console.log("_this.state.billing_email_list: ", _this.state.billing_email_list);
    //             });
    //         })
    //             .catch(function (error) {
    //                 _this.setState({ loading: false });
    //                 _this.notifyMessage("tc", 3, "Network error!");
    //                 console.log("handleSave NetworkError9==>", error);
    //             });
    //     } else if (document_payment_source == 'Manual_Payment') {
    //         console.log("document_payment_source>>> Manual");
    //         // Handle Manual Payments
    //     } else {
    //         console.log("document_payment_source>>> ", document_payment_source);
    //     }
    // };

    collapsesToggle = collapse => {
        let openedCollapses = this.state.openedCollapses;
        if (openedCollapses.includes(collapse)) {
            this.setState({
                openedCollapses: openedCollapses.filter(item => item !== collapse)
            });
        } else {
            openedCollapses.push(collapse);
            this.setState({
                openedCollapses: openedCollapses
            });
        }
    };
    
    async handleAdd() {

        let _this = this;
        _this.setState({ loading: true });

        console.log("handleAdd>>1  selected_customer_idState, invoice_created_dateState, invoice_due_dateState, invoice_amount_dueState, invoice_pdf_attachmentState, invoice_payment_receipt_pdf_attachmentState, invoice_statusState", selected_customer_idState, invoice_created_dateState, invoice_due_dateState, invoice_amount_dueState, invoice_pdf_attachmentState, invoice_payment_receipt_pdf_attachmentState, invoice_statusState);
        const { selected_customer_idState, invoice_created_dateState, invoice_due_dateState, invoice_amount_dueState, invoice_pdf_attachmentState, invoice_payment_receipt_pdf_attachmentState, invoice_statusState } = this.state; //invoice_amount_paidState, invoice_amount_remainingState 
        // const { nameState, billing_emailState, billing_nameState, billing_addressState } = _this.state;
        
        if (selected_customer_idState === "") { _this.setState({ selected_customer_idState: "has-danger" }); }
        if (invoice_created_dateState === "") { _this.setState({ invoice_created_dateState: "has-danger" }); }
        if (invoice_due_dateState === "") { _this.setState({ invoice_due_dateState: "has-danger" }); }
        if (invoice_amount_dueState === "") { _this.setState({ invoice_amount_dueState: "has-danger" }); } //invoice_amount_paidState, invoice_amount_remainingState 
        if (invoice_pdf_attachmentState === "") { _this.setState({ invoice_pdf_attachmentState: "has-danger" }); }     //if (invoice_payment_receipt_pdf_attachmentState === "") { _this.setState({ invoice_payment_receipt_pdf_attachmentState: "has-danger" }); }
        if (invoice_statusState === "") { _this.setState({ invoice_statusState: "has-danger" }); }

        if (selected_customer_idState === "has-success" && invoice_created_dateState === "has-success" && invoice_due_dateState === "has-success"
            && invoice_amount_dueState === "has-success" && invoice_pdf_attachmentState === "has-success" //&& invoice_payment_receipt_pdf_attachmentState === "has-success"
            && invoice_statusState === "has-success") {
            // if (nameState === "has-success" && billing_emailState === "has-success" && billing_nameState === "has-success" && billing_addressState === "has-success") {
            // const { billing_email, billing_name, billing_address } = _this.state;

            var now = new Date();

            //var inputFileRef1 = _this.inputFileRef1.current.files != undefined ? _this.inputFileRef1.current.files : '';
            var inputFileRef2 = _this.inputFileRef2.current.files != undefined ? _this.inputFileRef2.current.files : '';
            //var file1 = inputFileRef1[0];
            var file2 = inputFileRef2[0];

            if (file2 !== null && file2 !== '' && typeof file2 !== 'undefined') {

                Firebase.firestore().collection('Customers').doc(_this.state.selected_customer_id).get().then(function (own_customer_info) {
                    if (own_customer_info.exists) {
                        let new_invoice_data = {
                            Invoice_Customer_ID: (_this.state.selected_customer_id).trim(),
                            Invoice_ID: _this.state.invoice_id,
                            Invoice_Created_Date: _this.state.invoice_created_date_formatted,
                            Invoice_Amount: _this.state.invoice_amount_due, // Invoice_Amount_Paid: _this.state.invoice_amount_paid, Invoice_Amount_Remaining: _this.state.invoice_amount_remaining,
                            Invoice_Due_Date: _this.state.invoice_due_date_formatted,
                            Invoice_Status: _this.state.invoice_status.value,
                            Invoice_Payment_Source: _this.state.document_payment_source.value,
                            Invoice_PDF_Attachment_URL: _this.state.invoice_pdf_attachment_url,
                            Invoice_Payment_Receipt_PDF_Attachment_URL: _this.state.invoice_payment_receipt_pdf_attachment_url,
                        };
                        Firebase.firestore().collection('Manual_Invoices').doc(_this.state.invoice_id).set(new_invoice_data).then(function (response) {
                            let new_document_data = {
                                Document_Customer_ID: (_this.state.selected_customer_id).trim(),
                                Document_ID: _this.state.invoice_id_payment_receipt,
                                Document_Invoice_Reference_ID: _this.state.invoice_id,
                                Document_Created_Date: _this.state.invoice_created_date_formatted,
                                Document_Payment_Source: _this.state.document_payment_source.value,
                                Document_PDF_Attachment_URL: _this.state.invoice_payment_receipt_pdf_attachment_url,
                                Document_Type: 'Payment_Receipt',
                            }
                            Firebase.firestore().collection('Manual_Document_Attachments').doc(_this.state.invoice_id_payment_receipt).set(new_document_data).then(function (response) {
                                _this.setState({ loading: false });
                                console.log("handleAdd fileRef2 success");
                                _this.notifyMessage("tc", 2, "New Invoice added successfully.");
                                window.setTimeout(function () { _this.props.history.push("/billing/manual_subscriptions") }, 2000);
                            }).catch(function (err) {
                                _this.setState({ loading: false });
                                _this.notifyMessage("tc", 3, "Network error!");
                                console.log("handleAdd NetworkError8==>", err);
                            });
                        }).catch(function (err) {
                            _this.setState({ loading: false });
                            _this.notifyMessage("tc", 3, "Network error!");
                            console.log("handleAdd NetworkError8==>", err);
                        });
                    } else {
                        _this.setState({ loading: false });
                        _this.notifyMessage("tc", 3, "Network error!");
                        console.log("handleAdd NetworkError11");
                    }
                }).catch(function (err) {
                    _this.setState({ loading: false });
                    _this.notifyMessage("tc", 3, "Network error!");
                    console.log("handleAdd NetworkError12==>", err);
                });

            } else {
                Firebase.firestore().collection('Customers').doc(_this.state.selected_customer_id).get().then(function (own_customer_info) {
                    if (own_customer_info.exists) {
                        let new_invoice_data = {
                            Invoice_Customer_ID: (_this.state.selected_customer_id).trim(),
                            Invoice_ID: _this.state.invoice_id,
                            Invoice_Created_Date: _this.state.invoice_created_date_formatted,
                            Invoice_Amount: _this.state.invoice_amount_due, // Invoice_Amount_Paid: _this.state.invoice_amount_paid, Invoice_Amount_Remaining: _this.state.invoice_amount_remaining,
                            Invoice_Due_Date: _this.state.invoice_due_date_formatted,
                            Invoice_Status: _this.state.invoice_status.value,
                            Invoice_Payment_Source: _this.state.document_payment_source.value,
                            Invoice_PDF_Attachment_URL: _this.state.invoice_pdf_attachment_url,
                            Invoice_Payment_Receipt_PDF_Attachment_URL: _this.state.invoice_payment_receipt_pdf_attachment_url,
                        };
                        Firebase.firestore().collection('Manual_Invoices').doc(_this.state.invoice_id).set(new_invoice_data).then(function (response) {
                            _this.setState({ loading: false });
                            console.log("handleAdd fileRef1 success");
                            _this.notifyMessage("tc", 2, "New Invoice added successfully.");
                            window.setTimeout(function () { _this.props.history.push("/billing/manual_subscriptions") }, 2000);
                        }).catch(function (err) {
                            _this.setState({ loading: false });
                            _this.notifyMessage("tc", 3, "Network error!");
                            console.log("handleAdd NetworkError8==>", err);
                        });
                    } else {
                        _this.setState({ loading: false });
                        _this.notifyMessage("tc", 3, "Network error!");
                        console.log("handleAdd NetworkError11");
                    }
                }).catch(function (err) {
                    _this.setState({ loading: false });
                    _this.notifyMessage("tc", 3, "Network error!");
                    console.log("handleAdd NetworkError12==>", err);
                });
            }
        } else {
            _this.setState({ loading: false });
            _this.notifyMessage("tc", 3, "Please make sure all required fields are filled.");
        }
    }
    increaseAmount(field) {
        if (field == "invoice_amount_due") {
            this.setState({ invoice_amount_due: parseInt(this.state.invoice_amount_due) + 1 });
            if (parseInt(this.state.start_number) < 0 || Number.isNaN(parseInt(this.state.start_number)) == false) { this.setState({ invoice_amount_dueState: "has-danger" }); } else { this.setState({ invoice_amount_dueState: "has-success" }); }
        }
        // else if (field == "invoice_amount_paid") {
        //     this.setState({invoice_amount_paid: parseInt(this.state.invoice_amount_paid) + 1});
        //     if (parseInt(this.state.start_number) < 0 || Number.isNaN(parseInt(this.state.start_number)) == false) { this.setState({invoice_amount_dueState: "has-danger"}); } else { this.setState({invoice_amount_dueState: "has-success"}); }
        // } 
        // else if (field == "invoice_amount_remaining") {
        //     this.setState({invoice_amount_remaining: parseInt(this.state.invoice_amount_remaining) + 1});
        //     if (parseInt(this.state.start_number) < 0 || Number.isNaN(parseInt(this.state.start_number)) == false) { this.setState({invoice_amount_dueState: "has-danger"}); } else { this.setState({invoice_amount_dueState: "has-success"}); }
        // }
    }
    decreaseAmount(field) {
        if (field == "invoice_amount_due") {
            this.setState({ invoice_amount_due: parseInt(this.state.invoice_amount_due) - 1 });
            if (parseInt(this.state.start_number) < 0 || Number.isNaN(parseInt(this.state.start_number)) == false) { this.setState({ invoice_amount_dueState: "has-danger" }); } else { this.setState({ invoice_amount_dueState: "has-success" }); }
        }
        // else if (field == "invoice_amount_paid") {
        //     this.setState({invoice_amount_paid: parseInt(this.state.invoice_amount_paid) - 1});
        //     if (parseInt(this.state.start_number) < 0 || Number.isNaN(parseInt(this.state.start_number)) == false) { this.setState({invoice_amount_dueState: "has-danger"}); } else { this.setState({invoice_amount_dueState: "has-success"}); }
        // }
        // else if (field == "invoice_amount_remaining") {
        //     this.setState({invoice_amount_remaining: parseInt(this.state.invoice_amount_remaining) - 1});
        //     if (parseInt(this.state.start_number) < 0 || Number.isNaN(parseInt(this.state.start_number)) == false) { this.setState({invoice_amount_dueState: "has-danger"}); } else { this.setState({invoice_amount_dueState: "has-success"}); }
        // }
    }
    notifyMessage = (place, color, text) => {
        var type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }

        var options = {};
        options = {
            place: place,
            message: (
                <div className="text-md-center">
                    <div>
                        <b>{text}</b>
                    </div>
                </div>
            ),
            type: type,
            icon: "now-ui-icons ui-1_bell-53",
            autoDismiss: 3
        };
        if (options !== null) { this.notificationAlert.notificationAlert(options) }
    };
    // isValidBillingEmail = value => {
    //     let _this = this;
    //     let customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;
    //     Firebase.firestore().collection('Stripe_Customers').where('Stripe_Billing_User_Email', '==', value).get().then(function (response) {
    //         if (response.docs.length >= 1 && response.docs[0].data().Antqueue_Customer_ID !== customer_id) {
    //             console.log(' validEmail = false'); return false;
    //         }
    //         else {
    //             console.log(' validEmail = true'); return true;
    //         }
    //     })
    //         .catch(function (error) {
    //             _this.setState({ loading: false });
    //             _this.notifyMessage("tc", 3, "Network error!");
    //             console.log("isValidBillingEmail NetworkError==>", error);
    //         });

    //     return false;
    // };
    verifyLength = (value, length) => {
        return value.length >= length;
    };
    changeDropDownSelect(value, option) {
        if (option == 'changeInvoiceStatus') { this.setState({ invoice_statusState: "has-success" }); this.setState({ invoice_status: value }); }
        //else if (option == 'changeInvoiceDocumentType') { this.setState({ invoice_document_attachment_typeState: "has-success" }); this.setState({ invoice_document_attachment_type: value }); }
    }
    change = (event, stateName, type, stateNameEqualTo) => {
        switch (type) {
            case "length":
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + "State"]: "has-success" });
                } else {
                    this.setState({ [stateName + "State"]: "has-danger" });
                }
                break;
            case "selected_customer_id":
                var custIDRex = /^0[\d]{11}$/; //0 followed by 11 digits, total 12 digits
                if (custIDRex.test(event.target.value)) {
                    this.setState({ [stateName + "State"]: "has-success" });
                }
                else{
                    this.setState({ [stateName + "State"]: "has-danger" });
                }
                break;
            case "invoice_created_date":
                var dateString1 = new Date(event.target.value); var year1 = dateString1.getFullYear().toString(); var month1 = dateString1.getMonth() + 1; var day1 = dateString1.getDate();
                var timestamp1 = Date.parse(dateString1); var d1 = new Date(timestamp1);
                if (isNaN(timestamp1) == false && parseInt(year1) >= 2019) {
                    this.setState({ [stateName + "State"]: "has-success", invoice_created_date_formatted: d1 });
                    console.log("(isNaN(timestamp1) == false)");
                } else {
                    this.setState({ [stateName + "State"]: "has-danger" });
                    console.log("(isNaN(timestamp1) == true)");
                }
                console.log("d1", d1); console.log("event.target.value", event.target.value); console.log("dateString1", dateString1); console.log("day1-month1-year1", day1 + "-" + month1 + "-" + year1);      
                break;          
            case "invoice_due_date":
                var dateString2 = new Date(event.target.value); var year2 = dateString2.getFullYear().toString(); var month2 = dateString2.getMonth() + 1; var day2 = dateString2.getDate();
                var timestamp2 = Date.parse(dateString2); var d2 = new Date(timestamp2);
                if (isNaN(timestamp2) == false && parseInt(year2) >= 2019) {
                    this.setState({ [stateName + "State"]: "has-success", invoice_due_date_formatted: d2 });
                    console.log("(isNaN(timestamp2) == false)");
                } else {
                    this.setState({ [stateName + "State"]: "has-danger" });
                    console.log("(isNaN(timestamp2) == true)");
                }
                console.log("d2", d2); console.log("event.target.value", event.target.value); console.log("dateString2", dateString2); console.log("day2-month2-year2", day2 + "-" + month2 + "-" + year2);
                break;
            case "invoice_amount_due":
                var amountDueString = parseInt(event.target.value);
                if (isNaN(amountDueString) == false && amountDueString >= 1) {
                    this.setState({ [stateName + "State"]: "has-success" });
                    console.log("(isNaN(amount_dueState) == false)");
                } else {
                    this.setState({ [stateName + "State"]: "has-danger" });
                    console.log("(isNaN(amount_dueState) == true)");
                }
                console.log("event.target.value", event.target.value); //console.log("amountDueString", amountDueString);
                break;
            default:
                break;
        }
        this.setState({ [stateName]: event.target.value });
    };

    // handleBillingEmailChange = (newValue, actionMeta) => {
    //     let _this = this;
    //     if (newValue) {
    //         const { __isNew__, billing_name, billing_address, email, value } = newValue;

    //         const _email_ = __isNew__ ? value : email;
    //         const _name_ = __isNew__ ? '' : billing_name;
    //         const _address_ = __isNew__ ? '' : billing_address;

    //         let customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;
    //         Firebase.firestore().collection('Stripe_Customers').where('Stripe_Billing_User_Email', '==', _email_).get().then(function (response) {
    //             if (response.docs.length >= 1 && response.docs[0].data().Antqueue_Customer_ID !== customer_id) {
    //                 console.log(' validEmail = false');
    //                 _this.setState({ billing_emailState: "has-danger" });
    //                 _this.setState({
    //                     billing_emailStateMessage: "This email address is already being used by another Customer. Please provide a different email address!",
    //                 });
    //             }
    //             else {
    //                 console.log(' validEmail = true');
    //                 _this.setState({
    //                     billing_emailStateMessage: "This field is required.",
    //                     billing_emailState: "has-success",
    //                     selected_stripe_customer: newValue,
    //                     billing_email: _email_,
    //                     billing_name: _name_,
    //                     billing_address: _address_,
    //                 });
    //                 console.log({
    //                     selected_stripe_customer: newValue,
    //                     billing_email: email,
    //                     billing_name: _name_,
    //                     billing_address: _address_,
    //                 });
    //                 _this.change({ target: { value: _name_ || '' } }, "billing_name", "length", 1);
    //                 _this.change({ target: { value: _address_ || '' } }, "billing_address", "length", 1);
    //             }
    //         })
    //             .catch(function (error) {
    //                 _this.setState({ loading: false });
    //                 _this.notifyMessage("tc", 3, "Network error!");
    //                 console.log("isValidBillingEmail NetworkError==>", error);
    //             });

    //         // if (!_this.isValidBillingEmail(_email_)) {
    //         //     _this.setState({ billing_emailState: "has-danger" });
    //         // }
    //         // else {
    //         //     _this.setState({
    //         //         billing_emailState: "has-success",
    //         //         selected_stripe_customer: newValue,
    //         //         billing_email: _email_,
    //         //         billing_name: _name_,
    //         //         billing_address: _address_,
    //         //     });
    //         //     console.log({
    //         //         selected_stripe_customer: newValue,
    //         //         billing_email: email,
    //         //         billing_name: _name_,
    //         //         billing_address: _address_,
    //         //     });
    //         //     _this.change({target: {value:_name_ || ''}}, "billing_name", "length", 1);
    //         //     _this.change({target: {value:_address_ || ''}}, "billing_address", "length", 1);
    //         // }

    //     } else {
    //         _this.setState({
    //             selected_stripe_customer: null,
    //             billing_email: '',
    //             billing_name: '',
    //             billing_address: '',
    //             billing_nameState: '',
    //             billing_addressState: '',
    //         });
    //     }
    // };

    handleAttachment(e, field) {
        var _this = this; var ok = false; var customerID = _this.state.selected_customer_id;
        var elementID = ""; var inputFileRef = ""; var fileName = ""; var fileNameWithExt = ""; var attachment_file = ""; var attachment_file_name = "";
        if (field == "invoice_pdf_attachment") {
            elementID = "uploader1";
            inputFileRef = this.inputFileRef1.current.files;
            fileName = _this.state.invoice_id;           
        }
        else if (field == "invoice_payment_receipt_pdf_attachment") {
            elementID = "uploader2";
            inputFileRef = this.inputFileRef2.current.files;
            fileName = _this.state.invoice_id_payment_receipt;
        }
        attachment_file = inputFileRef[0];

        if (/*e.target.files[0]*/ attachment_file != undefined) {
            attachment_file_name = attachment_file.name; /*e.target.files[0]*/
            console.log("attachment_file_name", attachment_file_name);
            console.log("fileName", fileName);
            console.log("attachment_file_name.split('.')[1]", attachment_file_name.split('.')[1]);
            fileNameWithExt = fileName+'.'+attachment_file_name.split('.')[1]; 
            if (field == "invoice_pdf_attachment") {
                _this.setState({invoice_pdf_attachment_full_name: fileNameWithExt});
            }
            else if (field == "invoice_payment_receipt_pdf_attachment") {
                _this.setState({invoice_payment_receipt_pdf_attachment_full_name: fileNameWithExt});
            }
            switch (attachment_file_name.slice((attachment_file_name.lastIndexOf(".") - 1 >>> 0) + 2)) { //attachment_file_name.split('.')[1] 
                case 'pdf': ok = true; break;
                case 'png': ok = true; break;
                case 'jpg': ok = true; break;
                case 'jpeg': ok = true; break;
                case 'webp': ok = true; break;
                default: ok = false; break;
            }
        }
        if (/*e.target.files[0]*/ attachment_file != undefined && ok) {
            var task = "";
            var customer_id = (_this.state.selected_customer_id).trim();
            let metadata = {};
            console.log("this.state.invoice_id, customer_id", _this.state.invoice_id, customer_id);
            if (field == "invoice_pdf_attachment") {
                metadata = {
                    customMetadata: {
                        invoice_id: _this.state.invoice_id,
                        customer_id: customer_id,
                        document_type: 'Invoice'
                    }
                };
            } else if (field == "invoice_payment_receipt_pdf_attachment") {
                metadata = {
                    customMetadata: {
                        invoice_reference_id: _this.state.invoice_id,
                        document_id: _this.state.invoice_id_payment_receipt,
                        customer_id: customer_id,
                        document_type: 'Payment_Receipt'
                    }
                };
            }
        
            if (/*e.target.files[0]*/ attachment_file.size <= _this.state.general_document_attachment_max_size_in_mb * 1024 * 1024) {
                if (field == "invoice_pdf_attachment") {
                    var storageRef1 = Firebase.storage().ref('Payments/Manual_Invoices/'); console.log("handleAttachment storageRef1", storageRef1);
                    var fileName1 = fileNameWithExt; /*_this.state.invoice_id;*/ console.log("handleAttachment fileName1", fileName1);
                    var fileRef1 = storageRef1.child(customerID + '/' + fileName1); console.log("handleAttachment fileRef1", fileRef1);
                    task = fileRef1.put(attachment_file, metadata)
                } else if (field == "invoice_payment_receipt_pdf_attachment") {
                    var storageRef2 = Firebase.storage().ref('Payments/Other_Documents/'); console.log("handleAttachment storageRef1", storageRef1);
                    var fileName2 = fileNameWithExt; /*_this.state.invoice_id;*/ console.log("handleAttachment fileName2", fileName2);
                    var fileRef2 = storageRef2.child(customerID + '/' + fileName2); console.log("handleAttachment fileRef2", fileRef2);
                    task = fileRef2.put(attachment_file, metadata)
                }
                /*.then(res=>console.log('uploadsuccess'),err=>console.log('uploadfailed...',err))*/
                document.getElementById(elementID).style.display = 'block';

                task.on('state_changed', (snapshot) => {
                    var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    document.getElementById(elementID).value = percentage;

                }, () => { }, () => {
                    let _this = this, j = 0, ad = [], o = 0
                    if (field == "invoice_pdf_attachment") {
                        _this.setState({ invoice_pdf_attachmentState: "has-success" }); 
                    // .then(function (snapshot) {
                        fileRef1.getDownloadURL().then(function (res1) {
                            _this.setState({invoice_pdf_attachment_url: res1});
                            console.log("handleAttachment invoice_pdf_attachment_url", res1);
                        })
                    // })
                    }
                    else if (field == "invoice_payment_receipt_pdf_attachment") {
                        _this.setState({ invoice_payment_receipt_pdf_attachmentState: "has-success" });
                    // .then(function (snapshot) {
                        fileRef2.getDownloadURL().then(function (res2) {
                            _this.setState({invoice_payment_receipt_pdf_attachment_url: res2});
                            console.log("handleAttachment invoice_payment_receipt_pdf_attachment_url", res2);
                        })
                    // })
                    }
                    document.getElementById(elementID).style.display = 'none';
                })
                task.catch(error => {
                    if (field == "invoice_pdf_attachment") { _this.setState({ invoice_pdf_attachmentState: "has-danger" }); }
                    else if (field == "invoice_payment_receipt_pdf_attachment") { _this.setState({ invoice_payment_receipt_pdf_attachmentState: "has-danger" }); }
                    console.log('pdf upload error==> ', error);
                })
            }
            else {
                _this.notifyMessage("tc", 3, "Pdf attachment size must be less than " + _this.state.general_document_attachment_max_size_in_mb + " MB");
                if (field == "invoice_pdf_attachment") { _this.setState({ invoice_pdf_attachmentState: "has-danger" }); }
                else if (field == "invoice_payment_receipt_pdf_attachment") { _this.setState({ invoice_payment_receipt_pdf_attachmentState: "has-danger" }); }
            }
        } else {
            console.log("fileName", fileName); console.log("attachment_file", attachment_file);
            if (field == "invoice_pdf_attachment") { _this.setState({ invoice_pdf_attachmentState: "has-danger" }); }
            else if (field == "invoice_payment_receipt_pdf_attachment") { _this.setState({ invoice_payment_receipt_pdf_attachmentState: "has-danger" }); }
        }
    }

    render() {
        var ok = false
        // console.log(this.state.role)
        if (this.state.role == "Site_Admin" || this.state.role == "System_Admin" || this.state.role == "Location_Admin" || this.state.role == "Billing_Admin")
            ok = true
        // let { nameState, billing_emailState, billing_emailStateMessage, billing_nameState, billing_addressState, } = this.state;
        let { selected_customer_idState, invoice_created_dateState, invoice_due_dateState, invoice_amount_dueState, invoice_pdf_attachmentState, invoice_payment_receipt_pdf_attachmentState, invoice_statusState } = this.state; //invoice_document_attachment_typeState

        // const { billing_email_list, billing_name, billing_address } = this.state;

        return (
            <>
                <LoadingOverlay
                    active={this.state.loading}
                    spinner
                    text='Loading'
                    className='content'
                >
                    <NotificationAlert ref={(ref) => this.notificationAlert = ref} />
                    <Row>
                        <Col md="12">
                            {ok ? <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Invoice Add</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Col className="ml-auto mr-auto" xl="8" lg="10" md="12">
                                        <Row>
                                            <Col md="3">
                                                <Button
                                                    color="success"
                                                    onClick={e => this.handleAdd()}
                                                    block
                                                >
                                                    Add
                                                </Button>
                                            </Col>
                                            <Col md="3">
                                                <Button
                                                    color="youtube"
                                                    onClick={e => this.props.history.push("/locations")}
                                                    block
                                                >
                                                    Close
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row className="top-margin-10">
                                        </Row>
                                        <Row>
                                            <Col xl="8" lg="12" md="12">
                                                <Form className="form-horizontal">
                                                    {/* <Row>
                                                        <Label md="4">Main Location</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Select
                                                                    className="react-select primary"
                                                                    classNamePrefix="react-select"
                                                                    name="mainLocationSelect"
                                                                    value={this.state.selected_main_location}
                                                                    onChange={value =>
                                                                        this.setState({ selected_main_location: value })
                                                                    }
                                                                    options={this.state.main_location_list}
                                                                    placeholder="Select Main Location"
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row> */}
                                                    <Row>
                                                        <Label md="4">Invoice Key</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Input
                                                                    type="text"
                                                                    defaultValue={this.state.invoice_id}
                                                                    disabled
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Selected Customer ID</Label>
                                                        <Col md="8">
                                                            <FormGroup className={`has-label ${selected_customer_idState}`}>
                                                                <Input
                                                                    value={this.state.selected_customer_id}
                                                                    type="text"
                                                                    placeholder="0XXXXXXXXXXX"
                                                                    onChange={e =>
                                                                        this.change(e, "selected_customer_id", "selected_customer_id", 1)
                                                                    }
                                                                />
                                                                {this.state.selected_customer_idState === "has-danger" ? (
                                                                    <label className="error">This field is required.</label>
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Created Date</Label>
                                                        <Col md="8">
                                                            <FormGroup className={`has-label ${invoice_created_dateState}`}>
                                                                <DatePicker
                                                                    format="dd-MM-yyyy"
                                                                    value={this.state.invoice_created_date}
                                                                    onChange={e =>
                                                                        this.change(e, "invoice_created_date", "invoice_created_date")
                                                                    }
                                                                />
                                                                {this.state.invoice_created_dateState === "has-danger" ? (
                                                                    <label className="error">This field is required.</label>
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Due Date</Label>
                                                        <Col md="8">
                                                            <FormGroup className={`has-label ${invoice_due_dateState}`}>
                                                                <DatePicker
                                                                    format="dd-MM-yyyy"
                                                                    value={this.state.invoice_due_date}
                                                                    onChange={e =>
                                                                        this.change(e, "invoice_due_date", "invoice_due_date")
                                                                    }
                                                                />
                                                                {this.state.invoice_due_dateState === "has-danger" ? (
                                                                    <label className="error">This field is required.</label>
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row className="top-margin-10" />
                                                    <Row>
                                                        <Label md="4">Amount Due (USD)</Label>
                                                        <Col md="8">
                                                            <FormGroup className={`has-label ${invoice_amount_dueState}`}>
                                                                <Row>
                                                                    <Col md="6" xs="6">
                                                                        <FormGroup>
                                                                            <Input
                                                                                value={this.state.invoice_amount_due}
                                                                                type="number"
                                                                                onChange={e =>
                                                                                    this.change(e, "invoice_amount_due", "invoice_amount_due")
                                                                                }
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col md="6" xs="6" className="padding-left-0">
                                                                        <Button
                                                                            className="margin-top-0"
                                                                            color="success"
                                                                            onClick={e => this.increaseAmount("invoice_amount_due")}
                                                                        >
                                                                            <i className="fa fa-plus" />
                                                                        </Button>
                                                                        <Button
                                                                            className="margin-top-0"
                                                                            color="success"
                                                                            onClick={e => this.decreaseAmount("invoice_amount_due")}
                                                                        >
                                                                            <i className="fa fa-minus" />
                                                                        </Button>
                                                                    </Col>
                                                                </Row>
                                                                {this.state.invoice_amount_dueState === "has-danger" ? (
                                                                    <label className="error">This field is required.</label>
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row md='12' className='px-0 mx-0'>
                                                        <Label md="4">Invoice PDF</Label>
                                                        <Col md='8' style={{ paddingLeft: '0px', paddingBottom: '15px' }} >
                                                            <progress id="uploader1" value="0" max="100" style={{ display: 'none' }}>0%</progress>
                                                            <input type="file" id='file1' ref={this.inputFileRef1} key={this.state.inputKey1}
                                                                onChange={e => this.handleAttachment(e, "invoice_pdf_attachment")}
                                                            />
                                                            {this.state.invoice_pdf_attachment_full_name != undefined ?
                                                                (this.state.invoice_pdf_attachment_full_name.length > 0 ?
                                                                    <Row md='12' className='px-0 mx-0'>
                                                                    <img src={trash} style={{ width: '15px', marginTop: '3px' }} alt=""
                                                                        onClick={() => {
                                                                            console.log("this.state.invoice_pdf_attachment_full_name", this.state.invoice_pdf_attachment_full_name);
                                                                            var customerID = this.state.selected_customer_id;
                                                                            var task1 = Firebase.storage().ref('Payments/Manual_Invoices/' + customerID + '/').child(this.state.invoice_pdf_attachment_full_name).delete();
                                                                            task1.catch(error => { 
                                                                                console.log("task1 error", error);
                                                                            })
                                                                            
                                                                            this.setState({
                                                                                inputKey1: Math.random().toString(36),
                                                                                invoice_pdf_attachment: '',
                                                                                invoice_pdf_attachment_full_name: '',
                                                                                invoice_pdf_attachment_url: '',
                                                                                invoice_pdf_attachmentState: 'has-danger',
                                                                            })
                                                                        }}
                                                                    /></Row> : null)
                                                                : null}
                                                            {this.state.invoice_pdf_attachmentState === "has-danger" ? (
                                                                <div className="has-label has-danger position-relative form-group">
                                                                    <label className="error">This field is required.</label>
                                                                </div>
                                                            ) : null}
                                                        </Col>
                                                    </Row>
                                                    <Row md='12' className='px0 mx-0'>
                                                        <Label md="4">Invoice Payment Receipt PDF</Label>
                                                        <Col md='8' style={{ paddingLeft: '0px', paddingBottom: '15px' }} >
                                                            <progress id="uploader2" value="0" max="100" style={{ display: 'none' }}>0%</progress>
                                                            <input type="file" id='file2' ref={this.inputFileRef2} key={this.state.inputKey2}
                                                                onChange={e => this.handleAttachment(e, "invoice_payment_receipt_pdf_attachment")}
                                                            />
                                                            {this.state.invoice_payment_receipt_pdf_attachment_full_name != undefined ?
                                                                (this.state.invoice_payment_receipt_pdf_attachment_full_name.length > 0 ?
                                                                    <Row md='12' className='px-0 mx-0'>
                                                                    <img src={trash} style={{ width: '15px', marginTop: '3px' }} alt=""
                                                                        onClick={() => {
                                                                            console.log("this.state.invoice_payment_receipt_pdf_attachment_full_name", this.state.invoice_payment_receipt_pdf_attachment_full_name);
                                                                            var customerID = this.state.selected_customer_id;
                                                                            var task2 = Firebase.storage().ref('Payments/Manual_Invoices/' + customerID + '/').child(this.state.invoice_payment_receipt_pdf_attachment_full_name).delete();
                                                                            task2.catch(error => { 
                                                                                console.log("task2 error", error);
                                                                            })
                                                                            
                                                                            this.setState({
                                                                                inputKey2: Math.random().toString(36),
                                                                                invoice_payment_receipt_pdf_attachment: '',
                                                                                invoice_payment_receipt_pdf_attachment_full_name: '',
                                                                                invoice_payment_receipt_pdf_attachment_url: '',
                                                                                invoice_payment_receipt_pdf_attachmentState: 'has-danger',
                                                                            })
                                                                        }}
                                                                    /></Row> : null)
                                                                : null}
                                                            {/* {this.state.invoice_pdf_attachmentState === "has-danger" ? (
                                                                <div className="has-label has-danger position-relative form-group">
                                                                    <label className="error">This field is required.</label>
                                                                </div>
                                                            ) : null} */}
                                                        </Col>
                                                    </Row>
                                                    <Row className="top-margin-10">
                                                        <Label md="4">Remarks</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Input
                                                                    defaultValue={this.state.invoice_remarks}
                                                                    type="textarea"
                                                                    onChange={e =>
                                                                        this.change(e, "invoice_remarks", "length", 1)
                                                                    }
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Payment Source</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Select
                                                                    className="react-select primary"
                                                                    classNamePrefix="react-select"
                                                                    //name="document_payment_sourceSelect"
                                                                    value={this.state.document_payment_source}
                                                                    isDisabled={this.state.document_payment_source_disable_selection}
                                                                    onChange={value => this.changeDropDownSelect(value, "changePaymentSource")}
                                                                    options={this.state.document_payment_source_options_list}
                                                                    placeholder="Select Payment Source"
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Invoice Status</Label>
                                                        <Col md="8">
                                                            <FormGroup className={`has-label ${invoice_statusState}`}>
                                                                <Select
                                                                    className="react-select primary"
                                                                    classNamePrefix="react-select"
                                                                    name="invoice_statusSelect"
                                                                    value={this.state.invoice_status}
                                                                    onChange={value => this.changeDropDownSelect(value, "changeInvoiceStatus")}
                                                                    options={this.state.invoice_status_list}
                                                                    placeholder="Select Invoice status"
                                                                />
                                                                {this.state.invoice_statusState === "has-danger" ? (
                                                                    <label className="error">This field is required.</label>
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    {/* <Row>
                                                        <Label md="4">Document Type</Label>
                                                        <Col md="8">
                                                            <FormGroup className={`has-label ${invoice_document_attachment_typeState}`}>
                                                                <Select
                                                                    className="react-select primary"
                                                                    classNamePrefix="react-select"
                                                                    name="document_attachment_typeSelect"
                                                                    value={this.state.invoice_document_attachment_type}
                                                                    onChange={value => this.changeDropDownSelect(value, "changeInvoiceDocumentType") }
                                                                    options={this.state.invoice_document_attachment_type_list}
                                                                    placeholder="Select Document Type"
                                                                />
                                                                {this.state.invoice_document_attachment_typeState === "has-danger" ? (
                                                                    <label className="error">This field is required.</label>
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row> */}
                                                    {/* <Row>
                                                        <Label md="4">Icon</Label>
                                                        <Col md="8">
                                                            <IconUpload ref="icon" />
                                                        </Col>
                                                    </Row> */}
                                                    {/* <Row>
                                                        <Label md="4">Inactive/Active</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Row className="top-margin-7" />
                                                                <Switch
                                                                    offColor="success"
                                                                    offText={<i className="nc-icon nc-simple-remove" />}
                                                                    onColor="success"
                                                                    onText={<i className="nc-icon nc-check-2" />}
                                                                    defaultValue={this.state.active}
                                                                    value={this.state.active}
                                                                    onChange={e => { this.setState({ active: e.state.value }) }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row> */}
                                                    {/* <Row>
                                                        <Label md="4">Time Zone</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Select
                                                                    className="react-select primary"
                                                                    classNamePrefix="react-select"
                                                                    name="timezoneSelect"
                                                                    value={this.state.time_zone}
                                                                    onChange={value =>
                                                                        this.setState({ time_zone: value })
                                                                    }
                                                                    options={this.state.time_zone_list}
                                                                    placeholder="Select time zone"
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row> */}
                                                    {/* {billing_email_list &&
                                                        <Row>
                                                            <Label md="4">Billing Email</Label>
                                                            <Col md="8">
                                                                <FormGroup className={`has-label ${billing_emailState}`}>
                                                                    <CreatableSelect
                                                                        isClearable
                                                                        // isValidNewOption={this.isValidBillingEmail}
                                                                        onChange={this.handleBillingEmailChange}
                                                                        options={billing_email_list}
                                                                    />
                                                                    {billing_emailState === "has-danger" ? (
                                                                        <label className="error">{`${billing_emailStateMessage}`}</label>
                                                                    ) : null}
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>}
                                                    {billing_email_list &&
                                                        <Row>
                                                            <Label md="4"></Label>
                                                            <Col md="8">
                                                                <FormGroup>
                                                                    <label className="error">If you select an existing billing email address and change related billing information (name and/or address fields below), corresponding billing profile will also be updated. </label>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    } */}
                                                </Form>
                                            </Col>
                                        </Row>
                                    </Col>
                                </CardBody>
                            </Card> : null}
                        </Col>
                    </Row>
                </LoadingOverlay>
            </>
        );
    }
}

export default BillingManualPaymentInvoiceAdd