import React from "react";
import { DateRangePicker } from 'react-dates';
import LoadingOverlay from "react-loading-overlay";
import ReactTable from "react-table-6";
import Select from "react-select";
import {
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    FormGroup,
    Label,
    Modal,
} from "reactstrap";

import Firebase from "firebase";
import { ExportReactCSV } from '../../../components/ExportReactCSV'
import PaymentForm from "../../../components/PaymentForm";

import DateRangePickerWrapper from "../../../components/DateRangePickerWrapper";

class Billing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: [],

            subscriptions: [],
            invoices: [],

            startDate: null,
            endDate: null,
            focusedInput: null,

            sub_location: null,
            sub_location_list: [],

            filtered_subscriptions: [],
        };
        this.auth_info = {};
        // this.functions = null;
        // this.fbStore = null;
    }

    componentDidMount() {
        this.auth_info = JSON.parse(localStorage.getItem('auth_info')) || {};
        // this.initializeFirebase();
        this.fetchCustomerInfo();
        this.fetchSubLocationsList();

        // Firebase.functions().httpsCallable('stripe_listCustomerInfo')({ customer_id, sub_location: sub_location?.value, startDate: startDate ? startDate.format("X") : null, endDate: endDate ? endDate.add(1, 'days').format("X") : null })
        //     .then((response) => {
        //         console.log('fetchCustomerInfo', {customer_id, response});
        //         const res = response.data;
        //         if (res.status === 'ok') {
        //             const { subscriptions, invoices } = res.data;
        //             const filtered_subscriptions = this.filterSubscriptions(subscriptions, invoices);
        //             console.log({filtered_subscriptions});
        //             this.setState({ filtered_subscriptions, subscriptions, invoices })
        //         }
        //         this.setState({loading: false});
        //     })
        //     .catch((error) => {
        //         this.setState({loading: false});
        //         console.log('fetchCustomerInfo', {error});
        //     });
    }

    componentWillUnmount() {

    }

    componentDidUpdate(e) {

    }

    // initializeFirebase = () => {
    //   if (!this.functions) {
    //     this.functions = Firebase.functions();
    //     // this.functions.useFunctionsEmulator('http://localhost:5001');
    //     this.fbStore = Firebase.firestore();
    //   }
    // }

    toggleModalClassic = () => {
        this.setState({
            modalClassic: !this.state.modalClassic
        });
    };

    fetchSubLocationsList = async () => {
        const { customer_id, email } = this.auth_info || {};

        let accessible_locations = [];
        let sub_locations = [{ label: 'Select All', value: -1, image_url: '', address: '' }];
        try {
            const app_info = await Firebase.firestore().collection('Web_App_Users').doc(email).get();
            if (app_info.exists) {
                accessible_locations = app_info.data().Accessible_Locations;
                //console.log("accessible locations:",accessible_locations);
                // console.log('user-json', JSON.stringify(app_info.data()));
            }
        } catch (ex1) {
            console.log("loadSubLocation NetworkError2==>", ex1);
        }

        try {
            const response = await Firebase.firestore().collection('Sub_Locations').where('Customer_ID', '==', customer_id).get();
            response.docs.forEach(function (doc) {
                if (accessible_locations.includes(doc.id)) {
                    const { Name, Icon, Address } = doc.data();
                    sub_locations.push({ label: Name, value: doc.id, image_url: Icon, address: Address });
                }
            });
            this.setState({
                sub_location_list: sub_locations
            });
        } catch (ex2) {
            console.log("loadSubLocationByMain NetworkError2==>", ex2);
        }
    }

    fetchCustomerInfo = () => {
        const { customer_id, email } = this.auth_info || {};
        const { sub_location, startDate, endDate } = this.state;

        let sub_location_id = sub_location?.value;
        if (sub_location_id == -1) {
            sub_location_id = null;
        }
        this.setState({ loading: true });
        Firebase.functions().httpsCallable('stripe_listCustomerInfo')({ customer_id, sub_location: sub_location_id, startDate: startDate ? startDate.format("X") : null, endDate: endDate ? endDate.add(1, 'days').format("X") : null })
            .then((response) => {
                console.log('fetchCustomerInfo', { customer_id, response });
                const res = response.data;
                if (res.status === 'ok') {
                    const { subscriptions, invoices } = res.data;
                    const filtered_subscriptions = this.filterSubscriptions(subscriptions);
                    console.log({ filtered_subscriptions });
                    this.setState({ filtered_subscriptions, subscriptions, invoices })
                }
                this.setState({ loading: false });
            })
            .catch((error) => {
                this.setState({ loading: false });
                console.log('fetchCustomerInfo', { error });
            });
    }

    filterSubscriptions = (subscriptions, invoices) => {
        const filtered_subscriptions = subscriptions.filter(subscription => {
            return subscription.status != 'active' && subscription.status != 'trialing';
        });
        return filtered_subscriptions.slice(Math.max(filtered_subscriptions.length - 3, 0));
    };

    // fetchBillingSubscriptions = () => {
    //     const { email } = this.auth_info || {};
    //     const { sub_location } = this.state;

    //     this.setState({loading: true});
    //     Firebase.functions().httpsCallable('stripe_listBillingSubscriptions')({ email, sublocation: sub_location?.value })
    //         .then((response) => {
    //             console.log('fetchBillingSubscriptions', {email, response});
    //             const res = response.data;
    //             if (res.status === 'ok') {
    //                 const { subscriptions, invoices } = res.data;
    //                 this.setState({ subscriptions, invoices })
    //             }
    //             this.setState({loading: false});
    //         })
    //         .catch((error) => {
    //             this.setState({loading: false});
    //             console.log('fetchBillingSubscriptions', {error});
    //         });
    // }

    fetchBillingInvoiceList = () => {
        const { sub_location, startDate, endDate, selected } = this.state;
        const subscription = selected?.original.id;

        let sub_location_id = sub_location?.value;
        if (sub_location_id == -1) {
            sub_location_id = null;
        }
        this.setState({ loading: true });
        const payload = { sublocation: sub_location_id, subscription, startDate: startDate?.format("X"), endDate: endDate?.clone().add(1, 'days').format("X") };
        console.log('stripe_listBillingInvoices', payload);
        Firebase.functions().httpsCallable('stripe_listBillingInvoices')(payload)
            .then((response) => {
                console.log('fetchBillingInvoiceList', { response });
                const res = response.data;
                if (res.status === 'ok') {
                    const { invoices } = res.data;
                    this.setState({ invoices })
                }
                this.setState({ loading: false });
            })
            .catch((error) => {
                this.setState({ loading: false });
                console.log('fetchBillingInvoiceList', { error });
            });
    }

    handlePayNow = (subscription_id) => {
        const { filtered_subscriptions, loading } = this.state;
        const subscriptions = filtered_subscriptions.filter(e => e.id === subscription_id);
        const subscription = subscriptions ? subscriptions[0] : {};
        const { metadata, latest_invoice } = subscription;
        const { email, interval, packageId, sublocationId } = metadata;

        if (loading) return;

        this.setState({ loading: true });
        Firebase.firestore().collection("Packages").doc(packageId).get().then(package_snapdoc => {
            const { Name, Monthly_Price, Annual_Price } = package_snapdoc.data();
            const price = interval === 'Monthly' ? Monthly_Price : Annual_Price;

            this.setState({
                productSelected: {
                    id: packageId,
                    name: Name,
                    price: '$ ' + price,
                },
                email,
                interval,
                sub_location_id: sublocationId,
                invoice: latest_invoice,

                loading: false,
                modalClassic: true,
            });

            // this.toggleModalClassic();
        });
    };

    handleSubscriptionComplete = () => {
        //
    };

    prepareSubscriptionList = (response) => {
        // let no = 0;
        // response.data.map(subscription => {
        //     no ++;
        //     return {
        //         'sub-locatin-name': subscription.object,
        //         'package': subscription.object,
        //         'unit': subscription.object,
        //         'expire': subscription.days_until_due ? subscription.days_until_due + ' days left' : '-',
        //         'status': subscription.object,
        //     }
        // })
    }

    prepareInvoiceList = (invoices) => {
        //
    }

    onChangeSub(e, type) {
        console.log('onChangeSub', e);
        this.setState({ sub_location: e }, () => {
            // if (type === 'payment') {
            // } else {
            // }
            // this.fetchBillingSubscriptions();
            // this.fetchBillingInvoiceList();
            this.fetchCustomerInfo();
        });
    }

    render() {
        const {
            filtered_subscriptions,
            subscriptions,
            invoice,
            invoices,
            startDate,
            endDate,
            focusedInput,
            sub_location,
            sub_location_list,
            modalClassic,
            productSelected,
            email,
            interval,
            sub_location_id
        } = this.state;

        const { role } = this.auth_info;
        const fileName = 'Subscriptions.csv';
        console.log({ auth_info: this.auth_info, role });

        return (
            <>
                <LoadingOverlay
                    active={this.state.loading}
                    spinner
                    text='Loading'
                    className='content'
                >
                    <Row>
                        <Col md="12">
                            <Card className="">
                                <CardHeader>
                                    <CardTitle tag="h4">Billing</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <div className="padding_Card card-body">
                                        <div className="blockquote blockquote-primary padding">
                                            <Row>
                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <h6>Billing Profile</h6>
                                                </div>
                                            </Row>
                                            {filtered_subscriptions &&
                                                (<Row>
                                                    <Col>
                                                        {filtered_subscriptions.map(filtered_subscription =>
                                                        (<div key={filtered_subscription.id} className="payment-alert-notification-wrapper">
                                                            <div className="payment-alert-notification">Payment for {filtered_subscription.sublocation} for the month of {filtered_subscription.month}, {filtered_subscription.year} has been rejected due to: {filtered_subscription.status}.</div>
                                                            <div><button className="btn btn-warning btn-block" onClick={() => this.handlePayNow(filtered_subscription.id)}>Pay Now</button></div>
                                                        </div>)
                                                        )}
                                                        <Modal
                                                            isOpen={modalClassic}
                                                            toggle={this.toggleModalClassic}
                                                        >
                                                            {modalClassic &&
                                                                <PaymentForm
                                                                    productSelected={productSelected}
                                                                    email={email}
                                                                    invoice_no={invoice}
                                                                    interval={interval}
                                                                    sublocationId={sub_location_id}
                                                                    handleSubscriptionComplete={this.handleSubscriptionComplete}
                                                                />
                                                            }
                                                        </Modal>
                                                    </Col>
                                                </Row>)
                                            }
                                        </div>
                                    </div>
                                    <div className="padding_Card card-body">
                                        <div className="blockquote blockquote-primary padding">
                                            <Row>
                                                <Col xl="6" lg="6">
                                                    <h6>Subscriptions</h6>
                                                </Col>
                                                <Col xl="6" lg="6">
                                                    {role == 'Site_Admin' || role == 'System_Admin' || role == 'Billing_Admin' ?
                                                        <FormGroup className="flex-horizontal">
                                                            <Label>Sub Location</Label>
                                                            <Select
                                                                className="react-select info flex-item"
                                                                classNamePrefix="react-select"
                                                                placeholder="Select Sub Location"
                                                                name="selectSubLocation"
                                                                value={sub_location}
                                                                onChange={e =>
                                                                    this.onChangeSub(e, 'payment')
                                                                }
                                                                options={sub_location_list}
                                                            />
                                                            <ExportReactCSV csvData={subscriptions} fileName={fileName} />
                                                        </FormGroup>
                                                        : ''}
                                                </Col>
                                                <Col md="12" lg="12">
                                                    <ReactTable
                                                        data={subscriptions}
                                                        columns={[
                                                            {
                                                                Header: "Sub location Name",
                                                                accessor: "sublocation"
                                                            },
                                                            {
                                                                Header: "Package",
                                                                accessor: "package"
                                                            },
                                                            {
                                                                Header: "Unit",
                                                                accessor: "interval"
                                                            },
                                                            {
                                                                Header: "Expiry Date",
                                                                accessor: "expire"
                                                            },
                                                            {
                                                                Header: "Payment Status",
                                                                accessor: "status"
                                                            }
                                                        ]}
                                                        defaultPageSize={5}
                                                        showPaginationTop={false}
                                                        showPaginationBottom={true}
                                                        showPageSizeOptions={false}
                                                        getTrProps={(state, rowInfo) => {
                                                            if (rowInfo && rowInfo.row) {
                                                                return {
                                                                    onClick: (e) => {
                                                                        console.log({ rowInfo });
                                                                        this.setState({
                                                                            selected: rowInfo
                                                                        }, () => {
                                                                            this.fetchBillingInvoiceList();
                                                                        })
                                                                    },
                                                                    style: rowInfo.index === this.state.selected?.index ? {
                                                                        background: '#00afec',
                                                                        color: 'white'
                                                                    } : {}
                                                                }
                                                            } else {
                                                                return {}
                                                            }
                                                        }
                                                        }
                                                        /*
                                                        You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                                                        */
                                                        className="-striped -highlight primary-pagination"
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    <div className="padding_Card card-body">
                                        <div className="blockquote blockquote-primary padding">
                                            <Row>
                                                <Col xl="2" lg="2">
                                                    <h6>Payment History</h6>
                                                </Col>
                                                <Col xl="4" lg="4">
                                                    {role == 'Site_Admin' || role == 'System_Admin' || role == 'Billing_Admin' ?
                                                        <DateRangePicker
                                                            startDate={startDate} // momentPropTypes.momentObj or null,
                                                            endDate={endDate} // momentPropTypes.momentObj or null,
                                                            onDatesChange={({ startDate, endDate }) => {
                                                                console.log([startDate?.format("YYYY-MM-DD"), endDate?.format("YYYY-MM-DD")]);
                                                                this.setState({ startDate, endDate });
                                                            }} // PropTypes.func.isRequired,
                                                            onClose={({ startDate, endDate }) => {
                                                                console.log([startDate?.format("YYYY-MM-DD"), endDate?.format("YYYY-MM-DD")]);
                                                                this.fetchBillingInvoiceList();
                                                            }}
                                                            isOutsideRange={day => false}
                                                            startDateId="startDateId"
                                                            endDateId="endDateId"
                                                            focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                                            onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                                                        ></DateRangePicker>
                                                        : ''}
                                                </Col>
                                                <Col xl="6" lg="6">
                                                    {role == 'Site_Admin' || role == 'System_Admin' || role == 'Billing_Admin' ?
                                                        <FormGroup className="flex-horizontal">
                                                            <Label>Sub Location</Label>
                                                            <Select
                                                                className="react-select info flex-item"
                                                                classNamePrefix="react-select"
                                                                placeholder="Select Sub Location"
                                                                name="selectSubLocation"
                                                                value={sub_location}
                                                                onChange={e =>
                                                                    this.onChangeSub(e, 'invoice')
                                                                }
                                                                options={sub_location_list}
                                                            />
                                                            <ExportReactCSV csvData={invoices} fileName="invoices.csv" />
                                                        </FormGroup>
                                                        : ''}
                                                </Col>
                                                <Col md="12" lg="12">
                                                    <ReactTable
                                                        data={invoices}
                                                        columns={[
                                                            {
                                                                Header: "Invoice No",
                                                                accessor: "invoice_no",
                                                                Cell: ({ row }) => <a href={row._original.pdf_link}>{row.invoice_no}</a>
                                                            },
                                                            {
                                                                Header: "Invoice Date",
                                                                accessor: "invoice_date",
                                                                Cell: ({ row }) => <a href={row._original.pdf_link}>{row.invoice_date}</a>
                                                            },
                                                            {
                                                                Header: "Amount",
                                                                accessor: "amount",
                                                                Cell: ({ row }) => <a href={row._original.pdf_link}>{row.amount}</a>
                                                            },
                                                            {
                                                                Header: "Due Date",
                                                                accessor: "due_date",
                                                                Cell: ({ row }) => <a href={row._original.pdf_link}>{row.due_date}</a>
                                                            },
                                                            {
                                                                Header: "Payment Status",
                                                                accessor: "status",
                                                                Cell: ({ row }) => <a href={row._original.pdf_link}>{row.status}</a>
                                                            }
                                                        ]}
                                                        defaultPageSize={5}
                                                        showPaginationTop={false}
                                                        showPaginationBottom={true}
                                                        showPageSizeOptions={false}
                                                        /*
                                                        You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                                                        */
                                                        className="-striped -highlight primary-pagination"
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </LoadingOverlay>
            </>
        );
    }
}

export default Billing;
