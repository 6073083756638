import React, { useState, useEffect, useRef } from "react";
import LoadingOverlay from "react-loading-overlay";
import Switch from "react-bootstrap-switch";
import Select from "react-select";
import CopyToClipboard from "react-copy-to-clipboard";
import NotificationAlert from "react-notification-alert";
import CreatableSelect from "react-select/creatable";
import Firebase from "firebase";
import config from "../../../config";
import IconUploadToFirebase from "../../../components/CustomUpload/IconUploadToFirebase";
// import IconUpload2 from "../../../components/CustomUpload/IconUploadToFirebase";
import moment from "moment-timezone";

import {
  Col,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Collapse,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Table,
  UncontrolledTooltip,
} from "reactstrap";

import trash from "../../trash-alt-solid.svg";
import ServiceDetails from "./ServiceDetails";
import apiHandleServicesAddEdit,{callApiHandleCounterRun} from "./ServiceAddEditFuncs";

const uuidv1 = require("uuid/v1");
function ServiceAdd(props) {
  //   constructor (props) {
  // super(props)
  if (!Firebase.apps.length) {
    Firebase.initializeApp(config);
  }

  const [state, setState1] = useState({
    loading: true,
    customer_id: JSON.parse(localStorage.getItem("auth_info")).customer_id,
    role: JSON.parse(localStorage.getItem("auth_info")).role,
    selected_service_type: null,
    service_type_list: null,
    serviceTypeState: "",
    icon_max_limit: 0,
    main_location_list: [],
    sub_location_list: [],
    selected_main_location: null,
    selected_sub_location: null,
    validate_serv_for_tok_num_range_overlap: true,
    current_number_services: 0,
    time_list: [
      { value: 0, label: "00" },
      { value: 1, label: "01" },
      { value: 2, label: "02" },
      { value: 3, label: "03" },
      { value: 4, label: "04" },
      { value: 5, label: "05" },
      { value: 6, label: "06" },
      { value: 7, label: "07" },
      { value: 8, label: "08" },
      { value: 9, label: "09" },
      { value: 10, label: "10" },
      { value: 11, label: "11" },
      { value: 12, label: "12" },
      { value: 13, label: "13" },
      { value: 14, label: "14" },
      { value: 15, label: "15" },
      { value: 16, label: "16" },
      { value: 17, label: "17" },
      { value: 18, label: "18" },
      { value: 19, label: "19" },
      { value: 20, label: "20" },
      { value: 21, label: "21" },
      { value: 22, label: "22" },
      { value: 23, label: "23" },
    ],
    number_list: [],

    service_key: "",
    name: "",
    nameState: "",
    secondary_service_nameState: "",
    service_details: "",
    service_details_for_display: "",
    service_details_for_display_toggle: "",
    out_of_serv_hours_msg: "",
    start_character: "",
    number_digits: { value: 4, label: "4" },
    invalid_start_number: false,
    invalid_end_number: false,
    invalid_number_of_tokens: false,
    start_number: "0001",
    end_number: "0002",
    current_token_count: 0,
    number_of_tokens: 0,
    min_length_number_of_tokens: 1,
    priority: 0,
    new_token_slot_for_cancelled_token: false,
    reset_tokens_at_day_close: true,
    stop_token_gen_when_end_num_reached: true, //false,
    build_ai_generated: true,
    show_on_mobile_app: true,
    disable_token_gen_on_mobile_app: false,
    show_on_dispenser_app: true,
    hide_token_status_on_mobile_app: false,
    hide_token_num_on_mobile_app: false,
    token_auto_close_time_val: { value: 8, label: "8 hrs" }, //in hours

    new_week_select: { value: "0", label: "Sunday" },
    issue_start_time: "",
    issue_end_time: "",
    tok_issue_cut_off_time: "",
    call_start_time: "",
    call_end_time: "",
    service_days: [],

    openedCollapses: [],
    last_printed_number: "",
    last_printed_date_time: "",
    last_called_number: "",
    last_called_date_time: "",
    last_called_counter: "",
    last_called_user: "",
    current_status: "",
    last_generated_token: "0000",
    last_generated_token_date_time: "",
    //reset: '',
    time_zone: "",
    //pr: '',
    //auto: '',
    //build: '',
    service_group_list_order_number: 1,
    invalid_service_group_list_order_number: false,

    inputKey1: Math.random().toString(36),

    //new_service_group: false,
    service_group_nameState: "",
    service_group_descriptionState: "",

    // selected_service_group_profile: null,
    service_group_enabled: false,
    service_group_show_on_mobile_app: true,
    service_group_name: "",
    service_group_description: "",
    service_group_name_list: null,
    service_group_profile_id: null,
    default_service_group_name: null,
    //service_group_members_count: 0,

    // service_group_icon: null,
    // service_group_icon_enable: false,
    // service_group_icon_file_ext_ok: false,
    // service_group_icon_file_size_ok: false,
    // service_group_icon_name: "",
    // service_group_icon_url: "",
    // service_group_icon_attachmentState: "",

    selected_main_location_id: "",
    selected_sub_location_id: "",
    //service_group_name_disable_selection: false,

    //new_service_group: false,
    request_approval_group_nameState: "",
    request_approval_group_descriptionState: "",

    request_approval_group_enabled: false,
    request_approval_group_name: "",
    request_approval_group_description: "",
    request_approval_group_name_list: null,
    request_approval_group_profile_id: null,
    default_request_approval_group_name: null,

    min_num_of_days_appointment_can_be_scheduled_ahead: 0,
    max_num_of_days_appointment_can_be_scheduled_ahead: 7,
    min_min_num_of_days_appointment_can_be_scheduled_ahead: 0,
    min_max_num_of_days_appointment_can_be_scheduled_ahead: 0,
    max_min_num_of_days_appointment_can_be_scheduled_ahead: 0,
    max_max_num_of_days_appointment_can_be_scheduled_ahead: 0,
    invalid_min_num_of_days_appointment_can_be_scheduled_ahead: false,
    invalid_max_num_of_days_appointment_can_be_scheduled_ahead: false,
    disable_fields_based_on_service_type_appointment: true,
    min_service_group_list_order_number: 1,

    mobile_app_input_fields: [
      {
        id: uuidv1(),
        mobileAppInputFieldName: "",
        mobileAppInputFieldDataType: null,
        mobileAppInputFieldMandatory: null,
      },
    ],

    mobile_app_input_field_datatype_list: [],
    mobile_app_file_attachment_filetype_list: [],

    mobile_app_general_mandatory_options_list: [
      { value: true, label: "Mandatory Field" },
      { value: false, label: "Optional Field" },
    ],

    mobile_app_file_attachments: [
      {
        id: uuidv1(),
        mobileAppFileAttachmentName: "",
        mobileAppFileAttachmentFileTypesAllowed: null,
        mobileAppFileAttachmentMandatory: null,
      },
    ],

    max_number_of_input_fields: 0,
    max_number_of_file_attachments: 0,

    service_icon: "",
    service_group_icon: "",
    mobile_app_file_attachment_groups: [],
    force_close_appointment_after_service_date_passes: true,
    push_user_notifications_on_token_creation_or_changes: false,
    book_appointment_authorized_only:false,
    selected_calendar:'',
    calendars:[],
    Enable_Calendar_Days_Only:false,
  });
  var notificationAlert = useRef(null);
  var service_icon = useRef(null);
  var service_group_icon = useRef(null);
  const setState = (e) => {
    setState1((c) => ({ ...c, ...e }));
  };

  //   componentDidMount () {
  useEffect(() => {
    // initializeFirebase();                                          //functions emulator

    let service_key = uuidv1();
    setState({ service_key: service_key });

    // // Firebase.firestore().collection('Services')
    // // .get().then(q => {
    // //     console.log("Services Last_Generated_Token-->0000 length1> : ", q.docs.length);
    // //     var k=0; var l = 0;
    // //     q.docs.forEach(t => {
    // //         var servName = t.data().Name;
    // //         Firebase.firestore().collection('Services').doc(t.id).update({
    // //             Service_Type: 'Token',
    // //             Number_Of_Days_appointment_can_be_scheduled_ahead: 0,
    // //         });
    // //         k++;
    // //     })
    // //     console.log("Service_Type Token k",k,"l",l);
    // // })
  }, []);

  useEffect(() => {
    loadMainLocations();
  }, [state.service_key]);

   
  function serviceDetUpdate ( type='service_add') {
    let params = {
      version: '1',
      cmd_: 'C8',
      customerId: state.customer_id || '',
      mainLocId: state.main_location_id || '',
      subLocId: state.sub_location_id || '',
      // counterId: counter_id || '',
      emailId: JSON.parse(localStorage.getItem("auth_info")).email || '',
      userId: JSON.parse(localStorage.getItem("auth_info")).email || '',
      userName: state.user_name || '',
      timeZone: state.time_zone || '',
      refAction: type,
      serviceId:state.service_key
    }, c8Result
      //  c8Result = await callApiHandleCounterRun({ params });
      console.log('c8Result', c8Result)
  }

  async function loadMainLocations() {
    let response;

    try {
      let main_locations = [];
      // var customer_id = JSON.parse(localStorage.getItem("auth_info")).customer_id;

      // // ---------- Load Location List ---------- //
      let main_loc_query = Firebase.firestore()
        .collection("Main_Locations")
        .where("Customer_ID", "==", state.customer_id);
      const main_loc_snap = await main_loc_query.get();

      main_loc_snap.docs.map((doc) => {
        var main_loc_id = doc.id;
        //var main_loc_data = doc.data();
        var main_loc_name = doc.data().Name;
        main_locations.push({ label: main_loc_name, value: main_loc_id });
      });

      setState({ main_location_list: main_locations });
      if (main_locations.length > 0) {
        setState({ selected_main_location: main_locations[0] });
        loadSubLocationByMain(main_locations[0].value);
      } else {
        setState({ loading: false });
        notifyMessage("tc", 3, "Sorry, no main locations found.");
      }
    } catch (error) {
      response = {
        status: "error",
        error: { message: error.message, method: "loadData", detail: error },
        current_date: new Date(),
      };
      console.log(response);
      return response;
    }
  }

  function loadSubLocationByMain(main_id) {
    setState({ loading: true });
    setState({ selected_sub_location: null });
    let sub_locations = [];
    Firebase.firestore()
      .collection("Sub_Locations")
      .where("Customer_ID", "==", state.customer_id)
      .where("Main_Location_ID", "==", main_id)
      .get()
      .then(function (response) {
        response.docs.forEach(function (doc) {
          sub_locations.push({
            label: doc.data().Name,
            value: doc.id,
            counts: doc.data().Service_Count,
            package_id: doc.data().Package_ID,
            time_zone: doc.data().TimeZone,
            validate_serv_for_tok_num_range_overlap:
              doc.data().Validate_Service_For_Token_Number_Range_Overlap,
          });
        });

        setState({ sub_location_list: sub_locations });
        console.log({ sub_location_list: sub_locations });
        if (sub_locations.length > 0) {
          setState({ selected_sub_location: sub_locations[0] });

          loadData(state.customer_id, main_id, sub_locations[0].value, "-1");

          loadStartEndNumberList(sub_locations[0].value);
          setState({ time_zone: sub_locations[0].time_zone });
        } else {
          setState({ loading: false });
          notifyMessage(
            "tc",
            3,
            "This main location does not have any sub locations."
          );
        }
      })
      .catch(function (error) {
        setState({ loading: false });
        notifyMessage(
          "tc",
          3,
          "Encountered an unexpected error while loading!"
        );
        console.error("loadSubLocationByMain error: ", error);
      });
  }

  const loadData = async (
    customer_id,
    main_location_id,
    sub_location_id,
    service_id
  ) => {
    // const { mobile_app_input_field_datatype_list, mobile_app_general_mandatory_options_list } = state;
    // let customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;
    // let sub_location_id = state.sub_location_id;
    console.log({
      loadLocationsData: 0,
      customer_id: customer_id,
      main_location_id: main_location_id,
      sub_location_id: sub_location_id,
      service_id: service_id,
    });
    // const [response] = await Promise.all([
    //   Firebase.functions().httpsCallable('apiHandleServicesAddEdit')({
    //     cmd: 'serviceAdd_LoadData',
    //     customer_id: customer_id,
    //     main_location_id: main_location_id,
    //     sub_location_id: sub_location_id,
    //     service_id: service_id
    //   })
    // ])
    const response = await apiHandleServicesAddEdit({
      cmd: "serviceAdd_LoadData",
      customer_id: customer_id,
      main_location_id: main_location_id,
      sub_location_id: sub_location_id,
      service_id: service_id,
    });

    const res = response;
    // const res = response.data

    // console.log("res.data.sub_location.Subscription_Details", res.data.sub_location.Subscription_Details);
    // console.log("res.data.sub_location.Subscription_Details", res.data.sub_location.Subscription_Details);
    console.log("res", res);
    console.log("res?.status", res?.status);

    if (res?.status === "ok") {
      try {
        const customer_id = res.data.sub_location.Customer_ID;
        const {
          main_location,
          sub_location,
          //service,
          //service_group,
          //request_approval_group,
          service_group_name_list,
          request_approval_group_name_list,
          mobile_app,
          icon_max_limit,
          appointment_scheduling,
          calendars,
        } = res.data;

        let mobileAppInputFieldsArr = [];

        let mInputFieldID = uuidv1();
        let mInputFieldName = "";
        let mInputDataType = null; //mobile_app_input_field_datatype_list[0];
        let mInputMandatory = null; //mobile_app_general_mandatory_options_list[1];

        mobileAppInputFieldsArr.push({
          id: mInputFieldID,
          mobileAppInputFieldName: mInputFieldName,
          mobileAppInputFieldDataType: mInputDataType,
          mobileAppInputFieldMandatory: mInputMandatory,
        });

        console.log(
          "loadLocationsData>>>1 state.mobile_app_input_field",
          state.mobile_app_input_field
        );

        let mobileAppFileAttachmentsArr = [];

        let mFileAttachmentID = uuidv1();
        let mFileAttachmentName = "";
        let mFileAttachmentFileTypesAllowedArr1 = null; //mobile_app_input_field_datatype_list[0];
        let mFileAttachmentMandatory = null; //mobile_app_general_mandatory_options_list[1];

        mobileAppFileAttachmentsArr.push({
          id: mFileAttachmentID,
          mobileAppFileAttachmentName: mFileAttachmentName,
          File_Attachment_Grouping_Id: -1,
          mobileAppFileAttachmentFileTypesAllowed:
            mFileAttachmentFileTypesAllowedArr1,
          mobileAppFileAttachmentMandatory: mFileAttachmentMandatory,
        });

        console.log(
          "loadLocationsData>>>3 state.mobile_app_file_attachment",
          state.mobile_app_file_attachment
        );

        console.log(
          "loadSubLocationData>>>1.2",
          "selected_main_location_id",
          main_location.id,
          "selected_sub_location_id",
          sub_location.id,
          "main_location",
          main_location,
          "sub_location",
          sub_location,
          //"service", service,
          //"service_group", service_group,
          //"request_approval_group", request_approval_group,
          "service_group_name_list",
          service_group_name_list,
          "request_approval_group_name_list",
          request_approval_group_name_list,
          "mobile_app",
          mobile_app,
          "icon_max_limit",
          icon_max_limit,
          "appointment_scheduling",
          appointment_scheduling
        );

        //service_icon.current.handleSetUrl(service_group.Icon);
        // console.log('loadSubLocationData>>>2: icon_max_limit', icon_max_limit, 'customer_id', customer_id,
        //             'main_location.Name', main_location.Name, 'sub_location.Name', sub_location.Name, 'sub_location.address',  sub_location.Address, 'created_date_time',  sub_location.Created_Date,
        //             'sub_location.Customer_ID',  sub_location.Customer_ID, 'sub_location.position',  sub_location.Geolocation, 'sub_location.center',  sub_location.Geolocation,
        //             'sub_location.service_icon',  sub_location.Icon, 'sub_location.name',  sub_location.Name, 'sub_location.address',  sub_location.Address);
        // console.log('loadSubLocationData>>>3: sub_location.Billing_Profile_Id', sub_location.Billing_Profile_Id);
        // console.log('loadSubLocationData>>>4: service.Service_Group_Profile_Id', service.Service_Group_Profile_Id);
        // const sgpid = service.Service_Group_Profile_Id;
        // const default_service_group_name = sgpid ? service_group_name_list ?.filter(option=>option.service_group_id===sgpid)[0] : {service_group_id: '', value: '', label: '', service_group_name: '', service_group_description: '', service_group_icon_url: '', service_group_list_order_number: '', customer_id: '', main_location_id: '', sub_location_id: '' };
        // const defaultServiceGroupName = default_service_group_name?.service_group_name;
        // const defaultServiceGroupDescription = default_service_group_name?.service_group_description;
        // const defaultServiceGroupListOrderNum = default_service_group_name?.service_group_list_order_number;
        // const sgiu = default_service_group_name?.service_group_icon_url;
        // const defaultServiceGroupIconURL = ( typeof sgiu !== 'undefined' && sgiu !== null && sgiu !== '' ) ? sgiu : "";

        // const ragpid = service.Request_Approval_Group_Profile_Id;
        // const default_request_approval_group_name = ragpid ? request_approval_group_name_list ?.filter(option=>option.request_approval_group_id===ragpid)[0] : {request_approval_group_name: '', request_approval_group_description: ''};
        // const defaultRequestApprovalGroupName = default_request_approval_group_name?.request_approval_group_name;
        // const defaultRequestApprovalGroupDescription = default_request_approval_group_name?.request_approval_group_description;

        const main_location_name = main_location.Name;
        const sub_location_name = sub_location.Name;
        //const validate_serv_for_tok_num_range_overlap = sub_location.Validate_Service_For_Token_Number_Range_Overlap;

        // console.log('loadSubLocationData>>>11: service.Service_Group_Profile_Id', service.Service_Group_Profile_Id,
        //             'default_service_group_name', default_service_group_name,
        //             'defaultServiceGroupName', defaultServiceGroupName,
        //             'defaultServiceGroupDescription', defaultServiceGroupDescription,
        //             'defaultServiceGroupListOrderNum', defaultServiceGroupListOrderNum,
        //             'defaultServiceGroupIconURL', defaultServiceGroupIconURL,
        //             'service_group_icon', service_group.Icon);
        console.log(
          "main_location_name",
          main_location_name,
          "sub_location_name",
          sub_location_name
        );

        // ---------- Load Service Types ---------- //

        let serviceTypesArr = [];
        let serviceTypes = mobile_app.Service_Types;
        console.log("serviceTypes", serviceTypes);

        serviceTypes.forEach((b) => {
          serviceTypesArr.push({ label: b, value: b });
        });

        if (serviceTypesArr.length > 0) {
          setState({
            service_type_list: serviceTypesArr,
            selected_service_type: serviceTypesArr[0],
            serviceTypeState: "has-success",
          });
          console.log({ loadData: 1, service_type_list: serviceTypesArr });
        } else {
          setState({ serviceTypeState: "has-danger" });
        }

        // ---------- Load Input Field and File Attachment Data Types ---------- //
        let inputFieldDataTypesArr = [];
        let inputFieldDataTypes = mobile_app.Input_Field_Data_Types;
        inputFieldDataTypes.forEach((b) => {
          inputFieldDataTypesArr.push({ label: b, value: b });
        });

        if (inputFieldDataTypesArr.length > 0) {
          setState({
            mobile_app_input_field_datatype_list: inputFieldDataTypesArr,
          });
          console.log({
            loadData: 1,
            mobile_app_input_field_datatype_list: inputFieldDataTypesArr,
          });
        }

        let fileAttachmentFileTypesArr = [];
        let fileAttachmentFileTypes = mobile_app.File_Attachment_File_Types;
        fileAttachmentFileTypes.forEach((b) => {
          fileAttachmentFileTypesArr.push({ label: b, value: b });
        });

        if (fileAttachmentFileTypesArr.length > 0) {
          setState({
            mobile_app_file_attachment_filetype_list:
              fileAttachmentFileTypesArr,
          });
          console.log({
            loadData: 1,
            mobile_app_file_attachment_filetype_list:
              fileAttachmentFileTypesArr,
          });
        }

        // ---------- Load Service Related Restrictions ---------- //
        setState({
          max_number_of_input_fields:
            mobile_app.Mobile_App_Input_Restrictions.Service_Related
              .Max_Number_Of_Input_Fields,
        });
        setState({
          max_number_of_file_attachments:
            mobile_app.Mobile_App_Input_Restrictions.Service_Related
              .Max_Number_Of_File_Attachments,
        });
        console.log(
          "max_number_of_input_fields",
          mobile_app.Mobile_App_Input_Restrictions.Service_Related
            .Max_Number_Of_Input_Fields,
          "max_number_of_file_attachments",
          mobile_app.Mobile_App_Input_Restrictions.Service_Related
            .Max_Number_Of_File_Attachments
        );

        setState({
          loading: false,

          customer_id: sub_location.Customer_ID,
          icon_max_limit,

          selected_main_location_id: main_location.id,
          selected_sub_location_id: sub_location.id,

          main_location_name,
          sub_location_name,
          //validate_serv_for_tok_num_range_overlap: validate_serv_for_tok_num_range_overlap

          service_group_enabled: false, //true //( typeof sgpid !== 'undefined' && sgpid !== null && sgpid !== '' && sgpid !== '-1' ) ? true : false,
          // service_group_profile_id: sgpid,
          service_group_name_list,
          // default_service_group_name,
          // service_group_name: defaultServiceGroupName,
          // service_group_description: defaultServiceGroupDescription,
          // service_group_list_order_number: defaultServiceGroupListOrderNum,
          // service_group_icon: defaultServiceGroupIconURL,

          request_approval_group_enabled: false, //true //( typeof ragpid !== 'undefined' && ragpid !== null && ragpid !== '' && ragpid !== '-1' ) ? true : false,
          // request_approval_group_profile_id: ragpid,
          request_approval_group_name_list,
          // default_request_approval_group_name,
          // request_approval_group_name: defaultRequestApprovalGroupName,
          // request_approval_group_description: defaultRequestApprovalGroupDescription,

          service_group_nameState: "has-success",
          service_group_descriptionState: "has-success",

          request_approval_group_nameState: "has-success",
          request_approval_group_descriptionState: "has-success",

          min_num_of_days_appointment_can_be_scheduled_ahead: parseInt(
            appointment_scheduling.default_min_num_of_days_appointment_can_be_scheduled_ahead
          ),
          min_min_num_of_days_appointment_can_be_scheduled_ahead: parseInt(
            appointment_scheduling.min_min_num_of_days_appointment_can_be_scheduled_ahead
          ),
          min_max_num_of_days_appointment_can_be_scheduled_ahead: parseInt(
            appointment_scheduling.min_max_num_of_days_appointment_can_be_scheduled_ahead
          ),
          max_num_of_days_appointment_can_be_scheduled_ahead: parseInt(
            appointment_scheduling.default_max_num_of_days_appointment_can_be_scheduled_ahead
          ),
          max_min_num_of_days_appointment_can_be_scheduled_ahead: parseInt(
            appointment_scheduling.max_min_num_of_days_appointment_can_be_scheduled_ahead
          ),
          max_max_num_of_days_appointment_can_be_scheduled_ahead: parseInt(
            appointment_scheduling.max_max_num_of_days_appointment_can_be_scheduled_ahead
          ),
          calendars,
          // name: service.Name,
          // service_details: service.Details,
          // out_of_serv_hours_msg: service.Out_Of_Service_Hours_Message,
          // service_icon: service.Icon,
          // start_character: service.Start_Character,
          // number_digits: {value: service.Number_Digits, label: service.Number_Digits},
          // start_number: service.Start_Number,
          // end_number: service.End_Number,
          // number_of_tokens: service.Number_Of_Tokens,
          // current_token_count: service.Current_Token_Count,
          // build_ai_generated: service.Build_AI_Generated,
          // show_on_mobile_app: service.Show_On_Mobile_App,
          // disable_token_gen_on_mobile_app: service.Disable_Token_Generation_On_Mobile_App,
          // show_on_dispenser_app: service.Show_On_Dispenser_App,
          // hide_token_status_on_mobile_app: service.Hide_Token_Status_On_Mobile_App,
          // hide_token_num_on_mobile_app: service.Hide_Token_Number_On_Mobile_App,
          // token_auto_close_time_val: {value: service.Token_Auto_Close_Time, label: service.Token_Auto_Close_Time +' hrs'},
          // priority: service.Priority,
          // new_token_slot_for_cancelled_token: service.New_Token_Slot_For_Cancelled_Token,
          // service_days: service.Service_Days||[],
          // last_printed_number: service.Last_Printed_Number,
          // last_printed_date_time: service.Last_Printed_Date_Time,
          // last_called_number: service.Last_Called_Number,
          // last_called_date_time: service.Last_Called_Date_Time,
          // last_called_counter: service.Last_Called_Counter,
          // last_called_user: service.Last_Called_User,
          // current_status: service.Current_Status,
          // last_generated_token: service.Last_Generated_Token,
          // last_generated_token_date_time: service.Last_Generated_Token_Date_Time,
          // stop_token_gen_when_end_num_reached: service.Stop_Token_Generation_When_End_Num_Reached,
          // reset_tokens_at_day_close: service.Reset_Tokens_At_Day_Close,
          // time_zone: service.TimeZone,
          mobile_app_input_fields: mobileAppInputFieldsArr,
          mobile_app_file_attachments: mobileAppFileAttachmentsArr,
          // mobile_app_file_attachment_groups: service.mobile_app_file_attachment_groups||[]

          // selected_sub_location_id: service.Sub_Location_ID, selected_main_location_id: service.Main_Location_ID,
          // loading: false,
        });

        console.log("mobileAppFileAttachmentsArr", mobileAppFileAttachmentsArr);
        // console.log("1a service_icon.current state.service_icon", service.Icon);
        // console.log("1b service_group_icon.current state.service_group_icon", defaultServiceGroupIconURL);

        // if (typeof service.Icon !== 'undefined' && service.Icon !== null && service.Icon !== '' ) {
        //     service_icon.current.handleSetUrl(service.Icon);
        // }

        // if (typeof defaultServiceGroupIconURL !== 'undefined' && defaultServiceGroupIconURL !== null && defaultServiceGroupIconURL !== '' ) {
        //     service_group_icon.current.handleSetUrl(defaultServiceGroupIconURL);
        // }

        loadStartEndNumberList(sub_location_id);

        // console.log("2a service_icon.current state.service_icon", service.Icon);
        // console.log("2b service_group_icon.current state.service_group_icon", defaultServiceGroupIconURL);
      } catch (err) {
        setState({
          loading: false,
        });
        notifyMessage("tc", 3, "Network error!");
        console.log("loadLocationsData NetworkError1==>", err);
      }
    }
  };

  function loadStartEndNumberList(sub_location_id) {
    let number_list = [];
    setState({ loading: true });
    setState({ number_list: number_list });
    Firebase.firestore()
      .collection("Services")
      .where("Customer_ID", "==", state.customer_id)
      .where("Sub_Location_ID", "==", sub_location_id)
      .get()
      .then(function (response) {
        response.docs.forEach(function (doc) {
          number_list.push({
            start_number: doc.data().Start_Number,
            end_number: doc.data().End_Number,
          });
        });

        setState({ number_list: number_list });
        setState({ loading: false });
      })
      .catch(function (error) {
        setState({ loading: false });
        notifyMessage(
          "tc",
          3,
          "Encountered an unexpected error while loading!"
        );
        console.error("loadStartEndNumberList error: ", error);
      });
  }

  function handleAdd() {
    setState({ loading: true });

    const { mobile_app_input_fields, mobile_app_file_attachments } = state;
    const {
      serviceTypeState,
      nameState,
      secondary_service_nameState,
      service_group_nameState,
      service_group_descriptionState,
      request_approval_group_nameState,
      request_approval_group_descriptionState,
      service_group_icon_attachmentState,
      selected_calendar,
      Enable_Calendar_Days_Only

    } = state;

    let mobileAppInputFields = [];
    let mobAppInputFieldsErrCount = 0;
    console.log("mobile_app_input_fields", mobile_app_input_fields);

    mobile_app_input_fields.map((mobile_app_input_field, index) => {
      let mInputFieldID = mobile_app_input_field.id;
      let mInputFieldName = mobile_app_input_field.mobileAppInputFieldName;
      let mInputDataType =
        mobile_app_input_field.mobileAppInputFieldDataType?.value;
        let mInputCustomField =
        mobile_app_input_field.customField?.map(e=>e?.value)||[];
      let mInputMandatory =
        mobile_app_input_field.mobileAppInputFieldMandatory?.value;

      if (
        typeof mInputFieldID !== "undefined" &&
        mInputFieldID !== null &&
        mInputFieldID !== "" &&
        typeof mInputFieldName !== "undefined" &&
        mInputFieldName !== null &&
        mInputFieldName !== "" &&
        typeof mInputDataType !== "undefined" &&
        mInputDataType !== null &&
        mInputDataType !== "" &&
        ( (mInputDataType=='Dropdown' && 
          (typeof mInputCustomField !== "undefined" &&
          mInputCustomField !== null &&
          mInputCustomField !== "" )
        )||mInputDataType!='Dropdown') &&
        typeof mInputMandatory !== "undefined" &&
        mInputMandatory !== null &&
        mInputMandatory !== ""
      ) {
        mobileAppInputFields.push({
          id: mInputFieldID,
          Input_Field_Name: mInputFieldName,
          Input_Field_Data_Type: mInputDataType,
          ...(mInputDataType == "Dropdown"
            ? { Input_Field_Dropdown_Values: mInputCustomField }
            : {}),
          Input_Field_Mandatory: mInputMandatory,
        });
      } else if (
        (typeof mInputFieldName === "undefined" ||
          mInputFieldName === null ||
          mInputFieldName === "") &&
        mobile_app_input_fields.length === 1
      ) {
        mobAppInputFieldsErrCount = 0;
      } else {
        mobAppInputFieldsErrCount++;
      }
    });

    let mobileAppFileAttachments = [];
    let mobAppFileAttachmentsErrCount = 0;
    console.log("mobile_app_file_attachments", mobile_app_file_attachments);

    mobile_app_file_attachments.map((mobile_app_file_attachment, index) => {
      let mFileAttachmentID = mobile_app_file_attachment.id;
      let mFileAttachmentName =
        mobile_app_file_attachment.mobileAppFileAttachmentName;
      let mFileAttachmentFileTypesAllowedArr1 = [];
      let mFileAttachmentFileTypesAllowedArr2 = [];
      mFileAttachmentFileTypesAllowedArr1 =
        mobile_app_file_attachment.mobileAppFileAttachmentFileTypesAllowed !==
          null &&
        typeof mobile_app_file_attachment.mobileAppFileAttachmentFileTypesAllowed !==
          "undefined"
          ? mobile_app_file_attachment.mobileAppFileAttachmentFileTypesAllowed
          : null;

      let mFileAttachmentMandatory =
        mobile_app_file_attachment.mobileAppFileAttachmentMandatory !== null &&
        typeof mobile_app_file_attachment.mobileAppFileAttachmentMandatory
          .value !== "undefined"
          ? mobile_app_file_attachment.mobileAppFileAttachmentMandatory.value
          : null;

      console.log(
        "handleAdd>>> 1 mFileAttachmentID",
        mFileAttachmentID,
        "mFileAttachmentName",
        mFileAttachmentName,
        "mFileAttachmentMandatory",
        mFileAttachmentMandatory
      );

      if (
        typeof mFileAttachmentID !== "undefined" &&
        mFileAttachmentID !== null &&
        mFileAttachmentID !== "" &&
        typeof mFileAttachmentName !== "undefined" &&
        mFileAttachmentName !== null &&
        mFileAttachmentName !== "" &&
        typeof mFileAttachmentMandatory !== "undefined" &&
        mFileAttachmentMandatory !== null &&
        mFileAttachmentMandatory !== ""
      ) {
        if (
          typeof mFileAttachmentFileTypesAllowedArr1 !== "undefined" &&
          mFileAttachmentFileTypesAllowedArr1?.length > 0
        ) {
          mFileAttachmentFileTypesAllowedArr1.map((filetype, index) => {
            mFileAttachmentFileTypesAllowedArr2.push(filetype.value);
          });
          console.log(
            "handleAdd>>>2 path 1 mobAppFileAttachmentsErrCount",
            mobAppFileAttachmentsErrCount
          );
          console.log(
            "mFileAttachmentFileTypesAllowedArr1",
            mFileAttachmentFileTypesAllowedArr1
          );
          console.log(
            "mFileAttachmentFileTypesAllowedArr2",
            mFileAttachmentFileTypesAllowedArr2
          );
          mobileAppFileAttachments.push({
            id: mFileAttachmentID,
            File_Attachment_Name: mFileAttachmentName,
            File_Attachment_File_Types_Allowed:
              mFileAttachmentFileTypesAllowedArr2,
            File_Attachment_Mandatory: mFileAttachmentMandatory,
            File_Attachment_Grouping_Id:
              mobile_app_file_attachment.File_Attachment_Grouping_Id,
            File_Attachment_Description: "",
          });
        } else if (typeof mFileAttachmentFileTypesAllowedArr1 !== "undefined") {
          mFileAttachmentFileTypesAllowedArr2.push(
            state.mobile_app_file_attachment_filetype_list[0].value
          );
          console.log(
            "handleAdd>>>3 path 2 mobAppFileAttachmentsErrCount",
            mobAppFileAttachmentsErrCount
          );
          console.log(
            "mFileAttachmentFileTypesAllowedArr1",
            mFileAttachmentFileTypesAllowedArr1
          );
          console.log(
            "mFileAttachmentFileTypesAllowedArr2",
            mFileAttachmentFileTypesAllowedArr2
          );
          mobileAppFileAttachments.push({
            id: mFileAttachmentID,
            File_Attachment_Name: mFileAttachmentName,
            File_Attachment_File_Types_Allowed:
              mFileAttachmentFileTypesAllowedArr2,
            File_Attachment_Mandatory: mFileAttachmentMandatory,
            File_Attachment_Grouping_Id:
              mobile_app_file_attachment.File_Attachment_Grouping_Id || -1,
            File_Attachment_Description: "",
          });
        } else {
          mobAppFileAttachmentsErrCount++;
          console.log(
            "handleAdd>>>4 path 3 mobAppFileAttachmentsErrCount",
            mobAppFileAttachmentsErrCount
          );
          console.log(
            "mFileAttachmentFileTypesAllowedArr1",
            mFileAttachmentFileTypesAllowedArr1
          );
          console.log(
            "mFileAttachmentFileTypesAllowedArr2",
            mFileAttachmentFileTypesAllowedArr2
          );
        }
      } else if (
        (typeof mFileAttachmentName === "undefined" ||
          mFileAttachmentName === null ||
          mFileAttachmentName === "") &&
        mobile_app_file_attachments.length === 1
      ) {
        mobAppFileAttachmentsErrCount = 0;
      } else {
        mobAppFileAttachmentsErrCount++;
      }
    });

    console.log(
      "handleAdd>>>5 service_group_nameState",
      service_group_nameState,
      "service_group_descriptionState",
      service_group_descriptionState,
      "mobAppInputFieldsErrCount",
      mobAppInputFieldsErrCount
    );
    console.log({
      handleServiceGroupChange: "2",
      service_group_nameState: state.service_group_nameState,
      service_group_descriptionState: state.service_group_descriptionState,
      // selected_service_group_profile: servGroupID,
      service_group_profile_id: state.service_group_profile_id,
      service_group_name: state.service_group_name,
      service_group_description: state.service_group_description,
      service_group_list_order_number: state.service_group_list_order_number,
      service_group_icon: state.service_group_icon,
    });

    Firebase.functions()
      .httpsCallable("apiHandleServicesAddEdit")({
        cmd: "service_GetSelectedPackagePaymentRelatedRestrictions",
        sub_location_id: state.selected_sub_location.value,
        type: "SERVICES",
        antqueue_customer_id: state.customer_id,
      })
      .then(function (result) {
        const res = result.data;
        //console.log('validateItemCount', {result});
        if (res?.status === "ok") {
          var limitExceeded = res.data.countExceedsLimit;
          console.log("validateItemCount>>> countExceedsLimit", limitExceeded);
          setState({ loading: false });
          // return limitExceeded;
          if (limitExceeded === true) {
            notifyMessage(
              "tc",
              3,
              "Sorry, the selected package for current sub location does not allow any more Services to be added."
            );
          } else {
            if (serviceTypeState === "") {
              setState({ serviceTypeState: "has-danger" });
            }
            if (nameState === "") {
              setState({ nameState: "has-danger" });
            }
            if (secondary_service_nameState === "") {
              setState({ secondary_service_nameState: "has-danger" });
            }
            if (mobAppInputFieldsErrCount >= 1) {
              notifyMessage(
                "tc",
                3,
                "Please make sure any input fields provided have been filled in with all the required information!"
              );
              setState({ loading: false });
            } else if (mobAppFileAttachmentsErrCount >= 1) {
              notifyMessage(
                "tc",
                3,
                "Please make sure any file attachments provided have been filled in with all the required information!"
              );
              setState({ loading: false });
            } else {
              console.log({
                nameState: nameState,
                secondary_service_nameState: secondary_service_nameState,
                invalid_start_number: state.invalid_start_number,
                invalid_end_number: state.invalid_end_number,
                invalid_number_of_tokens: state.invalid_number_of_tokens,
                service_group_nameState: service_group_nameState,
                service_group_descriptionState: service_group_descriptionState,
                request_approval_group_nameState:
                  request_approval_group_nameState,
                request_approval_group_descriptionState:
                  request_approval_group_descriptionState,
                service_group_icon_attachmentState:
                  service_group_icon_attachmentState,
              });

              if (
                serviceTypeState === "has-success" &&
                nameState === "has-success" &&
                secondary_service_nameState === "has-success" &&
                !state.invalid_start_number &&
                !state.invalid_end_number &&
                !state.invalid_number_of_tokens &&
                service_group_nameState === "has-success" &&
                service_group_descriptionState === "has-success" &&
                request_approval_group_nameState === "has-success" &&
                request_approval_group_descriptionState === "has-success" &&
                service_group_icon_attachmentState !== "has-danger"
              ) {
                const {
                  service_group_enabled,
                  service_group_show_on_mobile_app,
                  default_service_group_name,
                  service_group_name,
                  service_group_description,
                  // service_group_icon,
                  service_group_list_order_number,
                  customer_id,
                  selected_main_location_id,
                  selected_sub_location_id,
                  service_group_profile_id,

                  request_approval_group_enabled,
                  default_request_approval_group_name,
                  request_approval_group_name,
                  request_approval_group_description,
                  request_approval_group_profile_id,

                  validate_serv_for_tok_num_range_overlap,
                } = state;

                console.log(
                  "handleAdd>>>1 default_service_group_name",
                  default_service_group_name,
                  "service_group_name",
                  service_group_name,
                  "service_group_description",
                  service_group_description
                );
                console.log(
                  "handleAdd>>>2 service_group_profile_id",
                  service_group_profile_id,
                  "request_approval_group_profile_id",
                  request_approval_group_profile_id
                );
                console.log(
                  "handleAdd>>>3 default_request_approval_group_name",
                  default_request_approval_group_name,
                  "request_approval_group_name",
                  request_approval_group_name,
                  "request_approval_group_description",
                  request_approval_group_description
                );

                // Check start and end number overlap //
                if (
                  checkNumberOverlap() &&
                  state.selected_sub_location
                    .validate_serv_for_tok_num_range_overlap
                ) {
                  setState({ loading: false });
                  notifyMessage("tc", 3, "Start and end number range overlap!");
                  return;
                }

                if (checkNumberOfTokensOutOfRange()) {
                  setState({ loading: false });
                  notifyMessage("tc", 3, "Number of tokens is out of range!");
                  return;
                }

                var servGroupProfileID = "";

                if (
                  service_group_enabled &&
                  typeof service_group_profile_id !== "undefined" &&
                  service_group_profile_id !== null &&
                  service_group_profile_id !== "" &&
                  service_group_profile_id !== "-1"
                ) {
                  try {
                    servGroupProfileID = service_group_profile_id;
                    let service_group_icon_file =
                      service_group_icon.current.state.file;
                    console.log(
                      "service_group_icon_file",
                      service_group_icon_file
                    );

                    if (service_group_icon_file !== null) {
                      // --------- Check Max Icon Size Limit --------- //
                      let max_bytes = state.icon_max_limit * 1024 * 1024;
                      if (service_group_icon_file.size > max_bytes) {
                        setState({ loading: false });
                        notifyMessage(
                          "tc",
                          3,
                          "Service Group icon file size exceeds maximum size of " +
                            state.icon_max_limit +
                            " MB. Please change icon!"
                        );
                      } else {
                        let metadata = {
                          customMetadata: {
                            customer_id: customer_id,
                            main_location_id: selected_main_location_id,
                            sub_location_id: selected_sub_location_id,
                            service_group_profile_id: service_group_profile_id,
                          },
                        };
                        console.log("metadata==>", metadata);
                        var storageRef1 = Firebase.storage().ref("/");
                        console.log(
                          "service_group_icon_file storageRef1",
                          storageRef1
                        );
                        var image_name1 =
                          "service_group_icon_" +
                          state.service_group_profile_id;
                        var fileRef1 = storageRef1.child(image_name1);
                        fileRef1
                          .put(service_group_icon_file, metadata)
                          .then(function (snapshot) {
                            fileRef1
                              .getDownloadURL()
                              .then(function (res1) {
                                // const response = Firebase.functions().httpsCallable(
                                //   'apiHandleServicesAddEdit'
                                // )({
                                //   cmd: 'service_SaveServiceGroupData',
                                //   name: service_group_name,
                                //   description: service_group_description,
                                //   icon_url: res1,
                                //   list_order_number: service_group_list_order_number,
                                //   customer_id: customer_id,
                                //   main_location_id: selected_main_location_id,
                                //   sub_location_id: selected_sub_location_id,
                                //   service_group_profile_id: service_group_profile_id
                                // })
                                const response = apiHandleServicesAddEdit({
                                  cmd: "service_SaveServiceGroupData",
                                  service_group_show_on_mobile_app: service_group_show_on_mobile_app,
                                  name: service_group_name,
                                  description: service_group_description,
                                  icon_url: res1,
                                  list_order_number: service_group_list_order_number,
                                  customer_id: customer_id,
                                  main_location_id: selected_main_location_id,
                                  sub_location_id: selected_sub_location_id,
                                  service_group_profile_id: service_group_profile_id,
                                });
                                const res = response;
                                // const res = response.data

                                console.log("res", res);
                                console.log("res?.status", res?.status);

                                // if (res?.status === 'ok') { servGroupProfileID = res.data.service_group_profile_id; console.log("path aab1 servGroupProfileID", servGroupProfileID) }
                                if (res?.status === "error") {
                                  const err = res.error;
                                  setState({ loading: false });
                                  //notifyMessage("tc", 3, err.message);
                                  console.log(
                                    "handleAdd NetworkError0==>",
                                    err
                                  );
                                  return;
                                }
                              })
                              .catch(function (error) {
                                setState({ loading: false });
                                notifyMessage(
                                  "tc",
                                  3,
                                  "Encountered an unexpected error while loading!"
                                );
                                console.error("handleAdd error: ", error);
                              });
                          })
                          .catch(function (error) {
                            setState({ loading: false });
                            notifyMessage(
                              "tc",
                              3,
                              "Encountered an unexpected error while loading!"
                            );
                            console.error("handleAdd error: ", error);
                          });
                      }
                    } else {
                      //   const response = Firebase.functions().httpsCallable(
                      //     'apiHandleServicesAddEdit'
                      //   )({
                      //     cmd: 'service_SaveServiceGroupData',
                      //     name: service_group_name,
                      //     description: service_group_description,
                      //     icon_url: 'no_change',
                      //     list_order_number: service_group_list_order_number,
                      //     customer_id: customer_id,
                      //     main_location_id: selected_main_location_id,
                      //     sub_location_id: selected_sub_location_id,
                      //     service_group_profile_id: service_group_profile_id
                      //   })
                      const response = apiHandleServicesAddEdit({
                        cmd: "service_SaveServiceGroupData",
                        service_group_show_on_mobile_app: service_group_show_on_mobile_app,
                        name: service_group_name,
                        description: service_group_description,
                        icon_url: "no_change",
                        list_order_number: service_group_list_order_number,
                        customer_id: customer_id,
                        main_location_id: selected_main_location_id,
                        sub_location_id: selected_sub_location_id,
                        service_group_profile_id: service_group_profile_id,
                      });
                      const res = response;
                      // const res = response.data

                      console.log("res", res);
                      console.log("res?.status", res?.status);

                      // if (res?.status === 'ok') { servGroupProfileID = res.data.service_group_profile_id; console.log("path aab2 servGroupProfileID", servGroupProfileID) }
                      if (res?.status === "error") {
                        const err = res.error;
                        setState({ loading: false });
                        //notifyMessage("tc", 3, err.message);
                        console.log("handleAdd NetworkError0==>", err);
                        return;
                      }
                    }
                  } catch (err) {
                    setState({ loading: false });
                    notifyMessage("tc", 3, "Network error!");
                    console.log("handleAdd NetworkError0==>", err);
                  }
                }

                var requestApprovalGroupProfileID = "";
                if (
                  request_approval_group_enabled &&
                  typeof request_approval_group_profile_id !== "undefined" &&
                  request_approval_group_profile_id !== null &&
                  request_approval_group_profile_id !== "" &&
                  request_approval_group_profile_id !== "-1"
                ) {
                  try {
                    // const response = Firebase.functions().httpsCallable(
                    //   'apiHandleServicesAddEdit'
                    // )({
                    //   cmd: 'service_SaveRequestApprovalGroupData',
                    //   name: request_approval_group_name,
                    //   description: request_approval_group_description,
                    //   customer_id: customer_id,
                    //   main_location_id: selected_main_location_id,
                    //   sub_location_id: selected_sub_location_id,
                    //   request_approval_group_profile_id: request_approval_group_profile_id
                    // })
                    const response = apiHandleServicesAddEdit({
                      cmd: "service_SaveRequestApprovalGroupData",
                      name: request_approval_group_name,
                      description: request_approval_group_description,
                      customer_id: customer_id,
                      main_location_id: selected_main_location_id,
                      sub_location_id: selected_sub_location_id,
                      request_approval_group_profile_id:
                        request_approval_group_profile_id,
                    });
                    const res = response;
                    // const res = response.data
                    if (res?.status === "error") {
                      const err = res.error;
                      setState({ loading: false });
                      //notifyMessage("tc", 3, err.message);
                      console.log("handleAdd NetworkError0==>", err);
                      return;
                    }
                    if (res?.status === "ok") {
                      requestApprovalGroupProfileID =
                        res.data.request_approval_group_profile_id;
                    }
                  } catch (err) {
                    setState({ loading: false });
                    notifyMessage("tc", 3, "Network error!");
                    console.log("handleAdd NetworkError0==>", err);
                  }
                }

                var now = new Date();
                let service_icon_file = service_icon.current.state.file;
                let timeZoneStringParts = state.time_zone.split(")");
                let timeZone = timeZoneStringParts[1];

                // let lastGenTokDateTime = state.last_generated_token_date_time;
                let currDateTime = moment(new Date())
                  .tz(timeZone)
                  .format("DD-MM-YYYY HH:mm:ss"); // output format: '24-02-2020 20:12:15',
                // let currTokCount = state.current_token_count;
                // let lastGenTok = state.last_generated_token;
                // let toksForServiceForTodayExist = true;

                // if ((   (lastGenTokDateTime.substring(0,2) != currDateTime.substring(0,2))      ||  /*Date*/
                //         (lastGenTokDateTime.substring(3,5) != currDateTime.substring(3,5))      ||  /*Month*/
                //         (lastGenTokDateTime.substring(6,10) != currDateTime.substring(6,10))   )  ||  /*Year*/
                //     (currTokCount <= 0 || currTokCount==undefined)) {
                //     toksForServiceForTodayExist = false;
                // }

                // if (toksForServiceForTodayExist == false) {
                //     lastGenTokDateTime = moment(new Date()).tz(timeZone).subtract(1, 'day').format("DD-MM-YYYY HH:mm:ss"); // output format: '23-02-2020 20:12:15'
                //     currTokCount = 0;
                //     lastGenTok = "0000";
                // }

                if (service_icon_file !== null) {
                  // --------- Check Max Icon Size Limit --------- //
                  let max_bytes = state.icon_max_limit * 1024 * 1024;
                  if (service_icon_file.size > max_bytes) {
                    setState({ loading: false });
                    notifyMessage(
                      "tc",
                      3,
                      "Icon file size exceeds maximum size of " +
                        state.icon_max_limit +
                        " MB. Please change icon!"
                    );
                  } else {
                    let metadata = {
                      customMetadata: {
                        customer_id: customer_id,
                        main_location_id: state.selected_main_location_id,
                        sub_location_id: state.selected_sub_location_id,
                        service_id: state.service_key,
                      },
                    };
                    console.log("metadata==>", metadata);
                    var storageRef = Firebase.storage().ref();
                    var image_name = "service_icon_" + state.service_key;
                    var mainLocationRef = storageRef.child(image_name);
                    mainLocationRef
                      .put(service_icon_file, metadata)
                      .then(function (snapshot) {
                        mainLocationRef
                          .getDownloadURL()
                          .then(function (res) {
                            let new_service_data = {
                              Created_Date: now,
                              Service_Type: state.selected_service_type.value,
                              Min_Number_Of_Days_Appointment_Can_Be_Scheduled_Ahead:
                                state.selected_service_type == "Appointment"
                                  ? parseInt(
                                      state.min_num_of_days_appointment_can_be_scheduled_ahead
                                    )
                                  :state.selected_service_type == "Form"?0: -1,
                              Max_Number_Of_Days_Appointment_Can_Be_Scheduled_Ahead:
                                state.selected_service_type == "Appointment"
                                  ? parseInt(
                                      state.max_num_of_days_appointment_can_be_scheduled_ahead
                                    )
                                  :state.selected_service_type == "Form"?1: -1,
                              Icon: res,
                              Name: state.name,
                              Customer_ID: customer_id,
                              Details: state.service_details,
                              Service_Details_For_Display:
                                state.service_details_for_display,
                              Service_Details_For_Display_Toggle:
                                state.service_details_for_display_toggle,
                              Out_Of_Service_Hours_Message:
                                state.out_of_serv_hours_msg,
                              Start_Character: state.start_character,
                              Number_Digits: state.number_digits.value,
                              Start_Number: state.start_number,
                              End_Number: state.end_number,
                              Current_Token_Count: state.current_token_count, //Current_Token_Count: currTokCount,
                              Number_Of_Tokens: state.number_of_tokens,
                              Priority: parseInt(state.priority),
                              New_Token_Slot_For_Cancelled_Token:
                                state.new_token_slot_for_cancelled_token,
                              Reset_Tokens_At_Day_Close:
                                state.reset_tokens_at_day_close,
                              Stop_Token_Generation_When_End_Num_Reached:
                                state.stop_token_gen_when_end_num_reached,
                              Token_Auto_Close_Time:
                                state.token_auto_close_time_val.value,
                              Build_AI_Generated: state.build_ai_generated,
                              Show_On_Mobile_App: state.show_on_mobile_app,
                              Disable_Token_Generation_On_Mobile_App:
                                state.disable_token_gen_on_mobile_app,
                              Show_On_Dispenser_App:
                                state.show_on_dispenser_app,
                              Hide_Token_Status_On_Mobile_App:
                                state.hide_token_status_on_mobile_app,
                              Hide_Token_Number_On_Mobile_App:
                                state.hide_token_num_on_mobile_app,
                              Service_Days: state.service_days || [],
                              Updated_Date: now,
                              Last_Printed_Number: state.last_printed_number,
                              Last_Printed_Date_Time:
                                state.last_printed_date_time,
                              Last_Called_Number: state.last_called_number,
                              Last_Called_Date_Time:
                                state.last_called_date_time,
                              Last_Called_Counter: state.last_called_counter,
                              Last_Called_User: state.last_called_user,
                              Current_Status: state.current_status,
                              Last_Generated_Token: state.last_generated_token, //lastGenTok,
                              //Last_Generated_Token_Date_Time: moment(new Date()).tz(timeZone).format("DD-MM-YYYY HH:mm:ss"), // output format: '24-02-2020 20:12:15',       //Last_Generated_Token_Date_Time: lastGenTokDateTime,
                              Last_Generated_Token_Date_Time: moment(new Date())
                                .tz(timeZone)
                                .subtract(1, "day")
                                .format("DD-MM-YYYY HH:mm:ss"), // output format: '23-02-2020 20:12:15'
                              Main_Location_ID:
                                state.selected_main_location.value,
                              Sub_Location_ID:
                                state.selected_sub_location.value,
                              Waiting_In_Queue: 0,
                              Served_Tokens: 0,
                              List_Order:
                                state.selected_sub_location.counts + 1,
                              TimeZone: state.time_zone,
                              Service_Group_Profile_Id: servGroupProfileID,
                              Request_Approval_Group_Profile_Id:
                                requestApprovalGroupProfileID,
                              Mobile_App_Input_Fields: mobileAppInputFields,
                              Mobile_App_File_Attachments:
                                mobileAppFileAttachments,
                              mobile_app_file_attachment_groups:
                                state.mobile_app_file_attachment_groups || [],
                              Service_Mobile_App: state.service_group_show_on_mobile_app, //TODO: Check if needed / related to Service_Group_Show_On_Mobile_App field
                              Force_Close_Appointment_After_Service_Date_Passes: state.force_close_appointment_after_service_date_passes,
                              Push_User_Notifications_On_Token_Creation_Or_Changes: state.push_user_notifications_on_token_creation_or_changes,
                              Book_Appointment_Authorized_Customers: state.book_appointment_authorized_only,
                              Calendar_ID:state?.selected_calendar||'',
                              Enable_Calendar_Days_Only:state?.Enable_Calendar_Days_Only||false,

                            };

                            Firebase.firestore()
                              .collection("Services")
                              .doc(state.service_key)
                              .set(new_service_data)
                              .then(function () {
                                setState({ loading: false });
                                notifyMessage(
                                  "tc",
                                  2,
                                  "New Service added successfully."
                                );
                                serviceDetUpdate()
                                goBack();
                              })
                              .catch(function (error) {
                                setState({ loading: false });
                                notifyMessage(
                                  "tc",
                                  3,
                                  "Encountered an unexpected error while loading!"
                                );
                                console.error("handleAdd error: ", error);
                              });
                          })
                          .catch(function (error) {
                            setState({ loading: false });
                            notifyMessage(
                              "tc",
                              3,
                              "Encountered an unexpected error while loading!"
                            );
                            console.error("handleAdd error: ", error);
                          });
                      })
                      .catch(function (error) {
                        setState({ loading: false });
                        notifyMessage(
                          "tc",
                          3,
                          "Encountered an unexpected error while loading!"
                        );
                        console.error("handleAdd error: ", error);
                      });
                  }
                } else {
                  let new_service_data = {
                    Created_Date: now,
                    Service_Type: state.selected_service_type.value,
                    Min_Number_Of_Days_Appointment_Can_Be_Scheduled_Ahead:
                      state.selected_service_type == "Appointment"
                        ? parseInt(
                            state.min_num_of_days_appointment_can_be_scheduled_ahead
                          )
                        :state.selected_service_type == "Form"?0: -1,
                    Max_Number_Of_Days_Appointment_Can_Be_Scheduled_Ahead:
                      state.selected_service_type == "Appointment"
                        ? parseInt(
                            state.max_num_of_days_appointment_can_be_scheduled_ahead
                          )
                        :state.selected_service_type == "Form"?1: -1,
                    Icon: "",
                    Name: state.name,
                    Customer_ID: customer_id,
                    Details: state.service_details,
                    Service_Details_For_Display:
                      state.service_details_for_display,
                    Service_Details_For_Display_Toggle:
                      state.service_details_for_display_toggle,
                    Out_Of_Service_Hours_Message: state.out_of_serv_hours_msg,
                    Start_Character: state.start_character,
                    Number_Digits: state.number_digits.value,
                    Start_Number: state.start_number,
                    End_Number: state.end_number,
                    Current_Token_Count: state.current_token_count, //currTokCount,
                    Number_Of_Tokens: state.number_of_tokens,
                    Priority: parseInt(state.priority),
                    New_Token_Slot_For_Cancelled_Token:
                      state.new_token_slot_for_cancelled_token,
                    Reset_Tokens_At_Day_Close: state.reset_tokens_at_day_close,
                    Stop_Token_Generation_When_End_Num_Reached:
                      state.stop_token_gen_when_end_num_reached,
                    Token_Auto_Close_Time:
                      state.token_auto_close_time_val.value,
                    Build_AI_Generated: state.build_ai_generated,
                    Show_On_Mobile_App: state.show_on_mobile_app,
                    Disable_Token_Generation_On_Mobile_App:
                      state.disable_token_gen_on_mobile_app,
                    Show_On_Dispenser_App: state.show_on_dispenser_app,
                    Hide_Token_Status_On_Mobile_App:
                      state.hide_token_status_on_mobile_app,
                    Hide_Token_Number_On_Mobile_App:
                      state.hide_token_num_on_mobile_app,
                    Service_Days: state.service_days || [],
                    Updated_Date: now,
                    Last_Printed_Number: state.last_printed_number,
                    Last_Printed_Date_Time: state.last_printed_date_time,
                    Last_Called_Number: state.last_called_number,
                    Last_Called_Date_Time: state.last_called_date_time,
                    Last_Called_Counter: state.last_called_counter,
                    Last_Called_User: state.last_called_user,
                    Current_Status: state.current_status,
                    Last_Generated_Token: state.last_generated_token, //lastGenTok,
                    // Last_Generated_Token_Date_Time: moment(new Date()).tz(timeZone).format("DD-MM-YYYY HH:mm:ss"), // output format: '24-02-2020 20:12:15',      //lastGenTokDateTime,
                    Last_Generated_Token_Date_Time: moment(new Date())
                      .tz(timeZone)
                      .subtract(1, "day")
                      .format("DD-MM-YYYY HH:mm:ss"), // output format: '23-02-2020 20:12:15'
                    Main_Location_ID: state.selected_main_location.value,
                    Sub_Location_ID: state.selected_sub_location.value,
                    Waiting_In_Queue: 0,
                    Served_Tokens: 0,
                    List_Order: state.selected_sub_location.counts + 1,
                    TimeZone: state.time_zone,
                    Service_Group_Profile_Id: servGroupProfileID,
                    Request_Approval_Group_Profile_Id:
                      requestApprovalGroupProfileID,
                    Mobile_App_Input_Fields: mobileAppInputFields,
                    Mobile_App_File_Attachments: mobileAppFileAttachments,
                    mobile_app_file_attachment_groups:
                      state.mobile_app_file_attachment_groups || [],
                    Service_Mobile_App: state.service_group_show_on_mobile_app, //27DEC2021 0200HRS, TODO: Check if needed / related to Service_Group_Show_On_Mobile_App field
                    Force_Close_Appointment_After_Service_Date_Passes: state.force_close_appointment_after_service_date_passes,
                    Push_User_Notifications_On_Token_Creation_Or_Changes: state.push_user_notifications_on_token_creation_or_changes,
                    Book_Appointment_Authorized_Customers:
                      state.book_appointment_authorized_only,
                      Calendar_ID:state?.selected_calendar||'',
                      Enable_Calendar_Days_Only:state?.Enable_Calendar_Days_Only||false,

                  };

                  Firebase.firestore()
                    .collection("Services")
                    .doc(state.service_key)
                    .set(new_service_data)
                    .then(function () {
                      setState({ loading: false });
                      notifyMessage("tc", 2, "Service saved successfully.");
                      serviceDetUpdate()
                      goBack();
                    })
                    .catch(function (error) {
                      setState({ loading: false });
                      notifyMessage(
                        "tc",
                        3,
                        "Encountered an unexpected error while loading!"
                      );
                      console.error("handleAdd error: ", error);
                    });
                }
              } else {
                setState({ loading: false });
                notifyMessage(
                  "tc",
                  3,
                  "Please make sure all required fields are filled."
                );
                console.log("handleAdd>>>2 ");
                console.log({
                  nameState: nameState,
                  secondary_service_nameState: secondary_service_nameState,
                  invalid_start_number: state.invalid_start_number,
                  invalid_end_number: state.invalid_end_number,
                  invalid_number_of_tokens: state.invalid_number_of_tokens,
                  service_group_nameState: state.service_group_nameState,
                  service_group_descriptionState:
                    state.service_group_descriptionState,
                  request_approval_group_nameState:
                    state.request_approval_group_nameState,
                  request_approval_group_descriptionState:
                    state.request_approval_group_descriptionState,
                  service_group_icon_attachmentState:
                    state.service_group_nameState,
                });
              }
            }
          }
        }
      })
      .catch(function (error) {
        setState({ loading: false });
        notifyMessage(
          "tc",
          3,
          "Encountered an unexpected error while loading!"
        );
        console.error("validateItemCount error: ", error);
        //return error;
      });
  }

  function handleAddDay() {
    if (
      state.issue_start_time !== "" &&
      state.issue_end_time !== "" &&
      state.call_start_time !== "" &&
      state.call_end_time !== ""
    ) {
      let new_service_day = {
        week_day_order: state.new_week_select.value,
        week_day: state.new_week_select.label,
        start_time: state.issue_start_time,
        end_time: state.issue_end_time,
        tok_issue_cut_off_time:
          typeof state.tok_issue_cut_off_time !== "undefined" &&
          state.tok_issue_cut_off_time !== "" &&
          state.tok_issue_cut_off_time !== 0 &&
          state.tok_issue_cut_off_time !== "0"
            ? state.tok_issue_cut_off_time
            : "0",
        call_start_time: state.call_start_time,
        call_end_time: state.call_end_time,
      };

      if (new_service_day.start_time >= new_service_day.end_time) {
        notifyMessage(
          "tc",
          3,
          "Token Issue Start time cannot be same or later than Token Issue End time!"
        );
        return;
      }

      if (new_service_day.call_start_time >= new_service_day.call_end_time) {
        notifyMessage(
          "tc",
          3,
          "Token Call Start time cannot be same or later than Token Call End time!"
        );
        return;
      }

      if (new_service_day.start_time >= new_service_day.call_start_time) {
        notifyMessage(
          "tc",
          3,
          "Token Issue Start time cannot be same or later than Token Call Start time!"
        );
        return;
      }

      // if (new_service_day.call_end_time >= new_service_day.end_time) {
      //     notifyMessage("tc", 3, "Token Call End time cannot be same or later than Token Call Issue time!");
      //     return;
      // }

      // if (new_service_day.call_end_time > new_service_day.tok_issue_cut_off_time) {
      //     notifyMessage("tc", 3, "Token Call End time cannot be later than Token Issue Current Day Cut-Off time!");
      //     return;
      // }

      let cur_service_days = state.service_days;
      let sames = cur_service_days.filter(
        (item) => item.week_day === new_service_day.week_day
      );
      let overlap = false;
      sames.forEach(function (one) {
        if (
          (new_service_day.start_time >= one.start_time &&
            new_service_day.start_time <= one.end_time) ||
          (new_service_day.end_time >= one.start_time &&
            new_service_day.end_time <= one.end_time) ||
          (new_service_day.call_start_time >= one.call_start_time &&
            new_service_day.call_start_time <= one.call_end_time) ||
          (new_service_day.call_end_time >= one.call_start_time &&
            new_service_day.call_end_time <= one.call_end_time)
        ) {
          notifyMessage("tc", 3, "Time Range Overlap!");
          overlap = true;
          return;
        }
      });

      if (overlap) return;

      cur_service_days.push(new_service_day);
      // ------------- Sort Date and Start Time ------------ //
      let sorted = cur_service_days.sort(function (a, b) {
        if (a.week_day === b.week_day) {
          let x = a.start_time > b.start_time ? -1 : 1;
          return x;
        } else {
          let x = a.week_day_order < b.week_day_order ? -1 : 1;
          return x;
        }
      });
      console.log("service_days", sorted);
      setState({ service_days: sorted });
      setState({ issue_start_time: "" });
      setState({ issue_end_time: "" });
      setState({ tok_issue_cut_off_time: "" });
      setState({ call_start_time: "" });
      setState({ call_end_time: "" });
    } else {
      notifyMessage(
        "tc",
        3,
        "You must fill in all the time slots correctly before you can set token issue times and token calling times!"
      );
    }
  }

  function handleDeleteDay(index) {
    var cur_service_days = state.service_days;
    if (index !== -1) cur_service_days.splice(index, 1);
    setState({ service_days: cur_service_days });
  }

  function isNormalInteger(str) {
    var n = Math.floor(Number(str));
    return n !== Infinity && String(n) === str && n >= 0;
  }

  function increaseMaxNumberOfDaysAppointmentCanBeScheduledAhead() {
    // let minnodacbsa = parseInt(state.min_num_of_days_appointment_can_be_scheduled_ahead);
    // let minminnodacbsa = parseInt(state.min_min_num_of_days_appointment_can_be_scheduled_ahead);
    // let minmaxnodacbsa = parseInt(state.min_max_num_of_days_appointment_can_be_scheduled_ahead);
    let maxnodacbsa = parseInt(
      state.max_num_of_days_appointment_can_be_scheduled_ahead
    );
    let maxminnodacbsa = parseInt(
      state.max_min_num_of_days_appointment_can_be_scheduled_ahead
    );
    let maxmaxnodacbsa = parseInt(
      state.max_max_num_of_days_appointment_can_be_scheduled_ahead
    );

    if (
      maxnodacbsa + 1 >= maxminnodacbsa &&
      maxnodacbsa + 1 <= maxmaxnodacbsa
    ) {
      setState({
        max_num_of_days_appointment_can_be_scheduled_ahead: maxnodacbsa + 1,
        invalid_max_num_of_days_appointment_can_be_scheduled_ahead: false,
      });
    } else {
      setState({
        invalid_max_num_of_days_appointment_can_be_scheduled_ahead: true,
      });
      notifyMessage(
        "tc",
        3,
        "Maximum Number of Days Appointment Can Be Scheduled Ahead must be between " +
          maxminnodacbsa +
          " or " +
          maxmaxnodacbsa +
          "!"
      );
    }
  }

  function decreaseMaxNumberOfDaysAppointmentCanBeScheduledAhead() {
    // let minnodacbsa = parseInt(state.min_num_of_days_appointment_can_be_scheduled_ahead);
    // let minminnodacbsa = parseInt(state.min_min_num_of_days_appointment_can_be_scheduled_ahead);
    // let minmaxnodacbsa = parseInt(state.min_max_num_of_days_appointment_can_be_scheduled_ahead);
    let maxnodacbsa = parseInt(
      state.max_num_of_days_appointment_can_be_scheduled_ahead
    );
    let maxminnodacbsa = parseInt(
      state.max_min_num_of_days_appointment_can_be_scheduled_ahead
    );
    let maxmaxnodacbsa = parseInt(
      state.max_max_num_of_days_appointment_can_be_scheduled_ahead
    );

    if (
      maxnodacbsa - 1 >= maxminnodacbsa &&
      maxnodacbsa - 1 <= maxmaxnodacbsa
    ) {
      setState({
        max_num_of_days_appointment_can_be_scheduled_ahead: maxnodacbsa - 1,
        invalid_max_num_of_days_appointment_can_be_scheduled_ahead: false,
      });
    } else {
      setState({
        invalid_max_num_of_days_appointment_can_be_scheduled_ahead: true,
      });
      notifyMessage(
        "tc",
        3,
        "Maximum Number of Days Appointment Can Be Scheduled Ahead must be between " +
          maxminnodacbsa +
          " or " +
          maxmaxnodacbsa +
          "!"
      );
    }
  }

  function increaseMinNumberOfDaysAppointmentCanBeScheduledAhead() {
    let minnodacbsa = parseInt(
      state.min_num_of_days_appointment_can_be_scheduled_ahead
    );
    let minminnodacbsa = parseInt(
      state.min_min_num_of_days_appointment_can_be_scheduled_ahead
    );
    let minmaxnodacbsa = parseInt(
      state.min_max_num_of_days_appointment_can_be_scheduled_ahead
    );
    // let maxnodacbsa = parseInt(state.max_num_of_days_appointment_can_be_scheduled_ahead);
    // let maxminnodacbsa = parseInt(state.max_min_num_of_days_appointment_can_be_scheduled_ahead);
    // let maxmaxnodacbsa = parseInt(state.max_max_num_of_days_appointment_can_be_scheduled_ahead);

    if (
      minnodacbsa + 1 >= minminnodacbsa &&
      minnodacbsa + 1 <= minmaxnodacbsa
    ) {
      setState({
        min_num_of_days_appointment_can_be_scheduled_ahead: minnodacbsa + 1,
        invalid_min_num_of_days_appointment_can_be_scheduled_ahead: false,
      });
    } else {
      setState({
        invalid_min_num_of_days_appointment_can_be_scheduled_ahead: true,
      });
      notifyMessage(
        "tc",
        3,
        "Minimum Number of Days Appointment Can Be Scheduled Ahead must be between " +
          minminnodacbsa +
          " or " +
          minmaxnodacbsa +
          "!"
      );
    }
  }

  function decreaseMinNumberOfDaysAppointmentCanBeScheduledAhead() {
    let minnodacbsa = parseInt(
      state.min_num_of_days_appointment_can_be_scheduled_ahead
    );
    let minminnodacbsa = parseInt(
      state.min_min_num_of_days_appointment_can_be_scheduled_ahead
    );
    let minmaxnodacbsa = parseInt(
      state.min_max_num_of_days_appointment_can_be_scheduled_ahead
    );
    // let maxnodacbsa = parseInt(state.max_num_of_days_appointment_can_be_scheduled_ahead);
    // let maxminnodacbsa = parseInt(state.max_min_num_of_days_appointment_can_be_scheduled_ahead);
    // let maxmaxnodacbsa = parseInt(state.max_max_num_of_days_appointment_can_be_scheduled_ahead);

    if (
      minnodacbsa - 1 >= minminnodacbsa &&
      minnodacbsa - 1 <= minmaxnodacbsa
    ) {
      setState({
        min_num_of_days_appointment_can_be_scheduled_ahead: minnodacbsa - 1,
        invalid_max_num_of_days_appointment_can_be_scheduled_ahead: false,
      });
    } else {
      setState({
        invalid_min_num_of_days_appointment_can_be_scheduled_ahead: true,
      });
      notifyMessage(
        "tc",
        3,
        "Minimum Number of Days Appointment Can Be Scheduled Ahead must be between " +
          minminnodacbsa +
          " or " +
          minmaxnodacbsa +
          "!"
      );
    }
  }

  function increaseServiceGroupListOrderNumber() {
    let sglon = parseInt(state.service_group_list_order_number);
    if (sglon - 1 >= 1) {
      setState({
        service_group_list_order_number: sglon + 1,
        invalid_service_group_list_order_number: false,
      });
    } else {
      setState({ invalid_service_group_list_order_number: true });
      notifyMessage("tc", 3, "Service Group List Order must be 1 or more!");
    }
  }

  function decreaseServiceGroupListOrderNumber() {
    let sglon = parseInt(state.service_group_list_order_number);
    if (sglon - 1 >= 1) {
      setState({
        service_group_list_order_number: sglon - 1,
        invalid_service_group_list_order_number: false,
      });
    } else {
      setState({ invalid_service_group_list_order_number: true });
      notifyMessage("tc", 3, "Service Group List Order must be 1 or more!");
    }
  }

  function increaseStartNumber() {
    if (state.start_number.length <= state.number_digits.value) {
      let new_num = parseInt(state.start_number) + 1;
      let new_token = new_num - 1;
      let str_num = new_num.toString();
      let str_token = new_token.toString();
      let digits = state.number_digits.value;
      let pad = "";
      for (let i = 0; i < digits; i++) {
        pad += "0";
      }

      let start_number =
        pad.substring(0, pad.length - str_num.length) + str_num;
      let last_generated_token =
        pad.substring(0, pad.length - str_token.length) + str_token;
      setState({ start_number: start_number });
      setState({ last_generated_token: last_generated_token });
      if (new_num < parseInt(state.end_number)) {
        setState({ invalid_start_number: false });
        setState({ invalid_end_number: false });
      } else {
        setState({ invalid_start_number: true });
      }
    } else {
      setState({ invalid_start_number: true });
    }
  }

  function decreaseStartNumber() {
    if (state.start_number.length <= state.number_digits.value) {
      let cur_value = parseInt(state.start_number);
      if (cur_value > 1) {
        let new_value = parseInt(state.start_number) - 1;
        let new_token = new_value - 1;
        let str_num = new_value.toString();
        let str_token = new_token.toString();
        let digits = state.number_digits.value;
        let pad = "";
        for (let i = 0; i < digits; i++) {
          pad += "0";
        }

        let start_number =
          pad.substring(0, pad.length - str_num.length) + str_num;
        let last_generated_token =
          pad.substring(0, pad.length - str_token.length) + str_token;
        setState({ start_number: start_number });
        setState({ last_generated_token: last_generated_token });
        if (new_value < parseInt(state.end_number)) {
          setState({ invalid_start_number: false });
          setState({ invalid_end_number: false });
        } else {
          setState({ invalid_start_number: true });
        }
      }
    } else {
      setState({ invalid_start_number: true });
    }
  }

  function increaseEndNumber() {
    if (state.end_number.length <= state.number_digits.value) {
      let new_value = parseInt(state.end_number) + 1;
      let str = new_value.toString();
      let digits = state.number_digits.value;
      let pad = "";
      for (let i = 0; i < digits; i++) {
        pad += "0";
      }

      let new_end_number = pad.substring(0, pad.length - str.length) + str;
      setState({ end_number: new_end_number });
      if (parseInt(state.start_number) < new_value) {
        setState({ invalid_start_number: false });
        setState({ invalid_end_number: false });
      } else {
        setState({ invalid_end_number: true });
      }
    } else {
      setState({ invalid_end_number: true });
    }
  }

  function decreaseEndNumber() {
    if (state.end_number.length <= state.number_digits.value) {
      let cur_value = parseInt(state.end_number);
      if (cur_value > 2) {
        let new_value = parseInt(state.end_number) - 1;
        let str = new_value.toString();
        let digits = state.number_digits.value;
        let pad = "";
        for (let i = 0; i < digits; i++) {
          pad += "0";
        }

        let new_end_number = pad.substring(0, pad.length - str.length) + str;
        setState({ end_number: new_end_number });
        if (parseInt(state.start_number) < new_value) {
          setState({ invalid_start_number: false });
          setState({ invalid_end_number: false });
        } else {
          setState({ invalid_end_number: true });
        }
      }
    } else {
      setState({ invalid_end_number: true });
    }
  }

  function increaseNumberOfTokens() {
    let number_of_tokens = parseInt(state.number_of_tokens);
    let token_range = getTokenRange();
    if (
      (number_of_tokens + 1 <= token_range && number_of_tokens + 1 >= 1) ||
      number_of_tokens == 0
    ) {
      setState({ invalid_number_of_tokens: false });
      let new_value = number_of_tokens + 1;
      setState({ number_of_tokens: new_value });
    } else {
      setState({ invalid_number_of_tokens: true });
      notifyMessage("tc", 3, "Number of tokens is out of range!");
    }
    // console.log("invalid_number_of_tokens", state.invalid_number_of_tokens, "getTokenRange()", getTokenRange(),"number_of_tokens", state.number_of_tokens);
  }

  function decreaseNumberOfTokens() {
    let number_of_tokens = parseInt(state.number_of_tokens);
    let token_range = getTokenRange();
    if (
      (number_of_tokens - 1 <= token_range && number_of_tokens - 1 >= 1) ||
      number_of_tokens == 0
    ) {
      setState({ invalid_number_of_tokens: false });
      let new_value = number_of_tokens - 1;
      setState({ number_of_tokens: new_value });
    } else {
      setState({ invalid_number_of_tokens: true });
      notifyMessage("tc", 3, "Number of tokens is out of range!");
    }
    // console.log("invalid_number_of_tokens", state.invalid_number_of_tokens, "getTokenRange()", getTokenRange(),"number_of_tokens", state.number_of_tokens);
  }

  function getTokenRange() {
    let tokRange = parseInt(state.end_number) - parseInt(state.start_number);
    return tokRange;
  }

  function getServiceDays() {
    return state.service_days.map((prop, key) => {
      const ticof = prop.tok_issue_cut_off_time;
      const tokIssueCutOffTime =
        typeof ticof !== "undefined" &&
        ticof !== "" &&
        ticof !== 0 &&
        ticof !== "0"
          ? ticof
          : "0";
      return (
        <tr key={key}>
          <td>{prop.week_day}</td>
          <td>{prop.start_time}</td>
          <td>{prop.end_time}</td>
          {state.disable_fields_based_on_service_type_appointment === false ? (
            <td>{tokIssueCutOffTime}</td>
          ) : null}
          <td>{prop.call_start_time}</td>
          <td>{prop.call_end_time}</td>
          <td className="td-actions">
            <Button
              className="btn-neutral"
              color="default"
              data-placement="left"
              id={"day" + key.toString()}
              title=""
              type="button"
              onClick={(e) => {
                e.preventDefault();
                handleDeleteDay(key);
              }}
            >
              <i className="nc-icon nc-simple-remove" />
            </Button>
            <UncontrolledTooltip
              delay={0}
              placement="left"
              target={"day" + key.toString()}
            >
              Remove item
            </UncontrolledTooltip>
          </td>
        </tr>
      );
    });
  }

  function onChangeServiceType(e) {
    setState({ selected_service_type: e });

    if (e.value === "Token") {
      setState({
        disable_fields_based_on_service_type_appointment: true,
      });
    } else {
      setState({
        disable_fields_based_on_service_type_appointment: false,
      });
    }
    console.log("e", e);
    console.log(
      "disable_fields_based_on_service_type_appointment",
      state.disable_fields_based_on_service_type_appointment
    );
  }

  function onChangeMain(e) {
    setState({ selected_main_location: e });
    loadSubLocationByMain(e.value);
  }

  function onChangeSub(e) {
    setState({ selected_sub_location: e });
    loadStartEndNumberList(e.value);
    setState({ time_zone: e.time_zone });
  }

  function checkNumberOverlap() {
    let number_list = state.number_list;
    let start_number = parseInt(state.start_number);
    let end_number = parseInt(state.end_number);
    let overlap = false;
    number_list.forEach(function (item) {
      if (
        (start_number >= parseInt(item.start_number) &&
          start_number <= parseInt(item.end_number)) ||
        (end_number >= parseInt(item.start_number) &&
          end_number <= parseInt(item.end_number))
      ) {
        overlap = true;
        return;
      }
    });

    return overlap;
  }

  function checkNumberOfTokensOutOfRange() {
    let number_of_tokens = parseInt(state.number_of_tokens);
    let token_range = getTokenRange();
    let out_of_range = true;

    if (
      (number_of_tokens <= token_range && number_of_tokens >= 1) ||
      number_of_tokens == 0
    ) {
      out_of_range = false;
    }
    return out_of_range;
  }

  const goBack = () => {
    setTimeout(() => {
      props.history.push("/services");
    }, 1000);
  };

  const notifyMessage = (place, color, text) => {
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }

    var options = {};
    options = {
      place: place,
      message: (
        <div className="text-md-center">
          <div>
            <b>{text}</b>
          </div>
        </div>
      ),
      type: type,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 3,
    };
    if (options !== null) {
      notificationAlert.current.notificationAlert(options);
    }
  };

  const verifyLength = (value, length) => {
    return value.length >= length;
  };

  const verifyNumber = (value) => {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  };

  const handleChange = (event, stateName, type, stateNameEqualTo) => {
    console.log(
      "state.mobile_app_file_attachment",
      state.mobile_app_file_attachment
    );
    switch (type) {
      case "length":
        if (verifyLength(event.target.value, stateNameEqualTo)) {
          setState({ [stateName + "State"]: "has-success" });
        } else {
          setState({ [stateName + "State"]: "has-danger" });
        }

        setState({ [stateName]: event.target.value });
        break;
      case "start_number":
        if (
          verifyNumber(event.target.value) &&
          parseInt(event.target.value) > 0 &&
          parseInt(event.target.value) < parseInt(state.end_number) &&
          event.target.value.length <= state.number_digits.value
        ) {
          setState({ invalid_start_number: false });
          setState({ invalid_end_number: false });
          setState({ start_number: event.target.value });
          let str = (parseInt(event.target.value) - 1).toString();
          let pad = "0000";
          let last_generated_token =
            pad.substring(0, pad.length - str.length) + str;
          setState({ last_generated_token: last_generated_token });
        } else {
          setState({ invalid_start_number: true });
          setState({ last_generated_token: "" });
        }
        break;
      case "end_number":
        if (
          verifyNumber(event.target.value) &&
          parseInt(event.target.value) > 1 &&
          parseInt(event.target.value) > parseInt(state.start_number) &&
          event.target.value.length <= state.number_digits.value
        ) {
          setState({ invalid_end_number: false });
          setState({ invalid_start_number: false });
          setState({ end_number: event.target.value });
        } else {
          setState({ end_number: event.target.value });
          setState({ invalid_end_number: true });
        }
        break;
      case "number_of_tokens":
        let number_of_tokens_targetValue = event.target.value;
        let token_range = getTokenRange();

        if (
          (verifyNumber(event.target.value) &&
            number_of_tokens_targetValue <= token_range &&
            number_of_tokens_targetValue >= 1) ||
          number_of_tokens_targetValue == 0
        ) {
          setState({ invalid_number_of_tokens: false });
          setState({ number_of_tokens: event.target.value });
        } else {
          setState({ invalid_number_of_tokens: true });
        }
        // console.log("invalid_number_of_tokens", state.invalid_number_of_tokens, "getTokenRange()", getTokenRange(),"number_of_tokens", state.number_of_tokens);
        break;
      case "max_num_of_days_appointment_can_be_scheduled_ahead":
        if (verifyNumber(event.target.value) && event.target.value >= 1) {
          setState({
            invalid_max_num_of_days_appointment_can_be_scheduled_ahead: false,
          });
          setState({
            max_num_of_days_appointment_can_be_scheduled_ahead:
              event.target.value,
          });
        } else {
          setState({
            invalid_max_num_of_days_appointment_can_be_scheduled_ahead: true,
          });
        }
        // console.log("invalid_max_num_of_days_appointment_can_be_scheduled_ahead", state.invalid_max_num_of_days_appointment_can_be_scheduled_ahead, "getTokenRange()", getTokenRange(),"max_num_of_days_appointment_can_be_scheduled_ahead", state.max_num_of_days_appointment_can_be_scheduled_ahead);
        break;
      case "min_num_of_days_appointment_can_be_scheduled_ahead":
        if (verifyNumber(event.target.value) && event.target.value >= 1) {
          setState({
            invalid_min_num_of_days_appointment_can_be_scheduled_ahead: false,
          });
          setState({
            min_num_of_days_appointment_can_be_scheduled_ahead:
              event.target.value,
          });
        } else {
          setState({
            invalid_min_num_of_days_appointment_can_be_scheduled_ahead: true,
          });
        }
        // console.log("invalid_min_num_of_days_appointment_can_be_scheduled_ahead", state.invalid_min_num_of_days_appointment_can_be_scheduled_ahead, "getTokenRange()", getTokenRange(),"max_num_of_days_appointment_can_be_scheduled_ahead", state.max_num_of_days_appointment_can_be_scheduled_ahead);
        break;
      case "service_group_list_order_number":
        if (
          verifyNumber(event.target.value) &&
          parseInt(event.target.value) >= 1
        ) {
          setState({ invalid_service_group_list_order_number: false });
          setState({ service_group_list_order_number: event.target.value });
        } else {
          setState({ invalid_service_group_list_order_number: true });
        }
        break;
      case "digits":
        setState({ number_digits: event });
        let digits = event.value;
        let str_start = parseInt(state.start_number).toString();
        let str_end = parseInt(state.end_number).toString();
        let pad = "";
        for (let i = 0; i < digits; i++) {
          pad += "0";
        }

        let start_number =
          pad.substring(0, pad.length - str_start.length) + str_start;
        let end_number =
          pad.substring(0, pad.length - str_end.length) + str_end;
        setState({ start_number: start_number });
        setState({ end_number: end_number });
        if (str_start.length > pad.length) {
          setState({ invalid_start_number: true });
        } else {
          setState({ invalid_start_number: false });
        }

        if (str_end.length > pad.length) {
          setState({ invalid_end_number: true });
        } else {
          setState({ invalid_end_number: false });
        }
        break;
      case "token_auto_close_time":
        setState({ token_auto_close_time_val: event });
        let token_auto_close_time = event.value;
        break;
      default:
        break;
    }
    // setState({ [stateName]: event.target.value });
  };

  const handleServiceGroupChange = (newValue, actionMeta) => {
    console.log({
      handleServiceGroupChange: "2",
      service_group_nameState: state.service_group_nameState,
      service_group_descriptionState: state.service_group_descriptionState,
      // selected_service_group_profile: servGroupID,
      service_group_profile_id: state.service_group_profile_id,
      service_group_name: state.service_group_name,
      service_group_description: state.service_group_description,
      service_group_list_order_number: state.service_group_list_order_number,
      service_group_icon: state.service_group_icon,
    });

    console.group("Value Changed");
    console.log(newValue);
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
    // let main_location_id = state.selected_main_location_id; let sub_location_id = state.sub_location_id;
    // let customer_id = JSON.parse(localStorage.getItem("auth_info")).customer_id; //state.customer_id;
    // let created_date = new Date(); let updated_date = new Date();
    // let name = state.service_group_name; let description = state.service_group_description;

    console.log("newValue", newValue, "actionMeta.action", actionMeta.action);

    if (newValue) {
      const {
        __isNew__,
        service_group_name,
        service_group_description,
        service_group_list_order_number,
        service_group_icon_url,
        service_group_id,
        value,
      } = newValue;

      const _service_group_name_ =
        typeof __isNew__ !== "undefined" && __isNew__ === true
          ? value
          : service_group_name;
      const _service_group_description_ =
        typeof __isNew__ !== "undefined" && __isNew__ === true
          ? ""
          : service_group_description;
      const _service_group_list_order_number_ =
        typeof __isNew__ !== "undefined" && __isNew__ === true
          ? ""
          : service_group_list_order_number;
      const _service_group_icon_url_ =
        typeof __isNew__ !== "undefined" && __isNew__ === true
          ? ""
          : service_group_icon_url;

      console.log(
        "__isNew__",
        __isNew__,
        "service_group_id",
        service_group_id,
        "value",
        value,
        "state.customer_id",
        state.customer_id,
        "service_group_name",
        service_group_name,
        "_service_group_name_",
        _service_group_name_,
        "_service_group_description_",
        _service_group_description_,
        "_service_group_list_order_number_",
        _service_group_list_order_number_,
        "_service_group_icon_url_",
        _service_group_icon_url_
      );

      if (__isNew__ !== true) {
        Firebase.firestore()
          .collection("Service_Groups")
          .doc(service_group_id)
          .get()
          .then(function (doc) {
            if (doc.exists) {
              console.log(" validServiceGroupName = true");
              service_group_icon.current.handleSetUrl(_service_group_icon_url_);
              setState({
                service_group_nameState: "has-success",
                service_group_descriptionState: "has-success",
                // selected_service_group_profile: newValue,
                service_group_profile_id: service_group_id,
                service_group_name: _service_group_name_,
                service_group_description: _service_group_description_,
                service_group_list_order_number:
                  _service_group_list_order_number_,
                service_group_icon: _service_group_icon_url_,
              });
              console.log({
                handleServiceGroupChange: "1",
                service_group_nameState: "has-success",
                service_group_descriptionState: "has-success",
                // selected_service_group_profile: newValue,
                service_group_profile_id: service_group_id,
                service_group_name: _service_group_name_,
                service_group_description: _service_group_description_,
                service_group_list_order_number:
                  _service_group_list_order_number_,
                service_group_icon: _service_group_icon_url_,
              });
              handleChange(
                { target: { value: _service_group_name_ || "" } },
                "service_group_name",
                "length",
                1
              );
              handleChange(
                { target: { value: _service_group_description_ || "" } },
                "service_group_description",
                "length",
                1
              );
              handleChange(
                { target: { value: _service_group_list_order_number_ || "" } },
                "service_group_list_order_number",
                "length",
                1
              );
            }
          })
          .catch(function (error) {
            setState({ loading: false });
            notifyMessage(
              "tc",
              3,
              "Encountered an unexpected error while loading!"
            );
            console.error("isValidServiceGroup error: ", error);
          });
      } else {
        var servGroupID = Firebase.firestore()
          .collection("Service_Groups")
          .doc().id;
        service_group_icon.current.handleSetUrl(_service_group_icon_url_);
        setState({
          service_group_nameState: "has-success",
          service_group_descriptionState: "has-success",
          // selected_service_group_profile: servGroupID,
          service_group_profile_id: servGroupID,
          service_group_name: _service_group_name_,
          service_group_description: _service_group_description_,
          service_group_list_order_number: _service_group_list_order_number_,
          service_group_icon: _service_group_icon_url_,
        });
        console.log({
          handleServiceGroupChange: "2",
          service_group_nameState: "has-success",
          service_group_descriptionState: "has-success",
          // selected_service_group_profile: servGroupID,
          service_group_profile_id: servGroupID,
          service_group_name: _service_group_name_,
          service_group_description: _service_group_description_,
          service_group_list_order_number: _service_group_list_order_number_,
          service_group_icon: _service_group_icon_url_,
        });
      }
    } else {
      console.log("actionMeta.action == 'clear'");
      service_group_icon.current.handleSetUrl("");
      setState({
        // selected_service_group_profile: null,
        service_group_nameState: "has-success",
        service_group_descriptionState: "has-success",

        service_group_profile_id: "",
        // service_group_name_list: '',
        default_service_group_name: "",
        service_group_name: "",
        service_group_description: "",
        service_group_list_order_number: "",
        service_group_icon: "",
      });
    }
  };
  async function handleAttachmentGroup(new1, meta) {
    let k = -1;
    console.log("group id new1", new1, "-------------");
    return new Promise((res) => {
      if (new1.__isNew__) {
        var Mobile_App_File_Attachment_Groups1 =
          state.mobile_app_file_attachment_groups;
        Mobile_App_File_Attachment_Groups1.push(new1.label);
        setState({
          mobile_app_file_attachment_groups: Mobile_App_File_Attachment_Groups1,
        });
        console.log(
          "state.mobile_app_file_attachment_groups",
          state.mobile_app_file_attachment_groups
        );
        Mobile_App_File_Attachment_Groups1.forEach((e, k) => {
          if (e == new1.label) res(k);
        });
        res(k);
      } else {
        state.mobile_app_file_attachment_groups.forEach((e, k) => {
          if (e == new1.label) res(k);
        });
        res(k);
      }
    });
  }

  const handleRequestApprovalGroupChange = (newValue, actionMeta) => {
    console.group("Value Changed");
    console.log(newValue);
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
    // let main_location_id = state.selected_main_location_id; let sub_location_id = state.sub_location_id;
    // let customer_id = JSON.parse(localStorage.getItem("auth_info")).customer_id; //state.customer_id;
    // let created_date = new Date(); let updated_date = new Date();
    // let name = state.service_group_name; let description = state.service_group_description;

    console.log("newValue", newValue, "actionMeta.action", actionMeta.action);

    if (newValue) {
      const {
        __isNew__,
        request_approval_group_name,
        request_approval_group_description,
        request_approval_group_id,
        value,
      } = newValue;

      const _request_approval_group_name_ =
        typeof __isNew__ !== "undefined" && __isNew__ === true
          ? value
          : request_approval_group_name;
      const _request_approval_group_description_ =
        typeof __isNew__ !== "undefined" && __isNew__ === true
          ? ""
          : request_approval_group_description;

      console.log(
        "__isNew__",
        __isNew__,
        "request_approval_group_id",
        request_approval_group_id,
        "value",
        value,
        "state.customer_id",
        state.customer_id,
        "request_approval_group_name",
        request_approval_group_name,
        "_request_approval_group_name_",
        _request_approval_group_name_,
        "_request_approval_group_description_",
        _request_approval_group_description_
      );

      if (__isNew__ !== true) {
        Firebase.firestore()
          .collection("Request_Approval_Groups")
          .doc(request_approval_group_id)
          .get()
          .then(function (doc) {
            if (doc.exists) {
              console.log(" validRequestApprovalGroupName = true");
              setState({
                request_approval_group_nameState: "has-success",
                request_approval_group_descriptionState: "has-success",
                // selected_request_approval_group_profile: newValue,
                request_approval_group_profile_id: request_approval_group_id,
                request_approval_group_name: _request_approval_group_name_,
                request_approval_group_description:
                  _request_approval_group_description_,
              });
              console.log({
                handleRequestApprovalGroupChange: "1",
                request_approval_group_nameState: "has-success",
                request_approval_group_descriptionState: "has-success",
                // selected_request_approval_group_profile: newValue,
                request_approval_group_profile_id: request_approval_group_id,
                request_approval_group_name: _request_approval_group_name_,
                request_approval_group_description:
                  _request_approval_group_description_,
              });
              handleChange(
                { target: { value: _request_approval_group_name_ || "" } },
                "request_approval_group_name",
                "length",
                1
              );
              handleChange(
                {
                  target: { value: _request_approval_group_description_ || "" },
                },
                "request_approval_group_description",
                "length",
                1
              );
            }
          })
          .catch(function (error) {
            setState({ loading: false });
            notifyMessage(
              "tc",
              3,
              "Encountered an unexpected error while loading!"
            );
            console.error("isValidRequestApprovalGroup error: ", error);
          });
      } else {
        var reqApprovalGroupID = Firebase.firestore()
          .collection("Request_Approval_Groups")
          .doc().id;
        setState({
          request_approval_group_nameState: "has-success",
          request_approval_group_descriptionState: "has-success",
          // selected_request_approval_group_profile: reqApprovalGroupID,
          request_approval_group_profile_id: reqApprovalGroupID,
          request_approval_group_name: _request_approval_group_name_,
          request_approval_group_description:
            _request_approval_group_description_,
        });
        console.log({
          handleRequestApprovalGroupChange: "2",
          request_approval_group_nameState: "has-success",
          request_approval_group_descriptionState: "has-success",
          // selected_request_approval_group_profile: reqApprovalGroupID,
          request_approval_group_profile_id: reqApprovalGroupID,
          request_approval_group_name: _request_approval_group_name_,
          request_approval_group_description:
            _request_approval_group_description_,
        });
      }
    } else {
      console.log("actionMeta.action == 'clear'");
      setState({
        // selected_request_approval_group_profile: null,
        request_approval_group_nameState: "has-success",
        request_approval_group_descriptionState: "has-success",

        request_approval_group_profile_id: "",
        // request_approval_group_name_list: '',
        default_request_approval_group_name: "",
        request_approval_group_name: "",
        request_approval_group_description: "",
      });
    }
  };

  // loadServiceGroupInfo = (customer_id) => {
  //     console.log("loadServiceGroupInfo>>>1 customer_id", customer_id);
  //     //const { customer_id } = auth_info;

  //     return new Promise((resolve, reject) => {
  //         Firebase.firestore().collection('Service_Groups').where('Customer_ID', '==', customer_id).get()
  //             .then(result=> {
  //                 const service_group_name_list = [];
  //                 result.docs.forEach(service_group =>{
  //                     const { Customer_ID, Name, Description, Icon, Main_Location_ID, Sub_Location_ID, /*Members_Count,*/ List_Order } = service_group.data();
  //                     const option = {
  //                         service_group_id: service_group.id,
  //                         //id: service_group.id,
  //                         value: service_group.id,
  //                         label: Name,
  //                         service_group_name: Name,
  //                         service_group_description: Description,
  //                         service_group_icon: Icon,
  //                         //service_group_members_count: Members_Count,
  //                         service_group_list_order_number: List_Order,
  //                         customer_id: Customer_ID,
  //                         main_location_id: Main_Location_ID,
  //                         sub_location_id: Sub_Location_ID,
  //                     };
  //                     service_group_name_list.push(option);
  //                 });
  //                 console.log("loadServiceGroupInfo>>>2 service_group_name_list", service_group_name_list);
  //                 resolve(service_group_name_list);
  //             })
  //             .catch(function (error) {
  //                 reject(error);
  //             });
  //     });
  // };

  // loadRequestApprovalGroupInfo = (customer_id) => {
  //     console.log("loadRequestApprovalGroupInfo>>>1 customer_id", customer_id);
  //     //const { customer_id } = auth_info;

  //     return new Promise((resolve, reject) => {
  //         Firebase.firestore().collection('Request_Approval_Groups').where('Customer_ID', '==', customer_id).get()
  //             .then(result=> {
  //                 const request_approval_group_name_list = [];
  //                 result.docs.forEach(request_approval_group =>{
  //                     const { Customer_ID, Name, Description, Main_Location_ID, Sub_Location_ID } = request_approval_group.data();
  //                     const option = {
  //                         request_approval_group_id: request_approval_group.id,
  //                         //id: request_approval_group.id,
  //                         value: request_approval_group.id,
  //                         label: Name,
  //                         request_approval_group_name: Name,
  //                         request_approval_group_description: Description,
  //                         customer_id: Customer_ID,
  //                         main_location_id: Main_Location_ID,
  //                         sub_location_id: Sub_Location_ID,
  //                     };
  //                     request_approval_group_name_list.push(option);
  //                 });
  //                 console.log("loadRequestApprovalGroupInfo>>>2 request_approval_group_name_list", request_approval_group_name_list);
  //                 resolve(request_approval_group_name_list);
  //             })
  //             .catch(function (error) {
  //                 reject(error);
  //             });
  //     });
  // };

  const handleChangeMobileAppInputField = (id, event, type) => {
    let inputFields = state.mobile_app_input_fields;
    let newInputFields = [];
    let dataTypeList = state.mobile_app_input_field_datatype_list;
    let mandatoryOptions = state.mobile_app_general_mandatory_options_list;

    if (type == "mobileAppInputFieldName") {
      console.log({
        v: 1,
        mobile_app_input_fields: newInputFields,
        event: event,
        event_target_name: event.target.name,
        event_target_val: event.target.value,
      });
      newInputFields = inputFields.map((i) => {
        console.log(
          "id",
          id,
          "i",
          i,
          "event.target.name",
          event.target.name,
          "event.target.value",
          event.target.value
        );
        if (id === i.id) {
          i["mobileAppInputFieldName"] = event.target.value;

          if (event.target.value === "") {
            console.log("path0");
            i["id"] = "";
            i["mobileAppInputFieldDataType"] = null;
            i["mobileAppInputFieldMandatory"] = null;
            i["customField"] = null;
          } else {
            if (
              i["mobileAppInputFieldDataType"] === null ||
              typeof i["mobileAppInputFieldDataType"] == "undefined" ||
              i["mobileAppInputFieldDataType"] === ""
            ) {
              i["mobileAppInputFieldDataType"] = dataTypeList[0];
              console.log("path1");
            } else {
              console.log("path2");
            }

            if (
              i["mobileAppInputFieldMandatory"] === null ||
              typeof i["mobileAppInputFieldMandatory"] == "undefined" ||
              i["mobileAppInputFieldMandatory"] === ""
            ) {
              i["mobileAppInputFieldMandatory"] = mandatoryOptions[1];
              console.log("path3");
            } else {
              console.log("path4");
            }
          }
        }
        return i;
      });
      setState({ mobile_app_input_fields: newInputFields });
      console.log({
        v: 2,
        mobile_app_input_fields: newInputFields,
        event: event,
        event_target_name: event.target.name,
        event_target_val: event.target.value,
      });
    } else if (type == "mobileAppInputFieldDataType") {
      console.log({
        v: 1,
        mobile_app_input_fields: newInputFields,
        event: event,
      });
      newInputFields = inputFields.map((i) => {
        console.log("id", id, "i", i, "event", event);
        if (id === i.id) {
          i["mobileAppInputFieldDataType"] = event;
        }
        return i;
      });
      setState({ mobile_app_input_fields: newInputFields });
      console.log({
        v: 2,
        mobile_app_input_fields: newInputFields,
        event: event,
      });
    } else if (type == "mobileAppInputFieldMandatory") {
      console.log({
        v: 1,
        mobile_app_input_fields: newInputFields,
        event: event,
      });
      newInputFields = inputFields.map((i) => {
        console.log("id", id, "i", i, "event", event);
        if (id === i.id) {
          i["mobileAppInputFieldMandatory"] = event;
        }
        return i;
      });
      setState({ mobile_app_input_fields: newInputFields });
    }else if (type == "customField") {
      console.log({
        v: 1,
        mobile_app_input_fields: newInputFields,
        event: event,
      });
      newInputFields = inputFields.map((i) => {
        console.log("id", id, "i", i, "event", event);
        if (id === i.id) {
          i["customField"] = event;
        }
        return i;
      });
      setState({ mobile_app_input_fields: newInputFields });
    }
  };

  const handleChangeMobileAppFileAttachment = (id, event, type) => {
    let fileAttachmentFields = state.mobile_app_file_attachments;
    let newFileAttachmentFields = [];
    let fileTypeList = state.mobile_app_file_attachment_filetype_list;
    let mandatoryOptions = state.mobile_app_general_mandatory_options_list;

    if (type == "mobileAppFileAttachmentName") {
      console.log({
        v: 1,
        mobile_app_file_attachments: newFileAttachmentFields,
        event: event,
        event_target_name: event.target.name,
        event_target_val: event.target.value,
      });
      newFileAttachmentFields = fileAttachmentFields.map((i) => {
        console.log(
          "id",
          id,
          "i",
          i,
          "event.target.name",
          event.target.name,
          "event.target.value",
          event.target.value
        );
        if (id === i.id) {
          i["mobileAppFileAttachmentName"] = event.target.value;

          if (event.target.value === "") {
            console.log("path0");
            i["id"] = "";
            i["mobileAppFileAttachmentFileTypesAllowed"] = null;
            i["mobileAppFileAttachmentMandatory"] = null;
          } else {
            if (
              i["mobileAppFileAttachmentFileTypesAllowed"] === null ||
              typeof i["mobileAppFileAttachmentFileTypesAllowed"] ==
                "undefined" ||
              i["mobileAppFileAttachmentFileTypesAllowed"] === ""
            ) {
              i["mobileAppFileAttachmentFileTypesAllowed"] = fileTypeList[0];
              console.log(
                "path1, i['mobileAppFileAttachmentFileTypesAllowed'].value ",
                i["mobileAppFileAttachmentFileTypesAllowed"].value
              );
            } else {
              console.log("path2");
            }

            if (
              i["mobileAppFileAttachmentMandatory"] === null ||
              typeof i["mobileAppFileAttachmentMandatory"] == "undefined" ||
              i["mobileAppFileAttachmentMandatory"] === ""
            ) {
              i["mobileAppFileAttachmentMandatory"] = mandatoryOptions[1];
              console.log("path3");
            } else {
              console.log("path4");
            }
          }
        }
        return i;
      });
      setState({ mobile_app_file_attachments: newFileAttachmentFields });
      console.log({
        v: 2,
        mobile_app_file_attachments: newFileAttachmentFields,
        event: event,
        event_target_name: event.target.name,
        event_target_val: event.target.value,
      });
    } else if (type == "mobileAppFileAttachmentFileTypesAllowed") {
      console.log({
        v: 1,
        mobile_app_file_attachments: newFileAttachmentFields,
        event: event,
      });
      newFileAttachmentFields = fileAttachmentFields.map((i) => {
        console.log("id", id, "i", i, "event", event);
        if (id === i.id) {
          i["mobileAppFileAttachmentFileTypesAllowed"] = event;
        }
        return i;
      });
      setState({ mobile_app_file_attachments: newFileAttachmentFields });
      console.log({
        v: 2,
        mobile_app_file_attachments: newFileAttachmentFields,
        event: event,
      });
    } else if (type == "mobileAppFileAttachmentMandatory") {
      console.log({
        v: 1,
        mobile_app_file_attachments: newFileAttachmentFields,
        event: event,
      });
      newFileAttachmentFields = fileAttachmentFields.map((i) => {
        console.log("id", id, "i", i, "event", event);
        if (id === i.id) {
          i["mobileAppFileAttachmentMandatory"] = event;
        }
        return i;
      });
      setState({ mobile_app_file_attachments: newFileAttachmentFields });
    }
  };

  const handleAddMobileAppInputField = () => {
    console.log({
      mobile_app_input_fields: state.mobile_app_input_fields,
      max_number_of_input_fields: state.max_number_of_input_fields,
    });
    if (
      state.mobile_app_input_fields.length <
      parseInt(state.max_number_of_input_fields)
    ) {
      setState({
        mobile_app_input_fields: [
          ...state.mobile_app_input_fields,
          {
            id: uuidv1(),
            mobileAppInputFieldName: "",
            mobileAppInputFieldDataType: "",
            mobileAppInputFieldMandatory: "",
            customField: [],
            value: "",
            label: "",
          },
        ],
      });
    } else {
      notifyMessage(
        "tc",
        3,
        "You have reached the maximum number of Input fields allowed per Service!"
      );
    }
  };

  const handleAddMobileAppFileAttachment = () => {
    console.log({
      mobile_app_file_attachments: state.mobile_app_file_attachments,
      max_number_of_input_fields: state.max_number_of_file_attachments,
    });
    if (
      state.mobile_app_file_attachments.length <
      parseInt(state.max_number_of_file_attachments)
    ) {
      setState({
        mobile_app_file_attachments: [
          ...state.mobile_app_file_attachments,
          {
            id: uuidv1(),
            mobileAppFileAttachmentName: "",
            mobileAppFileAttachmentFileTypesAllowed: "",
            mobileAppFileAttachmentMandatory: "",
            value: "",
            label: "",
          },
        ],
      });
    } else {
      notifyMessage(
        "tc",
        3,
        "You have reached the maximum number of File attachments allowed per Service!"
      );
    }
  };

  const handleRemoveMobileAppInputField = (id) => {
    const values = [...state.mobile_app_input_fields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setState({ mobile_app_input_fields: values });
  };

  const handleRemoveMobileAppFileAttachment = (id) => {
    const values = [...state.mobile_app_file_attachments];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setState({ mobile_app_file_attachments: values });
  };

  const collapsesToggle = (collapse) => {
    let openedCollapses = state.openedCollapses;
    if (openedCollapses.includes(collapse)) {
      setState({
        openedCollapses: openedCollapses.filter((item) => item !== collapse),
      });
    } else {
      openedCollapses.push(collapse);
      setState({
        openedCollapses: openedCollapses,
      });
    }
  };

  //   render () {
  var ok = false;
  // var mobileAppFileAttachmentFileTypesAllowedExist = false;
  if (
    state.role == "Site_Admin" ||
    state.role == "System_Admin" ||
    state.role == "Location_Admin"
  )
    ok = true;
  // if(state.mobile_app_file_attachment_filetype_list!=undefined && state.mobile_app_file_attachment_filetype_list.length > 0) {
  //     mobileAppFileAttachmentFileTypesAllowedExist = true;
  // }
  let {
    nameState,
    secondary_service_nameState,
    service_group_nameState,
    service_group_descriptionState,
    service_group_icon_attachmentState,

    request_approval_group_nameState,
    request_approval_group_descriptionState,
  } = state;

  const {
    disable_fields_based_on_service_type_appointment,
    service_group_enabled,
    service_group_name_list,
    default_service_group_name,
    service_group_description,
    request_approval_group_enabled,
    request_approval_group_name_list,
    default_request_approval_group_name,
    request_approval_group_description,
    request_approval_group_id,
    mobile_app_input_fields,
    mobile_app_file_attachments,
  } = state;

  return (
    <>
      <LoadingOverlay
        active={state.loading}
        spinner
        text="Loading"
        className="content"
      >
        <NotificationAlert ref={notificationAlert} />
        <Row>
          <Col md="12">
            {ok ? (
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Service Add</CardTitle>
                </CardHeader>
                <CardBody>
                  <Col className="ml-auto mr-auto" lg="8">
                    <Row>
                      <Col md="3">
                        <Button
                          color="success"
                          onClick={(e) => handleAdd()}
                          block
                        >
                          Add
                        </Button>
                      </Col>
                      <Col md="3">
                        <Button
                          color="youtube"
                          onClick={(e) => props.history.push("/services")}
                          block
                        >
                          Close
                        </Button>
                      </Col>
                    </Row>
                    <Row className="top-margin-10"></Row>
                    <Row>
                      <Col md="8">
                        <Form className="form-horizontal">
                          <hr />
                          <Row>
                            <Col md="12">
                              <FormGroup>
                                <h5>General</h5>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Label md="4">Service Type</Label>
                            <Col md="8">
                              <FormGroup>
                                <Select
                                  className="react-select info select-location"
                                  classNamePrefix="react-select"
                                  placeholder="Select Service Type"
                                  name="serviceType"
                                  label="Service Type"
                                  value={state.selected_service_type}
                                  onChange={(e) => onChangeServiceType(e)}
                                  options={state.service_type_list}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Label md="4">Main Location</Label>
                            <Col md="8">
                              <FormGroup>
                                <Select
                                  className="react-select info select-location"
                                  classNamePrefix="react-select"
                                  placeholder="Select Main Location"
                                  name="selectMainLocation"
                                  value={state.selected_main_location}
                                  onChange={(e) => onChangeMain(e)}
                                  options={state.main_location_list}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Label md="4">Sub Location</Label>
                            <Col md="8">
                              <FormGroup>
                                <Select
                                  className="react-select info select-location"
                                  classNamePrefix="react-select"
                                  placeholder="Select Sub Location"
                                  name="selectSubLocation"
                                  value={state.selected_sub_location}
                                  onChange={(e) => onChangeSub(e)}
                                  options={state.sub_location_list}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Label md="4">Service Key</Label>
                            <Col md="8">
                              <FormGroup>
                                <Row>
                                  <Col md="7">
                                    <Input value={state.service_key} disabled />
                                  </Col>
                                  <Col md="5">
                                    <CopyToClipboard
                                      text={state.service_key}
                                      onCopy={(e) =>
                                        notifyMessage(
                                          "tc",
                                          2,
                                          "Copied to clipboard"
                                        )
                                      }
                                    >
                                      <Button
                                        className="margin-0 btn btn-success text-capitalize"
                                        block
                                      >
                                        Copy
                                      </Button>
                                    </CopyToClipboard>
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Label md="4">Sublocation Timezone</Label>
                            <Col md="8">
                              <FormGroup>
                                <Input value={state.time_zone||''} disabled />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Label md="4">Service Name</Label>
                            <Col md="8">
                              <FormGroup className={`has-label ${nameState}`}>
                                <Input
                                  placeholder="Service Name"
                                  value={state.name}
                                  type="text"
                                  onChange={(e) =>
                                    handleChange(e, "name", "length", 1)
                                  }
                                />
                                {state.nameState === "has-danger" ? (
                                  <label className="error">
                                    This field is required.
                                  </label>
                                ) : null}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Label md="4">Secondary Service Name</Label>
                            <Col md="8">
                              <FormGroup className={`has-label ${secondary_service_nameState}`}>
                                <Input
                                  placeholder="Service Name"
                                  value={state.secondary_service_name}
                                  type="text"
                                  onChange={(e) =>
                                    handleChange(e, "secondary_service_name", "length", 1)
                                  }
                                />
                                {state.secondary_service_nameState === "has-danger" ? (
                                  <label className="error">
                                    This field is required.
                                  </label>
                                ) : null}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Label md="4">Icon</Label>
                            <Col md="8">
                              <IconUploadToFirebase ref={service_icon} />
                            </Col>
                          </Row>
                          <Row className="top-margin-10">
                            <Label md="4">Service Details</Label>
                            <Col md="8">
                              <FormGroup>
                                <Input
                                  placeholder="Service Details"
                                  value={state.service_details}
                                  type="textarea"
                                  onChange={(e) => {
                                    setState({
                                      service_details: e.target.value,
                                    });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row className="top-margin-10">
                            <Label md="4">Out of Service Hours Message</Label>
                            <Col md="8">
                              <FormGroup>
                                <Input
                                  placeholder="Out of Service Hours Message"
                                  value={state.out_of_serv_hours_msg}
                                  type="textarea"
                                  onChange={(e) => {
                                    setState({
                                      out_of_serv_hours_msg: e.target.value,
                                    });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Label md="4">Start Character</Label>
                            <Col md="8">
                              <FormGroup>
                                <Input
                                  placeholder="Start Character"
                                  value={state.start_character}
                                  type="text"
                                  maxLength="3"
                                  onChange={(e) => {
                                    setState({
                                      start_character: e.target.value,
                                    });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Label md="4">Number of digits</Label>
                            <Col md="3">
                              <FormGroup>
                                <Select
                                  className="react-select primary"
                                  classNamePrefix="react-select"
                                  value={state.number_digits}
                                  onChange={(value) =>
                                    handleChange(value, "digits", "digits")
                                  }
                                  options={[
                                    { value: 3, label: "3" },
                                    { value: 4, label: "4" },
                                    { value: 5, label: "5" },
                                    { value: 6, label: "6" },
                                  ]}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Label md="4">End Number</Label>
                            <Col md="8">
                              <FormGroup>
                                <Row>
                                  <Col md="4" xs="4">
                                    <Input
                                      value={state.end_number}
                                      type="text"
                                      maxLength={state.number_digits.value}
                                      invalid={state.invalid_end_number}
                                      onChange={(e) => {
                                        handleChange(
                                          e,
                                          "end_number",
                                          "end_number"
                                        );
                                      }}
                                    />
                                  </Col>
                                  <Col md="8" xs="8" className="padding-left-0">
                                    <Button
                                      className="margin-top-0"
                                      color="success"
                                      onClick={(e) => increaseEndNumber()}
                                    >
                                      <i className="fa fa-plus" />
                                    </Button>
                                    <Button
                                      className="margin-top-0"
                                      color="success"
                                      onClick={(e) => decreaseEndNumber()}
                                    >
                                      <i className="fa fa-minus" />
                                    </Button>
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Label md="4">Start Number</Label>
                            <Col md="8">
                              <FormGroup>
                                <Row>
                                  <Col md="4" xs="4">
                                    <Input
                                      value={state.start_number}
                                      type="text"
                                      maxLength={state.number_digits.value}
                                      invalid={state.invalid_start_number}
                                      onChange={(e) => {
                                        handleChange(
                                          e,
                                          "start_number",
                                          "start_number"
                                        );
                                      }}
                                    />
                                  </Col>
                                  <Col md="8" xs="8" className="padding-left-0">
                                    <Button
                                      className="margin-top-0"
                                      color="success"
                                      onClick={(e) => increaseStartNumber()}
                                    >
                                      <i className="fa fa-plus" />
                                    </Button>
                                    <Button
                                      className="margin-top-0"
                                      color="success"
                                      onClick={(e) => decreaseStartNumber()}
                                    >
                                      <i className="fa fa-minus" />
                                    </Button>
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Label md="4">Number Of Tokens</Label>
                            <Col md="8">
                              <FormGroup>
                                <Row>
                                  <Col md="4" xs="4">
                                    <Input
                                      value={state.number_of_tokens}
                                      type="text"
                                      maxLength={getTokenRange()}
                                      minLength={
                                        state.min_length_number_of_tokens
                                      }
                                      invalid={state.invalid_number_of_tokens}
                                      onChange={(e) => {
                                        handleChange(
                                          e,
                                          "number_of_tokens",
                                          "number_of_tokens"
                                        );
                                      }}
                                    />
                                  </Col>
                                  <Col md="8" xs="8" className="padding-left-0">
                                    <Button
                                      className="margin-top-0"
                                      color="success"
                                      onClick={(e) => increaseNumberOfTokens()}
                                    >
                                      <i className="fa fa-plus" />
                                    </Button>
                                    <Button
                                      className="margin-top-0"
                                      color="success"
                                      onClick={(e) => decreaseNumberOfTokens()}
                                    >
                                      <i className="fa fa-minus" />
                                    </Button>
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Label md="4">Auto Close Tokens after</Label>
                            <Col md="3">
                              <FormGroup>
                                <Select
                                  className="react-select primary"
                                  classNamePrefix="react-select"
                                  value={state.token_auto_close_time_val}
                                  onChange={(value) =>
                                    handleChange(
                                      value,
                                      "token_auto_close_time",
                                      "token_auto_close_time"
                                    )
                                  }
                                  options={[
                                    { value: 1, label: "1 hr" },
                                    { value: 2, label: "2 hrs" },
                                    { value: 3, label: "3 hrs" },
                                    { value: 4, label: "4 hrs" },
                                    { value: 5, label: "5 hrs" },
                                    { value: 6, label: "6 hrs" },
                                    { value: 7, label: "7 hrs" },
                                    { value: 8, label: "8 hrs" },
                                    { value: 9, label: "9 hrs" },
                                    { value: 10, label: "10 hrs" },
                                    { value: 11, label: "11 hrs" },
                                    { value: 12, label: "12 hrs" },
                                    { value: 13, label: "13 hrs" },
                                    { value: 14, label: "14 hrs" },
                                    { value: 15, label: "15 hrs" },
                                    { value: 16, label: "16 hrs" },
                                    { value: 17, label: "17 hrs" },
                                    { value: 18, label: "18 hrs" },
                                  ]}
                                  //{ value: 19, label: '19 hrs'},{ value: 20, label: '20 hrs'},{ value: 21, label: '21 hrs'},{ value: 22, label: '22 hrs'},{ value: 23, label: '23 hrs'},{ value: 24, label: '24 hrs'},
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          {disable_fields_based_on_service_type_appointment ===
                          false ? (
                            <>
                              <Row>
                                <Label md="4">
                                  Min Number Of Days Appointment Can Be
                                  Scheduled Ahead
                                </Label>
                                <Col md="8">
                                  <FormGroup>
                                    <Row>
                                      <Col md="4" xs="4">
                                        <Input
                                          value={
                                            state.min_num_of_days_appointment_can_be_scheduled_ahead
                                          }
                                          type="text"
                                          //maxLength={state.max_min_num_of_days_appointment_can_be_scheduled_ahead}
                                          minLength={
                                            state.min_min_num_of_days_appointment_can_be_scheduled_ahead
                                          }
                                          invalid={
                                            state.invalid_min_num_of_days_appointment_can_be_scheduled_ahead
                                          }
                                          onChange={(e) => {
                                            handleChange(
                                              e,
                                              "min_num_of_days_appointment_can_be_scheduled_ahead",
                                              "min_num_of_days_appointment_can_be_scheduled_ahead"
                                            );
                                          }}
                                        />
                                      </Col>
                                      <Col
                                        md="8"
                                        xs="8"
                                        className="padding-left-0"
                                      >
                                        <Button
                                          className="margin-top-0"
                                          color="success"
                                          onClick={(e) =>
                                            increaseMinNumberOfDaysAppointmentCanBeScheduledAhead()
                                          }
                                        >
                                          <i className="fa fa-plus" />
                                        </Button>
                                        <Button
                                          className="margin-top-0"
                                          color="success"
                                          onClick={(e) =>
                                            decreaseMinNumberOfDaysAppointmentCanBeScheduledAhead()
                                          }
                                        >
                                          <i className="fa fa-minus" />
                                        </Button>
                                      </Col>
                                    </Row>
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Label md="4">
                                  Max Number Of Days Appointment Can Be
                                  Scheduled Ahead
                                </Label>
                                <Col md="8">
                                  <FormGroup>
                                    <Row>
                                      <Col md="4" xs="4">
                                        <Input
                                          value={
                                            state.max_num_of_days_appointment_can_be_scheduled_ahead
                                          }
                                          type="text"
                                          //maxLength={state.max_max_num_of_days_appointment_can_be_scheduled_ahead}
                                          minLength={
                                            state.min_max_num_of_days_appointment_can_be_scheduled_ahead
                                          }
                                          invalid={
                                            state.invalid_max_num_of_days_appointment_can_be_scheduled_ahead
                                          }
                                          onChange={(e) => {
                                            handleChange(
                                              e,
                                              "max_num_of_days_appointment_can_be_scheduled_ahead",
                                              "max_num_of_days_appointment_can_be_scheduled_ahead"
                                            );
                                          }}
                                        />
                                      </Col>
                                      <Col
                                        md="8"
                                        xs="8"
                                        className="padding-left-0"
                                      >
                                        <Button
                                          className="margin-top-0"
                                          color="success"
                                          onClick={(e) =>
                                            increaseMaxNumberOfDaysAppointmentCanBeScheduledAhead()
                                          }
                                        >
                                          <i className="fa fa-plus" />
                                        </Button>
                                        <Button
                                          className="margin-top-0"
                                          color="success"
                                          onClick={(e) =>
                                            decreaseMaxNumberOfDaysAppointmentCanBeScheduledAhead()
                                          }
                                        >
                                          <i className="fa fa-minus" />
                                        </Button>
                                      </Col>
                                    </Row>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </>
                          ) : null}
                          <Row>
                            <Label md="4">Working/Holiday Calendar</Label>
                            <Col md="8">
                              <FormGroup>
                                <Select
                                  className="react-select info select-location"
                                  classNamePrefix="react-select"
                                  placeholder="Select a calendar to apply to current service"
                                  name="serviceCalendar"
                                  value={
                                    [state.calendars?.find(e=>e?.id==state?.selected_calendar)]?.map(e=>({label:e?.Name,value:e?.id}))?.[0]
                                  }
                                  onChange={(event) => {
                                    setState({selected_calendar:event?.value})
                                  }}
                                  options={
                                    state.calendars?.map(e=>({label:e?.Name,value:e?.id}))
                                  }
                                  //clearValue={mobile_app_input_field.mobileAppInputFieldName === ""}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                         {state.selected_calendar?.length>0&& <Row>
                            <Label md="4">Enable Calendar Days Only</Label>
                            <Col md="8">
                              <FormGroup>
                                <Row className="top-margin-7" />
                                <Switch
                                  offColor="success"
                                  onText="YES"
                                  offText="NO"
                                  // offText={
                                  //   <i className="nc-icon nc-simple-remove" />
                                  // }
                                  onColor="success"
                                  // onText={<i className="nc-icon nc-check-2" />}
                                  defaultValue={state.Enable_Calendar_Days_Only}
                                  value={state.Enable_Calendar_Days_Only}
                                  onChange={(e) => {
                                    setState({
                                      Enable_Calendar_Days_Only: e.state.value,
                                    });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>}
                          <ServiceDetails
                            service={state.service_key}
                            sub={
                              state.selected_sub_location != null
                                ? state.selected_sub_location.value
                                : ""
                            }
                            setDetails={(det) =>
                              setState({ service_details_for_display: det })
                            }
                            setDetailsTog={(det) =>
                              setState({
                                service_details_for_display_toggle: det,
                              })
                            }
                          />
                          <Row>
                            <Col md="">Priority</Col>
                            <Col md="">
                              <FormGroup>
                                {/* <Input
                                                                    value={state.priority}
                                                                    type="number"
                                                                    min={1}
                                                                    onChange={e => {setState({priority: e.target.value})}}
                                                                /> */}
                                <Switch
                                  onColor="success"
                                  offColor="success"
                                   onText="YES"
                                   offText="NO"
                                  /* defaultValue={state.priority>0} */
                                  value={state.priority > 0}
                                  onChange={(e) => {
                                    if (e.state.value) {
                                      setState({ priority: 1 });
                                    } else {
                                      setState({ priority: 0 });
                                    }
                                  }}
                                  // onText="High"
                                  // offText="Normal"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col>New Token Slot For Cancelled Token</Col>
                            <Col md="">
                              <FormGroup>
                                <Switch
                                  onColor="success"
                                  offColor="success"
                                  onText="YES"
                                  offText="NO"
                                  defaultValue={
                                    state.new_token_slot_for_cancelled_token
                                  }
                                  onChange={(e) => {
                                    setState({
                                      new_token_slot_for_cancelled_token:
                                        e.state.value,
                                    });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          {/* <Row>
                              <Col>Stop generating Tokens when end num has been reached</Col>
                              <Col md="">
                                  <FormGroup>
                                      <Switch
                                      onColor='success'
                                      offColor="success"
                                      onText="YES"
                                      offText="NO"
                                          defaultValue={state.stop_token_gen_when_end_num_reached}
                                          onChange={(e)=>{
                                              setState({stop_token_gen_when_end_num_reached:e.state.value})
                                          }}
                                      />
                                  </FormGroup>
                              </Col>
                          </Row> */}
                          <Row>
                            <Col>Reset tokens on day end</Col>
                            <Col md="">
                              <FormGroup>
                                {/* <div className="form-check-radio"> */}
                                {/* <Label check> */}
                                <Switch
                                  onColor="success"
                                  offColor="success"
                                  onText="YES"
                                  offText="NO"
                                  defaultValue={state.reset_tokens_at_day_close}
                                  onChange={(e) => {
                                    setState({
                                      reset_tokens_at_day_close: e.state.value,
                                    });
                                  }}
                                />

                                {/* </Label> */}
                                {/* </div> */}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row hidden={true}>
                            <Col>
                              <FormGroup>
                                <label className="error">
                                  This field is disabled because token
                                  scheduling has been turned on.{" "}
                                </label>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col>Build in AI generated</Col>
                            <Col md="">
                              <FormGroup>
                                <Switch
                                  onColor="success"
                                  offColor="success"
                                  onText="YES"
                                  offText="NO"
                                  defaultValue={state.build_ai_generated}
                                  value={state.build_ai_generated}
                                  onChange={(e) => {
                                    setState({
                                      build_ai_generated: e.state.value,
                                    });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col>Show on Mobile App</Col>
                            <Col md="">
                              <FormGroup>
                                <Switch
                                  onColor="success"
                                  offColor="success"
                                  onText="YES"
                                  offText="NO"
                                  defaultValue={state.show_on_mobile_app}
                                  onChange={(e) => {
                                    setState({
                                      show_on_mobile_app: e.state.value,
                                    });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col>Disable Token Generation on Mobile App</Col>
                            <Col md="">
                              <FormGroup>
                                <Switch
                                  onColor="success"
                                  offColor="success"
                                  onText="YES"
                                  offText="NO"
                                  defaultValue={
                                    state.disable_token_gen_on_mobile_app
                                  }
                                  onChange={(e) => {
                                    setState({
                                      disable_token_gen_on_mobile_app:
                                        e.state.value,
                                    });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col>Show on Dispenser (Kiosk) App</Col>
                            <Col md="">
                              <FormGroup>
                                <Switch
                                  onColor="success"
                                  offColor="success"
                                  onText="YES"
                                  offText="NO"
                                  defaultValue={state.show_on_dispenser_app}
                                  onChange={(e) => {
                                    setState({
                                      show_on_dispenser_app: e.state.value,
                                    });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col>Hide Token Status on Mobile App</Col>
                            <Col md="">
                              <FormGroup>
                                <Switch
                                  onColor="success"
                                  offColor="success"
                                  onText="YES"
                                  offText="NO"
                                  defaultValue={
                                    state.hide_token_status_on_mobile_app
                                  }
                                  onChange={(e) => {
                                    setState({
                                      hide_token_status_on_mobile_app:
                                        e.state.value,
                                    });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col>Hide Token Number on Mobile App</Col>
                            <Col md="">
                              <FormGroup>
                                <Switch
                                  onColor="success"
                                  offColor="success"
                                  onText="YES"
                                  offText="NO"
                                  defaultValue={
                                    state.hide_token_num_on_mobile_app
                                  }
                                  onChange={(e) => {
                                    setState({
                                      hide_token_num_on_mobile_app:
                                        e.state.value,
                                    });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          {state.selected_service_type?.value=='Appointment'? <Row>
                            <Col>Force Close Appointment After Service Date Has Passed</Col>
                            <Col md="">
                              <FormGroup>
                                <Switch
                                  onColor="success"
                                  offColor="success"
                                  onText="YES"
                                  offText="NO"
                                  value={state.force_close_appointment_after_service_date_passes}
                                  onChange={(e) => {
                                    setState({
                                      force_close_appointment_after_service_date_passes:
                                        e.state.value,
                                    });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>:''}
                           <Row>
                            <Col>Push User Notifications on Token Creation/Changes</Col>
                            <Col md="">
                              <FormGroup>
                                <Switch
                                  onColor="success"
                                  offColor="success"
                                  onText="YES"
                                  offText="NO"
                                  value={state.push_user_notifications_on_token_creation_or_changes}
                                  onChange={(e) => {
                                    setState({
                                      push_user_notifications_on_token_creation_or_changes:
                                        e.state.value,
                                    });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <br />
                          {/* {state?.selected_service_type?.value ==
                              "Appointment" && (
                              <Row>
                                <Col>
                                  Allow only authorized customer to book
                                  an appointment
                                </Col>
                                <Col md="">
                                  <FormGroup>
                                    <Switch
                                      onColor="success"
                                      offColor="success"
                                      onText="YES"
                                      offText="NO"
                                      value={
                                        state.book_appointment_authorized_only
                                      }
                                      onChange={(e) => {
                                        setState({
                                          book_appointment_authorized_only:
                                            e.state.value,
                                        });
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            )} */}
                          <hr />
                          <Row>
                            <Col md="12">
                              <FormGroup>
                                <h5>Mobile App Input Fields</h5>
                              </FormGroup>
                            </Col>
                          </Row>
                          {mobile_app_input_fields.map(
                            (mobile_app_input_field, index) => {
                              return (
                                <div key={mobile_app_input_field.id}>
                                  <Row>
                                    <Label md="4">{`Input Field ${
                                      parseInt(index) + 1
                                    } : Name`}</Label>
                                    <Col md="8">
                                      <FormGroup>
                                        {/* <div className="container"> */}
                                        {/* <div className="input-group add-on alert alert-dismissable"> */}
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="mobileAppInputFieldName"
                                          label="Mobile App Input Field"
                                          variant="filled"
                                          value={
                                            mobile_app_input_field.mobileAppInputFieldName
                                          }
                                          onChange={(event) => {
                                            handleChangeMobileAppInputField(
                                              mobile_app_input_field.id,
                                              event,
                                              "mobileAppInputFieldName"
                                            );
                                          }}
                                        />
                                        {/* <div className="input-group-btn" data-dismiss="alert">
                                                                                    <button
                                                                                        className="btn btn-default"
                                                                                        type="button"
                                                                                        disabled={state.mobile_app_input_fields.length === 1} onClick={() => handleRemoveMobileAppInputField(mobile_app_input_field.id)}
                                                                                    >x</button>
                                                                                </div> */}
                                        {/* </div> */}
                                        {/* </div> */}
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Label md="4">{`Input Field ${
                                      parseInt(index) + 1
                                    } : Data Type`}</Label>
                                    <Col md="8">
                                      <FormGroup>
                                        <Select
                                          className="react-select info select-location"
                                          classNamePrefix="react-select"
                                          placeholder="Select Mobile App Input Field Data Type"
                                          name="mobileAppInputFieldDataType"
                                          label="Mobile App Input Field Data Type"
                                          value={
                                            mobile_app_input_field.mobileAppInputFieldDataType ||
                                            (mobile_app_input_field.mobileAppInputFieldName !==
                                            ""
                                              ? state
                                                  .mobile_app_input_field_datatype_list[0]
                                              : "")
                                          }
                                          onChange={(event) => {
                                            handleChangeMobileAppInputField(
                                              mobile_app_input_field.id,
                                              event,
                                              "mobileAppInputFieldDataType"
                                            );
                                          }}
                                          options={
                                            state.mobile_app_input_field_datatype_list
                                          }
                                          //clearValue={mobile_app_input_field.mobileAppInputFieldName === ""}
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  {mobile_app_input_field.mobileAppInputFieldDataType?.value=='Dropdown'&&
                                   <Row>
                                    <Label md="4">{`Input Field ${
                                      parseInt(index) + 1
                                    } : Dropdown Values`}</Label>
                                    <Col md="8">
                                      <FormGroup>
                                        <CreatableSelect
                                          isMulti
                                          className="react-select info select-location"
                                          classNamePrefix="react-select"
                                          placeholder="Select Mobile App Input Field Data Type"
                                          name="customField"
                                          label="Mobile App Input Field Custom Field"
                                          value={
                                            mobile_app_input_field.customField || ""
                                          }
                                          onChange={(event) => {
                                            handleChangeMobileAppInputField(
                                              mobile_app_input_field.id,
                                              event,
                                              "customField"
                                            );
                                          }}
                                          // options={
                                          //   state.mobile_app_input_field_datatype_list
                                          // }
                                          //clearValue={mobile_app_input_field.mobileAppInputFieldName === ""}
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>}
                                  <Row>
                                    <Label md="4">{`Input Field ${
                                      parseInt(index) + 1
                                    } : Mandatory`}</Label>
                                    <Col md="8">
                                      <FormGroup>
                                        <Select
                                          className="react-select info select-location"
                                          classNamePrefix="react-select"
                                          placeholder="Select Mobile App Input Field Mandatory"
                                          name="mobileAppInputFieldMandatory"
                                          label="Mobile App Input Field Mandatory"
                                          value={
                                            mobile_app_input_field.mobileAppInputFieldMandatory ||
                                            (mobile_app_input_field.mobileAppInputFieldName !==
                                            ""
                                              ? state
                                                  .mobile_app_general_mandatory_options_list[1]
                                              : "")
                                          }
                                          onChange={(event) => {
                                            handleChangeMobileAppInputField(
                                              mobile_app_input_field.id,
                                              event,
                                              "mobileAppInputFieldMandatory"
                                            );
                                          }}
                                          options={
                                            state.mobile_app_general_mandatory_options_list
                                          }
                                          //clearValue={mobile_app_input_field.mobileAppInputFieldName === ""}
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row
                                    hidden={
                                      state.mobile_app_input_fields.length === 1
                                    }
                                  >
                                    <Label md="4"></Label>
                                    <Col md="8">
                                      <FormGroup>
                                        <Row>
                                          <Col md="7"></Col>
                                          <Col md="5">
                                            <FormGroup>
                                              <Button
                                                className="top-margin-2"
                                                color="danger"
                                                //disabled={state.mobile_app_input_fields.length === 1}
                                                onClick={() =>
                                                  handleRemoveMobileAppInputField(
                                                    mobile_app_input_field.id
                                                  )
                                                }
                                                block
                                              >
                                                Remove
                                              </Button>
                                            </FormGroup>
                                          </Col>
                                        </Row>
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </div>
                              );
                            }
                          )}
                          <Row>
                            <Label md="4"></Label>
                            <Col md="8">
                              <FormGroup>
                                <Row>
                                  <Col md="7"></Col>
                                  <Col md="5">
                                    <FormGroup>
                                      <Button
                                        className="top-margin-2"
                                        color="success"
                                        onClick={(e) =>
                                          handleAddMobileAppInputField()
                                        }
                                        block
                                      >
                                        Add
                                      </Button>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                          </Row>
                          <hr />
                          <Row>
                            <Col md="12">
                              <FormGroup>
                                <h5>Mobile App File Attachment Fields</h5>
                              </FormGroup>
                            </Col>
                          </Row>
                          {mobile_app_file_attachments.map(
                            (mobile_app_file_attachment, index) => {
                              return (
                                <div key={mobile_app_file_attachment.id}>
                                  <Row>
                                    <Label md="4">{`File Attachment ${
                                      parseInt(index) + 1
                                    } : Name`}</Label>
                                    <Col md="8">
                                      <FormGroup>
                                        {/* <div className="container"> */}
                                        {/* <div className="input-group add-on alert alert-dismissable"> */}
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="mobileAppFileAttachmentName"
                                          label="Mobile App File Attachment"
                                          variant="filled"
                                          //defaultValue={mobile_app_file_attachment.mobileAppFileAttachmentName}
                                          value={
                                            mobile_app_file_attachment.mobileAppFileAttachmentName
                                          }
                                          onChange={(event) => {
                                            handleChangeMobileAppFileAttachment(
                                              mobile_app_file_attachment.id,
                                              event,
                                              "mobileAppFileAttachmentName"
                                            );
                                          }}
                                        />
                                        {/* <div className="input-group-btn" data-dismiss="alert">
                                                                                    <button
                                                                                        className="btn btn-default"
                                                                                        type="button"
                                                                                        disabled={state.mobile_app_file_attachments.length === 1} onClick={() => handleRemoveMobileAppInputField(mobile_app_file_attachment.id)}
                                                                                    >x</button>
                                                                                </div> */}
                                        {/* </div> */}
                                        {/* </div> */}
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Label md="4">{`File Attachment ${
                                      parseInt(index) + 1
                                    } : File Types Allowed`}</Label>
                                    <Col md="8">
                                      <FormGroup>
                                        {/* <div className="container"> */}
                                        {/* <div className="input-group add-on alert alert-dismissable"> */}

                                        <Select
                                          placeholder="Select Allowed Mobile App File Attachment File Types"
                                          closeMenuOnSelect={true}
                                          className="react-select info select-location"
                                          classNamePrefix="react-select"
                                          name="mobileAppFileAttachmentFileTypesAllowed"
                                          label="Mobile App File Attachment File Type"
                                          value={
                                            mobile_app_file_attachment.mobileAppFileAttachmentFileTypesAllowed ||
                                            (mobile_app_file_attachment.mobileAppFileAttachmentName !==
                                            ""
                                              ? state
                                                  .mobile_app_file_attachment_filetype_list[0]
                                              : "")
                                          }
                                          onChange={(event) => {
                                            handleChangeMobileAppFileAttachment(
                                              mobile_app_file_attachment.id,
                                              event,
                                              "mobileAppFileAttachmentFileTypesAllowed"
                                            );
                                          }}
                                          options={
                                            state.mobile_app_file_attachment_filetype_list
                                          }
                                          //clearValue={mobile_app_file_attachment.mobileAppFileAttachmentName === ""}
                                          isMulti
                                        />
                                        {/* <div className="input-group-btn" data-dismiss="alert">
                                                                                    <button
                                                                                        className="btn btn-default"
                                                                                        type="button"
                                                                                        disabled={state.mobile_app_file_attachments.length === 1} onClick={() => handleRemoveMobileAppInputField(mobile_app_file_attachment.id)}
                                                                                    >x</button>
                                                                                </div> */}
                                        {/* </div> */}
                                        {/* </div> */}
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Label md="4">{`File Attachment ${
                                      parseInt(index) + 1
                                    } : Mandatory`}</Label>
                                    <Col md="8">
                                      <FormGroup>
                                        {/* <div className="container"> */}
                                        {/* <div className="input-group add-on alert alert-dismissable"> */}

                                        <Select
                                          className="react-select info select-location"
                                          classNamePrefix="react-select"
                                          placeholder="Select Mobile App File Attachment Mandatory"
                                          name="mobileAppFileAttachmentMandatory"
                                          label="Mobile App File Attachment Mandatory"
                                          value={
                                            mobile_app_file_attachment.mobileAppFileAttachmentMandatory ||
                                            (mobile_app_file_attachment.mobileAppFileAttachmentName !==
                                            ""
                                              ? state
                                                  .mobile_app_general_mandatory_options_list[1]
                                              : "")
                                          }
                                          onChange={(event) => {
                                            handleChangeMobileAppFileAttachment(
                                              mobile_app_file_attachment.id,
                                              event,
                                              "mobileAppFileAttachmentMandatory"
                                            );
                                          }}
                                          options={
                                            state.mobile_app_general_mandatory_options_list
                                          }
                                          //clearValue={mobile_app_file_attachment.mobileAppFileAttachmentName === ""}
                                        />
                                        {/* <div className="input-group-btn" data-dismiss="alert">
                                                                                    <button
                                                                                        className="btn btn-default"
                                                                                        type="button"
                                                                                        disabled={state.mobile_app_file_attachments.length === 1} onClick={() => handleRemoveMobileAppInputField(mobile_app_file_attachment.id)}
                                                                                    >x</button>
                                                                                </div> */}
                                        {/* </div> */}
                                        {/* </div> */}
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Label md="4">{`File Attachment ${
                                      parseInt(index) + 1
                                    } : Group Id`}</Label>
                                    <Col md="8">
                                      <FormGroup>
                                        {/* <div className="container"> */}
                                        {/* <div className="input-group add-on alert alert-dismissable"> */}

                                        <CreatableSelect
                                          className="react-select info select-location"
                                          classNamePrefix="react-select"
                                          placeholder="Select Mobile App File Attachment Group"
                                          name="mobileAppFileAttachmentGroup"
                                          label="Mobile App File Attachment Group"
                                          value={{
                                            label:
                                              state
                                                .mobile_app_file_attachment_groups[
                                                mobile_app_file_attachment.File_Attachment_Grouping_Id ||
                                                  0
                                              ] || "",
                                            value:
                                              mobile_app_file_attachment.File_Attachment_Grouping_Id ||
                                              "",
                                          }}
                                          onChange={async (a, b) => {
                                            //   console.log(await handleAttachmentGroup(a,b));
                                            //   mobile_app_file_attachment.File_Attachment_Grouping_Id =0
                                            console.log(
                                              "mobile_app_file_attachment.File_Attachment_Grouping_Id",
                                              mobile_app_file_attachment.File_Attachment_Grouping_Id
                                            );
                                            mobile_app_file_attachments.map(
                                              (e) => {
                                                if (
                                                  e.id ==
                                                  mobile_app_file_attachment.id
                                                ) {
                                                  //   e.File_Attachment_Grouping_Id = mobile_app_file_attachment.File_Attachment_Grouping_Id
                                                  handleAttachmentGroup(
                                                    a,
                                                    b
                                                  ).then((r) => {
                                                    mobile_app_file_attachment.File_Attachment_Grouping_Id =
                                                      r;
                                                    e.File_Attachment_Grouping_Id =
                                                      r;
                                                    console.log(
                                                      r,
                                                      "-------------------------"
                                                    );
                                                    setState({
                                                      mobile_app_file_attachments:
                                                        mobile_app_file_attachments,
                                                    });
                                                    console.log(
                                                      mobile_app_file_attachments
                                                    );
                                                  });
                                                }
                                              }
                                            );
                                            console.log(
                                              mobile_app_file_attachment,
                                              "mobiel app attachment data"
                                            );
                                          }}
                                          options={state.mobile_app_file_attachment_groups.map(
                                            (e, k) => ({
                                              label: e,
                                              value: k,
                                            })
                                          )}
                                          //clearValue={mobile_app_file_attachment.mobileAppFileAttachmentName === ""}
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                 
                                  <Row
                                    hidden={
                                      state.mobile_app_file_attachments
                                        .length === 1
                                    }
                                  >
                                    <Label md="4"></Label>
                                    <Col md="8">
                                      <FormGroup>
                                        <Row>
                                          <Col md="7"></Col>
                                          <Col md="5">
                                            <FormGroup>
                                              <Button
                                                className="top-margin-2"
                                                color="danger"
                                                //disabled={state.mobile_app_file_attachments.length === 1}
                                                onClick={() =>
                                                  handleRemoveMobileAppFileAttachment(
                                                    mobile_app_file_attachment.id
                                                  )
                                                }
                                                block
                                              >
                                                Remove
                                              </Button>
                                            </FormGroup>
                                          </Col>
                                        </Row>
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </div>
                              );
                            }
                          )}
                          <Row>
                            <Label md="4"></Label>
                            <Col md="8">
                              <FormGroup>
                                <Row>
                                  <Col md="7"></Col>
                                  <Col md="5">
                                    <FormGroup>
                                      <Button
                                        className="top-margin-2"
                                        color="success"
                                        onClick={(e) =>
                                          handleAddMobileAppFileAttachment()
                                        }
                                        block
                                      >
                                        Add
                                      </Button>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                          </Row>
                          <hr />
                          <Row>
                            <Col md="12">
                              <FormGroup>
                                <h5>Schedules</h5>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Label md="4">Select day of week</Label>
                            <Col md="8">
                              <FormGroup>
                                <Select
                                  className="react-select primary"
                                  classNamePrefix="react-select"
                                  name="weekSelect"
                                  value={state.new_week_select}
                                  onChange={(value) =>
                                    setState({ new_week_select: value })
                                  }
                                  options={[
                                    { value: "0", label: "Sunday" },
                                    { value: "1", label: "Monday" },
                                    { value: "2", label: "Tuesday" },
                                    { value: "3", label: "Wednesday" },
                                    { value: "4", label: "Thursday" },
                                    { value: "5", label: "Friday" },
                                    { value: "6", label: "Saturday" },
                                  ]}
                                  placeholder="Select day of the week"
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Label md="4">Token Issue Start</Label>
                            <Col md="8">
                              <FormGroup>
                                <Input
                                  placeholder="Token Issue Start Time"
                                  value={state.issue_start_time}
                                  type="time"
                                  onChange={(e) => {
                                    setState({
                                      issue_start_time: e.target.value,
                                    });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Label md="4">Token Issue End</Label>
                            <Col md="8">
                              <FormGroup>
                                <Input
                                  placeholder="Token Issue End Time"
                                  value={state.issue_end_time}
                                  type="time"
                                  onChange={(e) => {
                                    setState({
                                      issue_end_time: e.target.value,
                                    });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          {disable_fields_based_on_service_type_appointment ===
                          false ? (
                            <Row>
                              <Label md="4">
                                Token Current Day Cut-Off Time
                              </Label>
                              <Col md="8">
                                <FormGroup>
                                  <Input
                                    placeholder="Token Current Day Cut-Off"
                                    value={state.tok_issue_cut_off_time}
                                    type="time"
                                    onChange={(e) => {
                                      setState({
                                        tok_issue_cut_off_time: e.target.value,
                                      });
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          ) : null}

                          <Row>
                            <Label md="4">Token Call Start</Label>
                            <Col md="8">
                              <FormGroup>
                                <Input
                                  placeholder="Token Call Start Time"
                                  value={state.call_start_time}
                                  type="time"
                                  onChange={(e) => {
                                    setState({
                                      call_start_time: e.target.value,
                                    });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Label md="4">Token Call End</Label>
                            <Col md="8">
                              <FormGroup>
                                <Input
                                  placeholder="Token Call End Time"
                                  value={state.call_end_time}
                                  type="time"
                                  onChange={(e) => {
                                    setState({
                                      call_end_time: e.target.value,
                                    });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Label md="4"></Label>
                            <Col md="8">
                              <FormGroup>
                                <Row>
                                  <Col md="7"></Col>
                                  <Col md="5">
                                    <FormGroup>
                                      <Button
                                        className="top-margin-2"
                                        color="success"
                                        onClick={(e) => handleAddDay()}
                                        block
                                      >
                                        Add
                                      </Button>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="12">
                              <FormGroup>
                                <Table bordered>
                                  <thead className="text-center back">
                                    <tr>
                                      <th>Day Of Week</th>
                                      <th>Token Issue Start</th>
                                      <th>Token Issue End</th>
                                      {disable_fields_based_on_service_type_appointment ===
                                      false ? (
                                        <th>Token Current Day Cut-Off</th>
                                      ) : null}
                                      <th>Token Call Start</th>
                                      <th>Token Call End</th>
                                      <th>X</th>
                                      <th />
                                    </tr>
                                  </thead>
                                  <tbody className="text-center">
                                    {getServiceDays()}
                                  </tbody>
                                </Table>
                              </FormGroup>
                            </Col>
                          </Row>
                          <hr />
                          <Row>
                            <Col md="12">
                              <FormGroup>
                                <h5>Grouping</h5>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Label md="4">Service Group Enabled/Disabled</Label>
                            <Col md="8">
                              <FormGroup>
                                <Row className="top-margin-7" />
                                <Switch
                                  offColor="success"
                                   onText="YES"
                                   offText="NO"
                                  // offText={
                                  //   <i className="nc-icon nc-simple-remove" />
                                  // }
                                  onColor="success"
                                  // onText={<i className="nc-icon nc-check-2" />}
                                  defaultValue={state.service_group_enabled}
                                  value={state.service_group_enabled}
                                  onChange={(e) => {
                                    setState({
                                      service_group_enabled: e.state.value,
                                    });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          {service_group_enabled ? (
                            <div>
                              {/* //{ service_group_name_list && */}
                                <Row>
                                  <Label md="4">Show on Mobile App</Label>
                                  <Col md="8">
                                    <FormGroup>
                                      <Row className="top-margin-7" />
                                      <Switch
                                        offColor="success"
                                         onText="YES"
                                         offText="NO"
                                        // offText={
                                        //   <i className="nc-icon nc-simple-remove" />
                                        // }
                                        onColor="success"
                                        // onText={<i className="nc-icon nc-check-2" />}
                                        defaultValue={state.service_group_show_on_mobile_app}
                                        value={state.service_group_show_on_mobile_app}
                                        onChange={(e) => {
                                          setState({
                                            service_group_show_on_mobile_app: e.state.value, 
                                          });
                                        }}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                              <Row>
                                <Label md="4">Service Group Name</Label>
                                <Col md="8">
                                  <FormGroup
                                    className={`has-label ${service_group_nameState}`}
                                  >
                                    <CreatableSelect
                                      isClearable
                                      defaultValue={default_service_group_name}
                                      //value={default_service_group_name}
                                      isDisabled={false}
                                      // isValidNewOption={isValidBillingEmail}
                                      //value={state.service_group_name}
                                      onChange={handleServiceGroupChange}
                                      options={service_group_name_list}
                                    />
                                    {/* {service_group_nameState === "has-danger" ? (
                                                                            <label className="error">This field is required.</label>
                                                                        ) : null} */}
                                  </FormGroup>
                                </Col>
                              </Row>
                              {/* //} */}
                              <Row>
                                <Label md="4"></Label>
                                <Col md="8">
                                  <FormGroup>
                                    <label className="error">
                                      If you select an existing Service Group
                                      and change related information (name
                                      and/or description fields below),
                                      corresponding Service Group profile will
                                      also be updated.{" "}
                                    </label>
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Label md="4">Service Group Description</Label>
                                <Col md="8">
                                  <FormGroup
                                    className={`has-label ${service_group_descriptionState}`}
                                  >
                                    <Input
                                      placeholder="Service Group Description"
                                      type="text"
                                      value={service_group_description}
                                      // disabled={!selected_stripe_customer}
                                      onChange={(e) =>
                                        handleChange(
                                          e,
                                          "service_group_description",
                                          "length",
                                          1
                                        )
                                      }
                                    />
                                    {/* {service_group_descriptionState === "has-danger" ? (
                                                                            <label className="error">This field is required.</label>
                                                                        ) : null} */}
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Label md="4">Service Group List Order</Label>
                                <Col md="8">
                                  <FormGroup>
                                    <Row>
                                      <Col md="4" xs="4">
                                        <Input
                                          value={
                                            state.service_group_list_order_number
                                          }
                                          type="text"
                                          //maxLength={state.max_service_group_list_order_number}
                                          minLength={
                                            state.min_service_group_list_order_number
                                          }
                                          invalid={
                                            state.invalid_service_group_list_order_number
                                          }
                                          onChange={(e) => {
                                            handleChange(
                                              e,
                                              "service_group_list_order_number",
                                              "service_group_list_order_number"
                                            );
                                          }}
                                        />
                                      </Col>
                                      <Col
                                        md="8"
                                        xs="8"
                                        className="padding-left-0"
                                      >
                                        <Button
                                          className="margin-top-0"
                                          color="success"
                                          onClick={(e) =>
                                            increaseServiceGroupListOrderNumber()
                                          }
                                        >
                                          <i className="fa fa-plus" />
                                        </Button>
                                        <Button
                                          className="margin-top-0"
                                          color="success"
                                          onClick={(e) =>
                                            decreaseServiceGroupListOrderNumber()
                                          }
                                        >
                                          <i className="fa fa-minus" />
                                        </Button>
                                      </Col>
                                    </Row>
                                  </FormGroup>
                                </Col>
                              </Row>
                              {/* <Row md='12' className='px-0 mx-0'>
                                                                <Label md="4">Service Group Icon</Label>

                                                                <Col md='8' style={{ paddingLeft: '0px', paddingBottom: '15px' }} >
                                                                    <progress id="service_group_icon_uploader_1" value="0" max="100" style={{ display: 'none' }}>0%</progress>
                                                                    <input type="file" id='file1' ref={inputFileRef1} key={state.inputKey1}
                                                                        onChange={e => handleAttachment(e, "service_group_icon")}
                                                                    />
                                                                    {state.service_group_icon_name != undefined ?
                                                                        (state.service_group_icon_name.length > 0 ?
                                                                            <Row md='12' className='px-0 mx-0'>
                                                                            <img src={trash} style={{ width: '15px', marginTop: '3px' }} alt=""
                                                                                onClick={() => {
                                                                                    console.log("state.service_group_icon_name", state.service_group_icon_name);
                                                                                    var task1 = Firebase.storage().ref().child(state.service_group_icon_name).delete();
                                                                                    task1.catch(error => { 
                                                                                        console.log("task1 error", error);
                                                                                    })
                                                                                    
                                                                                    setState({
                                                                                        inputKey1: Math.random().toString(36),
                                                                                        service_group_icon: "",
                                                                                        service_group_icon_name: "",
                                                                                        service_group_icon: "",
                                                                                        service_group_icon_attachmentState: "",
                                                                                    })
                                                                                }}
                                                                            /></Row> : null)
                                                                        : null}
                                                                    {state.service_group_icon_attachmentState === "has-danger" ? (
                                                                        <div className="has-label has-danger position-relative form-group">
                                                                            <label className="error">Please provide a valid image/video file of type png, jpg, jpeg, webp OR mp4!!</label>
                                                                        </div>
                                                                    ) : null}
                                                                </Col>
                                                            </Row> */}
                              <Row>
                                <Label md="4">Service Group Icon</Label>
                                <Col md="8">
                                  <IconUploadToFirebase
                                    ref={service_group_icon}
                                  />
                                </Col>
                              </Row>
                            </div>
                          ) : null}
                          <hr />
                          <Row>
                            <Col md="12">
                              <FormGroup>
                                <h5>Request Approval</h5>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Label md="4">
                              Request Approval Enabled/Disabled
                            </Label>
                            <Col md="8">
                              <FormGroup>
                                <Row className="top-margin-7" />
                                <Switch
                                  offColor="success"
                                   onText="YES"
                                   offText="NO"
                                  // offText={
                                  //   <i className="nc-icon nc-simple-remove" />
                                  // }
                                  onColor="success"
                                  // onText={<i className="nc-icon nc-check-2" />}
                                  defaultValue={
                                    state.request_approval_group_enabled
                                  }
                                  value={state.request_approval_group_enabled}
                                  onChange={(e) => {
                                    setState({
                                      request_approval_group_enabled:
                                        e.state.value,
                                    });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          {request_approval_group_enabled ? (
                            <div>
                              <Row>
                                <Label md="4"></Label>
                                <Col md="8">
                                  <FormGroup>
                                    <label className="error">
                                      To enable Request Approval feature, you
                                      must create/select a Request Approval
                                      group.
                                    </label>
                                  </FormGroup>
                                </Col>
                              </Row>
                              {/* //{ service_group_name_list && */}
                              <Row>
                                <Label md="4">
                                  Request Approval Group Name
                                </Label>
                                <Col md="8">
                                  <FormGroup
                                    className={`has-label ${request_approval_group_nameState}`}
                                  >
                                    <CreatableSelect
                                      isClearable
                                      defaultValue={
                                        default_request_approval_group_name
                                      }
                                      isDisabled={false}
                                      // isValidNewOption={isValidBillingEmail}
                                      //value={state.request_approval_group_name}
                                      onChange={
                                        handleRequestApprovalGroupChange
                                      }
                                      options={request_approval_group_name_list}
                                    />
                                    {/* {request_approval_group_nameState === "has-danger" ? (
                                                                            <label className="error">This field is required.</label>
                                                                        ) : null} */}
                                  </FormGroup>
                                </Col>
                              </Row>
                              {/* //} */}
                              <Row>
                                <Label md="4"></Label>
                                <Col md="8">
                                  <FormGroup>
                                    <label className="error">
                                      If you select an existing Request Approval
                                      Group and change related information (name
                                      and/or description fields below),
                                      corresponding Request Approval Group
                                      profile will also be updated.{" "}
                                    </label>
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Label md="4">
                                  Request Approval Group Description
                                </Label>
                                <Col md="8">
                                  <FormGroup
                                    className={`has-label ${request_approval_group_descriptionState}`}
                                  >
                                    <Input
                                      placeholder="Request Approval Group Description"
                                      type="text"
                                      value={request_approval_group_description}
                                      // disabled={!selected_stripe_customer}
                                      onChange={(e) =>
                                        handleChange(
                                          e,
                                          "request_approval_group_description",
                                          "length",
                                          1
                                        )
                                      }
                                    />
                                    {/* {service_group_descriptionState === "has-danger" ? (
                                                                            <label className="error">This field is required.</label>
                                                                        ) : null} */}
                                  </FormGroup>
                                </Col>
                              </Row>
                            </div>
                          ) : null}
                          <hr />

                          <Row>
                            <Card className="card-plain width-100">
                              <CardHeader role="tab">
                                <a
                                  aria-expanded={state.openedCollapses.includes(
                                    "show-more"
                                  )}
                                  href="#"
                                  data-parent="#accordion"
                                  data-toggle="collapse"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    collapsesToggle("show-more");
                                  }}
                                >
                                  Show More...{" "}
                                </a>
                              </CardHeader>
                              <Collapse
                                role="tabpanel"
                                isOpen={state.openedCollapses.includes(
                                  "show-more"
                                )}
                              >
                                <CardBody>
                                  <Row>
                                    <Label md="4">Last Printed Number</Label>
                                    <Col md="8">
                                      <FormGroup>
                                        <Input
                                          value={state.last_printed_number}
                                          type="text"
                                          disabled
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Label md="4">Last Printed Datetime</Label>
                                    <Col md="8">
                                      <FormGroup>
                                        <Input
                                          value={state.last_printed_date_time}
                                          type="text"
                                          disabled
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Label md="4">Sub Location Count</Label>
                                    <Col md="8">
                                      <FormGroup>
                                        <Input
                                          value={state.sub_location_cnt}
                                          type="text"
                                          disabled
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Label md="4">Last Called Number</Label>
                                    <Col md="8">
                                      <FormGroup>
                                        <Input
                                          value={state.last_called_number}
                                          type="text"
                                          disabled
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Label md="4">Last Called Datetime</Label>
                                    <Col md="8">
                                      <FormGroup>
                                        <Input
                                          value={state.last_called_date_time}
                                          type="text"
                                          disabled
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Label md="4">Last Called Counter</Label>
                                    <Col md="8">
                                      <FormGroup>
                                        <Input
                                          value={state.last_called_counter}
                                          type="text"
                                          disabled
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Label md="4">Last Called User</Label>
                                    <Col md="8">
                                      <FormGroup>
                                        <Input
                                          value={state.last_called_user}
                                          type="text"
                                          disabled
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Label md="4">Current Status</Label>
                                    <Col md="8">
                                      <FormGroup>
                                        <Input
                                          value={state.current_status}
                                          type="text"
                                          disabled
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Label md="4">Last Generated Token</Label>
                                    <Col md="8">
                                      <FormGroup>
                                        <Input
                                          value={state.last_generated_token}
                                          type="text"
                                          disabled
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Label md="4">
                                      Last Generated Token Datetime
                                    </Label>
                                    <Col md="8">
                                      <FormGroup>
                                        <Input
                                          value={state.last_generated_token_date_time.toLocaleString()}
                                          type="text"
                                          disabled
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Collapse>
                            </Card>
                          </Row>
                        </Form>
                      </Col>
                    </Row>
                  </Col>
                </CardBody>
              </Card>
            ) : null}
          </Col>
        </Row>
      </LoadingOverlay>
    </>
  );
  //   }
}

export default ServiceAdd;
