import React from "react";
import NotificationAlert from "react-notification-alert";
import Firebase from "firebase";
import PasswordHash from 'password-hash';
import LoadingOverlay from "react-loading-overlay";
import CopyToClipboard from "react-copy-to-clipboard";
import Select from "react-select";
import Switch from "react-bootstrap-switch";
import PictureUpload from "../../../components/CustomUpload/PictureUpload.jsx";

import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardFooter,
    Button,
    FormGroup,
    InputGroup,
    Row,
    Col,
    Input,
    InputGroupAddon,
    InputGroupText,
    Form,
    Collapse,
    Label
} from "reactstrap";
import config from "../../../config";

const uuidv1 = require('uuid/v1');
const { getNameList } = require('country-list');
// const publicIp = require('public-ip');

class SupportCenterLoginByRole extends React.Component {
    constructor(props) {
        super(props);
        if (!Firebase.apps.length) {
            Firebase.initializeApp(config);
        }

        this.state = {
            loading: false,
            customer_id: '',
            role: '',
            current_time: '',
            locations: [],
            accessible_locs: [],

            customer_list: [],
            selected_customer: [],
            selected_customer_id: '',
            role_list: [],
            selected_role: [],
            selected_customer_role: '',

            openedCollapses: [],

            customerState: '',
            roleState: '',
            locationState: '',
            support_admin_role: false,
        };

        this.getAccessibleLocs = this.getAccessibleLocs.bind(this);
        this.loadLocations = this.loadLocations.bind(this);
    }

    componentDidMount() {
        let _this = this;
        let custID = "000000000001";
        let userEmailID = JSON.parse(localStorage.getItem('auth_info')).email;
        var auth = JSON.parse(localStorage.getItem('auth_info'));
        var role = auth.role;
        var supportAdminRole = auth.support_admin_role;
        _this.setState({
            role: role,
            support_admin_role: supportAdminRole
        });
        console.log("role", role, "supportAdminRole", supportAdminRole);

        Firebase.firestore().collection('Web_App_Users').doc(userEmailID).get().then(function (userData) {
            if (userData.exists) {
                if (userData.data().Support_Admin_Role != undefined && userData.data().Support_Admin_Role == true) {
                    _this.setState({ support_admin_role: true });
                }
            }
        }).then(() => {
            if (role == "Support_Admin" || supportAdminRole == true) {
                this.loadCustomers();
            }
        }).catch(function (err) {
            _this.setState({ loading: false });
            _this.notifyMessage("tc", 3, "Network Error.");
            console.log("componentDidMount NetworkError1==>", err);
        });

        // _this.getCurrentTime();
    }
    loadLocations(custID) {
        var _this = this;
        var customer_id = custID;
        var location_list = [];
        var sub_location_list = [];
        Firebase.firestore().collection('Main_Locations').where('Customer_ID', '==', customer_id).get().then(function (locations) {
            // console.log("locations.docs.length", locations.docs.length);
            locations.docs.forEach(function (location) {
                var one = {
                    name: location.data().Name,
                    sub_locations: []
                };

                Firebase.firestore().collection('Sub_Locations').where('Customer_ID', '==', customer_id).where('Main_Location_ID', '==', location.id).get().then(function (sub_locations) {
                    sub_locations.docs.forEach(function (sub_location) {
                        var one_sub = {
                            name: sub_location.data().Name,
                            id: sub_location.id
                        };
                        one.sub_locations.push(one_sub);
                        sub_location_list.push(sub_location.id);
                    });
                    location_list.push(one);
                    _this.setState({ locations: location_list });
                }).catch(function (err) {
                    console.log(err);
                });
            })
            // console.log(">>sub_location_list", sub_location_list);
            // console.log(">>sub_location_list.length", sub_location_list.length);

        }).catch(function (err) {
            console.log(err);
        });

        // console.log("location_list", location_list);
        // console.log("sub_location_list", sub_location_list);
        _this.setState({ accessible_locs: sub_location_list });

    }
    loadCustomers() {
        let _this = this;
        var customers = [];
        _this.setState({ loading: true });
        let customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;
        let role = JSON.parse(localStorage.getItem('auth_info')).role;

        if (role == 'Support_Admin' || _this.state.support_admin_role == true) {
            Firebase.firestore().collection('Customers').get().then(function (own_customer_info) {
                own_customer_info.docs.forEach(e => {
                    var one = {
                        value: e.id,
                        label: e.data().Name + " | " + e.data().Email + " | " + e.id
                    };
                    customers.push(one);
                })
                _this.setState({ customer_list: customers });
            }).catch(function (err) {
                _this.setState({ loading: false });
                _this.notifyMessage("tc", 3, "Network Error.");
                console.log("loadCustomers NetworkError1==>", err);
            });
        }
        _this.setState({ loading: false });
    }
    loadRoles(custID) {
        let _this = this;
        var users = [];
        _this.setState({ loading: true });
        let selectedCustID = custID;
        let role = JSON.parse(localStorage.getItem('auth_info')).role;
        let roleArr = [];

        if (role == 'Support_Admin' || _this.state.support_admin_role == true) {
            Firebase.firestore().collection('System_Config').doc('User_Roles').get().then(e => {

                const all_user_roles = e.data().All_Roles;
                for (var k in all_user_roles) {
                    if (all_user_roles[k] !== 'Site_Admin')
                        roleArr.push({ label: k, value: all_user_roles[k] })
                }

                // e.data().Roles.forEach(b=>{
                //     roleArr.push({label:b,value:b})
                // })
                console.log("roleArr", roleArr);
                if (roleArr.length >= 1) {
                    _this.setState({ role_list: roleArr });
                    _this.onChangeRole(roleArr[0]);
                    _this.setState({ selected_role: roleArr[0] });
                }
                else {
                    _this.notifyMessage("tc", 3, "There are no roles in this document.");
                    _this.setState({ selected_role: [] });
                    _this.setState({ roleState: "has-danger" });
                }


            }).catch(function (err) {
                _this.setState({ loading: false });
                _this.notifyMessage("tc", 3, "Network Error.");
                console.log("loadRoles NetworkError2==>", err);
            });
        }
        _this.setState({ loading: false });
    }
    getAccessibleLocsOfSelectedCustomer(custID) {
        let _this = this;
        _this.setState({ loading: true });
        let accessibleLocs = [];
        let role = JSON.parse(localStorage.getItem('auth_info')).role;

        if (role == 'Support_Admin' || _this.state.support_admin_role == true) {
            // console.log("userEmailID", userEmailID);
            Firebase.firestore().collection('Sub_Locations').where("Customer_ID", "==", custID).get().then(function (app_info) {
                if (app_info.exists) {
                    app_info.docs.forEach(function (doc) {
                        accessibleLocs.push(doc.id);
                    });
                    _this.setState({ accessible_locs: accessibleLocs });
                    console.log("accessibleLocs", accessibleLocs);
                    _this.setState({ loading: false });
                    return accessibleLocs;
                }

            }).catch(function (error) {
                _this.setState({ loading: false });
                _this.notifyMessage("tc", 3, "NetWork Error.");
                console.log("getAccessibleLocsOfSelectedCustomer NetworkError3==>", error);
            });
        }
        _this.setState({ loading: false });
    }
    getCurrentTime() {
        let _this = this;
        let now = new Date();
        _this.setState({ current_time: now.toLocaleString() });
        window.setTimeout(function () { _this.getCurrentTime() }, 500);
    }
    handleLoginByRoleSave() {
        let _this = this;
        let selectedCustID = _this.state.selected_customer_id;
        let selectedCustomerRole = _this.state.selected_customer_role;
        let accessibleLocs = _this.state.accessible_locs;
        let auth_info_obj = JSON.parse(localStorage.getItem('auth_info')),
            currUserEmailID = auth_info_obj.email,
            locationState = _this.state.locationState;

        if (selectedCustID === "" || selectedCustID === undefined || selectedCustID === null) { _this.setState({ customerState: "has-danger" }) }
        if (selectedCustomerRole === "" || selectedCustomerRole === undefined || selectedCustomerRole === null) { _this.setState({ roleState: "has-danger" }) }
        if (accessibleLocs === "" || accessibleLocs === undefined || accessibleLocs === null) { _this.setState({ locationState: "has-danger" }) }

        if (accessibleLocs.length == 0) {
            _this.notifyMessage("tc", 3, "Sorry there aren't any locations under this customer. Please contact the Site Admin.");
            _this.setState({ ["locationState"]: "has-danger" });
        } else {
            _this.setState({ ["locationState"]: "has-success" });
            locationState = 'has-success'
            if (_this.state.customerState === "has-success" && _this.state.roleState === "has-success" && locationState === "has-success") {
                // console.log("customerState roleState success");
                var save_data = {
                    Login_As_Specific_User_Email_ID: "",
                    Login_As_Specific_User_Changed_Date: "",
                    Login_By_Role: selectedCustomerRole,
                    Login_By_Role_Changed_Date: new Date(),
                    Accessible_Locations: accessibleLocs,
                    Customer_ID: selectedCustID,
                    Role: selectedCustomerRole,
                };
                // console.log("save_data", save_data, "currUserEmailID", currUserEmailID);
                Firebase.firestore().collection('Web_App_Users').doc(currUserEmailID).update(save_data)
                    .then(function () {
                        console.log("Successfully saved 'login by role' profile for " + selectedCustID + " to currently logged in user: " + currUserEmailID);
                        //_this.notifyMessage("tc", 2, "Successfully saved. You must logout and login to this account to emulate selected customer profile!");
                        // window.setTimeout(function() {  _this.props.history.push("/logout")}, 4000);

                        let auth_info = {
                            ...auth_info_obj,
                            customer_id: selectedCustID,
                            role: selectedCustomerRole,
                            message: ` Successfully switched account settings to role: ${selectedCustomerRole}  (Customer ID: ${selectedCustID})`,
                            messageShowOnce: true
                        };
                        localStorage.setItem(
                            "auth_info",
                            JSON.stringify(auth_info)
                        );
                        window.setTimeout(function () {
                            document.location.reload();
                        }, 4000);

                    })
                    .catch(function (error) {
                        console.log("Error saving document: ", error);
                        _this.notifyMessage("tc", 3, "Error saving document!");
                    });
            } else {
                _this.notifyMessage("tc", 3, "Sorry, you must provide a valid customer and role to proceed!");
            }
        }
    }
    getAccessibleLocs() {
        var _this = this;
        return this.state.locations.map((prop, toggle_key) => {
            return (
                <div key={toggle_key}>
                    <a href="#" className="custom-nav"><i className={this.state.openedCollapses.includes(toggle_key) ? "nc-icon nc-minimal-up" : "nc-icon nc-minimal-down"} /></a>
                    <a
                        aria-expanded={this.state.openedCollapses.includes(
                            toggle_key
                        )}
                        href="#"
                        data-parent="#accordion"
                        data-toggle="collapse"
                        onClick={() => this.collapsesToggle(toggle_key)}
                    >
                        {prop.name}
                    </a>
                    <Collapse
                        role="tabpanel"
                        isOpen={this.state.openedCollapses.includes(
                            toggle_key
                        )}
                    >
                        {prop.sub_locations.map((prop, key) => {
                            return (
                                <Label key={key} className="custom-nav-left">
                                    <Input
                                        disabled
                                        name={prop.id}
                                        type="checkbox"
                                        checked={
                                            _this.state.accessible_locs.filter(item => item === prop.id).length > 0
                                        }
                                    /* onChange={e => this.handleChange(e, prop.id, "location")} */
                                    />
                                    <span className="form-check-sign" />
                                    {prop.name}
                                </Label>
                            );
                        })}
                    </Collapse>
                </div>
            );
        });
    }
    onChangeCustomer(e) {
        let _this = this;
        _this.setState({ selected_customer: e });
        _this.setState({ selected_customer_id: e.value });
        // console.log(e.value)
        // _this.loadCustomers();
        _this.loadRoles(e.value);

        if (this.verifyLength(e.value, 1)) {
            this.setState({ ["customerState"]: "has-success" });
        } else {
            this.setState({ ["customerState"]: "has-danger" });
        }
    }
    onChangeRole(e) {
        let _this = this;
        let userRole = e.value;

        _this.setState({ selected_role: e });
        _this.setState({ selected_customer_role: userRole });
        _this.loadLocations(_this.state.selected_customer_id);
        // console.log("_this.state.selected_customer_id", _this.state.selected_customer_id);
        // console.log("e", e);
        // console.log("e.customer_id", e.customer_id);
        // console.log("e.value", e.value);

        if (this.verifyLength(e.value, 1)) {
            this.setState({ ["roleState"]: "has-success" });
        } else {
            this.setState({ ["roleState"]: "has-danger" });
        }
    }
    collapsesToggle = collapse => {
        let openedCollapses = this.state.openedCollapses;
        if (openedCollapses.includes(collapse)) {
            this.setState({
                openedCollapses: openedCollapses.filter(item => item !== collapse)
            });
        } else {
            openedCollapses.push(collapse);
            this.setState({
                openedCollapses: openedCollapses
            });
        }
    };
    handleChange = (event, stateName, type, stateNameEqualTo) => {
        switch (type) {
            case "length":
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + "State"]: "has-success" });
                } else {
                    this.setState({ [stateName + "State"]: "has-danger" });
                }

                this.setState({ [stateName]: event.target.value });
                break;
            case 'pin':
                if (this.verifyLength(event.target.value, stateNameEqualTo) && this.verifyNumber(event.target.value)) {
                    this.setState({ [stateName + "State"]: "has-success" });
                } else {
                    this.setState({ [stateName + "State"]: "has-danger" });
                }

                if (this.verifyNumber(event.target.value) || event.target.value.length === 0) {
                    this.setState({ [stateName]: event.target.value });
                }

                break;
            case "api_key":
                if (this.verifyLength(event.target.value, 1)) {
                    this.setState({ [stateName + "State"]: "has-success" });
                    const { name, value } = event.target;
                    this.setState({ [name]: value });
                } else {
                    this.setState({ [stateName + "State"]: "has-danger" });
                }
                break;
            case "location":
                var accessible_locs = this.state.accessible_locs;
                if (event.target.checked === true) {
                    accessible_locs.push(stateName);
                    this.setState({ accessible_locs: accessible_locs });
                } else {
                    var index = accessible_locs.indexOf(stateName);
                    if (index !== -1) accessible_locs.splice(index, 1);
                    this.setState({ accessible_locs: accessible_locs });
                }
                break;
            default:
                break;
        }
    };
    notifyMessage = (place, color, text) => {
        var type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }

        var options = {};
        options = {
            place: place,
            message: (
                <div className="text-md-center">
                    <div>
                        <b>{text}</b>
                    </div>
                </div>
            ),
            type: type,
            icon: "now-ui-icons ui-1_bell-53",
            autoDismiss: 3
        };
        if (options !== null) { this.notificationAlert.notificationAlert(options) }
    };
    verifyLength = (value, length) => {
        return value.length >= length;
    };
    verifyNumber = value => {
        var numberRex = new RegExp("^[0-9]+$");
        if (numberRex.test(value)) {
            return true;
        }
        return false;
    };
    render() {
        let role = this.state.role;
        let supportAdminRole = this.state.support_admin_role;
        // console.log("role", role, "supportAdminRole", supportAdminRole);

        var ok = false
        var accessLocsExist = false
        if (this.state.role == "Support_Admin" || supportAdminRole == true) {
            ok = true;
        }
        if (this.state.accessible_locs != undefined && this.state.accessible_locs.length >= 1) {
            accessLocsExist = true;
        }
        let {
            customerState,
            roleState,
            locationState,
        } = this.state;
        return (
            <>
                <LoadingOverlay
                    active={this.state.loading}
                    spinner
                    text='Loading'
                    className='content'
                >
                    <NotificationAlert ref={(ref) => this.notificationAlert = ref} />
                    <Row>
                        <Col md="12">
                            {ok ? <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Support Central - Login By Role</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Col className="ml-auto mr-auto" xl="8" lg="10" md="12">
                                        <Row>
                                            <Col md="3">
                                                <Button
                                                    color="success"
                                                    onClick={e => this.handleLoginByRoleSave()}
                                                    block
                                                >
                                                    Save
                                                </Button>
                                            </Col>
                                            <Col md="3">
                                                <Button
                                                    color="youtube"
                                                    onClick={e => this.props.history.push("/support_center")}
                                                    block
                                                >
                                                    Close
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row className="top-margin-10">
                                        </Row>
                                        <Row>
                                            <Col xl="8" lg="12" md="12">
                                                <Form className="form-horizontal">
                                                    <Row>
                                                        <Label md="4">Customer</Label>
                                                        <Col md="8">
                                                            <FormGroup className={`has-label ${customerState}`}>
                                                                <Select
                                                                    className="react-select info select-location"
                                                                    classNamePrefix="react-select"
                                                                    placeholder="Select Customer"
                                                                    name="selected_customer"
                                                                    value={this.state.selected_customer}
                                                                    onChange={e =>
                                                                        this.onChangeCustomer(e)
                                                                    }
                                                                    options={this.state.customer_list}
                                                                />
                                                                {this.state.customerState === "has-danger" ? (
                                                                    <label className="error">
                                                                        Please select a valid customer.
                                                                    </label>
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Role</Label>
                                                        <Col md="8">
                                                            <FormGroup className={`has-label ${roleState}`}>
                                                                <Select
                                                                    className="react-select info select-location"
                                                                    classNamePrefix="react-select"
                                                                    placeholder="Select Role"
                                                                    name="selected_role"
                                                                    value={this.state.selected_role}
                                                                    onChange={e =>
                                                                        this.onChangeRole(e)
                                                                    }
                                                                    options={this.state.role_list}
                                                                />
                                                                {this.state.roleState === "has-danger" ? (
                                                                    <label className="error">
                                                                        Please select a valid role.
                                                                    </label>
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    {accessLocsExist ? <Row>
                                                        <Label md="4">Accessible Locations</Label>
                                                        <Col md="8">
                                                            <div className="blockquote">
                                                                <div
                                                                    aria-multiselectable={true}
                                                                    className="card-collapse col-md-12"
                                                                    id="accordion"
                                                                    role="tablist"
                                                                >
                                                                    {this.getAccessibleLocs()}
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row> : null}
                                                </Form>
                                            </Col>
                                        </Row>
                                    </Col>
                                </CardBody>
                            </Card> : null}
                        </Col>
                    </Row>
                </LoadingOverlay>
            </>
        );
    }
}

export default SupportCenterLoginByRole;