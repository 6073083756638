import React from "react";
import LoadingOverlay from 'react-loading-overlay';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Label,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row
} from "reactstrap";

//import Spinner from 'react-spinkit';
//import { authRef } from '../reference';
import Firebase from "firebase";
import config from "../../config";

class VerifyEmail extends React.Component {

  constructor(props) {
    super(props);

    if (!Firebase.apps.length) {
      Firebase.initializeApp(config);
    }

    this.state = {
      error: '',
      validCode: null,
      verifiedCode: false,
    };
  }

  componentDidMount() {
    // Try to apply the email verification code.
    const authRef = Firebase.auth();
    authRef
      .applyActionCode(this.props.actionCode)
      .then(() => {
        // Email address has been verified.
        this.setState({ validCode: true, verifiedCode: true });
      }, error => {
        // Code is invalid or expired. Ask the user to verify their email address
        // again.
        this.setState({ error: error.message, validCode: false, verifiedCode: true });
        console.log({ action: "verifyEmail", function: "componentDidMount", error: error.message, validCode: false, verifiedCode: true });
      });
  }

  render() {

    let appVersion = process.env.REACT_APP_VERSION;
    const {
      error,
      validCode,
      verifiedCode,
    } = this.state;

    let wrapperFullPageContent, cardBodyContent;

    if (verifiedCode && validCode) {
      cardBodyContent = (
        <div className="row justify-content-center">
          <h3 style={{ color: '#fff' }} className="header text-center Margin-top--20">Your email has been verified</h3>
          <h5 style={{ color: '#fff' }} className="header text-center Margin-top--20">You can now sign in with your new account</h5>
        </div>
      );
    }
    else if (verifiedCode && !validCode) {
      cardBodyContent = (
        <div className="row justify-content-center">
          <h3 style={{ color: '#fff' }} className="header text-center Margin-top--20">Try verifying your email again</h3>
          <h5 style={{ color: '#ef476f' }} className="header text-center Margin-top--20">{error}</h5>
        </div>
      );
    }

    wrapperFullPageContent = (
      <div className="wrapper wrapper-full-page" ref={(ref) => this.fullPages = ref}>
        <LoadingOverlay
          active={!verifiedCode}
          spinner
          text='Loading'
        >
          <div className="full-page section-image">
            <div className="login-page">
              <Container>
                <Row>
                  <Col className="ml-auto mr-auto" lg="7" md="6">
                    <Card style={{ backgroundColor: '#ffffff00', boxShadow: '0px 0px 0px 0px rgb(0 0 0 / 1%)' }} className="card-login">
                      <CardHeader>
                        <CardHeader>
                          <div className="header text-center">
                            {/* <img src={'/antqueue-animated-logo.gif'} alt={"animated-antqueue-logo"} />  */}
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                              <lottie-player src="/antqueue-animated-logo-lottie-data.json" background="transparent" speed="1" style={{ width: "200px", height: "200px" }} loop autoplay></lottie-player>
                            </div>
                          </div>
                          <h3 style={{ color: '#fff' }} className="header text-center Margin-top--20">AntQueue Password Verify</h3>
                        </CardHeader>
                      </CardHeader>
                      <CardBody>
                        {cardBodyContent}
                      </CardBody>
                      <CardFooter>
                        <div style={{ color: '#fff', fontSize: '8px', marginTop: '25px' }} className="header text-center font-weight-normal text-capitalize">{"AntQueue v" + appVersion}</div>
                      </CardFooter>
                    </Card>
                  </Col>
                </Row>
              </Container>
              <div
                className="full-page-background"
                style={{
                  backgroundImage: `url(${require("../../assets/img/bg/queue_4000x2500_3.jpg")})`,
                  backgroundColor: "lightGrey"
                }}
              />
            </div>
          </div>
        </LoadingOverlay>
      </div>
    )

    return wrapperFullPageContent;
  }
}

export default VerifyEmail;
