import React from "react";
import NotificationAlert from "react-notification-alert";
import LoadingOverlay from 'react-loading-overlay';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Col,
    Row
} from "reactstrap";
import Firebase from "firebase";
import config from "../../config";
import info from '../../info';

class DeleteMobileUserAccountRequest extends React.Component {
    constructor(props) {
        super(props);

        if (!Firebase.apps.length) {
            Firebase.initializeApp(config);
        }

        this.state = {
            loading: false,
            email: '',
            emailState: "",
            submitted: false, 
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.notifyMessage = this.notifyMessage.bind(this);
    }

    componentDidMount() {
        document.body.classList.toggle("login-page");
    }
    componentWillUnmount() {
        document.body.classList.toggle("login-page");
    }

    notifyMessage = (place, color, text) => {
        var type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }

        var options = {
            place: place,
            message: (
                <div className="text-md-center">
                    <div>
                        <b>{text}</b>
                    </div>
                </div>
            ),
            type: type,
            icon: "now-ui-icons ui-1_bell-53",
            autoDismiss: 3
        };
        if (options !== null) { this.notificationAlert.notificationAlert(options) }
    };

    handleChange = (event, stateName, type) => {
        if (type === "email") {
            const value = event.target.value;
            this.setState({
                [stateName]: value,
                [stateName + "State"]: this.verifyEmail(value) ? "has-success" : "has-danger"
            });
        }
    };

    verifyEmail = value => {
        var emailRex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRex.test(value);
    };

    // currentDateTime = () => {
    //     const now = new Date();
    //     const year = now.getUTCFullYear();
    //     const month = now.toLocaleString('en-GB', { month: 'long' });
    //     const day = now.getUTCDate().toString().padStart(2, '0');
    //     const hours = now.getUTCHours().toString().padStart(2, '0');
    //     const minutes = now.getUTCMinutes().toString().padStart(2, '0');
    //     return `${year} ${month} ${day} ${hours}:${minutes} Z`;
    // };

    handleSubmit = async () => {
        if (this.state.emailState !== "has-success") {
            this.setState({ emailState: "has-danger" });
            return;
        }

        this.setState({ loading: true });

        const appName = "AntQueue";
        const email = this.state.email.toLowerCase();
        const token = this.generateToken(50);
        const link = `${info.customActionUrl}?mode=deleteMobileUserAccount&token=${token}`;
        //const currDateTimeVal = this.currentDateTime();
        const expirationTime = new Date().getTime() + (24 * 60 * 60 * 1000); // 24 hours
        
        try {
            const mob_app_user_info = await Firebase.firestore().collection('Mobile_App_Users')
                .where('Email', '==', email)
                .get();
            
            if (!mob_app_user_info.empty){ // Check if the QuerySnapshot is empty
                console.log("User found");
                await Firebase.firestore().collection('User_Account_Deletion_Requests').add({
                    email: email,
                    token: token,
                    expiration: expirationTime,
                    source: "Mobile_App",
                    status: "pending",
                    created: Firebase.firestore.Timestamp.now(),
                    updated: Firebase.firestore.Timestamp.now()
                })
                
                await Firebase.functions().httpsCallable('sendMail')({
                    email: email,
                    // subject: `Delete AntQueue mobile user account requested at ${currDateTimeVal}`,
                    // text: `<div><p>Hello,</p><p>We received a request to delete your AntQueue mobile user account using this email address, at ${currDateTimeVal}. If you want to delete your account, click this link:</p><p><a href="${link}" target="_blank" rel="noreferrer nofollow noopener">Delete AntQueue mobile user account</a></p><p>If you did not request this link, you can safely ignore this email. The link will expire in 24 hours.</p><p>Thanks,</p><p>Your AntQueue team</p></div></div>`
                    subject: `Delete your mobile user account for ${appName}`,
                    text: `<div><p>Hello,</p><p>Follow this link to delete your ${appName} mobile user account for your ${email} account.</p><p>${link}</p><p>If you didn’t ask to delete your account, you can ignore this email. The link will expire in 24 hours.</p><p>Thanks,</p><p>${appName} team</p></div></div>`
                });
            
                this.setState({ loading: false, submitted: true }); // Update submitted state
                this.notifyMessage("tc", 2, "Please check your email for instructions on how to delete your account.");
    
            } else {
                console.log("User not found");
                this.setState({ loading: false });
                this.notifyMessage("tc", 3, "This mobile user account does not exist on our platform. Please ensure that the email you provided is correct.");
            }
        } catch (e) {
            this.setState({ loading: false });
            this.notifyMessage("tc", 3, "An error occurred.");
            console.log("handleSubmit Error =>", e);
        }
    };

    generateToken(length) {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    }

    render() {
        const { emailState, submitted } = this.state;

        return (
            <div className="wrapper wrapper-full-page" ref="fullPages">
                <LoadingOverlay
                    active={this.state.loading}
                    spinner
                    text='Loading'
                >
                    <div className="full-page section-image">
                        <NotificationAlert ref={(ref) => this.notificationAlert = ref} />
                        <div className="login-page">
                            <Container>
                                <Row>
                                    <Col className="ml-auto mr-auto" lg="5" md="6">
                                        <Card style={{ backgroundColor: '#ffffff00', boxShadow: '0px 0px 0px 0px rgb(0 0 0 / 1%)' }}>
                                            <CardHeader>
                                                <div className="header text-center">
                                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                        <lottie-player src="/antqueue-animated-logo-lottie-data.json" background="transparent" speed="1" style={{ width: "200px", height: "200px" }} loop autoplay></lottie-player>
                                                    </div>
                                                    <h3 style={{ color: '#fff' }} className="header text-center Margin-top--20">AntQueue Delete Mobile User</h3>
                                                </div>
                                                <div style={{ color: '#fff' }} className="col-md-9">
                                                    <span className="login-form-answer"><a href="#" onClick={e => { e.preventDefault(); this.props.history.push("/login"); }}>{'< Go to login'}</a></span>
                                                </div>
                                            </CardHeader>
                                            <CardBody>
                                                <div>
                                                    <label style={{ color: '#fff' }}>Email Address</label>
                                                    <InputGroup className={`has-label ${emailState}`}>
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText style={{ padding: '10px 10px 10px 10px', backgroundColor: '#fff' }}>
                                                                <i className="nc-icon nc-email-85" />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input
                                                            placeholder="Email Address"
                                                            name="email"
                                                            type="email"
                                                            onChange={e => this.handleChange(e, "email", "email")}
                                                            onKeyDown={e => {
                                                                if (e.keyCode === 13) {
                                                                    this.handleSubmit();
                                                                }
                                                            }}
                                                            disabled={submitted}
                                                        />
                                                        {this.state.emailState === "has-danger" ? (
                                                            <label className="error">
                                                                Please enter a valid email address.
                                                            </label>
                                                        ) : null}
                                                    </InputGroup>
                                                </div>
                                                <div>
                                                    <Button
                                                        color="primary"
                                                        href="#"
                                                        block
                                                        onClick={this.handleSubmit}
                                                        disabled={submitted}
                                                    >
                                                        Delete User Account
                                                    </Button>
                                                </div>
                                            </CardBody>
                                            <CardFooter></CardFooter>
                                        </Card>
                                    </Col>
                                </Row>
                            </Container>
                            <div
                                className="full-page-background"
                                style={{
                                    backgroundImage: `url(${require("../../assets/img/bg/queue_4000x2500_3.jpg")})`,
                                    backgroundColor: "lightGrey"
                                }}
                            />
                        </div>
                    </div>
                </LoadingOverlay>
            </div>
        );
    }
}

export default DeleteMobileUserAccountRequest;