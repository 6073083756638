import React from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
// import am4themes_animated from "@amcharts/amcharts4/themes/animated";
// import am4themes_kelly from "@amcharts/amcharts4/themes/kelly";

import { AmchartsReact } from 'amchart4-react'

// am4core.useTheme(am4themes_animated);

// am4core.useTheme(am4themes_kelly);


class PeakLocLine extends React.Component {
  state = {
    chart: null
  }

  componentDidMount() {
    let chart = am4core.create("peak_lineChart", am4charts.XYChart);

    this.createChart(chart);

    this.chart = chart;
  }

  handleZoom = (e) => {
    console.log(e)
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  createChart = (chart) => {
    chart.data = this.props.data; 
    chart.colors.list = this.props.color;
    let label = this.props.label;

    // Create axes
    var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.timeUnit ="hour";
    dateAxis.count = 1;
    dateAxis.renderer.minGridDistance = 30;
    dateAxis.dateFormats.setKey("hour", "HH:mm");
    dateAxis.renderer.labels.template.rotation = 270;

    // Create value axis
    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());


    // Create series
    var series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = "value";
    series.dataFields.dateX = "hour";
    series.strokeWidth = 3;
    series.tooltipText = "{valueY.value}";
    series.fillOpacity = 0.1;
    series.timeUnit ="hour";
    series.count = 1;
    series.strokeWidth = 5;
    series.stroke = am4core.color("red");
    series.fill = am4core.color("red").lighten(0.5);


    // // Create a range to change stroke for values below 0
    // var range = valueAxis.createSeriesRange(series);
    // range.value = 0;
    // range.endValue = -1000;
    // range.contents.stroke = chart.colors.getIndex(4);
    // range.contents.fill = range.contents.stroke;
    // range.contents.strokeOpacity = 0.7;
    // range.contents.fillOpacity = 0.1;

    // // Add cursor
    // chart.cursor = new am4charts.XYCursor();
    // chart.cursor.xAxis = dateAxis;
    // chart.scrollbarX = new am4core.Scrollbar();

    // series.tooltip.getFillFromObject = false;
    // series.tooltip.adapter.add("x", (x, target)=>{
    //     if(series.tooltip.tooltipDataItem.valueY < 0){
    //         series.tooltip.background.fill = chart.colors.getIndex(4);
    //     }
    //     else{
    //         series.tooltip.background.fill = chart.colors.getIndex(0);
    //     }
    //     return x;
    // })


    // var i = 0;
    // var series = [];
    // for( i = 0 ; i < label.length ; i++ )
    // {
    //   series[i] = chart.series.push(new am4charts.ColumnSeries());
    //   series[i].dataFields.valueY = label[i];

    //   series[i].dataFields.valueY  = "value";
    //   series[i].dataFields.dateX = "hour";
    //   if( label.length < 9 )
    //     series[i].name = label[i];

    //   series[i].tooltipText = label[i]+": {dateAxisX}:{valueY}";
    //   series[i].stacked = true;
    //   series[i].stroke = am4core.color("#0835F9");
    //   series[i].strokeWidth = 1;
    //   series[i].strokeOpacity = 0.6;
    // }
    //console.log('chart.data--', chart.data );
    // Add cursor
    chart.cursor = new am4charts.XYCursor();

    // Add legend
    chart.legend = new am4charts.Legend();

    chart.exporting.menu = new am4core.ExportMenu();

    dateAxis.renderer.grid.template.strokeOpacity = 0.07;
    valueAxis.renderer.grid.template.strokeOpacity = 0.07;
  }

  render() {
    return (
      <div>
      <div id="peak_lineChart" style={{ width: "100%", height: "500px" }} />
      {this.state.chart ? <AmchartsReact chart={this.state.chart} /> : null}
      </div>
    );
  }
}

export default PeakLocLine;
