import React from "react";
import LoadingOverlay from "react-loading-overlay";
import Firebase from "firebase";
import NotificationAlert from "react-notification-alert";
import Select from "react-select";
import config from "../../../config";

import {
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Button,
    Collapse,
    Input,
    InputGroupAddon,
    InputGroup,
    InputGroupText,
} from "reactstrap";

class Locations extends React.Component {
    constructor(props) {
        super(props);
        if (!Firebase.apps.length) {
            Firebase.initializeApp(config);
        }

        this.state = {
            loading: false,
            empty_sub: false,
            main_location_count: 0,
            max_main_location_limit: 0,

            selected_customer: null,
            locations: [],
            openedCollapses: [],
            role:'',
            customer_list:[],
            singleSelect:null
        };

        this.onAddMain = this.onAddMain.bind(this);
        // this.functions = null;                                              //functions emulator
        // this.fbStore = null;                                                //functions emulator
    }

    // initializeFirebase = () => {                                            //functions emulator
        // if (!this.functions) {                                              //functions emulator
            // this.functions = Firebase.functions();                          //functions emulator
            // this.functions.useFunctionsEmulator('http://localhost:5000');//5001   //functions emulator
            // this.fbStore = Firebase.firestore();                            //functions emulator
        // }                                                                   //functions emulator
    // }                                                                       //functions emulator

    componentDidMount() {
        // this.initializeFirebase();                                          //functions emulator
        this.loadLocations();
    }

    loadLocations(customer_id) {
        if (customer_id == null || customer_id == '') {
            customer_id =  JSON.parse(localStorage.getItem('auth_info')).customer_id;
        }
        this.setState({
            loading: true, 
            locations: []
        });
        Firebase.functions().httpsCallable('stripe_fetchLocations')({customer_id})
            .then((response) => {
                const res = response.data;
                 console.log("res", res)
                var newState = { };
                if (res.status === 'ok') {
                    const { cat_settings, customers, locations,
                            main_location_count, max_main_location_limit, max_sub_location_limit,
                            role, selected_customer_id} = res.data;
                    if (this.state.selected_customer === null || this.state.selected_customer.id === selected_customer_id) {
                        const selected_customer = customers.find(customer => customer.id === selected_customer_id);
                        const empty_sub = locations.find(loc => (loc.sub_locations.length === 0)) !== undefined;
                        const openedCollapses = [];
                        for (let i=0; i < locations.length; i++) {
                            openedCollapses.push(i);
                        }

                        newState = {
                            cat_settings,
                            customer_list: customers,
                            empty_sub,
                            locations,
                            main_location_count,
                            max_main_location_limit,
                            max_sub_location_limit,
                            openedCollapses,
                            role,
                            selected_customer
                        };
                        console.log("cat_settings", cat_settings, "customers", customers,"empty_sub",empty_sub,
                                    "locations", locations,"main_location_count", main_location_count,
                                    "max_main_location_limit", max_main_location_limit, "newState", newState); 
                    }    
                }
                newState.loading = false;
                this.setState(newState);
            })
            .catch((error) => {
                console.log('fetchCustomerInfo', {error});
                this.setState({
                    loading: false
                });
            });
    }

    getLocations() {
        var ok = false;
        if(this.state.role === "Site_Admin" || this.state.role === "System_Admin" || this.state.role === "Location_Admin" || this.state.role === "Billing_Admin")
            ok = true;
        return this.state.locations.map((prop, toggle_key) => {
            // console.log("getLocations error1 ",toggle_key, "==>", prop);
            return (
                <div key={toggle_key}>
                    <Row>
                        <Col xl="8" lg="8" className="top-margin-custom overflow-ellipsis">
                            <a href="#" className="custom-nav"><i className={this.state.openedCollapses.includes(toggle_key)?"nc-icon nc-minimal-up":"nc-icon nc-minimal-down"}/></a>
                            <a
                                aria-expanded={this.state.openedCollapses.includes(
                                    toggle_key
                                )}
                                href="#"
                                id={'main-' + toggle_key}
                                data-parent="#accordion"
                                data-toggle="collapse"
                                onClick={ e => {e.preventDefault(); this.collapsesToggle(toggle_key);}}
                            >
                                {prop.name}
                            </a>
                        </Col>
                        {ok?<Col xl="2" lg="2" className="padding-custom">
                            <Button
                                onClick={e => {e.preventDefault(); this.onEditMain(prop.id)}}
                                className="btn btn-sm btn-success"
                                block
                            >
                                Edit
                            </Button>
                        </Col>:''}
                    </Row>
                    <Collapse
                        role="tabpanel"
                        isOpen={this.state.openedCollapses.includes(
                            toggle_key
                        )}
                    >
                        {prop.sub_locations.map((prop, key) => {
                            {/* console.log("getLocations error2 ", key, "==>", prop); */}
                            return (
                                <Row key={key}>
                                    <Col xl="8" lg="8" className="top-margin-custom padding-custom-location overflow-ellipsis">
                                        <span id={'sub-' + toggle_key + '-' + key} style={{ color: 'black'}} onClick={e => e.preventDefault()}>
                                        - {prop.name}
                                        </span>
                                        <br/>
                                        <span style={{ color: 'gray', fontSize: '0.8em', marginLeft: 16 }}>Expiry date: {prop.expire}</span>
                                        <br/>
                                        <span style={{ color: 'gray', fontSize: '0.8em', marginLeft: 16 }}>Status: {prop.status}</span>
                                    </Col>
                                    {ok? <><Col xl="2" lg="2" className="padding-custom">
                                        <Button
                                            onClick={e => {e.preventDefault(); this.onEditSub(prop.id)}}
                                            className="btn btn-sm btn-warning text-capitalize"
                                            block
                                        >
                                            Edit
                                        </Button>
                                    </Col>
                                    <Col xl="2" lg="2" className="padding-custom">
                                        <Button
                                            onClick={e => {e.preventDefault(); this.onManageSub(prop.id)}}
                                            className="btn btn-sm btn-success text-capitalize"
                                            block
                                        >
                                            Manage
                                        </Button>
                                    </Col>
                                    {/* <Col xl="2" lg="2" className="padding-custom">
                                        <Button
                                            onClick={e => {e.preventDefault(); this.onDisableSub(prop.id)}}
                                            className="btn btn-sm btn-danger text-capitalize"
                                            block
                                        >
                                            Disable
                                        </Button>
                                    </Col> */}
                                    </>:''}
                                </Row>
                            );
                        })}
                    </Collapse>
                </div>
            );
        });
    }
    collapsesToggle = collapse => {
        let openedCollapses = this.state.openedCollapses;
        if (openedCollapses.includes(collapse)) {
            this.setState({
                openedCollapses: openedCollapses.filter(item => item !== collapse)
            });
        } else {
            openedCollapses.push(collapse);
            this.setState({
                openedCollapses: openedCollapses
            });
        }
    };
    onAddMain(selectedCustomerID) {
        var role = this.state.role;
        console.log("role", role);
        
        if (this.state.empty_sub) {
            this.notifyMessage("tc", 3, "A main location without sub location(s) already exists. Please use/remove this first.");
            return;
        }

        // --------- Check Max Main Location Limit --------- //
        if (this.state.main_location_count < this.state.max_main_location_limit) {
            if (role === "Site_Admin") {
                this.props.history.push("/main_location/add"+"/"+selectedCustomerID.value);
            } else {
                this.props.history.push("/main_location/add/1");
            }
        } else {
            this.notifyMessage("tc", 3, "You have reached the main location max limit.");
        }
    }
    onAddSub(selectedCustomerID) {
        //this.props.history.push("/sub_location/add_select_package"); // tbc 15JUN2020
    
        var role = this.state.role;
        if (role === "Site_Admin") {
            this.props.history.push("/sub_location/add"+"/"+selectedCustomerID.value);
        } else {
            this.props.history.push("/sub_location/add/1");
        }
    }
    onEditMain(id) {
        this.props.history.push("/main_location/edit/" + id);
    }
    onEditSub(id) {
        this.props.history.push("/sub_location/edit/" + id);
    }
    onManageSub(id) {
        this.props.history.push("/sub_location/manage_package/" + id);
    }
    onDisableSub(id) {
        let _this = this;
        _this.setState({loading: true});
        let now = new Date();
        Firebase.firestore().collection('Sub_Locations').doc(id).update({Stop_Date:now}).then(function () {
            _this.setState({loading: false});
            _this.notifyMessage("tc", 2, "Disable Sub Location Success");
        }).catch(function (err) {
            _this.setState({loading: false});
            _this.notifyMessage("tc", 3, "Network Error.");
            console.log("onDisableSub NetworkError12=>", err);
        });
    }
    notifyMessage = (place, color, text) => {
        var type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }

        var options = {};
        options = {
            place: place,
            message: (
                <div className="text-md-center">
                    <div>
                        <b>{text}</b>
                    </div>
                </div>
            ),
            type: type,
            icon: "now-ui-icons ui-1_bell-53",
            autoDismiss: 3
        };
        if (options !== null) { this.notificationAlert.notificationAlert(options) }
    };
    onChangeCustom(e) {
        // if (e.value === "") {
        //     search = "";
        // } else {
        //     search = e.value;
        // }
        // this.setState({ singleSelect: e });
        this.setState({ selected_customer: e });
        this.loadLocations(e.id);
        console.log("e",e,"e.id",e.id);
    }
    render() {
        var ok = false;
        if(this.state.role === "Site_Admin" || this.state.role === "System_Admin" || this.state.role === "Location_Admin" || this.state.role === "Billing_Admin")
            ok = true;
        return (
            <>
                <LoadingOverlay
                    active={this.state.loading}
                    spinner
                    text='Loading'
                    className='content'
                >
                    <NotificationAlert ref={(ref) => this.notificationAlert = ref} />
                    <Row>
                        <Col md="12">
                        {ok?<Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Locations</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col className="ml-auto mr-auto" xl="10" lg="12" md="12">
                                           {ok? <><Row className="bottom-margin-20">
                                                {/* {this.state.role !== 'Site_Admin'? */}
                                                <Col lg="6">
                                                    <Col xl="6" lg="6">
                                                        <Button
                                                            onClick={e => {e.preventDefault(); this.onAddMain(this.state.selected_customer);}}
                                                            className="btn btn-info"
                                                            id="btnAddMain"
                                                            disabled={(this.state.selected_customer !== null) ? false: true}
                                                            block
                                                        >
                                                            Add Main Location
                                                        </Button>
                                                    </Col>
                                                    <Col xl="6" lg="6">
                                                        <Button
                                                            onClick={e => {e.preventDefault(); this.onAddSub(this.state.selected_customer)}}
                                                            className="btn btn-info"
                                                            id="btnAddSub"
                                                            disabled={(this.state.selected_customer !== null) ? false: true}
                                                            block
                                                        >
                                                            Add Sub Location
                                                        </Button>
                                                    </Col>
                                                </Col>
                                                {/* } */}
                                                {/* {this.state.role === 'Site_Admin'? 
                                                <Col lg="3">
                                                    <InputGroup className="input-search">
                                                        <Input defaultValue="" placeholder="Search" type="text" />
                                                        <InputGroupAddon addonType="append">
                                                            <InputGroupText>
                                                                <i className="nc-icon nc-zoom-split" />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                    </InputGroup>
                                                </Col>:''} */}
                                                <Col lg="6" hidden={this.state.role === "Site_Admin" ? false : true}>
                                                    <Select
                                                        className="react-select info select-location"
                                                        classNamePrefix="react-select"
                                                        placeholder="Select Customer"
                                                        name="selectCustomer"
                                                        value={this.state.selected_customer}
                                                        onChange={e =>
                                                            this.onChangeCustom(e)
                                                        }
                                                        options={this.state.customer_list}
                                                    />
                                                </Col>
                                            </Row></>:''}
                                            <Row>
                                                <Col md="12">
                                                    <div className="blockquote blockquote-primary">
                                                        <div
                                                            aria-multiselectable={true}
                                                            className="card-collapse col-md-12"
                                                            id="accordion"
                                                            role="tablist"
                                                        >
                                                            {this.getLocations()}
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>:null}
                        </Col>
                    </Row>
                </LoadingOverlay>
            </>
        );
    }
}

export default Locations;
