import React, { useState, useEffect } from "react";
import Select from "react-select";
import ReactTable from "react-table-6";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import {
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Button,
  Label,
  Modal,
  FormGroup,
  Row,
  Col,
  Input,
  ModalHeader,
} from "reactstrap";
import TokenAttachFile from "../views/pages/admin/TokenAttachFile";

export default function TokenDetailsModal(props) {
  const [state, setState1] = useState({ ...props.state });
  useEffect(() => {
    setState1({ ...props.state });
  }, [props.state]);
  const {
    selected_token,

    selected_token_serv_dateState,
    selected_token_main_locState,
    selected_token_sub_locState,
    selected_token_servState,
    selected_token_internal_respState,
    selected_token_token_statusState,
  } = state;

  let tokenData = selected_token;

  var inputFields = [];
  var fileAttachments = [];
  if (tokenData !== null && typeof tokenData !== "undefined") {
    tokenData.input_fields.map((prop, key) => {
      if (typeof prop.id !== "undefined" && prop.id !== null && prop.id !== "") {
        inputFields.push({
          id: prop.id,
          key: key + 1,
          field_name: prop.Input_Field_Name,
          field_value: prop.Input_Field_Value,
          field_data_type: prop.Input_Field_Data_Type,
          field_mandatory:
            prop.Input_Field_Mandatory == true ? (
              <span className="badge badge-pill badge-success">Yes</span>
            ) : (
              <span className="badge badge-pill badge-default">No</span>
            ),
        });
      }
    });

    tokenData.file_attachments.map((prop, key) => {
      if (typeof prop.id !== "undefined" && prop.id !== null && prop.id !== "") {
        fileAttachments.push({
          id: prop.id,
          key: key + 1,
          file_attachment_name: prop.File_Attachment_Name,
          // file_attachment_file_name: prop.File_Attachment_File_Name,
          file_size: prop.File_Attachment_File_Size_In_KB,
          file_type: prop.File_Attachment_File_Type,
          file_mandatory:
            prop.File_Attachment_Mandatory == true ? (
              <span className="badge badge-pill badge-success">Yes</span>
            ) : (
              <span className="badge badge-pill badge-default">No</span>
            ),
          action:
            prop.File_Attachment_URL !== "" ? (
              <div>
                <Button
                  className="btn btn-default btn-sm"
                  style={{ marginTop: "-7px", marginBottom: "-7px" }}
                >
                  <a href={prop.File_Attachment_URL} target="_blank" rel="noopener noreferrer">
                    Preview
                  </a>
                </Button>
              </div>
            ) : null,
        });
      }
    });

    // console.log("fileAttachments", fileAttachments);
  }
  // }, [props])
  return (
    <Modal
      size="lg"
      backdrop={"static"}
      //dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
      isOpen={state.modalClassic}
      toggle={props.toggleModalClassic}
    >
      {state.modalClassic && (
        <>
          <ModalHeader
            className="justify-content-center"
            toggle={props.toggleModalClassic}
          >
            {/* <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              
            >
              <i className="nc-icon nc-simple-remove" />
            </button> */}

            {tokenData.new_tok_ref_id !== null &&
            typeof tokenData.new_tok_ref_id !== "undefined" &&
            tokenData.new_tok_ref_id !== "" ? (
              <>
                <span>
                  Current Token Number (Cancelled): {tokenData.token_num}
                </span>
                <span>New Token Number: {tokenData.new_tok_ref_num}</span>
              </>
            ) : tokenData.cancelled_tok_ref_id !== null &&
              typeof tokenData.cancelled_tok_ref_id !== "undefined" &&
              tokenData.cancelled_tok_ref_id !== "" ? (
              <>
                <span>
                  Previous Token Number (Cancelled):{" "}
                  {tokenData.cancelled_tok_ref_num}
                </span>
                <span>Current Token Number: {tokenData.token_num}</span>
              </>
            ) : (
              <>
                <span>Current Token Number: {tokenData.token_num}</span>
              </>
            )}
          </ModalHeader>
          <div className="modal-body">
            <>
              <div className="content">
                <div className="nav-tabs-navigation">
                  <div className="nav-tabs-wrapper">
                    <Nav id="tabs" role="tablist" tabs>
                      <NavItem>
                        <NavLink
                          aria-expanded={
                            state.horizontalTabs === "modifyRequestTabPane"
                          }
                          data-toggle="tab"
                          href="#panel"
                          role="tab"
                          className={
                            state.horizontalTabs === "modifyRequestTabPane"
                              ? "active"
                              : ""
                          }
                          onClick={() =>
                            props.setState({
                              horizontalTabs: "modifyRequestTabPane",
                            })
                          }
                        >
                          Modify Service Request
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          aria-expanded={
                            state.horizontalTabs === "inputFieldsTabPane"
                          }
                          data-toggle="tab"
                          href="#panel"
                          role="tab"
                          className={
                            state.horizontalTabs === "inputFieldsTabPane"
                              ? "active"
                              : ""
                          }
                          onClick={() =>
                            props.setState({
                              horizontalTabs: "inputFieldsTabPane",
                            })
                          }
                        >
                          Input Fields
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          aria-expanded={
                            state.horizontalTabs === "attachmentFieldsTabPane"
                          }
                          data-toggle="tab"
                          href="#panel"
                          role="tab"
                          className={
                            state.horizontalTabs === "attachmentFieldsTabPane"
                              ? "active"
                              : ""
                          }
                          onClick={() =>
                            props.setState({
                              horizontalTabs: "attachmentFieldsTabPane",
                            })
                          }
                        >
                          File Attachments
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </div>
                <TabContent
                  className="text-center"
                  id="my-tab-content"
                  activeTab={state.horizontalTabs}
                >
                  <TabPane tabId="modifyRequestTabPane" role="tabpanel">
                    <Row className="top-margin-10" />
                    <Row>
                      <Label md="4">Service Date</Label>
                      <Col md="8" style={{ textAlign: "left" }}>
                        <FormGroup
                          className={`has-label ${selected_token_serv_dateState}`}
                        >
                          {/* z-Index css property set in index.html file */}
                          <DatePicker
                            format="dd-MM-yyyy"
                            value={state.selected_token_serv_date}
                            onChange={(e) =>
                              props.handleChangeModifyTokenFields(
                                e,
                                "MOD_servDateUnixTS"
                              )
                            }
                          />
                          {selected_token_serv_dateState === "has-danger" ? (
                            <label className="error">
                              A valid Service Date is required.
                            </label>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label md="4">Main Location</Label>
                      <Col md="8">
                        <FormGroup
                          className={`has-label ${selected_token_main_locState}`}
                        >
                          <Select
                            placeholder="Select"
                            closeMenuOnSelect={true}
                            value={state.selected_token_main_loc}
                            options={state.selected_token_main_loc_list}
                            onChange={(e) =>
                              props.handleChangeModifyTokenFields(
                                e,
                                "MOD_mainLocId"
                              )
                            }
                          />
                          {selected_token_main_locState === "has-danger" ? (
                            <label className="error">
                              This field is required.
                            </label>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label md="4">Sub Location</Label>
                      <Col md="8">
                        <FormGroup
                          className={`has-label ${selected_token_sub_locState}`}
                        >
                          <Select
                            placeholder="Select"
                            closeMenuOnSelect={true}
                            value={state.selected_token_sub_loc}
                            options={state.selected_token_sub_loc_list}
                            onChange={(e) =>
                              props.handleChangeModifyTokenFields(
                                e,
                                "MOD_subLocId"
                              )
                            }
                          />
                          {selected_token_sub_locState === "has-danger" ? (
                            <label className="error">
                              This field is required.
                            </label>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label md="4">Service</Label>
                      <Col md="8">
                        <FormGroup
                          className={`has-label ${selected_token_servState}`}
                        >
                          <Select
                            placeholder="Select"
                            closeMenuOnSelect={true}
                            value={state.selected_token_serv}
                            options={state.selected_token_serv_list}
                            onChange={(e) =>
                              props.handleChangeModifyTokenFields(
                                e,
                                "MOD_servId"
                              )
                            }
                          />
                          {selected_token_servState === "has-danger" ? (
                            <label className="error">
                              This field is required.
                            </label>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label md="4">Internal Response</Label>
                      <Col md="8">
                        <FormGroup>
                          <Input
                            value={state.selected_token_internal_resp}
                            type="text"
                            onChange={(e) =>
                              props.handleChangeModifyTokenFields(
                                e,
                                "MOD_internalResp"
                              )
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label md="4">Token Status</Label>
                      <Col md="8">
                        <FormGroup
                          className={`has-label ${selected_token_token_statusState}`}
                        >
                          <Select
                            placeholder="Select"
                            closeMenuOnSelect={true}
                            value={state.selected_token_token_status}
                            options={state.selected_token_token_status_list}
                            onChange={(e) =>
                              props.handleChangeModifyTokenFields(
                                e,
                                "MOD_tokenStatus"
                              )
                            }
                          />
                          {selected_token_token_statusState === "has-danger" ? (
                            <label className="error">
                              This field is required.
                            </label>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    {tokenData.new_tok_ref_id !== null &&
                    typeof tokenData.new_tok_ref_id !== "undefined" &&
                    tokenData.new_tok_ref_id !== "" ? (
                      <>
                        <Row>
                          <Label md="4">Cancelled Reason</Label>
                          <Col md="8">
                            <FormGroup>
                              <Input
                                disabled
                                defaultValue={
                                  state.selected_token_ref_cancelled_reason
                                }
                                type="textarea"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </>
                    ) : null}
                    <Row>
                      <Label md="4"></Label>
                      <Col md="8">
                        <FormGroup>
                          <div>
                            <button
                              className="btn btn-info btn-block"
                              variant="primary"
                              disabled={state.btn_disabled_save_tok}
                              onClick={() =>
                                props.handleRequest(tokenData, "modify_tok", 1)
                              }
                            >
                              {/* <Spinner
                                                                    as="span"
                                                                    animation="border"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                /> */}
                              {state.btn_disabled_save_tok == true
                                ? "Saving. Please wait."
                                : "Save"}
                            </button>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="inputFieldsTabPane" role="tabpanel">
                    <Row className="top-margin-10" />
                    {inputFields !== null &&
                    typeof inputFields !== "undefined" &&
                    inputFields.length !== 0 ? (
                      <>
                        <div>
                          <ReactTable
                            data={inputFields}
                            columns={[
                              {
                                Header: "#",
                                accessor: "key",
                                width: 40,
                              },
                              {
                                Header: "Field Name",
                                accessor: "field_name",
                              },
                              {
                                Header: "Field Value",
                                accessor: "field_value",
                              },
                              {
                                Header: "Data Type",
                                accessor: "field_data_type",
                              },
                              {
                                Header: "Mandatory",
                                accessor: "field_mandatory",
                              },
                            ]}
                            defaultPageSize={5}
                            showPaginationTop={false}
                            showPaginationBottom={true}
                            showPageSizeOptions={false}
                            /*
                                                                        You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                                                                        */
                            className="-striped -highlight primary-pagination"
                          />
                        </div>
                      </>
                    ) : (
                      "There are no input fields attached to this token!"
                    )}
                  </TabPane>
                  <TabPane tabId="attachmentFieldsTabPane" role="tabpanel">
                    <Row className="top-margin-10" />
                    {fileAttachments !== null &&
                    typeof fileAttachments !== "undefined" &&
                    fileAttachments.length !== 0 ? (
                      <>
                        <div>
                          <ReactTable
                            data={fileAttachments}
                            columns={[
                              {
                                Header: "#",
                                accessor: "key",
                                width: 40,
                              },
                              {
                                Header: "Attachment Name",
                                accessor: "file_attachment_name",
                              },
                              {
                                Header: "Attachment File Name",
                                accessor: "file_attachment_file_name",
                              },
                              {
                                Header: "File Size (Kb)",
                                accessor: "file_size",
                              },
                              {
                                Header: "File Type",
                                accessor: "file_type",
                              },
                              {
                                Header: "Mandatory",
                                accessor: "file_mandatory",
                              },
                              {
                                Header: "Action",
                                accessor: "action",
                                sortable: false,
                              },
                            ]}
                            defaultPageSize={5}
                            showPaginationTop={false}
                            showPaginationBottom={true}
                            showPageSizeOptions={false}
                            /*
                                                                        You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                                                                        */
                            className="-striped -highlight primary-pagination"
                          />
                        </div>
                      </>
                    ) : (
                      "There are no file attachments attached to this token!"
                    )}
                    <TokenAttachFile
                      setUpdate={(e) => {
                        // _setState({updateState:Date.now()});
                        fileAttachments = e;
                      }}
                      TokenData={tokenData}
                      notifyMessage={props.notifyMessage}
                    />
                  </TabPane>
                </TabContent>
              </div>
            </>
          </div>
          <div className="modal-footer">
            <div className="left-side">
              {/* {props?.other ? null : (
                <> */}
              <Button
                className="btn btn-primary"
                color="success"
                disabled={
                  state.btn_disabled_approve_tok ||
                  tokenData.token_status != "Pending_Approval"
                }
                onClick={() => props.handleRequest(tokenData, "approve_tok", 1)}
              >
                Approve <i className="nc-icon nc-check-2" />
              </Button>
              <Button
                className="btn btn-primary"
                color="danger"
                disabled={
                  state.btn_disabled_reject_tok ||
                  tokenData.token_status == "Rejected"
                }
                onClick={() => props.handleRequest(tokenData, "reject_tok", 1)}
              >
                Reject <i className="nc-icon nc-simple-remove" />
              </Button>
              {/* </>
              )} */}
              <Button
                className="btn btn-default"
                color="default"
                disabled={state.btn_disabled_mod_cancel}
                onClick={props.toggleModalClassic}
              >
                Cancel
              </Button>
            </div>
          </div>
        </>
      )}
    </Modal>
  );
}