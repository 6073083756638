import ReactTable from "react-table-6";
import React, { Component } from 'react';

// import "react-table/react-table.css";
// import "./styles.css";

const columns = [
  { Header: '', accessor: '0', headerStyle: { whiteSpace: 'unset' }, style: { whiteSpace: 'unset' }, maxWidth: 50 },
  // {Header: 'Created_DateTime', accessor: '1', headerStyle: { whiteSpace: 'unset' }, style: { whiteSpace: 'unset' },  minWidth: 250}, 
  { Header: 'Created_Date_Formated', accessor: '2', headerStyle: { whiteSpace: 'unset' }, style: { whiteSpace: 'unset' }, minWidth: 250 },
  { Header: 'Created_Time_Formated', accessor: '3', headerStyle: { whiteSpace: 'unset' }, style: { whiteSpace: 'unset' }, minWidth: 250 },
  { Header: 'Main_Location_Name', accessor: '4', headerStyle: { whiteSpace: 'unset' }, style: { whiteSpace: 'unset' }, minWidth: 300 },
  { Header: 'Sub_Location_Name', accessor: '5', headerStyle: { whiteSpace: 'unset' }, style: { whiteSpace: 'unset' }, minWidth: 300 },
  { Header: 'Service_Name', accessor: '6', headerStyle: { whiteSpace: 'unset' }, style: { whiteSpace: 'unset' }, minWidth: 300 },
  { Header: 'Service_Cancelled_Datetime', accessor: '7', headerStyle: { whiteSpace: 'unset' }, style: { whiteSpace: 'unset' }, minWidth: 300 },
  // {Header: 'Service_Start_Datetime', accessor: '8', headerStyle: { whiteSpace: 'unset' }, style: { whiteSpace: 'unset' },  minWidth: 300}, 
  // {Header:'Service_End_Datetime', accessor: '9', headerStyle: { whiteSpace: 'unset' }, style: { whiteSpace: 'unset' },  minWidth: 300}, 
  { Header: 'Priority', accessor: '10', headerStyle: { whiteSpace: 'unset' }, style: { whiteSpace: 'unset' }, minWidth: 100 },
  { Header: 'Mobile_App_User_Email', accessor: '11', headerStyle: { whiteSpace: 'unset' }, style: { whiteSpace: 'unset' }, minWidth: 300 },
  { Header: 'Mobile_App_User_Contact', accessor: '12', headerStyle: { whiteSpace: 'unset' }, style: { whiteSpace: 'unset' }, minWidth: 300 },
  // {Header: 'Mobile_App_User_ID', accessor: '13', headerStyle: { whiteSpace: 'unset' }, style: { whiteSpace: 'unset' },  minWidth: 300}, 
  { Header: 'Served_Counter', accessor: '14', headerStyle: { whiteSpace: 'unset' }, style: { whiteSpace: 'unset' }, minWidth: 200 },
  { Header: 'Customer_Comments', accessor: '15', headerStyle: { whiteSpace: 'unset' }, style: { whiteSpace: 'unset' }, minWidth: 250 },
  // {Header:'Customer_ID', accessor: '16', headerStyle: { whiteSpace: 'unset' }, style: { whiteSpace: 'unset' },  minWidth: 200}, 
  { Header: 'Customer_Rated_Datetime', accessor: '17', headerStyle: { whiteSpace: 'unset' }, style: { whiteSpace: 'unset' }, minWidth: 380 },
  { Header: 'Customer_Rating', accessor: '18', headerStyle: { whiteSpace: 'unset' }, style: { whiteSpace: 'unset' }, minWidth: 200 },
  { Header: 'Customer_Source', accessor: '19', headerStyle: { whiteSpace: 'unset' }, style: { whiteSpace: 'unset' }, minWidth: 200 },
  { Header: 'Token_Created_User_Name', accessor: '20', headerStyle: { whiteSpace: 'unset' }, style: { whiteSpace: 'unset' }, minWidth: 320 },
  { Header: 'Token_Number', accessor: '21', headerStyle: { whiteSpace: 'unset' }, style: { whiteSpace: 'unset' }, minWidth: 180 },
  { Header: 'Token_Status', accessor: '22', headerStyle: { whiteSpace: 'unset' }, style: { whiteSpace: 'unset' }, minWidth: 180 },
  { Header: 'Served_User_Name', accessor: '23', headerStyle: { whiteSpace: 'unset' }, style: { whiteSpace: 'unset' }, minWidth: 220 },
  { Header: 'Input_Source', accessor: '24', headerStyle: { whiteSpace: 'unset' }, style: { whiteSpace: 'unset' }, minWidth: 200 },
  { Header: 'Serving_Time', accessor: '25', headerStyle: { whiteSpace: 'unset' }, style: { whiteSpace: 'unset' }, minWidth: 200 },
  { Header: 'Waiting_Time', accessor: '26', headerStyle: { whiteSpace: 'unset' }, style: { whiteSpace: 'unset' }, minWidth: 200 },
  { Header: 'Percentile_Score', accessor: '27', headerStyle: { whiteSpace: 'unset' }, style: { whiteSpace: 'unset' }, minWidth: 200 },];
export default class ReactTableComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: this.props.page,
      rowEdit: null,
      selectedRowIndex: [],
      selectionChanged: false,
      bChange: false,
    };
  }

  componentDidMount() {
    let index = this.props.indexitem;
    // this.state.page = this.props.page; // TESTING_OLY
    this.setState({ page: this.props.page })
    this.setState({ selectedRowIndex: index, selectionChanged: true });

  }
  handleSelect(e) {
    const selected = this.state.selected;
    selected[e.target.name] = e.target.checked;
    this.setState({ selected });
  }
  render() {
    return (
      <div style={{ padding: '5px', paddingTop: '0px' }}>
        <ReactTable
          // manual
          minRows={0}
          defaultPageSize={10}
          className="-striped -highlight"
          data={this.props.data}
          columns={columns}
          page={this.state.bChange ? this.state.page : this.props.page}
          showPagination={false}
          onPageChange={page => this.setState({ page: page, bChange: true })}
          getTrProps={(state, rowInfo) => {
            return {
              style: {
                background:
                  rowInfo.index === this.props.indexitem - 1 ? "#FFFF00" : "white",
                color:
                  rowInfo.index === this.props.indexitem - 1 ? "black" : "black"
              }
            };
          }}
        />
      </div>
    );
  }
}
