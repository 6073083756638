import React, { useState, useEffect, useRef } from "react";
import NotificationAlert from "react-notification-alert";
import Firebase from "firebase";
import LoadingOverlay from "react-loading-overlay";
import CopyToClipboard from "react-copy-to-clipboard";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import Switch from "react-bootstrap-switch";
import IconUpload from "../../../components/CustomUpload/IconUpload";
import { ColorPicker } from "react-pick-color";
import dateFormat from "dateformat";

import { Col, Card, CardHeader, CardBody, CardTitle, Row, Label, Button, Input, Form, FormGroup } from "reactstrap";
import config from "../../../config";

function KioskPrintEdit(props) {
  // constructor (props) {
  // super(props)
  if (!Firebase.apps.length) {
    Firebase.initializeApp(config);
  }

  const printLogo = useRef(null);
  const background = useRef(null);
  const notificationAlert = useRef(null);

  const [state, setState1] = useState({
      printerIp:'',
      printerPort:'9100',
    mainLocFontFont: "",
    subLocFontFont: "",
    addressFont: "",
    text1Font: "",
    text2Font: "",
    serviceNameFont: "",
    serviceDetailsFont: "",
    tokenFont: "",
    tokensAheadFont: "",
    qrCodeFont: "",
    printDateFont: "",
    printFooterFont: "",

    dateFormat: "",
    loading: false,
    customer_id: "",
    main_location: "",
    sub_location: "",
    printLogo: true,
    printLogoUrl: "",
    printLogoSize: "",
    mainLocationEnable: true,
    mainLocFontSize: "",
    mainLocBold: true,
    subLocationEnable: true,
    subLocFontSize: "",
    subLocBold: true,
    addressEnable: true,
    addressSize: "",
    addressBold: true,
    text1Enable: true,
    text1: "",
    text1Size: "",
    text1Bold: true,
    text2Enable: true,

    text2: "",
    text2Size: "",
    text2Bold: true,
    printHeaderBottom: "",

    serviceName: true,
    serviceNameSize: "",
    serviceNameBold: false,

    serviceDetails: true,
    serviceDetailsSize: "",
    serviceDetailsBold: false,
    printServicePadding: "",

    tokenSize: "3",
    tokenBold: true,
    tokenAhead: false,
    tokensAheadSize: "",
    tokensAheadBold: false,

    qrCode: false,
    qrCodeSize: "",
    tokenBottomPadding: "",

    printDateDisplay: false,
    printDateSize: "",
    printDateBold: false,

    printFooterDisplay: false,
    printFooterText: "",
    printFooterSize: "",

    printFooterBackground: "",
    printFooterColor: "",
    printFooterTextSize: "",

    // max_height: 0,
    // max_width: 0,
    sub_location_id: "",
    current_time: "",

    show_location_icon: true,
    footer_text: "",
    button_layout_format: "full",
    enable_print_logo: true,
    print_type: null,
    print_type_list: [
      {
        value: "normal",
        label: "Normal Thermal Printer",
      },
      {
        value: "v001",
        label: "Standard Dispenser V001",
      },
    ],

    dispenser_id: "",
    dispenser_name: "",
    dispenser_nameState: "has-success",
    // height: 0,
    // width: 0,
    // invalid_height: false,
    // invalid_width: false,
    // pin: '',
    pinState: "has-success",
    // pin_type: 'password',
    // location_image_url: '',
    // location_name: '',
    // location_address: '',
    // service_list: [],
    // backgroundUrl: '',
    // logoPadding: { top: '', bottom: '' },
    // servicesMargin: '',
    // servicesRadius: '',
    // servicesSize: '',
    // defaultFont: '',
    // backgroundColor: '',
    // colorPick: false,
    // colorPlace: '',
    // servicesBackground: '',
    // servicesName: true,
    // servicesTextColor: '',
    // servicesFontSize: '',
    // servicesIconSize: '',
    // servicesIcon: true,
    // servicesFont: '',

    // servicesDetails: true,
    // servicesDetailsColor: '',
    // servicesDetailsFont: '',
    // servicesDetailsFontSize: '',

    // dateDisplay: true,
    // dateBackground: '',
    // dateColor: '',
    // dateFont: '',
    // dateFontSize: '',
    // dateSize: '',

    // footerDisplay: true,
    // footerBackground: '',
    // footerColor: '',
    // footerFont: '',
    // footerFontSize: '',
    // footerSize: ''
  });
  const setState = (e) => setState1((c) => ({ ...c, ...e }));
  // }

  // componentDidMount () {
  useEffect(() => {
    getCurrentTime();
    let id = props.match.params.id;
    if (id !== "" && id !== null && id !== undefined) {
      setState({ dispenser_id: id });
      loadData(id);
    }
  }, []);

  // }

  function loadData(id) {
    setState({ loading: true });
    // ---------- Load Max Height and Width ---------- //
    Firebase.firestore()
      .collection("System_Config")
      .doc("Dispensor_Settings")
      .get()
      .then(function (dispenser_setting) {
        if (dispenser_setting.exists) {
          setState({
            max_height: dispenser_setting.data().Dispensor_Max_Height,
          });
          setState({
            max_width: dispenser_setting.data().Dispensor_Max_Width,
          });
          // ---------- Load Dispenser Data ---------- //
          Firebase.firestore()
            .collection("Kiosk")
            .doc(id)
            .get()
            .then(function (doc) {
              if (doc.exists) {
                let one = state.print_type_list.find((item) => item.value === doc.data().Print_Type);
                setState({
                    printerIp:doc.data().PrinterIp || "",
                    printerPort:doc.data().PrinterPort || "9100",
                  mainLocFontFont: doc.data().mainLocFont_Font || "",
                  subLocFontFont: doc.data().subLocFont_Font || "",
                  addressFont: doc.data().address_Font || "",
                  text1Font: doc.data().text1_Font || "",
                  text2Font: doc.data().text2_Font || "",
                  serviceNameFont: doc.data().serviceName_Font || "",
                  serviceDetailsFont: doc.data().serviceDetails_Font || "",
                  tokenFont: doc.data().token_Font || "",
                  tokensAheadFont: doc.data().tokensAhead_Font || "",
                  qrCodeFont: doc.data().qrCode_Font || "",
                  printDateFont: doc.data().printDate_Font || "",

                  dateFormat: doc.data().Label_Date_Format || "",

                  loading: false,
                  printLogo: doc.data().Print_Logo != undefined ? doc.data().Print_Logo : false,
                  printLogoUrl: doc.data().Print_Logo_Url || "",
                  main_location: doc.data().Main_Location_Name,
                  sub_location: doc.data().Sub_Location_Name,
                  printLogoSize: doc.data().Print_Logo_Size || "",
                  mainLocationEnable: doc.data().MainLocation_Enable != undefined ? doc.data().MainLocation_Enable : false,
                  mainLocFontSize: doc.data().MainLocation_Font_Size || "",
                  mainLocBold: doc.data().MainLocation_Bold || "",
                  subLocationEnable: doc.data().SubLocation_Enable != undefined ? doc.data().SubLocation_Enable : false,
                  subLocFontSize: doc.data().SubLocation_Font_Size || "",
                  subLocBold: doc.data().SubLocation_Bold != undefined ? doc.data().SubLocation_Bold : false,
                  addressEnable: doc.data().Address_Enable != undefined ? doc.data().Address_Enable : false,
                  addressSize: doc.data().Address_Size || "",
                  addressBold: doc.data().Address_Bold != undefined ? doc.data().Address_Bold : false,
                  text1Enable: doc.data().Text1_Enable != undefined ? doc.data().Text1_Enable : false,
                  text1: doc.data().Text1 || "",
                  text1Size: doc.data().Text1_Size || "",
                  text1Bold: doc.data().Text1_Bold != undefined ? doc.data().Text1_Bold : false,

                  text2Enable: doc.data().Text2_Enable != undefined ? doc.data().Text2_Enable : false,
                  text2: doc.data().Text2 || "",
                  text2Size: doc.data().Text2_Size || "",
                  text2Bold: doc.data().Text2_Bold != undefined ? doc.data().Text2_Bold : false,
                  printHeaderBottom: doc.data().Print_Header_Bottom || "",

                  serviceName: doc.data().Service_Name != undefined ? doc.data().Service_Name : false,
                  serviceNameSize: doc.data().Service_Name_Size || "",
                  serviceNameBold: doc.data().Service_Name_Bold != undefined ? doc.data().Service_Name_Bold : false,

                  serviceDetails: doc.data().Service_Details != undefined ? doc.data().Service_Details : false,
                  serviceDetailsSize: doc.data().Service_Details_Size || "",
                  serviceDetailsBold: doc.data().Service_Details_Bold != undefined ? doc.data().Service_Details_Bold : false,
                  printServicePadding: doc.data().Print_Service_Padding || "",

                  tokenSize: doc.data().Token_Size || "42",
                  tokenBold: doc.data().Token_Bold != undefined ? doc.data().Token_Bold : true,
                  servicePadding: doc.data().Service_Padding || "",
                  tokenAhead: doc.data().Tokens_Ahead != undefined ? doc.data().Tokens_Ahead : false,
                  tokensAheadSize: doc.data().Tokens_Ahead_Size || "",
                  tokensAheadBold: doc.data().Tokens_Ahead_Bold != undefined ? doc.data().Tokens_Ahead_Bold : false,

                  qrCode: doc.data().Qr_Code != undefined ? doc.data().Qr_Code : false,
                  qrCodeSize: doc.data().Qr_Code_Size || "",
                  tokenBottomPadding: doc.data().Token_Bottom_Padding || "",

                  printDateDisplay: doc.data().Print_Date_Display != undefined ? doc.data().Print_Date_Display : false,
                  printDateSize: doc.data().Print_Date_Size || "",
                  printDateBold: doc.data().Print_Date_Bold || "",

                  printFooterDisplay: doc.data().Print_Footer_Display != undefined ? doc.data().Print_Footer_Display : false,
                  printFooterText: doc.data().Print_Footer_Text || "",
                  printFooterSize: doc.data().Print_Footer_Size || "",

                  printFooterBackground: doc.data().Print_Footer_Background || "",
                  printFooterColor: doc.data().Print_Footer_Text_Color || "",
                  printFooterFont: doc.data().Print_Footer_Font || "",
                  printFooterTextSize: doc.data().Print_Footer_Text_Size || "",

                  // -------------------------
                  // sub_location_id: doc.data().Sub_Location_ID,
                  // show_location_icon: doc.data().Show_Location_Icon,
                  // footer_text: doc.data().Footer_Text,
                  // button_layout_format: doc.data().Button_Layout_Format,
                  // enable_print_logo: doc.data().Enable_Print_Logo,
                  // print_type: one,
                  // dispenser_name: doc.data().Name,
                  // height: doc.data().Layout_Height,
                  // width: doc.data().Layout_Width,
                  // pin: doc.data().Pin,
                  // backgroundUrl: doc.data()?.Background_Url || '',
                  // logoPadding: doc.data()?.Logo_Padding || state.logoPadding,
                  // servicesMargin: doc.data()?.Services_Margin || '',
                  // servicesRadius: doc.data()?.Services_Radius || '',
                  // servicesSize: doc.data()?.Services_Size || '',
                  // defaultFont: doc.data()?.Default_Font || '',
                  // backgroundColor: doc.data()?.Background_Color || '',
                  // servicesBackground: doc.data()?.Services_Background || '',
                  // servicesName: doc.data()?.Services_Name || true,
                  // servicesTextColor: doc.data()?.Services_Text_Color || '',
                  // servicesFont: doc.data()?.Services_Font || '',
                  // servicesFontSize: doc.data()?.Services_Font_Size || '',
                  // servicesIconSize: doc.data()?.Services_Icon_Size || '',
                  // servicesIcon: doc.data()?.Services_Icon || true,

                  // servicesDetails: doc.data()?.Services_Details || true,
                  // servicesDetailsColor:
                  //   doc.data()?.Services_Details_Color || '',
                  // servicesDetailsFont: doc.data()?.Services_Details_Font || '',
                  // servicesDetailsFontSize:
                  //   doc.data()?.Services_Details_Font_Size || '',

                  // dateDisplay: doc.data()?.Date_Display || true,
                  // dateBackground: doc.data()?.Date_Background || '',
                  // dateColor: doc.data()?.Date_Color || '',
                  // dateFont: doc.data()?.Date_Font || '',
                  // dateFontSize: doc.data()?.Date_Font_Size || '',
                  // dateSize: doc.data()?.Date_Size || '',

                  // footerDisplay: doc.data()?.Footer_Display || true,
                  // footerBackground: doc.data()?.Footer_Background || '',
                  // footerColor: doc.data()?.Footer_Color || '',
                  // footerFont: doc.data()?.Footer_Font || '',
                  // footerFontSize: doc.data()?.Footer_Font_Size || '',
                  // footerSize: doc.data()?.Footer_Size || ''
                });

                printLogo.current.handleSetUrl(doc.data().Print_Logo_Url || "");

                // ---------- Load Sub Location Data ---------- //
                Firebase.firestore()
                  .collection("Sub_Locations")
                  .doc(doc.data().Sub_Location_ID)
                  .get()
                  .then(function (sub_location) {
                    if (sub_location.exists) {
                      setState({
                        location_image_url: sub_location.data().Icon,
                        location_name: sub_location.data().Name,
                        location_address: sub_location.data().Address,
                      });
                      // loadServiceListBySub(doc.data().Sub_Location_ID)
                    } else {
                      setState({ loading: false });
                      notifyMessage("tc", 3, "Network error1!");
                    }
                  })
                  .catch(function (err) {
                    setState({ loading: false });
                    notifyMessage("tc", 3, "Network error2!");
                  });
              } else {
                setState({ loading: false });
                notifyMessage("tc", 3, "Network error!3");
              }
            })
            .catch(function (err) {
              setState({ loading: false });
              notifyMessage("tc", 3, "Network error4!");
            });
        } else {
          setState({ loading: false });
          notifyMessage("tc", 3, "Network error!5");
        }
      })
      .catch(function (err) {
        setState({ loading: false });
        notifyMessage("tc", 3, "Network error!6");
      });
  }
  // function loadServiceListBySub (sub_id) {
  //   setState({ loading: true })
  //   let services = []
  //   Firebase.firestore()
  //     .collection('Services')
  //     .where('Sub_Location_ID', '==', sub_id)
  //     .get()
  //     .then(function (response) {
  //       response.docs.forEach(function (doc) {
  //         services.push({
  //           service_id: doc.id,
  //           service_name: doc.data().Name,
  //           service_details: doc.data().Details,
  //           icon: doc.data()?.Icon || ''
  //         })
  //       })

  //       setState({ service_list: services })
  //       setState({ loading: false })
  //     })
  //     .catch(function (err) {
  //       setState({ loading: false })
  //       notifyMessage('tc', 3, 'Network error!')
  //     })
  // }
  function getCurrentTime() {
    let now = new Date();
    setState({ current_time: now });
    window.setTimeout(function () {
      getCurrentTime();
    }, 500);
  }
  async function handleSave() {
    if (state.dispenser_nameState === "") {
      setState({ dispenser_nameState: "has-danger" });
    }

    if (state.pinState === "") {
      setState({ pinState: "has-danger" });
    }

    if (state.dispenser_nameState === "has-success" && state.pinState === "has-success" && !state.invalid_height && !state.invalid_width) {
      let res = "",
        backgroundUrl1 = "",
        update_dispenser_data = {};

      let now = new Date();
      setState({ loading: true });

      if (state.printLogo) {
        let file = printLogo.current.state.file;
        if (file !== null) {
          var storageRef = Firebase.storage().ref();
          var image_name = "Media_Gallery/Kiosk_Media/" + state.dispenser_id + "/printLogo-1";
          var subLocationRef = storageRef.child(image_name);
          let snapshot = await subLocationRef.put(file);
          // .then(function (snapshot) {
          res = await subLocationRef.getDownloadURL();
          // .then(function (res) {

          // })
          // .catch(function (err) {
          //   setState({ loading: false })
          //   notifyMessage('tc', 3, 'Network error!')
          // })
          // })
          // .catch(function (err) {
          //   setState({ loading: false })
          //   notifyMessage('tc', 3, 'Network error!')
          // })
        }
        // else {
        //   setState({ loading: false })
        //   notifyMessage('tc', 3, 'You must upload the print logo image.')
        // }
      }
      if (background.current?.files?.length > 0) {
        let file = background.current.files[0];
        if (file !== null) {
          var storageRef = Firebase.storage().ref();
          var image_name = "Media_Gallery/Kiosk_Media/" + state.dispenser_id + "/background";
          var subLocationRef = storageRef.child(image_name);
          let snapshot = await subLocationRef.put(file);
          // .then(function (snapshot) {
          backgroundUrl1 = await subLocationRef.getDownloadURL();
          // .then(function (res) {

          // })
          // .catch(function (err) {
          //   setState({ loading: false })
          //   notifyMessage('tc', 3, 'Network error!')
          // })
          // })
          // .catch(function (err) {
          //   setState({ loading: false })
          //   notifyMessage('tc', 3, 'Network error!')
          // })
        } else {
          setState({ loading: false });
          notifyMessage("tc", 3, "You must upload the print logo image.");
        }
      }

      update_dispenser_data = {
          PrinterIp:state.printerIp,
          PrinterPort:state.printerPort,
        mainLocFont_Font: state.mainLocFontFont,
        subLocFont_Font: state.subLocFontFont,
        address_Font: state.addressFont,
        text1_Font: state.text1Font,
        text2_Font: state.text2Font,
        serviceName_Font: state.serviceNameFont,
        serviceDetails_Font: state.serviceDetailsFont,
        token_Font: state.tokenFont,
        tokensAhead_Font: state.tokensAheadFont,
        qrCode_Font: state.qrCodeFont,
        printDate_Font: state.printDateFont,

        Label_Date_Format: state.dateFormat,

        Print_Logo: state.printLogo,
        Print_Logo_Url: res || state.printLogoUrl,
        Print_Logo_Size: state.printLogoSize,
        MainLocation_Enable: state.mainLocationEnable,
        MainLocation_Font_Size: state.mainLocFontSize,
        MainLocation_Bold: state.mainLocBold,
        SubLocation_Enable: state.subLocationEnable,
        SubLocation_Font_Size: state.subLocFontSize,
        SubLocation_Bold: state.subLocBold,
        Address_Enable: state.addressEnable,
        Address_Size: state.addressSize,
        Address_Bold: state.addressBold,

        Text1_Enable: state.text1Enable,
        Text1: state.text1,
        Text1_Size: state.text1Size,
        Text1_Bold: state.text1Bold,

        Text2_Enable: state.text2Enable,
        Text2: state.text2,
        Text2_Size: state.text2Size,
        Text2_Bold: state.text2Bold,
        Print_Header_Bottom: state.printHeaderBottom,

        Service_Name: state.serviceName,
        Service_Name_Size: state.serviceNameSize,
        Service_Name_Bold: state.serviceNameBold,

        Service_Details: state.serviceDetails,
        Service_Details_Size: state.serviceDetailsSize,
        Service_Details_Bold: state.serviceDetailsBold,
        Print_Service_Padding: state.printServicePadding,

        Token_Size: state.tokenSize,
        Token_Bold: state.tokenBold,
        Tokens_Ahead: state.tokenAhead,
        Tokens_Ahead_Size: state.tokensAheadSize,
        Tokens_Ahead_Bold: state.tokensAheadBold,

        Qr_Code: state.qrCode,
        Qr_Code_Size: state.qrCodeSize,
        Token_Bottom_Padding: state.tokenBottomPadding,

        Print_Date_Display: state.printDateDisplay,
        Print_Date_Size: state.printDateSize,
        Print_Date_Bold: state.printDateBold,

        Print_Footer_Display: state.printFooterDisplay,
        Print_Footer_Text: state.printFooterText,
        Print_Footer_Size: state.printFooterSize,

        Print_Footer_Background: state.printFooterBackground,
        Print_Footer_Text_Color: state.printFooterColor,
        Print_Footer_Font: state.printFooterFont,
        Print_Footer_Text_Size: state.printFooterTextSize,

        // //--------
        // Show_Location_Icon: state.show_location_icon,
        // Footer_Text: state.footer_text,
        // Button_Layout_Format: state.button_layout_format,
        // Enable_Print_Logo: state.enable_print_logo,
        // Print_Type: state?.print_type?.value || '',
        // Name: state.dispenser_name,
        // Layout_Height: state.height,
        // Layout_Width: state.width,
        // Pin: parseInt(state.pin),
        // Updated_Date: now,
        // Background_Url: state.backgroundUrl || backgroundUrl1,
        // Logo_Padding: state?.logoPadding || {},
        // Services_Margin: state.servicesMargin || '',
        // Services_Radius: state.servicesRadius || '',
        // Services_Size: state.servicesSize || '',
        // Default_Font: state.defaultFont || '',
        // Background_Color: state.backgroundColor || '',
        // Services_Background: state.servicesBackground || '',

        // Services_Name: state.servicesName || true,
        // Services_Text_Color: state.servicesTextColor || '',
        // Services_Font: state.servicesFont || '',
        // Services_Font_Size: state.servicesFontSize || '',

        // Services_Icon_Size: state.servicesIconSize || '',
        // Services_Icon: state.servicesIcon || true,

        // Services_Details: state.servicesDetails || true,
        // Services_Details_Color: state.servicesDetailsColor || '',
        // Services_Details_Font: state.servicesDetailsFont || '',
        // Services_Details_Font_Size: state.servicesDetailsFontSize || '',

        // Date_Display: state.dateDisplay || true,
        // Date_Background: state.dateBackground || '',
        // Date_Color: state.dateColor || '',
        // Date_Font: state.dateFont || '',
        // Date_Font_Size: state.dateFontSize || '',
        // Date_Size: state.dateSize || '',

        // Footer_Display: state.footerDisplay || true,
        // Footer_Background: state.footerBackground || '',
        // Footer_Color: state.footerColor || '',
        // Footer_Font: state.footerFont || '',
        // Footer_Font_Size: state.footerFontSize || '',
        // Footer_Size: state.footerSize || ''
      };

      Firebase.firestore()
        .collection("Kiosk")
        .doc(state.dispenser_id)
        .update(update_dispenser_data)
        .then(function () {
          setState({ loading: false });
          notifyMessage("tc", 2, "Print Label saved successfully.");
          window.setTimeout(function () {
            props.history.push("/Kiosks");
          }, 2000);
        })
        .catch(function (error) {
          setState({ loading: false });
          notifyMessage("tc", 3, "Network error!12");
        });
    }
  }
  const change = (event, stateName, type, stateNameEqualTo) => {
    switch (type) {
      case "length":
        if (verifyLength(event.target.value, stateNameEqualTo)) {
          setState({ [stateName + "State"]: "has-success" });
        } else {
          setState({ [stateName + "State"]: "has-danger" });
        }

        setState({ [stateName]: event.target.value });
        break;
      case "pin":
        if (verifyLength(event.target.value, stateNameEqualTo) && verifyNumber(event.target.value)) {
          setState({ [stateName + "State"]: "has-success" });
        } else {
          setState({ [stateName + "State"]: "has-danger" });
        }

        if (verifyNumber(event.target.value) || event.target.value.length === 0) {
          setState({ [stateName]: event.target.value });
        }

        break;
      default:
        break;
    }
  };
  function getServices() {
    return (
      <>
        {/* location */}
        {(state.printLogo || state.mainLocationEnable || state.subLocationEnable || state.addressEnable || state.text1Enable || state.text2Enable) && (
          <Row className="justify-content-center" style={{ paddingBottom: state.printHeaderBottom + "px" }}>
            <Col>
              {state.printLogo && (
                <Row className="justify-content-center p-1 align-self-center text-center">
                  <img
                    className="mw-auto"
                    style={{
                      width: state.printLogoSize + "px",
                      maxWidth: "initial",
                    }}
                    src={state.printLogoUrl || (printLogo.current?.state?.file && URL.createObjectURL(printLogo.current.state.file))}
                  />
                </Row>
              )}
              {state.mainLocationEnable && (
                <Row className="justify-content-center p-1 align-self-center text-center">
                  <span
                    className="overflow-ellipsis "
                    style={{
                      fontSize: state.mainLocFontSize + "rem",
                      fontFamily: state.mainLocFontFont,
                      fontWeight: state.mainLocBold ? "bold" : "normal",
                    }}>
                    {state.main_location}
                  </span>
                </Row>
              )}
              {state.subLocationEnable && (
                <Row className="justify-content-center p-1 align-self-center text-center">
                  <span
                    className="overflow-ellipsis "
                    style={{
                      fontSize: state.subLocFontSize + "rem",
                      fontFamily: state.subLocFontFont,
                      fontWeight: state.subLocBold ? "bold" : "normal",
                    }}>
                    {state.sub_location}
                  </span>
                </Row>
              )}
              {state.addressEnable && (
                <Row className="justify-content-center p-1 align-self-center text-center">
                  <span
                    className="overflow-ellipsis "
                    style={{
                      fontSize: state.addressSize + "rem",
                      fontFamily: state.addressFont,
                      fontWeight: state.addressBold ? "bold" : "normal",
                    }}>
                    **sub location Address**
                  </span>
                </Row>
              )}
              {state.text1Enable && (
                <Row className="justify-content-center p-1 align-self-center text-center">
                  <span
                    className="overflow-ellipsis"
                    style={{
                      fontSize: state.text1Size + "rem",
                      fontFamily: state.text1Font,
                      fontWeight: state.text1Bold ? "bold" : "normal",
                    }}>
                    {state.text1}
                  </span>
                </Row>
              )}
              {state.text2Enable && (
                <Row className="justify-content-center p-1 align-self-center text-center">
                  <span
                    className="overflow-ellipsis"
                    style={{
                      fontSize: state.text2Size + "rem",
                      fontFamily: state.text2Font,
                      fontWeight: state.text2Bold ? "bold" : "normal",
                    }}>
                    {state.text2}
                  </span>
                </Row>
              )}
            </Col>
          </Row>
        )}
        {/* service */}
        {(state.serviceName || state.serviceDetails) && (
          <Row className="justify-content-center" style={{ padding: state.printServicePadding + "px 0" }}>
            <Col>
              {state.serviceName && (
                <Row className="justify-content-center p-1 align-self-center text-center">
                  <span
                    className="overflow-ellipsis "
                    style={{
                      fontSize: state.serviceNameSize + "rem",
                      fontFamily: state.serviceNameFont,
                      fontWeight: state.serviceNameBold ? "bold" : "normal",
                    }}>
                    **Service Name**
                  </span>
                </Row>
              )}
              {state.serviceDetails && (
                <Row className="justify-content-center p-1 align-self-center text-center">
                  <span
                    className="overflow-ellipsis "
                    style={{
                      fontSize: state.serviceDetailsSize + "rem",
                      fontFamily: state.serviceDetailsFont,
                      fontWeight: state.serviceDetailsBold ? "bold" : "normal",
                      padding: "0 " + state.servicePadding + "px",
                    }}>
                    **Service Details**
                  </span>
                </Row>
              )}
            </Col>
          </Row>
        )}
        {/* token */}
        <Row className="justify-content-center" style={{ paddingBottom: state.tokenBottomPadding + "px" }}>
          <Col>
            <Row className="justify-content-center p-1 align-self-center text-center">
              <span
                className="overflow-ellipsis "
                style={{
                  fontSize: state.tokenSize + "rem",
                  fontFamily: state.tokenFont,
                  fontWeight: state.tokenBold ? "bold" : "normal",
                }}>
                **Token Number**
              </span>
            </Row>
            {state.tokenAhead && (
              <Row className="justify-content-center p-1 align-self-center text-center">
                <span
                  className="overflow-ellipsis "
                  style={{
                    fontSize: state.tokensAheadSize + "rem",
                    fontFamily: state.tokensAheadFont,
                    fontWeight: state.tokensAheadBold ? "bold" : "normal",
                  }}>
                  0 Tokens Ahead of you
                </span>
              </Row>
            )}
            {state.qrCode && false && (
              <Row className="justify-content-center p-1 align-self-center text-center">
                <img
                  className="mw-auto"
                  style={{
                    width: state.qrCodeSize + "px",
                    maxWidth: "initial",
                  }}
                  src={"https://upload.wikimedia.org/wikipedia/commons/7/76/Ar.wikipedia.comQRcode.png"}
                />
              </Row>
            )}
          </Col>
        </Row>
        {(state.printDateDisplay || state.printFooterDisplay) && (
          <Row className="justify-content-center" style={{ margin: state.servicesMargin + "px 0" }}>
            <Col>
              {state.printDateDisplay && (
                <Row className="justify-content-center p-1 align-self-center text-center">
                  <span
                    className="overflow-ellipsis "
                    style={{
                      fontSize: state.printDateSize + "rem",
                      fontFamily: state.printDateFont,
                      fontWeight: state.printDateBold ? "bold" : "normal",
                    }}>
                    {dateFormat(state.current_time, state.dateFormat)}
                  </span>
                </Row>
              )}
              {state.printFooterDisplay && (
                <Row className="justify-content-center p-1 align-self-center text-center">
                  <span
                    className="overflow-ellipsis "
                    style={{
                      // height: state.printFooterSize + 'px'||'auto',
                      fontSize: state.printFooterTextSize + "rem",
                      // fontFamily: state.printFooterTextFont,
                      // background:state.printFooterBackground,
                      // color:state.printFooterColor,
                      fontFamily: state.printFooterFont,
                    }}>
                    {state.printFooterText}
                  </span>
                </Row>
              )}
            </Col>
          </Row>
        )}
      </>
    );
  }
  const notifyMessage = (place, color, text) => {
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }

    var options = {};
    options = {
      place: place,
      message: (
        <div className="text-md-center">
          <div>
            <b>{text}</b>
          </div>
        </div>
      ),
      type: type,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 3,
    };
    if (options !== null) {
      notificationAlert.current.notificationAlert(options);
    }
  };
  const verifyLength = (value, length) => {
    return value.length >= length;
  };
  const verifyNumber = (value) => {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  };
  // render () {
  let { dispenser_nameState, pinState } = state;
  return (
    <>
      <LoadingOverlay active={state.loading} spinner text="Loading" className="content">
        <NotificationAlert ref={notificationAlert} />
        {state.colorPick ? (
          <div
            style={{
              position: "absolute",
              top: "20%",
              left: "35%",
              zIndex: "2000",
            }}>
            <ColorPicker
              onChange={(e) => {
                setState({ [state.colorPlace]: e.hex });
              }}
            />
          </div>
        ) : (
          ""
        )}
        <Row
          onClick={() => {
            if (state.colorPick)
              setState({
                colorPick: false,
              });
          }}>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Print Label Edit</CardTitle>
              </CardHeader>
              <CardBody>
                <Row className="w-75">
                  <Col lg="1" xs="1"></Col>
                  <Col lg="4" xs="5">
                    <Button color="success" onClick={(e) => handleSave()} block>
                      Save
                    </Button>
                  </Col>
                  <Col lg="4" xs="5">
                    <Button color="youtube" onClick={(e) => props.history.push("/Kiosks")} block>
                      Back
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col lg="7" xs="12" className="border-right" style={{ height: "100vh", overflow: "auto" }}>
                    {/* <Card className='border'>
                      <CardBody>
                        <Row>
                          <Col md='8' xs='8'>
                            <h6>General Screen Settings</h6>
                          </Col>
                          <Col md='4' xs='4' className='text-right'>
                            <Switch
                              className='left-margin-30'
                              defaultValue={state.show_location_icon}
                              value={state.show_location_icon}
                              offColor='success'
                              offText='OFF'
                              onColor='success'
                              onText='ON'
                              onChange={event =>
                                setState({
                                  show_location_icon: event.state.value
                                })
                              }
                            />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card> */}
                    {/* <Card className="">
                      <CardHeader>General</CardHeader>
                      <CardBody>
                        <Row>
                          <Label xs="6" className=''>
                            Printer Details
                          </Label>
                        </Row>
                        <Row>
                          <Label lg="2" xs="3" sm="4">
                            IP Address
                          </Label>
                          <Col>
                            <Input
                              value={state.printerIp}
                              type="text"
                              onChange={(e) => {
                                setState({
                                  printerIp: e.target.value,
                                });
                              }}
                            />
                          </Col>
                          <Label lg="2" xs="3" sm="4">
                            Port Number
                          </Label>
                          <Col>
                            <Input
                              value={state.printerPort}
                              type="text"
                              onChange={(e) => {
                                setState({
                                  printerPort: e.target.value,
                                });
                              }}
                            />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card> */}
                    <Card>
                      <CardHeader>Header</CardHeader>
                      <CardBody>
                        <Row className="my-3">
                          <Label xs="4">Logo Image</Label>
                          <Col>
                            <Switch
                              value={state.printLogo}
                              // offColor="success"
                              // offText="OFF"
                              // onColor="success"
                              // onText="ON"
                              onChange={(e) => setState({ printLogo: e.state.value })}
                            />
                          </Col>
                        </Row>
                        <Row className="my-3">
                          <Label xs="4"></Label>
                          <Col>
                            <Row className="align-items-center">
                              <Col xs="3" sm="4">
                                Header Image
                              </Col>
                              <Col>
                                <IconUpload ref={printLogo} />
                              </Col>
                            </Row>
                            <Row className="my-3">
                              <Label xs="4">Size</Label>
                              <Col>
                                <Input
                                  type="range"
                                  value={Number(state.printLogoSize)}
                                  min="0"
                                  max="500"
                                  onChange={(e) =>
                                    setState({
                                      printLogoSize: e.target.value,
                                    })
                                  }
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row className="my-3">
                          <Label xs="4">Main Location</Label>
                          <Col>
                            <Switch
                              value={state.mainLocationEnable}
                              // offColor="success"
                              // offText="OFF"
                              // onColor="success"
                              // onText="ON"
                              onChange={(e) => setState({ mainLocationEnable: e.state.value })}
                            />
                          </Col>
                        </Row>
                        <Row className="my-3">
                          <Label xs="4"></Label>
                          <Col>
                            {/* <Row className='my-3'>
                              <Label xs='4'>Font</Label>
                              <Col xs='6'>
                                <Input
                                  type='text'
                                  value={state.mainLocFontFont}
                                  min='0'
                                  max='500'
                                  onChange={e =>
                                    setState({
                                      mainLocFontFont: e.target.value
                                    })
                                  }
                                />
                              </Col>
                            </Row> */}
                            <Row className="my-3">
                              <Label xs="4">Font Size</Label>
                              <Col xs="6">
                                <Input
                                  type="range"
                                  value={Number(state.mainLocFontSize)}
                                  min="1"
                                  max="8"
                                  onChange={(e) =>
                                    setState({
                                      mainLocFontSize: e.target.value,
                                    })
                                  }
                                />
                              </Col>
                            </Row>
                            <Row className="my-3">
                              <Label xs="4">Bold Text</Label>
                              <Col>
                                <Switch
                                  value={state.mainLocBold}
                                  // offColor="success"
                                  // offText="OFF"
                                  // onColor="success"
                                  // onText="ON"
                                  onChange={(e) => setState({ mainLocBold: e.state.value })}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row className="my-3">
                          <Label xs="4">Sub Location</Label>
                          <Col>
                            <Switch
                              value={state.subLocationEnable}
                              // offColor="success"
                              // offText="OFF"
                              // onColor="success"
                              // onText="ON"
                              onChange={(e) => setState({ subLocationEnable: e.state.value })}
                            />
                          </Col>
                        </Row>
                        <Row className="my-3">
                          <Label xs="4"></Label>
                          <Col>
                            {/* <Row className='my-3'>
                              <Label xs='4'>Font</Label>
                              <Col>
                                <Input
                                  type='text'
                                  value={state.subLocFontFont}
                                  min='0'
                                  max='500'
                                  onChange={e =>
                                    setState({ subLocFontFont: e.target.value })
                                  }
                                />
                              </Col>
                            </Row> */}
                            <Row className="my-3">
                              <Label xs="4">Font Size</Label>
                              <Col>
                                <Input type="range" value={Number(state.subLocFontSize)} min="1" max="8" onChange={(e) => setState({ subLocFontSize: e.target.value })} />
                              </Col>
                            </Row>
                            <Row className="my-3">
                              <Label xs="4">Bold Text</Label>
                              <Col>
                                <Switch
                                  value={state.subLocBold}
                                  // offColor="success"
                                  // offText="OFF"
                                  // onColor="success"
                                  // onText="ON"
                                  onChange={(e) => setState({ subLocBold: e.state.value })}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row className="my-3">
                          <Label xs="4">Address</Label>
                          <Col>
                            <Switch
                              value={state.addressEnable}
                              // offColor="success"
                              // offText="OFF"
                              // onColor="success"
                              // onText="ON"
                              onChange={(e) => setState({ addressEnable: e.state.value })}
                            />
                          </Col>
                        </Row>
                        <Row className="my-3">
                          <Label xs="4"></Label>
                          <Col>
                            {/* <Row className='my-3'>
                              <Label xs='4'>Font</Label>
                              <Col>
                                <Input
                                  type='text'
                                  value={state.addressFont}
                                  min='0'
                                  max='500'
                                  onChange={e =>
                                    setState({ addressFont: e.target.value })
                                  }
                                />
                              </Col>
                            </Row>*/}
                            <Row className="my-3">
                              <Label xs="4">Font Size</Label>
                              <Col>
                                <Input type="range" value={Number(state.addressSize)} min="1" max="8" onChange={(e) => setState({ addressSize: e.target.value })} />
                              </Col>
                            </Row>
                            <Row className="my-3">
                              <Label xs="4">Bold Text</Label>
                              <Col>
                                <Switch
                                  value={state.addressBold}
                                  // offColor="success"
                                  // offText="OFF"
                                  // onColor="success"
                                  // onText="ON"
                                  onChange={(e) => setState({ addressBold: e.state.value })}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row className="my-3">
                          <Label xs="4">Custom Text 1</Label>
                          <Col>
                            <Switch
                              value={state.text1Enable}
                              // offColor="success"
                              // offText="OFF"
                              // onColor="success"
                              // onText="ON"
                              onChange={(e) => setState({ text1Enable: e.state.value })}
                            />
                          </Col>
                        </Row>
                        <Row className="my-3">
                          <Label xs="4"></Label>
                          <Col>
                            <Row>
                              <Label xs="4">Text</Label>
                              <Col>
                                <Input
                                  value={state.text1}
                                  type="text"
                                  onChange={(e) => {
                                    setState({
                                      text1: e.target.value,
                                    });
                                  }}
                                />
                              </Col>
                            </Row>
                            {/* <Row className='my-3'>
                              <Label xs='4'>Font</Label>
                              <Col>
                                <Input
                                  type='text'
                                  value={state.text1Font}
                                  min='0'
                                  max='500'
                                  onChange={e =>
                                    setState({ text1Font: e.target.value })
                                  }
                                />
                              </Col>
                            </Row>*/}
                            <Row className="my-3">
                              <Label xs="4">Font Size</Label>
                              <Col>
                                <Input type="range" value={Number(state.text1Size)} min="1" max="8" onChange={(e) => setState({ text1Size: e.target.value })} />
                              </Col>
                            </Row>
                            <Row className="my-3">
                              <Label xs="4">Bold Text</Label>
                              <Col>
                                <Switch
                                  value={state.text1Bold}
                                  // offColor="success"
                                  // offText="OFF"
                                  // onColor="success"
                                  // onText="ON"
                                  onChange={(e) => setState({ text1Bold: e.state.value })}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row className="my-3">
                          <Label xs="4">Custom Text 2</Label>
                          <Col>
                            <Switch
                              value={state.text2Enable}
                              // offColor="success"
                              // offText="OFF"
                              // onColor="success"
                              // onText="ON"
                              onChange={(e) => setState({ text2Enable: e.state.value })}
                            />
                          </Col>
                        </Row>
                        <Row className="my-3">
                          <Label xs="4"></Label>
                          <Col>
                            <Row>
                              <Label xs="4">Text</Label>
                              <Col>
                                <Input
                                  value={state.text2}
                                  type="text"
                                  onChange={(e) => {
                                    setState({
                                      text2: e.target.value,
                                    });
                                  }}
                                />
                              </Col>
                            </Row>
                            {/* <Row className='my-3'>
                              <Label xs='4'>Font</Label>
                              <Col>
                                <Input
                                  type='text'
                                  value={state.text2Font}
                                  min='0'
                                  max='500'
                                  onChange={e =>
                                    setState({ text2Font: e.target.value })
                                  }
                                />
                              </Col>
                            </Row>*/}
                            <Row className="my-3">
                              <Label xs="4">Font Size</Label>
                              <Col>
                                <Input type="range" value={Number(state.text2Size)} min="1" max="8" onChange={(e) => setState({ text2Size: e.target.value })} />
                              </Col>
                            </Row>
                            <Row className="my-3">
                              <Label xs="4">Bold Text</Label>
                              <Col>
                                <Switch
                                  value={state.text2Bold}
                                  // offColor="success"
                                  // offText="OFF"
                                  // onColor="success"
                                  // onText="ON"
                                  onChange={(e) => setState({ text2Bold: e.state.value })}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row className="my-3">
                          <Label xs="4">Bottom Padding</Label>
                          <Col>
                            <Input type="number" value={Number(state.printHeaderBottom)} min="0" max="500" onChange={(e) => setState({ printHeaderBottom: e.target.value })} />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <Card>
                      <CardHeader>Token</CardHeader>
                      <CardBody>
                        {/* service Name */}
                        <Row className="my-3">
                          <Label xs="4">Service Name</Label>
                          <Col>
                            <Switch
                              value={state.serviceName}
                              // offColor="success"
                              // offText="OFF"
                              // onColor="success"
                              // onText="ON"
                              onChange={(e) => setState({ serviceName: e.state.value })}
                            />
                          </Col>
                        </Row>
                        <Row className="my-3">
                          <Label xs="4"></Label>
                          <Col>
                            {/* <Row className='my-3'>
                              <Label xs='4'>Font</Label>
                              <Col>
                                <Input
                                  type='text'
                                  value={state.serviceNameFont}
                                  min='0'
                                  max='500'
                                  onChange={e =>
                                    setState({
                                      serviceNameFont: e.target.value
                                    })
                                  }
                                />
                              </Col>
                            </Row> */}
                            <Row className="my-3">
                              <Label xs="4">Font Size</Label>
                              <Col>
                                <Input
                                  type="range"
                                  value={Number(state.serviceNameSize)}
                                  min="1"
                                  max="8"
                                  onChange={(e) =>
                                    setState({
                                      serviceNameSize: e.target.value,
                                    })
                                  }
                                />
                              </Col>
                            </Row>
                            <Row className="my-3">
                              <Label xs="4">Bold Text</Label>
                              <Col>
                                <Switch
                                  value={state.serviceNameBold}
                                  // offColor="success"
                                  // offText="OFF"
                                  // onColor="success"
                                  // onText="ON"
                                  onChange={(e) => setState({ serviceNameBold: e.state.value })}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        {/* Service Details */}
                        <Row className="my-3">
                          <Label xs="4">Service Details</Label>
                          <Col>
                            <Switch
                              value={state.serviceDetails}
                              // offColor="success"
                              // offText="OFF"
                              // onColor="success"
                              // onText="ON"
                              onChange={(e) => setState({ serviceDetails: e.state.value })}
                            />
                          </Col>
                        </Row>
                        <Row className="my-3">
                          <Label xs="4"></Label>
                          <Col>
                            {/* <Row className='my-3'>
                              <Label xs='4'>Font</Label>
                              <Col>
                                <Input
                                  type='text'
                                  value={state.serviceDetailsFont}
                                  min='0'
                                  max='500'
                                  onChange={e =>
                                    setState({
                                      serviceDetailsFont: e.target.value
                                    })
                                  }
                                />
                              </Col>
                            </Row> */}
                            <Row className="my-3">
                              <Label xs="4">Font Size</Label>
                              <Col>
                                <Input
                                  type="range"
                                  value={Number(state.serviceDetailsSize)}
                                  min="1"
                                  max="8"
                                  onChange={(e) =>
                                    setState({
                                      serviceDetailsSize: e.target.value,
                                    })
                                  }
                                />
                              </Col>
                            </Row>
                            <Row className="my-3">
                              <Label xs="4">Bold Text</Label>
                              <Col>
                                <Switch
                                  value={state.serviceDetailsBold}
                                  // offColor="success"
                                  // offText="OFF"
                                  // onColor="success"
                                  // onText="ON"
                                  onChange={(e) =>
                                    setState({
                                      serviceDetailsBold: e.state.value,
                                    })
                                  }
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        {/* Padding */}
                        <Row className="my-3">
                          <Label xs="4">Service Padding</Label>
                          <Col xs="6">
                            <Input
                              type="number"
                              value={Number(state.printServicePadding)}
                              min="0"
                              max="500"
                              onChange={(e) =>
                                setState({
                                  printServicePadding: e.target.value,
                                })
                              }
                            />
                          </Col>
                        </Row>
                        {/* token number */}
                        <Row className="my-3">
                          <Label xs="4">Token Number</Label>
                        </Row>
                        <Row className="my-3">
                          <Label xs="4"></Label>
                          <Col>
                            {/* <Row className='my-3'>
                              <Label xs='4'>Font</Label>
                              <Col>
                                <Input
                                  type='text'
                                  value={state.tokenFont}
                                  min='0'
                                  max='500'
                                  onChange={e =>
                                    setState({
                                      tokenFont: e.target.value
                                    })
                                  }
                                />
                              </Col>
                            </Row> */}
                            <Row className="my-3">
                              <Label xs="4">Font Size</Label>
                              <Col>
                                <Input
                                  type="range"
                                  value={Number(state.tokenSize)}
                                  min="1"
                                  max="8"
                                  onChange={(e) =>
                                    setState({
                                      tokenSize: e.target.value,
                                    })
                                  }
                                />
                              </Col>
                            </Row>
                            <Row className="my-3">
                              <Label xs="4">Bold Text</Label>
                              <Col>
                                <Switch
                                  value={state.tokenBold}
                                  // offColor="success"
                                  // offText="OFF"
                                  // onColor="success"
                                  // onText="ON"
                                  onChange={(e) => setState({ tokenBold: e.state.value })}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        {/* token ahead */}
                        <Row className="my-3">
                          <Label xs="4">Tokens Ahead</Label>
                          <Col>
                            <Switch
                              value={state.tokenAhead}
                              // offColor="success"
                              // offText="OFF"
                              // onColor="success"
                              // onText="ON"
                              onChange={(e) => setState({ tokenAhead: e.state.value })}
                            />
                          </Col>
                        </Row>
                        <Row className="my-3">
                          <Label xs="4"></Label>
                          <Col>
                            {/* <Row className='my-3'>
                              <Label xs='4'>Font</Label>
                              <Col>
                                <Input
                                  type='text'
                                  value={state.tokensAheadFont}
                                  min='0'
                                  max='500'
                                  onChange={e =>
                                    setState({
                                      tokensAheadFont: e.target.value
                                    })
                                  }
                                />
                              </Col>
                            </Row> */}
                            <Row className="my-3">
                              <Label xs="4">Font Size</Label>
                              <Col>
                                <Input
                                  type="range"
                                  value={Number(state.tokensAheadSize)}
                                  min="1"
                                  max="8"
                                  onChange={(e) =>
                                    setState({
                                      tokensAheadSize: e.target.value,
                                    })
                                  }
                                />
                              </Col>
                            </Row>
                            <Row className="my-3">
                              <Label xs="4">Bold Text</Label>
                              <Col>
                                <Switch
                                  value={state.tokensAheadBold}
                                  // offColor="success"
                                  // offText="OFF"
                                  // onColor="success"
                                  // onText="ON"
                                  onChange={(e) => setState({ tokensAheadBold: e.state.value })}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        {/* qr code */}
                        <Row className="my-3">
                          <Label xs="4">QR Code</Label>
                          <Col>
                            <Switch
                              value={state.qrCode}
                              // offColor="success"
                              // offText="OFF"
                              // onColor="success"
                              // onText="ON"
                              onChange={(e) => setState({ qrCode: e.state.value })}
                            />
                          </Col>
                        </Row>
                        <Row className="my-3">
                          <Label xs="4"></Label>
                          <Col>
                            {/* <Row className='my-3'>
                              <Label xs='4'>Font</Label>
                              <Col>
                                <Input
                                  type='text'
                                  value={state.qrCodeFont}
                                  min='0'
                                  max='500'
                                  onChange={e =>
                                    setState({
                                      qrCodeFont: e.target.value
                                    })
                                  }
                                />
                              </Col>
                            </Row> */}
                            <Row className="my-3">
                              <Label xs="4">Size</Label>
                              <Col>
                                <Input
                                  type="range"
                                  value={Number(state.qrCodeSize)}
                                  min="0"
                                  max="500"
                                  onChange={(e) =>
                                    setState({
                                      qrCodeSize: e.target.value,
                                    })
                                  }
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        {/* Padding */}
                        <Row className="my-3">
                          <Label xs="4">Bottom Padding</Label>
                          <Col xs="6">
                            <Input type="number" value={Number(state.tokenBottomPadding)} min="0" max="500" onChange={(e) => setState({ tokenBottomPadding: e.target.value })} />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>

                    <Card className="border">
                      <CardHeader>
                        <h6>Footer</h6>
                      </CardHeader>
                      <CardBody>
                        {/* Date */}
                        <Row className="my-3">
                          <Label xs="4">Date Display</Label>
                          <Col>
                            <Switch
                              value={state.printDateDisplay}
                              // offColor="success"
                              // offText="OFF"
                              // onColor="success"
                              // onText="ON"
                              onChange={(e) => setState({ printDateDisplay: e.state.value })}
                            />
                          </Col>
                        </Row>
                        <Row className="my-3">
                          <Label xs="4"></Label>
                          <Col>
                            <Row className="my-3">
                              <Label xs="4">Format</Label>
                              <Col>
                                <CreatableSelect
                                  className="react-select info select-location"
                                  classNamePrefix="react-select"
                                  placeholder="Select Main Location"
                                  name="selectMainLocation"
                                  value={{ label: state.dateFormat, value: state.dateFormat }}
                                  onChange={(e) => {
                                    console.log(e);
                                    setState({ dateFormat: e.value });
                                  }}
                                  options={[
                                    { label: "Select Format", value: "" },
                                    { label: "d-m-yyyy", value: "d-m-yyyy" },
                                    { label: "d, m, yyyy, h:MM:ss TT", value: "d, m, yyyy, h:MM:ss TT" },
                                  ]}
                                />
                              </Col>
                            </Row>
                            {/* <Row className='my-3'>
                              <Label xs='4'>Font</Label>
                              <Col>
                                <Input
                                  type='text'
                                  value={state.printDateFont}
                                  min='0'
                                  max='500'
                                  onChange={e =>
                                    setState({
                                      printDateFont: e.target.value
                                    })
                                  }
                                />
                              </Col>
                            </Row> */}
                            <Row className="my-3">
                              <Label xs="4">Font Size</Label>
                              <Col>
                                <Input
                                  type="range"
                                  value={Number(state.printDateSize)}
                                  min="1"
                                  max="8"
                                  onChange={(e) =>
                                    setState({
                                      printDateSize: e.target.value,
                                    })
                                  }
                                />
                              </Col>
                            </Row>
                            <Row className="my-3">
                              <Label xs="4">Bold Text</Label>
                              <Col>
                                <Switch
                                  value={state.printDateBold}
                                  // offColor="success"
                                  // offText="OFF"
                                  // onColor="success"
                                  // onText="ON"
                                  onChange={(e) => setState({ printDateBold: e.state.value })}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        {/* footer text */}
                        <Row className="my-3">
                          <Label xs="4">Footer Text</Label>
                          <Col>
                            <Switch
                              value={state.printFooterDisplay}
                              // offColor="success"
                              // offText="OFF"
                              // onColor="success"
                              // onText="ON"
                              onChange={(e) => setState({ printFooterDisplay: e.state.value })}
                            />
                          </Col>
                        </Row>
                        <Row className="my-3">
                          <Label xs="4"></Label>
                          <Col>
                            {/* text */}
                            <Row>
                              <Label xs="4">Text</Label>
                              <Col>
                                <textarea
                                  value={state.printFooterText}
                                  // type='text'
                                  onChange={(e) => {
                                    setState({
                                      printFooterText: e.target.value,
                                    });
                                    console.log(e.target.value);
                                  }}></textarea>
                              </Col>
                            </Row>
                            {/* size */}

                            {/* <Row className='my-3'>
                              <Label xs='4'>Font Size</Label>
                              <Col>
                                <Input
                                  type='range'
                                  value={Number(state.printFooterSize)}
                                  min='1'
                                  max='8'
                                  onChange={e =>
                                    setState({
                                      printFooterSize: e.target.value
                                    })
                                  }
                                />
                              </Col>
                            </Row> */}

                            {/* <Row className='pb-2'>
                              <Label xs='4'>Background Color</Label>
                              <Col>
                                <div
                                  style={{
                                    background:
                                      state.printFooterBackground || 'white',
                                    width: '4vh',
                                    height: '4vh',
                                    verticalAlign: 'middle',
                                    borderRadius: '3px',
                                    display: 'inline-block',
                                    boxShadow: '0 0 3px 0px grey',
                                    margin: 'auto 5% auto 0'
                                  }}
                                  className='point1 btn'
                                  onClick={() => {
                                    setState({
                                      colorPick: !state.colorPick,
                                      colorPlace: 'printFooterBackground'
                                    })
                                  }}
                                ></div>
                              </Col>
                            </Row> */}
                            {/* <Row>
                              <Label xs='4'>Text Color</Label>
                              <Col>
                                <div
                                  style={{
                                    background: state.printFooterColor || 'white',
                                    width: '4vh',
                                    height: '4vh',
                                    verticalAlign: 'middle',
                                    borderRadius: '3px',
                                    display: 'inline-block',
                                    boxShadow: '0 0 3px 0px grey',
                                    margin: 'auto 5% auto 0'
                                  }}
                                  className='point1 btn'
                                  onClick={() => {
                                    setState({
                                      colorPick: !state.colorPick,
                                      colorPlace: 'printFooterColor'
                                    })
                                  }}
                                ></div>
                              </Col>
                            </Row> */}
                            {/* <Row className='my-3'>
                              <Label xs='4'>Font</Label>
                              <Col>
                                <Input
                                  type='text'
                                  value={state.printFooterFont}
                                  onChange={e =>
                                    setState({
                                      printFooterFont: e.target.value
                                    })
                                  }
                                />
                              </Col>
                            </Row>*/}
                            <Row className="my-3">
                              <Label xs="4">Font Size</Label>
                              <Col>
                                <Input
                                  type="range"
                                  value={Number(state.printFooterTextSize)}
                                  min="0"
                                  max="500"
                                  onChange={(e) =>
                                    setState({
                                      printFooterTextSize: e.target.value,
                                    })
                                  }
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    {/* <Card className='border'>
                        <CardBody>
                          <Row>
                            <Col lg='12'>
                              <h6>Logo and Printer</h6>
                            </Col>
                            <Col
                              md='8'
                              xs='8'
                              hidden={!state.enable_print_logo}
                            >
                            </Col>
                            <Col
                              md='8'
                              xs='8'
                              hidden={state.enable_print_logo}
                            />
                            <Col md='4' xs='4' className='text-right'>
                              <Switch
                                className='left-margin-30'
                                defaultValue={state.enable_print_logo}
                                value={state.enable_print_logo}
                                offColor='success'
                                offText='OFF'
                                onColor='success'
                                onText='ON'
                                onChange={event =>
                                  setState({
                                    enable_print_logo: event.state.value
                                  })
                                }
                              />
                            </Col>
                            <Col lg='12' className='top-margin-10' />
                            <Label lg='3' xs='3'>
                              Printer Type
                            </Label>
                            <Col lg='9' xs='9'>
                              <FormGroup>
                                <Select
                                  className='react-select info'
                                  classNamePrefix='react-select'
                                  placeholder='Select Print Type'
                                  name='selectPrintType'
                                  value={state.print_type}
                                  onChange={e =>
                                    setState({ print_type: e })
                                  }
                                  options={state.print_type_list}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card> */}
                  </Col>
                  <Col lg="5" xs="12">
                    <Card className="border">
                      <CardBody>
                        <Form className="form-horizontal">
                          <Row className="justify-content-center">
                            {/* <Col lg='1' xs='1' /> */}
                            <Col lg="11" xs="11">
                              <div
                                className="border card"
                                // style={{
                                //   background: `url(${state.backgroundUrl}) center center`,
                                //   backgroundSize: 'cover',
                                //   fontFamily: state.defaultFont,
                                //   backgroundColor: state.backgroundColor
                                // }}
                              >
                                <div style={{ paddingBottom: "174%" }}>
                                  <div className="w-100 h-100 padding-10 fixed-div">
                                    <div className="col-12 h-100">
                                      <Col className="d-flex h-100 justify-content-around  flex-column">{getServices()}</Col>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </LoadingOverlay>
    </>
  );
  // }
}

export default KioskPrintEdit;
