import React from "react";// reactstrap components
import LoadingOverlay from 'react-loading-overlay';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Label,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row
} from "reactstrap";

//import Spinner from 'react-spinkit';
//import { authRef } from '../reference';
import Firebase from "firebase";
import config from "../../config";

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);

    if (!Firebase.apps.length) {
      Firebase.initializeApp(config);
    }

    this.state = {
      email: null,
      error: '',
      password: '',
      success: false,
      validCode: null,
      verifiedCode: false,
      passwordState: '',
      confirmPasswordState: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleResetPassword = this.handleResetPassword.bind(this);
    this.focusElement = this.focusElement.bind(this);
  }

  componentDidMount() {
    console.log("running ResetPassword.jsx");
    // Verify the password reset code is valid.
    const authRef = Firebase.auth();
    authRef
      .verifyPasswordResetCode(this.props.actionCode)
      .then(email => {
        this.setState({ email, validCode: true, verifiedCode: true });
      }, error => {
        // Invalid or expired action code. Ask user to try to reset the password
        // again.
        this.setState({ error: error.message, validCode: false, verifiedCode: true });
        console.log({ action: "resetPassword", function: "componentDidMount", error: error.message, validCode: false, verifiedCode: true });
      });
  }

  handleResetPassword = (event) => {
    event.preventDefault();
    const { actionCode } = this.props;
    const newPassword = this.state.password;
    const confirmPassword = this.state.confirmPassword;

    if ((newPassword !== confirmPassword) || newPassword === "") {
      this.setState({ passwordState: 'has-danger' });
      this.setState({ confirmPasswordState: 'has-danger' });
    } else {
      this.setState({ passwordState: 'has-success' });
      this.setState({ confirmPasswordState: 'has-success' });

      //console.log("newPassword", newPassword, "confirmPassword", confirmPassword);
      // // Save the new password.
      const authRef = Firebase.auth();
      authRef
        .confirmPasswordReset(actionCode, newPassword)
        .then(() => {
          // Password reset has been confirmed and new password updated.
          this.setState({ success: true });
        }, error => {
          // Error occurred during confirmation. The code might have expired or the
          // password is too weak.
          this.setState({ error: error.message });
          console.log({ action: "resetPassword", function: "handleResetPassword", error: error.message });
        });
    }

  }

  handleChange = (event, stateName, type) => {
    switch (type) {
      case "password":
        if (this.verifyLength(event.target.value, 1)) {
          this.setState({ [stateName + "State"]: "has-success" });
          const { name, value } = event.target;
          this.setState({ [name]: value });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      case "confirmPassword":
        if (this.verifyLength(event.target.value, 1) && this.state.password === this.state.confirmPassword) {
          this.setState({ [stateName + "State"]: "has-success" });
          const { name, value } = event.target;
          this.setState({ [name]: value });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  };

  verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  focusElement(input) {
    input.focus(input);
  };
  render() {

    let appVersion = process.env.REACT_APP_VERSION;
    const {
      email,
      error,
      password,
      passwordState,
      confirmPassword,
      confirmPasswordState,
      success,
      validCode,
      verifiedCode,
    } = this.state;

    let wrapperFullPageContent, cardBodyContent;

    if (success) {
      // cardBodyContent = (
      //   <div className="row justify-content-center">
      //     <div className="col-md-9">
      //       <h3 style={{ color: '#fff' }} className="header text-center Margin-top--20">Password changed</h3>
      //       <h5 style={{ color: '#fff' }} className="header text-center Margin-top--20">You can now &nbsp;
      //         <a href="https://app.antqueue.com/login" rel="noopener noreferrer" style={{ color: '#fff' }}>
      //           <Button color="success" size="small" /* style={{ color: '#28a745' }} */ > Sign In </Button>
      //         </a>
      //         &nbsp; with your new password
      //       </h5>
      //     </div>
      //   </div >
      // );
      cardBodyContent = (
        <div className="row justify-content-center">
          <div className="col-md-9">
            <h3 style={{ color: '#fff' }} className="header text-center Margin-top--20">Password changed</h3>
            <h5 style={{ color: '#fff' }} className="header text-center Margin-top--20">You can now sign in with your new password</h5>
          </div>
        </div >
      );
    }
    else if (verifiedCode && validCode) {
      cardBodyContent = (
        <div className="row justify-content-center">
          <div className="col-md-9">
            <label style={{ color: '#fff' }}>New Password for {email}</label>
            <InputGroup className={`has-label ${passwordState}`}>
              <InputGroupAddon addonType="prepend">
                <InputGroupText style={{ padding: '10px 10px 10px 10px', backgroundColor: '#fff' }}>
                  <i className="nc-icon nc-email-85" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="New Password"
                name="password"
                type="password"
                onChange={e => this.handleChange(e, "password", "password")}
                onKeyDown={e => {
                  if (e.keyCode === 13) {
                    this.focusElement(this.refs['confirmPasswordRef']);
                  }
                }}
              />
              {this.state.passwordState === "has-danger" ? (
                <label className="error"> You must enter a new password. </label>
              ) : null}
            </InputGroup>
            <label style={{ color: '#fff' }}>Confirm New Password</label>
            <InputGroup className={`has-label ${confirmPasswordState}`}>
              <InputGroupAddon addonType="prepend">
                <InputGroupText style={{ padding: '10px 10px 10px 10px', backgroundColor: '#fff' }}>
                  <i className="nc-icon nc-key-25" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Confirm Password"
                type="password"
                name="confirmPassword"
                ref="confirmPasswordRef"
                autoComplete="off"
                onChange={e =>
                  this.handleChange(e, "confirmPassword", "password")
                }
                onKeyDown={e => {
                  if (e.keyCode === 13) {
                    this.handleResetPassword();
                  }
                }}
              />
              {this.state.confirmPasswordState === "has-danger" ? (
                <label className="error">This must be same as your new password.</label>
              ) : null}
            </InputGroup>
            <br />
            <div className="row">
              <div className="col-md-5">
                <Button
                  color="success"
                  onClick={this.handleResetPassword}
                  block
                >
                  Save
                </Button>
              </div>
            </div>
            <span style={{ color: '#ef476f' }} className="error">{error}</span>
          </div>
        </div>
      );
    }
    else if (verifiedCode && !validCode) {
      cardBodyContent = (
        <div className="row justify-content-center">
          <h3 style={{ color: '#fff' }} className="header text-center Margin-top--20">Try resetting your password again</h3>
          <h5 style={{ color: '#ef476f' }} className="header text-center Margin-top--20">{error}</h5>
        </div>
      );
    }

    wrapperFullPageContent = (
      <div className="wrapper wrapper-full-page" ref={(ref) => this.fullPages = ref}>
        <LoadingOverlay
          active={!verifiedCode}
          spinner
          text='Loading'
        >
          <div className="full-page section-image">
            <div className="login-page">
              <Container>
                <Row>
                  <Col className="ml-auto mr-auto" lg="7" md="6">
                    <Card style={{ backgroundColor: '#ffffff00', boxShadow: '0px 0px 0px 0px rgb(0 0 0 / 1%)' }} className="card-login">
                      <CardHeader>
                        <CardHeader>
                          <div className="header text-center">
                            {/* <img src={'/antqueue-animated-logo.gif'} alt={"animated-antqueue-logo"} />  */}
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                              <lottie-player src="/antqueue-animated-logo-lottie-data.json" background="transparent" speed="1" style={{ width: "200px", height: "200px" }} loop autoplay></lottie-player>
                            </div>
                          </div>
                          <h3 style={{ color: '#fff' }} className="header text-center Margin-top--20">AntQueue Password Reset</h3>
                        </CardHeader>
                      </CardHeader>
                      <CardBody>
                        {cardBodyContent}
                      </CardBody>
                      <CardFooter>
                        <div style={{ color: '#fff', fontSize: '8px', marginTop: '25px' }} className="header text-center font-weight-normal text-capitalize">{"AntQueue v" + appVersion}</div>
                      </CardFooter>
                    </Card>
                  </Col>
                </Row>
              </Container>
              <div
                className="full-page-background"
                style={{
                  backgroundImage: `url(${require("../../assets/img/bg/queue_4000x2500_3.jpg")})`,
                  backgroundColor: "lightGrey"
                }}
              />
            </div>
          </div>
        </LoadingOverlay>
      </div>
    )

    return wrapperFullPageContent;
  }
}

export default ResetPassword;
