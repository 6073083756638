import React, { useState, useEffect, useRef } from 'react'
import CreatableSelect from 'react-select/creatable'
import { components } from 'react-select'
import Switch from 'react-bootstrap-switch'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import { Col, Row, Button, FormGroup, Label, Input } from 'reactstrap'
import Firebase from 'firebase'
import config from '../../../config'

export default function ServiceDetails (props) {
  const [toggle, setToggle] = useState(false)
  const [options, setOptions] = useState([])
  const [options_arr, setOptions_arr] = useState([])
  const [valuee, setValuee] = useState({ value: '', label: '' })
  const input = useRef()
  const [inValue, setInValue] = useState('')
  const [detailsToggle, setDetailsToggle] = useState(false)
  const [alert, setAlert] = useState(<></>)
  if (!Firebase.apps.length) {
    Firebase.initializeApp(config)
  }
  useEffect(() => {
    if (props.sub != undefined && props.sub.length > 0) {
      Firebase.firestore()
        .collection('Services')
        .doc(props.service)
        .get()
        .then(function (response) {
          if (
            response.exists &&
            response.data().Service_Details_For_Display != undefined
          ) {
            setValuee({
              value: response.data().Service_Details_For_Display,
              label: response.data().Service_Details_For_Display
            })
            if (response.data().Service_Details_For_Display_Toggle != undefined)
              setDetailsToggle(response.data().Service_Details_For_Display_Toggle)
          }
        })
      Firebase.firestore()
        .collection('Sub_Locations')
        .doc(props.sub)
        .get()
        .then(function (response) {
          var det = response.data().Service_Details_For_Display
          if (det != undefined) {
            if (
              det.length > 0 &&
              typeof det !== 'string' &&
              typeof det !== 'number'
            ) {
              var arr = []
              det.forEach(e => {
                arr.push({ label: e, value: e })
              })
              setOptions(arr)
              setOptions_arr(det)
            }
          }
        })
    }
  }, [props.sub, props.service])
  function removeAlert () {
    setAlert(
      <ReactBSAlert
        warning
        showCancel
        title={
          "Remove '" +
          input.current.select.props.value.value +
          "' option from service details ?"
        }
        onConfirm={() => handleChange('remove')}
        onCancel={() => hideAlert()}
      ></ReactBSAlert>
    )
  }
  function hideAlert () {
    setAlert(<></>)
  }
  function handleChange (e, b = '') {
    var select = input.current.select.props.value.value
    switch (e) {
      case 'add':
        setOptions([
          ...options,
          {
            value: b,
            label: b
          }
        ])
        var tmp1 = [...options_arr, b]
        setOptions_arr(tmp1)
        Firebase.firestore()
          .collection('Sub_Locations')
          .doc(props.sub)
          .update({ Service_Details_For_Display: tmp1 })
        props.setDetails(b)
        setValuee({ value: b, label: b })

        break
      case 'remove':
        var option = options
        var option_arr = options_arr
        option.splice(
          option.indexOf({
            value: select,
            label: select
          }),
          1
        )
        setOptions(option)
        option_arr.splice(option_arr.indexOf(select), 1)
        setOptions_arr(option_arr)
        Firebase.firestore()
          .collection('Sub_Locations')
          .doc(props.sub)
          .update({ Service_Details_For_Display: option_arr })
        props.setDetails(select)
        setValuee({ value: '', label: '' })
        hideAlert()
        break
      default:
        break
    }
  }
  //   const MyOption = props => {
  //     const { innerProps, innerRef } = props
  //     console.log(props, '66666666666666666666666')
  //     return (
  //       <>
  //         <option value={props.value}>
  //           {props.label}
  //           <Button
  //             style={{
  //               marginTop: 'auto',
  //               background: 'transparent',
  //               color: 'black'
  //             }}
  //             onClick={() => removeAlert()}
  //           >
  //             X
  //           </Button>
  //         </option>
  //         {/* <br /> */}
  //       </>
  //     )
  //   }
  //   const Control = props => ({ children, ...rest }) => (
  //     <components {...rest}>👍 {children}</components>
  //   )

  return (
    <>
      {alert}
      <Row className='top-margin-10'>
        <Label md='4'>Service Details for Display</Label>
        <Col md='6' className='position-relative'>
          <FormGroup>
            <CreatableSelect
              ref={input}
              className='react-select primary'
              classNamePrefix='react-select'
              value={valuee}
              onChange={value => {
                setValuee(value)
                props.setDetails(value.value)
              }}
              options={options}
              onCreateOption={b => handleChange('add', b)}
              //   components={{ Option: MyOption }}
              //   components={{ Option: Control }}
            />
          </FormGroup>
          <Button
            style={{
              marginTop: 'auto',
              position: 'absolute',
              top: ' 0.7rem',
              right: '15%',
              padding: '0.2rem',
              color: 'black',
              background: 'transparent'
            }}
            onClick={() => removeAlert()}
          >
            X
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>Service Details Show on Display</Col>
        <Col>
          <FormGroup>{console.log(detailsToggle)}
            <Switch
              onColor='success'
              offColor='success'
              // defaultValue={detailsToggle}
              value={detailsToggle}
              onChange={e => {
                props.setDetailsTog(!detailsToggle)
                setDetailsToggle(!detailsToggle)
              }}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  )
}
