import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

class Customer3DChart extends Component {

  componentDidMount() {
    var chart = am4core.create("Customer", am4charts.PieChart3D);

    this.createChart(chart);

    this.chart = chart;
  }

  handleZoom = (e) => {
    console.log(e)
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  createChart = (chart) => {

    chart.data = this.props.data;
    chart.legend = new am4charts.Legend();
    // chart.innerRadius = am4core.percent(40);
    var series = chart.series.push(new am4charts.PieSeries3D());
    series.dataFields.value = "value";
    series.dataFields.category = "label";
    series.slices.template.propertyFields.fill="color"
    series.labels.template.disabled = true;
    series.ticks.template.disabled = true;
    series.slices.template.stroke = am4core.color("#fff");
    series.slices.template.strokeWidth = 2;
    series.slices.template.strokeOpacity = 1;

    chart.exporting.menu = new am4core.ExportMenu();
  }

  render() {
    return (
      <div id="Customer" style={{ width: "100%", height: "500px" }} />
    );
  }
}

export default Customer3DChart;
