import React from "react";
import Select from "react-select";
import PasswordHash from 'password-hash';
import NotificationAlert from "react-notification-alert";
import LoadingOverlay from "react-loading-overlay";
import Switch from "react-bootstrap-switch";
import Firebase from 'firebase';
import config from '../../../config';
import { roles } from "../../../utils/roles"

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardFooter,
    Button,
    FormGroup,
    InputGroup,
    Row,
    Col,
    Input,
    InputGroupAddon,
    InputGroupText,
    Form,
    Collapse,
    Label
} from "reactstrap";

class Checkbox extends React.Component {
    static defaultProps = {
        checked: false
    }
    render() {
        return (
            <input
                type={this.props.type}
                name={this.props.name}
                checked={this.props.checked}
                onChange={this.props.onChange}
            />
        );
    }
}

class UserAdd extends React.Component {
    constructor(props) {
        super(props);
        if (!Firebase.apps.length) {
            Firebase.initializeApp(config);
        }

        this.state = {
            loading: false,
            customer_role: '',
            customer_id: '',
            customer_list: [],
            roles: [],
            locations: [],

            name: '',
            email: '',
            designation: '',
            role: '',
            access_locations: [],
            use_status: true,
            otp_status: false,

            openedCollapses: [],

            nameState: '',
            emailState: '',
            customState: '',
            roleState: '',
            customer: '',
            customers_list: [],
            selected_customer: '',
            api: '',
            checkedItems: new Map(),
            request_approval_group_name_list: [],
            request_approval_groups_assigned: null,
        };

        this.handleChange = this.handleChange.bind(this);
        this.getLocations = this.getLocations.bind(this);
        this.loadCustomers = this.loadCustomers.bind(this);
        this.loadLocations = this.loadLocations.bind(this);
        this.notifyMessage = this.notifyMessage.bind(this);

        //   this.functions = null;                                              //functions emulator
        //   this.fbStore = null;                                                //functions emulator
    }

    //   initializeFirebase = () => {                                            //functions emulator
    //   if (!this.functions) {                                              //functions emulator
    //   this.functions = Firebase.functions();                          //functions emulator
    //   this.functions.useFunctionsEmulator('http://localhost:5001');   //functions emulator
    //   this.fbStore = Firebase.firestore();                            //functions emulator
    //   }                                                                   //functions emulator
    //   }                                                                       //functions emulator

    componentDidMount() {
        // this.initializeFirebase();                                          //functions emulator
        let _this = this;
        var customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;
        _this.setState({ customer_id: customer_id });
        var customer_role = JSON.parse(localStorage.getItem('auth_info')).role;
        if (customer_role === "Site_Admin") {
            _this.loadCustomers();
        }

        _this.setState({ loading: true });
        _this.setState({ customer_role: customer_role });
        var role_list = [];
        if (customer_role == "Site_Admin") {
            roles.forEach(function (role) {
                if (role.value != "Site_Admin")
                    role_list.push(role);
            });

            _this.setState({ roles: role_list });
            _this.loadCustomers();
        } else if (customer_role == "System_Admin") {
            roles.forEach(function (role) {
                if (role.value != "Site_Admin" && role.value != "System_Admin")
                    role_list.push(role);
            });

            _this.setState({ roles: role_list });
            _this.setState({ customer_id: customer_id });

        }
        else if (customer_role == "Location_Admin") {
            roles.forEach(function (role) {
                if (role.value != "Site_Admin" && role.value != "System_Admin" && role.value != "Location_Admin")
                    role_list.push(role);
            });

            _this.setState({ roles: role_list });
            _this.setState({ customer_id: customer_id });

        } else if (customer_role == "Location_Super") {
            roles.forEach(function (role) {
                if (role.value != "Site_Admin" && role.value != "System_Admin" && role.value != "Location_Admin" && role.value != "Location_Super")
                    role_list.push(role);
            });

            _this.setState({ roles: role_list });
            _this.setState({ customer_id: customer_id });

        } else {
            roles.forEach(function (role) {
                if (role.value != "Site_Admin" && role.value != "System_Admin" && role.value != "Location_Admin")
                    role_list.push(role);
            });

            _this.setState({ roles: role_list });
            _this.setState({ customer_id: customer_id });
        }
        _this.loadLocations();
        _this.loadRequestApprovalGroupInfo(customer_id);
    }

    loadCustomers() {
        var _this = this;
        //   var customers = [];
        // Firebase.firestore().collection('Web_App_Users').get().then(function (response) {
        //     response.docs.forEach(function (app_user) {
        //        if (app_user.data().Role === "System_Admin" || app_user.data().Role === "Location_Admin") {
        //            Firebase.firestore().collection('Customers').doc(app_user.data().Customer_ID).get().then(function (customer) {
        //                if (customer.exists) {
        //                    var one = {
        //                        value: customer.id,
        //                        label: customer.data().Email + " (" + customer.data().Name + ")"
        //                    }

        //                    customers.push(one);
        //                    _this.setState({customer_list: customers});
        //                }
        //            }).catch(function (err) {
        //                console.log(err);
        //            })
        //        }
        //     });
        // }).catch(function (err) {
        //     console.log(err);
        // });
        var customers = [];
        // Firebase.firestore().collection('Sub_Locations').get().then(function (sub_locations) {
        //     _this.setState({sub_locations: sub_locations.docs});
        // });
        Firebase.firestore().collection('Customers').get().then(function (response) {
            var whole_customers = response.docs;
            whole_customers.forEach(function (item) {
                var one = {
                    value: item.id,
                    label: item.data().Email + " (" + item.data().Name + ")"
                };

                if (one.value !== JSON.parse(localStorage.getItem('auth_info')).customer_id)
                    customers.push(one);
            });

            _this.setState({ customer_list: customers });
            _this.setState({ loading: false });
        });
    }

    loadLocations() {
        var _this = this;
        var customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;
        var role = JSON.parse(localStorage.getItem('auth_info')).role;
        this.setState({ customer_id: customer_id })
        var location_list = [];
        Firebase.firestore().collection('Main_Locations').where('Customer_ID', '==', customer_id).get().then(function (locations) {
            locations.docs.forEach(function (location) {
                var one = {
                    name: location.data().Name,
                    sub_locations: []
                };
                let customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;
                Firebase.firestore().collection('Sub_Locations').where('Main_Location_ID', '==', location.id).where('Customer_ID', '==', customer_id).get().then(function (sub_locations) {
                    sub_locations.docs.forEach(function (sub_location) {
                        var one_sub = {
                            name: sub_location.data().Name,
                            id: sub_location.id
                        };
                        one.sub_locations.push(one_sub);
                    });
                    location_list.push(one);
                    _this.setState({ locations: location_list });
                    _this.setState({ loading: false });
                }).catch(function (err) {
                    _this.setState({ loading: false });
                    _this.notifyMessage("tc", 3, "Network Error.");
                    console.log("loadLocations NetworkError1==>", err);
                });

            });
            _this.setState({ loading: false });
        }).catch(function (err) {
            _this.setState({ loading: false });
            _this.notifyMessage("tc", 3, "Network Error.");
            console.log("loadLocations NetworkError2==>", err);
        });


    }

    loadRequestApprovalGroupInfo = async (customer_id) => {
        console.log("loadRequestApprovalGroupInfo>>>1 customer_id", customer_id);
        //const { customer_id } = this.auth_info;
        var request_approval_group_name_list = [], request_approval_groups_snap = [];
        request_approval_groups_snap = await Firebase.firestore().collection('Request_Approval_Groups').where('Customer_ID', '==', customer_id).get();

        request_approval_groups_snap.forEach(request_approval_group => {
            const { Customer_ID, Name, Description, Main_Location_ID, Sub_Location_ID } = request_approval_group.data();
            const option = {
                // request_approval_group_id: request_approval_group.id,
                //id: request_approval_group.id,
                value: request_approval_group.id,
                label: Name,
                // request_approval_group_name: Name,
                // request_approval_group_description: Description,
                // customer_id: Customer_ID,
                // main_location_id: Main_Location_ID,
                // sub_location_id: Sub_Location_ID,
            };
            request_approval_group_name_list.push(option);
        });
        this.setState({ request_approval_group_name_list: request_approval_group_name_list });
        console.log({ request_approval_group_name_list: request_approval_group_name_list });        // return new Promise((resolve, reject) => {            
    };

    notifyMessage = (place, color, text) => {
        var type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }

        var options = {};
        options = {
            place: place,
            message: (
                <div className="text-md-center">
                    <div>
                        <b>{text}</b>
                    </div>
                </div>
            ),
            type: type,
            icon: "now-ui-icons ui-1_bell-53",
            autoDismiss: 3
        };
        if (options !== null) { this.notificationAlert.notificationAlert(options) }
    };

    collapsesToggle = collapse => {
        let openedCollapses = this.state.openedCollapses;
        if (openedCollapses.includes(collapse)) {
            this.setState({
                openedCollapses: openedCollapses.filter(item => item !== collapse)
            });
        } else {
            openedCollapses.push(collapse);
            this.setState({
                openedCollapses: openedCollapses
            });
        }
    };

    handleChange = (event, stateName, type) => {
        switch (type) {
            case "email":
                if (this.verifyEmail(event.target.value)) {
                    this.setState({ [stateName + "State"]: "has-success" });
                    const { name, value } = event.target;
                    this.setState({ [name]: value });
                } else {
                    this.setState({ [stateName + "State"]: "has-danger" });
                }
                break;
            case "name":
                if (this.verifyLength(event.target.value, 1)) {
                    this.setState({ [stateName + "State"]: "has-success" });
                    const { name, value } = event.target;
                    this.setState({ [name]: value });
                } else {
                    this.setState({ [stateName + "State"]: "has-danger" });
                }
                break;
            case "location":
                var access_locations = this.state.access_locations;
                var item = event.target.name; //var {name, value} = event.target;
                var isChecked = event.target.checked;
                if (isChecked === true) {
                    let _this = this;
                    _this.setState({ loading: true });

                    // const item = event.target.name;
                    // const isChecked = event.target.checked;
                    // _this.setState(prevState => ({
                    //     checkedItems: prevState.checkedItems.set(item, isChecked)
                    // }));
                    // console.log("checkedItems", _this.state.checkedItems);
                    // _this.setState({loading: false});

                    Firebase.functions().httpsCallable('getPaymentRestrictionsBasedOnSelectedPackage')({
                        sub_location_id: (item).trim(),
                        type: 'WEB_APP_USERS',
                        antqueue_customer_id: _this.state.customer_id,
                    }).then(function (result) {
                        const res = result.data;
                        console.log('validateItemCount', { result });
                        if (res.status === 'ok') {
                            var limitExceeded = res.data.countExceedsLimit;
                            console.log('validateItemCount>>> countExceedsLimit', limitExceeded);
                            _this.setState({ loading: false });
                            // return limitExceeded;
                            if (limitExceeded === true) {
                                _this.notifyMessage("tc", 3, "Sorry, the selected package for current sub location does not allow any more Users to be added.");
                                _this.setState(prevState => ({
                                    checkedItems: prevState.checkedItems.set(item, false)
                                }));
                            } else {
                                access_locations.push(stateName);
                                _this.setState({ access_locations: access_locations });
                                _this.setState(prevState => ({
                                    checkedItems: prevState.checkedItems.set(item, isChecked)
                                }));
                            }
                            console.log("checkedItems", _this.state.checkedItems);
                            _this.setState({ loading: false });
                        }
                    })
                        .catch((error) => {
                            _this.setState({ loading: false });
                            console.log('validateItemCount', { error });
                            return error;
                        });
                    break;
                } else {
                    let _this = this;
                    var index = access_locations.indexOf(stateName);
                    if (index !== -1) access_locations.splice(index, 1);
                    _this.setState({ access_locations: access_locations });
                    _this.setState(prevState => ({
                        checkedItems: prevState.checkedItems.set(item, isChecked)
                    }));
                    _this.setState({ loading: false });
                    console.log("checkedItems", _this.state.checkedItems);
                }
                //console.log("access_locations", access_locations); console.log("name", name, "value", value);
                break;
            case "role":
                this.setState({ role: event });
                this.setState({ customer: '' });
                this.setState({ [stateName + "State"]: "has-success" });
                if (event.value === "Site_Admin") {
                    this.refs.custom.setAttribute('hidden', true);
                    this.setState({ customState: 'has-success' });
                    this.setState({ apiState: '' });

                }
                if (event.value === "Dispenser_User" || event.value === "Kiosk_User") {
                    this.refs.api.removeAttribute('hidden');
                    this.setState({ apiState: 'has-none' });
                    if (this.state.customer_role === "Site_Admin") {
                        this.refs.custom.removeAttribute('hidden');
                        this.setState({ customState: '' });
                    }
                } else {
                    this.refs.api.setAttribute('hidden', true);
                    if (this.state.customer_role === "Site_Admin") {
                        this.refs.custom.removeAttribute('hidden');
                        this.setState({ customState: '' });
                        this.setState({ apiState: '' });
                    }
                }
                break;
            case "custom":
                this.setState({ customer: event.value });
                this.setState({ [stateName + "State"]: "has-success" });
                break;
            case "api":
                if (event.target.value.length == 6) {
                    this.setState({ api: event.target.value });
                    this.setState({ [stateName + "State"]: "has-success" });
                }
                else this.setState({ [stateName + "State"]: "has-danger" });
                break;
            default:
                break;
        }
        // this.setState({ [stateName]: event.target.value });
    };

    handleChangeRequestApprovalGroup = (event, type) => {
        let _this = this;
        if (type == "requestApprovalGroupsAssigned") {
            console.log({ v: 1, event: event, type: type });
            _this.setState({ request_approval_groups_assigned: event });
        }
    }

    verifyEmail = value => {
        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailRex.test(value)) {
            return true;
        }
        return false;
    };

    verifyLength = (value, length) => {
        if (value.length >= length) {
            return true;
        }
        return false;
    };

    handleSave = () => {
        if (this.state.nameState === "") {
            this.setState({ nameState: "has-danger" });
        }
        if (this.state.emailState === "") {
            this.setState({ emailState: "has-danger" });
        }
        if (this.state.roleState === "") {
            this.setState({ roleState: "has-danger" });
        }
        if (this.state.customState === "") {
            this.setState({ customState: "has-danger" });
        }
        if (this.state.customer_role === "Site_Admin") {
            if (this.state.customState !== "has-success")
                return;
        }
        //   console.log(this.state.apiState)
        //   if (this.state.apiState == "has-danger" || this.state.apiState == "has-none")
        //       return;

        //   if (!(this.state.nameState === "has-success" && this.state.emailState === "has-success" && this.state.roleState === "has-success")) {
        //       return;
        //   }

        var _this = this;
        _this.setState({ loading: true });

        var checkedItems = _this.state.checkedItems;
        let checkedItemKeys = [...checkedItems.entries()]
            .filter(({ 1: v }) => v === true)
            .map(([k]) => k);
        console.log("checkedItemKeys", checkedItemKeys);

        if (checkedItemKeys.length >= 1) {
            if (this.state.nameState === "has-success" && this.state.emailState === "has-success"
                && this.state.roleState === "has-success") {
                var now = new Date();
                //   Firebase.firestore().collection('Customers').get().then(function (res) {
                //       var last_id = "000000000000";
                //       var next_id = "000000000001";
                //       if (res.docs.length > 0) {
                //           last_id = res.docs[res.docs.length - 1].id;
                //           next_id = ++last_id;
                //       }

                //       var customer_id = next_id;

                //       if(_this.refs.custom.getAttribute('hidden') === null) {
                //           customer_id = _this.state.customer_id;
                //       }
                var requestApprovalGroupsAssignedArr = [];
                var requestApprovalGroupsAssignedArr1 = [];
                requestApprovalGroupsAssignedArr1 = _this.state.request_approval_groups_assigned;

                if (requestApprovalGroupsAssignedArr1 !== null && typeof requestApprovalGroupsAssignedArr1 !== 'undefined' &&
                    requestApprovalGroupsAssignedArr1.length >= 1) {
                    requestApprovalGroupsAssignedArr1.map((approvalGroup, index) => {
                        requestApprovalGroupsAssignedArr.push(approvalGroup.value);
                    });
                }

                var password = _this.generatePass(8);
                //   console.log("password", password);
                var otp_code = "";
                if (_this.state.otp_status)
                    otp_code = _this.generateId(6);
                var save_data = {
                    API_Key: _this.state.api,
                    Activated: _this.state.use_status,
                    Accessible_Locations: _this.state.access_locations,
                    Created_Date: now,
                    Customer_ID: _this.state.customer.length > 0 ? _this.state.customer : _this.state.customer_id,
                    Designation: _this.state.designation,
                    Last_Activity_Date: "",
                    Last_Updated_Date: now,
                    Last_Updated_User_ID: JSON.parse(localStorage.getItem('auth_info')).email,
                    Name: _this.state.name,
                    OTP_Code: otp_code,
                    OTP_Enabled: _this.state.otp_status,
                    Password: PasswordHash.generate(password),
                    Role: _this.state.role === "" ? "" : _this.state.role.value,
                    Google_Token: "",
                    Facebook_Token: "",
                    token: "",
                    What_Sup: "",
                    Viber: "",
                    Telegram: "",
                    Line: "",
                    Image_Url: "",
                    Notification: false,
                    Request_Approval_Groups_Assigned: requestApprovalGroupsAssignedArr,
                    First_Time_Login: true,
                };

                Firebase.firestore().collection('Web_App_Users').doc(_this.state.email.toLowerCase()).get().then(function (old) {
                    if (old.exists) {
                        _this.setState({ loading: false });
                        _this.notifyMessage("tc", 3, "This email address has already been registered with us!");
                    } else {
                        Firebase.firestore().collection('Web_App_Users').doc(_this.state.email.toLowerCase()).set(save_data)
                            .then(function () {
                                if (_this.refs.custom.getAttribute('hidden') === null) {
                                    //   var cus_data = {
                                    //       API_Key: "",
                                    //       Company_Name: "",
                                    //       Country: "",
                                    //       Email: _this.state.email.toLowerCase(),
                                    //       Name: _this.state.name,
                                    //       Partner_Interest: false,
                                    //       Phone_Number: "",
                                    //       Web_Site: "",
                                    //       Image_Url: ""
                                    //   };

                                    //   Firebase.firestore().collection('Customers').doc(_this.state.customer_id).set(cus_data)
                                    //       .then(function() {
                                    //var message_text = "Congratulation.<br />Your registration is successfully Done.<br />Please check your email for login details.<br/>Your login information:<br/>Username: " + _this.state.email.toLowerCase() + "<br/>Password: " + password;
                                    var message_text =
                                        "Dear Valued Customer,<br /><br>" +
                                        "Your account has been successfully registered with <b>Ant Queue</b> web app.<br /><br />" +
                                        "Your login details to <a href='https://app.antqueue.com/' target='_blank' style='color:#FF5733;'><b>app.antqueue.com</b></a> are as follows:<br /><br />" +
                                        "Username: " + _this.state.email.toLowerCase() + "<br/>" +
                                        "Password: " + password + "<br /><br />" +
                                        "Best Regards,<br/>" +
                                        "<b>Ant Queue Team</b><br/>";

                                    _this.sendEmail(_this.state.email.toLowerCase(), message_text);
                                    if (otp_code !== "") {
                                        var text = "Your otp code has been set.<br/>" +
                                            "Otp code : <b>" + otp_code + "</b>";
                                        Firebase.functions().httpsCallable('sendMail')({ email: _this.state.email.toLowerCase(), subject: 'New OTP Code', text: text }).then(function (error, result) {
                                            console.log(error, result);
                                        });
                                    }

                                    Firebase.functions().httpsCallable('createNewUser')({ email: _this.state.email.toLowerCase(), password: password }).then((result) => {
                                        console.log('new user created result ===> ', result);
                                    }, (err) => console.log("new user created error ==> ", err));

                                    _this.setState({ loading: false });
                                    _this.notifyMessage("tc", 2, "Add user success!");
                                    window.setTimeout(function () { _this.props.history.push("/users") }, 2000);
                                    //   })
                                    //   .catch(function(error) {
                                    //       _this.setState({loading: false});
                                    //       _this.notifyMessage("tc", 3, "Add user error!");
                                    //   });
                                } else {
                                    var message_text1 = //"Congratulation.<br />Your registration is successfully Done.<br />Please check your email for login details.<br/>Your login information:<br/>Username: " + _this.state.email.toLowerCase() + "<br/>Password: " + password;
                                        "Dear Valued Customer,<br /><br>" +
                                        "Your account has been successfully registered with <b>Ant Queue</b> web app.<br /><br />" +
                                        "Your login details to <a href='https://app.antqueue.com/' target='_blank' style='color:#FF5733;'><b>app.antqueue.com</b></a> are as follows:<br /><br />" +
                                        "Username: " + _this.state.email.toLowerCase() + "<br/>" +
                                        "Password: " + password + "<br /><br />" +
                                        "Best Regards,<br/>" +
                                        "<b>Ant Queue Team</b><br/>";

                                    _this.sendEmail(_this.state.email.toLowerCase(), message_text1);
                                    if (otp_code !== "") {
                                        var text2 = "Your otp code has been set.<br/>" +
                                            "Otp code : <b>" + otp_code + "</b>";
                                        Firebase.functions().httpsCallable('sendMail')({ email: _this.state.email.toLowerCase(), subject: 'New OTP Code', text: text2 }).then(function (error, result) {
                                            console.log(error, result);
                                        });
                                    }

                                    Firebase.functions().httpsCallable('createNewUser')({ email: _this.state.email.toLowerCase(), password: password }).then((result) => {
                                        console.log('new user created result ===> ', result);
                                    }, (err) => console.log("new user created error ==> ", err));

                                    _this.setState({ loading: false });
                                    _this.notifyMessage("tc", 2, "Add user success!");
                                    window.setTimeout(function () { _this.props.history.push("/users") }, 2000);
                                }
                            })
                            .catch(function (error) {
                                _this.setState({ loading: false });
                                _this.notifyMessage("tc", 3, "Add user error!");
                            });
                    }
                }).catch(function (err) {
                    _this.setState({ loading: false });
                    _this.notifyMessage("tc", 3, "Network Error.");
                    console.log("handleSave NetworkError3==>", err);
                });
                //   }).catch(function (err) {
                //       _this.setState({loading: false});
                //       _this.notifyMessage("tc", 3, "Network Error.");
                //   });
            } else {
                _this.setState({ loading: false });
            }
        } else {
            _this.notifyMessage("tc", 3, "Please select at least one sub location before saving!");
            _this.setState({ loading: false });
        }
    }

    generateId(length) {
        var result = '';
        var characters = '0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    };

    generatePass(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789,./<>?:{}[]-=_+)(*&^%$#@!~`';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    getLocations() {
        return this.state.locations.map((prop, toggle_key) => {
            return (
                <div key={toggle_key}>
                    <a href="#" className="custom-nav"><i className={this.state.openedCollapses.includes(toggle_key) ? "nc-icon nc-minimal-up" : "nc-icon nc-minimal-down"} /></a>
                    <a
                        aria-expanded={this.state.openedCollapses.includes(
                            toggle_key
                        )}
                        href="#"
                        data-parent="#accordion"
                        data-toggle="collapse"
                        onClick={e => { e.preventDefault(); this.collapsesToggle(toggle_key); }}
                    >
                        {prop.name}
                    </a>
                    <Collapse
                        role="tabpanel"
                        isOpen={this.state.openedCollapses.includes(
                            toggle_key
                        )}
                    >
                        {prop.sub_locations.map((prop, key) => {
                            return (
                                <Label key={key} className="row custom-nav-left">
                                    <Checkbox
                                        name={prop.id}
                                        type="checkbox"
                                        checked={!!this.state.checkedItems.get(prop.id)}
                                        onChange={e => this.handleChange(e, prop.id, "location")}
                                    />
                                    <span className="form-check-sign" />
                                    {prop.name}
                                </Label>
                            );
                        })}
                    </Collapse>
                </div>
            );
        });
    }

    sendEmail(email, text) {
        Firebase.functions().httpsCallable('sendMail')({ email: email, subject: 'Welcome to Antqueue Web App', text: text }).then(function (error, result) {
        });
    }

    render() {
        var ok = false
        console.log(this.state.customer_role)
        if (this.state.customer_role == "Site_Admin" || this.state.customer_role == "System_Admin" || this.state.customer_role == "Location_Admin")
            ok = true
        let {
            // register form
            nameState,
            emailState
        } = this.state;
        return (
            <>
                <LoadingOverlay
                    active={this.state.loading}
                    spinner
                    text='Loading'
                    className='content'
                >
                    <NotificationAlert ref={(ref) => this.notificationAlert = ref} />
                    <Row>
                        <Col md="12">
                            {ok ? <Card> {/*className="full-height-page"> */}
                                <CardHeader>
                                    <CardTitle tag="h4">User Add</CardTitle>
                                </CardHeader>
                                <CardHeader>
                                </CardHeader>
                                <CardBody>
                                    <Form action="#" method="#">
                                        <Col className="ml-auto mr-auto" lg="8">
                                            <div className="row">
                                                <div className="col-md-7">
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <Button
                                                                color="success"
                                                                onClick={this.handleSave}
                                                                block
                                                            >
                                                                Add
                                                            </Button>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <Button
                                                                color="youtube"
                                                                onClick={e => this.props.history.push("/users")}
                                                                block
                                                            >
                                                                Close
                                                            </Button>
                                                        </div>
                                                        {/*<div className="col-md-4">*/}
                                                        {/*<Button*/}
                                                        {/*color="warning"*/}
                                                        {/*href="#"*/}
                                                        {/*block*/}
                                                        {/*>*/}
                                                        {/*Reset Password*/}
                                                        {/*</Button>*/}
                                                        {/*</div>*/}
                                                    </div>
                                                    <div>
                                                        <span>Name</span>
                                                        <InputGroup className={`has-label ${nameState}`}>
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <i className="nc-icon nc-single-02" />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                placeholder="User Name"
                                                                defaultValue={this.state.name}
                                                                type="text"
                                                                name="name"
                                                                onChange={e => this.handleChange(e, "name", "name")}
                                                            />
                                                            {this.state.nameState === "has-danger" ? (
                                                                <label className="error">This field is required.</label>
                                                            ) : null}
                                                        </InputGroup>
                                                    </div>
                                                    <div>
                                                        <span>Email Address</span>
                                                        <InputGroup className={`has-label ${emailState}`}>
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <i className="nc-icon nc-single-02" />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                placeholder="Email Address"
                                                                defaultValue={this.state.email}
                                                                type="email"
                                                                name="email"
                                                                onChange={e => this.handleChange(e, "email", "email")}
                                                            />
                                                            {this.state.emailState === "has-danger" ? (
                                                                <label className="error">
                                                                    Please enter a valid email address.
                                                                </label>
                                                            ) : null}
                                                        </InputGroup>
                                                    </div>
                                                    <div>
                                                        <span>Designation</span>
                                                        <InputGroup>
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <i className="nc-icon nc-single-02" />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                placeholder="Designation"
                                                                defaultValue={this.state.designation}
                                                                type="text"
                                                                name="designation"
                                                                onChange={e => this.setState({ designation: e.target.value })}
                                                            />
                                                        </InputGroup>
                                                    </div>
                                                    <div>
                                                        <span>Role</span>
                                                        <FormGroup>
                                                            <Select
                                                                className="react-select info"
                                                                classNamePrefix="react-select"
                                                                placeholder="Select Role"
                                                                name="role"
                                                                defaultValue={this.state.role}
                                                                onChange={e => {
                                                                    this.handleChange(e, "role", "role")
                                                                    console.log(e.value)
                                                                }}
                                                                options={
                                                                    this.state.roles.map(function (val) {
                                                                        return { value: val.value, label: val.name };
                                                                    })
                                                                }
                                                            />
                                                            {this.state.roleState === "has-danger" ? (
                                                                <label className="error" style={{ color: "red" }}>This field is required.</label>
                                                            ) : null}
                                                        </FormGroup>
                                                    </div>
                                                    <div>
                                                        <span>Request Approval Groups</span>
                                                        <FormGroup>
                                                            <Select
                                                                placeholder="Select Request Approval Groups"
                                                                closeMenuOnSelect={true}
                                                                className="react-select info select-location"
                                                                classNamePrefix="react-select"
                                                                name="requestApprovalGroupsAssigned"
                                                                label="Request Approval Groups Assigned"
                                                                value={this.state.requestApprovalGroupsAssigned}
                                                                onChange={event => { this.handleChangeRequestApprovalGroup(event, "requestApprovalGroupsAssigned") }}
                                                                options={this.state.request_approval_group_name_list}
                                                                //clearValue={mobile_app_file_attachment.mobileAppFileAttachmentName === ""}
                                                                isMulti
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                    {/* <br/> */}
                                                    {/* {this.state.customer_role=='Site_Admin'?<div >
                                                <span>Customer</span>
                                                <Select
                                                    className="react-select info select-location"
                                                    classNamePrefix="react-select"
                                                    placeholder="Select Customer"
                                                    name="selectCustomer"
                                                    value={this.state.singleSelect}
                                                    onChange={e =>
                                                        this.setState({selected_customer:e.value})
                                                    }
                                                    options={this.state.customer_list}
                                                />
                                            </div>:''} */}
                                                    <div ref="api" hidden>
                                                        <br />
                                                        <span>API key</span>
                                                        <Input
                                                            placeholder="API key"
                                                            defaultValue={this.state.api}
                                                            type="text"
                                                            name="api"
                                                            onChange={value =>
                                                                this.handleChange(value, 'api', 'api')
                                                            }
                                                        />
                                                        {/* <Select
                                                    className={"react-select info "}
                                                    classNamePrefix="react-select"
                                                    placeholder="Select Customer"
                                                    name="customer"
                                                    
                                                    options={this.state.customer_list}
                                                /> */}
                                                        {this.state.apiState === "has-danger" ? (
                                                            <label className="error" style={{ color: "red" }}>This field is mandatory and only accepts 6 alphanumeric characters only!</label>
                                                        ) : null}
                                                    </div>
                                                    <div ref="custom" hidden>
                                                        <br />
                                                        <span>Customer</span>
                                                        <Select
                                                            className="react-select info"
                                                            classNamePrefix="react-select"
                                                            placeholder="Select Customer"
                                                            name="customer"
                                                            onChange={value =>
                                                                this.handleChange(value, 'custom', 'custom')
                                                            }
                                                            options={this.state.customer_list}
                                                        />
                                                        {this.state.customState === "has-danger" ? (
                                                            <label className="error" style={{ color: "red" }}>This field is required.</label>
                                                        ) : null}
                                                    </div>
                                                    <br />
                                                    <div>
                                                        <span>Select Accessible Locations</span>
                                                        <div className="blockquote blockquote-primary">
                                                            <div
                                                                aria-multiselectable={true}
                                                                className="card-collapse"
                                                                id="accordion"
                                                                role="tablist"
                                                            >
                                                                {this.getLocations()}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-5 text-md-center top-margin-section">
                                                    <Row>
                                                        <Col md="2">
                                                        </Col>
                                                        <Col md="4">
                                                            <span>User Enabled</span>
                                                        </Col>
                                                        <Col md="4">
                                                            <FormGroup>
                                                                <Switch
                                                                    defaultValue={this.state.use_status}
                                                                    offColor="success"
                                                                    offText=""
                                                                    onColor="success"
                                                                    onText=""
                                                                    onChange={event => this.setState({ use_status: event.state.value })}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    {/* <Row>
                                                <Col md="2">
                                                </Col>
                                                <Col md="4">
                                                    <span>OTP Enabled</span>
                                                </Col>
                                                <Col md="4">
                                                    <FormGroup>
                                                        <Switch
                                                            defaultValue={this.state.otp_status}
                                                            offColor="success"
                                                            offText=""
                                                            onColor="success"
                                                            onText=""
                                                            onChange={event => this.setState({otp_status: event.state.value})}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row> */}
                                                </div>
                                            </div>
                                        </Col>
                                    </Form>
                                </CardBody>
                                <CardFooter>
                                </CardFooter>
                            </Card> : null}
                        </Col>
                    </Row>
                </LoadingOverlay>
            </>
        );
    }
}

export default UserAdd;