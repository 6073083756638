import React from "react";
import NotificationAlert from "react-notification-alert";
import Firebase from "firebase";
import LoadingOverlay from "react-loading-overlay";
// import CopyToClipboard from "react-copy-to-clipboard";
import ReactBSAlert from "react-bootstrap-sweetalert";
// import Select from "react-select";

import {
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    // Label,
    Button,
    // Input,
    // Form,
    // FormGroup
} from "reactstrap";
import config from "../../../config";

class SupportCenter extends React.Component {
    constructor(props) {
        super(props);
        if (!Firebase.apps.length) {
            Firebase.initializeApp(config);
        }

        this.state = {
            loading: false,
            role: '',
            support_admin_role: false,
        };
    }
    componentDidMount() {
        let _this = this;
        // let custID = "000000000001";
        var auth = JSON.parse(localStorage.getItem('auth_info'));
        var role = auth.role;
        var supportAdminRole = auth.support_admin_role;
        _this.setState({
            role: role,
            support_admin_role: supportAdminRole
        });


        // this.getCurrentTime();
    }
    componentWillUnmount() {

    }
    componentDidUpdate(e) {

    }
    gotoLoginAsSpecificUser() {
        this.props.history.push('/support_central/login_as_specific_user');
    }
    gotoLoginByRole() {
        this.props.history.push('/support_central/login_by_role');
    }
    // getAccessibleLocsOfSelectedCustomer(custID) {
    //     let _this = this;
    //     _this.setState({loading: true});
    //     let accessibleLocs = [];
    //     // let role = JSON.parse(localStorage.getItem('auth_info')).role;

    //     // if (role=='Support_Admin') {
    //         // console.log("userEmailID", userEmailID);
    //         Firebase.firestore().collection('Sub_Locations').where("Customer_ID", "==", custID).get().then(function (app_info) {
    //             if (app_info.exists) {
    //                 app_info.docs.forEach(function (doc) {
    //                     accessibleLocs.push(doc.id);
    //                 });
    //                 _this.setState({accessible_locs: accessibleLocs});
    //                 console.log("accessibleLocs", accessibleLocs);
    //                 _this.setState({loading: false});
    //                 return accessibleLocs;
    //             }

    //         }).catch(function (error) {
    //             _this.setState({loading: false});
    //             _this.notifyMessage("tc", 3, "NetWork Error.");
    //             console.log("getAccessibleLocsOfSelectedCustomer NetworkError3==>", error);
    //         });
    //     // }
    //      _this.setState({loading: false});
    // }
    getCurrentTime() {
        let _this = this;
        let now = new Date();
        _this.setState({ current_time: now.toLocaleString() });
        window.setTimeout(function () { _this.getCurrentTime() }, 500);
    }
    loadDefaultSupportProfile() {

        let _this = this;
        let custID = "000000000001";
        _this.setState({ alert: null });
        _this.setState({ loading: true });

        let role = _this.state.role;
        let supportAdminRole = _this.state.support_admin_role;

        if (role == 'Support_Admin' || supportAdminRole == true) {
            Firebase.firestore().collection('Sub_Locations').where("Customer_ID", "==", custID).get().then(function (app_info) {
                if (app_info.docs.length >= 1) {

                    let userRole = "Support_Admin";
                    let auth_info_obj = JSON.parse(localStorage.getItem('auth_info')),
                        currUserEmailID = auth_info_obj.email;
                    let accessibleLocs = [];
                    console.log("accessibleLocs", accessibleLocs);

                    app_info.docs.forEach(function (doc) {
                        accessibleLocs.push(doc.id);
                    });
                    _this.setState({ accessible_locs: accessibleLocs });
                    console.log("accessibleLocs", accessibleLocs);

                    var save_data = {
                        // Last_Activity_Date:new Date()
                        Login_As_Specific_User_Email_ID: "",
                        Login_As_Specific_User_Changed_Date: "",
                        Login_By_Role: "",
                        Login_By_Role_Changed_Date: "",
                        Accessible_Locations: accessibleLocs,
                        Customer_ID: custID,
                        Role: userRole,
                    };

                    console.log("save_data", save_data, "currUserEmailID", currUserEmailID);

                    Firebase.firestore().collection('Web_App_Users').doc(currUserEmailID).update(save_data)
                        .then(function (user) {
                            console.log('user updated', user);
                            console.log("Successfully saved 'default support profile' for " + custID + " to currently logged in user: " + currUserEmailID);
                            //_this.notifyMessage("tc", 2, "Successfully saved. You must logout and login to this account to load default support profile!");
                            // window.setTimeout(function() { _this.props.history.push("/logout") }, 4000);

                            let auth_info = {
                                ...auth_info_obj,
                                customer_id: custID,
                                email: currUserEmailID,
                                role: userRole,
                                message: 'Account switched successfully to default',
                                messageShowOnce: true
                            };
                            localStorage.setItem(
                                "auth_info",
                                JSON.stringify(auth_info)
                            );
                            window.setTimeout(function () {
                                document.location.reload();
                            }, 4000);
                        })
                        .catch(function (error) {
                            console.log("Error saving document: ", error);
                            _this.notifyMessage("tc", 3, "Error saving document!");
                        });

                    _this.setState({ loading: false });
                } else {
                    console.log("app_info.exists false")
                }
            }).catch(function (error) {
                _this.setState({ loading: false });
                _this.notifyMessage("tc", 3, "NetWork Error.");
                console.log("loadDefaultSupportProfile NetworkError1==>", error);
            });
        }
        _this.setState({ loading: false });
    }
    warningWithConfirmMessage = (object) => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block" }}
                    title="Are you sure you want to Apply default account settings?"
                    onConfirm={() => this.loadDefaultSupportProfile()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="info"
                    cancelBtnBsStyle="danger"
                    confirmBtnText="Yes"
                    cancelBtnText="Cancel"
                    showCancel
                >

                </ReactBSAlert>
            )
        });
    };
    // showSuccessAlert = () => {
    //     this.setState({
    //         alert: (
    //             <ReactBSAlert
    //                 success
    //                 style={{ display: "block" }}
    //                 title="Default Support Profile will be loaded after you click 'OK'."
    //                 onConfirm={() => this.loadDefaultSupportProfile()}
    //                 onCancel={() => this.loadDefaultSupportProfile()}
    //                 confirmBtnBsStyle="info"
    //             >

    //             </ReactBSAlert>
    //         )
    //     });
    // };
    hideAlert = () => {
        this.setState({
            alert: null
        });
    };
    notifyMessage = (place, color, text) => {
        var type;
        switch (color) {
            case 1: type = "primary"; break;
            case 2: type = "success"; break;
            case 3: type = "danger"; break;
            case 4: type = "warning"; break;
            case 5: type = "info"; break;
            default: break;
        }

        var options = {};
        options = {
            place: place,
            message: (
                <div className="text-md-center">
                    <div>
                        <b>{text}</b>
                    </div>
                </div>
            ),
            type: type,
            icon: "now-ui-icons ui-1_bell-53",
            autoDismiss: 3
        };
        this.notificationAlert.notificationAlert(options);
    };
    render() {
        let role = this.state.role;
        let supportAdminRole = this.state.support_admin_role;
        console.log("role", role, "supportAdminRole", supportAdminRole);

        var ok = false
        if (this.state.role == "Support_Admin" || supportAdminRole == true)
            ok = true
        return (
            <>
                <LoadingOverlay
                    active={this.state.loading}
                    spinner
                    text='Loading'
                    className='content'
                >
                    <NotificationAlert ref={(ref) => this.notificationAlert = ref} />
                    {this.state.alert}
                    <Row>
                        <Col md="12">
                            {ok ? <Card className="full-height-page">
                                <CardHeader>
                                    <CardTitle tag="h4">Support Center</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Col className="ml-auto mr-auto" xl="10" lg="12" md="12">
                                        <Row className="bottom-margin-20">
                                            <Col xl="3" lg="4">
                                                <Button
                                                    className="btn btn-success"
                                                    onClick={e => { e.preventDefault(); this.gotoLoginAsSpecificUser(); }}
                                                    block
                                                >
                                                    Switch account settings to Specific User
                                                </Button>
                                            </Col>
                                            <Col xl="3" lg="4">
                                                <Button
                                                    className="btn btn-success"
                                                    onClick={e => { e.preventDefault(); this.gotoLoginByRole(); }}
                                                    block
                                                >
                                                    Switch account settings By Role
                                                </Button>
                                            </Col>
                                            <Col xl="4" lg="5" md='6'>
                                                <Button
                                                    className="btn btn-warning"
                                                    onClick={e => this.warningWithConfirmMessage()}
                                                    block
                                                >
                                                    Apply default account settings
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </CardBody>
                            </Card> : null}
                        </Col>
                    </Row>
                </LoadingOverlay>
            </>
        );
    }
}

export default SupportCenter;
