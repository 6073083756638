import React from "react";
import Firebase, { app } from 'firebase';
import PasswordHash from 'password-hash';
import config from '../../config';
import ReactBSAlert from "react-bootstrap-sweetalert";
import NotificationAlert from "react-notification-alert";
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from "prop-types";
// import GoogleLogin from 'react-google-login';
// import FacebookLogin from 'react-facebook-login';
import info from '../../info'
import request from 'request';
import LoadingOverlay from 'react-loading-overlay';

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Label,
    FormGroup,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Col,
    Row
} from "reactstrap";
import { loginUser } from '../../actions/actions';
// const publicIp = require('public-ip');
class Login extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    constructor(props) {
        super(props);
        if (props.location.hash !== "") {
            console.log("ddd", props);
        }

        const { cookies } = props;
        cookies.remove('auth_info');
        localStorage.removeItem('auth_info');
        if (!Firebase.apps.length) {
            Firebase.initializeApp(config);
        }

        this.state = {
            loading: false,
            cookies: cookies,
            email: '',
            password: '',
            rememberMe: '0',

            registerEmailState: "",
            registerPasswordState: "",

            alert: null,
            // address_info: null,

            // google_token: '',
            // facebook_token: '',
            // social_email: '',

            loginData: {},
            functionsBaseURL: ""
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSignIn = this.handleSignIn.bind(this);
        this.signIn = this.signIn.bind(this);
        this.notifyMessage = this.notifyMessage.bind(this);
        this.focusElement = this.focusElement.bind(this);
        // this.loadAddressInfo = this.loadAddressInfo.bind(this);
        // this.onClickSocial = this.onClickSocial.bind(this);
    };
    componentDidMount() {
        let _this = this;
        document.body.classList.toggle("login-page");
        _this.setState({ functionsBaseURL: config.baseURL });
        // this.loadAddressInfo();
    }
    componentWillUnmount() {
        document.body.classList.toggle("login-page");
    }
    // loadAddressInfo() {
    //     var _this = this;
    //     _this.setState({loading: true});
    //     publicIp.v4().then(ip => {
    //         console.log("your public ip address", ip);
    //         let url = 'https://api.ipgeolocation.io/ipgeo?apiKey=' + info.ip_api_key + '&ip='+ip+'&lang=en';

    //         request.get(url, function(err,res,body){
    //             if(err){
    //                 _this.setState({address_info: null});
    //                 _this.setState({loading: false});
    //                 _this.notifyMessage("tc", 3, "Network error!");
    //                 console.log("loadAddressInfo NetworkError1==>",err);
    //             }
    //             if(res.statusCode !== 200 ) {
    //                 _this.setState({address_info: null});
    //                 _this.setState({loading: false});
    //                 _this.notifyMessage("tc", 3, "Load geolocation error!");
    //             }

    //             let result = JSON.parse(body);
    //             _this.setState({address_info: result});
    //             _this.setState({loading: false});
    //         });
    //     });
    // };
    handleChange = (event, stateName, type) => {
        switch (type) {
            case "email":
                if (this.verifyEmail(event.target.value)) {
                    this.setState({ [stateName + "State"]: "has-success" });
                    const { name, value } = event.target;
                    this.setState({ [name]: value });
                } else {
                    this.setState({ [stateName + "State"]: "has-danger" });
                }
                break;
            case "password":
                if (this.verifyLength(event.target.value, 1)) {
                    this.setState({ [stateName + "State"]: "has-success" });
                    const { name, value } = event.target;
                    this.setState({ [name]: value });
                } else {
                    this.setState({ [stateName + "State"]: "has-danger" });
                }
                break;
            case "remember":
                if (this.state.rememberMe === "1") {
                    this.setState({ "rememberMe": "0" });
                } else {
                    const { name } = event.target;
                    this.setState({ [name]: "1" });
                }
                break;
            default:
                break;
        }
        this.setState({ [stateName]: event.target.value });
    };
    handleSignIn = () => {
        if (this.state.registerEmailState === "") {
            this.setState({ registerEmailState: "has-danger" });
        }

        if (
            this.state.registerPasswordState === ""
            // || this.state.registerConfirmPasswordState === ""
        ) {
            this.setState({ registerPasswordState: "has-danger" });
            // this.setState({ registerConfirmPasswordState: "has-danger" });
        }

        if (this.state.registerEmailState === "has-success" && this.state.registerPasswordState === "has-success") {
            var loginData = {
                email: (this.state.email.toLowerCase()).trim(),
                password: this.state.password.trim(),
                rememberMe: this.state.rememberMe
            };

            this.signIn(loginData);
        }
        // console.log("handleSignIn loginData==>",loginData);
    };
    focusElement(input) {
        input.focus(input);
    };

    /**
    * To-do: Update Firebase Authentication module with custom claims
    * 2023-07-31 by 
    * author: Firdous
    * */
    signIn = (loginData) => {
        this.setState({ loginData: loginData });
        var _this = this;
        _this.setState({ loading: true });
        var now = new Date();
        var last_activity_date = now;
        Firebase.auth().signInWithEmailAndPassword(loginData.email, loginData.password)
            .then((credential) => {
                // console.log("auth().currentUser==>", Firebase.auth().currentUser);
                Firebase.auth().onAuthStateChanged(user => {
                    if (user) {
                        // console.log("auth().token==>", Firebase.auth().token);

                        let ref1 = Firebase.firestore().collection('Web_App_Users').doc(loginData.email.toLowerCase());
                        ref1.get().then(function (app_user) {
                            if (app_user.exists) {

                                let currentUser = Firebase.auth().currentUser;
                                currentUser.getIdToken().then(function (token) {
                                    // console.log("token", token);

                                    const requestOptions = {
                                        method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'Authorization': `Bearer ${token}`
                                        },
                                    };

                                    let functionsBaseURL = _this.state.functionsBaseURL + "createUserToken";
                                    let url = new URL(functionsBaseURL),
                                        params = {
                                            uid: currentUser.uid,
                                            email: loginData.email.toLowerCase()
                                        }
                                    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
                                    fetch(url, requestOptions)
                                        .then(response => response.json())
                                        .then(tokenData => {
                                            // console.log("tokenData",tokenData, "tokenData.status", tokenData.status);
                                            if (tokenData.status === "success") {
                                                // if (PasswordHash.verify(loginData.password, app_user.data().Password)) {
                                                if (app_user.data().Activated) {
                                                    if (app_user.data().OTP_Enabled) {
                                                        _this.setState({ loading: false });
                                                        _this.inputAlert();
                                                    } else {
                                                        var auth_info = {
                                                            customer_id: tokenData.customer_id,
                                                            email: tokenData.email,
                                                            role: tokenData.role,
                                                            support_admin_role: tokenData.support_admin_role,
                                                            username: tokenData.username,
                                                            ...(!!app_user.data()?.First_Time_Login != undefined ?
                                                                { first_time_login: app_user.data()?.First_Time_Login } :
                                                                {}
                                                            )
                                                        };
                                                        /* Firebase.functions().httpsCallable('createUserToken')({ uid: currentUser.uid, user_info: app_user.data() }).then((result) => {
                                                            console.log("FUNCTION createUserToken result==>", result, "currentUser.token==>", Firebase.auth().currentUser.token, "app_user.data()==>", app_user.data());
                                                        }, (err) => console.log("customToken error==> ", err)); */
                                                        Firebase.firestore().collection('Web_App_Users').doc(app_user.id).update({ Last_Activity_Date: last_activity_date })
                                                            // TO-DO: Implement Custom claims related code
                                                            // .then(function () {
                                                            //     Firebase.auth().currentUser.getIdTokenResult()
                                                            //         .then((idTokenResult) => {
                                                            //             console.log("idTokenResult", idTokenResult);
                                                            //             // Confirm the user is an Admin.
                                                            //             // if (!!idTokenResult.claims.admin) {
                                                            //             //     // Show admin UI.
                                                            //             //     //showAdminUI();
                                                            //             // } else {
                                                            //             //     // Show regular user UI.
                                                            //             //     //showRegularUI();
                                                            //             // }
                                                            //         })
                                                            // })
                                                            .then(function () {
                                                                if (_this.state.rememberMe === "1")
                                                                    _this.state.cookies.set('auth_info', auth_info.email);

                                                                localStorage.setItem("auth_info", JSON.stringify(auth_info));
                                                                console.log('auth success');
                                                                window.setTimeout(function () { _this.props.history.push("/") }, 2000);
                                                                _this.setState({ loading: false });
                                                                _this.notifyMessage("tc", 2, "Login success!");
                                                            }).catch(function (err) {
                                                                _this.setState({ loading: false });
                                                                _this.notifyMessage("tc", 3, "Network error!");
                                                                console.log("signIn NetworkError13==>", err);
                                                            });
                                                    }
                                                } else {
                                                    _this.setState({ loading: false });
                                                    _this.notifyMessage("tc", 3, "User disabled!");
                                                }
                                                // } else {
                                                //     _this.setState({loading: false});
                                                //     _this.notifyMessage("tc", 3, "Incorrect password!");
                                                // }
                                            } else {
                                                _this.setState({ loading: false });
                                                _this.notifyMessage("tc", 3, "An unexpected error occurred. Please try again!");
                                                console.log("Unexpected API error", tokenData.message);
                                            }
                                        }).catch(function (error) {
                                            _this.setState({ loading: false });
                                            _this.notifyMessage("tc", 3, "Network error!");
                                            console.log("signIn NetworkError14==>", error);
                                        });

                                })
                            } else {
                                _this.setState({ loading: true });
                                _this.notifyMessage("tc", 3, "An unexpected error occurred. Please try again!");
                                console.log("Unexpected API error, web app user details doesn't exist");
                            }
                        })
                    }
                })

            }).catch((error) => {
                ///
                console.log(error);
                let errorMsg = '';
                switch (error.code) {
                    case "auth/invalid-email":
                        errorMsg = "Invalid email address format.";
                        break;
                    case "auth/user-not-found":
                        errorMsg = "Invalid user.";
                        break;
                    case "auth/wrong-password":
                        //if user registered in database but not in authentication, go to Passchangepage
                        // console.log("you must create firebase auth");
                        let ref = Firebase.firestore().collection('Web_App_Users').doc(loginData.email.toLowerCase());
                        // console.log("ref ===> ", ref);
                        ref.get().then(function (app_user) {
                            if (app_user.exists) {
                                var password = loginData.password;
                                // console.log(password, app_user.data().Password, '==>', PasswordHash.verify(password, app_user.data().Password));
                                if (PasswordHash.verify(password, app_user.data().Password)) {
                                    if (app_user.data().Activated) {
                                        if (app_user.data().OTP_Enabled) {
                                            _this.setState({ loading: false });
                                            _this.inputAlert();
                                            console.log('enabled!');
                                        } else {
                                            console.log(loginData);
                                            Firebase.auth().createUserWithEmailAndPassword(loginData.email, loginData.password)
                                                .then((credential) => {
                                                    // console.log("Credential ==> ", credential);
                                                    Firebase.auth().currentUser.updateProfile({
                                                        displayName: ""
                                                    });
                                                    /* let currentUser = Firebase.auth().currentUser;
                                                    let app_user_info = {
                                                        Email: app_user.id,
                                                        Customer_ID: app_user.data().Customer_ID,
                                                        Accessible_Locations: app_user.data().Accessible_Locations,
                                                        Role: app_user.data().Role
                                                    }
                                                    //add user infos to Web_App_Users_Info at first time user login
                                                    Firebase.firestore().collection('Web_App_Users_Info').doc(currentUser.uid).set(app_user_info)
                                                        .then(function () {
                                                            console.log("Successful to save to Web_App_Users_Info....");
                                                        }).catch(function (err) {
                                                            _this.setState({ loading: false });
                                                            _this.notifyMessage("tc", 3, "Network error when add to user infos!");
                                                        });
                                                    ref.set({uid: credential.user.uid}, {merge: true}); */
                                                })
                                                .catch(error1 => {
                                                    errorMsg = '';
                                                    switch (error1.code) {
                                                        case "auth/email-already-in-use":
                                                            errorMsg = "This e-mail is already in use.";
                                                            break;
                                                        case "auth/invalid-email":
                                                            errorMsg = "Invalid e-mail address format.";
                                                            break;
                                                        case "auth/weak-password":
                                                            errorMsg = "Password is too weak.";
                                                            break;
                                                        case "auth/too-many-requests":
                                                            errorMsg = "Too many requests. Please try again in a while.";
                                                            break;
                                                        default:
                                                            console.log("An unexpected error occurred. Please try again later."); //errorMsg = "Please check your internet connection."
                                                            console.log("error1", error1);
                                                            break;
                                                    }
                                                    _this.setState({ loading: false });
                                                    _this.notifyMessage("tc", 3, errorMsg);
                                                });
                                        }
                                    }
                                }
                            }
                        });
                        errorMsg = "Invalid email address or password.";
                        break;
                    case "auth/too-many-requests":
                        errorMsg = "Too many requests. Try again in a minute.";
                        break;
                    default:
                        console.log("An unexpected error occurred. Please try again later.");
                        errorMsg = "An unexpected error occurred. Please try again later."
                        break;
                }
                _this.setState({ loading: false });
                _this.notifyMessage("tc", 3, errorMsg);
            });

    };

    sendEmail(email, text) {
        Firebase.functions().httpsCallable('sendMail')({ email: email, subject: 'Welcome to Antqueue Web App', text: text }).then(function (result) {
            // console.log(result);
        });
    };
    inputAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    input
                    showCancel
                    style={{ display: "block", marginTop: "0px" }}
                    title="Please input your OTP code."
                    onConfirm={e => this.inputConfirm(e)}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="info"
                    cancelBtnBsStyle="danger"
                />
            )
        });
    };
    inputConfirm = e => {
        var _this = this;
        _this.setState({ loading: true });
        var user_email = _this.state.email.toLowerCase();
        // if (_this.state.social_email !== "") {
        //     user_email = _this.state.social_email.toLowerCase();
        // }

        var now = new Date();
        Firebase.firestore().collection('Web_App_Users').doc(user_email).get().then(function (doc) {
            if (doc.exists) {
                if (doc.data().OTP_Code === e) {
                    var auth_info = {
                        customer_id: doc.data().Customer_ID,
                        email: doc.id,
                        role: doc.data().Role,
                        support_admin_role: doc.data().Support_Admin_Role,
                        username: doc.data().Name
                    };
                    var otp_code = _this.generateId(6);
                    var update_otp_data = {
                        Last_Activity_Date: now,
                        OTP_Code: otp_code
                    };
                    // if (_this.state.google_token !== "") {
                    //     update_otp_data['Google_Token'] = _this.state.google_token;
                    //     _this.setState({google_token: ""});
                    //     _this.setState({social_email: ""});
                    // } else if (_this.state.facebook_token !== "") {
                    //     update_otp_data['Facebook_Token'] = _this.state.facebook_token;
                    //     _this.setState({facebook_token: ""});
                    //     _this.setState({social_email: ""});
                    // }

                    Firebase.firestore().collection('Web_App_Users').doc(user_email).update(update_otp_data)
                        .then(function () {
                            var text = "Your OTP code is changed.<br/>" +
                                "OTP code : <b>" + otp_code + "</b>";
                            Firebase.functions().httpsCallable('sendMail')({ email: user_email, subject: 'New OTP Code', text: text }).then(function (result) {
                                if (_this.state.rememberMe === "1")
                                    _this.state.cookies.set('auth_info', auth_info.email);

                                localStorage.setItem("auth_info", JSON.stringify(auth_info));
                                _this.notifyMessage("tc", 2, "Login success!");
                                _this.setState({ loading: false });
                                _this.hideAlert();
                                window.setTimeout(function () { _this.props.history.push("/") }, 2000);
                            }).catch(function (err) {
                                _this.setState({ loading: false });
                                _this.hideAlert();
                                _this.notifyMessage("tc", 3, "Network error!");
                                console.log("inputConfirm NetworkError15==>", err);
                            });
                        });
                } else {
                    _this.setState({ loading: false });
                    _this.hideAlert();
                    _this.notifyMessage("tc", 3, "Incorrect OTP code!");
                }
            } else {
                _this.setState({ loading: false });
                _this.hideAlert();
                _this.notifyMessage("tc", 3, "Network error!");
                console.log("inputConfirm NetworkError16");
            }
        }).catch(function (err) {
            _this.setState({ loading: false });
            _this.hideAlert();
            _this.notifyMessage("tc", 3, "Network error!");
            console.log("inputConfirm NetworkError17==>", err);
        });
    };
    hideAlert = () => {
        this.setState({
            alert: null
        });
    };
    verifyEmail = value => {
        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailRex.test(value)) {
            return true;
        }
        return false;
    };
    // function that verifies if a string has a given length or not
    verifyLength = (value, length) => {
        if (value.length >= length) {
            return true;
        }
        return false;
    };
    notifyMessage = (place, color, text) => {
        let _this = this;
        var type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }

        var options = {};
        options = {
            place: place,
            message: (
                <div className="text-md-center">
                    <div>
                        <b>{text}</b>
                    </div>
                </div>
            ),
            type: type,
            icon: "now-ui-icons ui-1_bell-53",
            autoDismiss: 3
        };
        if (options !== null && _this.notificationAlert != null) {
            _this.notificationAlert.notificationAlert(options);
        }
    };
    generateId(length) {
        var result = '';
        var characters = '0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    };
    generatePass(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789,./<>?:{}[]-=_+)(*&^%$#@!~`';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    };
    render() {
        let appVersion = process.env.REACT_APP_VERSION;
        let {
            registerEmailState,
            registerPasswordState,
        } = this.state;

        return (
            <div className="wrapper wrapper-full-page" ref={(ref) => this.fullPages = ref}>
                <LoadingOverlay
                    active={this.state.loading}
                    spinner
                    text='Loading'
                >
                    <div className="full-page section-image">
                        <>
                            <NotificationAlert ref={(ref) => this.notificationAlert = ref} />
                        </>
                        <div className="login-page">
                            {this.state.alert}
                            <Container>
                                <Row>
                                    <Col className="ml-auto mr-auto" lg="7" md="6">
                                        <Card style={{ backgroundColor: '#ffffff00', boxShadow: '0px 0px 0px 0px rgb(0 0 0 / 1%)' }} className="card-login">
                                            <CardHeader>
                                                <CardHeader>
                                                    <div className="header text-center">
                                                        {/* <img src={'/antqueue-animated-logo.gif'} alt={"animated-antqueue-logo"} />  */}
                                                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                            <lottie-player src="/antqueue-animated-logo-lottie-data.json" background="transparent" speed="1" style={{ width: "200px", height: "200px" }} loop autoplay></lottie-player>
                                                        </div>
                                                    </div>
                                                    <h3 style={{ color: '#fff' }} className="header text-center Margin-top--20">AntQueue Login</h3>
                                                </CardHeader>
                                            </CardHeader>
                                            <CardBody>
                                                <div className="row justify-content-center">
                                                    <div className="col-md-9">
                                                        <label style={{ color: '#fff' }}>Email Address</label>
                                                        <InputGroup className={`has-label ${registerEmailState}`}>
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText style={{ padding: '10px 10px 10px 10px', backgroundColor: '#fff' }}>
                                                                    <i className="nc-icon nc-email-85" />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                placeholder="Email Address"
                                                                name="email"
                                                                type="email"
                                                                onBlur={e => this.handleChange(e, "registerEmail", "email")}
                                                                onKeyDown={e => {
                                                                    if (e.keyCode === 13) {
                                                                        this.focusElement(this.refs['password']);
                                                                    }
                                                                }}
                                                            />
                                                            {this.state.registerEmailState === "has-danger" ? (
                                                                <label className="error">
                                                                    Please enter a valid email address.
                                                                </label>
                                                            ) : null}
                                                        </InputGroup>
                                                        <label style={{ color: '#fff' }}>Password</label>
                                                        <InputGroup className={`has-label ${registerPasswordState}`}>
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText style={{ padding: '10px 10px 10px 10px', backgroundColor: '#fff' }}>
                                                                    <i className="nc-icon nc-key-25" />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                placeholder="Password"
                                                                type="password"
                                                                name="password"
                                                                ref="password"
                                                                autoComplete="off"
                                                                onChange={e =>
                                                                    this.handleChange(e, "registerPassword", "password")
                                                                }
                                                                onKeyDown={e => {
                                                                    if (e.keyCode === 13) {
                                                                        this.handleSignIn();
                                                                    }
                                                                }}
                                                            />
                                                            {this.state.registerPasswordState === "has-danger" ? (
                                                                <label className="error">This field is required.</label>
                                                            ) : null}
                                                        </InputGroup>
                                                        <br />
                                                        {/* <FormGroup>
                                                            <FormGroup check>
                                                                <Label check>
                                                                    <Input
                                                                        type="checkbox"
                                                                        name="rememberMe"
                                                                        innerRef={this.state.rememberMe}
                                                                        onChange={e =>
                                                                            this.handleChange(e, "remember", "remember")
                                                                        }
                                                                    />
                                                                    <span className="form-check-sign" />
                                                                    Remember me.
                                                                </Label>
                                                            </FormGroup>
                                                        </FormGroup> */}
                                                        <div className="row">
                                                            <div className="col-md-5">
                                                                <Button
                                                                    color="success"
                                                                    onClick={this.handleSignIn}
                                                                    block
                                                                >
                                                                    Sign in
                                                                </Button>
                                                            </div>
                                                            <div className="col-md-7">
                                                                <Button
                                                                    color="primary"
                                                                    onClick={e => this.props.history.push("/forgot_password")}
                                                                    block
                                                                >
                                                                    Forgot Password
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-md-5">
                                                        <div className="site-link-button">
                                                            <GoogleLogin
                                                                clientId={info.google_client_id}
                                                                buttonText="Login"
                                                                onSuccess={this.responseGoogle}
                                                                onFailure={this.responseGoogle}
                                                                onRequest={this.onClickSocial}
                                                                cookiePolicy={'single_host_origin'}
                                                                render={renderProps => (
                                                                    <Button color="google" onClick={renderProps.onClick} disabled={renderProps.disabled} block>
                                                                        <i className="fa fa-google-plus" />
                                                                        Sign in with Google+
                                                                    </Button>
                                                                )}
                                                            />
                                                        </div> 
                                                         <div className="site-link-button">
                                                            <FacebookLogin
                                                                appId={info.facebook_app_id}
                                                                fields="name,email,picture"
                                                                onClick={this.onClickSocial}
                                                                callback={this.responseFacebook}
                                                                cssClass="btn btn-facebook btn-block"
                                                                icon="fa fa-facebook-square"
                                                            />
                                                        </div>
                                                    </div>  */}
                                                    <span style={{ color: '#fff' }} className="login-form-answer">Are you a new member? Please <a href="#" onClick={e => { e.preventDefault(); this.props.history.push("/register"); }}>Register.</a></span>
                                                </div>
                                            </CardBody>
                                            <CardFooter>
                                                <div style={{ color: '#fff', fontSize: '8px', marginTop: '25px' }} className="header text-center font-weight-normal text-capitalize">{"AntQueue v" + appVersion}</div>
                                            </CardFooter>
                                        </Card>
                                    </Col>
                                </Row>
                            </Container>
                            <div
                                className="full-page-background"
                                style={{
                                    backgroundImage: `url(${require("../../assets/img/bg/queue_4000x2000_4.jpg")})`,
                                    backgroundColor: "lightGrey"
                                }}
                            />
                        </div>
                    </div>
                </LoadingOverlay>
            </div>
        );
    };
}

export default withCookies(Login);
