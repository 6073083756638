import React, { useEffect, useState } from 'react';
import LoadingOverlay from "react-loading-overlay";
import {
  Col,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Row,
  Button,
  Input,
} from "reactstrap";
// import './App.css';
import { loadStripe } from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import Firebase from "firebase";
import { Redirect } from 'react-router-dom';

import config from '../config'

import '../assets/scss/dashboard.css';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(config.stripePublishKey);

if (!config.stripePublishKey) {
  console.error('**Stripe publishable key not set**');
}

// Custom styling can be passed to options when creating an Element.
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#e5424d',
      iconColor: '#e5424d'
    }
  }
};

const CardLogo = ({cardType}) => {
  if (cardType === 'visa') {
    return (
      <div style={{position: 'relative'}}>
        <div className="SVGInline SVGInline--cleaned SVG BrandIcon BrandIcon--size--32 Box-root Flex-flex">
            <svg className="SVGInline-svg SVGInline--cleaned-svg SVG-svg BrandIcon-svg BrandIcon--size--32-svg" height="32" width="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fillRule="evenodd">
                    <path d="M0 0h32v32H0z" fill="#00579f"></path>
                    <g fill="#fff" fillRule="nonzero">
                        <path d="M13.823 19.876H11.8l1.265-7.736h2.023zm7.334-7.546a5.036 5.036 0 0 0-1.814-.33c-1.998 0-3.405 1.053-3.414 2.56-.016 1.11 1.007 1.728 1.773 2.098.783.379 1.05.626 1.05.963-.009.518-.633.757-1.216.757-.808 0-1.24-.123-1.898-.411l-.267-.124-.283 1.737c.475.213 1.349.403 2.257.411 2.123 0 3.505-1.037 3.521-2.641.008-.881-.532-1.556-1.698-2.107-.708-.354-1.141-.593-1.141-.955.008-.33.366-.667 1.165-.667a3.471 3.471 0 0 1 1.507.297l.183.082zm2.69 4.806l.807-2.165c-.008.017.167-.452.266-.74l.142.666s.383 1.852.466 2.239h-1.682zm2.497-4.996h-1.565c-.483 0-.85.14-1.058.642l-3.005 7.094h2.123l.425-1.16h2.597c.059.271.242 1.16.242 1.16h1.873zm-16.234 0l-1.982 5.275-.216-1.07c-.366-1.234-1.515-2.575-2.797-3.242l1.815 6.765h2.14l3.18-7.728z"></path>
                        <path d="M6.289 12.14H3.033L3 12.297c2.54.641 4.221 2.189 4.912 4.049l-.708-3.556c-.116-.494-.474-.633-.915-.65z"></path>
                    </g>
                </g>
            </svg>
        </div>
        <div style={{position: 'absolute', top: '0px', bottom: '0px', left: '0px', right: '0px', borderRadius: '3.5px', boxShadow: 'rgba(33, 29, 51, 0.16) 0px 0px 0px 1px inset'}}></div>
      </div>
    )
  }
  if (cardType === 'amex') {
    return (
      <div style={{position: 'relative'}}>
        <div className="SVGInline SVGInline--cleaned SVG BrandIcon BrandIcon--size--32 Box-root Flex-flex">
            <svg className="SVGInline-svg SVGInline--cleaned-svg SVG-svg BrandIcon-svg BrandIcon--size--32-svg" height="32" width="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fillRule="evenodd">
                  <path fill="#0193CE" d="M0 0h32v32H0z"></path>
                  <path d="M17.79 18.183h4.29l1.31-1.51 1.44 1.51h1.52l-2.2-2.1 2.21-2.27h-1.52l-1.44 1.51-1.26-1.5H17.8v-.85h4.68l.92 1.18 1.09-1.18h4.05l-3.04 3.11 3.04 2.94h-4.05l-1.1-1.17-.92 1.17h-4.68v-.84zm3.67-.84h-2.53v-.84h2.36v-.83h-2.36v-.84h2.7l1.01 1.26-1.18 1.25zm-14.5 1.68h-3.5l2.97-6.05h2.8l.35.67v-.67h3.5l.7 1.68.7-1.68h3.31v6.05h-2.63v-.84l-.34.84h-2.1l-.35-.84v.84H8.53l-.35-1h-.87l-.35 1zm9.96-.84v-4.37h-1.74l-1.4 3.03-1.41-3.03h-1.74v4.04l-2.1-4.04h-1.4l-2.1 4.37h1.23l.35-1h2.27l.35 1h2.43v-3.36l1.6 3.36h1.05l1.57-3.36v3.36h1.04zm-8.39-1.85l-.7-1.85-.87 1.85h1.57z" fill="#FFF"></path>
                </g>
            </svg>
        </div>
        <div style={{position: 'absolute', top: '0px', bottom: '0px', left: '0px', right: '0px', borderRadius: '3.5px', boxShadow: 'rgba(33, 29, 51, 0.16) 0px 0px 0px 1px inset'}}></div>
      </div>
    )
  }
  if (cardType === 'mastercard') {
    return (
      <div style={{position: 'relative'}}>
        <div className="SVGInline SVGInline--cleaned SVG BrandIcon BrandIcon--size--32 Box-root Flex-flex">
          <svg className="SVGInline-svg SVGInline--cleaned-svg SVG-svg BrandIcon-svg BrandIcon--size--32-svg" height="32" width="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fillRule="evenodd">
              <path d="M0 0h32v32H0z" fill="#000"></path>
              <g fillRule="nonzero">
                <path d="M13.02 10.505h5.923v10.857H13.02z" fill="#ff5f00"></path>
                <path d="M13.396 15.935a6.944 6.944 0 0 1 2.585-5.43c-2.775-2.224-6.76-1.9-9.156.745s-2.395 6.723 0 9.368 6.38 2.969 9.156.744a6.944 6.944 0 0 1-2.585-5.427z" fill="#eb001b"></path><path d="M26.934 15.935c0 2.643-1.48 5.054-3.81 6.21s-5.105.851-7.143-.783a6.955 6.955 0 0 0 2.587-5.428c0-2.118-.954-4.12-2.587-5.429 2.038-1.633 4.81-1.937 7.142-.782s3.811 3.566 3.811 6.21z" fill="#f79e1b"></path>
              </g>
            </g>
          </svg>
        </div>
        <div style={{position: 'absolute', top: '0px', bottom: '0px', left: '0px', right: '0px', borderRadius: '3.5px', boxShadow: 'rgba(33, 29, 51, 0.16) 0px 0px 0px 1px inset'}}></div>
      </div>
    )
  }
  return (<div></div>);
};

const CardCell = ({paymentMethod, index, subscriptionStatus, isDefault, isUpgradePackage, invoice_no, processing, handleSubscription}) => {
  console.log(">>>>CardCell paymentMethod, index, subscriptionStatus, isDefault, isUpgradePackage, invoice_no, processing<<<<", paymentMethod, index, subscriptionStatus, isDefault, isUpgradePackage, invoice_no, processing);
  // console.log(">>>>CardCell subscriptionStatus<<<<", subscriptionStatus);
  const shortMonths = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const cardLabels = {
    visa: "Visa",
    amex: "American Express",
    mastercard: "Mastercard",
  };
  const { card, type } = paymentMethod;
  if (type !== 'card') return (<div></div>);

  return (
    <div className="ContentBlock Box-root Box-background--white Box-divider--light-bottom-1 Padding-horizontal--4 Padding-vertical--12">
        <div className="Box-root Flex-flex Flex-alignItems--center Flex-justifyContent--spaceBetween">
            <button className="UnstyledLink" style={{width: '100%'}}>
                <div className="Box-root" style={{pointerEvents: 'none', flexGrow: 1}}>
                    <div className="Box-root Flex-flex Flex-alignItems--center Flex-direction--row Flex-justifyContent--flexStart Flex-wrap--nowrap" style={{marginLeft: '-12px', marginTop: '-12px'}}>
                        <div className="Box-root Box-hideIfEmpty Margin-top--12 Margin-left--12" style={{pointerEvents: 'auto'}}>
                            <div className="Box-root" style={{pointerEvents: 'none'}}>
                                <div className="Box-root Flex-flex Flex-alignItems--center Flex-direction--row Flex-justifyContent--flexStart Flex-wrap--nowrap" style={{marginLeft: '-16px', marginTop: '-16px'}}>
                                    <div className="Box-root Box-hideIfEmpty Margin-top--16 Margin-left--16" style={{pointerEvents: 'auto'}}>
                                        <CardLogo cardType={card.brand}></CardLogo>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="Box-root Box-hideIfEmpty Margin-top--12 Margin-left--12" style={{pointerEvents: 'auto'}}>
                            <div className="Box-root" style={{pointerEvents: 'none'}}>
                                <div className="Box-root Flex-flex Flex-direction--column Flex-justifyContent--flexStart Flex-wrap--nowrap">
                                    <div className="Box-root Box-hideIfEmpty" style={{pointerEvents: 'auto'}}>
                                        <div className="Box-root" style={{pointerEvents: 'none'}}>
                                            <div className="Box-root Flex-flex Flex-alignItems--baseline Flex-direction--row Flex-justifyContent--flexStart Flex-wrap--nowrap" style={{marginLeft: '-8px', marginTop: '-8px'}}>
                                                <div className="Box-root Box-hideIfEmpty Margin-top--8 Margin-left--8" style={{pointerEvents: 'auto'}}>
                                                    <span className="Text-color--dark Text-fontSize--14 Text-fontWeight--medium Text-lineHeight--20 Text-numericSpacing--proportional Text-typeface--base Text-wrap--wrap Text-display--inline">
                                                        <span className="Text-color--inherit Text-fontSize--14 Text-fontWeight--medium Text-lineHeight--20 Text-numericSpacing--proportional Text-typeface--base Text-wrap--wrap Text-display--inline">
                                                            <div className="Box-root" style={{pointerEvents: 'none'}}>
                                                                <div className="Box-root Flex-flex Flex-alignItems--center Flex-direction--row Flex-justifyContent--flexStart Flex-wrap--nowrap" style={{marginLeft: '-4px', marginTop: '-4px'}}>
                                                                    <div className="Box-root Box-hideIfEmpty Margin-top--4 Margin-left--4" style={{pointerEvents: 'auto'}}><span>{cardLabels[card.brand]}</span></div>
                                                                    <div className="Box-root Box-hideIfEmpty Margin-top--4 Margin-left--4" style={{pointerEvents: 'auto'}}><span>•••• {card.last4}</span></div>
                                                                </div>
                                                            </div>
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="Box-root Box-hideIfEmpty" style={{pointerEvents: 'auto'}}>
                                      <span className="Text-color--gray Text-fontSize--14 Text-fontWeight--regular Text-lineHeight--20 Text-numericSpacing--proportional Text-typeface--base Text-wrap--wrap Text-display--inline">
                                        <span>Expires {shortMonths[card.exp_month]} {card.exp_year}</span>
                                      </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        { ( (parseInt(card.exp_year, 10) + 2000) < (new Date()).getFullYear() || ( (parseInt(card.exp_year, 10) + 2000) == (new Date()).getFullYear() && (parseInt(card.exp_month, 10)) < ((new Date()).getMonth() + 1)) ) ? 
                            ( <div className="Box-root Box-hideIfEmpty Margin-top--8 Margin-left--12" style={{pointerEvents: 'auto'}}>
                                <div className="Box-root Flex-flex">
                                    <div className="Box-root Flex-flex">
                                        <div className="Badge Badge--variant--status Box-root Box-background--red100 Flex-inlineFlex Flex-alignItems--center Flex-direction--row">
                                          <span className="Badge-text Text-color--red600 Text-fontSize--12 Text-fontWeight--medium Text-lineHeight--16 Text-numericSpacing--proportional Text-typeface--base Text-wrap--noWrap Text-display--inline">
                                          {/*</span><span className="Badge-text Text-color--blue600 Text-fontSize--12 Text-fontWeight--medium Text-lineHeight--16 Text-numericSpacing--proportional Text-typeface--base Text-wrap--noWrap Text-display--inline"> */}
                                            <span>Expired</span>
                                          </span>
                                        </div>
                                    </div>
                                </div>
                              </div> )
                                    : null }

                        { ( isDefault && (isUpgradePackage == false) && subscriptionStatus == "active") ?
                          ( <div className="Box-root Box-hideIfEmpty Margin-top--8 Margin-left--12" style={{pointerEvents: 'auto'}}>
                              <div className="Box-root Flex-flex">
                                  <div className="Box-root Flex-flex">
                                      <div className="Badge Badge--variant--status Box-root Box-background--blue100 Flex-inlineFlex Flex-alignItems--center Flex-direction--row">
                                        <span className="Badge-text Text-color--blue600 Text-fontSize--12 Text-fontWeight--medium Text-lineHeight--16 Text-numericSpacing--proportional Text-typeface--base Text-wrap--noWrap Text-display--inline">
                                        {/*</span><span className="Badge-text Text-color--blue600 Text-fontSize--12 Text-fontWeight--medium Text-lineHeight--16 Text-numericSpacing--proportional Text-typeface--base Text-wrap--noWrap Text-display--inline"> */}
                                          <span>Default</span>
                                        </span>
                                      </div>
                                  </div>
                              </div>
                            </div> ) : null }
                    </div>
                </div>
            </button>
            <div className="ButtonGroup Box-root" style={{pointerEvents: 'none'}}>
                <div className="Box-root Flex-flex Flex-alignItems--center Flex-direction--row Flex-justifyContent--flexStart Flex-wrap--nowrap" style={{marginLeft: '-8px', marginTop: '-8px'}}>
                    <div className="Box-root Box-hideIfEmpty Margin-top--8 Margin-left--8" style={{pointerEvents: 'auto'}}>
                        <div className="Box-root">
                            <div className="db-QuickActionsMenu-outerbox ListViewItem-actions Box-root Flex-flex Flex-justifyContent--flexEnd"> 

                            { (( isUpgradePackage || !isDefault ) && !( (parseInt(card.exp_year, 10) + 2000) < (new Date()).getFullYear() || ( (parseInt(card.exp_year, 10) + 2000) == (new Date()).getFullYear() && (parseInt(card.exp_month, 10)) < ((new Date()).getMonth() + 1)) ) ) ?                   
                              <button
                                id="submit-premium"
                                //className="w-full bg-pasha hover:bg-white hover:shadow-outline hover:text-pasha hover:border hover:border-black focus:shadow-outline text-white focus:bg-white focus:text-pasha font-light py-2 px-4 rounded-md"
                                className="btn btn-info text-capitalize btn btn-outline-primary"
                                type="button"
                                onClick={()=> {handleSubscription(index, paymentMethod)}}
                              >
                                <div className="">
                                  { window.location.pathname === '/BillingAlerts' ?
                                    (<div>{processing >= 1 ? 'Processing...' : 'Pay Now'}</div>) :
                                      (isUpgradePackage ?
                                        (<div>{processing >= 1 ? 'Subscribing...' : 'Subscribe'}</div>) 
                                        :  <div>{processing >= 1 ? 'Setting As Default...': 'Set As Default'}</div>)
                                  }
                                </div>
                              </button> : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <span></span>
    </div>
  );
}

const CheckoutForm = ({ 
  email,
  interval,
  invoice_no,
  productSelected,
  sublocationId,
  antqueueCustomerId,
  existingSubscriptionId,
  existingSubscriptionStatus,
  handleSubscriptionComplete
}) => {

  console.log('>>>const: CheckoutForm(email, interval, invoice_no, productSelected, sublocationId, antqueueCustomerId, existingSubscriptionId, existingSubscriptionStatus) <<<', email, interval, invoice_no, productSelected, sublocationId, antqueueCustomerId, existingSubscriptionId, existingSubscriptionStatus);

  const [errorToDisplay, setErrorToDisplay] = useState(null);
  const stripe = useStripe();
  const elements = useElements();

  const [processing, setProcessing] = useState(-2);
  const [accountInformation, setAccountInformation] = useState(null);
  const [cardHolderName, setCardHolderName] = useState('');
  const [customer, setCustomer] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState(null);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(null);
  const [subscriptionId, setSubscriptionId] = useState(existingSubscriptionId);
  const [subscriptionStatus, setSubscriptionStatus] = useState(existingSubscriptionStatus);
  const [subscriptionPackageId, setSubscriptionPackageId] = useState(null);
  const [subscriptionLatestInvoiceId, setSubscriptionLatestInvoiceId] = useState(null);
  const [isListingPaymentMethods, setIsListingPaymentMethods] = useState(false);
  const [addNewPaymentMethod, setAddNewPaymentMethod] = useState(false);
  
  const packageId = productSelected.id;
  const [isLoading, setIsLoading] = useState(true);


   // Handle real-time validation errors from the card Element.
   const handleChange = (event) => {
    //console.log("handleChange>>>1, event",event);
    if (event.error) {
      setErrorToDisplay(event.error.message);
    } else {
      setErrorToDisplay(null);
    }
  }

  // Send email confirmation of payment action
  const sendEmail = (email, text) => {
    //console.log('sendEmail', {email, text});
    return Firebase.functions().httpsCallable('sendMail')({email: email, subject: 'Thanks for your Subscription!', text: text})
  };
  // let functions;
  // let subscribingMethod = -1;

  useEffect(() => {
    fetchPaymentMethods();
  }, []);

  function handlePaymentThatRequiresCustomerAction({
    subscription,
    invoice,
    priceId,
    paymentMethodId,
    isRetry,
    message
  }) {

    console.log('>>>FUNC1: handlePaymentThatRequiresCustomerAction (subscription, invoice, priceId, paymentMethodId, isRetry, message) <<<', subscription, invoice, priceId, paymentMethodId, isRetry, message);

    if (message === 'no changes') {
      console.log('>>>FUNC2: handlePaymentThatRequiresCustomerAction (subscription, invoice, priceId, paymentMethodId, isRetry, message) <<<', subscription, invoice, priceId, paymentMethodId, isRetry, message);
      return { subscription, priceId, paymentMethodId, message };
    }
    if (subscription && subscription.status == "active") {
      console.log('>>>FUNC3: handlePaymentThatRequiresCustomerAction(subscription, invoice, priceId, paymentMethodId, isRetry, message) <<<', subscription, invoice, priceId, paymentMethodId, isRetry, message);
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    }

    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    let paymentIntent = invoice ? invoice.payment_intent : subscription.latest_invoice.payment_intent;
    console.log(">>>FUNC4: handlePaymentThatRequiresCustomerAction paymentIntent <<<", paymentIntent);

    if ( (paymentIntent && paymentIntent.status === 'requires_action') ||
          (isRetry === true && paymentIntent && paymentIntent.status === 'requires_payment_method') ) {
      console.log('>>>FUNC5: handlePaymentThatRequiresCustomerAction (subscription, invoice, priceId, paymentMethodId, isRetry, message) <<<', subscription, invoice, priceId, paymentMethodId, isRetry, message);
      console.log('>>>FUNC6: handlePaymentThatRequiresCustomerAction paymentIntent.status <<<', paymentIntent.status);
      console.log('>>>FUNC7: handlePaymentThatRequiresCustomerAction paymentIntent.client_secret <<<', paymentIntent.client_secret);
      return stripe
        .confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId,
        })
        .then((result) => {
          if (result.error) {
            console.log('>>>FUNC8: handlePaymentThatRequiresCustomerAction result <<<', result);
            // start code flow to handle updating the payment details
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc)
            throw result;
          } else {
            if (result.paymentIntent.status === 'succeeded') {
              console.log('>>>FUNC9: handlePaymentThatRequiresCustomerAction result.paymentIntent.status === succeeded (result) <<<', result);
              // There's a risk of the customer closing the window before callback
              // execution. To handle this case, set up a webhook endpoint and
              // listen to invoice.payment_succeeded. This webhook endpoint
              // returns an Invoice.
              return {
                priceId: priceId,
                subscription: subscription,
                invoice: invoice,
                paymentMethodId: paymentMethodId,
              };
            }
          }
        });
    } else {
      // No customer action needed
      console.log('>>>FUNC10: handlePaymentThatRequiresCustomerAction (subscription, invoice, priceId, paymentMethodId, isRetry, message) <<<', subscription, invoice, priceId, paymentMethodId, isRetry, message);
      setIsLoading(false);
      return { subscription, priceId, paymentMethodId };
    }
  }

  function handleRequiresPaymentMethod({ subscription, paymentMethodId, priceId, message, }) {

    console.log('>>>FUNC1: handleRequiresPaymentMethod(subscription, paymentMethodId, priceId, message) <<<', subscription, paymentMethodId, priceId, message);

    if (message === 'no changes') {
      return { subscription, priceId, paymentMethodId, message };
    }
    if (subscription.status == "active") {
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    } else if (
      subscription.latest_invoice.payment_intent.status ===
      'requires_payment_method'
    ) {
      // Using localStorage to store the state of the retry here
      // (feel free to replace with what you prefer)
      // Store the latest invoice ID and status
      localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id);
      localStorage.setItem('latestInvoicePaymentIntentStatus',subscription.latest_invoice.payment_intent.status);
      throw new Error('Your card was declined. Please try again with another card.');
    } else {
      setIsLoading(false);
      return { subscription, priceId, paymentMethodId };
    }
  }

  // function retrySubscriptionWithNewPaymentMethod({ interval, packageId, paymentMethodId, sublocationId, antqueueCustomerId }) {

  //   console.log('retrySubscriptionWithNewPaymentMethod>>>1 (interval, packageId, paymentMethodId, sublocationId, antqueueCustomerId) <<<', interval, packageId, paymentMethodId, sublocationId, antqueueCustomerId);
  //   // initializeFirebase();
  //   Firebase.functions().httpsCallable('stripe_retrySubscriptionWithNewPaymentMethod')({ interval, packageId, paymentMethodId, sublocationId, antqueueCustomerId })
  //       .then((response) => {
  //         // return response.json();
  //         return response.data;
  //       })
  //       // If the card is declined, display an error to the user.
  //       .then((result) => {
  //         if (result.error) {
  //           console.log('retrySubscriptionWithNewPaymentMethod>>>2 result.error', result.error);
  //           // The card had an error when trying to attach it to a customer
  //           throw result;
  //         }
  //         console.log('retrySubscriptionWithNewPaymentMethod>>>3 result.data', result.data);
  //         return result.data;
  //       })
  //       // Normalize the result to contain the object returned
  //       // by Stripe. Add the addional details we need.
  //       .then((result) => {
  //         console.log('retrySubscriptionWithNewPaymentMethod>>>4 subscription', result.subscription);
  //         return {
  //           // Use the Stripe 'object' property on the
  //           // returned result to understand what object is returned.
  //           subscription: result.subscription,
  //           invoice: result.subscription.latest_invoice,
  //           paymentMethodId: paymentMethodId,
  //           priceId: result.priceId,
  //           message: result.message,
  //           isRetry: true,
  //         };
  //       })
  //       // Some payment methods require a customer to do additional
  //       // authentication with their financial institution.
  //       // Eg: 2FA for cards.
  //       .then(handlePaymentThatRequiresCustomerAction)
  //       // If attaching this card to a Customer object succeeds,
  //       // but attempts to charge the customer fail. You will
  //       // get a requires_payment_method error.
  //       .then(handleRequiresPaymentMethod)
  //       // No more actions required. Provision your service for the user.
  //       .then(onSubscriptionComplete)
  //       .catch((error) => {
  //         setProcessing(-2);
  //         setIsLoading(false);
  //         // An error has happened. Display the failure to the user here.
  //         // We utilize the HTML element we created.
  //         if (error != undefined && error.message != undefined) {
  //           setErrorToDisplay(error.message); console.log("setErrorToDisplay path1", error.message);
  //         } else if (error != undefined && error.error.message != undefined) {
  //           setErrorToDisplay(error.error.message); console.log("setErrorToDisplay path2", error.error.message);
  //         }
  //         //s1etErrorToDisplay(error.message || error.error.decline_code);
  //         //s1etErrorToDisplay(error.error.message || error.error.decline_code);
  //         //s1etErrorToDisplay(error && error.error && error.error.decline_code);
  //       });
  // }

  function retryInvoiceWithNewPaymentMethod({interval, packageId, paymentMethodId, sublocationId, antqueueCustomerId}) {
    
    console.log('retryInvoiceWithNewPaymentMethod>>>1 ({interval, packageId, paymentMethodId, sublocationId, antqueueCustomerId}) <<<', interval, packageId, paymentMethodId, sublocationId, antqueueCustomerId);
    // initializeFirebase();
    Firebase.functions().httpsCallable('stripe_retryInvoiceWithNewPaymentMethod')({interval, packageId, paymentMethodId, sublocationId, antqueueCustomerId})
        .then((response) => {
          // return response.json();
          return response.data;
        })
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.error) {
            // The card had an error when trying to attach it to a customer
            console.log('retryInvoiceWithNewPaymentMethod>>>2 result.error', result.error);
            throw result;
          }
          console.log('retryInvoiceWithNewPaymentMethod>>>3 result.data', result.data);
          return result.data;
        })
        // Normalize the result to contain the object returned
        // by Stripe. Add the addional details we need.
        .then((result) => {
          return {
            // Use the Stripe 'object' property on the
            // returned result to understand what object is returned.
            subscription: result.subscription,
            invoice: result.invoice,
            paymentMethodId: paymentMethodId,
            priceId: result.priceId,
            message: result.message,
            isRetry: true,
          };
        })
        // Some payment methods require a customer to do additional
        // authentication with their financial institution.
        // Eg: 2FA for cards.
        .then(handlePaymentThatRequiresCustomerAction)
        // No more actions required. Provision your service for the user.
        .then(onSubscriptionComplete)
        .catch((error) => {
          setProcessing(-2);
          setIsLoading(false);
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          // s1etErrorToDisplay(error && error.error && error.error.decline_code);
          if (error != undefined && error.message != undefined) {
            setErrorToDisplay(error.message); console.log("setErrorToDisplay path1", error.message);
          } else if (error != undefined && error.error.message != undefined) {
            setErrorToDisplay(error.error.message); console.log("setErrorToDisplay path2", error.error.message);
          }
        })
  }

  async function onSubscriptionComplete(result) {

    console.log('>>>FUNC: onSubscriptionComplete(result) <<<', result);

    //console.log(result);
    // Payment was successful. Provision access to your service.
    // Remove invoice from localstorage because payment is now complete.
    // clearCache();
    if (result && !result.subscription && result.message !== 'no changes') {
        const subscription = { id: result.invoice.subscription };
        result.subscription = subscription;
        localStorage.clear();
    }

    
    // setAccountInformation(result);
    //console.log('accountInformation', result);
    setProcessing(-2);
    
    // const text = `
    // You have started the package ${productSelected.name}.
    // Price: ${productSelected.price}, ${interval}
    // `;
    // try {
    //   await sendEmail(email, text);
    // } catch (error) {
    //   //console.log('SendEmail', {error});
    // }
    handleSubscriptionComplete(result);
    // Change your UI to show a success message to your customer.
    // onSubscriptionSampleDemoComplete(result);
    // Call your backend to grant access to your service based on
    // the product your customer subscribed to.
    // Get the product by using result.subscription.price.product
    //console.log('handleSubscriptionComplete in progress! >> setIsLoading: false', 'isLoading', isLoading, '//result.//subscription.price.product', result/*, result.subscription.price.product*/);
    setIsLoading(false);
  }

  function handleSubscription(index, paymentMethod) {
    
    console.log('>>>FUNC: handleSubscription(index, paymentMethod) <<<', index, paymentMethod);
    console.log('processing', processing);

    setIsLoading(true);
    if (processing !== -2) {
      //console.log('already processing: ', processing);
      setIsLoading(false);
      return;
    }
    setProcessing(index);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // If a previous payment was attempted, get the lastest invoice
    const latestInvoicePaymentIntentStatus = localStorage.getItem(
      'latestInvoicePaymentIntentStatus'
    );
    console.log("handleSubscription>>>1 latestInvoicePaymentIntentStatus", latestInvoicePaymentIntentStatus)
    
    //console.log('[PaymentMethod]', paymentMethod);
    const paymentMethodId = paymentMethod.id;
    if (latestInvoicePaymentIntentStatus === 'requires_payment_method') {
      console.log("handleSubscription>>>2 latestInvoicePaymentIntentStatus === 'requires_payment_method'", latestInvoicePaymentIntentStatus);
      // Update the payment method and retry invoice payment
      const invoiceId = localStorage.getItem('latestInvoiceId');
      console.log('handleSubscription>>>3 (interval, packageId, paymentMethodId, sublocationId, antqueueCustomerId) <<<', interval, packageId, paymentMethodId, sublocationId, antqueueCustomerId);
      // console.log('handleSubscription>>>3 (customer?.id, paymentMethodId, invoiceId, priceId) <<<', customer?.id, paymentMethodId, invoiceId, -1);
      retryInvoiceWithNewPaymentMethod({
        // customerId: customer?.id,
        // paymentMethodId,
        // invoiceId,
        // priceId: "-1", // priceId,
        interval,
        packageId,
        paymentMethodId,
        sublocationId,
        antqueueCustomerId
      });
      // console.log('handleSubscription>>>3 (interval, packageId, paymentMethodId, sublocationId, antqueueCustomerId) <<<', interval, packageId, paymentMethodId, sublocationId, antqueueCustomerId);
      // retrySubscriptionWithNewPaymentMethod({
      //   interval,
      //   packageId,
      //   paymentMethodId,
      //   sublocationId,
      //   antqueueCustomerId
      // });
    } else {
      console.log("handleSubscription>>>4 latestInvoicePaymentIntentStatus !== 'requires_payment_method'", latestInvoicePaymentIntentStatus);
      // Create the subscription
      createSubscription({
        interval,
        packageId,
        paymentMethodId,
        sublocationId,
        antqueueCustomerId
      });
      // setIsLoading(false);
    }
  }

  // function createSubscription({ paymentMethodId }) {
  //   const url = 'http://localhost:5001/antqueuetest-dev04/us-central1/createSubscription';
  //   // const url = 'https://us-central1-antqueuetest-dev04.cloudfunctions.net/createSubscription';
  //   return (
  //     fetch(url, {
  //       method: 'post',
  //       headers: {
  //         'Content-type': 'application/json',
  //       },
  //       body: JSON.stringify({
  //         email,
  //         interval,
  //         packageId,
  //         paymentMethodId,
  //         sublocationId,
  //         antqueueCustomerId,
  //       }),
  //     })
  //       .then((response) => {
  //         return response.json();
  //       })
  //       // If the card is declined, display an error to the user.
  //       .then((result) => {
  //         if (result.error) {
  //           // The card had an error when trying to attach it to a customer
  //           throw result;
  //         }
  //         return result;
  //       })
  //       // Normalize the result to contain the object returned
  //       // by Stripe. Add the addional details we need.
  //       .then((result) => {
  //         return {
  //           // Use the Stripe 'object' property on the
  //           // returned result to understand what object is returned.
  //           subscription: result,
  //           paymentMethodId: paymentMethodId,
  //           priceId: productSelected.name,
  //         };
  //       })
  //       // Some payment methods require a customer to do additional
  //       // authentication with their financial institution.
  //       // Eg: 2FA for cards.
  //       .then(handlePaymentThatRequiresCustomerAction)
  //       // If attaching this card to a Customer object succeeds,
  //       // but attempts to charge the customer fail. You will
  //       // get a requires_payment_method error.
  //       .then(handleRequiresPaymentMethod)
  //       // No more actions required. Provision your service for the user.
  //       .then(onSubscriptionComplete)
  //       .catch((error) => {
  //       setProcessing(-2);
  //       setIsLoading(false);
  //       // An error has happened. Display the failure to the user here.
  //       // We utilize the HTML element we created.
  //       //s1etErrorToDisplay(error && error.error && error.error.decline_code);
  //       if (error != undefined && error.message != undefined) {
  //          s1etErrorToDisplay(error.message);
  //       } else if (error != undefined && error.error.message != undefined) {
  //          s1etErrorToDisplay(error.error.message);
  //       }
  //       })
  //   );
  // }

  function createSubscription ({interval, packageId, paymentMethodId, sublocationId, antqueueCustomerId}) {

    console.log('>>>FUNC: createSubscription(interval, packageId, paymentMethodId, sublocationId, antqueueCustomerId) <<<', interval, packageId, paymentMethodId, sublocationId, antqueueCustomerId);
    // initializeFirebase();
    Firebase.functions().httpsCallable('stripe_createSubscription')({ interval, packageId, paymentMethodId, sublocationId, antqueueCustomerId })
        .then((response) => {
          // return response.json();
          return response.data;
        })
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.error) {
            // The card had an error when trying to attach it to a customer
            throw result;
          }
          return result.data;
        })
        // Normalize the result to contain the object returned
        // by Stripe. Add the addional details we need.
        .then((result) => {
          return {
            // Use the Stripe 'object' property on the
            // returned result to understand what object is returned.
            subscription: result.subscription,
            paymentMethodId: paymentMethodId,
            priceId: result.priceId,
            message: result.message,
          };
        })
        // Some payment methods require a customer to do additional
        // authentication with their financial institution.
        // Eg: 2FA for cards.
        .then(handlePaymentThatRequiresCustomerAction)
        // If attaching this card to a Customer object succeeds,
        // but attempts to charge the customer fail. You will
        // get a requires_payment_method error.
        .then(handleRequiresPaymentMethod)
        // No more actions required. Provision your service for the user.
        .then(onSubscriptionComplete)
        .catch((error) => {
          setProcessing(-2);
          setIsLoading(false);
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          if (error != undefined && error.message != undefined) {
            setErrorToDisplay(error.message); console.log("setErrorToDisplay path1", error.message);
          } else if (error != undefined && error.error.message != undefined) {
            setErrorToDisplay(error.error.message); console.log("setErrorToDisplay path2", error.error.message);
          }
          //s1etErrorToDisplay(error.message || error.error.decline_code);
          //s1etErrorToDisplay(error.error.message || error.error.decline_code);
          //s1etErrorToDisplay(error && error.error && error.error.decline_code);
        });
  }

   // Handle form submission.
  const handleSubmit = async (event) => {

    //console.log('>>>FUNC: handleSubmit(event) <<<', event);

    // Block native form submission.
    event.preventDefault();

      setProcessing(-1);
      setIsLoading(true);
  
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }
  
      // Get a reference to a mounted CardElement. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.
      const cardElement = elements.getElement(CardElement);
  
      // If a previous payment was attempted, get the lastest invoice
      const latestInvoicePaymentIntentStatus = localStorage.getItem(
        'latestInvoicePaymentIntentStatus'
      );
  
      // Use your card Element with other Stripe.js APIs
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: cardHolderName,
        },
      });
  
      // const paymentMethodId = 0;
  
      if (error) {
        //console.log('[createPaymentMethod error]', error);
        setProcessing(-2);
        setIsLoading(false);
        // Inform the user if there was an error.
        //s1etErrorToDisplay(error && error.message);
        if (error != undefined && error.message != undefined) {
          setErrorToDisplay(error.message); console.log("setErrorToDisplay path1", error.message);
        } else if (error != undefined && error.error.message != undefined) {
          setErrorToDisplay(error.error.message); console.log("setErrorToDisplay path2", error.error.message);
        }
      } else {
        //console.log('[PaymentMethod]', paymentMethod);
        const paymentMethodId = paymentMethod.id;
        await Firebase.firestore().collection('Stripe_PaymentMethods').doc(paymentMethodId).set(paymentMethod);
        console.log("handleSubmit>>>1 latestInvoicePaymentIntentStatus", latestInvoicePaymentIntentStatus);
        if (latestInvoicePaymentIntentStatus === 'requires_payment_method') {
          // Update the payment method and retry invoice payment
          const invoiceId = localStorage.getItem('latestInvoiceId');
          console.log('handleSubmit>>>3 (interval, packageId, paymentMethodId, sublocationId, antqueueCustomerId) <<<', interval, packageId, paymentMethodId, sublocationId, antqueueCustomerId);
          // console.log('handleSubmit>>>2 customer?.id, paymentMethodId, invoiceId, priceId <<<', customer?.id, paymentMethodId, invoiceId, -1);
          retryInvoiceWithNewPaymentMethod({
            // customerId: customer?.id,
            // paymentMethodId,
            // invoiceId,
            // priceId: "-1", // priceId,
            interval,
            packageId,
            paymentMethodId,
            sublocationId,
            antqueueCustomerId
          });
          // console.log('handleSubmit>>>3 (interval, packageId, paymentMethodId, sublocationId, antqueueCustomerId) <<<', interval, packageId, paymentMethodId, sublocationId, antqueueCustomerId);
          // retrySubscriptionWithNewPaymentMethod({
          //   interval,
          //   packageId,
          //   paymentMethodId,
          //   sublocationId,
          //   antqueueCustomerId
          // });
        } else {
          // Create the subscription
          createSubscription({
            interval,
            packageId,
            paymentMethodId,
            sublocationId,
            antqueueCustomerId
          });
          // setIsLoading(false);
        }
      }
  };

  const fetchPaymentMethods = () => {

    // console.log('>>1>const: fetchPaymentMethods() /// [email, isListingPaymentMethods, customer, defaultPaymentMethod, subscriptionId, subscriptionStatus,  subscriptionPackageId, subscriptionLatestInvoiceId, subscription] <<<', email, isListingPaymentMethods, customer, defaultPaymentMethod, subscriptionId, subscriptionStatus,  subscriptionPackageId, subscriptionLatestInvoiceId, subscription);
    
    if (isListingPaymentMethods) {
      return;
    }

    // console.log('>>2>const: fetchPaymentMethods() /// [email, isListingPaymentMethods, customer, defaultPaymentMethod, subscriptionId, subscriptionStatus,  subscriptionPackageId, subscriptionLatestInvoiceId, subscription] <<<', email, isListingPaymentMethods, customer, defaultPaymentMethod, subscriptionId, subscriptionStatus,  subscriptionPackageId, subscriptionLatestInvoiceId, subscription);

    setIsListingPaymentMethods(true);
    // initializeFirebase();
    if (!email || customer || isListingPaymentMethods) {
      return;
    }

    console.log('>>3>const: fetchPaymentMethods() /// [email, isListingPaymentMethods, customer, defaultPaymentMethod, subscriptionId, subscriptionStatus,  subscriptionPackageId, subscriptionLatestInvoiceId] <<<', email, isListingPaymentMethods, customer, defaultPaymentMethod, subscriptionId, subscriptionStatus,  subscriptionPackageId, subscriptionLatestInvoiceId);

    Firebase.functions().httpsCallable('stripe_listCustomerPaymentMethods')({ email, sublocationId, subscriptionId })
      .then((response) => {
        console.log('stripe_listCustomerPaymentMethods', {response});
        const res = response.data;
        if (res.status === 'ok') {
            if (subscriptionId == -1) {
              const { customer, paymentMethods } = res.data;
              setCustomer(customer);
              setPaymentMethods(paymentMethods || [1, 2, 3]);
            } else {
              const { customer, paymentMethods, subscriptionInfo } = res.data;
              setCustomer(customer);
              setPaymentMethods(paymentMethods || [1, 2, 3]);
              setDefaultPaymentMethod(subscriptionInfo.default_payment_method);
              setSubscriptionId(subscriptionInfo.id);
              setSubscriptionStatus(subscriptionInfo.status);
              setSubscriptionPackageId(subscriptionInfo.metadata.packageId);
              setSubscriptionLatestInvoiceId(subscriptionInfo.latest_invoice.id);
              console.log("setDefaultPaymentMethod",subscriptionInfo.default_payment_method);
              console.log("setSubscriptionId",subscriptionInfo.id);
              console.log("setSubscriptionStatus",subscriptionInfo.status);
              console.log("setSubscriptionPackageId",subscriptionInfo.metadata.packageId);
              console.log("setSubscriptionLatestInvoiceId", subscriptionInfo.latest_invoice);
            }
        }
        setIsListingPaymentMethods(false);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsListingPaymentMethods(false);
        setIsLoading(false);
        //console.log('stripe_listCustomerPaymentMethods', {error});
      });
  };

  //console.log("paymentMethods", paymentMethods);

  return (
    <>
    <LoadingOverlay
        active={isLoading}
        spinner
        text='Loading'
        className='content'
    >
    <div id="payment-form" className="flex justify-center">

      <div className="w-full inline-block border p-4 rounded-md">

        {(subscriptionStatus == "active") ? 
            (
              <div className="timeline-heading" style={{textAlign:'Right'}}>Subscription Status: &nbsp;&nbsp;<span className="badge badge-pill badge-success">{subscriptionStatus}</span></div>
            ) 
              : (
                (subscriptionStatus == "no_subscription_yet") ? 
                    (
                      <div className="timeline-heading" style={{textAlign:'Right'}}>Subscription Status: &nbsp;&nbsp;<span className="badge badge-pill badge-danger">{subscriptionStatus}</span></div>
                    ) 
                      : (
                          <div className="timeline-heading" style={{textAlign:'Right'}}>Subscription Status: &nbsp;&nbsp;<span className="badge badge-pill badge-warning">{subscriptionStatus}</span></div>
                        )
                )}

        {(paymentMethods && paymentMethods.data && paymentMethods.data.length <= 1) ? (
        <div className="font-bold text-xl mb-4">
          Enter your card details. Your subscription will start now.
        </div>
        ) : ( 
        <div className="font-bold text-xl mb-4">
          Choose an existing payment method or enter your card details. Your subscription will be updated immediately.
        </div>
        )}
        
        <p className="text-gray-700 text-base">
          → Total due now {' '} <span className="font-bold">{productSelected.price}</span>
        </p>
        <p className="text-gray-700 text-base mb-5">
          → Selected package {' '} <span className="font-bold">{productSelected.name}</span>
        </p>

        {paymentMethods && paymentMethods.data &&
          (<div className="font-normal mb-5">
            {(paymentMethods.data.length >= 1) ? (
              <div className="font-bold text-xl mb-4">
                Existing payment methods
              </div>) : (
              <div className="font-bold text-xl mb-4">
                Payment method
              </div>)}
            <div className="">
            {paymentMethods.data.map((method, index) => (
              <div key={index}>
                {/*<div className="alert alert-info alert-dismissible fade show">
                  <button type="button" aria-hidden="true" className="close" data-dismiss="alert" aria-label="Close"><i className="nc-icon nc-simple-remove"></i></button>
                  <span>
                    <div style={{fontSize:"10px", fontColor:"white"}}>

                      <div style={{fontSize:'10px'}}>
                        <div>
                          <span className="font-bold">Subscription_ID...................:</span><span className="font-normal">{subscriptionId}</span>
                          <span className="font-bold">subscriptionLatestInvoiceId...................:</span><span className="font-normal">{subscriptionLatestInvoiceId}</span>
                        </div>
                        <div>
                          <span className="font-bold">Subscription_Package_ID:</span><span className="font-normal">{subscriptionPackageId}</span> <span> ||||| </span>
                          <span className="font-bold">Method_ID.............................:</span><span className="font-normal">{method.id}</span>
                        </div>
                        <div>
                          <span className="font-bold">Selected_Package_ID........:</span><span className="font-normal">{packageId}</span> <span> ||||| </span>
                          <span className="font-bold">defaultPaymentMethod...:</span><span className="font-normal">{defaultPaymentMethod}</span>
                        </div>
                        <div>
                          <span className="font-bold">Subscription_Status........:</span><span className="font-normal">{subscriptionStatus}</span> <span> ||||| </span>
                          <span className="font-bold"></span><span className="font-normal"></span>
                        </div>
                      </div>
                    </div>
                  </span>
                </div>*/}
                
                <CardCell
                  paymentMethod={method}
                  key={index}
                  index={index}
                  invoice_no={subscriptionLatestInvoiceId}//{invoice_no}
                  subscriptionStatus={subscriptionStatus}
                  isDefault={method.id === defaultPaymentMethod} //customer.invoice_settings.default_payment_method} //subscriptionInfo.data.default_payment_method}
                  isUpgradePackage={packageId !== subscriptionPackageId}
                  processing={processing === index}
                  handleSubscription={handleSubscription}>
                </CardCell>
              </div>
              )
            )}
            </div>
          </div>
          )}

        {!addNewPaymentMethod ? (
          <div className="w-full">
            <Button className="btn btn-info text-capitalize btn btn-secondary" onClick={()=>{setAddNewPaymentMethod(true)}}>Add a new payment method</Button>
          </div>
        ) : (
        <div className="w-full">
          <div className="font-bold text-xl mb-4">
            New payment method
          </div>
          <div className="flex flex-wrap -mx-3 mb-2">
            <div className="w-full px-3 md:mb-0">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Full name
              </label>
              <Input
                className="appearance-none block w-full bg-gray-200 border rounded-md py-3 px-2 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="name"
                type="text"
                placeholder="First and last name"
                required
                onChange={e => setCardHolderName(e.target.value)}
              />
            </div>
          </div>
          <form id="payment-form" onSubmit={handleSubmit}>
            <div className="flex flex-wrap -mx-3 mb-3">
              <div className="w-full px-3 mb-0">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Card
                </label>
                <div
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded-md py-3 px-2 leading-tight focus:outline-none focus:bg-white"
                  id="card-element"
                >
                  <CardElement
                      options={CARD_ELEMENT_OPTIONS}
                      onChange={handleChange}
                      //options={{
                        //style: {
                        //  base: {
                        //    color: '#266dd3',
                        //    fontSize: '16px',
                        //    fontWeight: '600',
                        //    fontFamily: '"Open Sans", sans-serif',
                        //    fontSmoothing: 'antialiased',
                        //    '::placeholder': {
                        //      color: '#CFD7DF',
                        //    },
                        //  },
                        //  invalid: {
                        //    color: '#e5424d',
                        //    ':focus': {
                        //      color: '#303238',
                        //    },
                        //  },
                        //},
                      //}}
                  />
                </div>
                <div className="form-group has-label has-danger">
                  <label id="error" className="error text-base mt-2" htmlFor="error"  role="alert">
                    {errorToDisplay ? errorToDisplay : null}
                  </label>
                </div>
              </div>
            </div>
            <button
              id="submit-premium"
              //className="w-full bg-pasha hover:bg-white hover:shadow-outline hover:text-pasha hover:border hover:border-black focus:shadow-outline text-white focus:bg-white focus:text-pasha font-light py-2 px-4 rounded-md"
              className="btn btn-info text-capitalize btn btn-secondary"
              type="submit"
            >
              <div className="">
                {invoice_no ?
                <div>{processing === -1 ? 'Processing...' : 'Pay Now'}</div>
                : <div>{processing === -1 ? 'Saving...' : 'Save Card'}</div>
                }
              </div>
            </button>
          </form>
        </div>
        )}
      </div>
    </div>
    </LoadingOverlay>
    </>
  );
};

const PaymentForm = (props) => (
  <Elements stripe={stripePromise}>
    <CheckoutForm {...props} />
  </Elements>
);

export default PaymentForm;
