import React from "react";
import LoadingOverlay from 'react-loading-overlay';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Label,
  Container,
  Col,
  Row
} from "reactstrap";
import Firebase from "firebase";
import config from "../../config";
import info from '../../info';

class DeleteMobileUserAccount extends React.Component {
  constructor(props) {
    super(props);

    if (!Firebase.apps.length) {
      Firebase.initializeApp(config);
    }

    this.state = {
      email: null,
      error: '',
      success: false,
      validCode: null,
      verifiedCode: false,
    };
  }

  async componentDidMount() {
    const { actionCode } = this.props;
    const invalidExpiredErr = "Invalid or expired action code.";  
    
    if (!actionCode) {
      this.setState({ error: invalidExpiredErr, validCode: false, verifiedCode: true });
      console.error({ location: 1, action: "deleteMobileUserAccount", error: "No action code provided." });
      return;
    }
  
    try {
      const snapshot = await Firebase.firestore()
        .collection('User_Account_Deletion_Requests')
        .where('token', '==', actionCode)
        .get();
  
      if (snapshot.empty) {
        this.setState({ error: invalidExpiredErr, validCode: false, verifiedCode: true });
        console.error({ location: 2, action: "deleteMobileUserAccount", error: "No matching token found." });
        return;
      }
  
      const data = snapshot.docs[0].data();
      const currentTime = Date.now();
  
      if (currentTime > data.expiration) {
        this.setState({ error: invalidExpiredErr, validCode: false, verifiedCode: true });
        console.error({ location: 3, action: "deleteMobileUserAccount", error: "Action code has expired." });
        return;
      }
  
      this.setState({ email: data.email });
  
      await Firebase.functions().httpsCallable('deleteUserAccountUsingEmailAndToken')({
        email: data.email,
        token: actionCode
      });
  
      this.setState({ success: true, verifiedCode: true, validCode: true });
  
    } catch (error) {
      this.setState({ error: error.message, validCode: false, verifiedCode: true });
      console.error({ location: 4, action: "deleteMobileUserAccount", error: error.message });
    }
  }

  // componentDidMount() {
  //   console.log("running DeleteMobileUserAccount.jsx");
  //   const { actionCode, token } = this.props;
  //   console.log("token", token);

  //   if (token) {
  //     Firebase.firestore().collection('User_Account_Deletion_Tokens')
  //       .where('token', '==', token)
  //       .get()
  //       .then(snapshot => {
  //         if (snapshot.empty) {
  //           this.setState({ error: 'Invalid or expired link.' });
  //           return;
  //         }

  //         const data = snapshot.docs[0].data();
  //         const expirationTime = data.expiration;
  //         const currentTime = new Date().getTime();

  //         if (currentTime > expirationTime) {
  //           this.setState({ error: 'Link has expired.' });
  //           return;
  //         }

  //         this.setState({
  //           email: data.email,
  //           verifiedCode: true,
  //           validCode: token
  //         });

  //       }).then(() => {

  //         // Delete the mobile user account
  //         return Firebase.functions().httpsCallable('deleteUserAccountUsingEmailAndToken')({
  //             email: this.state.email,
  //             token: this.state.validCode
  //         });
        
  //       }).then(() => {
  //         this.setState({ success: true });
  //       })
  //       .catch(error => {
  //         console.error('Error deleting user:', error);
  //         this.setState({ error: 'Failed to delete account.' });
  //       });
  //   }
  // }

  // render() {
  //   const { error, success } = this.state;

  //   return (
  //     <div className="wrapper wrapper-full-page" ref="fullPages">
  //       <LoadingOverlay
  //         active={this.state.loading}
  //         spinner
  //         text='Loading'
  //       >
  //         <div className="full-page section-image">
  //           <div className="login-page">
  //             <Container>
  //               <Row>
  //                 <Col className="ml-auto mr-auto" lg="5" md="6">
  //                   <Card style={{ backgroundColor: '#ffffff00', boxShadow: '0px 0px 0px 0px rgb(0 0 0 / 1%)' }}>
  //                     <CardHeader>
  //                       <div className="header text-center">
  //                         <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
  //                           <lottie-player src="/antqueue-animated-logo-lottie-data.json" background="transparent" speed="1" style={{ width: "200px", height: "200px" }} loop autoplay></lottie-player>
  //                         </div>
  //                         <h3 style={{ color: '#fff' }} className="header text-center Margin-top--20">AntQueue Account Deletion Status</h3>
  //                       </div>
  //                       <div style={{ color: '#fff' }} className="col-md-9">
  //                         <span className="login-form-answer"><a href="#" onClick={e => { e.preventDefault(); this.props.history.push("/login"); }}>{'< Go to login'}</a></span>
  //                       </div>
  //                     </CardHeader>
  //                     <CardBody>
  //                       {success ? (
  //                         <div>
  //                           <h4 style={{ color: '#fff' }}>Your account has been successfully deleted.</h4>
  //                           <Button color="primary" href="/" block>
  //                             Go to Home
  //                           </Button>
  //                         </div>
  //                       ) : (
  //                         <div>
  //                           {error && <h4 style={{ color: 'red' }}>{error}</h4>}
  //                         </div>
  //                       )}
  //                     </CardBody>
  //                     <CardFooter></CardFooter>
  //                   </Card>
  //                 </Col>
  //               </Row>
  //             </Container>
  //             <div
  //               className="full-page-background"
  //               style={{
  //                 backgroundImage: `url(${require("../../assets/img/bg/queue_4000x2500_3.jpg")})`,
  //                 backgroundColor: "lightGrey"
  //               }}
  //             />
  //           </div>
  //         </div>
  //       </LoadingOverlay>
  //     </div>
  //   );
  // }

  render() {

    let appVersion = process.env.REACT_APP_VERSION;
    const {
      error,
      validCode,
      verifiedCode,
    } = this.state;
    let deleteAccountUrl = info.deleteAccountUrl;
    
    let wrapperFullPageContent, cardBodyContent;

    if (verifiedCode && validCode) {
      cardBodyContent = (
        <div className="row justify-content-center">
          {/* <h3 style={{ color: '#fff' }} className="header text-center Margin-top--20">Your AntQueue account has been successfully deleted.</h3> */}
          <h5 style={{ color: '#fff' }} className="header text-center Margin-top--20">Your account and all associated data has been removed from our platform. </h5>
        </div>
      );
    }
    else if (verifiedCode && !validCode) {
      cardBodyContent = (
        <div className="row justify-content-center">
          <h3 style={{ color: '#fff' }} className="header text-center Margin-top--20">Provide your email 
            <a href={deleteAccountUrl} rel="noopener noreferrer" style={{ color: '#fff' }}>
            <Button color="success" size="small" /* style={{ color: '#28a745' }} */ > here </Button>
          </a> to delete your account.</h3>
          <h5 style={{ color: '#ef476f' }} className="header text-center Margin-top--20">{error}</h5>
        </div>
      );
    }

    wrapperFullPageContent = (
      <div className="wrapper wrapper-full-page" ref={(ref) => this.fullPages = ref}>
        <LoadingOverlay
          active={!verifiedCode}
          spinner
          text='Loading'
        >
          <div className="full-page section-image">
            <div className="login-page">
              <Container>
                <Row>
                  <Col className="ml-auto mr-auto" lg="7" md="6">
                    <Card style={{ backgroundColor: '#ffffff00', boxShadow: '0px 0px 0px 0px rgb(0 0 0 / 1%)' }} className="card-login">
                      <CardHeader>
                        <CardHeader>
                          <div className="header text-center">
                            {/* <img src={'/antqueue-animated-logo.gif'} alt={"animated-antqueue-logo"} />  */}
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                              <lottie-player src="/antqueue-animated-logo-lottie-data.json" background="transparent" speed="1" style={{ width: "200px", height: "200px" }} loop autoplay></lottie-player>
                            </div>
                          </div>
                          <h3 style={{ color: '#fff' }} className="header text-center Margin-top--20">AntQueue Delete Mobile User Status</h3>
                        </CardHeader>
                      </CardHeader>
                      <CardBody>
                        {cardBodyContent}
                      </CardBody>
                      <CardFooter>
                        <div style={{ color: '#fff', fontSize: '8px', marginTop: '25px' }} className="header text-center font-weight-normal text-capitalize">{"AntQueue v" + appVersion}</div>
                      </CardFooter>
                    </Card>
                  </Col>
                </Row>
              </Container>
              <div
                className="full-page-background"
                style={{
                  backgroundImage: `url(${require("../../assets/img/bg/queue_4000x2500_3.jpg")})`,
                  backgroundColor: "lightGrey"
                }}
              />
            </div>
          </div>
        </LoadingOverlay>
      </div>
    )

    return wrapperFullPageContent;
  }
}

export default DeleteMobileUserAccount;