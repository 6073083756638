import React from "react";
import Firebase from 'firebase';
import PasswordHash from 'password-hash';
import config from '../../../config';
import Select from "react-select";
import NotificationAlert from "react-notification-alert";
import ReactTable from "react-table-6";
import LoadingOverlay from "react-loading-overlay";
import Switch from "react-bootstrap-switch";
import { CSVLink } from "react-csv";
// import {roles} from "../../../utils/roles"

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button,
    Label,
    FormGroup,
    InputGroup,
    Row,
    Col,
    Input,
    InputGroupAddon,
    InputGroupText,
} from "reactstrap";
import { resetPasswordFunc } from '../Forgot'

var search = "";
class Users extends React.Component {
    constructor(props) {
        super(props);
        if (!Firebase.apps.length) {
            Firebase.initializeApp(config);
        }

        this.state = {
            loading: false,
            role: '',
            all_roles: [],
            checkedItems: [],
            user_data_list: [],
            user_data_list2: [],
            singleSelect: null,
            selected_customer: '',
            customer_list: [{
                value: '',
                label: 'Show All Customers'
            }],
            sub_locations: [],
            request_approval_groups: [],
        };

        this.loadData = this.loadData.bind(this);
        this.getData = this.getData.bind(this);
        this.onChangeStatus = this.onChangeStatus.bind(this);
        this.handleResetPass = this.handleResetPass.bind(this);
        this.onChangeCustom = this.onChangeCustom.bind(this);
    }

    componentDidMount() {
        this.setState({ loading: true });
        this.loadData();
    }

    // async loadData1() {
    //     var _this = this;
    //     this.setState({user_data_list: [], user_data_list2: []});
    //     var userRole = JSON.parse(localStorage.getItem('auth_info')).role;
    //     var customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;
    //     var roleArr = [], locArr = [], userArr = [], userArr2 = [];

    //     if (userRole=='System_Admin' || userRole== 'Location_Admin' ) {

    //         if (search === "") {
    //             search = customer_id;
    //         }

    //         _this.setState({role: userRole});
    //         _this.setState({users: []});

    //         const [user_roles_snap, sub_location_snap, web_app_users_snap] = await Promise.all([
    //             Firebase.firestore().collection('System_Config').doc('User_Roles').get(),
    //             Firebase.firestore().collection('Sub_Locations').where('Customer_ID', '==', customer_id).get(),
    //             Firebase.firestore().collection('Web_App_Users').where('Customer_ID', '==', search).get(),
    //             // Firebase.firestore().collection(tables.REQUEST_APPROVAL_GROUPS).where('Customer_ID', '==', customer_id).get(),
    //         ]);

    //         if (!user_roles_snap.exists) { return { error: { message: 'Invalid User Roles Config', method: 'query sys config' } }; }
    //         const all_user_roles = user_roles_snap.data().All_Roles;
    //         for (var k in all_user_roles) {
    //             roleArr.push({label: k, value: all_user_roles[k]})
    //         }
    //         _this.setState({all_roles: roleArr});
    //         console.log({all_roles: roleArr});

    //         sub_location_snap.forEach(sub_location =>{
    //             const { Customer_ID, Name, Detail, Main_Location_ID } = sub_location.data();
    //             const option = {
    //                 sub_location_id: sub_location.id,
    //                 //id: sub_location.id,
    //                 value: sub_location.id,
    //                 label: Name,
    //                 sub_location_name: Name,
    //                 sub_location_detail: Detail,
    //                 customer_id: Customer_ID,
    //                 main_location_id: Main_Location_ID,
    //             };
    //             locArr.push(option);
    //         });
    //         _this.setState({sub_locations: locArr});
    //         console.log({sub_locations: locArr});

    //         //for (let web_app_user of web_app_users_snap) {
    //         web_app_users_snap.forEach(web_app_user =>{ //return instead of break

    //             const { Name, Accessible_Locations, Designation, Role, OTP_Enabled, Activated, Is_Customer } = web_app_user.data();

    //             if (userRole === "Site_Admin") { if (Role === "Site_Admin" || (search !== "" && Is_Customer)){ return; } }
    //             if (userRole === "System_Admin") { if (Role === "Site_Admin" || Role === "System_Admin"){ return; } }
    //             if (userRole === "Location_Admin") { if (Role === "Site_Admin" || Role === "System_Admin" || Role === "Location_Admin") { return; } }

    //             const option = {
    //                 web_app_user_id: web_app_user.id, //id: web_app_user.id,
    //                 value: web_app_user.id, label: Name, name: Name, email: web_app_user.id,
    //                 locations: Accessible_Locations,
    //                 designation: Designation, role: Role, otp_enabled: OTP_Enabled, activated: Activated
    //             };
    //             userArr.push(option);

    //             const option2 = {
    //                 web_app_user_id: web_app_user.id,
    //                 name: Name, email: web_app_user.id,
    //                 location_names: _this.getNamesFromIDs(Accessible_Locations),
    //                 designation: Designation, role: Role, otp_enabled: OTP_Enabled, activated: Activated
    //             };
    //             userArr2.push(option2);
    //         }); //}
    //         _this.setState({user_data_list: userArr, user_data_list2: userArr2});
    //         console.log({user_data_list: userArr, user_data_list2: userArr2});

    //         if (userRole == 'Site_Admin') {
    //               _this.loadCustomers();
    //         } else {
    //             _this.setState({loading: false});
    //         }
    //     }
    // }

    loadData() {
        var _this = this;
        this.setState({ user_data_list: [], user_data_list2: [] });
        var role = JSON.parse(localStorage.getItem('auth_info')).role;
        var customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;
        var roleArr = [], locArr = [], userArr = [], userArr2 = [], servGrpArr = [], reqAppGrpArr = [];

        Firebase.firestore().collection('System_Config').doc('User_Roles').get().then((e) => {
            if (!e.empty) {
                const all_user_roles = e.data().All_Roles;
                for (var k in all_user_roles) {
                    if (all_user_roles[k] !== 'Site_Admin')
                        roleArr.push({ name: k, label: k, value: all_user_roles[k] })
                }
                _this.setState({ all_roles: roleArr });
                // console.log({all_roles: roleArr});
            }
        }).catch(function (err) {
            console.log("loadData NetworkError1==>", err);
        });

        Firebase.firestore().collection('Sub_Locations').where('Customer_ID', '==', customer_id).get().then(function (response) {
            response.docs.forEach(function (sub_location) {
                const { Customer_ID, Name, Detail, Main_Location_ID } = sub_location.data();
                const option = {
                    sub_location_id: sub_location.id,
                    //id: sub_location.id, value: sub_location.id, name: Name, label: Name,
                    name: Name,
                    sub_location_name: Name,
                    sub_location_detail: Detail,
                    customer_id: Customer_ID,
                    main_location_id: Main_Location_ID,
                };
                locArr.push(option);
            })
            _this.setState({ sub_locations: locArr });
            // console.log({sub_locations: locArr});
        }).catch(function (err) {
            console.log("loadData NetworkError2==>", err);
        });

        Firebase.firestore().collection('Request_Approval_Groups').where('Customer_ID', '==', customer_id).get().then(function (response) {
            response.docs.forEach(function (request_approval_group) {
                const { Name, Description, Customer_ID, Main_Location_ID, Sub_Location_ID } = request_approval_group.data();
                const option = {
                    request_approval_group_id: request_approval_group.id,
                    //id: request_approval_group.id, value: request_approval_group.id, name: Name, label: Name,
                    request_approval_group_name: Name,
                    request_approval_group_description: Description,
                    customer_id: Customer_ID,
                    main_location_id: Main_Location_ID,
                    sub_location_id: Sub_Location_ID,
                };
                reqAppGrpArr.push(option);
            })
            _this.setState({ request_approval_groups: reqAppGrpArr });
            // console.log({request_approval_groups: reqAppGrpArr});
        }).catch(function (err) {
            console.log("loadData NetworkError4==>", err);
        });

        let ref = Firebase.firestore().collection('Web_App_Users');
        if (search !== "") {
            ref = Firebase.firestore().collection('Web_App_Users').where('Customer_ID', '==', search);
        } else if (role === "System_Admin" || role === "Location_Admin") {
            ref = Firebase.firestore().collection('Web_App_Users').where('Customer_ID', '==', customer_id);
        }
        _this.setState({ role: role });
        _this.setState({ user_data_list: [] });

        ref.get().then(function (response) {
            response.docs.forEach(function (doc) {
                if (role === "Site_Admin") { if (doc.get('Role') === "Site_Admin" || (search !== "" && doc.get('Is_Customer'))) { return; } }
                if (role === "System_Admin") { if (doc.get('Role') === "Site_Admin" /*|| doc.get('Role') === "System_Admin"*/) { return; } }
                if (role === "Location_Admin") { if (doc.get('Role') === "Site_Admin" || doc.get('Role') === "System_Admin" /*|| doc.get('Role') === "Location_Admin"*/) { return; } }

                var accLocs = doc.get('Accessible_Locations');
                if (typeof accLocs === 'undefined' || accLocs == null || accLocs.length == 0) {
                    accLocs = '';
                } else {
                    accLocs = _this.getNamesFromIDs((doc.get('Accessible_Locations')), 'location_names');
                }

                var reqAppGrps = doc.get('Request_Approval_Groups');
                if (typeof reqAppGrps === 'undefined' || reqAppGrps == null || reqAppGrps.length == 0) {
                    reqAppGrps = '';
                } else {
                    reqAppGrps = _this.getNamesFromIDs((doc.get('Request_Approval_Groups')), 'request_approval_group_names');
                }

                var createdDate = doc.get('Created_Date').toDate();
                createdDate = (createdDate.getFullYear() + '-' + (parseInt(createdDate.getMonth()) + 1) + '-' + createdDate.getDate());


                userArr.push({
                    web_app_user_id: doc.id, name: doc.get('Name'), email: doc.id,
                    locations: doc.get('Accessible_Locations'),
                    request_approval_groups: doc.get('Request_Approval_Groups'),
                    designation: doc.get('Designation'),
                    role: doc.get('Role'), otp_enabled: doc.get('OTP_Enabled'), activated: doc.get('Activated')
                });


                userArr2.push({
                    web_app_user_id: doc.id, created_date: createdDate, name: doc.get('Name'), email: doc.id, //created_date1: doc.get('Created_Date').toDate().toDateString(),
                    location_names: accLocs,
                    request_approval_group_names: reqAppGrps,
                    designation: doc.get('Designation'),
                    role: doc.get('Role'), otp_enabled: doc.get('OTP_Enabled'), activated: doc.get('Activated')
                });

                _this.setState({ user_data_list: userArr, user_data_list2: userArr2 });
                // console.log({user_data_list: userArr, user_data_list2: userArr2});
            });
            if (_this.state.role === "Site_Admin") {
                _this.loadCustomers();
            } else {
                _this.setState({ loading: false });
            }
        }).catch(function (error) {
            _this.setState({ loading: false });
            _this.notifyMessage("tc", 3, "Can not load data!");
            console.log('error', error);
        });
    }

    loadCustomers() {
        var _this = this;
        var customers = [{
            value: '',
            label: 'Show All Customers'
        }];
        let customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;
        Firebase.firestore().collection('Sub_Locations').where('Customer_ID', '==', customer_id).get().then(function (sub_locations) {
            _this.setState({ sub_locations: sub_locations.docs });
        });
        Firebase.firestore().collection('Customers').get().then(function (response) {
            var whole_customers = response.docs;
            whole_customers.forEach(function (item) {
                var one = {
                    value: item.id,
                    label: item.data().Email + " (" + item.data().Name + ")"
                };

                if (one.value !== JSON.parse(localStorage.getItem('auth_info')).customer_id)
                    customers.push(one);
            });

            _this.setState({ customer_list: customers });
            _this.setState({ loading: false });
        });
    }

    notifyMessage = (place, color, text) => {
        var type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }

        var options = {};
        options = {
            place: place,
            message: (
                <div className="text-md-center">
                    <div>
                        <b>{text}</b>
                    </div>
                </div>
            ),
            type: type,
            icon: "now-ui-icons ui-1_bell-53",
            autoDismiss: 3
        };
        if (options !== null) { this.notificationAlert.notificationAlert(options) }
    }

    getData() {
        var data = [];
        this.state.user_data_list.map((prop, key) => {
            data.push({
                check: (
                    <FormGroup check key={key} style={{ marginTop: "-17px" }}>
                        <Label check>
                            <Input type="checkbox" onChange={e => this.onChangeStatus(e, prop, "check")} />
                            <span className="form-check-sign" />
                        </Label>
                    </FormGroup>
                ),
                id: key + 1,
                name: prop.name,
                email: prop.email,
                location: this.getLocations(prop.locations),
                //(<span>{this.getLocations(prop.locations)}<br/></span>),
                //(<p key={prop.id}>{this.getLocations(prop.locations)}</p>),
                designation: prop.designation,
                role: this.getRole(prop.role),
                enable: (
                    <Switch
                        offColor="success"
                        offText=""
                        onColor="success"
                        onText=""
                        disabled
                        defaultValue={prop.activated}
                        onChange={e => this.onChangeStatus(e, prop.id, "switch")}
                    />
                ),
                edit: (
                    <Button onClick={e => this.props.history.push("/user/edit/" + prop.email)} className="btn btn-warning" style={{ marginTop: '-7px', marginBottom: '-7px' }}>Edit</Button>
                )
            });
        });

        return data;
    }
    generatePass(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789,./<>?:{}[]-=_+)(*&^%$#@!~`';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }
    handleResetPass() {
        var _this = this;
        _this.setState({ loading: true });
        var checkedItems = _this.state.checkedItems;
        checkedItems.forEach(function (item) {
            var token = _this.generatePass(50);
            var email = item.email;
            // var message_text = "We have received a request to reset your password. Your new password is " + pass + "<b/>";
            // var update_data = {
            //     Password: PasswordHash.generate(pass)
            // };
            // Firebase.firestore().collection('Web_App_Users').doc(email).update(update_data).then(function () {
            //     _this.notifyMessage("tc", 2, "Password reset success!");
            //     _this.sendEmail(email, "Password Reset", message_text);
            //     Firebase.functions().httpsCallable('resetPasswordForUser')({ email: email, password: pass }).then((result) => {
            //         // console.log('password change result ===> ', result);
            //     }, (err) => console.log("password change error==>", err));
            //     _this.setState({ loading: false });
            // }).catch(function (err) {
            //     _this.setState({ loading: false });
            //     _this.notifyMessage("tc", 3, "Network Error.");
            //     console.log("handleResetPass NetworkError1==>", err);
            // });
            resetPasswordFunc(token, email).then(() => {
                _this.setState({ loading: false });
                _this.notifyMessage("tc", 2, "Password reset success, please check the mail (including spam)");
            }).catch(e => {
                _this.setState({ loading: false });
                if (e == 'not_exitence' || e?.code == 'auth/user-not-found')
                    _this.notifyMessage("tc", 3, "This email does not exist!");
                else _this.notifyMessage("tc", 3, "Network error!");

            })
        });
    }
    sendEmail(email, subject, text) {
        Firebase.functions().httpsCallable('sendMail')({ email: email, subject: subject, text: text }).then(function (error, result) {
            console.log(error, result);
        });
    }
    onChangeStatus(e, value, type) {
        switch (type) {
            case "check":
                var checked = e.target.checked;
                var checkedItems = this.state.checkedItems;
                if (checked) {
                    checkedItems.push(value);
                    this.setState({ checkedItems: checkedItems });
                } else {
                    checkedItems.splice(checkedItems.indexOf(value), 1);
                    this.setState({ checkedItems: checkedItems });
                }

                break;
            default:
                break;
        }
    }
    getLocations(id_values) {

        let _this = this;
        let sub_locations = _this.state.sub_locations;

        // console.log('id_values', id_values);
        // console.log('sub_locations', sub_locations);
        let result = [];

        id_values.forEach(function (id) {
            for (let i = 0; i < sub_locations.length; i++) {
                if (sub_locations[i].sub_location_id == id) {
                    result.push(sub_locations[i].sub_location_name);
                }
            }
        })

        // console.log('result', result);
        return result;

        // var result = "";
        // var names = this.state.sub_locations;
        // for (var i=0; i<locations.length; i++) {
        //     var same = names.filter(item => item.id === locations[i]);
        //     if (same.length === 1) {
        //         result += same[0].get('Name') + " / ";
        //     }
        // }

        // result = result.slice(0, result.length-3);
        // console.log("result", result);
        // return result;
    }

    getRole(value) {
        var roles = this.state.all_roles;
        var find = roles.filter(item => item.value === value);
        if (find.length > 0) {
            return find[0].name;
        } else {
            return "";
        }
    }

    getNamesFromIDs(id_values, type) {
        let _this = this;
        let sub_locations = _this.state.sub_locations;
        let request_approval_groups = _this.state.request_approval_groups;

        // console.log('id_values', id_values);
        // console.log('sub_locations', sub_locations, 'request_approval_groups', request_approval_groups);
        let result = [];

        if (type == 'location_names') {
            id_values.forEach(function (id) {
                for (let i = 0; i < sub_locations.length; i++) {
                    if (sub_locations[i].sub_location_id == id) {
                        result.push(sub_locations[i].sub_location_name);
                    }
                }
            })
        } else if (type == 'request_approval_group_names') {
            id_values.forEach(function (id) {
                for (let i = 0; i < request_approval_groups.length; i++) {
                    if (request_approval_groups[i].request_approval_group_id == id) {
                        result.push(request_approval_groups[i].request_approval_group_name);
                    }
                }
            })
        }
        // console.log('result', result);
        return result;
    }

    onChangeCustom(e) {
        if (e.value === "") {
            search = "";
        } else {
            search = e.value;
        }
        this.setState({ singleSelect: e });
        this.setState({ selected_customer: e.value });
        // console.log(e.value)
        this.loadData();
    }
    render() {
        var ok = true
        if (this.state.role == "Counter_Display_User" || this.state.role == "Main_Display_User" || this.state.role == "Location_Super" || this.state.role == "" || this.state.role == "Dispenser_User" || this.state.existing_role == "Kiosk_User")
            ok = false
        const data = this.getData();
        return (
            <>
                <LoadingOverlay
                    active={this.state.loading}
                    spinner
                    text='Loading'
                    className='content'
                >
                    <NotificationAlert ref={(ref) => this.notificationAlert = ref} />
                    <Row>
                        <Col md="12">
                            {ok ? <Card className="full-height-page">
                                <CardHeader>
                                    <CardTitle tag="h4">Users</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col lg="2">
                                            <div>
                                                <Button onClick={e => this.props.history.push("/user/add")} className="btn btn-success" block>Add User</Button>
                                            </div>
                                        </Col>
                                        <Col lg="2">
                                            <div>
                                                <Button className="btn btn-success" block disabled={this.state.checkedItems.length > 0 ? false : true} onClick={this.handleResetPass}>Reset Password</Button>
                                            </div>
                                        </Col>
                                        <Col lg="2">
                                            <div style={{ float: "center", marginLeft: 5 }}>
                                                <CSVLink
                                                    data={this.state.user_data_list2}
                                                    filename={"detail-data.csv"}
                                                    className="btn-round btn btn-outline-primary"
                                                    target="_blank"
                                                >
                                                    Download User Data to CSV
                                                </CSVLink>
                                            </div>
                                        </Col>
                                        {/* <Col lg="3">
                                            <InputGroup className="input-search">
                                                <Input defaultValue="" placeholder="Search" type="text" />
                                                <InputGroupAddon addonType="append">
                                                    <InputGroupText>
                                                        <i className="nc-icon nc-zoom-split" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </Col> */}
                                        <Col lg="3" style={{ zIndex: '20' }} hidden={this.state.role === "Site_Admin" ? false : true}>
                                            <Select
                                                className="react-select info select-location"
                                                classNamePrefix="react-select"
                                                placeholder="Select Customer"
                                                name="selectCustomer"
                                                value={this.state.singleSelect}
                                                onChange={e =>
                                                    this.onChangeCustom(e)
                                                }
                                                options={this.state.customer_list}
                                            />
                                        </Col>
                                    </Row>
                                    <div>
                                        <ReactTable
                                            data={data}
                                            columns={[
                                                {
                                                    Header: "#",
                                                    accessor: "check",
                                                    sortable: false,
                                                },
                                                {
                                                    Header: "Id",
                                                    accessor: "id"
                                                },
                                                {
                                                    Header: "FullName",
                                                    accessor: "name"
                                                },
                                                {
                                                    Header: "Designation",
                                                    accessor: "designation"
                                                },
                                                {
                                                    Header: "Email",
                                                    accessor: "email"
                                                },
                                                {
                                                    Header: "Role",
                                                    accessor: "role"
                                                },
                                                {
                                                    Header: "Locations",
                                                    accessor: "location"
                                                },
                                                {
                                                    Header: "Enable",
                                                    accessor: "enable",
                                                    sortable: false,
                                                },
                                                {
                                                    Header: "Action",
                                                    accessor: "edit",
                                                    sortable: false,
                                                }
                                            ]}
                                            defaultPageSize={5}
                                            showPaginationTop={false}
                                            showPaginationBottom={true}
                                            showPageSizeOptions={false}
                                            /*
                                            You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                                            */
                                            className="-striped -highlight primary-pagination"
                                        />
                                    </div>
                                </CardBody>
                            </Card> : null}
                        </Col>
                    </Row>
                </LoadingOverlay>
            </>
        );
    }
}

export default Users;