import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

class TokenLoc3D extends Component {

  componentDidMount() {
    const chart = am4core.create("Token3DChart", am4charts.PieChart3D);

    this.createChart(chart);

    this.chart = chart;
  }

  handleZoom = (e) => {
    console.log(e)
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  createChart = (chart) => {
      chart.data = this.props.data;
      chart.legend = new am4charts.Legend();
      // Add and configure Series
      var pieSeries = chart.series.push(new am4charts.PieSeries3D());
      pieSeries.dataFields.value = "value";
      pieSeries.dataFields.category = "label";
      pieSeries.slices.template.propertyFields.fill = "color"; 
      pieSeries.slices.template.stroke = am4core.color("#fff");
      pieSeries.slices.template.strokeWidth = 2;
      pieSeries.slices.template.strokeOpacity = 1;

      // Disabling labels and ticks on inner circle
      pieSeries.labels.template.disabled = true;
      pieSeries.ticks.template.disabled = true;

      // Disable sliding out of slices
      // pieSeries.slices.template.states.getKey("hover").properties.shiftRadius = 0;
      // pieSeries.slices.template.states.getKey("hover").properties.scale = 1;

      chart.exporting.menu = new am4core.ExportMenu();
  }

  render() {
    return (
      <div id="Token3DChart" style={{ width: "100%", height: "500px" }} />
    );
  }
}

export default TokenLoc3D;
