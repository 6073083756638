import React, { useState, useEffect, useRef } from "react";
import NotificationAlert from "react-notification-alert";
import Firebase from "firebase";
import LoadingOverlay from "react-loading-overlay";
import Select from "react-select";

import {
  Col,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Label,
  Button,
  Input,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
} from "reactstrap";
import config from "../../../config";
import {
  deleteItem,
  getCustomersOptions,
  WarningWithConfirmMessage,
} from "../../../components/helpers";
import "ka-table/style.css";
import { search } from "ka-table/actionCreators";
import { kaReducer, Table } from "ka-table";
import {
  DataType,
  PagingPosition,
  SortingMode,
  FilteringMode,
} from "ka-table/enums";
import { DateRangePicker } from "react-dates";
import moment from "moment";
const uuidv1 = require("uuid/v1");

const daysIndexes = [0, 1, 2, 3, 4, 5, 6];
const daysName = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];

function Calendars(props) {
  if (!Firebase.apps.length) {
    Firebase.initializeApp(config);
  }

  const notificationAlert = useRef(null);

  const [editFlag, setEditFlag] = useState(false);

  const [dateRange, setDateRange] = useState({});

  const [state, setState1] = useState({
    printerIp: "",
    printerPort: "9100",
    add: false,
    loading: false,
    role: JSON.parse(localStorage.getItem("auth_info")).role,
    customer_id: JSON.parse(localStorage.getItem("auth_info")).customer_id,
    calendars: [],
    calendar: {},
    modalState: false,
    calId: "",
    Name: "",
    type: "",
    Days: [],
    Holiday: [6, 0],

    alert: null,
    holidayOptions: [
      {
        label: "Saturday and Sunday",
        value: [6, 0],
      },
      {
        label: "Friday and Saturday",
        value: [5, 6],
      },
    ],
    default_calendar: [],
    selected_customer: {},
    selected_holidays: [],
    selected_weekends: [],
    selected_working_days: [],
    selected_bulk_update_days: [],
    selected_issue_time_start: "",
    selected_issue_time_end: "",
    // selected_issue_endTime: "",
    selected_cutoff_time: "",
    // selected_call_startTime: "",
    // selected_call_endTime: "",
    selected_call_time_start: "",
    selected_call_time_end: "",

    selected_issue_times: [],
    selected_cutoff_times: [],
    selected_call_times: [],
    tableprops: {
      data: [],
      columns: [
        {
          // dataType: DataType.Date,
          title: "Day",
          key: "Day",
        },
        {
          // dataType: DataType.String,
          title: "Holiday (Check Box)",
          key: "Holiday",
        },
        {
          // dataType: DataType.String,
          title: "Weekend (Check Box)",
          key: "Weekend",
        },
        // {
        //   title: "Token Issue Start",
        //   key: "issueStart",
        //   style: { textAlign: "center" },
        // },
        // {
        //   title: "Token Issue End",
        //   key: "issueEnd",
        //   style: { textAlign: "center" },
        // },
        // {
        //   title: "CutOff Time",
        //   key: "cutOff",
        //   style: { textAlign: "center" },
        // },
        // {
        //   title: "Token Call Start",
        //   key: "callStart",
        //   style: { textAlign: "center" },
        // },
        // {
        //   title: "Token Call End",
        //   key: "callEnd",
        //   style: { textAlign: "center" },
        // },
        {
          title: "Issue Times",
          key: "Issue_Times",
          style: { textAlign: "center" },
        },
        {
          title: "Cut Off Times",
          key: "Issue_Cut_Off_Times",
          style: { textAlign: "center" },
        },
        {
          title: "Call Times",
          key: "Call_Times",
          style: { textAlign: "center" },
        },

        {
          title: "Action",
          key: "action",
          sortable: false,
          width: 200,
          style: { textAlign: "center" },
        },
      ],
      paging: {
        enabled: true,
        pageIndex: 0,
        pageSize: 5,
        // pageSizes: data.length > 10 ? [10, 50, 100] : [],
        position: PagingPosition.Bottom,
      },
      sortingMode: SortingMode.Single,
      // editingMode: EditingMode.Cell,
      rowKeyField: "key",
      filteringMode: FilteringMode.HeaderFilter,
      columnResizing: true,
    },
  });
  const setState = (e) => setState1((c) => ({ ...c, ...e }));
  // }

  // componentDidMount () {
  useEffect(() => {
    // getCurrentTime();
    loadData();
    // loadMainLocations();
    // const close = (e) => {
    //   if (e.keyCode === 27) {
    //     let modalCheckbox = document.querySelector(
    //       "input[name='day-times']:checked"
    //     );
    //     console.log("modalCheckbox", modalCheckbox);
    //     if (modalCheckbox && modalCheckbox.checked) {
    //       modalCheckbox.click();
    //       e.stopPropagation();
    //     }
    //   }
    // };
    // window.addEventListener("keydown", close);
    // return () => window.removeEventListener("keydown", close);
  }, []);

  useEffect(() => {
    if (state.kioskModel?.height > 0 && state.kioskModel?.width > 0)
      setState({
        height: state.kioskModel?.height,
        width: state.kioskModel?.width,
      });
  }, [state.kioskModel]);

  useEffect(() => {
    // console.log("state.Days ", state.Days);
    setState({ tableprops: { ...state.tableprops, data: state.Days } });
  }, [state.Days]);

  async function loadData() {
    setState({ loading: true });

    if (state.role == "Site_Admin") {
      let customers = (await getCustomersOptions())?.map((c) => ({
        ...c,
        label: `${c.value} | ${c.Name} | ${c.Email}`,
      }));
      setState({ customers });
    }

    let query = Firebase.firestore()
      .collection("Calendars")
      .where("Customer_ID", "==", state.customer_id);

    console.log("state.role", state.role);
    if (state.role == "Site_Admin") {
      query = Firebase.firestore().collection("Calendars");
    }

    query
      .get()
      .then(async (data) => {
        let calendars = await Promise.all(
          data?.docs?.map(async (d) => {
            let Days =
              (
                await d?.ref
                  ?.collection("Days")
                  ?.get()
                  .catch((e) => console.log(e))
              )?.docs?.map((day) => ({
                ...day?.data(),
              })) || [];
            return { ...d?.data(), id: d.id, Days };
          })
        );
        setState({
          loading: false,
          calendars,
        });
      })
      .catch(function (err) {
        setState({ loading: false });
        console.log("load data error", err);
        notifyMessage("tc", 3, "Network error!");
      });
  }

  // function onChangeMain(e) {
  //   setState({ selected_main_location: e });
  //   loadSubLocationByMain(e.value);
  // }
  // function onChangeSub(e) {
  //   setState({ selected_sub_location: e });
  //   // loadStartEndNumberList(e.value);
  //   setState({ time_zone: e.time_zone });
  // }

  async function handleAddEdit(newState) {
    setState({ loading: true });
    if (state.Name?.length == 0) {
      setState({ loading: false });
      return notifyMessage("bc", 3, "Please enter Calendar Name");
    }
    if (state.Days?.length == 0) {
      setState({ loading: false });
      return notifyMessage("bc", 3, "Please populate Calendar days");
    }
    // if (!state.selected_customer?.value?.length > 0 && state.role=='Site_Admin') {
    //   setState({ loading: false });
    //   return notifyMessage("bc", 3, "Please select Calendar customer");
    // }
    let data = {
        Name: state.Name,
        // Days: state.Days,
        // Holiday: state.Holiday,
        Customer_ID: state.selected_customer?.value || state.customer_id,
        Default_Calendar: state.default_calendar,
        // Sub_Location_ID: state.selected_sub_location?.value,
        // Main_Location_ID: state.selected_main_location?.value,
      },
      doc;
    try {
      if (!state.calId?.length > 0) {
        // console.log("creating new calendar", data);
        let batch = Firebase.firestore().batch();
        doc = Firebase.firestore().collection("Calendars").doc();
        // await doc.set(data);

        batch.set(doc, data);

        if (state.Days?.length > 0)
          state.Days.map((day) =>
            batch.set(
              doc.collection("Days").doc(formatDayIdFromDate(day?.Day_Firestore_Timestamp)),
              day
            )
          );

        await batch.commit();

        // data.id = doc.id;
      } else {
        let batch = Firebase.firestore().batch();
        doc = Firebase.firestore().collection("Calendars").doc(state.calId);
        // await doc.set(data);

        batch.update(doc, data);

        if (state.Days?.length > 0)
          state.Days.map((day) =>
            batch.set(
              doc.collection("Days").doc(formatDayIdFromDate(day?.Day_Firestore_Timestamp) || ""),
              day
            )
          );

        await batch.commit();

        // console.log("state.calId , data", state.calId, data);
        // await Firebase.firestore()
        //   .collection("Calendars")
        //   .doc(state.calId)
        //   .update(data);
      }
      let calendarsTmp = state.calendars,
        index = state.calendars?.findIndex((e) => e?.id == state?.calId);

      index = index >= 0 ? index : state.calendars?.length;

      // calendarsTmp[index] = data;

      // console.log("newState", newState);

      setState({ ...newState, calendars: calendarsTmp });
    } catch (error) {
      setState({ loading: false });
      console.log(error);
      return notifyMessage("tc", 3, "Network error!");
    }
    setState({ loading: false, Name: "", Days: [] });
    loadData();
    notifyMessage("tc", 2, "Calendar saved successfully.");
  }

  const deleteCal = async (ref) => {
    try {
      await deleteItem(ref);
      setState({ alert: null });
    } catch (error) {
      notifyMessage("tc", 3, "Network error!");
      console.log(error);
    }
  };
  function handleAddDay() {
    if (
      state.issue_start_time !== "" &&
      state.issue_end_time !== "" &&
      state.call_start_time !== "" &&
      state.call_end_time !== ""
    ) {
      let new_service_day = {
        week_day_order: state.new_week_select.value,
        week_day: state.new_week_select.label,
        start_time: state.issue_start_time,
        end_time: state.issue_end_time,
        tok_issue_cut_off_time:
          typeof state.tok_issue_cut_off_time !== "undefined" &&
          state.tok_issue_cut_off_time !== "" &&
          state.tok_issue_cut_off_time !== 0 &&
          state.tok_issue_cut_off_time !== "0"
            ? state.tok_issue_cut_off_time
            : "0",
        call_start_time: state.call_start_time,
        call_end_time: state.call_end_time,
      };

      if (new_service_day.start_time >= new_service_day.end_time) {
        notifyMessage(
          "tc",
          3,
          "Token Issue Start time cannot be same or later than Token Issue End time!"
        );
        return;
      }

      if (new_service_day.call_start_time >= new_service_day.call_end_time) {
        notifyMessage(
          "tc",
          3,
          "Token Call Start time cannot be same or later than Token Call End time!"
        );
        return;
      }

      if (new_service_day.start_time >= new_service_day.call_start_time) {
        notifyMessage(
          "tc",
          3,
          "Token Issue Start time cannot be same or later than Token Call Start time!"
        );
        return;
      }

      // if (new_service_day.call_end_time >= new_service_day.end_time) {
      //     notifyMessage("tc", 3, "Token Call End time cannot be same or later than Token Call Issue time!");
      //     return;
      // }

      // if (new_service_day.call_end_time > new_service_day.tok_issue_cut_off_time) {
      //     notifyMessage("tc", 3, "Token Call End time cannot be later than Token Issue Current Day Cut-Off time!");
      //     return;
      // }

      let cur_service_days = state.default_calendar;
      let sames = cur_service_days.filter(
        (item) => item.week_day === new_service_day.week_day
      );
      let overlap = false;
      sames.forEach(function (one) {
        if (
          (new_service_day.start_time >= one.start_time &&
            new_service_day.start_time <= one.end_time) ||
          (new_service_day.end_time >= one.start_time &&
            new_service_day.end_time <= one.end_time) ||
          (new_service_day.call_start_time >= one.call_start_time &&
            new_service_day.call_start_time <= one.call_end_time) ||
          (new_service_day.call_end_time >= one.call_start_time &&
            new_service_day.call_end_time <= one.call_end_time)
        ) {
          notifyMessage("tc", 3, "Time Range Overlap!");
          overlap = true;
          return;
        }
      });

      if (overlap) return;

      cur_service_days.push(new_service_day);
      // ------------- Sort Date and Start Time ------------ //
      let sorted = cur_service_days.sort(function (a, b) {
        if (a.week_day === b.week_day) {
          let x = a.start_time > b.start_time ? -1 : 1;
          return x;
        } else {
          let x = a.week_day_order < b.week_day_order ? -1 : 1;
          return x;
        }
      });
      console.log("default_calendar", sorted);
      setState({ default_calendar: sorted });
      setState({ issue_start_time: "" });
      setState({ issue_end_time: "" });
      setState({ tok_issue_cut_off_time: "" });
      setState({ call_start_time: "" });
      setState({ call_end_time: "" });
    } else {
      notifyMessage(
        "tc",
        3,
        "You must fill in all the time slots correctly before you can set token issue times and token calling times!"
      );
    }
  }

  function handleDeleteDay(index) {
    var cur_service_days = state.default_calendar;
    if (index !== -1) cur_service_days.splice(index, 1);
    setState({ default_calendar: cur_service_days });
  }

  function getServiceDays() {
    return (
      state.default_calendar?.map((prop, key) => {
        const ticof = prop.tok_issue_cut_off_time;
        const tokIssueCutOffTime =
          typeof ticof !== "undefined" &&
          ticof !== "" &&
          ticof !== 0 &&
          ticof !== "0"
            ? ticof
            : "0";
        return (
          <tr key={key}>
            <td>{prop.week_day}</td>
            <td>{prop.start_time}</td>
            <td>{prop.end_time}</td>
            {/* {state.disable_fields_based_on_service_type_appointment ===
            false ? ( */}
            <td>{tokIssueCutOffTime}</td>
            {/* ) : null} */}
            <td>{prop.call_start_time}</td>
            <td>{prop.call_end_time}</td>
            <td className="td-actions">
              <Button
                className="btn-neutral"
                color="default"
                data-placement="left"
                id={"day" + key.toString()}
                title=""
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  handleDeleteDay(key);
                }}
              >
                <i className="nc-icon nc-simple-remove" />
              </Button>
              <UncontrolledTooltip
                delay={0}
                placement="left"
                target={"day" + key.toString()}
              >
                Remove item
              </UncontrolledTooltip>
            </td>
          </tr>
        );
      }) || []
    );
  }

  function renderCalendars() {
    let calendars = state.calendars;
    calendars.sort((a, b) => b.Created_Date - a.Created_Date);

    return calendars.map((cal, key) => (
      <>
        <Row className="col-12">
          <Col md="1" className="text-center align-self-center">
            <i className="nc-icon nc-calendar-60 h2 mb-0"></i>
          </Col>
          <Col md="7" className="align-self-center">
            <h6 className="col-12 font-weight-bold">{cal.Name}</h6>
          </Col>
          <Col md="4" className="text-center">
            <Row className="justify-content-end">
              <Col md="8" sm="8">
                <Button
                  color="warning"
                  onClick={() => toggleModalState(cal)}
                  block
                >
                  Edit
                </Button>
              </Col>
            </Row>

            <Row className="justify-content-end">
              <Col md="8" sm="8">
                <Button
                  color="youtube"
                  onClick={(e) => {
                    // console.log("delete alert to confrim");
                    // console.log(
                    //   WarningWithConfirmMessage({
                    //     text: `Delete ${cal?.Name} Calendar?`,
                    //     confirmCB: () =>
                    //       deleteCal({ collection: "Calendars", id: cal?.id }),
                    //     cancelCB: () => {},
                    //   })
                    // );
                    setState({
                      alert: (
                        <WarningWithConfirmMessage
                          text={`Delete ${cal?.Name} Calendar?`}
                          confirmCB={() => {
                            deleteCal({ collection: "Calendars", id: cal?.id });
                            loadData();
                          }}
                          cancelCB={() => {}}
                        />
                      ),
                    });
                  }}
                  block
                >
                  Delete
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr />
      </>
    ));
  }

  const notifyMessage = (place, color, text) => {
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }

    var options = {};
    options = {
      place: place,
      message: (
        <div className="text-md-center">
          <div>
            <b>{text}</b>
          </div>
        </div>
      ),
      type: type,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 3,
    };
    if (
      options !== null &&
      notificationAlert != null &&
      notificationAlert.current
    ) {
      notificationAlert.current.notificationAlert(options);
    }
  };

  const toggleModalState = (cal = false) => {
    if (!!cal) {
      return setState({
        calId: cal?.id,
        Name: cal?.Name,
        // type: cal?.type,
        Days:
          cal?.Days?.map((d) => ({ ...d, Day: d?.Day_Firestore_Timestamp?.toDate() || "" })) || [],
        Holiday: cal?.Holiday,
        default_calendar: cal?.Default_Calendar || [],
        selected_customer: { value: cal?.Customer_ID },
        modalState: !state.modaState,
        selected_issue_startTime: "",
        selected_issue_endTime: "",
        selected_cutoff_time: "",
        selected_call_startTime: "",
        selected_call_endTime: "",
        selected_holidays: [],
        selected_working_days: [],
        selected_weekends: [],
      });
    } else
      setState({
        modalState: !state.modaState,
        calId: "",
        Name: "",
        // type: cal?.type,
        Days: [],
        selected_customer: {},
      });
  };

  const dispatch = (action) => {
    setState({
      tableprops: kaReducer(state.tableprops, action),
    });
  };

  useEffect(() => {
    if (!dateRange?.startDate || !dateRange?.endDate) return;

    let startDateObj = new Date(dateRange?.startDate?.toDate()?.getTime()),
      endDateObj = new Date(dateRange?.endDate?.toDate()?.getTime()),
      daysNumArr = Array.from(
        Array(get2datesDaysNum(startDateObj, endDateObj))
      );

    let Days = daysNumArr
      ?.map((_, index) => {
        let dayTmp = new Date(startDateObj.getTime());

        dayTmp.setDate(dayTmp.getDate() + index);

        // let holidayCheck = state.Holiday.includes(dayTmp.getDay()),id=uuidv1()
        let id = uuidv1();
        window.uuid = uuidv1;
        return {
          Day: dayTmp,
          id,
          // Type: holidayCheck ? "holiday" : "working_day",
          Holiday: false,
          Weekend: false,
          // issueStart: "",
          // issueEnd: "",
          // cutOff: "",
          // callStart: "",
          // callEnd: "",
          Issue_Times: [],
          Issue_Cut_Off_Times: [],
          Call_Times: [],
        };
      })
      ?.filter((e) => !!e);
    if (state.Days?.length > 0) Days = [...Days, ...state.Days];
    // console.log("Days", Days);
    setState({
      Days,
    });
  }, [dateRange]);

  const addRemoveSaveIndex = (stateName, index) => {
    setState({
      [stateName]: state[stateName]?.includes(index)
        ? state[stateName]?.filter((e) => e != index)
        : [...state[stateName], index],
    });
  };

  const bulkUpdate = async () =>
    // filterDays,
    // selectedHolidays,
    // selectedWeekends,
    // start_time,
    // end_time
    {
      try {
        // console.log(
        //   "selected_issue_times",
        //   state.selected_issue_times,
        //   "selected_cutoff_times",
        //   state.selected_cutoff_times,
        //   "selected_call_times",
        //   state.selected_call_times
        //   // "selected_call_startTime",
        //   // state.selected_call_startTime,
        //   // "selected_call_endTime",
        //   // state.selected_call_endTime
        // );
        let days = [...state.Days];
        days = days?.map((day) => {
          let dayIndex = day?.Day_Firestore_Timestamp?.getDay();
          if (!state.selected_bulk_update_days?.includes(dayIndex)) return day;
          return {
            ...day,
            Holiday: state.selected_working_days?.includes(dayIndex)
              ? false
              : state.selected_holidays?.includes(dayIndex),
            // ||
            // day?.Holiday ||
            // false
            Weekend: state.selected_working_days?.includes(dayIndex)
              ? false
              : state.selected_weekends?.includes(dayIndex),
            // ||
            // day?.Weekend ||
            // false
            // issueStart: state.selected_issue_startTime || day?.callStart || "",
            // issueEnd: state.selected_issue_endTime || day?.callEnd || "",
            // cutOff: state.selected_cutoff_time || day?.cutOff || "",
            // callStart: state.selected_call_startTime || day?.callStart || "",
            // callEnd: state.selected_call_endTime || day?.callEnd || "",
            Issue_Times: state.selected_issue_times || day?.Issue_Times || "",
            Issue_Cut_Off_Times: state.selected_cutoff_times || day?.Issue_Cut_Off_Times || "",
            Call_Times: state.selected_call_times || day?.Call_Times || "",

          };
        });
        setState({ Days: days });
      } catch (error) {
        console.log(error);
      }
    };

  const updateDayIndexById = (id, fieldName, value) => {
    let Days = state.Days;
    let index = Days?.findIndex((e) => e?.id == id);
    if (index > -1) {
      Days[index] = { ...Days[index], [fieldName]: value };
    }
    // console.log("index", index);
    setState({ Days });
  };

  const renderTimesArr = (
    stateName = "",
    value = false,
    type = "alert",
    onChange = false
  ) =>
    (value || state[stateName])?.map((data, index) => (
      <div
        className={`${type} ${type}-info ${
          type == "alert" ? "pr-5" : ""
        } mx-2 d-flex justify-content-between align-items-center`}
      >
        <div>{data?.start ? data?.start + " - " + data?.end : data + ""}</div>
        {/* {stateName?.length > 0 && ( */}
        <button
          type="button"
          class="close"
          onClick={() => {
            let val = value || state?.[stateName];
            if (val?.length > 0) val.splice(index, 1);
            !onChange
              ? setState({
                  [stateName]: val || [],
                })
              : onChange(val);
          }}
        >
          <span aria-hidden="true">&times;</span>
        </button>
        {/* )} */}
      </div>
    ));

  const AddableComponent = ({
    label,
    stateName,
    onChange = false,
    currentValues = false,
  }) => {
    let names = stateName + "s";

    if (stateName == "selected_cutoff_time")
      return (
        <Row>
          {label?.length > 0 && <Label md="4">{label}</Label>}
          <Col>
            <Row className="align-items-center">
              <Col xs="8">
                <Input
                  className="m-auto position-relative"
                  placeholder={label}
                  value={state[stateName] || ""}
                  type="time"
                  onChange={({ target: { value } }) =>
                    setState({ [stateName]: value })
                  }
                />
              </Col>
              <Col xs="4" className="text-center">
                <Button
                  disabled={!state[stateName]?.length > 0}
                  onClick={(e) => {
                    let newArr = [...state[names], state[stateName]];
                    if (currentValues != false)
                      newArr = [...currentValues, state[stateName]];
                    console.log("newArr", newArr);
                    if (!onChange)
                      setState({
                        [names]: newArr,
                        [stateName]: "",
                      });
                    else {
                      // if (e?.target?.closest(".modal-toggle-label"))
                      //   e.target.closest(".modal-toggle-label").click();
                      onChange(newArr);
                    }
                  }}
                >
                  +
                </Button>
              </Col>
            </Row>
            <Row>{renderTimesArr(names, currentValues, "alert", onChange)}</Row>
          </Col>
        </Row>
      );
    return (
      <Row>
        {label?.length > 0 && <Label lg="4">{label}</Label>}
        <Col>
          <Row className="align-items-center">
            <Col xs="4">
              <Input
                className="m-auto position-relative"
                placeholder={label}
                value={state[stateName + "_start"] || ""}
                type="time"
                onChange={({ target: { value } }) =>
                  setState({ [stateName + "_start"]: value })
                }
              />
            </Col>
            <Col xs="4">
              <Input
                className="m-auto position-relative"
                placeholder={label}
                value={state[stateName + "_end"] || ""}
                type="time"
                onChange={({ target: { value } }) =>
                  setState({ [stateName + "_end"]: value })
                }
              />
            </Col>
            <Col xs="4" className="text-center">
              <Button
                disabled={
                  !(
                    state[stateName + "_start"]?.length > 0 &&
                    state[stateName + "_end"]?.length > 0
                  )
                }
                onClick={(e) => {
                  let start = stateName + "_start",
                    end = stateName + "_end",
                    newArr = [
                      ...state[names],
                      { start: state[start], end: state[end] },
                    ];
                  if (currentValues != false)
                    newArr = [
                      ...currentValues,
                      { start: state[start], end: state[end] },
                    ];
                  console.log("newArr , state[names]", newArr, state[names]);

                  // !onChange
                  //   ? setState({
                  //       [names]: newArr,
                  //       [start]: "",
                  //       [end]: "",
                  //     })
                  //   : onChange(newArr);

                  if (!onChange)
                    setState({
                      [names]: newArr,
                      [start]: "",
                      [end]: "",
                    });
                  else {
                    // if (e?.target?.closest(".modal-toggle-label"))
                    //   e.target.closest(".modal-toggle-label").click();
                    onChange(newArr);
                  }
                }}
              >
                +
              </Button>
            </Col>
          </Row>
          <Row>{renderTimesArr(names, currentValues, "alert", onChange)}</Row>
        </Col>
      </Row>
    );
  };

  const AddEditModal = (
    <Modal
      isOpen={state.modalState}
      size="xl"
      toggle={() => {
        if (state?.currentInnerModal?.length == 0)
          setState({ modalState: false });
      }}
      className="py-0 my-0"
      backdrop="static"
    >
      <LoadingOverlay
        active={state.loading}
        // active={true}
        spinner
        text="Loading"
        className="content dispenser"
      >
        <NotificationAlert ref={notificationAlert} />

        <ModalHeader toggle={() => setState({ modalState: false })}>
          {`${state.Name || "Add"}`}
        </ModalHeader>
        <ModalBody style={{ maxHeight: "82vh", overflowY: "auto" }}>
          <Row>
            <Label md="4">Name</Label>
            <Col md="8">
              {/* <FormGroup className={`has-label ${nameState}`}> */}
              <FormGroup className={`has-label`}>
                <Input
                  placeholder="Calendar Name"
                  value={state.Name}
                  type="text"
                  onChange={(e) => setState({ Name: e?.target?.value })}
                />
                {/* {state.name === "has-danger" ? (
                  <label className="error">
                    This field is required.
                  </label>
                ) : null} */}
              </FormGroup>
            </Col>
          </Row>

          {state.role == "Site_Admin" && (
            <Row>
              <Label md="4">Customer</Label>
              <Col md="8">
                <FormGroup>
                  <Select
                    className="react-select info select-location"
                    classNamePrefix="react-select"
                    placeholder="Select Customers"
                    name="customer"
                    value={state.customers?.find(
                      (c) => c.value == state.selected_customer?.value
                    )}
                    onChange={(e) => setState({ selected_customer: e })}
                    options={state.customers}
                  />
                </FormGroup>
              </Col>
            </Row>
          )}
          <Card>
            <CardBody className="bg-light">
              <Row>
                <Col md="12">
                  <FormGroup>
                    <h5>Default Schedule</h5>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Label md="4">Select day of week</Label>
                <Col md="8">
                  <FormGroup>
                    <Select
                      className="react-select primary bg-white"
                      classNamePrefix="react-select"
                      name="weekSelect"
                      value={state.new_week_select}
                      onChange={(value) => setState({ new_week_select: value })}
                      options={[
                        { value: "0", label: "Sunday" },
                        { value: "1", label: "Monday" },
                        { value: "2", label: "Tuesday" },
                        { value: "3", label: "Wednesday" },
                        { value: "4", label: "Thursday" },
                        { value: "5", label: "Friday" },
                        { value: "6", label: "Saturday" },
                      ]}
                      placeholder="Select day of the week"
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Label md="4">Token Issue Start</Label>
                <Col md="8">
                  <FormGroup>
                    <Input
                      placeholder="Token Issue Start Time"
                      value={state.issue_start_time}
                      type="time"
                      onChange={(e) => {
                        setState({
                          issue_start_time: e.target.value,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Label md="4">Token Issue End</Label>
                <Col md="8">
                  <FormGroup>
                    <Input
                      placeholder="Token Issue End Time"
                      value={state.issue_end_time}
                      type="time"
                      onChange={(e) => {
                        setState({
                          issue_end_time: e.target.value,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>

              {/* {disable_fields_based_on_service_type_appointment === false ? ( */}
              <Row>
                <Label md="4">Token Current Day Cut-Off Time</Label>
                <Col md="8">
                  <FormGroup>
                    <Input
                      placeholder="Token Current Day Cut-Off"
                      value={state.tok_issue_cut_off_time}
                      type="time"
                      onChange={(e) => {
                        setState({
                          tok_issue_cut_off_time: e.target.value,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {/* ) : null} */}

              <Row>
                <Label md="4">Token Call Start</Label>
                <Col md="8">
                  <FormGroup>
                    <Input
                      placeholder="Token Call Start Time"
                      value={state.call_start_time}
                      type="time"
                      onChange={(e) => {
                        setState({
                          call_start_time: e.target.value,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Label md="4">Token Call End</Label>
                <Col md="8">
                  <FormGroup>
                    <Input
                      placeholder="Token Call End Time"
                      value={state.call_end_time}
                      type="time"
                      onChange={(e) => {
                        setState({
                          call_end_time: e.target.value,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Label md="4"></Label>
                <Col md="8">
                  <FormGroup>
                    <Row>
                      <Col md="7"></Col>
                      <Col md="5">
                        <FormGroup>
                          <Button
                            className="top-margin-2"
                            color="success"
                            onClick={(e) => handleAddDay()}
                            block
                          >
                            Add
                          </Button>
                        </FormGroup>
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <FormGroup>
                    <table bordered className="w-100">
                      <thead className="text-center back">
                        <tr>
                          <th>Day Of Week</th>
                          <th>Token Issue Start</th>
                          <th>Token Issue End</th>
                          {/* {disable_fields_based_on_service_type_appointment ===
                      false ? ( */}
                          <th>Token Current Day Cut-Off</th>
                          {/* ) : null} */}
                          <th>Token Call Start</th>
                          <th>Token Call End</th>
                          <th className="text-center">X</th>
                        </tr>
                      </thead>
                      <tbody className="text-center">
                        {state?.default_calendar?.length > 0 &&
                          getServiceDays()}
                      </tbody>
                    </table>
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card>
            <CardBody className="bg-light">
              <AddableComponent
                label="Issue Start/End"
                stateName="selected_issue_time"
              />
              <AddableComponent
                label="CutOff Time"
                stateName="selected_cutoff_time"
              />
              <AddableComponent
                label="Call Start/End"
                stateName="selected_call_time"
              />
              <hr />
              <br />
              <Row>
                <Label md="4">Weekends</Label>
                {daysName.map((day, index) => (
                  <Col>
                    <Label className="w-100">
                      {day}
                      <FormGroup className="d-inline-block mx-2 vertical-middle my-0">
                        <Input
                          className="m-auto position-relative m-0 mx-2"
                          placeholder="End Time"
                          checked={state.selected_weekends?.includes(index)}
                          type="checkbox"
                          name={"selecte" + day}
                          onChange={({ target: { checked } }) => {
                            // console.log("selected_weekends checked", checked);
                            addRemoveSaveIndex("selected_weekends", index);
                            //----------
                            setState({
                              selected_working_days:
                                state.selected_working_days?.filter(
                                  (e) => e != index
                                ) || [],
                              // selected_holidays:
                              //   state.selected_holidays?.filter(
                              //     (e) => e != index
                              //   ) || [],
                            });
                          }}
                        />
                      </FormGroup>
                    </Label>
                  </Col>
                ))}
              </Row>
              <Row>
                <Label md="4">Holidays</Label>
                {daysName.map((day, index) => (
                  <Col>
                    <Label className="w-100">
                      {day}
                      <FormGroup className="d-inline-block mx-2 vertical-middle my-0">
                        <Input
                          className="m-auto position-relative m-0 mx-2"
                          placeholder="End Time"
                          checked={state.selected_holidays?.includes(index)}
                          type="checkbox"
                          name={"selecte" + day}
                          onChange={({ target: { checked } }) => {
                            // console.log("hi9oda6ys checked", checked);
                            addRemoveSaveIndex("selected_holidays", index);
                            //----------
                            setState({
                              selected_working_days:
                                state.selected_working_days?.filter(
                                  (e) => e != index
                                ) || [],
                              // selected_weekends:
                              //   state.selected_weekends?.filter(
                              //     (e) => e != index
                              //   ) || [],
                            });
                          }}
                        />
                      </FormGroup>
                    </Label>
                  </Col>
                ))}
              </Row>

              <Row>
                <Label md="4">Working days</Label>
                {daysName.map((day, index) => (
                  <Col>
                    <Label className="w-100">
                      {day}
                      <FormGroup className="d-inline-block mx-2 vertical-middle my-0">
                        <Input
                          className="m-auto position-relative m-0 mx-2"
                          placeholder="End Time"
                          checked={state.selected_working_days?.includes(index)}
                          type="radio"
                          name={"selecte" + day}
                          onChange={({ target: { checked } }) => {
                            // console.log(
                            //   "selected_working_days checked",
                            //   checked
                            // );
                            addRemoveSaveIndex("selected_working_days", index);
                            //----------
                            setState({
                              selected_holidays:
                                state.selected_holidays?.filter(
                                  (e) => e != index
                                ) || [],
                              selected_weekends:
                                state.selected_weekends?.filter(
                                  (e) => e != index
                                ) || [],
                            });
                          }}
                        />
                      </FormGroup>
                    </Label>
                  </Col>
                ))}
              </Row>
              <hr />
              <br />
              <Row>
                <Label md="4">Bulk Update days</Label>
                {daysName.map((day, index) => (
                  <Col>
                    <Label className="w-100">
                      {day}
                      <FormGroup className="d-inline-block mx-2 vertical-middle my-0">
                        <Input
                          className="m-auto position-relative m-0 mx-2"
                          placeholder="End Time"
                          checked={state.selected_bulk_update_days?.includes(
                            index
                          )}
                          type="checkbox"
                          onChange={({ target: { checked } }) => {
                            addRemoveSaveIndex(
                              "selected_bulk_update_days",
                              index
                            );
                          }}
                        />
                      </FormGroup>
                    </Label>
                  </Col>
                ))}
              </Row>

              <Row>
                <Button onClick={bulkUpdate} color="info" className="mx-auto">
                  Bulk Update
                </Button>
              </Row>
            </CardBody>
          </Card>

          <Row className="m-auto justify-content-between align-items-center">
            <Label>Days range</Label>
            <div>
              <FormGroup>
                <DateRangePicker
                  startDate={dateRange?.startDate || null}
                  endDate={dateRange?.endDate || null}
                  onDatesChange={async ({ startDate, endDate }) => {
                    // rangeToDays(startDate, endDate);
                    setDateRange({ startDate, endDate });
                  }}
                  isOutsideRange={(day) => {
                    return false;
                  }}
                  startDateId="startDateId"
                  endDateId="endDateId"
                  focusedInput={state?.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                  onFocusChange={(focusedInput) =>
                    setState({
                      ["focusedInput"]: focusedInput,
                    })
                  }
                ></DateRangePicker>
              </FormGroup>
            </div>
            <div
              className="bg-dark"
              style={{ width: "1px", height: "30px" }}
            ></div>
            <Label>Go To Date</Label>
            <div>
              <FormGroup>
                <Input
                  type="date"
                  defaultValue={state?.tableprops?.searchText || ""}
                  onChange={(event) => {
                    let dayStr = "";
                    if (event.currentTarget.value)
                      dayStr = new Date(
                        event.currentTarget.value
                      )?.toDateString();
                    dispatch(search(dayStr));
                  }}
                  className="top-element"
                />
              </FormGroup>
            </div>
          </Row>
          <Row className="mt-2">
            <Col>
              <Table
                {...state.tableprops}
                dispatch={dispatch}
                childComponents={{
                  cellText: {
                    content: (prop) => {
                      let value = prop.rowData?.[prop.column.key];
                      // console.log(
                      //   "prop.column.key",
                      //   prop.column.key,
                      //   prop.rowData?.[prop.column.key]
                      // );
                      switch (prop.column.key) {
                        case "Day":
                          return value?.toDateString();
                        case "Holiday":
                          return editFlag ? (
                            <label className="d-flex align-items-center">
                              Holiday? :
                              <Input
                                className="m-auto position-relative"
                                checked={value}
                                type="checkbox"
                                onChange={({ target: { checked } }) =>
                                  updateDayIndexById(
                                    prop.rowData?.id,
                                    prop.column.key,
                                    checked
                                  )
                                }
                                // value={
                                //   value == "holiday" ? "working_day" : "holiday"
                                // }
                              />
                            </label>
                          ) : (
                            value + ""
                          );
                        case "Weekend":
                          return editFlag ? (
                            <label className="d-flex align-items-center">
                              Weekend? :
                              <Input
                                className="m-auto position-relative"
                                checked={value}
                                type="checkbox"
                                onChange={({ target: { checked } }) =>
                                  updateDayIndexById(
                                    prop.rowData?.id,
                                    prop.column.key,
                                    checked
                                  )
                                }
                                // value={
                                //   value == "holiday" ? "working_day" : "holiday"
                                // }
                              />
                            </label>
                          ) : (
                            value + ""
                          );
                        // case "issueStart":
                        // case "issueEnd":
                        // case "cutOff":
                        // case "callStart":
                        // case "callEnd":
                        case "Issue_Times":
                        case "Issue_Cut_Off_Times":
                        case "Call_Times":
                          let text = " ",
                            stateName = "";
                          switch (prop.column.key) {
                            // case "issueStart":
                            //   text = "Issue Start";
                            //   break;
                            // case "issueEnd":
                            //   text = "Issue End";
                            //   break;
                            // case "callStart":
                            //   text = "Call Start";
                            //   break;
                            // case "callEnd":
                            //   text = "Call End";
                            case "Issue_Times":
                              text = "Issue Times";
                              stateName = "selected_issue_time";
                              break;
                            case "Issue_Cut_Off_Times":
                              text = "Cut Off Times";
                              stateName = "selected_cutoff_time";
                              break;
                            case "Call_Times":
                              text = "Call Times";
                              stateName = "selected_call_time";
                              break;
                            default:
                              break;
                          }
                          let onChange = (value) => {
                              updateDayIndexById(
                                prop.rowData?.id,
                                prop.column.key,
                                value
                              );
                            },
                            elementState = prop.column?.key + prop.rowData?.id;
                          const handleInnerClose = () =>
                            document.querySelector(`#${elementState}`)?.click();
                          return editFlag ? (
                            <label className="modal-toggle-label">
                              <label
                                className="alert alert-info fill-white p-2 px-3 rounded-pill pointer-hover"
                                htmlFor={elementState}
                              >
                                <i className="nc-icon nc-tap-01 text-white" />
                              </label>
                              <input
                                type="checkbox"
                                id={elementState}
                                name="day-times"
                                className="d-none toggle-next"
                                onChange={({ target: { checked } }) => {
                                  setState({ [elementState]: checked });
                                  if (checked) {
                                    setState({
                                      [stateName + "s"]: value,
                                      currentInnerModal: elementState,
                                    });
                                  } else setState({ currentInnerModal: "" });
                                }}
                              />
                              <div>
                                <label>
                                  <Modal
                                    tabindex="-1"
                                    isOpen={state?.[elementState] == true}
                                    toggle={handleInnerClose}
                                  >
                                    <ModalHeader toggle={handleInnerClose}>
                                      {text} for{" "}
                                      {prop.rowData?.Day_Firestore_Timestamp?.toDateString()}
                                    </ModalHeader>
                                    <ModalBody>
                                      <AddableComponent
                                        label={""}
                                        stateName={stateName}
                                        // onChange={onChange}
                                        // currentValues={value}
                                      />
                                    </ModalBody>
                                    <ModalFooter>
                                      <Button
                                        onClick={() =>
                                          document
                                            .querySelector(`#${elementState}`)
                                            ?.click()
                                        }
                                        color="secondary"
                                      >
                                        Close
                                      </Button>
                                      <Button
                                        onClick={() =>
                                          updateDayIndexById(
                                            prop.rowData?.id,
                                            prop.column.key,
                                            state?.[stateName + "s"] || []
                                          )
                                        }
                                        color="success"
                                      >
                                        Save
                                      </Button>
                                    </ModalFooter>
                                  </Modal>
                                </label>
                              </div>
                            </label>
                          ) : (
                            renderTimesArr("", value, "badge", onChange)
                          );

                        case "action":
                          return (
                            <>
                              <Button
                                size="xs"
                                className="btn btn-primary btn-sm"
                                color="info"
                                onClick={() => setEditFlag(!editFlag)}
                              >
                                <i className="nc-icon nc-alert-circle-i" />
                              </Button>
                              <Button
                                size="xs"
                                className="btn btn-primary btn-sm"
                                color="danger"
                                onClick={() => {
                                  // console.log("prevState", prevState);
                                  let prevState = state.Days;
                                  if (prevState?.length > 0) {
                                    // console.log(
                                    //   "prevState before splice",
                                    //   prevState
                                    // );
                                    prevState.splice(
                                      prevState.findIndex(
                                        (e) => e?.id == prop.rowData?.id
                                      ),
                                      1
                                    );
                                    // console.log(
                                    //   "prevState after splice",
                                    //   prevState
                                    // );
                                  }
                                  setState({ Days: prevState });
                                }}
                              >
                                X
                              </Button>
                            </>
                          );
                      }
                    },
                  },
                }}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => setState({ modalState: false })}
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleAddEdit({ modalState: false })}
            color="info"
          >
            {state.Name?.length > 0 ? "Save" : "Add"}
          </Button>
        </ModalFooter>
      </LoadingOverlay>
    </Modal>
  );

  return (
    <LoadingOverlay
      active={state.loading}
      // active={true}
      spinner
      text="Loading"
      className="content dispenser calendars"
    >
      <style>
        {`
          .modal .modal-title{
            display:inline-block;
          }
        `}
      </style>
      <NotificationAlert ref={notificationAlert} />
      {state.modalState && AddEditModal}
      {state.alert}
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Calendars</CardTitle>
            </CardHeader>
            <CardBody>
              <Row className="w-75">
                <Col lg="1" xs="1"></Col>
                <Col lg="4" xs="5">
                  <Button
                    color="success"
                    onClick={(e) => toggleModalState()}
                    block
                  >
                    Add
                  </Button>
                </Col>
              </Row>
              <Row style={{ overflow: "hidden" }}>
                <Col className="border-right">{renderCalendars()}</Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </LoadingOverlay>
  );
}

export default Calendars;

const get2datesDaysNum = (from, to) => {
  // console.log("from , to", from, to);
  if (!from || !to) return 0;
  let difference = to.getTime() - from.getTime(),
    TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
  return TotalDays;
};

const formatDayIdFromDate = (date) => moment(date).format("YYYY-MM-DD");
