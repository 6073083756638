import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
// import am4themes_animated from "@amcharts/amcharts4/themes/animated";
// import am4themes_kelly from "@amcharts/amcharts4/themes/kelly";

import { AmchartsReact } from 'amchart4-react'

class MainLocLine extends Component {
  state = {
    chart: null
  }

  componentDidMount() {
    let chart = am4core.create("ServCtr_lineChart", am4charts.XYChart);

    this.createChart(chart);

    this.chart = chart;
  }

  handleZoom = (e) => {
    console.log(e)
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  createChart = (chart) => {
    chart.data = this.props.data; 
    chart.colors.list = [am4core.color("#4472C4")];

   // Create axes
    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());

    categoryAxis.dataFields.category = "date";

    var  valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    var series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = "value";
    series.dataFields.categoryX = "date";
    series.tooltipText = "{categoryX} : {valueY}(min)";
    chart.cursor = new am4charts.XYCursor();
    categoryAxis.renderer.grid.template.strokeOpacity = 0.07;
    valueAxis.renderer.grid.template.strokeOpacity = 0.07;
  }

  render() {
    return (
      <div>
      <div id="ServCtr_lineChart" style={{ width: "100%", height: "500px" }} />
      {this.state.chart ? <AmchartsReact chart={this.state.chart} /> : null}
      </div>
    );
  }
}

export default MainLocLine;
