import React from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import Firebase from "firebase";
import config from "../../config";

if (!Firebase.apps.length) {
  Firebase.initializeApp(config);
}
export const WarningWithConfirmMessage = ({
  text = "Are you sure?",
  confirmText = "Yes",
  cancelText = "Cancel",
  confirmCB,
  cancelCB,
}) => (
  <ReactBSAlert
    warning
    title={text}
    onConfirm={confirmCB}
    onCancel={cancelCB}
    confirmBtnBsStyle="info"
    cancelBtnBsStyle="danger"
    confirmBtnText={confirmText}
    cancelBtnText={cancelText}
    showCancel
  ></ReactBSAlert>
);

export const deleteItem = async (object) =>
  await Firebase.firestore()
    .collection(object?.collection || "")
    .doc(object?.id || "")
    .delete()
    .catch(function (err) {
      console.log("deleteItem NetworkError==>", err);
    });

export const getCalendarsOptions = async (customer_id) =>
  (
    await Firebase.firestore()
      .collection("Calendars")
      .where("Customer_ID", "==", customer_id)
      .get()
      .catch(function (err) {
        console.log("deleteItem NetworkError==>", err);
      })
  )?.docs?.map((customer) => ({
    label: customer?.data()?.Name || customer?.id,
    value: customer?.id,
  }));

export const getCustomersOptions = async () =>
  (
    await Firebase.firestore()
      .collection("Customers")
      .get()
      .catch(function (err) {
        console.log("deleteItem NetworkError==>", err);
      })
  )?.docs?.map((customer) => ({
    label: customer?.data()?.Name || customer?.id,
    value: customer?.id,
    Email:customer?.data()?.Email,
    Name:customer?.data()?.Name
  }));
