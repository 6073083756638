        
import React, { useState } from "react";
import LoadingOverlay from "react-loading-overlay";
// import ChartistGraph from "react-chartist";

import Firebase from "firebase";

import Select from 'react-select';

import 'react-tagsinput/react-tagsinput.css' // If using WebPack and style-loader.
import _ from 'lodash'

import * as am4core from "@amcharts/amcharts4/core";

import { Dropdown, DropdownItem, DropdownMenu } from 'semantic-ui-react'
import 'bootstrap/dist/css/bootstrap.css';

import {
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row
} from "reactstrap";

import { DateRangePicker } from '@progress/kendo-react-dateinputs';
import { CustomStartDateInput } from './customStartDateInput.jsx';
import { CustomEndDateInput } from './customEndDateInput.jsx';
import { CSVLink } from "react-csv";
import moment from "moment-timezone";
import config from "../../../config";


import MainLocLineChart from '../../../components/amCharts/MainLocLine';
import MainLoc3DChart from '../../../components/amCharts/MainLoc3D';
import SubLoc3DChart from '../../../components/amCharts/SubLoc3DChart';
import SubLocLineChart from '../../../components/amCharts/SubLocLineChart';
import ServLocLineChart from '../../../components/amCharts/ServLocLineChart';
import ServLoc3DChart from '../../../components/amCharts/ServLoc3DChart';
import InsrcLocLineChart from '../../../components/amCharts/InsrcLocLineChart';
import InsrcLoc3DChart from '../../../components/amCharts/InsrcLoc3DChart';
import ServctrLocLineChart from '../../../components/amCharts/ServctrLocLineChart';
import ServctrLoc3DChart from '../../../components/amCharts/ServctrLoc3DChart';
import ServusrLocLineChart from '../../../components/amCharts/ServusrLocLineChart';
import ServusrLoc3DChart from '../../../components/amCharts/ServusrLoc3DChart';
import CustomerLocLineChart from '../../../components/amCharts/CustomerLocLineChart';
import CustomerLoc3DChart from '../../../components/amCharts/CustomerLoc3DChart';
import TokenLocLineChart from '../../../components/amCharts/TokenLocLineChart';
import TokenLoc3DChart from '../../../components/amCharts/TokenLoc3DChart';
import PercentileTable from '../../../components/TablePercentile';

import { getPriority } from "os";

const servicesInfo = [];
let nMainCnt = 0, nSerCnt = 0, nTSubLoc = 0;
const uuidv1 = require('uuid/v1');

const mainLocColor = ["#932468", "#08328f", "#235789", "#0F2FA3", "#7B0FA3"];
const subLocColor = ["#5096EB", "#3621FA", "#50FBA0", "#2173FA", "#CA21FA"];
const serviceColor = ["#5FF0EE", "#4679D6", "#B96AE7", "#323ADC", "#109A6E", "#B545DA", "#2C16D3", "#16D33E",
                    "#049F9D", "#053998", "#5E0492", "#3E4296", "#7AE9C5", "#B37CC5", "#A297F1", "#8DE9A0"];
const insrcColor = ["#1C0845", "#EB0F52", "#620BF1", "#0BF13C", "#F1980B"];
const servctrColor = ["#F10BD9", "#BB26AB", "#9E3893", "#B36DAC", "#E59DDE"];
const servusrColor = ["#800FFF", "#8000FF", "#806DFF", "#A599FA", "#80EDFF", "#80B6FF", "#ADBCD1", "#BEA5F0",
                    "#A66DE6", "#71479B", "#2A12CD", "#6358B0", "#04D5F7", "#1468DA", "#4B6FA0", "#684BA1"];
const CustomerColor =  ["#808080", "#8CF886", "#031AFC", "#9C5B71", "#FA2E38"];  //["#14F609", "#8CF886", "#031AFC", "#9C5B71", "#FA2E38"]; 
const TokenColor = ["#052DF7", "#4764FA", "#8296FC", "#B2BDF6", "#6A6AA8"];

const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
}
class Report2Percentile extends React.Component {

    constructor(props) {

        super(props);
        if (!Firebase.apps.length) {
            Firebase.initializeApp(config);
        }

        this.state = {
            selectedOption: 0,
            options: [],
            selectedOptionW : 0,
            page : 0,
            page2 : 0,
            selectedNumS : 1,
            selectedNumW : 1,
            dateRangeLimitSeconds: 5184000, // number of seconds in 1 day = 86400s
                                            // number of seconds in 30 days = (30 X 86400) = 2592000s
                                            // number of seconds in 60 days = (60 x 86400) = 5184000s
            dateRangeLimitDays:60,
            loading: false,
            
            defaultDate: {},
            bVisualize: false,
            start_date_formatted: '',
            end_date_formatted: '',
            defaultTimeZone: '',
            defaultTimeZoneSet: false,

            bLoaded : false,
            bdelete : false,
            startDate: '',
            endData: '',
            main_count:1,
            download_data : [],
            access_loc : [],
            time_segment :[],
            wtime_segment : [],
         
            main_location_list: [],
            addressDefinitions : [],
            sub_location_list: [],
            subTemp : [],
            sub_location_ID: [],
           
            services:[],
            servTemp : [],
            stateOptions: [],
           
            counter_user:[],
            counter_user1:[],

            counteruser:[],
            custom_rating:[],
            custom_rating1:[],

            customrating:[],
            token_status : [],
            token_status1 : [],

            tokenstatus :[],
            serv_Username : [],
            serv_Username1 : [],

            servUsername : [],

            detailTokenData:[],
           
            dataLineChartML : [],
            data3DChartML : [],
            mainLocLabel : [],
            mainLocColor : [],

            dataLineChartSL : [],
            data3DChartSL : [],
            subLocLabel : [],
            subLocColor : [],

            dataLineChartServ : [],
            data3DChartServ : [],
            servLabel : [],
            servColor : [],

            input_srcName : [],
            inputsrcName : [],
            dataLineChartInSrc :[],
            data3DChartInSrc : [],
            InSrcLabel : [],
            InSrcColor : [],

            dataLineChartServctr : [],
            data3DChartServctr : [],
            servctrLabel : [],
            servctrColor : [],

            dataLineChartServusr : [],
            data3DChartServusr : [],
            ServusrLabel : [],
            ServusrColor : [],

            dataLineChartCustomer : [],
            data3DChartCustomer : [],
            CustomerLabel : [],
            CustomerColor : [],

            dataLineChartToken : [],
            data3DChartToken : [],
            TokenLabel : [],
            TokenColor : [],

            MLCnt : 0,
            SLCnt : 0,
            SVLCnt : 0,
            ILCnt : 0,
            SCLCnt : 0,
            SULCnt : 0,
            bdate : false,
            bmain : false,
            bsub : false,
            bservice : false,
            bUpdate : false,
            bUpdate1 : false,
            bUpdate2 : false,
            bUpdate3 : false,

            STime : null,
            longSTime : null,
            WTime : null,
            longWTime : null,

        };

        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangesub = this.handleChangesub.bind(this);
        this.handleChangeService = this.handleChangeService.bind(this);
        this.handleChangeCounterUser = this.handleChangeCounterUser.bind(this);
        this.handleChangecustomrating = this.handleChangecustomrating.bind(this);
        this.handleChangetokenstatus = this.handleChangetokenstatus.bind(this);
        this.handleChangeservUsername = this.handleChangeservUsername.bind(this);
        this.handleChangePercentS = this.handleChangePercentS.bind(this);
        this.handleChangePercentW = this.handleChangePercentW.bind(this);
    }
    
    componentDidMount() {
        this.setState({bLoaded : true});
        this.setState({loading : true});
        // this.loadDefaultDate();
        this.loadAccessLoc();

        let opt = [];
        for( let i = 0 ; i < 100+1  ; i++ )
            opt.push(i);
        if( opt.length > 0 )
            this.setState({options : opt})
    }

    loadAccessLoc(){
        let _this = this;
        let access = [];
        let temp = [];
        let i = 0;
        let email = JSON.parse(localStorage.getItem('auth_info')).email;
        Firebase.firestore().collection('Web_App_Users').doc(email).get().then(function (response) {
            if (response.exists) {
                access.push(response.data().Accessible_Locations);
            };
            for( let i = 0 ; i < access.length ; i++ )
            {
                let id = access[i];
                let len = id.length;
                for( let j = 0 ; j < len ; j++ )
                {
                    let subid = id[j];
                    temp.push({label:subid});
                }
            }
            if (access.length > 0) {
                _this.state.access_loc = temp;
                _this.loadSubLocationByMain(temp);
            }
        }) ;
    }
    componentWillUnmount(){
    }
 
   // componentDidUpdate(e) {
    // }

    handleChangeDate(event) {
        let _this = this;
        let dateObject = event.target.value;
        _this.setState({ defaultDate: dateObject });

        let defaultTimeZone = _this.state.defaultTimeZone;
        let defaultTimeZoneSet = _this.state.defaultTimeZoneSet;
        let startDateFmt = "", endDateFmt = "";

        if (defaultTimeZoneSet && _this.isValidJSDate(dateObject.start)) {
            startDateFmt = parseInt(moment(dateObject.start).tz(defaultTimeZone).unix());
            // startDateFmt = (moment(new Date(startDateFmt * 1000)).tz(defaultTimeZone).format("YYYY-MM-DD")).toString();
            startDateFmt = startDateFmt.toString();
            _this.setState({ start_date_formatted: startDateFmt });
        }

        if (defaultTimeZoneSet && _this.isValidJSDate(dateObject.end)) {
            endDateFmt = parseInt((moment(dateObject.end).tz(defaultTimeZone).endOf('day')).unix()) + 1;
            // endDateFmt = (moment(new Date(endDateFmt * 1000)).tz(defaultTimeZone).format("YYYY-MM-DD")).toString();
            endDateFmt = endDateFmt.toString();
            _this.setState({ end_date_formatted: endDateFmt });
        }

        console.log("handleChangeDate>>", "dateObject", dateObject, "defaultTimeZone", defaultTimeZone, "startDateFmt", startDateFmt, "endDateFmt", endDateFmt);
    }

    isValidJSDate(jsDateObj) {
        var timestamp = Date.parse(jsDateObj);
        if (!(isNaN(timestamp))) { return true; }
        return false;
    }

    loadMainLocations(temp) {
        let _this = this;
        // let access = temp;
        let main_locations = [];
        let mainTemp = [];
        let i = 0;
        let customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;

        Firebase.firestore().collection('Main_Locations').where('Customer_ID', '==', customer_id).get().then(function (response) {
            response.docs.forEach(function (doc) {
                main_locations.push({ value: doc.id, label: doc.data().Name });
            });

            for (i = 0; i < main_locations.length; i++) {
                let nn = main_locations[i].value;
                let b = _this.boolExist(temp, nn);
                if (b === 1)
                    mainTemp.push({ value: main_locations[i].value, label: main_locations[i].label })
            }

            if (mainTemp.length > 0) {
                _this.setState({ main_location_list: mainTemp, addressDefinitions: mainTemp });
                // _this.loadSubLocationByMain(temp, mainTemp);
            }
        }, (error) => {
            console.log("loadMainLocations Error===>", error);
        });
    }

    loadSubLocationByMain(temp) {
        let _this = this;
        let defaultTimeZone = _this.state.defaultTimeZone;
        let TT = [], main = [], main2 = [];
        let sub_locations = [];
        let i = 0;
        let customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;
        Firebase.firestore().collection('Sub_Locations').where('Customer_ID', '==', customer_id).get().then(function (response) {
            response.docs.forEach(function (doc) {
                sub_locations.push({ value: doc.id, label: doc.data().Name, mID: doc.data().Main_Location_ID, timezone: doc.data().TimeZone });
                if (defaultTimeZone == "" || defaultTimeZone == undefined || defaultTimeZone == null) {
                    let timeZoneParts = doc.data().TimeZone.split(")");
                    defaultTimeZone = timeZoneParts[1];
                    _this.setState({ defaultTimeZone: defaultTimeZone });
                    _this.setState({ defaultTimeZoneSet: true });
                    console.log("defaultTimeZone", defaultTimeZone, "defaultTimeZoneSet", true);
                    _this.loadDefaultDate();
                }
                main.push({ label: doc.data().Main_Location_ID });
            });
            for (i = 0; i < sub_locations.length; i++) {
                // let mm = sub_locations[i].mID;
                // let bb = _this.boolExistID(mainTemp, mm);
                // if( bb === 0 ) continue;

                let nn = sub_locations[i].value;
                let b = _this.boolExist(temp, nn);
                if (b === 1) {
                    TT.push({ value: sub_locations[i].value, label: sub_locations[i].label, mID: sub_locations[i].mID, timezone: sub_locations[i].timezone })
                    let mainId = sub_locations[i].mID;
                    let bb = _this.boolExist(main, mainId);
                    if (bb === 1)
                        main2.push(main[i])
                }
            }
            if (TT.length > 0) {
                _this.setState({ sub_location_list: TT, sub_location_ID: TT });
                _this.setState({ subTemp: TT });
                // // console.log("loadSubLocationByMain >>TT", TT);
                _this.loadMainLocations(main2);
                _this.loadServicesByMainAndSub(main2, TT);
            }
        }, (error) => {
            console.log("loadSubLocationByMain Error==>", error);
        });
    }

    loadServicesByMainAndSub(main, sub) {
        let _this = this;
        let servicesT = [];
        let Ser = [];
        let subValue = [];
        let customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;
        sub.map((subItem, index) => {
            subValue.push(subItem.value);
        });
        Firebase.firestore().collection('Services').where('Customer_ID', '==', customer_id).get().then(function (response) {
            response.docs.forEach(function (doc) {
                if (subValue.includes(doc.data().Sub_Location_ID)) {
                    // console.log("services ==> ", doc)
                    servicesT.push({ value: doc.id, label: doc.data().Name, mID: doc.data().Main_Location_ID, sID: doc.data().Sub_Location_ID });
                    // servicesInfo.push({value:doc.data().Sub_Location_ID, label: doc.data().Name,  date: Sec});
                }
            });

            for (let i = 0; i < servicesT.length; i++) {
                let mm = servicesT[i].mID;
                let bb = _this.boolExist(main, mm);
                if (bb === 0) continue;

                let nn = servicesT[i].sID;

                let b = _this.boolExistID(sub, nn);
                if (b === 1)
                    Ser.push(servicesT[i]);
            }
            if (Ser.length > 0) {
                // console.log("service===>", Ser);
                _this.setState({ services: Ser, servTemp: Ser, stateOptions: Ser });
                _this.setState({ loading: false });
                // _this.loadTokenData();
            } else {
                console.log("No Services..");
                _this.setState({loading: false});
            }

        }, (error) => {
            console.log("loadServicesByMainAndSub Error ===> ", error);
            _this.setState({ loading: false });
        });
        // })
    }

    loadDefaultDate() {
        let _this = this;
        let today = new Date();
        let y = today.getFullYear();
        let m = today.getMonth();
        let d = today.getDate();

        var dateObject = {
            start: new Date(y, m, 1),
            end: new Date(y, m, d)
        };

        _this.setState({ defaultDate: dateObject });

        let defaultTimeZone = _this.state.defaultTimeZone;
        let defaultTimeZoneSet = _this.state.defaultTimeZoneSet;
        let startDateFmt = "", endDateFmt = "";  

        if (defaultTimeZoneSet && _this.isValidJSDate(dateObject.start)) {
            startDateFmt = parseInt(moment(dateObject.start).tz(defaultTimeZone).unix());
            // startDateFmt = (moment(new Date(startDateFmt * 1000)).tz(defaultTimeZone).format("YYYY-MM-DD")).toString();
            startDateFmt = startDateFmt.toString();
            _this.setState({ start_date_formatted: startDateFmt });
        }

        if (defaultTimeZoneSet && _this.isValidJSDate(dateObject.end)) {
            endDateFmt = parseInt((moment(dateObject.end).tz(defaultTimeZone).endOf('day')).unix()) + 1;
            // endDateFmt = (moment(new Date(endDateFmt * 1000)).tz(defaultTimeZone).format("YYYY-MM-DD")).toString();
            endDateFmt = endDateFmt.toString();
            _this.setState({ end_date_formatted: endDateFmt });
        }

        console.log("loadDefaultDate>>", "dateObject", dateObject, "defaultTimeZone", defaultTimeZone, "startDateFmt", startDateFmt, "endDateFmt", endDateFmt);
    }

    handleChange(selectedOption) {
        this.state.main_location_list = selectedOption;
        this.state.sub_location_list = [];
        this.LoadSubLocation();
       
        if( this.state.bVisualize )
        {
            this.setState({bmain : true});
            sleep(40).then(() => {
                this.subfiltering();
            });
        }
    }
   
    LoadSubLocation(){
        let _this = this;
        let SelOpt = _this.state.main_location_list;
        let nL = SelOpt.length;
        let sub = _this.state.subTemp;
        let subtt = [];
        _this.setState({sub_location_list: []});

        for( let j = 0 ; j < nL ; j++ )
         {   for( let i = 0 ; i < sub.length ; i++)
            {
                if( SelOpt[j].value === sub[i].mID )
                    subtt.push({value : sub[i].value, label : sub[i].label, mID:sub[i].mID, timezone:sub[i].timezone});
            }
        }
        _this.state.sub_location_list = subtt;
        _this.setState({sub_location_list : subtt, sub_location_ID : subtt });

        _this.LoadServNamesBySL();

    };

    handleChangesub(selectItem){
        this.state.sub_location_list = selectItem;
        this.setState({sub_location_list : selectItem});
        this.LoadServNamesBySL();
        if( this.state.bVisualize )
        {
            this.setState({bmain : true});
            // let src = this.state.detailTokenData;
            sleep(40).then(() => {
                this.subfiltering();
            });
        }
    }

    LoadServNamesBySL(){
        let _this = this;
        let SelOpt = _this.state.sub_location_list;
        let nL = SelOpt.length;

        let sub = _this.state.servTemp;
        let subtt = [];

        _this.setState({services: []});

        for( let j = 0 ; j < nL ; j++ )
           for( let i = 0 ; i < sub.length ; i++)
                if( SelOpt[j].value === sub[i].sID )
                    subtt.push({value : sub[i].value, label : sub[i].label, mID:sub[i].mID, sID:sub[i].sID});
        _this.state.services = subtt;
        _this.state.stateOptions = subtt;
        _this.setState({services : subtt, stateOptions : subtt });
    };

    handleChangeService(selectItemService){
        this.state.services = selectItemService;
        this.setState({ services : selectItemService});

        if( this.state.bVisualize )
        {
            this.setState({bmain : true});
            // let src = this.state.detailTokenData;
            sleep(40).then(() => {
                this.subfiltering();
            });
        }
        
    }


    boolMainLoc(id){
        let IDArr = this.state.main_location_list;

        let len = IDArr.length;

        for( let i = 0 ; i < len ; i++ )
        {
            let tt = IDArr[i].value;
            if( tt === id )
                return 1;
        }
        return 0;
    }
    boolSubLoc(id){
        let IDArr = this.state.sub_location_list;

        let len = IDArr.length;
        for( let i = 0 ; i < len ; i++ )
        {
            let tt = IDArr[i].value;
            if( tt === id )
                return 1;
        }
        return 0;
    }
    boolService(id){
        let IDArr = this.state.services;
        let nCnt = 0;
        let lenid = id.length;
        let len = IDArr.length;
        for( let j = 0 ; j < lenid ; j++ )
            for( let i = 0 ; i < len ; i++ )
            {
                let tt = IDArr[i].value;
                if( tt === id[j] )
                    nCnt++;
                if( nCnt === lenid )
                    return 1;
            }

        return 0;
    }
    boolCounter(id){
        let IDArr = this.state.counteruser;
        let nCnt = 0;
        let len = IDArr.length;
        for( let i = 0 ; i < len ; i++ )
        {
            let tt = IDArr[i].value;
            if( tt === id )
                return 1;
        }

        return 0;
    }
    getTimezone(id){
        let _this = this;
        let sub = _this.state.sub_location_list;
        for( let i = 0 ; i < sub.length ; i++ )
        {
            let tt = sub[i].timezone;
            let t = tt.split(")");

            if( id === sub[i].value)
                return t[1];
        }
    }

    formatDate(date) {
        var d = new Date(date), month = '' + (d.getMonth() + 1), day = '' + d.getDate(), year = d.getFullYear();
        if (month.length < 2) { month = '0' + month } if (day.length < 2) { day = '0' + day }
        return [year, month, day].join('-');
    }

    // loadtokenData1(){ //original
    //     let _this = this;
    //     let totalData = [];
    //     let start = _this.state.defaultDate.start;
    //     let end = _this.state.defaultDate.end;
    //     let startSec = Date.parse(start)/1000;
    //     let endSec = Date.parse(end)/1000;

    //     _this.state.detailTokenData = [];
    //     Firebase.firestore().collection('Token_Details').get().then(function (response) {

    //         response.docs.forEach(function (doc) {
    //             var Day = doc.data().Created_Datetime;
    //             var str = Day.substring(0, 3);
    //             let m = 0;

    //             switch(str)
    //             {
    //                 case "Jan": m = 0; break;
    //                 case "Feb": m = 1; break;
    //                 case "Mar": m = 2; break;
    //                 case "Apr": m = 3; break;
    //                 case "May": m = 4; break;
    //                 case "Jun": m = 5; break;
    //                 case "Jul": m = 6; break;
    //                 case "Aug": m = 7; break;
    //                 case "Sep": m = 8; break;
    //                 case "Oct": m = 9; break;
    //                 case "Nov": m = 10; break;
    //                 case "Dec": m = 11; break;
    //             }
    //             str = Day.substring(4, 7);
    //             let d = parseInt(str, 10);
    //             str = Day.substring(7, 12);
    //             let y = parseInt(str, 10);
    //             str = Day.substring(16, 19);
    //             let hh = parseInt(str, 10);
    //             str = Day.substring(18, 20);
    //             let mm = parseInt(str, 10);
    //             str = Day.substring(21, 24);
    //             let ss = parseInt(str, 10);

    //             let ID2 = doc.data().Sub_Location_ID;
    //             let timez = _this.getTimezone(ID2);
                 
    //             Day = new Date(y, m, d, hh, mm, ss).toLocaleString("en-US", {timeZone: timez});
    //             let Sec = new Date(Day);
    //             let sec = Date.parse(Sec)/1000;
    //             if( sec > startSec && sec < endSec)
    //             {
    //                 let ID1 = doc.data().Main_Location_ID;
    //                 let ID3 = doc.data().Services_ID;
    
    //                 let main = doc.data().Main_Location_Name;
    //                 let sub = doc.data().Sub_Location_Name;
    //                 let serv= doc.data().Service_Name;
    //                 let scd = doc.data().Service_Cancelled_Datetime;
    //                 let mauser = doc.data().Mobile_App_User_Contact;
    //                 let mauid = doc.data().Mobile_App_User_ID;
    //                 let cc = doc.data().Customer_Comments;
    //                 let cusID=doc.data().Customer_ID;
    //                 let cusRDtime = doc.data().Customer_Rated_Datetime;
    //                 let name = doc.data().Customer_Rating;
    //                 let cusScr = doc.data().Customer_Source;
    //                 let counterN = doc.data().Served_Counter;
    //                 let pt = doc.data().Priority;
    //                 let ptStr = parseInt(pt) == 0 ? "Normal" : "High";
    //                 let tcun = doc.data().Token_Created_User_Name;
    //                 let tn = doc.data().Token_Number;
    //                 let token = doc.data().Token_Status;
    //                 let suname = doc.data().Served_User_Name;
    //                 let inputsource = doc.data().Input_Source;

    //                 let st = doc.data().Serving_Time;
    //                 let wt = doc.data().Waiting_Time;
    //                 var a = [], seconds = 0;

    //                 if( st.length > 4 )
    //                 {
    //                     a = st.split(':'); // split it at the colons
    //                     seconds= (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]); 
    //                 }
    //                 var b = parseInt(wt, 10);
    //                 totalData.push({id: doc.id, date: Sec, Day: Day, mainID:main, mID: ID1, subID:sub, sID: ID2, serID:serv, vID: ID3, SCDT: scd,
    //                     MAUC: mauser, MAUI: mauid, CC:cc, CUSID: cusID, CUSRDT: cusRDtime, label: name, CUSSRC:cusScr, inputSrc: inputsource,
    //                     counteruser: counterN, servusr: suname, TCUN: tcun, TN: tn, token: token,
    //                     ST: seconds, WT: b, Prio: ptStr });
    //             }

    //         });
            
    //         if( totalData.length > 0 )
    //         {
    //             _this.state.detailTokenData = totalData;
    //             _this.setState({detailTokenData : totalData});
    //             _this.subfiltering();
    //         }

    //     })
    // }

    notifyMessage = (place, color, text) => {
        var type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }

        var options = {};
        options = {
            place: place,
            message: (
                <div className="text-md-center">
                    <div>
                        <b>{text}</b>
                    </div>
                </div>
            ),
            type: type,
            icon: "now-ui-icons ui-1_bell-53",
            autoDismiss: 3
        };
        if (options !== null) { this.notificationAlert.notificationAlert(options) }
    };

    loadTokenData() {
        let _this = this,
            startDateFmt = _this.state.start_date_formatted, endDateFmt = _this.state.end_date_formatted;
        let subLocIDArrVals = _this.exportArrayValues(_this.state.sub_location_ID),
            servIDArrVals = _this.exportArrayValues(_this.state.services);
        // console.log("reached here #1", "start", start, "end", end,
        //     "startSec", startSec, "endSec", endSec,
        //     "startDateFmt", startDateFmt, "endDateFmt", endDateFmt,
        //     '_this.state.stateOptions', _this.state.stateOptions, '_this.state.services', _this.state.services,
        //     'subLocIDArrVals', subLocIDArrVals, 'servIDArrVals', servIDArrVals,
        // );
        let totalData = [];
        _this.setState({ detailTokenData: [] });
        // let customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;

        console.log({
            "Currently running function:": "loadTokenData",
            start_date: startDateFmt,
            end_date: endDateFmt,
            sub_loc_arr: subLocIDArrVals,
            servs_arr: servIDArrVals,
            query_type: "vw_1"
        });

        Firebase.functions().httpsCallable('getBigQueryAnalyticsData', { timeout: 540000 }) ({ //HttpsCallableOptions timeout in ms
            start_date: startDateFmt,
            end_date: endDateFmt,
            sub_loc_arr: subLocIDArrVals,
            servs_arr: servIDArrVals,
            query_type: "vw_1"
        }).then(function (response) {

            console.log('response.data.length:' + response.data.length);

            if (response.data.length == 0){

                _this.notifyMessage("tc", 3, "No data found for these selected dates, locations and services!");
                window.setTimeout(function() { _this.props.history.push("/dashboards") }, 2000);
            
            } else {
    //console.log('data count:' + response.data.length);
    //console.log("response", response.data);
                JSON.parse(response.data).forEach(function (doc) {
                    // console.logs(i, "doc",doc);
                    if (_this.boolExistID(_this.state.services, doc.Services_ID) === 1) {
                        //let dateTimeString = (doc.Created_Date_Formated).toString();
                        let DayStr = doc.Created_Date_Formated;
                        let DayStr1 = DayStr.value;
                        let Day = (Date.parse(DayStr1)) / 1000; //new Date((dateTimeString.substring(0, 4),(dateTimeString.substring(5, 7),(dateTimeString.substring(8, 11))))); //Created_Datetime Created_Datetime_Unix_Timestamp
                        let dateStr2 = doc.Created_DateTime;
                        let ID2 = doc.Sub_Location_ID;
                        // console.log("doc.Created_Date_Formated", doc.Created_Date_Formated);
                        // console.log("dateTimeString",Day);
                        // console.log("Day", Day);
                        let Sec = new Date(Day * 1000);
                        let ID1 = doc.Main_Location_ID;
                        let ID3 = doc.Services_ID;

                        let main = doc.Main_Location_Name != undefined? doc.Main_Location_Name:"";
                        let sub = doc.Sub_Location_Name != undefined? doc.Sub_Location_Name:"";
                        let serv = doc.Service_Name != undefined? doc.Service_Name:"";
                        let sc = doc.Cancelled != undefined? doc.Cancelled:"";
                        let scd =  doc.Service_Cancelled_Datetime != undefined? doc.Service_Cancelled_Datetime:"";
                        let cusID = doc.Customer_ID != undefined? doc.Customer_ID:"";

                        let ssDate = doc.Service_Start_Date != undefined? doc.Service_Start_Date:""; //new              
                        let suid = doc.Served_User_Id != undefined? doc.Served_User_Id:""; //new
                        let suname = doc.Served_User_Name != undefined? doc.Served_User_Name:""; //new?
                        let counterN = doc.Served_Counter != undefined? doc.Served_Counter:""; //new?

                        let cc = doc.Customer_Comments != undefined? doc.Customer_Comments:"";
                        let mauc = doc.Mobile_App_User_Contact != undefined? doc.Mobile_App_User_Contact:""; //new
                        let mauid = doc.Mobile_App_User_ID != undefined? doc.Mobile_App_User_ID:""; //new
                        let maue = doc.Mobile_App_User_Email != undefined? doc.Mobile_App_User_Email:""; //new
                        let maun = doc.Mobile_App_User_Name != undefined? doc.Mobile_App_User_Name:""; //new
                        let rdtime = doc.Rated_Datetime != undefined? doc.Rated_Datetime:""; //new
                        let rcom = doc.Rated_Comments != undefined? doc.Rated_Comments:""; //new

                        let rating, name = "";
                        if (doc.Rating != undefined){
                            rating = doc.Rating; //new
                            name = doc.Rating;
                        } 
                        
                        let cusScr = doc.Customer_Source != undefined? doc.Customer_Source:"";
                        let pt = doc.Priority != undefined? doc.Priority:"";
                        let ptStr = "";
                        if (doc.Priority != undefined && doc.Priority != ""){
                            ptStr= parseInt(pt) == 0 ? "Normal" : "High";
                        }else{
                            ptStr = "Normal";
                        }
                        
                        let tcun =  doc.Token_Created_User_Name != undefined? doc.Token_Created_User_Name:"";
                        let tn =  doc.Token_Number != undefined? doc.Token_Number:"";
                        let token =  doc.Token_Status != undefined? doc.Token_Status:"";
                        let inputsource =  doc.Input_Source != undefined? doc.Input_Source:"";
                        let a = 0, b = 0;
                        if (doc.Serving_Time_S != undefined && doc.Serving_Time_S != null && doc.Serving_Time_S != ''){
                            let st = doc.Serving_Time_S;
                            a = parseInt(st, 10);
                        }else{
                            a = 0;
                        }
                        if (doc.Waiting_Time != undefined && doc.Waiting_Time != null && doc.Waiting_Time != ''){
                            let wt = doc.Waiting_Time;
                            b = parseInt(wt, 10);
                        }else{
                            b = 0;
                        }
                        // var a = [], seconds = 0;
                        // if (st.length > 4) {
                        //     a = st.split(':');
                        //     seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
                        // }
                        
                        totalData.push({
                            id: doc.id, date: Sec, dateUnformatted: dateStr2, Day: Day, mainID: main, mID: ID1, subID: sub, sID: ID2, serID: serv, vID: ID3, inputSrc: inputsource, ST: a, WT: b, token: token,
                            SCDT: scd, CC: cc, CUSID: cusID, CUSRDT: rdtime, CUSSRC: cusScr, TCUN: tcun, TN: tn, Prio: ptStr, label: name,
                            cusCom: cc, MAUC: mauc, MAUID: mauid, MAUE: maue, MAUN: maun, RDTIME: rdtime, RCOM: rcom, RATING: rating, servstdate: ssDate, servuid: suid, servusr: suname, counteruser: counterN
                        });

                        // totalData.push({
                        //     id: doc.id, date: Sec, Day: Day, mainID: main, mID: ID1, subID: sub, sID: ID2, serID: serv, vID: ID3, inputSrc: inputsource, ST: a, WT: b, token: token,
                        //     sc: sc,
                        //     SCDT: scd, MAUC: mauser, MAUI: mauid, CC: cc, CUSID: cusID, CUSRDT: cusRDtime, CUSSRC: cusScr, counteruser: counterN, servusr: suname, TCUN: tcun, TN: tn, Prio: ptStr, label: name,
                        // });
                    }
                });

                if (totalData.length > 0) {
                    _this.setState({ loading: false });
                    _this.setState({ detailTokenData: totalData });
                }
            }

        }).then(function () {
            // _this.setState({ loading: false });
            console.log("reached here #3 success");
            _this.subfiltering([], 0);

        }).catch(function (error) {
            _this.setState({ loading: false });
            console.log("reached here #4 error", error);
        });
        console.log("reached here #5 done");
    }

    handleChangeCounterUser(selected){
        this.state.counter_user= [];
        this.state.counter_user = selected;
        this.setState({counter_user : selected});

        this.setState({bmain : true, bsub : true});
        sleep(40).then(() => {
            this.subfiltering();
        });
    }

    handleChangetokenstatus(select){
        this.state.token_status= [];
        this.state.token_status = select;
    
        this.setState({token_status : select});
        this.setState({bmain : true, bsub : true});
        sleep(40).then(() => {
            this.subfiltering();
        });

    };
    handleChangecustomrating(select){
        this.state.custom_rating = select;
        this.setState({custom_rating : select});
        this.setState({bmain : true, bsub : true});
        sleep(40).then(() => {
            this.subfiltering();
        });
    };

    handleChangeservUsername(select){
        this.state.serv_Username= [];
        this.state.serv_Username = select;
        this.setState({serv_Username : select});
        this.setState({bmain : true, bsub : true});
        sleep(40).then(() => {
            this.subfiltering();
        });
    };
    handleChangePercentS(event){
        this.setState({selectedOption: event.target.value});
        let pp = event.target.value;
        let len = this.state.time_segment.length;
        let per = Math.floor( pp * len / 100 )+1;
        let per2 = 100*(per-1) / len;
        let per3 = 100*(per+1) / len ;
        per = (pp - per2) > (per3 - pp) ? per+1: per-1;
        if( pp === 0 ) per = 1;
        if( pp === 100 ) per = len;
        this.setState({selectedNumS: per});

        let page = Math.floor((per-1)/10) ;
        this.setState({page: page});
    };
    handleChangePercentW(event){
        this.setState({selectedOptionW: event.target.value});
        let pp = event.target.value;
        let len = this.state.wtime_segment.length;
        let per = Math.floor( pp * len / 100 )+1;
        let per2 = 100*(per-1) / len;
        let per3 = 100*(per+1) / len ;
        per = (pp - per2) > (per3 - pp) ? per+1: per-1;
        if( pp === 0 ) per = 1;
        if( pp === 100 ) per = len;

        this.setState({selectedNumW: per});

        let page = Math.floor((per-1)/10) ;
        this.setState({page2: page});

    };

    onVisualize() {
        let _this = this;
        if (!_this.state.bVisualize) {
            let start = _this.state.defaultDate.start, end = _this.state.defaultDate.end;
            let startSec = Date.parse(start) / 1000, endSec = Date.parse(end) / 1000;
            // let startSec = Date.parse(start) / 1000, endSec = (Date.parse(end) + 86400) / 1000; // number of seconds in 1 day = 86400s
            // let startDateFmt = _this.formatDate(start), endDateFmt = _this.formatDate(end);
            if ((endSec - startSec) > (_this.state.dateRangeLimitSeconds)) {
                _this.setState({ loading: false });
                _this.notifyMessage("tc", 3, `Please select a date range less than ${_this.state.dateRangeLimitDays} days!`);
            } else {
                _this.setState({ loading: true });
                // // console.log("selected Segmentation Type:", _this.state.segmentationType);
                _this.loadTokenData();
                // _this.subfiltering([], 0);
            }
        }
    }

    onVisualize() {
        let _this = this;
        if (!_this.state.bVisualize) {
            let start = _this.state.defaultDate.start, end = _this.state.defaultDate.end;
            // let startSec = Date.parse(start) / 1000, endSec = Date.parse(end) / 1000;
            let startSec = Date.parse(start) / 1000, endSec = (Date.parse(end) + 86400) / 1000; // number of seconds in 1 day = 86400s
            // let startDateFmt = _this.formatDate(start), endDateFmt = _this.formatDate(end);
            if ((endSec - startSec) > (_this.state.dateRangeLimitSeconds)) {
                _this.setState({ loading: false });
                _this.notifyMessage("tc", 3, `Please select a date range less than ${_this.state.dateRangeLimitDays} days!`);
            } else {
                _this.setState({ loading: true });
                // // console.log("selected Segmentation Type:", _this.state.segmentationType);
                _this.loadTokenData();
                // _this.subfiltering([], 0);
            }
        }
    }

    mainFiltering(){
        let total = this.state.detailTokenData;
        let len = total.length;
        let main = [], sub = [], service = [];
        for (let i = 0 ; i < len ; i++ )
        {
            let b1 = this.boolExist(main, total[i].mainID );
            let b2 = this.boolExist(sub, total[i].subID );
            let b3 = this.boolExist(service, total[i].serID );

            if( b1 === 1 && b2 === 1 && b3 ===1 ) continue;

            if( b1 === 0 ) 
            {
                main.push({value : total[i].id, label: total[i].mainID, mID: total[i].mID, sID: total[i].sID, vID : total[i].vID });
                // addressDefinitions.push({value : total[i].id, label: total[i].mainID, mID: total[i].mID, sID: total[i].sID, vID : total[i].vID });
            }
            if( b2 === 0 ) 
                sub.push({value: total[i].id, label: total[i].subID, mID: total[i].mID, sID: total[i].sID, vID : total[i].vID })
            if( b3 === 0 ) 
                service.push({value: total[i].id, label: total[i].serID,  mID: total[i].mID, sID: total[i].sID, vID : total[i].vID })
        }

        this.setState({main_location_list : main, addressDefinitions : main, sub_location_list : sub, subTemp: sub, services : service, servTemp: service, sub_location_ID : sub, stateOptions: service});
    }

    boolExist(temp, name){
        let bN = 0;
        for( let j = 0 ; j < temp.length ; j++ )
        {
            if( name === temp[j].label )
            {
                bN = 1;
                break;
            }
        }
        return bN;
    }

    boolExistID(temp, name){
        let bN = 0;
        for( let j = 0 ; j < temp.length ; j++ )
        {
            if( name === temp[j].value )
            {
                bN = 1;
                break;
            }
        }
        return bN;
    }

    exportArrayValues(temp) {
        let result = [];
        for (let j = 0; j < temp.length; j++) {
            result.push(temp[j].value);
        }
        return result;
    }

    convertTimestamptoDateORTime(unixTimestamp,conversionType) { 
        // convert to milliseconds and then create a new Date object 
        let dateObj = new Date(unixTimestamp * 1000); 
        if (conversionType == "date") { return dateObj.toUTCString() }
        else if (conversionType =="time") { return (dateObj.toUTCString()).slice(-11, -4) }
    } 

    subfiltering(){ //original
        let _this = this;
        let Src = _this.state.detailTokenData;
        let len = Src.length;
        let main = _this.state.main_location_list;
        let sub = _this.state.sub_location_ID;
        let service = _this.state.services;
        let servingData = [], waitingData = [];
        let Counter = [], Token = [], Served = [], Input = [];
    
        for (let i = 0 ; i < len ; i++ )
        {
            let ID1 = Src[i].mainID;
            if(_this.boolExist(main, ID1) === 0) continue;
            let ID2 = Src[i].subID;
            if( _this.boolExist(sub, ID2) === 0) continue;
    
            let ID3 = Src[i].serID;
            if( _this.boolExist(service, ID3) === 0 ) continue;
    
            let counter = Src[i].counteruser;
            let token = Src[i].token;
            let servedName = Src[i].servusr;
            let srcName = Src[i].inputSrc;
            let st = Src[i].ST; //_this.sec2time(Src[i].ST);
            let wt = Src[i].WT;//_this.sec2time(Src[i].WT);
            let dt = Src[i].Day;
            let unformattedD = Src[i].dateUnformatted; //this.convertTimestamptoDateORTime(Src[i].Day,"date");//new
            let dateF = unformattedD.substring(0, 11); //this.convertTimestamptoDateORTime(Src[i].Day,"time");//new
            let timeF = unformattedD.substring(15, 23); 
// console.log("dateF", dateF);
// console.log("timeF", timeF);
            let Sec = Date.parse(dt)/1000;
            let ssdt = new Date((Sec+Src[i].WT)*1000).toLocaleString();
            let sedt = new Date((Sec+Src[i].WT + Src[i].ST)*1000).toLocaleString();
    
            let b1 = _this.boolExist(Counter, counter);
            let b2 = _this.boolExist(Token, token);
            let b3 = _this.boolExist(Served, servedName);
            let b4 = _this.boolExist(Input, srcName);
            
            if( token.toLowerCase() === "pending" || token.toLowerCase() === "closed" )
            {
                ssdt = "";
                st = 0;
            }
            //if( st > 0 )
                servingData.push({Created_DateTime:Src[i].Day, Created_Date_Formated: dateF, Created_Time_Formated: timeF, Main_Location_Name: Src[i].mainID, Sub_Location_Name: Src[i].subID, Service_Name: Src[i].serID, Service_Cancelled_Datetime: Src[i].SCDT.trim(),
                    Service_Start_Datetime: ssdt, Service_End_Datetime: sedt,
                    Priority: Src[i].Prio, Mobile_App_User_Email:Src[i].MAUE, Mobile_App_User_Contact:Src[i].MAUC, Mobile_App_User_ID:Src[i].MAUI, Served_Counter: counter, Customer_Comments: Src[i].CC, Customer_ID: Src[i].CUSID, Customer_Rated_Datetime: Src[i].CUSRDT,
                    Customer_Rating: Src[i].label, Customer_Source: Src[i].CUSSRC, Token_Created_User_Name:Src[i].TCUN, Token_Number: Src[i].TN, Token_Status: token,
                    Served_User_Name: servedName, Input_Source:srcName, Serving_Time: st, Waiting_Time: wt });
    
            //if( wt > 0 )
                waitingData.push({Created_DateTime:Src[i].Day, Created_Date_Formated: dateF, Created_Time_Formated: timeF, Main_Location_Name: Src[i].mainID, Sub_Location_Name: Src[i].subID, Service_Name: Src[i].serID, Service_Cancelled_Datetime: Src[i].SCDT.trim(),
                    Service_Start_Datetime: ssdt, Service_End_Datetime: sedt,
                    Priority: Src[i].Prio, Mobile_App_User_Email:Src[i].MAUE, Mobile_App_User_Contact:Src[i].MAUC, Mobile_App_User_ID:Src[i].MAUI, Served_Counter: counter, Customer_Comments: Src[i].CC, Customer_ID: Src[i].CUSID, Customer_Rated_Datetime: Src[i].CUSRDT,
                    Customer_Rating: Src[i].label, Customer_Source: Src[i].CUSSRC, Token_Created_User_Name:Src[i].TCUN, Token_Number: Src[i].TN, Token_Status: token,
                    Served_User_Name: servedName, Input_Source:srcName, Serving_Time: st, Waiting_Time: wt });
    
            
    
            if( b1 === 1 && b2 === 1 && b3 === 1 && b4 === 1) continue;
    
            if( b1 === 0 )
                Counter.push({value: Src[i].id, label: counter});
            if( b2 === 0 )
                Token.push({value: Src[i].id, label: token});
            if( b3  === 0 )
                Served.push({value: Src[i].id, label: servedName});
            if( b4 === 0 )
                Input.push({value: Src[i].id, label: srcName});
        }
    
        let sert = [], waitt = [];
    
        for( let i = 0 ; i < Math.max(servingData.length, waitingData.length) ; i++ )
        {
            if( i < servingData.length )
            {
                let counter = servingData[i].Served_Counter;
                let token = servingData[i].Token_Status;
                let servedName = servingData[i].Served_User_Name;
                let srcName = servingData[i].Input_Source;
                let b1 = _this.boolExist(Counter, counter);
                let b2 = _this.boolExist(Token, token);
                let b3 = _this.boolExist(Served, servedName);
                let b4 = _this.boolExist(Input, srcName);
    
                if( b1 === 1 && b2 === 1 && b3 === 1 && b4 === 1 )
                    sert.push(servingData[i]);
            }
            if( i < waitingData.length )
            {
                let counter = waitingData[i].Served_Counter;
                let token = waitingData[i].Token_Status;
                let servedName = waitingData[i].Served_User_Name;
                let srcName = waitingData[i].Input_Source;
                let b1 = _this.boolExist(Counter, counter);
                let b2 = _this.boolExist(Token, token);
                let b3 = _this.boolExist(Served, servedName);
                let b4 = _this.boolExist(Input, srcName);
    
                if( b1 === 1 && b2 === 1 && b3 === 1 && b4 === 1 )
                    waitt.push(waitingData[i]);
            }
        }
        let custom = [];
        custom.push({value : "aa", label: "VERY_GOOD"});
        custom.push({value : "bb", label: "SATISFIED"});
        custom.push({value : "cc", label: "BAD"});
        custom.push({value : "dd", label: "VERY_BAD"});
        // custom.push({value : "ee", label: ""});
    
        _this.state.custom_rating = custom;
        if( !this.state.bsub )
        {
            _this.state.counter_user = Counter;
            _this.state.token_status = Token;
            _this.state.serv_Username = Served;
            _this.state.input_srcName = Input;
            _this.state.custom_rating = custom;
    
            _this.state.counter_user1 = Counter;
            _this.state.token_status1 = Token;
            _this.state.serv_Username1 = Served;
            _this.state.input_srcName1 = Input;
            _this.state.custom_rating1 = custom;
        }
        if( sert.length > 0)
        {
            _this.DataFiltering(sert, waitt);
        }
        if( sert.length === 0 )
        {
            _this.state.time_segment = [];
            _this.state.wtime_segment = [];
            _this.setState({loading : false, bVisualize : true, bMain : false});
        }
    
    };

    // subfiltering1(select, ncase) { //new

    //     let _this = this;
    //     let Src = _this.state.detailTokenData;
    //     let identy = null;
    //     let len = Src.length;
    //     // alert(len)
    //     // if( len === 0 )
    //     // {
    //     //     _this.setState({loading: false})
    //     //     return;
    //     // }
    //     let main = [], sub = [], service = [];
    //     let downloaddata = [], returndata = [];
    //     let Counter = [], Token = [], Served = [], Input = [], custom = [];
    //     for (let i = 0; i < len; i++) {
    //         if (ncase !== 0) {
    //             switch (ncase) {
    //                 case 1: identy = Src[i].mainID; break;
    //                 case 2: identy = Src[i].subID; break;
    //                 case 3: identy = Src[i].serID; break;
    //                 case 4: identy = Src[i].counteruser; break;
    //                 case 5: identy = Src[i].label; break;
    //                 case 6: identy = Src[i].token; break;
    //                 case 7: identy = Src[i].servusr; break;
    //             }
    //             if (_this.boolExist(select, identy) === 0) continue;
    //         }

    //         let ID1 = Src[i].mID;
    //         let ID2 = Src[i].sID;
    //         let ID3 = Src[i].vID;
    //         let main1 = [], sub1 = [], service1 = [];
    //         main1 = Src[i].mainID;
    //         sub1 = Src[i].subID;
    //         service1 = Src[i].serID;

    //         let token = Src[i].token;

    //         let srcName = Src[i].inputSrc;
    //         let cus = Src[i].label;
    //         let st = _this.sec2time(Src[i].ST);
    //         let wt = _this.sec2time(Src[i].WT);
            
    //         let servStartDate = Src[i].servstdate; //new
    //         let servedUserID = Src[i].servuid; //new
    //         let servedName = Src[i].servusr; //new
    //         let counter = Src[i].counteruser; //new?

    //         let CusCom = Src[i].cusCom;
    //         //let CusRating = Src[i].cusRate;

    //         let mauc = Src[i].MAUC; //new
    //         let mauid = Src[i].MAUID; //new
    //         let maue = Src[i].MAUE; //new
    //         let maun = Src[i].MAUN; //new
    //         let rdtime = Src[i].RDTIME; //new
    //         let rcom = Src[i].RCOM //new
    //         let rating = Src[i].RATING; //new

    //         // let dt = Src[i].Day;
    //         // let Sec = Date.parse(dt) / 1000;
    //         // let ssdt = new Date((Sec + Src[i].WT) * 1000).toLocaleString();
    //         // let sedt = new Date((Sec + Src[i].WT + Src[i].ST) * 1000).toLocaleString();

    //         // if (token.toLowerCase() === "pending" || token.toLowerCase() === "closed") {
    //         //     ssdt = "";
    //         //     st = "0:00:00";
    //         // }

    //         downloaddata.push({
    //             Created_DateTime: Src[i].Day, Created_DateTime_Formated: this.timeStampToDateTimeConvert(Src[i].Day), Main_Location_Name: Src[i].mainID, Sub_Location_Name: Src[i].subID, Service_Name: Src[i].serID,
    //             Input_Source: srcName, Serving_Time: st, Waiting_Time: wt, Token_Status: token, Customer_Comments: CusCom, //Rating: CusRating,
    //             Mobile_App_User_Contact: mauc, Mobile_App_User_ID: mauid, Mobile_App_User_Email: maue, Mobile_App_User_Name: maun, Rated_Datetime: rdtime, Rated_Comments: rcom, Rating: rating, Service_Start_Date: servStartDate, Served_User_ID: servedUserID, Served_User_Name: servedName, Counter: counter //, Rating: Src[i].label
    //             // Priority: Src[i].Prio, Mobile_App_User_Contact: Src[i].MAUC, Mobile_App_User_ID: Src[i].MAUID, Served_Counter: counter, Customer_Comments: Src[i].CC, Customer_ID: Src[i].CUSID, Customer_Rated_Datetime: Src[i].CUSRDT,
    //             // , Customer_Source: Src[i].CUSSRC, Token_Created_User_Name: Src[i].TCUN, Token_Number: Src[i].TN, 
    //             //,Service_Cancelled_Datetime: Src[i].SCDT.trim(),Service_Start_Datetime: ssdt, Service_End_Datetime: sedt,
    //         });

    //         // downloaddata.push({
    //         //     Created_DateTime: Src[i].Day, Main_Location_Name: Src[i].mainID, Sub_Location_Name: Src[i].subID, Service_Name: Src[i].serID,
    //         //     Input_Source: srcName, Serving_Time: st, Waiting_Time: wt, Token_Status: token, Served_User_Name: servedName
    //         //     // Priority: Src[i].Prio, Mobile_App_User_Contact: Src[i].MAUC, Mobile_App_User_ID: Src[i].MAUI, Served_Counter: counter, Customer_Comments: Src[i].CC, Customer_ID: Src[i].CUSID, Customer_Rated_Datetime: Src[i].CUSRDT,
    //         //     // Customer_Rating: Src[i].label, Customer_Source: Src[i].CUSSRC, Token_Created_User_Name: Src[i].TCUN, Token_Number: Src[i].TN, 
    //         //     //,Service_Cancelled_Datetime: Src[i].SCDT.trim(),Service_Start_Datetime: ssdt, Service_End_Datetime: sedt,
    //         // });


    //         let b1 = _this.boolExist(Counter, counter);
    //         let b2 = _this.boolExist(Token, token);
    //         let b3 = _this.boolExist(Served, servedName);
    //         let b4 = _this.boolExist(Input, srcName);
    //         let b5 = _this.boolExist(custom, cus);
    //         let bm = _this.boolExist(main, main1);
    //         let bs = _this.boolExist(sub, sub1);
    //         let bv = _this.boolExist(service, service1);
    //         if (bm === 0)
    //             main.push({ value: Src[i].id, label: main1, mID: ID1, sID: ID2, svID: ID3 });
    //         if (bs === 0)
    //             sub.push({ value: Src[i].id, label: sub1, mID: ID1, sID: ID2, svID: ID3 });
    //         if (bv === 0)
    //             service.push({ value: Src[i].id, label: service1, mID: ID1, sID: ID2, svID: ID3 });
    //         if (b1 === 0)
    //             Counter.push({ value: Src[i].id, label: counter, mID: ID1, sID: ID2, svID: ID3 });
    //         if (b2 === 0)
    //             Token.push({ value: Src[i].id, label: token, mID: ID1, sID: ID2, svID: ID3 });
    //         if (b3 === 0)
    //             Served.push({ value: Src[i].id, label: servedName, mID: ID1, sID: ID2, serID: ID3 });
    //         if (b4 === 0)
    //             Input.push({ value: Src[i].id, label: srcName, mID: ID1, sID: ID2, svID: ID3 });
    //         if (b5 === 0)
    //             custom.push({ value: Src[i].id, label: cus, mID: ID1, sID: ID2, svID: ID3 });

    //         returndata.push(Src[i]);
    //     }

    //     _this.setState({ download_data: downloaddata });
    //     if (!_this.state.bVisualize) {
    //         _this.setState({ custom_rating: custom });

    //         _this.setState({ counter_user: Counter });
    //         _this.setState({ token_status: Token });
    //         _this.setState({ serv_Username: Served });
    //         _this.setState({ input_srcName: Input });
    //         _this.setState({ custom_rating: custom });

    //         _this.setState({ counter_user1: Counter });
    //         _this.setState({ token_status1: Token });
    //         _this.setState({ serv_Username1: Served });
    //         _this.setState({ input_srcName1: Input });
    //         _this.setState({ custom_rating1: custom });
    //     }
    //     if (main.length > 0) {
    //         _this.GetTokenDataByDay(main, sub, service, Input, Counter, Served, custom, Token, returndata);
    //     }
    //     // _this.setState({ loading: false });
    // }

    DataFiltering(dd, dd2){
        let _this = this;
        let cou = _this.state.counter_user;
        let tk = _this.state.token_status;
        let su = _this.state.serv_Username;
        let cr = _this.state.custom_rating;
        let sdata = [], wdata = [];
        let cnt = 0, cnt2 = 0;
        let len = dd.length;
    
        dd.sort(function(a, b){
            return a.Serving_Time - b.Serving_Time;
        })
        
        for( let i = 0 ; i < len ; i++ ){
            let name1 = dd[i].Served_Counter;
            let name2 = dd[i].Token_Status;
            let name3 = dd[i].Served_User_Name;
            let name4 = dd[i].Customer_Rating;
            let st = _this.sec2time(dd[i].Serving_Time);
            let wt = _this.sec2time(dd[i].Waiting_Time);
            if( _this.boolExist(cou, name1) === 0 ) continue;
            if( _this.boolExist(tk, name2) === 0 ) continue;
            if( _this.boolExist(su, name3) === 0 ) continue;
            // if( _this.boolExist(cr, name4) === 0 ) continue;
    
            cnt++;
    
            let percent = 100*cnt/len;
            
            sdata.push({
                0: cnt, 
                // 1:dd[i].Created_DateTime, 
                2:dd[i].Created_Date_Formated, 3:dd[i].Created_Time_Formated, 4: dd[i].Main_Location_Name, 5: dd[i].Sub_Location_Name, 
                6: dd[i].Service_Name, 7: dd[i].Service_Cancelled_Datetime, 
                // 8: dd[i].Service_Start_Datetime, 9: dd[i].Service_End_Datetime, 
                10: dd[i].Priority,
                11: dd[i].Mobile_App_User_Email, 12:dd[i].Mobile_App_User_Contact, //13:dd[i].Mobile_App_User_ID, 
                14: dd[i].Served_Counter, 15: dd[i].Customer_Comments, //16: dd[i].Customer_ID,
                17: dd[i].Customer_Rated_Datetime, 
                18: dd[i].Customer_Rating, 19: dd[i].Customer_Source, 20:dd[i].Token_Created_User_Name, 21: dd[i].Token_Number, 
                22: dd[i].Token_Status, 23: dd[i].Served_User_Name, 24:dd[i].Input_Source, 25: st, 26: wt, 27 : percent.toFixed(2)
            });
    
        }
    
        dd = []; cnt = 0;
        dd2.sort(function(a, b){
            return a.Waiting_Time - b.Waiting_Time;
        })
        dd = dd2;
        len = dd.length;
        for( let i = 0 ; i < len ; i++ ){
            let name1 = dd[i].Served_Counter;
            let name2 = dd[i].Token_Status;
            let name3 = dd[i].Served_User_Name;
            let name4 = dd[i].Customer_Rating;
            let st = _this.sec2time(dd[i].Serving_Time);
            let wt = _this.sec2time(dd[i].Waiting_Time);
    
            if( _this.boolExist(cou, name1) === 0 ) continue;
            if( _this.boolExist(tk, name2) === 0 ) continue;
            if( _this.boolExist(su, name3) === 0 ) continue;
            // if( _this.boolExist(cr, name4) === 0 ) continue;
    
            cnt++;
            let percent = 100*cnt/len;
            
            wdata.push({
                0: cnt, 
                // 1:dd[i].Created_DateTime, 
                2:dd[i].Created_Date_Formated, 3:dd[i].Created_Time_Formated, 4: dd[i].Main_Location_Name, 5: dd[i].Sub_Location_Name, 
                6: dd[i].Service_Name, 7: dd[i].Service_Cancelled_Datetime, 
                // 8: dd[i].Service_Start_Datetime, 9: dd[i].Service_End_Datetime, 
                10: dd[i].Priority,
                11:dd[i].Mobile_App_User_Email, 12:dd[i].Mobile_App_User_Contact, //13:dd[i].Mobile_App_User_ID, 
                14: dd[i].Served_Counter, 15: dd[i].Customer_Comments, 
                // 16: dd[i].Customer_ID, 
                17: dd[i].Customer_Rated_Datetime,
                18: dd[i].Customer_Rating, 19: dd[i].Customer_Source, 20:dd[i].Token_Created_User_Name, 22: dd[i].Token_Number,
                22: dd[i].Token_Status, 23: dd[i].Served_User_Name, 24:dd[i].Input_Source, 25: st, 26: wt, 27 : percent.toFixed(2)
            });
    
        }
    
        if( sdata.length > 0 && wdata.length > 0)
        {
            _this.state.time_segment = sdata;
            _this.state.wtime_segment = wdata;
            sleep(40).then(()=>{
                _this.setState({loading : false, bVisualize : true, bMain : false});
                
            })
        }
        if( sdata.length === 0 )
        {
            _this.state.time_segment = [];
            _this.setState({loading : false, bVisualize : true, bMain : false});
        }
        if( wdata.length === 0 )
        {
            _this.state.wtime_segment = [];
            _this.setState({loading : false, bVisualize : true, bMain : false});
        }
    
    }
    getLineChartDataByDaily(mainCnt, lenMain, label, sM, eM, sD, eD, nDaysOfMonth, strMonth){
        let OriginLineMLoc = [];
        if( sM === eM )
        {
            for( let i = sD ; i < eD ; i++ )
            {
                let date = strMonth[sM-1] + i;
                let bZero = 0;
                let value = [];
                let result_temp = {};
                result_temp['date'] = date;
                for ( let ii = 0 ; ii < lenMain ; ii++ )
                {
                    value[ii] = mainCnt[i*lenMain+ii];
                        if( value[ii] !== 0 )
                            bZero = 1;
                }
                if( bZero === 0 ) continue;
                
                for ( let ii = 0 ; ii < lenMain ; ii++ )
                {
                    let name = label[ii];
                    value[ii] = mainCnt[i*lenMain+ii];
                    if( value[ii] !== 0 )
                        result_temp[name] = value[ii];
                    
                }

                OriginLineMLoc.push(result_temp);
            }
        }
        else
        {
            for( let i = sD ; i < nDaysOfMonth ; i++ )
            {
                let date = strMonth[sM-1] + i;
                let bZero = 0;
                let value = [];
                let result_temp = {};
                result_temp['date'] = date;
                for ( let ii = 0 ; ii < lenMain ; ii++ )
                {
                    value[ii] = mainCnt[i*lenMain+ii];
                        if( value[ii] !== 0 )
                            bZero = 1;
                }
                if( bZero === 0 ) continue;
                
                for ( let ii = 0 ; ii < lenMain ; ii++ )
                {
                    let name = label[ii];
                    value[ii] = mainCnt[i*lenMain+ii];
                    if( value[ii] !== 0 )
                        result_temp[name] = value[ii];
                    
                }

                OriginLineMLoc.push(result_temp);
            }
            
            for( let i = 1 ; i < eD ; i++ )
            {
                let date = strMonth[eM-1] + i;
                let bZero = 0;
                let value = [];
                let result_temp = {};
                result_temp['date'] = date;
                for ( let ii = 0 ; ii < lenMain ; ii++ )
                {
                    value[ii] = mainCnt[i*lenMain+ii];
                        if( value[ii] !== 0 )
                            bZero = 1;
                }
                if( bZero === 0 ) continue;
                
                for ( let ii = 0 ; ii < lenMain ; ii++ )
                {
                    let name = label[ii];
                    value[ii] = mainCnt[i*lenMain+ii];
                    if( value[ii] !== 0 )
                        result_temp[name] = value[ii];
                    
                }

                OriginLineMLoc.push(result_temp);
            }
        }
        return OriginLineMLoc;
    };

    getLineChartDataByMonthly(mainCnt, lenMain, label, sY, eY, sM, eM, strMonth){
        let OriginLineMLoc = [];
        if( sY === eY )
        {
            for( let i = sM ; i < eM + 1 ; i++ )
            {
                let date = strMonth[i-1];
                let value = [];
                let result_temp = {};
                result_temp['date'] = date;
                
                for ( let ii = 0 ; ii < lenMain ; ii++ )
                {
                    let name = label[ii];
                    value[ii] = mainCnt[i*lenMain+ii];
                    if( value[ii] !== 0 )
                        result_temp[name] = value[ii];
                    
                }

                OriginLineMLoc.push(result_temp);
            }
        }
        else
        {
            for( let i = sM ; i < 13 ; i++ )
            {
                let date = strMonth[sM-1];
                let value = [];
                let result_temp = {};
                result_temp['date'] = date;
                
                for ( let ii = 0 ; ii < lenMain ; ii++ )
                {
                    let name = label[ii];
                    value[ii] = mainCnt[i*lenMain+ii];
                    if( value[ii] !== 0 )
                        result_temp[name] = value[ii];
                }

                OriginLineMLoc.push(result_temp);
            }
            // this.setState({loading : true});
            
            for( let i = 1 ; i < eM ; i++ )
            {
                let date = strMonth[eM-1];
                let value = [];
                let result_temp = {};
                result_temp['date'] = date;
                
                for ( let ii = 0 ; ii < lenMain ; ii++ )
                {
                    let name = label[ii];
                    value[ii] = mainCnt[i*lenMain+ii];
                    if( value[ii] !== 0 )
                        result_temp[name] = value[ii];
                }

                OriginLineMLoc.push(result_temp);
            }
        }
        return OriginLineMLoc;
    };

    pad(num, size){
        return ('000' + num ).slice(size * -1);
    }

    sec2time(time) {
        // var pad = function(num, size) { return ('000' + n?um).slice(size * -1); },
        // time = parseFloat(timeInSeconds).toFixed(3),
        let hours = Math.floor(time / 60 / 60);
        let minutes = Math.floor(time / 60) % 60;
        let seconds = Math.floor(time - minutes * 60);
    
        return this.pad(hours, 2) + ':' + this.pad(minutes, 2) + ':' + this.pad(seconds, 2);
    }
    GetTokenDataByDay(src){
        let originData = src;
        
        let TokenLoc = this.state.token_status;
   
        if( originData.length === 0)
            return;
        let mainCnt = [0,], subCnt = [0,], servCnt = [0,], insrcCnt = [], servctrCnt = [], ServusrCnt = [], CustomerCnt = [], TokenCnt = [];
        let subTcnt = [0,], mainTCnt = [0,], servTcnt = [0,], insrcTcnt = [], servctrTcnt = [], ServusrTcnt = [], CustomerTcnt = [], TokenTcnt = [];
    
        let start = this.state.defaultDate.start;
        let end = this.state.defaultDate.end;
               
        let sY = start.getFullYear();
        let sM = start.getMonth()+1;
        let sD = start.getDate();

        let eY = end.getFullYear();
        let eM = end.getMonth()+1;
        let eD = end.getDate();

        let d = new Date(sY, sM, 0);
        let nDaysOfMonth = d.getDate();
        
        var one_day = 1000 * 60 * 60 * 24 
        if (sM == 11 && sD > 25) 
            end.setFullYear(eY + 1) 
          
        // To Calculate the result in milliseconds and then converting into days 
        var Result = Math.round(end.getTime() - start.getTime()) / (one_day); 
          
        // To remove the decimals from the (Result) resulting days value 
        var Final_Result = Result.toFixed(0); 

        let strMonth = ["Jan ", "Feb ", "Mar ", "Apr ", "May ", "Jun ", "Jul ", "Aug ", "Sep ", "Oct ", "Nov ", "Dec "];

        let lenToken = TokenLoc.length;
    
    let AveStime = 0, AveWtime = 0, lWtime = 0, lStime = 0, ss = [], pp = [], No = [], cc = [];
    let ws = [], wp = [], wn = [], wc = [];
    let sSeg1 = [], sSeg2 = [], sSeg3 = [], sSeg4 = [], sSeg5 = [], sSeg6 = [], sSeg7 = [];
    for ( let kk = 0 ; kk < 7 ; kk++ )
    {
        ss[kk] = 0; ws[kk] = 0;
        pp[kk] = 0; wp[kk] = 0;
        No[kk] = 0; wn[kk] = 0;
        cc[kk] = 0; wc[kk] = 0;
    }
/// Get data for chart drawing /////////////////////////////        
        if( Final_Result <= 31 )
        {
            for( let i = 0 ; i < originData.length ; i++ )
            {
                let Day = originData[i].date;
                let ost = parseInt(originData[i].ST, 10);
                let wst = parseInt(originData[i].WT, 10);
                AveStime += ost;
                if( lStime < ost ) lStime = ost;

                AveWtime += wst;
                if( lWtime < wst ) lWtime = wst;
                // let YY = Day.getFullYear();
                // let MM = Day.getMonth()+1;
                let DD = Day.getDate();
                
                let TokenName = originData[i].token;

                for( let j = 0 ; j < lenToken ; j++ )
                {
                    if( TokenName === TokenLoc[j].label )
                    {
                        let nn = DD * lenToken + j;
                        TokenCnt[nn]++;
                        TokenTcnt[j]++;

                        if( TokenName.toLowerCase() === "served")
                        {
                            if(ost <= 300 ) ss[0]++;
                            else if( ost <= 600 ) ss[1]++;
                            else if( ost <= 900 ) ss[2]++;
                            else if( ost <= 1200) ss[3]++;
                            else if( ost <= 1500) ss[4]++;
                            else if( ost <= 1800) ss[5]++;
                            else ss[6]++;

                            if(wst <= 300 ) ws[0]++;
                            else if( wst <= 600 ) ws[1]++;
                            else if( wst <= 900 ) ws[2]++;
                            else if( wst <= 1200) ws[3]++;
                            else if( wst <= 1500) ws[4]++;
                            else if( wst <= 1800) ws[5]++;
                            else ws[6]++;
                        }else if( TokenName.toLowerCase() === "pending")
                        {
                            if(ost <= 300 ) pp[0]++;
                            else if( ost <= 600 ) pp[1]++;
                            else if( ost <= 900 ) pp[2]++;
                            else if( ost <= 1200) pp[3]++;
                            else if( ost <= 1500) pp[4]++;
                            else if( ost <= 1800) pp[5]++;
                            else pp[6]++;

                            if(wst <= 300 ) wp[0]++;
                            else if( wst <= 600 ) wp[1]++;
                            else if( wst <= 900 ) wp[2]++;
                            else if( wst <= 1200) wp[3]++;
                            else if( wst <= 1500) wp[4]++;
                            else if( wst <= 1800) wp[5]++;
                            else wp[6]++;
                        }else if( TokenName.toLowerCase() === "no show")
                        {
                            if(ost <= 300 ) No[0]++;
                            else if( ost <= 600 ) No[1]++;
                            else if( ost <= 900 ) No[2]++;
                            else if( ost <= 1200) No[3]++;
                            else if( ost <= 1500) No[4]++;
                            else if( ost <= 1800) No[5]++;
                            else No[6]++;

                            if(wst <= 300 ) wn[0]++;
                            else if( wst <= 600 ) wn[1]++;
                            else if( wst <= 900 ) wn[2]++;
                            else if( wst <= 1200) wn[3]++;
                            else if( wst <= 1500) wn[4]++;
                            else if( wst <= 1800) wn[5]++;
                            else wn[6]++;
                        }else if( TokenName.toLowerCase() === "closed")
                        {
                            if(ost <= 300 ) cc[0]++;
                            else if( ost <= 600 ) cc[1]++;
                            else if( ost <= 900 ) cc[2]++;
                            else if( ost <= 1200) cc[3]++;
                            else if( ost <= 1500) cc[4]++;
                            else if( ost <= 1800) cc[5]++;
                            else cc[6]++;

                            if(wst <= 300 ) wc[0]++;
                            else if( wst <= 600 ) wc[1]++;
                            else if( wst <= 900 ) wc[2]++;
                            else if( wst <= 1200) wc[3]++;
                            else if( wst <= 1500) wc[4]++;
                            else if( wst <= 1800) wc[5]++;
                            else wc[6]++;
                        }
                    }

                }
            }
    /// Serving time , long serving time, wating time, long waiting time ////
            let ttemp = Math.floor(AveStime/originData.length);
            let ti = this.sec2time(ttemp);
            ttemp = Math.floor(AveWtime/originData.length);
            let wi = this.sec2time(ttemp);
            ttemp = Math.floor(lStime);
            let ls = this.sec2time(ttemp);
            ttemp = Math.floor(lWtime);
            let lw = this.sec2time(ttemp);
            this.setState({longSTime: ls, STime : ti,  longWTime : lw, WTime : wi});

            sSeg1 = {"id": 1, "Interval":'5 minutes and less', "Served": ss[0], "Pending":pp[0], "NoShow": No[0], "Closed": cc[0]};
            sSeg2 = {"id": 2, "Interval":"5 to 10 minutes", "Served": ss[1], "Pending":pp[1], "NoShow": No[1], "Closed": cc[1]};
            sSeg3 = {"id": 3, "Interval":"10 to 15 minutes", "Served": ss[2], "Pending":pp[2], "NoShow": No[2], "Closed": cc[2]};
            sSeg4 = {"id": 4, "Interval":"15 to 20 minutes", "Served": ss[3], "Pending":pp[3], "NoShow": No[3], "Closed": cc[3]};
            sSeg5 = {"id": 5, "Interval":"20 to 25 minutes", "Served": ss[4], "Pending":pp[4], "NoShow": No[4], "Closed": cc[4]};
            sSeg6 = {"id": 6, "Interval":"25 to 30 minutes", "Served": ss[5], "Pending":pp[5], "NoShow": No[5], "Closed": cc[5]};
            sSeg7 = {"id": 7, "Interval":"Above 30 minutes", "Served": ss[6], "Pending":pp[6], "NoShow": No[6], "Closed": cc[6]};
            
            let segment = [sSeg1, sSeg2, sSeg3, sSeg4, sSeg5, sSeg6, sSeg7];
            this.setState({time_segment: segment});
            segment = [];
            sSeg1 = {"id": 1, "Interval":'5 minutes and less', "Served": ws[0], "Pending":wp[0], "NoShow": wn[0], "Closed": wc[0]};
            sSeg2 = {"id": 2, "Interval":"5 to 10 minutes", "Served": ws[1], "Pending":wp[1], "NoShow": wn[1], "Closed": wc[1]};
            sSeg3 = {"id": 3, "Interval":"10 to 15 minutes", "Served": ws[2], "Pending":wp[2], "NoShow": wn[2], "Closed": wc[2]};
            sSeg4 = {"id": 4, "Interval":"15 to 20 minutes", "Served": ws[3], "Pending":wp[3], "NoShow": wn[3], "Closed": wc[3]};
            sSeg5 = {"id": 5, "Interval":"20 to 25 minutes", "Served": ws[4], "Pending":wp[4], "NoShow": wn[4], "Closed": wc[4]};
            sSeg6 = {"id": 6, "Interval":"25 to 30 minutes", "Served": ws[5], "Pending":wp[5], "NoShow": wn[5], "Closed": wc[5]};
            sSeg7 = {"id": 7, "Interval":"Above 30 minutes", "Served": ws[6], "Pending":wp[6], "NoShow": wn[6], "Closed": wc[6]};

            segment = [sSeg1, sSeg2, sSeg3, sSeg4, sSeg5, sSeg6, sSeg7];
            this.setState({wtime_segment: segment});
            segment =[];

        }
        else
        {
            for( let i = 0 ; i < originData.length ; i++ )
            {
                let Day = originData[i].date;
                
                let ost = parseInt(originData[i].ST, 10);
                let wst = parseInt(originData[i].WT, 10);
                AveStime += ost;
                if( lStime <ost )
                    lStime = ost;

                AveWtime += wst;
                if( lWtime < wst ) lWtime = wst;

                // let YY = Day.getFullYear();
                let DD = Day.getMonth()+1;
                let TokenName = originData[i].token;

                for( let j = 0 ; j < lenToken ; j++ )
                {
                    if( TokenName === TokenLoc[j].label )
                    {
                        let nn = DD * lenToken + j;
                        TokenCnt[nn]++;
                        TokenTcnt[j]++;

                        if( TokenName.toLowerCase() === "served")
                        {
                            if(ost <= 300 ) ss[0]++;
                            else if( ost <= 600 ) ss[1]++;
                            else if( ost <= 900 ) ss[2]++;
                            else if( ost <= 1200) ss[3]++;
                            else if( ost <= 1500) ss[4]++;
                            else if( ost <= 1800) ss[5]++;
                            else ss[6]++;

                            if(wst <= 300 ) ws[0]++;
                            else if( wst <= 600 ) ws[1]++;
                            else if( wst <= 900 ) ws[2]++;
                            else if( wst <= 1200) ws[3]++;
                            else if( wst <= 1500) ws[4]++;
                            else if( wst <= 1800) ws[5]++;
                            else ws[6]++;
                        }else if( TokenName.toLowerCase() === "pending")
                        {
                            if(ost <= 300 ) pp[0]++;
                            else if( ost <= 600 ) pp[1]++;
                            else if( ost <= 900 ) pp[2]++;
                            else if( ost <= 1200) pp[3]++;
                            else if( ost <= 1500) pp[4]++;
                            else if( ost <= 1800) pp[5]++;
                            else pp[6]++;

                            if(wst <= 300 ) wp[0]++;
                            else if( wst <= 600 ) wp[1]++;
                            else if( wst <= 900 ) wp[2]++;
                            else if( wst <= 1200) wp[3]++;
                            else if( wst <= 1500) wp[4]++;
                            else if( wst <= 1800) wp[5]++;
                            else wp[6]++;
                        }else if( TokenName.toLowerCase() === "no show")
                        {
                            if(ost <= 300 ) No[0]++;
                            else if( ost <= 600 ) No[1]++;
                            else if( ost <= 900 ) No[2]++;
                            else if( ost <= 1200) No[3]++;
                            else if( ost <= 1500) No[4]++;
                            else if( ost <= 1800) No[5]++;
                            else No[6]++;

                            if(wst <= 300 ) wn[0]++;
                            else if( wst <= 600 ) wn[1]++;
                            else if( wst <= 900 ) wn[2]++;
                            else if( wst <= 1200) wn[3]++;
                            else if( wst <= 1500) wn[4]++;
                            else if( wst <= 1800) wn[5]++;
                            else wn[6]++;
                        }else if( TokenName.toLowerCase() === "closed")
                        {
                            if(ost <= 300 ) cc[0]++;
                            else if( ost <= 600 ) cc[1]++;
                            else if( ost <= 900 ) cc[2]++;
                            else if( ost <= 1200) cc[3]++;
                            else if( ost <= 1500) cc[4]++;
                            else if( ost <= 1800) cc[5]++;
                            else cc[6]++;

                            if(wst <= 300 ) wc[0]++;
                            else if( wst <= 600 ) wc[1]++;
                            else if( wst <= 900 ) wc[2]++;
                            else if( wst <= 1200) wc[3]++;
                            else if( wst <= 1500) wc[4]++;
                            else if( wst <= 1800) wc[5]++;
                            else wc[6]++;
                        }
                    }

                }
            }
    // get serving time, long serving time, waiting time, long waiting time //////
            let ttemp = Math.floor(AveStime/originData.length);
            let ti = this.sec2time(ttemp);
            ttemp = Math.floor(AveWtime/originData.length);
            let wi = this.sec2time(ttemp);
            ttemp = Math.floor(lStime);
            let ls = this.sec2time(ttemp);
            ttemp = Math.floor(lWtime);
            let lw = this.sec2time(ttemp);
            this.setState({longSTime: ls, STime : ti,  longWTime : lw, WTime : wi});

            sSeg1 = {"Interval":"5 minutes and less", "Served": ss[0], "Pending":pp[0], "No Show": No[0], "Closed": cc[0]};
            sSeg2 = {"Interval":"5 minutes and less", "Served": ss[1], "Pending":pp[1], "No Show": No[1], "Closed": cc[1]};
            sSeg3 = {"Interval":"5 minutes and less", "Served": ss[2], "Pending":pp[2], "No Show": No[2], "Closed": cc[2]};
            sSeg4 = {"Interval":"5 minutes and less", "Served": ss[3], "Pending":pp[3], "No Show": No[3], "Closed": cc[3]};
            sSeg5 = {"Interval":"5 minutes and less", "Served": ss[4], "Pending":pp[4], "No Show": No[4], "Closed": cc[4]};
            sSeg6 = {"Interval":"5 minutes and less", "Served": ss[5], "Pending":pp[5], "No Show": No[5], "Closed": cc[5]};
            sSeg7 = {"Interval":"5 minutes and less", "Served": ss[6], "Pending":pp[6], "No Show": No[6], "Closed": cc[6]};
            
            let segment = [sSeg1, sSeg2, sSeg3, sSeg4, sSeg5, sSeg6, sSeg7];
            this.setState({time_segment: segment});
            segment = [];

            sSeg1 = {"id": 1, "Interval":'5 minutes and less', "Served": ws[0], "Pending":wp[0], "NoShow": wn[0], "Closed": wc[0]};
            sSeg2 = {"id": 2, "Interval":"5 to 10 minutes", "Served": ws[1], "Pending":wp[1], "NoShow": wn[1], "Closed": wc[1]};
            sSeg3 = {"id": 3, "Interval":"10 to 15 minutes", "Served": ws[2], "Pending":wp[2], "NoShow": wn[2], "Closed": wc[2]};
            sSeg4 = {"id": 4, "Interval":"15 to 20 minutes", "Served": ws[3], "Pending":wp[3], "NoShow": wn[3], "Closed": wc[3]};
            sSeg5 = {"id": 5, "Interval":"20 to 25 minutes", "Served": ws[4], "Pending":wp[4], "NoShow": wn[4], "Closed": wc[4]};
            sSeg6 = {"id": 6, "Interval":"25 to 30 minutes", "Served": ws[5], "Pending":wp[5], "NoShow": wn[5], "Closed": wc[5]};
            sSeg7 = {"id": 7, "Interval":"Above 30 minutes", "Served": ws[6], "Pending":wp[6], "NoShow": wn[6], "Closed": wc[6]};

            segment = [sSeg1, sSeg2, sSeg3, sSeg4, sSeg5, sSeg6, sSeg7];
            this.setState({wtime_segment: segment});
            segment =[];
        }
        if( sSeg1.length > 0 )
            this.setState({bUpdate1 : false, bUpdate2 : false, bUpdate3 : false, bUpdate :false, bmain : false, bsub: false, bservice:false, bdate:false});
        this.setState({loading: false, bVisualize : true});

    }

    onShowCounter(){
        // this.state.counter_user = this.state.counter_user1;
        let tt = this.state.counter_user1;
        this.setState({counter_user : tt});
        let src = this.state.detailTokenData;
        this.setState({bUpdate1 : true});
        sleep(40).then(() => {
            this.GetTokenDataByDay(src);
        })
    }
    onShowToken(){
        let src = this.state.detailTokenData;
        let tt = this.state.token_status1;
        this.setState({token_status : tt});
        this.setState({bUpdate2: true});
        sleep(40).then(() => {
            this.GetTokenDataByDay(src);
        })
    }
    onShowUserName(){
        let src = this.state.detailTokenData;
        let tt = this.state.serv_Username1;
        this.setState({serv_Username : tt});
        this.setState({bUpdate3: true});
        sleep(40).then(() => {
            this.GetTokenDataByDay(src);
        })
    }
    onShowCustomer(){
        let src = this.state.detailTokenData;

        let tt = this.state.custom_rating1;
        this.setState({custom_rating : tt});
        this.setState({bUpdate: true});
        sleep(40).then(() => {
            this.GetTokenDataByDay(src);
        })
    }

// creating data for sub_location charts

    //V^6@}J*s
    render() {
        let { main_location_list, sub_location_list, services, defaultDate } = this.state;
        return (
            <>
                <LoadingOverlay
                    active={this.state.loading}
                    spinner
                    text='Loading'
                    className='content'
                
                >
                <Row>
                    <Col md="12">
                        <Card className="content">
                            <CardHeader>
                                <h4 className="padding_Dashboard card-title">Report - Percentile</h4>
                                {/* <CardTitle tag="h4">Report - Percentile</CardTitle> */}
                            </CardHeader>
                            <CardBody >
                            <div className = "padding_Card card-body">
                                <div className = "blockquote blockquote-primary padding">
                                    <Row>
                                        <div className="col-12 col-md-6 col-lg-3">
                                            <h5>Select Date Range</h5>

                                            {/* <div id="daterangepicker" title="daterangepicker"></div> */}
                                            <DateRangePicker 
                                                value={this.state.defaultDate}
                                                onChange={this.handleChangeDate}
                                            />
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-3">
                                            <h5>Select Main Location</h5>
                                            <Select
                                                placeholder='Select Main Location'
                                                closeMenuOnSelect={true}
                                                value={this.state.main_location_list}
                                                isMulti
                                                options={this.state.addressDefinitions}
                                                onChange = {this.handleChange}
                                            />
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-3">
                                            <h5>Select Sub Location</h5>
                                            <Select
                                                placeholder='Select Sub Location'
                                                closeMenuOnSelect={true}
                                                value={this.state.sub_location_list}
                                                clearValue
                                                isMulti
                                                options={this.state.sub_location_ID}
                                                onChange = {this.handleChangesub}
                                            />
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-3">
                                            <h5>Select Services</h5>
                                            <Select
                                                placeholder='Select Services'
                                                closeMenuOnSelect={true}
                                                value={this.state.services}
                                                isClearable
                                                clearValue
                                                isMulti
                                                options={this.state.stateOptions}
                                                onChange = {(selectItemService)=>this.handleChangeService(selectItemService)}
                                            />
                                        </div>
                                    </Row>

                                    <div className="text-center padding_button">
                                                <button disabled={!(main_location_list.length>0 && sub_location_list.length>0 && services.length>0 && defaultDate.start && defaultDate.end)} className="btn-round btn btn-outline-success h6" type="button" onClick={() => this.onVisualize()}>Visualize</button>
                                    </div>
                                    <Row>
                                        <div className="text-center">
                                            <h5>Data maybe delayed up to 20 hours.</h5>
                                        </div>
                                    </Row>
                                </div>
                            </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {this.state.bVisualize ?
                <Row >
                    <Col md="8">
                        
                        <Row>
                            <Col md="12">
                                <Card className="ml-auto mr-auto ">
                                    <CardHeader>
                                        <Row >
                                            <Col><CardTitle tag="h4" style={{marginLeft: 30, float:"left"}}>Percentile Report By Serving Time</CardTitle></Col>
                                            <Col>
                                                <select className="form-control" style = {{float: "right", width: 80, marginTop:20, marginRight:25}} 
                                                id="serving" value={this.state.selectedOption} onChange={this.handleChangePercentS}>
                                                        { this.state.options.map((option) => <option>{option}</option>)}
                                                </select></Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        {!this.state.bMain ? 
                                        <PercentileTable data = {this.state.time_segment} indexitem = {this.state.selectedNumS} page = {this.state.page}/> :
                                        <div ></div>}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <Card className="ml-auto mr-auto ">
                                    <CardHeader>
                                    <Row >
                                        <Col><CardTitle tag="h4" style={{marginLeft: 30, float:"left"}}>Percentile Report By Waiting Time</CardTitle></Col>
                                        <Col>
                                            <select className="form-control" style={{width: 80, marginTop:20, float:"right", marginRight : 25}} 
                                            id="waiting" value={this.state.selectedOptionW} onChange={this.handleChangePercentW}>
                                                    { this.state.options.map((option) => <option>{option}</option>)}
                                            </select></Col>
                                    </Row>
                                    </CardHeader>
                                    <CardBody>
                                    {!this.state.bMain ? 
                                        <PercentileTable data = {this.state.wtime_segment} indexitem = {this.state.selectedNumW} page= {this.state.page2}/>:
                                        <div></div>}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col md="12">
                                <Card className="ml-auto mr-auto ">
                                    <CardHeader>
                                        <CardTitle tag="h4" style={{marginLeft: 30}}>Download Detail data table Segment</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <chartExample1 />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row> */}
                    </Col>
                    <Col md="4">
                        <div className="sticky_Card padding_Card">
                        
                                            
                        <Card className="ml-auto mr-auto " >
                            <CardHeader>
                                <CardTitle tag="h4">Sub Filters</CardTitle>
                            </CardHeader>
                            <div style={{height:5}} ></div>
                            <CardBody>
                            <Row style={{width: "100%",justifyContent:'space-between', alignItems : "center"}}>
                                    <div style={{float:"left", marginLeft: 20 }} >
                                        <h5>Counters:</h5>
                                        </div>
                                    <div style={{marginRight: 0}}>
                                            <button  style={{height: 25, paddingTop: 3}}
                                             className="btn-round btn btn-outline-success " type="button"
                                            onClick={()=>this.onShowCounter()}>ALL</button>
                                    </div>
                                </Row>
                                <Select
                                    placeholder="Counter User"
                                    closeMenuOnSelect={true}
                                    value={this.state.counter_user}
                                    clearValue
                                    isMulti
                                    options={this.state.counter_user1}
                                    onChange = {this.handleChangeCounterUser}
                                />
                                <Row style={{marginTop:20, width: "100%",justifyContent:'space-between', alignItems : "center"}}>
                                    <div style={{float:"left", marginLeft: 20 }} >
                                        <h5 style={{margin:0, padding:0}}>Customer Feedback:</h5>
                                    </div>
                                    <div style={{marginRight: 0}}>
                                            <button  style={{height: 25, paddingTop: 3}}
                                             className="btn-round btn btn-outline-success " type="button"
                                            onClick={()=>this.onShowCustomer()}>ALL</button>
                                    </div>
                                </Row>
                                <Select
                                    placeholder="Customer feedback"
                                    closeMenuOnSelect={true}
                                    value={this.state.custom_rating}
                                    clearValue
                                    isMulti
                                    options={this.state.custom_rating1}
                                    onChange = {this.handleChangecustomrating}
                                />
                                <Row style={{marginTop:20, width: "100%",justifyContent:'space-between', alignItems : "center"}}>
                                    <div style={{float:"left", marginLeft: 20 }} >

                                        <h5>Token Status:</h5>
                                    </div>
                                    <div style={{marginRight: 0}}>
                                            <button  style={{height: 25, paddingTop: 3}}
                                             className="btn-round btn btn-outline-success " type="button"
                                            onClick={()=>this.onShowToken()}>ALL</button>
                                    </div>
                                </Row>
                                <Select
                                    placeholder="Token Status"
                                    closeMenuOnSelect={true}
                                    value={this.state.token_status}
                                    clearValue
                                    isMulti
                                    options={this.state.token_status1}
                                    onChange = {this.handleChangetokenstatus}
                                />
                                <Row style={{marginTop:20, width: "100%",justifyContent:'space-between', alignItems : "center"}}>
                                    <div style={{float:"left", marginLeft: 20 }} >
                                        <h5 style={{margin:0, padding:0}}>Served User Name:</h5>
                                        </div>
                                    <div style={{marginRight: 0}}>
                                            <button  style={{height: 25, paddingTop: 3}}
                                             className="btn-round btn btn-outline-success " type="button"
                                            onClick={()=>this.onShowUserName()}>ALL</button>
                                    </div>
                                </Row>
                                <Select
                                    placeholder="Served User Name"
                                    closeMenuOnSelect={true}
                                    value={this.state.serv_Username}
                                    clearValue
                                    isMulti
                                    options={this.state.serv_Username1}
                                    onChange = {this.handleChangeservUsername}
                                />
                                <Row style={{marginTop:20, width: "100%", justifyContent: 'center', alignItems:'center'}}>
                                    <div md="6" style={{float: "center", marginLeft: 5}}>
                                    <CSVLink
                                        data={this.state.time_segment}
                                        filename={"servingTime-data.csv"}
                                        className="btn-round btn btn-outline-success"
                                        target="_blank"
                                        >
                                        Download Data By Serving Time to CSV
                                    </CSVLink>
                                    </div>
                                    <div md="6" style={{float: "center", marginLeft: 5}}>
                                    <CSVLink
                                        data={this.state.wtime_segment}
                                        filename={"waitingTime-data.csv"}
                                        className="btn-round btn btn-outline-success"
                                        target="_blank"
                                        >
                                        Download Data By Waiting Time to CSV
                                    </CSVLink>
                                    </div>
                                </Row>
                            </CardBody>

                        </Card>
                        </div>
                    </Col>
                </Row>:
                <div ></div>
                }
                </LoadingOverlay>
            </>
        );
    }
}


export default Report2Percentile;
