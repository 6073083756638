import React, { useEffect, useState, useRef } from "react";
import Firebase from "firebase";
import config from "../../../config";
import { Alert, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Badge, NavLink } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import moment from "moment-timezone";

let colors = ["primary", "secondary", "info", "warning", "success", "danger", "dark", "light"];

function NotifComp(props) {
  const [visible, setVisible] = useState(true);
  async function dismissTok() {
    await Firebase.firestore()
      .collection("Token_Details")
      .doc(props.id)
      .update({ Notify_Details: "notified" });
    setVisible(!visible);
  }
  return (
    <Alert color={colors[props?.priority - 1]} key={props.k} isOpen={visible} toggle={dismissTok}>
      {props.title}
    </Alert>
  );
}

function Notify(props) {
  const notificationAlert1 = useRef(null);
  const [notifDis, setNotifDis] = useState(false);
  const [customer_id, set_customer_id] = useState(JSON.parse(localStorage.getItem("auth_info")).customer_id);
  const [notifyArr, setNotifyArr] = useState([]);
  const [tokLength, setLength] = useState(0);
  if (!Firebase.apps.length) {
    Firebase.initializeApp(config);
  }

  useEffect(() => {
    let firstLoad = true,
      listener = null;
    (async () => {
      let today = new Date();
      let y = today.getFullYear();
      let m = today.getMonth();
      let d = today.getDate();
      let tmpArr = [],
        accessible_locations = [];
      let user = await Firebase.firestore()
        .collection("Web_App_Users")
        .doc(JSON.parse(localStorage.getItem("auth_info")).email)
        .get();
      accessible_locations = user.data().Accessible_Locations || [];
      listener = Firebase.firestore()
        .collection("Token_Details")
        .where("Created_Datetime_Unix_Timestamp", ">=", new Date(y, m, 1).getTime() / 1000)
        .where("Created_Datetime_Unix_Timestamp", "<=", new Date(y, m, d).getTime() / 1000 + 86400)
        .where("Customer_ID", "==", customer_id)
        .where("Input_Source", "==", "Mobile_App")
        .where("Push_Web_Notifications_On_Token_Creation_Or_Changes", "==", true)
        // .where("Input_Source", "==", "Web_Interface")
        .where("Token_Status", "in", ["Pending", "Pending_Approval", "Approved_Pending_Service", "Rejected"])
        .onSnapshot((t) => {
          t.docChanges().forEach((c, k) => {
            if (accessible_locations.includes(c.doc.data().Sub_Location_ID)) {
              // console.log(c.type);
              let tmp = token(c),
                currId = c.doc.id;
              // console.log(tmp);
              if (tmp.priority > 0) {
                let index = tmpArr.findIndex((i) => i.id == currId),
                  tokenObj = { ...tmp, k, id: currId, time: Date.now() };
                index >= 0 ? (tmpArr[index] = tokenObj) : tmpArr.push(tokenObj);
                if (!firstLoad) {
                  notifyMessage("tr", tmp.priority, tmp.title);
                }
              } else {
                let index = tmpArr.findIndex((i) => i.id == currId);
                // console.log(currId);
                if (index >= 0) {
                  tmpArr.splice(index, 1);
                }
              }
            }
          });
          // else
          //   t.docs.forEach(async (td, k) => {
          //     tmpArr.push({ ...token(td), k });
          //   });
          setLength(tmpArr.length);
          setNotifyArr(tmpArr.sort((a, b) => b.time - a.time));
          if (firstLoad) firstLoad = false;
        });
    })();
    return () => {
      listener != null && listener();
    };
  }, []);
  function token(change) {
    let data = change.doc.data(),
      notify = change.doc.data()?.Notify_Details,
      approval = data?.Token_Status == "Pending_Approval",
      title = "",
      priority = 0;
    if (!notify && change.type == "added") {
      // new normal and approval tokens notifications
      title = `New ${approval ? "Approval " : ""}Token Issued for: ${data?.Service_Name} : ${
        data?.Start_Character + data.Token_Number
      }`;
      priority = approval ? 4 : 1;
    } else if (notify == "mobile_edit" && change.type == "modified") {
      // changed token on mobile
      title = `Information's been changed in Token: ${
        data?.Start_Character + data?.Token_Number
      }, Service : ${data?.Service_Name}`;
      priority = 2;
    }
    if (props?.page)
      return {
        title,
        priority,
        token_detail_id: change.doc.id,
        id: change.doc.id,
        pending_duration: Date.now() - Number(data.Requested_Firestore_Timestamp.seconds * 1000),
        sub_loc_nam: data.Sub_Location_Name,
        requested_datetime: data.Requested_Datetime?.split("UTC")[0] || data.Requested_Datetime,
        service_datetime: data.Service_Datetime,
        service_datetime_ts: data.Service_Date_Firestore_Timestamp.seconds * 1000,
        service_datetime_formatted: moment(new Date(parseInt(data.Service_Datetime_Unix_Timestamp) * 1000))
          .tz(data.TimeZone)
          .format("YYYY-MM-DD"),
        serv_name: data.Service_Name,
        // service_datetime_ts: data.service_datetime_ts,
        requested_datetime_ts: data.Requested_Firestore_Timestamp.seconds * 1000,
        mobile_app_user:
          typeof data.Mobile_App_User_ID !== "undefined" &&
          data.Mobile_App_User_ID !== null &&
          data.Mobile_App_User_ID !== ""
            ? data.Mobile_App_User_Name +
              " / " +
              data.Mobile_App_User_Email +
              " / " +
              data.Mobile_App_User_Contact
            : "",
        token_num: data?.Start_Character + data?.Token_Number,
        token_status: data.Token_Status || "",
        mau_id: data.Mobile_App_User_ID,
        time_zone: data.TimeZone,
        input_fields: data.Mobile_App_Input_Fields,
        file_attachments: data.Mobile_App_File_Attachments,
        main_loc_id: data.Main_Location_ID,
        sub_loc_id: data.Sub_Location_ID,
        serv_id: data.Services_ID,
        new_tok_ref_id: data.New_Token_Reference_ID,
        new_tok_ref_prev_tok_cancelled_reason: data.Cancelled_Reason,
        new_tok_ref_num:
          data.New_Token_Reference_ID !== null &&
          typeof data.New_Token_Reference_ID !== "undefined" &&
          data.New_Token_Reference_ID !== ""
            ? data.New_Token_Reference_Num
            : "N/A",
      };
    else
      return {
        title,
        priority,
      };
  }
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }
    return [year, month, day].join("-");
  }
  function updateTok(id, obj) {
    Firebase.firestore().collection("Token_Details").doc(id).update(obj);
  }
  const notifyMessage = (place, color, text) => {
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }

    var options = {};
    options = {
      place: place,
      message: (
        <div className="text-md-center">
          <div>
            <b>{text}</b>
          </div>
        </div>
      ),
      type: type,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 3,
    };
    if (options !== null && notificationAlert1 != null && notificationAlert1.current != null)
      notificationAlert1.current.notificationAlert(options);
  };
  if (props?.page) return notifyArr;
  else
    return (
      <>
        <NotificationAlert ref={notificationAlert1} />

        <Dropdown direction="left" isOpen={notifDis} toggle={() => setNotifDis(!notifDis)}>
          <DropdownToggle tag="span">
            <NavLink
              href="#"
              // onClick={e => this.props.history.push("/account_settings")}
            >
              <i className="nc-icon nc-bell-55" />
              {tokLength > 0 && (
                <Badge
                  pill
                  color="danger"
                  // style={{  marginLeft: "-5px" ,padding:'2px'}}
                >
                  {tokLength}
                </Badge>
              )}
              <p>
                <span className="d-lg-none d-md-block">Notifications</span>
              </p>
            </NavLink>
          </DropdownToggle>
          <DropdownMenu
            modifiers={{
              setMaxHeight: {
                enabled: true,
                order: 890,
                fn: (data) => {
                  return {
                    ...data,
                    styles: {
                      ...data.styles,
                      overflow: "auto",
                      maxHeight: "40vh",
                      minWidth: "40vw",
                      maxWidth: "700px",
                      transform: "translateX(-100%)",
                      padding: "0.5rem",
                    },
                  };
                },
              },
            }}
          >
            {notifyArr.length > 0 ? (
              notifyArr.map((e, k) => <NotifComp key={k} {...e} />)
            ) : (
              <Alert color="info">No Notifications</Alert>
            )}
          </DropdownMenu>
        </Dropdown>
      </>
    );
}
export default Notify;
