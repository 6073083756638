import React, { PureComponent } from "react";
import Firebase from "firebase";
import "../../../assets/scss/css.css";
import "../../all.css";
// import '../../fontawesome.min.css'
import { Rnd } from "react-rnd";
import ReactDOM from "react-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import {
  Col,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Label,
  Button,
  Input,
  Form,
  FormGroup,
  Table,
} from "reactstrap";
import left from "../../arrow-left-solid.svg";
import right from "../../arrow-right-solid.svg";
import Ads from "./DisplayAds";

class TimeComp extends React.Component {
  state = { timeis: "" };
  componentDidMount () {
    setInterval(
      () =>
        this.setState({
          timeis: Date()
            .toLocaleString()
            .split("GMT")[0],
        }),
      1000
    );
  }
  render () {
    return <Col className="">{this.state.timeis}</Col>;
  }
}
// function allowDrop (ev) {
//   ev.preventDefault()
// }

// function drag (ev) {
//   ev.dataTransfer.setData('Text', ev.target.id)
// }

// function drop (ev) {
//   ev.preventDefault()
//   ev.stopPropagation()
//   console.log(typeof ev.target)
//   if (ev.target.classList.contains('drag_container')) {
//     var data = ev.dataTransfer.getData('Text')
//     ev.target.insertBefore(document.getElementById(data), ev.target.firstChild)
//     ev.preventDefault()
//   } else {
//     console.log(typeof ev)
//     //   if(ev){
//     if (ev.target.parentElement.contains('drag_container'))
//       drop(ev.target.parentElement)
//     else {
//       if (ev.target.parentElement.parentElement.contains('drag_container'))
//         drop(ev.target.parentElement.parentElement)
//       else if (
//         ev.target.parentElement.parentElement.parentElement.contains(
//           'drag_container'
//         )
//       )
//         drop(ev.target.parentElement.parentElement.parentElement)
//     }
//     // }
//   }
// }

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};
const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  // change background colour if dragging
  background: isDragging ? "lightgreen" : "",

  ...draggableStyle,
});
const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : "",
  padding: isDraggingOver ? "0 0 4rem 0" : "0",
  marginTop: "0",
  marginBottom: "auto",
});

function Column (props) {
  var _this = props._this,
    k = props.k,
    p = props.p;
  if (p.id != undefined)
    return (
      <>
        <Row md="12" id={p.id} index={k} key={k + "r"}>
          {_this.state.counter_display ? (
            <Col
              className="my-auto"
              style={{ fontSize: _this.state.counter_font_size + "pt" }}
            >
              {p.name}
            </Col>
          ) : (
            ""
          )}
          {_this.state.detail_display ? (
            <Col
              className="my-auto"
              style={{ fontSize: _this.state.detail_font_size + "pt" }}
            >
              {typeof p.detail == "object"
                ? p.detail.map((e) => (
                    <>
                      <div>{e}</div>
                    </>
                  ))
                : ""}
            </Col>
          ) : (
            ""
          )}
          {_this.state.token_display ? (
            <Col
              className="my-auto"
              style={{ fontSize: _this.state.tokens_font_size + "pt" }}
            >
              {p.num}
            </Col>
          ) : (
            ""
          )}
        </Row>
        <hr className="my-0" color={_this.state.tokens_line_color} />
      </>
    );
  return false;
}

function Head (props) {
  var _this = { state: props._this };
  return (
    <>
      {_this.state.counter_display ? (
        <Col className=" my-auto">
          {_this.state.counter_name.length > 0
            ? _this.state.counter_name
            : "Counter"}
        </Col>
      ) : (
        ""
      )}
      {_this.state.detail_display ? (
        <Col className=" my-auto">
          {_this.state.detail_name.length > 0
            ? _this.state.detail_name
            : "Details"}
        </Col>
      ) : (
        ""
      )}
      {_this.state.token_display ? (
        <Col className=" my-auto">
          {_this.state.token_name.length > 0 ? _this.state.token_name : "Token"}
        </Col>
      ) : (
        ""
      )}
    </>
  );
}

function Hover (p) {
  var setState = p.setState,
    notifyMessage = p.notify,
    run = p.run,
    hov = false;
  if (document.getElementById("image") != undefined)
    hov = document.getElementById("image").classList.contains("hov");
  return (
    <>
      {!run ? (
        hov ? (
          <div id="col1-order" className="col-div">
            <div
              onClick={() => {
                var ord = document.getElementById("col1"),
                  num = parseInt(ord.getAttribute("data-order"));
                if (document.getElementById("image").classList.contains("hov"))
                  if (num > -2) {
                    ord.setAttribute("data-order", num - 1);
                    setState({ col1: num - 1 });
                    ord.style.order = num - 1;
                  } else ord.setAttribute("data-order", 0);
                else
                  notifyMessage([
                    "bc",
                    4,
                    "Resize/Reorder is in full screen only",
                  ]);
              }}
            >
              <img src={left} style={{ width: "35px" }} />
            </div>
            <div
              onClick={() => {
                var ord = document.getElementById("col1"),
                  num = parseInt(ord.getAttribute("data-order"));
                if (document.getElementById("image").classList.contains("hov"))
                  if (num < 3) {
                    ord.setAttribute("data-order", num + 1);
                    setState({ col1: num + 1 });
                    ord.style.order = num + 1;
                  } else ord.setAttribute("data-order", 0);
                else
                  notifyMessage([
                    "bc",
                    4,
                    "Resize/Reorder is in full screen only",
                  ]);
              }}
            >
              <img src={right} style={{ width: "35px" }} />
            </div>
          </div>
        ) : (
          ""
        )
      ) : (
        ""
      )}
    </>
  );
}

const Layout = React.memo((props) => {
  let _this = props._this,
    setState = props.setState,
    notifyMessage = props.notify;
  var disAd = [],
    col1 = _this.state.col1,
    col2 = _this.state.col2,
    col3 = _this.state.col3;

  var logoStyle = {
      width: _this.state.logo_width + _this.state.logo_size_type,
      // height: _this.state.logo_height + _this.state.logo_size_type,
      marginTop: _this.state.logo_padding_top + "px",
      marginBottom: _this.state.logo_padding_bottom + "px",
    },
    headerStyle = {
      textAlign: _this.state.header_align,
      background: _this.state.header_back_color,
      color: _this.state.header_font_color,
      fontSize: _this.state.header_font_size + "pt",
      fontWeight: _this.state.header_bold_font ? "bold" : "normal",
    },
    messageStyle = {
      background: _this.state.footer_message_back_color,
      color: _this.state.footer_message_font_color,
      fontSize: _this.state.footer_message_font_size + "pt",
      fontWeight: _this.state.footer_message_bold_font ? "bold" : "normal",
    },
    dateStyle = {
      background: _this.state.date_back_color,
      color: _this.state.date_font_color,
      fontSize: _this.state.date_font_size + "pt",
      fontWeight: _this.state.date_bold_font ? "bold" : "normal",
    },
    detailStyle = {
      background: _this.state.detail_back_color,
      color: _this.state.detail_font_color,
      //   fontSize: _this.state.detail_font_size + 'pt',
      fontWeight: _this.state.detail_bold_font ? "bold" : "normal",
      textAlign: _this.state.detail_align,
    };
  var id2List = {
    droppable: "list1",
    droppable2: "selected_counter_list_right",
  };

  let logo = _this.state?.logo?.url?.length > 0 && _this.state.logo_display && (
    <Row className="mx-0" style={{...dateStyle,background:'transparent'}}>
      <Col md="12" style={{ textAlign: _this.state.logo_align }}>
        <img src={_this.state.logo.url} alt="" style={logoStyle} />
      </Col>
    </Row>
  );

  const getList = (id) => _this.state[id2List[id]];
  const onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        getList(source.droppableId),
        source.index,
        destination.index
      );

      let state = { list1: items, selected_counter_list: items };
      if (source.droppableId === "droppable2") {
        state = { selected_counter_list_right: items };
      }

      setState(state);
    } else {
      const result = move(
        getList(source.droppableId),
        getList(destination.droppableId),
        source,
        destination
      );
      setState({
        list1: result.droppable,
        selected_counter_list_right: result.droppable2,
      });
    }
  };
  const onDragEnd1 = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        _this.state.counters,
        source.index,
        destination.index
      );

      let state = {
        counters: items,
        selected_counter_list: items.map((e) => e.id),
      };

      setState(state);
    }
  };

  var run = true,
    rnd = "Rnd",
    element = <> </>;
  if (_this.state.run != undefined)
    if (_this.state.run) {
      element = <></>;
      rnd = "div";
    } else run = false;
  else run = false;
  switch (_this.state.layout_theme) {
    case "mini display":
      if (_this.state.col_num != undefined)
        if (_this.state.col_num.length != 1) {
          if (_this.state.ads != undefined)
            _this.state.ads.map((e, k) => {
              if (_this.state.adVid) {
                if (e.type == "vid") disAd.push(e);
              } else if (e.type == "img") disAd.push(e);
              if (k + 1 == _this.state.ads.length) {
                setState({ selected_ad: disAd, adVid: _this.state.adVid });
              }
            });
          if (_this.state.change){

            setState({ width1: 100, height1: 100, res_sign: "%" });
            }
          setState({ col_num: [1] });
        }
        return (
          <div className="height-100 width-100 position-relative transform-none d-flex flex-column">
            <Hover notify={notifyMessage} setState={setState} run={run} />
            {logo}
           

            <div
            className="flex-fill"
            // ref={provided.innerRef}
            // style={getListStyle(snapshot.isDraggingOver)}
            >
              {_this.state.counters.map((p, k) => {
                return (
                  <div>
                    <Row
                      md="12"
                      id={p.id}
                      index={k}
                      key={k + "r"}
                      className="flex-column mx-0"
                    >
                      {!!_this.state.counter_display && (
                        <>
                          <Col className=" my-auto text-center" style={headerStyle}>
                            {_this.state.counter_name.length > 0
                              ? _this.state.counter_name
                              : "Counter"}
                          </Col>
                          <Col
                            className="my-auto text-center"
                            style={{
                              fontSize: _this.state.counter_font_size + "pt",
                            }}
                          >
                            {p.name}
                          </Col>
                        </>
                      )}
                      {!!_this.state.detail_display && (
                        <>
                          <Col className=" my-auto text-center" style={headerStyle}>
                            {_this.state.detail_name.length > 0
                              ? _this.state.detail_name
                              : "Details"}
                          </Col>
                          <Col
                            className="my-auto text-center"
                            style={{
                              fontSize: _this.state.detail_font_size + "pt",
                            }}
                          >
                            {typeof p.detail == "object"
                              ? p.detail.map((e) => (
                                  <>
                                    <div>{e}</div>
                                  </>
                                ))
                              : ""}
                          </Col>
                        </>
                      )}
                      {!!_this.state.token_display && (
                        <>
                          <Col className=" my-auto text-center" style={headerStyle}>
                            {_this.state.token_name.length > 0
                              ? _this.state.token_name
                              : "Token"}
                          </Col>
                          <Col
                            className="my-auto text-center"
                            style={{
                              fontSize: _this.state.tokens_font_size + "pt",
                            }}
                          >
                            {p.num}
                          </Col>
                        </>
                      )}
                    </Row>
                    <hr
                      className="my-0"
                      color={_this.state.tokens_line_color}
                    />
                  </div>
                );
              })}
            </div>

            <Row className="mx-0" md="12" style={messageStyle}>
              <Col
                md="12"
                style={{ textAlign: _this.state.footer_message_align }}
              >
                {_this.state.footer_message}
              </Col>
            </Row>
            {!!_this.state.date_display && (
              <Row className="mx-0" md="12" style={dateStyle}>
                <Col md="12" style={{ textAlign: _this.state.date_align }}>
                  <TimeComp />
                </Col>
              </Row>
            )}
          </div>
        );

    case "single list":
      if (_this.state.col_num != undefined)
        if (_this.state.col_num.length != 1) {
          if (_this.state.ads != undefined)
            _this.state.ads.map((e, k) => {
              if (_this.state.adVid) {
                if (e.type == "vid") disAd.push(e);
              } else if (e.type == "img") disAd.push(e);
              if (k + 1 == _this.state.ads.length) {
                setState({ selected_ad: disAd, adVid: _this.state.adVid });
              }
            });
          if (_this.state.change){

            setState({ width1: 100, height1: 100, res_sign: "%" });
            }
          setState({ col_num: [1] });
        }
      return (
        <DragDropContext onDragEnd={onDragEnd1}>
          <Rnd
            className="height-100 width-50 position-relative transform-none "
            disableDragging={true}
            enableResizing={{
              top: true,
              right: true,
              bottom: true,
              left: true,
              topRight: false,
              bottomRight: false,
              bottomLeft: false,
              topLeft: false,
            }}
            onResizeStop={(e, b, c) => {
              if (!run) {
                if (document.getElementById("image").classList.contains("hov"))
                  setState({
                    width1: c.style.width,
                    height1: c.style.height,
                  });
                else {
                  notifyMessage([
                    "bc",
                    4,
                    "Resize/Reorder in full screen only",
                  ]);
                  document.getElementById("col1").style.width =
                    _this.state.width1 + _this.state.res_sign;
                  document.getElementById("col1").style.height =
                    _this.state.height1 + _this.state.res_sign;
                }
              }
            }}
            key="a"
            id="col1"
            data-order="0"
            style={{
              display: "flex",
              flexFlow: "column",
              justifyContent: "space-between",
              textAlign: _this.state.tokens_align,
              borderBottom: !run ? "3.5px solid #7d7d7d" : "",
              borderRight: !run ? "3.5px solid #7d7d7d" : "",
            }}
            size={{
              width: _this.state.width1 + _this.state.res_sign,
              height:_this.state.height1 + _this.state.res_sign,
            }}
          > 
            <Hover notify={notifyMessage} setState={setState} run={run} />
           
            {logo}
            
            <Row md="12" className="mx-0 py-1 mb-1" style={headerStyle}>
              <Head _this={_this.state} />
            </Row>
            <Droppable
              droppableId="droppable"
              className="drag_container"
              style={{ flex: 1 }}
              dropDisabled={run ? true : false}
            >
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {_this.state.counters.map((p, k) => {
                    return (
                      <Draggable
                        key={p.id}
                        draggableId={p.id}
                        index={k}
                        isDragDisabled={run ? true : false}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <Column
                              _this={{ state: _this.state }}
                              p={p}
                              k={k}
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                </div>
              )}
            </Droppable>
            <Row className="mx-0" md="12" style={messageStyle}>
              <Col
                md="12"
                style={{ textAlign: _this.state.footer_message_align }}
              >
                {_this.state.footer_message}
              </Col>
            </Row>
            {!!_this.state.date_display&&<Row className="mx-0" md="12" style={dateStyle}>
                <Col md="12" style={{ textAlign: _this.state.date_align }}>
                  <TimeComp />
                </Col>
              </Row>}
            {/* </div> */}
          </Rnd>
        </DragDropContext>
      );
    case "single list with ad":
      if (_this.state.col_num != undefined)
        if (_this.state.col_num.length != 2) {
          if (_this.state.ads != undefined)
            _this.state.ads.map((e, k) => {
              if (_this.state.adVid) {
                if (e.type == "vid") disAd.push(e);
              } else if (e.type == "img") disAd.push(e);
              if (k + 1 == _this.state.ads.length) {
                setState({ selected_ad: disAd, adVid: _this.state.adVid });
              }
            });
          if (_this.state.change)
            setState({
              width1: 50,
              height1: 100,
              width2: 50,
              height2: 100,
              res_sign: "%",
            });

          setState({ col_num: [1, 2] });
        }
      return (
        <>
          <DragDropContext onDragEnd={onDragEnd1}>
            <Rnd
              className="height-100 width-50  position-relative transform-none "
              disableDragging={true}
              enableResizing={{
                top: true,
                right: true,
                bottom: true,
                left: true,
                topRight: false,
                bottomRight: false,
                bottomLeft: false,
                topLeft: false,
              }}
              onResizeStop={(e, b, c) => {
                if (!run) {
                  if (
                    document.getElementById("image").classList.contains("hov")
                  )
                    setState({
                      width1: c.style.width,
                      height1: c.style.height,
                    });
                  else {
                    notifyMessage([
                      "bc",
                      4,
                      "Resize/Reorder in full screen only",
                    ]);
                    document.getElementById("col1").style.width =
                      _this.state.width1 + "px";
                    document.getElementById("col1").style.height =
                      _this.state.height1 + "px";
                  }
                }
              }}
              key="a0"
              id="col1"
              data-order="0"
              style={{
                display: "flex",
                flexFlow: "column",
                justifyContent: "space-between",
                textAlign: _this.state.tokens_align,
                borderBottom: !run ? "3.5px solid #7d7d7d" : "",
                borderRight: !run ? "3.5px solid #7d7d7d" : "",
                order: col1,
              }}
              size={{
                width: parseFloat(_this.state.width1) + _this.state.res_sign,
                height: parseFloat(_this.state.height1) + _this.state.res_sign,
              }}
            >
              <Hover notify={notifyMessage} setState={setState} run={run} />

              { _this.state.logo_column == 1 && logo}
              <Row md="12" className="mx-0 py-1 mb-1" style={headerStyle}>
                <Head _this={_this.state} />
              </Row>
              <Droppable
                droppableId="droppable"
                className="drag_container"
                style={{ flex: 1 }}
                dropDisabled={run ? true : false}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {_this.state.counters.map((p, k) => {
                      return (
                        <Draggable
                          key={p.id}
                          draggableId={p.id}
                          index={k}
                          isDragDisabled={run ? true : false}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <Column
                                _this={{ state: _this.state }}
                                p={p}
                                k={k}
                              />
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                  </div>
                )}
              </Droppable>
              <Row className="mx-0" md="12" style={messageStyle}>
                <Col
                  md="12"
                  style={{ textAlign: _this.state.footer_message_align }}
                >
                  {_this.state.footer_message}
                </Col>
              </Row>
              {!!_this.state.date_display && (
                <Row className="mx-0" md="12" style={dateStyle}>
                  <Col md="12" style={{ textAlign: _this.state.date_align }}>
                    <TimeComp />
                  </Col>
                </Row>
              )}
            </Rnd>
          </DragDropContext>

          <Rnd
            className="height-100 width-50  position-relative transform-none "
            disableDragging={true}
            enableResizing={{
              top: true,
              right: true,
              bottom: true,
              left: true,
              topRight: false,
              bottomRight: false,
              bottomLeft: false,
              topLeft: false,
            }}
            onResizeStop={(e, b, c) => {
              if (!run) {
                if (document.getElementById("image").classList.contains("hov"))
                  setState({
                    width2: c.style.width,
                    height2: c.style.height,
                  });
                else {
                  notifyMessage([
                    "bc",
                    4,
                    "Resize/Reorder in full screen only",
                  ]);
                  document.getElementById("col2").style.width =
                    _this.state.width2 + "px";
                  document.getElementById("col2").style.height =
                    _this.state.height2 + "px";
                }
              }
            }}
            key="a1"
            id="col2"
            data-order="0"
            style={{
              display: "flex",
              flexFlow: "column",
              justifyContent: "space-between",
              textAlign: _this.state.tokens_align,
              borderBottom: !run ? "3.5px solid #7d7d7d" : "",
              borderRight: !run ? "3.5px solid #7d7d7d" : "",
              order: col2,
            }}
            size={{
              width: parseFloat(_this.state.width2) + _this.state.res_sign,
              height: parseFloat(_this.state.height2) + _this.state.res_sign,
            }}
          >
            <Hover notify={notifyMessage} setState={setState} run={run} />
            { _this.state.logo_column == 2 && logo}
          
            {/* <div id='col2-d'> */}
            <div
              className=" position-relative"
              style={{
                display: "flex",
                overflow: "hidden",
                flexFlow: "column",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <Ads
                ads={_this.state.selected_ad}
                dur={_this.state.imgDur}
                sliding={_this.state.sliding}
              />
            </div>
            {/* </div> */}
          </Rnd>
        </>
      );
    case "double list":
      var i = 0,
        j = 0;
      if (_this.state.col_num != undefined)
        if (_this.state.col_num.length != 2) {
          if (_this.state.ads != undefined)
            _this.state.ads.map((e, k) => {
              if (_this.state.adVid) {
                if (e.type == "vid") disAd.push(e);
              } else if (e.type == "img") disAd.push(e);
              if (k + 1 == _this.state.ads.length) {
                setState({ selected_ad: disAd, adVid: _this.state.adVid });
              }
            });
          if (_this.state.change)
            setState({
              width1: 50,
              height1: 100,
              width2: 50,
              res_sign: "%",
              col_num: [1, 2],
            });
          setState({
            col_num: [1, 2],
          });
        }
      return (
        <>
          <DragDropContext onDragEnd={onDragEnd}>
            <Rnd
              className="height-100 width-50  position-relative transform-none "
              disableDragging={true}
              enableResizing={{
                top: true,
                right: true,
                bottom: true,
                left: true,
                topRight: false,
                bottomRight: false,
                bottomLeft: false,
                topLeft: false,
              }}
              onResizeStop={(e, b, c) => {
                if (!run) {
                  if (
                    document.getElementById("image").classList.contains("hov")
                  )
                    setState({
                      width1: c.style.width,
                      height1: c.style.height,
                    });
                  else {
                    notifyMessage([
                      "bc",
                      4,
                      "Resize/Reorder in full screen only",
                    ]);
                    document.getElementById("col1").style.width =
                      _this.state.width1 + "px";
                    document.getElementById("col1").style.height =
                      _this.state.height1 + "px";
                  }
                }
              }}
              key="a0"
              id="col1"
              data-order="0"
              style={{
                display: "flex",
                flexFlow: "column",
                justifyContent: "space-between",
                textAlign: _this.state.tokens_align,
                borderBottom: !run ? "3.5px solid #7d7d7d" : "",
                borderRight: !run ? "3.5px solid #7d7d7d" : "",
                order: col1,
              }}
              size={{
                width: parseFloat(_this.state.width1) + _this.state.res_sign,
                height: parseFloat(_this.state.height1) + _this.state.res_sign,
              }}
            >
              <Hover notify={notifyMessage} setState={setState} run={run} />
              {/* <div id='col1-d'> */}
              { _this.state.logo_column == 1 && logo}
            
              <Row md="12" className="mx-0 py-1 mb-1" style={headerStyle}>
                <Head _this={_this.state} />
              </Row>
              <Droppable
                droppableId="droppable"
                className="drag_container"
                style={{ flex: 1 }}
                dropDisabled={run ? true : false}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {_this.state.list1.map((o, k) => {
                      var p = _this.state.counters.find((e) => e.id == o) || {};
                      var ok = false;
                      if (i < 12) ok = true;
                      if (_this.state.selected_counter_list_right?.length > 0) {
                        if (
                          !_this.state.selected_counter_list_right?.includes(
                            p.id
                          )
                        )
                          ok = true;
                        else ok = false;
                      }
                      if (ok && p.id != undefined) {
                        i++;
                        return (
                          <Draggable
                            key={p.id}
                            draggableId={p.id}
                            index={k}
                            isDragDisabled={run ? true : false}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                <Column
                                  _this={{ state: _this.state }}
                                  p={p}
                                  k={k}
                                />
                              </div>
                            )}
                          </Draggable>
                        );
                      }
                    })}
                  </div>
                )}
              </Droppable>
            </Rnd>

            <Rnd
              className="height-100 width-50  position-relative transform-none "
              disableDragging={true}
              enableResizing={{
                top: true,
                right: true,
                bottom: true,
                left: true,
                topRight: false,
                bottomRight: false,
                bottomLeft: false,
                topLeft: false,
              }}
              onResizeStop={(e, b, c) => {
                if (!run) {
                  if (
                    document.getElementById("image").classList.contains("hov")
                  )
                    setState({
                      width2: c.style.width,
                      height2: c.style.height,
                    });
                  else {
                    notifyMessage([
                      "bc",
                      4,
                      "Resize/Reorder in full screen only",
                    ]);
                    document.getElementById("col2").style.width =
                      _this.state.width2 + "px";
                    document.getElementById("col2").style.height =
                      _this.state.height2 + "px";
                  }
                }
              }}
              key="a1"
              id="col2"
              data-order="0"
              style={{
                display: "flex",
                flexFlow: "column",
                justifyContent: "space-between",
                textAlign: _this.state.tokens_align,
                borderBottom: !run ? "3.5px solid #7d7d7d" : "",
                borderRight: !run ? "3.5px solid #7d7d7d" : "",
                order: col2,
              }}
              size={{
                width: parseFloat(_this.state.width2) + _this.state.res_sign,
                height: parseFloat(_this.state.height2) + _this.state.res_sign,
              }}
            >
              <Hover notify={notifyMessage} setState={setState} run={run} />
              { _this.state.logo_column == 2 && logo}
             
              {/* <div id='col2-d'> */}
              <Row md="12" className="mx-0 py-1 mb-1" style={headerStyle}>
                <Head _this={_this.state} />
              </Row>
              <Droppable
                droppableId="droppable2"
                className="drag_container"
                style={{ flex: 1 }}
                dropDisabled={run ? true : false}
                // onDrop={event => drop(event)}
                // onDragOver={event => allowDrop(event)}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {_this.state.selected_counter_list_right?.length > 0
                      ? _this.state.selected_counter_list_right.map((o, k) => {
                          var p =
                            _this.state.counters.find((e) => e.id == o) || {};
                          var ok = true;
                          if (ok) {
                            j++;
                            return (
                              <Draggable
                                key={p.id}
                                draggableId={p.id}
                                index={k}
                                isDragDisabled={run ? true : false}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                  >
                                    <Column
                                      _this={{ state: _this.state }}
                                      p={p}
                                      k={k}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            );
                          }
                        })
                      : _this.state.counters.map((p, k) => {
                          var ok = false;
                          if (j > 12) ok = true;
                          if (ok) {
                            j++;
                            return (
                              <>
                                <Column
                                  _this={{ state: _this.state }}
                                  p={p}
                                  k={k}
                                />
                              </>
                            );
                          }
                        })}
                  </div>
                )}
              </Droppable>
              <Row className="mx-0" md="12" style={messageStyle}>
                <Col
                  md="12"
                  style={{ textAlign: _this.state.footer_message_align }}
                >
                  {_this.state.footer_message}
                </Col>
              </Row>
              {!!_this.state.date_display && (
                <Row className="mx-0" md="12" style={dateStyle}>
                  <Col md="12" style={{ textAlign: _this.state.date_align }}>
                    <TimeComp />
                  </Col>
                </Row>
              )}
              {/* </div> */}
            </Rnd>
          </DragDropContext>
        </>
      );
    case "double list with ad":
      var i = 0,
        j = 0;
      if (_this.state.col_num != undefined)
        if (_this.state.col_num.length != 3) {
          if (_this.state.ads != undefined)
            _this.state.ads.map((e, k) => {
              if (_this.state.adVid) {
                if (e.type == "vid") disAd.push(e);
              } else if (e.type == "img") disAd.push(e);
              if (k + 1 == _this.state.ads.length) {
                setState({ selected_ad: disAd, adVid: _this.state.adVid });
              }
            });
          if (_this.state.change)
            setState({
              width1: 33.3,
              height1: 100,
              width2: 33.3,
              height2: 100,
              width3: 33.3,
              height3: 100,
              res_sign: "%",
            });
          setState({ col_num: [1, 2, 3] });
        }
      return (
        <>
          <DragDropContext onDragEnd={onDragEnd}>
            <Rnd
              className="height-100 width-50  position-relative transform-none "
              disableDragging={true}
              enableResizing={{
                top: true,
                right: true,
                bottom: true,
                left: true,
                topRight: false,
                bottomRight: false,
                bottomLeft: false,
                topLeft: false,
              }}
              onResizeStop={(e, b, c) => {
                if (!run) {
                  if (
                    document.getElementById("image").classList.contains("hov")
                  )
                    setState({
                      width1: c.style.width,
                      height1: c.style.height,
                    });
                  else {
                    notifyMessage([
                      "bc",
                      4,
                      "Resize/Reorder in full screen only",
                    ]);
                    document.getElementById("col1").style.width =
                      _this.state.width1 + "px";
                    document.getElementById("col1").style.height =
                      _this.state.height1 + "px";
                  }
                }
              }}
              key="a0"
              id="col1"
              data-order="0"
              style={{
                display: "flex",
                flexFlow: "column",
                justifyContent: "space-between",
                textAlign: _this.state.tokens_align,
                borderBottom: !run ? "3.5px solid #7d7d7d" : "",
                borderRight: !run ? "3.5px solid #7d7d7d" : "",
                order: col1,
              }}
              size={{
                width: parseFloat(_this.state.width1) + _this.state.res_sign,
                height: parseFloat(_this.state.height1) + _this.state.res_sign,
              }}
            >
              <Hover notify={notifyMessage} setState={setState} run={run} />
              { _this.state.logo_column == 1 && logo}
             
              <Row md="12" className="mx-0 py-1 mb-1" style={headerStyle}>
                <Head _this={_this.state} />
              </Row>
              <Droppable
                droppableId="droppable"
                className="drag_container"
                style={{ flex: 1 }}
                dropDisabled={run ? true : false}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {_this.state.list1.map((o, k) => {
                      var p = _this.state.counters.find((e) => e.id == o) || {};
                      var ok = false;
                      if (i < 12) ok = true;
                      if (_this.state.selected_counter_list_right?.length > 0) {
                        if (
                          !_this.state.selected_counter_list_right?.includes(
                            p.id
                          )
                        )
                          ok = true;
                        else ok = false;
                      }
                      if (ok && p.id != undefined) {
                        i++;
                        return (
                          <Draggable
                            key={p.id}
                            draggableId={p.id}
                            index={k}
                            isDragDisabled={run ? true : false}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                <Column
                                  _this={{ state: _this.state }}
                                  p={p}
                                  k={k}
                                />
                              </div>
                            )}
                          </Draggable>
                        );
                      }
                    })}
                  </div>
                )}
              </Droppable>
            
            </Rnd>

            <Rnd
              className="height-100 width-50  position-relative transform-none "
              disableDragging={true}
              enableResizing={{
                top: true,
                right: true,
                bottom: true,
                left: true,
                topRight: false,
                bottomRight: false,
                bottomLeft: false,
                topLeft: false,
              }}
              onResizeStop={(e, b, c) => {
                if (!run) {
                  if (
                    document.getElementById("image").classList.contains("hov")
                  )
                    setState({
                      width2: c.style.width,
                      height2: c.style.height,
                    });
                  else {
                    notifyMessage([
                      "bc",
                      4,
                      "Resize/Reorder in full screen only",
                    ]);
                    document.getElementById("col2").style.width =
                      _this.state.width2 + "px";
                    document.getElementById("col2").style.height =
                      _this.state.height2 + "px";
                  }
                }
              }}
              key="a2"
              id="col2"
              data-order="0"
              style={{
                display: "flex",
                flexFlow: "column",
                justifyContent: "space-between",
                textAlign: _this.state.tokens_align,
                borderBottom: !run ? "3.5px solid #7d7d7d" : "",
                borderRight: !run ? "3.5px solid #7d7d7d" : "",
                order: col2,
              }}
              size={{
                width: parseFloat(_this.state.width2) + _this.state.res_sign,
                height: parseFloat(_this.state.height2) + _this.state.res_sign,
              }}
            >
              <Hover notify={notifyMessage} setState={setState} run={run} />
              { _this.state.logo_column == 2 && logo}
             
              {/* <div id='col2-d'> */}
              <div
                className=" position-relative"
                style={{
                  display: "flex",
                  overflow: "hidden",
                  flexFlow: "column",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <Ads
                  ads={_this.state.selected_ad}
                  dur={_this.state.imgDur}
                  sliding={_this.state.sliding}
                />
              </div>
              {/* </div> */}
            </Rnd>

            <Rnd
              className="height-100 width-50  position-relative transform-none "
              disableDragging={true}
              enableResizing={{
                top: true,
                right: true,
                bottom: true,
                left: true,
                topRight: false,
                bottomRight: false,
                bottomLeft: false,
                topLeft: false,
              }}
              onResizeStop={(e, b, c) => {
                if (!run) {
                  if (
                    document.getElementById("image").classList.contains("hov")
                  )
                    setState({
                      width3: c.style.width,
                      height3: c.style.height,
                    });
                  else {
                    notifyMessage([
                      "bc",
                      4,
                      "Resize/Reorder in full screen only",
                    ]);
                    document.getElementById("col3").style.width =
                      _this.state.width3 + "px";
                    document.getElementById("col3").style.height =
                      _this.state.height3 + "px";
                  }
                }
              }}
              key="a1"
              id="col3"
              data-order="0"
              style={{
                display: "flex",
                flexFlow: "column",
                justifyContent: "space-between",
                textAlign: _this.state.tokens_align,
                borderBottom: !run ? "3.5px solid #7d7d7d" : "",
                borderRight: !run ? "3.5px solid #7d7d7d" : "",
                order: col3,
              }}
              size={{
                width: parseFloat(_this.state.width3) + _this.state.res_sign,
                height: parseFloat(_this.state.height3) + _this.state.res_sign,
              }}
            >
              <Hover notify={notifyMessage} setState={setState} run={run} />
              { _this.state.logo_column == 3 && logo}
            
              {/* <div id='col2-d'> */}
              <Row md="12" className="mx-0 py-1 mb-1" style={headerStyle}>
                <Head _this={_this.state} />
              </Row>
              <Droppable
                droppableId="droppable2"
                className="drag_container"
                style={{ flex: 1 }}
                dropDisabled={run ? true : false}
                // onDrop={event => drop(event)}
                // onDragOver={event => allowDrop(event)}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {_this.state.selected_counter_list_right
                      ? _this.state.selected_counter_list_right.map((o, k) => {
                          var p =
                            _this.state.counters.find((e) => e.id == o) || {};
                          var ok = true;
                          //   if (_this.state.selected_counter_list_right?.length > 0) {
                          //     if (
                          //       _this.state.selected_counter_list_right?.includes(p.id)
                          //     )
                          //       ok = true
                          //   } else if (j > 12) ok = true
                          if (ok) {
                            j++;
                            return (
                              <Draggable
                                key={p.id}
                                draggableId={p.id}
                                index={k}
                                isDragDisabled={run ? true : false}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                  >
                                    <Column
                                      _this={{ state: _this.state }}
                                      p={p}
                                      k={k}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            );
                          }
                        })
                      : _this.state.counters.map((p, k) => {
                          var ok = false;
                          // if (_this.state.selected_counter_list_right?.length > 0) {
                          //   if (_this.state.selected_counter_list_right?.includes(p.id))
                          //     ok = true
                          // } else
                          if (j > 12) ok = true;
                          if (ok) {
                            j++;
                            //   if (k >= parseInt(_this.state.counters.length / 2))
                            return (
                              <>
                                <Column
                                  _this={{ state: _this.state }}
                                  p={p}
                                  k={k}
                                />
                              </>
                            );
                          }
                        })}
                  </div>
                )}
              </Droppable>
              <Row className="mx-0" md="12" style={messageStyle}>
                <Col
                  md="12"
                  style={{ textAlign: _this.state.footer_message_align }}
                >
                  {_this.state.footer_message}
                </Col>
              </Row>
              {!!_this.state.date_display&&<Row className="mx-0" md="12" style={dateStyle}>
                <Col md="12" style={{ textAlign: _this.state.date_align }}>
                  <TimeComp />
                </Col>
              </Row>}
              {/* </div> */}
            </Rnd>
          </DragDropContext>
        </>
      );
    default:
      return "Layout not selected";
  }
});
export default Layout;
