import React from "react";
import NotificationAlert from "react-notification-alert";
import Firebase from "firebase";
import PasswordHash from 'password-hash';
import LoadingOverlay from "react-loading-overlay";
// import CopyToClipboard from "react-copy-to-clipboard";
import Select from "react-select";
import Switch from "react-bootstrap-switch";
import PictureUpload from "../../../components/CustomUpload/PictureUpload.jsx";

import {
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Label,
    Button,
    Input,
    Form,
    FormGroup,
    // InputGroup,
    // InputGroupAddon,
    // InputGroupText,
} from "reactstrap";
import config from "../../../config";

// const uuidv1 = require('uuid/v1');
const { getNameList } = require('country-list');
// const publicIp = require('public-ip');

class CustomerAdd extends React.Component {
    constructor(props) {
        super(props);
        if (!Firebase.apps.length) {
            Firebase.initializeApp(config);
        }

        this.state = {
            loading: false,
            customer_id: '',
            current_time: '',

            // loading: false,
            // role: '',
            // current_time: '',

            icon_max_limit: 0,

            api_key: '',
            company_name: '',
            country: null,
            country_code: '',
            created_date: '',
            customer_category: '',
            email: '',
            image_url: '',
            name: '',
            partner_interest: false,
            phone_number: '',
            website: '',

            country_list: [
                {
                    value: "",
                    label: " Single Options",
                    isDisabled: true
                }
            ],
            category_list: [],
            customer_image: null,

            company_nameState: '',
            countryState: '',
            country_codeState: '',
            customer_categoryState: '',
            emailState: '',
            nameState: '',
            partner_interestState: '',
            phone_numberState: '',
            
            address_info: null
        };
    }

    componentDidMount() {
        var role = JSON.parse(localStorage.getItem('auth_info')).role;  
        this.setState({role:role});

        if (role === 'Site_Admin'){
            this.loadData();
        }
        this.getCurrentTime();
        // this.loadMainLocations();
    }
    loadData(){
        let _this = this;

        var array = Object.keys(getNameList());
        var list = _this.state.country_list;
        array.forEach(function (item) {
            list.push({value: item, label: item});
        });
        _this.setState({country_list: list});

        Firebase.firestore().collection('System_Config').doc('Package_Categories').get().then(function (doc) {
            // console.log(doc);
            var categories = doc.data().Customer_Category;
            var category_list = [];
            categories.forEach(function (category) {
                var category_one = {
                    value: category,
                    label: category
                };

                category_list.push(category_one);
            });

            _this.setState({category_list: category_list});
            _this.setState({loading: false});
        }).catch(function (err) {
            _this.setState({loading: false});
            _this.notifyMessage("tc", 3, "Network error!");
        });

        // ---------- Load Icon Max Size ---------- //
        Firebase.firestore().collection('System_Config').doc('Upload_Limits').get().then(function (upload_limit_info) {
            if (upload_limit_info.exists) {
                _this.setState({icon_max_limit: upload_limit_info.data().Max_Icon_size_in_MB});
            } else {
                _this.setState({loading: false});
                _this.notifyMessage("tc", 3, "Network error!");
                console.log("loadAddressInfo NetworkError5");
            }
        }).catch(function (err) {
            _this.setState({loading: false});
            _this.notifyMessage("tc", 3, "Network error!");
            console.log("loadAddressInfo NetworkError6==>", err);
        });

        // _this.loadAddressInfo();
    }
    // loadAddressInfo() {
    //     var _this = this;
    //     _this.setState({loading: true});
    //     publicIp.v4().then(ip => {
    //         console.log("your public ip address: ", ip);
    //         let url = 'https://api.ipgeolocation.io/ipgeo?apiKey=' + info.ip_api_key + '&ip='+ip+'&lang=en';
    
    //         request.get(url, function(err,res,body){
    //             if(err){
    //                 _this.setState({address_info: null});
    //                 _this.setState({loading: false});
    //                 _this.notifyMessage("tc", 3, "Network error!");
    //                 console.log("Network Error 1: ",err);
    //             }
    //             if(res.statusCode !== 200 ) {
    //                 _this.setState({address_info: null});
    //                 _this.setState({loading: false});
    //                 _this.notifyMessage("tc", 3, "Load geolocation error!");
    //             }
    
    //             let result = JSON.parse(body);
    //             _this.setState({address_info: result});
    //             let countryCodeVal = _this.getThreeDigitCountryCode(result.country_name.toLowerCase());
    //             _this.setState({country_code: countryCodeVal});
    //             // console.log("countryCodeVal",countryCodeVal);
    //             _this.setState({loading: false});
    //         });
    //     });
    // }
    getThreeDigitCountryCode(search_term) {
        // console.log("test", search_term);  
        // return "TEST";
        var _this = this;
        var countryCode = "INVALID_ERROR_0";
        var countriesArr = [];
        var resultsArr = [];
    
        Firebase.firestore()
            .collection('System_Config').doc("Country_List")
            .collection('Countries').get().then(function (querySnapshot) {
                querySnapshot.forEach(function(doc){
                    countriesArr.push({
                        "simpleCountryName": doc.data().Country_Name.toLowerCase(),
                        "threeLetterCode": doc.data().Three_Letter_Country_Code}
                    );
                });
        })
        .then( function(result) {
    
            for (let i= 0; i < countriesArr.length; i++){
                if ((countriesArr[i].simpleCountryName).includes(search_term)){
                    resultsArr.push({
                        "code": countriesArr[i].threeLetterCode
                    })
                }
            }
    
            if (resultsArr.length === 1) {
                countryCode = resultsArr[0].code;
            } else {
                countryCode = "INVALID_ERROR_1";
            }
    
            // console.log("countriesArr", countriesArr);
            // console.log("resultsArr", resultsArr);
            console.log("countryCode", countryCode);
            _this.setState({country_code: countryCode});
            return countryCode;
        })
        .catch( function(error){
            console.log("error",error);
            //this.setState({country_code: "INVALID_ERROR_2"});
            return "INVALID_ERROR_2";
        })
      }
    getCurrentTime() {
        let _this = this;
        let now = new Date();
        _this.setState({current_time: now.toLocaleString()});
        window.setTimeout(function() { _this.getCurrentTime() }, 500);
    }
    handleCustomerAdd() {
        if (this.state.company_nameState === "") {  this.setState({ company_nameState: "has-danger" })  }
        if (this.state.country_codeState === "") { this.setState({ country_codeState: "has-danger" }) }
        if (this.state.emailState === "") { this.setState({ emailState: "has-danger" }) }
        if (this.state.nameState === "") { this.setState({ nameState: "has-danger" }) }
    
        if (this.state.company_nameState === "has-success" && this.state.country_codeState === "has-success" && this.state.emailState === "has-success" && this.state.nameState === "has-success") {
            var email = this.state.email.toLowerCase();
            let ref = Firebase.firestore().collection('Web_App_Users').doc(email);
            var _this = this;
            _this.setState({loading: true});
            ref.get().then(function(doc) {
                if (doc.exists) {
                    _this.setState({loading: false});
                    _this.notifyMessage("tc", 3, "Sorry, an account for the specified email address already exists in AntQueue!");
                } else {
                    Firebase.firestore().collection('Customers').get().then(function (res) {
                        var last_id = "000000000000";
                        var next_id = "000000000001";
                        if (res.docs.length > 0) {
                            last_id = res.docs[res.docs.length - 1].id;
                            var str = (parseInt(last_id) + 1).toString();
                            var pad = "000000000000";
                            next_id = pad.substring(0, pad.length - str.length) + str;
                        }
    
                        var random_pass = _this.generatePass(8);
                        var customer_id = next_id;
                        var now = new Date();
                        var file = _this.refs.customer_image.state.file;
                        var reg_data1 = [];
                        var image_upload_ok = false;

                        if (file !== null) {

                            // --------- Check Max Icon Size Limit --------- //
                            let max_bytes = _this.state.icon_max_limit * 1024 * 1024;
                            if (file.size > max_bytes) {
                                _this.setState({loading: false});
                                _this.notifyMessage("tc", 3, "Icon file size exceeds maximum size.");
                            } else {
                                image_upload_ok = true;
                            }

                            var storageRef = Firebase.storage().ref();
                            let metadata = {
                                customMetadata: {
                                    email: _this.state.email,
                                    customer_id: customer_id
                                }
                            };
                            var image_name = "customer_image_" + customer_id; //+ this.state.email.toLowerCase();
                            var customerRef = storageRef.child(image_name);
                            customerRef.put(file, metadata).then(function (snapshot) {
                                customerRef.getDownloadURL().then(function (res) {
                                    reg_data1 = {
                                        API_Key: _this.state.api_key?_this.state.api_key:'',
                                        Company_Name: _this.state.company_name?_this.state.company_name:'',
                                        Country: _this.state.country.value?_this.state.country.value:'',
                                        Country_Code: _this.state.country_code?_this.state.country_code:'',
                                        Created_Date: now,
                                        Customer_Category: _this.state.customer_category.value?_this.state.customer_category.value:'',
                                        Email: email,
                                        Image_Url: res,
                                        Name: _this.state.name?_this.state.name:'',
                                        Partner_Interest: _this.state.partner_interest,
                                        Phone_Number: _this.state.phone_number?_this.state.phone_number:'',
                                        Web_Site: _this.state.website?_this.state.website:'',
                                        
                                    };
                                }, (error) => {
                                    console.log("getDownloadURL error==>", error);
                                    _this.setState({loading: false});
                                });
                            }).catch(function (err) {
                                _this.setState({loading: false});
                                _this.notifyMessage("tc", 3, "Network error!");
                                console.log("handleCustomerSave NetworkError4==>", err);
                            });
                        } else {

                            image_upload_ok = true;

                            reg_data1 = {
                                API_Key: _this.state.api_key?_this.state.api_key:'',
                                Company_Name: _this.state.company_name?_this.state.company_name:'',
                                Country: _this.state.country.value?_this.state.country.value:'',
                                Country_Code: _this.state.country_code?_this.state.country_code:'',
                                Created_Date: now,
                                Customer_Category: _this.state.customer_category.value?_this.state.customer_category.value:'',
                                Email: email,
                                Image_Url: "",
                                Name: _this.state.name?_this.state.name:'',
                                Partner_Interest: _this.state.partner_interest,
                                Phone_Number: _this.state.phone_number?_this.state.phone_number:'',
                                Web_Site: _this.state.website?_this.state.website:'',
                                
                            };
                        }
                        
                        if (image_upload_ok === true) {
                            Firebase.firestore().collection('Customers').doc(customer_id.toString()).set(reg_data1)
                                .then(function() {
                                    var reg_data2 = {
                                        API_Key: "",
                                        Accessible_Locations: [],
                                        Activated: true,
                                        Created_Date: now,
                                        Customer_ID: customer_id,
                                        Designation: "",
                                        Image_Url: "",
                                        Last_Activity_Date: "",
                                        Last_Updated_Date: now,
                                        Last_Updated_User_ID: "",
                                        Name: "",
                                        Notification: true, //false,
                                        OTP_Code: "",
                                        OTP_Enabled: false,
                                        Password: PasswordHash.generate(random_pass),
                                        Role: "System_Admin",
                                        Google_Token: "",
                                        Facebook_Token: "",
                                        token: "",
                                        What_Sup: "",
                                        Viber: "",
                                        Telegram: "",
                                        Line: "",
                                        
                                    };
                                    Firebase.firestore().collection('Web_App_Users').doc(email).set(reg_data2)
                                        .then(function() {
                                            /////// Register success code ////////
                                            // _this.notifyMessage("tc", 2, "Register success!");
                                            var message_text =
                                                "Dear Valued Customer,<br /><br>" +
                                                "Your account has been successfully registered with <b>Ant Queue</b> web app.<br /><br />" +
                                                "Your login details to <a href='https://app.antqueue.com/' target='_blank' style='color:#FF5733;'><b>app.antqueue.com</b></a> are as follows:<br /><br />" +
                                                "Username: " + email.toLowerCase() + "<br/>" +
                                                "Password: " + random_pass + "<br /><br />" +
                                                "Best Regards,<br/>" +
                                                "<b>Ant Queue Team</b><br/>";
                                            
                                            Firebase.functions().httpsCallable('sendMail')({email: email, subject: 'Welcome to Antqueue Web App', text: message_text}).then(function(result) {
                                                console.log("result", result, "random_pass", random_pass);
                                                Firebase.functions().httpsCallable('createNewUser')({email: email, password: random_pass}).then((result) => {
                                                    console.log('new user created result ===> ', result);
                                                    var message_text1 = "Customer has successfully registered to AntQueue Web App. Login details have been sent via email.";
                                                    // _this.SuccessAlert(message_text1);
                                                    _this.notifyMessage("tc", 2, message_text1);
                                                    _this.setState({loading: false});
                                                    window.setTimeout(function() { _this.props.history.push("/customers") }, 4000);
                                                }, (err) => console.log("new user created error ==> ", err));
                                            });
                                    
                                        }).catch(function(error) {
                                            _this.setState({loading: false});
                                            _this.notifyMessage("tc", 3, "Register error!");
                                            console.log("Registration failed: ",error);
                                        });
                                }).catch(function(error) {
                                    _this.setState({loading: false});
                                    _this.notifyMessage("tc", 3, "Register Failed!");
                                    console.log("Registration failed: ",error);
                                });
                        }
                    }).catch(function (err) {
                        _this.setState({loading: false});
                        _this.notifyMessage("tc", 3, "Network error!");
                        console.log("Network Error 2: ",err);
                    });
                }
            }).catch(function(error) {
                _this.setState({loading: false});
                _this.notifyMessage("tc", 3, "Network error!");
                console.log("Network Error 3: ",error);
            });
        }
    }
    handleChange = (event, stateName, type, stateNameEqualTo) => {
        switch(type) {
            case "length":
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + "State"]: "has-success" });
                } else {
                    this.setState({ [stateName + "State"]: "has-danger" });
                }

                this.setState({ [stateName]: event.target.value });
                break;
            case 'pin':
                if (this.verifyLength(event.target.value, stateNameEqualTo) && this.verifyNumber(event.target.value)) {
                    this.setState({ [stateName + "State"]: "has-success" });
                } else {
                    this.setState({ [stateName + "State"]: "has-danger" });
                }

                if (this.verifyNumber(event.target.value) || event.target.value.length===0) {
                    this.setState({[stateName]: event.target.value});
                }

                break;
            case "api_key":
                if (this.verifyLength(event.target.value, 1)) {
                    this.setState({ [stateName + "State"]: "has-success" });
                    const {name, value} = event.target;
                    this.setState({[name]: value});
                } else {
                    this.setState({ [stateName + "State"]: "has-danger" });
                }
                break;
            case "email":
                if (this.verifyEmail(event.target.value)) {
                    this.setState({ [stateName + "State"]: "has-success" });
                    const {name, value} = event.target;
                    this.setState({[name]: value});
                } else {
                    this.setState({ [stateName + "State"]: "has-danger" });
                }
                break;
            case "company_name":
                if (this.verifyLength(event.target.value, 1)) {
                    this.setState({ [stateName + "State"]: "has-success" });
                    const {name, value} = event.target;
                    this.setState({[name]: value});
                } else {
                    this.setState({ [stateName + "State"]: "has-danger" });
                }
                break;
            case "name":
                if (this.verifyLength(event.target.value, 1)) {
                    this.setState({ [stateName + "State"]: "has-success" });
                    const {name, value} = event.target;
                    this.setState({[name]: value});
                } else {
                    this.setState({ [stateName + "State"]: "has-danger" });
                }
                break;
            case "country_name":
                if (this.verifyLength(event.target.value, 1)) {
                    this.setState({ [stateName + "State"]: "has-success" });
                    const {name, value} = event.target;
                    this.setState({[name]: value});
                } else {
                    this.setState({ [stateName + "State"]: "has-danger" });
                }
                break;
            case "country_code":
                if (this.verifyLength(event.target.value, 1)) {
                    this.setState({ [stateName + "State"]: "has-success" });
                    const {name, value} = event.target;
                    this.setState({[name]: value});
                } else {
                    this.setState({ [stateName + "State"]: "has-danger" });
                }
                break;
            case "phone_number":
                if (this.verifyLength(event.target.value, 1) && this.validE164(event.target.value)) {
                    this.setState({ [stateName + "State"]: "has-success" });
                    const {name, value} = event.target;
                    this.setState({[name]: value});
                } else {
                    this.setState({ [stateName + "State"]: "has-danger" });
                }
                break;
            case "website":
                if (this.verifyLength(event.target.value, 1)) {
                    this.setState({ [stateName + "State"]: "has-success" });
                    const {name, value} = event.target;
                    this.setState({[name]: value});
                } else {
                    this.setState({ [stateName + "State"]: "has-danger" });
                }
                break;
            default:
                break;
        }
    };
    changeCountry(e){
        let _this = this;
        _this.setState({ country: e });
        let selectedCountry = e.value.toLowerCase();
        console.log("selectedCountry", selectedCountry);
        _this.handleChange(e, "country", "country")

        // this.loadAddressInfo();
        if (selectedCountry !== undefined && selectedCountry !== null)
        {
            let countryCodeVal = _this.getThreeDigitCountryCode(selectedCountry);
            _this.setState({country_code: countryCodeVal});
            console.log("countryCodeVal", countryCodeVal);
        }
        _this.setState({loading: false});
    }
    notifyMessage = (place, color, text) => {
        var type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }

        var options = {};
        options = {
            place: place,
            message: (
                <div className="text-md-center">
                    <div>
                        <b>{text}</b>
                    </div>
                </div>
            ),
            type: type,
            icon: "now-ui-icons ui-1_bell-53",
            autoDismiss: 3
        };
        if (options !== null) { this.notificationAlert.notificationAlert(options) }
    };
    verifyEmail = value => {
        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailRex.test(value)) {
            return true;
        }
        return false;
      };
    /// Validate E164 format
    // validE164(num) { /* return /^\+?[1-9]\d{1,14}$/.test(num) */ return /^\+(?:[0-9] ?){6,14}[0-9]$/.test(num); }
    validE164 = value => {
        var phoneRex = /^\+(?:[0-9] ?){6,14}[0-9]$/; // /^\+?[1-9]\d{1,14}$/
        if (phoneRex.test(value)) { return true; }
        return false;
    }
    verifyLength = (value, length) => {
        return value.length >= length;
    };
    verifyNumber = value => {
        var numberRex = new RegExp("^[0-9]+$");
        if (numberRex.test(value)) {
            return true;
        }
        return false;
    };
    generatePass(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789,./<>?:{}[]-=_+)(*&^%$#@!~`';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
      }
    render() {
        var ok=false
        if(this.state.role === "Site_Admin" || this.state.role === "Support_Admin")
            ok=true
        let {
            company_nameState,
            // countryState,
            country_codeState,
            // customer_categoryState,
            emailState,
            nameState,
            // partner_interestState,
            // phone_numberState,
        } = this.state;
        return (
            <>
                <LoadingOverlay
                    active={this.state.loading}
                    spinner
                    text='Loading'
                    className='content'
                >
                    <NotificationAlert ref={(ref) => this.notificationAlert = ref} />
                    <Row>
                        <Col md="12">
                            {ok?<Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Customer Add</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Col className="ml-auto mr-auto" xl="8" lg="10" md="12">
                                        <Row>
                                            <Col md="3">
                                                <Button
                                                    color="success"
                                                    onClick={e => this.handleCustomerAdd()}
                                                    block
                                                >
                                                    Add
                                                </Button>
                                            </Col>
                                            <Col md="3">
                                                <Button
                                                    color="youtube"
                                                    onClick={e => this.props.history.push("/customers")}
                                                    block
                                                >
                                                    Close
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row className="top-margin-10">
                                        </Row>
                                        <Row>
                                            <Col xl="8" lg="12" md="12">
                                                <Form className="form-horizontal">
                                                    <Row>
                                                        <Label md="4">API Key</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Input
                                                                    name="api_key"
                                                                    placeholder="API Key"
                                                                    type="text"
                                                                    onChange={e => this.handleChange(e, "api_key", "api_key")}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Company Name</Label>
                                                        <Col md="8">
                                                            <FormGroup className={`has-label ${company_nameState}`}>
                                                                <Input
                                                                    name="company_name"
                                                                    placeholder="Company Name"
                                                                    type="text"
                                                                    onChange={e =>
                                                                        this.handleChange(e, "company_name", "company_name")
                                                                    }
                                                                />
                                                                {this.state.company_nameState === "has-danger" ? (
                                                                    <label className="error">This field is required.</label>
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Image</Label>
                                                        <Col md="8">
                                                            <PictureUpload ref="customer_image" />
                                                        </Col>
                                                    </Row>
                                                    <Row className="top-margin-10"/>
                                                    <Row>
                                                        <Label md="4">Country</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Select
                                                                    className="react-select info select-location"
                                                                    classNamePrefix="react-select"
                                                                    placeholder="Select Country"
                                                                    name="selectCountry"
                                                                    value={this.state.country}
                                                                    onChange={e => 
                                                                        this.changeCountry(e)
                                                                    } /* e.target.value */
                                                                    options={this.state.country_list}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Country Code (3 Letter abbreviation)</Label>
                                                        <Col md="8">
                                                            <FormGroup className={`has-label ${country_codeState}`}>
                                                                <Input
                                                                    placeholder="Country Code"
                                                                    name="country_code"
                                                                    type="text"
                                                                    value={this.state.country_code}
                                                                    onChange={e =>
                                                                        this.handleChange(e, "country_code", "country_code")
                                                                    }
                                                                />
                                                                {this.state.country_codeState === "has-danger" ? (
                                                                    <label className="error">This field is required.</label>
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Customer Category</Label>
                                                        <Col md="8">
                                                            <FormGroup /*className={`has-label ${countryState}`}*/>
                                                                <Select
                                                                    className="react-select info select-location"
                                                                    classNamePrefix="react-select"
                                                                    placeholder="Select Customer Category"
                                                                    name="selectCustomerCategory"
                                                                    value={this.state.customer_category}
                                                                    onChange={e => this.setState({ customer_category: e })}
                                                                    options={this.state.category_list}
                                                                />
                                                                {/* {this.state.customer_categoryState === "has-danger" ? (
                                                                        <label className="error">This field is required.</label>
                                                                    ) : null} */}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Customer Email</Label>
                                                        <Col md="8">
                                                            <FormGroup className={`has-label ${emailState}`}>
                                                                <Input
                                                                    placeholder="Customer Email"
                                                                    name="email"
                                                                    type="text"
                                                                    onChange={e =>
                                                                        this.handleChange(e, "email", "email")
                                                                    }
                                                                />
                                                                {this.state.emailState === "has-danger" ? (
                                                                    <label className="error">This field is required.</label>
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Customer Name</Label>
                                                        <Col md="8">
                                                            <FormGroup className={`has-label ${nameState}`}>
                                                                <Input
                                                                    name="name"
                                                                    placeholder="Customer Name"
                                                                    type="text"
                                                                    onChange={e =>
                                                                        this.handleChange(e, "name", "name")
                                                                    }
                                                                />
                                                                {this.state.nameState === "has-danger" ? (
                                                                    <label className="error">This field is required.</label>
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Phone Number</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Input
                                                                    name="phone_number"
                                                                    placeholder="Phone Number"
                                                                    type="text"
                                                                    onChange={e => this.handleChange(e, "phone_number", "phone_number")}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Website</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Input
                                                                    name="website"
                                                                    placeholder="Website"
                                                                    type="text"
                                                                    onChange={e => this.handleChange(e, "website", "website")}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Partner Interest</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Row className="top-margin-7"/>
                                                                <Switch
                                                                    offColor="success"
                                                                    offText={<i className="nc-icon nc-simple-remove" />}
                                                                    onColor="success"
                                                                    onText={<i className="nc-icon nc-check-2" />}
                                                                    defaultValue={this.state.partner_interest}
                                                                    value={this.state.partner_interest}
                                                                    onChange={e => {this.setState({partner_interest: e.state.value})}}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </Col>
                                        </Row>
                                    </Col>
                                </CardBody>
                            </Card>:null}
                        </Col>
                    </Row>
                </LoadingOverlay>
            </>
        );
    }
}

export default CustomerAdd;
