import React from "react";
import LoadingOverlay from "react-loading-overlay";
import Select from "react-select";
import Switch from "react-bootstrap-switch";
import Firebase from "firebase";
import NotificationAlert from "react-notification-alert";
import CreatableSelect from 'react-select/creatable';
import config from "../../../config";
import IconUpload from "../../../components/CustomUpload/IconUpload";
// import { SingleDatePicker } from 'react-dates';
import { DateInput } from '@progress/kendo-react-dateinputs';

import {
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Collapse,
    Row,
    Button,
    Form,
    FormGroup,
    Label,
    Input
} from "reactstrap";

import publicIp from "public-ip";
import geoTz from "geo-tz";
import moment from "moment-timezone";
import { d } from '@progress/kendo-date-math';

class BillingManualPaymentInvoiceEdit extends React.Component {
    constructor(props) {
        super(props);
        if (!Firebase.apps.length) {
            Firebase.initializeApp(config);
        }

        this.state = {
            loading: false,
            package_id: '',
            customer_id: '',
            invoice_id: '',
            package_enable_mobile: true,
            payment_source: { value: 'Manual_Payment', label: 'Manual_Payment' },
            payment_source_options_list: '',
            payment_source_disable_selection: true,
            pdf_attachment: '',
            invoice_attachment_max_size_in_mb: 1,
            invoice_amount_due: 0,
            invoice_amount_paid: 0,
            invoice_amount_remaining: 0,
            invalid_amount_due: false,
            invalid_amount_paid: false,
            invalid_amount_remaining: false,

            invoice_created_dateState: '',
            invoice_due_dateState: '',
            invoice_amount_dueState: '',
            invoice_statusState: '',

            // billing_emailState: '',
            // billing_emailStateMessage: 'This field is required.',
            // billing_nameState: '',
            // billing_addressState: '',
            // billing_email: '',
            // billing_name: '',
            // billing_address: '',
            // billing_email_list: null,
        };
    }

    componentDidMount() {
        let _this=this;
        let id = _this.props.match.params.id;
        _this.auth_info = JSON.parse(localStorage.getItem('auth_info'));
        var role = _this.auth_info.role;

        // this.initializeFirebase();
        if (id !== "" && id !== null && id !== undefined) {
            _this.setState({loading: true, invoice_id: id, role:role});
            _this.loadPaymentSources();
            _this.loadSubLocationData(id);
            Firebase.firestore().collection('Sub_Locations').get().then(function (sub_location_info) {
                sub_location_info.docs.forEach(result => {
                    if (typeof result.data().Package_ID === 'undefined'){
                        console.log("componentDidMount>>>1:", result.id, result.data().Name);
                    } else {
                        console.log("componentDidMount>>>2:", result.id, result.data().Name);
                    }
                })
            });
        }

        // /// temp PopulateSublocationData ///
        // Firebase.firestore().collection('Sub_Locations').get().then(function (sub_location_info) {
        //     sub_location_info.docs.forEach(result => {
        //         if (
        //             //(result.id === 'cd7dc390-1eb4-11eb-8ca8-2b1f6e15175e') &&
        //             (typeof result.data().Subscription_Details !== 'undefined') && 
        //             (typeof result.data().Subscription_Details.Created_Date !== 'undefined')
        //             ) {
        //             console.log("[result.id /Payment_Source === 'undefined']", result.id);
        //             Firebase.firestore().collection('Sub_Locations').doc(result.id).set({
        //                 "Subscription_Details": {
        //                   "Payment_Source": "Online_Payment"
        //                 }
        //               },{merge:true})
        //         } 
        //         else if ((typeof result.data().Subscription_Details === 'undefined')) {
        //             console.log("[result.id /Payment_Source !== 'undefined']", result.id);
        //             Firebase.firestore().collection('Sub_Locations').doc(result.id).set({
        //                 "Subscription_Details": {
        //                   "Payment_Source": "Manual_Payment"
        //                 }
        //               },{merge:true})
        //         }
        //     })

        // }).then(() => {
        //     _this.setState({loading: false});
        // });
    }

    loadPaymentSources = () => {
        let _this=this
        var role = this.auth_info.role;
        Firebase.firestore().collection('System_Config').doc('Payment_Sources').get().then(function (doc) {
            var payment_source_options = doc.data().Payment_Source_Options;
            var payment_source_options_list = [];
            payment_source_options.forEach(function (payment) {
                var sources = { value: payment, label: payment };
                payment_source_options_list.push(sources);
            });
            _this.setState({payment_source_options_list: payment_source_options_list});
            console.log('payment_source_options_list', payment_source_options_list);
            if (role==="Site_Admin") {
                _this.setState({payment_source_disable_selection: false});
            }
        }).catch(function (err) {
            _this.setState({loading: false});
            _this.notifyMessage("tc", 3, "Network error!");
            console.log('loadPaymentSources error', err);
        });
    }

    collapsesToggle = collapse => {
        let openedCollapses = this.state.openedCollapses;
        if (openedCollapses.includes(collapse)) {
            this.setState({
                openedCollapses: openedCollapses.filter(item => item !== collapse)
            });
        } else {
            openedCollapses.push(collapse);
            this.setState({
                openedCollapses: openedCollapses
            });
        }
    };

    // saveStripeCustomer = async ({selected, email, name, address, antqueue_customer_id}) => {
    //     const { id, billing_email, billing_name, billing_address } = selected || {};
    //     const dbRef = Firebase.firestore().collection('Stripe_Customers');
    //     if (selected) {
    //         if (billing_name !== name || billing_address !== address) {
    //             //need to update
    //             dbRef.doc(id).set({
    //                 Stripe_Billing_User_Name: name,
    //                 Stripe_Billing_User_Address: address,
    //             });
    //         }
    //         return id;
    //     } else {
    //         // check this email is already used in other antqueue customer
    //         const stripe_customer_fb = await dbRef.where('Stripe_Billing_User_Email', '==', email).get();
    //         if (stripe_customer_fb.exists) {
    //             return null;
    //         }
    //         // create new stripe_customer
    //         const docRef = dbRef.add({
    //             Stripe_Billing_User_Email: email,
    //             Stripe_Billing_User_Name: name,
    //             Stripe_Billing_User_Address: address,
    //             Antqueue_Customer_ID: antqueue_customer_id
    //         });
    //         return docRef.id;
    //     }
    // }
    async handleSave2() {
        const {
            defaultBillingEmail,
            billing_name,
            billing_address,
        } = this.state;
        const { customer_id } = this.auth_info;
        console.log("email:", defaultBillingEmail.email, "name:", billing_name, "address:", billing_address, "antqueue_customer_id:", customer_id );
    }

    async handleSave() {
        const { nameState, billing_emailState, billing_nameState, billing_addressState } = this.state;
        if (nameState === "") {
            this.setState({ nameState: "has-danger" });
        }
        if (billing_emailState === "") {
            this.setState({ billing_emailState: "has-danger" });
        }
        if (billing_nameState === "") {
            this.setState({ billing_nameState: "has-danger" });
        }
        if (billing_addressState === "") {
            this.setState({ billing_addressState: "has-danger" });
        }

        if (nameState === "has-success" && billing_emailState === "has-success"  && billing_nameState === "has-success" && billing_addressState === "has-success") {
            let _this = this;
            // _this.setState({loading: true});

            const { customer_id } = this.auth_info;
            const { defaultBillingEmail, billing_name, billing_address, payment_source } = this.state; 
            var stripe_customer_id = null;
            console.log("handleSave>>>1 defaultBillingEmail", defaultBillingEmail, "billing_name",billing_name, "billing_address",billing_address, "payment_source",payment_source);
            
            if (payment_source.value === "Online_Payment") {
                console.log("handleSave>>>2 Online_Payment path");
                console.log("defaultBillingEmail.email", defaultBillingEmail.email, "billing_name", billing_name, "billing_address", billing_address, "customer_id", customer_id);
                try {                
                    const response = await Firebase.functions().httpsCallable('stripe_saveUpdateStripeCustomerInfo')({ 
                        email: defaultBillingEmail.email, //billing_email,
                        name: billing_name,
                        address: billing_address,
                        antqueue_customer_id: customer_id
                    });
                    const res = response.data;
                    if (res.status === 'error') {
                        const err = res.error;
                        _this.setState({loading: false});
                        _this.notifyMessage("tc", 3, err.message);
                        console.log("handleSave NetworkError0==>", err);
                        return;
                    }
                    
                    stripe_customer_id = res.data.stripe_customer_doc_id;
                    console.log("handleSave>>>3 stripe_customer_id", stripe_customer_id);
                } catch(err) {
                    _this.setState({loading: false});
                    _this.notifyMessage("tc", 3, "Network error!");
                    console.log("handleSave NetworkError0==>", err);
                }

            } else if (payment_source.value === "Manual_Payment") {
                console.log("handleSave>>>4 Manual_Payment path");

            } else {
                console.log("handleSave>>>5 Other path");
            }

            var now = new Date();
            let file = this.refs.icon.state.file;
            console.log("file", file)
            if (file !== null) {
                // --------- Check Max Icon Size Limit --------- //
                let max_bytes = _this.state.icon_max_limit * 1024 * 1024;
                if (file.size > max_bytes) {
                    _this.setState({loading: false});
                    _this.notifyMessage("tc", 3, "Icon file size exceeds maximum size of " + _this.state.icon_max_limit +  " MB. Please change icon!");
                } else {
                    Firebase.firestore().collection('Sub_Locations').doc(_this.state.sub_location_id).get().then(res => {
                        
                        let metadata = {
                            customMetadata: {
                                customer_id: customer_id,
                                main_location_id: res.data().Main_Location_ID,
                                sub_location_id: this.state.sub_location_id,
                            }
                        }
                        console.log(metadata);
                    var storageRef = Firebase.storage().ref();
                    var image_name = "sub_location_icon_" + this.state.sub_location_id;
                    var subLocationRef = storageRef.child(image_name);
                        subLocationRef.put(file, metadata).then(function (snapshot) {
                        subLocationRef.getDownloadURL().then(function (res) {
                            let update_sub_location_data = {
                                Address: _this.state.address,
                                Icon: res,
                                Name: _this.state.name,
                                Remarks: _this.state.remarks,
                                Show_On_Mobile_App: _this.state.enable_mobile,
                                Status: _this.state.active,
                                Allow_Only_One_Token_Per_Person_Per_Sublocation: _this.state.allow_only_one_token_per_person_per_sublocation,
                                TimeZone: _this.state.time_zone.value,
                                Geolocation: _this.state.position,
                                Updated_Date: now,                                
                                Billing_Profile_Id: _this.state.billing_profile_id, //stripe_customer_id,
                            };
                            Firebase.firestore().collection('Sub_Locations').doc(_this.state.sub_location_id).update(update_sub_location_data)
                                .then(function() {
                                    let batch = Firebase.firestore().batch();
                                    Firebase.firestore().collection('Services').where('Customer_ID', '==', customer_id).where('Sub_Location_ID', '==',_this.state.sub_location_id).get().then(query=> {
                                        query.docs.forEach(t =>{
                                            let updateData = { TimeZone: _this.state.time_zone.value };
                                            let serv = Firebase.firestore().collection('Services').doc(t.id);
                                            batch.update(serv, updateData);
                                        })
                                        batch.commit().then(() => {
                                            console.log('Updated all Services documents under the Sublocation with the same Timezone.');
                                            _this.setState({loading: false});
                                            _this.notifyMessage("tc", 2, "Invoice saved successfully.");
                                            window.setTimeout(function() { _this.props.history.push("/locations") }, 2000); })
                                    }).catch(function (error) {
                                        _this.setState({loading: false});
                                        _this.notifyMessage("tc", 3, "Network error!");
                                        console.log("handleSave NetworkError9==>", error);
                                    });
                                }).catch(function (error) {
                                _this.setState({loading: false});
                                _this.notifyMessage("tc", 3, "Network error!");
                                console.log("handleSave NetworkError8==>", error);
                            });
                        }).catch(function (err) {
                            _this.setState({loading: false});
                            _this.notifyMessage("tc", 3, "Network error!");
                            console.log("handleSave NetworkError10==>", err);
                        });
                    }).catch(function (err) {
                        _this.setState({loading: false});
                        _this.notifyMessage("tc", 3, "Network error!");
                        console.log("handleSave NetworkError11==>", err);
                    });
                    }, (err) => {
                        console.log("Sub_Locations load error==> ", err);
                        _this.setState({ loading: false });
                    })
                }
            } else 
            if (file === null) {
                let update_sub_location_data = {
                    Address: _this.state.address,
                    Name: _this.state.name,
                    Remarks: _this.state.remarks,
                    Show_On_Mobile_App: _this.state.enable_mobile,
                    Status: _this.state.active,
                    Allow_Only_One_Token_Per_Person_Per_Sublocation: _this.state.allow_only_one_token_per_person_per_sublocation,
                    TimeZone: _this.state.time_zone.value,
                    Geolocation: _this.state.position,
                    Updated_Date: now,
                    Billing_Profile_Id: _this.state.billing_profile_id, //stripe_customer_id,
                };
                console.log("handleSave>>>6 id:", _this.state.sub_location_id, "update:", update_sub_location_data);

                Firebase.firestore().collection('Sub_Locations').doc(_this.state.sub_location_id).update(update_sub_location_data)
                    .then(function() {
                        let batch = Firebase.firestore().batch();
                        Firebase.firestore().collection('Services').where('Customer_ID', '==', customer_id).where('Sub_Location_ID', '==',_this.state.sub_location_id).get().then(query=> {
                            query.docs.forEach(t =>{
                                let updateData = { TimeZone: _this.state.time_zone.value };
                                let serv = Firebase.firestore().collection('Services').doc(t.id);
                                batch.update(serv, updateData);
                            })
                            batch.commit().then(() => {
                                console.log('Updated all Services documents under the Sublocation with the same Timezone.');
                                _this.setState({loading: false});
                                _this.notifyMessage("tc", 2, "Invoice saved successfully.");
                                window.setTimeout(function() { _this.props.history.push("/locations") }, 2000); })
                        }).catch(function (error) {
                            _this.setState({loading: false});
                            _this.notifyMessage("tc", 3, "Network error!");
                            console.log("handleSave NetworkError13==>", error);
                        });
                    }).catch(function (error) {
                    _this.setState({loading: false});
                    _this.notifyMessage("tc", 3, "Network error!");
                    console.log("handleSave NetworkError12==>", error);
                });
            }
        }
    }
    notifyMessage = (place, color, text) => {
        var type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }

        var options = {};
        options = {
            place: place,
            message: (
                <div className="text-md-center">
                    <div>
                        <b>{text}</b>
                    </div>
                </div>
            ),
            type: type,
            icon: "now-ui-icons ui-1_bell-53",
            autoDismiss: 3
        };
        if (options !== null) { this.notificationAlert.notificationAlert(options) }
    };
    verifyLength = (value, length) => {
        return value.length >= length;
    };
    change = (event, stateName, type, stateNameEqualTo) => {
        switch (type) {
            case "length":
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + "State"]: "has-success" });
                } else {
                    this.setState({ [stateName + "State"]: "has-danger" });
                }
                break;
            default:
                break;
        }
        this.setState({ [stateName]: event.target.value });
    };
    static getTimeString(time) {
        if (time === null || time === undefined)
            return "";

        if (time.seconds === null || time.seconds === undefined)
            return "";

        let date = new Date(time.seconds*1000);
        return date.toLocaleString();
    };

    isValidBillingEmail = (inputValue, selectValue, selectOptions) => {
        console.log({method: 'isValidBillingEmail', inputValue, selectValue, selectOptions});
    };

    verifyEmail = value => {
        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return emailRex.test(value);
    }

    handleBillingEmailChange = (newValue, actionMeta) => {
        let _this = this;
        console.group('Value Changed');
        console.log(newValue);
        console.log(`action: ${actionMeta.action}`);
        console.groupEnd();

        if (_this.verifyEmail(newValue.value) === false && actionMeta.action == 'create-option') {
            console.log(' validEmail = false');
            _this.setState({ billing_emailState: "has-danger" });
            _this.setState({
                billing_emailStateMessage: "Please provide a valid email address!",
            });
        } else {
            if (newValue) {
                const { __isNew__, billing_name, billing_address, email, value } = newValue;
    
                const _email_ = __isNew__ ? value : email;
                const _name_ = __isNew__ ? '' : billing_name;
                const _address_ = __isNew__ ? '' : billing_address;
    
                let customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;
                Firebase.firestore().collection('Stripe_Customers').where('Stripe_Billing_User_Email', '==', _email_).get().then(function (response) {
                    if (response.docs.length >= 1 && response.docs[0].data().Antqueue_Customer_ID !== customer_id) {
                       console.log(' validEmail = false');
                       _this.setState({ billing_emailState: "has-danger" });
                       _this.setState({
                           billing_emailStateMessage: "This email address is already being used by another Customer. Please provide a different email address!",
                        });
                    }
                    else {
                        console.log(' validEmail = true');
                        _this.setState({
                            billing_emailStateMessage: "This field is required.",
                            billing_emailState: "has-success",
                            selected_stripe_customer: newValue,
                            billing_email: _email_,
                            billing_name: _name_,
                            billing_address: _address_,
                        });
                        console.log({
                            selected_stripe_customer: newValue,
                            billing_email: email,
                            billing_name: _name_,
                            billing_address: _address_,
                        });
                        _this.change({target: {value:_name_ || ''}}, "billing_name", "length", 1);
                        _this.change({target: {value:_address_ || ''}}, "billing_address", "length", 1);
                    }
                })
                .catch(function (error) {
                    _this.setState({loading: false});
                    _this.notifyMessage("tc", 3, "Network error!");
                    console.log("isValidBillingEmail NetworkError==>", error);
                });
    
               // if (!_this.isValidBillingEmail(_email_)) {
                //     _this.setState({ billing_emailState: "has-danger" });
                // }
                // else {
                //     _this.setState({
                //         billing_emailState: "has-success",
                //         selected_stripe_customer: newValue,
                //         billing_email: _email_,
                //         billing_name: _name_,
                //         billing_address: _address_,
                //     });
                //     console.log({
                //         selected_stripe_customer: newValue,
                //         billing_email: email,
                //         billing_name: _name_,
                //         billing_address: _address_,
                //     });
                //     _this.change({target: {value:_name_ || ''}}, "billing_name", "length", 1);
                //     _this.change({target: {value:_address_ || ''}}, "billing_address", "length", 1);
                // }
    
            } else {
                _this.setState({
                    selected_stripe_customer: null,
                    billing_email: '',
                    billing_name: '',
                    billing_address: '',
                    billing_nameState: '',
                    billing_addressState: '',
                });
            }
        }
    };

    render() {
        var ok = false
        if(this.state.role == "Site_Admin" || this.state.role == "System_Admin"  || this.state.role == "Location_Admin" || this.state.role == "Billing_Admin")
            ok=true
        let {
            nameState,
            billing_emailState,
            billing_emailStateMessage,
            billing_nameState,
            billing_addressState
        } = this.state;
        const { billing_email_list, billing_name, billing_address, billing_profile_id, defaultBillingEmail } = this.state;
        
        return (
            <>
                <LoadingOverlay
                    active={this.state.loading}
                    spinner
                    text='Loading'
                    className='content'
                >
                    <NotificationAlert ref={(ref) => this.notificationAlert = ref} />
                    <Row>
                        <Col md="12">
                            {ok?<Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Invoice Edit</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Col className="ml-auto mr-auto" lg="8">
                                        <Row>
                                            <Col md="3">
                                                <Button
                                                    color="success"
                                                    onClick={e => this.handleSave()}
                                                    block
                                                >
                                                    Save
                                                </Button>
                                            </Col>
                                            <Col md="3">
                                                <Button
                                                    color="youtube"
                                                    onClick={e => this.props.history.push("/locations")}
                                                    block
                                                >
                                                    Close
                                                </Button>
                                            </Col>
                                            <Col md="3">
                                                <Button
                                                    color="primary"
                                                    onClick={e => this.props.history.push("/sub_location/manage_package/" + this.state.sub_location_id)}
                                                    block
                                                >
                                                    Upgrade
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row className="top-margin-10">
                                        </Row>
                                        <Row>
                                            <Col md="8">
                                                <Form className="form-horizontal">
                                                    <Row>
                                                        <Label md="4">Invoice Key</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Input
                                                                    type="text"
                                                                    defaultValue={this.state.sub_location_id}
                                                                    disabled
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Invoice Name</Label>
                                                        <Col md="8">
                                                            <FormGroup className={`has-label ${nameState}`}>
                                                                <Input
                                                                    placeholder="Enter Invoice Name"
                                                                    type="text"
                                                                    defaultValue={this.state.name}
                                                                    onChange={e =>
                                                                        this.change(e, "name", "length", 1)
                                                                    }
                                                                />
                                                                {this.state.nameState === "has-danger" ? (
                                                                    <label className="error">This field is required.</label>
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Icon</Label>
                                                        <Col md="8">
                                                            <IconUpload ref="icon" />
                                                        </Col>
                                                    </Row>
                                                    <Row className="top-margin-10"/>
                                                    <Row>
                                                        <Label md="4">Address</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Input
                                                                    type="text"
                                                                    defaultValue={this.state.address}
                                                                    onChange={e => {this.setState({address: e.target.value})}}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Enable on mobile app</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Row className="top-margin-7"/>
                                                                <Switch
                                                                    offColor="success"
                                                                    offText={<i className="nc-icon nc-simple-remove" />}
                                                                    onColor="success"
                                                                    onText={<i className="nc-icon nc-check-2" />}
                                                                    disabled={!this.state.package_enable_mobile}
                                                                    defaultValue={this.state.enable_mobile}
                                                                    value={this.state.enable_mobile}
                                                                    onChange={e => {this.setState({enable_mobile: e.state.value})}}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Inactive/Active</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Row className="top-margin-7"/>
                                                                <Switch
                                                                    offColor="success"
                                                                    offText={<i className="nc-icon nc-simple-remove" />}
                                                                    onColor="success"
                                                                    onText={<i className="nc-icon nc-check-2" />}
                                                                    defaultValue={this.state.active}
                                                                    value={this.state.active}
                                                                    onChange={e => {this.setState({active: e.state.value})}}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Allow only One Token per Person per Sublocation</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Row className="top-margin-7"/>
                                                                <Switch
                                                                    offColor="success"
                                                                    offText={<i className="nc-icon nc-simple-remove" />}
                                                                    onColor="success"
                                                                    onText={<i className="nc-icon nc-check-2" />}
                                                                    defaultValue={this.state.allow_only_one_token_per_person_per_sublocation}
                                                                    value={this.state.allow_only_one_token_per_person_per_sublocation}
                                                                    onChange={e => {this.setState({allow_only_one_token_per_person_per_sublocation: e.state.value})}}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>  
                                                    {/* <Row>
                                                        <Label md="4">Package</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Input
                                                                    defaultValue={this.state.package_name}
                                                                    type="text"
                                                                    disabled
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row> */}
                                                    <Row>
                                                        <Label md="4">Time Zone</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Select
                                                                    className="react-select primary"
                                                                    classNamePrefix="react-select"
                                                                    name="timezoneSelect"
                                                                    value={this.state.time_zone}
                                                                    onChange={value =>
                                                                        this.setState({ time_zone: value })
                                                                    }
                                                                    options={this.state.time_zone_list}
                                                                    placeholder="Select time zone"
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row className="top-margin-10">
                                                        <Label md="4">Remarks</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Input
                                                                    value={this.state.remarks}
                                                                    type="textarea"
                                                                    onChange={e => {this.setState({remarks: e.target.value})}}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row className="top-margin-10">
                                                        <Label md="4">Payment Source</Label>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <Select
                                                                    className="react-select info"
                                                                    classNamePrefix="react-select"
                                                                    value={this.state.payment_source}
                                                                    isDisabled={this.state.payment_source_disable_selection}
                                                                    onChange={value =>
                                                                        this.setState({ payment_source: value })
                                                                    }
                                                                    options={this.state.payment_source_options_list}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    {
                                                        console.log('render>>>1 defaultBillingEmail', defaultBillingEmail)
                                                    }
                                                    {billing_email_list && /*billing_email_list && billing_profile_id !== null &&*/
                                                    <Row>
                                                        <Label md="4">Billing Email</Label>
                                                        <Col md="8">
                                                            <FormGroup className={`has-label ${billing_emailState}`}>
                                                                <CreatableSelect
                                                                    isClearable
                                                                    defaultValue={defaultBillingEmail}
                                                                    isDisabled={this.state.billing_email_disable_selection}
                                                                    // isValidNewOption={this.isValidBillingEmail}
                                                                    // value={this.state.billing_email}
                                                                    onChange={this.handleBillingEmailChange}
                                                                    options={billing_email_list}
                                                                />
                                                                {billing_emailState === "has-danger" ? (
                                                                    <label className="error">{`${billing_emailStateMessage}`}</label>
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="4"></Col>
                                                        <Col md="8">
                                                            <FormGroup>
                                                                <label className="error">If you change billing information (name and/or address fields), corresponding billing profile will also be updated.</label>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    }
                                                    <Row>
                                                        <Label md="4">Billing Name</Label>
                                                        <Col md="8">
                                                            <FormGroup className={`has-label ${billing_nameState}`}>
                                                                <Input
                                                                    placeholder="Your Billing Name"
                                                                    type="text"
                                                                    value={ billing_name }
                                                                    // disabled={!selected_stripe_customer}
                                                                    onChange={e =>
                                                                        this.change(e, "billing_name", "length", 1)
                                                                    }
                                                                />
                                                                {billing_nameState === "has-danger" ? (
                                                                    <label className="error">This field is required.</label>
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">Billing Address</Label>
                                                        <Col md="8">
                                                            <FormGroup className={`has-label ${billing_addressState}`}>
                                                                <Input
                                                                    placeholder="Your Billing Address"
                                                                    type="text"
                                                                    value={ billing_address }
                                                                    // disabled={!selected_stripe_customer}
                                                                    onChange={e =>
                                                                        this.change(e, "billing_address", "length", 1)
                                                                    }
                                                                />
                                                                {billing_addressState === "has-danger" ? (
                                                                    <label className="error">This field is required.</label>
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Card className="card-plain width-100">
                                                            <CardHeader role="tab">
                                                                <a
                                                                    aria-expanded={this.state.openedCollapses.includes(
                                                                        "show-more"
                                                                    )}
                                                                    href="#"
                                                                    data-parent="#accordion"
                                                                    data-toggle="collapse"
                                                                    onClick={e => {e.preventDefault(); this.collapsesToggle("show-more")}}
                                                                >
                                                                    {this.state.openedCollapses.includes("show-more")?'Show Less...':'Show More...'}{" "}
                                                                </a>
                                                            </CardHeader>
                                                            <Collapse
                                                                role="tabpanel"
                                                                isOpen={this.state.openedCollapses.includes(
                                                                    "show-more"
                                                                )}
                                                            >
                                                                <CardBody>
                                                                    <Row>
                                                                        <Label md="4">Expiry Date</Label>
                                                                        <Col md="8">
                                                                            <FormGroup>
                                                                                <Input
                                                                                    type="text"
                                                                                    value={this.state.expire}
                                                                                    disabled
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Label md="4">Status</Label>
                                                                        <Col md="8">
                                                                            <FormGroup>
                                                                                <Input
                                                                                    type="text"
                                                                                    value={this.state.status}
                                                                                    disabled
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Label md="4">Pending Queue Count</Label>
                                                                        <Col md="8">
                                                                            <FormGroup>
                                                                                <Input
                                                                                    defaultValue={this.state.pending_count}
                                                                                    type="text"
                                                                                    disabled
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            </Collapse>
                                                        </Card>
                                                    </Row>
                                                </Form>
                                            </Col>
                                        </Row>
                                    </Col>
                                </CardBody>
                            </Card>:null}
                        </Col>
                    </Row>
                </LoadingOverlay>
            </>
        );
    }
}

export default BillingManualPaymentInvoiceEdit;
