import uuidv1 from "uuid/v1";
import React, { useState, useEffect } from "react";
import Firebase from "firebase";
import config from "../../../config";
import Switch from "react-bootstrap-switch";
import Select from "react-select";
import { Row, Col, Button } from "reactstrap";
export default function TokenAttachFile (props) {
  let TokenData = props.TokenData;
  let userId = Firebase.auth().currentUser.uid;
  let notifyMessage = props.notifyMessage;
  let setUpdate = props?.setUpdate||null;
  const [size, setSize] = useState(5);
  const [slots, setSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState({File_Attachment_Name:'Select File Slot'});
  const [readOnly, setReadOnly] = useState(false);
  useEffect(() => {
    Firebase.firestore()
      .collection("System_Config")
      .doc("Upload_Limits")
      .get()
      .then((e) => {
        setSize(e.data().Max_Mobile_App_Attachment_File_size_in_MB);
      });
    Firebase.firestore()
      .collection("Services")
      .doc(TokenData.serv_id)
      .get()
      .then((e) => {
        setSlots(e.data().Mobile_App_File_Attachments);
      });
  }, []);
  function upload (e) {
    var ok = false;
    var Type = "";
    e.persist();
    if (e.target.files[0] != undefined) {
      console.log(slots);
      if (
        slots.length > 0 &&
        slots.map((s) =>
          s.File_Attachment_File_Types_Allowed.includes(
            e.target.files[0].name.split(".")[1].toUpperCase()
          )
        ) &&
        selectedSlot.File_Attachment_Name != undefined
      ) {
        Type = "ok";
      } else if (selectedSlot.File_Attachment_Name == undefined)
        notifyMessage("tc", 3, "Attachment file error, Slot not selected");
    } else notifyMessage("tc", 3, "Attachment file error, not found");

    if (Type == "ok") {
      let customer_id = TokenData.customer_id;

      const res = {
        status: "ok",
        data: { countExceedsLimit: false },
      };
      if (res.status === "ok") {
        var limitExceeded = res.data.countExceedsLimit;
        console.log("validateItemCount>>> countExceedsLimit", limitExceeded);
        //   setState({
        //     loading: false
        //   })
        // return limitExceeded;
        if (limitExceeded !== true) {
          let metadata = {
            customMetadata: {
              customer_id: customer_id,
              main_location_id: TokenData.main_loc_id,
              sub_location_id: TokenData.sub_loc_id,
            },
          };
          console.log(metadata);
          if (e.target.files[0].size <= size * 1024 * 1024) {
            var task = Firebase.storage().ref(
                "Mobile_App_File_Attachments/" +
                  TokenData.customer_id +
                  "/" +
                  userId +
                  "/" +
                  e.target.files[0].name
              ),
              task1 = task.put(e.target.files[0], metadata);

            document.getElementById("uploader").style.display = "block";

            task1.on(
              "state_changed",
              (snapshot) => {
                var percentage =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                document.getElementById("uploader").value = percentage;
              },
              () => {},
              () => {
                let j = 0,
                  ad = [],
                  o = 0,
                  index = -1,
                  attach = selectedSlot;
                console.log(attach, "selected slot!!");
                TokenData.file_attachments.forEach((e, k) => {
                  if (
                    e.File_Attachment_Name == attach.File_Attachment_Name ||
                    ""
                  )
                    index = k;
                });
                document.getElementById("uploader").style.display = "none";
                task.getDownloadURL().then(function (downloadURL) {
                  var idd = uuidv1();
                  if ( TokenData.file_attachments?.length>0)
                    if (index>=0) {
                      console.log(
                        index,
                        "same index, the attachment name is already there"
                      );
                      TokenData.file_attachments[
                        index
                      ].File_Attachment_Uploaded_By_Counter_User_Id = JSON.parse(
                        localStorage.getItem("auth_info")
                      ).email;
                      TokenData.file_attachments[
                        index
                      ].File_Attachment_File_Type = e.target.files[0].name.split(
                        "."
                      )[1];
                      TokenData.file_attachments[
                        index
                      ].File_Attachment_File_Name = e.target.files[0].name.split(
                        "."
                      )[0];
                      TokenData.file_attachments[
                        index
                      ].File_Attachment_File_Size_In_KB =
                        e.target.files[0].size / 1024;
                      TokenData.file_attachments[
                        index
                      ].File_Attachment_Uploaded_Datetime = Firebase.firestore.Timestamp.now();
                      TokenData.file_attachments[
                        index
                      ].File_Attachment_Uploaded_Datetime_Unix_Timestamp = new Date().valueOf();
                      TokenData.file_attachments[
                        index
                      ].File_Attachment_URL = downloadURL;
                      TokenData.file_attachments[
                        index
                      ].File_Attachment_Read_Only_For_Mobile_App_User = readOnly;
                    } else
                      TokenData.file_attachments.push({
                        File_Attachment_Read_Only_For_Mobile_App_User: readOnly,
                        File_Attachment_Name: attach.File_Attachment_Name || "",
                        File_Attachment_URL: downloadURL,
                        File_Attachment_File_Name: e.target.files[0].name.split(
                          "."
                        )[0],
                        id: attach.id || "",
                        File_Attachment_File_Size_In_KB:
                          e.target.files[0].size / 1024,
                        File_Attachment_File_Type: e.target.files[0].name.split(
                          "."
                        )[1],
                        File_Attachment_Description:
                          attach.File_Attachment_Description || "",
                        File_Attachment_Uploaded_By_Counter_User_Id: JSON.parse(
                          localStorage.getItem("auth_info")
                        ).email,
                        File_Attachment_Uploaded_By_Mobile_App_User_Id:
                          attach.File_Attachment_Uploaded_By_Mobile_App_User_Id ||
                          "",
                        File_Attachment_Uploaded_Datetime: Firebase.firestore.Timestamp.now(),
                        File_Attachment_Uploaded_Datetime_Unix_Timestamp: new Date().valueOf(),
                        File_Attachment_Grouping_Id:
                          attach.File_Attachment_Grouping_Id || -1,
                      });
                  else
                    TokenData.file_attachments = [
                      {
                        File_Attachment_Read_Only_For_Mobile_App_User: readOnly,
                        File_Attachment_Name: attach.File_Attachment_Name || "",
                        File_Attachment_URL: downloadURL,
                        File_Attachment_File_Name: e.target.files[0].name.split(
                          "."
                        )[0],
                        id: attach.id || "",
                        File_Attachment_File_Size_In_KB:
                          e.target.files[0].size / 1024,
                        File_Attachment_File_Type: e.target.files[0].name.split(
                          "."
                        )[1],
                        File_Attachment_Description:
                          attach.File_Attachment_Description || "",
                        File_Attachment_Uploaded_By_Counter_User_Id: JSON.parse(
                          localStorage.getItem("auth_info")
                        ).email,
                        File_Attachment_Uploaded_By_Mobile_App_User_Id:
                          attach.File_Attachment_Uploaded_By_Mobile_App_User_Id ||
                          "",
                        File_Attachment_Uploaded_Datetime: Firebase.firestore.Timestamp.now(),
                        File_Attachment_Uploaded_Datetime_Unix_Timestamp: new Date().valueOf(),
                        File_Attachment_Grouping_Id:
                          attach.File_Attachment_Grouping_Id || -1,
                      },
                    ];
                  console.log(
                    TokenData.file_attachments,
                    "token data attchments"
                  );
                  Firebase.firestore()
                    .collection("Token_Details")
                    .doc(TokenData.id)
                    .update({
                      Mobile_App_File_Attachments: TokenData.file_attachments,
                      Notify_Details:'web_edit'
                    })
                    .then(() => {setUpdate!=null&&setUpdate( TokenData?.file_attachments||[])});
                });
              }
            );
            task1.catch((error) => {
              console.log("token attachment upload error==> ", error);
            });
          } else {
            console.log("upload size isssue, size more than accepted!!");
            notifyMessage(
              "tc",
              3,
              "attachment size must be less than or equal to " +
                5 +
                " MB for image AD " +
                "and " +
                5 +
                " MB for video AD."
            );
          }
        }
      }
    } else
      notifyMessage(
        "tc",
        3,
        "Attachment file error, type not accepted, ONly " +
          slots.map((s) => s.File_Attachment_File_Types_Allowed.map((e) => e)) +
          " are allowed"
      );
  }
  if(slots.length>0)
  return (
    <>
      <Row style={{ alignItems: "baseline" }} className='token-attach-modal border border-primary border-pill px-4 py-2 my-4'>
        <progress id="uploader" value="0" max="100" style={{ display: "none" }}>
          0%
        </progress>
        <Col>
          <Select
            //   className='react-select'
            //   classNamePrefix='react-select'
            style={{ width: "9rem !important", zIndex: "200" }}
            placeholder="Select File Slot"
            name="file_slot"
            value={{
              label: selectedSlot.File_Attachment_Name,
              value: selectedSlot,
            }}
            onChange={(e) => {
              setSelectedSlot(e.value);
              console.log(selectedSlot);
            }}
            options={slots.map((e) => ({
              label: e.File_Attachment_Name,
              value: e,
            }))}
          />
        </Col>
        <Col>
          <label htmlFor="">
            Read Only:
            <Switch
              defaultValue={false}
              value={readOnly}
              onChange={(e) => setReadOnly(e.state.value)}
            />
          </label>
        </Col>
        <Col>
          <Button
            onClick={() => {
              document.getElementById("filee").click();
            }}
          >
            Upload File
          </Button>
        </Col>
        <input
          type="file"
          style={{ display: "none" }}
          id="filee"
          onChange={(e) => selectedSlot.File_Attachment_Name=='Select File Slot'?notifyMessage(
            "tc",
            3,
            "Please select File Slot first from select menu." 
          ):upload(e)}
        />
      </Row>
      <div style={{ height: "100px" }}></div>
    </>
  )
  else return (<><br/><p>*Can't upload files to this token, no slots found.</p></>)
}
