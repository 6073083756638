import React, { useState, useEffect, useRef } from "react";
import Firebase from "firebase";
//import Select from "react-select";
import ReactBSAlert from "react-bootstrap-sweetalert";
import NotificationAlert from "react-notification-alert";
import LoadingOverlay from "react-loading-overlay";
import Switch from "react-bootstrap-switch";
import Select from "react-select";
import ReactDragListView from "react-drag-listview/lib/index.js";
import config from "../../../config";
import "../../../assets/scss/css.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import moment from "moment-timezone";
import {
  Button,
  Label,
  Modal,
  Form,
  FormGroup,
  InputGroup,
  Row,
  Col,
  Input,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
} from "reactstrap";
//import ReactToPrint from "react-to-print";
import { useReactToPrint } from "react-to-print";
import {callApiHandleCounterRun} from "./ServiceAddEditFuncs";
import Duplicate from './servicesAdvanced'
class ComponentToPrint extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      LocationName,
      LocationAddress,
      ServiceName,
      StartCharacter,
      Token,
      TokenWithStartChar,
      CurrentDatetime,
      TokensAhead,
    } = this.props;
    const style1_24L10 = {
      fontSize: "24px",
      textAlign: "left",
      paddingLeft: "10px",
    };
    const style2_32L10 = {
      fontSize: "32px",
      textAlign: "left",
      paddingLeft: "10px",
    };
    const style3_24L10B180 = {
      fontSize: "24px",
      textAlign: "left",
      paddingLeft: "10px",
      display: "block",
      height: "180px",
    };
    return (
      <div style={style3_24L10B180}>
        <p style={style2_32L10}>
          <strong>{LocationName}</strong>
        </p>
        <p style={style1_24L10}>{LocationAddress}</p>
        <p style={style1_24L10}>{ServiceName}</p>
        <p style={style2_32L10}>
          <strong>{TokenWithStartChar}</strong>
        </p>
        <p style={style1_24L10}>Date: {CurrentDatetime}</p>
        <p style={style1_24L10}>Customers ahead: {TokensAhead}</p>
        <p style={style1_24L10}>&nbsp;</p>
      </div>
    );
  }
}

function ComponentToPrint2(props) {
  //TESTING_ONLY
  const {
    LocationName,
    LocationAddress,
    ServiceName,
    StartCharacter,
    Token,
    TokenWithStartChar,
    CurrentDatetime,
    TokensAhead,
  } = props;
  const style1_24L10 = {
    fontSize: "24px",
    textAlign: "left",
    paddingLeft: "10px",
  };
  const style2_32L10 = {
    fontSize: "32px",
    textAlign: "left",
    paddingLeft: "10px",
  };
  const style3_24L10B180 = {
    fontSize: "24px",
    textAlign: "left",
    paddingLeft: "10px",
    display: "block",
    height: "180px",
  };
  return (
    <div style={style3_24L10B180}>
      <p style={style2_32L10}>
        <strong>{LocationName}</strong>
      </p>
      <p style={style1_24L10}>{LocationAddress}</p>
      <p style={style1_24L10}>{ServiceName}</p>
      <p style={style2_32L10}>
        <strong>{TokenWithStartChar}</strong>
      </p>
      <p style={style1_24L10}>Date: {CurrentDatetime}</p>
      <p style={style1_24L10}>Customers ahead: {TokensAhead}</p>
      <p style={style1_24L10}>&nbsp;</p>
    </div>
  );
}

function Services(props) {
  //   constructor (props) {
  // super(props)
  if (!Firebase.apps.length) {
    Firebase.initializeApp(config);
  }
  // Firebase.functions().useFunctionsEmulator('http://localhost:5001')

  const [state, setState1] = useState({
    advanced_toggle: false,
    ordering_updating: false,
    ordering: false,
    loading: false,
    customer_id: JSON.parse(localStorage.getItem("auth_info")).customer_id,
    selected_main_location: "",
    main_location_list: [],
    selected_sub_location: [],
    sub_location_list: [],
    selected_service_groups: "",
    service_groups_list: [],
    default_service_icon: "",
    data: [],
    alert: "",
    hideCustom: true,
    functions_base_url: "",
    phoneNum: "",
    token_details_list: [],

    num_of_toks_to_gen_max_val: 100,
    num_of_toks_to_gen_min_val: 1,
    invalid_num_of_toks_to_gen: false,
    num_of_toks_to_gen: 1,
    num_of_toks_to_gen_curr_val: 0,

    selected_serv_start_num: 0,
    selected_serv_end_num: 0,
    selected_serv_num_of_tok: 0,
    selected_serv_num_tok_remaining: 0,

    modalClassic: false,
    selected_service: "",

    btn_disabled_gen_tok_range: false,
    groups: [],
    snaps: "",
  });
  const setState = (e) => {
    setState1((c) => ({ ...c, ...e }));
  };

  const now = new Date().toLocaleTimeString();

  const notifyAlert = useRef(null);
  const componentRef = useRef();
  const mobileNumRef = useRef();

  // var handlePrint = () => {};
  // var componentRef = () => {};

  // functions = null;                                              //functions emulator
  // fbStore = null;                                                //functions emulator
  //   }

  // initializeFirebase = () => {                                            //functions emulator
  // if (!functions) {                                              //functions emulator
  // functions = Firebase.functions();                          //functions emulator
  // functions.useFunctionsEmulator('http://localhost:5001');   //functions emulator
  // fbStore = Firebase.firestore();                            //functions emulator
  // }                                                                   //functions emulator
  // }                                                                       //functions emulator

  //   componentDidMount () {
  useEffect(() => {
    // initializeFirebase();                                          //functions emulator

    var role = JSON.parse(localStorage.getItem("auth_info")).role;
    if (
      role === "Site_Admin" ||
      role === "System_Admin" ||
      role === "Location_Admin" || 
      role === "Location_Super" 
    ) {
      setState({ hideCustom: false });
    }
    loadMainLocations();
    loadServiceGroups(state.customer_id);

    setState({ functions_base_url: config.baseURL });
    
  }, []);

  const toggleModalClassic = () => {
    setState({
      loading: false,
      modalClassic: !state.modalClassic,
    });
  };

  // onClickClose() {
  //     setState({modalClassic: true});
  // }

  const loadServiceGroups = (customer_id) => {
    console.log("loadServiceGroupInfo>>>1 customer_id", customer_id);
    //const { customer_id } = auth_info;

    return new Promise((resolve, reject) => {
      Firebase.firestore()
        .collection("Service_Groups")
        .where("Customer_ID", "==", customer_id)
        .get()
        .then((result) => {
          const service_groups_list = [];
          result.docs.forEach((service_group) => {
            const {
              Customer_ID,
              Name,
              Description,
              Icon,
              Main_Location_ID,
              Sub_Location_ID,
              /*Members_Count,*/ List_Order,
            } = service_group.data();
            const option = {
              service_group_id: service_group.id,
              //id: service_group.id,
              value: service_group.id,
              label: Name,
              service_group_name: Name,
              service_group_description: Description,
              service_group_icon: Icon,
              //service_group_members_count: Members_Count,
              service_group_list_order_number: List_Order,
              customer_id: Customer_ID,
              main_location_id: Main_Location_ID,
              sub_location_id: Sub_Location_ID,
            };
            service_groups_list.push(option);
          });
          console.log(
            "loadServiceGroupInfo>>>2 service_groups_list",
            service_groups_list
          );
          setState({ service_groups_list: service_groups_list });
          resolve(service_groups_list);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  };

  function loadMainLocations() {
    setState({ loading: true });
    let main_locations = [];
    var customer_id = JSON.parse(localStorage.getItem("auth_info")).customer_id;
    Firebase.firestore()
      .collection("System_Config")
      .doc("Mobile_App")
      .get()
      .then(function (resp) {
        var servIconPathDefault = resp.data().Default_Icons.Services;
        console.log(servIconPathDefault);
        setState({ default_service_icon: servIconPathDefault });
        // console.log("here",servIconPathDefault);
        Firebase.firestore()
          .collection("Main_Locations")
          .where("Customer_ID", "==", customer_id)
          .get()
          .then(function (response) {
            response.docs.forEach(function (doc) {
              main_locations.push({ label: doc.data().Name, value: doc.id });
            });

            setState({ main_location_list: main_locations });
            if (main_locations.length > 0) {
              setState({ selected_main_location: main_locations[0] });
              loadSubLocationByMain(main_locations[0].value);
            } else {
              setState({ loading: false });
            }

            //var servIconPathDefault = "";
          });
      })
      .catch(function (err) {
        setState({ loading: false });
        notifyMessage("tc", 3, "Network error!");
        console.log("loadMainLocations NetworkError0==>", err);
      });
  }
  function loadSubLocationByMain(main_id) {
    setState({ loading: true });
    setState({ selected_sub_location: null });
    let sub_locations = [];
    let customer_id = JSON.parse(localStorage.getItem("auth_info")).customer_id;
    Firebase.firestore()
      .collection("Sub_Locations")
      .where("Customer_ID", "==", customer_id)
      .where("Main_Location_ID", "==", main_id)
      .get()
      .then(function (response) {
        response.docs.forEach(function (doc) {
          sub_locations.push({
            label: doc.data().Name,
            value: doc.id,
            counts: doc.data().Service_Count,
          });
        });

        setState({ sub_location_list: sub_locations });
        if (sub_locations.length > 0) {
          // console.log("sub_locations==>", sub_locations);
          setState({ selected_sub_location: sub_locations[0] });
          //   loadServicesBySub(sub_locations[0].value)
        } else {
          setState({ loading: false });
        }
      })
      .catch(function (err) {
        setState({ loading: false });
        notifyMessage("tc", 3, "Network error!");
        console.log("loadSubLocationByMain NetworkError2==>", err);
      });
  }

  // /**
  //  * Sorts an array of objects by column/property.
  //  * @param {Array} array - The array of objects.
  //  * @param {object} sortObject - The object that contains the sort order keys with directions (asc/desc). e.g. { age: 'desc', name: 'asc' }
  //  * @returns {Array} The sorted array.
  //  */
  function multiSort(array, sortObject = {}) {
    // 18JUN2020 1547
    const sortKeys = Object.keys(sortObject);
    // Return array if no sort object is supplied.
    if (!sortKeys.length) {
      return array;
    }
    // Change the values of the sortObject keys to -1, 0, or 1.
    for (let key in sortObject) {
      sortObject[key] =
        sortObject[key] === "desc" || sortObject[key] === -1
          ? -1
          : sortObject[key] === "skip" || sortObject[key] === 0
          ? 0
          : 1;
    }
    const keySort = (a, b, direction) => {
      direction = direction !== null ? direction : 1;
      if (a === b) {
        // If the values are the same, do not switch positions.
        return 0;
      }
      // If b > a, multiply by -1 to get the reverse direction.
      return a > b ? direction : -1 * direction;
    };
    return array.sort((a, b) => {
      let sorted = 0;
      let index = 0;
      // Loop until sorted (-1 or 1) or until the sort keys have been processed.
      while (sorted === 0 && index < sortKeys.length) {
        const key = sortKeys[index];
        if (key) {
          const direction = sortObject[key];

          sorted = keySort(a[key], b[key], direction);
          index++;
        }
      }
      return sorted;
    });
  }
  useEffect(() => {
    if (
      state.selected_sub_location != undefined &&
      state.selected_sub_location.value != undefined &&
      state.selected_sub_location.value.length > 0
    )
      loadServicesBySub(state.selected_sub_location.value);
  }, [state.default_service_icon, state.selected_sub_location]);
  function loadServicesBySub(sub_id) {
    let i = 0,
      icon = state.default_service_icon;
    setState({ loading: true });
    let services = [];
    let customer_id = JSON.parse(localStorage.getItem("auth_info")).customer_id;
    //removing the listener before initiating a new one
    console.log(state.snaps);
    if (typeof state.snaps == "function") state.snaps();
    var snaps1 = Firebase.firestore()
      .collection("Services")
      .where("Customer_ID", "==", customer_id)
      .where("Sub_Location_ID", "==", sub_id)
      .orderBy("Name", "asc")
      .onSnapshot(
        async function (response) {
          // console.log(response.docs.map(e=>e.data().Name))
          i = 0;
          if (response.docs.length < services.length) services = [];
          response.docs.forEach(function (doc) {
            i++;
            let stopTokWhenEndNumReached =
              doc.data().Stop_Token_Generation_When_End_Num_Reached;
            let tokDet_bySubLoc_byCurrDay_byAllUsers_byServ_length =
              doc.data().Current_Token_Count;
            let lastGenTokenForCurrDate = doc.data().Last_Generated_Token;
            let numberOfTokens = doc.data().Number_Of_Tokens;
            let startNum = doc.data().Start_Number;
            let endNum = doc.data().End_Number;
            let tokRangeCount = 0;
            let lastTokGenForServForToday = true;
            let numTokRemainingForServForToday = 0;

            if (parseInt(numberOfTokens) != 0 && numberOfTokens != undefined) {
              tokRangeCount = parseInt(numberOfTokens);
            } else if (
              parseInt(numberOfTokens) == 0 ||
              numberOfTokens == undefined
            ) {
              tokRangeCount = parseInt(endNum) - parseInt(startNum) + 1;
            }

            if (
              (stopTokWhenEndNumReached == true &&
                tokDet_bySubLoc_byCurrDay_byAllUsers_byServ_length >=
                  tokRangeCount) ||
              (stopTokWhenEndNumReached == true &&
                (parseInt(lastGenTokenForCurrDate) >= parseInt(endNum) ||
                  lastGenTokenForCurrDate.toString() == endNum.toString())) //||
            ) {
              lastTokGenForServForToday = true;
              numTokRemainingForServForToday = 0;
            } else {
              lastTokGenForServForToday = false;
              numTokRemainingForServForToday =
                tokRangeCount -
                tokDet_bySubLoc_byCurrDay_byAllUsers_byServ_length;
            }
            let one = {
              associated : doc.data().Associated_Counter_Ids,
              service_name: doc.data().Name,
              id: doc.id,
              icon:
                doc.data().Icon === undefined || doc.data().Icon === ""
                  ? icon
                  : doc.data().Icon,
              start_number: startNum,
              end_number: endNum,
              details: doc.data().Details,
              priority: doc.data().Priority,
              list_order: doc.data().List_Order,
              waiting_in_queue: doc.data().Waiting_In_Queue,
              served_tokens: doc.data().Served_Tokens,
              // reset_at_day_end: doc.data().Reset_At_Day_End,
              // priority_int: parseInt(doc.data().Priority),
              // auto_close_time: parseInt(doc.data().Auto_Close_Time),
              // build_ai_generated: doc.data().Build_AI_Generated,
              last_generated_token_date_time:
                doc.data().Last_Generated_Token_Date_Time.seconds == undefined
                  ? doc.data().Last_Generated_Token_Date_Time
                  : new Date(
                      doc.data().Last_Generated_Token_Date_Time.seconds * 1000
                    ).toDateString(),
              stop_tok_gen_when_end_num_reached:
                doc.data().Stop_Token_Generation_When_End_Num_Reached,
              current_token_count:
                tokDet_bySubLoc_byCurrDay_byAllUsers_byServ_length,
              number_of_tokens: numberOfTokens,
              number_of_tokens_remaining: numTokRemainingForServForToday,
              service_type: doc.data().Service_Type,
              // last_generated_token_date_time: '',
              last_generated_token: lastGenTokenForCurrDate,
              last_called_number: doc.data().Last_Called_Number,
              last_called_counter: doc.data().Last_Called_Counter,
              last_token_generated_for_serv_for_today:
                lastTokGenForServForToday,
              updated_date: doc.data().Updated_Date,
              order: i,
              Service_Group_Profile_Id:
                doc.data().Service_Group_Profile_Id || "",
            };
            var inc = false;
            services.forEach((b) => {
              if (b.id == one.id) {
                b.associated = doc.data().Associated_Counter_Ids
                b.service_name = doc.data().Name;
                b.id = doc.id;
                b.icon =
                  doc.data().Icon === undefined || doc.data().Icon === ""
                    ? icon
                    : doc.data().Icon;
                b.start_number = startNum;
                b.end_number = endNum;
                b.details = doc.data().Details;
                b.priority = doc.data().Priority;
                b.list_order = doc.data().List_Order;
                b.waiting_in_queue = doc.data().Pending_Count;
                b.served_tokens = doc.data().Served_Tokens;
                // b.reset_at_day_end= doc.data().Reset_At_Day_End;
                // b.priority_int= parseInt(doc.data().Priority);
                // b.auto_close_time= parseInt(doc.data().Auto_Close_Time);
                // b.build_ai_generated= doc.data().Build_AI_Generated;
                b.last_generated_token_date_time =
                  doc.data().Last_Generated_Token_Date_Time.seconds == undefined
                    ? doc.data().Last_Generated_Token_Date_Time
                    : new Date(
                        doc.data().Last_Generated_Token_Date_Time.seconds * 1000
                      ).toDateString();
                b.stop_tok_gen_when_end_num_reached =
                  doc.data().Stop_Token_Generation_When_End_Num_Reached;
                b.current_token_count =
                  tokDet_bySubLoc_byCurrDay_byAllUsers_byServ_length;
                b.number_of_tokens = numberOfTokens;
                b.number_of_tokens_remaining = numTokRemainingForServForToday;
                b.service_type = doc.data().Service_Type;
                // b.last_generated_token_date_time= '';
                b.last_generated_token = lastGenTokenForCurrDate;
                b.last_called_number = doc.data().Last_Called_Number;
                b.last_called_counter = doc.data().Last_Called_Counter;
                b.last_token_generated_for_serv_for_today =
                  lastTokGenForServForToday;
                b.updated_date = doc.data().Updated_Date;
                b.Service_Group_Profile_Id =
                  doc.data().Service_Group_Profile_Id || "";
                inc = true;
              }
            });
            if (!inc) services.push(one);
          });

          try {
            let groups = [];
            await services.map(async(e, k) => {
              let name = "",
                order = 0,
                found = false;

              if (e.Service_Group_Profile_Id != "") {
                await state.service_groups_list.map(async(s, k) => {
                  // console.log(s.service_group_id == e.Service_Group_Profile_Id);
                  if (s.service_group_id == e.Service_Group_Profile_Id) {
                    name = s.service_group_name;
                    order = s.service_group_list_order_number;

                    if (k == state.service_groups_list.length - 1)
                      await groups.map((g) => {
                        if (g.id == e.Service_Group_Profile_Id) {
                          found = true;
                          g.items.push(e);

                          g.items.sort((a, b) =>
                            a.list_order > b.list_order
                              ? 1
                              : b.list_order > a.list_order
                              ? -1
                              : 0
                          );
                          g.name = name;
                          g.order = order;
                        }
                      });
                  }
                });
                if (!found)
                  groups.push({
                    items: [e],
                    id: e.Service_Group_Profile_Id,
                    name,
                    order
                  });
              } else
                groups.push({
                  items: [e],
                  id: k,
                  name,
                  order: e.list_order,
                });
              console.log(name,order)
            });
            await groups.sort((a, b) =>
              a.order > b.order ? 1 : b.order > a.order ? -1 : 0
            );
            if(!state.ordering_updating)
            setState({ data: groups, loading: false });
            // setState({ data: services, loading: false });
            // setState({ loading: false })
          } catch (err) {
            console.log("err", err);
          }
        },
        (error) => {
          console.log("Services error ==> ", error, sub_id);
          setState({ loading: false, data: [] });
        }
      );
    setState({ snaps: snaps1 });
  }

  function handleAdd() {
    setState({ loading: true });
    Firebase.functions()
      .httpsCallable("apiHandleServicesAddEdit")({
        cmd: "service_GetSelectedPackagePaymentRelatedRestrictions",
        sub_location_id: state.selected_sub_location.value,
        type: "SERVICES",
        antqueue_customer_id: state.customer_id,
      })
      .then(function (result) {
        const res = result.data;
        console.log("validateItemCount", { result });
        if (res.status === "ok") {
          var limitExceeded = res.data.countExceedsLimit;
          console.log("validateItemCount>>> countExceedsLimit", limitExceeded);
          setState({ loading: false });
          // return limitExceeded;
          if (limitExceeded === true) {
            notifyMessage(
              "tc",
              3,
              "Sorry, the selected package for current sub location does not allow any more Services to be added."
            );
          } else {
            props.history.push("/service/add");
          }
        }
      })
      .catch((error) => {
        setState({ loading: false });
        console.log("validateItemCount", { error });
        return error;
      });
  }

  function onChangeMain(e) {
    setState({ selected_main_location: e });
    loadSubLocationByMain(e.value);
  }

  function onChangeSub(e) {
    setState({ selected_sub_location: e });
    // console.log("value of e",e.value,"selected_sub_location",e);
    loadServicesBySub(e.value);
  }

  function handleChangeServGroups(selectedOptions) {
    // let _this = this;
    // if (selectedOptions!== null && selectedOptions.length >= 1) {
    //     let selectedServGroupProfileIds = selectedOptions.map((prop,key) => { return prop.value });
    //     console.log("selectedServGroupProfileIds", selectedServGroupProfileIds);
    //     setState({selected_service_groups: []});
    //     setState({selected_service_groups: selectedOptions});
    //     // loadTokenData(selectedServGroupProfileIds);
    // } else {
    //     setState({selected_service_groups: []});
    // }
  }

  function deleteServiceFromCounters(srvID) {
    // Remove Service id(s) from ALL Counters in the Counter's "Serving_Services" and "Filtered_Services" under same Customer_ID
    var customer_id = JSON.parse(localStorage.getItem("auth_info")).customer_id;

    Firebase.firestore()
      .collection("Counters")
      .where("Customer_ID", "==", customer_id)
      .where("Serving_Services", "array-contains", srvID)
      .get()
      .then((query) => {
        console.log(
          "Removing ServiceID: ",
          srvID,
          " from Serving_Services & Filtered_Services from CustomerID ",
          customer_id
        );
        query.docs.forEach((c) => {
          var servServices = c.data().Serving_Services;
          var fltrdServices = c.data().Filtered_Services;

          if (
            servServices != undefined &&
            servServices != "" &&
            servServices.length >= 1 &&
            servServices.includes(srvID)
          ) {
            console.log("c.id", c.id, "OLD servServices", servServices);
            var index1 = servServices.indexOf(srvID);
            if (index1 > -1) {
              servServices.splice(index1, 1);
            }
            console.log("c.id", c.id, "NEW servServices", servServices);
            Firebase.firestore()
              .collection("Counters")
              .doc(c.id)
              .update({
                Updated_Date: Firebase.firestore.FieldValue.serverTimestamp(),
                Serving_Services: servServices
              });
          } else {
            console.log(
              "c.id",
              c.id,
              "servServices == undefined || servServices == '' || servServices.length < 1 || servServices.includes(srvID) == false"
            );
          }

          if (
            fltrdServices != undefined &&
            fltrdServices != "" &&
            fltrdServices.length >= 1 &&
            fltrdServices.includes(srvID)
          ) {
            console.log("c.id", c.id, "OLD fltrdServices", fltrdServices);
            var index2 = fltrdServices.indexOf(srvID);
            if (index2 > -1) {
              fltrdServices.splice(index2, 1);
            }
            console.log("c.id", c.id, "NEW fltrdServices", fltrdServices);
            Firebase.firestore().collection("Counters").doc(c.id).update({
              Updated_Date: Firebase.firestore.FieldValue.serverTimestamp(),
              Filtered_Services: fltrdServices,
            });
          } else {
            console.log(
              "c.id",
              c.id,
              "fltrdServices == undefined || fltrdServices == '' || fltrdServices.length < 1 || fltrdServices.includes(srvID) == false"
            );
          }
        });
      })
      .catch(function (err) {
        // setState({ loading: false })
        notifyMessage("tc", 3, "Service wasn't linked to any counter");
        console.log("deleteItem NetworkError1==>", err);
      });
  }
  
  async function serviceDetUpdate (id) {
    let params = {
      version: '1',
      cmd_: 'C8',
      customerId: state.customer_id || '',
      mainLocId: state.main_location_id || '',
      subLocId: state.sub_location_id || '',
      // counterId: counter_id || '',
      emailId: JSON.parse(localStorage.getItem("auth_info")).email || '',
      userId: JSON.parse(localStorage.getItem("auth_info")).email || '',
      userName: state.user_name || '',
      timeZone: state.time_zone || '',
      refAction: 'service_deletion',
      serviceId:id
    }, c8Result
       c8Result = await callApiHandleCounterRun({ params });
      console.log('c8Result', c8Result)
  }

  function deleteItem(object) {
    // console.log("Delete!");
    setState({ loading: true });
    Firebase.firestore()
      .collection("Services")
      .doc(object.id)
      .delete()
      .then(function (res) {
        console.log('service deleted succssfully')
        serviceDetUpdate(object.id)
        setState({ loading: false, alert: null });
        var srvID = object.id; //serviceID;
        deleteServiceFromCounters(srvID);

        var storageRef = Firebase.storage().ref();
        var image_name = "service_icon_" + object.id;
        if (object.icon !== "" && object.icon != state.default_service_icon) {
          var customerRef = storageRef.child(image_name);
          customerRef
            .delete()
            .then(function () {
              // ------- Update Service Count -------- //
              Firebase.firestore()
                .collection("Sub_Locations")
                .doc(state.selected_sub_location.value)
                .update({
                  Updated_Date: Firebase.firestore.FieldValue.serverTimestamp(),
                  Service_Count: state.selected_sub_location.counts - 1,
                })
                .then(async function () {
                  await Firebase.firestore().collection('Counters').where('Serving_Services','array-contains',object.id).get().then(counters=>{
                    counters.docs().forEach(async e=>{
                      if(e.exists)
                      await Firebase.firestore().collection('Counters').doc(e.id).update({
                        Updated_Date: Firebase.firestore.FieldValue.serverTimestamp(),
                        Serving_Services:e.data().Serving_Services?.filter(s=>s!=object.id),Filtered_Services:e.data().Filtered_Services?.filter(s=>s!=object.id)
                      })
                    })
                  })
                  successDelete();
                })
                .catch(function (err) {
                  setState({ loading: false });
                  notifyMessage("tc", 3, "");
                  console.log("deleteItem NetworkError3==>", err);
                });
            })
            .catch(function (err) {
              setState({ loading: false });
              notifyMessage(
                "tc",
                3,
                "No Storage data was found for deleted service"
              );
              console.log("deleteItem NetworkError4==>", err);
            });
        } else {
          // ------- Update Service Count -------- //
          Firebase.firestore()
            .collection("Sub_Locations")
            .doc(state.selected_sub_location.value)
            .update({
              Updated_Date: Firebase.firestore.FieldValue.serverTimestamp(),
              Service_Count: state.selected_sub_location.counts - 1,
            })
            .then(function () {
              successDelete();
            })
            .catch(function (err) {
              setState({ loading: false });
              notifyMessage(
                "tc",
                3,
                "Error updating sub location service count"
              );
              console.log("deleteItem NetworkError3==>", err);
            });
        }
      })
      .catch(function (err) {
        setState({ loading: false });
        notifyMessage("tc", 3, "Error finding and deleting the service!");
        console.log("deleteItem NetworkError5==>", err);
      });
  }
  function serviceComponent(items, k) {
    const data = items;

    const dragProps1 = {
      onDragEnd(fromIndex, toIndex) {
        setState({ordering:true})
        const to_priority = data[toIndex].list_order;
        const item = data.splice(fromIndex, 1)[0];
        data.splice(toIndex, 0, item);

        let start = fromIndex > toIndex ? toIndex : fromIndex;
        let end = fromIndex > toIndex ? fromIndex : toIndex;

        for (let j = start; j <= end; j++) {
          // setState({ loading: true });
          // Firebase.firestore()
          //   .collection("Services")
          //   .doc(data[j].id)
          //   .update({ Updated_Date: Firebase.firestore.FieldValue.serverTimestamp(), List_Order: j+Number(data[j-1]?.items.length)||j })
          //   .then(function () {
          //     setState({ loading: false });
          //     console.log("services loading false");
          //   })
          //   .catch(function (err) {
          setState({ loading: false });
          //     notifyMessage("tc", 3, "Network error!");
          //     console.log("render NetworkError7==>", err);
          //   });
        }
        // setState({ data: data });
      },
      nodeSelector: ".service",
      handleSelector: ".service-link",
    };

    return (
      <ReactDragListView {...dragProps1}>
        {items.map((item, k) => (
          <>
            <li key={k} className="service">
              <hr />
              <Row style={{ paddingLeft: "0.5rem" }}>
                <Col md="1">
                  <img
                    alt="..."
                    src={item.icon}
                    className="width-100" //"full-size"
                  />
                </Col>
                <Col md="2">
                  <div className="left-margin-10">
                    <a>{item.service_name}</a>
                    <br />
                    <small>{item.details}</small>
                  </div>
                </Col>
                <Col md="4" className="text-center">
                  <div
                    className="left-margin-10 top-margin-10"
                    style={{ fontSize: "0.7rem" }}
                  >
                    <Row>
                      <Col md="6" className="px-0 text-left">
                        <a>Start Number : </a>
                        <span>{item.start_number}</span>
                      </Col>
                      {/* <br/> */}
                      {/* <hr/> */}
                      <Col md="6" className="px-0 text-left">
                        <a>End Number : </a>
                        <span>{item.end_number}</span>
                      </Col>
                      {/* <br/> */}
                      {/* <hr/> */}
                      <Col md="6" className="px-0 text-left">
                        <a>Current Token : </a>
                        <span>{item.last_called_number}</span>
                      </Col>
                      <Col md="6" className="px-0 text-left">
                        <a>Pending Tokens : </a>
                        <span>{item.waiting_in_queue}</span>
                      </Col>
                      {/* <br/> */}
                      {/* <hr/> */}
                      <Col md="6" className="px-0 text-left">
                        <a>Served Tokens : </a>
                        <span>{item.served_tokens}</span>
                      </Col>
                      {/* <br/> */}
                      {/* <hr/> */}
                      <Col md="6" className="px-0 text-left">
                        <a> </a>
                        <span> </span>
                      </Col>
                      {/* <br/> */}
                      {/* <hr/> */}
                      <Col md="6" className="px-0 text-left">
                        <a>Last Generated Token : </a>
                        <span>{item.last_generated_token}</span>
                      </Col>
                      {/* <br/> */}
                      {/* <hr/> */}
                      <Col md="6" className="px-0 text-left">
                        <a>Last Generated Token At : </a>
                        <span>{item.last_generated_token_date_time}</span>
                      </Col>
                      {/* <br/> */}
                      {/* <hr/> */}
                      <Col md="6" className="px-0 text-left">
                        <a>Last Called Counter : </a>
                        <span>{item.last_called_counter}</span>
                      </Col>
                      {/* <br/> */}
                      {/* <hr/> */}
                      {/* <Col md='6' className='px-0 text-left'><details>
																		<summary>More info</summary>
																		<br/>
																		<br/>
																		<a>Last Generated Token In : </a>
																		<br/>
																		<span>{item.last_generated_token_date_time}</span>
																		<br/>
																		<br/>
																		<a>Last Printed Token : </a>
																		<span>{item.last_generated_token}</span>
																		<br/>
																		<br/>
																		<a>Last Called Counter : </a>
																		<span>{item.last_called_counter}</span>
																</details></Col> */}
                    </Row>
                  </div>
                </Col>
                <Col md="4" className="text-center" hidden={state.hideCustom}>
                  <div className="top-margin-12" />
                  <Button
                    className="btn btn-warning"
                    onClick={(e) =>
                      props.history.push("/service/edit/" + item.id)
                    }
                  >
                    Edit
                  </Button>
                  <Button
                    className="btn btn-danger"
                    onClick={(e) => {
                      e.preventDefault();
                      warningWithConfirmMessage(item);
                    }}
                  >
                    Delete
                  </Button>
                  <Button
                    className="btn btn-success"
                    onClick={(e) => {
                      e.preventDefault();
                      handleGenerateNewTokenNum(item.id);
                    }}
                  >
                    New Token
                  </Button>
                  <Button
                    className="btn btn-info"
                    onClick={(e) => {
                      e.preventDefault();
                      showNewTokenRangeSelector(item);
                    }}
                  >
                    New Token Range
                  </Button>
                </Col>
                <Col md="1">
                  <a
                    href="#"
                    className=" navbar-toggler service-link "
                    onClick={(e) => e.preventDefault()}
                    style={{ fontSize: "0.9rem" }}
                  >
                    <i className="nc-icon nc-align-center"></i>
                  </a>
                </Col>
              </Row>
              <hr />
            </li>
          </>
        ))}
      </ReactDragListView>
    );
  }
  function getServices() {
    // Current Serving Num
    // Pending Count
    // Num of Served Tokens (in Last 12hrs)
    const data = [];
    const auth = JSON.parse(localStorage.getItem("auth_info"));
    let groups = state.data;
    // let groups = [] ;
    // if(state.groups.length>0)
    // groups = state.groups
    // else if(state.data.length>0)
    // {
    // console.log(state.service_groups_list);

    // state.data.map((e) => {
    //   let name = "",
    //     order = "",
    //     found = false;

    //   state.service_groups_list.map((s, k) => {
    //     if (s.service_group_id == e.Service_Group_Profile_Id) {
    //       name = s.service_group_name;
    //       order = s.service_group_list_order_number;
    //     }
    //     if (k == state.service_groups_list.length - 1)
    //       groups.map((g) => {
    //         if (g.id == e.Service_Group_Profile_Id) {
    //           found = true;
    //           g.items.push(e);
    //           g.name = name;
    //           g.order = order;
    //         }
    //       });
    //   });

    //   if (!found)
    //     groups.push({
    //       items: [e],
    //       id: e.Service_Group_Profile_Id,
    //       name,
    //       order,
    //     });
    // });
    // groups.sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0));
    // if (
    //   groups.length != state.groups.length &&
    //   groups[0] != state.groups[0] &&
    //   groups != []
    // ) {
    //   setState({ groups: groups });
    //   console.log("generating new group !!!!!!!!!!!!");
    // }
    // }
    // return state.data.map((item, index) => {
    return groups.map((group, index) => {
      // let item = groups[group];
      // data.push({
      //   title: `rows${index}`,
      // });
      // if (state.data.length == index)
      //   state = {
      //     data,
      //   };
      return (
        <li key={index} className="account-tab group">
          <Row>
            <Col>
              {group.name.length > 0 ? group.name : "Ungrouped services"}
            </Col>
            <Col md="7"></Col>
            <Col md="1">
              <a
                href="#"
                className="float-right navbar-toggler group-link"
                onClick={(e) => e.preventDefault()}
              >
                <i className="nc-icon nc-align-center"></i>
              </a>
            </Col>
          </Row>
          {serviceComponent(group.items, index)}
        </li>
      );
    });
  }

  const handleGenerateNewTokenNum = (serviceId) => {
    console.log("running handleGenerateNewTokenNum()__ serviceId", serviceId);
    //var serviceId = "6fc71a80-e7bf-11e9-aaee-5d5c2544f5fa"; //id

	setState({ loading: true });
    var email = JSON.parse(localStorage.getItem("auth_info")).email;
    //var ApiKey = state.api_key;

    //let userId = //'rndUsrId';    //UserId            || '';
    let apiKey = 8700077; //'LNBqORi';     //ApiKey            || '';
    //let serviceId = serviceId; //serviceId    || '';
    let subLocId = state.selected_sub_location.value; //state.sublocation_id;          //SubLocId          || '';
    let mainLocId = state.selected_main_location.value; //state.mainlocation_id;        //MainLocId         || '';

    let currentUser = Firebase.auth().currentUser;
    currentUser.getIdToken().then(function (token) {
      // console.log("token", token);

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      let fullBaseURL = state.functions_base_url + "apiGenerateTokenForWebInterface";
      console.log("state.functions_base_url fullBaseURL", fullBaseURL);
      console.log({
        EmailId: email,
        ApiKey: apiKey,
        ServiceId: serviceId,
        SubLocId: subLocId,
        MainLocId: mainLocId,
      });
      let url = new URL(fullBaseURL),
        params = {
          EmailId: email,
          ApiKey: apiKey,
          ServiceId: serviceId,
          SubLocId: subLocId,
          MainLocId: mainLocId,
        };
      Object.keys(params).forEach((key) =>
        url.searchParams.append(key, params[key])
      );
      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((tokenData) => {
          showSuccessfulNewToken(tokenData);
        })
        .catch(function (error) {
          setState({ loading: false });
          notifyMessage("tc", 3, "Network error!");
          console.log("handleGenerateNewTokenNum NetworkError==>", error);
        });
    });
  };

  function showSuccessfulNewToken(tokenData) {
    var newTokenTitle = "";
    if (tokenData.status == "success") {
      newTokenTitle =
        "New Token Generated: " +
        tokenData.result.StartCharacter +
        tokenData.result.Token;
      setState({
        alert: (
          <ReactBSAlert
            success
            showCancel
            showCloseButton
            confirmBtnText="Print"
            cancelBtnText="SMS"
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            title={newTokenTitle}
            onConfirm={() => printTokenNum(tokenData.result)}
            onCancel={() => hideAlert()}
            customButtons={
              <React.Fragment>
                <button onClick={() => printTokenNum(tokenData.result)}>
                  Print
                </button>
                <button onClick={() => promptSendTwilioText(tokenData.result)}>
                  SMS
                </button>
                <button onClick={() => hideAlert()}>Close</button>
              </React.Fragment>
            }
          ></ReactBSAlert>
        ),
      });
    } else {
      newTokenTitle = tokenData.message;
      setState({
        alert: (
          <ReactBSAlert
            success
            showCloseButton
            title={newTokenTitle}
            onConfirm={() => hideAlert()}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="info"
          ></ReactBSAlert>
        ),
      });
    }
  }

  function showNewTokenSMSSentFailedAlert(status, message) {
    if (status == "failed") {
      let newTokenTitle = message;
      setState({
        alert: (
          <ReactBSAlert
            success
            showCloseButton
            title={newTokenTitle}
            onConfirm={() => hideAlert()}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="info"
          ></ReactBSAlert>
        ),
      });
    }
  }

  function showNewTokenSMSSentSuccessfulORFailedAlert(tokenData) {
    //let phone_num_code = "";
    var newTokenTitle = "";
    if (tokenData.status == "success") {
      newTokenTitle =
        "SMS for token " +tokenData.result.Token +" was successfully sent to " + mobileNumRef.current /*tokenData.result.ToPhoneNumber*/;
      setState({
        alert: (
          <ReactBSAlert
            success
            showCancel
            showCloseButton
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            title={newTokenTitle}
            onConfirm={() => hideAlert()}
            onCancel={() => hideAlert()}
          ></ReactBSAlert>
        ),
      });
    } else {
      newTokenTitle = tokenData.message;
      setState({
        alert: (
          <ReactBSAlert
            success
            showCloseButton
            title={newTokenTitle}
            onConfirm={() => hideAlert()}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="info"
          ></ReactBSAlert>
        ),
      });
    }
    setState({ phoneNum: "" });
  }

  function promptSendTwilioText(data) {
    hideAlert();
    console.log("promptSendTwilioText", data);
    var newTokenTitle = "";
    newTokenTitle = "New Token Generated: " + data.StartCharacter + data.Token;
    setState({
      alert: (
        <ReactBSAlert
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          title={newTokenTitle}
          onConfirm={(response) => sendTwilioText(data)}
          onCancel={() => hideAlert()}
        >
          <div style={{ textAlign: "left" }}>
            Enter SMS number (+[Country_Code][Area_Code][Phone_Number]):
          </div>
          <div style={{ height: "10px" }}></div>
          <div style={{ textAlign: "left" }}>
            <PhoneInput
              country={"us"}
              value={state.phoneNum}
              onChange={(phoneNum) => setPhoneNumber(phoneNum)}
            />
          </div>
          <div style={{ height: "210px" }}></div>
        </ReactBSAlert>
      ),
    });
  }

  function setPhoneNumber(num) {
    let phoneNumFormatted = "+" + num.toString();
    console.log("phoneNumNonFormatted", num, "phoneNumFormatted", phoneNumFormatted);
    setState({ phoneNum: phoneNumFormatted });
    mobileNumRef.current = phoneNumFormatted;
  }

  /// Validate E164 format
  function validE164(num) {
    return /^\+?[1-9]\d{1,14}$/.test(num);
  }

  const sendTwilioText = (data) => {
    hideAlert();

    // console.log("LocationName", data.LocationName, "LocationAddress", data.LocationAddress, "ServiceName", data.ServiceName, "StartCharacter", data.StartCharacter, "Token", data.Token, "currTokenRef.current", currTokenRef.current, "CurrentDatetime", data.CurrentDatetime, "CurrentDatetimeDate", data.CurrentDatetime.date, "CurrentDatetimeTimeZone", data.CurrentDatetime.timezone, "TokensAhead", data.TokensAhead, "ToPhoneNumber", toPhoneNumber, "mobileNumRef.current", mobileNumRef.current /*toPhoneNumber*/);
    console.log({ data: data });
    let toPhoneNumber = mobileNumRef.current; //phone_num;
    //let phoneNumCode = "";
    let locationName = data.LocationName,
      locationAddress = data.LocationAddress,
      subLocationId = data.SubLocationId,
      serviceName = data.ServiceName;
    let startCharacter = data.StartCharacter,
      token = data.Token,
      currentDatetime = data.CurrentDatetime;
    let currentDatetimeDate = data.CurrentDatetime.date,
      currentDatetimeTimeZone = data.CurrentDatetime.timezone;
    let tokensAhead = data.TokensAhead;

    let fullBaseURL = state.functions_base_url + "sendTwilioText";
    console.log("fullBaseURL", fullBaseURL);

    if (!validE164(toPhoneNumber)) {
      //throw new Error("number must be E164 format!");
      let status = "failed";
      let message =
        "SMS for token " +
        startCharacter + token +
        " was not sent to " +
        toPhoneNumber +
        " because the number was not of correct E164 format! Eg: +[Country_Code][Area_Code][Phone_Number]";
      showNewTokenSMSSentFailedAlert(status, message);
    } else {
      let url = new URL(fullBaseURL),
        params = {
          subLocationId: subLocationId,
          locationName: locationName,
          locationAddress: locationAddress,
          serviceName: serviceName,
          startCharacter: startCharacter,
          token: token,
          currentDatetime: currentDatetime,
          currentDatetimeDate: currentDatetimeDate,
          currentDatetimeTimeZone: currentDatetimeTimeZone,
          tokensAhead: tokensAhead,
          toPhoneNumber: toPhoneNumber,
        };
      Object.keys(params).forEach((key) => 
        url.searchParams.append(key, params[key])
      );
      fetch(url)
        .then((response) => response.json())
        .then(
          (tokenData) => showNewTokenSMSSentSuccessfulORFailedAlert(tokenData)
          //,console.log("tokenData", tokenData)
        )
        .catch((error) => console.log("fetch error", error));
    }
  };

  const handleChange = (event, stateName, type, stateNameEqualTo) => {
    console.log("event.target.value", event.target.value);
    switch (type) {
      case "num_of_toks_to_gen_field":
        let number_of_tokens_targetValue = event.target.value;
        let token_range = getTokenRange(state.selected_service);
        let num_of_toks_to_gen_min_val = state.num_of_toks_to_gen_min_val;

        if (
          (verifyNumber(event.target.value) &&
            number_of_tokens_targetValue <= token_range &&
            number_of_tokens_targetValue >= num_of_toks_to_gen_min_val) ||
          number_of_tokens_targetValue == 0
        ) {
          setState({ invalid_number_of_tokens: false });
          setState({
            num_of_toks_to_gen: number_of_tokens_targetValue,
            num_of_toks_to_gen_curr_val: number_of_tokens_targetValue,
          });
        } else {
          setState({ invalid_number_of_tokens: true });
        }
        console.log(
          "invalid_number_of_tokens",
          state.invalid_number_of_tokens,
          "getTokenRange(state.selected_service)",
          getTokenRange(state.selected_service),
          "num_of_toks_to_gen",
          state.num_of_toks_to_gen,
          "num_of_toks_to_gen_curr_val",
          state.num_of_toks_to_gen_curr_val
        );
        break;
      default:
        break;
    }
  };

  function increaseNumberOfTokensToGenerate() {
    let num_of_toks_to_gen = parseInt(state.num_of_toks_to_gen);
    let token_range = getTokenRange(state.selected_service);
    let num_of_toks_to_gen_min_val = state.num_of_toks_to_gen_min_val;
    let selected_serv_num_tok_remaining = state.selected_serv_num_tok_remaining;

    if (selected_serv_num_tok_remaining === 0) {
      setState({ invalid_num_of_toks_to_gen: true });
      notifyMessage(
        "tc",
        3,
        "There are no currently available slots to generate this token range!"
      );
    } else if (
      num_of_toks_to_gen + 1 <= token_range &&
      num_of_toks_to_gen + 1 >= num_of_toks_to_gen_min_val
    ) {
      setState({ invalid_num_of_toks_to_gen: false });
      let new_value = num_of_toks_to_gen + 1;
      setState({
        num_of_toks_to_gen: new_value,
        num_of_toks_to_gen_curr_val: new_value,
      });
    } else {
      setState({ invalid_num_of_toks_to_gen: true });
      notifyMessage(
        "tc",
        3,
        `Number of tokens to generate must be between ${state.num_of_toks_to_gen_min_val} and ${state.num_of_toks_to_gen_max_val}!`
      );
    }
    console.log(
      "invalid_num_of_toks_to_gen",
      state.invalid_num_of_toks_to_gen,
      "getTokenRange(state.selected_service)",
      getTokenRange(state.selected_service),
      "num_of_toks_to_gen",
      state.num_of_toks_to_gen,
      "num_of_toks_to_gen_curr_val",
      state.num_of_toks_to_gen_curr_val
    );
  }

  function decreaseNumberOfTokensToGenerate() {
    let num_of_toks_to_gen = parseInt(state.num_of_toks_to_gen);
    let token_range = getTokenRange(state.selected_service);
    let num_of_toks_to_gen_min_val = state.num_of_toks_to_gen_min_val;
    let selected_serv_num_tok_remaining = state.selected_serv_num_tok_remaining;

    if (selected_serv_num_tok_remaining === 0) {
      setState({ invalid_num_of_toks_to_gen: true });
      notifyMessage(
        "tc",
        3,
        "There are no currently available slots to generate this token range!"
      );
    } else if (
      num_of_toks_to_gen - 1 <= token_range &&
      num_of_toks_to_gen - 1 >= num_of_toks_to_gen_min_val
    ) {
      setState({ invalid_num_of_toks_to_gen: false });
      let new_value = num_of_toks_to_gen - 1;
      setState({
        num_of_toks_to_gen: new_value,
        num_of_toks_to_gen_curr_val: new_value,
      });
    } else {
      setState({ invalid_num_of_toks_to_gen: true });
      notifyMessage(
        "tc",
        3,
        `Number of tokens to generate must be between ${state.num_of_toks_to_gen_min_val} and ${state.num_of_toks_to_gen_max_val}!`
      );
    }
    console.log(
      "invalid_num_of_toks_to_gen",
      state.invalid_num_of_toks_to_gen,
      "getTokenRange(state.selected_service)",
      getTokenRange(state.selected_service),
      "num_of_toks_to_gen",
      state.num_of_toks_to_gen,
      "num_of_toks_to_gen_curr_val",
      state.num_of_toks_to_gen_curr_val
    );
  }

  function getTokenRange(servData) {
    console.log(
      "servData.number_of_tokens_remaining",
      servData.number_of_tokens_remaining
    );
    return servData.number_of_tokens_remaining; //servData.number_of_tokens_remaining;
  }

  function showNewTokenRangeSelector(data) {
    hideAlert();
    const { modalClassic } = state;
    let lastTokGenForServForToday =
      data.last_token_generated_for_serv_for_today;
    let lastGenTokenForCurrDate = data.last_generated_token;
    let serviceType = data.service_type;

    console.log("showNewTokenRangeSelector", data);
    let newTokenTitle = "";
    if (serviceType == "Token") {
      if (
        //1+1==2 ||   //TESTING_ONLY
        lastTokGenForServForToday === true
      ) {
        newTokenTitle =
          "Last token for this service for today (" +
          lastGenTokenForCurrDate +
          ") has been generated!";
        setState({
          alert: (
            <ReactBSAlert
              confirmBtnBsStyle="info"
              cancelBtnBsStyle="danger"
              title={newTokenTitle}
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
            ></ReactBSAlert>
          ),
        });
      } else {
        newTokenTitle = "Generate new token range: ";
        resetModalValues();
        setState({
          selected_serv_start_num: data.start_number,
          selected_serv_end_num: data.end_number,
          selected_serv_num_of_tok: data.number_of_tokens,
          selected_serv_num_tok_remaining: data.number_of_tokens_remaining,
        });
        setState({ selected_service: data });
        setState({
          num_of_toks_to_gen_min_val: 1,
          num_of_toks_to_gen_max_val: getTokenRange(data),
        });
        toggleModalClassic();
      }
    } else {
      newTokenTitle =
        "Sorry, you can only generate token ranges for service type 'Token' Only!";
      resetModalValues();
      setState({
        alert: (
          <ReactBSAlert
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            title={newTokenTitle}
            onConfirm={() => hideAlert()}
            onCancel={() => hideAlert()}
          ></ReactBSAlert>
        ),
      });
    }
  }

  //https://www.freecodecamp.org/news/thrown-for-a-loop-understanding-for-loops-and-timeouts-in-javascript-558d8255d8a4/
  //loop inside api call

  // function loopDone() {
  //   console.log('The loop is done!');
  // }
  // for(let i = 1; i < 6; i++) {
  //   setTimeout(()=>{
  //     console.log(i);
  //     if(i === 5){
  //         setTimeout(loopDone, 1000); // update this
  //     }
  //   },i * 1000);
  // }

  function handleGenerateNewTokenNumRange(servData) {
    //var servId = "6fc71a80-e7bf-11e9-aaee-5d5c2544f5fa"; //id;
    let servId = servData.id;
    let numOfTokToGen = state.num_of_toks_to_gen;
    let numOfTokRemaining = state.selected_serv_num_tok_remaining;

    setState({ loading: true });
    setState({ btn_disabled_gen_tok_range: true });
    console.log({
      servData: servData,
      servId: servId,
      numOfTokToGen: numOfTokToGen,
    });

    var email = JSON.parse(localStorage.getItem("auth_info")).email;
    //var ApiKey = state.api_key;

    //let userId =                         F                     //'rndUsrId';    //UserId            || '';
    let apiKey = 8700077; //'LNBqORi';     //ApiKey            || '';
    let serviceId = servData.id; //ServiceId    || '';
    let subLocId = state.selected_sub_location.value; //state.sublocation_id;          //SubLocId          || '';
    let mainLocId = state.selected_main_location.value; //state.mainlocation_id;        //MainLocId         || '';

    let currentUser = Firebase.auth().currentUser;

    if (
      verifyNumber(numOfTokToGen) &&
      numOfTokToGen >= 1 &&
      numOfTokRemaining !== 0
    ) {
      let j = 0;

      var loopTimeMS = 500; //500ms per loop

      //https://www.freecodecamp.org/news/thrown-for-a-loop-understanding-for-loops-and-timeouts-in-javascript-558d8255d8a4/
      function loopDone() {
        console.log("The loop is done!");
        console.log("reached numOfTokToGen === j");
        resetModalValues();
      }

      for (let i = 1; i < numOfTokToGen; i++) {
        setTimeout(() => {
          console.log(i);

          currentUser.getIdToken().then(function (token) {
            // console.log("token", token);

            const requestOptions = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            };

            let fullBaseURL = state.functions_base_url + "apiGenerateTokenForWebInterface";
            console.log("state.functions_base_url fullBaseURL", fullBaseURL);
            let url = new URL(fullBaseURL),
              params = {
                EmailId: email,
                ApiKey: apiKey,
                ServiceId: serviceId,
                SubLocId: subLocId,
                MainLocId: mainLocId,
              };
            Object.keys(params).forEach((key) =>
              url.searchParams.append(key, params[key])
            );
            fetch(url, requestOptions)
              .then((response) => response.json())
              .then((tokenData) => {
                j++;
                setState({ num_of_toks_to_gen_curr_val: j });
                showSuccessfulNewTokenRange(tokenData, j);
                console.log({
                  num_of_toks_to_gen: numOfTokToGen,
                  num_of_toks_to_gen_curr_val: j,
                  numOfTokToGen: numOfTokToGen,
                  j: j,
                });

                if (i === numOfTokToGen - 1) {
                  //if (numOfTokToGen === j) {
                  setTimeout(loopDone, loopTimeMS); // update this
                }
              })
              .catch(function (error) {
                setState({ loading: false });
                notifyMessage("tc", 3, "Network error!");
                console.log(
                  "showSuccessfulNewTokenRange NetworkError==>",
                  error
                );
              });
          });
        }, i * loopTimeMS);
      }
    } else {
      resetModalValues();
      setState({ modalClassic: false });
      if (numOfTokRemaining === 0) {
        showFailedNewTokenRange(
          "failed",
          "There are no currently available slots to generate this token range!"
        );
      } else {
        showFailedNewTokenRange(
          "failed",
          "An unexpected error occured while generating token range!"
        );
      }
    }
  }

  function showSuccessfulNewTokenRange(tokenData, i) {
    let newTokenTitle = "";
    console.log("showSuccessfulNewTokenRange i", i);
    setState({ modalClassic: false });

    if (tokenData.status == "success") {
      newTokenTitle =
        "New Token Generated: " +
        tokenData.result.StartCharacter +
        tokenData.result.Token;
      setState({
        alert: (
          <ReactBSAlert
            success
            showCancel
            showCloseButton
            confirmBtnText="Print"
            cancelBtnText="SMS"
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            title={newTokenTitle}
            onConfirm={() => printTokenNum(tokenData.result)}
            onCancel={() => hideAlert()}
            customButtons={
              <React.Fragment>
                {state.num_of_toks_to_gen === i ? (
                  <div>
                    Token {i} / {state.num_of_toks_to_gen}. Token generation has
                    succesfully completed.
                  </div>
                ) : (
                  <div>
                    Token {i} / {state.num_of_toks_to_gen}. Please wait until
                    this process has completed.
                  </div>
                )}

                <br />
                {/* <button onClick={() => printTokenNum(tokenData.result)}>Print</button> */}
                {/* <button onClick={() => promptSendTwilioText(tokenData.result)}>SMS</button> */}
                <button onClick={() => hideAlert()}>Close</button>
              </React.Fragment>
            }
          ></ReactBSAlert>
        ),
      });
    } else {
      newTokenTitle = tokenData.message;
      setState({
        alert: (
          <ReactBSAlert
            success
            showCloseButton
            title={newTokenTitle}
            onConfirm={() => hideAlert()}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="info"
          ></ReactBSAlert>
        ),
      });
    }
  }

  function showFailedNewTokenRange(status, message) {
    if (status == "failed") {
      let newTokenTitle = message;
      setState({
        alert: (
          <ReactBSAlert
            success
            showCloseButton
            title={newTokenTitle}
            onConfirm={() => hideAlert()}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="info"
          ></ReactBSAlert>
        ),
      });
    }
  }

  const verifyNumber = (value) => {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  };

  function resetModalValues() {
    setState({
      loading: false,
      btn_disabled_gen_tok_range: false,
      selected_serv_start_num: "",
      selected_serv_end_num: "",
      selected_serv_num_of_tok: 0,
      selected_serv_num_tok_remaining: 0,
    });
  }

  const printTokenNum = (data) => {
    hideAlert();

    console.log("LocationName", data.LocationName);
    console.log("LocationAddress", data.LocationAddress);
    console.log("ServiceName", data.ServiceName);
    console.log("StartCharacter", data.StartCharacter);
    console.log("Token", data.Token);
    console.log("CurrentDatetime", data.CurrentDatetime);
    console.log("TokensAhead", data.TokensAhead);

    setState({
      LocationName: data.LocationName,
      LocationAddress: data.LocationAddress,
      ServiceName: data.ServiceName,
      StartCharacter: data.StartCharacter,
      Token: data.Token,
      CurrentDatetime: data.CurrentDatetime,
      TokensAhead: data.TokensAhead,
    });

    console.log("LocationName", data.LocationName);
    console.log("LocationAddress", data.LocationAddress);
    console.log("ServiceName", data.ServiceName);
    console.log("StartCharacter", data.StartCharacter);
    console.log("Token", data.Token);
    console.log("CurrentDatetime", data.CurrentDatetime);
    console.log("TokensAhead", data.TokensAhead);
    handlePrint && handlePrint(); //TESTING_ONLY 123

    // } else if (tokenRange === true) {

    //     hideAlert();
    //     console.log("printTokenNum", data);
    //     // context.router.push({
    //     //     pathname: '/service_requests',
    //     //     state: {token_details_list: data.TokenDetailsList}
    //     // });

    //     props.history.push({
    //         pathname:"/token_details",
    //         state:{
    //             services_screen_params: {
    //                 StartNum: data.Token.StartNum,
    //                 EndNum: data.Token.EndNum,
    //                 MainLocName: data.MainLocName,
    //                 MainLocId: data.MainLocId,
    //                 SubLocName: data.SubLocName,
    //                 SubLocId: data.SubLocId,
    //                 ServiceName: data.ServiceName,
    //                 ServiceId: data.ServiceId,
    //                 //token_details_list: data.TokenDetailsList
    //             }
    //         }
    //     });
    // }
  }

  const notifyMessage = (place, color, text) => {
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }

    var options = {};
    options = {
      place: place,
      message: (
        <div className="text-md-center">
          <div>
            <b>{text}</b>
          </div>
        </div>
      ),
      type: type,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 3,
    };

    if (notifyAlert !== null && notifyAlert.current != null) {
      // notificationAlert.notificationAlert(options);
      notifyAlert.current.notificationAlert(options);
    }
  };

  const warningWithConfirmMessage = (object) => {
    setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "0px" }} //marginTop: "-100px" }}
          title="Are you sure?"
          onConfirm={() => deleteItem(object)}
          onCancel={() => hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, delete it!"
          cancelBtnText="Cancel"
          showCancel
        ></ReactBSAlert>
      ),
    });
  };
  const successDelete = () => {
    setState({
      alert: (
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-10px" }}
          title="Deleted!"
          onConfirm={() => confirmDelete()}
          onCancel={() => confirmDelete()}
          confirmBtnBsStyle="info"
        ></ReactBSAlert>
      ),
    });
  };
  const confirmDelete = () => {
    setState({
      alert: null,
    });

    loadServicesBySub(state.selected_sub_location.value);
  };
  const hideAlert = () => {
    setState({
      alert: null,
      loading: false
    });
  };

  //   render () {
  const { modalClassic } = state;
  const servData = state.selected_service;
  //   console.log("servData", servData);
  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
        setState({ordering:true})

      // console.log('asdaswew')
      if (fromIndex === -1 || toIndex === -1) return;
      var data;
      if (state.data.length < 2) data = [...state.data, { order: 0 }];
      // if (state.groups.length < 2) data = [...state.groups, { order: 0 }];
      else data = [...state.data];
      // else data = [...state.groups];
      var service_groups_list = state.service_groups_list;

      const item = data.splice(fromIndex, 1)[0];
      data.splice(toIndex, 0, item);

      // setState({ loading: true });

      data[fromIndex].order = toIndex;
      // service_groups_list.map((e) => {
      //   if (e.service_group_id == data[fromIndex].id)
      //     e.service_group_list_order_number = toIndex;
      // });
      let start = fromIndex > toIndex ? toIndex : fromIndex;
      let end = fromIndex > toIndex ? fromIndex : toIndex;

      // for (let i = start + 1; i <= end; i++) {
      //   if (data[i] != undefined) {
      //     data[i].order = data[i].order + 1;
      //     service_groups_list.map((e) => {
      //       if (e.service_group_id == data[i].id) {
      //         e.service_group_list_order_number = i;
      //       }
      //     });
      //   }
      // }
      setState({ data: data, service_groups_list: service_groups_list });

      // Store to database //
      for (let j = start; j <= end; j++) {
        // setState({ loading: true });
        if (
          data[j] != undefined &&
          data[j].id != undefined &&
          data[j].id.length > 0
        ) {
          // Firebase.firestore()
          //   .collection("Service_Groups")
          //   .doc(data[j].id)
          //   .update({ List_Order: j })
          //   .then(async function () {
          //     await data[j].items.forEach((item,k)=>{
          //       Firebase.firestore()
          //       .collection("Services")
          //       .doc(item.id)
          //       .update({ List_Order: k+Number(data[j-1]?.items.length)||k })
          //       })
              setState({ loading: false });
          //   })
          //   .catch(function (err) {
          //     setState({ loading: false });
          //     notifyMessage("tc", 3, "Network error!");
          //     console.log("render NetworkError7==>", err);
          //   });
        } else {
          setState({ loading: false });
          if (data[j]?.items?.length == 1 && data[j].items[0].id.length > 0)
            // Firebase.firestore()
            //   .collection("Services")
            //   .doc(data[j].items[0].id)
            //   .update({ List_Order: j+Number(data[j-1]?.items.length)||j })
            //   .then(function () {
            //     setState({ loading: false });
            //   })
            //   .catch(function (err) {
                setState({ loading: false });
            //     notifyMessage("tc", 3, "Network error!");
            //     console.log("render NetworkError7==>", err);
            //   });
        }
      }
      // console.log(data)
      // setState({ groups: data, service_groups_list: service_groups_list });
    },
    nodeSelector: ".group",
    handleSelector: ".group-link",
  };
  const {
    LocationName,
    LocationAddress,
    ServiceName,
    StartCharacter,
    Token,
    CurrentDatetime,
    TokensAhead,
  } = state;

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  async function saveLayout(){
    setState({loading:true,ordering_updating:true})
    setTimeout(()=>{setState({ordering_updating:false})},2000)
    await state.data.forEach(async(g,g_k)=>{

      if(g.id.length>0)
        await Firebase.firestore()
        .collection("Service_Groups")
        .doc(g.id)
        .update({
          Updated_Date: Firebase.firestore.FieldValue.serverTimestamp(),
          List_Order: g_k
        }).then(() => {
          console.log(g.name,g_k,'update')
        })
      await g.items.forEach(async(s,s_k)=>{
        if(s.id.length>0)
          await Firebase.firestore()
          .collection("Services")
          .doc(s.id)
          .update({ List_Order: s_k+Number(state.data[g_k-1]?.items.length-1)+g_k||s_k+g_k }).then(()=>{
            console.log(s.service_name, s_k+Number(state.data[g_k-1]?.items.length-1)+g_k||s_k+g_k )
            if(s_k == g.items.length-1 && g_k == state.data.length-1)
              return setState({loading:false,ordering:false})
          })
      })
      
    })
  }

  // return (
  //   <div>
  //     <ComponentToPrint ref={componentRef} />
  //     <button onClick={handlePrint}>Print this out!</button>
  //   </div>
  // );

  return (
    <>
      <LoadingOverlay
        active={state.loading}
        spinner
        text="Loading"
        className="content"
      >
        <div className="rna-container">
          {" "}
          {/* z-Index css property set in index.html file */}
          <NotificationAlert ref={notifyAlert} />
          {state.alert}
        </div>

        {servData !== null && typeof servData !== "undefined" && (
          <Modal
            size="lg"
            backdrop={"static"}
            //dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            isOpen={state.modalClassic}
            toggle={toggleModalClassic}
          >
            {modalClassic && (
              <>
                <div className="modal-header justify-content-center">
                  <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModalClassic}
                  >
                    <i className="nc-icon nc-simple-remove" />
                  </button>
                  <span>
                    <h5>Selected Service: {servData.service_name}</h5>
                  </span>
                </div>
                <div className="modal-body">
                  <>
                    <div className="content">
                      <Form className="form-horizontal">
                        <div>
                          <CardTitle tag="h4">Details</CardTitle>
                        </div>
                        <div>
                          <Col md="12" lg="12">
                            <Table>
                              <thead>
                                <tr>
                                  <th>Service Name</th>
                                  <td>{servData.service_name}</td>
                                </tr>

                                <tr>
                                  <th>Start Number</th>
                                  <td>{servData.start_number}</td>
                                </tr>

                                <tr>
                                  <th>End Number</th>
                                  <td>{servData.end_number}</td>
                                </tr>

                                <tr>
                                  <th>Current Token</th>
                                  <td>{servData.last_called_number}</td>
                                </tr>

                                <tr>
                                  <th>Pending Tokens</th>
                                  <td>{servData.waiting_in_queue}</td>
                                </tr>

                                <tr>
                                  <th>Served Tokens</th>
                                  <td>{servData.served_tokens}</td>
                                </tr>

                                <tr>
                                  <th>Number Of Tokens</th>
                                  <td>{servData.number_of_tokens}</td>
                                </tr>

                                <tr>
                                  <th>Last Generated Token</th>
                                  <td>{servData.last_generated_token}</td>
                                </tr>

                                <tr>
                                  <th>Last Generated Token At</th>
                                  <td>
                                    {servData.last_generated_token_date_time}
                                  </td>
                                </tr>

                                <tr>
                                  <th>Last Called Counter</th>
                                  <td>{servData.last_called_counter}</td>
                                </tr>

                                <tr>
                                  <th>Number Of Tokens Remaining</th>
                                  <td>{servData.number_of_tokens_remaining}</td>
                                </tr>
                              </thead>
                            </Table>
                          </Col>
                        </div>

                        <br />
                        <div>
                          <CardTitle tag="h4">
                            Enter number of tokens to generate
                          </CardTitle>
                        </div>

                        <div style={{ height: "10px" }}></div>
                        <div style={{ textAlign: "left" }}>
                          <Col md="8">
                            <FormGroup>
                              <Row>
                                <Col md="4" xs="4">
                                  <Input
                                    value={state.num_of_toks_to_gen}
                                    type={"text"}
                                    maxLength={getTokenRange(servData)}
                                    minLength={state.num_of_toks_to_gen_min_val}
                                    invalid={state.invalid_num_of_toks_to_gen}
                                    onChange={(e) => {
                                      handleChange(
                                        e,
                                        "num_of_toks_to_gen_field",
                                        "num_of_toks_to_gen_field"
                                      );
                                    }}
                                  />
                                </Col>
                                <Col md="8" xs="8" className="padding-left-0">
                                  <Button
                                    className="margin-top-0"
                                    color="success"
                                    onClick={(e) =>
                                      increaseNumberOfTokensToGenerate()
                                    }
                                  >
                                    <i className="fa fa-plus" />
                                  </Button>
                                  <Button
                                    className="margin-top-0"
                                    color="success"
                                    onClick={(e) =>
                                      decreaseNumberOfTokensToGenerate()
                                    }
                                  >
                                    <i className="fa fa-minus" />
                                  </Button>
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                        </div>

                        <Row>
                          <Label md="4"></Label>
                          <Col md="8">
                            <FormGroup>
                              <div>
                                <button
                                  className="btn btn-info btn-block"
                                  variant="primary"
                                  disabled={state.btn_disabled_gen_tok_range}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleGenerateNewTokenNumRange(servData);
                                  }}
                                >
                                  {state.btn_disabled_gen_tok_range == true
                                    ? "Getting ready to generate token range. Please wait."
                                    : "Generate Token Range"}
                                </button>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </>
                </div>
              </>
            )}
          </Modal>
        )}
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Services</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col lg="2">
                    <div>
                      <Button
                        className="btn btn-success"
                        onClick={(e) => {
                          e.preventDefault();
                          handleAdd();
                        }}
                        block
                      >
                        Add New
                      </Button>
                    </div>
                     {state.ordering?<div className='text-center'>
                      <Button className='btn btn-primary' onClick={saveLayout} block>Save Layout</Button>
                    </div>:''}
                  </Col>
                  <Col lg="3">
                    <h5>
                      Select Main
                      Location&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </h5>
                    <Select
                      className="react-select info select-location"
                      classNamePrefix="react-select"
                      placeholder="Select Main Location"
                      name="selectMainLocation"
                      value={state.selected_main_location}
                      onChange={(e) => onChangeMain(e)}
                      options={state.main_location_list}
                    />
                  </Col>
                  <Col lg="3">
                    <h5>
                      Select Sub
                      Location&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </h5>
                    <Select
                      className="react-select info select-location"
                      classNamePrefix="react-select"
                      placeholder="Select Sub Location"
                      name="selectSubLocation"
                      value={state.selected_sub_location}
                      onChange={(e) => onChangeSub(e)}
                      options={state.sub_location_list}
                    />
                  </Col>
                 {/* <Col lg="2">
                    <div>
                      <Button
                        className="btn btn-success"
                        onClick={(e) => {
                          e.preventDefault();
                          // handleAdd();
                          setState({advancedToggle:true})
                        }}
                        block
                      >
                        Advanced Options
                      </Button>
                    </div>
                     {state.advancedToggle?
                     <Duplicate setSub={(e)=>{setState({ selected_sub_location: e });loadServicesBySub(e.value)}}  groups={state.data} subs={state.sub_location_list} hide={(e)=>setState({advancedToggle:e})}/>:''}
                  </Col> */}
                  {/* <Col lg="3">
                    <h5>Select Service Group(s)</h5>
                    <Select
                      placeholder="Select Service Group"
                      closeMenuOnSelect={true}
                      value={state.selected_service_groups}
                      isMulti
                      options={state.service_groups_list}
                      onChange={handleChangeServGroups}
                    />
                  </Col> */}
                </Row>
                <Row className="top-margin-10" />
                <div className="simple-inner">
                  <ReactDragListView {...dragProps}>
                    <ul style={{ listStyleType: "none" }}>{getServices()}</ul>
                  </ReactDragListView>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <div
            style={{
              display: "none",
              position: "absolute",
              left: 0,
              top: 0,
            }}
          >
            <div>
              <ComponentToPrint
                ref={componentRef}
                LocationName={LocationName}
                LocationAddress={LocationAddress}
                ServiceName={ServiceName}
                StartCharacter={StartCharacter}
                Token={Token}
                TokenWithStartChar={
                  (StartCharacter && Token) != undefined
                    ? StartCharacter.toString() + Token.toString()
                    : Token
                }
                CurrentDatetime={CurrentDatetime}
                TokensAhead={TokensAhead}
              />
              <button onClick={handlePrint}>Print this out!</button>
            </div>
          </div>
        </Row>
      </LoadingOverlay>
    </>
  );
  //   }
}

export default Services;
