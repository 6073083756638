import React from "react";
import NotificationAlert from "react-notification-alert";
import Firebase from "firebase";
import PasswordHash from 'password-hash';
import LoadingOverlay from "react-loading-overlay";
import CopyToClipboard from "react-copy-to-clipboard";
import Select from "react-select";
import Switch from "react-bootstrap-switch";
import PictureUpload from "../../../components/CustomUpload/PictureUpload.jsx";

import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardFooter,
    Button,
    FormGroup,
    InputGroup,
    Row,
    Col,
    Input,
    InputGroupAddon,
    InputGroupText,
    Form,
    Collapse,
    Label
} from "reactstrap";
import config from "../../../config";

const uuidv1 = require('uuid/v1');
const { getNameList } = require('country-list');
// const publicIp = require('public-ip');

class SupportCenterLoginAsSpecificUser extends React.Component {
    constructor(props) {
        super(props);
        if (!Firebase.apps.length) {
            Firebase.initializeApp(config);
        }

        this.state = {
            loading: false,
            customer_id: '',
            role: '',
            current_time: '',
            locations: [],
            accessible_locs: [],

            customer_list: [],
            selected_customer: [],
            selected_customer_id: '',
            user_list: [],
            selected_user: [],
            selected_user_email: '',
            selected_user_role: '',

            openedCollapses: [],

            customerState: '',
            userState: '',
            support_admin_role: false,
        };

        this.getAccessibleLocs = this.getAccessibleLocs.bind(this);
        this.loadLocations = this.loadLocations.bind(this);
    }

    componentDidMount() {
        let _this = this;
        let custID = "000000000001";
        let userEmailID =  JSON.parse(localStorage.getItem('auth_info')).email;
        var auth = JSON.parse(localStorage.getItem('auth_info'));
        var role = auth.role;
        var supportAdminRole = auth.support_admin_role;
        _this.setState({
            role: role,
            support_admin_role: supportAdminRole
        });
        console.log("role", role, "supportAdminRole", supportAdminRole);

        Firebase.firestore().collection('Web_App_Users').doc(userEmailID).get().then(function (userData) {
            if (userData.exists) {
                if ( userData.data().Support_Admin_Role != undefined && userData.data().Support_Admin_Role == true ) {
                    _this.setState({support_admin_role: true});
                }
            }
        }).then(() => {
            if(role == "Support_Admin" || supportAdminRole == true){
                this.loadCustomers();
            }
        }).catch(function (err) {
            _this.setState({loading: false});
            _this.notifyMessage("tc", 3, "Network Error.");
            console.log("componentDidMount NetworkError1==>", err);
        });
       
        // _this.getCurrentTime();
    }
    loadLocations(custID, userEmailID, userRole) {
        var _this = this;
        var customer_id = custID;
        var location_list = [];
        Firebase.firestore().collection('Main_Locations').where('Customer_ID', '==', customer_id).get().then(function (locations) {
            locations.docs.forEach(function (location) {
                var one = {
                    name: location.data().Name,
                    sub_locations: []
                };

                Firebase.firestore().collection('Sub_Locations').where('Customer_ID', '==', customer_id).where('Main_Location_ID', '==', location.id).get().then(function (sub_locations) {
                    sub_locations.docs.forEach(function (sub_location) {
                        var one_sub = {
                            name: sub_location.data().Name,
                            id: sub_location.id
                        };
                        one.sub_locations.push(one_sub);
                    });
                    location_list.push(one);
                    _this.setState({locations: location_list});
                }).catch(function (err) {
                    console.log(err);
                });

            });
        }).catch(function (err) {
            console.log(err);
        });

        // console.log("userEmailID", userEmailID);
        _this.setState({ accessible_locs: _this.getAccessibleLocsOfSelectedUser(userEmailID) });
        _this.setState({ selected_user_role: userRole });

    }
    loadCustomers() {
        let _this = this;
        var customers = [];
        _this.setState({loading: true});
        let customer_id = JSON.parse(localStorage.getItem('auth_info')).customer_id;
        let role = JSON.parse(localStorage.getItem('auth_info')).role;

        if (role=='Support_Admin' || _this.state.support_admin_role == true) {
            Firebase.firestore().collection('Customers').get().then(function (own_customer_info) {
                own_customer_info.docs.forEach(e=>{
                    var one = {
                        value: e.id,
                        label: e.data().Name + " | " + e.data().Email + " | " + e.id
                    };
                    customers.push(one);
                }) 
                _this.setState({customer_list: customers});
            }).catch(function (err) {
                _this.setState({loading: false});
                _this.notifyMessage("tc", 3, "Network Error.");
                console.log("loadCustomers NetworkError1==>", err);
            });
        }
        _this.setState({loading: false});
    }
    loadUsers(custID) {
        let _this = this;
        var users = [];
        _this.setState({loading: true});
        let selectedCustID = custID;
        let role = JSON.parse(localStorage.getItem('auth_info')).role;

        if (role=='Support_Admin' || _this.state.support_admin_role == true) {
            Firebase.firestore().collection('Web_App_Users')
            .where('Customer_ID','==',selectedCustID)
            .get().then(function (userData) {
                if (userData.docs.length < 1) {
                    _this.notifyMessage("tc", 3, "There are no users attached to this Customer ID! Please contact the Site Admin.");
                    _this.setState({ selected_user: [] });
                    _this.setState({ userState: "has-danger" });
                }
                else {
                    userData.docs.forEach(e=>{
                        var one = {
                            value: e.id,
                            label: e.data().Name + " (" + e.id + ")",
                            accessible_locs: e.data().Accessible_Locations,
                            role: e.data().Role,
                            customer_id: e.data().Customer_ID
                        };
                        users.push(one);
                    })
                    _this.setState({user_list: users});
                    // console.log("users[0]", users[0]);
                    // console.log("users[0].value", users[0].value);
                    // console.log("users[0].role", users[0].role);
                    _this.onChangeUser(users[0]);

                    _this.setState({ selected_user: users[0] });
                    _this.setState({ selected_user_email: users[0].value });
                    _this.setState({ selected_user_role: users[0].role });
                }
                // console.log("users", users);
            }).catch(function (err) {
                _this.setState({loading: false});
                _this.notifyMessage("tc", 3, "Network Error.");
                console.log("loadUsers NetworkError2==>", err);
            });
        }
        _this.setState({loading: false});
    }
    getAccessibleLocsOfSelectedUser(userEmailID) {
        let _this = this;
        _this.setState({loading: true});
        let accessibleLocs = [];
        let role = JSON.parse(localStorage.getItem('auth_info')).role;

        // if (role=='Support_Admin' || _this.state.support_admin_role == true) {
            // console.log("userEmailID", userEmailID);
            Firebase.firestore().collection('Web_App_Users').doc(userEmailID).get().then(function (app_info) {
                if (app_info.exists) {
                    accessibleLocs = app_info.data().Accessible_Locations;
                    _this.setState({accessible_locs: accessibleLocs});
                    // console.log("accessibleLocs", accessibleLocs);
                    _this.setState({loading: false});
                    return accessibleLocs;
                }
         
            }).catch(function (error) {
                _this.setState({loading: false});
                _this.notifyMessage("tc", 3, "NetWork Error.");
                console.log("getAccessibleLocsOfSelectedUser NetworkError3==>", error);
            });
        // }
    }
    getCurrentTime() {
        let _this = this;
        let now = new Date();
        _this.setState({current_time: now.toLocaleString()});
        window.setTimeout(function() { _this.getCurrentTime() }, 500);
    }
    handleLoginAsSpecificUserSave() {
        let _this = this;
        let selectedCustID = _this.state.selected_customer_id;
        let selectedUserEmailID = _this.state.selected_user_email;
        let selectedUserAccessibleLocs = _this.state.accessible_locs;
        let selectedUserRole = _this.state.selected_user_role;
        let auth_info_obj = JSON.parse(localStorage.getItem('auth_info')),
            currUserEmailID = auth_info_obj.email;  
       
        if (selectedCustID === "" || selectedCustID === undefined || selectedCustID === null)
        { _this.setState({ customerState: "has-danger" }) }
        if (selectedUserEmailID === "" || selectedUserEmailID === undefined || selectedUserEmailID === null)
        { _this.setState({ userState: "has-danger" }) }
    
        if (_this.state.customerState === "has-success" && _this.state.userState === "has-success" && (currUserEmailID !== undefined || currUserEmailID !== null)) {
            // console.log("customerState userState success");
            var save_data = {
                Login_As_Specific_User_Email_ID: selectedUserEmailID,
                Login_As_Specific_User_Changed_Date: new Date(),
                Login_By_Role: "",
                Login_By_Role_Changed_Date: "",
                Accessible_Locations: selectedUserAccessibleLocs,
                Customer_ID: selectedCustID,
                Role: selectedUserRole,
            };
            console.log("save_data", save_data, "currUserEmailID", currUserEmailID);
            Firebase.firestore().collection('Web_App_Users').doc(currUserEmailID).update(save_data)
                .then(function() {
                    console.log("Successfully saved 'login as specific user' profile for "+selectedUserEmailID+" to currently logged in user: "+currUserEmailID);
                    //_this.notifyMessage("tc", 2, "Successfully saved. You must logout and login to this account to emulate selected user profile!");
                    // window.setTimeout(function() { _this.props.history.push("/logout") }, 4000);
                    
                    let auth_info = {
                        ...auth_info_obj,
                        customer_id: selectedCustID,
                        role: selectedUserRole,
                        message:`Successfully switched account settings to user: ${_this.state.selected_user_email} (Customer ID: ${selectedCustID})`,
                        messageShowOnce:true
                      };
                      localStorage.setItem(
                        "auth_info",
                        JSON.stringify(auth_info)
                      );
                      window.setTimeout(function () {
                        document.location.reload();
                      }, 4000);

                })
                .catch(function(error) {
                    console.log("Error saving document: ", error);
                    _this.notifyMessage("tc", 3, "Error saving document!");
                });
        } else {
            _this.notifyMessage("tc", 3, "Sorry, you must provide a valid customer and user to proceed!");
        }
    }
    getAccessibleLocs() {
        var _this = this;
        return this.state.locations.map((prop, toggle_key) => {
            return (
                <div key={toggle_key}>
                    <a href="#" className="custom-nav"><i className={this.state.openedCollapses.includes(toggle_key)?"nc-icon nc-minimal-up":"nc-icon nc-minimal-down"}/></a>
                    <a
                        aria-expanded={this.state.openedCollapses.includes(
                            toggle_key
                        )}
                        href="#"
                        data-parent="#accordion"
                        data-toggle="collapse"
                        onClick={() => this.collapsesToggle(toggle_key)}
                    >
                        {prop.name}
                    </a>
                    <Collapse
                        role="tabpanel"
                        isOpen={this.state.openedCollapses.includes(
                            toggle_key
                        )}
                    >
                        {prop.sub_locations.map((prop, key) => {
                            return (
                                <Label key={key} className="custom-nav-left">
                                    <Input
                                        disabled
                                        name={prop.id}
                                        type="checkbox"
                                        checked={
                                            _this.state.accessible_locs.filter(item => item === prop.id).length > 0
                                        }
                                        /* onChange={e => this.handleChange(e, prop.id, "location")} */
                                    />
                                    <span className="form-check-sign" />
                                    {prop.name}
                                </Label>
                            );
                        })}
                    </Collapse>
                </div>
            );
        });
    }
    onChangeCustomer(e) {
        let _this = this;
        _this.setState({ selected_customer: e });
        _this.setState({ selected_customer_id: e.value });
        // console.log(e.value)
        // _this.loadCustomers();
        _this.loadUsers(e.value);

        if (this.verifyLength(e.value, 1)) {
            this.setState({ ["customerState"]: "has-success" });
        } else {
            this.setState({ ["customerState"]: "has-danger" });
        }
    }
    onChangeUser(e) {
        let _this = this;
        let custID = e.customer_id;
        let userEmailID = e.value;
        let userRole = e.role;

        _this.setState({ selected_user: e });
        _this.setState({ selected_user_email: userEmailID });
        _this.setState({ selected_user_role: userRole });
        _this.loadLocations(custID, userEmailID, userRole);

        console.log("e", e);
        // console.log("e.customer_id", e.customer_id);
        // console.log("e.value", e.value);

        if (this.verifyLength(e.value, 1)) {
            this.setState({ ["userState"]: "has-success" });
        } else {
            this.setState({ ["userState"]: "has-danger" });
        }
    }
    collapsesToggle = collapse => {
        let openedCollapses = this.state.openedCollapses;
        if (openedCollapses.includes(collapse)) {
            this.setState({
                openedCollapses: openedCollapses.filter(item => item !== collapse)
            });
        } else {
            openedCollapses.push(collapse);
            this.setState({
                openedCollapses: openedCollapses
            });
        }
    };
    handleChange = (event, stateName, type, stateNameEqualTo) => {
        switch(type) {
            case "length":
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + "State"]: "has-success" });
                } else {
                    this.setState({ [stateName + "State"]: "has-danger" });
                }

                this.setState({ [stateName]: event.target.value });
                break;
            case 'pin':
                if (this.verifyLength(event.target.value, stateNameEqualTo) && this.verifyNumber(event.target.value)) {
                    this.setState({ [stateName + "State"]: "has-success" });
                } else {
                    this.setState({ [stateName + "State"]: "has-danger" });
                }

                if (this.verifyNumber(event.target.value) || event.target.value.length===0) {
                    this.setState({[stateName]: event.target.value});
                }

                break;
            case "api_key":
                if (this.verifyLength(event.target.value, 1)) {
                    this.setState({ [stateName + "State"]: "has-success" });
                    const {name, value} = event.target;
                    this.setState({[name]: value});
                } else {
                    this.setState({ [stateName + "State"]: "has-danger" });
                }
                break;
            case "location" :
                var accessible_locs = this.state.accessible_locs;
                if (event.target.checked === true) {
                    accessible_locs.push(stateName);
                    this.setState({accessible_locs: accessible_locs});
                } else {
                    var index = accessible_locs.indexOf(stateName);
                    if (index !== -1) accessible_locs.splice(index, 1);
                    this.setState({accessible_locs: accessible_locs});
                }
                break;  
            default:
                break;
        }
    };
    notifyMessage = (place, color, text) => {
        var type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }

        var options = {};
        options = {
            place: place,
            message: (
                <div className="text-md-center">
                    <div>
                        <b>{text}</b>
                    </div>
                </div>
            ),
            type: type,
            icon: "now-ui-icons ui-1_bell-53",
            autoDismiss: 3
        };
        if (options !== null) { this.notificationAlert.notificationAlert(options) }
    };
    verifyLength = (value, length) => {
        return value.length >= length;
    };
    verifyNumber = value => {
        var numberRex = new RegExp("^[0-9]+$");
        if (numberRex.test(value)) {
            return true;
        }
        return false;
    };
    render() {
        let role = this.state.role;
        let supportAdminRole = this.state.support_admin_role;
        console.log("role", role, "supportAdminRole", supportAdminRole);

        var ok = false
        var accessLocsExist = false
        if(this.state.role == "Support_Admin" || supportAdminRole == true) {
            ok = true;
        }
        if(this.state.accessible_locs!=undefined && this.state.accessible_locs.length >= 1) {
            accessLocsExist = true;
        }
        let {
            customerState,
            userState,
        } = this.state;
        return (
            <>
                <LoadingOverlay
                    active={this.state.loading}
                    spinner
                    text='Loading'
                    className='content'
                >
                    <NotificationAlert ref={(ref) => this.notificationAlert = ref} />
                    <Row>
                        <Col md="12">
                            {ok?<Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Support Central - Switch account to Specific User</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Col className="ml-auto mr-auto" xl="8" lg="10" md="12">
                                        <Row>
                                            <Col md="3">
                                                <Button
                                                    color="success"
                                                    onClick={e => this.handleLoginAsSpecificUserSave()}
                                                    block
                                                >
                                                    Save
                                                </Button>
                                            </Col>
                                            <Col md="3">
                                                <Button
                                                    color="youtube"
                                                    onClick={e => this.props.history.push("/support_center")}
                                                    block
                                                >
                                                    Close
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row className="top-margin-10">
                                        </Row>
                                        <Row>
                                            <Col xl="8" lg="12" md="12">
                                                <Form className="form-horizontal">
                                                    <Row>
                                                        <Label md="4">Customer</Label>
                                                        <Col md="8">
                                                            <FormGroup className={`has-label ${customerState}`}>
                                                                <Select
                                                                    className="react-select info select-location"
                                                                    classNamePrefix="react-select"
                                                                    placeholder="Select Customer"
                                                                    name="selected_customer"
                                                                    value={this.state.selected_customer}
                                                                    onChange={e =>
                                                                        this.onChangeCustomer(e)
                                                                    }
                                                                    options={this.state.customer_list}
                                                                />
                                                                {this.state.customerState === "has-danger" ? (
                                                                    <label className="error">
                                                                        Please select a valid customer.
                                                                    </label>
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label md="4">User</Label>
                                                        <Col md="8">
                                                            <FormGroup className={`has-label ${userState}`}>
                                                                <Select
                                                                    className="react-select info select-location"
                                                                    classNamePrefix="react-select"
                                                                    placeholder="Select User"
                                                                    name="selected_user"
                                                                    value={this.state.selected_user}
                                                                    onChange={e =>
                                                                        this.onChangeUser(e)
                                                                    }
                                                                    options={this.state.user_list}
                                                                />
                                                                {this.state.userState === "has-danger" ? (
                                                                    <label className="error">
                                                                        Please select a valid user.
                                                                    </label>
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    {accessLocsExist?<Row>
                                                        <Label md="4">Accessible Locations</Label>
                                                        <Col md="8">
                                                            <div className="blockquote">
                                                                <div
                                                                    aria-multiselectable={true}
                                                                    className="card-collapse col-md-12"
                                                                    id="accordion"
                                                                    role="tablist"
                                                                >
                                                                    {this.getAccessibleLocs()}
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row> : null}
                                                </Form>
                                            </Col>
                                        </Row>
                                    </Col>
                                </CardBody>
                            </Card>:null}
                        </Col>
                    </Row>
                </LoadingOverlay>
            </>
        );
    }
}

export default SupportCenterLoginAsSpecificUser;