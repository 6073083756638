import React from "react";
import NotificationAlert from "react-notification-alert";
import Firebase from "firebase";
import LoadingOverlay from "react-loading-overlay";
import Select from "react-select";
import ReactBSAlert from "react-bootstrap-sweetalert";
import printerImage from "../../../assets/img/printer2.png";
import config from "../../../config";

import {
  Col,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Button,
  Label,
  Form,
  FormGroup,
  Input,
} from "reactstrap";

class Kiosks extends React.Component {
  constructor(props) {
    super(props);
    if (!Firebase.apps.length) {
      Firebase.initializeApp(config);
    }

    this.state = {
      loading: false,
      customer_id: "",
      role: "",

      dispenser_list: [],
      main_location_list: [],
      sub_location_list: [],
      selected_main_location: null,
      selected_sub_location: null,
      alert: null,
      kioskModels: [],
      kioskModel: {},
    };
  }
  componentDidMount() {
    this.loadMaxSize();
  }
  loadMaxSize() {
    let _this = this;
    _this.setState({ loading: true });
    // ---------- Load Max Height and Width ---------- //
    Firebase.firestore()
      .collection("System_Config")
      .doc("Dispensor_Settings")
      .get()
      .then(function (dispenser_setting) {
        if (dispenser_setting.exists) {
          _this.setState({
            max_height: dispenser_setting.data().Dispensor_Max_Height,
          });
          _this.setState({
            max_width: dispenser_setting.data().Dispensor_Max_Width,
          });
          _this.setState({
            kioskModels: dispenser_setting.data().Kiosk_Models,
          });
          _this.loadMainLocations();
        } else {
          _this.setState({ loading: false });
          _this.notifyMessage("tc", 3, "Network error!");
          console.log("loadMaxSize NetworkError1");
        }
      })
      .catch(function (err) {
        _this.setState({ loading: false });
        _this.notifyMessage("tc", 3, "Network error!");
        console.log("loadMaxSize NetworkError2==>", err);
      });
  }
  loadMainLocations() {
    let _this = this;
    let main_locations = [];
    let customer_id = JSON.parse(localStorage.getItem("auth_info")).customer_id;
    let email = JSON.parse(localStorage.getItem("auth_info")).email;
    _this.setState({ customer_id: customer_id });
    let role = JSON.parse(localStorage.getItem("auth_info")).role;
    _this.setState({ role: role });

    // ---------- Load Location List ---------- //
    Firebase.firestore()
      .collection("Main_Locations")
      .where("Customer_ID", "==", customer_id)
      .get()
      .then(function (response) {
        response.docs.forEach(function (doc) {
          main_locations.push({ label: doc.data().Name, value: doc.id });
        });

        _this.setState({ main_location_list: main_locations });
        if (main_locations.length > 0) {
          _this.setState({ selected_main_location: main_locations[0] });
          _this.loadSubLocationByMain(main_locations[0].value);
        } else {
          _this.setState({ loading: false });
        }
      })
      .catch(function (err) {
        _this.setState({ loading: false });
        _this.notifyMessage("tc", 3, "Network error!");
        console.log("loadMainLocations NetworkError3==>", err);
      });
  }
  loadSubLocationByMain(main_id) {
    let _this = this;
    _this.setState({ loading: true });
    _this.setState({ selected_sub_location: null });
    let sub_locations = [];
    let customer_id = JSON.parse(localStorage.getItem("auth_info")).customer_id;
    Firebase.firestore()
      .collection("Sub_Locations")
      .where("Customer_ID", "==", customer_id)
      .where("Main_Location_ID", "==", main_id)
      .get()
      .then(function (response) {
        response.docs.forEach(function (doc) {
          sub_locations.push({
            label: doc.data().Name,
            value: doc.id,
            image_url: doc.data().Icon,
            address: doc.data().Address,
          });
        });

        _this.setState({ sub_location_list: sub_locations });
        if (sub_locations.length > 0) {
          let first_one = sub_locations[0];
          _this.setState({ selected_sub_location: first_one });
          _this.setState({ location_image_url: first_one.image_url });
          _this.setState({ location_name: first_one.label });
          _this.setState({ location_address: first_one.address });
          _this.loadDispenserListBySub(sub_locations[0].value);
        } else {
          _this.setState({ loading: false });
          _this.notifyMessage(
            "tc",
            3,
            "This main location does not have any sub locations."
          );
        }
      })
      .catch(function (err) {
        _this.setState({ loading: false });
        _this.notifyMessage("tc", 3, "Network error!");
        console.log("loadSubLocationByMain NetworkError4==>", err);
      });
  }
  loadDispenserListBySub(sub_id) {
    let _this = this;
    _this.setState({ loading: true });
    let dispensers = [];
    let customer_id = JSON.parse(localStorage.getItem("auth_info")).customer_id;
    Firebase.firestore()
      .collection("Kiosk")
      .where("Customer_ID", "==", customer_id)
      .where("Sub_Location_ID", "==", sub_id)
      .get()
      .then(function (response) {
        response.docs.forEach(function (dispenser) {
          let one = {
            id: dispenser.id,
            name: dispenser.data().Name,
            package_id: dispenser.data().Package_ID,
            created_date: dispenser.data().Created_Date,
            button_layout_format: dispenser.data().Button_Layout_Format,
            footer_text: dispenser.data().Footer_Text,
            enable_print_logo: dispenser.data().Enable_Print_Logo,
            print_logo_image_url: dispenser.data().Print_Logo_Image_Url,
            print_type: dispenser.data().Print_Type,
            show_location_icon: dispenser.data().Show_Location_Icon,
            height: dispenser.data().Layout_Height,
            width: dispenser.data().Layout_Width,
            pin: dispenser.data().Pin,
          };

          dispensers.push(one);
        });

        let sorted = dispensers.sort(function (a, b) {
          return a.created_date < b.created_date ? -1 : 1;
        });

        _this.setState({ dispenser_list: sorted });
        _this.setState({ loading: false });
      })
      .catch(function (err) {
        _this.setState({ loading: false });
        _this.notifyMessage("tc", 3, "Network error!");
        console.log("loadDispenserListBySub NetworkError5==>", err);
      });
  }
  handleAdd() {
    this.props.history.push("/kiosk/add");
  }
  // handleRun(id) {
  //     localStorage.setItem("running_dispenser", id);
  //     this.props.history.push('/kiosk/run/' + id);
  // }
  handleBasicEdit(id) {
    this.props.history.push("/kiosk/edit/" + id);
  }
  handlePrintEdit(id) {
    this.props.history.push("/kioskPrint/edit/" + id);
  }
  // handleBasicEdit(id) {
  //     this.props.history.push('/dispenser/basicEdit/' + id);
  // }
  // handleAdvanceEdit(id) {
  //     this.props.history.push('/dispenser/advanceEdit/' + id);
  // }
  getDispensers() {
    let _this = this;
    return this.state.dispenser_list.map(function (prop, key) {
      return (
        <>
          {" "}
          <Row key={key} className="col-12">
            <hr />
            <Col md="7" className="text-center">
              {/* <Row>
                        <Col md="8" sm="12">
                            <button className="btn btn-block btn-info"
                            //  onClick={e => _this.handleRun(prop.id)}
                             >
                                DETECT
                            </button>
                        </Col>
                    </Row> */}

              <Row>
                <Col md="8" sm="12">
                  <Button
                    color="warning"
                    onClick={(e) => _this.handleBasicEdit(prop.id)}
                    block
                  >
                    EDIT KIOSK
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col md="8" sm="12">
                  <Button
                    color="danger"
                    onClick={(e) => _this.handlePrintEdit(prop.id)}
                    block
                  >
                    EDIT PRINT LABEL
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col
                  md="8"
                  sm="12"
                  hidden={
                    !(
                      _this.state.role === "Site_Admin" ||
                      _this.state.role === "System_Admin"
                    )
                  }
                >
                  <Button
                    color="youtube"
                    onClick={(e) => _this.warningWithConfirmMessage(prop)}
                    block
                  >
                    Delete
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col md="5" className="d-flex flex-column justify-content-center">
              <Row className=" text-center d-flex flex-column justify-content-center">
                <Col md="7" sm="12" className="text-center mx-auto">
                  <img src={printerImage} alt="..." />
                  <h6>{prop.name}</h6>
                </Col>
              </Row>
            </Col>
          </Row>
          <hr />
        </>
      );
    });
  }
  onChangeMain(e) {
    this.setState({ selected_main_location: e });
    this.loadSubLocationByMain(e.value);
  }
  onChangeSub(e) {
    this.setState({ selected_sub_location: e });
    this.setState({ location_image_url: e.image_url });
    this.setState({ location_name: e.label });
    this.setState({ location_address: e.address });
    this.loadDispenserListBySub(e.value);
  }
  deleteItem(object) {
    var _this = this;
    _this.setState({ loading: true });
    Firebase.firestore()
      .collection("Kiosk")
      .doc(object.id)
      .delete()
      .then(function (res) {
        var storageRef = Firebase.storage().ref();
        // var kioskFolder = "Media_Gallery/Kiosk_Media/" + object.id;
        // Firebase.storage()
        //   .bucket()
        //   .deleteFiles({
        //     prefix: `${kioskFolder}/`,
        //   })
        //   .then(() => {
        //     _this.setState({ loading: false });
        //     _this.successDelete();
        //   });
        _this.setState({ loading: false });
        _this.successDelete();

        var image_name1 =
          "Media_Gallery/Kiosk_Media/" + object.id + "/headerLogo";
        var image_name2 =
          "Media_Gallery/Kiosk_Media/" + object.id + "/background";
        try {
          try {
            var customerRef1 = storageRef.child(image_name1);
            customerRef1.delete();
          } catch (e) {
            console.log(e);
          }
          try {
            var customerRef2 = storageRef.child(image_name2);
            customerRef2.delete();
          } catch (e) {
            console.log(e);

          }
        } catch (e) {
          console.log(e);
          _this.setState({ loading: false });

        }

        var image_name = "kiosk_print_logo_" + object.id;
        if (object.print_logo_image_url !== "") {
          var customerRef = storageRef.child(image_name);
          customerRef
            .delete()
            .then(function () {
            //   _this.setState({ loading: false });
            //   _this.successDelete();
            })
            .catch(function (err) {
              _this.setState({ loading: false });
            //   _this.notifyMessage("tc", 3, "Network error!");
              console.log("deleteItem NetworkError6==>", err);
            });
        } else {
          _this.setState({ loading: false });
          _this.successDelete();
        }
      })
      .catch(function (err) {
        _this.setState({ loading: false });
        _this.notifyMessage("tc", 3, "Network Error.");
        console.log("deleteItem NetworkError7==>", err);
      });
  }
  warningWithConfirmMessage = (object) => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          //   style={{ display: "block", marginTop: "-100px" }}
          title="Are you sure?"
          onConfirm={() => this.deleteItem(object)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, delete it!"
          cancelBtnText="Cancel"
          showCancel
        ></ReactBSAlert>
      ),
    });
  };
  successDelete = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          success
        //   style={{ display: "block", marginTop: "-100px" }}
          title="Deleted!"
          onConfirm={() => this.confirmDelete()}
          onCancel={() => this.confirmDelete()}
          confirmBtnBsStyle="info"
        ></ReactBSAlert>
      ),
    });
  };
  confirmDelete = () => {
    this.setState({
      alert: null,
    });

    this.loadDispenserListBySub(this.state.selected_sub_location.value);
  };
  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };
  notifyMessage = (place, color, text) => {
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }

    var options = {};
    options = {
      place: place,
      message: (
        <div className="text-md-center">
          <div>
            <b>{text}</b>
          </div>
        </div>
      ),
      type: type,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 3,
    };
    if (options !== null&&this.notificationAlert!=null) {
      this.notificationAlert.notificationAlert(options);
    }
  };
  render() {
    var ok = true;
    if (
      this.state.role == "Counter_Display_User" ||
      this.state.role == "Main_Display_User" ||
      this.state.role == "Location_Supervisor" ||
      this.state.role == "Counter_User" ||
      this.state.role == "Counter_Super"
    )
      ok = false;
    return (
      <>
        <LoadingOverlay
          active={this.state.loading}
          spinner
          text="Loading"
          className="content dispenser"
        >
          <NotificationAlert ref={(ref) => (this.notificationAlert = ref)} />
          {this.state.alert}
          {this.state.kioskModel.value == "other" && (
            <ReactBSAlert
              title="Add Kiosk Model Info"
              style={{ transform: "scale(1.3)" }}
              className="row"
              onConfirm={() => {
                this.setState({ kioskAddErr: "" });
                let newModel = {
                  ...this.state.kioskModel,
                  label: this.state.kioskModel.name,
                };
                if (
                  (this.state.kioskModel.wifi ||
                    this.state.kioskModel.bluetooth) &&
                  this.state.kioskModel.name?.length > 0
                ) {
                  this.setState({
                    kioskModel: newModel,
                    kioskAddErr: "loading",
                  });
                  newModel.value = this.state.kioskModel.name;
                  delete newModel["name"];

                  Firebase.firestore()
                    .collection("System_Config")
                    .doc("Dispensor_Settings")
                    .update({
                      Kiosk_Models: [...this.state.kioskModels, newModel],
                    })
                    .then(() => {
                      this.setState({
                        kioskModel: newModel,
                        kioskModels: [newModel, ...this.state.kioskModels],
                        kioskAddErr: "",
                      });
                    });
                } else
                  this.setState({
                    kioskAddErr: "Please Complete the required fields.",
                  });
              }}
              onCancel={() => {
                this.setState({ kioskModel: { label: "", value: "" } });
              }}
              confirmBtnBsStyle="info"
              cancelBtnBsStyle="danger"
              confirmBtnText="Add"
              cancelBtnText="Cancel"
              showCancel
              showConfirm={this.state.kioskAddErr != "loading"}
            >
              <Col>
                <Row className="my-3">
                  {this.state.kioskAddErr != "loading" && (
                    <Label className="text-danger m-auto">
                      {this.state.kioskAddErr}
                    </Label>
                  )}
                </Row>
                <Row>
                  <Label lg="4" xs="3">
                    Name:
                  </Label>
                  <Col className="align-self-center">
                    <Input
                      type="text"
                      onChange={(e) => {
                        this.setState({
                          kioskModel: {
                            ...this.state.kioskModel,
                            name: e.target.value,
                          },
                        });
                      }}
                    />
                    {(this.state.kioskModel?.name == undefined ||
                      this.state.kioskModel?.name?.length == 0) &&
                    this.state.kioskAddErr?.length > 8 ? (
                      <label className="error text-danger">
                        This field is required.
                      </label>
                    ) : null}
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Label lg="6" xs="9">
                    Is Self Contained (Printer&Device):
                  </Label>
                  <Col className="align-self-center">
                    <Input
                      type="checkbox"
                      onChange={(e) => {
                        this.setState({
                          kioskModel: {
                            ...this.state.kioskModel,
                            selfContained: e.target.checked,
                          },
                        });
                      }}
                    />
                    <Label></Label>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Label lg="4" xs="3">
                    Supports:
                  </Label>
                  <Col className="align-self-center">
                    <Input
                      type="checkbox"
                      onChange={(e) => {
                        this.setState({
                          kioskModel: {
                            ...this.state.kioskModel,
                            wifi: e.target.checked,
                          },
                        });
                      }}
                    />
                    <Label>Wifi</Label>
                  </Col>
                  <Col className="align-self-center">
                    <Input
                      type="checkbox"
                      onChange={(e) => {
                        this.setState({
                          kioskModel: {
                            ...this.state.kioskModel,
                            bluetooth: e.target.checked,
                          },
                        });
                      }}
                    />
                    <Label>Bluetooth</Label>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Label lg="4" xs="3">
                    Resolution:
                  </Label>
                  <Col className="align-self-center">
                    <Label>Height</Label>
                    <Input
                      type="number"
                      max={this.state.max_height}
                      onChange={(e) => {
                        this.setState({
                          kioskModel: {
                            ...this.state.kioskModel,
                            height: e.target.value,
                          },
                        });
                      }}
                    />
                  </Col>
                  <Col className="align-self-center">
                    <Label>Width</Label>
                    <Input
                      type="number"
                      max={this.state.max_width}
                      onChange={(e) => {
                        this.setState({
                          kioskModel: {
                            ...this.state.kioskModel,
                            width: e.target.value,
                          },
                        });
                      }}
                    />
                  </Col>
                </Row>
                {(this.state.kioskModel?.bluetooth == undefined ||
                  this.state.kioskModel?.wifi == undefined ||
                  this.state.kioskModel?.bluetooth == false ||
                  this.state.kioskModel?.wifi == false) &&
                this.state.kioskAddErr?.length > 8 ? (
                  <label className="error text-danger">
                    At least one connection support is required.
                  </label>
                ) : null}
              </Col>
            </ReactBSAlert>
          )}
          <Row>
            <Col md="12">
              {ok ? (
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Kiosk</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Col className="ml-auto mr-auto" xl="8" lg="12" md="12">
                      <Row>
                        <Col xl="4" lg="6" md="6" sm="6">
                          <Button
                            color="success"
                            onClick={(e) => this.handleAdd()}
                            block
                          >
                            Add Kiosk
                          </Button>
                        </Col>
                        <Col xl="4" lg="6" md="6" sm="6">
                          <Button
                            color="primary"
                            onClick={(e) =>
                              this.setState({
                                kioskModel: {
                                  label: "Add another",
                                  value: "other",
                                },
                                kioskAddErr: "",
                              })
                            }
                            block
                          >
                            Add Kiosk Model
                          </Button>
                        </Col>

                        {/* </Row> */}
                      </Row>
                      <Form>
                        <Row>
                          <Col lg="8" xs="12">
                            <FormGroup>
                              <Label>Main Location</Label>
                              <Select
                                className="react-select info select-location"
                                classNamePrefix="react-select"
                                placeholder="Select Main Location"
                                name="selectMainLocation"
                                value={this.state.selected_main_location}
                                onChange={(e) => this.onChangeMain(e)}
                                options={this.state.main_location_list}
                              />
                            </FormGroup>
                          </Col>
                          {/* {this.state.role == "Site_Admin"&&
                                                // <Row>
                                                    <Col lg="8" xs="12">
                                                        <Select placeholder="Select Kiosk Model" value={this.state.kioskModel} onChange={(e) => this.setState({ kioskModel: e })} options={[...this.state.kioskModels, { label: "Add another", value: "other" }]} />
                                                    </Col>
                                                } */}
                        </Row>
                        <Row>
                          <Col lg="8" xs="12">
                            <FormGroup>
                              <Label>Sub Location</Label>
                              <Select
                                className="react-select info select-location"
                                classNamePrefix="react-select"
                                placeholder="Select Sub Location"
                                name="selectSubLocation"
                                value={this.state.selected_sub_location}
                                onChange={(e) => this.onChangeSub(e)}
                                options={this.state.sub_location_list}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        {/* {this.state.role == "Site_Admin"&&<Row>
                                                <Col lg="8" xs="12">
                                                    <Select placeholder="Select Kiosk Model" value={this.state.kioskModel} onChange={(e) => this.setState({ kioskModel: e })} options={[...this.state.kioskModels, { label: "Add another", value: "other" }]} />
                                                </Col>
                                            </Row>} */}
                      </Form>
                      <Row>
                        <Col xl="12" lg="12" md="12">
                          {this.getDispensers()}
                        </Col>
                      </Row>
                    </Col>
                  </CardBody>
                </Card>
              ) : null}
            </Col>
          </Row>
        </LoadingOverlay>
      </>
    );
  }
}

export default Kiosks;
