import React, { useEffect, useState, useRef } from "react";
import Firebase from "firebase";
import config from "../../../config";
import LoadingOverlay from "react-loading-overlay";
import NotificationAlert from "react-notification-alert";
import ReactBSAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import "ka-table/style.css";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Collapse,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Button,
  Label,
  Modal,
  FormGroup,
  InputGroup,
  Row,
  Col,
  Input,
  InputGroupAddon,
  InputGroupText,
  Spinner,
} from "reactstrap";

import { kaReducer, Table } from "ka-table";
import { DataType, PagingPosition, SortingMode, FilteringMode, SortDirection } from "ka-table/enums";
import { CSVLink } from "react-csv";
import { kaPropsUtils } from "ka-table/utils";

function AvailableAppointments() {
  const notificationAlert = useRef(null);

  const [state, setState1] = useState({
    loading: false,
    customer_id: "",
    email: "",
    role: "",
    location_package_id: "",
    location_image_url: "",
    location_name: "",
    location_address: "",
    selected_main_location: {},
    main_location_list: [],
    selected_sub_location: [],
    sub_location_list: [],
    services_list: [],
    services_data: [],
    services_tokens: [],
    daysStart: -5,
    daysEnd: 10,
  });

  if (!Firebase.apps.length) {
    Firebase.initializeApp(config);
  }
  useEffect(() => {
    loadMainLocations();
  }, []);
  const setState = (e) => setState1((c) => ({ ...c, ...e }));

  function loadMainLocations() {
    setState({ loading: true });
    let main_locations = [];
    let customer_id = JSON.parse(localStorage.getItem("auth_info")).customer_id;
    let email = JSON.parse(localStorage.getItem("auth_info")).email;
    let role = JSON.parse(localStorage.getItem("auth_info")).role;
    setState({ customer_id: customer_id });
    setState({ email: email });
    setState({ role: role });
 
    // ---------- Load Max Height and Width ---------- //
    Firebase.firestore()
      .collection("System_Config")
      .doc("Dispensor_Settings")
      .get()
      .then(function (dispenser_setting) {
        if (dispenser_setting.exists) {
          setState({
            max_height: dispenser_setting.data().Dispensor_Max_Height,
            max_width: dispenser_setting.data().Dispensor_Max_Width,
            kioskModels: dispenser_setting.data()?.Kiosk_Models || [],
          });
          Firebase.firestore()
            .collection("Main_Locations")
            .where("Customer_ID", "==", customer_id)
            .get()
            .then(function (response) {
              response.docs.forEach(function (doc) {
                main_locations.push({ label: doc.data().Name, value: doc.id });
              });

              setState({ main_location_list: main_locations });
              if (main_locations.length > 0) {
                let first_one =
                  main_locations.find((e) => e.label == "AntQueue Maldives") || main_locations[0];
                setState({ selected_main_location: first_one });
                loadSubLocationByMain(first_one.value, customer_id);
              } else {
                setState({ loading: false });
              }
            })
            .catch(function (err) {
              setState({ loading: false });
              notifyMessage("tc", 3, "Network error!");
              console.log("loadMainLocations NetworkError1==>", err);
            });
        } else {
          setState({ loading: false });
          notifyMessage("tc", 3, "Network error!");
          console.log("loadMainLocations NetworkError2");
        }
      })
      .catch(function (err) {
        setState({ loading: false });
        notifyMessage("tc", 3, "Network error!");
        console.log("loadMainLocations NetworkError3==>", err);
      });
  }
  function loadSubLocationByMain(main_id, id = "") {
    let customer_id = state.customer_id || id;
    setState({ loading: true });
    setState({ selected_sub_location: null });
    let sub_locations = [];
    Firebase.firestore()
      .collection("Sub_Locations")
      .where("Customer_ID", "==", customer_id)
      .where("Main_Location_ID", "==", main_id)
      .get()
      .then(function (response) {
        response.docs.forEach(function (doc) {
          sub_locations.push({
            label: doc.data().Name,
            value: doc.id,
            package_id: doc.data().Package_ID,
            image_url: doc.data().Icon,
            address: doc.data().Address,
          });
        });

        setState({ sub_location_list: sub_locations });
        if (sub_locations.length > 0) {
          let first_one = sub_locations[0];
          setState({ selected_sub_location: first_one });
          setState({ location_package_id: first_one.package_id });
          setState({ location_image_url: first_one.image_url });
          setState({ location_name: first_one.label });
          setState({ location_address: first_one.address });
          loadServiceListBySub(first_one.value, customer_id);
        } else {
          setState({ loading: false });
          notifyMessage("tc", 3, "This main location does not have any sub locations.");
        }
      })
      .catch(function (err) {
        setState({ loading: false });
        notifyMessage("tc", 3, "Network error!");
        console.log("loadSubLocationByMain NetworkError4==>", err);
      });
  }
  function loadServiceListBySub(sub_id, id = "") {
    let customer_id = state.customer_id || id;
    console.log(customer_id);
    setState({ loading: true });
    let services = [];
    Firebase.firestore()
      .collection("Services")
      .where("Customer_ID", "==", customer_id)
      .where("Sub_Location_ID", "==", sub_id)
      .where("Service_Type", "==", "Appointment")
      .get()
      .then(function (response) {
        console.log(response.docs.length);
        response.docs.forEach(function (doc) {
          
          services.push({
            service_id: doc.id,
            service_name: doc.data().Name,
            secService_name: doc.data()?.Secondary_Service_Name || "",
            service_details: doc.data().Details,
            icon: doc.data()?.Icon || "",
            tokensNum: (parseInt(doc.data().End_Number) - parseInt(doc.data().Start_Number))||0,
          });
        });

        setState({ services_list: services });
        setState({ loading: false });

        getServicesTokens(services, customer_id);
      })
      .catch(function (err) {
        setState({ loading: false });
        console.log("loadServiceListBySub NetworkError5==>", err);
        notifyMessage("tc", 3, "Network error!");
      });
  }
  async function getServicesTokens(services, id = "") {
    let customer_id = state.customer_id || id;
    let services_tokens = [],
      services_data = [],
      counter = 0;
    setState({ loading: true });

    await services.forEach((service) => {
      Firebase.firestore()
        .collection("Token_Details")
        .where("Customer_ID", "==", customer_id)
        .where("Services_ID", "==", service.service_id)
        .where("Token_Status", "in", [
          "Pending",
          "Pending_Approval",
          "Closed",
          "No_Show",
          "Now_Serving",
          "Served",
        ])
        // .get()
        .onSnapshot(async function (response) {
          counter++;
          let dateObject = {},
            tokens = [];

          await response.docs.forEach(function (doc) {
            tokens.push({
              number: doc.data().Token_Number_String,
              appointmentDate: doc.data().Service_Datetime_Unix_Timestamp,
              userEmail: doc.data().Mobile_App_User_Email,
              userName: doc.data().Mobile_App_User_Name,
              userPhone: doc.data().Mobile_App_User_Contact,
            });
          });
          await tokens.forEach((e) => {
            let tmpDate = getDate(e.appointmentDate * 1000);
            let dateIndex = dateObject[tmpDate] + 1 || 1;

            dateObject = { ...dateObject, [tmpDate]: dateIndex };
          });

          let index1 = services_tokens.indexOf(
            services_tokens.find((e) => e.service_id == service.service_id)
          );
          if (index1 >= 0) {
            services_tokens[index] = { service_id: service.service_id, tokens };
          } else services_tokens = services_tokens.concat({ service_id: service.service_id, tokens });

          setState({ services_tokens: services_tokens });


          let index = services_data.indexOf(services_data.find((e) => e.service_id == service.service_id));
          if (index >= 0) {
            services_data[index] = {
              service_id: service.service_id,
              service_name: service.service_name,
              tokensNum: service.tokensNum,
              ...dateObject,
            };
          } else services_data = services_data.concat({ tokensNum: service.tokensNum, service_id: service.service_id, service_name: service.service_name, ...dateObject });

          setState({
            services_data: services_data,
          });
        });
    });
    setState({ loading: false });
  }
  function showServiceTokens(service_id, day) {
    let row = state.services_data.find((e) => e.service_id == service_id);
    let tokens = state.services_tokens.find((e) => e.service_id == service_id).tokens;
    setState({
      alert: (
        <ReactBSAlert
          // success
          showCloseButton
          // title={'Service: ('+row.service_name+') \'s Tokens'}
          // onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          showConfirm={false}
          // confirmBtnBsStyle="info"
          style={{ minWidth: "50vw", minHeight: "50vh" }}
        >
          {/* <Table
            columns={[
              { title: "Requested Day", key: "day" },
              { title: "Service Name", key: "service_name" },
              { title: "Status", key: "status" },
              { title: "Number of Tokens", key: "day" },
            ]}
            data={[{ day, ...row, state: row.tokensNum - tokens.length > 0 ? "Open" : "Booked" }]}
          /> */}

          <table
            className="ka-table"
            style={{
              width: "100%",
              tableLayout: "fixed",
              fontWeight: "normal",
              marginTop: "3rem",
              textAlign: "left",
            }}
          >
            <thead className="ka-thead">
              <tr className="ka-tr ka-thead-row" style={{ background: "#F1F5F7" }}>
                <th className="ka-thead-cell">Requested Day</th>
                <th className="ka-thead-cell">Service Name</th>
                <th className="ka-thead-cell">Status</th>
                <th className="ka-thead-cell">Number of Tokens</th>
              </tr>
            </thead>
            <tr>
              <td className="ka-cell">{day}</td>
              <td className="ka-cell">{row.service_name}</td>
              <td className="ka-cell">{row.tokensNum - tokens.length > 0 ? "Open" : "Booked"}</td>
              <td className="ka-cell">
                {tokens.filter((e) => getDate(e.appointmentDate * 1000) == day).length || 0}
              </td>
            </tr>
          </table>

          <hr />
          <table style={{ width: "100%", tableLayout: "fixed", fontWeight: "normal", textAlign: "left" }}>
            <thead>
              <tr className="ka-tr ka-thead-row" style={{ background: "#F1F5F7" }}>
                <th className="ka-thead-cell" style={{ width: "17%" }}>
                  Token Number
                </th>
                <th className="ka-thead-cell">User Name</th>
                <th className="ka-thead-cell" style={{ width: "43%" }}>
                  User Email
                </th>
                <th className="ka-thead-cell">User Phone</th>
              </tr>
            </thead>
            <tbody>
              {tokens
                .filter((e) => getDate(e.appointmentDate * 1000) == day)
                .map((e) => (
                  <tr>
                    <td className="ka-cell">{e.number}</td>
                    <td className="ka-cell">{e.userName}</td>
                    <td className="ka-cell">{e.userEmail}</td>
                    <td className="ka-cell">{e.userPhone}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </ReactBSAlert>
      ),
    });
  }
  const hideAlert = () => {
    setState({
      alert: null,
      loading: false,
    });
  };

  function daysHeader(from, to) {
    let headers = [];
    Array.from(Array(10)).forEach((e, i) => {
      var next = new Date();
      next.setDate(next.getDate() + from+i);
      var isToday = getDate(next) == getDate(new Date());
      headers.push({
        title: next.getDate() + " " + next.toString().split(" ")[1],
        key: next.getDate() + "-" + (next.getMonth() + 1) + "-" + next.getFullYear(),
        style: isToday?{ textAlign: "center" ,background:'rgba(0,0,0,0.1)'}:{ textAlign: "center" },
        label: "day",
      });
    });
    return [{ title: "Service Name", key: "service_name", width: 200 }, ...headers];
  }
  function getDate(date) {
    let d = new Date(date);
    return d.getDate() + "-" + (d.getMonth() + 1) + "-" + d.getFullYear();
  }
  function onChangeMain(e) {
    setState({ selected_main_location: e });
    loadSubLocationByMain(e.value);
  }
  function onChangeSub(e) {
    setState({ selected_sub_location: e });
    setState({ location_package_id: e.package_id });
    setState({ location_image_url: e.image_url });
    setState({ location_name: e.label });
    setState({ location_address: e.address });
    loadServiceListBySub(e.value);
  }
  const notifyMessage = (place, color, text) => {
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }

    var options = {};
    options = {
      place: place,
      message: (
        <div className="text-md-center">
          <div>
            <b>{text}</b>
          </div>
        </div>
      ),
      type: type,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 3,
    };
    if (options !== null && notificationAlert != null) {
      notificationAlert.current.notificationAlert(options);
    }
  };

  var ok = false
  if (state.role == "System_Admin" || state.role == "Location_Admin" || state.role == "Counter_Super")
    ok = true
  return (
    <LoadingOverlay active={state.loading} spinner text="Loading" className="content dispenser">
      <NotificationAlert ref={notificationAlert} />
      {state.alert}
      {ok ?
      <>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <h4 className="padding_Dashboard card-title">Available Appointments</h4>
            </CardHeader>
            <CardBody>
              <Row>
                <Label lg="4" xs="3">
                  Main Location
                </Label>
                <Col lg="8" xs="9">
                  <FormGroup>
                    <Select
                      className="react-select info select-location"
                      classNamePrefix="react-select"
                      placeholder="Select Main Location"
                      name="selectMainLocation"
                      value={state.selected_main_location}
                      onChange={(e) => onChangeMain(e)}
                      options={state.main_location_list}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Label lg="4" xs="3">
                  Sub Location
                </Label>
                <Col lg="8" xs="9">
                  <FormGroup>
                    <Select
                      className="react-select info select-location"
                      classNamePrefix="react-select"
                      placeholder="Select Sub Location"
                      name="selectSubLocation"
                      value={state.selected_sub_location}
                      onChange={(e) => onChangeSub(e)}
                      options={state.sub_location_list}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Table
                    // data={this.state.token_details_data}
                    data={state.services_data}
                    columns={daysHeader(state.daysStart, 10)}
                    // dispatch={dispatch}
                    rowKeyField={"key"}
                    childComponents={{
                      cellText: {
                        content: (prop) => {
                          let dayTokens = prop.rowData[prop.column.key] || "-";
                          let percentage = ((Number(dayTokens)||0 )/ Number(prop.rowData.tokensNum));
                          switch (prop.column.label) {
                            case "day":
                              return (
                                <div
                                  className={
                                    "pointer-hover " +
                                    (percentage > 0.99
                                      ? "text-danger"
                                      : percentage > 0.75
                                      ? "text-warning"
                                      : "text-success")
                                  }
                                  onClick={() => showServiceTokens(prop.rowData.service_id, prop.column.key)}
                                >
                                  {dayTokens}
                                </div>
                              );
                          }
                        },
                      },
                    }}

                    // defaultPageSize={5}
                    // showPaginationTop={false}
                    // showPaginationBottom={true}
                    // showPageSizeOptions={false}
                    /*
                                            You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                                            */
                    // className="-striped -highlight primary-pagination"
                  />
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <button
                      className="btn-round btn btn-outline-primary"
                      onClick={() =>
                        setState({ daysStart:  state.daysStart - 10})
                      }
                    >
                      Previous
                    </button>
                    Click the Number for more details
                    <button
                      className="btn-round btn btn-outline-primary"
                      onClick={() =>
                        setState({ daysStart: state.daysStart + 10 })
                      }
                    >
                      Next
                    </button>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      </> : "Sorry, you don't have permission to use this function, Please contact System Administrator if this is a mistake."}
    </LoadingOverlay>
  );
}

export default AvailableAppointments;
