import React, { useState, useEffect, useRef } from "react";
import LoadingOverlay from "react-loading-overlay";
import Select from "react-select";
import NotificationAlert from "react-notification-alert";
import ReactBSAlert from "react-bootstrap-sweetalert";
import Firebase from "firebase";
import ReactTable from "react-table-6";
import { DateRangePicker, DatePicker, DateInput } from "@progress/kendo-react-dateinputs";
import moment from "moment-timezone";
import config from "../../../config";
import "ka-table/style.css";
import { kaReducer, Table } from "ka-table";
import { DataType, PagingPosition, SortingMode, FilteringMode, SortDirection } from "ka-table/enums";
import { CSVLink } from "react-csv";
import { kaPropsUtils } from "ka-table/utils";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Collapse,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Button,
  Label,
  Modal,
  FormGroup,
  InputGroup,
  Row,
  Col,
  Input,
  InputGroupAddon,
  InputGroupText,
  Spinner,
} from "reactstrap";
import Notify from "../../layouts/Admin/notify";
import TokenDetailsModal from "../../../components/TokenDetailsModal";

export default function RequestNotifications(props) {
  let data = Notify({ page: true });
  const notificationAlert = useRef(null);
  const [state, setState1] = useState({
    approving_user_id: "",
    approving_user_name: "",
    functionsBaseURL: "",
    tableprops: { data: [], columns: [] },
    loading: false,
    token_details_list: [],
    selected_token: null,
    selected_token_user_id: "",
    selected_token_time_zone: "",
    selected_token_input_fields_arr: [],
    selected_token_file_attachments_arr: [],
    selected_token_serv_date: "",
    selected_token_serv_date_unix_timestamp: "",
    selected_token_main_loc: [],
    selected_token_sub_loc: [],
    selected_token_serv: [],
    selected_token_internal_resp: "",
    selected_token_token_status: [],
    modalClassic: false,
    alert: null,
    data,
    selected_token_main_loc_list_ALL: [],
    selected_token_sub_loc_list_ALL: [],
    selected_token_serv_list_ALL: [],

    selected_token_serv_dateState: "has-success",
    selected_token_main_locState: "",
    selected_token_sub_locState: "",
    selected_token_servState: "",
    selected_token_internal_respState: "has-success",
    selected_token_token_statusState: "has-success",
  });
  const setState = (e) => setState1((c) => ({ ...c, ...e }));
  useEffect(() => {
    setState({
      functionsBaseURL: config.baseURL,
      approving_user_id: JSON.parse(localStorage.getItem("auth_info")).email,
      approving_user_name:JSON.parse(localStorage.getItem("auth_info")).username
    });
    loadMainLocations();
  }, []);
  useEffect(() => {
    setState({ data: data });
  }, [data]);

  if (!Firebase.apps.length) {
    Firebase.initializeApp(config);
  }

  const dispatch = (action) => {
    setState({
      tableprops: kaReducer(state.tableprops, action),
    });
  };

  const toggleModalClassic = () => {
    setState({
      loading: false,
      modalClassic: !state.modalClassic,
    });
  };

  function loadMainLocations() {
    // setState({loading: true});
    let main_locations = [],
      selectedMainLocIds = [];
    let customer_id = JSON.parse(localStorage.getItem("auth_info")).customer_id;
    let email = JSON.parse(localStorage.getItem("auth_info")).email;
    let role = JSON.parse(localStorage.getItem("auth_info")).role;
    setState({ customer_id: customer_id });
    setState({ email: email });
    setState({ role: role });

    Firebase.firestore()
      .collection("Main_Locations")
      .where("Customer_ID", "==", customer_id)
      .get()
      .then(function (response) {
        response.docs.forEach(function (doc) {
          main_locations.push({ label: doc.data().Name, value: doc.id });
        });
        if (main_locations.length >= 1) {
          setState({
            main_location_list: [],
            main_location_list: main_locations,
            selected_main_locations: [],
            selected_token_main_loc_list_ALL: main_locations,
            selected_main_locations: main_locations, //main_locations[0]
          });
          selectedMainLocIds = main_locations.map((prop, key) => {
            return prop.value;
          });
          loadSubLocationsByMain(selectedMainLocIds);
          // console.log("selectedMainLocIds", selectedMainLocIds);
        }
      })
      .catch(function (err) {
        setState({ loading: false });
        notifyMessage("tc", 3, "Network error!");
        console.log("loadMainLocations NetworkError0==>", err);
      });
  }

  function loadSubLocationsByMain(selectedMainLocIds) {
    // setState({loading: true});
    // console.log("selectedMainLocIds", selectedMainLocIds);
    let sub_locations = [],
      selectedSubLocIds = [];
    var customer_id = JSON.parse(localStorage.getItem("auth_info")).customer_id;
    if (selectedMainLocIds.length >= 1) {
      Firebase.firestore()
        .collection("Sub_Locations")
        .where("Customer_ID", "==", customer_id)
        .get()
        .then(function (response) {
          response.docs.forEach(function (doc) {
            if (selectedMainLocIds.includes(doc.data().Main_Location_ID)) {
              sub_locations.push({
                label: doc.data().Name,
                value: doc.id,
                main_loc_id: doc.data().Main_Location_ID,
              });
            }
          });
          if (sub_locations.length >= 1) {
            setState({
              sub_location_list: [],
              sub_location_list: sub_locations,
              selected_token_sub_loc_list_ALL: sub_locations,
            });
            selectedSubLocIds = sub_locations.map((prop, key) => {
              return prop.value;
            });
            loadServicesBySub(selectedSubLocIds);
          } else {
            setState({ loading: false });
            notifyMessage("tc", 3, "No sub locations found!");
          }
        })
        .catch(function (err) {
          setState({ loading: false });
          notifyMessage("tc", 3, "Network error!");
          console.log("loadSubLocationsByMain NetworkError0==>", err);
        });
    }
    // else { setState({loading: false}); notifyMessage("tc", 3, "No sub locations found for selected main location(s)!"); console.log("No sub locations found"); }
  }

  function loadServicesBySub(selectedSubLocIds) {
    // setState({loading: true});
    // console.log("selectedSubLocIds", selectedSubLocIds);
    let services = [],
      selectedServIds = [];
    var customer_id = JSON.parse(localStorage.getItem("auth_info")).customer_id;
    if (selectedSubLocIds.length >= 1) {
      Firebase.firestore()
        .collection("Services")
        .where("Customer_ID", "==", customer_id)
        .get()
        .then(function (response) {
          response.docs.forEach(function (doc) {
            if (selectedSubLocIds.includes(doc.data().Sub_Location_ID)) {
              services.push({
                label: doc.data().Name,
                value: doc.id,
                main_loc_id: doc.data().Main_Location_ID,
                sub_loc_id: doc.data().Sub_Location_ID,
              });
            }
          });
          if (services.length >= 1) {
            setState({
              service_list: [],
              service_list: services,
              selected_token_serv_list_ALL: services,
              selected_services: [],
              selected_services: services,
            });
            // console.log("services", services);
            // console.log("selectedServIds", selectedServIds);
          } else {
            setState({ loading: false });
            notifyMessage("tc", 3, "No sub locations found!");
          }
        })
        .catch(function (err) {
          setState({ loading: false });
          notifyMessage("tc", 3, "Network error!");
          console.log("loadServicesBySub NetworkError0==>", err);
        });
    }
    // else { setState({loading: false}); notifyMessage("tc", 3, "No sub locations found for selected main location(s)!"); console.log("No sub locations found"); }
  }
  function loadSelectedTokenSubLocsByMainLoc(e) {
    try {
      let main_loc_id = e.value;

      // let selectedTokMainLocListALL =  /* no_change */     //state.selected_token_main_loc_list_ALL;
      let selectedTokSubLocListALL = state.selected_token_sub_loc_list_ALL;
      // let selectedTokServListALL =     /* no_change */     //state.selected_token_serv_list_ALL;

      // let selectedTokMainLocList =     /* no_change */     //selectedTokMainLocListALL.filter((item, i) => { if (i>= 0) { return item; } }); //ALl main_loc items
      let selectedTokSubLocList = selectedTokSubLocListALL.filter((item, i) => {
        if (item.main_loc_id == main_loc_id) {
          return item;
        }
      });
      // let selectedTokServList =        /* no_change */     //selectedTokServListALL.filter((item, i) => { if (item.sub_loc_id == selectedToken.sub_loc_id) { return item; } });

      if (
        selectedTokSubLocList.length <= 0 ||
        selectedTokSubLocList === null ||
        typeof selectedTokSubLocList == "undefined"
      ) {
        setState({ selected_token_sub_loc_list: [] });
        setState({ selected_token_sub_loc: [] });
        setState({ selected_token_serv_list: [] });
        setState({ selected_token_serv: [] });
        setState({ selected_token_sub_locState: "has-danger" });
        setState({ selected_token_servState: "has-danger" });
        notifyMessage("tc", 3, "There are no sub locations configured under main location!");
      } else {
        let selectedTokMainLocID = e; //selectedTokMainLocList.filter((item, i) => { return item.value == selectedToken.main_loc_id; });
        let selectedTokSubLocID = selectedTokSubLocList[0]; //selectedTokSubLocList.filter((item, i) => { return item.value == selectedToken.sub_loc_id; });
        // let selectedTokServID =      /* no_change */     //selectedTokServList.filter((item, i) => { return item.value == selectedToken.serv_id; });

        // setState({selected_token_main_loc_list: selectedTokMainLocList});      /* no_change */
        setState({ selected_token_main_loc: selectedTokMainLocID });
        setState({ selected_token_sub_loc_list: selectedTokSubLocList });
        setState({ selected_token_sub_loc: selectedTokSubLocID });
        // setState({selected_token_serv_list: selectedTokServList});             /* no_change */
        // setState({selected_token_serv: selectedTokServID});                    /* no_change */
        setState({ selected_token_main_locState: "has-success" });
        setState({ selected_token_sub_locState: "has-success" });

        loadSelectedTokenServsBySubLoc(selectedTokSubLocID);
      }
    } catch (err) {
      console.log("an error occured: ", err);
    }
  }

  function loadSelectedTokenServsBySubLoc(e) {
    try {
      let sub_loc_id = e.value;

      // let selectedTokMainLocListALL =  /* no_change */     //state.selected_token_main_loc_list_ALL;
      // let selectedTokSubLocListALL =   /* no_change */     //state.selected_token_sub_loc_list_ALL;
      let selectedTokServListALL = state.selected_token_serv_list_ALL;

      // let selectedTokMainLocList =     /* no_change */     //selectedTokMainLocListALL.filter((item, i) => { if (i>= 0) { return item; } }); //ALl main_loc items
      // let selectedTokSubLocList =      /* no_change */     //selectedTokSubLocListALL.filter((item, i) => { if (item.main_loc_id == main_loc_id) { return item; } });
      let selectedTokServList = selectedTokServListALL.filter((item, i) => {
        if (item.sub_loc_id == sub_loc_id) {
          return item;
        }
      });

      if (
        selectedTokServList.length <= 0 ||
        selectedTokServList === null ||
        typeof selectedTokServList == "undefined"
      ) {
        setState({ selected_token_serv_list: [] });
        setState({ selected_token_serv: [] });
        setState({ selected_token_servState: "has-danger" });
        notifyMessage("tc", 3, "There are no services configured under sub location!");
      } else {
        // let selectedTokMainLocID =   /* no_change */     //selectedTokMainLocList.filter((item, i) => { return item.value == selectedToken.main_loc_id; });
        let selectedTokSubLocID = e; //selectedTokSubLocList[0]; //selectedTokSubLocList.filter((item, i) => { return item.value == selectedToken.sub_loc_id; });
        let selectedTokServID = selectedTokServList[0]; // selectedTokServList.filter((item, i) => { return item.value == selectedToken.serv_id; });

        // setState({selected_token_main_loc_list: selectedTokMainLocList});      /* no_change */
        // setState({selected_token_main_loc: selectedTokMainLocID});             /* no_change */
        // setState({selected_token_sub_loc_list: selectedTokSubLocList});        /* no_change *
        setState({ selected_token_sub_loc: selectedTokSubLocID });
        setState({ selected_token_serv_list: selectedTokServList });
        setState({ selected_token_serv: selectedTokServID });
        setState({ selected_token_sub_locState: "has-success" });
        setState({ selected_token_servState: "has-success" });
        // console.log({selected_token_main_loc_list: selectedTokMainLocList});         /* no_change */
        // console.log({selected_token_main_loc: selectedTokMainLocID});                /* no_change */
        // console.log({selected_token_sub_loc_list: selectedTokSubLocList});           /* no_change *
      }
    } catch (err) {
      console.log("an error occured: ", err);
    }
  }
  const handleChangeModifyTokenFields = (e, fieldType) => {
    switch (fieldType) {
      case "MOD_servDateUnixTS": {
        let selectedTokTimeZone = state.selected_token_time_zone;
        let dateString = new Date(e.target.value);
        let year = dateString.getFullYear().toString();
        let month = dateString.getMonth() + 1;
        let day = dateString.getDate();
        let timestamp = Date.parse(dateString);
        let d1 = new Date(timestamp);
        if (isNaN(timestamp) == false && parseInt(year) >= 2019) {
          setState({
            selected_token_serv_dateState: "has-success",
            selected_token_serv_date: dateString,
            selected_token_serv_date_formatted: moment(new Date(parseInt(timestamp)))
              .tz(selectedTokTimeZone)
              .format("YYYY-MM-DD"),
            selected_token_serv_date_unix_timestamp: parseInt(timestamp) / 1000,
          });
        } else {
          setState({
            selected_token_serv_dateState: "has-danger",
          });
          notifyMessage("tc", 3, "There was a problem with the new date value set!");
        }

        break;
      }
      case "MOD_mainLocId":
        setState({
          selected_token_main_locState: "has-success",
          selected_token_main_loc: e,
        });

        loadSelectedTokenSubLocsByMainLoc(e);
        break;
      case "MOD_subLocId":
        setState({
          selected_token_sub_locState: "has-success",
          selected_token_sub_loc: e,
        });
        console.log({
          selected_token_sub_locState: "has-success",
          selected_token_sub_loc: e,
        });
        loadSelectedTokenServsBySubLoc(e);
        break;
      case "MOD_servId":
        setState({
          selected_token_servState: "has-success",
          selected_token_serv: e,
        });
        console.log({
          selected_token_servState: "has-success",
          selected_token_serv: e,
        });
        break;
      case "MOD_internalResp":
        setState({
          selected_token_internal_respState: "has-success",
          selected_token_internal_resp: e.target.value,
        });
        console.log({
          selected_token_internal_respState: "has-success",
          selected_token_internal_resp: e.target.value,
        });
        break;
      case "MOD_tokenStatus":
        setState({
          selected_token_token_statusState: "has-success",
          selected_token_token_status: e,
        });
        console.log({
          selected_token_token_statusState: "has-success",
          selected_token_token_status: e,
        });
        break;
      // case "MOD_inputFieldsArr":
      //     setState({
      //         selected_token_input_fieldsState: "has-success",
      //         selected_token_input_fields:  e.target.value,
      //     });
      //     console.log({
      //         selected_token_input_fieldsState: "has-success",
      //         selected_token_input_fields:  e.target.value,
      //     });
      //     break;
      // case "MOD_fileAttachmentsArr":
      //     setState({
      //         selected_token_file_attachmentsState: "has-success",
      //         selected_token_file_attachments:  e.target.value,
      //     });
      //     console.log({
      //         selected_token_file_attachmentsState: "has-success",
      //         selected_token_file_attachments:  e.target.value,
      //     });
      //     break;
      default:
        break;
    }
  };
  const resetModalValues = (mode) => {
    // enable modal buttons
    if (mode === 1) {
      setState({
        loading: false,

        btn_disabled_save_tok: false,
        btn_disabled_approve_tok: false,
        btn_disabled_reject_tok: false,
        btn_disabled_mod_cancel: false,
      });
    }
    // reset selected token field values
    else if (mode === 2) {
      setState({
        loading: false,

        selected_token_serv_date: "",
        selected_token_serv_date_unix_timestamp: "",
        selected_token_main_loc: "",
        selected_token_sub_loc: "",
        selected_token_serv: "",
        selected_token_internal_resp: "",
      });
    }

    // enable modal buttons and reset selected token field values
    else {
      setState({
        loading: false,

        btn_disabled_save_tok: false,
        btn_disabled_approve_tok: false,
        btn_disabled_reject_tok: false,
        btn_disabled_mod_cancel: false,

        selected_token_serv_date: "",
        selected_token_serv_date_unix_timestamp: "",
        selected_token_main_loc: "",
        selected_token_sub_loc: "",
        selected_token_serv: "",
        selected_token_internal_resp: "",

        selected_token_ref_new_tok_id: "",
        selected_token_ref_new_tok_num: "",
        selected_token_ref_cancelled_reason: "",
      });
    }
  };
  const handleRequest = async (selectedToken, action, modalToggle) => {
    let approving_user_id = state.approving_user_id;
    let approving_user_name = state.approving_user_name;
    // let approving_user_request_approval_groups = state.approving_user_request_approval_groups;

    let tokID = selectedToken.token_detail_id;
    let timeZone = selectedToken.time_zone;
    let tokTokenStatus = selectedToken.token_status;
    // setState({selected_token: selectedToken})

    let updateData = {};
    let tokStatus = "";
    let resp;
    let status;
    let message;
    let message_code;
    let method;
    let result;

    if (tokTokenStatus == "Served" || tokTokenStatus == "Now_Serving") {
      if (tokTokenStatus == "Served") {
        resp = {
          status: "success",
          message: "This token has already been served. You can't modify token.",
        };
      } else if (tokTokenStatus == "Now_Serving") {
        resp = {
          status: "success",
          message: "This token is being served. You can't modify token.",
        };
      }
      notifyMessage("tc", 2, resp.message);
      if (modalToggle == 1) {
        setTimeout(function () {
          toggleModalClassic();
          resetModalValues();
        }, 1500);
      }
      return;
    }

    setState({ loading: true });
    let tokenDetailsList = state.token_details_list;
    // console.log("token_details_list0", state.token_details_list);
    let approvedTS = moment(new Date()).tz(timeZone).format("MMM DD,YYYY [at] hh:mm:ss a [UTC] z");
    let approvedUnixTS = Math.floor(new Date().getTime() / 1000);

    setState({
      // loading: true,
      btn_disabled_save_tok: true,
      btn_disabled_approve_tok: true,
      btn_disabled_reject_tok: true,
      btn_disabled_mod_cancel: true,
    });

    if (action === "approve_tok") {
      tokStatus = "Approved_Pending_Service";
      updateData["Token_Status"] = tokStatus;
    } else if (action === "reject_tok") {
      tokStatus = "Rejected";
      updateData["Token_Status"] = tokStatus;
    } else if (action === "modify_tok") {
      let selected_token_serv_dateState = state.selected_token_serv_dateState;
      let selected_token_main_locState = state.selected_token_main_locState;
      let selected_token_sub_locState = state.selected_token_sub_locState;
      let selected_token_servState = state.selected_token_servState;
      let selected_token_internal_respState = state.selected_token_internal_respState;
      let selected_token_token_statusState = state.selected_token_token_statusState;

      if (
        selected_token_serv_dateState == "has-success" &&
        selected_token_main_locState == "has-success" &&
        selected_token_sub_locState == "has-success" &&
        selected_token_servState == "has-success" &&
        selected_token_internal_respState == "has-success" &&
        selected_token_token_statusState == "has-success"
      ) {
        let tokenData = state.selected_token;
        let tokID = tokenData.token_detail_id;

        let tokUserId = tokenData.mau_id;
        let tokTimeZone = tokenData.time_zone;
        let tokInputFieldsArr = tokenData.input_fields;
        let tokFileAttachmentsArr = tokenData.file_attachments;

        let tokServDate = tokenData.service_datetime;
        let tokServDateFormatted = tokenData.service_datetime_formatted;
        let tokServDateUnixTS = tokenData.service_datetime_ts;
        let tokServDateFSTS = tokenData.service_datetime_fs_ts;
        let tokMainLocID = tokenData.main_loc_id;
        let tokSubLocID = tokenData.sub_loc_id;
        let tokServID = tokenData.serv_id;
        let tokInternalResp = tokenData.internal_response;
        let tokTokenStatus = tokenData.token_status;

        let selectedTokId = tokID; //internal field
        let selectedTokUserId = state.selected_token_user_id; //internal field
        let selectedTokTimeZone = state.selected_token_time_zone; //internal field
        let selectedTokInputFieldsArr = state.selected_token_input_fields_arr; //internal field
        let selectedTokFileAttachmentsArr = state.selected_token_file_attachments_arr; //internal field

        let selectedTokServDate = state.selected_token_serv_date;
        let selectedTokServDateFormatted = state.selected_token_serv_date_formatted;
        let selectedTokServDateUnixTS = state.selected_token_serv_date_unix_timestamp;
        let selectedTokMainLocID =
          state.selected_token_main_loc.value === null ||
          typeof state.selected_token_main_loc.value == "undefined" ||
          state.selected_token_main_loc.value == ""
            ? tokMainLocID
            : state.selected_token_main_loc.value;
        let selectedTokSubLocID =
          state.selected_token_sub_loc.value === null ||
          typeof state.selected_token_sub_loc.value == "undefined" ||
          state.selected_token_sub_loc.value == ""
            ? tokSubLocID
            : state.selected_token_sub_loc.value;
        let selectedTokServID =
          state.selected_token_serv.value === null ||
          typeof state.selected_token_serv.value == "undefined" ||
          state.selected_token_serv.value == ""
            ? tokServID
            : state.selected_token_serv.value;
        let selectedTokInternalResp = state.selected_token_internal_resp;
        let selectedTokTokenStatus =
          state.selected_token_token_status.value === null ||
          typeof state.selected_token_token_status.value == "undefined" ||
          state.selected_token_token_status.value == ""
            ? tokTokenStatus
            : state.selected_token_token_status.value;

        if (
          //1+1==2 === false && // TESTING_ONLY
          // tokServDateUnixTS == selectedTokServDateUnixTS &&
          tokServDateFormatted == selectedTokServDateFormatted &&
          (tokMainLocID == selectedTokMainLocID ||
            typeof selectedTokMainLocID == "undefined" ||
            selectedTokMainLocID === null ||
            selectedTokMainLocID == "") &&
          (tokSubLocID == selectedTokSubLocID ||
            typeof selectedTokSubLocID == "undefined" ||
            selectedTokSubLocID === null ||
            selectedTokSubLocID == "") &&
          (tokServID == selectedTokServID ||
            typeof selectedTokServID == "undefined" ||
            selectedTokServID === null ||
            selectedTokServID == "") &&
          (tokInternalResp === selectedTokInternalResp ||
            typeof selectedTokInternalResp == "undefined" ||
            selectedTokInternalResp === null ||
            selectedTokInternalResp == "") &&
          (tokTokenStatus === selectedTokTokenStatus ||
            typeof selectedTokTokenStatus == "undefined" ||
            selectedTokTokenStatus === null ||
            selectedTokTokenStatus == "")
        ) {
          resp = {
            status: "success",
            message: "No fields were changed. Token was not modified.",
          };
          notifyMessage("tc", 2, resp.message);
          if (modalToggle == 1) {
            setTimeout(function () {
              toggleModalClassic();
              resetModalValues(1);
            }, 1500);
          }
          return;
        } else {
          console.log("api called");
          console.log({
            Version: 4,
            TokenId: selectedTokId,
            UserId: selectedTokUserId,
            TimeZone: selectedTokTimeZone,
            InputFieldsArr: selectedTokInputFieldsArr,
            FileAttachmentsArr: selectedTokFileAttachmentsArr,

            MainLocId: selectedTokMainLocID,
            SubLocId: selectedTokSubLocID,
            ServiceId: selectedTokServID,
            ServDateFormatted: selectedTokServDateFormatted,
            InternalResponse: selectedTokInternalResp,
            TokenStatus: selectedTokTokenStatus,
          });

          let currentUser = Firebase.auth().currentUser;
          currentUser.getIdToken().then(function (token) {
            // console.log("token", token);

            const requestOptions = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify({
                Version: 4,
                TokenId: selectedTokId,
                UserId: selectedTokUserId,
                TimeZone: selectedTokTimeZone,
                InputFieldsArr: selectedTokInputFieldsArr,
                FileAttachmentsArr: selectedTokFileAttachmentsArr,
                MainLocId: selectedTokMainLocID,
                SubLocId: selectedTokSubLocID,
                ServiceId: selectedTokServID,
                ServiceDateFormatted: selectedTokServDateFormatted,
                InternalResponse: selectedTokInternalResp,
                TokenStatus: selectedTokTokenStatus,
              }),
            };
            let functionsBaseURL =
              state.functionsBaseURL +
              // "http://localhost:5001/antqueuelive/us-central1/"+
              "apiModifyCurrentToken_GenerateNewORUpdateExisting";
            let url = new URL(functionsBaseURL);
            fetch(url, requestOptions)
              .then((response) => response.json())
              .then((responseData) => {
                console.log("responseData", responseData);

                if (responseData.status == "success") {
                  // Update modal window with details of new token if required!
                  if (
                    responseData.message_code == "no_change" ||
                    responseData.message_code == "update_existing_token"
                  ) {
                    notifyMessage("tc", 2, responseData.message);
                    if (modalToggle == 1) {
                      setTimeout(function () {
                        toggleModalClassic();
                        resetModalValues(1);
                      }, 1500);
                    }
                    return;
                  }

                  if (responseData.message_code == "cancel_existing_token_and_create_new_token") {
                    let selectedTokenPosition = 0,
                      i = 0;
                    for (i; i < tokenDetailsList.length; i++) {
                      if (tokenDetailsList[i].token_detail_id === tokID) break;
                    }
                    selectedTokenPosition = i;

                    // 1. Make a shallow copy of the items
                    let tokDetailsList = [...state.token_details_list];
                    // 2. Make a shallow copy of the item you want to mutate
                    let tokDet = { ...tokDetailsList[selectedTokenPosition] }; //{...tokDetailsList[i]};
                    // 3. Replace the property you're intested in
                    tokDet.new_tok_ref_id = responseData.result.NewTokenReferenceID;
                    tokDet.new_tok_ref_num = responseData.result.NewToken;
                    tokDet.new_tok_ref_prev_tok_cancelled_reason = responseData.result.PrevTokCancelledReason;
                    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
                    tokDetailsList[i] = tokDet;
                    // 5. Set the state to our new copy
                    setState({ token_details_list: tokDetailsList });

                    setState({
                      selected_token_ref_new_tok_id: responseData.result.NewTokenReferenceID,
                    });
                    setState({
                      selected_token_ref_new_tok_num: responseData.result.NewToken,
                    });
                    setState({
                      selected_token_ref_cancelled_reason: responseData.result.PrevTokCancelledReason,
                    });

                    console.log("token_details_list1", tokDetailsList);
                    // console.log("token_details_list2", state.token_details_list);
                    notifyMessage("tc", 2, responseData.message);
                    if (modalToggle == 1) {
                      setTimeout(function () {
                        toggleModalClassic();
                        // resetModalValues(); //TESTING_ONLY
                      }, 1500);
                    }
                    return;
                  }
                } else if (responseData.status == "failed") {
                  resetModalValues(1);
                  notifyMessage("tc", 3, responseData.message);
                  console.log("Some error occured -- handleRequest -- 1 (api/technical)");
                  return;
                } else {
                  resetModalValues(1);
                  notifyMessage("tc", 3, "Some error occured!");
                  console.log("Some error occured -- handleRequest -- 2 (api/technical)");
                  return;
                }
              })
              .catch((error) => {
                resetModalValues(1);
                notifyMessage("tc", 3, "Some error occured!");
                console.log("Some error occured -- handleRequest -- 3 (api/technical)", error);
                return;
              });
          });
        }
      } else {
        notifyMessage("tc", 3, "Some error occured! Token was not modified.");

        // if (modalToggle == 1) {
        //     setTimeout(function(){
        //         toggleModalClassic();
        resetModalValues(2);
        //     }, 1500);
        // }
        return;
      }
    }

    if (action === "approve_tok" || action === "reject_tok") {
      console.log("part2_handleRequest");

      updateData["Token_Status"] = tokStatus;
      updateData["Approving_User_Id"] = approving_user_id;
      updateData["Approving_User_Name"] = approving_user_name;
      updateData["Approved_Timestamp"] = approvedTS;
      updateData["Approved_Unix_Timestamp"] = approvedUnixTS;
      updateData["Last_Update_Timestamp"] = approvedTS;
      updateData["Last_Update_Unix_Timestamp"] = approvedUnixTS;
      updateData["Notify_Details"] = "edit";
      Firebase.firestore()
        .collection("Token_Details")
        .doc(tokID)
        .update(updateData)
        .then(function () {
          let selectedTokenPosition = 0,
            i = 0;
          for (i; i < tokenDetailsList.length; i++) {
            if (tokenDetailsList[i].token_detail_id === tokID) break;
          }
          selectedTokenPosition = i;

          // 1. Make a shallow copy of the items
          let tokDetailsList = [...state.token_details_list];
          // 2. Make a shallow copy of the item you want to mutate
          let tokDet = { ...tokDetailsList[selectedTokenPosition] }; //{...tokDetailsList[i]};
          // 3. Replace the property you're intested in
          tokDet.token_status = tokStatus;
          // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
          tokDetailsList[i] = tokDet;
          // 5. Set the state to our new copy
          setState({ token_details_list: tokDetailsList });

          // console.log("token_details_list1", tokDetailsList);
          // console.log("token_details_list2", state.token_details_list);
          // notifyMessage("tc", 2, action === "approve_tok" ? "Token was approved" : "Token was rejected");
          if (modalToggle == 1) {
            setTimeout(function () {
              toggleModalClassic();
              resetModalValues();
            }, 1500);
          }
        })
        .catch(function (err) {
          setState({ loading: false });
          notifyMessage("tc", 3, "Network error!");
          console.log("handleRequest NetworkError1==>", err);
        });

      console.log("tokID", tokID, "action", action, "selectedToken", selectedToken);
    }
  };
  const notifyMessage = (place, color, text) => {
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }

    var options = {};
    options = {
      place: place,
      message: (
        <div className="text-md-center">
          <div>
            <b>{text}</b>
          </div>
        </div>
      ),
      type: type,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 3,
    };
    if (options !== null && notificationAlert != null) {
      notificationAlert.current.notificationAlert(options);
    }
  };
  function showTokenDetails(selectedToken) {
    let tokID = selectedToken.token_detail_id;

    let selectedTokId = tokID;
    let selectedTokTokenStatus = {
      label: formatTokenStatus(selectedToken.token_status),
      value: selectedToken.token_status,
    };
    let selectedTokUserId = selectedToken.mau_id;
    let selectedTokTimeZone = selectedToken.time_zone;
    let selectedTokInputFieldsArr = selectedToken.input_fields;
    let selectedTokFileAttachmentsArr = selectedToken.file_attachments;

    let selectedTokServDate = new Date(selectedToken.service_datetime_ts);
    let selectedTokServDateFormatted = selectedToken.service_datetime_formatted;
    let selectedTokServDateUnixTS = parseInt(selectedToken.service_datetime_ts);

    console.log(
      "selectedTokServDate",
      selectedTokServDate,
      "selectedTokServDateFormatted",
      selectedTokServDateFormatted,
      "selectedTokServDateUnixTS",
      selectedTokServDateUnixTS
    );

    let selectedTokMainLocListALL = state.selected_token_main_loc_list_ALL;
    let selectedTokSubLocListALL = state.selected_token_sub_loc_list_ALL;
    let selectedTokServListALL = state.selected_token_serv_list_ALL;
    let selectedTokTokenStatusALL = state.selected_token_token_status_list_ALL;

    let selectedTokMainLocList = selectedTokMainLocListALL.filter((item, i) => {
      if (i >= 0) {
        return item;
      }
    }); //ALl main_loc items
    let selectedTokSubLocList = selectedTokSubLocListALL.filter((item, i) => {
      if (item.main_loc_id == selectedToken.main_loc_id) {
        return item;
      }
    });
    let selectedTokServList = selectedTokServListALL.filter((item, i) => {
      if (item.sub_loc_id == selectedToken.sub_loc_id) {
        return item;
      }
    });
    let selectedTokTokenStatusList = selectedTokTokenStatusALL;

    if (
      selectedTokMainLocList.length <= 0 ||
      selectedTokMainLocList === null ||
      typeof selectedTokMainLocList == "undefined"
    ) {
      setState({ selected_token_main_loc_list: [] });
      setState({ selected_token_main_loc: [] });
      setState({ selected_token_sub_loc_list: [] });
      setState({ selected_token_sub_loc: [] });
      setState({ selected_token_serv_list: [] });
      setState({ selected_token_serv: [] });
      setState({ selected_token_main_locState: "has-danger" });
      setState({ selected_token_sub_locState: "has-danger" });
      setState({ selected_token_servState: "has-danger" });
      notifyMessage("tc", 3, "There are no main locations configured!");
      return;
    }

    if (
      selectedTokSubLocList.length <= 0 ||
      selectedTokSubLocList === null ||
      typeof selectedTokSubLocList == "undefined"
    ) {
      setState({ selected_token_sub_loc_list: [] });
      setState({ selected_token_sub_loc: [] });
      setState({ selected_token_serv_list: [] });
      setState({ selected_token_serv: [] });
      setState({ selected_token_sub_locState: "has-danger" });
      setState({ selected_token_servState: "has-danger" });
      notifyMessage("tc", 3, "There are no sub locations configured under main location!");
      return;
    }

    if (
      selectedTokServList.length <= 0 ||
      selectedTokServList === null ||
      typeof selectedTokServList == "undefined"
    ) {
      setState({ selected_token_serv_list: [] });
      setState({ selected_token_serv: [] });
      setState({ selected_token_servState: "has-danger" });
      notifyMessage("tc", 3, "There are no services configured under sub location!");
      return;
    }

    let selectedTokMainLocID = selectedTokMainLocList.filter((item, i) => {
      return item.value == selectedToken.main_loc_id;
    });
    let selectedTokSubLocID = selectedTokSubLocList.filter((item, i) => {
      return item.value == selectedToken.sub_loc_id;
    });
    let selectedTokServID = selectedTokServList.filter((item, i) => {
      return item.value == selectedToken.serv_id;
    });

    let selectedTokInternalResp = selectedToken.internal_response;
    let selectedTokRefNewTokID = selectedToken.new_tok_ref_id;
    let selectedTokRefNewTokNum = selectedToken.new_tok_ref_num;
    let selectedTokRefCancelledReason = selectedToken.new_tok_ref_prev_tok_cancelled_reason;

    setState({ selected_token_id: selectedTokId }); //internal field
    setState({ selected_token_user_id: selectedTokUserId }); //internal field
    setState({ selected_token_time_zone: selectedTokTimeZone }); //internal field
    setState({
      selected_token_input_fields_arr: selectedTokInputFieldsArr,
    }); //internal field
    setState({
      selected_token_file_attachments_arr: selectedTokFileAttachmentsArr,
    }); //internal field

    setState({ selected_token_serv_date: selectedTokServDate });
    setState({
      selected_token_serv_date_formatted: selectedTokServDateFormatted,
    });
    setState({
      selected_token_serv_date_unix_timestamp: selectedTokServDateUnixTS,
    });

    setState({ selected_token_main_loc_list: selectedTokMainLocList });
    setState({ selected_token_main_loc: selectedTokMainLocID });
    setState({ selected_token_sub_loc_list: selectedTokSubLocList });
    setState({ selected_token_sub_loc: selectedTokSubLocID });
    setState({ selected_token_serv_list: selectedTokServList });
    setState({ selected_token_serv: selectedTokServID });
    setState({
      selected_token_token_status_list: selectedTokTokenStatusList,
    });
    setState({ selected_token_token_status: selectedTokTokenStatus });

    setState({ selected_token_main_locState: "has-success" });
    setState({ selected_token_sub_locState: "has-success" });
    setState({ selected_token_servState: "has-success" });
    setState({ selected_token_servState: "has-success" });
    setState({ selected_token_token_statusState: "has-success" });

    setState({ selected_token_internal_resp: selectedTokInternalResp });

    setState({ selected_token_ref_new_tok_id: selectedTokRefNewTokID });
    setState({ selected_token_ref_new_tok_num: selectedTokRefNewTokNum });
    setState({
      selected_token_ref_cancelled_reason: selectedTokRefCancelledReason,
    });

    console.log({ selected_token_id: selectedTokId }); //internal field
    console.log({ selected_token_user_id: selectedTokUserId }); //internal field
    console.log({ selected_token_time_zone: selectedTokTimeZone }); //internal field
    console.log({ selected_token_input_fields_arr: selectedTokInputFieldsArr }); //internal field
    console.log({
      selected_token_file_attachments_arr: selectedTokFileAttachmentsArr,
    }); //internal field

    console.log({ selected_token_serv_date: selectedTokServDate });
    console.log({
      selected_token_serv_date_formatted: selectedTokServDateFormatted,
    });
    console.log({
      selected_token_serv_date_unix_timestamp: selectedTokServDateUnixTS,
    });

    console.log({ selected_token_main_loc_list: selectedTokMainLocList });
    console.log({ selected_token_main_loc: selectedTokMainLocID });
    console.log({ selected_token_sub_loc_list: selectedTokSubLocList });
    console.log({ selected_token_sub_loc: selectedTokSubLocID });
    console.log({ selected_token_serv_list: selectedTokServList });
    console.log({ selected_token_serv: selectedTokServID });
    console.log({
      selected_token_token_status_list: selectedTokTokenStatusList,
    });
    console.log({ selected_token_token_status: selectedTokTokenStatus });

    console.log({ selected_token_main_locState: "has-success" });
    console.log({ selected_token_sub_locState: "has-success" });
    console.log({ selected_token_servState: "has-success" });
    console.log({ selected_token_servState: "has-success" });
    console.log({ selected_token_token_statusState: "has-success" });

    console.log({ selected_token_internal_resp: selectedTokInternalResp });

    console.log({ selected_token_ref_new_tok_id: selectedTokRefNewTokID });
    console.log({ selected_token_ref_new_tok_num: selectedTokRefNewTokNum });
    console.log({
      selected_token_ref_cancelled_reason: selectedTokRefCancelledReason,
    });

    let horizontalTabsVal = "modifyRequestTabPane";
    if (
      selectedToken.input_fields !== null &&
      typeof selectedToken.input_fields !== "undefined" &&
      selectedToken.input_fields.length >= 1
    ) {
      horizontalTabsVal = "inputFieldsTabPane";
      console.log("showTokenDetails1");
    } else if (
      selectedToken.file_attachments !== null &&
      typeof selectedToken.file_attachments !== "undefined" &&
      selectedToken.file_attachments.length >= 1
    ) {
      horizontalTabsVal = "attachmentFieldsTabPane";
      console.log("showTokenDetails2");
    }
    setState({ horizontalTabs: horizontalTabsVal });

    resetModalValues(1);
    setState({ modalClassic: true });
    setState({ selected_token: selectedToken });

    console.log("tokID", tokID);
    console.log("selectedToken", selectedToken);
  }
  function formatTokenStatus(str) {
    var splitStr = str.toLowerCase().split("_");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }
  function tokenStatus(e) {
    let status = formatTokenStatus(e);
    if (e === "Closed" || e === "Cancelled" || e === "No_show" || e === "No_Show" || e === "Rejected")
      return <span className="badge badge-pill badge-danger">{status}</span>;
    else if (e === "Pending" || e === "Pending_Approval")
      return <span className="badge badge-pill badge-warning">{status}</span>;
    else if (e === "Now_Serving" || e === "Approved_Pending_Service")
      return <span className="badge badge-pill badge-info">{status}</span>;
    else if (e === "Served") return <span className="badge badge-pill badge-success">{status}</span>;
    else return <span className="badge badge-pill badge-default">{status}</span>;
  }

  function pendingDurFuncStr(e) {
    let d = e / (24 * 60 * 60 * 1000);
    d = d % 1 >= 0.9 ? (d -= d % 1) + 1 : d;

    let h = (d % 1) * 24;

    h = h % 1 >= 0.9 ? (h -= h % 1) + 1 : h;

    let m = (h % 1) * 60,
      s = (m % 1) * 60;
    let str =
      (Math.floor(d) > 0 ? Math.floor(d) + " Days " : "") +
      (Math.floor(h) > 0 ? " " + Math.floor(h) + "  Hours " : d != 0 || m != 0 ? "" : " 0 Hours") +
      (Math.floor(m) > 9 ? " " + Math.floor(m) + " Minutes " : "");
    // +(s > 0 ? ", " + Math.floor(s) + " S" : "");
    return { d, str };
  }

  function pendingDurFunc(elapced, remaining) {
    let str = "",
      d1 = 0,
      str1 = "",
      d = 0;
    let elapced_dur = pendingDurFuncStr(elapced);
    d = elapced_dur.d;
    str = elapced_dur.str;
    let remaining_dur = pendingDurFuncStr(remaining);
    d1 = remaining_dur.d;
    str1 = remaining_dur.str;
    //remaining time is less than 4 hours
    if (d > 1 || (remaining >= 0 && remaining / (60 * 60 * 1000) < 4))
      return <span className="badge badge-pill badge-danger">{str || "0"}</span>;

    return <span className="badge badge-pill badge-success">{str || "0"}</span>;
  }

  return (
    <LoadingOverlay active={state.loading} spinner text="Loading" className="content">
      <div className="rna-container">
        <NotificationAlert ref={notificationAlert} />
        {state.alert}
      </div>
      {state.selected_token !== null && typeof state.selected_token !== "undefined" && (
        <TokenDetailsModal
          state={state}
          setState={(e) => setState({ ...e })}
          toggleModalClassic={toggleModalClassic}
          handleChangeModifyTokenFields={handleChangeModifyTokenFields}
          handleRequest={handleRequest}
          notifyMessage={notifyMessage}
        />
      )}

      <Row className="approval-table">
        <Col md="12">
          <Card>
            <CardHeader>
              <h4 className="padding_Dashboard card-title">Requests Notifications</h4>
            </CardHeader>
            <CardBody>
              <Table
                data={state.data}
                columns={[
                  {
                    title: "Pending Duration",
                    key: "pending_duration",
                    // width: 160,
                    style: { textAlign: "center", overflow: "hidden" },
                    sortDirection: SortDirection.Descend,
                  },
                  {
                    dataType: DataType.String,
                    title: "Service Name",
                    key: "serv_name",
                  },
                  {
                    dataType: DataType.String,
                    title: "Requested Date",
                    key: "requested_datetime",
                  },
                  {
                    dataType: DataType.String,
                    title: "Token Number",
                    key: "token_num",
                    width: 100,
                    style: { textAlign: "center" },
                    filterRowOperator: "Pending_Approval",
                  },
                  {
                    dataType: DataType.String,
                    title: "Mobile App User",
                    key: "mobile_app_user",
                    width: 200,
                  },
                  {
                    dataType: DataType.String,
                    title: "Sub Location",
                    key: "sub_loc_nam",
                  },
                  {
                    title: "Token Status",
                    key: "token_status",
                    width: 160,
                    style: { textAlign: "center" },
                  },
                  {
                    title: "Info",
                    key: "info",
                    sortable: false,
                    width: 100,
                    style: { textAlign: "center" },
                  },
                  {
                    title: "Dismissal",
                    key: "dismiss",
                    sortable: false,
                    width: 120,
                    style: { textAlign: "center" },
                  },
                ]}
                paging={{
                  enabled: true,
                  pageIndex: 0,
                  pageSize: 10,
                  pageSizes: state.data.length > 10 ? [10, 50, 100] : [],
                  position: PagingPosition.Bottom,
                }}
                sortingMode={SortingMode.Single}
                rowKeyField={"k"}
                filteringMode={FilteringMode.HeaderFilter}
                columnResizing={true}
                dispatch={dispatch}
                childComponents={{
                  cellText: {
                    content: (prop) => {
                      switch (prop.column.key) {
                        case "pending_duration":
                          return pendingDurFunc(
                            Date.now() - Number(prop.rowData?.requested_datetime_ts),
                            prop.rowData?.service_datetime_ts > Date.now()
                              ? Number(prop.rowData?.service_datetime_ts) -
                                  Number(prop.rowData?.requested_datetime_ts)
                              : 0
                          );
                        case "token_status":
                          return tokenStatus(prop?.rowData?.token_status);
                        case "info":
                          return (
                            <Button
                              size="xs"
                              className="btn btn-primary btn-sm"
                              color="info"
                              onClick={() => showTokenDetails(prop?.rowData)}
                            >
                              <i className="nc-icon nc-alert-circle-i" />
                            </Button>
                          );
                        case "dismiss":
                          return (
                            <Button
                              size="xs"
                              className="btn btn-primary btn-sm"
                              color="danger"
                              onClick={() => {
                                Firebase.firestore()
                                  .collection("Token_Details")
                                  .doc(prop.rowData.id)
                                  .update({ Notify_Details: "notified" });
                              }}
                            >
                              <i className="nc-icon nc-simple-remove" />
                            </Button>
                          );
                      }
                    },
                  },
                }}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </LoadingOverlay>
  );
}